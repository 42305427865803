import {
  Component,
  OnDestroy,
  Input,
} from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms'

import { CountriesToPhoneCodes } from '@@types'

import { Subject } from 'rxjs/Subject'

import {
  map,
  takeUntil,
} from 'rxjs/operators'

import { toLower } from 'ramda'

@Component({
  selector: 'cb-phone-control',
  templateUrl: './phone-control.component.html',
  styleUrls: ['./phone-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneControlComponent,
      multi: true,
    },
  ],
})
export class PhoneControlComponent implements ControlValueAccessor, OnDestroy {
  public form: FormGroup

  public _source: CountriesToPhoneCodes[]
  public data: CountriesToPhoneCodes[]

  @Input('source')
  public set source(value: CountriesToPhoneCodes[]) {
    if (value.length) {
      this._source = value
      this.data = value
    }
  }

  public get source() {
    return this._source
  }

  public mask: '00000000000000'

  public destroy: Subject<void>

  public constructor(
    private builder: FormBuilder,
  ) {
    this.destroy = new Subject()

    this.form = this.builder.group({
      code: '',
      number: '',
    })
  }

  // KendoDropDownList
  public filterChange(value: string) {
    if (value) {
      this.data = this.source.filter(item => {
        return item.code.indexOf(value) !== -1 || toLower(item.country.name).indexOf(toLower(value)) !== -1
      })
    } else {
      this.data = this.source
    }
  }

  public reset() {
    this.data = this.source
  }
  // End KendoDropDownList

  // ControlValueAccessor
  public writeValue(value: any) {
    if (value) {
      const [code, number] = value.split(' ')
      this.form.setValue({ code, number })
    }
  }

  public registerOnChange(fn: (value: any) => void) {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy),
      map(({ code, number }) => `${code} ${number}`),
    )
    .subscribe(fn)
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable()
  }

  public registerOnTouched(fn: () => void) { }
  // End ControlValueAccessor

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
