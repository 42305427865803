import { Component } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

@Component({
  selector: 'cb-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public form: FormGroup
  public submit = false
  public constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
  ) {
    this.form = this.builder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    })
  }

  public sendPasswordReset() {
    this.submit = true
    this.auth.forgotPassword(this.getEmail())
  }

  private getEmail() {
    const control = this.form.get('email')
    return control!.value
  }
}
