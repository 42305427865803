import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router'
import {
  filter,
  map,
} from 'rxjs/operators'
import { LifecycleService } from '@core/services/lifecycle/lifecycle.service'

@Injectable({
  providedIn: 'root'
})
export class AgreementCheckGuard implements CanActivate, CanActivateChild {
  public canActivateChild = this.canActivate
  constructor(
    private service: LifecycleService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {

    const determineRouting$ = this.service.myAgreements.asObservable().pipe(
      filter(value => value != null),
      map(items => items!.length === 0)
    )



    determineRouting$.pipe(
      filter(item => !item),
    ).subscribe(() => this.router.navigate(['acknowledgement']))

    return determineRouting$
  }
}
