import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  FusionAuthClient,
  LoginResponse,
} from '@fusionauth/typescript-client'
import ClientResponse from '@fusionauth/typescript-client/build/src/ClientResponse'
import { isNil } from 'ramda'
import jwtDecode from 'jwt-decode'
import { FusionauthService } from '@core/services/fusionauth/fusionauth.service'
import { environment } from '@environments/environment'
import { PasswordCriteria } from '../../lib/password-criteria'
import { LifecycleDispatch, LifecycleActionType } from '@core/services/lifecycle/lifecycle.dispatch'
import { BehaviorSubject } from 'rxjs'
import {
  pluck,
  filter,
} from 'rxjs/operators'

enum emailTemplate {
  SetupPassword = 'Setup Password',
}
@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private authClient
  public config
  public email: string
  private expiresAt: string | null
  public emailTemplate
  public password: string
  private refreshInProgress: Boolean
  private returnURL
  private _token: any
  public twoFactorId: string
  private userId: string
  public decodedJwtRx: BehaviorSubject<any> = new BehaviorSubject(null)
  public activeUserIdRx: BehaviorSubject<string | null> = new BehaviorSubject(null)
  public userTypeRx: BehaviorSubject<string | null> = new BehaviorSubject(null)

  public constructor(
    private fusionauth: FusionauthService,
    private router: Router,
    private dispatch: LifecycleDispatch,
  ) {
    this.authClient = new FusionAuthClient(environment.fusionauth.apiKey, environment.fusionauth.baseUrl)
    this.authClient.setRequestCredentials('include')
    this.expiresAt = null
    this.refreshInProgress = false
    this.emailTemplate = emailTemplate

    this.decodedJwtRx.pipe(
      filter(value => value != null),
      pluck('CBV2'),
      pluck('X-Hasura-Active-Id')
    ).subscribe(this.activeUserIdRx)

    this.decodedJwtRx.pipe(
      filter(value => value != null),
      pluck('CBV2'),
      pluck('X-Hasura-Role')
    ).subscribe(this.userTypeRx)
  }

  public get token() {
    return this._token
  }

  public set token(value: any) {
    if (this.decodedJwtRx) {
      value ? this.decodedJwtRx.next(jwtDecode(value || '')) : this.decodedJwtRx.next(value)
    }
    this._token = value
  }

  public async login(username: string, password: string): Promise<ClientResponse<LoginResponse> | undefined> {
    const loginResponse = await this.fusionauth.login(username, password)
    if (loginResponse.statusCode === 200) {
      this.token = loginResponse.response.token!
      localStorage.setItem('qa', JSON.stringify({ token: this.token }))
      if (this.token) {
        this.refreshInProgress = false
        this.updateExpiresAt()
        this.dispatch.onLogin.next()
      }
    }
    if (loginResponse.statusCode > 200 && loginResponse.statusCode <= 242) {
      return loginResponse
    }
    if (typeof loginResponse.exception !== 'undefined') {
      return Promise.reject(loginResponse.exception.message)
    }
  }

  public async logout() {
    try {
      await this.fusionauth.logout(this.userId)
      this.expiresAt = null
      this.token = null
      this.router.navigateByUrl('/login')
      this.dispatch.generateAction.next({ type: LifecycleActionType.USER_LOGOUT })
    } catch (error) {
      console.error(error)
    }
  }

  public async twoFactorLogin(code: string, twoFactorId: string) {
    const twoFactorLoginResponse = await this.fusionauth.twoFactorLogin(code, twoFactorId)
    if (twoFactorLoginResponse.statusCode === 200) {
      this.token = twoFactorLoginResponse.response.token!
      if (this.token) {
        this.dispatch.onLogin.next()
        this.refreshInProgress = false
        this.updateExpiresAt()
      }
      return twoFactorLoginResponse
    }
  }

  public enableTwoFactor(userId: string, code: string, secret: string, deliveryMethod: number) {
    return this.fusionauth.enableTwoFactor(userId, code, secret, deliveryMethod)
  }

  public getTwoFactorSecret() {
    return this.fusionauth.generateTwoFactorSecret()
  }

  public sendTwoFactorCode(mobileNumber: string, secret: string) {
    return this.fusionauth.sendTwoFactorCode(mobileNumber, secret)
  }

  public sendTwoFactorCodeForLogin(twoFactorId: string) {
    return this.fusionauth.sendTwoFactorCodeForLogin(twoFactorId)
  }

  public async forgotPassword(email: string): Promise<boolean> {
    const forgotPasswordResponse = await this.fusionauth.forgotPassword(email)
    if (forgotPasswordResponse.statusCode === 200) {
      return true
    }

    return Promise.reject(forgotPasswordResponse.exception.message)
  }

  public async getChangePasswordId(email: string): Promise<string> {
    const generateChangePasswordIdResponse = await this.fusionauth.generateChangePasswordId(email)
    if (generateChangePasswordIdResponse.statusCode === 200) {
      return generateChangePasswordIdResponse.response.changePasswordId!
    }

    return Promise.reject(generateChangePasswordIdResponse.exception.message)
  }

  public changePassword(newPassword: string, passwordId: string, currentPassword: string | null = null) {
    const changePasswordResponse = this.fusionauth.changePassword(newPassword, passwordId, currentPassword)
    return changePasswordResponse.then(res => {
      switch (res.statusCode) {
        case 200:
          return res.response
        case 400:
          return res.response
        case 401:
          return 'Invalid Authorization Header'
        case 404:
          return 'change password token has expired please return to forgot password page to send a new password reset email'
        case 500:
          return 'Internal Error'
        case 503:
          return 'Search Index is not available'
        default:
          console.error('Not a valid status code')
          break
      }
    })
  }

  public get isAuthenticated(): boolean {
    return !isNil(this.expiresAt)
  }

  public async refreshToken() {
    if (!this.refreshInProgress) {
      this.refreshInProgress = true
      this.token = await this.fusionauth.refreshToken()
      if (this.token) {
        this.updateExpiresAt()
        this.refreshInProgress = false
      }
    }
  }

  private updateExpiresAt() {
    if (this.token) {
      const claims = JSON.parse(atob(this.token.split('.')[1]))
      this.expiresAt = claims.exp
      this.userId = claims.sub
    } else {
      this.expiresAt = null
    }
  }

  public get authorizationHeader(): string {
    return !isNil(this.token) ? `Bearer ${this.token}` : ''
  }

  public get decodedJwt() {
    return this.token ? jwtDecode(this.token) as any : ''
  }

  public get loginPath() {
    return ['/login']
  }

  public get postLoginURL() {
    const url = this.returnURL || '/'

    this.returnURL = null

    return url
  }

  public getRole() {
    const decodedJwt = this.decodedJwt
    const role = decodedJwt.CBV2['X-Hasura-Role']
    return {
      'X-Hasura-Role': role
    }
  }

  public getUserByChangePasswordId(changePasswordId: string) {
    return this.fusionauth.retrieveUserByChangePasswordId(changePasswordId)
  }

  public getUserByEmail(email: string) {
    return this.fusionauth.retrieveUserByEmail(email)
  }

  public getUserByUserId(userId: string) {
    return this.fusionauth.retrieveUserByUserId(userId)
  }

  public updateUserMobilePhone(userId: string, email: string, mobilePhone: string) {
    return this.fusionauth.updateUserMobilePhone(userId, email, mobilePhone)
  }

  public getTenant(tenantId: string) {
    return this.fusionauth.retrieveTenant(tenantId)
  }

  public generatePasswordCriteria(passwordValidationRules: any): PasswordCriteria {
    const criteria: PasswordCriteria = {
      passwordLength: `Between ${passwordValidationRules.minLength}
      and ${passwordValidationRules.maxLength} characters.`,
      mixedCase: passwordValidationRules.requireMixedCase ? 'At least one upper case and lower case letter.' : '',
      nonAlpha: passwordValidationRules.requireNonAlpha ? 'At least one special character.' : '',
      number: passwordValidationRules.requireNumber ? 'At least one number.' : ''
    }

    return criteria
  }

  public getEmailTemplates() {
    return this.fusionauth.retrieveEmailTemplates()
  }

  public verifyEmail(verificationId: string) {
    return this.fusionauth.verifyEmail(verificationId)
  }

  public sendEmailVerification(email: string) {
    return this.fusionauth.resendVerificationEmail(email)
  }

  public updateUserTwoFactorDeliveryMethod(userId: string, deliveryMethod: number, secret: string | null = null) {
    return this.fusionauth.updateUserTwoFactorDeliveryMethod(userId, deliveryMethod, secret)
  }
}
