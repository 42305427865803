import { Injectable } from '@angular/core'

import { BehaviorSubject, combineLatest } from 'rxjs'
import {
  map as mapRX,
  tap,
  filter,
  map,
  switchMap,
  mapTo,
} from 'rxjs/operators'

import {
  Stake,
  UserPermission,
  PartnerPermission,
  AdminPermission,
  Stakeholder,
} from '@@types'

import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { UtilityService } from '../utility/utility.service'
import { StakeService } from '../stake/stake.service'
import {
  pipe,
  keys,
  forEach,
} from 'ramda'
import { LifecycleDispatch } from '../lifecycle/lifecycle.dispatch'

export interface SystemAccess {
  isValid: boolean
  clients: boolean
  createClients: boolean
  editClients: boolean
  leads: boolean
  createLeads: boolean
  editLeads: boolean
  partners: boolean
  createPartners: boolean
  editPartners: boolean
  clientNotes: boolean
  leadNotes: boolean
  partnerNotes: boolean
  clientActivity: boolean
  leadActivity: boolean
  partnerActivity: boolean
  settings: boolean
  settingsRNP: boolean
  settingsAdmins: boolean
  settingsAdvanced: boolean
  settingsCompliance: boolean
  settingsPaymentMethods: boolean
  settingsPlatformServers: boolean
  settingsSegments: boolean
  settingsStakeholders: boolean
  requests: boolean
  transactions: boolean

  accounts: boolean
  createAccount: boolean
  editAccount: boolean
  viewClientAccounts: boolean
  viewLeadAccounts: boolean
  viewPartnerAccounts: boolean
  createClientAccounts: boolean
  createLeadAccounts: boolean
  createPartnerAccounts: boolean

  createAccountApprovalNotRequired: boolean
  createClientAccountApprovalNotRequired: boolean
  createLeadAccountApprovalNotRequired: boolean
  createPartnerAccountApprovalNotRequired: boolean

  editClientRisk: boolean
  viewClientRisk: boolean
  editLeadRisk: boolean
  viewLeadRisk: boolean
  editPartnerRisk: boolean
  viewPartnerRisk: boolean

  editClientCategory: boolean
  viewClientCategory: boolean
  editLeadCategory: boolean
  viewLeadCategory: boolean
  editPartnerCategory: boolean
  viewPartnerCategory: boolean

  editClientFlags: boolean
  viewClientFlags: boolean
  editLeadFlags: boolean
  viewLeadFlags: boolean
  editPartnerFlags: boolean
  viewPartnerFlags: boolean

  editClientRepresentatives: boolean
  viewClientRepresentatives: boolean
  editLeadRepresentatives: boolean
  viewLeadRepresentatives: boolean
  editPartnerRepresentatives: boolean
  viewPartnerRepresentatives: boolean

  editClientTags: boolean
  viewClientTags: boolean
  editLeadTags: boolean
  viewLeadTags: boolean
  editPartnerTags: boolean
  viewPartnerTags: boolean


  createTransaction: boolean
  createDeposit: boolean
  createWithdrawal: boolean
  createTransfer: boolean
  createFees: boolean
  createCredit: boolean
  editTransaction: boolean
  editDeposit: boolean
  editWithdrawal: boolean
  editTransfer: boolean
  editFees: boolean
  editCredit: boolean
  reviewDeposit: boolean
  reviewWithdrawal: boolean
  reviewTransfer: boolean
  reviewTransferBetweenProfiles: boolean
  reviewFees: boolean
  reviewCredit: boolean

  stakeholderList: boolean
  createStakeholder: boolean
  editStakeholder: boolean
  stakeholderFiles: boolean
  uploadStakeholderFile: boolean
  editStakeholderFile: boolean
  stakeholderActivity: boolean
  viewBankAccounts: boolean
  createBankAccount: boolean
  editBankAccount: boolean
}

export enum UserType {
  Admin = 'administrator',
  Partner = 'partner',
  User = 'user',
}

const noUser: SystemAccess = {
  isValid: false,
  clients: false,
  createClients: false,
  editClients: false,
  leads: false,
  createLeads: false,
  editLeads: false,
  partners: false,
  createPartners: false,
  editPartners: false,
  clientNotes: false,
  leadNotes: false,
  partnerNotes: false,
  clientActivity: false,
  leadActivity: false,
  partnerActivity: false,
  settings: false,
  settingsRNP: false,
  settingsAdmins: false,
  settingsAdvanced: false,
  settingsCompliance: false,
  settingsPaymentMethods: false,
  settingsPlatformServers: false,
  settingsSegments: false,
  settingsStakeholders: false,
  requests: false,
  transactions: false,
  accounts: false,
  createAccount: false,
  editAccount: false,
  viewClientAccounts: false,
  viewLeadAccounts: false,
  viewPartnerAccounts: false,
  createClientAccounts: false,
  createLeadAccounts: false,
  createPartnerAccounts: false,

  createAccountApprovalNotRequired: false,
  createClientAccountApprovalNotRequired: false,
  createLeadAccountApprovalNotRequired: false,
  createPartnerAccountApprovalNotRequired: false,

  editClientRisk: false,
  viewClientRisk: false,
  editLeadRisk: false,
  viewLeadRisk: false,
  editPartnerRisk: false,
  viewPartnerRisk: false,

  editClientCategory: false,
  viewClientCategory: false,
  editLeadCategory: false,
  viewLeadCategory: false,
  editPartnerCategory: false,
  viewPartnerCategory: false,

  editClientFlags: false,
  viewClientFlags: false,
  editLeadFlags: false,
  viewLeadFlags: false,
  editPartnerFlags: false,
  viewPartnerFlags: false,

  editClientRepresentatives: false,
  viewClientRepresentatives: false,
  editLeadRepresentatives: false,
  viewLeadRepresentatives: false,
  editPartnerRepresentatives: false,
  viewPartnerRepresentatives: false,

  editClientTags: false,
  viewClientTags: false,
  editLeadTags: false,
  viewLeadTags: false,
  editPartnerTags: false,
  viewPartnerTags: false,
  createTransaction: false,
  createDeposit: false,
  createWithdrawal: false,
  createTransfer: false,
  createFees: false,
  createCredit: false,
  editTransaction: false,
  editDeposit: false,
  editWithdrawal: false,
  editTransfer: false,
  editFees: false,
  editCredit: false,
  reviewDeposit: false,
  reviewWithdrawal: false,
  reviewTransfer: false,
  reviewTransferBetweenProfiles: false,
  reviewFees: false,
  reviewCredit: false,


  stakeholderList: false,
  createStakeholder: false,
  editStakeholder: false,
  stakeholderFiles: false,
  uploadStakeholderFile: false,
  editStakeholderFile: false,
  stakeholderActivity: false,
  viewBankAccounts: false,
  createBankAccount: false,
  editBankAccount: false,
}

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  public userType: UserType
  public user: any = null
  public totalAccess = false
  public currentStakeholder: Stakeholder
  public location = new BehaviorSubject<SystemAccess>(noUser)

  constructor(
    private auth: AuthenticationService,
    private stake: StakeService,
    private util: UtilityService,
    private dispatch: LifecycleDispatch,
  ) {
    combineLatest(
      [
        this.auth.activeUserIdRx.pipe(filter(value => value != null)),
        this.auth.userTypeRx.pipe(filter(value => value != null))
      ]
    ).pipe(
      map(([activeId, _userType]) => activeId),
      switchMap(id => this.stake.stakeUserAccessById(id!)),
      tap(currentStake => this.currentStakeholder = currentStake.holder),
      mapRX(this.mapPermissions)
    ).subscribe(access => {
        this.location.next(access)
        this.userType = this.auth.decodedJwt.CBV2['X-Hasura-Role']
      })

    this.dispatch.onUserLogOut.pipe(
      mapTo(noUser),
    ).subscribe(this.location)

    const cleanse$ = this.dispatch.onUserLogOut.pipe(
      mapTo(null)
    )

    cleanse$.subscribe(this.auth.userTypeRx)
  }

  public get activeId() {
    return this.auth.decodedJwt['CBV2']['X-Hasura-Active-Id']
  }

  public get isAdmin() {
    return this.userType === UserType.Admin
  }

  public get isPartner() {
    return this.userType === UserType.Partner
  }

  public get isUser() {
    return this.userType === UserType.User
  }

  public mapPermissions = (stake: Stake): SystemAccess => {

    // USER
    if (stake && stake.user_permission_set && !this.totalAccess) {
      const permissionSet: UserPermission = stake.user_permission_set

      const access: SystemAccess = {
        isValid: true,
        clients: false,
        createClients: false,
        editClients: false,
        leads: false,
        createLeads: false,
        editLeads: false,
        partners: false,
        createPartners: false,
        editPartners: false,
        clientNotes: false,
        leadNotes: false,
        partnerNotes: false,
        clientActivity: false,
        leadActivity: false,
        partnerActivity: false,
        settings: false,
        settingsRNP: false,
        settingsAdmins: false,
        settingsAdvanced: false,
        settingsCompliance: false,
        settingsPaymentMethods: false,
        settingsPlatformServers: false,
        settingsSegments: false,
        settingsStakeholders: false,
        requests: false,
        transactions: this.util.checkRoles([
          permissionSet.transaction_list
        ], 1),
        accounts: this.util.checkRoles([
          permissionSet.account_list
        ], 1),
        createAccount: this.util.checkRoles([
          permissionSet.manage_account
        ], 5),
        editAccount: this.util.checkRoles([
          permissionSet.manage_account
        ], 4),

        viewClientAccounts: false,
        viewLeadAccounts: false,
        viewPartnerAccounts: false,

        createClientAccounts: false,
        createLeadAccounts: false,
        createPartnerAccounts: false,

        createAccountApprovalNotRequired: this.util.checkRoles([
          permissionSet.account_require_approval
        ], 5),
        createClientAccountApprovalNotRequired: false,
        createLeadAccountApprovalNotRequired: false,
        createPartnerAccountApprovalNotRequired: false,

        editClientRisk: false,
        viewClientRisk: false,
        editLeadRisk: false,
        viewLeadRisk: false,
        editPartnerRisk: false,
        viewPartnerRisk: false,

        editClientCategory: false,
        viewClientCategory: false,
        editLeadCategory: false,
        viewLeadCategory: false,
        editPartnerCategory: false,
        viewPartnerCategory: false,

        editClientFlags: false,
        viewClientFlags: false,
        editLeadFlags: false,
        viewLeadFlags: false,
        editPartnerFlags: false,
        viewPartnerFlags: false,

        editClientRepresentatives: false,
        viewClientRepresentatives: false,
        editLeadRepresentatives: false,
        viewLeadRepresentatives: false,
        editPartnerRepresentatives: false,
        viewPartnerRepresentatives: false,

        editClientTags: false,
        viewClientTags: false,
        editLeadTags: false,
        viewLeadTags: false,
        editPartnerTags: false,
        viewPartnerTags: false,

        createTransaction: this.util.checkRoles([
          permissionSet.transaction_deposit,
          permissionSet.transaction_withdrawal,
          permissionSet.transaction_transfer,
          permissionSet.transaction_service_fee,
          permissionSet.transaction_transfer_between_profiles,
        ], 5),
        createDeposit: this.util.checkRoles([
          permissionSet.transaction_deposit,
        ], 5),
        createWithdrawal: this.util.checkRoles([
          permissionSet.transaction_withdrawal,
        ], 5),
        createTransfer: this.util.checkRoles([
          permissionSet.transaction_transfer,
        ], 5),
        createFees: this.util.checkRoles([
          permissionSet.transaction_service_fee,
        ], 5),
        createCredit: this.util.checkRoles([
          permissionSet.transaction_credit,
        ], 5),
        editTransaction: this.util.checkRoles([
          permissionSet.transaction_deposit,
          permissionSet.transaction_withdrawal,
          permissionSet.transaction_transfer,
          permissionSet.transaction_service_fee,
          permissionSet.transaction_transfer_between_profiles,
        ], 4),
        editDeposit: false,
        editWithdrawal: false,
        editTransfer: false,
        editFees: false,
        editCredit: false,
        reviewDeposit: false,
        reviewWithdrawal: false,
        reviewTransfer: false,
        reviewTransferBetweenProfiles: false,
        reviewFees: false,
        reviewCredit: false,

        stakeholderList: false,
        createStakeholder: false,
        editStakeholder: true,
        stakeholderFiles: false,
        uploadStakeholderFile: this.util.checkRoles([
          permissionSet.manage_stakeholder_file,
        ], 4),
        editStakeholderFile: false,
        stakeholderActivity: false,
        viewBankAccounts: false,
        createBankAccount: false,
        editBankAccount: false,
      }

      return access
    }

    // Partner
    if (stake && stake.partner_permission_set && !this.totalAccess) {
      const permissionSet: PartnerPermission = stake.partner_permission_set

      const access: SystemAccess = {
        isValid: true,
        clients: this.util.checkRoles([
          permissionSet.client_list
        ], 5),
        createClients: this.util.checkRoles([
          permissionSet.create_client,
        ], 5),
        editClients: this.util.checkRoles([
          permissionSet.edit_client,
        ], 5),
        leads: this.util.checkRoles([
          permissionSet.lead_list
        ], 5),
        createLeads: this.util.checkRoles([
          permissionSet.create_lead,
        ], 5),
        editLeads: this.util.checkRoles([
          permissionSet.edit_lead,
        ], 5),
        partners: this.util.checkRoles([
          permissionSet.partner_list
        ], 5),
        createPartners: this.util.checkRoles([
          permissionSet.create_partner,
        ], 5),
        editPartners: this.util.checkRoles([
          permissionSet.edit_partner,
        ], 5),
        clientNotes: this.util.checkRoles([
          permissionSet.client_manage_notes!,
        ], 5),
        leadNotes: this.util.checkRoles([
          permissionSet.lead_manage_notes,
        ], 5),
        partnerNotes: this.util.checkRoles([
          permissionSet.partner_manage_notes,
        ], 5),

        clientActivity: this.util.checkRoles([
          permissionSet.client_activity!,
        ], 5),
        leadActivity: this.util.checkRoles([
          permissionSet.lead_activity,
        ], 5),
        partnerActivity: this.util.checkRoles([
          permissionSet.partner_activity,
        ], 5),
        settings: false,
        settingsRNP: false,
        settingsAdmins: false,
        settingsAdvanced: false,
        settingsCompliance: false,
        settingsPaymentMethods: false,
        settingsPlatformServers: false,
        settingsSegments: false,
        settingsStakeholders: false,
        requests: false,
        transactions: this.util.checkRoles([
          permissionSet.client_transaction_deposit,
          permissionSet.client_transaction_withdrawal,
          permissionSet.client_transaction_between,
          permissionSet.client_transaction_credit,
        ], 3),
        accounts: this.util.checkRoles([
          permissionSet.client_accounts,
          permissionSet.lead_account_list,
          permissionSet.partner_accounts,
        ], 5),
        createAccount: true,
        editAccount: true,

        viewClientAccounts: this.util.checkRoles([
          permissionSet.client_accounts,
        ], 5),
        viewLeadAccounts: this.util.checkRoles([
          permissionSet.lead_account_list,
        ], 5),
        viewPartnerAccounts: this.util.checkRoles([
          permissionSet.partner_accounts,
        ], 5),

        createClientAccounts: this.util.checkRoles([
          permissionSet.client_manage_account,
        ], 4),
        createLeadAccounts: this.util.checkRoles([
          permissionSet.lead_manage_account,
        ], 4),
        createPartnerAccounts: this.util.checkRoles([
          permissionSet.partner_manage_account,
        ], 4),

        createAccountApprovalNotRequired: this.util.checkRoles([
          permissionSet.account_require_approval
        ], 5),
        createClientAccountApprovalNotRequired: this.util.checkRoles([
          permissionSet.client_account_require_approval
        ], 5),
        createLeadAccountApprovalNotRequired: this.util.checkRoles([
          permissionSet.lead_account_require_approval
        ], 5),
        createPartnerAccountApprovalNotRequired: this.util.checkRoles([
          permissionSet.partner_account_require_approval
        ], 5),

        editClientRisk: this.util.checkRoles([
          permissionSet.edit_client,
        ], 5),
        viewClientRisk: true,
        editLeadRisk: this.util.checkRoles([
          permissionSet.edit_lead,
        ], 5),
        viewLeadRisk: true,
        editPartnerRisk: this.util.checkRoles([
          permissionSet.edit_partner,
        ], 5),
        viewPartnerRisk: true,

        editClientCategory: this.util.checkRoles([
          permissionSet.edit_client,
        ], 5),
        viewClientCategory: true,
        editLeadCategory: this.util.checkRoles([
          permissionSet.edit_lead,
        ], 5),
        viewLeadCategory: true,
        editPartnerCategory: this.util.checkRoles([
          permissionSet.edit_partner,
        ], 5),
        viewPartnerCategory: true,

        editClientFlags: this.util.checkRoles([
          permissionSet.edit_client,
        ], 5),
        viewClientFlags: true,
        editLeadFlags: this.util.checkRoles([
          permissionSet.edit_lead,
        ], 5),
        viewLeadFlags: true,
        editPartnerFlags: this.util.checkRoles([
          permissionSet.edit_partner,
        ], 5),
        viewPartnerFlags: true,

        editClientRepresentatives: false,
        viewClientRepresentatives: false,
        editLeadRepresentatives: false,
        viewLeadRepresentatives: false,
        editPartnerRepresentatives: false,
        viewPartnerRepresentatives: false,

        editClientTags: this.util.checkRoles([
          permissionSet.edit_client,
        ], 5),
        viewClientTags: true,
        editLeadTags: this.util.checkRoles([
          permissionSet.edit_lead,
        ], 5),
        viewLeadTags: true,
        editPartnerTags: this.util.checkRoles([
          permissionSet.edit_partner,
        ], 5),
        viewPartnerTags: true,


        createTransaction: true,
        createDeposit: true,
        createWithdrawal: true,
        createTransfer: true,
        createFees: true,
        createCredit: true,
        editTransaction: true,
        editDeposit: true,
        editWithdrawal: true,
        editTransfer: true,
        editFees: true,
        editCredit: true,
        reviewDeposit: false,
        reviewWithdrawal: false,
        reviewTransfer: false,
        reviewTransferBetweenProfiles: false,
        reviewFees: false,
        reviewCredit: false,

        stakeholderList: this.util.checkRoles([
          permissionSet.stakeholder_list,
        ], 5),
        createStakeholder: false,
        editStakeholder: false,
        stakeholderFiles: false,
        uploadStakeholderFile: false,
        editStakeholderFile: false,
        stakeholderActivity: false,
        viewBankAccounts: false,
        createBankAccount: false,
        editBankAccount: false,
      }
      // const access: SystemAccess = {
      //   clients: false,
      //   leads: false,
      //   partners: false,
      //   settings: false,
      //   transactions: this.util.checkRoles([
      //     permissionSet.transaction_list
      //   ], 1),
      //   accounts: this.util.checkRoles([
      //     permissionSet.account_list
      //   ], 1),
      //   createAccount: this.util.checkRoles([
      //     permissionSet.manage_account
      //   ], 5),
      //   editAccount: this.util.checkRoles([
      //     permissionSet.manage_account
      //   ], 4),
      //   createTransaction: this.util.checkRoles([
      //     permissionSet.transaction_deposit,
      //     permissionSet.transaction_withdrawal,
      //     permissionSet.transaction_transfer,
      //     permissionSet.transaction_service_fee,
      //     permissionSet.transaction_transfer_between_profiles,
      //   ], 5),
      //   createDeposit: this.util.checkRoles([
      //     permissionSet.transaction_deposit,
      //   ], 5),
      //   createWithdrawal: this.util.checkRoles([
      //     permissionSet.transaction_withdrawal,
      //   ], 5),
      //   createTransfer: this.util.checkRoles([
      //     permissionSet.transaction_transfer,
      //   ], 5),
      //   createFees: this.util.checkRoles([
      //     permissionSet.transaction_service_fee,
      //   ], 5),
      //   createCredit: this.util.checkRoles([
      //     permissionSet.transaction_credit,
      //   ], 5),
      //   editTransaction: this.util.checkRoles([
      //     permissionSet.transaction_deposit,
      //     permissionSet.transaction_withdrawal,
      //     permissionSet.transaction_transfer,
      //     permissionSet.transaction_service_fee,
      //     permissionSet.transaction_transfer_between_profiles,
      //   ], 4),
      //   editDeposit: this.util.checkRoles([
      //     permissionSet.transaction_deposit,
      //   ], 4),
      //   editWithdrawal: this.util.checkRoles([
      //     permissionSet.transaction_withdrawal,
      //   ], 4),
      //   editTransfer: this.util.checkRoles([
      //     permissionSet.transaction_transfer,
      //   ], 4),
      //   editFees: this.util.checkRoles([
      //     permissionSet.transaction_service_fee,
      //   ], 4),
      //   editCredit: this.util.checkRoles([
      //     permissionSet.transaction_credit,
      //   ], 4),
      // }

      return access
    }

    const permissions = stake.admin_permission_sets.map(set => set.permission)
    const adminPermissionSet: AdminPermission = permissions.reduce((total, current, _index) => {
      if (!total) {
        return current
      }
      const obj = {}
      const process = pipe(
        keys,
        forEach(item => {
          const max = [total![item], current[item]].sort()[1]
          obj[item] = max
        })
      )

      process(current)

      return obj
    }, null) as AdminPermission

    return {
      isValid: true,
      clients: this.util.checkRoles([
        adminPermissionSet.client_list
      ], 4),
      createClients: this.util.checkRoles([
        adminPermissionSet.create_client
      ], 5),
      editClients: this.util.checkRoles([
        adminPermissionSet.edit_client
      ], 5),
      leads: this.util.checkRoles([
        adminPermissionSet.lead_list
      ], 5),
      createLeads: this.util.checkRoles([
        adminPermissionSet.create_lead
      ], 4),
      editLeads: this.util.checkRoles([
        adminPermissionSet.edit_lead
      ], 5),
      partners: this.util.checkRoles([
        adminPermissionSet.partner_list
      ], 4),
      createPartners: this.util.checkRoles([
        adminPermissionSet.create_partner
      ], 5),
      editPartners: this.util.checkRoles([
        adminPermissionSet.edit_partner
      ], 5),
      clientNotes: this.util.checkRoles([
        adminPermissionSet.notes_client
      ], 4),
      leadNotes: this.util.checkRoles([
        adminPermissionSet.notes_lead
      ], 4),
      partnerNotes: this.util.checkRoles([
        adminPermissionSet.notes_partner
      ], 4),
      clientActivity: this.util.checkRoles([
        adminPermissionSet.activity_list_client
      ], 5),
      leadActivity: this.util.checkRoles([
        adminPermissionSet.activity_list_lead
      ], 5),
      partnerActivity: this.util.checkRoles([
        adminPermissionSet.activity_list_partner
      ], 5),
      settings: this.util.checkRoles([
        adminPermissionSet.settings
      ], 5),
      settingsRNP: this.util.checkRoles([
        adminPermissionSet.settings_RNP
      ], 5),
      settingsAdmins: this.util.checkRoles([
        adminPermissionSet.settings_admins
      ], 5),
      settingsAdvanced: this.util.checkRoles([
        adminPermissionSet.settings_advanced
      ], 5),
      settingsCompliance: this.util.checkRoles([
        adminPermissionSet.settings_compliance
      ], 5),
      settingsPaymentMethods: this.util.checkRoles([
        adminPermissionSet.settings_payment_methods
      ], 5),
      settingsPlatformServers: this.util.checkRoles([
        adminPermissionSet.settings_platform_servers
      ], 5),
      settingsSegments: this.util.checkRoles([
        adminPermissionSet.settings_segments
      ], 5),
      settingsStakeholders: this.util.checkRoles([
        adminPermissionSet.settings_stakeholders
      ], 5),
      requests: true,
      transactions: this.util.checkRoles([
        adminPermissionSet.transaction_list_client,
        adminPermissionSet.transaction_list_lead,
        adminPermissionSet.transaction_list_partner,
      ], 5),
      accounts: this.util.checkRoles([
        adminPermissionSet.account_list_client,
        adminPermissionSet.account_list_lead,
        adminPermissionSet.account_list_partner,
      ], 5),
      createAccount: true,
      editAccount: true,
      viewClientAccounts: this.util.checkRoles([
        adminPermissionSet.account_list_client,
      ], 5),
      viewLeadAccounts: this.util.checkRoles([
        adminPermissionSet.account_list_lead,
      ], 5),
      viewPartnerAccounts: this.util.checkRoles([
        adminPermissionSet.account_list_partner,
      ], 5),
      createClientAccounts: this.util.checkRoles([
        adminPermissionSet.manage_account_client
      ], 4),
      createLeadAccounts: this.util.checkRoles([
        adminPermissionSet.manage_account_lead
      ], 4),
      createPartnerAccounts: this.util.checkRoles([
        adminPermissionSet.manage_account_partner
      ], 4),

      createAccountApprovalNotRequired: true,
      createClientAccountApprovalNotRequired: true,
      createLeadAccountApprovalNotRequired: true,
      createPartnerAccountApprovalNotRequired: true,

      editClientRisk: this.util.checkRoles([
        adminPermissionSet.risk_client,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_client,
      ], 5),
      viewClientRisk: this.util.checkRoles([
        adminPermissionSet.risk_client,
      ], 4),
      editLeadRisk: this.util.checkRoles([
        adminPermissionSet.risk_lead,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_lead,
      ], 5),
      viewLeadRisk: this.util.checkRoles([
        adminPermissionSet.risk_lead,
      ], 4),
      editPartnerRisk: this.util.checkRoles([
        adminPermissionSet.risk_partner,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_partner,
      ], 5),
      viewPartnerRisk: this.util.checkRoles([
        adminPermissionSet.risk_partner,
      ], 4),

      editClientCategory: this.util.checkRoles([
        adminPermissionSet.category_client,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_client,
      ], 5),
      viewClientCategory: this.util.checkRoles([
        adminPermissionSet.category_client,
      ], 4),
      editLeadCategory: this.util.checkRoles([
        adminPermissionSet.category_lead,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_lead,
      ], 5),
      viewLeadCategory: this.util.checkRoles([
        adminPermissionSet.category_lead,
      ], 4),
      editPartnerCategory: this.util.checkRoles([
        adminPermissionSet.category_partner,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_partner,
      ], 5),
      viewPartnerCategory: this.util.checkRoles([
        adminPermissionSet.category_partner,
      ], 4),

      editClientFlags: this.util.checkRoles([
        adminPermissionSet.flag_client,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_client,
      ], 5),
      viewClientFlags: this.util.checkRoles([
        adminPermissionSet.flag_client,
      ], 4),
      editLeadFlags: this.util.checkRoles([
        adminPermissionSet.flag_lead,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_lead,
      ], 5),
      viewLeadFlags: this.util.checkRoles([
        adminPermissionSet.flag_lead,
      ], 4),
      editPartnerFlags: this.util.checkRoles([
        adminPermissionSet.flag_partner,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_partner,
      ], 5),
      viewPartnerFlags: this.util.checkRoles([
        adminPermissionSet.flag_partner,
      ], 4),

      editClientRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_client,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_client,
      ], 5),
      viewClientRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_client,
      ], 4),
      editLeadRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_lead,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_lead,
      ], 5),
      viewLeadRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_lead,
      ], 4),
      editPartnerRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_partner,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_partner,
      ], 5),
      viewPartnerRepresentatives: this.util.checkRoles([
        adminPermissionSet.representative_partner,
      ], 4),

      editClientTags: this.util.checkRoles([
        adminPermissionSet.tag_client,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_client,
      ], 5),
      viewClientTags: this.util.checkRoles([
        adminPermissionSet.tag_client,
      ], 4),
      editLeadTags: this.util.checkRoles([
        adminPermissionSet.tag_lead,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_lead,
      ], 5),
      viewLeadTags: this.util.checkRoles([
        adminPermissionSet.tag_lead,
      ], 4),
      editPartnerTags: this.util.checkRoles([
        adminPermissionSet.tag_partner,
      ], 5) && this.util.checkRoles([
        adminPermissionSet.edit_partner,
      ], 5),
      viewPartnerTags: this.util.checkRoles([
        adminPermissionSet.tag_partner,
      ], 4),


      createTransaction: this.util.checkRoles([
        adminPermissionSet.deposit_transaction_client,
        adminPermissionSet.deposit_transaction_lead,
        adminPermissionSet.deposit_transaction_partner,
        adminPermissionSet.withdrawal_transaction_client,
        adminPermissionSet.withdrawal_transaction_lead,
        adminPermissionSet.withdrawal_transaction_partner,
        adminPermissionSet.transfer_transaction_client,
        adminPermissionSet.transfer_transaction_lead,
        adminPermissionSet.transfer_transaction_partner,
        adminPermissionSet.transfer_between_profiles_transaction_client,
        adminPermissionSet.transfer_between_profiles_transaction_lead,
        adminPermissionSet.transfer_between_profiles_transaction_partner,
      ], 4),
      createDeposit: this.util.checkRoles([
        adminPermissionSet.deposit_transaction_client,
        adminPermissionSet.deposit_transaction_lead,
        adminPermissionSet.deposit_transaction_partner,
      ], 5),
      createWithdrawal: this.util.checkRoles([
        adminPermissionSet.withdrawal_transaction_client,
        adminPermissionSet.withdrawal_transaction_lead,
        adminPermissionSet.withdrawal_transaction_partner,
      ], 4),
      createTransfer: this.util.checkRoles([
        adminPermissionSet.transfer_transaction_client,
        adminPermissionSet.transfer_transaction_lead,
        adminPermissionSet.transfer_transaction_partner,
        adminPermissionSet.transfer_between_profiles_transaction_client,
        adminPermissionSet.transfer_between_profiles_transaction_lead,
        adminPermissionSet.transfer_between_profiles_transaction_partner,
      ], 4),
      createFees: false,
      createCredit: false,
      editTransaction: true,
      editDeposit: true,
      editWithdrawal: true,
      editTransfer: true,
      editFees: true,
      editCredit: true,
      reviewDeposit: this.util.checkRoles([
        adminPermissionSet.deposit_transaction_client,
        adminPermissionSet.deposit_transaction_lead,
        adminPermissionSet.deposit_transaction_partner,
      ], 5),
      reviewWithdrawal: this.util.checkRoles([
        adminPermissionSet.withdrawal_transaction_client,
        adminPermissionSet.withdrawal_transaction_lead,
        adminPermissionSet.withdrawal_transaction_partner,
      ], 5),
      reviewTransfer: this.util.checkRoles([
        adminPermissionSet.transfer_transaction_client,
        adminPermissionSet.transfer_transaction_lead,
        adminPermissionSet.transfer_transaction_partner,
      ], 5),
      reviewTransferBetweenProfiles: this.util.checkRoles([
        adminPermissionSet.transfer_between_profiles_transaction_client,
        adminPermissionSet.transfer_between_profiles_transaction_lead,
        adminPermissionSet.transfer_between_profiles_transaction_partner,
      ], 5),
      reviewFees: this.util.checkRoles([
        adminPermissionSet.service_fee_transaction_client,
        adminPermissionSet.service_fee_transaction_lead,
        adminPermissionSet.service_fee_transaction_partner,
      ], 5),
      reviewCredit: this.util.checkRoles([
        adminPermissionSet.credit_transaction_client,
        adminPermissionSet.credit_transaction_lead,
        adminPermissionSet.credit_transaction_partner,
      ], 5),

      // stakeholderList: this.util.checkRoles([
      //   permissionSet.stakeholder_list
      // ], 5),

      stakeholderList: this.util.checkRoles([
        adminPermissionSet.stakeholder_list
      ], 5),
      createStakeholder: this.util.checkRoles([
        adminPermissionSet.manage_stakeholder,
      ], 4, true) || this.util.checkRoles([
        adminPermissionSet.manage_stakeholder,
      ], 5),
      editStakeholder: this.util.checkRoles([
        adminPermissionSet.manage_stakeholder,
      ], 3, true) || this.util.checkRoles([
        adminPermissionSet.manage_stakeholder,
      ], 5),
      stakeholderFiles: this.util.checkRoles([
        adminPermissionSet.file_list,
      ], 5),
      uploadStakeholderFile: this.util.checkRoles([
        adminPermissionSet.manage_file
      ], 4, true) || this.util.checkRoles([
        adminPermissionSet.manage_file
      ], 5),
      editStakeholderFile: this.util.checkRoles([
        adminPermissionSet.manage_file
      ], 3, true) || this.util.checkRoles([
        adminPermissionSet.manage_file
      ], 5),
      stakeholderActivity: this.util.checkRoles([
        adminPermissionSet.activity_list
      ], 5),
      viewBankAccounts: this.util.checkRoles([
        adminPermissionSet.bank_account_list
      ], 5),
      createBankAccount: this.util.checkRoles([
        adminPermissionSet.manage_bank_account
      ], 4, true) || this.util.checkRoles([
        adminPermissionSet.manage_bank_account
      ], 5),
      editBankAccount: this.util.checkRoles([
        adminPermissionSet.manage_bank_account
      ], 3, true) || this.util.checkRoles([
        adminPermissionSet.manage_bank_account
      ], 5),
    }
  }
}
