import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { AuthGuard } from '@authentication/guards/auth.guard'

import { ProfileTypeResolver } from '@core/resolvers/profile-type.resolver'

import { LayoutComponent } from '@core/pages/layout/layout.component'
import { DashboardComponent } from '@dashboard/pages/dashboard/dashboard.component'
import { PageNotFoundComponent } from '@core/pages/page-not-found/page-not-found.component'
import { AgreementCheckGuard } from '@core/guards/agreement-check.guard'

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AgreementCheckGuard, AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        component: DashboardComponent,
      },
      {
        path: 'profiles',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/accounts/accounts.module#AccountsModule',
      },
      {
        path: 'clients',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/clients/clients.module#ClientsModule',
        resolve: {
          profileType: ProfileTypeResolver,
        },
        data: {
          type: 'Client',
          permission: 'clients',
        },
      },
      {
        path: 'partners',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/partners/partners.module#PartnersModule',
        resolve: {
          profileType: ProfileTypeResolver,
        },
        data: {
          type: 'Partner',
          permission: 'partners',
        },
      },
      {
        path: 'leads',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/leads/leads.module#LeadsModule',
        resolve: {
          profileType: ProfileTypeResolver,
        },
        data: {
          type: 'Lead',
        },
      },
      {
        path: 'transactions',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/transactions/transactions.module#TransactionsModule',
      },
      {
        path: 'stakeholders',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/stakeholders/stakeholders.module#StakeholdersModule',
      },
      {
        path: 'requests',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/requests/requests.module#RequestsModule',
        data: {
          permission: 'requests',
        },
      },
      // {
      //   path: 'marketing',
      //   canActivateChild: [AuthGuard],
      //   loadChildren: 'app/features/marketing/marketing.module#MarketingModule',
      // },
      // {
      //   path: 'reports',
      //   canActivateChild: [AuthGuard],
      //   loadChildren: 'app/features/reports/reports.module#ReportsModule',
      // },
      {
        path: 'marketing',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/marketing/marketing.module#MarketingModule',
      },
      {
        path: 'settings',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/settings/settings.module#SettingsModule',
      },
      {
        path: 'user',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/user-settings/user-settings.module#UserSettingsModule',
      },
      {
        path: 'notifications',
        canActivateChild: [AuthGuard],
        loadChildren: 'app/features/notifications/notifications.module#NotificationsModule',
      },
      { path: 'demo', loadChildren: 'app/features/demo/demo.module#DemoModule' },
      { path: '**', component: PageNotFoundComponent },
    ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    // enableTracing: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
