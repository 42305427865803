import {
  Component,
  OnInit,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms'
import {
  ActivatedRoute,
  Router,
} from '@angular/router'
import { CountriesToPhoneCodes } from '@@types'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { MockData} from '../../mock-data/mock-data'
import { LifecycleDispatch, LifecycleAction, LifecycleActionType } from '@core/services/lifecycle/lifecycle.dispatch'

@Component({
  selector: 'cb-set-two-factor',
  templateUrl: './set-two-factor.component.html',
  styleUrls: ['./set-two-factor.component.scss']
})

export class SetTwoFactorComponent implements OnInit {
  public angularxQrCode = ''
  public codeSent: boolean
  public email: string | null
  public form: FormGroup
  public mockData: CountriesToPhoneCodes[]
  public qrSecret: string
  public smsSecret: any
  public userId: any
  public mobileError: boolean
  public errorCode: string
  public errorMessage: string
  public constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
    private dispatch: LifecycleDispatch,
    private mock: MockData,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.form = this.builder.group({
      radio: ['sms', Validators.required],
      phone: [''],
      code: [''],
      first_name: [null],
      last_name: [null]
    })

    this.mockData = this.mock.mockCountriesToPhoneCodes
    this.mockData.sort((a, b) => Number(a.code.slice(1)) < Number(b.code.slice(1)) ? -1 : 1)
  }

  public get radio() {
    return this.form.get('radio')!.value
  }

  public get code() {
    return this.form.get('code')!.value
  }

  public get firstName() {
    return this.form.get('first_name')!.value
  }

  public get lastName() {
    return this.form.get('last_name')!.value
  }

  public resetMobileError() {
    this.mobileError = false
    const phonControl = this.form.get('phone') as FormControl
    phonControl.setValue('')
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId')
      this.auth.getUserByUserId(this.userId).then(userResponse => {
        this.email = userResponse.response.user!.email!
      })
    })

    const twoFactorSecretResponse = this.auth.getTwoFactorSecret()

    twoFactorSecretResponse.then(response => {
      this.smsSecret = response.secret
      this.qrSecret = response.secretBase32Encoded
      this.angularxQrCode = `otpauth://totp/Example:email@currentdesk.com?secret=${this.qrSecret}&issuer=v2.currentdesk.com`
    })
  }

  public goBack() {
    const changePasswordIdResponse = this.auth.getChangePasswordId(this.email!)
    changePasswordIdResponse.then(changePasswordId => {
        this.router.navigate([`setPassword/${changePasswordId}`])
    })
  }

  public formatMobileNumber() {
    const mobileNumber = this.form.get('phone')!.value
    const formattedMobileNumber = mobileNumber.slice(1)
    return formattedMobileNumber
  }

  public sendCode() {
    const mobileNumber = this.formatMobileNumber()
    this.auth.sendTwoFactorCode(mobileNumber, this.smsSecret).then(res => {
      this.codeSent = res.wasSuccessful()
      if (!this.codeSent) {
        this.mobileError = true
        this.errorCode = res.statusCode.toString()
      } else {
        this.mobileError = false
        this.errorCode = ''
      }
    })
  }

  public async finishSetup() {
    const deliveryMethod = this.radio === 'app' ? 0 : 1

    const enableTwoFactorResponse = await this.auth.enableTwoFactor(this.userId, this.code, this.smsSecret, deliveryMethod)

    if (enableTwoFactorResponse.wasSuccessful()) {
      this.auth.login(this.email!, this.auth.password).then(async (res) => {
        const response = await this.auth.twoFactorLogin(this.code, (res!.response.twoFactorId!))
        if (response!.statusCode === 200) {
          if (deliveryMethod === 1) {
            this.auth.updateUserMobilePhone(this.userId, (this.email!), this.formatMobileNumber())
            .then(updateResponse => {
              if (updateResponse.statusCode === 400) {
                this.mobileError = true
                this.errorCode = updateResponse.statusCode.toString()
              } else {
                this.router.navigateByUrl(this.auth.postLoginURL)
              }
            })
          }
          const action: LifecycleAction = {
            type: LifecycleActionType.FINISH_USER_SETUP,
            data: {
              first_name: this.firstName,
              last_name: this.lastName,
            }
          }
          this.dispatch.generateAction.next(action)
          this.router.navigateByUrl(this.auth.postLoginURL)
        } else {
          this.errorCode = response!.statusCode.toString()
        }
      })
    } else {
      if (enableTwoFactorResponse.statusCode === 400) {
        this.errorCode = enableTwoFactorResponse.statusCode.toString()
      }
    }
  }
}
