import {
  Component,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core'
import {
  Subject,
  Observable,
  BehaviorSubject
} from 'rxjs'
import { LifecycleService } from '@core/services/lifecycle/lifecycle.service'
import {
  Agreement,
  Stakeholder
} from '@@types'
import {
  takeUntil
} from 'rxjs/operators'
import { FormBuilder } from '@angular/forms'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { AgreementsAcknowledgementConfirmComponent } from '@core/components/agreements-acknowledgement-confirm/agreements-acknowledgement-confirm.component'
import { IdentityService } from '@core/services/identity/identity.service'
import { Router } from '@angular/router'

@Component({
  selector: 'cb-agreement-acknowledgement',
  templateUrl: './agreement-acknowledgement.component.html',
  styleUrls: ['./agreement-acknowledgement.component.scss']
})
export class AgreementAcknowledgementComponent implements OnDestroy {
  public destroy: Subject<void> = new Subject()
  public agreementData: Observable<Agreement[] | null>
  public me: BehaviorSubject<Stakeholder | null> = new BehaviorSubject(null)

  @ViewChild(AgreementsAcknowledgementConfirmComponent, {static: false})
  private component: AgreementsAcknowledgementConfirmComponent
  public agreementsNotAccepted = true

  public constructor(
    private auth: AuthenticationService,
    protected builder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private identity: IdentityService,
    private lifecycle: LifecycleService,
    private router: Router,
  ) {
    this.identity.me.pipe(
      takeUntil(this.destroy),
    ).subscribe(this.me)

    this.agreementData = this.lifecycle.myAgreements
  }

  public async cancel() {
    await this.auth.logout()
  }

  public async continue() {
    const response = await this.component.saveAcceptedAgreements()
    if (response) {
      this.lifecycle.myAgreements.next([])
      this.router.navigate(['/'])
    }
  }


  public receiveAgreementAcknowledgmentValidation(value: boolean) {
    this.agreementsNotAccepted = value
    this.changeDetector.detectChanges()
  }

  ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }

}
