import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export enum Toast {
  createProfile = 'createProfile',
  createAccount = 'createAccount',
  createPlatformServer = 'createPlatformServer',
  serverError = 'serverError',
  uploadFile = 'uploadFile',
  createAgreement = 'createAgreement',
  updateAgreement = 'updateAgreement',
  deleteAgreement = 'deleteAgreement',
  publishAgreement = 'publishAgreement',
  createBankAccount = 'createBankAccount',
  accountGroupSettings = 'accountGroupSettings',
  createAccountGroup = 'createAccountGroup',
  createSecurity = 'createSecurity',
  updateSecurity = 'updateSecurity',
  updateSymbol = 'updateSymbol',
  updatePlatformServer = 'updatePlatformServer',
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public deliverToast: Subject<Toast> = new Subject()
}
