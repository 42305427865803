// THIS IS A GENERATED FILE
// tslint:disable
export type Maybe<T> = T | null;


/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  timestamptz: string,
  numeric: number,
  bigint: number,
  date: any,
  jsonb: any,
  json: any,
  uuid: any,
}

export interface AccessFormat {
   __typename?: 'access_format',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  type: AccessFormatType,
  type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  url: Scalars['String'],
}

export interface AccessFormatAggregate {
   __typename?: 'access_format_aggregate',
  aggregate?: Maybe<AccessFormatAggregateFields>,
  nodes: Array<AccessFormat>,
}

export interface AccessFormatAggregateFields {
   __typename?: 'access_format_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccessFormatMaxFields>,
  min?: Maybe<AccessFormatMinFields>,
}


export interface AccessFormatAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccessFormatSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccessFormatAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccessFormatMaxOrderBy>,
  min?: Maybe<AccessFormatMinOrderBy>,
}

export interface AccessFormatArrRelInsertInput {
  data: Array<AccessFormatInsertInput>,
  on_conflict?: Maybe<AccessFormatOnConflict>,
}

export interface AccessFormatBoolExp {
  _and?: Maybe<Array<Maybe<AccessFormatBoolExp>>>,
  _not?: Maybe<AccessFormatBoolExp>,
  _or?: Maybe<Array<Maybe<AccessFormatBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  type?: Maybe<AccessFormatTypeBoolExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  url?: Maybe<StringComparisonExp>,
}

export enum AccessFormatConstraint {
  AccessFormatPkey = 'access_format_pkey'
}

export interface AccessFormatInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  type?: Maybe<AccessFormatTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface AccessFormatMaxFields {
   __typename?: 'access_format_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface AccessFormatMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface AccessFormatMinFields {
   __typename?: 'access_format_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface AccessFormatMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface AccessFormatMutationResponse {
   __typename?: 'access_format_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccessFormat>,
}

export interface AccessFormatObjRelInsertInput {
  data: AccessFormatInsertInput,
  on_conflict?: Maybe<AccessFormatOnConflict>,
}

export interface AccessFormatOnConflict {
  constraint: AccessFormatConstraint,
  update_columns: Array<AccessFormatUpdateColumn>,
  where?: Maybe<AccessFormatBoolExp>,
}

export interface AccessFormatOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  type?: Maybe<AccessFormatTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface AccessFormatPkColumnsInput {
  id: Scalars['String'],
}

export enum AccessFormatSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  PlatformServerId = 'platform_server_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface AccessFormatSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface AccessFormatType {
   __typename?: 'access_format_type',
  access_formats: Array<AccessFormat>,
  access_formats_aggregate: AccessFormatAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface AccessFormatTypeAccessFormatsArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface AccessFormatTypeAccessFormatsAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}

export interface AccessFormatTypeAggregate {
   __typename?: 'access_format_type_aggregate',
  aggregate?: Maybe<AccessFormatTypeAggregateFields>,
  nodes: Array<AccessFormatType>,
}

export interface AccessFormatTypeAggregateFields {
   __typename?: 'access_format_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccessFormatTypeMaxFields>,
  min?: Maybe<AccessFormatTypeMinFields>,
}


export interface AccessFormatTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccessFormatTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccessFormatTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccessFormatTypeMaxOrderBy>,
  min?: Maybe<AccessFormatTypeMinOrderBy>,
}

export interface AccessFormatTypeArrRelInsertInput {
  data: Array<AccessFormatTypeInsertInput>,
  on_conflict?: Maybe<AccessFormatTypeOnConflict>,
}

export interface AccessFormatTypeBoolExp {
  _and?: Maybe<Array<Maybe<AccessFormatTypeBoolExp>>>,
  _not?: Maybe<AccessFormatTypeBoolExp>,
  _or?: Maybe<Array<Maybe<AccessFormatTypeBoolExp>>>,
  access_formats?: Maybe<AccessFormatBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AccessFormatTypeConstraint {
  AccessFormatTypeNameKey = 'access_format_type_name_key',
  AccessFormatTypePkey = 'access_format_type_pkey'
}

export interface AccessFormatTypeInsertInput {
  access_formats?: Maybe<AccessFormatArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccessFormatTypeMaxFields {
   __typename?: 'access_format_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccessFormatTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccessFormatTypeMinFields {
   __typename?: 'access_format_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccessFormatTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccessFormatTypeMutationResponse {
   __typename?: 'access_format_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccessFormatType>,
}

export interface AccessFormatTypeObjRelInsertInput {
  data: AccessFormatTypeInsertInput,
  on_conflict?: Maybe<AccessFormatTypeOnConflict>,
}

export interface AccessFormatTypeOnConflict {
  constraint: AccessFormatTypeConstraint,
  update_columns: Array<AccessFormatTypeUpdateColumn>,
  where?: Maybe<AccessFormatTypeBoolExp>,
}

export interface AccessFormatTypeOrderBy {
  access_formats_aggregate?: Maybe<AccessFormatAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccessFormatTypePkColumnsInput {
  id: Scalars['String'],
}

export enum AccessFormatTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface AccessFormatTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum AccessFormatTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum AccessFormatUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  PlatformServerId = 'platform_server_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface Account {
   __typename?: 'account',
  account_grid_view?: Maybe<Account>,
  account_number?: Maybe<AccountNumber>,
  allow_password_change: Scalars['Boolean'],
  balance: Scalars['numeric'],
  created_at: Scalars['timestamptz'],
  credit: Scalars['numeric'],
  currency: Currency,
  currency_id: Scalars['String'],
  equity: Scalars['numeric'],
  group?: Maybe<AccountGroup>,
  group_id?: Maybe<Scalars['String']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  id: Scalars['String'],
  incoming_transactions: Array<Transaction>,
  incoming_transactions_aggregate: TransactionAggregate,
  leverage?: Maybe<Scalars['Int']>,
  margin_used: Scalars['numeric'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  master_partner_client_permission: MasterPartnerClientPermission,
  master_partner_client_permission_id: Scalars['String'],
  master_partner_lead_permission: MasterPartnerLeadPermission,
  master_partner_lead_permission_id: Scalars['String'],
  master_partner_partner_permission: MasterPartnerPartnerPermission,
  master_partner_partner_permission_id: Scalars['String'],
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  needs_margin_call: Scalars['Boolean'],
  number: Scalars['Int'],
  outgoing_transactions: Array<Transaction>,
  outgoing_transactions_aggregate: TransactionAggregate,
  platform_access?: Maybe<PlatformAccess>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_account_id?: Maybe<Scalars['String']>,
  profile: Profile,
  profile_id: Scalars['String'],
  profit_and_loss?: Maybe<Scalars['numeric']>,
  type: AccountType,
  type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface AccountIncomingTransactionsArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface AccountIncomingTransactionsAggregateArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface AccountOutgoingTransactionsArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface AccountOutgoingTransactionsAggregateArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}

export interface AccountAggregate {
   __typename?: 'account_aggregate',
  aggregate?: Maybe<AccountAggregateFields>,
  nodes: Array<Account>,
}

export interface AccountAggregateFields {
   __typename?: 'account_aggregate_fields',
  avg?: Maybe<AccountAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountMaxFields>,
  min?: Maybe<AccountMinFields>,
  stddev?: Maybe<AccountStddevFields>,
  stddev_pop?: Maybe<AccountStddevPopFields>,
  stddev_samp?: Maybe<AccountStddevSampFields>,
  sum?: Maybe<AccountSumFields>,
  var_pop?: Maybe<AccountVarPopFields>,
  var_samp?: Maybe<AccountVarSampFields>,
  variance?: Maybe<AccountVarianceFields>,
}


export interface AccountAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountAggregateOrderBy {
  avg?: Maybe<AccountAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountMaxOrderBy>,
  min?: Maybe<AccountMinOrderBy>,
  stddev?: Maybe<AccountStddevOrderBy>,
  stddev_pop?: Maybe<AccountStddevPopOrderBy>,
  stddev_samp?: Maybe<AccountStddevSampOrderBy>,
  sum?: Maybe<AccountSumOrderBy>,
  var_pop?: Maybe<AccountVarPopOrderBy>,
  var_samp?: Maybe<AccountVarSampOrderBy>,
  variance?: Maybe<AccountVarianceOrderBy>,
}

export interface AccountArrRelInsertInput {
  data: Array<AccountInsertInput>,
  on_conflict?: Maybe<AccountOnConflict>,
}

export interface AccountAvgFields {
   __typename?: 'account_avg_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountAvgOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountBoolExp {
  _and?: Maybe<Array<Maybe<AccountBoolExp>>>,
  _not?: Maybe<AccountBoolExp>,
  _or?: Maybe<Array<Maybe<AccountBoolExp>>>,
  account_grid_view?: Maybe<AccountBoolExp>,
  account_number?: Maybe<AccountNumberBoolExp>,
  allow_password_change?: Maybe<BooleanComparisonExp>,
  balance?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  credit?: Maybe<NumericComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  equity?: Maybe<NumericComparisonExp>,
  group?: Maybe<AccountGroupBoolExp>,
  group_id?: Maybe<StringComparisonExp>,
  high_water_mark?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  incoming_transactions?: Maybe<TransactionBoolExp>,
  leverage?: Maybe<IntComparisonExp>,
  margin_used?: Maybe<NumericComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionBoolExp>,
  master_partner_client_permission_id?: Maybe<StringComparisonExp>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionBoolExp>,
  master_partner_lead_permission_id?: Maybe<StringComparisonExp>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionBoolExp>,
  master_partner_partner_permission_id?: Maybe<StringComparisonExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  needs_margin_call?: Maybe<BooleanComparisonExp>,
  number?: Maybe<IntComparisonExp>,
  outgoing_transactions?: Maybe<TransactionBoolExp>,
  platform_access?: Maybe<PlatformAccessBoolExp>,
  platform_access_id?: Maybe<StringComparisonExp>,
  platform_account_id?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  type?: Maybe<AccountTypeBoolExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AccountConstraint {
  AccountPkey = 'account_pkey',
  AccountPlatformAccountIdGroupIdKey = 'account_platform_account_id_group_id_key'
}

export interface AccountCreationRequest {
   __typename?: 'account_creation_request',
  account_group?: Maybe<AccountGroup>,
  account_type: AccountType,
  allow_password_change: Scalars['Boolean'],
  balance?: Maybe<Scalars['numeric']>,
  created_at: Scalars['timestamptz'],
  currency: Currency,
  currency_id: Scalars['String'],
  group_id?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  leverage?: Maybe<Scalars['Int']>,
  leverageByLeverage?: Maybe<Leverage>,
  master_user_permission?: Maybe<MasterUserPermission>,
  master_user_permission_id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  platform_access?: Maybe<PlatformAccess>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_id?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServer>,
  platform_server_type?: Maybe<PlatformServerType>,
  profile: Profile,
  profile_id: Scalars['String'],
  request: Request,
  request_id: Scalars['String'],
  server_id?: Maybe<Scalars['String']>,
  type_id: Scalars['String'],
}

export interface AccountCreationRequestAggregate {
   __typename?: 'account_creation_request_aggregate',
  aggregate?: Maybe<AccountCreationRequestAggregateFields>,
  nodes: Array<AccountCreationRequest>,
}

export interface AccountCreationRequestAggregateFields {
   __typename?: 'account_creation_request_aggregate_fields',
  avg?: Maybe<AccountCreationRequestAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountCreationRequestMaxFields>,
  min?: Maybe<AccountCreationRequestMinFields>,
  stddev?: Maybe<AccountCreationRequestStddevFields>,
  stddev_pop?: Maybe<AccountCreationRequestStddevPopFields>,
  stddev_samp?: Maybe<AccountCreationRequestStddevSampFields>,
  sum?: Maybe<AccountCreationRequestSumFields>,
  var_pop?: Maybe<AccountCreationRequestVarPopFields>,
  var_samp?: Maybe<AccountCreationRequestVarSampFields>,
  variance?: Maybe<AccountCreationRequestVarianceFields>,
}


export interface AccountCreationRequestAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountCreationRequestSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountCreationRequestAggregateOrderBy {
  avg?: Maybe<AccountCreationRequestAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountCreationRequestMaxOrderBy>,
  min?: Maybe<AccountCreationRequestMinOrderBy>,
  stddev?: Maybe<AccountCreationRequestStddevOrderBy>,
  stddev_pop?: Maybe<AccountCreationRequestStddevPopOrderBy>,
  stddev_samp?: Maybe<AccountCreationRequestStddevSampOrderBy>,
  sum?: Maybe<AccountCreationRequestSumOrderBy>,
  var_pop?: Maybe<AccountCreationRequestVarPopOrderBy>,
  var_samp?: Maybe<AccountCreationRequestVarSampOrderBy>,
  variance?: Maybe<AccountCreationRequestVarianceOrderBy>,
}

export interface AccountCreationRequestArrRelInsertInput {
  data: Array<AccountCreationRequestInsertInput>,
  on_conflict?: Maybe<AccountCreationRequestOnConflict>,
}

export interface AccountCreationRequestAvgFields {
   __typename?: 'account_creation_request_avg_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestAvgOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestBoolExp {
  _and?: Maybe<Array<Maybe<AccountCreationRequestBoolExp>>>,
  _not?: Maybe<AccountCreationRequestBoolExp>,
  _or?: Maybe<Array<Maybe<AccountCreationRequestBoolExp>>>,
  account_group?: Maybe<AccountGroupBoolExp>,
  account_type?: Maybe<AccountTypeBoolExp>,
  allow_password_change?: Maybe<BooleanComparisonExp>,
  balance?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  group_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  leverage?: Maybe<IntComparisonExp>,
  leverageByLeverage?: Maybe<LeverageBoolExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_access?: Maybe<PlatformAccessBoolExp>,
  platform_access_id?: Maybe<StringComparisonExp>,
  platform_id?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_type?: Maybe<PlatformServerTypeBoolExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  request?: Maybe<RequestBoolExp>,
  request_id?: Maybe<StringComparisonExp>,
  server_id?: Maybe<StringComparisonExp>,
  type_id?: Maybe<StringComparisonExp>,
}

export enum AccountCreationRequestConstraint {
  AccountCreationRequestPkey = 'account_creation_request_pkey'
}

export interface AccountCreationRequestIncInput {
  balance?: Maybe<Scalars['numeric']>,
  leverage?: Maybe<Scalars['Int']>,
}

export interface AccountCreationRequestInsertInput {
  account_group?: Maybe<AccountGroupObjRelInsertInput>,
  account_type?: Maybe<AccountTypeObjRelInsertInput>,
  allow_password_change?: Maybe<Scalars['Boolean']>,
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  leverageByLeverage?: Maybe<LeverageObjRelInsertInput>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_access?: Maybe<PlatformAccessObjRelInsertInput>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_id?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_type?: Maybe<PlatformServerTypeObjRelInsertInput>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  request?: Maybe<RequestObjRelInsertInput>,
  request_id?: Maybe<Scalars['String']>,
  server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
}

export interface AccountCreationRequestMaxFields {
   __typename?: 'account_creation_request_max_fields',
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
}

export interface AccountCreationRequestMaxOrderBy {
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
  server_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface AccountCreationRequestMinFields {
   __typename?: 'account_creation_request_min_fields',
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
}

export interface AccountCreationRequestMinOrderBy {
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
  server_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface AccountCreationRequestMutationResponse {
   __typename?: 'account_creation_request_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountCreationRequest>,
}

export interface AccountCreationRequestObjRelInsertInput {
  data: AccountCreationRequestInsertInput,
  on_conflict?: Maybe<AccountCreationRequestOnConflict>,
}

export interface AccountCreationRequestOnConflict {
  constraint: AccountCreationRequestConstraint,
  update_columns: Array<AccountCreationRequestUpdateColumn>,
  where?: Maybe<AccountCreationRequestBoolExp>,
}

export interface AccountCreationRequestOrderBy {
  account_group?: Maybe<AccountGroupOrderBy>,
  account_type?: Maybe<AccountTypeOrderBy>,
  allow_password_change?: Maybe<OrderBy>,
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  leverageByLeverage?: Maybe<LeverageOrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_access?: Maybe<PlatformAccessOrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_id?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_type?: Maybe<PlatformServerTypeOrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  request?: Maybe<RequestOrderBy>,
  request_id?: Maybe<OrderBy>,
  server_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface AccountCreationRequestPkColumnsInput {
  id: Scalars['String'],
}

export enum AccountCreationRequestSelectColumn {
  AllowPasswordChange = 'allow_password_change',
  Balance = 'balance',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  GroupId = 'group_id',
  Id = 'id',
  Leverage = 'leverage',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  PlatformAccessId = 'platform_access_id',
  PlatformId = 'platform_id',
  ProfileId = 'profile_id',
  RequestId = 'request_id',
  ServerId = 'server_id',
  TypeId = 'type_id'
}

export interface AccountCreationRequestSetInput {
  allow_password_change?: Maybe<Scalars['Boolean']>,
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  server_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
}

export interface AccountCreationRequestStddevFields {
   __typename?: 'account_creation_request_stddev_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestStddevOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestStddevPopFields {
   __typename?: 'account_creation_request_stddev_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestStddevPopOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestStddevSampFields {
   __typename?: 'account_creation_request_stddev_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestStddevSampOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestSumFields {
   __typename?: 'account_creation_request_sum_fields',
  balance?: Maybe<Scalars['numeric']>,
  leverage?: Maybe<Scalars['Int']>,
}

export interface AccountCreationRequestSumOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export enum AccountCreationRequestUpdateColumn {
  AllowPasswordChange = 'allow_password_change',
  Balance = 'balance',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  GroupId = 'group_id',
  Id = 'id',
  Leverage = 'leverage',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  PlatformAccessId = 'platform_access_id',
  PlatformId = 'platform_id',
  ProfileId = 'profile_id',
  RequestId = 'request_id',
  ServerId = 'server_id',
  TypeId = 'type_id'
}

export interface AccountCreationRequestVarPopFields {
   __typename?: 'account_creation_request_var_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestVarPopOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestVarSampFields {
   __typename?: 'account_creation_request_var_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestVarSampOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountCreationRequestVarianceFields {
   __typename?: 'account_creation_request_variance_fields',
  balance?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
}

export interface AccountCreationRequestVarianceOrderBy {
  balance?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
}

export interface AccountGridView {
   __typename?: 'account_grid_view',
  account?: Maybe<Account>,
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  group?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  margin_used?: Maybe<Scalars['numeric']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  profile_type?: Maybe<Scalars['String']>,
  profit_and_loss?: Maybe<Scalars['numeric']>,
  type?: Maybe<Scalars['String']>,
}

export interface AccountGridViewAggregate {
   __typename?: 'account_grid_view_aggregate',
  aggregate?: Maybe<AccountGridViewAggregateFields>,
  nodes: Array<AccountGridView>,
}

export interface AccountGridViewAggregateFields {
   __typename?: 'account_grid_view_aggregate_fields',
  avg?: Maybe<AccountGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGridViewMaxFields>,
  min?: Maybe<AccountGridViewMinFields>,
  stddev?: Maybe<AccountGridViewStddevFields>,
  stddev_pop?: Maybe<AccountGridViewStddevPopFields>,
  stddev_samp?: Maybe<AccountGridViewStddevSampFields>,
  sum?: Maybe<AccountGridViewSumFields>,
  var_pop?: Maybe<AccountGridViewVarPopFields>,
  var_samp?: Maybe<AccountGridViewVarSampFields>,
  variance?: Maybe<AccountGridViewVarianceFields>,
}


export interface AccountGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGridViewAggregateOrderBy {
  avg?: Maybe<AccountGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGridViewMaxOrderBy>,
  min?: Maybe<AccountGridViewMinOrderBy>,
  stddev?: Maybe<AccountGridViewStddevOrderBy>,
  stddev_pop?: Maybe<AccountGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<AccountGridViewStddevSampOrderBy>,
  sum?: Maybe<AccountGridViewSumOrderBy>,
  var_pop?: Maybe<AccountGridViewVarPopOrderBy>,
  var_samp?: Maybe<AccountGridViewVarSampOrderBy>,
  variance?: Maybe<AccountGridViewVarianceOrderBy>,
}

export interface AccountGridViewAvgFields {
   __typename?: 'account_grid_view_avg_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewAvgOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewBoolExp {
  _and?: Maybe<Array<Maybe<AccountGridViewBoolExp>>>,
  _not?: Maybe<AccountGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGridViewBoolExp>>>,
  account?: Maybe<AccountBoolExp>,
  balance?: Maybe<NumericComparisonExp>,
  credit?: Maybe<NumericComparisonExp>,
  equity?: Maybe<NumericComparisonExp>,
  group?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  margin_used?: Maybe<NumericComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  profile_id?: Maybe<StringComparisonExp>,
  profile_type?: Maybe<StringComparisonExp>,
  profit_and_loss?: Maybe<NumericComparisonExp>,
  type?: Maybe<StringComparisonExp>,
}

export interface AccountGridViewMaxFields {
   __typename?: 'account_grid_view_max_fields',
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  group?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  margin_used?: Maybe<Scalars['numeric']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  profile_type?: Maybe<Scalars['String']>,
  profit_and_loss?: Maybe<Scalars['numeric']>,
  type?: Maybe<Scalars['String']>,
}

export interface AccountGridViewMaxOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  profile_type?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface AccountGridViewMinFields {
   __typename?: 'account_grid_view_min_fields',
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  group?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  margin_used?: Maybe<Scalars['numeric']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  profile_type?: Maybe<Scalars['String']>,
  profit_and_loss?: Maybe<Scalars['numeric']>,
  type?: Maybe<Scalars['String']>,
}

export interface AccountGridViewMinOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  profile_type?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface AccountGridViewOrderBy {
  account?: Maybe<AccountOrderBy>,
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  profile_type?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export enum AccountGridViewSelectColumn {
  Balance = 'balance',
  Credit = 'credit',
  Equity = 'equity',
  Group = 'group',
  Id = 'id',
  MarginUsed = 'margin_used',
  Name = 'name',
  Number = 'number',
  ProfileId = 'profile_id',
  ProfileType = 'profile_type',
  ProfitAndLoss = 'profit_and_loss',
  Type = 'type'
}

export interface AccountGridViewStddevFields {
   __typename?: 'account_grid_view_stddev_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewStddevOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewStddevPopFields {
   __typename?: 'account_grid_view_stddev_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewStddevPopOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewStddevSampFields {
   __typename?: 'account_grid_view_stddev_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewStddevSampOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewSumFields {
   __typename?: 'account_grid_view_sum_fields',
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  profit_and_loss?: Maybe<Scalars['numeric']>,
}

export interface AccountGridViewSumOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewVarPopFields {
   __typename?: 'account_grid_view_var_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewVarPopOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewVarSampFields {
   __typename?: 'account_grid_view_var_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewVarSampOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGridViewVarianceFields {
   __typename?: 'account_grid_view_variance_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  profit_and_loss?: Maybe<Scalars['Float']>,
}

export interface AccountGridViewVarianceOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  profit_and_loss?: Maybe<OrderBy>,
}

export interface AccountGroup {
   __typename?: 'account_group',
  accounts: Array<Account>,
  accounts_aggregate: AccountAggregate,
  book: BookType,
  book_id: Scalars['String'],
  category: ProfileCategory,
  category_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  currency: Currency,
  currency_id: Scalars['String'],
  default?: Maybe<AccountGroupDefault>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_assignable_to_partners: Scalars['Boolean'],
  is_deleted: Scalars['Boolean'],
  is_enabled: Scalars['Boolean'],
  is_swap_enabled: Scalars['Boolean'],
  leverage: Scalars['Int'],
  margin_call_level: Scalars['Int'],
  margin_type: MarginType,
  margin_type_id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  platform_group: Scalars['String'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  securities: Array<AccountGroupSecurity>,
  securities_aggregate: AccountGroupSecurityAggregate,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level: Scalars['Int'],
  updated_at: Scalars['timestamptz'],
}


export interface AccountGroupAccountsArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface AccountGroupAccountsAggregateArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface AccountGroupSecuritiesArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface AccountGroupSecuritiesAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}

export interface AccountGroupAggregate {
   __typename?: 'account_group_aggregate',
  aggregate?: Maybe<AccountGroupAggregateFields>,
  nodes: Array<AccountGroup>,
}

export interface AccountGroupAggregateFields {
   __typename?: 'account_group_aggregate_fields',
  avg?: Maybe<AccountGroupAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGroupMaxFields>,
  min?: Maybe<AccountGroupMinFields>,
  stddev?: Maybe<AccountGroupStddevFields>,
  stddev_pop?: Maybe<AccountGroupStddevPopFields>,
  stddev_samp?: Maybe<AccountGroupStddevSampFields>,
  sum?: Maybe<AccountGroupSumFields>,
  var_pop?: Maybe<AccountGroupVarPopFields>,
  var_samp?: Maybe<AccountGroupVarSampFields>,
  variance?: Maybe<AccountGroupVarianceFields>,
}


export interface AccountGroupAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGroupSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGroupAggregateOrderBy {
  avg?: Maybe<AccountGroupAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGroupMaxOrderBy>,
  min?: Maybe<AccountGroupMinOrderBy>,
  stddev?: Maybe<AccountGroupStddevOrderBy>,
  stddev_pop?: Maybe<AccountGroupStddevPopOrderBy>,
  stddev_samp?: Maybe<AccountGroupStddevSampOrderBy>,
  sum?: Maybe<AccountGroupSumOrderBy>,
  var_pop?: Maybe<AccountGroupVarPopOrderBy>,
  var_samp?: Maybe<AccountGroupVarSampOrderBy>,
  variance?: Maybe<AccountGroupVarianceOrderBy>,
}

export interface AccountGroupArrRelInsertInput {
  data: Array<AccountGroupInsertInput>,
  on_conflict?: Maybe<AccountGroupOnConflict>,
}

export interface AccountGroupAvgFields {
   __typename?: 'account_group_avg_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupAvgOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupBoolExp {
  _and?: Maybe<Array<Maybe<AccountGroupBoolExp>>>,
  _not?: Maybe<AccountGroupBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGroupBoolExp>>>,
  accounts?: Maybe<AccountBoolExp>,
  book?: Maybe<BookTypeBoolExp>,
  book_id?: Maybe<StringComparisonExp>,
  category?: Maybe<ProfileCategoryBoolExp>,
  category_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  default?: Maybe<AccountGroupDefaultBoolExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_assignable_to_partners?: Maybe<BooleanComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  is_swap_enabled?: Maybe<BooleanComparisonExp>,
  leverage?: Maybe<IntComparisonExp>,
  margin_call_level?: Maybe<IntComparisonExp>,
  margin_type?: Maybe<MarginTypeBoolExp>,
  margin_type_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  platform_group?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  securities?: Maybe<AccountGroupSecurityBoolExp>,
  starting_balance?: Maybe<IntComparisonExp>,
  stopout_level?: Maybe<IntComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AccountGroupConstraint {
  AccountGroupPkey = 'account_group_pkey'
}

export interface AccountGroupDefault {
   __typename?: 'account_group_default',
  currency: Currency,
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  group: AccountGroup,
  group_id: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  platform_server_environment: PlatformServerEnvironment,
  platform_server_type: PlatformServerType,
  platform_server_type_id: Scalars['String'],
  profile_category: ProfileCategory,
  profile_category_id: Scalars['String'],
}

export interface AccountGroupDefaultAggregate {
   __typename?: 'account_group_default_aggregate',
  aggregate?: Maybe<AccountGroupDefaultAggregateFields>,
  nodes: Array<AccountGroupDefault>,
}

export interface AccountGroupDefaultAggregateFields {
   __typename?: 'account_group_default_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGroupDefaultMaxFields>,
  min?: Maybe<AccountGroupDefaultMinFields>,
}


export interface AccountGroupDefaultAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGroupDefaultSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGroupDefaultAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGroupDefaultMaxOrderBy>,
  min?: Maybe<AccountGroupDefaultMinOrderBy>,
}

export interface AccountGroupDefaultArrRelInsertInput {
  data: Array<AccountGroupDefaultInsertInput>,
  on_conflict?: Maybe<AccountGroupDefaultOnConflict>,
}

export interface AccountGroupDefaultBoolExp {
  _and?: Maybe<Array<Maybe<AccountGroupDefaultBoolExp>>>,
  _not?: Maybe<AccountGroupDefaultBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGroupDefaultBoolExp>>>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  environment_id?: Maybe<StringComparisonExp>,
  group?: Maybe<AccountGroupBoolExp>,
  group_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  platform_server_environment?: Maybe<PlatformServerEnvironmentBoolExp>,
  platform_server_type?: Maybe<PlatformServerTypeBoolExp>,
  platform_server_type_id?: Maybe<StringComparisonExp>,
  profile_category?: Maybe<ProfileCategoryBoolExp>,
  profile_category_id?: Maybe<StringComparisonExp>,
}

export enum AccountGroupDefaultConstraint {
  AccountGroupDefaultGroupIdKey = 'account_group_default_group_id_key',
  AccountGroupDefaultPkey = 'account_group_default_pkey'
}

export interface AccountGroupDefaultInsertInput {
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group?: Maybe<AccountGroupObjRelInsertInput>,
  group_id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  platform_server_environment?: Maybe<PlatformServerEnvironmentObjRelInsertInput>,
  platform_server_type?: Maybe<PlatformServerTypeObjRelInsertInput>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category?: Maybe<ProfileCategoryObjRelInsertInput>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupDefaultMaxFields {
   __typename?: 'account_group_default_max_fields',
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupDefaultMaxOrderBy {
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface AccountGroupDefaultMinFields {
   __typename?: 'account_group_default_min_fields',
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupDefaultMinOrderBy {
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface AccountGroupDefaultMutationResponse {
   __typename?: 'account_group_default_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountGroupDefault>,
}

export interface AccountGroupDefaultObjRelInsertInput {
  data: AccountGroupDefaultInsertInput,
  on_conflict?: Maybe<AccountGroupDefaultOnConflict>,
}

export interface AccountGroupDefaultOnConflict {
  constraint: AccountGroupDefaultConstraint,
  update_columns: Array<AccountGroupDefaultUpdateColumn>,
  where?: Maybe<AccountGroupDefaultBoolExp>,
}

export interface AccountGroupDefaultOrderBy {
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group?: Maybe<AccountGroupOrderBy>,
  group_id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_server_environment?: Maybe<PlatformServerEnvironmentOrderBy>,
  platform_server_type?: Maybe<PlatformServerTypeOrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category?: Maybe<ProfileCategoryOrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface AccountGroupDefaultPkColumnsInput {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String'],
}

export enum AccountGroupDefaultSelectColumn {
  CurrencyId = 'currency_id',
  EnvironmentId = 'environment_id',
  GroupId = 'group_id',
  OrganizationId = 'organization_id',
  PlatformServerTypeId = 'platform_server_type_id',
  ProfileCategoryId = 'profile_category_id'
}

export interface AccountGroupDefaultSetInput {
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export enum AccountGroupDefaultUpdateColumn {
  CurrencyId = 'currency_id',
  EnvironmentId = 'environment_id',
  GroupId = 'group_id',
  OrganizationId = 'organization_id',
  PlatformServerTypeId = 'platform_server_type_id',
  ProfileCategoryId = 'profile_category_id'
}

export interface AccountGroupGridView {
   __typename?: 'account_group_grid_view',
  account_group?: Maybe<AccountGroup>,
  book?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_default?: Maybe<Scalars['Boolean']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  is_swap_enabled?: Maybe<Scalars['Boolean']>,
  leverage?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  securities?: Maybe<Scalars['bigint']>,
}

export interface AccountGroupGridViewAggregate {
   __typename?: 'account_group_grid_view_aggregate',
  aggregate?: Maybe<AccountGroupGridViewAggregateFields>,
  nodes: Array<AccountGroupGridView>,
}

export interface AccountGroupGridViewAggregateFields {
   __typename?: 'account_group_grid_view_aggregate_fields',
  avg?: Maybe<AccountGroupGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGroupGridViewMaxFields>,
  min?: Maybe<AccountGroupGridViewMinFields>,
  stddev?: Maybe<AccountGroupGridViewStddevFields>,
  stddev_pop?: Maybe<AccountGroupGridViewStddevPopFields>,
  stddev_samp?: Maybe<AccountGroupGridViewStddevSampFields>,
  sum?: Maybe<AccountGroupGridViewSumFields>,
  var_pop?: Maybe<AccountGroupGridViewVarPopFields>,
  var_samp?: Maybe<AccountGroupGridViewVarSampFields>,
  variance?: Maybe<AccountGroupGridViewVarianceFields>,
}


export interface AccountGroupGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGroupGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGroupGridViewAggregateOrderBy {
  avg?: Maybe<AccountGroupGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGroupGridViewMaxOrderBy>,
  min?: Maybe<AccountGroupGridViewMinOrderBy>,
  stddev?: Maybe<AccountGroupGridViewStddevOrderBy>,
  stddev_pop?: Maybe<AccountGroupGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<AccountGroupGridViewStddevSampOrderBy>,
  sum?: Maybe<AccountGroupGridViewSumOrderBy>,
  var_pop?: Maybe<AccountGroupGridViewVarPopOrderBy>,
  var_samp?: Maybe<AccountGroupGridViewVarSampOrderBy>,
  variance?: Maybe<AccountGroupGridViewVarianceOrderBy>,
}

export interface AccountGroupGridViewAvgFields {
   __typename?: 'account_group_grid_view_avg_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewAvgOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewBoolExp {
  _and?: Maybe<Array<Maybe<AccountGroupGridViewBoolExp>>>,
  _not?: Maybe<AccountGroupGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGroupGridViewBoolExp>>>,
  account_group?: Maybe<AccountGroupBoolExp>,
  book?: Maybe<StringComparisonExp>,
  category?: Maybe<StringComparisonExp>,
  code?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_default?: Maybe<BooleanComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  is_swap_enabled?: Maybe<BooleanComparisonExp>,
  leverage?: Maybe<IntComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_group?: Maybe<StringComparisonExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  securities?: Maybe<BigintComparisonExp>,
}

export interface AccountGroupGridViewMaxFields {
   __typename?: 'account_group_grid_view_max_fields',
  book?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  securities?: Maybe<Scalars['bigint']>,
}

export interface AccountGroupGridViewMaxOrderBy {
  book?: Maybe<OrderBy>,
  category?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewMinFields {
   __typename?: 'account_group_grid_view_min_fields',
  book?: Maybe<Scalars['String']>,
  category?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  securities?: Maybe<Scalars['bigint']>,
}

export interface AccountGroupGridViewMinOrderBy {
  book?: Maybe<OrderBy>,
  category?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewOrderBy {
  account_group?: Maybe<AccountGroupOrderBy>,
  book?: Maybe<OrderBy>,
  category?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_default?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  is_swap_enabled?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export enum AccountGroupGridViewSelectColumn {
  Book = 'book',
  Category = 'category',
  Code = 'code',
  Id = 'id',
  IsDefault = 'is_default',
  IsEnabled = 'is_enabled',
  IsSwapEnabled = 'is_swap_enabled',
  Leverage = 'leverage',
  Name = 'name',
  PlatformGroup = 'platform_group',
  PlatformServerId = 'platform_server_id',
  Securities = 'securities'
}

export interface AccountGroupGridViewStddevFields {
   __typename?: 'account_group_grid_view_stddev_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewStddevOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewStddevPopFields {
   __typename?: 'account_group_grid_view_stddev_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewStddevPopOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewStddevSampFields {
   __typename?: 'account_group_grid_view_stddev_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewStddevSampOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewSumFields {
   __typename?: 'account_group_grid_view_sum_fields',
  leverage?: Maybe<Scalars['Int']>,
  securities?: Maybe<Scalars['bigint']>,
}

export interface AccountGroupGridViewSumOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewVarPopFields {
   __typename?: 'account_group_grid_view_var_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewVarPopOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewVarSampFields {
   __typename?: 'account_group_grid_view_var_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewVarSampOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupGridViewVarianceFields {
   __typename?: 'account_group_grid_view_variance_fields',
  leverage?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
}

export interface AccountGroupGridViewVarianceOrderBy {
  leverage?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
}

export interface AccountGroupIncInput {
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
}

export interface AccountGroupInsertInput {
  accounts?: Maybe<AccountArrRelInsertInput>,
  book?: Maybe<BookTypeObjRelInsertInput>,
  book_id?: Maybe<Scalars['String']>,
  category?: Maybe<ProfileCategoryObjRelInsertInput>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  default?: Maybe<AccountGroupDefaultObjRelInsertInput>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_assignable_to_partners?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  is_swap_enabled?: Maybe<Scalars['Boolean']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  margin_type?: Maybe<MarginTypeObjRelInsertInput>,
  margin_type_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  securities?: Maybe<AccountGroupSecurityArrRelInsertInput>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountGroupMaxFields {
   __typename?: 'account_group_max_fields',
  book_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  margin_type_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountGroupMaxOrderBy {
  book_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  margin_type_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountGroupMinFields {
   __typename?: 'account_group_min_fields',
  book_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  margin_type_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountGroupMinOrderBy {
  book_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  margin_type_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountGroupMutationResponse {
   __typename?: 'account_group_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountGroup>,
}

export interface AccountGroupObjRelInsertInput {
  data: AccountGroupInsertInput,
  on_conflict?: Maybe<AccountGroupOnConflict>,
}

export interface AccountGroupOnConflict {
  constraint: AccountGroupConstraint,
  update_columns: Array<AccountGroupUpdateColumn>,
  where?: Maybe<AccountGroupBoolExp>,
}

export interface AccountGroupOrderBy {
  accounts_aggregate?: Maybe<AccountAggregateOrderBy>,
  book?: Maybe<BookTypeOrderBy>,
  book_id?: Maybe<OrderBy>,
  category?: Maybe<ProfileCategoryOrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  default?: Maybe<AccountGroupDefaultOrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_assignable_to_partners?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  is_swap_enabled?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  margin_type?: Maybe<MarginTypeOrderBy>,
  margin_type_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_group?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  securities_aggregate?: Maybe<AccountGroupSecurityAggregateOrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountGroupPkColumnsInput {
  id: Scalars['String'],
}

export interface AccountGroupSecurity {
   __typename?: 'account_group_security',
  group: AccountGroup,
  group_id: Scalars['String'],
  rule: TradeRule,
  rule_id: Scalars['String'],
  security: Security,
  security_id: Scalars['String'],
}

export interface AccountGroupSecurityAggregate {
   __typename?: 'account_group_security_aggregate',
  aggregate?: Maybe<AccountGroupSecurityAggregateFields>,
  nodes: Array<AccountGroupSecurity>,
}

export interface AccountGroupSecurityAggregateFields {
   __typename?: 'account_group_security_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGroupSecurityMaxFields>,
  min?: Maybe<AccountGroupSecurityMinFields>,
}


export interface AccountGroupSecurityAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGroupSecurityAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGroupSecurityMaxOrderBy>,
  min?: Maybe<AccountGroupSecurityMinOrderBy>,
}

export interface AccountGroupSecurityArrRelInsertInput {
  data: Array<AccountGroupSecurityInsertInput>,
  on_conflict?: Maybe<AccountGroupSecurityOnConflict>,
}

export interface AccountGroupSecurityBoolExp {
  _and?: Maybe<Array<Maybe<AccountGroupSecurityBoolExp>>>,
  _not?: Maybe<AccountGroupSecurityBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGroupSecurityBoolExp>>>,
  group?: Maybe<AccountGroupBoolExp>,
  group_id?: Maybe<StringComparisonExp>,
  rule?: Maybe<TradeRuleBoolExp>,
  rule_id?: Maybe<StringComparisonExp>,
  security?: Maybe<SecurityBoolExp>,
  security_id?: Maybe<StringComparisonExp>,
}

export enum AccountGroupSecurityConstraint {
  AccountGroupSecurityPkey = 'account_group_security_pkey'
}

export interface AccountGroupSecurityInsertInput {
  group?: Maybe<AccountGroupObjRelInsertInput>,
  group_id?: Maybe<Scalars['String']>,
  rule?: Maybe<TradeRuleObjRelInsertInput>,
  rule_id?: Maybe<Scalars['String']>,
  security?: Maybe<SecurityObjRelInsertInput>,
  security_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupSecurityMaxFields {
   __typename?: 'account_group_security_max_fields',
  group_id?: Maybe<Scalars['String']>,
  rule_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupSecurityMaxOrderBy {
  group_id?: Maybe<OrderBy>,
  rule_id?: Maybe<OrderBy>,
  security_id?: Maybe<OrderBy>,
}

export interface AccountGroupSecurityMinFields {
   __typename?: 'account_group_security_min_fields',
  group_id?: Maybe<Scalars['String']>,
  rule_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupSecurityMinOrderBy {
  group_id?: Maybe<OrderBy>,
  rule_id?: Maybe<OrderBy>,
  security_id?: Maybe<OrderBy>,
}

export interface AccountGroupSecurityMutationResponse {
   __typename?: 'account_group_security_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountGroupSecurity>,
}

export interface AccountGroupSecurityObjRelInsertInput {
  data: AccountGroupSecurityInsertInput,
  on_conflict?: Maybe<AccountGroupSecurityOnConflict>,
}

export interface AccountGroupSecurityOnConflict {
  constraint: AccountGroupSecurityConstraint,
  update_columns: Array<AccountGroupSecurityUpdateColumn>,
  where?: Maybe<AccountGroupSecurityBoolExp>,
}

export interface AccountGroupSecurityOrderBy {
  group?: Maybe<AccountGroupOrderBy>,
  group_id?: Maybe<OrderBy>,
  rule?: Maybe<TradeRuleOrderBy>,
  rule_id?: Maybe<OrderBy>,
  security?: Maybe<SecurityOrderBy>,
  security_id?: Maybe<OrderBy>,
}

export interface AccountGroupSecurityPkColumnsInput {
  group_id: Scalars['String'],
  security_id: Scalars['String'],
}

export enum AccountGroupSecuritySelectColumn {
  GroupId = 'group_id',
  RuleId = 'rule_id',
  SecurityId = 'security_id'
}

export interface AccountGroupSecuritySetInput {
  group_id?: Maybe<Scalars['String']>,
  rule_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
}

export enum AccountGroupSecurityUpdateColumn {
  GroupId = 'group_id',
  RuleId = 'rule_id',
  SecurityId = 'security_id'
}

export enum AccountGroupSelectColumn {
  BookId = 'book_id',
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Description = 'description',
  Id = 'id',
  IsAssignableToPartners = 'is_assignable_to_partners',
  IsDeleted = 'is_deleted',
  IsEnabled = 'is_enabled',
  IsSwapEnabled = 'is_swap_enabled',
  Leverage = 'leverage',
  MarginCallLevel = 'margin_call_level',
  MarginTypeId = 'margin_type_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  PlatformGroup = 'platform_group',
  PlatformServerId = 'platform_server_id',
  StartingBalance = 'starting_balance',
  StopoutLevel = 'stopout_level',
  UpdatedAt = 'updated_at'
}

export interface AccountGroupSetInput {
  book_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_assignable_to_partners?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  is_swap_enabled?: Maybe<Scalars['Boolean']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  margin_type_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  platform_group?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountGroupStddevFields {
   __typename?: 'account_group_stddev_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupStddevOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupStddevPopFields {
   __typename?: 'account_group_stddev_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupStddevPopOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupStddevSampFields {
   __typename?: 'account_group_stddev_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupStddevSampOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupSumFields {
   __typename?: 'account_group_sum_fields',
  leverage?: Maybe<Scalars['Int']>,
  margin_call_level?: Maybe<Scalars['Int']>,
  starting_balance?: Maybe<Scalars['Int']>,
  stopout_level?: Maybe<Scalars['Int']>,
}

export interface AccountGroupSumOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export enum AccountGroupUpdateColumn {
  BookId = 'book_id',
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Description = 'description',
  Id = 'id',
  IsAssignableToPartners = 'is_assignable_to_partners',
  IsDeleted = 'is_deleted',
  IsEnabled = 'is_enabled',
  IsSwapEnabled = 'is_swap_enabled',
  Leverage = 'leverage',
  MarginCallLevel = 'margin_call_level',
  MarginTypeId = 'margin_type_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  PlatformGroup = 'platform_group',
  PlatformServerId = 'platform_server_id',
  StartingBalance = 'starting_balance',
  StopoutLevel = 'stopout_level',
  UpdatedAt = 'updated_at'
}

export interface AccountGroupVarPopFields {
   __typename?: 'account_group_var_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupVarPopOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupVarSampFields {
   __typename?: 'account_group_var_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupVarSampOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupVarianceFields {
   __typename?: 'account_group_variance_fields',
  leverage?: Maybe<Scalars['Float']>,
  margin_call_level?: Maybe<Scalars['Float']>,
  starting_balance?: Maybe<Scalars['Float']>,
  stopout_level?: Maybe<Scalars['Float']>,
}

export interface AccountGroupVarianceOrderBy {
  leverage?: Maybe<OrderBy>,
  margin_call_level?: Maybe<OrderBy>,
  starting_balance?: Maybe<OrderBy>,
  stopout_level?: Maybe<OrderBy>,
}

export interface AccountGroupsToPartners {
   __typename?: 'account_groups_to_partners',
  group_id: Scalars['String'],
  partner_id: Scalars['String'],
}

export interface AccountGroupsToPartnersAggregate {
   __typename?: 'account_groups_to_partners_aggregate',
  aggregate?: Maybe<AccountGroupsToPartnersAggregateFields>,
  nodes: Array<AccountGroupsToPartners>,
}

export interface AccountGroupsToPartnersAggregateFields {
   __typename?: 'account_groups_to_partners_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountGroupsToPartnersMaxFields>,
  min?: Maybe<AccountGroupsToPartnersMinFields>,
}


export interface AccountGroupsToPartnersAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountGroupsToPartnersSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountGroupsToPartnersAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountGroupsToPartnersMaxOrderBy>,
  min?: Maybe<AccountGroupsToPartnersMinOrderBy>,
}

export interface AccountGroupsToPartnersArrRelInsertInput {
  data: Array<AccountGroupsToPartnersInsertInput>,
  on_conflict?: Maybe<AccountGroupsToPartnersOnConflict>,
}

export interface AccountGroupsToPartnersBoolExp {
  _and?: Maybe<Array<Maybe<AccountGroupsToPartnersBoolExp>>>,
  _not?: Maybe<AccountGroupsToPartnersBoolExp>,
  _or?: Maybe<Array<Maybe<AccountGroupsToPartnersBoolExp>>>,
  group_id?: Maybe<StringComparisonExp>,
  partner_id?: Maybe<StringComparisonExp>,
}

export enum AccountGroupsToPartnersConstraint {
  AccountGroupsToPartnersPkey = 'account_groups_to_partners_pkey'
}

export interface AccountGroupsToPartnersInsertInput {
  group_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupsToPartnersMaxFields {
   __typename?: 'account_groups_to_partners_max_fields',
  group_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupsToPartnersMaxOrderBy {
  group_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
}

export interface AccountGroupsToPartnersMinFields {
   __typename?: 'account_groups_to_partners_min_fields',
  group_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
}

export interface AccountGroupsToPartnersMinOrderBy {
  group_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
}

export interface AccountGroupsToPartnersMutationResponse {
   __typename?: 'account_groups_to_partners_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountGroupsToPartners>,
}

export interface AccountGroupsToPartnersObjRelInsertInput {
  data: AccountGroupsToPartnersInsertInput,
  on_conflict?: Maybe<AccountGroupsToPartnersOnConflict>,
}

export interface AccountGroupsToPartnersOnConflict {
  constraint: AccountGroupsToPartnersConstraint,
  update_columns: Array<AccountGroupsToPartnersUpdateColumn>,
  where?: Maybe<AccountGroupsToPartnersBoolExp>,
}

export interface AccountGroupsToPartnersOrderBy {
  group_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
}

export interface AccountGroupsToPartnersPkColumnsInput {
  group_id: Scalars['String'],
  partner_id: Scalars['String'],
}

export enum AccountGroupsToPartnersSelectColumn {
  GroupId = 'group_id',
  PartnerId = 'partner_id'
}

export interface AccountGroupsToPartnersSetInput {
  group_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
}

export enum AccountGroupsToPartnersUpdateColumn {
  GroupId = 'group_id',
  PartnerId = 'partner_id'
}

export interface AccountIncInput {
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['Int']>,
}

export interface AccountInsertInput {
  account_grid_view?: Maybe<AccountObjRelInsertInput>,
  allow_password_change?: Maybe<Scalars['Boolean']>,
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  equity?: Maybe<Scalars['numeric']>,
  group?: Maybe<AccountGroupObjRelInsertInput>,
  group_id?: Maybe<Scalars['String']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  incoming_transactions?: Maybe<TransactionArrRelInsertInput>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionObjRelInsertInput>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionObjRelInsertInput>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionObjRelInsertInput>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  needs_margin_call?: Maybe<Scalars['Boolean']>,
  number?: Maybe<Scalars['Int']>,
  outgoing_transactions?: Maybe<TransactionArrRelInsertInput>,
  platform_access?: Maybe<PlatformAccessObjRelInsertInput>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_account_id?: Maybe<Scalars['String']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  type?: Maybe<AccountTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountMaxFields {
   __typename?: 'account_max_fields',
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  equity?: Maybe<Scalars['numeric']>,
  group_id?: Maybe<Scalars['String']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_account_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountMaxOrderBy {
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_account_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountMinFields {
   __typename?: 'account_min_fields',
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  equity?: Maybe<Scalars['numeric']>,
  group_id?: Maybe<Scalars['String']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_account_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountMinOrderBy {
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_account_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountMutationResponse {
   __typename?: 'account_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Account>,
}

export interface AccountNumber {
   __typename?: 'account_number',
  account?: Maybe<Account>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
}

export interface AccountNumberAggregate {
   __typename?: 'account_number_aggregate',
  aggregate?: Maybe<AccountNumberAggregateFields>,
  nodes: Array<AccountNumber>,
}

export interface AccountNumberAggregateFields {
   __typename?: 'account_number_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountNumberMaxFields>,
  min?: Maybe<AccountNumberMinFields>,
}


export interface AccountNumberAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountNumberSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountNumberAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountNumberMaxOrderBy>,
  min?: Maybe<AccountNumberMinOrderBy>,
}

export interface AccountNumberBoolExp {
  _and?: Maybe<Array<Maybe<AccountNumberBoolExp>>>,
  _not?: Maybe<AccountNumberBoolExp>,
  _or?: Maybe<Array<Maybe<AccountNumberBoolExp>>>,
  account?: Maybe<AccountBoolExp>,
  id?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
}

export interface AccountNumberMaxFields {
   __typename?: 'account_number_max_fields',
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
}

export interface AccountNumberMaxOrderBy {
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountNumberMinFields {
   __typename?: 'account_number_min_fields',
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
}

export interface AccountNumberMinOrderBy {
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountNumberOrderBy {
  account?: Maybe<AccountOrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export enum AccountNumberSelectColumn {
  Id = 'id',
  Number = 'number'
}

export interface AccountObjRelInsertInput {
  data: AccountInsertInput,
  on_conflict?: Maybe<AccountOnConflict>,
}

export interface AccountOnConflict {
  constraint: AccountConstraint,
  update_columns: Array<AccountUpdateColumn>,
  where?: Maybe<AccountBoolExp>,
}

export interface AccountOrderBy {
  account_grid_view?: Maybe<AccountOrderBy>,
  account_number?: Maybe<AccountNumberOrderBy>,
  allow_password_change?: Maybe<OrderBy>,
  balance?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  group?: Maybe<AccountGroupOrderBy>,
  group_id?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  incoming_transactions_aggregate?: Maybe<TransactionAggregateOrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionOrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionOrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionOrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  needs_margin_call?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  outgoing_transactions_aggregate?: Maybe<TransactionAggregateOrderBy>,
  platform_access?: Maybe<PlatformAccessOrderBy>,
  platform_access_id?: Maybe<OrderBy>,
  platform_account_id?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  type?: Maybe<AccountTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountPkColumnsInput {
  id: Scalars['String'],
}

export enum AccountSelectColumn {
  AllowPasswordChange = 'allow_password_change',
  Balance = 'balance',
  CreatedAt = 'created_at',
  Credit = 'credit',
  CurrencyId = 'currency_id',
  Equity = 'equity',
  GroupId = 'group_id',
  HighWaterMark = 'high_water_mark',
  Id = 'id',
  Leverage = 'leverage',
  MarginUsed = 'margin_used',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerLeadPermissionId = 'master_partner_lead_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  NeedsMarginCall = 'needs_margin_call',
  Number = 'number',
  PlatformAccessId = 'platform_access_id',
  PlatformAccountId = 'platform_account_id',
  ProfileId = 'profile_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface AccountSetInput {
  allow_password_change?: Maybe<Scalars['Boolean']>,
  balance?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  equity?: Maybe<Scalars['numeric']>,
  group_id?: Maybe<Scalars['String']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  needs_margin_call?: Maybe<Scalars['Boolean']>,
  number?: Maybe<Scalars['Int']>,
  platform_access_id?: Maybe<Scalars['String']>,
  platform_account_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountStddevFields {
   __typename?: 'account_stddev_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountStddevOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountStddevPopFields {
   __typename?: 'account_stddev_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountStddevPopOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountStddevSampFields {
   __typename?: 'account_stddev_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountStddevSampOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountSumFields {
   __typename?: 'account_sum_fields',
  balance?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  leverage?: Maybe<Scalars['Int']>,
  margin_used?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['Int']>,
}

export interface AccountSumOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountType {
   __typename?: 'account_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface AccountTypeAggregate {
   __typename?: 'account_type_aggregate',
  aggregate?: Maybe<AccountTypeAggregateFields>,
  nodes: Array<AccountType>,
}

export interface AccountTypeAggregateFields {
   __typename?: 'account_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AccountTypeMaxFields>,
  min?: Maybe<AccountTypeMinFields>,
}


export interface AccountTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<AccountTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AccountTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AccountTypeMaxOrderBy>,
  min?: Maybe<AccountTypeMinOrderBy>,
}

export interface AccountTypeArrRelInsertInput {
  data: Array<AccountTypeInsertInput>,
  on_conflict?: Maybe<AccountTypeOnConflict>,
}

export interface AccountTypeBoolExp {
  _and?: Maybe<Array<Maybe<AccountTypeBoolExp>>>,
  _not?: Maybe<AccountTypeBoolExp>,
  _or?: Maybe<Array<Maybe<AccountTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AccountTypeConstraint {
  AccountTypeNameKey = 'account_type_name_key',
  AccountTypePkey = 'account_type_pkey'
}

export interface AccountTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountTypeMaxFields {
   __typename?: 'account_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountTypeMinFields {
   __typename?: 'account_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AccountTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountTypeMutationResponse {
   __typename?: 'account_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AccountType>,
}

export interface AccountTypeObjRelInsertInput {
  data: AccountTypeInsertInput,
  on_conflict?: Maybe<AccountTypeOnConflict>,
}

export interface AccountTypeOnConflict {
  constraint: AccountTypeConstraint,
  update_columns: Array<AccountTypeUpdateColumn>,
  where?: Maybe<AccountTypeBoolExp>,
}

export interface AccountTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AccountTypePkColumnsInput {
  id: Scalars['String'],
}

export enum AccountTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface AccountTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum AccountTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum AccountUpdateColumn {
  AllowPasswordChange = 'allow_password_change',
  Balance = 'balance',
  CreatedAt = 'created_at',
  Credit = 'credit',
  CurrencyId = 'currency_id',
  Equity = 'equity',
  GroupId = 'group_id',
  HighWaterMark = 'high_water_mark',
  Id = 'id',
  Leverage = 'leverage',
  MarginUsed = 'margin_used',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerLeadPermissionId = 'master_partner_lead_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  NeedsMarginCall = 'needs_margin_call',
  Number = 'number',
  PlatformAccessId = 'platform_access_id',
  PlatformAccountId = 'platform_account_id',
  ProfileId = 'profile_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface AccountVarPopFields {
   __typename?: 'account_var_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountVarPopOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountVarSampFields {
   __typename?: 'account_var_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountVarSampOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface AccountVarianceFields {
   __typename?: 'account_variance_fields',
  balance?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  leverage?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  number?: Maybe<Scalars['Float']>,
}

export interface AccountVarianceOrderBy {
  balance?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
}

export interface ActivitiesToChannels {
   __typename?: 'activities_to_channels',
  activity: Activity,
  activity_id: Scalars['String'],
  channel: Scalars['String'],
  id: Scalars['String'],
}

export interface ActivitiesToChannelsAggregate {
   __typename?: 'activities_to_channels_aggregate',
  aggregate?: Maybe<ActivitiesToChannelsAggregateFields>,
  nodes: Array<ActivitiesToChannels>,
}

export interface ActivitiesToChannelsAggregateFields {
   __typename?: 'activities_to_channels_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivitiesToChannelsMaxFields>,
  min?: Maybe<ActivitiesToChannelsMinFields>,
}


export interface ActivitiesToChannelsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivitiesToChannelsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivitiesToChannelsMaxOrderBy>,
  min?: Maybe<ActivitiesToChannelsMinOrderBy>,
}

export interface ActivitiesToChannelsArrRelInsertInput {
  data: Array<ActivitiesToChannelsInsertInput>,
  on_conflict?: Maybe<ActivitiesToChannelsOnConflict>,
}

export interface ActivitiesToChannelsBoolExp {
  _and?: Maybe<Array<Maybe<ActivitiesToChannelsBoolExp>>>,
  _not?: Maybe<ActivitiesToChannelsBoolExp>,
  _or?: Maybe<Array<Maybe<ActivitiesToChannelsBoolExp>>>,
  activity?: Maybe<ActivityBoolExp>,
  activity_id?: Maybe<StringComparisonExp>,
  channel?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
}

export enum ActivitiesToChannelsConstraint {
  ActivitiesToChannelsPkey = 'activities_to_channels_pkey'
}

export interface ActivitiesToChannelsInsertInput {
  activity?: Maybe<ActivityObjRelInsertInput>,
  activity_id?: Maybe<Scalars['String']>,
  channel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
}

export interface ActivitiesToChannelsMaxFields {
   __typename?: 'activities_to_channels_max_fields',
  activity_id?: Maybe<Scalars['String']>,
  channel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
}

export interface ActivitiesToChannelsMaxOrderBy {
  activity_id?: Maybe<OrderBy>,
  channel?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface ActivitiesToChannelsMinFields {
   __typename?: 'activities_to_channels_min_fields',
  activity_id?: Maybe<Scalars['String']>,
  channel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
}

export interface ActivitiesToChannelsMinOrderBy {
  activity_id?: Maybe<OrderBy>,
  channel?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface ActivitiesToChannelsMutationResponse {
   __typename?: 'activities_to_channels_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ActivitiesToChannels>,
}

export interface ActivitiesToChannelsObjRelInsertInput {
  data: ActivitiesToChannelsInsertInput,
  on_conflict?: Maybe<ActivitiesToChannelsOnConflict>,
}

export interface ActivitiesToChannelsOnConflict {
  constraint: ActivitiesToChannelsConstraint,
  update_columns: Array<ActivitiesToChannelsUpdateColumn>,
  where?: Maybe<ActivitiesToChannelsBoolExp>,
}

export interface ActivitiesToChannelsOrderBy {
  activity?: Maybe<ActivityOrderBy>,
  activity_id?: Maybe<OrderBy>,
  channel?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface ActivitiesToChannelsPkColumnsInput {
  id: Scalars['String'],
}

export enum ActivitiesToChannelsSelectColumn {
  ActivityId = 'activity_id',
  Channel = 'channel',
  Id = 'id'
}

export interface ActivitiesToChannelsSetInput {
  activity_id?: Maybe<Scalars['String']>,
  channel?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
}

export enum ActivitiesToChannelsUpdateColumn {
  ActivityId = 'activity_id',
  Channel = 'channel',
  Id = 'id'
}

export interface Activity {
   __typename?: 'activity',
  activities_to_channels: Array<ActivitiesToChannels>,
  activities_to_channels_aggregate: ActivitiesToChannelsAggregate,
  category: ActivityCategory,
  category_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  master_partner_stakeholder_permission: MasterPartnerStakeholderPermission,
  master_partner_stakeholder_permission_id: Scalars['String'],
  misc_data?: Maybe<Scalars['jsonb']>,
  notifications: Array<Notification>,
  notifications_aggregate: NotificationAggregate,
  occurred_at: Scalars['timestamptz'],
  recipient: ActivitySubject,
  recipient_id: Scalars['String'],
  subject: ActivitySubject,
  subject_id: Scalars['String'],
  template_data: Scalars['jsonb'],
}


export interface ActivityActivitiesToChannelsArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface ActivityActivitiesToChannelsAggregateArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface ActivityMiscDataArgs {
  path?: Maybe<Scalars['String']>
}


export interface ActivityNotificationsArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface ActivityNotificationsAggregateArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface ActivityTemplateDataArgs {
  path?: Maybe<Scalars['String']>
}

export interface ActivityAggregate {
   __typename?: 'activity_aggregate',
  aggregate?: Maybe<ActivityAggregateFields>,
  nodes: Array<Activity>,
}

export interface ActivityAggregateFields {
   __typename?: 'activity_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivityMaxFields>,
  min?: Maybe<ActivityMinFields>,
}


export interface ActivityAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivitySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivityAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivityMaxOrderBy>,
  min?: Maybe<ActivityMinOrderBy>,
}

export interface ActivityAppendInput {
  misc_data?: Maybe<Scalars['jsonb']>,
  template_data?: Maybe<Scalars['jsonb']>,
}

export interface ActivityArrRelInsertInput {
  data: Array<ActivityInsertInput>,
  on_conflict?: Maybe<ActivityOnConflict>,
}

export interface ActivityBoolExp {
  _and?: Maybe<Array<Maybe<ActivityBoolExp>>>,
  _not?: Maybe<ActivityBoolExp>,
  _or?: Maybe<Array<Maybe<ActivityBoolExp>>>,
  activities_to_channels?: Maybe<ActivitiesToChannelsBoolExp>,
  category?: Maybe<ActivityCategoryBoolExp>,
  category_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionBoolExp>,
  master_partner_stakeholder_permission_id?: Maybe<StringComparisonExp>,
  misc_data?: Maybe<JsonbComparisonExp>,
  notifications?: Maybe<NotificationBoolExp>,
  occurred_at?: Maybe<TimestamptzComparisonExp>,
  recipient?: Maybe<ActivitySubjectBoolExp>,
  recipient_id?: Maybe<StringComparisonExp>,
  subject?: Maybe<ActivitySubjectBoolExp>,
  subject_id?: Maybe<StringComparisonExp>,
  template_data?: Maybe<JsonbComparisonExp>,
}

export interface ActivityCategory {
   __typename?: 'activity_category',
  created_at: Scalars['date'],
  id: Scalars['String'],
  name: Scalars['String'],
  templates: Array<ActivityTemplate>,
  templates_aggregate: ActivityTemplateAggregate,
}


export interface ActivityCategoryTemplatesArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}


export interface ActivityCategoryTemplatesAggregateArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}

export interface ActivityCategoryAggregate {
   __typename?: 'activity_category_aggregate',
  aggregate?: Maybe<ActivityCategoryAggregateFields>,
  nodes: Array<ActivityCategory>,
}

export interface ActivityCategoryAggregateFields {
   __typename?: 'activity_category_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivityCategoryMaxFields>,
  min?: Maybe<ActivityCategoryMinFields>,
}


export interface ActivityCategoryAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivityCategorySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivityCategoryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivityCategoryMaxOrderBy>,
  min?: Maybe<ActivityCategoryMinOrderBy>,
}

export interface ActivityCategoryArrRelInsertInput {
  data: Array<ActivityCategoryInsertInput>,
  on_conflict?: Maybe<ActivityCategoryOnConflict>,
}

export interface ActivityCategoryBoolExp {
  _and?: Maybe<Array<Maybe<ActivityCategoryBoolExp>>>,
  _not?: Maybe<ActivityCategoryBoolExp>,
  _or?: Maybe<Array<Maybe<ActivityCategoryBoolExp>>>,
  created_at?: Maybe<DateComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  templates?: Maybe<ActivityTemplateBoolExp>,
}

export enum ActivityCategoryConstraint {
  ActivityCategoryNameKey = 'activity_category_name_key',
  ActivityCategoryPkey = 'activity_category_pkey'
}

export interface ActivityCategoryInsertInput {
  created_at?: Maybe<Scalars['date']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  templates?: Maybe<ActivityTemplateArrRelInsertInput>,
}

export interface ActivityCategoryMaxFields {
   __typename?: 'activity_category_max_fields',
  created_at?: Maybe<Scalars['date']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface ActivityCategoryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface ActivityCategoryMinFields {
   __typename?: 'activity_category_min_fields',
  created_at?: Maybe<Scalars['date']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface ActivityCategoryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface ActivityCategoryMutationResponse {
   __typename?: 'activity_category_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ActivityCategory>,
}

export interface ActivityCategoryObjRelInsertInput {
  data: ActivityCategoryInsertInput,
  on_conflict?: Maybe<ActivityCategoryOnConflict>,
}

export interface ActivityCategoryOnConflict {
  constraint: ActivityCategoryConstraint,
  update_columns: Array<ActivityCategoryUpdateColumn>,
  where?: Maybe<ActivityCategoryBoolExp>,
}

export interface ActivityCategoryOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  templates_aggregate?: Maybe<ActivityTemplateAggregateOrderBy>,
}

export interface ActivityCategoryPkColumnsInput {
  id: Scalars['String'],
}

export enum ActivityCategorySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name'
}

export interface ActivityCategorySetInput {
  created_at?: Maybe<Scalars['date']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export enum ActivityCategoryUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name'
}

export interface ActivityChannel {
   __typename?: 'activity_channel',
  id: Scalars['String'],
}

export interface ActivityChannelAggregate {
   __typename?: 'activity_channel_aggregate',
  aggregate?: Maybe<ActivityChannelAggregateFields>,
  nodes: Array<ActivityChannel>,
}

export interface ActivityChannelAggregateFields {
   __typename?: 'activity_channel_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivityChannelMaxFields>,
  min?: Maybe<ActivityChannelMinFields>,
}


export interface ActivityChannelAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivityChannelSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivityChannelAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivityChannelMaxOrderBy>,
  min?: Maybe<ActivityChannelMinOrderBy>,
}

export interface ActivityChannelArrRelInsertInput {
  data: Array<ActivityChannelInsertInput>,
  on_conflict?: Maybe<ActivityChannelOnConflict>,
}

export interface ActivityChannelBoolExp {
  _and?: Maybe<Array<Maybe<ActivityChannelBoolExp>>>,
  _not?: Maybe<ActivityChannelBoolExp>,
  _or?: Maybe<Array<Maybe<ActivityChannelBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
}

export enum ActivityChannelConstraint {
  ActivityChannelIdKey = 'activity_channel_id_key',
  ActivityChannelPkey = 'activity_channel_pkey'
}

export interface ActivityChannelInsertInput {
  id?: Maybe<Scalars['String']>,
}

export interface ActivityChannelMaxFields {
   __typename?: 'activity_channel_max_fields',
  id?: Maybe<Scalars['String']>,
}

export interface ActivityChannelMaxOrderBy {
  id?: Maybe<OrderBy>,
}

export interface ActivityChannelMinFields {
   __typename?: 'activity_channel_min_fields',
  id?: Maybe<Scalars['String']>,
}

export interface ActivityChannelMinOrderBy {
  id?: Maybe<OrderBy>,
}

export interface ActivityChannelMutationResponse {
   __typename?: 'activity_channel_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ActivityChannel>,
}

export interface ActivityChannelObjRelInsertInput {
  data: ActivityChannelInsertInput,
  on_conflict?: Maybe<ActivityChannelOnConflict>,
}

export interface ActivityChannelOnConflict {
  constraint: ActivityChannelConstraint,
  update_columns: Array<ActivityChannelUpdateColumn>,
  where?: Maybe<ActivityChannelBoolExp>,
}

export interface ActivityChannelOrderBy {
  id?: Maybe<OrderBy>,
}

export interface ActivityChannelPkColumnsInput {
  id: Scalars['String'],
}

export enum ActivityChannelSelectColumn {
  Id = 'id'
}

export interface ActivityChannelSetInput {
  id?: Maybe<Scalars['String']>,
}

export enum ActivityChannelUpdateColumn {
  Id = 'id'
}

export enum ActivityConstraint {
  ActivityPkey = 'activity_pkey'
}

export interface ActivityDeleteAtPathInput {
  misc_data?: Maybe<Array<Maybe<Scalars['String']>>>,
  template_data?: Maybe<Array<Maybe<Scalars['String']>>>,
}

export interface ActivityDeleteElemInput {
  misc_data?: Maybe<Scalars['Int']>,
  template_data?: Maybe<Scalars['Int']>,
}

export interface ActivityDeleteKeyInput {
  misc_data?: Maybe<Scalars['String']>,
  template_data?: Maybe<Scalars['String']>,
}

export interface ActivityInsertInput {
  activities_to_channels?: Maybe<ActivitiesToChannelsArrRelInsertInput>,
  category?: Maybe<ActivityCategoryObjRelInsertInput>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionObjRelInsertInput>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  misc_data?: Maybe<Scalars['jsonb']>,
  notifications?: Maybe<NotificationArrRelInsertInput>,
  occurred_at?: Maybe<Scalars['timestamptz']>,
  recipient?: Maybe<ActivitySubjectObjRelInsertInput>,
  recipient_id?: Maybe<Scalars['String']>,
  subject?: Maybe<ActivitySubjectObjRelInsertInput>,
  subject_id?: Maybe<Scalars['String']>,
  template_data?: Maybe<Scalars['jsonb']>,
}

export interface ActivityMaxFields {
   __typename?: 'activity_max_fields',
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  occurred_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
}

export interface ActivityMaxOrderBy {
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  occurred_at?: Maybe<OrderBy>,
  recipient_id?: Maybe<OrderBy>,
  subject_id?: Maybe<OrderBy>,
}

export interface ActivityMinFields {
   __typename?: 'activity_min_fields',
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  occurred_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
}

export interface ActivityMinOrderBy {
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  occurred_at?: Maybe<OrderBy>,
  recipient_id?: Maybe<OrderBy>,
  subject_id?: Maybe<OrderBy>,
}

export interface ActivityMutationResponse {
   __typename?: 'activity_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Activity>,
}

export interface ActivityObjRelInsertInput {
  data: ActivityInsertInput,
  on_conflict?: Maybe<ActivityOnConflict>,
}

export interface ActivityOnConflict {
  constraint: ActivityConstraint,
  update_columns: Array<ActivityUpdateColumn>,
  where?: Maybe<ActivityBoolExp>,
}

export interface ActivityOrderBy {
  activities_to_channels_aggregate?: Maybe<ActivitiesToChannelsAggregateOrderBy>,
  category?: Maybe<ActivityCategoryOrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionOrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  misc_data?: Maybe<OrderBy>,
  notifications_aggregate?: Maybe<NotificationAggregateOrderBy>,
  occurred_at?: Maybe<OrderBy>,
  recipient?: Maybe<ActivitySubjectOrderBy>,
  recipient_id?: Maybe<OrderBy>,
  subject?: Maybe<ActivitySubjectOrderBy>,
  subject_id?: Maybe<OrderBy>,
  template_data?: Maybe<OrderBy>,
}

export interface ActivityPkColumnsInput {
  id: Scalars['String'],
}

export interface ActivityPrependInput {
  misc_data?: Maybe<Scalars['jsonb']>,
  template_data?: Maybe<Scalars['jsonb']>,
}

export enum ActivitySelectColumn {
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  Id = 'id',
  MasterPartnerStakeholderPermissionId = 'master_partner_stakeholder_permission_id',
  MiscData = 'misc_data',
  OccurredAt = 'occurred_at',
  RecipientId = 'recipient_id',
  SubjectId = 'subject_id',
  TemplateData = 'template_data'
}

export interface ActivitySetInput {
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  misc_data?: Maybe<Scalars['jsonb']>,
  occurred_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
  template_data?: Maybe<Scalars['jsonb']>,
}

export interface ActivitySubject {
   __typename?: 'activity_subject',
  id: Scalars['String'],
  misc_data?: Maybe<Scalars['jsonb']>,
  name: Scalars['String'],
  organization_id: Scalars['String'],
  organization_name: Scalars['String'],
  profile?: Maybe<Profile>,
  stakeholder?: Maybe<Stakeholder>,
  subject_id: Scalars['String'],
  type: Scalars['String'],
}


export interface ActivitySubjectMiscDataArgs {
  path?: Maybe<Scalars['String']>
}

export interface ActivitySubjectAggregate {
   __typename?: 'activity_subject_aggregate',
  aggregate?: Maybe<ActivitySubjectAggregateFields>,
  nodes: Array<ActivitySubject>,
}

export interface ActivitySubjectAggregateFields {
   __typename?: 'activity_subject_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivitySubjectMaxFields>,
  min?: Maybe<ActivitySubjectMinFields>,
}


export interface ActivitySubjectAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivitySubjectSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivitySubjectAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivitySubjectMaxOrderBy>,
  min?: Maybe<ActivitySubjectMinOrderBy>,
}

export interface ActivitySubjectAppendInput {
  misc_data?: Maybe<Scalars['jsonb']>,
}

export interface ActivitySubjectArrRelInsertInput {
  data: Array<ActivitySubjectInsertInput>,
  on_conflict?: Maybe<ActivitySubjectOnConflict>,
}

export interface ActivitySubjectBoolExp {
  _and?: Maybe<Array<Maybe<ActivitySubjectBoolExp>>>,
  _not?: Maybe<ActivitySubjectBoolExp>,
  _or?: Maybe<Array<Maybe<ActivitySubjectBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  misc_data?: Maybe<JsonbComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  organization_name?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  subject_id?: Maybe<StringComparisonExp>,
  type?: Maybe<StringComparisonExp>,
}

export enum ActivitySubjectConstraint {
  ActivitySubjectPkey = 'activity_subject_pkey'
}

export interface ActivitySubjectDeleteAtPathInput {
  misc_data?: Maybe<Array<Maybe<Scalars['String']>>>,
}

export interface ActivitySubjectDeleteElemInput {
  misc_data?: Maybe<Scalars['Int']>,
}

export interface ActivitySubjectDeleteKeyInput {
  misc_data?: Maybe<Scalars['String']>,
}

export interface ActivitySubjectInsertInput {
  id?: Maybe<Scalars['String']>,
  misc_data?: Maybe<Scalars['jsonb']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  organization_name?: Maybe<Scalars['String']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  subject_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface ActivitySubjectMaxFields {
   __typename?: 'activity_subject_max_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  organization_name?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface ActivitySubjectMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  organization_name?: Maybe<OrderBy>,
  subject_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface ActivitySubjectMinFields {
   __typename?: 'activity_subject_min_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  organization_name?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface ActivitySubjectMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  organization_name?: Maybe<OrderBy>,
  subject_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface ActivitySubjectMutationResponse {
   __typename?: 'activity_subject_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ActivitySubject>,
}

export interface ActivitySubjectObjRelInsertInput {
  data: ActivitySubjectInsertInput,
  on_conflict?: Maybe<ActivitySubjectOnConflict>,
}

export interface ActivitySubjectOnConflict {
  constraint: ActivitySubjectConstraint,
  update_columns: Array<ActivitySubjectUpdateColumn>,
  where?: Maybe<ActivitySubjectBoolExp>,
}

export interface ActivitySubjectOrderBy {
  id?: Maybe<OrderBy>,
  misc_data?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  organization_name?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  subject_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface ActivitySubjectPkColumnsInput {
  id: Scalars['String'],
}

export interface ActivitySubjectPrependInput {
  misc_data?: Maybe<Scalars['jsonb']>,
}

export enum ActivitySubjectSelectColumn {
  Id = 'id',
  MiscData = 'misc_data',
  Name = 'name',
  OrganizationId = 'organization_id',
  OrganizationName = 'organization_name',
  SubjectId = 'subject_id',
  Type = 'type'
}

export interface ActivitySubjectSetInput {
  id?: Maybe<Scalars['String']>,
  misc_data?: Maybe<Scalars['jsonb']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  organization_name?: Maybe<Scalars['String']>,
  subject_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export enum ActivitySubjectUpdateColumn {
  Id = 'id',
  MiscData = 'misc_data',
  Name = 'name',
  OrganizationId = 'organization_id',
  OrganizationName = 'organization_name',
  SubjectId = 'subject_id',
  Type = 'type'
}

export interface ActivityTemplate {
   __typename?: 'activity_template',
  activity_category_id: Scalars['String'],
  en_US: Scalars['String'],
  id: Scalars['String'],
  perspective: Scalars['String'],
}

export interface ActivityTemplateAggregate {
   __typename?: 'activity_template_aggregate',
  aggregate?: Maybe<ActivityTemplateAggregateFields>,
  nodes: Array<ActivityTemplate>,
}

export interface ActivityTemplateAggregateFields {
   __typename?: 'activity_template_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ActivityTemplateMaxFields>,
  min?: Maybe<ActivityTemplateMinFields>,
}


export interface ActivityTemplateAggregateFieldsCountArgs {
  columns?: Maybe<Array<ActivityTemplateSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ActivityTemplateAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ActivityTemplateMaxOrderBy>,
  min?: Maybe<ActivityTemplateMinOrderBy>,
}

export interface ActivityTemplateArrRelInsertInput {
  data: Array<ActivityTemplateInsertInput>,
  on_conflict?: Maybe<ActivityTemplateOnConflict>,
}

export interface ActivityTemplateBoolExp {
  _and?: Maybe<Array<Maybe<ActivityTemplateBoolExp>>>,
  _not?: Maybe<ActivityTemplateBoolExp>,
  _or?: Maybe<Array<Maybe<ActivityTemplateBoolExp>>>,
  activity_category_id?: Maybe<StringComparisonExp>,
  en_US?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  perspective?: Maybe<StringComparisonExp>,
}

export enum ActivityTemplateConstraint {
  ActivityTemplatePkey = 'activity_template_pkey'
}

export interface ActivityTemplateInsertInput {
  activity_category_id?: Maybe<Scalars['String']>,
  en_US?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  perspective?: Maybe<Scalars['String']>,
}

export interface ActivityTemplateMaxFields {
   __typename?: 'activity_template_max_fields',
  activity_category_id?: Maybe<Scalars['String']>,
  en_US?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  perspective?: Maybe<Scalars['String']>,
}

export interface ActivityTemplateMaxOrderBy {
  activity_category_id?: Maybe<OrderBy>,
  en_US?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  perspective?: Maybe<OrderBy>,
}

export interface ActivityTemplateMinFields {
   __typename?: 'activity_template_min_fields',
  activity_category_id?: Maybe<Scalars['String']>,
  en_US?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  perspective?: Maybe<Scalars['String']>,
}

export interface ActivityTemplateMinOrderBy {
  activity_category_id?: Maybe<OrderBy>,
  en_US?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  perspective?: Maybe<OrderBy>,
}

export interface ActivityTemplateMutationResponse {
   __typename?: 'activity_template_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ActivityTemplate>,
}

export interface ActivityTemplateObjRelInsertInput {
  data: ActivityTemplateInsertInput,
  on_conflict?: Maybe<ActivityTemplateOnConflict>,
}

export interface ActivityTemplateOnConflict {
  constraint: ActivityTemplateConstraint,
  update_columns: Array<ActivityTemplateUpdateColumn>,
  where?: Maybe<ActivityTemplateBoolExp>,
}

export interface ActivityTemplateOrderBy {
  activity_category_id?: Maybe<OrderBy>,
  en_US?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  perspective?: Maybe<OrderBy>,
}

export interface ActivityTemplatePkColumnsInput {
  id: Scalars['String'],
}

export enum ActivityTemplateSelectColumn {
  ActivityCategoryId = 'activity_category_id',
  EnUs = 'en_US',
  Id = 'id',
  Perspective = 'perspective'
}

export interface ActivityTemplateSetInput {
  activity_category_id?: Maybe<Scalars['String']>,
  en_US?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  perspective?: Maybe<Scalars['String']>,
}

export enum ActivityTemplateUpdateColumn {
  ActivityCategoryId = 'activity_category_id',
  EnUs = 'en_US',
  Id = 'id',
  Perspective = 'perspective'
}

export enum ActivityUpdateColumn {
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  Id = 'id',
  MasterPartnerStakeholderPermissionId = 'master_partner_stakeholder_permission_id',
  MiscData = 'misc_data',
  OccurredAt = 'occurred_at',
  RecipientId = 'recipient_id',
  SubjectId = 'subject_id',
  TemplateData = 'template_data'
}

export interface AdminPermission {
   __typename?: 'admin_permission',
  account_list?: Maybe<Scalars['String']>,
  account_list_client: Scalars['String'],
  account_list_lead: Scalars['String'],
  account_list_partner: Scalars['String'],
  account_settings_client: Scalars['String'],
  account_settings_lead: Scalars['String'],
  account_settings_partner: Scalars['String'],
  activity_list: Scalars['String'],
  activity_list_client: Scalars['String'],
  activity_list_lead: Scalars['String'],
  activity_list_partner: Scalars['String'],
  approve_account_client: Scalars['String'],
  approve_account_lead: Scalars['String'],
  approve_account_partner: Scalars['String'],
  approve_client: Scalars['String'],
  approve_lead: Scalars['String'],
  approve_partner: Scalars['String'],
  approve_stakeholder: Scalars['String'],
  approve_status_client: Scalars['String'],
  approve_status_lead: Scalars['String'],
  approve_status_partner: Scalars['String'],
  bank_account_list: Scalars['String'],
  category_client: Scalars['String'],
  category_lead: Scalars['String'],
  category_partner: Scalars['String'],
  client_list: Scalars['String'],
  create_client: Scalars['String'],
  create_lead: Scalars['String'],
  create_partner: Scalars['String'],
  credit_transaction_client: Scalars['String'],
  credit_transaction_lead: Scalars['String'],
  credit_transaction_partner: Scalars['String'],
  delete_client: Scalars['String'],
  delete_lead: Scalars['String'],
  delete_partner: Scalars['String'],
  deposit_transaction_client: Scalars['String'],
  deposit_transaction_lead: Scalars['String'],
  deposit_transaction_partner: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  edit_client: Scalars['String'],
  edit_lead: Scalars['String'],
  edit_partner: Scalars['String'],
  file_list: Scalars['String'],
  flag_client: Scalars['String'],
  flag_lead: Scalars['String'],
  flag_partner: Scalars['String'],
  id: Scalars['String'],
  lead_list: Scalars['String'],
  manage_account_client: Scalars['String'],
  manage_account_lead: Scalars['String'],
  manage_account_partner: Scalars['String'],
  manage_bank_account: Scalars['String'],
  manage_file: Scalars['String'],
  manage_stakeholder: Scalars['String'],
  name: Scalars['String'],
  notes_client: Scalars['String'],
  notes_lead: Scalars['String'],
  notes_partner: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  partner_list: Scalars['String'],
  representative_client: Scalars['String'],
  representative_lead: Scalars['String'],
  representative_partner: Scalars['String'],
  restrict_negative_balance_client: Scalars['String'],
  restrict_negative_balance_lead: Scalars['String'],
  restrict_negative_balance_partner: Scalars['String'],
  risk_client: Scalars['String'],
  risk_lead: Scalars['String'],
  risk_partner: Scalars['String'],
  service_fee_transaction_client: Scalars['String'],
  service_fee_transaction_lead: Scalars['String'],
  service_fee_transaction_partner: Scalars['String'],
  settings: Scalars['String'],
  settings_RNP: Scalars['String'],
  settings_admins: Scalars['String'],
  settings_advanced: Scalars['String'],
  settings_compliance: Scalars['String'],
  settings_payment_methods: Scalars['String'],
  settings_platform_servers: Scalars['String'],
  settings_segments: Scalars['String'],
  settings_stakeholders: Scalars['String'],
  stakeholder_list: Scalars['String'],
  tag_client: Scalars['String'],
  tag_lead: Scalars['String'],
  tag_partner: Scalars['String'],
  transaction_list_client: Scalars['String'],
  transaction_list_lead: Scalars['String'],
  transaction_list_partner: Scalars['String'],
  transfer_between_profiles_transaction_client: Scalars['String'],
  transfer_between_profiles_transaction_lead: Scalars['String'],
  transfer_between_profiles_transaction_partner: Scalars['String'],
  transfer_transaction_client: Scalars['String'],
  transfer_transaction_lead: Scalars['String'],
  transfer_transaction_partner: Scalars['String'],
  withdrawal_transaction_client: Scalars['String'],
  withdrawal_transaction_lead: Scalars['String'],
  withdrawal_transaction_partner: Scalars['String'],
}

export interface AdminPermissionAggregate {
   __typename?: 'admin_permission_aggregate',
  aggregate?: Maybe<AdminPermissionAggregateFields>,
  nodes: Array<AdminPermission>,
}

export interface AdminPermissionAggregateFields {
   __typename?: 'admin_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AdminPermissionMaxFields>,
  min?: Maybe<AdminPermissionMinFields>,
}


export interface AdminPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<AdminPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AdminPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AdminPermissionMaxOrderBy>,
  min?: Maybe<AdminPermissionMinOrderBy>,
}

export interface AdminPermissionArrRelInsertInput {
  data: Array<AdminPermissionInsertInput>,
  on_conflict?: Maybe<AdminPermissionOnConflict>,
}

export interface AdminPermissionBoolExp {
  _and?: Maybe<Array<Maybe<AdminPermissionBoolExp>>>,
  _not?: Maybe<AdminPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<AdminPermissionBoolExp>>>,
  account_list?: Maybe<StringComparisonExp>,
  account_list_client?: Maybe<StringComparisonExp>,
  account_list_lead?: Maybe<StringComparisonExp>,
  account_list_partner?: Maybe<StringComparisonExp>,
  account_settings_client?: Maybe<StringComparisonExp>,
  account_settings_lead?: Maybe<StringComparisonExp>,
  account_settings_partner?: Maybe<StringComparisonExp>,
  activity_list?: Maybe<StringComparisonExp>,
  activity_list_client?: Maybe<StringComparisonExp>,
  activity_list_lead?: Maybe<StringComparisonExp>,
  activity_list_partner?: Maybe<StringComparisonExp>,
  approve_account_client?: Maybe<StringComparisonExp>,
  approve_account_lead?: Maybe<StringComparisonExp>,
  approve_account_partner?: Maybe<StringComparisonExp>,
  approve_client?: Maybe<StringComparisonExp>,
  approve_lead?: Maybe<StringComparisonExp>,
  approve_partner?: Maybe<StringComparisonExp>,
  approve_stakeholder?: Maybe<StringComparisonExp>,
  approve_status_client?: Maybe<StringComparisonExp>,
  approve_status_lead?: Maybe<StringComparisonExp>,
  approve_status_partner?: Maybe<StringComparisonExp>,
  bank_account_list?: Maybe<StringComparisonExp>,
  category_client?: Maybe<StringComparisonExp>,
  category_lead?: Maybe<StringComparisonExp>,
  category_partner?: Maybe<StringComparisonExp>,
  client_list?: Maybe<StringComparisonExp>,
  create_client?: Maybe<StringComparisonExp>,
  create_lead?: Maybe<StringComparisonExp>,
  create_partner?: Maybe<StringComparisonExp>,
  credit_transaction_client?: Maybe<StringComparisonExp>,
  credit_transaction_lead?: Maybe<StringComparisonExp>,
  credit_transaction_partner?: Maybe<StringComparisonExp>,
  delete_client?: Maybe<StringComparisonExp>,
  delete_lead?: Maybe<StringComparisonExp>,
  delete_partner?: Maybe<StringComparisonExp>,
  deposit_transaction_client?: Maybe<StringComparisonExp>,
  deposit_transaction_lead?: Maybe<StringComparisonExp>,
  deposit_transaction_partner?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  edit_client?: Maybe<StringComparisonExp>,
  edit_lead?: Maybe<StringComparisonExp>,
  edit_partner?: Maybe<StringComparisonExp>,
  file_list?: Maybe<StringComparisonExp>,
  flag_client?: Maybe<StringComparisonExp>,
  flag_lead?: Maybe<StringComparisonExp>,
  flag_partner?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  lead_list?: Maybe<StringComparisonExp>,
  manage_account_client?: Maybe<StringComparisonExp>,
  manage_account_lead?: Maybe<StringComparisonExp>,
  manage_account_partner?: Maybe<StringComparisonExp>,
  manage_bank_account?: Maybe<StringComparisonExp>,
  manage_file?: Maybe<StringComparisonExp>,
  manage_stakeholder?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  notes_client?: Maybe<StringComparisonExp>,
  notes_lead?: Maybe<StringComparisonExp>,
  notes_partner?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner_list?: Maybe<StringComparisonExp>,
  representative_client?: Maybe<StringComparisonExp>,
  representative_lead?: Maybe<StringComparisonExp>,
  representative_partner?: Maybe<StringComparisonExp>,
  restrict_negative_balance_client?: Maybe<StringComparisonExp>,
  restrict_negative_balance_lead?: Maybe<StringComparisonExp>,
  restrict_negative_balance_partner?: Maybe<StringComparisonExp>,
  risk_client?: Maybe<StringComparisonExp>,
  risk_lead?: Maybe<StringComparisonExp>,
  risk_partner?: Maybe<StringComparisonExp>,
  service_fee_transaction_client?: Maybe<StringComparisonExp>,
  service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  settings?: Maybe<StringComparisonExp>,
  settings_RNP?: Maybe<StringComparisonExp>,
  settings_admins?: Maybe<StringComparisonExp>,
  settings_advanced?: Maybe<StringComparisonExp>,
  settings_compliance?: Maybe<StringComparisonExp>,
  settings_payment_methods?: Maybe<StringComparisonExp>,
  settings_platform_servers?: Maybe<StringComparisonExp>,
  settings_segments?: Maybe<StringComparisonExp>,
  settings_stakeholders?: Maybe<StringComparisonExp>,
  stakeholder_list?: Maybe<StringComparisonExp>,
  tag_client?: Maybe<StringComparisonExp>,
  tag_lead?: Maybe<StringComparisonExp>,
  tag_partner?: Maybe<StringComparisonExp>,
  transaction_list_client?: Maybe<StringComparisonExp>,
  transaction_list_lead?: Maybe<StringComparisonExp>,
  transaction_list_partner?: Maybe<StringComparisonExp>,
  transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  transfer_transaction_client?: Maybe<StringComparisonExp>,
  transfer_transaction_lead?: Maybe<StringComparisonExp>,
  transfer_transaction_partner?: Maybe<StringComparisonExp>,
  withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
}

export enum AdminPermissionConstraint {
  AdminPermissionPkey = 'admin_permission_pkey'
}

export interface AdminPermissionInsertInput {
  account_list?: Maybe<Scalars['String']>,
  account_list_client?: Maybe<Scalars['String']>,
  account_list_lead?: Maybe<Scalars['String']>,
  account_list_partner?: Maybe<Scalars['String']>,
  account_settings_client?: Maybe<Scalars['String']>,
  account_settings_lead?: Maybe<Scalars['String']>,
  account_settings_partner?: Maybe<Scalars['String']>,
  activity_list?: Maybe<Scalars['String']>,
  activity_list_client?: Maybe<Scalars['String']>,
  activity_list_lead?: Maybe<Scalars['String']>,
  activity_list_partner?: Maybe<Scalars['String']>,
  approve_account_client?: Maybe<Scalars['String']>,
  approve_account_lead?: Maybe<Scalars['String']>,
  approve_account_partner?: Maybe<Scalars['String']>,
  approve_client?: Maybe<Scalars['String']>,
  approve_lead?: Maybe<Scalars['String']>,
  approve_partner?: Maybe<Scalars['String']>,
  approve_stakeholder?: Maybe<Scalars['String']>,
  approve_status_client?: Maybe<Scalars['String']>,
  approve_status_lead?: Maybe<Scalars['String']>,
  approve_status_partner?: Maybe<Scalars['String']>,
  bank_account_list?: Maybe<Scalars['String']>,
  category_client?: Maybe<Scalars['String']>,
  category_lead?: Maybe<Scalars['String']>,
  category_partner?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  credit_transaction_client?: Maybe<Scalars['String']>,
  credit_transaction_lead?: Maybe<Scalars['String']>,
  credit_transaction_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  deposit_transaction_client?: Maybe<Scalars['String']>,
  deposit_transaction_lead?: Maybe<Scalars['String']>,
  deposit_transaction_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  file_list?: Maybe<Scalars['String']>,
  flag_client?: Maybe<Scalars['String']>,
  flag_lead?: Maybe<Scalars['String']>,
  flag_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  manage_account_client?: Maybe<Scalars['String']>,
  manage_account_lead?: Maybe<Scalars['String']>,
  manage_account_partner?: Maybe<Scalars['String']>,
  manage_bank_account?: Maybe<Scalars['String']>,
  manage_file?: Maybe<Scalars['String']>,
  manage_stakeholder?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes_client?: Maybe<Scalars['String']>,
  notes_lead?: Maybe<Scalars['String']>,
  notes_partner?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  representative_client?: Maybe<Scalars['String']>,
  representative_lead?: Maybe<Scalars['String']>,
  representative_partner?: Maybe<Scalars['String']>,
  restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  risk_client?: Maybe<Scalars['String']>,
  risk_lead?: Maybe<Scalars['String']>,
  risk_partner?: Maybe<Scalars['String']>,
  service_fee_transaction_client?: Maybe<Scalars['String']>,
  service_fee_transaction_lead?: Maybe<Scalars['String']>,
  service_fee_transaction_partner?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  settings_RNP?: Maybe<Scalars['String']>,
  settings_admins?: Maybe<Scalars['String']>,
  settings_advanced?: Maybe<Scalars['String']>,
  settings_compliance?: Maybe<Scalars['String']>,
  settings_payment_methods?: Maybe<Scalars['String']>,
  settings_platform_servers?: Maybe<Scalars['String']>,
  settings_segments?: Maybe<Scalars['String']>,
  settings_stakeholders?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  tag_client?: Maybe<Scalars['String']>,
  tag_lead?: Maybe<Scalars['String']>,
  tag_partner?: Maybe<Scalars['String']>,
  transaction_list_client?: Maybe<Scalars['String']>,
  transaction_list_lead?: Maybe<Scalars['String']>,
  transaction_list_partner?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  transfer_transaction_client?: Maybe<Scalars['String']>,
  transfer_transaction_lead?: Maybe<Scalars['String']>,
  transfer_transaction_partner?: Maybe<Scalars['String']>,
  withdrawal_transaction_client?: Maybe<Scalars['String']>,
  withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface AdminPermissionMaxFields {
   __typename?: 'admin_permission_max_fields',
  account_list?: Maybe<Scalars['String']>,
  account_list_client?: Maybe<Scalars['String']>,
  account_list_lead?: Maybe<Scalars['String']>,
  account_list_partner?: Maybe<Scalars['String']>,
  account_settings_client?: Maybe<Scalars['String']>,
  account_settings_lead?: Maybe<Scalars['String']>,
  account_settings_partner?: Maybe<Scalars['String']>,
  activity_list?: Maybe<Scalars['String']>,
  activity_list_client?: Maybe<Scalars['String']>,
  activity_list_lead?: Maybe<Scalars['String']>,
  activity_list_partner?: Maybe<Scalars['String']>,
  approve_account_client?: Maybe<Scalars['String']>,
  approve_account_lead?: Maybe<Scalars['String']>,
  approve_account_partner?: Maybe<Scalars['String']>,
  approve_client?: Maybe<Scalars['String']>,
  approve_lead?: Maybe<Scalars['String']>,
  approve_partner?: Maybe<Scalars['String']>,
  approve_stakeholder?: Maybe<Scalars['String']>,
  approve_status_client?: Maybe<Scalars['String']>,
  approve_status_lead?: Maybe<Scalars['String']>,
  approve_status_partner?: Maybe<Scalars['String']>,
  bank_account_list?: Maybe<Scalars['String']>,
  category_client?: Maybe<Scalars['String']>,
  category_lead?: Maybe<Scalars['String']>,
  category_partner?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  credit_transaction_client?: Maybe<Scalars['String']>,
  credit_transaction_lead?: Maybe<Scalars['String']>,
  credit_transaction_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  deposit_transaction_client?: Maybe<Scalars['String']>,
  deposit_transaction_lead?: Maybe<Scalars['String']>,
  deposit_transaction_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  file_list?: Maybe<Scalars['String']>,
  flag_client?: Maybe<Scalars['String']>,
  flag_lead?: Maybe<Scalars['String']>,
  flag_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  manage_account_client?: Maybe<Scalars['String']>,
  manage_account_lead?: Maybe<Scalars['String']>,
  manage_account_partner?: Maybe<Scalars['String']>,
  manage_bank_account?: Maybe<Scalars['String']>,
  manage_file?: Maybe<Scalars['String']>,
  manage_stakeholder?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes_client?: Maybe<Scalars['String']>,
  notes_lead?: Maybe<Scalars['String']>,
  notes_partner?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  representative_client?: Maybe<Scalars['String']>,
  representative_lead?: Maybe<Scalars['String']>,
  representative_partner?: Maybe<Scalars['String']>,
  restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  risk_client?: Maybe<Scalars['String']>,
  risk_lead?: Maybe<Scalars['String']>,
  risk_partner?: Maybe<Scalars['String']>,
  service_fee_transaction_client?: Maybe<Scalars['String']>,
  service_fee_transaction_lead?: Maybe<Scalars['String']>,
  service_fee_transaction_partner?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  settings_RNP?: Maybe<Scalars['String']>,
  settings_admins?: Maybe<Scalars['String']>,
  settings_advanced?: Maybe<Scalars['String']>,
  settings_compliance?: Maybe<Scalars['String']>,
  settings_payment_methods?: Maybe<Scalars['String']>,
  settings_platform_servers?: Maybe<Scalars['String']>,
  settings_segments?: Maybe<Scalars['String']>,
  settings_stakeholders?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  tag_client?: Maybe<Scalars['String']>,
  tag_lead?: Maybe<Scalars['String']>,
  tag_partner?: Maybe<Scalars['String']>,
  transaction_list_client?: Maybe<Scalars['String']>,
  transaction_list_lead?: Maybe<Scalars['String']>,
  transaction_list_partner?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  transfer_transaction_client?: Maybe<Scalars['String']>,
  transfer_transaction_lead?: Maybe<Scalars['String']>,
  transfer_transaction_partner?: Maybe<Scalars['String']>,
  withdrawal_transaction_client?: Maybe<Scalars['String']>,
  withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface AdminPermissionMaxOrderBy {
  account_list?: Maybe<OrderBy>,
  account_list_client?: Maybe<OrderBy>,
  account_list_lead?: Maybe<OrderBy>,
  account_list_partner?: Maybe<OrderBy>,
  account_settings_client?: Maybe<OrderBy>,
  account_settings_lead?: Maybe<OrderBy>,
  account_settings_partner?: Maybe<OrderBy>,
  activity_list?: Maybe<OrderBy>,
  activity_list_client?: Maybe<OrderBy>,
  activity_list_lead?: Maybe<OrderBy>,
  activity_list_partner?: Maybe<OrderBy>,
  approve_account_client?: Maybe<OrderBy>,
  approve_account_lead?: Maybe<OrderBy>,
  approve_account_partner?: Maybe<OrderBy>,
  approve_client?: Maybe<OrderBy>,
  approve_lead?: Maybe<OrderBy>,
  approve_partner?: Maybe<OrderBy>,
  approve_stakeholder?: Maybe<OrderBy>,
  approve_status_client?: Maybe<OrderBy>,
  approve_status_lead?: Maybe<OrderBy>,
  approve_status_partner?: Maybe<OrderBy>,
  bank_account_list?: Maybe<OrderBy>,
  category_client?: Maybe<OrderBy>,
  category_lead?: Maybe<OrderBy>,
  category_partner?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  credit_transaction_client?: Maybe<OrderBy>,
  credit_transaction_lead?: Maybe<OrderBy>,
  credit_transaction_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  deposit_transaction_client?: Maybe<OrderBy>,
  deposit_transaction_lead?: Maybe<OrderBy>,
  deposit_transaction_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  file_list?: Maybe<OrderBy>,
  flag_client?: Maybe<OrderBy>,
  flag_lead?: Maybe<OrderBy>,
  flag_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  manage_account_client?: Maybe<OrderBy>,
  manage_account_lead?: Maybe<OrderBy>,
  manage_account_partner?: Maybe<OrderBy>,
  manage_bank_account?: Maybe<OrderBy>,
  manage_file?: Maybe<OrderBy>,
  manage_stakeholder?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notes_client?: Maybe<OrderBy>,
  notes_lead?: Maybe<OrderBy>,
  notes_partner?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  representative_client?: Maybe<OrderBy>,
  representative_lead?: Maybe<OrderBy>,
  representative_partner?: Maybe<OrderBy>,
  restrict_negative_balance_client?: Maybe<OrderBy>,
  restrict_negative_balance_lead?: Maybe<OrderBy>,
  restrict_negative_balance_partner?: Maybe<OrderBy>,
  risk_client?: Maybe<OrderBy>,
  risk_lead?: Maybe<OrderBy>,
  risk_partner?: Maybe<OrderBy>,
  service_fee_transaction_client?: Maybe<OrderBy>,
  service_fee_transaction_lead?: Maybe<OrderBy>,
  service_fee_transaction_partner?: Maybe<OrderBy>,
  settings?: Maybe<OrderBy>,
  settings_RNP?: Maybe<OrderBy>,
  settings_admins?: Maybe<OrderBy>,
  settings_advanced?: Maybe<OrderBy>,
  settings_compliance?: Maybe<OrderBy>,
  settings_payment_methods?: Maybe<OrderBy>,
  settings_platform_servers?: Maybe<OrderBy>,
  settings_segments?: Maybe<OrderBy>,
  settings_stakeholders?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  tag_client?: Maybe<OrderBy>,
  tag_lead?: Maybe<OrderBy>,
  tag_partner?: Maybe<OrderBy>,
  transaction_list_client?: Maybe<OrderBy>,
  transaction_list_lead?: Maybe<OrderBy>,
  transaction_list_partner?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  transfer_transaction_client?: Maybe<OrderBy>,
  transfer_transaction_lead?: Maybe<OrderBy>,
  transfer_transaction_partner?: Maybe<OrderBy>,
  withdrawal_transaction_client?: Maybe<OrderBy>,
  withdrawal_transaction_lead?: Maybe<OrderBy>,
  withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface AdminPermissionMinFields {
   __typename?: 'admin_permission_min_fields',
  account_list?: Maybe<Scalars['String']>,
  account_list_client?: Maybe<Scalars['String']>,
  account_list_lead?: Maybe<Scalars['String']>,
  account_list_partner?: Maybe<Scalars['String']>,
  account_settings_client?: Maybe<Scalars['String']>,
  account_settings_lead?: Maybe<Scalars['String']>,
  account_settings_partner?: Maybe<Scalars['String']>,
  activity_list?: Maybe<Scalars['String']>,
  activity_list_client?: Maybe<Scalars['String']>,
  activity_list_lead?: Maybe<Scalars['String']>,
  activity_list_partner?: Maybe<Scalars['String']>,
  approve_account_client?: Maybe<Scalars['String']>,
  approve_account_lead?: Maybe<Scalars['String']>,
  approve_account_partner?: Maybe<Scalars['String']>,
  approve_client?: Maybe<Scalars['String']>,
  approve_lead?: Maybe<Scalars['String']>,
  approve_partner?: Maybe<Scalars['String']>,
  approve_stakeholder?: Maybe<Scalars['String']>,
  approve_status_client?: Maybe<Scalars['String']>,
  approve_status_lead?: Maybe<Scalars['String']>,
  approve_status_partner?: Maybe<Scalars['String']>,
  bank_account_list?: Maybe<Scalars['String']>,
  category_client?: Maybe<Scalars['String']>,
  category_lead?: Maybe<Scalars['String']>,
  category_partner?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  credit_transaction_client?: Maybe<Scalars['String']>,
  credit_transaction_lead?: Maybe<Scalars['String']>,
  credit_transaction_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  deposit_transaction_client?: Maybe<Scalars['String']>,
  deposit_transaction_lead?: Maybe<Scalars['String']>,
  deposit_transaction_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  file_list?: Maybe<Scalars['String']>,
  flag_client?: Maybe<Scalars['String']>,
  flag_lead?: Maybe<Scalars['String']>,
  flag_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  manage_account_client?: Maybe<Scalars['String']>,
  manage_account_lead?: Maybe<Scalars['String']>,
  manage_account_partner?: Maybe<Scalars['String']>,
  manage_bank_account?: Maybe<Scalars['String']>,
  manage_file?: Maybe<Scalars['String']>,
  manage_stakeholder?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes_client?: Maybe<Scalars['String']>,
  notes_lead?: Maybe<Scalars['String']>,
  notes_partner?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  representative_client?: Maybe<Scalars['String']>,
  representative_lead?: Maybe<Scalars['String']>,
  representative_partner?: Maybe<Scalars['String']>,
  restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  risk_client?: Maybe<Scalars['String']>,
  risk_lead?: Maybe<Scalars['String']>,
  risk_partner?: Maybe<Scalars['String']>,
  service_fee_transaction_client?: Maybe<Scalars['String']>,
  service_fee_transaction_lead?: Maybe<Scalars['String']>,
  service_fee_transaction_partner?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  settings_RNP?: Maybe<Scalars['String']>,
  settings_admins?: Maybe<Scalars['String']>,
  settings_advanced?: Maybe<Scalars['String']>,
  settings_compliance?: Maybe<Scalars['String']>,
  settings_payment_methods?: Maybe<Scalars['String']>,
  settings_platform_servers?: Maybe<Scalars['String']>,
  settings_segments?: Maybe<Scalars['String']>,
  settings_stakeholders?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  tag_client?: Maybe<Scalars['String']>,
  tag_lead?: Maybe<Scalars['String']>,
  tag_partner?: Maybe<Scalars['String']>,
  transaction_list_client?: Maybe<Scalars['String']>,
  transaction_list_lead?: Maybe<Scalars['String']>,
  transaction_list_partner?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  transfer_transaction_client?: Maybe<Scalars['String']>,
  transfer_transaction_lead?: Maybe<Scalars['String']>,
  transfer_transaction_partner?: Maybe<Scalars['String']>,
  withdrawal_transaction_client?: Maybe<Scalars['String']>,
  withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface AdminPermissionMinOrderBy {
  account_list?: Maybe<OrderBy>,
  account_list_client?: Maybe<OrderBy>,
  account_list_lead?: Maybe<OrderBy>,
  account_list_partner?: Maybe<OrderBy>,
  account_settings_client?: Maybe<OrderBy>,
  account_settings_lead?: Maybe<OrderBy>,
  account_settings_partner?: Maybe<OrderBy>,
  activity_list?: Maybe<OrderBy>,
  activity_list_client?: Maybe<OrderBy>,
  activity_list_lead?: Maybe<OrderBy>,
  activity_list_partner?: Maybe<OrderBy>,
  approve_account_client?: Maybe<OrderBy>,
  approve_account_lead?: Maybe<OrderBy>,
  approve_account_partner?: Maybe<OrderBy>,
  approve_client?: Maybe<OrderBy>,
  approve_lead?: Maybe<OrderBy>,
  approve_partner?: Maybe<OrderBy>,
  approve_stakeholder?: Maybe<OrderBy>,
  approve_status_client?: Maybe<OrderBy>,
  approve_status_lead?: Maybe<OrderBy>,
  approve_status_partner?: Maybe<OrderBy>,
  bank_account_list?: Maybe<OrderBy>,
  category_client?: Maybe<OrderBy>,
  category_lead?: Maybe<OrderBy>,
  category_partner?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  credit_transaction_client?: Maybe<OrderBy>,
  credit_transaction_lead?: Maybe<OrderBy>,
  credit_transaction_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  deposit_transaction_client?: Maybe<OrderBy>,
  deposit_transaction_lead?: Maybe<OrderBy>,
  deposit_transaction_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  file_list?: Maybe<OrderBy>,
  flag_client?: Maybe<OrderBy>,
  flag_lead?: Maybe<OrderBy>,
  flag_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  manage_account_client?: Maybe<OrderBy>,
  manage_account_lead?: Maybe<OrderBy>,
  manage_account_partner?: Maybe<OrderBy>,
  manage_bank_account?: Maybe<OrderBy>,
  manage_file?: Maybe<OrderBy>,
  manage_stakeholder?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notes_client?: Maybe<OrderBy>,
  notes_lead?: Maybe<OrderBy>,
  notes_partner?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  representative_client?: Maybe<OrderBy>,
  representative_lead?: Maybe<OrderBy>,
  representative_partner?: Maybe<OrderBy>,
  restrict_negative_balance_client?: Maybe<OrderBy>,
  restrict_negative_balance_lead?: Maybe<OrderBy>,
  restrict_negative_balance_partner?: Maybe<OrderBy>,
  risk_client?: Maybe<OrderBy>,
  risk_lead?: Maybe<OrderBy>,
  risk_partner?: Maybe<OrderBy>,
  service_fee_transaction_client?: Maybe<OrderBy>,
  service_fee_transaction_lead?: Maybe<OrderBy>,
  service_fee_transaction_partner?: Maybe<OrderBy>,
  settings?: Maybe<OrderBy>,
  settings_RNP?: Maybe<OrderBy>,
  settings_admins?: Maybe<OrderBy>,
  settings_advanced?: Maybe<OrderBy>,
  settings_compliance?: Maybe<OrderBy>,
  settings_payment_methods?: Maybe<OrderBy>,
  settings_platform_servers?: Maybe<OrderBy>,
  settings_segments?: Maybe<OrderBy>,
  settings_stakeholders?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  tag_client?: Maybe<OrderBy>,
  tag_lead?: Maybe<OrderBy>,
  tag_partner?: Maybe<OrderBy>,
  transaction_list_client?: Maybe<OrderBy>,
  transaction_list_lead?: Maybe<OrderBy>,
  transaction_list_partner?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  transfer_transaction_client?: Maybe<OrderBy>,
  transfer_transaction_lead?: Maybe<OrderBy>,
  transfer_transaction_partner?: Maybe<OrderBy>,
  withdrawal_transaction_client?: Maybe<OrderBy>,
  withdrawal_transaction_lead?: Maybe<OrderBy>,
  withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface AdminPermissionMutationResponse {
   __typename?: 'admin_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AdminPermission>,
}

export interface AdminPermissionObjRelInsertInput {
  data: AdminPermissionInsertInput,
  on_conflict?: Maybe<AdminPermissionOnConflict>,
}

export interface AdminPermissionOnConflict {
  constraint: AdminPermissionConstraint,
  update_columns: Array<AdminPermissionUpdateColumn>,
  where?: Maybe<AdminPermissionBoolExp>,
}

export interface AdminPermissionOrderBy {
  account_list?: Maybe<OrderBy>,
  account_list_client?: Maybe<OrderBy>,
  account_list_lead?: Maybe<OrderBy>,
  account_list_partner?: Maybe<OrderBy>,
  account_settings_client?: Maybe<OrderBy>,
  account_settings_lead?: Maybe<OrderBy>,
  account_settings_partner?: Maybe<OrderBy>,
  activity_list?: Maybe<OrderBy>,
  activity_list_client?: Maybe<OrderBy>,
  activity_list_lead?: Maybe<OrderBy>,
  activity_list_partner?: Maybe<OrderBy>,
  approve_account_client?: Maybe<OrderBy>,
  approve_account_lead?: Maybe<OrderBy>,
  approve_account_partner?: Maybe<OrderBy>,
  approve_client?: Maybe<OrderBy>,
  approve_lead?: Maybe<OrderBy>,
  approve_partner?: Maybe<OrderBy>,
  approve_stakeholder?: Maybe<OrderBy>,
  approve_status_client?: Maybe<OrderBy>,
  approve_status_lead?: Maybe<OrderBy>,
  approve_status_partner?: Maybe<OrderBy>,
  bank_account_list?: Maybe<OrderBy>,
  category_client?: Maybe<OrderBy>,
  category_lead?: Maybe<OrderBy>,
  category_partner?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  credit_transaction_client?: Maybe<OrderBy>,
  credit_transaction_lead?: Maybe<OrderBy>,
  credit_transaction_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  deposit_transaction_client?: Maybe<OrderBy>,
  deposit_transaction_lead?: Maybe<OrderBy>,
  deposit_transaction_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  file_list?: Maybe<OrderBy>,
  flag_client?: Maybe<OrderBy>,
  flag_lead?: Maybe<OrderBy>,
  flag_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  manage_account_client?: Maybe<OrderBy>,
  manage_account_lead?: Maybe<OrderBy>,
  manage_account_partner?: Maybe<OrderBy>,
  manage_bank_account?: Maybe<OrderBy>,
  manage_file?: Maybe<OrderBy>,
  manage_stakeholder?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notes_client?: Maybe<OrderBy>,
  notes_lead?: Maybe<OrderBy>,
  notes_partner?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  representative_client?: Maybe<OrderBy>,
  representative_lead?: Maybe<OrderBy>,
  representative_partner?: Maybe<OrderBy>,
  restrict_negative_balance_client?: Maybe<OrderBy>,
  restrict_negative_balance_lead?: Maybe<OrderBy>,
  restrict_negative_balance_partner?: Maybe<OrderBy>,
  risk_client?: Maybe<OrderBy>,
  risk_lead?: Maybe<OrderBy>,
  risk_partner?: Maybe<OrderBy>,
  service_fee_transaction_client?: Maybe<OrderBy>,
  service_fee_transaction_lead?: Maybe<OrderBy>,
  service_fee_transaction_partner?: Maybe<OrderBy>,
  settings?: Maybe<OrderBy>,
  settings_RNP?: Maybe<OrderBy>,
  settings_admins?: Maybe<OrderBy>,
  settings_advanced?: Maybe<OrderBy>,
  settings_compliance?: Maybe<OrderBy>,
  settings_payment_methods?: Maybe<OrderBy>,
  settings_platform_servers?: Maybe<OrderBy>,
  settings_segments?: Maybe<OrderBy>,
  settings_stakeholders?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  tag_client?: Maybe<OrderBy>,
  tag_lead?: Maybe<OrderBy>,
  tag_partner?: Maybe<OrderBy>,
  transaction_list_client?: Maybe<OrderBy>,
  transaction_list_lead?: Maybe<OrderBy>,
  transaction_list_partner?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  transfer_transaction_client?: Maybe<OrderBy>,
  transfer_transaction_lead?: Maybe<OrderBy>,
  transfer_transaction_partner?: Maybe<OrderBy>,
  withdrawal_transaction_client?: Maybe<OrderBy>,
  withdrawal_transaction_lead?: Maybe<OrderBy>,
  withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface AdminPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum AdminPermissionSelectColumn {
  AccountList = 'account_list',
  AccountListClient = 'account_list_client',
  AccountListLead = 'account_list_lead',
  AccountListPartner = 'account_list_partner',
  AccountSettingsClient = 'account_settings_client',
  AccountSettingsLead = 'account_settings_lead',
  AccountSettingsPartner = 'account_settings_partner',
  ActivityList = 'activity_list',
  ActivityListClient = 'activity_list_client',
  ActivityListLead = 'activity_list_lead',
  ActivityListPartner = 'activity_list_partner',
  ApproveAccountClient = 'approve_account_client',
  ApproveAccountLead = 'approve_account_lead',
  ApproveAccountPartner = 'approve_account_partner',
  ApproveClient = 'approve_client',
  ApproveLead = 'approve_lead',
  ApprovePartner = 'approve_partner',
  ApproveStakeholder = 'approve_stakeholder',
  ApproveStatusClient = 'approve_status_client',
  ApproveStatusLead = 'approve_status_lead',
  ApproveStatusPartner = 'approve_status_partner',
  BankAccountList = 'bank_account_list',
  CategoryClient = 'category_client',
  CategoryLead = 'category_lead',
  CategoryPartner = 'category_partner',
  ClientList = 'client_list',
  CreateClient = 'create_client',
  CreateLead = 'create_lead',
  CreatePartner = 'create_partner',
  CreditTransactionClient = 'credit_transaction_client',
  CreditTransactionLead = 'credit_transaction_lead',
  CreditTransactionPartner = 'credit_transaction_partner',
  DeleteClient = 'delete_client',
  DeleteLead = 'delete_lead',
  DeletePartner = 'delete_partner',
  DepositTransactionClient = 'deposit_transaction_client',
  DepositTransactionLead = 'deposit_transaction_lead',
  DepositTransactionPartner = 'deposit_transaction_partner',
  Description = 'description',
  EditClient = 'edit_client',
  EditLead = 'edit_lead',
  EditPartner = 'edit_partner',
  FileList = 'file_list',
  FlagClient = 'flag_client',
  FlagLead = 'flag_lead',
  FlagPartner = 'flag_partner',
  Id = 'id',
  LeadList = 'lead_list',
  ManageAccountClient = 'manage_account_client',
  ManageAccountLead = 'manage_account_lead',
  ManageAccountPartner = 'manage_account_partner',
  ManageBankAccount = 'manage_bank_account',
  ManageFile = 'manage_file',
  ManageStakeholder = 'manage_stakeholder',
  Name = 'name',
  NotesClient = 'notes_client',
  NotesLead = 'notes_lead',
  NotesPartner = 'notes_partner',
  OrganizationId = 'organization_id',
  PartnerList = 'partner_list',
  RepresentativeClient = 'representative_client',
  RepresentativeLead = 'representative_lead',
  RepresentativePartner = 'representative_partner',
  RestrictNegativeBalanceClient = 'restrict_negative_balance_client',
  RestrictNegativeBalanceLead = 'restrict_negative_balance_lead',
  RestrictNegativeBalancePartner = 'restrict_negative_balance_partner',
  RiskClient = 'risk_client',
  RiskLead = 'risk_lead',
  RiskPartner = 'risk_partner',
  ServiceFeeTransactionClient = 'service_fee_transaction_client',
  ServiceFeeTransactionLead = 'service_fee_transaction_lead',
  ServiceFeeTransactionPartner = 'service_fee_transaction_partner',
  Settings = 'settings',
  SettingsRnp = 'settings_RNP',
  SettingsAdmins = 'settings_admins',
  SettingsAdvanced = 'settings_advanced',
  SettingsCompliance = 'settings_compliance',
  SettingsPaymentMethods = 'settings_payment_methods',
  SettingsPlatformServers = 'settings_platform_servers',
  SettingsSegments = 'settings_segments',
  SettingsStakeholders = 'settings_stakeholders',
  StakeholderList = 'stakeholder_list',
  TagClient = 'tag_client',
  TagLead = 'tag_lead',
  TagPartner = 'tag_partner',
  TransactionListClient = 'transaction_list_client',
  TransactionListLead = 'transaction_list_lead',
  TransactionListPartner = 'transaction_list_partner',
  TransferBetweenProfilesTransactionClient = 'transfer_between_profiles_transaction_client',
  TransferBetweenProfilesTransactionLead = 'transfer_between_profiles_transaction_lead',
  TransferBetweenProfilesTransactionPartner = 'transfer_between_profiles_transaction_partner',
  TransferTransactionClient = 'transfer_transaction_client',
  TransferTransactionLead = 'transfer_transaction_lead',
  TransferTransactionPartner = 'transfer_transaction_partner',
  WithdrawalTransactionClient = 'withdrawal_transaction_client',
  WithdrawalTransactionLead = 'withdrawal_transaction_lead',
  WithdrawalTransactionPartner = 'withdrawal_transaction_partner'
}

export interface AdminPermissionSetInput {
  account_list?: Maybe<Scalars['String']>,
  account_list_client?: Maybe<Scalars['String']>,
  account_list_lead?: Maybe<Scalars['String']>,
  account_list_partner?: Maybe<Scalars['String']>,
  account_settings_client?: Maybe<Scalars['String']>,
  account_settings_lead?: Maybe<Scalars['String']>,
  account_settings_partner?: Maybe<Scalars['String']>,
  activity_list?: Maybe<Scalars['String']>,
  activity_list_client?: Maybe<Scalars['String']>,
  activity_list_lead?: Maybe<Scalars['String']>,
  activity_list_partner?: Maybe<Scalars['String']>,
  approve_account_client?: Maybe<Scalars['String']>,
  approve_account_lead?: Maybe<Scalars['String']>,
  approve_account_partner?: Maybe<Scalars['String']>,
  approve_client?: Maybe<Scalars['String']>,
  approve_lead?: Maybe<Scalars['String']>,
  approve_partner?: Maybe<Scalars['String']>,
  approve_stakeholder?: Maybe<Scalars['String']>,
  approve_status_client?: Maybe<Scalars['String']>,
  approve_status_lead?: Maybe<Scalars['String']>,
  approve_status_partner?: Maybe<Scalars['String']>,
  bank_account_list?: Maybe<Scalars['String']>,
  category_client?: Maybe<Scalars['String']>,
  category_lead?: Maybe<Scalars['String']>,
  category_partner?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  credit_transaction_client?: Maybe<Scalars['String']>,
  credit_transaction_lead?: Maybe<Scalars['String']>,
  credit_transaction_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  deposit_transaction_client?: Maybe<Scalars['String']>,
  deposit_transaction_lead?: Maybe<Scalars['String']>,
  deposit_transaction_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  file_list?: Maybe<Scalars['String']>,
  flag_client?: Maybe<Scalars['String']>,
  flag_lead?: Maybe<Scalars['String']>,
  flag_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  manage_account_client?: Maybe<Scalars['String']>,
  manage_account_lead?: Maybe<Scalars['String']>,
  manage_account_partner?: Maybe<Scalars['String']>,
  manage_bank_account?: Maybe<Scalars['String']>,
  manage_file?: Maybe<Scalars['String']>,
  manage_stakeholder?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes_client?: Maybe<Scalars['String']>,
  notes_lead?: Maybe<Scalars['String']>,
  notes_partner?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  representative_client?: Maybe<Scalars['String']>,
  representative_lead?: Maybe<Scalars['String']>,
  representative_partner?: Maybe<Scalars['String']>,
  restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  risk_client?: Maybe<Scalars['String']>,
  risk_lead?: Maybe<Scalars['String']>,
  risk_partner?: Maybe<Scalars['String']>,
  service_fee_transaction_client?: Maybe<Scalars['String']>,
  service_fee_transaction_lead?: Maybe<Scalars['String']>,
  service_fee_transaction_partner?: Maybe<Scalars['String']>,
  settings?: Maybe<Scalars['String']>,
  settings_RNP?: Maybe<Scalars['String']>,
  settings_admins?: Maybe<Scalars['String']>,
  settings_advanced?: Maybe<Scalars['String']>,
  settings_compliance?: Maybe<Scalars['String']>,
  settings_payment_methods?: Maybe<Scalars['String']>,
  settings_platform_servers?: Maybe<Scalars['String']>,
  settings_segments?: Maybe<Scalars['String']>,
  settings_stakeholders?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  tag_client?: Maybe<Scalars['String']>,
  tag_lead?: Maybe<Scalars['String']>,
  tag_partner?: Maybe<Scalars['String']>,
  transaction_list_client?: Maybe<Scalars['String']>,
  transaction_list_lead?: Maybe<Scalars['String']>,
  transaction_list_partner?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  transfer_transaction_client?: Maybe<Scalars['String']>,
  transfer_transaction_lead?: Maybe<Scalars['String']>,
  transfer_transaction_partner?: Maybe<Scalars['String']>,
  withdrawal_transaction_client?: Maybe<Scalars['String']>,
  withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export enum AdminPermissionUpdateColumn {
  AccountList = 'account_list',
  AccountListClient = 'account_list_client',
  AccountListLead = 'account_list_lead',
  AccountListPartner = 'account_list_partner',
  AccountSettingsClient = 'account_settings_client',
  AccountSettingsLead = 'account_settings_lead',
  AccountSettingsPartner = 'account_settings_partner',
  ActivityList = 'activity_list',
  ActivityListClient = 'activity_list_client',
  ActivityListLead = 'activity_list_lead',
  ActivityListPartner = 'activity_list_partner',
  ApproveAccountClient = 'approve_account_client',
  ApproveAccountLead = 'approve_account_lead',
  ApproveAccountPartner = 'approve_account_partner',
  ApproveClient = 'approve_client',
  ApproveLead = 'approve_lead',
  ApprovePartner = 'approve_partner',
  ApproveStakeholder = 'approve_stakeholder',
  ApproveStatusClient = 'approve_status_client',
  ApproveStatusLead = 'approve_status_lead',
  ApproveStatusPartner = 'approve_status_partner',
  BankAccountList = 'bank_account_list',
  CategoryClient = 'category_client',
  CategoryLead = 'category_lead',
  CategoryPartner = 'category_partner',
  ClientList = 'client_list',
  CreateClient = 'create_client',
  CreateLead = 'create_lead',
  CreatePartner = 'create_partner',
  CreditTransactionClient = 'credit_transaction_client',
  CreditTransactionLead = 'credit_transaction_lead',
  CreditTransactionPartner = 'credit_transaction_partner',
  DeleteClient = 'delete_client',
  DeleteLead = 'delete_lead',
  DeletePartner = 'delete_partner',
  DepositTransactionClient = 'deposit_transaction_client',
  DepositTransactionLead = 'deposit_transaction_lead',
  DepositTransactionPartner = 'deposit_transaction_partner',
  Description = 'description',
  EditClient = 'edit_client',
  EditLead = 'edit_lead',
  EditPartner = 'edit_partner',
  FileList = 'file_list',
  FlagClient = 'flag_client',
  FlagLead = 'flag_lead',
  FlagPartner = 'flag_partner',
  Id = 'id',
  LeadList = 'lead_list',
  ManageAccountClient = 'manage_account_client',
  ManageAccountLead = 'manage_account_lead',
  ManageAccountPartner = 'manage_account_partner',
  ManageBankAccount = 'manage_bank_account',
  ManageFile = 'manage_file',
  ManageStakeholder = 'manage_stakeholder',
  Name = 'name',
  NotesClient = 'notes_client',
  NotesLead = 'notes_lead',
  NotesPartner = 'notes_partner',
  OrganizationId = 'organization_id',
  PartnerList = 'partner_list',
  RepresentativeClient = 'representative_client',
  RepresentativeLead = 'representative_lead',
  RepresentativePartner = 'representative_partner',
  RestrictNegativeBalanceClient = 'restrict_negative_balance_client',
  RestrictNegativeBalanceLead = 'restrict_negative_balance_lead',
  RestrictNegativeBalancePartner = 'restrict_negative_balance_partner',
  RiskClient = 'risk_client',
  RiskLead = 'risk_lead',
  RiskPartner = 'risk_partner',
  ServiceFeeTransactionClient = 'service_fee_transaction_client',
  ServiceFeeTransactionLead = 'service_fee_transaction_lead',
  ServiceFeeTransactionPartner = 'service_fee_transaction_partner',
  Settings = 'settings',
  SettingsRnp = 'settings_RNP',
  SettingsAdmins = 'settings_admins',
  SettingsAdvanced = 'settings_advanced',
  SettingsCompliance = 'settings_compliance',
  SettingsPaymentMethods = 'settings_payment_methods',
  SettingsPlatformServers = 'settings_platform_servers',
  SettingsSegments = 'settings_segments',
  SettingsStakeholders = 'settings_stakeholders',
  StakeholderList = 'stakeholder_list',
  TagClient = 'tag_client',
  TagLead = 'tag_lead',
  TagPartner = 'tag_partner',
  TransactionListClient = 'transaction_list_client',
  TransactionListLead = 'transaction_list_lead',
  TransactionListPartner = 'transaction_list_partner',
  TransferBetweenProfilesTransactionClient = 'transfer_between_profiles_transaction_client',
  TransferBetweenProfilesTransactionLead = 'transfer_between_profiles_transaction_lead',
  TransferBetweenProfilesTransactionPartner = 'transfer_between_profiles_transaction_partner',
  TransferTransactionClient = 'transfer_transaction_client',
  TransferTransactionLead = 'transfer_transaction_lead',
  TransferTransactionPartner = 'transfer_transaction_partner',
  WithdrawalTransactionClient = 'withdrawal_transaction_client',
  WithdrawalTransactionLead = 'withdrawal_transaction_lead',
  WithdrawalTransactionPartner = 'withdrawal_transaction_partner'
}

export interface Agreement {
   __typename?: 'agreement',
  acknowledgement: Scalars['String'],
  created_at: Scalars['timestamptz'],
  file?: Maybe<File>,
  file_id?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_acceptance_required: Scalars['Boolean'],
  is_deleted: Scalars['Boolean'],
  is_published: Scalars['Boolean'],
  name: Scalars['String'],
  scenario_id: Scalars['String'],
  segment_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface AgreementAggregate {
   __typename?: 'agreement_aggregate',
  aggregate?: Maybe<AgreementAggregateFields>,
  nodes: Array<Agreement>,
}

export interface AgreementAggregateFields {
   __typename?: 'agreement_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AgreementMaxFields>,
  min?: Maybe<AgreementMinFields>,
}


export interface AgreementAggregateFieldsCountArgs {
  columns?: Maybe<Array<AgreementSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AgreementAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AgreementMaxOrderBy>,
  min?: Maybe<AgreementMinOrderBy>,
}

export interface AgreementArrRelInsertInput {
  data: Array<AgreementInsertInput>,
  on_conflict?: Maybe<AgreementOnConflict>,
}

export interface AgreementBoolExp {
  _and?: Maybe<Array<Maybe<AgreementBoolExp>>>,
  _not?: Maybe<AgreementBoolExp>,
  _or?: Maybe<Array<Maybe<AgreementBoolExp>>>,
  acknowledgement?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  file?: Maybe<FileBoolExp>,
  file_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_acceptance_required?: Maybe<BooleanComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  is_published?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  scenario_id?: Maybe<StringComparisonExp>,
  segment_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AgreementConstraint {
  AgreementPkey = 'agreement_pkey'
}

export interface AgreementGridView {
   __typename?: 'agreement_grid_view',
  acknowledgement?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_published?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  scenario?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface AgreementGridViewAggregate {
   __typename?: 'agreement_grid_view_aggregate',
  aggregate?: Maybe<AgreementGridViewAggregateFields>,
  nodes: Array<AgreementGridView>,
}

export interface AgreementGridViewAggregateFields {
   __typename?: 'agreement_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AgreementGridViewMaxFields>,
  min?: Maybe<AgreementGridViewMinFields>,
}


export interface AgreementGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<AgreementGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AgreementGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AgreementGridViewMaxOrderBy>,
  min?: Maybe<AgreementGridViewMinOrderBy>,
}

export interface AgreementGridViewBoolExp {
  _and?: Maybe<Array<Maybe<AgreementGridViewBoolExp>>>,
  _not?: Maybe<AgreementGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<AgreementGridViewBoolExp>>>,
  acknowledgement?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  is_published?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  scenario?: Maybe<StringComparisonExp>,
  segment?: Maybe<StringComparisonExp>,
}

export interface AgreementGridViewMaxFields {
   __typename?: 'agreement_grid_view_max_fields',
  acknowledgement?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  scenario?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface AgreementGridViewMaxOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface AgreementGridViewMinFields {
   __typename?: 'agreement_grid_view_min_fields',
  acknowledgement?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  scenario?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface AgreementGridViewMinOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface AgreementGridViewOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  is_published?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export enum AgreementGridViewSelectColumn {
  Acknowledgement = 'acknowledgement',
  Id = 'id',
  IsDeleted = 'is_deleted',
  IsPublished = 'is_published',
  Name = 'name',
  Scenario = 'scenario',
  Segment = 'segment'
}

export interface AgreementInsertInput {
  acknowledgement?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  file?: Maybe<FileObjRelInsertInput>,
  file_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_acceptance_required?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_published?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  scenario_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AgreementMaxFields {
   __typename?: 'agreement_max_fields',
  acknowledgement?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  scenario_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AgreementMaxOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AgreementMinFields {
   __typename?: 'agreement_min_fields',
  acknowledgement?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  scenario_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AgreementMinOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AgreementMutationResponse {
   __typename?: 'agreement_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Agreement>,
}

export interface AgreementObjRelInsertInput {
  data: AgreementInsertInput,
  on_conflict?: Maybe<AgreementOnConflict>,
}

export interface AgreementOnConflict {
  constraint: AgreementConstraint,
  update_columns: Array<AgreementUpdateColumn>,
  where?: Maybe<AgreementBoolExp>,
}

export interface AgreementOrderBy {
  acknowledgement?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  file?: Maybe<FileOrderBy>,
  file_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_acceptance_required?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  is_published?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  scenario_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AgreementPkColumnsInput {
  id: Scalars['String'],
}

export interface AgreementScenario {
   __typename?: 'agreement_scenario',
  id: Scalars['String'],
  name: Scalars['String'],
}

export interface AgreementScenarioAggregate {
   __typename?: 'agreement_scenario_aggregate',
  aggregate?: Maybe<AgreementScenarioAggregateFields>,
  nodes: Array<AgreementScenario>,
}

export interface AgreementScenarioAggregateFields {
   __typename?: 'agreement_scenario_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AgreementScenarioMaxFields>,
  min?: Maybe<AgreementScenarioMinFields>,
}


export interface AgreementScenarioAggregateFieldsCountArgs {
  columns?: Maybe<Array<AgreementScenarioSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AgreementScenarioAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AgreementScenarioMaxOrderBy>,
  min?: Maybe<AgreementScenarioMinOrderBy>,
}

export interface AgreementScenarioArrRelInsertInput {
  data: Array<AgreementScenarioInsertInput>,
  on_conflict?: Maybe<AgreementScenarioOnConflict>,
}

export interface AgreementScenarioBoolExp {
  _and?: Maybe<Array<Maybe<AgreementScenarioBoolExp>>>,
  _not?: Maybe<AgreementScenarioBoolExp>,
  _or?: Maybe<Array<Maybe<AgreementScenarioBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
}

export enum AgreementScenarioConstraint {
  AgreementScenarioPkey = 'agreement_scenario_pkey'
}

export interface AgreementScenarioInsertInput {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface AgreementScenarioMaxFields {
   __typename?: 'agreement_scenario_max_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface AgreementScenarioMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface AgreementScenarioMinFields {
   __typename?: 'agreement_scenario_min_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface AgreementScenarioMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface AgreementScenarioMutationResponse {
   __typename?: 'agreement_scenario_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AgreementScenario>,
}

export interface AgreementScenarioObjRelInsertInput {
  data: AgreementScenarioInsertInput,
  on_conflict?: Maybe<AgreementScenarioOnConflict>,
}

export interface AgreementScenarioOnConflict {
  constraint: AgreementScenarioConstraint,
  update_columns: Array<AgreementScenarioUpdateColumn>,
  where?: Maybe<AgreementScenarioBoolExp>,
}

export interface AgreementScenarioOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface AgreementScenarioPkColumnsInput {
  id: Scalars['String'],
}

export enum AgreementScenarioSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface AgreementScenarioSetInput {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export enum AgreementScenarioUpdateColumn {
  Id = 'id',
  Name = 'name'
}

export enum AgreementSelectColumn {
  Acknowledgement = 'acknowledgement',
  CreatedAt = 'created_at',
  FileId = 'file_id',
  Id = 'id',
  IsAcceptanceRequired = 'is_acceptance_required',
  IsDeleted = 'is_deleted',
  IsPublished = 'is_published',
  Name = 'name',
  ScenarioId = 'scenario_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export interface AgreementSetInput {
  acknowledgement?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_acceptance_required?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_published?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  scenario_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum AgreementUpdateColumn {
  Acknowledgement = 'acknowledgement',
  CreatedAt = 'created_at',
  FileId = 'file_id',
  Id = 'id',
  IsAcceptanceRequired = 'is_acceptance_required',
  IsDeleted = 'is_deleted',
  IsPublished = 'is_published',
  Name = 'name',
  ScenarioId = 'scenario_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export interface AgreementsSatisfied {
   __typename?: 'agreements_satisfied',
  agreement_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  stakeholder_id: Scalars['String'],
}

export interface AgreementsSatisfiedAggregate {
   __typename?: 'agreements_satisfied_aggregate',
  aggregate?: Maybe<AgreementsSatisfiedAggregateFields>,
  nodes: Array<AgreementsSatisfied>,
}

export interface AgreementsSatisfiedAggregateFields {
   __typename?: 'agreements_satisfied_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AgreementsSatisfiedMaxFields>,
  min?: Maybe<AgreementsSatisfiedMinFields>,
}


export interface AgreementsSatisfiedAggregateFieldsCountArgs {
  columns?: Maybe<Array<AgreementsSatisfiedSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AgreementsSatisfiedAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AgreementsSatisfiedMaxOrderBy>,
  min?: Maybe<AgreementsSatisfiedMinOrderBy>,
}

export interface AgreementsSatisfiedArrRelInsertInput {
  data: Array<AgreementsSatisfiedInsertInput>,
  on_conflict?: Maybe<AgreementsSatisfiedOnConflict>,
}

export interface AgreementsSatisfiedBoolExp {
  _and?: Maybe<Array<Maybe<AgreementsSatisfiedBoolExp>>>,
  _not?: Maybe<AgreementsSatisfiedBoolExp>,
  _or?: Maybe<Array<Maybe<AgreementsSatisfiedBoolExp>>>,
  agreement_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
}

export enum AgreementsSatisfiedConstraint {
  AgreementsSatisfiedPkey = 'agreements_satisfied_pkey'
}

export interface AgreementsSatisfiedInsertInput {
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_id?: Maybe<Scalars['String']>,
}

export interface AgreementsSatisfiedMaxFields {
   __typename?: 'agreements_satisfied_max_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_id?: Maybe<Scalars['String']>,
}

export interface AgreementsSatisfiedMaxOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
}

export interface AgreementsSatisfiedMinFields {
   __typename?: 'agreements_satisfied_min_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_id?: Maybe<Scalars['String']>,
}

export interface AgreementsSatisfiedMinOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
}

export interface AgreementsSatisfiedMutationResponse {
   __typename?: 'agreements_satisfied_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AgreementsSatisfied>,
}

export interface AgreementsSatisfiedObjRelInsertInput {
  data: AgreementsSatisfiedInsertInput,
  on_conflict?: Maybe<AgreementsSatisfiedOnConflict>,
}

export interface AgreementsSatisfiedOnConflict {
  constraint: AgreementsSatisfiedConstraint,
  update_columns: Array<AgreementsSatisfiedUpdateColumn>,
  where?: Maybe<AgreementsSatisfiedBoolExp>,
}

export interface AgreementsSatisfiedOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
}

export interface AgreementsSatisfiedPkColumnsInput {
  agreement_id: Scalars['String'],
  stakeholder_id: Scalars['String'],
}

export enum AgreementsSatisfiedSelectColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  StakeholderId = 'stakeholder_id'
}

export interface AgreementsSatisfiedSetInput {
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_id?: Maybe<Scalars['String']>,
}

export enum AgreementsSatisfiedUpdateColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  StakeholderId = 'stakeholder_id'
}

export interface AgreementsToPaymentMethods {
   __typename?: 'agreements_to_payment_methods',
  agreement: Agreement,
  agreement_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  method_id: Scalars['String'],
}

export interface AgreementsToPaymentMethodsAggregate {
   __typename?: 'agreements_to_payment_methods_aggregate',
  aggregate?: Maybe<AgreementsToPaymentMethodsAggregateFields>,
  nodes: Array<AgreementsToPaymentMethods>,
}

export interface AgreementsToPaymentMethodsAggregateFields {
   __typename?: 'agreements_to_payment_methods_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AgreementsToPaymentMethodsMaxFields>,
  min?: Maybe<AgreementsToPaymentMethodsMinFields>,
}


export interface AgreementsToPaymentMethodsAggregateFieldsCountArgs {
  columns?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AgreementsToPaymentMethodsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AgreementsToPaymentMethodsMaxOrderBy>,
  min?: Maybe<AgreementsToPaymentMethodsMinOrderBy>,
}

export interface AgreementsToPaymentMethodsArrRelInsertInput {
  data: Array<AgreementsToPaymentMethodsInsertInput>,
  on_conflict?: Maybe<AgreementsToPaymentMethodsOnConflict>,
}

export interface AgreementsToPaymentMethodsBoolExp {
  _and?: Maybe<Array<Maybe<AgreementsToPaymentMethodsBoolExp>>>,
  _not?: Maybe<AgreementsToPaymentMethodsBoolExp>,
  _or?: Maybe<Array<Maybe<AgreementsToPaymentMethodsBoolExp>>>,
  agreement?: Maybe<AgreementBoolExp>,
  agreement_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  method_id?: Maybe<StringComparisonExp>,
}

export enum AgreementsToPaymentMethodsConstraint {
  AgreementsToPaymentMethodsPkey = 'agreements_to_payment_methods_pkey'
}

export interface AgreementsToPaymentMethodsInsertInput {
  agreement?: Maybe<AgreementObjRelInsertInput>,
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  method_id?: Maybe<Scalars['String']>,
}

export interface AgreementsToPaymentMethodsMaxFields {
   __typename?: 'agreements_to_payment_methods_max_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  method_id?: Maybe<Scalars['String']>,
}

export interface AgreementsToPaymentMethodsMaxOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
}

export interface AgreementsToPaymentMethodsMinFields {
   __typename?: 'agreements_to_payment_methods_min_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  method_id?: Maybe<Scalars['String']>,
}

export interface AgreementsToPaymentMethodsMinOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
}

export interface AgreementsToPaymentMethodsMutationResponse {
   __typename?: 'agreements_to_payment_methods_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AgreementsToPaymentMethods>,
}

export interface AgreementsToPaymentMethodsObjRelInsertInput {
  data: AgreementsToPaymentMethodsInsertInput,
  on_conflict?: Maybe<AgreementsToPaymentMethodsOnConflict>,
}

export interface AgreementsToPaymentMethodsOnConflict {
  constraint: AgreementsToPaymentMethodsConstraint,
  update_columns: Array<AgreementsToPaymentMethodsUpdateColumn>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>,
}

export interface AgreementsToPaymentMethodsOrderBy {
  agreement?: Maybe<AgreementOrderBy>,
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
}

export interface AgreementsToPaymentMethodsPkColumnsInput {
  agreement_id: Scalars['String'],
  method_id: Scalars['String'],
}

export enum AgreementsToPaymentMethodsSelectColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  MethodId = 'method_id'
}

export interface AgreementsToPaymentMethodsSetInput {
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  method_id?: Maybe<Scalars['String']>,
}

export enum AgreementsToPaymentMethodsUpdateColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  MethodId = 'method_id'
}

export interface AnnualIncome {
   __typename?: 'annual_income',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface AnnualIncomeAggregate {
   __typename?: 'annual_income_aggregate',
  aggregate?: Maybe<AnnualIncomeAggregateFields>,
  nodes: Array<AnnualIncome>,
}

export interface AnnualIncomeAggregateFields {
   __typename?: 'annual_income_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AnnualIncomeMaxFields>,
  min?: Maybe<AnnualIncomeMinFields>,
}


export interface AnnualIncomeAggregateFieldsCountArgs {
  columns?: Maybe<Array<AnnualIncomeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AnnualIncomeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AnnualIncomeMaxOrderBy>,
  min?: Maybe<AnnualIncomeMinOrderBy>,
}

export interface AnnualIncomeArrRelInsertInput {
  data: Array<AnnualIncomeInsertInput>,
  on_conflict?: Maybe<AnnualIncomeOnConflict>,
}

export interface AnnualIncomeBoolExp {
  _and?: Maybe<Array<Maybe<AnnualIncomeBoolExp>>>,
  _not?: Maybe<AnnualIncomeBoolExp>,
  _or?: Maybe<Array<Maybe<AnnualIncomeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AnnualIncomeConstraint {
  AnnualIncomeNameKey = 'annual_income_name_key',
  AnnualIncomePkey = 'annual_income_pkey'
}

export interface AnnualIncomeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AnnualIncomeMaxFields {
   __typename?: 'annual_income_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AnnualIncomeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AnnualIncomeMinFields {
   __typename?: 'annual_income_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AnnualIncomeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AnnualIncomeMutationResponse {
   __typename?: 'annual_income_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AnnualIncome>,
}

export interface AnnualIncomeObjRelInsertInput {
  data: AnnualIncomeInsertInput,
  on_conflict?: Maybe<AnnualIncomeOnConflict>,
}

export interface AnnualIncomeOnConflict {
  constraint: AnnualIncomeConstraint,
  update_columns: Array<AnnualIncomeUpdateColumn>,
  where?: Maybe<AnnualIncomeBoolExp>,
}

export interface AnnualIncomeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AnnualIncomePkColumnsInput {
  id: Scalars['String'],
}

export enum AnnualIncomeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface AnnualIncomeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum AnnualIncomeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ApiKey {
   __typename?: 'api_key',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  value: Scalars['String'],
}

export interface ApiKeyAggregate {
   __typename?: 'api_key_aggregate',
  aggregate?: Maybe<ApiKeyAggregateFields>,
  nodes: Array<ApiKey>,
}

export interface ApiKeyAggregateFields {
   __typename?: 'api_key_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ApiKeyMaxFields>,
  min?: Maybe<ApiKeyMinFields>,
}


export interface ApiKeyAggregateFieldsCountArgs {
  columns?: Maybe<Array<ApiKeySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ApiKeyAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ApiKeyMaxOrderBy>,
  min?: Maybe<ApiKeyMinOrderBy>,
}

export interface ApiKeyArrRelInsertInput {
  data: Array<ApiKeyInsertInput>,
  on_conflict?: Maybe<ApiKeyOnConflict>,
}

export interface ApiKeyBoolExp {
  _and?: Maybe<Array<Maybe<ApiKeyBoolExp>>>,
  _not?: Maybe<ApiKeyBoolExp>,
  _or?: Maybe<Array<Maybe<ApiKeyBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum ApiKeyConstraint {
  ApiKeyPkey = 'api_key_pkey',
  ApiKeyValueKey = 'api_key_value_key'
}

export interface ApiKeyInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface ApiKeyMaxFields {
   __typename?: 'api_key_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface ApiKeyMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface ApiKeyMinFields {
   __typename?: 'api_key_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface ApiKeyMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface ApiKeyMutationResponse {
   __typename?: 'api_key_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ApiKey>,
}

export interface ApiKeyObjRelInsertInput {
  data: ApiKeyInsertInput,
  on_conflict?: Maybe<ApiKeyOnConflict>,
}

export interface ApiKeyOnConflict {
  constraint: ApiKeyConstraint,
  update_columns: Array<ApiKeyUpdateColumn>,
  where?: Maybe<ApiKeyBoolExp>,
}

export interface ApiKeyOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface ApiKeyPkColumnsInput {
  id: Scalars['String'],
}

export enum ApiKeySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface ApiKeySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export enum ApiKeyUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface AttributeMetadata {
   __typename?: 'attribute_metadata',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  query: Scalars['String'],
  text_field: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  value_field: Scalars['String'],
}

export interface AttributeMetadataAggregate {
   __typename?: 'attribute_metadata_aggregate',
  aggregate?: Maybe<AttributeMetadataAggregateFields>,
  nodes: Array<AttributeMetadata>,
}

export interface AttributeMetadataAggregateFields {
   __typename?: 'attribute_metadata_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AttributeMetadataMaxFields>,
  min?: Maybe<AttributeMetadataMinFields>,
}


export interface AttributeMetadataAggregateFieldsCountArgs {
  columns?: Maybe<Array<AttributeMetadataSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AttributeMetadataAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AttributeMetadataMaxOrderBy>,
  min?: Maybe<AttributeMetadataMinOrderBy>,
}

export interface AttributeMetadataArrRelInsertInput {
  data: Array<AttributeMetadataInsertInput>,
  on_conflict?: Maybe<AttributeMetadataOnConflict>,
}

export interface AttributeMetadataBoolExp {
  _and?: Maybe<Array<Maybe<AttributeMetadataBoolExp>>>,
  _not?: Maybe<AttributeMetadataBoolExp>,
  _or?: Maybe<Array<Maybe<AttributeMetadataBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  query?: Maybe<StringComparisonExp>,
  text_field?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value_field?: Maybe<StringComparisonExp>,
}

export enum AttributeMetadataConstraint {
  AttributeMetadataPkey = 'attribute_metadata_pkey'
}

export interface AttributeMetadataInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  query?: Maybe<Scalars['String']>,
  text_field?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value_field?: Maybe<Scalars['String']>,
}

export interface AttributeMetadataMaxFields {
   __typename?: 'attribute_metadata_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  query?: Maybe<Scalars['String']>,
  text_field?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value_field?: Maybe<Scalars['String']>,
}

export interface AttributeMetadataMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  query?: Maybe<OrderBy>,
  text_field?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value_field?: Maybe<OrderBy>,
}

export interface AttributeMetadataMinFields {
   __typename?: 'attribute_metadata_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  query?: Maybe<Scalars['String']>,
  text_field?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value_field?: Maybe<Scalars['String']>,
}

export interface AttributeMetadataMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  query?: Maybe<OrderBy>,
  text_field?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value_field?: Maybe<OrderBy>,
}

export interface AttributeMetadataMutationResponse {
   __typename?: 'attribute_metadata_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AttributeMetadata>,
}

export interface AttributeMetadataObjRelInsertInput {
  data: AttributeMetadataInsertInput,
  on_conflict?: Maybe<AttributeMetadataOnConflict>,
}

export interface AttributeMetadataOnConflict {
  constraint: AttributeMetadataConstraint,
  update_columns: Array<AttributeMetadataUpdateColumn>,
  where?: Maybe<AttributeMetadataBoolExp>,
}

export interface AttributeMetadataOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  query?: Maybe<OrderBy>,
  text_field?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value_field?: Maybe<OrderBy>,
}

export interface AttributeMetadataPkColumnsInput {
  id: Scalars['String'],
}

export enum AttributeMetadataSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Query = 'query',
  TextField = 'text_field',
  UpdatedAt = 'updated_at',
  ValueField = 'value_field'
}

export interface AttributeMetadataSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  query?: Maybe<Scalars['String']>,
  text_field?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value_field?: Maybe<Scalars['String']>,
}

export enum AttributeMetadataUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Query = 'query',
  TextField = 'text_field',
  UpdatedAt = 'updated_at',
  ValueField = 'value_field'
}

export interface AttributeType {
   __typename?: 'attribute_type',
  created_at: Scalars['timestamptz'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface AttributeTypeAggregate {
   __typename?: 'attribute_type_aggregate',
  aggregate?: Maybe<AttributeTypeAggregateFields>,
  nodes: Array<AttributeType>,
}

export interface AttributeTypeAggregateFields {
   __typename?: 'attribute_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<AttributeTypeMaxFields>,
  min?: Maybe<AttributeTypeMinFields>,
}


export interface AttributeTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<AttributeTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface AttributeTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<AttributeTypeMaxOrderBy>,
  min?: Maybe<AttributeTypeMinOrderBy>,
}

export interface AttributeTypeArrRelInsertInput {
  data: Array<AttributeTypeInsertInput>,
  on_conflict?: Maybe<AttributeTypeOnConflict>,
}

export interface AttributeTypeBoolExp {
  _and?: Maybe<Array<Maybe<AttributeTypeBoolExp>>>,
  _not?: Maybe<AttributeTypeBoolExp>,
  _or?: Maybe<Array<Maybe<AttributeTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum AttributeTypeConstraint {
  AttributeTypeNameKey = 'attribute_type_name_key',
  AttributeTypePkey = 'attribute_type_pkey'
}

export interface AttributeTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AttributeTypeMaxFields {
   __typename?: 'attribute_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AttributeTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AttributeTypeMinFields {
   __typename?: 'attribute_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface AttributeTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AttributeTypeMutationResponse {
   __typename?: 'attribute_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<AttributeType>,
}

export interface AttributeTypeObjRelInsertInput {
  data: AttributeTypeInsertInput,
  on_conflict?: Maybe<AttributeTypeOnConflict>,
}

export interface AttributeTypeOnConflict {
  constraint: AttributeTypeConstraint,
  update_columns: Array<AttributeTypeUpdateColumn>,
  where?: Maybe<AttributeTypeBoolExp>,
}

export interface AttributeTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface AttributeTypePkColumnsInput {
  name: Scalars['String'],
}

export enum AttributeTypeSelectColumn {
  CreatedAt = 'created_at',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface AttributeTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum AttributeTypeUpdateColumn {
  CreatedAt = 'created_at',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface BankAccount {
   __typename?: 'bank_account',
  account_holder: Scalars['String'],
  account_name?: Maybe<Scalars['String']>,
  account_number: Scalars['String'],
  address?: Maybe<Scalars['String']>,
  branch?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  country?: Maybe<Country>,
  country_id?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  id: Scalars['String'],
  intermediary?: Maybe<IntermediaryBank>,
  intermediary_id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  payments: Array<Payment>,
  payments_aggregate: PaymentAggregate,
  postal_code?: Maybe<Scalars['String']>,
  stakeholder: Stakeholder,
  stakeholder_id: Scalars['String'],
  state?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
}


export interface BankAccountPaymentsArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface BankAccountPaymentsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}

export interface BankAccountAggregate {
   __typename?: 'bank_account_aggregate',
  aggregate?: Maybe<BankAccountAggregateFields>,
  nodes: Array<BankAccount>,
}

export interface BankAccountAggregateFields {
   __typename?: 'bank_account_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<BankAccountMaxFields>,
  min?: Maybe<BankAccountMinFields>,
}


export interface BankAccountAggregateFieldsCountArgs {
  columns?: Maybe<Array<BankAccountSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface BankAccountAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<BankAccountMaxOrderBy>,
  min?: Maybe<BankAccountMinOrderBy>,
}

export interface BankAccountArrRelInsertInput {
  data: Array<BankAccountInsertInput>,
  on_conflict?: Maybe<BankAccountOnConflict>,
}

export interface BankAccountBoolExp {
  _and?: Maybe<Array<Maybe<BankAccountBoolExp>>>,
  _not?: Maybe<BankAccountBoolExp>,
  _or?: Maybe<Array<Maybe<BankAccountBoolExp>>>,
  account_holder?: Maybe<StringComparisonExp>,
  account_name?: Maybe<StringComparisonExp>,
  account_number?: Maybe<StringComparisonExp>,
  address?: Maybe<StringComparisonExp>,
  branch?: Maybe<StringComparisonExp>,
  city?: Maybe<StringComparisonExp>,
  code?: Maybe<StringComparisonExp>,
  country?: Maybe<CountryBoolExp>,
  country_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  intermediary?: Maybe<IntermediaryBankBoolExp>,
  intermediary_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  payments?: Maybe<PaymentBoolExp>,
  postal_code?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  state?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum BankAccountConstraint {
  BankAccountIntermediaryIdKey = 'bank_account_intermediary_id_key',
  BankAccountPkey = 'bank_account_pkey'
}

export interface BankAccountInsertInput {
  account_holder?: Maybe<Scalars['String']>,
  account_name?: Maybe<Scalars['String']>,
  account_number?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  branch?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  country?: Maybe<CountryObjRelInsertInput>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  intermediary?: Maybe<IntermediaryBankObjRelInsertInput>,
  intermediary_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  payments?: Maybe<PaymentArrRelInsertInput>,
  postal_code?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  stakeholder_id?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BankAccountMaxFields {
   __typename?: 'bank_account_max_fields',
  account_holder?: Maybe<Scalars['String']>,
  account_name?: Maybe<Scalars['String']>,
  account_number?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  branch?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  intermediary_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BankAccountMaxOrderBy {
  account_holder?: Maybe<OrderBy>,
  account_name?: Maybe<OrderBy>,
  account_number?: Maybe<OrderBy>,
  address?: Maybe<OrderBy>,
  branch?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  intermediary_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  postal_code?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  state?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BankAccountMinFields {
   __typename?: 'bank_account_min_fields',
  account_holder?: Maybe<Scalars['String']>,
  account_name?: Maybe<Scalars['String']>,
  account_number?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  branch?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  intermediary_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BankAccountMinOrderBy {
  account_holder?: Maybe<OrderBy>,
  account_name?: Maybe<OrderBy>,
  account_number?: Maybe<OrderBy>,
  address?: Maybe<OrderBy>,
  branch?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  intermediary_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  postal_code?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  state?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BankAccountMutationResponse {
   __typename?: 'bank_account_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<BankAccount>,
}

export interface BankAccountObjRelInsertInput {
  data: BankAccountInsertInput,
  on_conflict?: Maybe<BankAccountOnConflict>,
}

export interface BankAccountOnConflict {
  constraint: BankAccountConstraint,
  update_columns: Array<BankAccountUpdateColumn>,
  where?: Maybe<BankAccountBoolExp>,
}

export interface BankAccountOrderBy {
  account_holder?: Maybe<OrderBy>,
  account_name?: Maybe<OrderBy>,
  account_number?: Maybe<OrderBy>,
  address?: Maybe<OrderBy>,
  branch?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  code?: Maybe<OrderBy>,
  country?: Maybe<CountryOrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  intermediary?: Maybe<IntermediaryBankOrderBy>,
  intermediary_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  payments_aggregate?: Maybe<PaymentAggregateOrderBy>,
  postal_code?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  state?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BankAccountPkColumnsInput {
  id: Scalars['String'],
}

export enum BankAccountSelectColumn {
  AccountHolder = 'account_holder',
  AccountName = 'account_name',
  AccountNumber = 'account_number',
  Address = 'address',
  Branch = 'branch',
  City = 'city',
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  IntermediaryId = 'intermediary_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  PostalCode = 'postal_code',
  StakeholderId = 'stakeholder_id',
  State = 'state',
  UpdatedAt = 'updated_at'
}

export interface BankAccountSetInput {
  account_holder?: Maybe<Scalars['String']>,
  account_name?: Maybe<Scalars['String']>,
  account_number?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  branch?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  intermediary_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum BankAccountUpdateColumn {
  AccountHolder = 'account_holder',
  AccountName = 'account_name',
  AccountNumber = 'account_number',
  Address = 'address',
  Branch = 'branch',
  City = 'city',
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  IntermediaryId = 'intermediary_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  PostalCode = 'postal_code',
  StakeholderId = 'stakeholder_id',
  State = 'state',
  UpdatedAt = 'updated_at'
}


export interface BigintComparisonExp {
  _eq?: Maybe<Scalars['bigint']>,
  _gt?: Maybe<Scalars['bigint']>,
  _gte?: Maybe<Scalars['bigint']>,
  _in?: Maybe<Array<Scalars['bigint']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['bigint']>,
  _lte?: Maybe<Scalars['bigint']>,
  _neq?: Maybe<Scalars['bigint']>,
  _nin?: Maybe<Array<Scalars['bigint']>>,
}

export interface BookType {
   __typename?: 'book_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface BookTypeAggregate {
   __typename?: 'book_type_aggregate',
  aggregate?: Maybe<BookTypeAggregateFields>,
  nodes: Array<BookType>,
}

export interface BookTypeAggregateFields {
   __typename?: 'book_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<BookTypeMaxFields>,
  min?: Maybe<BookTypeMinFields>,
}


export interface BookTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<BookTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface BookTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<BookTypeMaxOrderBy>,
  min?: Maybe<BookTypeMinOrderBy>,
}

export interface BookTypeArrRelInsertInput {
  data: Array<BookTypeInsertInput>,
  on_conflict?: Maybe<BookTypeOnConflict>,
}

export interface BookTypeBoolExp {
  _and?: Maybe<Array<Maybe<BookTypeBoolExp>>>,
  _not?: Maybe<BookTypeBoolExp>,
  _or?: Maybe<Array<Maybe<BookTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum BookTypeConstraint {
  BookTypeNameKey = 'book_type_name_key',
  BookTypePkey = 'book_type_pkey'
}

export interface BookTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BookTypeMaxFields {
   __typename?: 'book_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BookTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BookTypeMinFields {
   __typename?: 'book_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface BookTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BookTypeMutationResponse {
   __typename?: 'book_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<BookType>,
}

export interface BookTypeObjRelInsertInput {
  data: BookTypeInsertInput,
  on_conflict?: Maybe<BookTypeOnConflict>,
}

export interface BookTypeOnConflict {
  constraint: BookTypeConstraint,
  update_columns: Array<BookTypeUpdateColumn>,
  where?: Maybe<BookTypeBoolExp>,
}

export interface BookTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface BookTypePkColumnsInput {
  id: Scalars['String'],
}

export enum BookTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface BookTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum BookTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface BooleanComparisonExp {
  _eq?: Maybe<Scalars['Boolean']>,
  _gt?: Maybe<Scalars['Boolean']>,
  _gte?: Maybe<Scalars['Boolean']>,
  _in?: Maybe<Array<Scalars['Boolean']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['Boolean']>,
  _lte?: Maybe<Scalars['Boolean']>,
  _neq?: Maybe<Scalars['Boolean']>,
  _nin?: Maybe<Array<Scalars['Boolean']>>,
}

export interface ChargeUnit {
   __typename?: 'charge_unit',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ChargeUnitAggregate {
   __typename?: 'charge_unit_aggregate',
  aggregate?: Maybe<ChargeUnitAggregateFields>,
  nodes: Array<ChargeUnit>,
}

export interface ChargeUnitAggregateFields {
   __typename?: 'charge_unit_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ChargeUnitMaxFields>,
  min?: Maybe<ChargeUnitMinFields>,
}


export interface ChargeUnitAggregateFieldsCountArgs {
  columns?: Maybe<Array<ChargeUnitSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ChargeUnitAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ChargeUnitMaxOrderBy>,
  min?: Maybe<ChargeUnitMinOrderBy>,
}

export interface ChargeUnitArrRelInsertInput {
  data: Array<ChargeUnitInsertInput>,
  on_conflict?: Maybe<ChargeUnitOnConflict>,
}

export interface ChargeUnitBoolExp {
  _and?: Maybe<Array<Maybe<ChargeUnitBoolExp>>>,
  _not?: Maybe<ChargeUnitBoolExp>,
  _or?: Maybe<Array<Maybe<ChargeUnitBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ChargeUnitConstraint {
  ChargeUnitNameKey = 'charge_unit_name_key',
  ChargeUnitPkey = 'charge_unit_pkey'
}

export interface ChargeUnitInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ChargeUnitMaxFields {
   __typename?: 'charge_unit_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ChargeUnitMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ChargeUnitMinFields {
   __typename?: 'charge_unit_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ChargeUnitMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ChargeUnitMutationResponse {
   __typename?: 'charge_unit_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ChargeUnit>,
}

export interface ChargeUnitObjRelInsertInput {
  data: ChargeUnitInsertInput,
  on_conflict?: Maybe<ChargeUnitOnConflict>,
}

export interface ChargeUnitOnConflict {
  constraint: ChargeUnitConstraint,
  update_columns: Array<ChargeUnitUpdateColumn>,
  where?: Maybe<ChargeUnitBoolExp>,
}

export interface ChargeUnitOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ChargeUnitPkColumnsInput {
  id: Scalars['String'],
}

export enum ChargeUnitSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ChargeUnitSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ChargeUnitUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface CountriesToPhoneCodes {
   __typename?: 'countries_to_phone_codes',
  code: Scalars['String'],
  country: Country,
  country_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  updated_at: Scalars['timestamptz'],
}

export interface CountriesToPhoneCodesAggregate {
   __typename?: 'countries_to_phone_codes_aggregate',
  aggregate?: Maybe<CountriesToPhoneCodesAggregateFields>,
  nodes: Array<CountriesToPhoneCodes>,
}

export interface CountriesToPhoneCodesAggregateFields {
   __typename?: 'countries_to_phone_codes_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<CountriesToPhoneCodesMaxFields>,
  min?: Maybe<CountriesToPhoneCodesMinFields>,
}


export interface CountriesToPhoneCodesAggregateFieldsCountArgs {
  columns?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface CountriesToPhoneCodesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<CountriesToPhoneCodesMaxOrderBy>,
  min?: Maybe<CountriesToPhoneCodesMinOrderBy>,
}

export interface CountriesToPhoneCodesArrRelInsertInput {
  data: Array<CountriesToPhoneCodesInsertInput>,
  on_conflict?: Maybe<CountriesToPhoneCodesOnConflict>,
}

export interface CountriesToPhoneCodesBoolExp {
  _and?: Maybe<Array<Maybe<CountriesToPhoneCodesBoolExp>>>,
  _not?: Maybe<CountriesToPhoneCodesBoolExp>,
  _or?: Maybe<Array<Maybe<CountriesToPhoneCodesBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  country?: Maybe<CountryBoolExp>,
  country_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum CountriesToPhoneCodesConstraint {
  CountryPhoneCodePkey = 'country_phone_code_pkey'
}

export interface CountriesToPhoneCodesInsertInput {
  code?: Maybe<Scalars['String']>,
  country?: Maybe<CountryObjRelInsertInput>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountriesToPhoneCodesMaxFields {
   __typename?: 'countries_to_phone_codes_max_fields',
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountriesToPhoneCodesMaxOrderBy {
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountriesToPhoneCodesMinFields {
   __typename?: 'countries_to_phone_codes_min_fields',
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountriesToPhoneCodesMinOrderBy {
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountriesToPhoneCodesMutationResponse {
   __typename?: 'countries_to_phone_codes_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<CountriesToPhoneCodes>,
}

export interface CountriesToPhoneCodesObjRelInsertInput {
  data: CountriesToPhoneCodesInsertInput,
  on_conflict?: Maybe<CountriesToPhoneCodesOnConflict>,
}

export interface CountriesToPhoneCodesOnConflict {
  constraint: CountriesToPhoneCodesConstraint,
  update_columns: Array<CountriesToPhoneCodesUpdateColumn>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>,
}

export interface CountriesToPhoneCodesOrderBy {
  code?: Maybe<OrderBy>,
  country?: Maybe<CountryOrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountriesToPhoneCodesPkColumnsInput {
  code: Scalars['String'],
  country_id: Scalars['String'],
}

export enum CountriesToPhoneCodesSelectColumn {
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export interface CountriesToPhoneCodesSetInput {
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum CountriesToPhoneCodesUpdateColumn {
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export interface Country {
   __typename?: 'country',
  created_at: Scalars['timestamptz'],
  flag?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  name: Scalars['String'],
  phone_codes: Array<CountriesToPhoneCodes>,
  phone_codes_aggregate: CountriesToPhoneCodesAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface CountryPhoneCodesArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}


export interface CountryPhoneCodesAggregateArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}

export interface CountryAggregate {
   __typename?: 'country_aggregate',
  aggregate?: Maybe<CountryAggregateFields>,
  nodes: Array<Country>,
}

export interface CountryAggregateFields {
   __typename?: 'country_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<CountryMaxFields>,
  min?: Maybe<CountryMinFields>,
}


export interface CountryAggregateFieldsCountArgs {
  columns?: Maybe<Array<CountrySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface CountryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<CountryMaxOrderBy>,
  min?: Maybe<CountryMinOrderBy>,
}

export interface CountryArrRelInsertInput {
  data: Array<CountryInsertInput>,
  on_conflict?: Maybe<CountryOnConflict>,
}

export interface CountryBoolExp {
  _and?: Maybe<Array<Maybe<CountryBoolExp>>>,
  _not?: Maybe<CountryBoolExp>,
  _or?: Maybe<Array<Maybe<CountryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  flag?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  phone_codes?: Maybe<CountriesToPhoneCodesBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum CountryConstraint {
  CountryNameKey = 'country_name_key',
  CountryPkey = 'country_pkey'
}

export interface CountryInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phone_codes?: Maybe<CountriesToPhoneCodesArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountryMaxFields {
   __typename?: 'country_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountryMinFields {
   __typename?: 'country_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CountryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountryMutationResponse {
   __typename?: 'country_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Country>,
}

export interface CountryObjRelInsertInput {
  data: CountryInsertInput,
  on_conflict?: Maybe<CountryOnConflict>,
}

export interface CountryOnConflict {
  constraint: CountryConstraint,
  update_columns: Array<CountryUpdateColumn>,
  where?: Maybe<CountryBoolExp>,
}

export interface CountryOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  phone_codes_aggregate?: Maybe<CountriesToPhoneCodesAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CountryPkColumnsInput {
  id: Scalars['String'],
}

export enum CountrySelectColumn {
  CreatedAt = 'created_at',
  Flag = 'flag',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface CountrySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum CountryUpdateColumn {
  CreatedAt = 'created_at',
  Flag = 'flag',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface CurrenciesToOrganizations {
   __typename?: 'currencies_to_organizations',
  created_at: Scalars['timestamptz'],
  currency: Currency,
  currency_id: Scalars['String'],
  is_enabled: Scalars['Boolean'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  updated_by?: Maybe<Stakeholder>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface CurrenciesToOrganizationsAggregate {
   __typename?: 'currencies_to_organizations_aggregate',
  aggregate?: Maybe<CurrenciesToOrganizationsAggregateFields>,
  nodes: Array<CurrenciesToOrganizations>,
}

export interface CurrenciesToOrganizationsAggregateFields {
   __typename?: 'currencies_to_organizations_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<CurrenciesToOrganizationsMaxFields>,
  min?: Maybe<CurrenciesToOrganizationsMinFields>,
}


export interface CurrenciesToOrganizationsAggregateFieldsCountArgs {
  columns?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface CurrenciesToOrganizationsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<CurrenciesToOrganizationsMaxOrderBy>,
  min?: Maybe<CurrenciesToOrganizationsMinOrderBy>,
}

export interface CurrenciesToOrganizationsArrRelInsertInput {
  data: Array<CurrenciesToOrganizationsInsertInput>,
  on_conflict?: Maybe<CurrenciesToOrganizationsOnConflict>,
}

export interface CurrenciesToOrganizationsBoolExp {
  _and?: Maybe<Array<Maybe<CurrenciesToOrganizationsBoolExp>>>,
  _not?: Maybe<CurrenciesToOrganizationsBoolExp>,
  _or?: Maybe<Array<Maybe<CurrenciesToOrganizationsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  updated_by?: Maybe<StakeholderBoolExp>,
  updated_by_id?: Maybe<StringComparisonExp>,
}

export enum CurrenciesToOrganizationsConstraint {
  OrganizationsToCurrenciesPkey = 'organizations_to_currencies_pkey'
}

export interface CurrenciesToOrganizationsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by?: Maybe<StakeholderObjRelInsertInput>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface CurrenciesToOrganizationsMaxFields {
   __typename?: 'currencies_to_organizations_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface CurrenciesToOrganizationsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface CurrenciesToOrganizationsMinFields {
   __typename?: 'currencies_to_organizations_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface CurrenciesToOrganizationsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface CurrenciesToOrganizationsMutationResponse {
   __typename?: 'currencies_to_organizations_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<CurrenciesToOrganizations>,
}

export interface CurrenciesToOrganizationsObjRelInsertInput {
  data: CurrenciesToOrganizationsInsertInput,
  on_conflict?: Maybe<CurrenciesToOrganizationsOnConflict>,
}

export interface CurrenciesToOrganizationsOnConflict {
  constraint: CurrenciesToOrganizationsConstraint,
  update_columns: Array<CurrenciesToOrganizationsUpdateColumn>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>,
}

export interface CurrenciesToOrganizationsOrderBy {
  created_at?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by?: Maybe<StakeholderOrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface CurrenciesToOrganizationsPkColumnsInput {
  currency_id: Scalars['String'],
  organization_id: Scalars['String'],
}

export enum CurrenciesToOrganizationsSelectColumn {
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  IsEnabled = 'is_enabled',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id'
}

export interface CurrenciesToOrganizationsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export enum CurrenciesToOrganizationsUpdateColumn {
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  IsEnabled = 'is_enabled',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id'
}

export interface Currency {
   __typename?: 'currency',
  code: Scalars['String'],
  created_at: Scalars['timestamptz'],
  currencies_to_organizations: Array<CurrenciesToOrganizations>,
  currencies_to_organizations_aggregate: CurrenciesToOrganizationsAggregate,
  decimals: Scalars['Int'],
  id: Scalars['String'],
  name: Scalars['String'],
  number: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface CurrencyCurrenciesToOrganizationsArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface CurrencyCurrenciesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}

export interface CurrencyAggregate {
   __typename?: 'currency_aggregate',
  aggregate?: Maybe<CurrencyAggregateFields>,
  nodes: Array<Currency>,
}

export interface CurrencyAggregateFields {
   __typename?: 'currency_aggregate_fields',
  avg?: Maybe<CurrencyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<CurrencyMaxFields>,
  min?: Maybe<CurrencyMinFields>,
  stddev?: Maybe<CurrencyStddevFields>,
  stddev_pop?: Maybe<CurrencyStddevPopFields>,
  stddev_samp?: Maybe<CurrencyStddevSampFields>,
  sum?: Maybe<CurrencySumFields>,
  var_pop?: Maybe<CurrencyVarPopFields>,
  var_samp?: Maybe<CurrencyVarSampFields>,
  variance?: Maybe<CurrencyVarianceFields>,
}


export interface CurrencyAggregateFieldsCountArgs {
  columns?: Maybe<Array<CurrencySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface CurrencyAggregateOrderBy {
  avg?: Maybe<CurrencyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<CurrencyMaxOrderBy>,
  min?: Maybe<CurrencyMinOrderBy>,
  stddev?: Maybe<CurrencyStddevOrderBy>,
  stddev_pop?: Maybe<CurrencyStddevPopOrderBy>,
  stddev_samp?: Maybe<CurrencyStddevSampOrderBy>,
  sum?: Maybe<CurrencySumOrderBy>,
  var_pop?: Maybe<CurrencyVarPopOrderBy>,
  var_samp?: Maybe<CurrencyVarSampOrderBy>,
  variance?: Maybe<CurrencyVarianceOrderBy>,
}

export interface CurrencyArrRelInsertInput {
  data: Array<CurrencyInsertInput>,
  on_conflict?: Maybe<CurrencyOnConflict>,
}

export interface CurrencyAvgFields {
   __typename?: 'currency_avg_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyAvgOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyBoolExp {
  _and?: Maybe<Array<Maybe<CurrencyBoolExp>>>,
  _not?: Maybe<CurrencyBoolExp>,
  _or?: Maybe<Array<Maybe<CurrencyBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currencies_to_organizations?: Maybe<CurrenciesToOrganizationsBoolExp>,
  decimals?: Maybe<IntComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum CurrencyConstraint {
  CurrencyCodeKey = 'currency_code_key',
  CurrencyPkey = 'currency_pkey'
}

export interface CurrencyGridView {
   __typename?: 'currency_grid_view',
  code?: Maybe<Scalars['String']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface CurrencyGridViewAggregate {
   __typename?: 'currency_grid_view_aggregate',
  aggregate?: Maybe<CurrencyGridViewAggregateFields>,
  nodes: Array<CurrencyGridView>,
}

export interface CurrencyGridViewAggregateFields {
   __typename?: 'currency_grid_view_aggregate_fields',
  avg?: Maybe<CurrencyGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<CurrencyGridViewMaxFields>,
  min?: Maybe<CurrencyGridViewMinFields>,
  stddev?: Maybe<CurrencyGridViewStddevFields>,
  stddev_pop?: Maybe<CurrencyGridViewStddevPopFields>,
  stddev_samp?: Maybe<CurrencyGridViewStddevSampFields>,
  sum?: Maybe<CurrencyGridViewSumFields>,
  var_pop?: Maybe<CurrencyGridViewVarPopFields>,
  var_samp?: Maybe<CurrencyGridViewVarSampFields>,
  variance?: Maybe<CurrencyGridViewVarianceFields>,
}


export interface CurrencyGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<CurrencyGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface CurrencyGridViewAggregateOrderBy {
  avg?: Maybe<CurrencyGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<CurrencyGridViewMaxOrderBy>,
  min?: Maybe<CurrencyGridViewMinOrderBy>,
  stddev?: Maybe<CurrencyGridViewStddevOrderBy>,
  stddev_pop?: Maybe<CurrencyGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<CurrencyGridViewStddevSampOrderBy>,
  sum?: Maybe<CurrencyGridViewSumOrderBy>,
  var_pop?: Maybe<CurrencyGridViewVarPopOrderBy>,
  var_samp?: Maybe<CurrencyGridViewVarSampOrderBy>,
  variance?: Maybe<CurrencyGridViewVarianceOrderBy>,
}

export interface CurrencyGridViewAvgFields {
   __typename?: 'currency_grid_view_avg_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewAvgOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewBoolExp {
  _and?: Maybe<Array<Maybe<CurrencyGridViewBoolExp>>>,
  _not?: Maybe<CurrencyGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<CurrencyGridViewBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  decimals?: Maybe<IntComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
}

export interface CurrencyGridViewMaxFields {
   __typename?: 'currency_grid_view_max_fields',
  code?: Maybe<Scalars['String']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface CurrencyGridViewMaxOrderBy {
  code?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export interface CurrencyGridViewMinFields {
   __typename?: 'currency_grid_view_min_fields',
  code?: Maybe<Scalars['String']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface CurrencyGridViewMinOrderBy {
  code?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export interface CurrencyGridViewOrderBy {
  code?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export enum CurrencyGridViewSelectColumn {
  Code = 'code',
  Decimals = 'decimals',
  Id = 'id',
  IsEnabled = 'is_enabled',
  Name = 'name',
  OrganizationId = 'organization_id'
}

export interface CurrencyGridViewStddevFields {
   __typename?: 'currency_grid_view_stddev_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewStddevOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewStddevPopFields {
   __typename?: 'currency_grid_view_stddev_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewStddevPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewStddevSampFields {
   __typename?: 'currency_grid_view_stddev_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewStddevSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewSumFields {
   __typename?: 'currency_grid_view_sum_fields',
  decimals?: Maybe<Scalars['Int']>,
}

export interface CurrencyGridViewSumOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewVarPopFields {
   __typename?: 'currency_grid_view_var_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewVarPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewVarSampFields {
   __typename?: 'currency_grid_view_var_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewVarSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyGridViewVarianceFields {
   __typename?: 'currency_grid_view_variance_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyGridViewVarianceOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyIncInput {
  decimals?: Maybe<Scalars['Int']>,
}

export interface CurrencyInsertInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currencies_to_organizations?: Maybe<CurrenciesToOrganizationsArrRelInsertInput>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CurrencyMaxFields {
   __typename?: 'currency_max_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CurrencyMaxOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CurrencyMinFields {
   __typename?: 'currency_min_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CurrencyMinOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CurrencyMutationResponse {
   __typename?: 'currency_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Currency>,
}

export interface CurrencyObjRelInsertInput {
  data: CurrencyInsertInput,
  on_conflict?: Maybe<CurrencyOnConflict>,
}

export interface CurrencyOnConflict {
  constraint: CurrencyConstraint,
  update_columns: Array<CurrencyUpdateColumn>,
  where?: Maybe<CurrencyBoolExp>,
}

export interface CurrencyOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currencies_to_organizations_aggregate?: Maybe<CurrenciesToOrganizationsAggregateOrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface CurrencyPkColumnsInput {
  id: Scalars['String'],
}

export enum CurrencySelectColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Number = 'number',
  UpdatedAt = 'updated_at'
}

export interface CurrencySetInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface CurrencyStddevFields {
   __typename?: 'currency_stddev_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyStddevOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyStddevPopFields {
   __typename?: 'currency_stddev_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyStddevPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyStddevSampFields {
   __typename?: 'currency_stddev_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyStddevSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencySumFields {
   __typename?: 'currency_sum_fields',
  decimals?: Maybe<Scalars['Int']>,
}

export interface CurrencySumOrderBy {
  decimals?: Maybe<OrderBy>,
}

export enum CurrencyUpdateColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Number = 'number',
  UpdatedAt = 'updated_at'
}

export interface CurrencyVarPopFields {
   __typename?: 'currency_var_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyVarPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyVarSampFields {
   __typename?: 'currency_var_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyVarSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface CurrencyVarianceFields {
   __typename?: 'currency_variance_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface CurrencyVarianceOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummary {
   __typename?: 'daily_account_revenue_summary',
  account_id: Scalars['String'],
  b_book_close_trade_revenue: Scalars['numeric'],
  b_book_open_trade_revenue: Scalars['numeric'],
  commission_revenue: Scalars['numeric'],
  created_at: Scalars['timestamptz'],
  currency_conversion_revenue: Scalars['numeric'],
  currency_id: Scalars['String'],
  id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_commission_rebate: Scalars['numeric'],
  partner_earnings: Scalars['numeric'],
  partner_spread_rebate: Scalars['numeric'],
  spread_markup_revenue: Scalars['numeric'],
  summary_date: Scalars['date'],
  swap_revenue: Scalars['numeric'],
  transaction_fee_revenue: Scalars['numeric'],
  updated_at: Scalars['timestamptz'],
}

export interface DailyAccountRevenueSummaryAggregate {
   __typename?: 'daily_account_revenue_summary_aggregate',
  aggregate?: Maybe<DailyAccountRevenueSummaryAggregateFields>,
  nodes: Array<DailyAccountRevenueSummary>,
}

export interface DailyAccountRevenueSummaryAggregateFields {
   __typename?: 'daily_account_revenue_summary_aggregate_fields',
  avg?: Maybe<DailyAccountRevenueSummaryAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<DailyAccountRevenueSummaryMaxFields>,
  min?: Maybe<DailyAccountRevenueSummaryMinFields>,
  stddev?: Maybe<DailyAccountRevenueSummaryStddevFields>,
  stddev_pop?: Maybe<DailyAccountRevenueSummaryStddevPopFields>,
  stddev_samp?: Maybe<DailyAccountRevenueSummaryStddevSampFields>,
  sum?: Maybe<DailyAccountRevenueSummarySumFields>,
  var_pop?: Maybe<DailyAccountRevenueSummaryVarPopFields>,
  var_samp?: Maybe<DailyAccountRevenueSummaryVarSampFields>,
  variance?: Maybe<DailyAccountRevenueSummaryVarianceFields>,
}


export interface DailyAccountRevenueSummaryAggregateFieldsCountArgs {
  columns?: Maybe<Array<DailyAccountRevenueSummarySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface DailyAccountRevenueSummaryAggregateOrderBy {
  avg?: Maybe<DailyAccountRevenueSummaryAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<DailyAccountRevenueSummaryMaxOrderBy>,
  min?: Maybe<DailyAccountRevenueSummaryMinOrderBy>,
  stddev?: Maybe<DailyAccountRevenueSummaryStddevOrderBy>,
  stddev_pop?: Maybe<DailyAccountRevenueSummaryStddevPopOrderBy>,
  stddev_samp?: Maybe<DailyAccountRevenueSummaryStddevSampOrderBy>,
  sum?: Maybe<DailyAccountRevenueSummarySumOrderBy>,
  var_pop?: Maybe<DailyAccountRevenueSummaryVarPopOrderBy>,
  var_samp?: Maybe<DailyAccountRevenueSummaryVarSampOrderBy>,
  variance?: Maybe<DailyAccountRevenueSummaryVarianceOrderBy>,
}

export interface DailyAccountRevenueSummaryArrRelInsertInput {
  data: Array<DailyAccountRevenueSummaryInsertInput>,
  on_conflict?: Maybe<DailyAccountRevenueSummaryOnConflict>,
}

export interface DailyAccountRevenueSummaryAvgFields {
   __typename?: 'daily_account_revenue_summary_avg_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryAvgOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryBoolExp {
  _and?: Maybe<Array<Maybe<DailyAccountRevenueSummaryBoolExp>>>,
  _not?: Maybe<DailyAccountRevenueSummaryBoolExp>,
  _or?: Maybe<Array<Maybe<DailyAccountRevenueSummaryBoolExp>>>,
  account_id?: Maybe<StringComparisonExp>,
  b_book_close_trade_revenue?: Maybe<NumericComparisonExp>,
  b_book_open_trade_revenue?: Maybe<NumericComparisonExp>,
  commission_revenue?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency_conversion_revenue?: Maybe<NumericComparisonExp>,
  currency_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner_commission_rebate?: Maybe<NumericComparisonExp>,
  partner_earnings?: Maybe<NumericComparisonExp>,
  partner_spread_rebate?: Maybe<NumericComparisonExp>,
  spread_markup_revenue?: Maybe<NumericComparisonExp>,
  summary_date?: Maybe<DateComparisonExp>,
  swap_revenue?: Maybe<NumericComparisonExp>,
  transaction_fee_revenue?: Maybe<NumericComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum DailyAccountRevenueSummaryConstraint {
  DailyAccountRevenueSummaryPkey = 'daily_account_revenue_summary_pkey'
}

export interface DailyAccountRevenueSummaryIncInput {
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountRevenueSummaryInsertInput {
  account_id?: Maybe<Scalars['String']>,
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  summary_date?: Maybe<Scalars['date']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface DailyAccountRevenueSummaryMaxFields {
   __typename?: 'daily_account_revenue_summary_max_fields',
  account_id?: Maybe<Scalars['String']>,
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  summary_date?: Maybe<Scalars['date']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface DailyAccountRevenueSummaryMaxOrderBy {
  account_id?: Maybe<OrderBy>,
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryMinFields {
   __typename?: 'daily_account_revenue_summary_min_fields',
  account_id?: Maybe<Scalars['String']>,
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  summary_date?: Maybe<Scalars['date']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface DailyAccountRevenueSummaryMinOrderBy {
  account_id?: Maybe<OrderBy>,
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryMutationResponse {
   __typename?: 'daily_account_revenue_summary_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<DailyAccountRevenueSummary>,
}

export interface DailyAccountRevenueSummaryObjRelInsertInput {
  data: DailyAccountRevenueSummaryInsertInput,
  on_conflict?: Maybe<DailyAccountRevenueSummaryOnConflict>,
}

export interface DailyAccountRevenueSummaryOnConflict {
  constraint: DailyAccountRevenueSummaryConstraint,
  update_columns: Array<DailyAccountRevenueSummaryUpdateColumn>,
  where?: Maybe<DailyAccountRevenueSummaryBoolExp>,
}

export interface DailyAccountRevenueSummaryOrderBy {
  account_id?: Maybe<OrderBy>,
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryPkColumnsInput {
  id: Scalars['String'],
}

export enum DailyAccountRevenueSummarySelectColumn {
  AccountId = 'account_id',
  BBookCloseTradeRevenue = 'b_book_close_trade_revenue',
  BBookOpenTradeRevenue = 'b_book_open_trade_revenue',
  CommissionRevenue = 'commission_revenue',
  CreatedAt = 'created_at',
  CurrencyConversionRevenue = 'currency_conversion_revenue',
  CurrencyId = 'currency_id',
  Id = 'id',
  OrganizationId = 'organization_id',
  PartnerCommissionRebate = 'partner_commission_rebate',
  PartnerEarnings = 'partner_earnings',
  PartnerSpreadRebate = 'partner_spread_rebate',
  SpreadMarkupRevenue = 'spread_markup_revenue',
  SummaryDate = 'summary_date',
  SwapRevenue = 'swap_revenue',
  TransactionFeeRevenue = 'transaction_fee_revenue',
  UpdatedAt = 'updated_at'
}

export interface DailyAccountRevenueSummarySetInput {
  account_id?: Maybe<Scalars['String']>,
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  summary_date?: Maybe<Scalars['date']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface DailyAccountRevenueSummaryStddevFields {
   __typename?: 'daily_account_revenue_summary_stddev_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryStddevOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryStddevPopFields {
   __typename?: 'daily_account_revenue_summary_stddev_pop_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryStddevPopOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryStddevSampFields {
   __typename?: 'daily_account_revenue_summary_stddev_samp_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryStddevSampOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummarySumFields {
   __typename?: 'daily_account_revenue_summary_sum_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['numeric']>,
  b_book_open_trade_revenue?: Maybe<Scalars['numeric']>,
  commission_revenue?: Maybe<Scalars['numeric']>,
  currency_conversion_revenue?: Maybe<Scalars['numeric']>,
  partner_commission_rebate?: Maybe<Scalars['numeric']>,
  partner_earnings?: Maybe<Scalars['numeric']>,
  partner_spread_rebate?: Maybe<Scalars['numeric']>,
  spread_markup_revenue?: Maybe<Scalars['numeric']>,
  swap_revenue?: Maybe<Scalars['numeric']>,
  transaction_fee_revenue?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountRevenueSummarySumOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export enum DailyAccountRevenueSummaryUpdateColumn {
  AccountId = 'account_id',
  BBookCloseTradeRevenue = 'b_book_close_trade_revenue',
  BBookOpenTradeRevenue = 'b_book_open_trade_revenue',
  CommissionRevenue = 'commission_revenue',
  CreatedAt = 'created_at',
  CurrencyConversionRevenue = 'currency_conversion_revenue',
  CurrencyId = 'currency_id',
  Id = 'id',
  OrganizationId = 'organization_id',
  PartnerCommissionRebate = 'partner_commission_rebate',
  PartnerEarnings = 'partner_earnings',
  PartnerSpreadRebate = 'partner_spread_rebate',
  SpreadMarkupRevenue = 'spread_markup_revenue',
  SummaryDate = 'summary_date',
  SwapRevenue = 'swap_revenue',
  TransactionFeeRevenue = 'transaction_fee_revenue',
  UpdatedAt = 'updated_at'
}

export interface DailyAccountRevenueSummaryVarPopFields {
   __typename?: 'daily_account_revenue_summary_var_pop_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryVarPopOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryVarSampFields {
   __typename?: 'daily_account_revenue_summary_var_samp_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryVarSampOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountRevenueSummaryVarianceFields {
   __typename?: 'daily_account_revenue_summary_variance_fields',
  b_book_close_trade_revenue?: Maybe<Scalars['Float']>,
  b_book_open_trade_revenue?: Maybe<Scalars['Float']>,
  commission_revenue?: Maybe<Scalars['Float']>,
  currency_conversion_revenue?: Maybe<Scalars['Float']>,
  partner_commission_rebate?: Maybe<Scalars['Float']>,
  partner_earnings?: Maybe<Scalars['Float']>,
  partner_spread_rebate?: Maybe<Scalars['Float']>,
  spread_markup_revenue?: Maybe<Scalars['Float']>,
  swap_revenue?: Maybe<Scalars['Float']>,
  transaction_fee_revenue?: Maybe<Scalars['Float']>,
}

export interface DailyAccountRevenueSummaryVarianceOrderBy {
  b_book_close_trade_revenue?: Maybe<OrderBy>,
  b_book_open_trade_revenue?: Maybe<OrderBy>,
  commission_revenue?: Maybe<OrderBy>,
  currency_conversion_revenue?: Maybe<OrderBy>,
  partner_commission_rebate?: Maybe<OrderBy>,
  partner_earnings?: Maybe<OrderBy>,
  partner_spread_rebate?: Maybe<OrderBy>,
  spread_markup_revenue?: Maybe<OrderBy>,
  swap_revenue?: Maybe<OrderBy>,
  transaction_fee_revenue?: Maybe<OrderBy>,
}

export interface DailyAccountStatus {
   __typename?: 'daily_account_status',
  account: Account,
  account_id: Scalars['String'],
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  created_at: Scalars['timestamptz'],
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  organization_id: Scalars['String'],
  other_volume?: Maybe<Scalars['numeric']>,
  record_date: Scalars['date'],
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  updated_at: Scalars['timestamptz'],
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusAggregate {
   __typename?: 'daily_account_status_aggregate',
  aggregate?: Maybe<DailyAccountStatusAggregateFields>,
  nodes: Array<DailyAccountStatus>,
}

export interface DailyAccountStatusAggregateFields {
   __typename?: 'daily_account_status_aggregate_fields',
  avg?: Maybe<DailyAccountStatusAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<DailyAccountStatusMaxFields>,
  min?: Maybe<DailyAccountStatusMinFields>,
  stddev?: Maybe<DailyAccountStatusStddevFields>,
  stddev_pop?: Maybe<DailyAccountStatusStddevPopFields>,
  stddev_samp?: Maybe<DailyAccountStatusStddevSampFields>,
  sum?: Maybe<DailyAccountStatusSumFields>,
  var_pop?: Maybe<DailyAccountStatusVarPopFields>,
  var_samp?: Maybe<DailyAccountStatusVarSampFields>,
  variance?: Maybe<DailyAccountStatusVarianceFields>,
}


export interface DailyAccountStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<DailyAccountStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface DailyAccountStatusAggregateOrderBy {
  avg?: Maybe<DailyAccountStatusAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<DailyAccountStatusMaxOrderBy>,
  min?: Maybe<DailyAccountStatusMinOrderBy>,
  stddev?: Maybe<DailyAccountStatusStddevOrderBy>,
  stddev_pop?: Maybe<DailyAccountStatusStddevPopOrderBy>,
  stddev_samp?: Maybe<DailyAccountStatusStddevSampOrderBy>,
  sum?: Maybe<DailyAccountStatusSumOrderBy>,
  var_pop?: Maybe<DailyAccountStatusVarPopOrderBy>,
  var_samp?: Maybe<DailyAccountStatusVarSampOrderBy>,
  variance?: Maybe<DailyAccountStatusVarianceOrderBy>,
}

export interface DailyAccountStatusArrRelInsertInput {
  data: Array<DailyAccountStatusInsertInput>,
  on_conflict?: Maybe<DailyAccountStatusOnConflict>,
}

export interface DailyAccountStatusAvgFields {
   __typename?: 'daily_account_status_avg_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusAvgOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusBoolExp {
  _and?: Maybe<Array<Maybe<DailyAccountStatusBoolExp>>>,
  _not?: Maybe<DailyAccountStatusBoolExp>,
  _or?: Maybe<Array<Maybe<DailyAccountStatusBoolExp>>>,
  account?: Maybe<AccountBoolExp>,
  account_id?: Maybe<StringComparisonExp>,
  balance?: Maybe<NumericComparisonExp>,
  calculated_balance?: Maybe<NumericComparisonExp>,
  calculated_equity?: Maybe<NumericComparisonExp>,
  cfd_volume?: Maybe<NumericComparisonExp>,
  close_profit_and_loss?: Maybe<NumericComparisonExp>,
  close_swap?: Maybe<NumericComparisonExp>,
  close_trade_commission?: Maybe<NumericComparisonExp>,
  commissions_charged?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  credit?: Maybe<NumericComparisonExp>,
  deposit?: Maybe<NumericComparisonExp>,
  dividend_admin_fee?: Maybe<NumericComparisonExp>,
  dividends?: Maybe<NumericComparisonExp>,
  equity?: Maybe<NumericComparisonExp>,
  fee?: Maybe<NumericComparisonExp>,
  floating_profit_and_loss?: Maybe<NumericComparisonExp>,
  forex_volume?: Maybe<NumericComparisonExp>,
  futures_volume?: Maybe<NumericComparisonExp>,
  high_water_mark?: Maybe<NumericComparisonExp>,
  interest?: Maybe<NumericComparisonExp>,
  margin_free?: Maybe<NumericComparisonExp>,
  margin_used?: Maybe<NumericComparisonExp>,
  open_trade_commission?: Maybe<NumericComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  other_volume?: Maybe<NumericComparisonExp>,
  record_date?: Maybe<DateComparisonExp>,
  swaps_charged?: Maybe<NumericComparisonExp>,
  taxes?: Maybe<NumericComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  volume?: Maybe<NumericComparisonExp>,
  volume_in_USD?: Maybe<NumericComparisonExp>,
  withdrawal?: Maybe<NumericComparisonExp>,
}

export enum DailyAccountStatusConstraint {
  DailyAccountStatusAccountIdOrganizationIdRecordDateKey = 'daily_account_status_account_id_organization_id_record_date_key',
  DailyAccountStatusPkey = 'daily_account_status_pkey'
}

export interface DailyAccountStatusIncInput {
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusInsertInput {
  account?: Maybe<AccountObjRelInsertInput>,
  account_id?: Maybe<Scalars['String']>,
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  record_date?: Maybe<Scalars['date']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusMaxFields {
   __typename?: 'daily_account_status_max_fields',
  account_id?: Maybe<Scalars['String']>,
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  record_date?: Maybe<Scalars['date']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusMaxOrderBy {
  account_id?: Maybe<OrderBy>,
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  record_date?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusMinFields {
   __typename?: 'daily_account_status_min_fields',
  account_id?: Maybe<Scalars['String']>,
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  record_date?: Maybe<Scalars['date']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusMinOrderBy {
  account_id?: Maybe<OrderBy>,
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  record_date?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusMutationResponse {
   __typename?: 'daily_account_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<DailyAccountStatus>,
}

export interface DailyAccountStatusObjRelInsertInput {
  data: DailyAccountStatusInsertInput,
  on_conflict?: Maybe<DailyAccountStatusOnConflict>,
}

export interface DailyAccountStatusOnConflict {
  constraint: DailyAccountStatusConstraint,
  update_columns: Array<DailyAccountStatusUpdateColumn>,
  where?: Maybe<DailyAccountStatusBoolExp>,
}

export interface DailyAccountStatusOrderBy {
  account?: Maybe<AccountOrderBy>,
  account_id?: Maybe<OrderBy>,
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  record_date?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusPkColumnsInput {
  account_id: Scalars['String'],
  organization_id: Scalars['String'],
  record_date: Scalars['date'],
}

export enum DailyAccountStatusSelectColumn {
  AccountId = 'account_id',
  Balance = 'balance',
  CalculatedBalance = 'calculated_balance',
  CalculatedEquity = 'calculated_equity',
  CfdVolume = 'cfd_volume',
  CloseProfitAndLoss = 'close_profit_and_loss',
  CloseSwap = 'close_swap',
  CloseTradeCommission = 'close_trade_commission',
  CommissionsCharged = 'commissions_charged',
  CreatedAt = 'created_at',
  Credit = 'credit',
  Deposit = 'deposit',
  DividendAdminFee = 'dividend_admin_fee',
  Dividends = 'dividends',
  Equity = 'equity',
  Fee = 'fee',
  FloatingProfitAndLoss = 'floating_profit_and_loss',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  HighWaterMark = 'high_water_mark',
  Interest = 'interest',
  MarginFree = 'margin_free',
  MarginUsed = 'margin_used',
  OpenTradeCommission = 'open_trade_commission',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  RecordDate = 'record_date',
  SwapsCharged = 'swaps_charged',
  Taxes = 'taxes',
  UpdatedAt = 'updated_at',
  Volume = 'volume',
  VolumeInUsd = 'volume_in_USD',
  Withdrawal = 'withdrawal'
}

export interface DailyAccountStatusSetInput {
  account_id?: Maybe<Scalars['String']>,
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  record_date?: Maybe<Scalars['date']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusStddevFields {
   __typename?: 'daily_account_status_stddev_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusStddevOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusStddevPopFields {
   __typename?: 'daily_account_status_stddev_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusStddevPopOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusStddevSampFields {
   __typename?: 'daily_account_status_stddev_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusStddevSampOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusSumFields {
   __typename?: 'daily_account_status_sum_fields',
  balance?: Maybe<Scalars['numeric']>,
  calculated_balance?: Maybe<Scalars['numeric']>,
  calculated_equity?: Maybe<Scalars['numeric']>,
  cfd_volume?: Maybe<Scalars['numeric']>,
  close_profit_and_loss?: Maybe<Scalars['numeric']>,
  close_swap?: Maybe<Scalars['numeric']>,
  close_trade_commission?: Maybe<Scalars['numeric']>,
  commissions_charged?: Maybe<Scalars['numeric']>,
  credit?: Maybe<Scalars['numeric']>,
  deposit?: Maybe<Scalars['numeric']>,
  dividend_admin_fee?: Maybe<Scalars['numeric']>,
  dividends?: Maybe<Scalars['numeric']>,
  equity?: Maybe<Scalars['numeric']>,
  fee?: Maybe<Scalars['numeric']>,
  floating_profit_and_loss?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  high_water_mark?: Maybe<Scalars['numeric']>,
  interest?: Maybe<Scalars['numeric']>,
  margin_free?: Maybe<Scalars['numeric']>,
  margin_used?: Maybe<Scalars['numeric']>,
  open_trade_commission?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  swaps_charged?: Maybe<Scalars['numeric']>,
  taxes?: Maybe<Scalars['numeric']>,
  volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
  withdrawal?: Maybe<Scalars['numeric']>,
}

export interface DailyAccountStatusSumOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export enum DailyAccountStatusUpdateColumn {
  AccountId = 'account_id',
  Balance = 'balance',
  CalculatedBalance = 'calculated_balance',
  CalculatedEquity = 'calculated_equity',
  CfdVolume = 'cfd_volume',
  CloseProfitAndLoss = 'close_profit_and_loss',
  CloseSwap = 'close_swap',
  CloseTradeCommission = 'close_trade_commission',
  CommissionsCharged = 'commissions_charged',
  CreatedAt = 'created_at',
  Credit = 'credit',
  Deposit = 'deposit',
  DividendAdminFee = 'dividend_admin_fee',
  Dividends = 'dividends',
  Equity = 'equity',
  Fee = 'fee',
  FloatingProfitAndLoss = 'floating_profit_and_loss',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  HighWaterMark = 'high_water_mark',
  Interest = 'interest',
  MarginFree = 'margin_free',
  MarginUsed = 'margin_used',
  OpenTradeCommission = 'open_trade_commission',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  RecordDate = 'record_date',
  SwapsCharged = 'swaps_charged',
  Taxes = 'taxes',
  UpdatedAt = 'updated_at',
  Volume = 'volume',
  VolumeInUsd = 'volume_in_USD',
  Withdrawal = 'withdrawal'
}

export interface DailyAccountStatusVarPopFields {
   __typename?: 'daily_account_status_var_pop_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusVarPopOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusVarSampFields {
   __typename?: 'daily_account_status_var_samp_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusVarSampOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyAccountStatusVarianceFields {
   __typename?: 'daily_account_status_variance_fields',
  balance?: Maybe<Scalars['Float']>,
  calculated_balance?: Maybe<Scalars['Float']>,
  calculated_equity?: Maybe<Scalars['Float']>,
  cfd_volume?: Maybe<Scalars['Float']>,
  close_profit_and_loss?: Maybe<Scalars['Float']>,
  close_swap?: Maybe<Scalars['Float']>,
  close_trade_commission?: Maybe<Scalars['Float']>,
  commissions_charged?: Maybe<Scalars['Float']>,
  credit?: Maybe<Scalars['Float']>,
  deposit?: Maybe<Scalars['Float']>,
  dividend_admin_fee?: Maybe<Scalars['Float']>,
  dividends?: Maybe<Scalars['Float']>,
  equity?: Maybe<Scalars['Float']>,
  fee?: Maybe<Scalars['Float']>,
  floating_profit_and_loss?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  high_water_mark?: Maybe<Scalars['Float']>,
  interest?: Maybe<Scalars['Float']>,
  margin_free?: Maybe<Scalars['Float']>,
  margin_used?: Maybe<Scalars['Float']>,
  open_trade_commission?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  swaps_charged?: Maybe<Scalars['Float']>,
  taxes?: Maybe<Scalars['Float']>,
  volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
  withdrawal?: Maybe<Scalars['Float']>,
}

export interface DailyAccountStatusVarianceOrderBy {
  balance?: Maybe<OrderBy>,
  calculated_balance?: Maybe<OrderBy>,
  calculated_equity?: Maybe<OrderBy>,
  cfd_volume?: Maybe<OrderBy>,
  close_profit_and_loss?: Maybe<OrderBy>,
  close_swap?: Maybe<OrderBy>,
  close_trade_commission?: Maybe<OrderBy>,
  commissions_charged?: Maybe<OrderBy>,
  credit?: Maybe<OrderBy>,
  deposit?: Maybe<OrderBy>,
  dividend_admin_fee?: Maybe<OrderBy>,
  dividends?: Maybe<OrderBy>,
  equity?: Maybe<OrderBy>,
  fee?: Maybe<OrderBy>,
  floating_profit_and_loss?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  high_water_mark?: Maybe<OrderBy>,
  interest?: Maybe<OrderBy>,
  margin_free?: Maybe<OrderBy>,
  margin_used?: Maybe<OrderBy>,
  open_trade_commission?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  swaps_charged?: Maybe<OrderBy>,
  taxes?: Maybe<OrderBy>,
  volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
  withdrawal?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccount {
   __typename?: 'daily_volume_per_account',
  CFD_volume: Scalars['numeric'],
  account_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  forex_volume: Scalars['numeric'],
  futures_volume: Scalars['numeric'],
  id: Scalars['String'],
  nominal_volume: Scalars['numeric'],
  organization_id: Scalars['String'],
  other_volume: Scalars['numeric'],
  platform_volume: Scalars['numeric'],
  profile_id: Scalars['String'],
  summary_date: Scalars['date'],
  volume_in_USD: Scalars['numeric'],
}

export interface DailyVolumePerAccountAggregate {
   __typename?: 'daily_volume_per_account_aggregate',
  aggregate?: Maybe<DailyVolumePerAccountAggregateFields>,
  nodes: Array<DailyVolumePerAccount>,
}

export interface DailyVolumePerAccountAggregateFields {
   __typename?: 'daily_volume_per_account_aggregate_fields',
  avg?: Maybe<DailyVolumePerAccountAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<DailyVolumePerAccountMaxFields>,
  min?: Maybe<DailyVolumePerAccountMinFields>,
  stddev?: Maybe<DailyVolumePerAccountStddevFields>,
  stddev_pop?: Maybe<DailyVolumePerAccountStddevPopFields>,
  stddev_samp?: Maybe<DailyVolumePerAccountStddevSampFields>,
  sum?: Maybe<DailyVolumePerAccountSumFields>,
  var_pop?: Maybe<DailyVolumePerAccountVarPopFields>,
  var_samp?: Maybe<DailyVolumePerAccountVarSampFields>,
  variance?: Maybe<DailyVolumePerAccountVarianceFields>,
}


export interface DailyVolumePerAccountAggregateFieldsCountArgs {
  columns?: Maybe<Array<DailyVolumePerAccountSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface DailyVolumePerAccountAggregateOrderBy {
  avg?: Maybe<DailyVolumePerAccountAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<DailyVolumePerAccountMaxOrderBy>,
  min?: Maybe<DailyVolumePerAccountMinOrderBy>,
  stddev?: Maybe<DailyVolumePerAccountStddevOrderBy>,
  stddev_pop?: Maybe<DailyVolumePerAccountStddevPopOrderBy>,
  stddev_samp?: Maybe<DailyVolumePerAccountStddevSampOrderBy>,
  sum?: Maybe<DailyVolumePerAccountSumOrderBy>,
  var_pop?: Maybe<DailyVolumePerAccountVarPopOrderBy>,
  var_samp?: Maybe<DailyVolumePerAccountVarSampOrderBy>,
  variance?: Maybe<DailyVolumePerAccountVarianceOrderBy>,
}

export interface DailyVolumePerAccountArrRelInsertInput {
  data: Array<DailyVolumePerAccountInsertInput>,
  on_conflict?: Maybe<DailyVolumePerAccountOnConflict>,
}

export interface DailyVolumePerAccountAvgFields {
   __typename?: 'daily_volume_per_account_avg_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountAvgOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountBoolExp {
  CFD_volume?: Maybe<NumericComparisonExp>,
  _and?: Maybe<Array<Maybe<DailyVolumePerAccountBoolExp>>>,
  _not?: Maybe<DailyVolumePerAccountBoolExp>,
  _or?: Maybe<Array<Maybe<DailyVolumePerAccountBoolExp>>>,
  account_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  forex_volume?: Maybe<NumericComparisonExp>,
  futures_volume?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  nominal_volume?: Maybe<NumericComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  other_volume?: Maybe<NumericComparisonExp>,
  platform_volume?: Maybe<NumericComparisonExp>,
  profile_id?: Maybe<StringComparisonExp>,
  summary_date?: Maybe<DateComparisonExp>,
  volume_in_USD?: Maybe<NumericComparisonExp>,
}

export enum DailyVolumePerAccountConstraint {
  DailyVolumePerAccountAccountIdSummaryDateOrganizationI = 'daily_volume_per_account_account_id_summary_date_organization_i',
  DailyVolumePerAccountPkey = 'daily_volume_per_account_pkey'
}

export interface DailyVolumePerAccountIncInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountInsertInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountMaxFields {
   __typename?: 'daily_volume_per_account_max_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountMaxOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountMinFields {
   __typename?: 'daily_volume_per_account_min_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountMinOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountMutationResponse {
   __typename?: 'daily_volume_per_account_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<DailyVolumePerAccount>,
}

export interface DailyVolumePerAccountObjRelInsertInput {
  data: DailyVolumePerAccountInsertInput,
  on_conflict?: Maybe<DailyVolumePerAccountOnConflict>,
}

export interface DailyVolumePerAccountOnConflict {
  constraint: DailyVolumePerAccountConstraint,
  update_columns: Array<DailyVolumePerAccountUpdateColumn>,
  where?: Maybe<DailyVolumePerAccountBoolExp>,
}

export interface DailyVolumePerAccountOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountPkColumnsInput {
  id: Scalars['String'],
}

export enum DailyVolumePerAccountSelectColumn {
  CfdVolume = 'CFD_volume',
  AccountId = 'account_id',
  CreatedAt = 'created_at',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  Id = 'id',
  NominalVolume = 'nominal_volume',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  PlatformVolume = 'platform_volume',
  ProfileId = 'profile_id',
  SummaryDate = 'summary_date',
  VolumeInUsd = 'volume_in_USD'
}

export interface DailyVolumePerAccountSetInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountStddevFields {
   __typename?: 'daily_volume_per_account_stddev_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountStddevOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountStddevPopFields {
   __typename?: 'daily_volume_per_account_stddev_pop_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountStddevPopOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountStddevSampFields {
   __typename?: 'daily_volume_per_account_stddev_samp_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountStddevSampOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountSumFields {
   __typename?: 'daily_volume_per_account_sum_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  nominal_volume?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  platform_volume?: Maybe<Scalars['numeric']>,
  volume_in_USD?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerAccountSumOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export enum DailyVolumePerAccountUpdateColumn {
  CfdVolume = 'CFD_volume',
  AccountId = 'account_id',
  CreatedAt = 'created_at',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  Id = 'id',
  NominalVolume = 'nominal_volume',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  PlatformVolume = 'platform_volume',
  ProfileId = 'profile_id',
  SummaryDate = 'summary_date',
  VolumeInUsd = 'volume_in_USD'
}

export interface DailyVolumePerAccountVarPopFields {
   __typename?: 'daily_volume_per_account_var_pop_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountVarPopOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountVarSampFields {
   __typename?: 'daily_volume_per_account_var_samp_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountVarSampOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerAccountVarianceFields {
   __typename?: 'daily_volume_per_account_variance_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  platform_volume?: Maybe<Scalars['Float']>,
  volume_in_USD?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerAccountVarianceOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  platform_volume?: Maybe<OrderBy>,
  volume_in_USD?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfile {
   __typename?: 'daily_volume_per_profile',
  CFD_volume: Scalars['numeric'],
  created_at: Scalars['timestamptz'],
  forex_volume: Scalars['numeric'],
  futures_volume: Scalars['numeric'],
  id: Scalars['String'],
  nominal_volume_CFD: Scalars['numeric'],
  nominal_volume_forex: Scalars['numeric'],
  nominal_volume_futures: Scalars['numeric'],
  nominal_volume_other: Scalars['numeric'],
  organization_id: Scalars['String'],
  other_volume: Scalars['numeric'],
  profile_id: Scalars['String'],
  summary_date: Scalars['date'],
  volume_in_USD_CFD: Scalars['numeric'],
  volume_in_USD_forex: Scalars['numeric'],
  volume_in_USD_futures: Scalars['numeric'],
  volume_in_USD_other: Scalars['numeric'],
}

export interface DailyVolumePerProfileAggregate {
   __typename?: 'daily_volume_per_profile_aggregate',
  aggregate?: Maybe<DailyVolumePerProfileAggregateFields>,
  nodes: Array<DailyVolumePerProfile>,
}

export interface DailyVolumePerProfileAggregateFields {
   __typename?: 'daily_volume_per_profile_aggregate_fields',
  avg?: Maybe<DailyVolumePerProfileAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<DailyVolumePerProfileMaxFields>,
  min?: Maybe<DailyVolumePerProfileMinFields>,
  stddev?: Maybe<DailyVolumePerProfileStddevFields>,
  stddev_pop?: Maybe<DailyVolumePerProfileStddevPopFields>,
  stddev_samp?: Maybe<DailyVolumePerProfileStddevSampFields>,
  sum?: Maybe<DailyVolumePerProfileSumFields>,
  var_pop?: Maybe<DailyVolumePerProfileVarPopFields>,
  var_samp?: Maybe<DailyVolumePerProfileVarSampFields>,
  variance?: Maybe<DailyVolumePerProfileVarianceFields>,
}


export interface DailyVolumePerProfileAggregateFieldsCountArgs {
  columns?: Maybe<Array<DailyVolumePerProfileSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface DailyVolumePerProfileAggregateOrderBy {
  avg?: Maybe<DailyVolumePerProfileAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<DailyVolumePerProfileMaxOrderBy>,
  min?: Maybe<DailyVolumePerProfileMinOrderBy>,
  stddev?: Maybe<DailyVolumePerProfileStddevOrderBy>,
  stddev_pop?: Maybe<DailyVolumePerProfileStddevPopOrderBy>,
  stddev_samp?: Maybe<DailyVolumePerProfileStddevSampOrderBy>,
  sum?: Maybe<DailyVolumePerProfileSumOrderBy>,
  var_pop?: Maybe<DailyVolumePerProfileVarPopOrderBy>,
  var_samp?: Maybe<DailyVolumePerProfileVarSampOrderBy>,
  variance?: Maybe<DailyVolumePerProfileVarianceOrderBy>,
}

export interface DailyVolumePerProfileArrRelInsertInput {
  data: Array<DailyVolumePerProfileInsertInput>,
  on_conflict?: Maybe<DailyVolumePerProfileOnConflict>,
}

export interface DailyVolumePerProfileAvgFields {
   __typename?: 'daily_volume_per_profile_avg_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileAvgOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileBoolExp {
  CFD_volume?: Maybe<NumericComparisonExp>,
  _and?: Maybe<Array<Maybe<DailyVolumePerProfileBoolExp>>>,
  _not?: Maybe<DailyVolumePerProfileBoolExp>,
  _or?: Maybe<Array<Maybe<DailyVolumePerProfileBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  forex_volume?: Maybe<NumericComparisonExp>,
  futures_volume?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  nominal_volume_CFD?: Maybe<NumericComparisonExp>,
  nominal_volume_forex?: Maybe<NumericComparisonExp>,
  nominal_volume_futures?: Maybe<NumericComparisonExp>,
  nominal_volume_other?: Maybe<NumericComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  other_volume?: Maybe<NumericComparisonExp>,
  profile_id?: Maybe<StringComparisonExp>,
  summary_date?: Maybe<DateComparisonExp>,
  volume_in_USD_CFD?: Maybe<NumericComparisonExp>,
  volume_in_USD_forex?: Maybe<NumericComparisonExp>,
  volume_in_USD_futures?: Maybe<NumericComparisonExp>,
  volume_in_USD_other?: Maybe<NumericComparisonExp>,
}

export enum DailyVolumePerProfileConstraint {
  DailyVolumePerProfilePkey = 'daily_volume_per_profile_pkey',
  DailyVolumePerProfileSummaryDateProfileIdOrganizationI = 'daily_volume_per_profile_summary_date_profile_id_organization_i'
}

export interface DailyVolumePerProfileIncInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileInsertInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileMaxFields {
   __typename?: 'daily_volume_per_profile_max_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileMaxOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileMinFields {
   __typename?: 'daily_volume_per_profile_min_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileMinOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileMutationResponse {
   __typename?: 'daily_volume_per_profile_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<DailyVolumePerProfile>,
}

export interface DailyVolumePerProfileObjRelInsertInput {
  data: DailyVolumePerProfileInsertInput,
  on_conflict?: Maybe<DailyVolumePerProfileOnConflict>,
}

export interface DailyVolumePerProfileOnConflict {
  constraint: DailyVolumePerProfileConstraint,
  update_columns: Array<DailyVolumePerProfileUpdateColumn>,
  where?: Maybe<DailyVolumePerProfileBoolExp>,
}

export interface DailyVolumePerProfileOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  summary_date?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfilePkColumnsInput {
  id: Scalars['String'],
}

export enum DailyVolumePerProfileSelectColumn {
  CfdVolume = 'CFD_volume',
  CreatedAt = 'created_at',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  Id = 'id',
  NominalVolumeCfd = 'nominal_volume_CFD',
  NominalVolumeForex = 'nominal_volume_forex',
  NominalVolumeFutures = 'nominal_volume_futures',
  NominalVolumeOther = 'nominal_volume_other',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  ProfileId = 'profile_id',
  SummaryDate = 'summary_date',
  VolumeInUsdCfd = 'volume_in_USD_CFD',
  VolumeInUsdForex = 'volume_in_USD_forex',
  VolumeInUsdFutures = 'volume_in_USD_futures',
  VolumeInUsdOther = 'volume_in_USD_other'
}

export interface DailyVolumePerProfileSetInput {
  CFD_volume?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  other_volume?: Maybe<Scalars['numeric']>,
  profile_id?: Maybe<Scalars['String']>,
  summary_date?: Maybe<Scalars['date']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileStddevFields {
   __typename?: 'daily_volume_per_profile_stddev_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileStddevOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileStddevPopFields {
   __typename?: 'daily_volume_per_profile_stddev_pop_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileStddevPopOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileStddevSampFields {
   __typename?: 'daily_volume_per_profile_stddev_samp_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileStddevSampOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileSumFields {
   __typename?: 'daily_volume_per_profile_sum_fields',
  CFD_volume?: Maybe<Scalars['numeric']>,
  forex_volume?: Maybe<Scalars['numeric']>,
  futures_volume?: Maybe<Scalars['numeric']>,
  nominal_volume_CFD?: Maybe<Scalars['numeric']>,
  nominal_volume_forex?: Maybe<Scalars['numeric']>,
  nominal_volume_futures?: Maybe<Scalars['numeric']>,
  nominal_volume_other?: Maybe<Scalars['numeric']>,
  other_volume?: Maybe<Scalars['numeric']>,
  volume_in_USD_CFD?: Maybe<Scalars['numeric']>,
  volume_in_USD_forex?: Maybe<Scalars['numeric']>,
  volume_in_USD_futures?: Maybe<Scalars['numeric']>,
  volume_in_USD_other?: Maybe<Scalars['numeric']>,
}

export interface DailyVolumePerProfileSumOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export enum DailyVolumePerProfileUpdateColumn {
  CfdVolume = 'CFD_volume',
  CreatedAt = 'created_at',
  ForexVolume = 'forex_volume',
  FuturesVolume = 'futures_volume',
  Id = 'id',
  NominalVolumeCfd = 'nominal_volume_CFD',
  NominalVolumeForex = 'nominal_volume_forex',
  NominalVolumeFutures = 'nominal_volume_futures',
  NominalVolumeOther = 'nominal_volume_other',
  OrganizationId = 'organization_id',
  OtherVolume = 'other_volume',
  ProfileId = 'profile_id',
  SummaryDate = 'summary_date',
  VolumeInUsdCfd = 'volume_in_USD_CFD',
  VolumeInUsdForex = 'volume_in_USD_forex',
  VolumeInUsdFutures = 'volume_in_USD_futures',
  VolumeInUsdOther = 'volume_in_USD_other'
}

export interface DailyVolumePerProfileVarPopFields {
   __typename?: 'daily_volume_per_profile_var_pop_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileVarPopOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileVarSampFields {
   __typename?: 'daily_volume_per_profile_var_samp_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileVarSampOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}

export interface DailyVolumePerProfileVarianceFields {
   __typename?: 'daily_volume_per_profile_variance_fields',
  CFD_volume?: Maybe<Scalars['Float']>,
  forex_volume?: Maybe<Scalars['Float']>,
  futures_volume?: Maybe<Scalars['Float']>,
  nominal_volume_CFD?: Maybe<Scalars['Float']>,
  nominal_volume_forex?: Maybe<Scalars['Float']>,
  nominal_volume_futures?: Maybe<Scalars['Float']>,
  nominal_volume_other?: Maybe<Scalars['Float']>,
  other_volume?: Maybe<Scalars['Float']>,
  volume_in_USD_CFD?: Maybe<Scalars['Float']>,
  volume_in_USD_forex?: Maybe<Scalars['Float']>,
  volume_in_USD_futures?: Maybe<Scalars['Float']>,
  volume_in_USD_other?: Maybe<Scalars['Float']>,
}

export interface DailyVolumePerProfileVarianceOrderBy {
  CFD_volume?: Maybe<OrderBy>,
  forex_volume?: Maybe<OrderBy>,
  futures_volume?: Maybe<OrderBy>,
  nominal_volume_CFD?: Maybe<OrderBy>,
  nominal_volume_forex?: Maybe<OrderBy>,
  nominal_volume_futures?: Maybe<OrderBy>,
  nominal_volume_other?: Maybe<OrderBy>,
  other_volume?: Maybe<OrderBy>,
  volume_in_USD_CFD?: Maybe<OrderBy>,
  volume_in_USD_forex?: Maybe<OrderBy>,
  volume_in_USD_futures?: Maybe<OrderBy>,
  volume_in_USD_other?: Maybe<OrderBy>,
}


export interface DateComparisonExp {
  _eq?: Maybe<Scalars['date']>,
  _gt?: Maybe<Scalars['date']>,
  _gte?: Maybe<Scalars['date']>,
  _in?: Maybe<Array<Scalars['date']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['date']>,
  _lte?: Maybe<Scalars['date']>,
  _neq?: Maybe<Scalars['date']>,
  _nin?: Maybe<Array<Scalars['date']>>,
}

export interface EmploymentStatus {
   __typename?: 'employment_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface EmploymentStatusAggregate {
   __typename?: 'employment_status_aggregate',
  aggregate?: Maybe<EmploymentStatusAggregateFields>,
  nodes: Array<EmploymentStatus>,
}

export interface EmploymentStatusAggregateFields {
   __typename?: 'employment_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<EmploymentStatusMaxFields>,
  min?: Maybe<EmploymentStatusMinFields>,
}


export interface EmploymentStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<EmploymentStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface EmploymentStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<EmploymentStatusMaxOrderBy>,
  min?: Maybe<EmploymentStatusMinOrderBy>,
}

export interface EmploymentStatusArrRelInsertInput {
  data: Array<EmploymentStatusInsertInput>,
  on_conflict?: Maybe<EmploymentStatusOnConflict>,
}

export interface EmploymentStatusBoolExp {
  _and?: Maybe<Array<Maybe<EmploymentStatusBoolExp>>>,
  _not?: Maybe<EmploymentStatusBoolExp>,
  _or?: Maybe<Array<Maybe<EmploymentStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum EmploymentStatusConstraint {
  EmploymentStatusNameKey = 'employment_status_name_key',
  EmploymentStatusPkey = 'employment_status_pkey'
}

export interface EmploymentStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface EmploymentStatusMaxFields {
   __typename?: 'employment_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface EmploymentStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface EmploymentStatusMinFields {
   __typename?: 'employment_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface EmploymentStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface EmploymentStatusMutationResponse {
   __typename?: 'employment_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<EmploymentStatus>,
}

export interface EmploymentStatusObjRelInsertInput {
  data: EmploymentStatusInsertInput,
  on_conflict?: Maybe<EmploymentStatusOnConflict>,
}

export interface EmploymentStatusOnConflict {
  constraint: EmploymentStatusConstraint,
  update_columns: Array<EmploymentStatusUpdateColumn>,
  where?: Maybe<EmploymentStatusBoolExp>,
}

export interface EmploymentStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface EmploymentStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum EmploymentStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface EmploymentStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum EmploymentStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ExperienceType {
   __typename?: 'experience_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ExperienceTypeAggregate {
   __typename?: 'experience_type_aggregate',
  aggregate?: Maybe<ExperienceTypeAggregateFields>,
  nodes: Array<ExperienceType>,
}

export interface ExperienceTypeAggregateFields {
   __typename?: 'experience_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ExperienceTypeMaxFields>,
  min?: Maybe<ExperienceTypeMinFields>,
}


export interface ExperienceTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<ExperienceTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ExperienceTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ExperienceTypeMaxOrderBy>,
  min?: Maybe<ExperienceTypeMinOrderBy>,
}

export interface ExperienceTypeArrRelInsertInput {
  data: Array<ExperienceTypeInsertInput>,
  on_conflict?: Maybe<ExperienceTypeOnConflict>,
}

export interface ExperienceTypeBoolExp {
  _and?: Maybe<Array<Maybe<ExperienceTypeBoolExp>>>,
  _not?: Maybe<ExperienceTypeBoolExp>,
  _or?: Maybe<Array<Maybe<ExperienceTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ExperienceTypeConstraint {
  ExperienceTypeNameKey = 'experience_type_name_key',
  ExperienceTypePkey = 'experience_type_pkey'
}

export interface ExperienceTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ExperienceTypeMaxFields {
   __typename?: 'experience_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ExperienceTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ExperienceTypeMinFields {
   __typename?: 'experience_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ExperienceTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ExperienceTypeMutationResponse {
   __typename?: 'experience_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ExperienceType>,
}

export interface ExperienceTypeObjRelInsertInput {
  data: ExperienceTypeInsertInput,
  on_conflict?: Maybe<ExperienceTypeOnConflict>,
}

export interface ExperienceTypeOnConflict {
  constraint: ExperienceTypeConstraint,
  update_columns: Array<ExperienceTypeUpdateColumn>,
  where?: Maybe<ExperienceTypeBoolExp>,
}

export interface ExperienceTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ExperienceTypePkColumnsInput {
  id: Scalars['String'],
}

export enum ExperienceTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ExperienceTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ExperienceTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface File {
   __typename?: 'file',
  agreement_id?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  description?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id: Scalars['String'],
  is_marked_for_deletion: Scalars['Boolean'],
  is_private: Scalars['Boolean'],
  master_partner_stakeholder_id: Scalars['String'],
  master_partner_stakeholder_permission: MasterPartnerStakeholderPermission,
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  organization?: Maybe<FilesToOrganizations>,
  path: Scalars['String'],
  segment: Segment,
  segment_id: Scalars['String'],
  stakeholder?: Maybe<FilesToStakeholders>,
  title: Scalars['String'],
  type?: Maybe<FileType>,
  type_id?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
  uploaded_by: Stakeholder,
  uploaded_by_id: Scalars['String'],
}

export interface FileAggregate {
   __typename?: 'file_aggregate',
  aggregate?: Maybe<FileAggregateFields>,
  nodes: Array<File>,
}

export interface FileAggregateFields {
   __typename?: 'file_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FileMaxFields>,
  min?: Maybe<FileMinFields>,
}


export interface FileAggregateFieldsCountArgs {
  columns?: Maybe<Array<FileSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FileAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<FileMaxOrderBy>,
  min?: Maybe<FileMinOrderBy>,
}

export interface FileArrRelInsertInput {
  data: Array<FileInsertInput>,
  on_conflict?: Maybe<FileOnConflict>,
}

export interface FileBoolExp {
  _and?: Maybe<Array<Maybe<FileBoolExp>>>,
  _not?: Maybe<FileBoolExp>,
  _or?: Maybe<Array<Maybe<FileBoolExp>>>,
  agreement_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  expires_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_marked_for_deletion?: Maybe<BooleanComparisonExp>,
  is_private?: Maybe<BooleanComparisonExp>,
  master_partner_stakeholder_id?: Maybe<StringComparisonExp>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionBoolExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<FilesToOrganizationsBoolExp>,
  path?: Maybe<StringComparisonExp>,
  segment?: Maybe<SegmentBoolExp>,
  segment_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<FilesToStakeholdersBoolExp>,
  title?: Maybe<StringComparisonExp>,
  type?: Maybe<FileTypeBoolExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  uploaded_by?: Maybe<StakeholderBoolExp>,
  uploaded_by_id?: Maybe<StringComparisonExp>,
}

export enum FileConstraint {
  FilePathKey = 'file_path_key',
  FilePkey = 'file_pkey'
}

export interface FileInsertInput {
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_marked_for_deletion?: Maybe<Scalars['Boolean']>,
  is_private?: Maybe<Scalars['Boolean']>,
  master_partner_stakeholder_id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionObjRelInsertInput>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  organization?: Maybe<FilesToOrganizationsObjRelInsertInput>,
  path?: Maybe<Scalars['String']>,
  segment?: Maybe<SegmentObjRelInsertInput>,
  segment_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<FilesToStakeholdersObjRelInsertInput>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<FileTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  uploaded_by?: Maybe<StakeholderObjRelInsertInput>,
  uploaded_by_id?: Maybe<Scalars['String']>,
}

export interface FileMaxFields {
   __typename?: 'file_max_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  path?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  uploaded_by_id?: Maybe<Scalars['String']>,
}

export interface FileMaxOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_partner_stakeholder_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  path?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  uploaded_by_id?: Maybe<OrderBy>,
}

export interface FileMinFields {
   __typename?: 'file_min_fields',
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  path?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  uploaded_by_id?: Maybe<Scalars['String']>,
}

export interface FileMinOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_partner_stakeholder_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  path?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  uploaded_by_id?: Maybe<OrderBy>,
}

export interface FileMutationResponse {
   __typename?: 'file_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<File>,
}

export interface FileObjRelInsertInput {
  data: FileInsertInput,
  on_conflict?: Maybe<FileOnConflict>,
}

export interface FileOnConflict {
  constraint: FileConstraint,
  update_columns: Array<FileUpdateColumn>,
  where?: Maybe<FileBoolExp>,
}

export interface FileOrderBy {
  agreement_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_marked_for_deletion?: Maybe<OrderBy>,
  is_private?: Maybe<OrderBy>,
  master_partner_stakeholder_id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionOrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  organization?: Maybe<FilesToOrganizationsOrderBy>,
  path?: Maybe<OrderBy>,
  segment?: Maybe<SegmentOrderBy>,
  segment_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<FilesToStakeholdersOrderBy>,
  title?: Maybe<OrderBy>,
  type?: Maybe<FileTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  uploaded_by?: Maybe<StakeholderOrderBy>,
  uploaded_by_id?: Maybe<OrderBy>,
}

export interface FilePkColumnsInput {
  id: Scalars['String'],
}

export enum FileSelectColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  Description = 'description',
  ExpiresAt = 'expires_at',
  Id = 'id',
  IsMarkedForDeletion = 'is_marked_for_deletion',
  IsPrivate = 'is_private',
  MasterPartnerStakeholderId = 'master_partner_stakeholder_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Path = 'path',
  SegmentId = 'segment_id',
  Title = 'title',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  UploadedById = 'uploaded_by_id'
}

export interface FileSetInput {
  agreement_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_marked_for_deletion?: Maybe<Scalars['Boolean']>,
  is_private?: Maybe<Scalars['Boolean']>,
  master_partner_stakeholder_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  path?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  uploaded_by_id?: Maybe<Scalars['String']>,
}

export interface FileType {
   __typename?: 'file_type',
  category: FileTypeCategory,
  category_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  files: Array<File>,
  files_aggregate: FileAggregate,
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  segment: Segment,
  segment_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface FileTypeFilesArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}


export interface FileTypeFilesAggregateArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}

export interface FileTypeAggregate {
   __typename?: 'file_type_aggregate',
  aggregate?: Maybe<FileTypeAggregateFields>,
  nodes: Array<FileType>,
}

export interface FileTypeAggregateFields {
   __typename?: 'file_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FileTypeMaxFields>,
  min?: Maybe<FileTypeMinFields>,
}


export interface FileTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<FileTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FileTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<FileTypeMaxOrderBy>,
  min?: Maybe<FileTypeMinOrderBy>,
}

export interface FileTypeArrRelInsertInput {
  data: Array<FileTypeInsertInput>,
  on_conflict?: Maybe<FileTypeOnConflict>,
}

export interface FileTypeBoolExp {
  _and?: Maybe<Array<Maybe<FileTypeBoolExp>>>,
  _not?: Maybe<FileTypeBoolExp>,
  _or?: Maybe<Array<Maybe<FileTypeBoolExp>>>,
  category?: Maybe<FileTypeCategoryBoolExp>,
  category_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  files?: Maybe<FileBoolExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  segment?: Maybe<SegmentBoolExp>,
  segment_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface FileTypeCategory {
   __typename?: 'file_type_category',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface FileTypeCategoryAggregate {
   __typename?: 'file_type_category_aggregate',
  aggregate?: Maybe<FileTypeCategoryAggregateFields>,
  nodes: Array<FileTypeCategory>,
}

export interface FileTypeCategoryAggregateFields {
   __typename?: 'file_type_category_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FileTypeCategoryMaxFields>,
  min?: Maybe<FileTypeCategoryMinFields>,
}


export interface FileTypeCategoryAggregateFieldsCountArgs {
  columns?: Maybe<Array<FileTypeCategorySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FileTypeCategoryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<FileTypeCategoryMaxOrderBy>,
  min?: Maybe<FileTypeCategoryMinOrderBy>,
}

export interface FileTypeCategoryArrRelInsertInput {
  data: Array<FileTypeCategoryInsertInput>,
  on_conflict?: Maybe<FileTypeCategoryOnConflict>,
}

export interface FileTypeCategoryBoolExp {
  _and?: Maybe<Array<Maybe<FileTypeCategoryBoolExp>>>,
  _not?: Maybe<FileTypeCategoryBoolExp>,
  _or?: Maybe<Array<Maybe<FileTypeCategoryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum FileTypeCategoryConstraint {
  FileTypeCategoryPkey = 'file_type_category_pkey'
}

export interface FileTypeCategoryInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeCategoryMaxFields {
   __typename?: 'file_type_category_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeCategoryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypeCategoryMinFields {
   __typename?: 'file_type_category_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeCategoryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypeCategoryMutationResponse {
   __typename?: 'file_type_category_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<FileTypeCategory>,
}

export interface FileTypeCategoryObjRelInsertInput {
  data: FileTypeCategoryInsertInput,
  on_conflict?: Maybe<FileTypeCategoryOnConflict>,
}

export interface FileTypeCategoryOnConflict {
  constraint: FileTypeCategoryConstraint,
  update_columns: Array<FileTypeCategoryUpdateColumn>,
  where?: Maybe<FileTypeCategoryBoolExp>,
}

export interface FileTypeCategoryOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypeCategoryPkColumnsInput {
  id: Scalars['String'],
}

export enum FileTypeCategorySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface FileTypeCategorySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum FileTypeCategoryUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export enum FileTypeConstraint {
  FileTypePkey = 'file_type_pkey'
}

export interface FileTypeGridView {
   __typename?: 'file_type_grid_view',
  category?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['bigint']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface FileTypeGridViewAggregate {
   __typename?: 'file_type_grid_view_aggregate',
  aggregate?: Maybe<FileTypeGridViewAggregateFields>,
  nodes: Array<FileTypeGridView>,
}

export interface FileTypeGridViewAggregateFields {
   __typename?: 'file_type_grid_view_aggregate_fields',
  avg?: Maybe<FileTypeGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FileTypeGridViewMaxFields>,
  min?: Maybe<FileTypeGridViewMinFields>,
  stddev?: Maybe<FileTypeGridViewStddevFields>,
  stddev_pop?: Maybe<FileTypeGridViewStddevPopFields>,
  stddev_samp?: Maybe<FileTypeGridViewStddevSampFields>,
  sum?: Maybe<FileTypeGridViewSumFields>,
  var_pop?: Maybe<FileTypeGridViewVarPopFields>,
  var_samp?: Maybe<FileTypeGridViewVarSampFields>,
  variance?: Maybe<FileTypeGridViewVarianceFields>,
}


export interface FileTypeGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<FileTypeGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FileTypeGridViewAggregateOrderBy {
  avg?: Maybe<FileTypeGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<FileTypeGridViewMaxOrderBy>,
  min?: Maybe<FileTypeGridViewMinOrderBy>,
  stddev?: Maybe<FileTypeGridViewStddevOrderBy>,
  stddev_pop?: Maybe<FileTypeGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<FileTypeGridViewStddevSampOrderBy>,
  sum?: Maybe<FileTypeGridViewSumOrderBy>,
  var_pop?: Maybe<FileTypeGridViewVarPopOrderBy>,
  var_samp?: Maybe<FileTypeGridViewVarSampOrderBy>,
  variance?: Maybe<FileTypeGridViewVarianceOrderBy>,
}

export interface FileTypeGridViewAvgFields {
   __typename?: 'file_type_grid_view_avg_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewAvgOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewBoolExp {
  _and?: Maybe<Array<Maybe<FileTypeGridViewBoolExp>>>,
  _not?: Maybe<FileTypeGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<FileTypeGridViewBoolExp>>>,
  category?: Maybe<StringComparisonExp>,
  count?: Maybe<BigintComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  segment?: Maybe<StringComparisonExp>,
}

export interface FileTypeGridViewMaxFields {
   __typename?: 'file_type_grid_view_max_fields',
  category?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['bigint']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface FileTypeGridViewMaxOrderBy {
  category?: Maybe<OrderBy>,
  count?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface FileTypeGridViewMinFields {
   __typename?: 'file_type_grid_view_min_fields',
  category?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['bigint']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface FileTypeGridViewMinOrderBy {
  category?: Maybe<OrderBy>,
  count?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface FileTypeGridViewOrderBy {
  category?: Maybe<OrderBy>,
  count?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export enum FileTypeGridViewSelectColumn {
  Category = 'category',
  Count = 'count',
  CreatedAt = 'created_at',
  CreatedBy = 'created_by',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  OrganizationId = 'organization_id',
  Segment = 'segment'
}

export interface FileTypeGridViewStddevFields {
   __typename?: 'file_type_grid_view_stddev_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewStddevOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewStddevPopFields {
   __typename?: 'file_type_grid_view_stddev_pop_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewStddevPopOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewStddevSampFields {
   __typename?: 'file_type_grid_view_stddev_samp_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewStddevSampOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewSumFields {
   __typename?: 'file_type_grid_view_sum_fields',
  count?: Maybe<Scalars['bigint']>,
}

export interface FileTypeGridViewSumOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewVarPopFields {
   __typename?: 'file_type_grid_view_var_pop_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewVarPopOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewVarSampFields {
   __typename?: 'file_type_grid_view_var_samp_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewVarSampOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeGridViewVarianceFields {
   __typename?: 'file_type_grid_view_variance_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface FileTypeGridViewVarianceOrderBy {
  count?: Maybe<OrderBy>,
}

export interface FileTypeInsertInput {
  category?: Maybe<FileTypeCategoryObjRelInsertInput>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  files?: Maybe<FileArrRelInsertInput>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<SegmentObjRelInsertInput>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeMaxFields {
   __typename?: 'file_type_max_fields',
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeMaxOrderBy {
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypeMinFields {
   __typename?: 'file_type_min_fields',
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FileTypeMinOrderBy {
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypeMutationResponse {
   __typename?: 'file_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<FileType>,
}

export interface FileTypeObjRelInsertInput {
  data: FileTypeInsertInput,
  on_conflict?: Maybe<FileTypeOnConflict>,
}

export interface FileTypeOnConflict {
  constraint: FileTypeConstraint,
  update_columns: Array<FileTypeUpdateColumn>,
  where?: Maybe<FileTypeBoolExp>,
}

export interface FileTypeOrderBy {
  category?: Maybe<FileTypeCategoryOrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  files_aggregate?: Maybe<FileAggregateOrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<SegmentOrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FileTypePkColumnsInput {
  id: Scalars['String'],
}

export enum FileTypeSelectColumn {
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  OrganizationId = 'organization_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export interface FileTypeSetInput {
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum FileTypeUpdateColumn {
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  OrganizationId = 'organization_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export enum FileUpdateColumn {
  AgreementId = 'agreement_id',
  CreatedAt = 'created_at',
  Description = 'description',
  ExpiresAt = 'expires_at',
  Id = 'id',
  IsMarkedForDeletion = 'is_marked_for_deletion',
  IsPrivate = 'is_private',
  MasterPartnerStakeholderId = 'master_partner_stakeholder_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Path = 'path',
  SegmentId = 'segment_id',
  Title = 'title',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  UploadedById = 'uploaded_by_id'
}

export interface FilesToOrganizations {
   __typename?: 'files_to_organizations',
  created_at: Scalars['timestamptz'],
  file: File,
  file_id: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface FilesToOrganizationsAggregate {
   __typename?: 'files_to_organizations_aggregate',
  aggregate?: Maybe<FilesToOrganizationsAggregateFields>,
  nodes: Array<FilesToOrganizations>,
}

export interface FilesToOrganizationsAggregateFields {
   __typename?: 'files_to_organizations_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FilesToOrganizationsMaxFields>,
  min?: Maybe<FilesToOrganizationsMinFields>,
}


export interface FilesToOrganizationsAggregateFieldsCountArgs {
  columns?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FilesToOrganizationsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<FilesToOrganizationsMaxOrderBy>,
  min?: Maybe<FilesToOrganizationsMinOrderBy>,
}

export interface FilesToOrganizationsArrRelInsertInput {
  data: Array<FilesToOrganizationsInsertInput>,
  on_conflict?: Maybe<FilesToOrganizationsOnConflict>,
}

export interface FilesToOrganizationsBoolExp {
  _and?: Maybe<Array<Maybe<FilesToOrganizationsBoolExp>>>,
  _not?: Maybe<FilesToOrganizationsBoolExp>,
  _or?: Maybe<Array<Maybe<FilesToOrganizationsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  file?: Maybe<FileBoolExp>,
  file_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum FilesToOrganizationsConstraint {
  FilesToOrganizationsFileIdKey = 'files_to_organizations_file_id_key',
  FilesToOrganizationsPkey = 'files_to_organizations_pkey'
}

export interface FilesToOrganizationsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  file?: Maybe<FileObjRelInsertInput>,
  file_id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToOrganizationsMaxFields {
   __typename?: 'files_to_organizations_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToOrganizationsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToOrganizationsMinFields {
   __typename?: 'files_to_organizations_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToOrganizationsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToOrganizationsMutationResponse {
   __typename?: 'files_to_organizations_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<FilesToOrganizations>,
}

export interface FilesToOrganizationsObjRelInsertInput {
  data: FilesToOrganizationsInsertInput,
  on_conflict?: Maybe<FilesToOrganizationsOnConflict>,
}

export interface FilesToOrganizationsOnConflict {
  constraint: FilesToOrganizationsConstraint,
  update_columns: Array<FilesToOrganizationsUpdateColumn>,
  where?: Maybe<FilesToOrganizationsBoolExp>,
}

export interface FilesToOrganizationsOrderBy {
  created_at?: Maybe<OrderBy>,
  file?: Maybe<FileOrderBy>,
  file_id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToOrganizationsPkColumnsInput {
  file_id: Scalars['String'],
  organization_id: Scalars['String'],
}

export enum FilesToOrganizationsSelectColumn {
  CreatedAt = 'created_at',
  FileId = 'file_id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface FilesToOrganizationsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum FilesToOrganizationsUpdateColumn {
  CreatedAt = 'created_at',
  FileId = 'file_id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface FilesToStakeholders {
   __typename?: 'files_to_stakeholders',
  created_at: Scalars['timestamptz'],
  file: File,
  file_id: Scalars['String'],
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  stakeholder: Stakeholder,
  stakeholder_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface FilesToStakeholdersAggregate {
   __typename?: 'files_to_stakeholders_aggregate',
  aggregate?: Maybe<FilesToStakeholdersAggregateFields>,
  nodes: Array<FilesToStakeholders>,
}

export interface FilesToStakeholdersAggregateFields {
   __typename?: 'files_to_stakeholders_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<FilesToStakeholdersMaxFields>,
  min?: Maybe<FilesToStakeholdersMinFields>,
}


export interface FilesToStakeholdersAggregateFieldsCountArgs {
  columns?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface FilesToStakeholdersAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<FilesToStakeholdersMaxOrderBy>,
  min?: Maybe<FilesToStakeholdersMinOrderBy>,
}

export interface FilesToStakeholdersArrRelInsertInput {
  data: Array<FilesToStakeholdersInsertInput>,
  on_conflict?: Maybe<FilesToStakeholdersOnConflict>,
}

export interface FilesToStakeholdersBoolExp {
  _and?: Maybe<Array<Maybe<FilesToStakeholdersBoolExp>>>,
  _not?: Maybe<FilesToStakeholdersBoolExp>,
  _or?: Maybe<Array<Maybe<FilesToStakeholdersBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  file?: Maybe<FileBoolExp>,
  file_id?: Maybe<StringComparisonExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum FilesToStakeholdersConstraint {
  FilesToStakeholdersFileIdKey = 'files_to_stakeholders_file_id_key',
  FilesToStakeholdersPkey = 'files_to_stakeholders_pkey'
}

export interface FilesToStakeholdersInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  file?: Maybe<FileObjRelInsertInput>,
  file_id?: Maybe<Scalars['String']>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToStakeholdersMaxFields {
   __typename?: 'files_to_stakeholders_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToStakeholdersMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToStakeholdersMinFields {
   __typename?: 'files_to_stakeholders_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface FilesToStakeholdersMinOrderBy {
  created_at?: Maybe<OrderBy>,
  file_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToStakeholdersMutationResponse {
   __typename?: 'files_to_stakeholders_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<FilesToStakeholders>,
}

export interface FilesToStakeholdersObjRelInsertInput {
  data: FilesToStakeholdersInsertInput,
  on_conflict?: Maybe<FilesToStakeholdersOnConflict>,
}

export interface FilesToStakeholdersOnConflict {
  constraint: FilesToStakeholdersConstraint,
  update_columns: Array<FilesToStakeholdersUpdateColumn>,
  where?: Maybe<FilesToStakeholdersBoolExp>,
}

export interface FilesToStakeholdersOrderBy {
  created_at?: Maybe<OrderBy>,
  file?: Maybe<FileOrderBy>,
  file_id?: Maybe<OrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface FilesToStakeholdersPkColumnsInput {
  file_id: Scalars['String'],
  stakeholder_id: Scalars['String'],
}

export enum FilesToStakeholdersSelectColumn {
  CreatedAt = 'created_at',
  FileId = 'file_id',
  MasterUserPermissionId = 'master_user_permission_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface FilesToStakeholdersSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  file_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum FilesToStakeholdersUpdateColumn {
  CreatedAt = 'created_at',
  FileId = 'file_id',
  MasterUserPermissionId = 'master_user_permission_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface Gender {
   __typename?: 'gender',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface GenderAggregate {
   __typename?: 'gender_aggregate',
  aggregate?: Maybe<GenderAggregateFields>,
  nodes: Array<Gender>,
}

export interface GenderAggregateFields {
   __typename?: 'gender_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<GenderMaxFields>,
  min?: Maybe<GenderMinFields>,
}


export interface GenderAggregateFieldsCountArgs {
  columns?: Maybe<Array<GenderSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface GenderAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<GenderMaxOrderBy>,
  min?: Maybe<GenderMinOrderBy>,
}

export interface GenderArrRelInsertInput {
  data: Array<GenderInsertInput>,
  on_conflict?: Maybe<GenderOnConflict>,
}

export interface GenderBoolExp {
  _and?: Maybe<Array<Maybe<GenderBoolExp>>>,
  _not?: Maybe<GenderBoolExp>,
  _or?: Maybe<Array<Maybe<GenderBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum GenderConstraint {
  GenderNameKey = 'gender_name_key',
  GenderPkey = 'gender_pkey'
}

export interface GenderInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface GenderMaxFields {
   __typename?: 'gender_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface GenderMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface GenderMinFields {
   __typename?: 'gender_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface GenderMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface GenderMutationResponse {
   __typename?: 'gender_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Gender>,
}

export interface GenderObjRelInsertInput {
  data: GenderInsertInput,
  on_conflict?: Maybe<GenderOnConflict>,
}

export interface GenderOnConflict {
  constraint: GenderConstraint,
  update_columns: Array<GenderUpdateColumn>,
  where?: Maybe<GenderBoolExp>,
}

export interface GenderOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface GenderPkColumnsInput {
  id: Scalars['String'],
}

export enum GenderSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface GenderSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum GenderUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IdentificationType {
   __typename?: 'identification_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface IdentificationTypeAggregate {
   __typename?: 'identification_type_aggregate',
  aggregate?: Maybe<IdentificationTypeAggregateFields>,
  nodes: Array<IdentificationType>,
}

export interface IdentificationTypeAggregateFields {
   __typename?: 'identification_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<IdentificationTypeMaxFields>,
  min?: Maybe<IdentificationTypeMinFields>,
}


export interface IdentificationTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<IdentificationTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface IdentificationTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<IdentificationTypeMaxOrderBy>,
  min?: Maybe<IdentificationTypeMinOrderBy>,
}

export interface IdentificationTypeArrRelInsertInput {
  data: Array<IdentificationTypeInsertInput>,
  on_conflict?: Maybe<IdentificationTypeOnConflict>,
}

export interface IdentificationTypeBoolExp {
  _and?: Maybe<Array<Maybe<IdentificationTypeBoolExp>>>,
  _not?: Maybe<IdentificationTypeBoolExp>,
  _or?: Maybe<Array<Maybe<IdentificationTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum IdentificationTypeConstraint {
  IdentificationTypeNameKey = 'identification_type_name_key',
  IdentificationTypePkey = 'identification_type_pkey'
}

export interface IdentificationTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IdentificationTypeMaxFields {
   __typename?: 'identification_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IdentificationTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IdentificationTypeMinFields {
   __typename?: 'identification_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IdentificationTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IdentificationTypeMutationResponse {
   __typename?: 'identification_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<IdentificationType>,
}

export interface IdentificationTypeObjRelInsertInput {
  data: IdentificationTypeInsertInput,
  on_conflict?: Maybe<IdentificationTypeOnConflict>,
}

export interface IdentificationTypeOnConflict {
  constraint: IdentificationTypeConstraint,
  update_columns: Array<IdentificationTypeUpdateColumn>,
  where?: Maybe<IdentificationTypeBoolExp>,
}

export interface IdentificationTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IdentificationTypePkColumnsInput {
  id: Scalars['String'],
}

export enum IdentificationTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IdentificationTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum IdentificationTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IndividualPartnerAccountGroupDefault {
   __typename?: 'individual_partner_account_group_default',
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  group_id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String'],
}

export interface IndividualPartnerAccountGroupDefaultAggregate {
   __typename?: 'individual_partner_account_group_default_aggregate',
  aggregate?: Maybe<IndividualPartnerAccountGroupDefaultAggregateFields>,
  nodes: Array<IndividualPartnerAccountGroupDefault>,
}

export interface IndividualPartnerAccountGroupDefaultAggregateFields {
   __typename?: 'individual_partner_account_group_default_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<IndividualPartnerAccountGroupDefaultMaxFields>,
  min?: Maybe<IndividualPartnerAccountGroupDefaultMinFields>,
}


export interface IndividualPartnerAccountGroupDefaultAggregateFieldsCountArgs {
  columns?: Maybe<Array<IndividualPartnerAccountGroupDefaultSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface IndividualPartnerAccountGroupDefaultAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<IndividualPartnerAccountGroupDefaultMaxOrderBy>,
  min?: Maybe<IndividualPartnerAccountGroupDefaultMinOrderBy>,
}

export interface IndividualPartnerAccountGroupDefaultArrRelInsertInput {
  data: Array<IndividualPartnerAccountGroupDefaultInsertInput>,
  on_conflict?: Maybe<IndividualPartnerAccountGroupDefaultOnConflict>,
}

export interface IndividualPartnerAccountGroupDefaultBoolExp {
  _and?: Maybe<Array<Maybe<IndividualPartnerAccountGroupDefaultBoolExp>>>,
  _not?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>,
  _or?: Maybe<Array<Maybe<IndividualPartnerAccountGroupDefaultBoolExp>>>,
  currency_id?: Maybe<StringComparisonExp>,
  environment_id?: Maybe<StringComparisonExp>,
  group_id?: Maybe<StringComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner_id?: Maybe<StringComparisonExp>,
  platform_server_type_id?: Maybe<StringComparisonExp>,
  profile_category_id?: Maybe<StringComparisonExp>,
}

export enum IndividualPartnerAccountGroupDefaultConstraint {
  IndividualPartnerAccountGroupDefaultPkey = 'individual_partner_account_group_default_pkey'
}

export interface IndividualPartnerAccountGroupDefaultInsertInput {
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface IndividualPartnerAccountGroupDefaultMaxFields {
   __typename?: 'individual_partner_account_group_default_max_fields',
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface IndividualPartnerAccountGroupDefaultMaxOrderBy {
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface IndividualPartnerAccountGroupDefaultMinFields {
   __typename?: 'individual_partner_account_group_default_min_fields',
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export interface IndividualPartnerAccountGroupDefaultMinOrderBy {
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface IndividualPartnerAccountGroupDefaultMutationResponse {
   __typename?: 'individual_partner_account_group_default_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<IndividualPartnerAccountGroupDefault>,
}

export interface IndividualPartnerAccountGroupDefaultObjRelInsertInput {
  data: IndividualPartnerAccountGroupDefaultInsertInput,
  on_conflict?: Maybe<IndividualPartnerAccountGroupDefaultOnConflict>,
}

export interface IndividualPartnerAccountGroupDefaultOnConflict {
  constraint: IndividualPartnerAccountGroupDefaultConstraint,
  update_columns: Array<IndividualPartnerAccountGroupDefaultUpdateColumn>,
  where?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>,
}

export interface IndividualPartnerAccountGroupDefaultOrderBy {
  currency_id?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  group_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  profile_category_id?: Maybe<OrderBy>,
}

export interface IndividualPartnerAccountGroupDefaultPkColumnsInput {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String'],
}

export enum IndividualPartnerAccountGroupDefaultSelectColumn {
  CurrencyId = 'currency_id',
  EnvironmentId = 'environment_id',
  GroupId = 'group_id',
  OrganizationId = 'organization_id',
  PartnerId = 'partner_id',
  PlatformServerTypeId = 'platform_server_type_id',
  ProfileCategoryId = 'profile_category_id'
}

export interface IndividualPartnerAccountGroupDefaultSetInput {
  currency_id?: Maybe<Scalars['String']>,
  environment_id?: Maybe<Scalars['String']>,
  group_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  profile_category_id?: Maybe<Scalars['String']>,
}

export enum IndividualPartnerAccountGroupDefaultUpdateColumn {
  CurrencyId = 'currency_id',
  EnvironmentId = 'environment_id',
  GroupId = 'group_id',
  OrganizationId = 'organization_id',
  PartnerId = 'partner_id',
  PlatformServerTypeId = 'platform_server_type_id',
  ProfileCategoryId = 'profile_category_id'
}

export interface Industry {
   __typename?: 'industry',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  organizations: Array<Organization>,
  organizations_aggregate: OrganizationAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface IndustryOrganizationsArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}


export interface IndustryOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}

export interface IndustryAggregate {
   __typename?: 'industry_aggregate',
  aggregate?: Maybe<IndustryAggregateFields>,
  nodes: Array<Industry>,
}

export interface IndustryAggregateFields {
   __typename?: 'industry_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<IndustryMaxFields>,
  min?: Maybe<IndustryMinFields>,
}


export interface IndustryAggregateFieldsCountArgs {
  columns?: Maybe<Array<IndustrySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface IndustryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<IndustryMaxOrderBy>,
  min?: Maybe<IndustryMinOrderBy>,
}

export interface IndustryArrRelInsertInput {
  data: Array<IndustryInsertInput>,
  on_conflict?: Maybe<IndustryOnConflict>,
}

export interface IndustryBoolExp {
  _and?: Maybe<Array<Maybe<IndustryBoolExp>>>,
  _not?: Maybe<IndustryBoolExp>,
  _or?: Maybe<Array<Maybe<IndustryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organizations?: Maybe<OrganizationBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum IndustryConstraint {
  IndustryNameKey = 'industry_name_key',
  IndustryPkey = 'industry_pkey'
}

export interface IndustryInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organizations?: Maybe<OrganizationArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IndustryMaxFields {
   __typename?: 'industry_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IndustryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IndustryMinFields {
   __typename?: 'industry_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IndustryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IndustryMutationResponse {
   __typename?: 'industry_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Industry>,
}

export interface IndustryObjRelInsertInput {
  data: IndustryInsertInput,
  on_conflict?: Maybe<IndustryOnConflict>,
}

export interface IndustryOnConflict {
  constraint: IndustryConstraint,
  update_columns: Array<IndustryUpdateColumn>,
  where?: Maybe<IndustryBoolExp>,
}

export interface IndustryOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organizations_aggregate?: Maybe<OrganizationAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IndustryPkColumnsInput {
  id: Scalars['String'],
}

export enum IndustrySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IndustrySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum IndustryUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IntComparisonExp {
  _eq?: Maybe<Scalars['Int']>,
  _gt?: Maybe<Scalars['Int']>,
  _gte?: Maybe<Scalars['Int']>,
  _in?: Maybe<Array<Scalars['Int']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['Int']>,
  _lte?: Maybe<Scalars['Int']>,
  _neq?: Maybe<Scalars['Int']>,
  _nin?: Maybe<Array<Scalars['Int']>>,
}

export interface IntermediaryBank {
   __typename?: 'intermediary_bank',
  bank_account?: Maybe<BankAccount>,
  code: Scalars['String'],
  country?: Maybe<Country>,
  country_id?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface IntermediaryBankAggregate {
   __typename?: 'intermediary_bank_aggregate',
  aggregate?: Maybe<IntermediaryBankAggregateFields>,
  nodes: Array<IntermediaryBank>,
}

export interface IntermediaryBankAggregateFields {
   __typename?: 'intermediary_bank_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<IntermediaryBankMaxFields>,
  min?: Maybe<IntermediaryBankMinFields>,
}


export interface IntermediaryBankAggregateFieldsCountArgs {
  columns?: Maybe<Array<IntermediaryBankSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface IntermediaryBankAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<IntermediaryBankMaxOrderBy>,
  min?: Maybe<IntermediaryBankMinOrderBy>,
}

export interface IntermediaryBankArrRelInsertInput {
  data: Array<IntermediaryBankInsertInput>,
  on_conflict?: Maybe<IntermediaryBankOnConflict>,
}

export interface IntermediaryBankBoolExp {
  _and?: Maybe<Array<Maybe<IntermediaryBankBoolExp>>>,
  _not?: Maybe<IntermediaryBankBoolExp>,
  _or?: Maybe<Array<Maybe<IntermediaryBankBoolExp>>>,
  bank_account?: Maybe<BankAccountBoolExp>,
  code?: Maybe<StringComparisonExp>,
  country?: Maybe<CountryBoolExp>,
  country_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum IntermediaryBankConstraint {
  IntermediaryBankPkey = 'intermediary_bank_pkey'
}

export interface IntermediaryBankInsertInput {
  bank_account?: Maybe<BankAccountObjRelInsertInput>,
  code?: Maybe<Scalars['String']>,
  country?: Maybe<CountryObjRelInsertInput>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IntermediaryBankMaxFields {
   __typename?: 'intermediary_bank_max_fields',
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IntermediaryBankMaxOrderBy {
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IntermediaryBankMinFields {
   __typename?: 'intermediary_bank_min_fields',
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface IntermediaryBankMinOrderBy {
  code?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IntermediaryBankMutationResponse {
   __typename?: 'intermediary_bank_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<IntermediaryBank>,
}

export interface IntermediaryBankObjRelInsertInput {
  data: IntermediaryBankInsertInput,
  on_conflict?: Maybe<IntermediaryBankOnConflict>,
}

export interface IntermediaryBankOnConflict {
  constraint: IntermediaryBankConstraint,
  update_columns: Array<IntermediaryBankUpdateColumn>,
  where?: Maybe<IntermediaryBankBoolExp>,
}

export interface IntermediaryBankOrderBy {
  bank_account?: Maybe<BankAccountOrderBy>,
  code?: Maybe<OrderBy>,
  country?: Maybe<CountryOrderBy>,
  country_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface IntermediaryBankPkColumnsInput {
  id: Scalars['String'],
}

export enum IntermediaryBankSelectColumn {
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface IntermediaryBankSetInput {
  code?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum IntermediaryBankUpdateColumn {
  Code = 'code',
  CountryId = 'country_id',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface InvestmentObjective {
   __typename?: 'investment_objective',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface InvestmentObjectiveAggregate {
   __typename?: 'investment_objective_aggregate',
  aggregate?: Maybe<InvestmentObjectiveAggregateFields>,
  nodes: Array<InvestmentObjective>,
}

export interface InvestmentObjectiveAggregateFields {
   __typename?: 'investment_objective_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<InvestmentObjectiveMaxFields>,
  min?: Maybe<InvestmentObjectiveMinFields>,
}


export interface InvestmentObjectiveAggregateFieldsCountArgs {
  columns?: Maybe<Array<InvestmentObjectiveSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface InvestmentObjectiveAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<InvestmentObjectiveMaxOrderBy>,
  min?: Maybe<InvestmentObjectiveMinOrderBy>,
}

export interface InvestmentObjectiveArrRelInsertInput {
  data: Array<InvestmentObjectiveInsertInput>,
  on_conflict?: Maybe<InvestmentObjectiveOnConflict>,
}

export interface InvestmentObjectiveBoolExp {
  _and?: Maybe<Array<Maybe<InvestmentObjectiveBoolExp>>>,
  _not?: Maybe<InvestmentObjectiveBoolExp>,
  _or?: Maybe<Array<Maybe<InvestmentObjectiveBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum InvestmentObjectiveConstraint {
  InvestmentObjectiveNameKey = 'investment_objective_name_key',
  InvestmentObjectivePkey = 'investment_objective_pkey'
}

export interface InvestmentObjectiveInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface InvestmentObjectiveMaxFields {
   __typename?: 'investment_objective_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface InvestmentObjectiveMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface InvestmentObjectiveMinFields {
   __typename?: 'investment_objective_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface InvestmentObjectiveMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface InvestmentObjectiveMutationResponse {
   __typename?: 'investment_objective_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<InvestmentObjective>,
}

export interface InvestmentObjectiveObjRelInsertInput {
  data: InvestmentObjectiveInsertInput,
  on_conflict?: Maybe<InvestmentObjectiveOnConflict>,
}

export interface InvestmentObjectiveOnConflict {
  constraint: InvestmentObjectiveConstraint,
  update_columns: Array<InvestmentObjectiveUpdateColumn>,
  where?: Maybe<InvestmentObjectiveBoolExp>,
}

export interface InvestmentObjectiveOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface InvestmentObjectivePkColumnsInput {
  id: Scalars['String'],
}

export enum InvestmentObjectiveSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface InvestmentObjectiveSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum InvestmentObjectiveUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}


export interface JsonComparisonExp {
  _eq?: Maybe<Scalars['json']>,
  _gt?: Maybe<Scalars['json']>,
  _gte?: Maybe<Scalars['json']>,
  _in?: Maybe<Array<Scalars['json']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['json']>,
  _lte?: Maybe<Scalars['json']>,
  _neq?: Maybe<Scalars['json']>,
  _nin?: Maybe<Array<Scalars['json']>>,
}


export interface JsonbComparisonExp {
  _contained_in?: Maybe<Scalars['jsonb']>,
  _contains?: Maybe<Scalars['jsonb']>,
  _eq?: Maybe<Scalars['jsonb']>,
  _gt?: Maybe<Scalars['jsonb']>,
  _gte?: Maybe<Scalars['jsonb']>,
  _has_key?: Maybe<Scalars['String']>,
  _has_keys_all?: Maybe<Array<Scalars['String']>>,
  _has_keys_any?: Maybe<Array<Scalars['String']>>,
  _in?: Maybe<Array<Scalars['jsonb']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['jsonb']>,
  _lte?: Maybe<Scalars['jsonb']>,
  _neq?: Maybe<Scalars['jsonb']>,
  _nin?: Maybe<Array<Scalars['jsonb']>>,
}

export interface Leverage {
   __typename?: 'leverage',
  ratio: Scalars['Int'],
  supported_platform_server_types: Array<LeveragesToPlatformServerTypes>,
  supported_platform_server_types_aggregate: LeveragesToPlatformServerTypesAggregate,
}


export interface LeverageSupportedPlatformServerTypesArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface LeverageSupportedPlatformServerTypesAggregateArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}

export interface LeverageAggregate {
   __typename?: 'leverage_aggregate',
  aggregate?: Maybe<LeverageAggregateFields>,
  nodes: Array<Leverage>,
}

export interface LeverageAggregateFields {
   __typename?: 'leverage_aggregate_fields',
  avg?: Maybe<LeverageAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LeverageMaxFields>,
  min?: Maybe<LeverageMinFields>,
  stddev?: Maybe<LeverageStddevFields>,
  stddev_pop?: Maybe<LeverageStddevPopFields>,
  stddev_samp?: Maybe<LeverageStddevSampFields>,
  sum?: Maybe<LeverageSumFields>,
  var_pop?: Maybe<LeverageVarPopFields>,
  var_samp?: Maybe<LeverageVarSampFields>,
  variance?: Maybe<LeverageVarianceFields>,
}


export interface LeverageAggregateFieldsCountArgs {
  columns?: Maybe<Array<LeverageSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LeverageAggregateOrderBy {
  avg?: Maybe<LeverageAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<LeverageMaxOrderBy>,
  min?: Maybe<LeverageMinOrderBy>,
  stddev?: Maybe<LeverageStddevOrderBy>,
  stddev_pop?: Maybe<LeverageStddevPopOrderBy>,
  stddev_samp?: Maybe<LeverageStddevSampOrderBy>,
  sum?: Maybe<LeverageSumOrderBy>,
  var_pop?: Maybe<LeverageVarPopOrderBy>,
  var_samp?: Maybe<LeverageVarSampOrderBy>,
  variance?: Maybe<LeverageVarianceOrderBy>,
}

export interface LeverageArrRelInsertInput {
  data: Array<LeverageInsertInput>,
  on_conflict?: Maybe<LeverageOnConflict>,
}

export interface LeverageAvgFields {
   __typename?: 'leverage_avg_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageAvgOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageBoolExp {
  _and?: Maybe<Array<Maybe<LeverageBoolExp>>>,
  _not?: Maybe<LeverageBoolExp>,
  _or?: Maybe<Array<Maybe<LeverageBoolExp>>>,
  ratio?: Maybe<IntComparisonExp>,
  supported_platform_server_types?: Maybe<LeveragesToPlatformServerTypesBoolExp>,
}

export enum LeverageConstraint {
  LeveragePkey = 'leverage_pkey'
}

export interface LeverageGridView {
   __typename?: 'leverage_grid_view',
  id?: Maybe<Scalars['String']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_id?: Maybe<Scalars['String']>,
  platform_servers_to_supported_leverage?: Maybe<PlatformServersToSupportedLeverages>,
}

export interface LeverageGridViewAggregate {
   __typename?: 'leverage_grid_view_aggregate',
  aggregate?: Maybe<LeverageGridViewAggregateFields>,
  nodes: Array<LeverageGridView>,
}

export interface LeverageGridViewAggregateFields {
   __typename?: 'leverage_grid_view_aggregate_fields',
  avg?: Maybe<LeverageGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LeverageGridViewMaxFields>,
  min?: Maybe<LeverageGridViewMinFields>,
  stddev?: Maybe<LeverageGridViewStddevFields>,
  stddev_pop?: Maybe<LeverageGridViewStddevPopFields>,
  stddev_samp?: Maybe<LeverageGridViewStddevSampFields>,
  sum?: Maybe<LeverageGridViewSumFields>,
  var_pop?: Maybe<LeverageGridViewVarPopFields>,
  var_samp?: Maybe<LeverageGridViewVarSampFields>,
  variance?: Maybe<LeverageGridViewVarianceFields>,
}


export interface LeverageGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<LeverageGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LeverageGridViewAggregateOrderBy {
  avg?: Maybe<LeverageGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<LeverageGridViewMaxOrderBy>,
  min?: Maybe<LeverageGridViewMinOrderBy>,
  stddev?: Maybe<LeverageGridViewStddevOrderBy>,
  stddev_pop?: Maybe<LeverageGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<LeverageGridViewStddevSampOrderBy>,
  sum?: Maybe<LeverageGridViewSumOrderBy>,
  var_pop?: Maybe<LeverageGridViewVarPopOrderBy>,
  var_samp?: Maybe<LeverageGridViewVarSampOrderBy>,
  variance?: Maybe<LeverageGridViewVarianceOrderBy>,
}

export interface LeverageGridViewAvgFields {
   __typename?: 'leverage_grid_view_avg_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewAvgOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewBoolExp {
  _and?: Maybe<Array<Maybe<LeverageGridViewBoolExp>>>,
  _not?: Maybe<LeverageGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<LeverageGridViewBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  leverage?: Maybe<IntComparisonExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  platform_servers_to_supported_leverage?: Maybe<PlatformServersToSupportedLeveragesBoolExp>,
}

export interface LeverageGridViewMaxFields {
   __typename?: 'leverage_grid_view_max_fields',
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_id?: Maybe<Scalars['String']>,
}

export interface LeverageGridViewMaxOrderBy {
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
}

export interface LeverageGridViewMinFields {
   __typename?: 'leverage_grid_view_min_fields',
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_id?: Maybe<Scalars['String']>,
}

export interface LeverageGridViewMinOrderBy {
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
}

export interface LeverageGridViewOrderBy {
  id?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  platform_servers_to_supported_leverage?: Maybe<PlatformServersToSupportedLeveragesOrderBy>,
}

export enum LeverageGridViewSelectColumn {
  Id = 'id',
  IsEnabled = 'is_enabled',
  Leverage = 'leverage',
  PlatformServerId = 'platform_server_id'
}

export interface LeverageGridViewStddevFields {
   __typename?: 'leverage_grid_view_stddev_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewStddevOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewStddevPopFields {
   __typename?: 'leverage_grid_view_stddev_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewStddevPopOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewStddevSampFields {
   __typename?: 'leverage_grid_view_stddev_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewStddevSampOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewSumFields {
   __typename?: 'leverage_grid_view_sum_fields',
  leverage?: Maybe<Scalars['Int']>,
}

export interface LeverageGridViewSumOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewVarPopFields {
   __typename?: 'leverage_grid_view_var_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewVarPopOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewVarSampFields {
   __typename?: 'leverage_grid_view_var_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewVarSampOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageGridViewVarianceFields {
   __typename?: 'leverage_grid_view_variance_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeverageGridViewVarianceOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeverageIncInput {
  ratio?: Maybe<Scalars['Int']>,
}

export interface LeverageInsertInput {
  ratio?: Maybe<Scalars['Int']>,
  supported_platform_server_types?: Maybe<LeveragesToPlatformServerTypesArrRelInsertInput>,
}

export interface LeverageMaxFields {
   __typename?: 'leverage_max_fields',
  ratio?: Maybe<Scalars['Int']>,
}

export interface LeverageMaxOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageMinFields {
   __typename?: 'leverage_min_fields',
  ratio?: Maybe<Scalars['Int']>,
}

export interface LeverageMinOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageMutationResponse {
   __typename?: 'leverage_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Leverage>,
}

export interface LeverageObjRelInsertInput {
  data: LeverageInsertInput,
  on_conflict?: Maybe<LeverageOnConflict>,
}

export interface LeverageOnConflict {
  constraint: LeverageConstraint,
  update_columns: Array<LeverageUpdateColumn>,
  where?: Maybe<LeverageBoolExp>,
}

export interface LeverageOrderBy {
  ratio?: Maybe<OrderBy>,
  supported_platform_server_types_aggregate?: Maybe<LeveragesToPlatformServerTypesAggregateOrderBy>,
}

export interface LeveragePkColumnsInput {
  ratio: Scalars['Int'],
}

export enum LeverageSelectColumn {
  Ratio = 'ratio'
}

export interface LeverageSetInput {
  ratio?: Maybe<Scalars['Int']>,
}

export interface LeverageStddevFields {
   __typename?: 'leverage_stddev_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageStddevOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageStddevPopFields {
   __typename?: 'leverage_stddev_pop_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageStddevPopOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageStddevSampFields {
   __typename?: 'leverage_stddev_samp_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageStddevSampOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageSumFields {
   __typename?: 'leverage_sum_fields',
  ratio?: Maybe<Scalars['Int']>,
}

export interface LeverageSumOrderBy {
  ratio?: Maybe<OrderBy>,
}

export enum LeverageUpdateColumn {
  Ratio = 'ratio'
}

export interface LeverageVarPopFields {
   __typename?: 'leverage_var_pop_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageVarPopOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageVarSampFields {
   __typename?: 'leverage_var_samp_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageVarSampOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeverageVarianceFields {
   __typename?: 'leverage_variance_fields',
  ratio?: Maybe<Scalars['Float']>,
}

export interface LeverageVarianceOrderBy {
  ratio?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypes {
   __typename?: 'leverages_to_platform_server_types',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  leverage: Scalars['Int'],
  platform_server_type: PlatformServerType,
  platform_server_type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface LeveragesToPlatformServerTypesAggregate {
   __typename?: 'leverages_to_platform_server_types_aggregate',
  aggregate?: Maybe<LeveragesToPlatformServerTypesAggregateFields>,
  nodes: Array<LeveragesToPlatformServerTypes>,
}

export interface LeveragesToPlatformServerTypesAggregateFields {
   __typename?: 'leverages_to_platform_server_types_aggregate_fields',
  avg?: Maybe<LeveragesToPlatformServerTypesAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LeveragesToPlatformServerTypesMaxFields>,
  min?: Maybe<LeveragesToPlatformServerTypesMinFields>,
  stddev?: Maybe<LeveragesToPlatformServerTypesStddevFields>,
  stddev_pop?: Maybe<LeveragesToPlatformServerTypesStddevPopFields>,
  stddev_samp?: Maybe<LeveragesToPlatformServerTypesStddevSampFields>,
  sum?: Maybe<LeveragesToPlatformServerTypesSumFields>,
  var_pop?: Maybe<LeveragesToPlatformServerTypesVarPopFields>,
  var_samp?: Maybe<LeveragesToPlatformServerTypesVarSampFields>,
  variance?: Maybe<LeveragesToPlatformServerTypesVarianceFields>,
}


export interface LeveragesToPlatformServerTypesAggregateFieldsCountArgs {
  columns?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LeveragesToPlatformServerTypesAggregateOrderBy {
  avg?: Maybe<LeveragesToPlatformServerTypesAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<LeveragesToPlatformServerTypesMaxOrderBy>,
  min?: Maybe<LeveragesToPlatformServerTypesMinOrderBy>,
  stddev?: Maybe<LeveragesToPlatformServerTypesStddevOrderBy>,
  stddev_pop?: Maybe<LeveragesToPlatformServerTypesStddevPopOrderBy>,
  stddev_samp?: Maybe<LeveragesToPlatformServerTypesStddevSampOrderBy>,
  sum?: Maybe<LeveragesToPlatformServerTypesSumOrderBy>,
  var_pop?: Maybe<LeveragesToPlatformServerTypesVarPopOrderBy>,
  var_samp?: Maybe<LeveragesToPlatformServerTypesVarSampOrderBy>,
  variance?: Maybe<LeveragesToPlatformServerTypesVarianceOrderBy>,
}

export interface LeveragesToPlatformServerTypesArrRelInsertInput {
  data: Array<LeveragesToPlatformServerTypesInsertInput>,
  on_conflict?: Maybe<LeveragesToPlatformServerTypesOnConflict>,
}

export interface LeveragesToPlatformServerTypesAvgFields {
   __typename?: 'leverages_to_platform_server_types_avg_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesAvgOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesBoolExp {
  _and?: Maybe<Array<Maybe<LeveragesToPlatformServerTypesBoolExp>>>,
  _not?: Maybe<LeveragesToPlatformServerTypesBoolExp>,
  _or?: Maybe<Array<Maybe<LeveragesToPlatformServerTypesBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  leverage?: Maybe<IntComparisonExp>,
  platform_server_type?: Maybe<PlatformServerTypeBoolExp>,
  platform_server_type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum LeveragesToPlatformServerTypesConstraint {
  LeveragesToPlatformServerPlatformServerTypeIdLeverKey = 'leverages_to_platform_server__platform_server_type_id_lever_key',
  LeveragesToPlatformServerTypesPkey = 'leverages_to_platform_server_types_pkey'
}

export interface LeveragesToPlatformServerTypesIncInput {
  leverage?: Maybe<Scalars['Int']>,
}

export interface LeveragesToPlatformServerTypesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_type?: Maybe<PlatformServerTypeObjRelInsertInput>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LeveragesToPlatformServerTypesMaxFields {
   __typename?: 'leverages_to_platform_server_types_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LeveragesToPlatformServerTypesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesMinFields {
   __typename?: 'leverages_to_platform_server_types_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LeveragesToPlatformServerTypesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesMutationResponse {
   __typename?: 'leverages_to_platform_server_types_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<LeveragesToPlatformServerTypes>,
}

export interface LeveragesToPlatformServerTypesObjRelInsertInput {
  data: LeveragesToPlatformServerTypesInsertInput,
  on_conflict?: Maybe<LeveragesToPlatformServerTypesOnConflict>,
}

export interface LeveragesToPlatformServerTypesOnConflict {
  constraint: LeveragesToPlatformServerTypesConstraint,
  update_columns: Array<LeveragesToPlatformServerTypesUpdateColumn>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>,
}

export interface LeveragesToPlatformServerTypesOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  leverage?: Maybe<OrderBy>,
  platform_server_type?: Maybe<PlatformServerTypeOrderBy>,
  platform_server_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesPkColumnsInput {
  id: Scalars['String'],
}

export enum LeveragesToPlatformServerTypesSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Leverage = 'leverage',
  PlatformServerTypeId = 'platform_server_type_id',
  UpdatedAt = 'updated_at'
}

export interface LeveragesToPlatformServerTypesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  leverage?: Maybe<Scalars['Int']>,
  platform_server_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LeveragesToPlatformServerTypesStddevFields {
   __typename?: 'leverages_to_platform_server_types_stddev_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesStddevOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesStddevPopFields {
   __typename?: 'leverages_to_platform_server_types_stddev_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesStddevPopOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesStddevSampFields {
   __typename?: 'leverages_to_platform_server_types_stddev_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesStddevSampOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesSumFields {
   __typename?: 'leverages_to_platform_server_types_sum_fields',
  leverage?: Maybe<Scalars['Int']>,
}

export interface LeveragesToPlatformServerTypesSumOrderBy {
  leverage?: Maybe<OrderBy>,
}

export enum LeveragesToPlatformServerTypesUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Leverage = 'leverage',
  PlatformServerTypeId = 'platform_server_type_id',
  UpdatedAt = 'updated_at'
}

export interface LeveragesToPlatformServerTypesVarPopFields {
   __typename?: 'leverages_to_platform_server_types_var_pop_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesVarPopOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesVarSampFields {
   __typename?: 'leverages_to_platform_server_types_var_samp_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesVarSampOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LeveragesToPlatformServerTypesVarianceFields {
   __typename?: 'leverages_to_platform_server_types_variance_fields',
  leverage?: Maybe<Scalars['Float']>,
}

export interface LeveragesToPlatformServerTypesVarianceOrderBy {
  leverage?: Maybe<OrderBy>,
}

export interface LiquidAssets {
   __typename?: 'liquid_assets',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface LiquidAssetsAggregate {
   __typename?: 'liquid_assets_aggregate',
  aggregate?: Maybe<LiquidAssetsAggregateFields>,
  nodes: Array<LiquidAssets>,
}

export interface LiquidAssetsAggregateFields {
   __typename?: 'liquid_assets_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LiquidAssetsMaxFields>,
  min?: Maybe<LiquidAssetsMinFields>,
}


export interface LiquidAssetsAggregateFieldsCountArgs {
  columns?: Maybe<Array<LiquidAssetsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LiquidAssetsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<LiquidAssetsMaxOrderBy>,
  min?: Maybe<LiquidAssetsMinOrderBy>,
}

export interface LiquidAssetsArrRelInsertInput {
  data: Array<LiquidAssetsInsertInput>,
  on_conflict?: Maybe<LiquidAssetsOnConflict>,
}

export interface LiquidAssetsBoolExp {
  _and?: Maybe<Array<Maybe<LiquidAssetsBoolExp>>>,
  _not?: Maybe<LiquidAssetsBoolExp>,
  _or?: Maybe<Array<Maybe<LiquidAssetsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum LiquidAssetsConstraint {
  LiquidAssetsNameKey = 'liquid_assets_name_key',
  LiquidAssetsPkey = 'liquid_assets_pkey'
}

export interface LiquidAssetsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LiquidAssetsMaxFields {
   __typename?: 'liquid_assets_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LiquidAssetsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LiquidAssetsMinFields {
   __typename?: 'liquid_assets_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface LiquidAssetsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LiquidAssetsMutationResponse {
   __typename?: 'liquid_assets_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<LiquidAssets>,
}

export interface LiquidAssetsObjRelInsertInput {
  data: LiquidAssetsInsertInput,
  on_conflict?: Maybe<LiquidAssetsOnConflict>,
}

export interface LiquidAssetsOnConflict {
  constraint: LiquidAssetsConstraint,
  update_columns: Array<LiquidAssetsUpdateColumn>,
  where?: Maybe<LiquidAssetsBoolExp>,
}

export interface LiquidAssetsOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface LiquidAssetsPkColumnsInput {
  id: Scalars['String'],
}

export enum LiquidAssetsSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface LiquidAssetsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum LiquidAssetsUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface Locale {
   __typename?: 'locale',
  id: Scalars['String'],
  name: Scalars['String'],
}

export interface LocaleAggregate {
   __typename?: 'locale_aggregate',
  aggregate?: Maybe<LocaleAggregateFields>,
  nodes: Array<Locale>,
}

export interface LocaleAggregateFields {
   __typename?: 'locale_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LocaleMaxFields>,
  min?: Maybe<LocaleMinFields>,
}


export interface LocaleAggregateFieldsCountArgs {
  columns?: Maybe<Array<LocaleSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LocaleAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<LocaleMaxOrderBy>,
  min?: Maybe<LocaleMinOrderBy>,
}

export interface LocaleArrRelInsertInput {
  data: Array<LocaleInsertInput>,
  on_conflict?: Maybe<LocaleOnConflict>,
}

export interface LocaleBoolExp {
  _and?: Maybe<Array<Maybe<LocaleBoolExp>>>,
  _not?: Maybe<LocaleBoolExp>,
  _or?: Maybe<Array<Maybe<LocaleBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
}

export enum LocaleConstraint {
  LocaleNameKey = 'locale_name_key',
  LocalePkey = 'locale_pkey'
}

export interface LocaleInsertInput {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface LocaleMaxFields {
   __typename?: 'locale_max_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface LocaleMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface LocaleMinFields {
   __typename?: 'locale_min_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface LocaleMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface LocaleMutationResponse {
   __typename?: 'locale_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Locale>,
}

export interface LocaleObjRelInsertInput {
  data: LocaleInsertInput,
  on_conflict?: Maybe<LocaleOnConflict>,
}

export interface LocaleOnConflict {
  constraint: LocaleConstraint,
  update_columns: Array<LocaleUpdateColumn>,
  where?: Maybe<LocaleBoolExp>,
}

export interface LocaleOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface LocalePkColumnsInput {
  id: Scalars['String'],
}

export enum LocaleSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface LocaleSetInput {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export enum LocaleUpdateColumn {
  Id = 'id',
  Name = 'name'
}

export interface LocalesToOrganizations {
   __typename?: 'locales_to_organizations',
  is_default: Scalars['Boolean'],
  is_enabled: Scalars['Boolean'],
  locale?: Maybe<Locale>,
  locale_id: Scalars['String'],
  organization?: Maybe<Organization>,
  organization_id: Scalars['String'],
}

export interface LocalesToOrganizationsAggregate {
   __typename?: 'locales_to_organizations_aggregate',
  aggregate?: Maybe<LocalesToOrganizationsAggregateFields>,
  nodes: Array<LocalesToOrganizations>,
}

export interface LocalesToOrganizationsAggregateFields {
   __typename?: 'locales_to_organizations_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LocalesToOrganizationsMaxFields>,
  min?: Maybe<LocalesToOrganizationsMinFields>,
}


export interface LocalesToOrganizationsAggregateFieldsCountArgs {
  columns?: Maybe<Array<LocalesToOrganizationsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LocalesToOrganizationsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<LocalesToOrganizationsMaxOrderBy>,
  min?: Maybe<LocalesToOrganizationsMinOrderBy>,
}

export interface LocalesToOrganizationsArrRelInsertInput {
  data: Array<LocalesToOrganizationsInsertInput>,
  on_conflict?: Maybe<LocalesToOrganizationsOnConflict>,
}

export interface LocalesToOrganizationsBoolExp {
  _and?: Maybe<Array<Maybe<LocalesToOrganizationsBoolExp>>>,
  _not?: Maybe<LocalesToOrganizationsBoolExp>,
  _or?: Maybe<Array<Maybe<LocalesToOrganizationsBoolExp>>>,
  is_default?: Maybe<BooleanComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  locale?: Maybe<LocaleBoolExp>,
  locale_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
}

export enum LocalesToOrganizationsConstraint {
  LocalesToOrganizationsPkey = 'locales_to_organizations_pkey'
}

export interface LocalesToOrganizationsInsertInput {
  is_default?: Maybe<Scalars['Boolean']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  locale?: Maybe<LocaleObjRelInsertInput>,
  locale_id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface LocalesToOrganizationsMaxFields {
   __typename?: 'locales_to_organizations_max_fields',
  locale_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface LocalesToOrganizationsMaxOrderBy {
  locale_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export interface LocalesToOrganizationsMinFields {
   __typename?: 'locales_to_organizations_min_fields',
  locale_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
}

export interface LocalesToOrganizationsMinOrderBy {
  locale_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export interface LocalesToOrganizationsMutationResponse {
   __typename?: 'locales_to_organizations_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<LocalesToOrganizations>,
}

export interface LocalesToOrganizationsObjRelInsertInput {
  data: LocalesToOrganizationsInsertInput,
  on_conflict?: Maybe<LocalesToOrganizationsOnConflict>,
}

export interface LocalesToOrganizationsOnConflict {
  constraint: LocalesToOrganizationsConstraint,
  update_columns: Array<LocalesToOrganizationsUpdateColumn>,
  where?: Maybe<LocalesToOrganizationsBoolExp>,
}

export interface LocalesToOrganizationsOrderBy {
  is_default?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  locale?: Maybe<LocaleOrderBy>,
  locale_id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
}

export interface LocalesToOrganizationsPkColumnsInput {
  locale_id: Scalars['String'],
  organization_id: Scalars['String'],
}

export enum LocalesToOrganizationsSelectColumn {
  IsDefault = 'is_default',
  IsEnabled = 'is_enabled',
  LocaleId = 'locale_id',
  OrganizationId = 'organization_id'
}

export interface LocalesToOrganizationsSetInput {
  is_default?: Maybe<Scalars['Boolean']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  locale_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
}

export enum LocalesToOrganizationsUpdateColumn {
  IsDefault = 'is_default',
  IsEnabled = 'is_enabled',
  LocaleId = 'locale_id',
  OrganizationId = 'organization_id'
}

export interface LookupView {
   __typename?: 'lookup_view',
  id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface LookupViewAggregate {
   __typename?: 'lookup_view_aggregate',
  aggregate?: Maybe<LookupViewAggregateFields>,
  nodes: Array<LookupView>,
}

export interface LookupViewAggregateFields {
   __typename?: 'lookup_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<LookupViewMaxFields>,
  min?: Maybe<LookupViewMinFields>,
}


export interface LookupViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<LookupViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface LookupViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<LookupViewMaxOrderBy>,
  min?: Maybe<LookupViewMinOrderBy>,
}

export interface LookupViewBoolExp {
  _and?: Maybe<Array<Maybe<LookupViewBoolExp>>>,
  _not?: Maybe<LookupViewBoolExp>,
  _or?: Maybe<Array<Maybe<LookupViewBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export interface LookupViewMaxFields {
   __typename?: 'lookup_view_max_fields',
  id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface LookupViewMaxOrderBy {
  id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface LookupViewMinFields {
   __typename?: 'lookup_view_min_fields',
  id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface LookupViewMinOrderBy {
  id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface LookupViewOrderBy {
  id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export enum LookupViewSelectColumn {
  Id = 'id',
  Value = 'value'
}

export interface MarginType {
   __typename?: 'margin_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface MarginTypeAggregate {
   __typename?: 'margin_type_aggregate',
  aggregate?: Maybe<MarginTypeAggregateFields>,
  nodes: Array<MarginType>,
}

export interface MarginTypeAggregateFields {
   __typename?: 'margin_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MarginTypeMaxFields>,
  min?: Maybe<MarginTypeMinFields>,
}


export interface MarginTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<MarginTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MarginTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MarginTypeMaxOrderBy>,
  min?: Maybe<MarginTypeMinOrderBy>,
}

export interface MarginTypeArrRelInsertInput {
  data: Array<MarginTypeInsertInput>,
  on_conflict?: Maybe<MarginTypeOnConflict>,
}

export interface MarginTypeBoolExp {
  _and?: Maybe<Array<Maybe<MarginTypeBoolExp>>>,
  _not?: Maybe<MarginTypeBoolExp>,
  _or?: Maybe<Array<Maybe<MarginTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum MarginTypeConstraint {
  MarginTypeNameKey = 'margin_type_name_key',
  MarginTypePkey = 'margin_type_pkey'
}

export interface MarginTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface MarginTypeMaxFields {
   __typename?: 'margin_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface MarginTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface MarginTypeMinFields {
   __typename?: 'margin_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface MarginTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface MarginTypeMutationResponse {
   __typename?: 'margin_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MarginType>,
}

export interface MarginTypeObjRelInsertInput {
  data: MarginTypeInsertInput,
  on_conflict?: Maybe<MarginTypeOnConflict>,
}

export interface MarginTypeOnConflict {
  constraint: MarginTypeConstraint,
  update_columns: Array<MarginTypeUpdateColumn>,
  where?: Maybe<MarginTypeBoolExp>,
}

export interface MarginTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface MarginTypePkColumnsInput {
  id: Scalars['String'],
}

export enum MarginTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface MarginTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum MarginTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface MasterAdminClientPermission {
   __typename?: 'master_admin_client_permission',
  admin_account_list_client?: Maybe<Scalars['String']>,
  admin_account_settings_client?: Maybe<Scalars['String']>,
  admin_activity_list_client?: Maybe<Scalars['String']>,
  admin_approve_account_client?: Maybe<Scalars['String']>,
  admin_approve_client?: Maybe<Scalars['String']>,
  admin_approve_status_client?: Maybe<Scalars['String']>,
  admin_category_client?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_credit_transaction_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_deposit_transaction_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_flag_client?: Maybe<Scalars['String']>,
  admin_manage_account_client?: Maybe<Scalars['String']>,
  admin_notes_client?: Maybe<Scalars['String']>,
  admin_representative_client?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  admin_risk_client?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_client?: Maybe<Scalars['String']>,
  admin_tag_client?: Maybe<Scalars['String']>,
  admin_transaction_list_client?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  admin_transfer_transaction_client?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manager_account_settings_client?: Maybe<Scalars['String']>,
  manager_approve_status_client?: Maybe<Scalars['String']>,
  manager_category_client?: Maybe<Scalars['String']>,
  manager_client_list?: Maybe<Scalars['String']>,
  manager_credit_transaction_client?: Maybe<Scalars['String']>,
  manager_deposit_transaction_client?: Maybe<Scalars['String']>,
  manager_flag_client?: Maybe<Scalars['String']>,
  manager_manage_account_client?: Maybe<Scalars['String']>,
  manager_notes_client?: Maybe<Scalars['String']>,
  manager_representative_client?: Maybe<Scalars['String']>,
  manager_risk_client?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_client?: Maybe<Scalars['String']>,
  manager_tag_client?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  manager_transfer_transaction_client?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  readonly_credit_transaction_client?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_client?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  restricted_account_list_client?: Maybe<Scalars['String']>,
  restricted_account_settings_client?: Maybe<Scalars['String']>,
  restricted_activity_list_client?: Maybe<Scalars['String']>,
  restricted_approve_account_client?: Maybe<Scalars['String']>,
  restricted_approve_client?: Maybe<Scalars['String']>,
  restricted_approve_status_client?: Maybe<Scalars['String']>,
  restricted_category_client?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_credit_transaction_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_flag_client?: Maybe<Scalars['String']>,
  restricted_manage_account_client?: Maybe<Scalars['String']>,
  restricted_notes_client?: Maybe<Scalars['String']>,
  restricted_representative_client?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restricted_risk_client?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_client?: Maybe<Scalars['String']>,
  restricted_tag_client?: Maybe<Scalars['String']>,
  restricted_transaction_list_client?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_client?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_credit_transaction_client?: Maybe<Scalars['String']>,
  user_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_manage_account_client?: Maybe<Scalars['String']>,
  user_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_client?: Maybe<Scalars['String']>,
}

export interface MasterAdminClientPermissionAggregate {
   __typename?: 'master_admin_client_permission_aggregate',
  aggregate?: Maybe<MasterAdminClientPermissionAggregateFields>,
  nodes: Array<MasterAdminClientPermission>,
}

export interface MasterAdminClientPermissionAggregateFields {
   __typename?: 'master_admin_client_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterAdminClientPermissionMaxFields>,
  min?: Maybe<MasterAdminClientPermissionMinFields>,
}


export interface MasterAdminClientPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterAdminClientPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterAdminClientPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterAdminClientPermissionMaxOrderBy>,
  min?: Maybe<MasterAdminClientPermissionMinOrderBy>,
}

export interface MasterAdminClientPermissionArrRelInsertInput {
  data: Array<MasterAdminClientPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminClientPermissionOnConflict>,
}

export interface MasterAdminClientPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterAdminClientPermissionBoolExp>>>,
  _not?: Maybe<MasterAdminClientPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterAdminClientPermissionBoolExp>>>,
  admin_account_list_client?: Maybe<StringComparisonExp>,
  admin_account_settings_client?: Maybe<StringComparisonExp>,
  admin_activity_list_client?: Maybe<StringComparisonExp>,
  admin_approve_account_client?: Maybe<StringComparisonExp>,
  admin_approve_client?: Maybe<StringComparisonExp>,
  admin_approve_status_client?: Maybe<StringComparisonExp>,
  admin_category_client?: Maybe<StringComparisonExp>,
  admin_client_list?: Maybe<StringComparisonExp>,
  admin_create_client?: Maybe<StringComparisonExp>,
  admin_credit_transaction_client?: Maybe<StringComparisonExp>,
  admin_delete_client?: Maybe<StringComparisonExp>,
  admin_deposit_transaction_client?: Maybe<StringComparisonExp>,
  admin_edit_client?: Maybe<StringComparisonExp>,
  admin_flag_client?: Maybe<StringComparisonExp>,
  admin_manage_account_client?: Maybe<StringComparisonExp>,
  admin_notes_client?: Maybe<StringComparisonExp>,
  admin_representative_client?: Maybe<StringComparisonExp>,
  admin_restrict_negative_balance_client?: Maybe<StringComparisonExp>,
  admin_risk_client?: Maybe<StringComparisonExp>,
  admin_service_fee_transaction_client?: Maybe<StringComparisonExp>,
  admin_tag_client?: Maybe<StringComparisonExp>,
  admin_transaction_list_client?: Maybe<StringComparisonExp>,
  admin_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  admin_transfer_transaction_client?: Maybe<StringComparisonExp>,
  admin_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_account_settings_client?: Maybe<StringComparisonExp>,
  manager_approve_status_client?: Maybe<StringComparisonExp>,
  manager_category_client?: Maybe<StringComparisonExp>,
  manager_client_list?: Maybe<StringComparisonExp>,
  manager_credit_transaction_client?: Maybe<StringComparisonExp>,
  manager_deposit_transaction_client?: Maybe<StringComparisonExp>,
  manager_flag_client?: Maybe<StringComparisonExp>,
  manager_manage_account_client?: Maybe<StringComparisonExp>,
  manager_notes_client?: Maybe<StringComparisonExp>,
  manager_representative_client?: Maybe<StringComparisonExp>,
  manager_risk_client?: Maybe<StringComparisonExp>,
  manager_service_fee_transaction_client?: Maybe<StringComparisonExp>,
  manager_tag_client?: Maybe<StringComparisonExp>,
  manager_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  manager_transfer_transaction_client?: Maybe<StringComparisonExp>,
  manager_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  readonly_credit_transaction_client?: Maybe<StringComparisonExp>,
  readonly_deposit_transaction_client?: Maybe<StringComparisonExp>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  readonly_transfer_transaction_client?: Maybe<StringComparisonExp>,
  readonly_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  restricted_account_list_client?: Maybe<StringComparisonExp>,
  restricted_account_settings_client?: Maybe<StringComparisonExp>,
  restricted_activity_list_client?: Maybe<StringComparisonExp>,
  restricted_approve_account_client?: Maybe<StringComparisonExp>,
  restricted_approve_client?: Maybe<StringComparisonExp>,
  restricted_approve_status_client?: Maybe<StringComparisonExp>,
  restricted_category_client?: Maybe<StringComparisonExp>,
  restricted_client_list?: Maybe<StringComparisonExp>,
  restricted_create_client?: Maybe<StringComparisonExp>,
  restricted_credit_transaction_client?: Maybe<StringComparisonExp>,
  restricted_delete_client?: Maybe<StringComparisonExp>,
  restricted_deposit_transaction_client?: Maybe<StringComparisonExp>,
  restricted_edit_client?: Maybe<StringComparisonExp>,
  restricted_flag_client?: Maybe<StringComparisonExp>,
  restricted_manage_account_client?: Maybe<StringComparisonExp>,
  restricted_notes_client?: Maybe<StringComparisonExp>,
  restricted_representative_client?: Maybe<StringComparisonExp>,
  restricted_restrict_negative_balance_client?: Maybe<StringComparisonExp>,
  restricted_risk_client?: Maybe<StringComparisonExp>,
  restricted_service_fee_transaction_client?: Maybe<StringComparisonExp>,
  restricted_tag_client?: Maybe<StringComparisonExp>,
  restricted_transaction_list_client?: Maybe<StringComparisonExp>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  restricted_transfer_transaction_client?: Maybe<StringComparisonExp>,
  restricted_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  user_credit_transaction_client?: Maybe<StringComparisonExp>,
  user_deposit_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_credit_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_deposit_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_service_fee_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_transfer_transaction_client?: Maybe<StringComparisonExp>,
  user_limited_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
  user_manage_account_client?: Maybe<StringComparisonExp>,
  user_service_fee_transaction_client?: Maybe<StringComparisonExp>,
  user_transfer_between_profiles_transaction_client?: Maybe<StringComparisonExp>,
  user_transfer_transaction_client?: Maybe<StringComparisonExp>,
  user_withdrawal_transaction_client?: Maybe<StringComparisonExp>,
}

export enum MasterAdminClientPermissionConstraint {
  MasterAdminClientPermissioAdminAccountSettingsClientKey = 'master_admin_client_permissio_admin_account_settings_client_key',
  MasterAdminClientPermissioAdminCreditTransactionClieKey = 'master_admin_client_permissio_admin_credit_transaction_clie_key',
  MasterAdminClientPermissioAdminDepositTransactionCliKey = 'master_admin_client_permissio_admin_deposit_transaction_cli_key',
  MasterAdminClientPermissioAdminRestrictNegativeBalanKey = 'master_admin_client_permissio_admin_restrict_negative_balan_key',
  MasterAdminClientPermissioAdminServiceFeeTransactionKey = 'master_admin_client_permissio_admin_service_fee_transaction_key',
  MasterAdminClientPermissioAdminTransactionListClientKey = 'master_admin_client_permissio_admin_transaction_list_client_key',
  MasterAdminClientPermissioAdminTransferBetweenProfilKey = 'master_admin_client_permissio_admin_transfer_between_profil_key',
  MasterAdminClientPermissioAdminTransferTransactionClKey = 'master_admin_client_permissio_admin_transfer_transaction_cl_key',
  MasterAdminClientPermissioAdminWithdrawalTransactionKey = 'master_admin_client_permissio_admin_withdrawal_transaction__key',
  MasterAdminClientPermissioManagerAccountSettingsClieKey = 'master_admin_client_permissio_manager_account_settings_clie_key',
  MasterAdminClientPermissioManagerApproveStatusClientKey = 'master_admin_client_permissio_manager_approve_status_client_key',
  MasterAdminClientPermissioManagerCreditTransactionClKey = 'master_admin_client_permissio_manager_credit_transaction_cl_key',
  MasterAdminClientPermissioManagerDepositTransactionCKey = 'master_admin_client_permissio_manager_deposit_transaction_c_key',
  MasterAdminClientPermissioManagerManageAccountClientKey = 'master_admin_client_permissio_manager_manage_account_client_key',
  MasterAdminClientPermissioManagerRepresentativeClientKey = 'master_admin_client_permissio_manager_representative_client_key',
  MasterAdminClientPermissioManagerServiceFeeTransactiKey = 'master_admin_client_permissio_manager_service_fee_transacti_key',
  MasterAdminClientPermissioManagerTransferBetweenProfKey = 'master_admin_client_permissio_manager_transfer_between_prof_key',
  MasterAdminClientPermissioManagerTransferTransactionKey = 'master_admin_client_permissio_manager_transfer_transaction__key',
  MasterAdminClientPermissioManagerWithdrawalTransactioKey = 'master_admin_client_permissio_manager_withdrawal_transactio_key',
  MasterAdminClientPermissioReadonlyCreditTransactionCKey = 'master_admin_client_permissio_readonly_credit_transaction_c_key',
  MasterAdminClientPermissioReadonlyDepositTransactionKey = 'master_admin_client_permissio_readonly_deposit_transaction__key',
  MasterAdminClientPermissioReadonlyTransferBetweenProKey = 'master_admin_client_permissio_readonly_transfer_between_pro_key',
  MasterAdminClientPermissioReadonlyTransferTransactionKey = 'master_admin_client_permissio_readonly_transfer_transaction_key',
  MasterAdminClientPermissioReadonlyWithdrawalTransactiKey = 'master_admin_client_permissio_readonly_withdrawal_transacti_key',
  MasterAdminClientPermissioRestrictedAccountListClienKey = 'master_admin_client_permissio_restricted_account_list_clien_key',
  MasterAdminClientPermissioRestrictedAccountSettingsCKey = 'master_admin_client_permissio_restricted_account_settings_c_key',
  MasterAdminClientPermissioRestrictedActivityListClieKey = 'master_admin_client_permissio_restricted_activity_list_clie_key',
  MasterAdminClientPermissioRestrictedApproveAccountClKey = 'master_admin_client_permissio_restricted_approve_account_cl_key',
  MasterAdminClientPermissioRestrictedApproveStatusCliKey = 'master_admin_client_permissio_restricted_approve_status_cli_key',
  MasterAdminClientPermissioRestrictedCreditTransactionKey = 'master_admin_client_permissio_restricted_credit_transaction_key',
  MasterAdminClientPermissioRestrictedDepositTransactioKey = 'master_admin_client_permissio_restricted_deposit_transactio_key',
  MasterAdminClientPermissioRestrictedManageAccountCliKey = 'master_admin_client_permissio_restricted_manage_account_cli_key',
  MasterAdminClientPermissioRestrictedRepresentativeCliKey = 'master_admin_client_permissio_restricted_representative_cli_key',
  MasterAdminClientPermissioRestrictedRestrictNegativeKey = 'master_admin_client_permissio_restricted_restrict_negative__key',
  MasterAdminClientPermissioRestrictedServiceFeeTransaKey = 'master_admin_client_permissio_restricted_service_fee_transa_key',
  MasterAdminClientPermissioRestrictedTransactionListCKey = 'master_admin_client_permissio_restricted_transaction_list_c_key',
  MasterAdminClientPermissioRestrictedTransferBetweenPKey = 'master_admin_client_permissio_restricted_transfer_between_p_key',
  MasterAdminClientPermissioRestrictedTransferTransactiKey = 'master_admin_client_permissio_restricted_transfer_transacti_key',
  MasterAdminClientPermissioRestrictedWithdrawalTransacKey = 'master_admin_client_permissio_restricted_withdrawal_transac_key',
  MasterAdminClientPermissioUserCreditTransactionClienKey = 'master_admin_client_permissio_user_credit_transaction_clien_key',
  MasterAdminClientPermissioUserDepositTransactionClieKey = 'master_admin_client_permissio_user_deposit_transaction_clie_key',
  MasterAdminClientPermissioUserLimitedCreditTransactiKey = 'master_admin_client_permissio_user_limited_credit_transacti_key',
  MasterAdminClientPermissioUserLimitedDepositTransactKey = 'master_admin_client_permissio_user_limited_deposit_transact_key',
  MasterAdminClientPermissioUserLimitedServiceFeeTranKey = 'master_admin_client_permissio_user_limited_service_fee_tran_key',
  MasterAdminClientPermissioUserLimitedTransferBetweenKey = 'master_admin_client_permissio_user_limited_transfer_between_key',
  MasterAdminClientPermissioUserLimitedTransferTransacKey = 'master_admin_client_permissio_user_limited_transfer_transac_key',
  MasterAdminClientPermissioUserLimitedWithdrawalTransKey = 'master_admin_client_permissio_user_limited_withdrawal_trans_key',
  MasterAdminClientPermissioUserServiceFeeTransactionKey = 'master_admin_client_permissio_user_service_fee_transaction__key',
  MasterAdminClientPermissioUserTransferBetweenProfileKey = 'master_admin_client_permissio_user_transfer_between_profile_key',
  MasterAdminClientPermissioUserTransferTransactionCliKey = 'master_admin_client_permissio_user_transfer_transaction_cli_key',
  MasterAdminClientPermissioUserWithdrawalTransactionCKey = 'master_admin_client_permissio_user_withdrawal_transaction_c_key',
  MasterAdminClientPermissionAdminAccountListClientKey = 'master_admin_client_permission_admin_account_list_client_key',
  MasterAdminClientPermissionAdminActivityListClientKey = 'master_admin_client_permission_admin_activity_list_client_key',
  MasterAdminClientPermissionAdminApproveAccountClientKey = 'master_admin_client_permission_admin_approve_account_client_key',
  MasterAdminClientPermissionAdminApproveClientKey = 'master_admin_client_permission_admin_approve_client_key',
  MasterAdminClientPermissionAdminApproveStatusClientKey = 'master_admin_client_permission_admin_approve_status_client_key',
  MasterAdminClientPermissionAdminCategoryClientKey = 'master_admin_client_permission_admin_category_client_key',
  MasterAdminClientPermissionAdminClientListKey = 'master_admin_client_permission_admin_client_list_key',
  MasterAdminClientPermissionAdminCreateClientKey = 'master_admin_client_permission_admin_create_client_key',
  MasterAdminClientPermissionAdminDeleteClientKey = 'master_admin_client_permission_admin_delete_client_key',
  MasterAdminClientPermissionAdminEditClientKey = 'master_admin_client_permission_admin_edit_client_key',
  MasterAdminClientPermissionAdminFlagClientKey = 'master_admin_client_permission_admin_flag_client_key',
  MasterAdminClientPermissionAdminManageAccountClientKey = 'master_admin_client_permission_admin_manage_account_client_key',
  MasterAdminClientPermissionAdminNotesClientKey = 'master_admin_client_permission_admin_notes_client_key',
  MasterAdminClientPermissionAdminRepresentativeClientKey = 'master_admin_client_permission_admin_representative_client_key',
  MasterAdminClientPermissionAdminRiskClientKey = 'master_admin_client_permission_admin_risk_client_key',
  MasterAdminClientPermissionAdminTagClientKey = 'master_admin_client_permission_admin_tag_client_key',
  MasterAdminClientPermissionManagerCategoryClientKey = 'master_admin_client_permission_manager_category_client_key',
  MasterAdminClientPermissionManagerClientListKey = 'master_admin_client_permission_manager_client_list_key',
  MasterAdminClientPermissionManagerFlagClientKey = 'master_admin_client_permission_manager_flag_client_key',
  MasterAdminClientPermissionManagerNotesClientKey = 'master_admin_client_permission_manager_notes_client_key',
  MasterAdminClientPermissionManagerRiskClientKey = 'master_admin_client_permission_manager_risk_client_key',
  MasterAdminClientPermissionManagerTagClientKey = 'master_admin_client_permission_manager_tag_client_key',
  MasterAdminClientPermissionPkey = 'master_admin_client_permission_pkey',
  MasterAdminClientPermissionRestrictedApproveClientKey = 'master_admin_client_permission_restricted_approve_client_key',
  MasterAdminClientPermissionRestrictedCategoryClientKey = 'master_admin_client_permission_restricted_category_client_key',
  MasterAdminClientPermissionRestrictedClientListKey = 'master_admin_client_permission_restricted_client_list_key',
  MasterAdminClientPermissionRestrictedCreateClientKey = 'master_admin_client_permission_restricted_create_client_key',
  MasterAdminClientPermissionRestrictedDeleteClientKey = 'master_admin_client_permission_restricted_delete_client_key',
  MasterAdminClientPermissionRestrictedEditClientKey = 'master_admin_client_permission_restricted_edit_client_key',
  MasterAdminClientPermissionRestrictedFlagClientKey = 'master_admin_client_permission_restricted_flag_client_key',
  MasterAdminClientPermissionRestrictedNotesClientKey = 'master_admin_client_permission_restricted_notes_client_key',
  MasterAdminClientPermissionRestrictedRiskClientKey = 'master_admin_client_permission_restricted_risk_client_key',
  MasterAdminClientPermissionRestrictedTagClientKey = 'master_admin_client_permission_restricted_tag_client_key',
  MasterAdminClientPermissionUserManageAccountClientKey = 'master_admin_client_permission_user_manage_account_client_key'
}

export interface MasterAdminClientPermissionInsertInput {
  admin_account_list_client?: Maybe<Scalars['String']>,
  admin_account_settings_client?: Maybe<Scalars['String']>,
  admin_activity_list_client?: Maybe<Scalars['String']>,
  admin_approve_account_client?: Maybe<Scalars['String']>,
  admin_approve_client?: Maybe<Scalars['String']>,
  admin_approve_status_client?: Maybe<Scalars['String']>,
  admin_category_client?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_credit_transaction_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_deposit_transaction_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_flag_client?: Maybe<Scalars['String']>,
  admin_manage_account_client?: Maybe<Scalars['String']>,
  admin_notes_client?: Maybe<Scalars['String']>,
  admin_representative_client?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  admin_risk_client?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_client?: Maybe<Scalars['String']>,
  admin_tag_client?: Maybe<Scalars['String']>,
  admin_transaction_list_client?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  admin_transfer_transaction_client?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_client?: Maybe<Scalars['String']>,
  manager_approve_status_client?: Maybe<Scalars['String']>,
  manager_category_client?: Maybe<Scalars['String']>,
  manager_client_list?: Maybe<Scalars['String']>,
  manager_credit_transaction_client?: Maybe<Scalars['String']>,
  manager_deposit_transaction_client?: Maybe<Scalars['String']>,
  manager_flag_client?: Maybe<Scalars['String']>,
  manager_manage_account_client?: Maybe<Scalars['String']>,
  manager_notes_client?: Maybe<Scalars['String']>,
  manager_representative_client?: Maybe<Scalars['String']>,
  manager_risk_client?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_client?: Maybe<Scalars['String']>,
  manager_tag_client?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  manager_transfer_transaction_client?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  readonly_credit_transaction_client?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_client?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  restricted_account_list_client?: Maybe<Scalars['String']>,
  restricted_account_settings_client?: Maybe<Scalars['String']>,
  restricted_activity_list_client?: Maybe<Scalars['String']>,
  restricted_approve_account_client?: Maybe<Scalars['String']>,
  restricted_approve_client?: Maybe<Scalars['String']>,
  restricted_approve_status_client?: Maybe<Scalars['String']>,
  restricted_category_client?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_credit_transaction_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_flag_client?: Maybe<Scalars['String']>,
  restricted_manage_account_client?: Maybe<Scalars['String']>,
  restricted_notes_client?: Maybe<Scalars['String']>,
  restricted_representative_client?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restricted_risk_client?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_client?: Maybe<Scalars['String']>,
  restricted_tag_client?: Maybe<Scalars['String']>,
  restricted_transaction_list_client?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_client?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_credit_transaction_client?: Maybe<Scalars['String']>,
  user_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_manage_account_client?: Maybe<Scalars['String']>,
  user_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_client?: Maybe<Scalars['String']>,
}

export interface MasterAdminClientPermissionMaxFields {
   __typename?: 'master_admin_client_permission_max_fields',
  admin_account_list_client?: Maybe<Scalars['String']>,
  admin_account_settings_client?: Maybe<Scalars['String']>,
  admin_activity_list_client?: Maybe<Scalars['String']>,
  admin_approve_account_client?: Maybe<Scalars['String']>,
  admin_approve_client?: Maybe<Scalars['String']>,
  admin_approve_status_client?: Maybe<Scalars['String']>,
  admin_category_client?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_credit_transaction_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_deposit_transaction_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_flag_client?: Maybe<Scalars['String']>,
  admin_manage_account_client?: Maybe<Scalars['String']>,
  admin_notes_client?: Maybe<Scalars['String']>,
  admin_representative_client?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  admin_risk_client?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_client?: Maybe<Scalars['String']>,
  admin_tag_client?: Maybe<Scalars['String']>,
  admin_transaction_list_client?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  admin_transfer_transaction_client?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_client?: Maybe<Scalars['String']>,
  manager_approve_status_client?: Maybe<Scalars['String']>,
  manager_category_client?: Maybe<Scalars['String']>,
  manager_client_list?: Maybe<Scalars['String']>,
  manager_credit_transaction_client?: Maybe<Scalars['String']>,
  manager_deposit_transaction_client?: Maybe<Scalars['String']>,
  manager_flag_client?: Maybe<Scalars['String']>,
  manager_manage_account_client?: Maybe<Scalars['String']>,
  manager_notes_client?: Maybe<Scalars['String']>,
  manager_representative_client?: Maybe<Scalars['String']>,
  manager_risk_client?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_client?: Maybe<Scalars['String']>,
  manager_tag_client?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  manager_transfer_transaction_client?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  readonly_credit_transaction_client?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_client?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  restricted_account_list_client?: Maybe<Scalars['String']>,
  restricted_account_settings_client?: Maybe<Scalars['String']>,
  restricted_activity_list_client?: Maybe<Scalars['String']>,
  restricted_approve_account_client?: Maybe<Scalars['String']>,
  restricted_approve_client?: Maybe<Scalars['String']>,
  restricted_approve_status_client?: Maybe<Scalars['String']>,
  restricted_category_client?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_credit_transaction_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_flag_client?: Maybe<Scalars['String']>,
  restricted_manage_account_client?: Maybe<Scalars['String']>,
  restricted_notes_client?: Maybe<Scalars['String']>,
  restricted_representative_client?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restricted_risk_client?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_client?: Maybe<Scalars['String']>,
  restricted_tag_client?: Maybe<Scalars['String']>,
  restricted_transaction_list_client?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_client?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_credit_transaction_client?: Maybe<Scalars['String']>,
  user_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_manage_account_client?: Maybe<Scalars['String']>,
  user_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_client?: Maybe<Scalars['String']>,
}

export interface MasterAdminClientPermissionMaxOrderBy {
  admin_account_list_client?: Maybe<OrderBy>,
  admin_account_settings_client?: Maybe<OrderBy>,
  admin_activity_list_client?: Maybe<OrderBy>,
  admin_approve_account_client?: Maybe<OrderBy>,
  admin_approve_client?: Maybe<OrderBy>,
  admin_approve_status_client?: Maybe<OrderBy>,
  admin_category_client?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_credit_transaction_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_deposit_transaction_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_flag_client?: Maybe<OrderBy>,
  admin_manage_account_client?: Maybe<OrderBy>,
  admin_notes_client?: Maybe<OrderBy>,
  admin_representative_client?: Maybe<OrderBy>,
  admin_restrict_negative_balance_client?: Maybe<OrderBy>,
  admin_risk_client?: Maybe<OrderBy>,
  admin_service_fee_transaction_client?: Maybe<OrderBy>,
  admin_tag_client?: Maybe<OrderBy>,
  admin_transaction_list_client?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  admin_transfer_transaction_client?: Maybe<OrderBy>,
  admin_withdrawal_transaction_client?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_client?: Maybe<OrderBy>,
  manager_approve_status_client?: Maybe<OrderBy>,
  manager_category_client?: Maybe<OrderBy>,
  manager_client_list?: Maybe<OrderBy>,
  manager_credit_transaction_client?: Maybe<OrderBy>,
  manager_deposit_transaction_client?: Maybe<OrderBy>,
  manager_flag_client?: Maybe<OrderBy>,
  manager_manage_account_client?: Maybe<OrderBy>,
  manager_notes_client?: Maybe<OrderBy>,
  manager_representative_client?: Maybe<OrderBy>,
  manager_risk_client?: Maybe<OrderBy>,
  manager_service_fee_transaction_client?: Maybe<OrderBy>,
  manager_tag_client?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  manager_transfer_transaction_client?: Maybe<OrderBy>,
  manager_withdrawal_transaction_client?: Maybe<OrderBy>,
  readonly_credit_transaction_client?: Maybe<OrderBy>,
  readonly_deposit_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_transaction_client?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_client?: Maybe<OrderBy>,
  restricted_account_list_client?: Maybe<OrderBy>,
  restricted_account_settings_client?: Maybe<OrderBy>,
  restricted_activity_list_client?: Maybe<OrderBy>,
  restricted_approve_account_client?: Maybe<OrderBy>,
  restricted_approve_client?: Maybe<OrderBy>,
  restricted_approve_status_client?: Maybe<OrderBy>,
  restricted_category_client?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_credit_transaction_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_deposit_transaction_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_flag_client?: Maybe<OrderBy>,
  restricted_manage_account_client?: Maybe<OrderBy>,
  restricted_notes_client?: Maybe<OrderBy>,
  restricted_representative_client?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_client?: Maybe<OrderBy>,
  restricted_risk_client?: Maybe<OrderBy>,
  restricted_service_fee_transaction_client?: Maybe<OrderBy>,
  restricted_tag_client?: Maybe<OrderBy>,
  restricted_transaction_list_client?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  restricted_transfer_transaction_client?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_credit_transaction_client?: Maybe<OrderBy>,
  user_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_credit_transaction_client?: Maybe<OrderBy>,
  user_limited_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_transaction_client?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_manage_account_client?: Maybe<OrderBy>,
  user_service_fee_transaction_client?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_transfer_transaction_client?: Maybe<OrderBy>,
  user_withdrawal_transaction_client?: Maybe<OrderBy>,
}

export interface MasterAdminClientPermissionMinFields {
   __typename?: 'master_admin_client_permission_min_fields',
  admin_account_list_client?: Maybe<Scalars['String']>,
  admin_account_settings_client?: Maybe<Scalars['String']>,
  admin_activity_list_client?: Maybe<Scalars['String']>,
  admin_approve_account_client?: Maybe<Scalars['String']>,
  admin_approve_client?: Maybe<Scalars['String']>,
  admin_approve_status_client?: Maybe<Scalars['String']>,
  admin_category_client?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_credit_transaction_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_deposit_transaction_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_flag_client?: Maybe<Scalars['String']>,
  admin_manage_account_client?: Maybe<Scalars['String']>,
  admin_notes_client?: Maybe<Scalars['String']>,
  admin_representative_client?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  admin_risk_client?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_client?: Maybe<Scalars['String']>,
  admin_tag_client?: Maybe<Scalars['String']>,
  admin_transaction_list_client?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  admin_transfer_transaction_client?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_client?: Maybe<Scalars['String']>,
  manager_approve_status_client?: Maybe<Scalars['String']>,
  manager_category_client?: Maybe<Scalars['String']>,
  manager_client_list?: Maybe<Scalars['String']>,
  manager_credit_transaction_client?: Maybe<Scalars['String']>,
  manager_deposit_transaction_client?: Maybe<Scalars['String']>,
  manager_flag_client?: Maybe<Scalars['String']>,
  manager_manage_account_client?: Maybe<Scalars['String']>,
  manager_notes_client?: Maybe<Scalars['String']>,
  manager_representative_client?: Maybe<Scalars['String']>,
  manager_risk_client?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_client?: Maybe<Scalars['String']>,
  manager_tag_client?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  manager_transfer_transaction_client?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  readonly_credit_transaction_client?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_client?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  restricted_account_list_client?: Maybe<Scalars['String']>,
  restricted_account_settings_client?: Maybe<Scalars['String']>,
  restricted_activity_list_client?: Maybe<Scalars['String']>,
  restricted_approve_account_client?: Maybe<Scalars['String']>,
  restricted_approve_client?: Maybe<Scalars['String']>,
  restricted_approve_status_client?: Maybe<Scalars['String']>,
  restricted_category_client?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_credit_transaction_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_flag_client?: Maybe<Scalars['String']>,
  restricted_manage_account_client?: Maybe<Scalars['String']>,
  restricted_notes_client?: Maybe<Scalars['String']>,
  restricted_representative_client?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restricted_risk_client?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_client?: Maybe<Scalars['String']>,
  restricted_tag_client?: Maybe<Scalars['String']>,
  restricted_transaction_list_client?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_client?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_credit_transaction_client?: Maybe<Scalars['String']>,
  user_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_manage_account_client?: Maybe<Scalars['String']>,
  user_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_client?: Maybe<Scalars['String']>,
}

export interface MasterAdminClientPermissionMinOrderBy {
  admin_account_list_client?: Maybe<OrderBy>,
  admin_account_settings_client?: Maybe<OrderBy>,
  admin_activity_list_client?: Maybe<OrderBy>,
  admin_approve_account_client?: Maybe<OrderBy>,
  admin_approve_client?: Maybe<OrderBy>,
  admin_approve_status_client?: Maybe<OrderBy>,
  admin_category_client?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_credit_transaction_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_deposit_transaction_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_flag_client?: Maybe<OrderBy>,
  admin_manage_account_client?: Maybe<OrderBy>,
  admin_notes_client?: Maybe<OrderBy>,
  admin_representative_client?: Maybe<OrderBy>,
  admin_restrict_negative_balance_client?: Maybe<OrderBy>,
  admin_risk_client?: Maybe<OrderBy>,
  admin_service_fee_transaction_client?: Maybe<OrderBy>,
  admin_tag_client?: Maybe<OrderBy>,
  admin_transaction_list_client?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  admin_transfer_transaction_client?: Maybe<OrderBy>,
  admin_withdrawal_transaction_client?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_client?: Maybe<OrderBy>,
  manager_approve_status_client?: Maybe<OrderBy>,
  manager_category_client?: Maybe<OrderBy>,
  manager_client_list?: Maybe<OrderBy>,
  manager_credit_transaction_client?: Maybe<OrderBy>,
  manager_deposit_transaction_client?: Maybe<OrderBy>,
  manager_flag_client?: Maybe<OrderBy>,
  manager_manage_account_client?: Maybe<OrderBy>,
  manager_notes_client?: Maybe<OrderBy>,
  manager_representative_client?: Maybe<OrderBy>,
  manager_risk_client?: Maybe<OrderBy>,
  manager_service_fee_transaction_client?: Maybe<OrderBy>,
  manager_tag_client?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  manager_transfer_transaction_client?: Maybe<OrderBy>,
  manager_withdrawal_transaction_client?: Maybe<OrderBy>,
  readonly_credit_transaction_client?: Maybe<OrderBy>,
  readonly_deposit_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_transaction_client?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_client?: Maybe<OrderBy>,
  restricted_account_list_client?: Maybe<OrderBy>,
  restricted_account_settings_client?: Maybe<OrderBy>,
  restricted_activity_list_client?: Maybe<OrderBy>,
  restricted_approve_account_client?: Maybe<OrderBy>,
  restricted_approve_client?: Maybe<OrderBy>,
  restricted_approve_status_client?: Maybe<OrderBy>,
  restricted_category_client?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_credit_transaction_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_deposit_transaction_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_flag_client?: Maybe<OrderBy>,
  restricted_manage_account_client?: Maybe<OrderBy>,
  restricted_notes_client?: Maybe<OrderBy>,
  restricted_representative_client?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_client?: Maybe<OrderBy>,
  restricted_risk_client?: Maybe<OrderBy>,
  restricted_service_fee_transaction_client?: Maybe<OrderBy>,
  restricted_tag_client?: Maybe<OrderBy>,
  restricted_transaction_list_client?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  restricted_transfer_transaction_client?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_credit_transaction_client?: Maybe<OrderBy>,
  user_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_credit_transaction_client?: Maybe<OrderBy>,
  user_limited_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_transaction_client?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_manage_account_client?: Maybe<OrderBy>,
  user_service_fee_transaction_client?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_transfer_transaction_client?: Maybe<OrderBy>,
  user_withdrawal_transaction_client?: Maybe<OrderBy>,
}

export interface MasterAdminClientPermissionMutationResponse {
   __typename?: 'master_admin_client_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterAdminClientPermission>,
}

export interface MasterAdminClientPermissionObjRelInsertInput {
  data: MasterAdminClientPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminClientPermissionOnConflict>,
}

export interface MasterAdminClientPermissionOnConflict {
  constraint: MasterAdminClientPermissionConstraint,
  update_columns: Array<MasterAdminClientPermissionUpdateColumn>,
  where?: Maybe<MasterAdminClientPermissionBoolExp>,
}

export interface MasterAdminClientPermissionOrderBy {
  admin_account_list_client?: Maybe<OrderBy>,
  admin_account_settings_client?: Maybe<OrderBy>,
  admin_activity_list_client?: Maybe<OrderBy>,
  admin_approve_account_client?: Maybe<OrderBy>,
  admin_approve_client?: Maybe<OrderBy>,
  admin_approve_status_client?: Maybe<OrderBy>,
  admin_category_client?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_credit_transaction_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_deposit_transaction_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_flag_client?: Maybe<OrderBy>,
  admin_manage_account_client?: Maybe<OrderBy>,
  admin_notes_client?: Maybe<OrderBy>,
  admin_representative_client?: Maybe<OrderBy>,
  admin_restrict_negative_balance_client?: Maybe<OrderBy>,
  admin_risk_client?: Maybe<OrderBy>,
  admin_service_fee_transaction_client?: Maybe<OrderBy>,
  admin_tag_client?: Maybe<OrderBy>,
  admin_transaction_list_client?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  admin_transfer_transaction_client?: Maybe<OrderBy>,
  admin_withdrawal_transaction_client?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_client?: Maybe<OrderBy>,
  manager_approve_status_client?: Maybe<OrderBy>,
  manager_category_client?: Maybe<OrderBy>,
  manager_client_list?: Maybe<OrderBy>,
  manager_credit_transaction_client?: Maybe<OrderBy>,
  manager_deposit_transaction_client?: Maybe<OrderBy>,
  manager_flag_client?: Maybe<OrderBy>,
  manager_manage_account_client?: Maybe<OrderBy>,
  manager_notes_client?: Maybe<OrderBy>,
  manager_representative_client?: Maybe<OrderBy>,
  manager_risk_client?: Maybe<OrderBy>,
  manager_service_fee_transaction_client?: Maybe<OrderBy>,
  manager_tag_client?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  manager_transfer_transaction_client?: Maybe<OrderBy>,
  manager_withdrawal_transaction_client?: Maybe<OrderBy>,
  readonly_credit_transaction_client?: Maybe<OrderBy>,
  readonly_deposit_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  readonly_transfer_transaction_client?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_client?: Maybe<OrderBy>,
  restricted_account_list_client?: Maybe<OrderBy>,
  restricted_account_settings_client?: Maybe<OrderBy>,
  restricted_activity_list_client?: Maybe<OrderBy>,
  restricted_approve_account_client?: Maybe<OrderBy>,
  restricted_approve_client?: Maybe<OrderBy>,
  restricted_approve_status_client?: Maybe<OrderBy>,
  restricted_category_client?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_credit_transaction_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_deposit_transaction_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_flag_client?: Maybe<OrderBy>,
  restricted_manage_account_client?: Maybe<OrderBy>,
  restricted_notes_client?: Maybe<OrderBy>,
  restricted_representative_client?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_client?: Maybe<OrderBy>,
  restricted_risk_client?: Maybe<OrderBy>,
  restricted_service_fee_transaction_client?: Maybe<OrderBy>,
  restricted_tag_client?: Maybe<OrderBy>,
  restricted_transaction_list_client?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  restricted_transfer_transaction_client?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_credit_transaction_client?: Maybe<OrderBy>,
  user_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_credit_transaction_client?: Maybe<OrderBy>,
  user_limited_deposit_transaction_client?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_limited_transfer_transaction_client?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_client?: Maybe<OrderBy>,
  user_manage_account_client?: Maybe<OrderBy>,
  user_service_fee_transaction_client?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_client?: Maybe<OrderBy>,
  user_transfer_transaction_client?: Maybe<OrderBy>,
  user_withdrawal_transaction_client?: Maybe<OrderBy>,
}

export interface MasterAdminClientPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterAdminClientPermissionSelectColumn {
  AdminAccountListClient = 'admin_account_list_client',
  AdminAccountSettingsClient = 'admin_account_settings_client',
  AdminActivityListClient = 'admin_activity_list_client',
  AdminApproveAccountClient = 'admin_approve_account_client',
  AdminApproveClient = 'admin_approve_client',
  AdminApproveStatusClient = 'admin_approve_status_client',
  AdminCategoryClient = 'admin_category_client',
  AdminClientList = 'admin_client_list',
  AdminCreateClient = 'admin_create_client',
  AdminCreditTransactionClient = 'admin_credit_transaction_client',
  AdminDeleteClient = 'admin_delete_client',
  AdminDepositTransactionClient = 'admin_deposit_transaction_client',
  AdminEditClient = 'admin_edit_client',
  AdminFlagClient = 'admin_flag_client',
  AdminManageAccountClient = 'admin_manage_account_client',
  AdminNotesClient = 'admin_notes_client',
  AdminRepresentativeClient = 'admin_representative_client',
  AdminRestrictNegativeBalanceClient = 'admin_restrict_negative_balance_client',
  AdminRiskClient = 'admin_risk_client',
  AdminServiceFeeTransactionClient = 'admin_service_fee_transaction_client',
  AdminTagClient = 'admin_tag_client',
  AdminTransactionListClient = 'admin_transaction_list_client',
  AdminTransferBetweenProfilesTransactionClient = 'admin_transfer_between_profiles_transaction_client',
  AdminTransferTransactionClient = 'admin_transfer_transaction_client',
  AdminWithdrawalTransactionClient = 'admin_withdrawal_transaction_client',
  Id = 'id',
  ManagerAccountSettingsClient = 'manager_account_settings_client',
  ManagerApproveStatusClient = 'manager_approve_status_client',
  ManagerCategoryClient = 'manager_category_client',
  ManagerClientList = 'manager_client_list',
  ManagerCreditTransactionClient = 'manager_credit_transaction_client',
  ManagerDepositTransactionClient = 'manager_deposit_transaction_client',
  ManagerFlagClient = 'manager_flag_client',
  ManagerManageAccountClient = 'manager_manage_account_client',
  ManagerNotesClient = 'manager_notes_client',
  ManagerRepresentativeClient = 'manager_representative_client',
  ManagerRiskClient = 'manager_risk_client',
  ManagerServiceFeeTransactionClient = 'manager_service_fee_transaction_client',
  ManagerTagClient = 'manager_tag_client',
  ManagerTransferBetweenProfilesTransactionClient = 'manager_transfer_between_profiles_transaction_client',
  ManagerTransferTransactionClient = 'manager_transfer_transaction_client',
  ManagerWithdrawalTransactionClient = 'manager_withdrawal_transaction_client',
  ReadonlyCreditTransactionClient = 'readonly_credit_transaction_client',
  ReadonlyDepositTransactionClient = 'readonly_deposit_transaction_client',
  ReadonlyTransferBetweenProfilesTransactionClient = 'readonly_transfer_between_profiles_transaction_client',
  ReadonlyTransferTransactionClient = 'readonly_transfer_transaction_client',
  ReadonlyWithdrawalTransactionClient = 'readonly_withdrawal_transaction_client',
  RestrictedAccountListClient = 'restricted_account_list_client',
  RestrictedAccountSettingsClient = 'restricted_account_settings_client',
  RestrictedActivityListClient = 'restricted_activity_list_client',
  RestrictedApproveAccountClient = 'restricted_approve_account_client',
  RestrictedApproveClient = 'restricted_approve_client',
  RestrictedApproveStatusClient = 'restricted_approve_status_client',
  RestrictedCategoryClient = 'restricted_category_client',
  RestrictedClientList = 'restricted_client_list',
  RestrictedCreateClient = 'restricted_create_client',
  RestrictedCreditTransactionClient = 'restricted_credit_transaction_client',
  RestrictedDeleteClient = 'restricted_delete_client',
  RestrictedDepositTransactionClient = 'restricted_deposit_transaction_client',
  RestrictedEditClient = 'restricted_edit_client',
  RestrictedFlagClient = 'restricted_flag_client',
  RestrictedManageAccountClient = 'restricted_manage_account_client',
  RestrictedNotesClient = 'restricted_notes_client',
  RestrictedRepresentativeClient = 'restricted_representative_client',
  RestrictedRestrictNegativeBalanceClient = 'restricted_restrict_negative_balance_client',
  RestrictedRiskClient = 'restricted_risk_client',
  RestrictedServiceFeeTransactionClient = 'restricted_service_fee_transaction_client',
  RestrictedTagClient = 'restricted_tag_client',
  RestrictedTransactionListClient = 'restricted_transaction_list_client',
  RestrictedTransferBetweenProfilesTransactionClient = 'restricted_transfer_between_profiles_transaction_client',
  RestrictedTransferTransactionClient = 'restricted_transfer_transaction_client',
  RestrictedWithdrawalTransactionClient = 'restricted_withdrawal_transaction_client',
  UserCreditTransactionClient = 'user_credit_transaction_client',
  UserDepositTransactionClient = 'user_deposit_transaction_client',
  UserLimitedCreditTransactionClient = 'user_limited_credit_transaction_client',
  UserLimitedDepositTransactionClient = 'user_limited_deposit_transaction_client',
  UserLimitedServiceFeeTransactionClient = 'user_limited_service_fee_transaction_client',
  UserLimitedTransferBetweenProfilesTransactionClient = 'user_limited_transfer_between_profiles_transaction_client',
  UserLimitedTransferTransactionClient = 'user_limited_transfer_transaction_client',
  UserLimitedWithdrawalTransactionClient = 'user_limited_withdrawal_transaction_client',
  UserManageAccountClient = 'user_manage_account_client',
  UserServiceFeeTransactionClient = 'user_service_fee_transaction_client',
  UserTransferBetweenProfilesTransactionClient = 'user_transfer_between_profiles_transaction_client',
  UserTransferTransactionClient = 'user_transfer_transaction_client',
  UserWithdrawalTransactionClient = 'user_withdrawal_transaction_client'
}

export interface MasterAdminClientPermissionSetInput {
  admin_account_list_client?: Maybe<Scalars['String']>,
  admin_account_settings_client?: Maybe<Scalars['String']>,
  admin_activity_list_client?: Maybe<Scalars['String']>,
  admin_approve_account_client?: Maybe<Scalars['String']>,
  admin_approve_client?: Maybe<Scalars['String']>,
  admin_approve_status_client?: Maybe<Scalars['String']>,
  admin_category_client?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_credit_transaction_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_deposit_transaction_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_flag_client?: Maybe<Scalars['String']>,
  admin_manage_account_client?: Maybe<Scalars['String']>,
  admin_notes_client?: Maybe<Scalars['String']>,
  admin_representative_client?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  admin_risk_client?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_client?: Maybe<Scalars['String']>,
  admin_tag_client?: Maybe<Scalars['String']>,
  admin_transaction_list_client?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  admin_transfer_transaction_client?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_client?: Maybe<Scalars['String']>,
  manager_approve_status_client?: Maybe<Scalars['String']>,
  manager_category_client?: Maybe<Scalars['String']>,
  manager_client_list?: Maybe<Scalars['String']>,
  manager_credit_transaction_client?: Maybe<Scalars['String']>,
  manager_deposit_transaction_client?: Maybe<Scalars['String']>,
  manager_flag_client?: Maybe<Scalars['String']>,
  manager_manage_account_client?: Maybe<Scalars['String']>,
  manager_notes_client?: Maybe<Scalars['String']>,
  manager_representative_client?: Maybe<Scalars['String']>,
  manager_risk_client?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_client?: Maybe<Scalars['String']>,
  manager_tag_client?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  manager_transfer_transaction_client?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  readonly_credit_transaction_client?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_client?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  restricted_account_list_client?: Maybe<Scalars['String']>,
  restricted_account_settings_client?: Maybe<Scalars['String']>,
  restricted_activity_list_client?: Maybe<Scalars['String']>,
  restricted_approve_account_client?: Maybe<Scalars['String']>,
  restricted_approve_client?: Maybe<Scalars['String']>,
  restricted_approve_status_client?: Maybe<Scalars['String']>,
  restricted_category_client?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_credit_transaction_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_flag_client?: Maybe<Scalars['String']>,
  restricted_manage_account_client?: Maybe<Scalars['String']>,
  restricted_notes_client?: Maybe<Scalars['String']>,
  restricted_representative_client?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_client?: Maybe<Scalars['String']>,
  restricted_risk_client?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_client?: Maybe<Scalars['String']>,
  restricted_tag_client?: Maybe<Scalars['String']>,
  restricted_transaction_list_client?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_client?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_credit_transaction_client?: Maybe<Scalars['String']>,
  user_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_client?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_client?: Maybe<Scalars['String']>,
  user_manage_account_client?: Maybe<Scalars['String']>,
  user_service_fee_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_client?: Maybe<Scalars['String']>,
  user_transfer_transaction_client?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_client?: Maybe<Scalars['String']>,
}

export enum MasterAdminClientPermissionUpdateColumn {
  AdminAccountListClient = 'admin_account_list_client',
  AdminAccountSettingsClient = 'admin_account_settings_client',
  AdminActivityListClient = 'admin_activity_list_client',
  AdminApproveAccountClient = 'admin_approve_account_client',
  AdminApproveClient = 'admin_approve_client',
  AdminApproveStatusClient = 'admin_approve_status_client',
  AdminCategoryClient = 'admin_category_client',
  AdminClientList = 'admin_client_list',
  AdminCreateClient = 'admin_create_client',
  AdminCreditTransactionClient = 'admin_credit_transaction_client',
  AdminDeleteClient = 'admin_delete_client',
  AdminDepositTransactionClient = 'admin_deposit_transaction_client',
  AdminEditClient = 'admin_edit_client',
  AdminFlagClient = 'admin_flag_client',
  AdminManageAccountClient = 'admin_manage_account_client',
  AdminNotesClient = 'admin_notes_client',
  AdminRepresentativeClient = 'admin_representative_client',
  AdminRestrictNegativeBalanceClient = 'admin_restrict_negative_balance_client',
  AdminRiskClient = 'admin_risk_client',
  AdminServiceFeeTransactionClient = 'admin_service_fee_transaction_client',
  AdminTagClient = 'admin_tag_client',
  AdminTransactionListClient = 'admin_transaction_list_client',
  AdminTransferBetweenProfilesTransactionClient = 'admin_transfer_between_profiles_transaction_client',
  AdminTransferTransactionClient = 'admin_transfer_transaction_client',
  AdminWithdrawalTransactionClient = 'admin_withdrawal_transaction_client',
  Id = 'id',
  ManagerAccountSettingsClient = 'manager_account_settings_client',
  ManagerApproveStatusClient = 'manager_approve_status_client',
  ManagerCategoryClient = 'manager_category_client',
  ManagerClientList = 'manager_client_list',
  ManagerCreditTransactionClient = 'manager_credit_transaction_client',
  ManagerDepositTransactionClient = 'manager_deposit_transaction_client',
  ManagerFlagClient = 'manager_flag_client',
  ManagerManageAccountClient = 'manager_manage_account_client',
  ManagerNotesClient = 'manager_notes_client',
  ManagerRepresentativeClient = 'manager_representative_client',
  ManagerRiskClient = 'manager_risk_client',
  ManagerServiceFeeTransactionClient = 'manager_service_fee_transaction_client',
  ManagerTagClient = 'manager_tag_client',
  ManagerTransferBetweenProfilesTransactionClient = 'manager_transfer_between_profiles_transaction_client',
  ManagerTransferTransactionClient = 'manager_transfer_transaction_client',
  ManagerWithdrawalTransactionClient = 'manager_withdrawal_transaction_client',
  ReadonlyCreditTransactionClient = 'readonly_credit_transaction_client',
  ReadonlyDepositTransactionClient = 'readonly_deposit_transaction_client',
  ReadonlyTransferBetweenProfilesTransactionClient = 'readonly_transfer_between_profiles_transaction_client',
  ReadonlyTransferTransactionClient = 'readonly_transfer_transaction_client',
  ReadonlyWithdrawalTransactionClient = 'readonly_withdrawal_transaction_client',
  RestrictedAccountListClient = 'restricted_account_list_client',
  RestrictedAccountSettingsClient = 'restricted_account_settings_client',
  RestrictedActivityListClient = 'restricted_activity_list_client',
  RestrictedApproveAccountClient = 'restricted_approve_account_client',
  RestrictedApproveClient = 'restricted_approve_client',
  RestrictedApproveStatusClient = 'restricted_approve_status_client',
  RestrictedCategoryClient = 'restricted_category_client',
  RestrictedClientList = 'restricted_client_list',
  RestrictedCreateClient = 'restricted_create_client',
  RestrictedCreditTransactionClient = 'restricted_credit_transaction_client',
  RestrictedDeleteClient = 'restricted_delete_client',
  RestrictedDepositTransactionClient = 'restricted_deposit_transaction_client',
  RestrictedEditClient = 'restricted_edit_client',
  RestrictedFlagClient = 'restricted_flag_client',
  RestrictedManageAccountClient = 'restricted_manage_account_client',
  RestrictedNotesClient = 'restricted_notes_client',
  RestrictedRepresentativeClient = 'restricted_representative_client',
  RestrictedRestrictNegativeBalanceClient = 'restricted_restrict_negative_balance_client',
  RestrictedRiskClient = 'restricted_risk_client',
  RestrictedServiceFeeTransactionClient = 'restricted_service_fee_transaction_client',
  RestrictedTagClient = 'restricted_tag_client',
  RestrictedTransactionListClient = 'restricted_transaction_list_client',
  RestrictedTransferBetweenProfilesTransactionClient = 'restricted_transfer_between_profiles_transaction_client',
  RestrictedTransferTransactionClient = 'restricted_transfer_transaction_client',
  RestrictedWithdrawalTransactionClient = 'restricted_withdrawal_transaction_client',
  UserCreditTransactionClient = 'user_credit_transaction_client',
  UserDepositTransactionClient = 'user_deposit_transaction_client',
  UserLimitedCreditTransactionClient = 'user_limited_credit_transaction_client',
  UserLimitedDepositTransactionClient = 'user_limited_deposit_transaction_client',
  UserLimitedServiceFeeTransactionClient = 'user_limited_service_fee_transaction_client',
  UserLimitedTransferBetweenProfilesTransactionClient = 'user_limited_transfer_between_profiles_transaction_client',
  UserLimitedTransferTransactionClient = 'user_limited_transfer_transaction_client',
  UserLimitedWithdrawalTransactionClient = 'user_limited_withdrawal_transaction_client',
  UserManageAccountClient = 'user_manage_account_client',
  UserServiceFeeTransactionClient = 'user_service_fee_transaction_client',
  UserTransferBetweenProfilesTransactionClient = 'user_transfer_between_profiles_transaction_client',
  UserTransferTransactionClient = 'user_transfer_transaction_client',
  UserWithdrawalTransactionClient = 'user_withdrawal_transaction_client'
}

export interface MasterAdminLeadPermission {
   __typename?: 'master_admin_lead_permission',
  admin_account_list_lead?: Maybe<Scalars['String']>,
  admin_account_settings_lead?: Maybe<Scalars['String']>,
  admin_activity_list_lead?: Maybe<Scalars['String']>,
  admin_approve_account_lead?: Maybe<Scalars['String']>,
  admin_approve_lead?: Maybe<Scalars['String']>,
  admin_approve_status_lead?: Maybe<Scalars['String']>,
  admin_category_lead?: Maybe<Scalars['String']>,
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_credit_transaction_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_deposit_transaction_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_flag_lead?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_manage_account_lead?: Maybe<Scalars['String']>,
  admin_notes_lead?: Maybe<Scalars['String']>,
  admin_representative_lead?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  admin_risk_lead?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  admin_tag_lead?: Maybe<Scalars['String']>,
  admin_transaction_list_lead?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  admin_transfer_transaction_lead?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manager_account_settings_lead?: Maybe<Scalars['String']>,
  manager_approve_status_lead?: Maybe<Scalars['String']>,
  manager_category_lead?: Maybe<Scalars['String']>,
  manager_credit_transaction_lead?: Maybe<Scalars['String']>,
  manager_deposit_transaction_lead?: Maybe<Scalars['String']>,
  manager_flag_lead?: Maybe<Scalars['String']>,
  manager_lead_list?: Maybe<Scalars['String']>,
  manager_manage_account_lead?: Maybe<Scalars['String']>,
  manager_notes_lead?: Maybe<Scalars['String']>,
  manager_representative_lead?: Maybe<Scalars['String']>,
  manager_risk_lead?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  manager_tag_lead?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  manager_transfer_transaction_lead?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  readonly_credit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_lead?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  restricted_account_list_lead?: Maybe<Scalars['String']>,
  restricted_account_settings_lead?: Maybe<Scalars['String']>,
  restricted_activity_list_lead?: Maybe<Scalars['String']>,
  restricted_approve_account_lead?: Maybe<Scalars['String']>,
  restricted_approve_lead?: Maybe<Scalars['String']>,
  restricted_approve_status_lead?: Maybe<Scalars['String']>,
  restricted_category_lead?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_credit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_flag_lead?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_manage_account_lead?: Maybe<Scalars['String']>,
  restricted_notes_lead?: Maybe<Scalars['String']>,
  restricted_representative_lead?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restricted_risk_lead?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  restricted_tag_lead?: Maybe<Scalars['String']>,
  restricted_transaction_list_lead?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_lead?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_manage_account_lead?: Maybe<Scalars['String']>,
  user_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
}

export interface MasterAdminLeadPermissionAggregate {
   __typename?: 'master_admin_lead_permission_aggregate',
  aggregate?: Maybe<MasterAdminLeadPermissionAggregateFields>,
  nodes: Array<MasterAdminLeadPermission>,
}

export interface MasterAdminLeadPermissionAggregateFields {
   __typename?: 'master_admin_lead_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterAdminLeadPermissionMaxFields>,
  min?: Maybe<MasterAdminLeadPermissionMinFields>,
}


export interface MasterAdminLeadPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterAdminLeadPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterAdminLeadPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterAdminLeadPermissionMaxOrderBy>,
  min?: Maybe<MasterAdminLeadPermissionMinOrderBy>,
}

export interface MasterAdminLeadPermissionArrRelInsertInput {
  data: Array<MasterAdminLeadPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminLeadPermissionOnConflict>,
}

export interface MasterAdminLeadPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterAdminLeadPermissionBoolExp>>>,
  _not?: Maybe<MasterAdminLeadPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterAdminLeadPermissionBoolExp>>>,
  admin_account_list_lead?: Maybe<StringComparisonExp>,
  admin_account_settings_lead?: Maybe<StringComparisonExp>,
  admin_activity_list_lead?: Maybe<StringComparisonExp>,
  admin_approve_account_lead?: Maybe<StringComparisonExp>,
  admin_approve_lead?: Maybe<StringComparisonExp>,
  admin_approve_status_lead?: Maybe<StringComparisonExp>,
  admin_category_lead?: Maybe<StringComparisonExp>,
  admin_create_lead?: Maybe<StringComparisonExp>,
  admin_credit_transaction_lead?: Maybe<StringComparisonExp>,
  admin_delete_lead?: Maybe<StringComparisonExp>,
  admin_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  admin_edit_lead?: Maybe<StringComparisonExp>,
  admin_flag_lead?: Maybe<StringComparisonExp>,
  admin_lead_list?: Maybe<StringComparisonExp>,
  admin_manage_account_lead?: Maybe<StringComparisonExp>,
  admin_notes_lead?: Maybe<StringComparisonExp>,
  admin_representative_lead?: Maybe<StringComparisonExp>,
  admin_restrict_negative_balance_lead?: Maybe<StringComparisonExp>,
  admin_risk_lead?: Maybe<StringComparisonExp>,
  admin_service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  admin_tag_lead?: Maybe<StringComparisonExp>,
  admin_transaction_list_lead?: Maybe<StringComparisonExp>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  admin_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  admin_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_account_settings_lead?: Maybe<StringComparisonExp>,
  manager_approve_status_lead?: Maybe<StringComparisonExp>,
  manager_category_lead?: Maybe<StringComparisonExp>,
  manager_credit_transaction_lead?: Maybe<StringComparisonExp>,
  manager_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  manager_flag_lead?: Maybe<StringComparisonExp>,
  manager_lead_list?: Maybe<StringComparisonExp>,
  manager_manage_account_lead?: Maybe<StringComparisonExp>,
  manager_notes_lead?: Maybe<StringComparisonExp>,
  manager_representative_lead?: Maybe<StringComparisonExp>,
  manager_risk_lead?: Maybe<StringComparisonExp>,
  manager_service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  manager_tag_lead?: Maybe<StringComparisonExp>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  manager_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  manager_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  readonly_credit_transaction_lead?: Maybe<StringComparisonExp>,
  readonly_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  readonly_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  readonly_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_account_list_lead?: Maybe<StringComparisonExp>,
  restricted_account_settings_lead?: Maybe<StringComparisonExp>,
  restricted_activity_list_lead?: Maybe<StringComparisonExp>,
  restricted_approve_account_lead?: Maybe<StringComparisonExp>,
  restricted_approve_lead?: Maybe<StringComparisonExp>,
  restricted_approve_status_lead?: Maybe<StringComparisonExp>,
  restricted_category_lead?: Maybe<StringComparisonExp>,
  restricted_create_lead?: Maybe<StringComparisonExp>,
  restricted_credit_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_delete_lead?: Maybe<StringComparisonExp>,
  restricted_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_edit_lead?: Maybe<StringComparisonExp>,
  restricted_flag_lead?: Maybe<StringComparisonExp>,
  restricted_lead_list?: Maybe<StringComparisonExp>,
  restricted_manage_account_lead?: Maybe<StringComparisonExp>,
  restricted_notes_lead?: Maybe<StringComparisonExp>,
  restricted_representative_lead?: Maybe<StringComparisonExp>,
  restricted_restrict_negative_balance_lead?: Maybe<StringComparisonExp>,
  restricted_risk_lead?: Maybe<StringComparisonExp>,
  restricted_service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_tag_lead?: Maybe<StringComparisonExp>,
  restricted_transaction_list_lead?: Maybe<StringComparisonExp>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  restricted_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  user_credit_transaction_lead?: Maybe<StringComparisonExp>,
  user_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_credit_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_deposit_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  user_limited_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
  user_manage_account_lead?: Maybe<StringComparisonExp>,
  user_service_fee_transaction_lead?: Maybe<StringComparisonExp>,
  user_transfer_between_profiles_transaction_lead?: Maybe<StringComparisonExp>,
  user_transfer_transaction_lead?: Maybe<StringComparisonExp>,
  user_withdrawal_transaction_lead?: Maybe<StringComparisonExp>,
}

export enum MasterAdminLeadPermissionConstraint {
  MasterAdminLeadPermissionAdminAccountListLeadKey = 'master_admin_lead_permission_admin_account_list_lead_key',
  MasterAdminLeadPermissionAdminAccountSettingsLeadKey = 'master_admin_lead_permission_admin_account_settings_lead_key',
  MasterAdminLeadPermissionAdminActivityListLeadKey = 'master_admin_lead_permission_admin_activity_list_lead_key',
  MasterAdminLeadPermissionAdminApproveAccountLeadKey = 'master_admin_lead_permission_admin_approve_account_lead_key',
  MasterAdminLeadPermissionAdminApproveLeadKey = 'master_admin_lead_permission_admin_approve_lead_key',
  MasterAdminLeadPermissionAdminApproveStatusLeadKey = 'master_admin_lead_permission_admin_approve_status_lead_key',
  MasterAdminLeadPermissionAdminCategoryLeadKey = 'master_admin_lead_permission_admin_category_lead_key',
  MasterAdminLeadPermissionAdminCreateLeadKey = 'master_admin_lead_permission_admin_create_lead_key',
  MasterAdminLeadPermissionAdminCreditTransactionLeadKey = 'master_admin_lead_permission_admin_credit_transaction_lead_key',
  MasterAdminLeadPermissionAdminDeleteLeadKey = 'master_admin_lead_permission_admin_delete_lead_key',
  MasterAdminLeadPermissionAdminDepositTransactionLeadKey = 'master_admin_lead_permission_admin_deposit_transaction_lead_key',
  MasterAdminLeadPermissionAdminEditLeadKey = 'master_admin_lead_permission_admin_edit_lead_key',
  MasterAdminLeadPermissionAdminFlagLeadKey = 'master_admin_lead_permission_admin_flag_lead_key',
  MasterAdminLeadPermissionAdminLeadListKey = 'master_admin_lead_permission_admin_lead_list_key',
  MasterAdminLeadPermissionAdminManageAccountLeadKey = 'master_admin_lead_permission_admin_manage_account_lead_key',
  MasterAdminLeadPermissionAdminNotesLeadKey = 'master_admin_lead_permission_admin_notes_lead_key',
  MasterAdminLeadPermissionAdminRepresentativeLeadKey = 'master_admin_lead_permission_admin_representative_lead_key',
  MasterAdminLeadPermissionAdminRestrictNegativeBalancKey = 'master_admin_lead_permission_admin_restrict_negative_balanc_key',
  MasterAdminLeadPermissionAdminRiskLeadKey = 'master_admin_lead_permission_admin_risk_lead_key',
  MasterAdminLeadPermissionAdminServiceFeeTransactionKey = 'master_admin_lead_permission_admin_service_fee_transaction__key',
  MasterAdminLeadPermissionAdminTagLeadKey = 'master_admin_lead_permission_admin_tag_lead_key',
  MasterAdminLeadPermissionAdminTransactionListLeadKey = 'master_admin_lead_permission_admin_transaction_list_lead_key',
  MasterAdminLeadPermissionAdminTransferBetweenProfileKey = 'master_admin_lead_permission_admin_transfer_between_profile_key',
  MasterAdminLeadPermissionAdminTransferTransactionLeaKey = 'master_admin_lead_permission_admin_transfer_transaction_lea_key',
  MasterAdminLeadPermissionAdminWithdrawalTransactionLKey = 'master_admin_lead_permission_admin_withdrawal_transaction_l_key',
  MasterAdminLeadPermissionManagerAccountSettingsLeadKey = 'master_admin_lead_permission_manager_account_settings_lead_key',
  MasterAdminLeadPermissionManagerApproveStatusLeadKey = 'master_admin_lead_permission_manager_approve_status_lead_key',
  MasterAdminLeadPermissionManagerCategoryLeadKey = 'master_admin_lead_permission_manager_category_lead_key',
  MasterAdminLeadPermissionManagerCreditTransactionLeaKey = 'master_admin_lead_permission_manager_credit_transaction_lea_key',
  MasterAdminLeadPermissionManagerDepositTransactionLeKey = 'master_admin_lead_permission_manager_deposit_transaction_le_key',
  MasterAdminLeadPermissionManagerFlagLeadKey = 'master_admin_lead_permission_manager_flag_lead_key',
  MasterAdminLeadPermissionManagerLeadListKey = 'master_admin_lead_permission_manager_lead_list_key',
  MasterAdminLeadPermissionManagerManageAccountLeadKey = 'master_admin_lead_permission_manager_manage_account_lead_key',
  MasterAdminLeadPermissionManagerNotesLeadKey = 'master_admin_lead_permission_manager_notes_lead_key',
  MasterAdminLeadPermissionManagerRepresentativeLeadKey = 'master_admin_lead_permission_manager_representative_lead_key',
  MasterAdminLeadPermissionManagerRiskLeadKey = 'master_admin_lead_permission_manager_risk_lead_key',
  MasterAdminLeadPermissionManagerServiceFeeTransactioKey = 'master_admin_lead_permission_manager_service_fee_transactio_key',
  MasterAdminLeadPermissionManagerTagLeadKey = 'master_admin_lead_permission_manager_tag_lead_key',
  MasterAdminLeadPermissionManagerTransferBetweenProfiKey = 'master_admin_lead_permission_manager_transfer_between_profi_key',
  MasterAdminLeadPermissionManagerTransferTransactionLKey = 'master_admin_lead_permission_manager_transfer_transaction_l_key',
  MasterAdminLeadPermissionManagerWithdrawalTransactionKey = 'master_admin_lead_permission_manager_withdrawal_transaction_key',
  MasterAdminLeadPermissionPkey = 'master_admin_lead_permission_pkey',
  MasterAdminLeadPermissionReadonlyCreditTransactionLeKey = 'master_admin_lead_permission_readonly_credit_transaction_le_key',
  MasterAdminLeadPermissionReadonlyDepositTransactionLKey = 'master_admin_lead_permission_readonly_deposit_transaction_l_key',
  MasterAdminLeadPermissionReadonlyTransferBetweenProfKey = 'master_admin_lead_permission_readonly_transfer_between_prof_key',
  MasterAdminLeadPermissionReadonlyTransferTransactionKey = 'master_admin_lead_permission_readonly_transfer_transaction__key',
  MasterAdminLeadPermissionReadonlyWithdrawalTransactioKey = 'master_admin_lead_permission_readonly_withdrawal_transactio_key',
  MasterAdminLeadPermissionRestrictedAccountListLeadKey = 'master_admin_lead_permission_restricted_account_list_lead_key',
  MasterAdminLeadPermissionRestrictedAccountSettingsLeKey = 'master_admin_lead_permission_restricted_account_settings_le_key',
  MasterAdminLeadPermissionRestrictedActivityListLeadKey = 'master_admin_lead_permission_restricted_activity_list_lead_key',
  MasterAdminLeadPermissionRestrictedApproveAccountLeaKey = 'master_admin_lead_permission_restricted_approve_account_lea_key',
  MasterAdminLeadPermissionRestrictedApproveLeadKey = 'master_admin_lead_permission_restricted_approve_lead_key',
  MasterAdminLeadPermissionRestrictedApproveStatusLeadKey = 'master_admin_lead_permission_restricted_approve_status_lead_key',
  MasterAdminLeadPermissionRestrictedCategoryLeadKey = 'master_admin_lead_permission_restricted_category_lead_key',
  MasterAdminLeadPermissionRestrictedCreateLeadKey = 'master_admin_lead_permission_restricted_create_lead_key',
  MasterAdminLeadPermissionRestrictedCreditTransactionKey = 'master_admin_lead_permission_restricted_credit_transaction__key',
  MasterAdminLeadPermissionRestrictedDeleteLeadKey = 'master_admin_lead_permission_restricted_delete_lead_key',
  MasterAdminLeadPermissionRestrictedDepositTransactionKey = 'master_admin_lead_permission_restricted_deposit_transaction_key',
  MasterAdminLeadPermissionRestrictedEditLeadKey = 'master_admin_lead_permission_restricted_edit_lead_key',
  MasterAdminLeadPermissionRestrictedFlagLeadKey = 'master_admin_lead_permission_restricted_flag_lead_key',
  MasterAdminLeadPermissionRestrictedLeadListKey = 'master_admin_lead_permission_restricted_lead_list_key',
  MasterAdminLeadPermissionRestrictedManageAccountLeadKey = 'master_admin_lead_permission_restricted_manage_account_lead_key',
  MasterAdminLeadPermissionRestrictedNotesLeadKey = 'master_admin_lead_permission_restricted_notes_lead_key',
  MasterAdminLeadPermissionRestrictedRepresentativeLeadKey = 'master_admin_lead_permission_restricted_representative_lead_key',
  MasterAdminLeadPermissionRestrictedRestrictNegativeBKey = 'master_admin_lead_permission_restricted_restrict_negative_b_key',
  MasterAdminLeadPermissionRestrictedRiskLeadKey = 'master_admin_lead_permission_restricted_risk_lead_key',
  MasterAdminLeadPermissionRestrictedServiceFeeTransacKey = 'master_admin_lead_permission_restricted_service_fee_transac_key',
  MasterAdminLeadPermissionRestrictedTagLeadKey = 'master_admin_lead_permission_restricted_tag_lead_key',
  MasterAdminLeadPermissionRestrictedTransactionListLeKey = 'master_admin_lead_permission_restricted_transaction_list_le_key',
  MasterAdminLeadPermissionRestrictedTransferBetweenPrKey = 'master_admin_lead_permission_restricted_transfer_between_pr_key',
  MasterAdminLeadPermissionRestrictedTransferTransactioKey = 'master_admin_lead_permission_restricted_transfer_transactio_key',
  MasterAdminLeadPermissionRestrictedWithdrawalTransactKey = 'master_admin_lead_permission_restricted_withdrawal_transact_key',
  MasterAdminLeadPermissionUserCreditTransactionLeadKey = 'master_admin_lead_permission_user_credit_transaction_lead_key',
  MasterAdminLeadPermissionUserDepositTransactionLeadKey = 'master_admin_lead_permission_user_deposit_transaction_lead_key',
  MasterAdminLeadPermissionUserLimitedCreditTransactioKey = 'master_admin_lead_permission_user_limited_credit_transactio_key',
  MasterAdminLeadPermissionUserLimitedDepositTransactiKey = 'master_admin_lead_permission_user_limited_deposit_transacti_key',
  MasterAdminLeadPermissionUserLimitedServiceFeeTransKey = 'master_admin_lead_permission_user_limited_service_fee_trans_key',
  MasterAdminLeadPermissionUserLimitedTransferBetweenKey = 'master_admin_lead_permission_user_limited_transfer_between__key',
  MasterAdminLeadPermissionUserLimitedTransferTransactKey = 'master_admin_lead_permission_user_limited_transfer_transact_key',
  MasterAdminLeadPermissionUserLimitedWithdrawalTransaKey = 'master_admin_lead_permission_user_limited_withdrawal_transa_key',
  MasterAdminLeadPermissionUserManageAccountLeadKey = 'master_admin_lead_permission_user_manage_account_lead_key',
  MasterAdminLeadPermissionUserServiceFeeTransactionLKey = 'master_admin_lead_permission_user_service_fee_transaction_l_key',
  MasterAdminLeadPermissionUserTransferBetweenProfilesKey = 'master_admin_lead_permission_user_transfer_between_profiles_key',
  MasterAdminLeadPermissionUserTransferTransactionLeadKey = 'master_admin_lead_permission_user_transfer_transaction_lead_key',
  MasterAdminLeadPermissionUserWithdrawalTransactionLeKey = 'master_admin_lead_permission_user_withdrawal_transaction_le_key'
}

export interface MasterAdminLeadPermissionInsertInput {
  admin_account_list_lead?: Maybe<Scalars['String']>,
  admin_account_settings_lead?: Maybe<Scalars['String']>,
  admin_activity_list_lead?: Maybe<Scalars['String']>,
  admin_approve_account_lead?: Maybe<Scalars['String']>,
  admin_approve_lead?: Maybe<Scalars['String']>,
  admin_approve_status_lead?: Maybe<Scalars['String']>,
  admin_category_lead?: Maybe<Scalars['String']>,
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_credit_transaction_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_deposit_transaction_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_flag_lead?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_manage_account_lead?: Maybe<Scalars['String']>,
  admin_notes_lead?: Maybe<Scalars['String']>,
  admin_representative_lead?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  admin_risk_lead?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  admin_tag_lead?: Maybe<Scalars['String']>,
  admin_transaction_list_lead?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  admin_transfer_transaction_lead?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_lead?: Maybe<Scalars['String']>,
  manager_approve_status_lead?: Maybe<Scalars['String']>,
  manager_category_lead?: Maybe<Scalars['String']>,
  manager_credit_transaction_lead?: Maybe<Scalars['String']>,
  manager_deposit_transaction_lead?: Maybe<Scalars['String']>,
  manager_flag_lead?: Maybe<Scalars['String']>,
  manager_lead_list?: Maybe<Scalars['String']>,
  manager_manage_account_lead?: Maybe<Scalars['String']>,
  manager_notes_lead?: Maybe<Scalars['String']>,
  manager_representative_lead?: Maybe<Scalars['String']>,
  manager_risk_lead?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  manager_tag_lead?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  manager_transfer_transaction_lead?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  readonly_credit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_lead?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  restricted_account_list_lead?: Maybe<Scalars['String']>,
  restricted_account_settings_lead?: Maybe<Scalars['String']>,
  restricted_activity_list_lead?: Maybe<Scalars['String']>,
  restricted_approve_account_lead?: Maybe<Scalars['String']>,
  restricted_approve_lead?: Maybe<Scalars['String']>,
  restricted_approve_status_lead?: Maybe<Scalars['String']>,
  restricted_category_lead?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_credit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_flag_lead?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_manage_account_lead?: Maybe<Scalars['String']>,
  restricted_notes_lead?: Maybe<Scalars['String']>,
  restricted_representative_lead?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restricted_risk_lead?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  restricted_tag_lead?: Maybe<Scalars['String']>,
  restricted_transaction_list_lead?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_lead?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_manage_account_lead?: Maybe<Scalars['String']>,
  user_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
}

export interface MasterAdminLeadPermissionMaxFields {
   __typename?: 'master_admin_lead_permission_max_fields',
  admin_account_list_lead?: Maybe<Scalars['String']>,
  admin_account_settings_lead?: Maybe<Scalars['String']>,
  admin_activity_list_lead?: Maybe<Scalars['String']>,
  admin_approve_account_lead?: Maybe<Scalars['String']>,
  admin_approve_lead?: Maybe<Scalars['String']>,
  admin_approve_status_lead?: Maybe<Scalars['String']>,
  admin_category_lead?: Maybe<Scalars['String']>,
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_credit_transaction_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_deposit_transaction_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_flag_lead?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_manage_account_lead?: Maybe<Scalars['String']>,
  admin_notes_lead?: Maybe<Scalars['String']>,
  admin_representative_lead?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  admin_risk_lead?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  admin_tag_lead?: Maybe<Scalars['String']>,
  admin_transaction_list_lead?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  admin_transfer_transaction_lead?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_lead?: Maybe<Scalars['String']>,
  manager_approve_status_lead?: Maybe<Scalars['String']>,
  manager_category_lead?: Maybe<Scalars['String']>,
  manager_credit_transaction_lead?: Maybe<Scalars['String']>,
  manager_deposit_transaction_lead?: Maybe<Scalars['String']>,
  manager_flag_lead?: Maybe<Scalars['String']>,
  manager_lead_list?: Maybe<Scalars['String']>,
  manager_manage_account_lead?: Maybe<Scalars['String']>,
  manager_notes_lead?: Maybe<Scalars['String']>,
  manager_representative_lead?: Maybe<Scalars['String']>,
  manager_risk_lead?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  manager_tag_lead?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  manager_transfer_transaction_lead?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  readonly_credit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_lead?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  restricted_account_list_lead?: Maybe<Scalars['String']>,
  restricted_account_settings_lead?: Maybe<Scalars['String']>,
  restricted_activity_list_lead?: Maybe<Scalars['String']>,
  restricted_approve_account_lead?: Maybe<Scalars['String']>,
  restricted_approve_lead?: Maybe<Scalars['String']>,
  restricted_approve_status_lead?: Maybe<Scalars['String']>,
  restricted_category_lead?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_credit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_flag_lead?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_manage_account_lead?: Maybe<Scalars['String']>,
  restricted_notes_lead?: Maybe<Scalars['String']>,
  restricted_representative_lead?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restricted_risk_lead?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  restricted_tag_lead?: Maybe<Scalars['String']>,
  restricted_transaction_list_lead?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_lead?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_manage_account_lead?: Maybe<Scalars['String']>,
  user_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
}

export interface MasterAdminLeadPermissionMaxOrderBy {
  admin_account_list_lead?: Maybe<OrderBy>,
  admin_account_settings_lead?: Maybe<OrderBy>,
  admin_activity_list_lead?: Maybe<OrderBy>,
  admin_approve_account_lead?: Maybe<OrderBy>,
  admin_approve_lead?: Maybe<OrderBy>,
  admin_approve_status_lead?: Maybe<OrderBy>,
  admin_category_lead?: Maybe<OrderBy>,
  admin_create_lead?: Maybe<OrderBy>,
  admin_credit_transaction_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_deposit_transaction_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_flag_lead?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_manage_account_lead?: Maybe<OrderBy>,
  admin_notes_lead?: Maybe<OrderBy>,
  admin_representative_lead?: Maybe<OrderBy>,
  admin_restrict_negative_balance_lead?: Maybe<OrderBy>,
  admin_risk_lead?: Maybe<OrderBy>,
  admin_service_fee_transaction_lead?: Maybe<OrderBy>,
  admin_tag_lead?: Maybe<OrderBy>,
  admin_transaction_list_lead?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  admin_transfer_transaction_lead?: Maybe<OrderBy>,
  admin_withdrawal_transaction_lead?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_lead?: Maybe<OrderBy>,
  manager_approve_status_lead?: Maybe<OrderBy>,
  manager_category_lead?: Maybe<OrderBy>,
  manager_credit_transaction_lead?: Maybe<OrderBy>,
  manager_deposit_transaction_lead?: Maybe<OrderBy>,
  manager_flag_lead?: Maybe<OrderBy>,
  manager_lead_list?: Maybe<OrderBy>,
  manager_manage_account_lead?: Maybe<OrderBy>,
  manager_notes_lead?: Maybe<OrderBy>,
  manager_representative_lead?: Maybe<OrderBy>,
  manager_risk_lead?: Maybe<OrderBy>,
  manager_service_fee_transaction_lead?: Maybe<OrderBy>,
  manager_tag_lead?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  manager_transfer_transaction_lead?: Maybe<OrderBy>,
  manager_withdrawal_transaction_lead?: Maybe<OrderBy>,
  readonly_credit_transaction_lead?: Maybe<OrderBy>,
  readonly_deposit_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_transaction_lead?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_lead?: Maybe<OrderBy>,
  restricted_account_list_lead?: Maybe<OrderBy>,
  restricted_account_settings_lead?: Maybe<OrderBy>,
  restricted_activity_list_lead?: Maybe<OrderBy>,
  restricted_approve_account_lead?: Maybe<OrderBy>,
  restricted_approve_lead?: Maybe<OrderBy>,
  restricted_approve_status_lead?: Maybe<OrderBy>,
  restricted_category_lead?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_credit_transaction_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_deposit_transaction_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_flag_lead?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_manage_account_lead?: Maybe<OrderBy>,
  restricted_notes_lead?: Maybe<OrderBy>,
  restricted_representative_lead?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_lead?: Maybe<OrderBy>,
  restricted_risk_lead?: Maybe<OrderBy>,
  restricted_service_fee_transaction_lead?: Maybe<OrderBy>,
  restricted_tag_lead?: Maybe<OrderBy>,
  restricted_transaction_list_lead?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  restricted_transfer_transaction_lead?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_credit_transaction_lead?: Maybe<OrderBy>,
  user_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_credit_transaction_lead?: Maybe<OrderBy>,
  user_limited_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_transaction_lead?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_manage_account_lead?: Maybe<OrderBy>,
  user_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_transfer_transaction_lead?: Maybe<OrderBy>,
  user_withdrawal_transaction_lead?: Maybe<OrderBy>,
}

export interface MasterAdminLeadPermissionMinFields {
   __typename?: 'master_admin_lead_permission_min_fields',
  admin_account_list_lead?: Maybe<Scalars['String']>,
  admin_account_settings_lead?: Maybe<Scalars['String']>,
  admin_activity_list_lead?: Maybe<Scalars['String']>,
  admin_approve_account_lead?: Maybe<Scalars['String']>,
  admin_approve_lead?: Maybe<Scalars['String']>,
  admin_approve_status_lead?: Maybe<Scalars['String']>,
  admin_category_lead?: Maybe<Scalars['String']>,
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_credit_transaction_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_deposit_transaction_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_flag_lead?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_manage_account_lead?: Maybe<Scalars['String']>,
  admin_notes_lead?: Maybe<Scalars['String']>,
  admin_representative_lead?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  admin_risk_lead?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  admin_tag_lead?: Maybe<Scalars['String']>,
  admin_transaction_list_lead?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  admin_transfer_transaction_lead?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_lead?: Maybe<Scalars['String']>,
  manager_approve_status_lead?: Maybe<Scalars['String']>,
  manager_category_lead?: Maybe<Scalars['String']>,
  manager_credit_transaction_lead?: Maybe<Scalars['String']>,
  manager_deposit_transaction_lead?: Maybe<Scalars['String']>,
  manager_flag_lead?: Maybe<Scalars['String']>,
  manager_lead_list?: Maybe<Scalars['String']>,
  manager_manage_account_lead?: Maybe<Scalars['String']>,
  manager_notes_lead?: Maybe<Scalars['String']>,
  manager_representative_lead?: Maybe<Scalars['String']>,
  manager_risk_lead?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  manager_tag_lead?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  manager_transfer_transaction_lead?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  readonly_credit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_lead?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  restricted_account_list_lead?: Maybe<Scalars['String']>,
  restricted_account_settings_lead?: Maybe<Scalars['String']>,
  restricted_activity_list_lead?: Maybe<Scalars['String']>,
  restricted_approve_account_lead?: Maybe<Scalars['String']>,
  restricted_approve_lead?: Maybe<Scalars['String']>,
  restricted_approve_status_lead?: Maybe<Scalars['String']>,
  restricted_category_lead?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_credit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_flag_lead?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_manage_account_lead?: Maybe<Scalars['String']>,
  restricted_notes_lead?: Maybe<Scalars['String']>,
  restricted_representative_lead?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restricted_risk_lead?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  restricted_tag_lead?: Maybe<Scalars['String']>,
  restricted_transaction_list_lead?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_lead?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_manage_account_lead?: Maybe<Scalars['String']>,
  user_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
}

export interface MasterAdminLeadPermissionMinOrderBy {
  admin_account_list_lead?: Maybe<OrderBy>,
  admin_account_settings_lead?: Maybe<OrderBy>,
  admin_activity_list_lead?: Maybe<OrderBy>,
  admin_approve_account_lead?: Maybe<OrderBy>,
  admin_approve_lead?: Maybe<OrderBy>,
  admin_approve_status_lead?: Maybe<OrderBy>,
  admin_category_lead?: Maybe<OrderBy>,
  admin_create_lead?: Maybe<OrderBy>,
  admin_credit_transaction_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_deposit_transaction_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_flag_lead?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_manage_account_lead?: Maybe<OrderBy>,
  admin_notes_lead?: Maybe<OrderBy>,
  admin_representative_lead?: Maybe<OrderBy>,
  admin_restrict_negative_balance_lead?: Maybe<OrderBy>,
  admin_risk_lead?: Maybe<OrderBy>,
  admin_service_fee_transaction_lead?: Maybe<OrderBy>,
  admin_tag_lead?: Maybe<OrderBy>,
  admin_transaction_list_lead?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  admin_transfer_transaction_lead?: Maybe<OrderBy>,
  admin_withdrawal_transaction_lead?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_lead?: Maybe<OrderBy>,
  manager_approve_status_lead?: Maybe<OrderBy>,
  manager_category_lead?: Maybe<OrderBy>,
  manager_credit_transaction_lead?: Maybe<OrderBy>,
  manager_deposit_transaction_lead?: Maybe<OrderBy>,
  manager_flag_lead?: Maybe<OrderBy>,
  manager_lead_list?: Maybe<OrderBy>,
  manager_manage_account_lead?: Maybe<OrderBy>,
  manager_notes_lead?: Maybe<OrderBy>,
  manager_representative_lead?: Maybe<OrderBy>,
  manager_risk_lead?: Maybe<OrderBy>,
  manager_service_fee_transaction_lead?: Maybe<OrderBy>,
  manager_tag_lead?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  manager_transfer_transaction_lead?: Maybe<OrderBy>,
  manager_withdrawal_transaction_lead?: Maybe<OrderBy>,
  readonly_credit_transaction_lead?: Maybe<OrderBy>,
  readonly_deposit_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_transaction_lead?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_lead?: Maybe<OrderBy>,
  restricted_account_list_lead?: Maybe<OrderBy>,
  restricted_account_settings_lead?: Maybe<OrderBy>,
  restricted_activity_list_lead?: Maybe<OrderBy>,
  restricted_approve_account_lead?: Maybe<OrderBy>,
  restricted_approve_lead?: Maybe<OrderBy>,
  restricted_approve_status_lead?: Maybe<OrderBy>,
  restricted_category_lead?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_credit_transaction_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_deposit_transaction_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_flag_lead?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_manage_account_lead?: Maybe<OrderBy>,
  restricted_notes_lead?: Maybe<OrderBy>,
  restricted_representative_lead?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_lead?: Maybe<OrderBy>,
  restricted_risk_lead?: Maybe<OrderBy>,
  restricted_service_fee_transaction_lead?: Maybe<OrderBy>,
  restricted_tag_lead?: Maybe<OrderBy>,
  restricted_transaction_list_lead?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  restricted_transfer_transaction_lead?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_credit_transaction_lead?: Maybe<OrderBy>,
  user_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_credit_transaction_lead?: Maybe<OrderBy>,
  user_limited_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_transaction_lead?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_manage_account_lead?: Maybe<OrderBy>,
  user_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_transfer_transaction_lead?: Maybe<OrderBy>,
  user_withdrawal_transaction_lead?: Maybe<OrderBy>,
}

export interface MasterAdminLeadPermissionMutationResponse {
   __typename?: 'master_admin_lead_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterAdminLeadPermission>,
}

export interface MasterAdminLeadPermissionObjRelInsertInput {
  data: MasterAdminLeadPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminLeadPermissionOnConflict>,
}

export interface MasterAdminLeadPermissionOnConflict {
  constraint: MasterAdminLeadPermissionConstraint,
  update_columns: Array<MasterAdminLeadPermissionUpdateColumn>,
  where?: Maybe<MasterAdminLeadPermissionBoolExp>,
}

export interface MasterAdminLeadPermissionOrderBy {
  admin_account_list_lead?: Maybe<OrderBy>,
  admin_account_settings_lead?: Maybe<OrderBy>,
  admin_activity_list_lead?: Maybe<OrderBy>,
  admin_approve_account_lead?: Maybe<OrderBy>,
  admin_approve_lead?: Maybe<OrderBy>,
  admin_approve_status_lead?: Maybe<OrderBy>,
  admin_category_lead?: Maybe<OrderBy>,
  admin_create_lead?: Maybe<OrderBy>,
  admin_credit_transaction_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_deposit_transaction_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_flag_lead?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_manage_account_lead?: Maybe<OrderBy>,
  admin_notes_lead?: Maybe<OrderBy>,
  admin_representative_lead?: Maybe<OrderBy>,
  admin_restrict_negative_balance_lead?: Maybe<OrderBy>,
  admin_risk_lead?: Maybe<OrderBy>,
  admin_service_fee_transaction_lead?: Maybe<OrderBy>,
  admin_tag_lead?: Maybe<OrderBy>,
  admin_transaction_list_lead?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  admin_transfer_transaction_lead?: Maybe<OrderBy>,
  admin_withdrawal_transaction_lead?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_lead?: Maybe<OrderBy>,
  manager_approve_status_lead?: Maybe<OrderBy>,
  manager_category_lead?: Maybe<OrderBy>,
  manager_credit_transaction_lead?: Maybe<OrderBy>,
  manager_deposit_transaction_lead?: Maybe<OrderBy>,
  manager_flag_lead?: Maybe<OrderBy>,
  manager_lead_list?: Maybe<OrderBy>,
  manager_manage_account_lead?: Maybe<OrderBy>,
  manager_notes_lead?: Maybe<OrderBy>,
  manager_representative_lead?: Maybe<OrderBy>,
  manager_risk_lead?: Maybe<OrderBy>,
  manager_service_fee_transaction_lead?: Maybe<OrderBy>,
  manager_tag_lead?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  manager_transfer_transaction_lead?: Maybe<OrderBy>,
  manager_withdrawal_transaction_lead?: Maybe<OrderBy>,
  readonly_credit_transaction_lead?: Maybe<OrderBy>,
  readonly_deposit_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  readonly_transfer_transaction_lead?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_lead?: Maybe<OrderBy>,
  restricted_account_list_lead?: Maybe<OrderBy>,
  restricted_account_settings_lead?: Maybe<OrderBy>,
  restricted_activity_list_lead?: Maybe<OrderBy>,
  restricted_approve_account_lead?: Maybe<OrderBy>,
  restricted_approve_lead?: Maybe<OrderBy>,
  restricted_approve_status_lead?: Maybe<OrderBy>,
  restricted_category_lead?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_credit_transaction_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_deposit_transaction_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_flag_lead?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_manage_account_lead?: Maybe<OrderBy>,
  restricted_notes_lead?: Maybe<OrderBy>,
  restricted_representative_lead?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_lead?: Maybe<OrderBy>,
  restricted_risk_lead?: Maybe<OrderBy>,
  restricted_service_fee_transaction_lead?: Maybe<OrderBy>,
  restricted_tag_lead?: Maybe<OrderBy>,
  restricted_transaction_list_lead?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  restricted_transfer_transaction_lead?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_credit_transaction_lead?: Maybe<OrderBy>,
  user_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_credit_transaction_lead?: Maybe<OrderBy>,
  user_limited_deposit_transaction_lead?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_limited_transfer_transaction_lead?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_lead?: Maybe<OrderBy>,
  user_manage_account_lead?: Maybe<OrderBy>,
  user_service_fee_transaction_lead?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_lead?: Maybe<OrderBy>,
  user_transfer_transaction_lead?: Maybe<OrderBy>,
  user_withdrawal_transaction_lead?: Maybe<OrderBy>,
}

export interface MasterAdminLeadPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterAdminLeadPermissionSelectColumn {
  AdminAccountListLead = 'admin_account_list_lead',
  AdminAccountSettingsLead = 'admin_account_settings_lead',
  AdminActivityListLead = 'admin_activity_list_lead',
  AdminApproveAccountLead = 'admin_approve_account_lead',
  AdminApproveLead = 'admin_approve_lead',
  AdminApproveStatusLead = 'admin_approve_status_lead',
  AdminCategoryLead = 'admin_category_lead',
  AdminCreateLead = 'admin_create_lead',
  AdminCreditTransactionLead = 'admin_credit_transaction_lead',
  AdminDeleteLead = 'admin_delete_lead',
  AdminDepositTransactionLead = 'admin_deposit_transaction_lead',
  AdminEditLead = 'admin_edit_lead',
  AdminFlagLead = 'admin_flag_lead',
  AdminLeadList = 'admin_lead_list',
  AdminManageAccountLead = 'admin_manage_account_lead',
  AdminNotesLead = 'admin_notes_lead',
  AdminRepresentativeLead = 'admin_representative_lead',
  AdminRestrictNegativeBalanceLead = 'admin_restrict_negative_balance_lead',
  AdminRiskLead = 'admin_risk_lead',
  AdminServiceFeeTransactionLead = 'admin_service_fee_transaction_lead',
  AdminTagLead = 'admin_tag_lead',
  AdminTransactionListLead = 'admin_transaction_list_lead',
  AdminTransferBetweenProfilesTransactionLead = 'admin_transfer_between_profiles_transaction_lead',
  AdminTransferTransactionLead = 'admin_transfer_transaction_lead',
  AdminWithdrawalTransactionLead = 'admin_withdrawal_transaction_lead',
  Id = 'id',
  ManagerAccountSettingsLead = 'manager_account_settings_lead',
  ManagerApproveStatusLead = 'manager_approve_status_lead',
  ManagerCategoryLead = 'manager_category_lead',
  ManagerCreditTransactionLead = 'manager_credit_transaction_lead',
  ManagerDepositTransactionLead = 'manager_deposit_transaction_lead',
  ManagerFlagLead = 'manager_flag_lead',
  ManagerLeadList = 'manager_lead_list',
  ManagerManageAccountLead = 'manager_manage_account_lead',
  ManagerNotesLead = 'manager_notes_lead',
  ManagerRepresentativeLead = 'manager_representative_lead',
  ManagerRiskLead = 'manager_risk_lead',
  ManagerServiceFeeTransactionLead = 'manager_service_fee_transaction_lead',
  ManagerTagLead = 'manager_tag_lead',
  ManagerTransferBetweenProfilesTransactionLead = 'manager_transfer_between_profiles_transaction_lead',
  ManagerTransferTransactionLead = 'manager_transfer_transaction_lead',
  ManagerWithdrawalTransactionLead = 'manager_withdrawal_transaction_lead',
  ReadonlyCreditTransactionLead = 'readonly_credit_transaction_lead',
  ReadonlyDepositTransactionLead = 'readonly_deposit_transaction_lead',
  ReadonlyTransferBetweenProfilesTransactionLead = 'readonly_transfer_between_profiles_transaction_lead',
  ReadonlyTransferTransactionLead = 'readonly_transfer_transaction_lead',
  ReadonlyWithdrawalTransactionLead = 'readonly_withdrawal_transaction_lead',
  RestrictedAccountListLead = 'restricted_account_list_lead',
  RestrictedAccountSettingsLead = 'restricted_account_settings_lead',
  RestrictedActivityListLead = 'restricted_activity_list_lead',
  RestrictedApproveAccountLead = 'restricted_approve_account_lead',
  RestrictedApproveLead = 'restricted_approve_lead',
  RestrictedApproveStatusLead = 'restricted_approve_status_lead',
  RestrictedCategoryLead = 'restricted_category_lead',
  RestrictedCreateLead = 'restricted_create_lead',
  RestrictedCreditTransactionLead = 'restricted_credit_transaction_lead',
  RestrictedDeleteLead = 'restricted_delete_lead',
  RestrictedDepositTransactionLead = 'restricted_deposit_transaction_lead',
  RestrictedEditLead = 'restricted_edit_lead',
  RestrictedFlagLead = 'restricted_flag_lead',
  RestrictedLeadList = 'restricted_lead_list',
  RestrictedManageAccountLead = 'restricted_manage_account_lead',
  RestrictedNotesLead = 'restricted_notes_lead',
  RestrictedRepresentativeLead = 'restricted_representative_lead',
  RestrictedRestrictNegativeBalanceLead = 'restricted_restrict_negative_balance_lead',
  RestrictedRiskLead = 'restricted_risk_lead',
  RestrictedServiceFeeTransactionLead = 'restricted_service_fee_transaction_lead',
  RestrictedTagLead = 'restricted_tag_lead',
  RestrictedTransactionListLead = 'restricted_transaction_list_lead',
  RestrictedTransferBetweenProfilesTransactionLead = 'restricted_transfer_between_profiles_transaction_lead',
  RestrictedTransferTransactionLead = 'restricted_transfer_transaction_lead',
  RestrictedWithdrawalTransactionLead = 'restricted_withdrawal_transaction_lead',
  UserCreditTransactionLead = 'user_credit_transaction_lead',
  UserDepositTransactionLead = 'user_deposit_transaction_lead',
  UserLimitedCreditTransactionLead = 'user_limited_credit_transaction_lead',
  UserLimitedDepositTransactionLead = 'user_limited_deposit_transaction_lead',
  UserLimitedServiceFeeTransactionLead = 'user_limited_service_fee_transaction_lead',
  UserLimitedTransferBetweenProfilesTransactionLead = 'user_limited_transfer_between_profiles_transaction_lead',
  UserLimitedTransferTransactionLead = 'user_limited_transfer_transaction_lead',
  UserLimitedWithdrawalTransactionLead = 'user_limited_withdrawal_transaction_lead',
  UserManageAccountLead = 'user_manage_account_lead',
  UserServiceFeeTransactionLead = 'user_service_fee_transaction_lead',
  UserTransferBetweenProfilesTransactionLead = 'user_transfer_between_profiles_transaction_lead',
  UserTransferTransactionLead = 'user_transfer_transaction_lead',
  UserWithdrawalTransactionLead = 'user_withdrawal_transaction_lead'
}

export interface MasterAdminLeadPermissionSetInput {
  admin_account_list_lead?: Maybe<Scalars['String']>,
  admin_account_settings_lead?: Maybe<Scalars['String']>,
  admin_activity_list_lead?: Maybe<Scalars['String']>,
  admin_approve_account_lead?: Maybe<Scalars['String']>,
  admin_approve_lead?: Maybe<Scalars['String']>,
  admin_approve_status_lead?: Maybe<Scalars['String']>,
  admin_category_lead?: Maybe<Scalars['String']>,
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_credit_transaction_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_deposit_transaction_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_flag_lead?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_manage_account_lead?: Maybe<Scalars['String']>,
  admin_notes_lead?: Maybe<Scalars['String']>,
  admin_representative_lead?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  admin_risk_lead?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  admin_tag_lead?: Maybe<Scalars['String']>,
  admin_transaction_list_lead?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  admin_transfer_transaction_lead?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_lead?: Maybe<Scalars['String']>,
  manager_approve_status_lead?: Maybe<Scalars['String']>,
  manager_category_lead?: Maybe<Scalars['String']>,
  manager_credit_transaction_lead?: Maybe<Scalars['String']>,
  manager_deposit_transaction_lead?: Maybe<Scalars['String']>,
  manager_flag_lead?: Maybe<Scalars['String']>,
  manager_lead_list?: Maybe<Scalars['String']>,
  manager_manage_account_lead?: Maybe<Scalars['String']>,
  manager_notes_lead?: Maybe<Scalars['String']>,
  manager_representative_lead?: Maybe<Scalars['String']>,
  manager_risk_lead?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  manager_tag_lead?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  manager_transfer_transaction_lead?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  readonly_credit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_lead?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  restricted_account_list_lead?: Maybe<Scalars['String']>,
  restricted_account_settings_lead?: Maybe<Scalars['String']>,
  restricted_activity_list_lead?: Maybe<Scalars['String']>,
  restricted_approve_account_lead?: Maybe<Scalars['String']>,
  restricted_approve_lead?: Maybe<Scalars['String']>,
  restricted_approve_status_lead?: Maybe<Scalars['String']>,
  restricted_category_lead?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_credit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_flag_lead?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_manage_account_lead?: Maybe<Scalars['String']>,
  restricted_notes_lead?: Maybe<Scalars['String']>,
  restricted_representative_lead?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_lead?: Maybe<Scalars['String']>,
  restricted_risk_lead?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  restricted_tag_lead?: Maybe<Scalars['String']>,
  restricted_transaction_list_lead?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_lead?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
  user_manage_account_lead?: Maybe<Scalars['String']>,
  user_service_fee_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_lead?: Maybe<Scalars['String']>,
  user_transfer_transaction_lead?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_lead?: Maybe<Scalars['String']>,
}

export enum MasterAdminLeadPermissionUpdateColumn {
  AdminAccountListLead = 'admin_account_list_lead',
  AdminAccountSettingsLead = 'admin_account_settings_lead',
  AdminActivityListLead = 'admin_activity_list_lead',
  AdminApproveAccountLead = 'admin_approve_account_lead',
  AdminApproveLead = 'admin_approve_lead',
  AdminApproveStatusLead = 'admin_approve_status_lead',
  AdminCategoryLead = 'admin_category_lead',
  AdminCreateLead = 'admin_create_lead',
  AdminCreditTransactionLead = 'admin_credit_transaction_lead',
  AdminDeleteLead = 'admin_delete_lead',
  AdminDepositTransactionLead = 'admin_deposit_transaction_lead',
  AdminEditLead = 'admin_edit_lead',
  AdminFlagLead = 'admin_flag_lead',
  AdminLeadList = 'admin_lead_list',
  AdminManageAccountLead = 'admin_manage_account_lead',
  AdminNotesLead = 'admin_notes_lead',
  AdminRepresentativeLead = 'admin_representative_lead',
  AdminRestrictNegativeBalanceLead = 'admin_restrict_negative_balance_lead',
  AdminRiskLead = 'admin_risk_lead',
  AdminServiceFeeTransactionLead = 'admin_service_fee_transaction_lead',
  AdminTagLead = 'admin_tag_lead',
  AdminTransactionListLead = 'admin_transaction_list_lead',
  AdminTransferBetweenProfilesTransactionLead = 'admin_transfer_between_profiles_transaction_lead',
  AdminTransferTransactionLead = 'admin_transfer_transaction_lead',
  AdminWithdrawalTransactionLead = 'admin_withdrawal_transaction_lead',
  Id = 'id',
  ManagerAccountSettingsLead = 'manager_account_settings_lead',
  ManagerApproveStatusLead = 'manager_approve_status_lead',
  ManagerCategoryLead = 'manager_category_lead',
  ManagerCreditTransactionLead = 'manager_credit_transaction_lead',
  ManagerDepositTransactionLead = 'manager_deposit_transaction_lead',
  ManagerFlagLead = 'manager_flag_lead',
  ManagerLeadList = 'manager_lead_list',
  ManagerManageAccountLead = 'manager_manage_account_lead',
  ManagerNotesLead = 'manager_notes_lead',
  ManagerRepresentativeLead = 'manager_representative_lead',
  ManagerRiskLead = 'manager_risk_lead',
  ManagerServiceFeeTransactionLead = 'manager_service_fee_transaction_lead',
  ManagerTagLead = 'manager_tag_lead',
  ManagerTransferBetweenProfilesTransactionLead = 'manager_transfer_between_profiles_transaction_lead',
  ManagerTransferTransactionLead = 'manager_transfer_transaction_lead',
  ManagerWithdrawalTransactionLead = 'manager_withdrawal_transaction_lead',
  ReadonlyCreditTransactionLead = 'readonly_credit_transaction_lead',
  ReadonlyDepositTransactionLead = 'readonly_deposit_transaction_lead',
  ReadonlyTransferBetweenProfilesTransactionLead = 'readonly_transfer_between_profiles_transaction_lead',
  ReadonlyTransferTransactionLead = 'readonly_transfer_transaction_lead',
  ReadonlyWithdrawalTransactionLead = 'readonly_withdrawal_transaction_lead',
  RestrictedAccountListLead = 'restricted_account_list_lead',
  RestrictedAccountSettingsLead = 'restricted_account_settings_lead',
  RestrictedActivityListLead = 'restricted_activity_list_lead',
  RestrictedApproveAccountLead = 'restricted_approve_account_lead',
  RestrictedApproveLead = 'restricted_approve_lead',
  RestrictedApproveStatusLead = 'restricted_approve_status_lead',
  RestrictedCategoryLead = 'restricted_category_lead',
  RestrictedCreateLead = 'restricted_create_lead',
  RestrictedCreditTransactionLead = 'restricted_credit_transaction_lead',
  RestrictedDeleteLead = 'restricted_delete_lead',
  RestrictedDepositTransactionLead = 'restricted_deposit_transaction_lead',
  RestrictedEditLead = 'restricted_edit_lead',
  RestrictedFlagLead = 'restricted_flag_lead',
  RestrictedLeadList = 'restricted_lead_list',
  RestrictedManageAccountLead = 'restricted_manage_account_lead',
  RestrictedNotesLead = 'restricted_notes_lead',
  RestrictedRepresentativeLead = 'restricted_representative_lead',
  RestrictedRestrictNegativeBalanceLead = 'restricted_restrict_negative_balance_lead',
  RestrictedRiskLead = 'restricted_risk_lead',
  RestrictedServiceFeeTransactionLead = 'restricted_service_fee_transaction_lead',
  RestrictedTagLead = 'restricted_tag_lead',
  RestrictedTransactionListLead = 'restricted_transaction_list_lead',
  RestrictedTransferBetweenProfilesTransactionLead = 'restricted_transfer_between_profiles_transaction_lead',
  RestrictedTransferTransactionLead = 'restricted_transfer_transaction_lead',
  RestrictedWithdrawalTransactionLead = 'restricted_withdrawal_transaction_lead',
  UserCreditTransactionLead = 'user_credit_transaction_lead',
  UserDepositTransactionLead = 'user_deposit_transaction_lead',
  UserLimitedCreditTransactionLead = 'user_limited_credit_transaction_lead',
  UserLimitedDepositTransactionLead = 'user_limited_deposit_transaction_lead',
  UserLimitedServiceFeeTransactionLead = 'user_limited_service_fee_transaction_lead',
  UserLimitedTransferBetweenProfilesTransactionLead = 'user_limited_transfer_between_profiles_transaction_lead',
  UserLimitedTransferTransactionLead = 'user_limited_transfer_transaction_lead',
  UserLimitedWithdrawalTransactionLead = 'user_limited_withdrawal_transaction_lead',
  UserManageAccountLead = 'user_manage_account_lead',
  UserServiceFeeTransactionLead = 'user_service_fee_transaction_lead',
  UserTransferBetweenProfilesTransactionLead = 'user_transfer_between_profiles_transaction_lead',
  UserTransferTransactionLead = 'user_transfer_transaction_lead',
  UserWithdrawalTransactionLead = 'user_withdrawal_transaction_lead'
}

export interface MasterAdminPartnerPermission {
   __typename?: 'master_admin_partner_permission',
  admin_account_list_partner?: Maybe<Scalars['String']>,
  admin_account_settings_partner?: Maybe<Scalars['String']>,
  admin_activity_list_partner?: Maybe<Scalars['String']>,
  admin_approve_account_partner?: Maybe<Scalars['String']>,
  admin_approve_partner?: Maybe<Scalars['String']>,
  admin_approve_status_partner?: Maybe<Scalars['String']>,
  admin_category_partner?: Maybe<Scalars['String']>,
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_credit_transaction_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_deposit_transaction_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_flag_partner?: Maybe<Scalars['String']>,
  admin_manage_account_partner?: Maybe<Scalars['String']>,
  admin_notes_partner?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_representative_partner?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  admin_risk_partner?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  admin_tag_partner?: Maybe<Scalars['String']>,
  admin_transaction_list_partner?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  admin_transfer_transaction_partner?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manager_account_settings_partner?: Maybe<Scalars['String']>,
  manager_approve_status_partner?: Maybe<Scalars['String']>,
  manager_category_partner?: Maybe<Scalars['String']>,
  manager_credit_transaction_partner?: Maybe<Scalars['String']>,
  manager_deposit_transaction_partner?: Maybe<Scalars['String']>,
  manager_flag_partner?: Maybe<Scalars['String']>,
  manager_manage_account_partner?: Maybe<Scalars['String']>,
  manager_notes_partner?: Maybe<Scalars['String']>,
  manager_partner_list?: Maybe<Scalars['String']>,
  manager_representative_partner?: Maybe<Scalars['String']>,
  manager_risk_partner?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  manager_tag_partner?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  manager_transfer_transaction_partner?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  readonly_credit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_partner?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  restricted_account_list_partner?: Maybe<Scalars['String']>,
  restricted_account_settings_partner?: Maybe<Scalars['String']>,
  restricted_activity_list_partner?: Maybe<Scalars['String']>,
  restricted_approve_account_partner?: Maybe<Scalars['String']>,
  restricted_approve_partner?: Maybe<Scalars['String']>,
  restricted_approve_status_partner?: Maybe<Scalars['String']>,
  restricted_category_partner?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_credit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_flag_partner?: Maybe<Scalars['String']>,
  restricted_manage_account_partner?: Maybe<Scalars['String']>,
  restricted_notes_partner?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_representative_partner?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  restricted_risk_partner?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  restricted_tag_partner?: Maybe<Scalars['String']>,
  restricted_transaction_list_partner?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_partner?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_manage_account_partner?: Maybe<Scalars['String']>,
  user_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface MasterAdminPartnerPermissionAggregate {
   __typename?: 'master_admin_partner_permission_aggregate',
  aggregate?: Maybe<MasterAdminPartnerPermissionAggregateFields>,
  nodes: Array<MasterAdminPartnerPermission>,
}

export interface MasterAdminPartnerPermissionAggregateFields {
   __typename?: 'master_admin_partner_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterAdminPartnerPermissionMaxFields>,
  min?: Maybe<MasterAdminPartnerPermissionMinFields>,
}


export interface MasterAdminPartnerPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterAdminPartnerPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterAdminPartnerPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterAdminPartnerPermissionMaxOrderBy>,
  min?: Maybe<MasterAdminPartnerPermissionMinOrderBy>,
}

export interface MasterAdminPartnerPermissionArrRelInsertInput {
  data: Array<MasterAdminPartnerPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminPartnerPermissionOnConflict>,
}

export interface MasterAdminPartnerPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterAdminPartnerPermissionBoolExp>>>,
  _not?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterAdminPartnerPermissionBoolExp>>>,
  admin_account_list_partner?: Maybe<StringComparisonExp>,
  admin_account_settings_partner?: Maybe<StringComparisonExp>,
  admin_activity_list_partner?: Maybe<StringComparisonExp>,
  admin_approve_account_partner?: Maybe<StringComparisonExp>,
  admin_approve_partner?: Maybe<StringComparisonExp>,
  admin_approve_status_partner?: Maybe<StringComparisonExp>,
  admin_category_partner?: Maybe<StringComparisonExp>,
  admin_create_partner?: Maybe<StringComparisonExp>,
  admin_credit_transaction_partner?: Maybe<StringComparisonExp>,
  admin_delete_partner?: Maybe<StringComparisonExp>,
  admin_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  admin_edit_partner?: Maybe<StringComparisonExp>,
  admin_flag_partner?: Maybe<StringComparisonExp>,
  admin_manage_account_partner?: Maybe<StringComparisonExp>,
  admin_notes_partner?: Maybe<StringComparisonExp>,
  admin_partner_list?: Maybe<StringComparisonExp>,
  admin_representative_partner?: Maybe<StringComparisonExp>,
  admin_restrict_negative_balance_partner?: Maybe<StringComparisonExp>,
  admin_risk_partner?: Maybe<StringComparisonExp>,
  admin_service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  admin_tag_partner?: Maybe<StringComparisonExp>,
  admin_transaction_list_partner?: Maybe<StringComparisonExp>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  admin_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  admin_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_account_settings_partner?: Maybe<StringComparisonExp>,
  manager_approve_status_partner?: Maybe<StringComparisonExp>,
  manager_category_partner?: Maybe<StringComparisonExp>,
  manager_credit_transaction_partner?: Maybe<StringComparisonExp>,
  manager_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  manager_flag_partner?: Maybe<StringComparisonExp>,
  manager_manage_account_partner?: Maybe<StringComparisonExp>,
  manager_notes_partner?: Maybe<StringComparisonExp>,
  manager_partner_list?: Maybe<StringComparisonExp>,
  manager_representative_partner?: Maybe<StringComparisonExp>,
  manager_risk_partner?: Maybe<StringComparisonExp>,
  manager_service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  manager_tag_partner?: Maybe<StringComparisonExp>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  manager_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  manager_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
  readonly_credit_transaction_partner?: Maybe<StringComparisonExp>,
  readonly_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  readonly_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  readonly_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_account_list_partner?: Maybe<StringComparisonExp>,
  restricted_account_settings_partner?: Maybe<StringComparisonExp>,
  restricted_activity_list_partner?: Maybe<StringComparisonExp>,
  restricted_approve_account_partner?: Maybe<StringComparisonExp>,
  restricted_approve_partner?: Maybe<StringComparisonExp>,
  restricted_approve_status_partner?: Maybe<StringComparisonExp>,
  restricted_category_partner?: Maybe<StringComparisonExp>,
  restricted_create_partner?: Maybe<StringComparisonExp>,
  restricted_credit_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_delete_partner?: Maybe<StringComparisonExp>,
  restricted_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_edit_partner?: Maybe<StringComparisonExp>,
  restricted_flag_partner?: Maybe<StringComparisonExp>,
  restricted_manage_account_partner?: Maybe<StringComparisonExp>,
  restricted_notes_partner?: Maybe<StringComparisonExp>,
  restricted_partner_list?: Maybe<StringComparisonExp>,
  restricted_representative_partner?: Maybe<StringComparisonExp>,
  restricted_restrict_negative_balance_partner?: Maybe<StringComparisonExp>,
  restricted_risk_partner?: Maybe<StringComparisonExp>,
  restricted_service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_tag_partner?: Maybe<StringComparisonExp>,
  restricted_transaction_list_partner?: Maybe<StringComparisonExp>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  restricted_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
  user_credit_transaction_partner?: Maybe<StringComparisonExp>,
  user_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_credit_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_deposit_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  user_limited_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
  user_manage_account_partner?: Maybe<StringComparisonExp>,
  user_service_fee_transaction_partner?: Maybe<StringComparisonExp>,
  user_transfer_between_profiles_transaction_partner?: Maybe<StringComparisonExp>,
  user_transfer_transaction_partner?: Maybe<StringComparisonExp>,
  user_withdrawal_transaction_partner?: Maybe<StringComparisonExp>,
}

export enum MasterAdminPartnerPermissionConstraint {
  MasterAdminPartnerPermissiAdminAccountSettingsPartneKey = 'master_admin_partner_permissi_admin_account_settings_partne_key',
  MasterAdminPartnerPermissiAdminApproveAccountPartnerKey = 'master_admin_partner_permissi_admin_approve_account_partner_key',
  MasterAdminPartnerPermissiAdminCreditTransactionPartKey = 'master_admin_partner_permissi_admin_credit_transaction_part_key',
  MasterAdminPartnerPermissiAdminDepositTransactionParKey = 'master_admin_partner_permissi_admin_deposit_transaction_par_key',
  MasterAdminPartnerPermissiAdminRestrictNegativeBalanKey = 'master_admin_partner_permissi_admin_restrict_negative_balan_key',
  MasterAdminPartnerPermissiAdminServiceFeeTransactionKey = 'master_admin_partner_permissi_admin_service_fee_transaction_key',
  MasterAdminPartnerPermissiAdminTransactionListPartneKey = 'master_admin_partner_permissi_admin_transaction_list_partne_key',
  MasterAdminPartnerPermissiAdminTransferBetweenProfilKey = 'master_admin_partner_permissi_admin_transfer_between_profil_key',
  MasterAdminPartnerPermissiAdminTransferTransactionPaKey = 'master_admin_partner_permissi_admin_transfer_transaction_pa_key',
  MasterAdminPartnerPermissiAdminWithdrawalTransactionKey = 'master_admin_partner_permissi_admin_withdrawal_transaction__key',
  MasterAdminPartnerPermissiManagerAccountSettingsPartKey = 'master_admin_partner_permissi_manager_account_settings_part_key',
  MasterAdminPartnerPermissiManagerApproveStatusPartneKey = 'master_admin_partner_permissi_manager_approve_status_partne_key',
  MasterAdminPartnerPermissiManagerCreditTransactionPaKey = 'master_admin_partner_permissi_manager_credit_transaction_pa_key',
  MasterAdminPartnerPermissiManagerDepositTransactionPKey = 'master_admin_partner_permissi_manager_deposit_transaction_p_key',
  MasterAdminPartnerPermissiManagerManageAccountPartneKey = 'master_admin_partner_permissi_manager_manage_account_partne_key',
  MasterAdminPartnerPermissiManagerRepresentativePartneKey = 'master_admin_partner_permissi_manager_representative_partne_key',
  MasterAdminPartnerPermissiManagerServiceFeeTransactiKey = 'master_admin_partner_permissi_manager_service_fee_transacti_key',
  MasterAdminPartnerPermissiManagerTransferBetweenProfKey = 'master_admin_partner_permissi_manager_transfer_between_prof_key',
  MasterAdminPartnerPermissiManagerTransferTransactionKey = 'master_admin_partner_permissi_manager_transfer_transaction__key',
  MasterAdminPartnerPermissiManagerWithdrawalTransactioKey = 'master_admin_partner_permissi_manager_withdrawal_transactio_key',
  MasterAdminPartnerPermissiReadonlyCreditTransactionPKey = 'master_admin_partner_permissi_readonly_credit_transaction_p_key',
  MasterAdminPartnerPermissiReadonlyDepositTransactionKey = 'master_admin_partner_permissi_readonly_deposit_transaction__key',
  MasterAdminPartnerPermissiReadonlyTransferBetweenProKey = 'master_admin_partner_permissi_readonly_transfer_between_pro_key',
  MasterAdminPartnerPermissiReadonlyTransferTransactionKey = 'master_admin_partner_permissi_readonly_transfer_transaction_key',
  MasterAdminPartnerPermissiReadonlyWithdrawalTransactiKey = 'master_admin_partner_permissi_readonly_withdrawal_transacti_key',
  MasterAdminPartnerPermissiRestrictedAccountListPartnKey = 'master_admin_partner_permissi_restricted_account_list_partn_key',
  MasterAdminPartnerPermissiRestrictedAccountSettingsPKey = 'master_admin_partner_permissi_restricted_account_settings_p_key',
  MasterAdminPartnerPermissiRestrictedActivityListPartKey = 'master_admin_partner_permissi_restricted_activity_list_part_key',
  MasterAdminPartnerPermissiRestrictedApproveAccountPaKey = 'master_admin_partner_permissi_restricted_approve_account_pa_key',
  MasterAdminPartnerPermissiRestrictedApproveStatusParKey = 'master_admin_partner_permissi_restricted_approve_status_par_key',
  MasterAdminPartnerPermissiRestrictedCreditTransactionKey = 'master_admin_partner_permissi_restricted_credit_transaction_key',
  MasterAdminPartnerPermissiRestrictedDepositTransactioKey = 'master_admin_partner_permissi_restricted_deposit_transactio_key',
  MasterAdminPartnerPermissiRestrictedManageAccountParKey = 'master_admin_partner_permissi_restricted_manage_account_par_key',
  MasterAdminPartnerPermissiRestrictedRepresentativeParKey = 'master_admin_partner_permissi_restricted_representative_par_key',
  MasterAdminPartnerPermissiRestrictedRestrictNegativeKey = 'master_admin_partner_permissi_restricted_restrict_negative__key',
  MasterAdminPartnerPermissiRestrictedServiceFeeTransaKey = 'master_admin_partner_permissi_restricted_service_fee_transa_key',
  MasterAdminPartnerPermissiRestrictedTransactionListPKey = 'master_admin_partner_permissi_restricted_transaction_list_p_key',
  MasterAdminPartnerPermissiRestrictedTransferBetweenPKey = 'master_admin_partner_permissi_restricted_transfer_between_p_key',
  MasterAdminPartnerPermissiRestrictedTransferTransactiKey = 'master_admin_partner_permissi_restricted_transfer_transacti_key',
  MasterAdminPartnerPermissiRestrictedWithdrawalTransacKey = 'master_admin_partner_permissi_restricted_withdrawal_transac_key',
  MasterAdminPartnerPermissiUserCreditTransactionPartnKey = 'master_admin_partner_permissi_user_credit_transaction_partn_key',
  MasterAdminPartnerPermissiUserDepositTransactionPartKey = 'master_admin_partner_permissi_user_deposit_transaction_part_key',
  MasterAdminPartnerPermissiUserLimitedCreditTransactiKey = 'master_admin_partner_permissi_user_limited_credit_transacti_key',
  MasterAdminPartnerPermissiUserLimitedDepositTransactKey = 'master_admin_partner_permissi_user_limited_deposit_transact_key',
  MasterAdminPartnerPermissiUserLimitedServiceFeeTranKey = 'master_admin_partner_permissi_user_limited_service_fee_tran_key',
  MasterAdminPartnerPermissiUserLimitedTransferBetweenKey = 'master_admin_partner_permissi_user_limited_transfer_between_key',
  MasterAdminPartnerPermissiUserLimitedTransferTransacKey = 'master_admin_partner_permissi_user_limited_transfer_transac_key',
  MasterAdminPartnerPermissiUserLimitedWithdrawalTransKey = 'master_admin_partner_permissi_user_limited_withdrawal_trans_key',
  MasterAdminPartnerPermissiUserServiceFeeTransactionKey = 'master_admin_partner_permissi_user_service_fee_transaction__key',
  MasterAdminPartnerPermissiUserTransferBetweenProfileKey = 'master_admin_partner_permissi_user_transfer_between_profile_key',
  MasterAdminPartnerPermissiUserTransferTransactionParKey = 'master_admin_partner_permissi_user_transfer_transaction_par_key',
  MasterAdminPartnerPermissiUserWithdrawalTransactionPKey = 'master_admin_partner_permissi_user_withdrawal_transaction_p_key',
  MasterAdminPartnerPermissioAdminApproveStatusPartnerKey = 'master_admin_partner_permissio_admin_approve_status_partner_key',
  MasterAdminPartnerPermissioAdminManageAccountPartnerKey = 'master_admin_partner_permissio_admin_manage_account_partner_key',
  MasterAdminPartnerPermissioAdminRepresentativePartnerKey = 'master_admin_partner_permissio_admin_representative_partner_key',
  MasterAdminPartnerPermissionAdminAccountListPartnerKey = 'master_admin_partner_permission_admin_account_list_partner_key',
  MasterAdminPartnerPermissionAdminActivityListPartnerKey = 'master_admin_partner_permission_admin_activity_list_partner_key',
  MasterAdminPartnerPermissionAdminApprovePartnerKey = 'master_admin_partner_permission_admin_approve_partner_key',
  MasterAdminPartnerPermissionAdminCategoryPartnerKey = 'master_admin_partner_permission_admin_category_partner_key',
  MasterAdminPartnerPermissionAdminCreatePartnerKey = 'master_admin_partner_permission_admin_create_partner_key',
  MasterAdminPartnerPermissionAdminDeletePartnerKey = 'master_admin_partner_permission_admin_delete_partner_key',
  MasterAdminPartnerPermissionAdminEditPartnerKey = 'master_admin_partner_permission_admin_edit_partner_key',
  MasterAdminPartnerPermissionAdminFlagPartnerKey = 'master_admin_partner_permission_admin_flag_partner_key',
  MasterAdminPartnerPermissionAdminNotesPartnerKey = 'master_admin_partner_permission_admin_notes_partner_key',
  MasterAdminPartnerPermissionAdminPartnerListKey = 'master_admin_partner_permission_admin_partner_list_key',
  MasterAdminPartnerPermissionAdminRiskPartnerKey = 'master_admin_partner_permission_admin_risk_partner_key',
  MasterAdminPartnerPermissionAdminTagPartnerKey = 'master_admin_partner_permission_admin_tag_partner_key',
  MasterAdminPartnerPermissionManagerCategoryPartnerKey = 'master_admin_partner_permission_manager_category_partner_key',
  MasterAdminPartnerPermissionManagerFlagPartnerKey = 'master_admin_partner_permission_manager_flag_partner_key',
  MasterAdminPartnerPermissionManagerNotesPartnerKey = 'master_admin_partner_permission_manager_notes_partner_key',
  MasterAdminPartnerPermissionManagerPartnerListKey = 'master_admin_partner_permission_manager_partner_list_key',
  MasterAdminPartnerPermissionManagerRiskPartnerKey = 'master_admin_partner_permission_manager_risk_partner_key',
  MasterAdminPartnerPermissionManagerTagPartnerKey = 'master_admin_partner_permission_manager_tag_partner_key',
  MasterAdminPartnerPermissionPkey = 'master_admin_partner_permission_pkey',
  MasterAdminPartnerPermissionRestrictedApprovePartnerKey = 'master_admin_partner_permission_restricted_approve_partner_key',
  MasterAdminPartnerPermissionRestrictedCategoryPartnerKey = 'master_admin_partner_permission_restricted_category_partner_key',
  MasterAdminPartnerPermissionRestrictedCreatePartnerKey = 'master_admin_partner_permission_restricted_create_partner_key',
  MasterAdminPartnerPermissionRestrictedDeletePartnerKey = 'master_admin_partner_permission_restricted_delete_partner_key',
  MasterAdminPartnerPermissionRestrictedEditPartnerKey = 'master_admin_partner_permission_restricted_edit_partner_key',
  MasterAdminPartnerPermissionRestrictedFlagPartnerKey = 'master_admin_partner_permission_restricted_flag_partner_key',
  MasterAdminPartnerPermissionRestrictedNotesPartnerKey = 'master_admin_partner_permission_restricted_notes_partner_key',
  MasterAdminPartnerPermissionRestrictedPartnerListKey = 'master_admin_partner_permission_restricted_partner_list_key',
  MasterAdminPartnerPermissionRestrictedRiskPartnerKey = 'master_admin_partner_permission_restricted_risk_partner_key',
  MasterAdminPartnerPermissionRestrictedTagPartnerKey = 'master_admin_partner_permission_restricted_tag_partner_key',
  MasterAdminPartnerPermissionUserManageAccountPartnerKey = 'master_admin_partner_permission_user_manage_account_partner_key'
}

export interface MasterAdminPartnerPermissionInsertInput {
  admin_account_list_partner?: Maybe<Scalars['String']>,
  admin_account_settings_partner?: Maybe<Scalars['String']>,
  admin_activity_list_partner?: Maybe<Scalars['String']>,
  admin_approve_account_partner?: Maybe<Scalars['String']>,
  admin_approve_partner?: Maybe<Scalars['String']>,
  admin_approve_status_partner?: Maybe<Scalars['String']>,
  admin_category_partner?: Maybe<Scalars['String']>,
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_credit_transaction_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_deposit_transaction_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_flag_partner?: Maybe<Scalars['String']>,
  admin_manage_account_partner?: Maybe<Scalars['String']>,
  admin_notes_partner?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_representative_partner?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  admin_risk_partner?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  admin_tag_partner?: Maybe<Scalars['String']>,
  admin_transaction_list_partner?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  admin_transfer_transaction_partner?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_partner?: Maybe<Scalars['String']>,
  manager_approve_status_partner?: Maybe<Scalars['String']>,
  manager_category_partner?: Maybe<Scalars['String']>,
  manager_credit_transaction_partner?: Maybe<Scalars['String']>,
  manager_deposit_transaction_partner?: Maybe<Scalars['String']>,
  manager_flag_partner?: Maybe<Scalars['String']>,
  manager_manage_account_partner?: Maybe<Scalars['String']>,
  manager_notes_partner?: Maybe<Scalars['String']>,
  manager_partner_list?: Maybe<Scalars['String']>,
  manager_representative_partner?: Maybe<Scalars['String']>,
  manager_risk_partner?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  manager_tag_partner?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  manager_transfer_transaction_partner?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  readonly_credit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_partner?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  restricted_account_list_partner?: Maybe<Scalars['String']>,
  restricted_account_settings_partner?: Maybe<Scalars['String']>,
  restricted_activity_list_partner?: Maybe<Scalars['String']>,
  restricted_approve_account_partner?: Maybe<Scalars['String']>,
  restricted_approve_partner?: Maybe<Scalars['String']>,
  restricted_approve_status_partner?: Maybe<Scalars['String']>,
  restricted_category_partner?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_credit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_flag_partner?: Maybe<Scalars['String']>,
  restricted_manage_account_partner?: Maybe<Scalars['String']>,
  restricted_notes_partner?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_representative_partner?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  restricted_risk_partner?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  restricted_tag_partner?: Maybe<Scalars['String']>,
  restricted_transaction_list_partner?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_partner?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_manage_account_partner?: Maybe<Scalars['String']>,
  user_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface MasterAdminPartnerPermissionMaxFields {
   __typename?: 'master_admin_partner_permission_max_fields',
  admin_account_list_partner?: Maybe<Scalars['String']>,
  admin_account_settings_partner?: Maybe<Scalars['String']>,
  admin_activity_list_partner?: Maybe<Scalars['String']>,
  admin_approve_account_partner?: Maybe<Scalars['String']>,
  admin_approve_partner?: Maybe<Scalars['String']>,
  admin_approve_status_partner?: Maybe<Scalars['String']>,
  admin_category_partner?: Maybe<Scalars['String']>,
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_credit_transaction_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_deposit_transaction_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_flag_partner?: Maybe<Scalars['String']>,
  admin_manage_account_partner?: Maybe<Scalars['String']>,
  admin_notes_partner?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_representative_partner?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  admin_risk_partner?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  admin_tag_partner?: Maybe<Scalars['String']>,
  admin_transaction_list_partner?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  admin_transfer_transaction_partner?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_partner?: Maybe<Scalars['String']>,
  manager_approve_status_partner?: Maybe<Scalars['String']>,
  manager_category_partner?: Maybe<Scalars['String']>,
  manager_credit_transaction_partner?: Maybe<Scalars['String']>,
  manager_deposit_transaction_partner?: Maybe<Scalars['String']>,
  manager_flag_partner?: Maybe<Scalars['String']>,
  manager_manage_account_partner?: Maybe<Scalars['String']>,
  manager_notes_partner?: Maybe<Scalars['String']>,
  manager_partner_list?: Maybe<Scalars['String']>,
  manager_representative_partner?: Maybe<Scalars['String']>,
  manager_risk_partner?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  manager_tag_partner?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  manager_transfer_transaction_partner?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  readonly_credit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_partner?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  restricted_account_list_partner?: Maybe<Scalars['String']>,
  restricted_account_settings_partner?: Maybe<Scalars['String']>,
  restricted_activity_list_partner?: Maybe<Scalars['String']>,
  restricted_approve_account_partner?: Maybe<Scalars['String']>,
  restricted_approve_partner?: Maybe<Scalars['String']>,
  restricted_approve_status_partner?: Maybe<Scalars['String']>,
  restricted_category_partner?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_credit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_flag_partner?: Maybe<Scalars['String']>,
  restricted_manage_account_partner?: Maybe<Scalars['String']>,
  restricted_notes_partner?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_representative_partner?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  restricted_risk_partner?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  restricted_tag_partner?: Maybe<Scalars['String']>,
  restricted_transaction_list_partner?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_partner?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_manage_account_partner?: Maybe<Scalars['String']>,
  user_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface MasterAdminPartnerPermissionMaxOrderBy {
  admin_account_list_partner?: Maybe<OrderBy>,
  admin_account_settings_partner?: Maybe<OrderBy>,
  admin_activity_list_partner?: Maybe<OrderBy>,
  admin_approve_account_partner?: Maybe<OrderBy>,
  admin_approve_partner?: Maybe<OrderBy>,
  admin_approve_status_partner?: Maybe<OrderBy>,
  admin_category_partner?: Maybe<OrderBy>,
  admin_create_partner?: Maybe<OrderBy>,
  admin_credit_transaction_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_deposit_transaction_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_flag_partner?: Maybe<OrderBy>,
  admin_manage_account_partner?: Maybe<OrderBy>,
  admin_notes_partner?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_representative_partner?: Maybe<OrderBy>,
  admin_restrict_negative_balance_partner?: Maybe<OrderBy>,
  admin_risk_partner?: Maybe<OrderBy>,
  admin_service_fee_transaction_partner?: Maybe<OrderBy>,
  admin_tag_partner?: Maybe<OrderBy>,
  admin_transaction_list_partner?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  admin_transfer_transaction_partner?: Maybe<OrderBy>,
  admin_withdrawal_transaction_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_partner?: Maybe<OrderBy>,
  manager_approve_status_partner?: Maybe<OrderBy>,
  manager_category_partner?: Maybe<OrderBy>,
  manager_credit_transaction_partner?: Maybe<OrderBy>,
  manager_deposit_transaction_partner?: Maybe<OrderBy>,
  manager_flag_partner?: Maybe<OrderBy>,
  manager_manage_account_partner?: Maybe<OrderBy>,
  manager_notes_partner?: Maybe<OrderBy>,
  manager_partner_list?: Maybe<OrderBy>,
  manager_representative_partner?: Maybe<OrderBy>,
  manager_risk_partner?: Maybe<OrderBy>,
  manager_service_fee_transaction_partner?: Maybe<OrderBy>,
  manager_tag_partner?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  manager_transfer_transaction_partner?: Maybe<OrderBy>,
  manager_withdrawal_transaction_partner?: Maybe<OrderBy>,
  readonly_credit_transaction_partner?: Maybe<OrderBy>,
  readonly_deposit_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_transaction_partner?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_partner?: Maybe<OrderBy>,
  restricted_account_list_partner?: Maybe<OrderBy>,
  restricted_account_settings_partner?: Maybe<OrderBy>,
  restricted_activity_list_partner?: Maybe<OrderBy>,
  restricted_approve_account_partner?: Maybe<OrderBy>,
  restricted_approve_partner?: Maybe<OrderBy>,
  restricted_approve_status_partner?: Maybe<OrderBy>,
  restricted_category_partner?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_credit_transaction_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_deposit_transaction_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_flag_partner?: Maybe<OrderBy>,
  restricted_manage_account_partner?: Maybe<OrderBy>,
  restricted_notes_partner?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_representative_partner?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_partner?: Maybe<OrderBy>,
  restricted_risk_partner?: Maybe<OrderBy>,
  restricted_service_fee_transaction_partner?: Maybe<OrderBy>,
  restricted_tag_partner?: Maybe<OrderBy>,
  restricted_transaction_list_partner?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  restricted_transfer_transaction_partner?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_credit_transaction_partner?: Maybe<OrderBy>,
  user_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_credit_transaction_partner?: Maybe<OrderBy>,
  user_limited_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_transaction_partner?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_manage_account_partner?: Maybe<OrderBy>,
  user_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_transfer_transaction_partner?: Maybe<OrderBy>,
  user_withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface MasterAdminPartnerPermissionMinFields {
   __typename?: 'master_admin_partner_permission_min_fields',
  admin_account_list_partner?: Maybe<Scalars['String']>,
  admin_account_settings_partner?: Maybe<Scalars['String']>,
  admin_activity_list_partner?: Maybe<Scalars['String']>,
  admin_approve_account_partner?: Maybe<Scalars['String']>,
  admin_approve_partner?: Maybe<Scalars['String']>,
  admin_approve_status_partner?: Maybe<Scalars['String']>,
  admin_category_partner?: Maybe<Scalars['String']>,
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_credit_transaction_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_deposit_transaction_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_flag_partner?: Maybe<Scalars['String']>,
  admin_manage_account_partner?: Maybe<Scalars['String']>,
  admin_notes_partner?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_representative_partner?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  admin_risk_partner?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  admin_tag_partner?: Maybe<Scalars['String']>,
  admin_transaction_list_partner?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  admin_transfer_transaction_partner?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_partner?: Maybe<Scalars['String']>,
  manager_approve_status_partner?: Maybe<Scalars['String']>,
  manager_category_partner?: Maybe<Scalars['String']>,
  manager_credit_transaction_partner?: Maybe<Scalars['String']>,
  manager_deposit_transaction_partner?: Maybe<Scalars['String']>,
  manager_flag_partner?: Maybe<Scalars['String']>,
  manager_manage_account_partner?: Maybe<Scalars['String']>,
  manager_notes_partner?: Maybe<Scalars['String']>,
  manager_partner_list?: Maybe<Scalars['String']>,
  manager_representative_partner?: Maybe<Scalars['String']>,
  manager_risk_partner?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  manager_tag_partner?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  manager_transfer_transaction_partner?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  readonly_credit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_partner?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  restricted_account_list_partner?: Maybe<Scalars['String']>,
  restricted_account_settings_partner?: Maybe<Scalars['String']>,
  restricted_activity_list_partner?: Maybe<Scalars['String']>,
  restricted_approve_account_partner?: Maybe<Scalars['String']>,
  restricted_approve_partner?: Maybe<Scalars['String']>,
  restricted_approve_status_partner?: Maybe<Scalars['String']>,
  restricted_category_partner?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_credit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_flag_partner?: Maybe<Scalars['String']>,
  restricted_manage_account_partner?: Maybe<Scalars['String']>,
  restricted_notes_partner?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_representative_partner?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  restricted_risk_partner?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  restricted_tag_partner?: Maybe<Scalars['String']>,
  restricted_transaction_list_partner?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_partner?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_manage_account_partner?: Maybe<Scalars['String']>,
  user_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export interface MasterAdminPartnerPermissionMinOrderBy {
  admin_account_list_partner?: Maybe<OrderBy>,
  admin_account_settings_partner?: Maybe<OrderBy>,
  admin_activity_list_partner?: Maybe<OrderBy>,
  admin_approve_account_partner?: Maybe<OrderBy>,
  admin_approve_partner?: Maybe<OrderBy>,
  admin_approve_status_partner?: Maybe<OrderBy>,
  admin_category_partner?: Maybe<OrderBy>,
  admin_create_partner?: Maybe<OrderBy>,
  admin_credit_transaction_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_deposit_transaction_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_flag_partner?: Maybe<OrderBy>,
  admin_manage_account_partner?: Maybe<OrderBy>,
  admin_notes_partner?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_representative_partner?: Maybe<OrderBy>,
  admin_restrict_negative_balance_partner?: Maybe<OrderBy>,
  admin_risk_partner?: Maybe<OrderBy>,
  admin_service_fee_transaction_partner?: Maybe<OrderBy>,
  admin_tag_partner?: Maybe<OrderBy>,
  admin_transaction_list_partner?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  admin_transfer_transaction_partner?: Maybe<OrderBy>,
  admin_withdrawal_transaction_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_partner?: Maybe<OrderBy>,
  manager_approve_status_partner?: Maybe<OrderBy>,
  manager_category_partner?: Maybe<OrderBy>,
  manager_credit_transaction_partner?: Maybe<OrderBy>,
  manager_deposit_transaction_partner?: Maybe<OrderBy>,
  manager_flag_partner?: Maybe<OrderBy>,
  manager_manage_account_partner?: Maybe<OrderBy>,
  manager_notes_partner?: Maybe<OrderBy>,
  manager_partner_list?: Maybe<OrderBy>,
  manager_representative_partner?: Maybe<OrderBy>,
  manager_risk_partner?: Maybe<OrderBy>,
  manager_service_fee_transaction_partner?: Maybe<OrderBy>,
  manager_tag_partner?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  manager_transfer_transaction_partner?: Maybe<OrderBy>,
  manager_withdrawal_transaction_partner?: Maybe<OrderBy>,
  readonly_credit_transaction_partner?: Maybe<OrderBy>,
  readonly_deposit_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_transaction_partner?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_partner?: Maybe<OrderBy>,
  restricted_account_list_partner?: Maybe<OrderBy>,
  restricted_account_settings_partner?: Maybe<OrderBy>,
  restricted_activity_list_partner?: Maybe<OrderBy>,
  restricted_approve_account_partner?: Maybe<OrderBy>,
  restricted_approve_partner?: Maybe<OrderBy>,
  restricted_approve_status_partner?: Maybe<OrderBy>,
  restricted_category_partner?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_credit_transaction_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_deposit_transaction_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_flag_partner?: Maybe<OrderBy>,
  restricted_manage_account_partner?: Maybe<OrderBy>,
  restricted_notes_partner?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_representative_partner?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_partner?: Maybe<OrderBy>,
  restricted_risk_partner?: Maybe<OrderBy>,
  restricted_service_fee_transaction_partner?: Maybe<OrderBy>,
  restricted_tag_partner?: Maybe<OrderBy>,
  restricted_transaction_list_partner?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  restricted_transfer_transaction_partner?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_credit_transaction_partner?: Maybe<OrderBy>,
  user_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_credit_transaction_partner?: Maybe<OrderBy>,
  user_limited_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_transaction_partner?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_manage_account_partner?: Maybe<OrderBy>,
  user_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_transfer_transaction_partner?: Maybe<OrderBy>,
  user_withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface MasterAdminPartnerPermissionMutationResponse {
   __typename?: 'master_admin_partner_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterAdminPartnerPermission>,
}

export interface MasterAdminPartnerPermissionObjRelInsertInput {
  data: MasterAdminPartnerPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminPartnerPermissionOnConflict>,
}

export interface MasterAdminPartnerPermissionOnConflict {
  constraint: MasterAdminPartnerPermissionConstraint,
  update_columns: Array<MasterAdminPartnerPermissionUpdateColumn>,
  where?: Maybe<MasterAdminPartnerPermissionBoolExp>,
}

export interface MasterAdminPartnerPermissionOrderBy {
  admin_account_list_partner?: Maybe<OrderBy>,
  admin_account_settings_partner?: Maybe<OrderBy>,
  admin_activity_list_partner?: Maybe<OrderBy>,
  admin_approve_account_partner?: Maybe<OrderBy>,
  admin_approve_partner?: Maybe<OrderBy>,
  admin_approve_status_partner?: Maybe<OrderBy>,
  admin_category_partner?: Maybe<OrderBy>,
  admin_create_partner?: Maybe<OrderBy>,
  admin_credit_transaction_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_deposit_transaction_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_flag_partner?: Maybe<OrderBy>,
  admin_manage_account_partner?: Maybe<OrderBy>,
  admin_notes_partner?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_representative_partner?: Maybe<OrderBy>,
  admin_restrict_negative_balance_partner?: Maybe<OrderBy>,
  admin_risk_partner?: Maybe<OrderBy>,
  admin_service_fee_transaction_partner?: Maybe<OrderBy>,
  admin_tag_partner?: Maybe<OrderBy>,
  admin_transaction_list_partner?: Maybe<OrderBy>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  admin_transfer_transaction_partner?: Maybe<OrderBy>,
  admin_withdrawal_transaction_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_account_settings_partner?: Maybe<OrderBy>,
  manager_approve_status_partner?: Maybe<OrderBy>,
  manager_category_partner?: Maybe<OrderBy>,
  manager_credit_transaction_partner?: Maybe<OrderBy>,
  manager_deposit_transaction_partner?: Maybe<OrderBy>,
  manager_flag_partner?: Maybe<OrderBy>,
  manager_manage_account_partner?: Maybe<OrderBy>,
  manager_notes_partner?: Maybe<OrderBy>,
  manager_partner_list?: Maybe<OrderBy>,
  manager_representative_partner?: Maybe<OrderBy>,
  manager_risk_partner?: Maybe<OrderBy>,
  manager_service_fee_transaction_partner?: Maybe<OrderBy>,
  manager_tag_partner?: Maybe<OrderBy>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  manager_transfer_transaction_partner?: Maybe<OrderBy>,
  manager_withdrawal_transaction_partner?: Maybe<OrderBy>,
  readonly_credit_transaction_partner?: Maybe<OrderBy>,
  readonly_deposit_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  readonly_transfer_transaction_partner?: Maybe<OrderBy>,
  readonly_withdrawal_transaction_partner?: Maybe<OrderBy>,
  restricted_account_list_partner?: Maybe<OrderBy>,
  restricted_account_settings_partner?: Maybe<OrderBy>,
  restricted_activity_list_partner?: Maybe<OrderBy>,
  restricted_approve_account_partner?: Maybe<OrderBy>,
  restricted_approve_partner?: Maybe<OrderBy>,
  restricted_approve_status_partner?: Maybe<OrderBy>,
  restricted_category_partner?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_credit_transaction_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_deposit_transaction_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_flag_partner?: Maybe<OrderBy>,
  restricted_manage_account_partner?: Maybe<OrderBy>,
  restricted_notes_partner?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_representative_partner?: Maybe<OrderBy>,
  restricted_restrict_negative_balance_partner?: Maybe<OrderBy>,
  restricted_risk_partner?: Maybe<OrderBy>,
  restricted_service_fee_transaction_partner?: Maybe<OrderBy>,
  restricted_tag_partner?: Maybe<OrderBy>,
  restricted_transaction_list_partner?: Maybe<OrderBy>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  restricted_transfer_transaction_partner?: Maybe<OrderBy>,
  restricted_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_credit_transaction_partner?: Maybe<OrderBy>,
  user_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_credit_transaction_partner?: Maybe<OrderBy>,
  user_limited_deposit_transaction_partner?: Maybe<OrderBy>,
  user_limited_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_limited_transfer_transaction_partner?: Maybe<OrderBy>,
  user_limited_withdrawal_transaction_partner?: Maybe<OrderBy>,
  user_manage_account_partner?: Maybe<OrderBy>,
  user_service_fee_transaction_partner?: Maybe<OrderBy>,
  user_transfer_between_profiles_transaction_partner?: Maybe<OrderBy>,
  user_transfer_transaction_partner?: Maybe<OrderBy>,
  user_withdrawal_transaction_partner?: Maybe<OrderBy>,
}

export interface MasterAdminPartnerPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterAdminPartnerPermissionSelectColumn {
  AdminAccountListPartner = 'admin_account_list_partner',
  AdminAccountSettingsPartner = 'admin_account_settings_partner',
  AdminActivityListPartner = 'admin_activity_list_partner',
  AdminApproveAccountPartner = 'admin_approve_account_partner',
  AdminApprovePartner = 'admin_approve_partner',
  AdminApproveStatusPartner = 'admin_approve_status_partner',
  AdminCategoryPartner = 'admin_category_partner',
  AdminCreatePartner = 'admin_create_partner',
  AdminCreditTransactionPartner = 'admin_credit_transaction_partner',
  AdminDeletePartner = 'admin_delete_partner',
  AdminDepositTransactionPartner = 'admin_deposit_transaction_partner',
  AdminEditPartner = 'admin_edit_partner',
  AdminFlagPartner = 'admin_flag_partner',
  AdminManageAccountPartner = 'admin_manage_account_partner',
  AdminNotesPartner = 'admin_notes_partner',
  AdminPartnerList = 'admin_partner_list',
  AdminRepresentativePartner = 'admin_representative_partner',
  AdminRestrictNegativeBalancePartner = 'admin_restrict_negative_balance_partner',
  AdminRiskPartner = 'admin_risk_partner',
  AdminServiceFeeTransactionPartner = 'admin_service_fee_transaction_partner',
  AdminTagPartner = 'admin_tag_partner',
  AdminTransactionListPartner = 'admin_transaction_list_partner',
  AdminTransferBetweenProfilesTransactionPartner = 'admin_transfer_between_profiles_transaction_partner',
  AdminTransferTransactionPartner = 'admin_transfer_transaction_partner',
  AdminWithdrawalTransactionPartner = 'admin_withdrawal_transaction_partner',
  Id = 'id',
  ManagerAccountSettingsPartner = 'manager_account_settings_partner',
  ManagerApproveStatusPartner = 'manager_approve_status_partner',
  ManagerCategoryPartner = 'manager_category_partner',
  ManagerCreditTransactionPartner = 'manager_credit_transaction_partner',
  ManagerDepositTransactionPartner = 'manager_deposit_transaction_partner',
  ManagerFlagPartner = 'manager_flag_partner',
  ManagerManageAccountPartner = 'manager_manage_account_partner',
  ManagerNotesPartner = 'manager_notes_partner',
  ManagerPartnerList = 'manager_partner_list',
  ManagerRepresentativePartner = 'manager_representative_partner',
  ManagerRiskPartner = 'manager_risk_partner',
  ManagerServiceFeeTransactionPartner = 'manager_service_fee_transaction_partner',
  ManagerTagPartner = 'manager_tag_partner',
  ManagerTransferBetweenProfilesTransactionPartner = 'manager_transfer_between_profiles_transaction_partner',
  ManagerTransferTransactionPartner = 'manager_transfer_transaction_partner',
  ManagerWithdrawalTransactionPartner = 'manager_withdrawal_transaction_partner',
  ReadonlyCreditTransactionPartner = 'readonly_credit_transaction_partner',
  ReadonlyDepositTransactionPartner = 'readonly_deposit_transaction_partner',
  ReadonlyTransferBetweenProfilesTransactionPartner = 'readonly_transfer_between_profiles_transaction_partner',
  ReadonlyTransferTransactionPartner = 'readonly_transfer_transaction_partner',
  ReadonlyWithdrawalTransactionPartner = 'readonly_withdrawal_transaction_partner',
  RestrictedAccountListPartner = 'restricted_account_list_partner',
  RestrictedAccountSettingsPartner = 'restricted_account_settings_partner',
  RestrictedActivityListPartner = 'restricted_activity_list_partner',
  RestrictedApproveAccountPartner = 'restricted_approve_account_partner',
  RestrictedApprovePartner = 'restricted_approve_partner',
  RestrictedApproveStatusPartner = 'restricted_approve_status_partner',
  RestrictedCategoryPartner = 'restricted_category_partner',
  RestrictedCreatePartner = 'restricted_create_partner',
  RestrictedCreditTransactionPartner = 'restricted_credit_transaction_partner',
  RestrictedDeletePartner = 'restricted_delete_partner',
  RestrictedDepositTransactionPartner = 'restricted_deposit_transaction_partner',
  RestrictedEditPartner = 'restricted_edit_partner',
  RestrictedFlagPartner = 'restricted_flag_partner',
  RestrictedManageAccountPartner = 'restricted_manage_account_partner',
  RestrictedNotesPartner = 'restricted_notes_partner',
  RestrictedPartnerList = 'restricted_partner_list',
  RestrictedRepresentativePartner = 'restricted_representative_partner',
  RestrictedRestrictNegativeBalancePartner = 'restricted_restrict_negative_balance_partner',
  RestrictedRiskPartner = 'restricted_risk_partner',
  RestrictedServiceFeeTransactionPartner = 'restricted_service_fee_transaction_partner',
  RestrictedTagPartner = 'restricted_tag_partner',
  RestrictedTransactionListPartner = 'restricted_transaction_list_partner',
  RestrictedTransferBetweenProfilesTransactionPartner = 'restricted_transfer_between_profiles_transaction_partner',
  RestrictedTransferTransactionPartner = 'restricted_transfer_transaction_partner',
  RestrictedWithdrawalTransactionPartner = 'restricted_withdrawal_transaction_partner',
  UserCreditTransactionPartner = 'user_credit_transaction_partner',
  UserDepositTransactionPartner = 'user_deposit_transaction_partner',
  UserLimitedCreditTransactionPartner = 'user_limited_credit_transaction_partner',
  UserLimitedDepositTransactionPartner = 'user_limited_deposit_transaction_partner',
  UserLimitedServiceFeeTransactionPartner = 'user_limited_service_fee_transaction_partner',
  UserLimitedTransferBetweenProfilesTransactionPartner = 'user_limited_transfer_between_profiles_transaction_partner',
  UserLimitedTransferTransactionPartner = 'user_limited_transfer_transaction_partner',
  UserLimitedWithdrawalTransactionPartner = 'user_limited_withdrawal_transaction_partner',
  UserManageAccountPartner = 'user_manage_account_partner',
  UserServiceFeeTransactionPartner = 'user_service_fee_transaction_partner',
  UserTransferBetweenProfilesTransactionPartner = 'user_transfer_between_profiles_transaction_partner',
  UserTransferTransactionPartner = 'user_transfer_transaction_partner',
  UserWithdrawalTransactionPartner = 'user_withdrawal_transaction_partner'
}

export interface MasterAdminPartnerPermissionSetInput {
  admin_account_list_partner?: Maybe<Scalars['String']>,
  admin_account_settings_partner?: Maybe<Scalars['String']>,
  admin_activity_list_partner?: Maybe<Scalars['String']>,
  admin_approve_account_partner?: Maybe<Scalars['String']>,
  admin_approve_partner?: Maybe<Scalars['String']>,
  admin_approve_status_partner?: Maybe<Scalars['String']>,
  admin_category_partner?: Maybe<Scalars['String']>,
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_credit_transaction_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_deposit_transaction_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_flag_partner?: Maybe<Scalars['String']>,
  admin_manage_account_partner?: Maybe<Scalars['String']>,
  admin_notes_partner?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_representative_partner?: Maybe<Scalars['String']>,
  admin_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  admin_risk_partner?: Maybe<Scalars['String']>,
  admin_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  admin_tag_partner?: Maybe<Scalars['String']>,
  admin_transaction_list_partner?: Maybe<Scalars['String']>,
  admin_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  admin_transfer_transaction_partner?: Maybe<Scalars['String']>,
  admin_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_account_settings_partner?: Maybe<Scalars['String']>,
  manager_approve_status_partner?: Maybe<Scalars['String']>,
  manager_category_partner?: Maybe<Scalars['String']>,
  manager_credit_transaction_partner?: Maybe<Scalars['String']>,
  manager_deposit_transaction_partner?: Maybe<Scalars['String']>,
  manager_flag_partner?: Maybe<Scalars['String']>,
  manager_manage_account_partner?: Maybe<Scalars['String']>,
  manager_notes_partner?: Maybe<Scalars['String']>,
  manager_partner_list?: Maybe<Scalars['String']>,
  manager_representative_partner?: Maybe<Scalars['String']>,
  manager_risk_partner?: Maybe<Scalars['String']>,
  manager_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  manager_tag_partner?: Maybe<Scalars['String']>,
  manager_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  manager_transfer_transaction_partner?: Maybe<Scalars['String']>,
  manager_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  readonly_credit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_deposit_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  readonly_transfer_transaction_partner?: Maybe<Scalars['String']>,
  readonly_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  restricted_account_list_partner?: Maybe<Scalars['String']>,
  restricted_account_settings_partner?: Maybe<Scalars['String']>,
  restricted_activity_list_partner?: Maybe<Scalars['String']>,
  restricted_approve_account_partner?: Maybe<Scalars['String']>,
  restricted_approve_partner?: Maybe<Scalars['String']>,
  restricted_approve_status_partner?: Maybe<Scalars['String']>,
  restricted_category_partner?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_credit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_deposit_transaction_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_flag_partner?: Maybe<Scalars['String']>,
  restricted_manage_account_partner?: Maybe<Scalars['String']>,
  restricted_notes_partner?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_representative_partner?: Maybe<Scalars['String']>,
  restricted_restrict_negative_balance_partner?: Maybe<Scalars['String']>,
  restricted_risk_partner?: Maybe<Scalars['String']>,
  restricted_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  restricted_tag_partner?: Maybe<Scalars['String']>,
  restricted_transaction_list_partner?: Maybe<Scalars['String']>,
  restricted_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  restricted_transfer_transaction_partner?: Maybe<Scalars['String']>,
  restricted_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_credit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_deposit_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_limited_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
  user_manage_account_partner?: Maybe<Scalars['String']>,
  user_service_fee_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_between_profiles_transaction_partner?: Maybe<Scalars['String']>,
  user_transfer_transaction_partner?: Maybe<Scalars['String']>,
  user_withdrawal_transaction_partner?: Maybe<Scalars['String']>,
}

export enum MasterAdminPartnerPermissionUpdateColumn {
  AdminAccountListPartner = 'admin_account_list_partner',
  AdminAccountSettingsPartner = 'admin_account_settings_partner',
  AdminActivityListPartner = 'admin_activity_list_partner',
  AdminApproveAccountPartner = 'admin_approve_account_partner',
  AdminApprovePartner = 'admin_approve_partner',
  AdminApproveStatusPartner = 'admin_approve_status_partner',
  AdminCategoryPartner = 'admin_category_partner',
  AdminCreatePartner = 'admin_create_partner',
  AdminCreditTransactionPartner = 'admin_credit_transaction_partner',
  AdminDeletePartner = 'admin_delete_partner',
  AdminDepositTransactionPartner = 'admin_deposit_transaction_partner',
  AdminEditPartner = 'admin_edit_partner',
  AdminFlagPartner = 'admin_flag_partner',
  AdminManageAccountPartner = 'admin_manage_account_partner',
  AdminNotesPartner = 'admin_notes_partner',
  AdminPartnerList = 'admin_partner_list',
  AdminRepresentativePartner = 'admin_representative_partner',
  AdminRestrictNegativeBalancePartner = 'admin_restrict_negative_balance_partner',
  AdminRiskPartner = 'admin_risk_partner',
  AdminServiceFeeTransactionPartner = 'admin_service_fee_transaction_partner',
  AdminTagPartner = 'admin_tag_partner',
  AdminTransactionListPartner = 'admin_transaction_list_partner',
  AdminTransferBetweenProfilesTransactionPartner = 'admin_transfer_between_profiles_transaction_partner',
  AdminTransferTransactionPartner = 'admin_transfer_transaction_partner',
  AdminWithdrawalTransactionPartner = 'admin_withdrawal_transaction_partner',
  Id = 'id',
  ManagerAccountSettingsPartner = 'manager_account_settings_partner',
  ManagerApproveStatusPartner = 'manager_approve_status_partner',
  ManagerCategoryPartner = 'manager_category_partner',
  ManagerCreditTransactionPartner = 'manager_credit_transaction_partner',
  ManagerDepositTransactionPartner = 'manager_deposit_transaction_partner',
  ManagerFlagPartner = 'manager_flag_partner',
  ManagerManageAccountPartner = 'manager_manage_account_partner',
  ManagerNotesPartner = 'manager_notes_partner',
  ManagerPartnerList = 'manager_partner_list',
  ManagerRepresentativePartner = 'manager_representative_partner',
  ManagerRiskPartner = 'manager_risk_partner',
  ManagerServiceFeeTransactionPartner = 'manager_service_fee_transaction_partner',
  ManagerTagPartner = 'manager_tag_partner',
  ManagerTransferBetweenProfilesTransactionPartner = 'manager_transfer_between_profiles_transaction_partner',
  ManagerTransferTransactionPartner = 'manager_transfer_transaction_partner',
  ManagerWithdrawalTransactionPartner = 'manager_withdrawal_transaction_partner',
  ReadonlyCreditTransactionPartner = 'readonly_credit_transaction_partner',
  ReadonlyDepositTransactionPartner = 'readonly_deposit_transaction_partner',
  ReadonlyTransferBetweenProfilesTransactionPartner = 'readonly_transfer_between_profiles_transaction_partner',
  ReadonlyTransferTransactionPartner = 'readonly_transfer_transaction_partner',
  ReadonlyWithdrawalTransactionPartner = 'readonly_withdrawal_transaction_partner',
  RestrictedAccountListPartner = 'restricted_account_list_partner',
  RestrictedAccountSettingsPartner = 'restricted_account_settings_partner',
  RestrictedActivityListPartner = 'restricted_activity_list_partner',
  RestrictedApproveAccountPartner = 'restricted_approve_account_partner',
  RestrictedApprovePartner = 'restricted_approve_partner',
  RestrictedApproveStatusPartner = 'restricted_approve_status_partner',
  RestrictedCategoryPartner = 'restricted_category_partner',
  RestrictedCreatePartner = 'restricted_create_partner',
  RestrictedCreditTransactionPartner = 'restricted_credit_transaction_partner',
  RestrictedDeletePartner = 'restricted_delete_partner',
  RestrictedDepositTransactionPartner = 'restricted_deposit_transaction_partner',
  RestrictedEditPartner = 'restricted_edit_partner',
  RestrictedFlagPartner = 'restricted_flag_partner',
  RestrictedManageAccountPartner = 'restricted_manage_account_partner',
  RestrictedNotesPartner = 'restricted_notes_partner',
  RestrictedPartnerList = 'restricted_partner_list',
  RestrictedRepresentativePartner = 'restricted_representative_partner',
  RestrictedRestrictNegativeBalancePartner = 'restricted_restrict_negative_balance_partner',
  RestrictedRiskPartner = 'restricted_risk_partner',
  RestrictedServiceFeeTransactionPartner = 'restricted_service_fee_transaction_partner',
  RestrictedTagPartner = 'restricted_tag_partner',
  RestrictedTransactionListPartner = 'restricted_transaction_list_partner',
  RestrictedTransferBetweenProfilesTransactionPartner = 'restricted_transfer_between_profiles_transaction_partner',
  RestrictedTransferTransactionPartner = 'restricted_transfer_transaction_partner',
  RestrictedWithdrawalTransactionPartner = 'restricted_withdrawal_transaction_partner',
  UserCreditTransactionPartner = 'user_credit_transaction_partner',
  UserDepositTransactionPartner = 'user_deposit_transaction_partner',
  UserLimitedCreditTransactionPartner = 'user_limited_credit_transaction_partner',
  UserLimitedDepositTransactionPartner = 'user_limited_deposit_transaction_partner',
  UserLimitedServiceFeeTransactionPartner = 'user_limited_service_fee_transaction_partner',
  UserLimitedTransferBetweenProfilesTransactionPartner = 'user_limited_transfer_between_profiles_transaction_partner',
  UserLimitedTransferTransactionPartner = 'user_limited_transfer_transaction_partner',
  UserLimitedWithdrawalTransactionPartner = 'user_limited_withdrawal_transaction_partner',
  UserManageAccountPartner = 'user_manage_account_partner',
  UserServiceFeeTransactionPartner = 'user_service_fee_transaction_partner',
  UserTransferBetweenProfilesTransactionPartner = 'user_transfer_between_profiles_transaction_partner',
  UserTransferTransactionPartner = 'user_transfer_transaction_partner',
  UserWithdrawalTransactionPartner = 'user_withdrawal_transaction_partner'
}

export interface MasterAdminStakeholderPermission {
   __typename?: 'master_admin_stakeholder_permission',
  admin_activity_list?: Maybe<Scalars['String']>,
  admin_approve_stakeholder?: Maybe<Scalars['String']>,
  admin_bank_account_list?: Maybe<Scalars['String']>,
  admin_file_list?: Maybe<Scalars['String']>,
  admin_manage_bank_account?: Maybe<Scalars['String']>,
  admin_manage_file?: Maybe<Scalars['String']>,
  admin_manage_stakeholder?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manager_manage_bank_account?: Maybe<Scalars['String']>,
  manager_manage_file?: Maybe<Scalars['String']>,
  manager_manage_stakeholder?: Maybe<Scalars['String']>,
  manager_stakeholder_list?: Maybe<Scalars['String']>,
  restricted_activity_list?: Maybe<Scalars['String']>,
  restricted_approve_stakeholder?: Maybe<Scalars['String']>,
  restricted_bank_account_list?: Maybe<Scalars['String']>,
  restricted_file_list?: Maybe<Scalars['String']>,
  restricted_manage_bank_account?: Maybe<Scalars['String']>,
  restricted_manage_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder?: Maybe<Scalars['String']>,
  restricted_stakeholder_list: Scalars['String'],
  user_limited_manage_bank_account?: Maybe<Scalars['String']>,
  user_limited_manage_file?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder?: Maybe<Scalars['String']>,
  user_manage_bank_account?: Maybe<Scalars['String']>,
  user_manage_file?: Maybe<Scalars['String']>,
  user_manage_stakeholder?: Maybe<Scalars['String']>,
}

export interface MasterAdminStakeholderPermissionAggregate {
   __typename?: 'master_admin_stakeholder_permission_aggregate',
  aggregate?: Maybe<MasterAdminStakeholderPermissionAggregateFields>,
  nodes: Array<MasterAdminStakeholderPermission>,
}

export interface MasterAdminStakeholderPermissionAggregateFields {
   __typename?: 'master_admin_stakeholder_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterAdminStakeholderPermissionMaxFields>,
  min?: Maybe<MasterAdminStakeholderPermissionMinFields>,
}


export interface MasterAdminStakeholderPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterAdminStakeholderPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterAdminStakeholderPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterAdminStakeholderPermissionMaxOrderBy>,
  min?: Maybe<MasterAdminStakeholderPermissionMinOrderBy>,
}

export interface MasterAdminStakeholderPermissionArrRelInsertInput {
  data: Array<MasterAdminStakeholderPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminStakeholderPermissionOnConflict>,
}

export interface MasterAdminStakeholderPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterAdminStakeholderPermissionBoolExp>>>,
  _not?: Maybe<MasterAdminStakeholderPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterAdminStakeholderPermissionBoolExp>>>,
  admin_activity_list?: Maybe<StringComparisonExp>,
  admin_approve_stakeholder?: Maybe<StringComparisonExp>,
  admin_bank_account_list?: Maybe<StringComparisonExp>,
  admin_file_list?: Maybe<StringComparisonExp>,
  admin_manage_bank_account?: Maybe<StringComparisonExp>,
  admin_manage_file?: Maybe<StringComparisonExp>,
  admin_manage_stakeholder?: Maybe<StringComparisonExp>,
  admin_stakeholder_list?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_manage_bank_account?: Maybe<StringComparisonExp>,
  manager_manage_file?: Maybe<StringComparisonExp>,
  manager_manage_stakeholder?: Maybe<StringComparisonExp>,
  manager_stakeholder_list?: Maybe<StringComparisonExp>,
  restricted_activity_list?: Maybe<StringComparisonExp>,
  restricted_approve_stakeholder?: Maybe<StringComparisonExp>,
  restricted_bank_account_list?: Maybe<StringComparisonExp>,
  restricted_file_list?: Maybe<StringComparisonExp>,
  restricted_manage_bank_account?: Maybe<StringComparisonExp>,
  restricted_manage_file?: Maybe<StringComparisonExp>,
  restricted_manage_stakeholder?: Maybe<StringComparisonExp>,
  restricted_stakeholder_list?: Maybe<StringComparisonExp>,
  user_limited_manage_bank_account?: Maybe<StringComparisonExp>,
  user_limited_manage_file?: Maybe<StringComparisonExp>,
  user_limited_manage_stakeholder?: Maybe<StringComparisonExp>,
  user_manage_bank_account?: Maybe<StringComparisonExp>,
  user_manage_file?: Maybe<StringComparisonExp>,
  user_manage_stakeholder?: Maybe<StringComparisonExp>,
}

export enum MasterAdminStakeholderPermissionConstraint {
  MasterAdminStakeholderPermissionPkey = 'master_admin_stakeholder_permission_pkey'
}

export interface MasterAdminStakeholderPermissionInsertInput {
  admin_activity_list?: Maybe<Scalars['String']>,
  admin_approve_stakeholder?: Maybe<Scalars['String']>,
  admin_bank_account_list?: Maybe<Scalars['String']>,
  admin_file_list?: Maybe<Scalars['String']>,
  admin_manage_bank_account?: Maybe<Scalars['String']>,
  admin_manage_file?: Maybe<Scalars['String']>,
  admin_manage_stakeholder?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_bank_account?: Maybe<Scalars['String']>,
  manager_manage_file?: Maybe<Scalars['String']>,
  manager_manage_stakeholder?: Maybe<Scalars['String']>,
  manager_stakeholder_list?: Maybe<Scalars['String']>,
  restricted_activity_list?: Maybe<Scalars['String']>,
  restricted_approve_stakeholder?: Maybe<Scalars['String']>,
  restricted_bank_account_list?: Maybe<Scalars['String']>,
  restricted_file_list?: Maybe<Scalars['String']>,
  restricted_manage_bank_account?: Maybe<Scalars['String']>,
  restricted_manage_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_limited_manage_bank_account?: Maybe<Scalars['String']>,
  user_limited_manage_file?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder?: Maybe<Scalars['String']>,
  user_manage_bank_account?: Maybe<Scalars['String']>,
  user_manage_file?: Maybe<Scalars['String']>,
  user_manage_stakeholder?: Maybe<Scalars['String']>,
}

export interface MasterAdminStakeholderPermissionMaxFields {
   __typename?: 'master_admin_stakeholder_permission_max_fields',
  admin_activity_list?: Maybe<Scalars['String']>,
  admin_approve_stakeholder?: Maybe<Scalars['String']>,
  admin_bank_account_list?: Maybe<Scalars['String']>,
  admin_file_list?: Maybe<Scalars['String']>,
  admin_manage_bank_account?: Maybe<Scalars['String']>,
  admin_manage_file?: Maybe<Scalars['String']>,
  admin_manage_stakeholder?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_bank_account?: Maybe<Scalars['String']>,
  manager_manage_file?: Maybe<Scalars['String']>,
  manager_manage_stakeholder?: Maybe<Scalars['String']>,
  manager_stakeholder_list?: Maybe<Scalars['String']>,
  restricted_activity_list?: Maybe<Scalars['String']>,
  restricted_approve_stakeholder?: Maybe<Scalars['String']>,
  restricted_bank_account_list?: Maybe<Scalars['String']>,
  restricted_file_list?: Maybe<Scalars['String']>,
  restricted_manage_bank_account?: Maybe<Scalars['String']>,
  restricted_manage_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_limited_manage_bank_account?: Maybe<Scalars['String']>,
  user_limited_manage_file?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder?: Maybe<Scalars['String']>,
  user_manage_bank_account?: Maybe<Scalars['String']>,
  user_manage_file?: Maybe<Scalars['String']>,
  user_manage_stakeholder?: Maybe<Scalars['String']>,
}

export interface MasterAdminStakeholderPermissionMaxOrderBy {
  admin_activity_list?: Maybe<OrderBy>,
  admin_approve_stakeholder?: Maybe<OrderBy>,
  admin_bank_account_list?: Maybe<OrderBy>,
  admin_file_list?: Maybe<OrderBy>,
  admin_manage_bank_account?: Maybe<OrderBy>,
  admin_manage_file?: Maybe<OrderBy>,
  admin_manage_stakeholder?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_bank_account?: Maybe<OrderBy>,
  manager_manage_file?: Maybe<OrderBy>,
  manager_manage_stakeholder?: Maybe<OrderBy>,
  manager_stakeholder_list?: Maybe<OrderBy>,
  restricted_activity_list?: Maybe<OrderBy>,
  restricted_approve_stakeholder?: Maybe<OrderBy>,
  restricted_bank_account_list?: Maybe<OrderBy>,
  restricted_file_list?: Maybe<OrderBy>,
  restricted_manage_bank_account?: Maybe<OrderBy>,
  restricted_manage_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_limited_manage_bank_account?: Maybe<OrderBy>,
  user_limited_manage_file?: Maybe<OrderBy>,
  user_limited_manage_stakeholder?: Maybe<OrderBy>,
  user_manage_bank_account?: Maybe<OrderBy>,
  user_manage_file?: Maybe<OrderBy>,
  user_manage_stakeholder?: Maybe<OrderBy>,
}

export interface MasterAdminStakeholderPermissionMinFields {
   __typename?: 'master_admin_stakeholder_permission_min_fields',
  admin_activity_list?: Maybe<Scalars['String']>,
  admin_approve_stakeholder?: Maybe<Scalars['String']>,
  admin_bank_account_list?: Maybe<Scalars['String']>,
  admin_file_list?: Maybe<Scalars['String']>,
  admin_manage_bank_account?: Maybe<Scalars['String']>,
  admin_manage_file?: Maybe<Scalars['String']>,
  admin_manage_stakeholder?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_bank_account?: Maybe<Scalars['String']>,
  manager_manage_file?: Maybe<Scalars['String']>,
  manager_manage_stakeholder?: Maybe<Scalars['String']>,
  manager_stakeholder_list?: Maybe<Scalars['String']>,
  restricted_activity_list?: Maybe<Scalars['String']>,
  restricted_approve_stakeholder?: Maybe<Scalars['String']>,
  restricted_bank_account_list?: Maybe<Scalars['String']>,
  restricted_file_list?: Maybe<Scalars['String']>,
  restricted_manage_bank_account?: Maybe<Scalars['String']>,
  restricted_manage_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_limited_manage_bank_account?: Maybe<Scalars['String']>,
  user_limited_manage_file?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder?: Maybe<Scalars['String']>,
  user_manage_bank_account?: Maybe<Scalars['String']>,
  user_manage_file?: Maybe<Scalars['String']>,
  user_manage_stakeholder?: Maybe<Scalars['String']>,
}

export interface MasterAdminStakeholderPermissionMinOrderBy {
  admin_activity_list?: Maybe<OrderBy>,
  admin_approve_stakeholder?: Maybe<OrderBy>,
  admin_bank_account_list?: Maybe<OrderBy>,
  admin_file_list?: Maybe<OrderBy>,
  admin_manage_bank_account?: Maybe<OrderBy>,
  admin_manage_file?: Maybe<OrderBy>,
  admin_manage_stakeholder?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_bank_account?: Maybe<OrderBy>,
  manager_manage_file?: Maybe<OrderBy>,
  manager_manage_stakeholder?: Maybe<OrderBy>,
  manager_stakeholder_list?: Maybe<OrderBy>,
  restricted_activity_list?: Maybe<OrderBy>,
  restricted_approve_stakeholder?: Maybe<OrderBy>,
  restricted_bank_account_list?: Maybe<OrderBy>,
  restricted_file_list?: Maybe<OrderBy>,
  restricted_manage_bank_account?: Maybe<OrderBy>,
  restricted_manage_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_limited_manage_bank_account?: Maybe<OrderBy>,
  user_limited_manage_file?: Maybe<OrderBy>,
  user_limited_manage_stakeholder?: Maybe<OrderBy>,
  user_manage_bank_account?: Maybe<OrderBy>,
  user_manage_file?: Maybe<OrderBy>,
  user_manage_stakeholder?: Maybe<OrderBy>,
}

export interface MasterAdminStakeholderPermissionMutationResponse {
   __typename?: 'master_admin_stakeholder_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterAdminStakeholderPermission>,
}

export interface MasterAdminStakeholderPermissionObjRelInsertInput {
  data: MasterAdminStakeholderPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminStakeholderPermissionOnConflict>,
}

export interface MasterAdminStakeholderPermissionOnConflict {
  constraint: MasterAdminStakeholderPermissionConstraint,
  update_columns: Array<MasterAdminStakeholderPermissionUpdateColumn>,
  where?: Maybe<MasterAdminStakeholderPermissionBoolExp>,
}

export interface MasterAdminStakeholderPermissionOrderBy {
  admin_activity_list?: Maybe<OrderBy>,
  admin_approve_stakeholder?: Maybe<OrderBy>,
  admin_bank_account_list?: Maybe<OrderBy>,
  admin_file_list?: Maybe<OrderBy>,
  admin_manage_bank_account?: Maybe<OrderBy>,
  admin_manage_file?: Maybe<OrderBy>,
  admin_manage_stakeholder?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_bank_account?: Maybe<OrderBy>,
  manager_manage_file?: Maybe<OrderBy>,
  manager_manage_stakeholder?: Maybe<OrderBy>,
  manager_stakeholder_list?: Maybe<OrderBy>,
  restricted_activity_list?: Maybe<OrderBy>,
  restricted_approve_stakeholder?: Maybe<OrderBy>,
  restricted_bank_account_list?: Maybe<OrderBy>,
  restricted_file_list?: Maybe<OrderBy>,
  restricted_manage_bank_account?: Maybe<OrderBy>,
  restricted_manage_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_limited_manage_bank_account?: Maybe<OrderBy>,
  user_limited_manage_file?: Maybe<OrderBy>,
  user_limited_manage_stakeholder?: Maybe<OrderBy>,
  user_manage_bank_account?: Maybe<OrderBy>,
  user_manage_file?: Maybe<OrderBy>,
  user_manage_stakeholder?: Maybe<OrderBy>,
}

export interface MasterAdminStakeholderPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterAdminStakeholderPermissionSelectColumn {
  AdminActivityList = 'admin_activity_list',
  AdminApproveStakeholder = 'admin_approve_stakeholder',
  AdminBankAccountList = 'admin_bank_account_list',
  AdminFileList = 'admin_file_list',
  AdminManageBankAccount = 'admin_manage_bank_account',
  AdminManageFile = 'admin_manage_file',
  AdminManageStakeholder = 'admin_manage_stakeholder',
  AdminStakeholderList = 'admin_stakeholder_list',
  Id = 'id',
  ManagerManageBankAccount = 'manager_manage_bank_account',
  ManagerManageFile = 'manager_manage_file',
  ManagerManageStakeholder = 'manager_manage_stakeholder',
  ManagerStakeholderList = 'manager_stakeholder_list',
  RestrictedActivityList = 'restricted_activity_list',
  RestrictedApproveStakeholder = 'restricted_approve_stakeholder',
  RestrictedBankAccountList = 'restricted_bank_account_list',
  RestrictedFileList = 'restricted_file_list',
  RestrictedManageBankAccount = 'restricted_manage_bank_account',
  RestrictedManageFile = 'restricted_manage_file',
  RestrictedManageStakeholder = 'restricted_manage_stakeholder',
  RestrictedStakeholderList = 'restricted_stakeholder_list',
  UserLimitedManageBankAccount = 'user_limited_manage_bank_account',
  UserLimitedManageFile = 'user_limited_manage_file',
  UserLimitedManageStakeholder = 'user_limited_manage_stakeholder',
  UserManageBankAccount = 'user_manage_bank_account',
  UserManageFile = 'user_manage_file',
  UserManageStakeholder = 'user_manage_stakeholder'
}

export interface MasterAdminStakeholderPermissionSetInput {
  admin_activity_list?: Maybe<Scalars['String']>,
  admin_approve_stakeholder?: Maybe<Scalars['String']>,
  admin_bank_account_list?: Maybe<Scalars['String']>,
  admin_file_list?: Maybe<Scalars['String']>,
  admin_manage_bank_account?: Maybe<Scalars['String']>,
  admin_manage_file?: Maybe<Scalars['String']>,
  admin_manage_stakeholder?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_bank_account?: Maybe<Scalars['String']>,
  manager_manage_file?: Maybe<Scalars['String']>,
  manager_manage_stakeholder?: Maybe<Scalars['String']>,
  manager_stakeholder_list?: Maybe<Scalars['String']>,
  restricted_activity_list?: Maybe<Scalars['String']>,
  restricted_approve_stakeholder?: Maybe<Scalars['String']>,
  restricted_bank_account_list?: Maybe<Scalars['String']>,
  restricted_file_list?: Maybe<Scalars['String']>,
  restricted_manage_bank_account?: Maybe<Scalars['String']>,
  restricted_manage_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_limited_manage_bank_account?: Maybe<Scalars['String']>,
  user_limited_manage_file?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder?: Maybe<Scalars['String']>,
  user_manage_bank_account?: Maybe<Scalars['String']>,
  user_manage_file?: Maybe<Scalars['String']>,
  user_manage_stakeholder?: Maybe<Scalars['String']>,
}

export enum MasterAdminStakeholderPermissionUpdateColumn {
  AdminActivityList = 'admin_activity_list',
  AdminApproveStakeholder = 'admin_approve_stakeholder',
  AdminBankAccountList = 'admin_bank_account_list',
  AdminFileList = 'admin_file_list',
  AdminManageBankAccount = 'admin_manage_bank_account',
  AdminManageFile = 'admin_manage_file',
  AdminManageStakeholder = 'admin_manage_stakeholder',
  AdminStakeholderList = 'admin_stakeholder_list',
  Id = 'id',
  ManagerManageBankAccount = 'manager_manage_bank_account',
  ManagerManageFile = 'manager_manage_file',
  ManagerManageStakeholder = 'manager_manage_stakeholder',
  ManagerStakeholderList = 'manager_stakeholder_list',
  RestrictedActivityList = 'restricted_activity_list',
  RestrictedApproveStakeholder = 'restricted_approve_stakeholder',
  RestrictedBankAccountList = 'restricted_bank_account_list',
  RestrictedFileList = 'restricted_file_list',
  RestrictedManageBankAccount = 'restricted_manage_bank_account',
  RestrictedManageFile = 'restricted_manage_file',
  RestrictedManageStakeholder = 'restricted_manage_stakeholder',
  RestrictedStakeholderList = 'restricted_stakeholder_list',
  UserLimitedManageBankAccount = 'user_limited_manage_bank_account',
  UserLimitedManageFile = 'user_limited_manage_file',
  UserLimitedManageStakeholder = 'user_limited_manage_stakeholder',
  UserManageBankAccount = 'user_manage_bank_account',
  UserManageFile = 'user_manage_file',
  UserManageStakeholder = 'user_manage_stakeholder'
}

export interface MasterAdminSystemPermission {
   __typename?: 'master_admin_system_permission',
  admin_settings: Scalars['String'],
  admin_settings_RNP: Scalars['String'],
  admin_settings_admins: Scalars['String'],
  admin_settings_advanced: Scalars['String'],
  admin_settings_compliance: Scalars['String'],
  admin_settings_payment_methods: Scalars['String'],
  admin_settings_platform_servers: Scalars['String'],
  admin_settings_segments: Scalars['String'],
  admin_settings_stakeholders: Scalars['String'],
  id: Scalars['String'],
  restricted_settings: Scalars['String'],
  restricted_settings_RNP: Scalars['String'],
  restricted_settings_admins: Scalars['String'],
  restricted_settings_advanced: Scalars['String'],
  restricted_settings_compliance: Scalars['String'],
  restricted_settings_payment_methods: Scalars['String'],
  restricted_settings_platform_servers: Scalars['String'],
  restricted_settings_segments: Scalars['String'],
  restricted_settings_stakeholders: Scalars['String'],
}

export interface MasterAdminSystemPermissionAggregate {
   __typename?: 'master_admin_system_permission_aggregate',
  aggregate?: Maybe<MasterAdminSystemPermissionAggregateFields>,
  nodes: Array<MasterAdminSystemPermission>,
}

export interface MasterAdminSystemPermissionAggregateFields {
   __typename?: 'master_admin_system_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterAdminSystemPermissionMaxFields>,
  min?: Maybe<MasterAdminSystemPermissionMinFields>,
}


export interface MasterAdminSystemPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterAdminSystemPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterAdminSystemPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterAdminSystemPermissionMaxOrderBy>,
  min?: Maybe<MasterAdminSystemPermissionMinOrderBy>,
}

export interface MasterAdminSystemPermissionArrRelInsertInput {
  data: Array<MasterAdminSystemPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminSystemPermissionOnConflict>,
}

export interface MasterAdminSystemPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterAdminSystemPermissionBoolExp>>>,
  _not?: Maybe<MasterAdminSystemPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterAdminSystemPermissionBoolExp>>>,
  admin_settings?: Maybe<StringComparisonExp>,
  admin_settings_RNP?: Maybe<StringComparisonExp>,
  admin_settings_admins?: Maybe<StringComparisonExp>,
  admin_settings_advanced?: Maybe<StringComparisonExp>,
  admin_settings_compliance?: Maybe<StringComparisonExp>,
  admin_settings_payment_methods?: Maybe<StringComparisonExp>,
  admin_settings_platform_servers?: Maybe<StringComparisonExp>,
  admin_settings_segments?: Maybe<StringComparisonExp>,
  admin_settings_stakeholders?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  restricted_settings?: Maybe<StringComparisonExp>,
  restricted_settings_RNP?: Maybe<StringComparisonExp>,
  restricted_settings_admins?: Maybe<StringComparisonExp>,
  restricted_settings_advanced?: Maybe<StringComparisonExp>,
  restricted_settings_compliance?: Maybe<StringComparisonExp>,
  restricted_settings_payment_methods?: Maybe<StringComparisonExp>,
  restricted_settings_platform_servers?: Maybe<StringComparisonExp>,
  restricted_settings_segments?: Maybe<StringComparisonExp>,
  restricted_settings_stakeholders?: Maybe<StringComparisonExp>,
}

export enum MasterAdminSystemPermissionConstraint {
  MasterAdminSystemPermissionPkey = 'master_admin_system_permission_pkey'
}

export interface MasterAdminSystemPermissionInsertInput {
  admin_settings?: Maybe<Scalars['String']>,
  admin_settings_RNP?: Maybe<Scalars['String']>,
  admin_settings_admins?: Maybe<Scalars['String']>,
  admin_settings_advanced?: Maybe<Scalars['String']>,
  admin_settings_compliance?: Maybe<Scalars['String']>,
  admin_settings_payment_methods?: Maybe<Scalars['String']>,
  admin_settings_platform_servers?: Maybe<Scalars['String']>,
  admin_settings_segments?: Maybe<Scalars['String']>,
  admin_settings_stakeholders?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  restricted_settings?: Maybe<Scalars['String']>,
  restricted_settings_RNP?: Maybe<Scalars['String']>,
  restricted_settings_admins?: Maybe<Scalars['String']>,
  restricted_settings_advanced?: Maybe<Scalars['String']>,
  restricted_settings_compliance?: Maybe<Scalars['String']>,
  restricted_settings_payment_methods?: Maybe<Scalars['String']>,
  restricted_settings_platform_servers?: Maybe<Scalars['String']>,
  restricted_settings_segments?: Maybe<Scalars['String']>,
  restricted_settings_stakeholders?: Maybe<Scalars['String']>,
}

export interface MasterAdminSystemPermissionMaxFields {
   __typename?: 'master_admin_system_permission_max_fields',
  admin_settings?: Maybe<Scalars['String']>,
  admin_settings_RNP?: Maybe<Scalars['String']>,
  admin_settings_admins?: Maybe<Scalars['String']>,
  admin_settings_advanced?: Maybe<Scalars['String']>,
  admin_settings_compliance?: Maybe<Scalars['String']>,
  admin_settings_payment_methods?: Maybe<Scalars['String']>,
  admin_settings_platform_servers?: Maybe<Scalars['String']>,
  admin_settings_segments?: Maybe<Scalars['String']>,
  admin_settings_stakeholders?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  restricted_settings?: Maybe<Scalars['String']>,
  restricted_settings_RNP?: Maybe<Scalars['String']>,
  restricted_settings_admins?: Maybe<Scalars['String']>,
  restricted_settings_advanced?: Maybe<Scalars['String']>,
  restricted_settings_compliance?: Maybe<Scalars['String']>,
  restricted_settings_payment_methods?: Maybe<Scalars['String']>,
  restricted_settings_platform_servers?: Maybe<Scalars['String']>,
  restricted_settings_segments?: Maybe<Scalars['String']>,
  restricted_settings_stakeholders?: Maybe<Scalars['String']>,
}

export interface MasterAdminSystemPermissionMaxOrderBy {
  admin_settings?: Maybe<OrderBy>,
  admin_settings_RNP?: Maybe<OrderBy>,
  admin_settings_admins?: Maybe<OrderBy>,
  admin_settings_advanced?: Maybe<OrderBy>,
  admin_settings_compliance?: Maybe<OrderBy>,
  admin_settings_payment_methods?: Maybe<OrderBy>,
  admin_settings_platform_servers?: Maybe<OrderBy>,
  admin_settings_segments?: Maybe<OrderBy>,
  admin_settings_stakeholders?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  restricted_settings?: Maybe<OrderBy>,
  restricted_settings_RNP?: Maybe<OrderBy>,
  restricted_settings_admins?: Maybe<OrderBy>,
  restricted_settings_advanced?: Maybe<OrderBy>,
  restricted_settings_compliance?: Maybe<OrderBy>,
  restricted_settings_payment_methods?: Maybe<OrderBy>,
  restricted_settings_platform_servers?: Maybe<OrderBy>,
  restricted_settings_segments?: Maybe<OrderBy>,
  restricted_settings_stakeholders?: Maybe<OrderBy>,
}

export interface MasterAdminSystemPermissionMinFields {
   __typename?: 'master_admin_system_permission_min_fields',
  admin_settings?: Maybe<Scalars['String']>,
  admin_settings_RNP?: Maybe<Scalars['String']>,
  admin_settings_admins?: Maybe<Scalars['String']>,
  admin_settings_advanced?: Maybe<Scalars['String']>,
  admin_settings_compliance?: Maybe<Scalars['String']>,
  admin_settings_payment_methods?: Maybe<Scalars['String']>,
  admin_settings_platform_servers?: Maybe<Scalars['String']>,
  admin_settings_segments?: Maybe<Scalars['String']>,
  admin_settings_stakeholders?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  restricted_settings?: Maybe<Scalars['String']>,
  restricted_settings_RNP?: Maybe<Scalars['String']>,
  restricted_settings_admins?: Maybe<Scalars['String']>,
  restricted_settings_advanced?: Maybe<Scalars['String']>,
  restricted_settings_compliance?: Maybe<Scalars['String']>,
  restricted_settings_payment_methods?: Maybe<Scalars['String']>,
  restricted_settings_platform_servers?: Maybe<Scalars['String']>,
  restricted_settings_segments?: Maybe<Scalars['String']>,
  restricted_settings_stakeholders?: Maybe<Scalars['String']>,
}

export interface MasterAdminSystemPermissionMinOrderBy {
  admin_settings?: Maybe<OrderBy>,
  admin_settings_RNP?: Maybe<OrderBy>,
  admin_settings_admins?: Maybe<OrderBy>,
  admin_settings_advanced?: Maybe<OrderBy>,
  admin_settings_compliance?: Maybe<OrderBy>,
  admin_settings_payment_methods?: Maybe<OrderBy>,
  admin_settings_platform_servers?: Maybe<OrderBy>,
  admin_settings_segments?: Maybe<OrderBy>,
  admin_settings_stakeholders?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  restricted_settings?: Maybe<OrderBy>,
  restricted_settings_RNP?: Maybe<OrderBy>,
  restricted_settings_admins?: Maybe<OrderBy>,
  restricted_settings_advanced?: Maybe<OrderBy>,
  restricted_settings_compliance?: Maybe<OrderBy>,
  restricted_settings_payment_methods?: Maybe<OrderBy>,
  restricted_settings_platform_servers?: Maybe<OrderBy>,
  restricted_settings_segments?: Maybe<OrderBy>,
  restricted_settings_stakeholders?: Maybe<OrderBy>,
}

export interface MasterAdminSystemPermissionMutationResponse {
   __typename?: 'master_admin_system_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterAdminSystemPermission>,
}

export interface MasterAdminSystemPermissionObjRelInsertInput {
  data: MasterAdminSystemPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminSystemPermissionOnConflict>,
}

export interface MasterAdminSystemPermissionOnConflict {
  constraint: MasterAdminSystemPermissionConstraint,
  update_columns: Array<MasterAdminSystemPermissionUpdateColumn>,
  where?: Maybe<MasterAdminSystemPermissionBoolExp>,
}

export interface MasterAdminSystemPermissionOrderBy {
  admin_settings?: Maybe<OrderBy>,
  admin_settings_RNP?: Maybe<OrderBy>,
  admin_settings_admins?: Maybe<OrderBy>,
  admin_settings_advanced?: Maybe<OrderBy>,
  admin_settings_compliance?: Maybe<OrderBy>,
  admin_settings_payment_methods?: Maybe<OrderBy>,
  admin_settings_platform_servers?: Maybe<OrderBy>,
  admin_settings_segments?: Maybe<OrderBy>,
  admin_settings_stakeholders?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  restricted_settings?: Maybe<OrderBy>,
  restricted_settings_RNP?: Maybe<OrderBy>,
  restricted_settings_admins?: Maybe<OrderBy>,
  restricted_settings_advanced?: Maybe<OrderBy>,
  restricted_settings_compliance?: Maybe<OrderBy>,
  restricted_settings_payment_methods?: Maybe<OrderBy>,
  restricted_settings_platform_servers?: Maybe<OrderBy>,
  restricted_settings_segments?: Maybe<OrderBy>,
  restricted_settings_stakeholders?: Maybe<OrderBy>,
}

export interface MasterAdminSystemPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterAdminSystemPermissionSelectColumn {
  AdminSettings = 'admin_settings',
  AdminSettingsRnp = 'admin_settings_RNP',
  AdminSettingsAdmins = 'admin_settings_admins',
  AdminSettingsAdvanced = 'admin_settings_advanced',
  AdminSettingsCompliance = 'admin_settings_compliance',
  AdminSettingsPaymentMethods = 'admin_settings_payment_methods',
  AdminSettingsPlatformServers = 'admin_settings_platform_servers',
  AdminSettingsSegments = 'admin_settings_segments',
  AdminSettingsStakeholders = 'admin_settings_stakeholders',
  Id = 'id',
  RestrictedSettings = 'restricted_settings',
  RestrictedSettingsRnp = 'restricted_settings_RNP',
  RestrictedSettingsAdmins = 'restricted_settings_admins',
  RestrictedSettingsAdvanced = 'restricted_settings_advanced',
  RestrictedSettingsCompliance = 'restricted_settings_compliance',
  RestrictedSettingsPaymentMethods = 'restricted_settings_payment_methods',
  RestrictedSettingsPlatformServers = 'restricted_settings_platform_servers',
  RestrictedSettingsSegments = 'restricted_settings_segments',
  RestrictedSettingsStakeholders = 'restricted_settings_stakeholders'
}

export interface MasterAdminSystemPermissionSetInput {
  admin_settings?: Maybe<Scalars['String']>,
  admin_settings_RNP?: Maybe<Scalars['String']>,
  admin_settings_admins?: Maybe<Scalars['String']>,
  admin_settings_advanced?: Maybe<Scalars['String']>,
  admin_settings_compliance?: Maybe<Scalars['String']>,
  admin_settings_payment_methods?: Maybe<Scalars['String']>,
  admin_settings_platform_servers?: Maybe<Scalars['String']>,
  admin_settings_segments?: Maybe<Scalars['String']>,
  admin_settings_stakeholders?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  restricted_settings?: Maybe<Scalars['String']>,
  restricted_settings_RNP?: Maybe<Scalars['String']>,
  restricted_settings_admins?: Maybe<Scalars['String']>,
  restricted_settings_advanced?: Maybe<Scalars['String']>,
  restricted_settings_compliance?: Maybe<Scalars['String']>,
  restricted_settings_payment_methods?: Maybe<Scalars['String']>,
  restricted_settings_platform_servers?: Maybe<Scalars['String']>,
  restricted_settings_segments?: Maybe<Scalars['String']>,
  restricted_settings_stakeholders?: Maybe<Scalars['String']>,
}

export enum MasterAdminSystemPermissionUpdateColumn {
  AdminSettings = 'admin_settings',
  AdminSettingsRnp = 'admin_settings_RNP',
  AdminSettingsAdmins = 'admin_settings_admins',
  AdminSettingsAdvanced = 'admin_settings_advanced',
  AdminSettingsCompliance = 'admin_settings_compliance',
  AdminSettingsPaymentMethods = 'admin_settings_payment_methods',
  AdminSettingsPlatformServers = 'admin_settings_platform_servers',
  AdminSettingsSegments = 'admin_settings_segments',
  AdminSettingsStakeholders = 'admin_settings_stakeholders',
  Id = 'id',
  RestrictedSettings = 'restricted_settings',
  RestrictedSettingsRnp = 'restricted_settings_RNP',
  RestrictedSettingsAdmins = 'restricted_settings_admins',
  RestrictedSettingsAdvanced = 'restricted_settings_advanced',
  RestrictedSettingsCompliance = 'restricted_settings_compliance',
  RestrictedSettingsPaymentMethods = 'restricted_settings_payment_methods',
  RestrictedSettingsPlatformServers = 'restricted_settings_platform_servers',
  RestrictedSettingsSegments = 'restricted_settings_segments',
  RestrictedSettingsStakeholders = 'restricted_settings_stakeholders'
}

export interface MasterPartnerClientPermission {
   __typename?: 'master_partner_client_permission',
  admin_client_account_require_approval: Scalars['String'],
  admin_client_accounts: Scalars['String'],
  admin_client_activity: Scalars['String'],
  admin_client_list: Scalars['String'],
  admin_client_manage_account: Scalars['String'],
  admin_client_manage_notes: Scalars['String'],
  admin_client_transaction_between: Scalars['String'],
  admin_client_transaction_credit: Scalars['String'],
  admin_client_transaction_deposit: Scalars['String'],
  admin_client_transaction_list: Scalars['String'],
  admin_client_transaction_transfer: Scalars['String'],
  admin_client_transaction_withdrawal: Scalars['String'],
  admin_create_client: Scalars['String'],
  admin_delete_client: Scalars['String'],
  admin_edit_client: Scalars['String'],
  admin_read_client_approval: Scalars['String'],
  id: Scalars['String'],
  manager_client_manage_account: Scalars['String'],
  manager_client_transaction_between: Scalars['String'],
  manager_client_transaction_credit: Scalars['String'],
  manager_client_transaction_deposit: Scalars['String'],
  manager_client_transaction_transfer: Scalars['String'],
  manager_client_transaction_withdrawal: Scalars['String'],
  restricted_client_account_require_approval: Scalars['String'],
  restricted_client_accounts: Scalars['String'],
  restricted_client_activity: Scalars['String'],
  restricted_client_list: Scalars['String'],
  restricted_client_manage_account: Scalars['String'],
  restricted_client_manage_notes: Scalars['String'],
  restricted_client_transaction_between: Scalars['String'],
  restricted_client_transaction_credit: Scalars['String'],
  restricted_client_transaction_deposit: Scalars['String'],
  restricted_client_transaction_list: Scalars['String'],
  restricted_client_transaction_transfer: Scalars['String'],
  restricted_client_transaction_withdrawal: Scalars['String'],
  restricted_create_client: Scalars['String'],
  restricted_delete_client: Scalars['String'],
  restricted_edit_client: Scalars['String'],
  restricted_read_client_approval: Scalars['String'],
  user_client_manage_account: Scalars['String'],
  user_client_transaction_between: Scalars['String'],
  user_client_transaction_credit: Scalars['String'],
  user_client_transaction_deposit: Scalars['String'],
  user_client_transaction_transfer: Scalars['String'],
  user_client_transaction_withdrawal: Scalars['String'],
}

export interface MasterPartnerClientPermissionAggregate {
   __typename?: 'master_partner_client_permission_aggregate',
  aggregate?: Maybe<MasterPartnerClientPermissionAggregateFields>,
  nodes: Array<MasterPartnerClientPermission>,
}

export interface MasterPartnerClientPermissionAggregateFields {
   __typename?: 'master_partner_client_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterPartnerClientPermissionMaxFields>,
  min?: Maybe<MasterPartnerClientPermissionMinFields>,
}


export interface MasterPartnerClientPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterPartnerClientPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterPartnerClientPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterPartnerClientPermissionMaxOrderBy>,
  min?: Maybe<MasterPartnerClientPermissionMinOrderBy>,
}

export interface MasterPartnerClientPermissionArrRelInsertInput {
  data: Array<MasterPartnerClientPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerClientPermissionOnConflict>,
}

export interface MasterPartnerClientPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterPartnerClientPermissionBoolExp>>>,
  _not?: Maybe<MasterPartnerClientPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterPartnerClientPermissionBoolExp>>>,
  admin_client_account_require_approval?: Maybe<StringComparisonExp>,
  admin_client_accounts?: Maybe<StringComparisonExp>,
  admin_client_activity?: Maybe<StringComparisonExp>,
  admin_client_list?: Maybe<StringComparisonExp>,
  admin_client_manage_account?: Maybe<StringComparisonExp>,
  admin_client_manage_notes?: Maybe<StringComparisonExp>,
  admin_client_transaction_between?: Maybe<StringComparisonExp>,
  admin_client_transaction_credit?: Maybe<StringComparisonExp>,
  admin_client_transaction_deposit?: Maybe<StringComparisonExp>,
  admin_client_transaction_list?: Maybe<StringComparisonExp>,
  admin_client_transaction_transfer?: Maybe<StringComparisonExp>,
  admin_client_transaction_withdrawal?: Maybe<StringComparisonExp>,
  admin_create_client?: Maybe<StringComparisonExp>,
  admin_delete_client?: Maybe<StringComparisonExp>,
  admin_edit_client?: Maybe<StringComparisonExp>,
  admin_read_client_approval?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_client_manage_account?: Maybe<StringComparisonExp>,
  manager_client_transaction_between?: Maybe<StringComparisonExp>,
  manager_client_transaction_credit?: Maybe<StringComparisonExp>,
  manager_client_transaction_deposit?: Maybe<StringComparisonExp>,
  manager_client_transaction_transfer?: Maybe<StringComparisonExp>,
  manager_client_transaction_withdrawal?: Maybe<StringComparisonExp>,
  restricted_client_account_require_approval?: Maybe<StringComparisonExp>,
  restricted_client_accounts?: Maybe<StringComparisonExp>,
  restricted_client_activity?: Maybe<StringComparisonExp>,
  restricted_client_list?: Maybe<StringComparisonExp>,
  restricted_client_manage_account?: Maybe<StringComparisonExp>,
  restricted_client_manage_notes?: Maybe<StringComparisonExp>,
  restricted_client_transaction_between?: Maybe<StringComparisonExp>,
  restricted_client_transaction_credit?: Maybe<StringComparisonExp>,
  restricted_client_transaction_deposit?: Maybe<StringComparisonExp>,
  restricted_client_transaction_list?: Maybe<StringComparisonExp>,
  restricted_client_transaction_transfer?: Maybe<StringComparisonExp>,
  restricted_client_transaction_withdrawal?: Maybe<StringComparisonExp>,
  restricted_create_client?: Maybe<StringComparisonExp>,
  restricted_delete_client?: Maybe<StringComparisonExp>,
  restricted_edit_client?: Maybe<StringComparisonExp>,
  restricted_read_client_approval?: Maybe<StringComparisonExp>,
  user_client_manage_account?: Maybe<StringComparisonExp>,
  user_client_transaction_between?: Maybe<StringComparisonExp>,
  user_client_transaction_credit?: Maybe<StringComparisonExp>,
  user_client_transaction_deposit?: Maybe<StringComparisonExp>,
  user_client_transaction_transfer?: Maybe<StringComparisonExp>,
  user_client_transaction_withdrawal?: Maybe<StringComparisonExp>,
}

export enum MasterPartnerClientPermissionConstraint {
  MasterPartnerClientPermissionPkey = 'master_partner_client_permission_pkey'
}

export interface MasterPartnerClientPermissionInsertInput {
  admin_client_account_require_approval?: Maybe<Scalars['String']>,
  admin_client_accounts?: Maybe<Scalars['String']>,
  admin_client_activity?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_client_manage_account?: Maybe<Scalars['String']>,
  admin_client_manage_notes?: Maybe<Scalars['String']>,
  admin_client_transaction_between?: Maybe<Scalars['String']>,
  admin_client_transaction_credit?: Maybe<Scalars['String']>,
  admin_client_transaction_deposit?: Maybe<Scalars['String']>,
  admin_client_transaction_list?: Maybe<Scalars['String']>,
  admin_client_transaction_transfer?: Maybe<Scalars['String']>,
  admin_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_read_client_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_client_manage_account?: Maybe<Scalars['String']>,
  manager_client_transaction_between?: Maybe<Scalars['String']>,
  manager_client_transaction_credit?: Maybe<Scalars['String']>,
  manager_client_transaction_deposit?: Maybe<Scalars['String']>,
  manager_client_transaction_transfer?: Maybe<Scalars['String']>,
  manager_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_client_account_require_approval?: Maybe<Scalars['String']>,
  restricted_client_accounts?: Maybe<Scalars['String']>,
  restricted_client_activity?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_client_manage_account?: Maybe<Scalars['String']>,
  restricted_client_manage_notes?: Maybe<Scalars['String']>,
  restricted_client_transaction_between?: Maybe<Scalars['String']>,
  restricted_client_transaction_credit?: Maybe<Scalars['String']>,
  restricted_client_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_client_transaction_list?: Maybe<Scalars['String']>,
  restricted_client_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_read_client_approval?: Maybe<Scalars['String']>,
  user_client_manage_account?: Maybe<Scalars['String']>,
  user_client_transaction_between?: Maybe<Scalars['String']>,
  user_client_transaction_credit?: Maybe<Scalars['String']>,
  user_client_transaction_deposit?: Maybe<Scalars['String']>,
  user_client_transaction_transfer?: Maybe<Scalars['String']>,
  user_client_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerClientPermissionMaxFields {
   __typename?: 'master_partner_client_permission_max_fields',
  admin_client_account_require_approval?: Maybe<Scalars['String']>,
  admin_client_accounts?: Maybe<Scalars['String']>,
  admin_client_activity?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_client_manage_account?: Maybe<Scalars['String']>,
  admin_client_manage_notes?: Maybe<Scalars['String']>,
  admin_client_transaction_between?: Maybe<Scalars['String']>,
  admin_client_transaction_credit?: Maybe<Scalars['String']>,
  admin_client_transaction_deposit?: Maybe<Scalars['String']>,
  admin_client_transaction_list?: Maybe<Scalars['String']>,
  admin_client_transaction_transfer?: Maybe<Scalars['String']>,
  admin_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_read_client_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_client_manage_account?: Maybe<Scalars['String']>,
  manager_client_transaction_between?: Maybe<Scalars['String']>,
  manager_client_transaction_credit?: Maybe<Scalars['String']>,
  manager_client_transaction_deposit?: Maybe<Scalars['String']>,
  manager_client_transaction_transfer?: Maybe<Scalars['String']>,
  manager_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_client_account_require_approval?: Maybe<Scalars['String']>,
  restricted_client_accounts?: Maybe<Scalars['String']>,
  restricted_client_activity?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_client_manage_account?: Maybe<Scalars['String']>,
  restricted_client_manage_notes?: Maybe<Scalars['String']>,
  restricted_client_transaction_between?: Maybe<Scalars['String']>,
  restricted_client_transaction_credit?: Maybe<Scalars['String']>,
  restricted_client_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_client_transaction_list?: Maybe<Scalars['String']>,
  restricted_client_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_read_client_approval?: Maybe<Scalars['String']>,
  user_client_manage_account?: Maybe<Scalars['String']>,
  user_client_transaction_between?: Maybe<Scalars['String']>,
  user_client_transaction_credit?: Maybe<Scalars['String']>,
  user_client_transaction_deposit?: Maybe<Scalars['String']>,
  user_client_transaction_transfer?: Maybe<Scalars['String']>,
  user_client_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerClientPermissionMaxOrderBy {
  admin_client_account_require_approval?: Maybe<OrderBy>,
  admin_client_accounts?: Maybe<OrderBy>,
  admin_client_activity?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_client_manage_account?: Maybe<OrderBy>,
  admin_client_manage_notes?: Maybe<OrderBy>,
  admin_client_transaction_between?: Maybe<OrderBy>,
  admin_client_transaction_credit?: Maybe<OrderBy>,
  admin_client_transaction_deposit?: Maybe<OrderBy>,
  admin_client_transaction_list?: Maybe<OrderBy>,
  admin_client_transaction_transfer?: Maybe<OrderBy>,
  admin_client_transaction_withdrawal?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_read_client_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_client_manage_account?: Maybe<OrderBy>,
  manager_client_transaction_between?: Maybe<OrderBy>,
  manager_client_transaction_credit?: Maybe<OrderBy>,
  manager_client_transaction_deposit?: Maybe<OrderBy>,
  manager_client_transaction_transfer?: Maybe<OrderBy>,
  manager_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_client_account_require_approval?: Maybe<OrderBy>,
  restricted_client_accounts?: Maybe<OrderBy>,
  restricted_client_activity?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_client_manage_account?: Maybe<OrderBy>,
  restricted_client_manage_notes?: Maybe<OrderBy>,
  restricted_client_transaction_between?: Maybe<OrderBy>,
  restricted_client_transaction_credit?: Maybe<OrderBy>,
  restricted_client_transaction_deposit?: Maybe<OrderBy>,
  restricted_client_transaction_list?: Maybe<OrderBy>,
  restricted_client_transaction_transfer?: Maybe<OrderBy>,
  restricted_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_read_client_approval?: Maybe<OrderBy>,
  user_client_manage_account?: Maybe<OrderBy>,
  user_client_transaction_between?: Maybe<OrderBy>,
  user_client_transaction_credit?: Maybe<OrderBy>,
  user_client_transaction_deposit?: Maybe<OrderBy>,
  user_client_transaction_transfer?: Maybe<OrderBy>,
  user_client_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerClientPermissionMinFields {
   __typename?: 'master_partner_client_permission_min_fields',
  admin_client_account_require_approval?: Maybe<Scalars['String']>,
  admin_client_accounts?: Maybe<Scalars['String']>,
  admin_client_activity?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_client_manage_account?: Maybe<Scalars['String']>,
  admin_client_manage_notes?: Maybe<Scalars['String']>,
  admin_client_transaction_between?: Maybe<Scalars['String']>,
  admin_client_transaction_credit?: Maybe<Scalars['String']>,
  admin_client_transaction_deposit?: Maybe<Scalars['String']>,
  admin_client_transaction_list?: Maybe<Scalars['String']>,
  admin_client_transaction_transfer?: Maybe<Scalars['String']>,
  admin_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_read_client_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_client_manage_account?: Maybe<Scalars['String']>,
  manager_client_transaction_between?: Maybe<Scalars['String']>,
  manager_client_transaction_credit?: Maybe<Scalars['String']>,
  manager_client_transaction_deposit?: Maybe<Scalars['String']>,
  manager_client_transaction_transfer?: Maybe<Scalars['String']>,
  manager_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_client_account_require_approval?: Maybe<Scalars['String']>,
  restricted_client_accounts?: Maybe<Scalars['String']>,
  restricted_client_activity?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_client_manage_account?: Maybe<Scalars['String']>,
  restricted_client_manage_notes?: Maybe<Scalars['String']>,
  restricted_client_transaction_between?: Maybe<Scalars['String']>,
  restricted_client_transaction_credit?: Maybe<Scalars['String']>,
  restricted_client_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_client_transaction_list?: Maybe<Scalars['String']>,
  restricted_client_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_read_client_approval?: Maybe<Scalars['String']>,
  user_client_manage_account?: Maybe<Scalars['String']>,
  user_client_transaction_between?: Maybe<Scalars['String']>,
  user_client_transaction_credit?: Maybe<Scalars['String']>,
  user_client_transaction_deposit?: Maybe<Scalars['String']>,
  user_client_transaction_transfer?: Maybe<Scalars['String']>,
  user_client_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerClientPermissionMinOrderBy {
  admin_client_account_require_approval?: Maybe<OrderBy>,
  admin_client_accounts?: Maybe<OrderBy>,
  admin_client_activity?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_client_manage_account?: Maybe<OrderBy>,
  admin_client_manage_notes?: Maybe<OrderBy>,
  admin_client_transaction_between?: Maybe<OrderBy>,
  admin_client_transaction_credit?: Maybe<OrderBy>,
  admin_client_transaction_deposit?: Maybe<OrderBy>,
  admin_client_transaction_list?: Maybe<OrderBy>,
  admin_client_transaction_transfer?: Maybe<OrderBy>,
  admin_client_transaction_withdrawal?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_read_client_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_client_manage_account?: Maybe<OrderBy>,
  manager_client_transaction_between?: Maybe<OrderBy>,
  manager_client_transaction_credit?: Maybe<OrderBy>,
  manager_client_transaction_deposit?: Maybe<OrderBy>,
  manager_client_transaction_transfer?: Maybe<OrderBy>,
  manager_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_client_account_require_approval?: Maybe<OrderBy>,
  restricted_client_accounts?: Maybe<OrderBy>,
  restricted_client_activity?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_client_manage_account?: Maybe<OrderBy>,
  restricted_client_manage_notes?: Maybe<OrderBy>,
  restricted_client_transaction_between?: Maybe<OrderBy>,
  restricted_client_transaction_credit?: Maybe<OrderBy>,
  restricted_client_transaction_deposit?: Maybe<OrderBy>,
  restricted_client_transaction_list?: Maybe<OrderBy>,
  restricted_client_transaction_transfer?: Maybe<OrderBy>,
  restricted_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_read_client_approval?: Maybe<OrderBy>,
  user_client_manage_account?: Maybe<OrderBy>,
  user_client_transaction_between?: Maybe<OrderBy>,
  user_client_transaction_credit?: Maybe<OrderBy>,
  user_client_transaction_deposit?: Maybe<OrderBy>,
  user_client_transaction_transfer?: Maybe<OrderBy>,
  user_client_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerClientPermissionMutationResponse {
   __typename?: 'master_partner_client_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterPartnerClientPermission>,
}

export interface MasterPartnerClientPermissionObjRelInsertInput {
  data: MasterPartnerClientPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerClientPermissionOnConflict>,
}

export interface MasterPartnerClientPermissionOnConflict {
  constraint: MasterPartnerClientPermissionConstraint,
  update_columns: Array<MasterPartnerClientPermissionUpdateColumn>,
  where?: Maybe<MasterPartnerClientPermissionBoolExp>,
}

export interface MasterPartnerClientPermissionOrderBy {
  admin_client_account_require_approval?: Maybe<OrderBy>,
  admin_client_accounts?: Maybe<OrderBy>,
  admin_client_activity?: Maybe<OrderBy>,
  admin_client_list?: Maybe<OrderBy>,
  admin_client_manage_account?: Maybe<OrderBy>,
  admin_client_manage_notes?: Maybe<OrderBy>,
  admin_client_transaction_between?: Maybe<OrderBy>,
  admin_client_transaction_credit?: Maybe<OrderBy>,
  admin_client_transaction_deposit?: Maybe<OrderBy>,
  admin_client_transaction_list?: Maybe<OrderBy>,
  admin_client_transaction_transfer?: Maybe<OrderBy>,
  admin_client_transaction_withdrawal?: Maybe<OrderBy>,
  admin_create_client?: Maybe<OrderBy>,
  admin_delete_client?: Maybe<OrderBy>,
  admin_edit_client?: Maybe<OrderBy>,
  admin_read_client_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_client_manage_account?: Maybe<OrderBy>,
  manager_client_transaction_between?: Maybe<OrderBy>,
  manager_client_transaction_credit?: Maybe<OrderBy>,
  manager_client_transaction_deposit?: Maybe<OrderBy>,
  manager_client_transaction_transfer?: Maybe<OrderBy>,
  manager_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_client_account_require_approval?: Maybe<OrderBy>,
  restricted_client_accounts?: Maybe<OrderBy>,
  restricted_client_activity?: Maybe<OrderBy>,
  restricted_client_list?: Maybe<OrderBy>,
  restricted_client_manage_account?: Maybe<OrderBy>,
  restricted_client_manage_notes?: Maybe<OrderBy>,
  restricted_client_transaction_between?: Maybe<OrderBy>,
  restricted_client_transaction_credit?: Maybe<OrderBy>,
  restricted_client_transaction_deposit?: Maybe<OrderBy>,
  restricted_client_transaction_list?: Maybe<OrderBy>,
  restricted_client_transaction_transfer?: Maybe<OrderBy>,
  restricted_client_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_client?: Maybe<OrderBy>,
  restricted_delete_client?: Maybe<OrderBy>,
  restricted_edit_client?: Maybe<OrderBy>,
  restricted_read_client_approval?: Maybe<OrderBy>,
  user_client_manage_account?: Maybe<OrderBy>,
  user_client_transaction_between?: Maybe<OrderBy>,
  user_client_transaction_credit?: Maybe<OrderBy>,
  user_client_transaction_deposit?: Maybe<OrderBy>,
  user_client_transaction_transfer?: Maybe<OrderBy>,
  user_client_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerClientPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterPartnerClientPermissionSelectColumn {
  AdminClientAccountRequireApproval = 'admin_client_account_require_approval',
  AdminClientAccounts = 'admin_client_accounts',
  AdminClientActivity = 'admin_client_activity',
  AdminClientList = 'admin_client_list',
  AdminClientManageAccount = 'admin_client_manage_account',
  AdminClientManageNotes = 'admin_client_manage_notes',
  AdminClientTransactionBetween = 'admin_client_transaction_between',
  AdminClientTransactionCredit = 'admin_client_transaction_credit',
  AdminClientTransactionDeposit = 'admin_client_transaction_deposit',
  AdminClientTransactionList = 'admin_client_transaction_list',
  AdminClientTransactionTransfer = 'admin_client_transaction_transfer',
  AdminClientTransactionWithdrawal = 'admin_client_transaction_withdrawal',
  AdminCreateClient = 'admin_create_client',
  AdminDeleteClient = 'admin_delete_client',
  AdminEditClient = 'admin_edit_client',
  AdminReadClientApproval = 'admin_read_client_approval',
  Id = 'id',
  ManagerClientManageAccount = 'manager_client_manage_account',
  ManagerClientTransactionBetween = 'manager_client_transaction_between',
  ManagerClientTransactionCredit = 'manager_client_transaction_credit',
  ManagerClientTransactionDeposit = 'manager_client_transaction_deposit',
  ManagerClientTransactionTransfer = 'manager_client_transaction_transfer',
  ManagerClientTransactionWithdrawal = 'manager_client_transaction_withdrawal',
  RestrictedClientAccountRequireApproval = 'restricted_client_account_require_approval',
  RestrictedClientAccounts = 'restricted_client_accounts',
  RestrictedClientActivity = 'restricted_client_activity',
  RestrictedClientList = 'restricted_client_list',
  RestrictedClientManageAccount = 'restricted_client_manage_account',
  RestrictedClientManageNotes = 'restricted_client_manage_notes',
  RestrictedClientTransactionBetween = 'restricted_client_transaction_between',
  RestrictedClientTransactionCredit = 'restricted_client_transaction_credit',
  RestrictedClientTransactionDeposit = 'restricted_client_transaction_deposit',
  RestrictedClientTransactionList = 'restricted_client_transaction_list',
  RestrictedClientTransactionTransfer = 'restricted_client_transaction_transfer',
  RestrictedClientTransactionWithdrawal = 'restricted_client_transaction_withdrawal',
  RestrictedCreateClient = 'restricted_create_client',
  RestrictedDeleteClient = 'restricted_delete_client',
  RestrictedEditClient = 'restricted_edit_client',
  RestrictedReadClientApproval = 'restricted_read_client_approval',
  UserClientManageAccount = 'user_client_manage_account',
  UserClientTransactionBetween = 'user_client_transaction_between',
  UserClientTransactionCredit = 'user_client_transaction_credit',
  UserClientTransactionDeposit = 'user_client_transaction_deposit',
  UserClientTransactionTransfer = 'user_client_transaction_transfer',
  UserClientTransactionWithdrawal = 'user_client_transaction_withdrawal'
}

export interface MasterPartnerClientPermissionSetInput {
  admin_client_account_require_approval?: Maybe<Scalars['String']>,
  admin_client_accounts?: Maybe<Scalars['String']>,
  admin_client_activity?: Maybe<Scalars['String']>,
  admin_client_list?: Maybe<Scalars['String']>,
  admin_client_manage_account?: Maybe<Scalars['String']>,
  admin_client_manage_notes?: Maybe<Scalars['String']>,
  admin_client_transaction_between?: Maybe<Scalars['String']>,
  admin_client_transaction_credit?: Maybe<Scalars['String']>,
  admin_client_transaction_deposit?: Maybe<Scalars['String']>,
  admin_client_transaction_list?: Maybe<Scalars['String']>,
  admin_client_transaction_transfer?: Maybe<Scalars['String']>,
  admin_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_create_client?: Maybe<Scalars['String']>,
  admin_delete_client?: Maybe<Scalars['String']>,
  admin_edit_client?: Maybe<Scalars['String']>,
  admin_read_client_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_client_manage_account?: Maybe<Scalars['String']>,
  manager_client_transaction_between?: Maybe<Scalars['String']>,
  manager_client_transaction_credit?: Maybe<Scalars['String']>,
  manager_client_transaction_deposit?: Maybe<Scalars['String']>,
  manager_client_transaction_transfer?: Maybe<Scalars['String']>,
  manager_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_client_account_require_approval?: Maybe<Scalars['String']>,
  restricted_client_accounts?: Maybe<Scalars['String']>,
  restricted_client_activity?: Maybe<Scalars['String']>,
  restricted_client_list?: Maybe<Scalars['String']>,
  restricted_client_manage_account?: Maybe<Scalars['String']>,
  restricted_client_manage_notes?: Maybe<Scalars['String']>,
  restricted_client_transaction_between?: Maybe<Scalars['String']>,
  restricted_client_transaction_credit?: Maybe<Scalars['String']>,
  restricted_client_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_client_transaction_list?: Maybe<Scalars['String']>,
  restricted_client_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_client_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_client?: Maybe<Scalars['String']>,
  restricted_delete_client?: Maybe<Scalars['String']>,
  restricted_edit_client?: Maybe<Scalars['String']>,
  restricted_read_client_approval?: Maybe<Scalars['String']>,
  user_client_manage_account?: Maybe<Scalars['String']>,
  user_client_transaction_between?: Maybe<Scalars['String']>,
  user_client_transaction_credit?: Maybe<Scalars['String']>,
  user_client_transaction_deposit?: Maybe<Scalars['String']>,
  user_client_transaction_transfer?: Maybe<Scalars['String']>,
  user_client_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export enum MasterPartnerClientPermissionUpdateColumn {
  AdminClientAccountRequireApproval = 'admin_client_account_require_approval',
  AdminClientAccounts = 'admin_client_accounts',
  AdminClientActivity = 'admin_client_activity',
  AdminClientList = 'admin_client_list',
  AdminClientManageAccount = 'admin_client_manage_account',
  AdminClientManageNotes = 'admin_client_manage_notes',
  AdminClientTransactionBetween = 'admin_client_transaction_between',
  AdminClientTransactionCredit = 'admin_client_transaction_credit',
  AdminClientTransactionDeposit = 'admin_client_transaction_deposit',
  AdminClientTransactionList = 'admin_client_transaction_list',
  AdminClientTransactionTransfer = 'admin_client_transaction_transfer',
  AdminClientTransactionWithdrawal = 'admin_client_transaction_withdrawal',
  AdminCreateClient = 'admin_create_client',
  AdminDeleteClient = 'admin_delete_client',
  AdminEditClient = 'admin_edit_client',
  AdminReadClientApproval = 'admin_read_client_approval',
  Id = 'id',
  ManagerClientManageAccount = 'manager_client_manage_account',
  ManagerClientTransactionBetween = 'manager_client_transaction_between',
  ManagerClientTransactionCredit = 'manager_client_transaction_credit',
  ManagerClientTransactionDeposit = 'manager_client_transaction_deposit',
  ManagerClientTransactionTransfer = 'manager_client_transaction_transfer',
  ManagerClientTransactionWithdrawal = 'manager_client_transaction_withdrawal',
  RestrictedClientAccountRequireApproval = 'restricted_client_account_require_approval',
  RestrictedClientAccounts = 'restricted_client_accounts',
  RestrictedClientActivity = 'restricted_client_activity',
  RestrictedClientList = 'restricted_client_list',
  RestrictedClientManageAccount = 'restricted_client_manage_account',
  RestrictedClientManageNotes = 'restricted_client_manage_notes',
  RestrictedClientTransactionBetween = 'restricted_client_transaction_between',
  RestrictedClientTransactionCredit = 'restricted_client_transaction_credit',
  RestrictedClientTransactionDeposit = 'restricted_client_transaction_deposit',
  RestrictedClientTransactionList = 'restricted_client_transaction_list',
  RestrictedClientTransactionTransfer = 'restricted_client_transaction_transfer',
  RestrictedClientTransactionWithdrawal = 'restricted_client_transaction_withdrawal',
  RestrictedCreateClient = 'restricted_create_client',
  RestrictedDeleteClient = 'restricted_delete_client',
  RestrictedEditClient = 'restricted_edit_client',
  RestrictedReadClientApproval = 'restricted_read_client_approval',
  UserClientManageAccount = 'user_client_manage_account',
  UserClientTransactionBetween = 'user_client_transaction_between',
  UserClientTransactionCredit = 'user_client_transaction_credit',
  UserClientTransactionDeposit = 'user_client_transaction_deposit',
  UserClientTransactionTransfer = 'user_client_transaction_transfer',
  UserClientTransactionWithdrawal = 'user_client_transaction_withdrawal'
}

export interface MasterPartnerLeadPermission {
   __typename?: 'master_partner_lead_permission',
  admin_create_lead: Scalars['String'],
  admin_delete_lead: Scalars['String'],
  admin_edit_lead: Scalars['String'],
  admin_lead_account_list: Scalars['String'],
  admin_lead_account_require_approval: Scalars['String'],
  admin_lead_activity: Scalars['String'],
  admin_lead_list: Scalars['String'],
  admin_lead_manage_account: Scalars['String'],
  admin_lead_manage_notes: Scalars['String'],
  admin_read_lead_approval: Scalars['String'],
  id: Scalars['String'],
  manager_lead_manage_account: Scalars['String'],
  restricted_create_lead: Scalars['String'],
  restricted_delete_lead: Scalars['String'],
  restricted_edit_lead: Scalars['String'],
  restricted_lead_account_list: Scalars['String'],
  restricted_lead_account_require_approval: Scalars['String'],
  restricted_lead_activity: Scalars['String'],
  restricted_lead_list: Scalars['String'],
  restricted_lead_manage_account: Scalars['String'],
  restricted_lead_manage_notes: Scalars['String'],
  restricted_read_lead_approval: Scalars['String'],
  user_lead_manage_account: Scalars['String'],
}

export interface MasterPartnerLeadPermissionAggregate {
   __typename?: 'master_partner_lead_permission_aggregate',
  aggregate?: Maybe<MasterPartnerLeadPermissionAggregateFields>,
  nodes: Array<MasterPartnerLeadPermission>,
}

export interface MasterPartnerLeadPermissionAggregateFields {
   __typename?: 'master_partner_lead_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterPartnerLeadPermissionMaxFields>,
  min?: Maybe<MasterPartnerLeadPermissionMinFields>,
}


export interface MasterPartnerLeadPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterPartnerLeadPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterPartnerLeadPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterPartnerLeadPermissionMaxOrderBy>,
  min?: Maybe<MasterPartnerLeadPermissionMinOrderBy>,
}

export interface MasterPartnerLeadPermissionArrRelInsertInput {
  data: Array<MasterPartnerLeadPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerLeadPermissionOnConflict>,
}

export interface MasterPartnerLeadPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterPartnerLeadPermissionBoolExp>>>,
  _not?: Maybe<MasterPartnerLeadPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterPartnerLeadPermissionBoolExp>>>,
  admin_create_lead?: Maybe<StringComparisonExp>,
  admin_delete_lead?: Maybe<StringComparisonExp>,
  admin_edit_lead?: Maybe<StringComparisonExp>,
  admin_lead_account_list?: Maybe<StringComparisonExp>,
  admin_lead_account_require_approval?: Maybe<StringComparisonExp>,
  admin_lead_activity?: Maybe<StringComparisonExp>,
  admin_lead_list?: Maybe<StringComparisonExp>,
  admin_lead_manage_account?: Maybe<StringComparisonExp>,
  admin_lead_manage_notes?: Maybe<StringComparisonExp>,
  admin_read_lead_approval?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_lead_manage_account?: Maybe<StringComparisonExp>,
  restricted_create_lead?: Maybe<StringComparisonExp>,
  restricted_delete_lead?: Maybe<StringComparisonExp>,
  restricted_edit_lead?: Maybe<StringComparisonExp>,
  restricted_lead_account_list?: Maybe<StringComparisonExp>,
  restricted_lead_account_require_approval?: Maybe<StringComparisonExp>,
  restricted_lead_activity?: Maybe<StringComparisonExp>,
  restricted_lead_list?: Maybe<StringComparisonExp>,
  restricted_lead_manage_account?: Maybe<StringComparisonExp>,
  restricted_lead_manage_notes?: Maybe<StringComparisonExp>,
  restricted_read_lead_approval?: Maybe<StringComparisonExp>,
  user_lead_manage_account?: Maybe<StringComparisonExp>,
}

export enum MasterPartnerLeadPermissionConstraint {
  MasterPartnerLeadPermissionPkey = 'master_partner_lead_permission_pkey'
}

export interface MasterPartnerLeadPermissionInsertInput {
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_lead_account_list?: Maybe<Scalars['String']>,
  admin_lead_account_require_approval?: Maybe<Scalars['String']>,
  admin_lead_activity?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_lead_manage_account?: Maybe<Scalars['String']>,
  admin_lead_manage_notes?: Maybe<Scalars['String']>,
  admin_read_lead_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_lead_account_list?: Maybe<Scalars['String']>,
  restricted_lead_account_require_approval?: Maybe<Scalars['String']>,
  restricted_lead_activity?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_lead_manage_notes?: Maybe<Scalars['String']>,
  restricted_read_lead_approval?: Maybe<Scalars['String']>,
  user_lead_manage_account?: Maybe<Scalars['String']>,
}

export interface MasterPartnerLeadPermissionMaxFields {
   __typename?: 'master_partner_lead_permission_max_fields',
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_lead_account_list?: Maybe<Scalars['String']>,
  admin_lead_account_require_approval?: Maybe<Scalars['String']>,
  admin_lead_activity?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_lead_manage_account?: Maybe<Scalars['String']>,
  admin_lead_manage_notes?: Maybe<Scalars['String']>,
  admin_read_lead_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_lead_account_list?: Maybe<Scalars['String']>,
  restricted_lead_account_require_approval?: Maybe<Scalars['String']>,
  restricted_lead_activity?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_lead_manage_notes?: Maybe<Scalars['String']>,
  restricted_read_lead_approval?: Maybe<Scalars['String']>,
  user_lead_manage_account?: Maybe<Scalars['String']>,
}

export interface MasterPartnerLeadPermissionMaxOrderBy {
  admin_create_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_lead_account_list?: Maybe<OrderBy>,
  admin_lead_account_require_approval?: Maybe<OrderBy>,
  admin_lead_activity?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_lead_manage_account?: Maybe<OrderBy>,
  admin_lead_manage_notes?: Maybe<OrderBy>,
  admin_read_lead_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_lead_manage_account?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_lead_account_list?: Maybe<OrderBy>,
  restricted_lead_account_require_approval?: Maybe<OrderBy>,
  restricted_lead_activity?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_lead_manage_account?: Maybe<OrderBy>,
  restricted_lead_manage_notes?: Maybe<OrderBy>,
  restricted_read_lead_approval?: Maybe<OrderBy>,
  user_lead_manage_account?: Maybe<OrderBy>,
}

export interface MasterPartnerLeadPermissionMinFields {
   __typename?: 'master_partner_lead_permission_min_fields',
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_lead_account_list?: Maybe<Scalars['String']>,
  admin_lead_account_require_approval?: Maybe<Scalars['String']>,
  admin_lead_activity?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_lead_manage_account?: Maybe<Scalars['String']>,
  admin_lead_manage_notes?: Maybe<Scalars['String']>,
  admin_read_lead_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_lead_account_list?: Maybe<Scalars['String']>,
  restricted_lead_account_require_approval?: Maybe<Scalars['String']>,
  restricted_lead_activity?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_lead_manage_notes?: Maybe<Scalars['String']>,
  restricted_read_lead_approval?: Maybe<Scalars['String']>,
  user_lead_manage_account?: Maybe<Scalars['String']>,
}

export interface MasterPartnerLeadPermissionMinOrderBy {
  admin_create_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_lead_account_list?: Maybe<OrderBy>,
  admin_lead_account_require_approval?: Maybe<OrderBy>,
  admin_lead_activity?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_lead_manage_account?: Maybe<OrderBy>,
  admin_lead_manage_notes?: Maybe<OrderBy>,
  admin_read_lead_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_lead_manage_account?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_lead_account_list?: Maybe<OrderBy>,
  restricted_lead_account_require_approval?: Maybe<OrderBy>,
  restricted_lead_activity?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_lead_manage_account?: Maybe<OrderBy>,
  restricted_lead_manage_notes?: Maybe<OrderBy>,
  restricted_read_lead_approval?: Maybe<OrderBy>,
  user_lead_manage_account?: Maybe<OrderBy>,
}

export interface MasterPartnerLeadPermissionMutationResponse {
   __typename?: 'master_partner_lead_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterPartnerLeadPermission>,
}

export interface MasterPartnerLeadPermissionObjRelInsertInput {
  data: MasterPartnerLeadPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerLeadPermissionOnConflict>,
}

export interface MasterPartnerLeadPermissionOnConflict {
  constraint: MasterPartnerLeadPermissionConstraint,
  update_columns: Array<MasterPartnerLeadPermissionUpdateColumn>,
  where?: Maybe<MasterPartnerLeadPermissionBoolExp>,
}

export interface MasterPartnerLeadPermissionOrderBy {
  admin_create_lead?: Maybe<OrderBy>,
  admin_delete_lead?: Maybe<OrderBy>,
  admin_edit_lead?: Maybe<OrderBy>,
  admin_lead_account_list?: Maybe<OrderBy>,
  admin_lead_account_require_approval?: Maybe<OrderBy>,
  admin_lead_activity?: Maybe<OrderBy>,
  admin_lead_list?: Maybe<OrderBy>,
  admin_lead_manage_account?: Maybe<OrderBy>,
  admin_lead_manage_notes?: Maybe<OrderBy>,
  admin_read_lead_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_lead_manage_account?: Maybe<OrderBy>,
  restricted_create_lead?: Maybe<OrderBy>,
  restricted_delete_lead?: Maybe<OrderBy>,
  restricted_edit_lead?: Maybe<OrderBy>,
  restricted_lead_account_list?: Maybe<OrderBy>,
  restricted_lead_account_require_approval?: Maybe<OrderBy>,
  restricted_lead_activity?: Maybe<OrderBy>,
  restricted_lead_list?: Maybe<OrderBy>,
  restricted_lead_manage_account?: Maybe<OrderBy>,
  restricted_lead_manage_notes?: Maybe<OrderBy>,
  restricted_read_lead_approval?: Maybe<OrderBy>,
  user_lead_manage_account?: Maybe<OrderBy>,
}

export interface MasterPartnerLeadPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterPartnerLeadPermissionSelectColumn {
  AdminCreateLead = 'admin_create_lead',
  AdminDeleteLead = 'admin_delete_lead',
  AdminEditLead = 'admin_edit_lead',
  AdminLeadAccountList = 'admin_lead_account_list',
  AdminLeadAccountRequireApproval = 'admin_lead_account_require_approval',
  AdminLeadActivity = 'admin_lead_activity',
  AdminLeadList = 'admin_lead_list',
  AdminLeadManageAccount = 'admin_lead_manage_account',
  AdminLeadManageNotes = 'admin_lead_manage_notes',
  AdminReadLeadApproval = 'admin_read_lead_approval',
  Id = 'id',
  ManagerLeadManageAccount = 'manager_lead_manage_account',
  RestrictedCreateLead = 'restricted_create_lead',
  RestrictedDeleteLead = 'restricted_delete_lead',
  RestrictedEditLead = 'restricted_edit_lead',
  RestrictedLeadAccountList = 'restricted_lead_account_list',
  RestrictedLeadAccountRequireApproval = 'restricted_lead_account_require_approval',
  RestrictedLeadActivity = 'restricted_lead_activity',
  RestrictedLeadList = 'restricted_lead_list',
  RestrictedLeadManageAccount = 'restricted_lead_manage_account',
  RestrictedLeadManageNotes = 'restricted_lead_manage_notes',
  RestrictedReadLeadApproval = 'restricted_read_lead_approval',
  UserLeadManageAccount = 'user_lead_manage_account'
}

export interface MasterPartnerLeadPermissionSetInput {
  admin_create_lead?: Maybe<Scalars['String']>,
  admin_delete_lead?: Maybe<Scalars['String']>,
  admin_edit_lead?: Maybe<Scalars['String']>,
  admin_lead_account_list?: Maybe<Scalars['String']>,
  admin_lead_account_require_approval?: Maybe<Scalars['String']>,
  admin_lead_activity?: Maybe<Scalars['String']>,
  admin_lead_list?: Maybe<Scalars['String']>,
  admin_lead_manage_account?: Maybe<Scalars['String']>,
  admin_lead_manage_notes?: Maybe<Scalars['String']>,
  admin_read_lead_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_create_lead?: Maybe<Scalars['String']>,
  restricted_delete_lead?: Maybe<Scalars['String']>,
  restricted_edit_lead?: Maybe<Scalars['String']>,
  restricted_lead_account_list?: Maybe<Scalars['String']>,
  restricted_lead_account_require_approval?: Maybe<Scalars['String']>,
  restricted_lead_activity?: Maybe<Scalars['String']>,
  restricted_lead_list?: Maybe<Scalars['String']>,
  restricted_lead_manage_account?: Maybe<Scalars['String']>,
  restricted_lead_manage_notes?: Maybe<Scalars['String']>,
  restricted_read_lead_approval?: Maybe<Scalars['String']>,
  user_lead_manage_account?: Maybe<Scalars['String']>,
}

export enum MasterPartnerLeadPermissionUpdateColumn {
  AdminCreateLead = 'admin_create_lead',
  AdminDeleteLead = 'admin_delete_lead',
  AdminEditLead = 'admin_edit_lead',
  AdminLeadAccountList = 'admin_lead_account_list',
  AdminLeadAccountRequireApproval = 'admin_lead_account_require_approval',
  AdminLeadActivity = 'admin_lead_activity',
  AdminLeadList = 'admin_lead_list',
  AdminLeadManageAccount = 'admin_lead_manage_account',
  AdminLeadManageNotes = 'admin_lead_manage_notes',
  AdminReadLeadApproval = 'admin_read_lead_approval',
  Id = 'id',
  ManagerLeadManageAccount = 'manager_lead_manage_account',
  RestrictedCreateLead = 'restricted_create_lead',
  RestrictedDeleteLead = 'restricted_delete_lead',
  RestrictedEditLead = 'restricted_edit_lead',
  RestrictedLeadAccountList = 'restricted_lead_account_list',
  RestrictedLeadAccountRequireApproval = 'restricted_lead_account_require_approval',
  RestrictedLeadActivity = 'restricted_lead_activity',
  RestrictedLeadList = 'restricted_lead_list',
  RestrictedLeadManageAccount = 'restricted_lead_manage_account',
  RestrictedLeadManageNotes = 'restricted_lead_manage_notes',
  RestrictedReadLeadApproval = 'restricted_read_lead_approval',
  UserLeadManageAccount = 'user_lead_manage_account'
}

export interface MasterPartnerPartnerPermission {
   __typename?: 'master_partner_partner_permission',
  admin_create_partner: Scalars['String'],
  admin_delete_partner: Scalars['String'],
  admin_edit_partner: Scalars['String'],
  admin_partner_account_require_approval: Scalars['String'],
  admin_partner_accounts: Scalars['String'],
  admin_partner_activity: Scalars['String'],
  admin_partner_list: Scalars['String'],
  admin_partner_manage_account: Scalars['String'],
  admin_partner_manage_notes: Scalars['String'],
  admin_partner_transaction_between: Scalars['String'],
  admin_partner_transaction_credit: Scalars['String'],
  admin_partner_transaction_deposit: Scalars['String'],
  admin_partner_transaction_list: Scalars['String'],
  admin_partner_transaction_transfer: Scalars['String'],
  admin_partner_transaction_withdrawal: Scalars['String'],
  admin_read_partner_approval: Scalars['String'],
  id: Scalars['String'],
  manager_partner_manage_account: Scalars['String'],
  manager_partner_transaction_between: Scalars['String'],
  manager_partner_transaction_credit: Scalars['String'],
  manager_partner_transaction_deposit: Scalars['String'],
  manager_partner_transaction_transfer: Scalars['String'],
  manager_partner_transaction_withdrawal: Scalars['String'],
  restricted_create_partner: Scalars['String'],
  restricted_delete_partner: Scalars['String'],
  restricted_edit_partner: Scalars['String'],
  restricted_partner_account_require_approval: Scalars['String'],
  restricted_partner_accounts: Scalars['String'],
  restricted_partner_activity: Scalars['String'],
  restricted_partner_list: Scalars['String'],
  restricted_partner_manage_account: Scalars['String'],
  restricted_partner_manage_notes: Scalars['String'],
  restricted_partner_transaction_between: Scalars['String'],
  restricted_partner_transaction_credit: Scalars['String'],
  restricted_partner_transaction_deposit: Scalars['String'],
  restricted_partner_transaction_list: Scalars['String'],
  restricted_partner_transaction_transfer: Scalars['String'],
  restricted_partner_transaction_withdrawal: Scalars['String'],
  restricted_read_partner_approval: Scalars['String'],
  user_partner_manage_account: Scalars['String'],
  user_partner_transaction_between: Scalars['String'],
  user_partner_transaction_credit: Scalars['String'],
  user_partner_transaction_deposit: Scalars['String'],
  user_partner_transaction_transfer: Scalars['String'],
  user_partner_transaction_withdrawal: Scalars['String'],
}

export interface MasterPartnerPartnerPermissionAggregate {
   __typename?: 'master_partner_partner_permission_aggregate',
  aggregate?: Maybe<MasterPartnerPartnerPermissionAggregateFields>,
  nodes: Array<MasterPartnerPartnerPermission>,
}

export interface MasterPartnerPartnerPermissionAggregateFields {
   __typename?: 'master_partner_partner_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterPartnerPartnerPermissionMaxFields>,
  min?: Maybe<MasterPartnerPartnerPermissionMinFields>,
}


export interface MasterPartnerPartnerPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterPartnerPartnerPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterPartnerPartnerPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterPartnerPartnerPermissionMaxOrderBy>,
  min?: Maybe<MasterPartnerPartnerPermissionMinOrderBy>,
}

export interface MasterPartnerPartnerPermissionArrRelInsertInput {
  data: Array<MasterPartnerPartnerPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerPartnerPermissionOnConflict>,
}

export interface MasterPartnerPartnerPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterPartnerPartnerPermissionBoolExp>>>,
  _not?: Maybe<MasterPartnerPartnerPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterPartnerPartnerPermissionBoolExp>>>,
  admin_create_partner?: Maybe<StringComparisonExp>,
  admin_delete_partner?: Maybe<StringComparisonExp>,
  admin_edit_partner?: Maybe<StringComparisonExp>,
  admin_partner_account_require_approval?: Maybe<StringComparisonExp>,
  admin_partner_accounts?: Maybe<StringComparisonExp>,
  admin_partner_activity?: Maybe<StringComparisonExp>,
  admin_partner_list?: Maybe<StringComparisonExp>,
  admin_partner_manage_account?: Maybe<StringComparisonExp>,
  admin_partner_manage_notes?: Maybe<StringComparisonExp>,
  admin_partner_transaction_between?: Maybe<StringComparisonExp>,
  admin_partner_transaction_credit?: Maybe<StringComparisonExp>,
  admin_partner_transaction_deposit?: Maybe<StringComparisonExp>,
  admin_partner_transaction_list?: Maybe<StringComparisonExp>,
  admin_partner_transaction_transfer?: Maybe<StringComparisonExp>,
  admin_partner_transaction_withdrawal?: Maybe<StringComparisonExp>,
  admin_read_partner_approval?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_partner_manage_account?: Maybe<StringComparisonExp>,
  manager_partner_transaction_between?: Maybe<StringComparisonExp>,
  manager_partner_transaction_credit?: Maybe<StringComparisonExp>,
  manager_partner_transaction_deposit?: Maybe<StringComparisonExp>,
  manager_partner_transaction_transfer?: Maybe<StringComparisonExp>,
  manager_partner_transaction_withdrawal?: Maybe<StringComparisonExp>,
  restricted_create_partner?: Maybe<StringComparisonExp>,
  restricted_delete_partner?: Maybe<StringComparisonExp>,
  restricted_edit_partner?: Maybe<StringComparisonExp>,
  restricted_partner_account_require_approval?: Maybe<StringComparisonExp>,
  restricted_partner_accounts?: Maybe<StringComparisonExp>,
  restricted_partner_activity?: Maybe<StringComparisonExp>,
  restricted_partner_list?: Maybe<StringComparisonExp>,
  restricted_partner_manage_account?: Maybe<StringComparisonExp>,
  restricted_partner_manage_notes?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_between?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_credit?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_deposit?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_list?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_transfer?: Maybe<StringComparisonExp>,
  restricted_partner_transaction_withdrawal?: Maybe<StringComparisonExp>,
  restricted_read_partner_approval?: Maybe<StringComparisonExp>,
  user_partner_manage_account?: Maybe<StringComparisonExp>,
  user_partner_transaction_between?: Maybe<StringComparisonExp>,
  user_partner_transaction_credit?: Maybe<StringComparisonExp>,
  user_partner_transaction_deposit?: Maybe<StringComparisonExp>,
  user_partner_transaction_transfer?: Maybe<StringComparisonExp>,
  user_partner_transaction_withdrawal?: Maybe<StringComparisonExp>,
}

export enum MasterPartnerPartnerPermissionConstraint {
  MasterPartnerPartnerPermissionPkey = 'master_partner_partner_permission_pkey'
}

export interface MasterPartnerPartnerPermissionInsertInput {
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_partner_account_require_approval?: Maybe<Scalars['String']>,
  admin_partner_accounts?: Maybe<Scalars['String']>,
  admin_partner_activity?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_partner_manage_account?: Maybe<Scalars['String']>,
  admin_partner_manage_notes?: Maybe<Scalars['String']>,
  admin_partner_transaction_between?: Maybe<Scalars['String']>,
  admin_partner_transaction_credit?: Maybe<Scalars['String']>,
  admin_partner_transaction_deposit?: Maybe<Scalars['String']>,
  admin_partner_transaction_list?: Maybe<Scalars['String']>,
  admin_partner_transaction_transfer?: Maybe<Scalars['String']>,
  admin_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_read_partner_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_partner_manage_account?: Maybe<Scalars['String']>,
  manager_partner_transaction_between?: Maybe<Scalars['String']>,
  manager_partner_transaction_credit?: Maybe<Scalars['String']>,
  manager_partner_transaction_deposit?: Maybe<Scalars['String']>,
  manager_partner_transaction_transfer?: Maybe<Scalars['String']>,
  manager_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_partner_account_require_approval?: Maybe<Scalars['String']>,
  restricted_partner_accounts?: Maybe<Scalars['String']>,
  restricted_partner_activity?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_partner_manage_account?: Maybe<Scalars['String']>,
  restricted_partner_manage_notes?: Maybe<Scalars['String']>,
  restricted_partner_transaction_between?: Maybe<Scalars['String']>,
  restricted_partner_transaction_credit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_list?: Maybe<Scalars['String']>,
  restricted_partner_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_read_partner_approval?: Maybe<Scalars['String']>,
  user_partner_manage_account?: Maybe<Scalars['String']>,
  user_partner_transaction_between?: Maybe<Scalars['String']>,
  user_partner_transaction_credit?: Maybe<Scalars['String']>,
  user_partner_transaction_deposit?: Maybe<Scalars['String']>,
  user_partner_transaction_transfer?: Maybe<Scalars['String']>,
  user_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerPartnerPermissionMaxFields {
   __typename?: 'master_partner_partner_permission_max_fields',
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_partner_account_require_approval?: Maybe<Scalars['String']>,
  admin_partner_accounts?: Maybe<Scalars['String']>,
  admin_partner_activity?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_partner_manage_account?: Maybe<Scalars['String']>,
  admin_partner_manage_notes?: Maybe<Scalars['String']>,
  admin_partner_transaction_between?: Maybe<Scalars['String']>,
  admin_partner_transaction_credit?: Maybe<Scalars['String']>,
  admin_partner_transaction_deposit?: Maybe<Scalars['String']>,
  admin_partner_transaction_list?: Maybe<Scalars['String']>,
  admin_partner_transaction_transfer?: Maybe<Scalars['String']>,
  admin_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_read_partner_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_partner_manage_account?: Maybe<Scalars['String']>,
  manager_partner_transaction_between?: Maybe<Scalars['String']>,
  manager_partner_transaction_credit?: Maybe<Scalars['String']>,
  manager_partner_transaction_deposit?: Maybe<Scalars['String']>,
  manager_partner_transaction_transfer?: Maybe<Scalars['String']>,
  manager_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_partner_account_require_approval?: Maybe<Scalars['String']>,
  restricted_partner_accounts?: Maybe<Scalars['String']>,
  restricted_partner_activity?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_partner_manage_account?: Maybe<Scalars['String']>,
  restricted_partner_manage_notes?: Maybe<Scalars['String']>,
  restricted_partner_transaction_between?: Maybe<Scalars['String']>,
  restricted_partner_transaction_credit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_list?: Maybe<Scalars['String']>,
  restricted_partner_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_read_partner_approval?: Maybe<Scalars['String']>,
  user_partner_manage_account?: Maybe<Scalars['String']>,
  user_partner_transaction_between?: Maybe<Scalars['String']>,
  user_partner_transaction_credit?: Maybe<Scalars['String']>,
  user_partner_transaction_deposit?: Maybe<Scalars['String']>,
  user_partner_transaction_transfer?: Maybe<Scalars['String']>,
  user_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerPartnerPermissionMaxOrderBy {
  admin_create_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_partner_account_require_approval?: Maybe<OrderBy>,
  admin_partner_accounts?: Maybe<OrderBy>,
  admin_partner_activity?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_partner_manage_account?: Maybe<OrderBy>,
  admin_partner_manage_notes?: Maybe<OrderBy>,
  admin_partner_transaction_between?: Maybe<OrderBy>,
  admin_partner_transaction_credit?: Maybe<OrderBy>,
  admin_partner_transaction_deposit?: Maybe<OrderBy>,
  admin_partner_transaction_list?: Maybe<OrderBy>,
  admin_partner_transaction_transfer?: Maybe<OrderBy>,
  admin_partner_transaction_withdrawal?: Maybe<OrderBy>,
  admin_read_partner_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_partner_manage_account?: Maybe<OrderBy>,
  manager_partner_transaction_between?: Maybe<OrderBy>,
  manager_partner_transaction_credit?: Maybe<OrderBy>,
  manager_partner_transaction_deposit?: Maybe<OrderBy>,
  manager_partner_transaction_transfer?: Maybe<OrderBy>,
  manager_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_partner_account_require_approval?: Maybe<OrderBy>,
  restricted_partner_accounts?: Maybe<OrderBy>,
  restricted_partner_activity?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_partner_manage_account?: Maybe<OrderBy>,
  restricted_partner_manage_notes?: Maybe<OrderBy>,
  restricted_partner_transaction_between?: Maybe<OrderBy>,
  restricted_partner_transaction_credit?: Maybe<OrderBy>,
  restricted_partner_transaction_deposit?: Maybe<OrderBy>,
  restricted_partner_transaction_list?: Maybe<OrderBy>,
  restricted_partner_transaction_transfer?: Maybe<OrderBy>,
  restricted_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_read_partner_approval?: Maybe<OrderBy>,
  user_partner_manage_account?: Maybe<OrderBy>,
  user_partner_transaction_between?: Maybe<OrderBy>,
  user_partner_transaction_credit?: Maybe<OrderBy>,
  user_partner_transaction_deposit?: Maybe<OrderBy>,
  user_partner_transaction_transfer?: Maybe<OrderBy>,
  user_partner_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerPartnerPermissionMinFields {
   __typename?: 'master_partner_partner_permission_min_fields',
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_partner_account_require_approval?: Maybe<Scalars['String']>,
  admin_partner_accounts?: Maybe<Scalars['String']>,
  admin_partner_activity?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_partner_manage_account?: Maybe<Scalars['String']>,
  admin_partner_manage_notes?: Maybe<Scalars['String']>,
  admin_partner_transaction_between?: Maybe<Scalars['String']>,
  admin_partner_transaction_credit?: Maybe<Scalars['String']>,
  admin_partner_transaction_deposit?: Maybe<Scalars['String']>,
  admin_partner_transaction_list?: Maybe<Scalars['String']>,
  admin_partner_transaction_transfer?: Maybe<Scalars['String']>,
  admin_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_read_partner_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_partner_manage_account?: Maybe<Scalars['String']>,
  manager_partner_transaction_between?: Maybe<Scalars['String']>,
  manager_partner_transaction_credit?: Maybe<Scalars['String']>,
  manager_partner_transaction_deposit?: Maybe<Scalars['String']>,
  manager_partner_transaction_transfer?: Maybe<Scalars['String']>,
  manager_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_partner_account_require_approval?: Maybe<Scalars['String']>,
  restricted_partner_accounts?: Maybe<Scalars['String']>,
  restricted_partner_activity?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_partner_manage_account?: Maybe<Scalars['String']>,
  restricted_partner_manage_notes?: Maybe<Scalars['String']>,
  restricted_partner_transaction_between?: Maybe<Scalars['String']>,
  restricted_partner_transaction_credit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_list?: Maybe<Scalars['String']>,
  restricted_partner_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_read_partner_approval?: Maybe<Scalars['String']>,
  user_partner_manage_account?: Maybe<Scalars['String']>,
  user_partner_transaction_between?: Maybe<Scalars['String']>,
  user_partner_transaction_credit?: Maybe<Scalars['String']>,
  user_partner_transaction_deposit?: Maybe<Scalars['String']>,
  user_partner_transaction_transfer?: Maybe<Scalars['String']>,
  user_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterPartnerPartnerPermissionMinOrderBy {
  admin_create_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_partner_account_require_approval?: Maybe<OrderBy>,
  admin_partner_accounts?: Maybe<OrderBy>,
  admin_partner_activity?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_partner_manage_account?: Maybe<OrderBy>,
  admin_partner_manage_notes?: Maybe<OrderBy>,
  admin_partner_transaction_between?: Maybe<OrderBy>,
  admin_partner_transaction_credit?: Maybe<OrderBy>,
  admin_partner_transaction_deposit?: Maybe<OrderBy>,
  admin_partner_transaction_list?: Maybe<OrderBy>,
  admin_partner_transaction_transfer?: Maybe<OrderBy>,
  admin_partner_transaction_withdrawal?: Maybe<OrderBy>,
  admin_read_partner_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_partner_manage_account?: Maybe<OrderBy>,
  manager_partner_transaction_between?: Maybe<OrderBy>,
  manager_partner_transaction_credit?: Maybe<OrderBy>,
  manager_partner_transaction_deposit?: Maybe<OrderBy>,
  manager_partner_transaction_transfer?: Maybe<OrderBy>,
  manager_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_partner_account_require_approval?: Maybe<OrderBy>,
  restricted_partner_accounts?: Maybe<OrderBy>,
  restricted_partner_activity?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_partner_manage_account?: Maybe<OrderBy>,
  restricted_partner_manage_notes?: Maybe<OrderBy>,
  restricted_partner_transaction_between?: Maybe<OrderBy>,
  restricted_partner_transaction_credit?: Maybe<OrderBy>,
  restricted_partner_transaction_deposit?: Maybe<OrderBy>,
  restricted_partner_transaction_list?: Maybe<OrderBy>,
  restricted_partner_transaction_transfer?: Maybe<OrderBy>,
  restricted_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_read_partner_approval?: Maybe<OrderBy>,
  user_partner_manage_account?: Maybe<OrderBy>,
  user_partner_transaction_between?: Maybe<OrderBy>,
  user_partner_transaction_credit?: Maybe<OrderBy>,
  user_partner_transaction_deposit?: Maybe<OrderBy>,
  user_partner_transaction_transfer?: Maybe<OrderBy>,
  user_partner_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerPartnerPermissionMutationResponse {
   __typename?: 'master_partner_partner_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterPartnerPartnerPermission>,
}

export interface MasterPartnerPartnerPermissionObjRelInsertInput {
  data: MasterPartnerPartnerPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerPartnerPermissionOnConflict>,
}

export interface MasterPartnerPartnerPermissionOnConflict {
  constraint: MasterPartnerPartnerPermissionConstraint,
  update_columns: Array<MasterPartnerPartnerPermissionUpdateColumn>,
  where?: Maybe<MasterPartnerPartnerPermissionBoolExp>,
}

export interface MasterPartnerPartnerPermissionOrderBy {
  admin_create_partner?: Maybe<OrderBy>,
  admin_delete_partner?: Maybe<OrderBy>,
  admin_edit_partner?: Maybe<OrderBy>,
  admin_partner_account_require_approval?: Maybe<OrderBy>,
  admin_partner_accounts?: Maybe<OrderBy>,
  admin_partner_activity?: Maybe<OrderBy>,
  admin_partner_list?: Maybe<OrderBy>,
  admin_partner_manage_account?: Maybe<OrderBy>,
  admin_partner_manage_notes?: Maybe<OrderBy>,
  admin_partner_transaction_between?: Maybe<OrderBy>,
  admin_partner_transaction_credit?: Maybe<OrderBy>,
  admin_partner_transaction_deposit?: Maybe<OrderBy>,
  admin_partner_transaction_list?: Maybe<OrderBy>,
  admin_partner_transaction_transfer?: Maybe<OrderBy>,
  admin_partner_transaction_withdrawal?: Maybe<OrderBy>,
  admin_read_partner_approval?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_partner_manage_account?: Maybe<OrderBy>,
  manager_partner_transaction_between?: Maybe<OrderBy>,
  manager_partner_transaction_credit?: Maybe<OrderBy>,
  manager_partner_transaction_deposit?: Maybe<OrderBy>,
  manager_partner_transaction_transfer?: Maybe<OrderBy>,
  manager_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_create_partner?: Maybe<OrderBy>,
  restricted_delete_partner?: Maybe<OrderBy>,
  restricted_edit_partner?: Maybe<OrderBy>,
  restricted_partner_account_require_approval?: Maybe<OrderBy>,
  restricted_partner_accounts?: Maybe<OrderBy>,
  restricted_partner_activity?: Maybe<OrderBy>,
  restricted_partner_list?: Maybe<OrderBy>,
  restricted_partner_manage_account?: Maybe<OrderBy>,
  restricted_partner_manage_notes?: Maybe<OrderBy>,
  restricted_partner_transaction_between?: Maybe<OrderBy>,
  restricted_partner_transaction_credit?: Maybe<OrderBy>,
  restricted_partner_transaction_deposit?: Maybe<OrderBy>,
  restricted_partner_transaction_list?: Maybe<OrderBy>,
  restricted_partner_transaction_transfer?: Maybe<OrderBy>,
  restricted_partner_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_read_partner_approval?: Maybe<OrderBy>,
  user_partner_manage_account?: Maybe<OrderBy>,
  user_partner_transaction_between?: Maybe<OrderBy>,
  user_partner_transaction_credit?: Maybe<OrderBy>,
  user_partner_transaction_deposit?: Maybe<OrderBy>,
  user_partner_transaction_transfer?: Maybe<OrderBy>,
  user_partner_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterPartnerPartnerPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterPartnerPartnerPermissionSelectColumn {
  AdminCreatePartner = 'admin_create_partner',
  AdminDeletePartner = 'admin_delete_partner',
  AdminEditPartner = 'admin_edit_partner',
  AdminPartnerAccountRequireApproval = 'admin_partner_account_require_approval',
  AdminPartnerAccounts = 'admin_partner_accounts',
  AdminPartnerActivity = 'admin_partner_activity',
  AdminPartnerList = 'admin_partner_list',
  AdminPartnerManageAccount = 'admin_partner_manage_account',
  AdminPartnerManageNotes = 'admin_partner_manage_notes',
  AdminPartnerTransactionBetween = 'admin_partner_transaction_between',
  AdminPartnerTransactionCredit = 'admin_partner_transaction_credit',
  AdminPartnerTransactionDeposit = 'admin_partner_transaction_deposit',
  AdminPartnerTransactionList = 'admin_partner_transaction_list',
  AdminPartnerTransactionTransfer = 'admin_partner_transaction_transfer',
  AdminPartnerTransactionWithdrawal = 'admin_partner_transaction_withdrawal',
  AdminReadPartnerApproval = 'admin_read_partner_approval',
  Id = 'id',
  ManagerPartnerManageAccount = 'manager_partner_manage_account',
  ManagerPartnerTransactionBetween = 'manager_partner_transaction_between',
  ManagerPartnerTransactionCredit = 'manager_partner_transaction_credit',
  ManagerPartnerTransactionDeposit = 'manager_partner_transaction_deposit',
  ManagerPartnerTransactionTransfer = 'manager_partner_transaction_transfer',
  ManagerPartnerTransactionWithdrawal = 'manager_partner_transaction_withdrawal',
  RestrictedCreatePartner = 'restricted_create_partner',
  RestrictedDeletePartner = 'restricted_delete_partner',
  RestrictedEditPartner = 'restricted_edit_partner',
  RestrictedPartnerAccountRequireApproval = 'restricted_partner_account_require_approval',
  RestrictedPartnerAccounts = 'restricted_partner_accounts',
  RestrictedPartnerActivity = 'restricted_partner_activity',
  RestrictedPartnerList = 'restricted_partner_list',
  RestrictedPartnerManageAccount = 'restricted_partner_manage_account',
  RestrictedPartnerManageNotes = 'restricted_partner_manage_notes',
  RestrictedPartnerTransactionBetween = 'restricted_partner_transaction_between',
  RestrictedPartnerTransactionCredit = 'restricted_partner_transaction_credit',
  RestrictedPartnerTransactionDeposit = 'restricted_partner_transaction_deposit',
  RestrictedPartnerTransactionList = 'restricted_partner_transaction_list',
  RestrictedPartnerTransactionTransfer = 'restricted_partner_transaction_transfer',
  RestrictedPartnerTransactionWithdrawal = 'restricted_partner_transaction_withdrawal',
  RestrictedReadPartnerApproval = 'restricted_read_partner_approval',
  UserPartnerManageAccount = 'user_partner_manage_account',
  UserPartnerTransactionBetween = 'user_partner_transaction_between',
  UserPartnerTransactionCredit = 'user_partner_transaction_credit',
  UserPartnerTransactionDeposit = 'user_partner_transaction_deposit',
  UserPartnerTransactionTransfer = 'user_partner_transaction_transfer',
  UserPartnerTransactionWithdrawal = 'user_partner_transaction_withdrawal'
}

export interface MasterPartnerPartnerPermissionSetInput {
  admin_create_partner?: Maybe<Scalars['String']>,
  admin_delete_partner?: Maybe<Scalars['String']>,
  admin_edit_partner?: Maybe<Scalars['String']>,
  admin_partner_account_require_approval?: Maybe<Scalars['String']>,
  admin_partner_accounts?: Maybe<Scalars['String']>,
  admin_partner_activity?: Maybe<Scalars['String']>,
  admin_partner_list?: Maybe<Scalars['String']>,
  admin_partner_manage_account?: Maybe<Scalars['String']>,
  admin_partner_manage_notes?: Maybe<Scalars['String']>,
  admin_partner_transaction_between?: Maybe<Scalars['String']>,
  admin_partner_transaction_credit?: Maybe<Scalars['String']>,
  admin_partner_transaction_deposit?: Maybe<Scalars['String']>,
  admin_partner_transaction_list?: Maybe<Scalars['String']>,
  admin_partner_transaction_transfer?: Maybe<Scalars['String']>,
  admin_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  admin_read_partner_approval?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_partner_manage_account?: Maybe<Scalars['String']>,
  manager_partner_transaction_between?: Maybe<Scalars['String']>,
  manager_partner_transaction_credit?: Maybe<Scalars['String']>,
  manager_partner_transaction_deposit?: Maybe<Scalars['String']>,
  manager_partner_transaction_transfer?: Maybe<Scalars['String']>,
  manager_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_create_partner?: Maybe<Scalars['String']>,
  restricted_delete_partner?: Maybe<Scalars['String']>,
  restricted_edit_partner?: Maybe<Scalars['String']>,
  restricted_partner_account_require_approval?: Maybe<Scalars['String']>,
  restricted_partner_accounts?: Maybe<Scalars['String']>,
  restricted_partner_activity?: Maybe<Scalars['String']>,
  restricted_partner_list?: Maybe<Scalars['String']>,
  restricted_partner_manage_account?: Maybe<Scalars['String']>,
  restricted_partner_manage_notes?: Maybe<Scalars['String']>,
  restricted_partner_transaction_between?: Maybe<Scalars['String']>,
  restricted_partner_transaction_credit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_partner_transaction_list?: Maybe<Scalars['String']>,
  restricted_partner_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_read_partner_approval?: Maybe<Scalars['String']>,
  user_partner_manage_account?: Maybe<Scalars['String']>,
  user_partner_transaction_between?: Maybe<Scalars['String']>,
  user_partner_transaction_credit?: Maybe<Scalars['String']>,
  user_partner_transaction_deposit?: Maybe<Scalars['String']>,
  user_partner_transaction_transfer?: Maybe<Scalars['String']>,
  user_partner_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export enum MasterPartnerPartnerPermissionUpdateColumn {
  AdminCreatePartner = 'admin_create_partner',
  AdminDeletePartner = 'admin_delete_partner',
  AdminEditPartner = 'admin_edit_partner',
  AdminPartnerAccountRequireApproval = 'admin_partner_account_require_approval',
  AdminPartnerAccounts = 'admin_partner_accounts',
  AdminPartnerActivity = 'admin_partner_activity',
  AdminPartnerList = 'admin_partner_list',
  AdminPartnerManageAccount = 'admin_partner_manage_account',
  AdminPartnerManageNotes = 'admin_partner_manage_notes',
  AdminPartnerTransactionBetween = 'admin_partner_transaction_between',
  AdminPartnerTransactionCredit = 'admin_partner_transaction_credit',
  AdminPartnerTransactionDeposit = 'admin_partner_transaction_deposit',
  AdminPartnerTransactionList = 'admin_partner_transaction_list',
  AdminPartnerTransactionTransfer = 'admin_partner_transaction_transfer',
  AdminPartnerTransactionWithdrawal = 'admin_partner_transaction_withdrawal',
  AdminReadPartnerApproval = 'admin_read_partner_approval',
  Id = 'id',
  ManagerPartnerManageAccount = 'manager_partner_manage_account',
  ManagerPartnerTransactionBetween = 'manager_partner_transaction_between',
  ManagerPartnerTransactionCredit = 'manager_partner_transaction_credit',
  ManagerPartnerTransactionDeposit = 'manager_partner_transaction_deposit',
  ManagerPartnerTransactionTransfer = 'manager_partner_transaction_transfer',
  ManagerPartnerTransactionWithdrawal = 'manager_partner_transaction_withdrawal',
  RestrictedCreatePartner = 'restricted_create_partner',
  RestrictedDeletePartner = 'restricted_delete_partner',
  RestrictedEditPartner = 'restricted_edit_partner',
  RestrictedPartnerAccountRequireApproval = 'restricted_partner_account_require_approval',
  RestrictedPartnerAccounts = 'restricted_partner_accounts',
  RestrictedPartnerActivity = 'restricted_partner_activity',
  RestrictedPartnerList = 'restricted_partner_list',
  RestrictedPartnerManageAccount = 'restricted_partner_manage_account',
  RestrictedPartnerManageNotes = 'restricted_partner_manage_notes',
  RestrictedPartnerTransactionBetween = 'restricted_partner_transaction_between',
  RestrictedPartnerTransactionCredit = 'restricted_partner_transaction_credit',
  RestrictedPartnerTransactionDeposit = 'restricted_partner_transaction_deposit',
  RestrictedPartnerTransactionList = 'restricted_partner_transaction_list',
  RestrictedPartnerTransactionTransfer = 'restricted_partner_transaction_transfer',
  RestrictedPartnerTransactionWithdrawal = 'restricted_partner_transaction_withdrawal',
  RestrictedReadPartnerApproval = 'restricted_read_partner_approval',
  UserPartnerManageAccount = 'user_partner_manage_account',
  UserPartnerTransactionBetween = 'user_partner_transaction_between',
  UserPartnerTransactionCredit = 'user_partner_transaction_credit',
  UserPartnerTransactionDeposit = 'user_partner_transaction_deposit',
  UserPartnerTransactionTransfer = 'user_partner_transaction_transfer',
  UserPartnerTransactionWithdrawal = 'user_partner_transaction_withdrawal'
}

export interface MasterPartnerStakeholderPermission {
   __typename?: 'master_partner_stakeholder_permission',
  admin_manage_stakeholder_file: Scalars['String'],
  admin_stakeholder_activity: Scalars['String'],
  admin_stakeholder_bank_account_list: Scalars['String'],
  admin_stakeholder_file_list: Scalars['String'],
  admin_stakeholder_list: Scalars['String'],
  id: Scalars['String'],
  manager_manage_stakeholder_file: Scalars['String'],
  restricted_manage_stakeholder_file: Scalars['String'],
  restricted_stakeholder_activity: Scalars['String'],
  restricted_stakeholder_bank_account_list: Scalars['String'],
  restricted_stakeholder_file_list: Scalars['String'],
  restricted_stakeholder_list: Scalars['String'],
  user_manage_stakeholder_file: Scalars['String'],
}

export interface MasterPartnerStakeholderPermissionAggregate {
   __typename?: 'master_partner_stakeholder_permission_aggregate',
  aggregate?: Maybe<MasterPartnerStakeholderPermissionAggregateFields>,
  nodes: Array<MasterPartnerStakeholderPermission>,
}

export interface MasterPartnerStakeholderPermissionAggregateFields {
   __typename?: 'master_partner_stakeholder_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterPartnerStakeholderPermissionMaxFields>,
  min?: Maybe<MasterPartnerStakeholderPermissionMinFields>,
}


export interface MasterPartnerStakeholderPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterPartnerStakeholderPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterPartnerStakeholderPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterPartnerStakeholderPermissionMaxOrderBy>,
  min?: Maybe<MasterPartnerStakeholderPermissionMinOrderBy>,
}

export interface MasterPartnerStakeholderPermissionArrRelInsertInput {
  data: Array<MasterPartnerStakeholderPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerStakeholderPermissionOnConflict>,
}

export interface MasterPartnerStakeholderPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterPartnerStakeholderPermissionBoolExp>>>,
  _not?: Maybe<MasterPartnerStakeholderPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterPartnerStakeholderPermissionBoolExp>>>,
  admin_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  admin_stakeholder_activity?: Maybe<StringComparisonExp>,
  admin_stakeholder_bank_account_list?: Maybe<StringComparisonExp>,
  admin_stakeholder_file_list?: Maybe<StringComparisonExp>,
  admin_stakeholder_list?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  restricted_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  restricted_stakeholder_activity?: Maybe<StringComparisonExp>,
  restricted_stakeholder_bank_account_list?: Maybe<StringComparisonExp>,
  restricted_stakeholder_file_list?: Maybe<StringComparisonExp>,
  restricted_stakeholder_list?: Maybe<StringComparisonExp>,
  user_manage_stakeholder_file?: Maybe<StringComparisonExp>,
}

export enum MasterPartnerStakeholderPermissionConstraint {
  MasterPartnerStakeholderPermissionPkey = 'master_partner_stakeholder_permission_pkey'
}

export interface MasterPartnerStakeholderPermissionInsertInput {
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_activity?: Maybe<Scalars['String']>,
  admin_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_activity?: Maybe<Scalars['String']>,
  restricted_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
}

export interface MasterPartnerStakeholderPermissionMaxFields {
   __typename?: 'master_partner_stakeholder_permission_max_fields',
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_activity?: Maybe<Scalars['String']>,
  admin_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_activity?: Maybe<Scalars['String']>,
  restricted_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
}

export interface MasterPartnerStakeholderPermissionMaxOrderBy {
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_activity?: Maybe<OrderBy>,
  admin_stakeholder_bank_account_list?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_activity?: Maybe<OrderBy>,
  restricted_stakeholder_bank_account_list?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
}

export interface MasterPartnerStakeholderPermissionMinFields {
   __typename?: 'master_partner_stakeholder_permission_min_fields',
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_activity?: Maybe<Scalars['String']>,
  admin_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_activity?: Maybe<Scalars['String']>,
  restricted_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
}

export interface MasterPartnerStakeholderPermissionMinOrderBy {
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_activity?: Maybe<OrderBy>,
  admin_stakeholder_bank_account_list?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_activity?: Maybe<OrderBy>,
  restricted_stakeholder_bank_account_list?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
}

export interface MasterPartnerStakeholderPermissionMutationResponse {
   __typename?: 'master_partner_stakeholder_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterPartnerStakeholderPermission>,
}

export interface MasterPartnerStakeholderPermissionObjRelInsertInput {
  data: MasterPartnerStakeholderPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerStakeholderPermissionOnConflict>,
}

export interface MasterPartnerStakeholderPermissionOnConflict {
  constraint: MasterPartnerStakeholderPermissionConstraint,
  update_columns: Array<MasterPartnerStakeholderPermissionUpdateColumn>,
  where?: Maybe<MasterPartnerStakeholderPermissionBoolExp>,
}

export interface MasterPartnerStakeholderPermissionOrderBy {
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_activity?: Maybe<OrderBy>,
  admin_stakeholder_bank_account_list?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_stakeholder_list?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_activity?: Maybe<OrderBy>,
  restricted_stakeholder_bank_account_list?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_stakeholder_list?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
}

export interface MasterPartnerStakeholderPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterPartnerStakeholderPermissionSelectColumn {
  AdminManageStakeholderFile = 'admin_manage_stakeholder_file',
  AdminStakeholderActivity = 'admin_stakeholder_activity',
  AdminStakeholderBankAccountList = 'admin_stakeholder_bank_account_list',
  AdminStakeholderFileList = 'admin_stakeholder_file_list',
  AdminStakeholderList = 'admin_stakeholder_list',
  Id = 'id',
  ManagerManageStakeholderFile = 'manager_manage_stakeholder_file',
  RestrictedManageStakeholderFile = 'restricted_manage_stakeholder_file',
  RestrictedStakeholderActivity = 'restricted_stakeholder_activity',
  RestrictedStakeholderBankAccountList = 'restricted_stakeholder_bank_account_list',
  RestrictedStakeholderFileList = 'restricted_stakeholder_file_list',
  RestrictedStakeholderList = 'restricted_stakeholder_list',
  UserManageStakeholderFile = 'user_manage_stakeholder_file'
}

export interface MasterPartnerStakeholderPermissionSetInput {
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_activity?: Maybe<Scalars['String']>,
  admin_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_stakeholder_list?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_activity?: Maybe<Scalars['String']>,
  restricted_stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_stakeholder_list?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
}

export enum MasterPartnerStakeholderPermissionUpdateColumn {
  AdminManageStakeholderFile = 'admin_manage_stakeholder_file',
  AdminStakeholderActivity = 'admin_stakeholder_activity',
  AdminStakeholderBankAccountList = 'admin_stakeholder_bank_account_list',
  AdminStakeholderFileList = 'admin_stakeholder_file_list',
  AdminStakeholderList = 'admin_stakeholder_list',
  Id = 'id',
  ManagerManageStakeholderFile = 'manager_manage_stakeholder_file',
  RestrictedManageStakeholderFile = 'restricted_manage_stakeholder_file',
  RestrictedStakeholderActivity = 'restricted_stakeholder_activity',
  RestrictedStakeholderBankAccountList = 'restricted_stakeholder_bank_account_list',
  RestrictedStakeholderFileList = 'restricted_stakeholder_file_list',
  RestrictedStakeholderList = 'restricted_stakeholder_list',
  UserManageStakeholderFile = 'user_manage_stakeholder_file'
}

export interface MasterUserPermission {
   __typename?: 'master_user_permission',
  admin_account_list?: Maybe<Scalars['String']>,
  admin_account_require_approval?: Maybe<Scalars['String']>,
  admin_manage_account?: Maybe<Scalars['String']>,
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_transaction_credit?: Maybe<Scalars['String']>,
  admin_transaction_deposit?: Maybe<Scalars['String']>,
  admin_transaction_list?: Maybe<Scalars['String']>,
  admin_transaction_service_fee?: Maybe<Scalars['String']>,
  admin_transaction_transfer?: Maybe<Scalars['String']>,
  admin_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  admin_transaction_withdrawal?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manager_manage_account?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  manager_transaction_deposit?: Maybe<Scalars['String']>,
  manager_transaction_list?: Maybe<Scalars['String']>,
  manager_transaction_transfer?: Maybe<Scalars['String']>,
  manager_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_account_list?: Maybe<Scalars['String']>,
  restricted_account_require_approval?: Maybe<Scalars['String']>,
  restricted_manage_account?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_transaction_credit?: Maybe<Scalars['String']>,
  restricted_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_transaction_list?: Maybe<Scalars['String']>,
  restricted_transaction_service_fee?: Maybe<Scalars['String']>,
  restricted_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  restricted_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_limited_manage_account?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_limited_transaction_deposit?: Maybe<Scalars['String']>,
  user_limited_transaction_list?: Maybe<Scalars['String']>,
  user_limited_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_manage_account?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_transaction_deposit?: Maybe<Scalars['String']>,
  user_transaction_list?: Maybe<Scalars['String']>,
  user_transaction_transfer?: Maybe<Scalars['String']>,
  user_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterUserPermissionAggregate {
   __typename?: 'master_user_permission_aggregate',
  aggregate?: Maybe<MasterUserPermissionAggregateFields>,
  nodes: Array<MasterUserPermission>,
}

export interface MasterUserPermissionAggregateFields {
   __typename?: 'master_user_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<MasterUserPermissionMaxFields>,
  min?: Maybe<MasterUserPermissionMinFields>,
}


export interface MasterUserPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<MasterUserPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface MasterUserPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<MasterUserPermissionMaxOrderBy>,
  min?: Maybe<MasterUserPermissionMinOrderBy>,
}

export interface MasterUserPermissionArrRelInsertInput {
  data: Array<MasterUserPermissionInsertInput>,
  on_conflict?: Maybe<MasterUserPermissionOnConflict>,
}

export interface MasterUserPermissionBoolExp {
  _and?: Maybe<Array<Maybe<MasterUserPermissionBoolExp>>>,
  _not?: Maybe<MasterUserPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<MasterUserPermissionBoolExp>>>,
  admin_account_list?: Maybe<StringComparisonExp>,
  admin_account_require_approval?: Maybe<StringComparisonExp>,
  admin_manage_account?: Maybe<StringComparisonExp>,
  admin_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  admin_stakeholder_file_list?: Maybe<StringComparisonExp>,
  admin_transaction_credit?: Maybe<StringComparisonExp>,
  admin_transaction_deposit?: Maybe<StringComparisonExp>,
  admin_transaction_list?: Maybe<StringComparisonExp>,
  admin_transaction_service_fee?: Maybe<StringComparisonExp>,
  admin_transaction_transfer?: Maybe<StringComparisonExp>,
  admin_transaction_transfer_between_profiles?: Maybe<StringComparisonExp>,
  admin_transaction_withdrawal?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manager_manage_account?: Maybe<StringComparisonExp>,
  manager_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  manager_transaction_deposit?: Maybe<StringComparisonExp>,
  manager_transaction_list?: Maybe<StringComparisonExp>,
  manager_transaction_transfer?: Maybe<StringComparisonExp>,
  manager_transaction_withdrawal?: Maybe<StringComparisonExp>,
  restricted_account_list?: Maybe<StringComparisonExp>,
  restricted_account_require_approval?: Maybe<StringComparisonExp>,
  restricted_manage_account?: Maybe<StringComparisonExp>,
  restricted_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  restricted_stakeholder_file_list?: Maybe<StringComparisonExp>,
  restricted_transaction_credit?: Maybe<StringComparisonExp>,
  restricted_transaction_deposit?: Maybe<StringComparisonExp>,
  restricted_transaction_list?: Maybe<StringComparisonExp>,
  restricted_transaction_service_fee?: Maybe<StringComparisonExp>,
  restricted_transaction_transfer?: Maybe<StringComparisonExp>,
  restricted_transaction_transfer_between_profiles?: Maybe<StringComparisonExp>,
  restricted_transaction_withdrawal?: Maybe<StringComparisonExp>,
  user_limited_manage_account?: Maybe<StringComparisonExp>,
  user_limited_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  user_limited_transaction_deposit?: Maybe<StringComparisonExp>,
  user_limited_transaction_list?: Maybe<StringComparisonExp>,
  user_limited_transaction_withdrawal?: Maybe<StringComparisonExp>,
  user_manage_account?: Maybe<StringComparisonExp>,
  user_manage_stakeholder_file?: Maybe<StringComparisonExp>,
  user_transaction_deposit?: Maybe<StringComparisonExp>,
  user_transaction_list?: Maybe<StringComparisonExp>,
  user_transaction_transfer?: Maybe<StringComparisonExp>,
  user_transaction_withdrawal?: Maybe<StringComparisonExp>,
}

export enum MasterUserPermissionConstraint {
  MasterUserPermissionPkey = 'master_user_permission_pkey'
}

export interface MasterUserPermissionInsertInput {
  admin_account_list?: Maybe<Scalars['String']>,
  admin_account_require_approval?: Maybe<Scalars['String']>,
  admin_manage_account?: Maybe<Scalars['String']>,
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_transaction_credit?: Maybe<Scalars['String']>,
  admin_transaction_deposit?: Maybe<Scalars['String']>,
  admin_transaction_list?: Maybe<Scalars['String']>,
  admin_transaction_service_fee?: Maybe<Scalars['String']>,
  admin_transaction_transfer?: Maybe<Scalars['String']>,
  admin_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  admin_transaction_withdrawal?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_account?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  manager_transaction_deposit?: Maybe<Scalars['String']>,
  manager_transaction_list?: Maybe<Scalars['String']>,
  manager_transaction_transfer?: Maybe<Scalars['String']>,
  manager_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_account_list?: Maybe<Scalars['String']>,
  restricted_account_require_approval?: Maybe<Scalars['String']>,
  restricted_manage_account?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_transaction_credit?: Maybe<Scalars['String']>,
  restricted_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_transaction_list?: Maybe<Scalars['String']>,
  restricted_transaction_service_fee?: Maybe<Scalars['String']>,
  restricted_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  restricted_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_limited_manage_account?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_limited_transaction_deposit?: Maybe<Scalars['String']>,
  user_limited_transaction_list?: Maybe<Scalars['String']>,
  user_limited_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_manage_account?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_transaction_deposit?: Maybe<Scalars['String']>,
  user_transaction_list?: Maybe<Scalars['String']>,
  user_transaction_transfer?: Maybe<Scalars['String']>,
  user_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterUserPermissionMaxFields {
   __typename?: 'master_user_permission_max_fields',
  admin_account_list?: Maybe<Scalars['String']>,
  admin_account_require_approval?: Maybe<Scalars['String']>,
  admin_manage_account?: Maybe<Scalars['String']>,
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_transaction_credit?: Maybe<Scalars['String']>,
  admin_transaction_deposit?: Maybe<Scalars['String']>,
  admin_transaction_list?: Maybe<Scalars['String']>,
  admin_transaction_service_fee?: Maybe<Scalars['String']>,
  admin_transaction_transfer?: Maybe<Scalars['String']>,
  admin_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  admin_transaction_withdrawal?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_account?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  manager_transaction_deposit?: Maybe<Scalars['String']>,
  manager_transaction_list?: Maybe<Scalars['String']>,
  manager_transaction_transfer?: Maybe<Scalars['String']>,
  manager_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_account_list?: Maybe<Scalars['String']>,
  restricted_account_require_approval?: Maybe<Scalars['String']>,
  restricted_manage_account?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_transaction_credit?: Maybe<Scalars['String']>,
  restricted_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_transaction_list?: Maybe<Scalars['String']>,
  restricted_transaction_service_fee?: Maybe<Scalars['String']>,
  restricted_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  restricted_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_limited_manage_account?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_limited_transaction_deposit?: Maybe<Scalars['String']>,
  user_limited_transaction_list?: Maybe<Scalars['String']>,
  user_limited_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_manage_account?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_transaction_deposit?: Maybe<Scalars['String']>,
  user_transaction_list?: Maybe<Scalars['String']>,
  user_transaction_transfer?: Maybe<Scalars['String']>,
  user_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterUserPermissionMaxOrderBy {
  admin_account_list?: Maybe<OrderBy>,
  admin_account_require_approval?: Maybe<OrderBy>,
  admin_manage_account?: Maybe<OrderBy>,
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_transaction_credit?: Maybe<OrderBy>,
  admin_transaction_deposit?: Maybe<OrderBy>,
  admin_transaction_list?: Maybe<OrderBy>,
  admin_transaction_service_fee?: Maybe<OrderBy>,
  admin_transaction_transfer?: Maybe<OrderBy>,
  admin_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  admin_transaction_withdrawal?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_account?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  manager_transaction_deposit?: Maybe<OrderBy>,
  manager_transaction_list?: Maybe<OrderBy>,
  manager_transaction_transfer?: Maybe<OrderBy>,
  manager_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_account_list?: Maybe<OrderBy>,
  restricted_account_require_approval?: Maybe<OrderBy>,
  restricted_manage_account?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_transaction_credit?: Maybe<OrderBy>,
  restricted_transaction_deposit?: Maybe<OrderBy>,
  restricted_transaction_list?: Maybe<OrderBy>,
  restricted_transaction_service_fee?: Maybe<OrderBy>,
  restricted_transaction_transfer?: Maybe<OrderBy>,
  restricted_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  restricted_transaction_withdrawal?: Maybe<OrderBy>,
  user_limited_manage_account?: Maybe<OrderBy>,
  user_limited_manage_stakeholder_file?: Maybe<OrderBy>,
  user_limited_transaction_deposit?: Maybe<OrderBy>,
  user_limited_transaction_list?: Maybe<OrderBy>,
  user_limited_transaction_withdrawal?: Maybe<OrderBy>,
  user_manage_account?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
  user_transaction_deposit?: Maybe<OrderBy>,
  user_transaction_list?: Maybe<OrderBy>,
  user_transaction_transfer?: Maybe<OrderBy>,
  user_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterUserPermissionMinFields {
   __typename?: 'master_user_permission_min_fields',
  admin_account_list?: Maybe<Scalars['String']>,
  admin_account_require_approval?: Maybe<Scalars['String']>,
  admin_manage_account?: Maybe<Scalars['String']>,
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_transaction_credit?: Maybe<Scalars['String']>,
  admin_transaction_deposit?: Maybe<Scalars['String']>,
  admin_transaction_list?: Maybe<Scalars['String']>,
  admin_transaction_service_fee?: Maybe<Scalars['String']>,
  admin_transaction_transfer?: Maybe<Scalars['String']>,
  admin_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  admin_transaction_withdrawal?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_account?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  manager_transaction_deposit?: Maybe<Scalars['String']>,
  manager_transaction_list?: Maybe<Scalars['String']>,
  manager_transaction_transfer?: Maybe<Scalars['String']>,
  manager_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_account_list?: Maybe<Scalars['String']>,
  restricted_account_require_approval?: Maybe<Scalars['String']>,
  restricted_manage_account?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_transaction_credit?: Maybe<Scalars['String']>,
  restricted_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_transaction_list?: Maybe<Scalars['String']>,
  restricted_transaction_service_fee?: Maybe<Scalars['String']>,
  restricted_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  restricted_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_limited_manage_account?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_limited_transaction_deposit?: Maybe<Scalars['String']>,
  user_limited_transaction_list?: Maybe<Scalars['String']>,
  user_limited_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_manage_account?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_transaction_deposit?: Maybe<Scalars['String']>,
  user_transaction_list?: Maybe<Scalars['String']>,
  user_transaction_transfer?: Maybe<Scalars['String']>,
  user_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface MasterUserPermissionMinOrderBy {
  admin_account_list?: Maybe<OrderBy>,
  admin_account_require_approval?: Maybe<OrderBy>,
  admin_manage_account?: Maybe<OrderBy>,
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_transaction_credit?: Maybe<OrderBy>,
  admin_transaction_deposit?: Maybe<OrderBy>,
  admin_transaction_list?: Maybe<OrderBy>,
  admin_transaction_service_fee?: Maybe<OrderBy>,
  admin_transaction_transfer?: Maybe<OrderBy>,
  admin_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  admin_transaction_withdrawal?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_account?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  manager_transaction_deposit?: Maybe<OrderBy>,
  manager_transaction_list?: Maybe<OrderBy>,
  manager_transaction_transfer?: Maybe<OrderBy>,
  manager_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_account_list?: Maybe<OrderBy>,
  restricted_account_require_approval?: Maybe<OrderBy>,
  restricted_manage_account?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_transaction_credit?: Maybe<OrderBy>,
  restricted_transaction_deposit?: Maybe<OrderBy>,
  restricted_transaction_list?: Maybe<OrderBy>,
  restricted_transaction_service_fee?: Maybe<OrderBy>,
  restricted_transaction_transfer?: Maybe<OrderBy>,
  restricted_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  restricted_transaction_withdrawal?: Maybe<OrderBy>,
  user_limited_manage_account?: Maybe<OrderBy>,
  user_limited_manage_stakeholder_file?: Maybe<OrderBy>,
  user_limited_transaction_deposit?: Maybe<OrderBy>,
  user_limited_transaction_list?: Maybe<OrderBy>,
  user_limited_transaction_withdrawal?: Maybe<OrderBy>,
  user_manage_account?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
  user_transaction_deposit?: Maybe<OrderBy>,
  user_transaction_list?: Maybe<OrderBy>,
  user_transaction_transfer?: Maybe<OrderBy>,
  user_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterUserPermissionMutationResponse {
   __typename?: 'master_user_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<MasterUserPermission>,
}

export interface MasterUserPermissionObjRelInsertInput {
  data: MasterUserPermissionInsertInput,
  on_conflict?: Maybe<MasterUserPermissionOnConflict>,
}

export interface MasterUserPermissionOnConflict {
  constraint: MasterUserPermissionConstraint,
  update_columns: Array<MasterUserPermissionUpdateColumn>,
  where?: Maybe<MasterUserPermissionBoolExp>,
}

export interface MasterUserPermissionOrderBy {
  admin_account_list?: Maybe<OrderBy>,
  admin_account_require_approval?: Maybe<OrderBy>,
  admin_manage_account?: Maybe<OrderBy>,
  admin_manage_stakeholder_file?: Maybe<OrderBy>,
  admin_stakeholder_file_list?: Maybe<OrderBy>,
  admin_transaction_credit?: Maybe<OrderBy>,
  admin_transaction_deposit?: Maybe<OrderBy>,
  admin_transaction_list?: Maybe<OrderBy>,
  admin_transaction_service_fee?: Maybe<OrderBy>,
  admin_transaction_transfer?: Maybe<OrderBy>,
  admin_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  admin_transaction_withdrawal?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manager_manage_account?: Maybe<OrderBy>,
  manager_manage_stakeholder_file?: Maybe<OrderBy>,
  manager_transaction_deposit?: Maybe<OrderBy>,
  manager_transaction_list?: Maybe<OrderBy>,
  manager_transaction_transfer?: Maybe<OrderBy>,
  manager_transaction_withdrawal?: Maybe<OrderBy>,
  restricted_account_list?: Maybe<OrderBy>,
  restricted_account_require_approval?: Maybe<OrderBy>,
  restricted_manage_account?: Maybe<OrderBy>,
  restricted_manage_stakeholder_file?: Maybe<OrderBy>,
  restricted_stakeholder_file_list?: Maybe<OrderBy>,
  restricted_transaction_credit?: Maybe<OrderBy>,
  restricted_transaction_deposit?: Maybe<OrderBy>,
  restricted_transaction_list?: Maybe<OrderBy>,
  restricted_transaction_service_fee?: Maybe<OrderBy>,
  restricted_transaction_transfer?: Maybe<OrderBy>,
  restricted_transaction_transfer_between_profiles?: Maybe<OrderBy>,
  restricted_transaction_withdrawal?: Maybe<OrderBy>,
  user_limited_manage_account?: Maybe<OrderBy>,
  user_limited_manage_stakeholder_file?: Maybe<OrderBy>,
  user_limited_transaction_deposit?: Maybe<OrderBy>,
  user_limited_transaction_list?: Maybe<OrderBy>,
  user_limited_transaction_withdrawal?: Maybe<OrderBy>,
  user_manage_account?: Maybe<OrderBy>,
  user_manage_stakeholder_file?: Maybe<OrderBy>,
  user_transaction_deposit?: Maybe<OrderBy>,
  user_transaction_list?: Maybe<OrderBy>,
  user_transaction_transfer?: Maybe<OrderBy>,
  user_transaction_withdrawal?: Maybe<OrderBy>,
}

export interface MasterUserPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum MasterUserPermissionSelectColumn {
  AdminAccountList = 'admin_account_list',
  AdminAccountRequireApproval = 'admin_account_require_approval',
  AdminManageAccount = 'admin_manage_account',
  AdminManageStakeholderFile = 'admin_manage_stakeholder_file',
  AdminStakeholderFileList = 'admin_stakeholder_file_list',
  AdminTransactionCredit = 'admin_transaction_credit',
  AdminTransactionDeposit = 'admin_transaction_deposit',
  AdminTransactionList = 'admin_transaction_list',
  AdminTransactionServiceFee = 'admin_transaction_service_fee',
  AdminTransactionTransfer = 'admin_transaction_transfer',
  AdminTransactionTransferBetweenProfiles = 'admin_transaction_transfer_between_profiles',
  AdminTransactionWithdrawal = 'admin_transaction_withdrawal',
  Id = 'id',
  ManagerManageAccount = 'manager_manage_account',
  ManagerManageStakeholderFile = 'manager_manage_stakeholder_file',
  ManagerTransactionDeposit = 'manager_transaction_deposit',
  ManagerTransactionList = 'manager_transaction_list',
  ManagerTransactionTransfer = 'manager_transaction_transfer',
  ManagerTransactionWithdrawal = 'manager_transaction_withdrawal',
  RestrictedAccountList = 'restricted_account_list',
  RestrictedAccountRequireApproval = 'restricted_account_require_approval',
  RestrictedManageAccount = 'restricted_manage_account',
  RestrictedManageStakeholderFile = 'restricted_manage_stakeholder_file',
  RestrictedStakeholderFileList = 'restricted_stakeholder_file_list',
  RestrictedTransactionCredit = 'restricted_transaction_credit',
  RestrictedTransactionDeposit = 'restricted_transaction_deposit',
  RestrictedTransactionList = 'restricted_transaction_list',
  RestrictedTransactionServiceFee = 'restricted_transaction_service_fee',
  RestrictedTransactionTransfer = 'restricted_transaction_transfer',
  RestrictedTransactionTransferBetweenProfiles = 'restricted_transaction_transfer_between_profiles',
  RestrictedTransactionWithdrawal = 'restricted_transaction_withdrawal',
  UserLimitedManageAccount = 'user_limited_manage_account',
  UserLimitedManageStakeholderFile = 'user_limited_manage_stakeholder_file',
  UserLimitedTransactionDeposit = 'user_limited_transaction_deposit',
  UserLimitedTransactionList = 'user_limited_transaction_list',
  UserLimitedTransactionWithdrawal = 'user_limited_transaction_withdrawal',
  UserManageAccount = 'user_manage_account',
  UserManageStakeholderFile = 'user_manage_stakeholder_file',
  UserTransactionDeposit = 'user_transaction_deposit',
  UserTransactionList = 'user_transaction_list',
  UserTransactionTransfer = 'user_transaction_transfer',
  UserTransactionWithdrawal = 'user_transaction_withdrawal'
}

export interface MasterUserPermissionSetInput {
  admin_account_list?: Maybe<Scalars['String']>,
  admin_account_require_approval?: Maybe<Scalars['String']>,
  admin_manage_account?: Maybe<Scalars['String']>,
  admin_manage_stakeholder_file?: Maybe<Scalars['String']>,
  admin_stakeholder_file_list?: Maybe<Scalars['String']>,
  admin_transaction_credit?: Maybe<Scalars['String']>,
  admin_transaction_deposit?: Maybe<Scalars['String']>,
  admin_transaction_list?: Maybe<Scalars['String']>,
  admin_transaction_service_fee?: Maybe<Scalars['String']>,
  admin_transaction_transfer?: Maybe<Scalars['String']>,
  admin_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  admin_transaction_withdrawal?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manager_manage_account?: Maybe<Scalars['String']>,
  manager_manage_stakeholder_file?: Maybe<Scalars['String']>,
  manager_transaction_deposit?: Maybe<Scalars['String']>,
  manager_transaction_list?: Maybe<Scalars['String']>,
  manager_transaction_transfer?: Maybe<Scalars['String']>,
  manager_transaction_withdrawal?: Maybe<Scalars['String']>,
  restricted_account_list?: Maybe<Scalars['String']>,
  restricted_account_require_approval?: Maybe<Scalars['String']>,
  restricted_manage_account?: Maybe<Scalars['String']>,
  restricted_manage_stakeholder_file?: Maybe<Scalars['String']>,
  restricted_stakeholder_file_list?: Maybe<Scalars['String']>,
  restricted_transaction_credit?: Maybe<Scalars['String']>,
  restricted_transaction_deposit?: Maybe<Scalars['String']>,
  restricted_transaction_list?: Maybe<Scalars['String']>,
  restricted_transaction_service_fee?: Maybe<Scalars['String']>,
  restricted_transaction_transfer?: Maybe<Scalars['String']>,
  restricted_transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  restricted_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_limited_manage_account?: Maybe<Scalars['String']>,
  user_limited_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_limited_transaction_deposit?: Maybe<Scalars['String']>,
  user_limited_transaction_list?: Maybe<Scalars['String']>,
  user_limited_transaction_withdrawal?: Maybe<Scalars['String']>,
  user_manage_account?: Maybe<Scalars['String']>,
  user_manage_stakeholder_file?: Maybe<Scalars['String']>,
  user_transaction_deposit?: Maybe<Scalars['String']>,
  user_transaction_list?: Maybe<Scalars['String']>,
  user_transaction_transfer?: Maybe<Scalars['String']>,
  user_transaction_withdrawal?: Maybe<Scalars['String']>,
}

export enum MasterUserPermissionUpdateColumn {
  AdminAccountList = 'admin_account_list',
  AdminAccountRequireApproval = 'admin_account_require_approval',
  AdminManageAccount = 'admin_manage_account',
  AdminManageStakeholderFile = 'admin_manage_stakeholder_file',
  AdminStakeholderFileList = 'admin_stakeholder_file_list',
  AdminTransactionCredit = 'admin_transaction_credit',
  AdminTransactionDeposit = 'admin_transaction_deposit',
  AdminTransactionList = 'admin_transaction_list',
  AdminTransactionServiceFee = 'admin_transaction_service_fee',
  AdminTransactionTransfer = 'admin_transaction_transfer',
  AdminTransactionTransferBetweenProfiles = 'admin_transaction_transfer_between_profiles',
  AdminTransactionWithdrawal = 'admin_transaction_withdrawal',
  Id = 'id',
  ManagerManageAccount = 'manager_manage_account',
  ManagerManageStakeholderFile = 'manager_manage_stakeholder_file',
  ManagerTransactionDeposit = 'manager_transaction_deposit',
  ManagerTransactionList = 'manager_transaction_list',
  ManagerTransactionTransfer = 'manager_transaction_transfer',
  ManagerTransactionWithdrawal = 'manager_transaction_withdrawal',
  RestrictedAccountList = 'restricted_account_list',
  RestrictedAccountRequireApproval = 'restricted_account_require_approval',
  RestrictedManageAccount = 'restricted_manage_account',
  RestrictedManageStakeholderFile = 'restricted_manage_stakeholder_file',
  RestrictedStakeholderFileList = 'restricted_stakeholder_file_list',
  RestrictedTransactionCredit = 'restricted_transaction_credit',
  RestrictedTransactionDeposit = 'restricted_transaction_deposit',
  RestrictedTransactionList = 'restricted_transaction_list',
  RestrictedTransactionServiceFee = 'restricted_transaction_service_fee',
  RestrictedTransactionTransfer = 'restricted_transaction_transfer',
  RestrictedTransactionTransferBetweenProfiles = 'restricted_transaction_transfer_between_profiles',
  RestrictedTransactionWithdrawal = 'restricted_transaction_withdrawal',
  UserLimitedManageAccount = 'user_limited_manage_account',
  UserLimitedManageStakeholderFile = 'user_limited_manage_stakeholder_file',
  UserLimitedTransactionDeposit = 'user_limited_transaction_deposit',
  UserLimitedTransactionList = 'user_limited_transaction_list',
  UserLimitedTransactionWithdrawal = 'user_limited_transaction_withdrawal',
  UserManageAccount = 'user_manage_account',
  UserManageStakeholderFile = 'user_manage_stakeholder_file',
  UserTransactionDeposit = 'user_transaction_deposit',
  UserTransactionList = 'user_transaction_list',
  UserTransactionTransfer = 'user_transaction_transfer',
  UserTransactionWithdrawal = 'user_transaction_withdrawal'
}

export interface MutationRoot {
   __typename?: 'mutation_root',
  actionName?: Maybe<SampleOutput>,
  delete_access_format?: Maybe<AccessFormatMutationResponse>,
  delete_access_format_by_pk?: Maybe<AccessFormat>,
  delete_access_format_type?: Maybe<AccessFormatTypeMutationResponse>,
  delete_access_format_type_by_pk?: Maybe<AccessFormatType>,
  delete_account?: Maybe<AccountMutationResponse>,
  delete_account_by_pk?: Maybe<Account>,
  delete_account_creation_request?: Maybe<AccountCreationRequestMutationResponse>,
  delete_account_creation_request_by_pk?: Maybe<AccountCreationRequest>,
  delete_account_group?: Maybe<AccountGroupMutationResponse>,
  delete_account_group_by_pk?: Maybe<AccountGroup>,
  delete_account_group_default?: Maybe<AccountGroupDefaultMutationResponse>,
  delete_account_group_default_by_pk?: Maybe<AccountGroupDefault>,
  delete_account_group_security?: Maybe<AccountGroupSecurityMutationResponse>,
  delete_account_group_security_by_pk?: Maybe<AccountGroupSecurity>,
  delete_account_groups_to_partners?: Maybe<AccountGroupsToPartnersMutationResponse>,
  delete_account_groups_to_partners_by_pk?: Maybe<AccountGroupsToPartners>,
  delete_account_type?: Maybe<AccountTypeMutationResponse>,
  delete_account_type_by_pk?: Maybe<AccountType>,
  delete_activities_to_channels?: Maybe<ActivitiesToChannelsMutationResponse>,
  delete_activities_to_channels_by_pk?: Maybe<ActivitiesToChannels>,
  delete_activity?: Maybe<ActivityMutationResponse>,
  delete_activity_by_pk?: Maybe<Activity>,
  delete_activity_category?: Maybe<ActivityCategoryMutationResponse>,
  delete_activity_category_by_pk?: Maybe<ActivityCategory>,
  delete_activity_channel?: Maybe<ActivityChannelMutationResponse>,
  delete_activity_channel_by_pk?: Maybe<ActivityChannel>,
  delete_activity_subject?: Maybe<ActivitySubjectMutationResponse>,
  delete_activity_subject_by_pk?: Maybe<ActivitySubject>,
  delete_activity_template?: Maybe<ActivityTemplateMutationResponse>,
  delete_activity_template_by_pk?: Maybe<ActivityTemplate>,
  delete_admin_permission?: Maybe<AdminPermissionMutationResponse>,
  delete_admin_permission_by_pk?: Maybe<AdminPermission>,
  delete_agreement?: Maybe<AgreementMutationResponse>,
  delete_agreement_by_pk?: Maybe<Agreement>,
  delete_agreement_scenario?: Maybe<AgreementScenarioMutationResponse>,
  delete_agreement_scenario_by_pk?: Maybe<AgreementScenario>,
  delete_agreements_satisfied?: Maybe<AgreementsSatisfiedMutationResponse>,
  delete_agreements_satisfied_by_pk?: Maybe<AgreementsSatisfied>,
  delete_agreements_to_payment_methods?: Maybe<AgreementsToPaymentMethodsMutationResponse>,
  delete_agreements_to_payment_methods_by_pk?: Maybe<AgreementsToPaymentMethods>,
  delete_annual_income?: Maybe<AnnualIncomeMutationResponse>,
  delete_annual_income_by_pk?: Maybe<AnnualIncome>,
  delete_api_key?: Maybe<ApiKeyMutationResponse>,
  delete_api_key_by_pk?: Maybe<ApiKey>,
  delete_attribute_metadata?: Maybe<AttributeMetadataMutationResponse>,
  delete_attribute_metadata_by_pk?: Maybe<AttributeMetadata>,
  delete_attribute_type?: Maybe<AttributeTypeMutationResponse>,
  delete_attribute_type_by_pk?: Maybe<AttributeType>,
  delete_bank_account?: Maybe<BankAccountMutationResponse>,
  delete_bank_account_by_pk?: Maybe<BankAccount>,
  delete_book_type?: Maybe<BookTypeMutationResponse>,
  delete_book_type_by_pk?: Maybe<BookType>,
  delete_charge_unit?: Maybe<ChargeUnitMutationResponse>,
  delete_charge_unit_by_pk?: Maybe<ChargeUnit>,
  delete_countries_to_phone_codes?: Maybe<CountriesToPhoneCodesMutationResponse>,
  delete_countries_to_phone_codes_by_pk?: Maybe<CountriesToPhoneCodes>,
  delete_country?: Maybe<CountryMutationResponse>,
  delete_country_by_pk?: Maybe<Country>,
  delete_currencies_to_organizations?: Maybe<CurrenciesToOrganizationsMutationResponse>,
  delete_currencies_to_organizations_by_pk?: Maybe<CurrenciesToOrganizations>,
  delete_currency?: Maybe<CurrencyMutationResponse>,
  delete_currency_by_pk?: Maybe<Currency>,
  delete_daily_account_revenue_summary?: Maybe<DailyAccountRevenueSummaryMutationResponse>,
  delete_daily_account_revenue_summary_by_pk?: Maybe<DailyAccountRevenueSummary>,
  delete_daily_account_status?: Maybe<DailyAccountStatusMutationResponse>,
  delete_daily_account_status_by_pk?: Maybe<DailyAccountStatus>,
  delete_daily_volume_per_account?: Maybe<DailyVolumePerAccountMutationResponse>,
  delete_daily_volume_per_account_by_pk?: Maybe<DailyVolumePerAccount>,
  delete_daily_volume_per_profile?: Maybe<DailyVolumePerProfileMutationResponse>,
  delete_daily_volume_per_profile_by_pk?: Maybe<DailyVolumePerProfile>,
  delete_employment_status?: Maybe<EmploymentStatusMutationResponse>,
  delete_employment_status_by_pk?: Maybe<EmploymentStatus>,
  delete_experience_type?: Maybe<ExperienceTypeMutationResponse>,
  delete_experience_type_by_pk?: Maybe<ExperienceType>,
  delete_file?: Maybe<FileMutationResponse>,
  delete_file_by_pk?: Maybe<File>,
  delete_file_type?: Maybe<FileTypeMutationResponse>,
  delete_file_type_by_pk?: Maybe<FileType>,
  delete_file_type_category?: Maybe<FileTypeCategoryMutationResponse>,
  delete_file_type_category_by_pk?: Maybe<FileTypeCategory>,
  delete_files_to_organizations?: Maybe<FilesToOrganizationsMutationResponse>,
  delete_files_to_organizations_by_pk?: Maybe<FilesToOrganizations>,
  delete_files_to_stakeholders?: Maybe<FilesToStakeholdersMutationResponse>,
  delete_files_to_stakeholders_by_pk?: Maybe<FilesToStakeholders>,
  delete_gender?: Maybe<GenderMutationResponse>,
  delete_gender_by_pk?: Maybe<Gender>,
  delete_identification_type?: Maybe<IdentificationTypeMutationResponse>,
  delete_identification_type_by_pk?: Maybe<IdentificationType>,
  delete_individual_partner_account_group_default?: Maybe<IndividualPartnerAccountGroupDefaultMutationResponse>,
  delete_individual_partner_account_group_default_by_pk?: Maybe<IndividualPartnerAccountGroupDefault>,
  delete_industry?: Maybe<IndustryMutationResponse>,
  delete_industry_by_pk?: Maybe<Industry>,
  delete_intermediary_bank?: Maybe<IntermediaryBankMutationResponse>,
  delete_intermediary_bank_by_pk?: Maybe<IntermediaryBank>,
  delete_investment_objective?: Maybe<InvestmentObjectiveMutationResponse>,
  delete_investment_objective_by_pk?: Maybe<InvestmentObjective>,
  delete_leverage?: Maybe<LeverageMutationResponse>,
  delete_leverage_by_pk?: Maybe<Leverage>,
  delete_leverages_to_platform_server_types?: Maybe<LeveragesToPlatformServerTypesMutationResponse>,
  delete_leverages_to_platform_server_types_by_pk?: Maybe<LeveragesToPlatformServerTypes>,
  delete_liquid_assets?: Maybe<LiquidAssetsMutationResponse>,
  delete_liquid_assets_by_pk?: Maybe<LiquidAssets>,
  delete_locale?: Maybe<LocaleMutationResponse>,
  delete_locale_by_pk?: Maybe<Locale>,
  delete_locales_to_organizations?: Maybe<LocalesToOrganizationsMutationResponse>,
  delete_locales_to_organizations_by_pk?: Maybe<LocalesToOrganizations>,
  delete_margin_type?: Maybe<MarginTypeMutationResponse>,
  delete_margin_type_by_pk?: Maybe<MarginType>,
  delete_master_admin_client_permission?: Maybe<MasterAdminClientPermissionMutationResponse>,
  delete_master_admin_client_permission_by_pk?: Maybe<MasterAdminClientPermission>,
  delete_master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionMutationResponse>,
  delete_master_admin_lead_permission_by_pk?: Maybe<MasterAdminLeadPermission>,
  delete_master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionMutationResponse>,
  delete_master_admin_partner_permission_by_pk?: Maybe<MasterAdminPartnerPermission>,
  delete_master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionMutationResponse>,
  delete_master_admin_stakeholder_permission_by_pk?: Maybe<MasterAdminStakeholderPermission>,
  delete_master_admin_system_permission?: Maybe<MasterAdminSystemPermissionMutationResponse>,
  delete_master_admin_system_permission_by_pk?: Maybe<MasterAdminSystemPermission>,
  delete_master_partner_client_permission?: Maybe<MasterPartnerClientPermissionMutationResponse>,
  delete_master_partner_client_permission_by_pk?: Maybe<MasterPartnerClientPermission>,
  delete_master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionMutationResponse>,
  delete_master_partner_lead_permission_by_pk?: Maybe<MasterPartnerLeadPermission>,
  delete_master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionMutationResponse>,
  delete_master_partner_partner_permission_by_pk?: Maybe<MasterPartnerPartnerPermission>,
  delete_master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionMutationResponse>,
  delete_master_partner_stakeholder_permission_by_pk?: Maybe<MasterPartnerStakeholderPermission>,
  delete_master_user_permission?: Maybe<MasterUserPermissionMutationResponse>,
  delete_master_user_permission_by_pk?: Maybe<MasterUserPermission>,
  delete_name_title?: Maybe<NameTitleMutationResponse>,
  delete_name_title_by_pk?: Maybe<NameTitle>,
  delete_notification?: Maybe<NotificationMutationResponse>,
  delete_notification_by_pk?: Maybe<Notification>,
  delete_organization?: Maybe<OrganizationMutationResponse>,
  delete_organization_by_pk?: Maybe<Organization>,
  delete_origin?: Maybe<OriginMutationResponse>,
  delete_origin_by_pk?: Maybe<Origin>,
  delete_partner_permission?: Maybe<PartnerPermissionMutationResponse>,
  delete_partner_permission_by_pk?: Maybe<PartnerPermission>,
  delete_payment?: Maybe<PaymentMutationResponse>,
  delete_payment_attribute_global_identifier?: Maybe<PaymentAttributeGlobalIdentifierMutationResponse>,
  delete_payment_attribute_global_identifier_by_pk?: Maybe<PaymentAttributeGlobalIdentifier>,
  delete_payment_attribute_key?: Maybe<PaymentAttributeKeyMutationResponse>,
  delete_payment_attribute_key_by_pk?: Maybe<PaymentAttributeKey>,
  delete_payment_attribute_section?: Maybe<PaymentAttributeSectionMutationResponse>,
  delete_payment_attribute_section_by_pk?: Maybe<PaymentAttributeSection>,
  delete_payment_attribute_subsection?: Maybe<PaymentAttributeSubsectionMutationResponse>,
  delete_payment_attribute_subsection_by_pk?: Maybe<PaymentAttributeSubsection>,
  delete_payment_attribute_value?: Maybe<PaymentAttributeValueMutationResponse>,
  delete_payment_attribute_value_by_pk?: Maybe<PaymentAttributeValue>,
  delete_payment_by_pk?: Maybe<Payment>,
  delete_payment_method?: Maybe<PaymentMethodMutationResponse>,
  delete_payment_method_attribute?: Maybe<PaymentMethodAttributeMutationResponse>,
  delete_payment_method_attribute_by_pk?: Maybe<PaymentMethodAttribute>,
  delete_payment_method_attribute_key?: Maybe<PaymentMethodAttributeKeyMutationResponse>,
  delete_payment_method_attribute_key_by_pk?: Maybe<PaymentMethodAttributeKey>,
  delete_payment_method_attribute_section?: Maybe<PaymentMethodAttributeSectionMutationResponse>,
  delete_payment_method_attribute_section_by_pk?: Maybe<PaymentMethodAttributeSection>,
  delete_payment_method_attribute_subsection?: Maybe<PaymentMethodAttributeSubsectionMutationResponse>,
  delete_payment_method_attribute_subsection_by_pk?: Maybe<PaymentMethodAttributeSubsection>,
  delete_payment_method_by_pk?: Maybe<PaymentMethod>,
  delete_payment_method_logo?: Maybe<PaymentMethodLogoMutationResponse>,
  delete_payment_method_logo_by_pk?: Maybe<PaymentMethodLogo>,
  delete_payment_method_type?: Maybe<PaymentMethodTypeMutationResponse>,
  delete_payment_method_type_attribute?: Maybe<PaymentMethodTypeAttributeMutationResponse>,
  delete_payment_method_type_attribute_by_pk?: Maybe<PaymentMethodTypeAttribute>,
  delete_payment_method_type_attribute_key?: Maybe<PaymentMethodTypeAttributeKeyMutationResponse>,
  delete_payment_method_type_attribute_key_by_pk?: Maybe<PaymentMethodTypeAttributeKey>,
  delete_payment_method_type_attribute_section?: Maybe<PaymentMethodTypeAttributeSectionMutationResponse>,
  delete_payment_method_type_attribute_section_by_pk?: Maybe<PaymentMethodTypeAttributeSection>,
  delete_payment_method_type_attribute_subsection?: Maybe<PaymentMethodTypeAttributeSubsectionMutationResponse>,
  delete_payment_method_type_attribute_subsection_by_pk?: Maybe<PaymentMethodTypeAttributeSubsection>,
  delete_payment_method_type_by_pk?: Maybe<PaymentMethodType>,
  delete_payment_method_type_currency?: Maybe<PaymentMethodTypeCurrencyMutationResponse>,
  delete_payment_method_type_currency_by_pk?: Maybe<PaymentMethodTypeCurrency>,
  delete_payment_orientation?: Maybe<PaymentOrientationMutationResponse>,
  delete_payment_orientation_by_pk?: Maybe<PaymentOrientation>,
  delete_payment_provider?: Maybe<PaymentProviderMutationResponse>,
  delete_payment_provider_by_pk?: Maybe<PaymentProvider>,
  delete_payment_provider_integration_mode?: Maybe<PaymentProviderIntegrationModeMutationResponse>,
  delete_payment_provider_integration_mode_by_pk?: Maybe<PaymentProviderIntegrationMode>,
  delete_payment_provider_method_type?: Maybe<PaymentProviderMethodTypeMutationResponse>,
  delete_payment_provider_method_type_by_pk?: Maybe<PaymentProviderMethodType>,
  delete_payment_status?: Maybe<PaymentStatusMutationResponse>,
  delete_payment_status_by_pk?: Maybe<PaymentStatus>,
  delete_phone_code?: Maybe<PhoneCodeMutationResponse>,
  delete_phone_code_by_pk?: Maybe<PhoneCode>,
  delete_phone_type?: Maybe<PhoneTypeMutationResponse>,
  delete_phone_type_by_pk?: Maybe<PhoneType>,
  delete_platform_access?: Maybe<PlatformAccessMutationResponse>,
  delete_platform_access_by_pk?: Maybe<PlatformAccess>,
  delete_platform_server?: Maybe<PlatformServerMutationResponse>,
  delete_platform_server_by_pk?: Maybe<PlatformServer>,
  delete_platform_server_environment?: Maybe<PlatformServerEnvironmentMutationResponse>,
  delete_platform_server_environment_by_pk?: Maybe<PlatformServerEnvironment>,
  delete_platform_server_retry_status?: Maybe<PlatformServerRetryStatusMutationResponse>,
  delete_platform_server_retry_status_by_pk?: Maybe<PlatformServerRetryStatus>,
  delete_platform_server_status?: Maybe<PlatformServerStatusMutationResponse>,
  delete_platform_server_status_by_pk?: Maybe<PlatformServerStatus>,
  delete_platform_server_type?: Maybe<PlatformServerTypeMutationResponse>,
  delete_platform_server_type_by_pk?: Maybe<PlatformServerType>,
  delete_platform_servers_to_supported_leverages?: Maybe<PlatformServersToSupportedLeveragesMutationResponse>,
  delete_platform_servers_to_supported_leverages_by_pk?: Maybe<PlatformServersToSupportedLeverages>,
  delete_platform_symbol?: Maybe<PlatformSymbolMutationResponse>,
  delete_platform_symbol_by_pk?: Maybe<PlatformSymbol>,
  delete_profile?: Maybe<ProfileMutationResponse>,
  delete_profile_activity?: Maybe<ProfileActivityMutationResponse>,
  delete_profile_activity_by_pk?: Maybe<ProfileActivity>,
  delete_profile_by_pk?: Maybe<Profile>,
  delete_profile_category?: Maybe<ProfileCategoryMutationResponse>,
  delete_profile_category_by_pk?: Maybe<ProfileCategory>,
  delete_profile_entry_type?: Maybe<ProfileEntryTypeMutationResponse>,
  delete_profile_entry_type_by_pk?: Maybe<ProfileEntryType>,
  delete_profile_flags?: Maybe<ProfileFlagsMutationResponse>,
  delete_profile_flags_by_pk?: Maybe<ProfileFlags>,
  delete_profile_note?: Maybe<ProfileNoteMutationResponse>,
  delete_profile_note_by_pk?: Maybe<ProfileNote>,
  delete_profile_note_version?: Maybe<ProfileNoteVersionMutationResponse>,
  delete_profile_note_version_by_pk?: Maybe<ProfileNoteVersion>,
  delete_profile_note_versions_stakeholder_mentions?: Maybe<ProfileNoteVersionsStakeholderMentionsMutationResponse>,
  delete_profile_note_versions_stakeholder_mentions_by_pk?: Maybe<ProfileNoteVersionsStakeholderMentions>,
  delete_profile_note_versions_stakeholder_relevant_parties?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMutationResponse>,
  delete_profile_note_versions_stakeholder_relevant_parties_by_pk?: Maybe<ProfileNoteVersionsStakeholderRelevantParties>,
  delete_profile_risk?: Maybe<ProfileRiskMutationResponse>,
  delete_profile_risk_by_pk?: Maybe<ProfileRisk>,
  delete_profile_status?: Maybe<ProfileStatusMutationResponse>,
  delete_profile_status_by_pk?: Maybe<ProfileStatus>,
  delete_profile_type?: Maybe<ProfileTypeMutationResponse>,
  delete_profile_type_by_pk?: Maybe<ProfileType>,
  delete_profiles_to_tags?: Maybe<ProfilesToTagsMutationResponse>,
  delete_profiles_to_tags_by_pk?: Maybe<ProfilesToTags>,
  delete_representatives_to_profiles?: Maybe<RepresentativesToProfilesMutationResponse>,
  delete_representatives_to_profiles_by_pk?: Maybe<RepresentativesToProfiles>,
  delete_request?: Maybe<RequestMutationResponse>,
  delete_request_by_pk?: Maybe<Request>,
  delete_request_status?: Maybe<RequestStatusMutationResponse>,
  delete_request_status_by_pk?: Maybe<RequestStatus>,
  delete_request_type?: Maybe<RequestTypeMutationResponse>,
  delete_request_type_by_pk?: Maybe<RequestType>,
  delete_security?: Maybe<SecurityMutationResponse>,
  delete_security_by_pk?: Maybe<Security>,
  delete_security_grid_view?: Maybe<SecurityGridViewMutationResponse>,
  delete_segment?: Maybe<SegmentMutationResponse>,
  delete_segment_by_pk?: Maybe<Segment>,
  delete_segment_condition?: Maybe<SegmentConditionMutationResponse>,
  delete_segment_condition_by_pk?: Maybe<SegmentCondition>,
  delete_segment_criterion?: Maybe<SegmentCriterionMutationResponse>,
  delete_segment_criterion_by_pk?: Maybe<SegmentCriterion>,
  delete_segment_criterion_type?: Maybe<SegmentCriterionTypeMutationResponse>,
  delete_segment_criterion_type_by_pk?: Maybe<SegmentCriterionType>,
  delete_segment_operator?: Maybe<SegmentOperatorMutationResponse>,
  delete_segment_operator_by_pk?: Maybe<SegmentOperator>,
  delete_segment_operators_to_segment_property_conditions?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMutationResponse>,
  delete_segment_operators_to_segment_property_conditions_by_pk?: Maybe<SegmentOperatorsToSegmentPropertyConditions>,
  delete_segment_property?: Maybe<SegmentPropertyMutationResponse>,
  delete_segment_property_by_pk?: Maybe<SegmentProperty>,
  delete_segment_property_condition?: Maybe<SegmentPropertyConditionMutationResponse>,
  delete_segment_property_condition_by_pk?: Maybe<SegmentPropertyCondition>,
  delete_source_of_funds?: Maybe<SourceOfFundsMutationResponse>,
  delete_source_of_funds_by_pk?: Maybe<SourceOfFunds>,
  delete_spread_mode?: Maybe<SpreadModeMutationResponse>,
  delete_spread_mode_by_pk?: Maybe<SpreadMode>,
  delete_stake?: Maybe<StakeMutationResponse>,
  delete_stake_ancestry?: Maybe<StakeAncestryMutationResponse>,
  delete_stake_ancestry_by_pk?: Maybe<StakeAncestry>,
  delete_stake_by_pk?: Maybe<Stake>,
  delete_stake_title?: Maybe<StakeTitleMutationResponse>,
  delete_stake_title_by_pk?: Maybe<StakeTitle>,
  delete_stakeholder?: Maybe<StakeholderMutationResponse>,
  delete_stakeholder_attribute?: Maybe<StakeholderAttributeMutationResponse>,
  delete_stakeholder_attribute_by_pk?: Maybe<StakeholderAttribute>,
  delete_stakeholder_attribute_key?: Maybe<StakeholderAttributeKeyMutationResponse>,
  delete_stakeholder_attribute_key_by_pk?: Maybe<StakeholderAttributeKey>,
  delete_stakeholder_attribute_keys_to_stakeholder_classes?: Maybe<StakeholderAttributeKeysToStakeholderClassesMutationResponse>,
  delete_stakeholder_attribute_keys_to_stakeholder_classes_by_pk?: Maybe<StakeholderAttributeKeysToStakeholderClasses>,
  delete_stakeholder_attribute_section?: Maybe<StakeholderAttributeSectionMutationResponse>,
  delete_stakeholder_attribute_section_by_pk?: Maybe<StakeholderAttributeSection>,
  delete_stakeholder_attribute_subsection?: Maybe<StakeholderAttributeSubsectionMutationResponse>,
  delete_stakeholder_attribute_subsection_by_pk?: Maybe<StakeholderAttributeSubsection>,
  delete_stakeholder_by_pk?: Maybe<Stakeholder>,
  delete_stakeholder_class?: Maybe<StakeholderClassMutationResponse>,
  delete_stakeholder_class_by_pk?: Maybe<StakeholderClass>,
  delete_stakes_to_admin_permissions?: Maybe<StakesToAdminPermissionsMutationResponse>,
  delete_stakes_to_admin_permissions_by_pk?: Maybe<StakesToAdminPermissions>,
  delete_stakes_to_stake_titles?: Maybe<StakesToStakeTitlesMutationResponse>,
  delete_stakes_to_stake_titles_by_pk?: Maybe<StakesToStakeTitles>,
  delete_swap_mode?: Maybe<SwapModeMutationResponse>,
  delete_swap_mode_by_pk?: Maybe<SwapMode>,
  delete_symbol?: Maybe<SymbolMutationResponse>,
  delete_symbol_by_pk?: Maybe<Symbol>,
  delete_symbol_extension?: Maybe<SymbolExtensionMutationResponse>,
  delete_symbol_extension_by_pk?: Maybe<SymbolExtension>,
  delete_symbol_type?: Maybe<SymbolTypeMutationResponse>,
  delete_symbol_type_by_pk?: Maybe<SymbolType>,
  delete_tag?: Maybe<TagMutationResponse>,
  delete_tag_by_pk?: Maybe<Tag>,
  delete_trade_rule?: Maybe<TradeRuleMutationResponse>,
  delete_trade_rule_by_pk?: Maybe<TradeRule>,
  delete_transaction?: Maybe<TransactionMutationResponse>,
  delete_transaction_by_pk?: Maybe<Transaction>,
  delete_transaction_creation_request?: Maybe<TransactionCreationRequestMutationResponse>,
  delete_transaction_creation_request_by_pk?: Maybe<TransactionCreationRequest>,
  delete_transaction_platform_status?: Maybe<TransactionPlatformStatusMutationResponse>,
  delete_transaction_platform_status_by_pk?: Maybe<TransactionPlatformStatus>,
  delete_transaction_profile_grid_view?: Maybe<TransactionProfileGridViewMutationResponse>,
  delete_transaction_status?: Maybe<TransactionStatusMutationResponse>,
  delete_transaction_status_by_pk?: Maybe<TransactionStatus>,
  delete_transaction_type?: Maybe<TransactionTypeMutationResponse>,
  delete_transaction_type_by_pk?: Maybe<TransactionType>,
  delete_transfer_approval_option?: Maybe<TransferApprovalOptionMutationResponse>,
  delete_transfer_approval_option_by_pk?: Maybe<TransferApprovalOption>,
  delete_user_access?: Maybe<UserAccessMutationResponse>,
  delete_user_access_by_pk?: Maybe<UserAccess>,
  delete_user_invite?: Maybe<UserInviteMutationResponse>,
  delete_user_invite_by_pk?: Maybe<UserInvite>,
  delete_user_permission?: Maybe<UserPermissionMutationResponse>,
  delete_user_permission_by_pk?: Maybe<UserPermission>,
  delete_workflow?: Maybe<WorkflowMutationResponse>,
  delete_workflow_by_pk?: Maybe<Workflow>,
  delete_workflow_stage?: Maybe<WorkflowStageMutationResponse>,
  delete_workflow_stage_by_pk?: Maybe<WorkflowStage>,
  delete_workflows_to_profiles?: Maybe<WorkflowsToProfilesMutationResponse>,
  delete_workflows_to_profiles_by_pk?: Maybe<WorkflowsToProfiles>,
  delete_years_experience?: Maybe<YearsExperienceMutationResponse>,
  delete_years_experience_by_pk?: Maybe<YearsExperience>,
  insert_access_format?: Maybe<AccessFormatMutationResponse>,
  insert_access_format_one?: Maybe<AccessFormat>,
  insert_access_format_type?: Maybe<AccessFormatTypeMutationResponse>,
  insert_access_format_type_one?: Maybe<AccessFormatType>,
  insert_account?: Maybe<AccountMutationResponse>,
  insert_account_creation_request?: Maybe<AccountCreationRequestMutationResponse>,
  insert_account_creation_request_one?: Maybe<AccountCreationRequest>,
  insert_account_group?: Maybe<AccountGroupMutationResponse>,
  insert_account_group_default?: Maybe<AccountGroupDefaultMutationResponse>,
  insert_account_group_default_one?: Maybe<AccountGroupDefault>,
  insert_account_group_one?: Maybe<AccountGroup>,
  insert_account_group_security?: Maybe<AccountGroupSecurityMutationResponse>,
  insert_account_group_security_one?: Maybe<AccountGroupSecurity>,
  insert_account_groups_to_partners?: Maybe<AccountGroupsToPartnersMutationResponse>,
  insert_account_groups_to_partners_one?: Maybe<AccountGroupsToPartners>,
  insert_account_one?: Maybe<Account>,
  insert_account_type?: Maybe<AccountTypeMutationResponse>,
  insert_account_type_one?: Maybe<AccountType>,
  insert_activities_to_channels?: Maybe<ActivitiesToChannelsMutationResponse>,
  insert_activities_to_channels_one?: Maybe<ActivitiesToChannels>,
  insert_activity?: Maybe<ActivityMutationResponse>,
  insert_activity_category?: Maybe<ActivityCategoryMutationResponse>,
  insert_activity_category_one?: Maybe<ActivityCategory>,
  insert_activity_channel?: Maybe<ActivityChannelMutationResponse>,
  insert_activity_channel_one?: Maybe<ActivityChannel>,
  insert_activity_one?: Maybe<Activity>,
  insert_activity_subject?: Maybe<ActivitySubjectMutationResponse>,
  insert_activity_subject_one?: Maybe<ActivitySubject>,
  insert_activity_template?: Maybe<ActivityTemplateMutationResponse>,
  insert_activity_template_one?: Maybe<ActivityTemplate>,
  insert_admin_permission?: Maybe<AdminPermissionMutationResponse>,
  insert_admin_permission_one?: Maybe<AdminPermission>,
  insert_agreement?: Maybe<AgreementMutationResponse>,
  insert_agreement_one?: Maybe<Agreement>,
  insert_agreement_scenario?: Maybe<AgreementScenarioMutationResponse>,
  insert_agreement_scenario_one?: Maybe<AgreementScenario>,
  insert_agreements_satisfied?: Maybe<AgreementsSatisfiedMutationResponse>,
  insert_agreements_satisfied_one?: Maybe<AgreementsSatisfied>,
  insert_agreements_to_payment_methods?: Maybe<AgreementsToPaymentMethodsMutationResponse>,
  insert_agreements_to_payment_methods_one?: Maybe<AgreementsToPaymentMethods>,
  insert_annual_income?: Maybe<AnnualIncomeMutationResponse>,
  insert_annual_income_one?: Maybe<AnnualIncome>,
  insert_api_key?: Maybe<ApiKeyMutationResponse>,
  insert_api_key_one?: Maybe<ApiKey>,
  insert_attribute_metadata?: Maybe<AttributeMetadataMutationResponse>,
  insert_attribute_metadata_one?: Maybe<AttributeMetadata>,
  insert_attribute_type?: Maybe<AttributeTypeMutationResponse>,
  insert_attribute_type_one?: Maybe<AttributeType>,
  insert_bank_account?: Maybe<BankAccountMutationResponse>,
  insert_bank_account_one?: Maybe<BankAccount>,
  insert_book_type?: Maybe<BookTypeMutationResponse>,
  insert_book_type_one?: Maybe<BookType>,
  insert_charge_unit?: Maybe<ChargeUnitMutationResponse>,
  insert_charge_unit_one?: Maybe<ChargeUnit>,
  insert_countries_to_phone_codes?: Maybe<CountriesToPhoneCodesMutationResponse>,
  insert_countries_to_phone_codes_one?: Maybe<CountriesToPhoneCodes>,
  insert_country?: Maybe<CountryMutationResponse>,
  insert_country_one?: Maybe<Country>,
  insert_currencies_to_organizations?: Maybe<CurrenciesToOrganizationsMutationResponse>,
  insert_currencies_to_organizations_one?: Maybe<CurrenciesToOrganizations>,
  insert_currency?: Maybe<CurrencyMutationResponse>,
  insert_currency_one?: Maybe<Currency>,
  insert_daily_account_revenue_summary?: Maybe<DailyAccountRevenueSummaryMutationResponse>,
  insert_daily_account_revenue_summary_one?: Maybe<DailyAccountRevenueSummary>,
  insert_daily_account_status?: Maybe<DailyAccountStatusMutationResponse>,
  insert_daily_account_status_one?: Maybe<DailyAccountStatus>,
  insert_daily_volume_per_account?: Maybe<DailyVolumePerAccountMutationResponse>,
  insert_daily_volume_per_account_one?: Maybe<DailyVolumePerAccount>,
  insert_daily_volume_per_profile?: Maybe<DailyVolumePerProfileMutationResponse>,
  insert_daily_volume_per_profile_one?: Maybe<DailyVolumePerProfile>,
  insert_employment_status?: Maybe<EmploymentStatusMutationResponse>,
  insert_employment_status_one?: Maybe<EmploymentStatus>,
  insert_experience_type?: Maybe<ExperienceTypeMutationResponse>,
  insert_experience_type_one?: Maybe<ExperienceType>,
  insert_file?: Maybe<FileMutationResponse>,
  insert_file_one?: Maybe<File>,
  insert_file_type?: Maybe<FileTypeMutationResponse>,
  insert_file_type_category?: Maybe<FileTypeCategoryMutationResponse>,
  insert_file_type_category_one?: Maybe<FileTypeCategory>,
  insert_file_type_one?: Maybe<FileType>,
  insert_files_to_organizations?: Maybe<FilesToOrganizationsMutationResponse>,
  insert_files_to_organizations_one?: Maybe<FilesToOrganizations>,
  insert_files_to_stakeholders?: Maybe<FilesToStakeholdersMutationResponse>,
  insert_files_to_stakeholders_one?: Maybe<FilesToStakeholders>,
  insert_gender?: Maybe<GenderMutationResponse>,
  insert_gender_one?: Maybe<Gender>,
  insert_identification_type?: Maybe<IdentificationTypeMutationResponse>,
  insert_identification_type_one?: Maybe<IdentificationType>,
  insert_individual_partner_account_group_default?: Maybe<IndividualPartnerAccountGroupDefaultMutationResponse>,
  insert_individual_partner_account_group_default_one?: Maybe<IndividualPartnerAccountGroupDefault>,
  insert_industry?: Maybe<IndustryMutationResponse>,
  insert_industry_one?: Maybe<Industry>,
  insert_intermediary_bank?: Maybe<IntermediaryBankMutationResponse>,
  insert_intermediary_bank_one?: Maybe<IntermediaryBank>,
  insert_investment_objective?: Maybe<InvestmentObjectiveMutationResponse>,
  insert_investment_objective_one?: Maybe<InvestmentObjective>,
  insert_leverage?: Maybe<LeverageMutationResponse>,
  insert_leverage_one?: Maybe<Leverage>,
  insert_leverages_to_platform_server_types?: Maybe<LeveragesToPlatformServerTypesMutationResponse>,
  insert_leverages_to_platform_server_types_one?: Maybe<LeveragesToPlatformServerTypes>,
  insert_liquid_assets?: Maybe<LiquidAssetsMutationResponse>,
  insert_liquid_assets_one?: Maybe<LiquidAssets>,
  insert_locale?: Maybe<LocaleMutationResponse>,
  insert_locale_one?: Maybe<Locale>,
  insert_locales_to_organizations?: Maybe<LocalesToOrganizationsMutationResponse>,
  insert_locales_to_organizations_one?: Maybe<LocalesToOrganizations>,
  insert_margin_type?: Maybe<MarginTypeMutationResponse>,
  insert_margin_type_one?: Maybe<MarginType>,
  insert_master_admin_client_permission?: Maybe<MasterAdminClientPermissionMutationResponse>,
  insert_master_admin_client_permission_one?: Maybe<MasterAdminClientPermission>,
  insert_master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionMutationResponse>,
  insert_master_admin_lead_permission_one?: Maybe<MasterAdminLeadPermission>,
  insert_master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionMutationResponse>,
  insert_master_admin_partner_permission_one?: Maybe<MasterAdminPartnerPermission>,
  insert_master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionMutationResponse>,
  insert_master_admin_stakeholder_permission_one?: Maybe<MasterAdminStakeholderPermission>,
  insert_master_admin_system_permission?: Maybe<MasterAdminSystemPermissionMutationResponse>,
  insert_master_admin_system_permission_one?: Maybe<MasterAdminSystemPermission>,
  insert_master_partner_client_permission?: Maybe<MasterPartnerClientPermissionMutationResponse>,
  insert_master_partner_client_permission_one?: Maybe<MasterPartnerClientPermission>,
  insert_master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionMutationResponse>,
  insert_master_partner_lead_permission_one?: Maybe<MasterPartnerLeadPermission>,
  insert_master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionMutationResponse>,
  insert_master_partner_partner_permission_one?: Maybe<MasterPartnerPartnerPermission>,
  insert_master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionMutationResponse>,
  insert_master_partner_stakeholder_permission_one?: Maybe<MasterPartnerStakeholderPermission>,
  insert_master_user_permission?: Maybe<MasterUserPermissionMutationResponse>,
  insert_master_user_permission_one?: Maybe<MasterUserPermission>,
  insert_name_title?: Maybe<NameTitleMutationResponse>,
  insert_name_title_one?: Maybe<NameTitle>,
  insert_notification?: Maybe<NotificationMutationResponse>,
  insert_notification_one?: Maybe<Notification>,
  insert_organization?: Maybe<OrganizationMutationResponse>,
  insert_organization_one?: Maybe<Organization>,
  insert_origin?: Maybe<OriginMutationResponse>,
  insert_origin_one?: Maybe<Origin>,
  insert_partner_permission?: Maybe<PartnerPermissionMutationResponse>,
  insert_partner_permission_one?: Maybe<PartnerPermission>,
  insert_payment?: Maybe<PaymentMutationResponse>,
  insert_payment_attribute_global_identifier?: Maybe<PaymentAttributeGlobalIdentifierMutationResponse>,
  insert_payment_attribute_global_identifier_one?: Maybe<PaymentAttributeGlobalIdentifier>,
  insert_payment_attribute_key?: Maybe<PaymentAttributeKeyMutationResponse>,
  insert_payment_attribute_key_one?: Maybe<PaymentAttributeKey>,
  insert_payment_attribute_section?: Maybe<PaymentAttributeSectionMutationResponse>,
  insert_payment_attribute_section_one?: Maybe<PaymentAttributeSection>,
  insert_payment_attribute_subsection?: Maybe<PaymentAttributeSubsectionMutationResponse>,
  insert_payment_attribute_subsection_one?: Maybe<PaymentAttributeSubsection>,
  insert_payment_attribute_value?: Maybe<PaymentAttributeValueMutationResponse>,
  insert_payment_attribute_value_one?: Maybe<PaymentAttributeValue>,
  insert_payment_method?: Maybe<PaymentMethodMutationResponse>,
  insert_payment_method_attribute?: Maybe<PaymentMethodAttributeMutationResponse>,
  insert_payment_method_attribute_key?: Maybe<PaymentMethodAttributeKeyMutationResponse>,
  insert_payment_method_attribute_key_one?: Maybe<PaymentMethodAttributeKey>,
  insert_payment_method_attribute_one?: Maybe<PaymentMethodAttribute>,
  insert_payment_method_attribute_section?: Maybe<PaymentMethodAttributeSectionMutationResponse>,
  insert_payment_method_attribute_section_one?: Maybe<PaymentMethodAttributeSection>,
  insert_payment_method_attribute_subsection?: Maybe<PaymentMethodAttributeSubsectionMutationResponse>,
  insert_payment_method_attribute_subsection_one?: Maybe<PaymentMethodAttributeSubsection>,
  insert_payment_method_logo?: Maybe<PaymentMethodLogoMutationResponse>,
  insert_payment_method_logo_one?: Maybe<PaymentMethodLogo>,
  insert_payment_method_one?: Maybe<PaymentMethod>,
  insert_payment_method_type?: Maybe<PaymentMethodTypeMutationResponse>,
  insert_payment_method_type_attribute?: Maybe<PaymentMethodTypeAttributeMutationResponse>,
  insert_payment_method_type_attribute_key?: Maybe<PaymentMethodTypeAttributeKeyMutationResponse>,
  insert_payment_method_type_attribute_key_one?: Maybe<PaymentMethodTypeAttributeKey>,
  insert_payment_method_type_attribute_one?: Maybe<PaymentMethodTypeAttribute>,
  insert_payment_method_type_attribute_section?: Maybe<PaymentMethodTypeAttributeSectionMutationResponse>,
  insert_payment_method_type_attribute_section_one?: Maybe<PaymentMethodTypeAttributeSection>,
  insert_payment_method_type_attribute_subsection?: Maybe<PaymentMethodTypeAttributeSubsectionMutationResponse>,
  insert_payment_method_type_attribute_subsection_one?: Maybe<PaymentMethodTypeAttributeSubsection>,
  insert_payment_method_type_currency?: Maybe<PaymentMethodTypeCurrencyMutationResponse>,
  insert_payment_method_type_currency_one?: Maybe<PaymentMethodTypeCurrency>,
  insert_payment_method_type_one?: Maybe<PaymentMethodType>,
  insert_payment_one?: Maybe<Payment>,
  insert_payment_orientation?: Maybe<PaymentOrientationMutationResponse>,
  insert_payment_orientation_one?: Maybe<PaymentOrientation>,
  insert_payment_provider?: Maybe<PaymentProviderMutationResponse>,
  insert_payment_provider_integration_mode?: Maybe<PaymentProviderIntegrationModeMutationResponse>,
  insert_payment_provider_integration_mode_one?: Maybe<PaymentProviderIntegrationMode>,
  insert_payment_provider_method_type?: Maybe<PaymentProviderMethodTypeMutationResponse>,
  insert_payment_provider_method_type_one?: Maybe<PaymentProviderMethodType>,
  insert_payment_provider_one?: Maybe<PaymentProvider>,
  insert_payment_status?: Maybe<PaymentStatusMutationResponse>,
  insert_payment_status_one?: Maybe<PaymentStatus>,
  insert_phone_code?: Maybe<PhoneCodeMutationResponse>,
  insert_phone_code_one?: Maybe<PhoneCode>,
  insert_phone_type?: Maybe<PhoneTypeMutationResponse>,
  insert_phone_type_one?: Maybe<PhoneType>,
  insert_platform_access?: Maybe<PlatformAccessMutationResponse>,
  insert_platform_access_one?: Maybe<PlatformAccess>,
  insert_platform_server?: Maybe<PlatformServerMutationResponse>,
  insert_platform_server_environment?: Maybe<PlatformServerEnvironmentMutationResponse>,
  insert_platform_server_environment_one?: Maybe<PlatformServerEnvironment>,
  insert_platform_server_one?: Maybe<PlatformServer>,
  insert_platform_server_retry_status?: Maybe<PlatformServerRetryStatusMutationResponse>,
  insert_platform_server_retry_status_one?: Maybe<PlatformServerRetryStatus>,
  insert_platform_server_status?: Maybe<PlatformServerStatusMutationResponse>,
  insert_platform_server_status_one?: Maybe<PlatformServerStatus>,
  insert_platform_server_type?: Maybe<PlatformServerTypeMutationResponse>,
  insert_platform_server_type_one?: Maybe<PlatformServerType>,
  insert_platform_servers_to_supported_leverages?: Maybe<PlatformServersToSupportedLeveragesMutationResponse>,
  insert_platform_servers_to_supported_leverages_one?: Maybe<PlatformServersToSupportedLeverages>,
  insert_platform_symbol?: Maybe<PlatformSymbolMutationResponse>,
  insert_platform_symbol_one?: Maybe<PlatformSymbol>,
  insert_profile?: Maybe<ProfileMutationResponse>,
  insert_profile_activity?: Maybe<ProfileActivityMutationResponse>,
  insert_profile_activity_one?: Maybe<ProfileActivity>,
  insert_profile_category?: Maybe<ProfileCategoryMutationResponse>,
  insert_profile_category_one?: Maybe<ProfileCategory>,
  insert_profile_entry_type?: Maybe<ProfileEntryTypeMutationResponse>,
  insert_profile_entry_type_one?: Maybe<ProfileEntryType>,
  insert_profile_flags?: Maybe<ProfileFlagsMutationResponse>,
  insert_profile_flags_one?: Maybe<ProfileFlags>,
  insert_profile_note?: Maybe<ProfileNoteMutationResponse>,
  insert_profile_note_one?: Maybe<ProfileNote>,
  insert_profile_note_version?: Maybe<ProfileNoteVersionMutationResponse>,
  insert_profile_note_version_one?: Maybe<ProfileNoteVersion>,
  insert_profile_note_versions_stakeholder_mentions?: Maybe<ProfileNoteVersionsStakeholderMentionsMutationResponse>,
  insert_profile_note_versions_stakeholder_mentions_one?: Maybe<ProfileNoteVersionsStakeholderMentions>,
  insert_profile_note_versions_stakeholder_relevant_parties?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMutationResponse>,
  insert_profile_note_versions_stakeholder_relevant_parties_one?: Maybe<ProfileNoteVersionsStakeholderRelevantParties>,
  insert_profile_one?: Maybe<Profile>,
  insert_profile_risk?: Maybe<ProfileRiskMutationResponse>,
  insert_profile_risk_one?: Maybe<ProfileRisk>,
  insert_profile_status?: Maybe<ProfileStatusMutationResponse>,
  insert_profile_status_one?: Maybe<ProfileStatus>,
  insert_profile_type?: Maybe<ProfileTypeMutationResponse>,
  insert_profile_type_one?: Maybe<ProfileType>,
  insert_profiles_to_tags?: Maybe<ProfilesToTagsMutationResponse>,
  insert_profiles_to_tags_one?: Maybe<ProfilesToTags>,
  insert_representatives_to_profiles?: Maybe<RepresentativesToProfilesMutationResponse>,
  insert_representatives_to_profiles_one?: Maybe<RepresentativesToProfiles>,
  insert_request?: Maybe<RequestMutationResponse>,
  insert_request_one?: Maybe<Request>,
  insert_request_status?: Maybe<RequestStatusMutationResponse>,
  insert_request_status_one?: Maybe<RequestStatus>,
  insert_request_type?: Maybe<RequestTypeMutationResponse>,
  insert_request_type_one?: Maybe<RequestType>,
  insert_security?: Maybe<SecurityMutationResponse>,
  insert_security_grid_view?: Maybe<SecurityGridViewMutationResponse>,
  insert_security_grid_view_one?: Maybe<SecurityGridView>,
  insert_security_one?: Maybe<Security>,
  insert_segment?: Maybe<SegmentMutationResponse>,
  insert_segment_condition?: Maybe<SegmentConditionMutationResponse>,
  insert_segment_condition_one?: Maybe<SegmentCondition>,
  insert_segment_criterion?: Maybe<SegmentCriterionMutationResponse>,
  insert_segment_criterion_one?: Maybe<SegmentCriterion>,
  insert_segment_criterion_type?: Maybe<SegmentCriterionTypeMutationResponse>,
  insert_segment_criterion_type_one?: Maybe<SegmentCriterionType>,
  insert_segment_one?: Maybe<Segment>,
  insert_segment_operator?: Maybe<SegmentOperatorMutationResponse>,
  insert_segment_operator_one?: Maybe<SegmentOperator>,
  insert_segment_operators_to_segment_property_conditions?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMutationResponse>,
  insert_segment_operators_to_segment_property_conditions_one?: Maybe<SegmentOperatorsToSegmentPropertyConditions>,
  insert_segment_property?: Maybe<SegmentPropertyMutationResponse>,
  insert_segment_property_condition?: Maybe<SegmentPropertyConditionMutationResponse>,
  insert_segment_property_condition_one?: Maybe<SegmentPropertyCondition>,
  insert_segment_property_one?: Maybe<SegmentProperty>,
  insert_source_of_funds?: Maybe<SourceOfFundsMutationResponse>,
  insert_source_of_funds_one?: Maybe<SourceOfFunds>,
  insert_spread_mode?: Maybe<SpreadModeMutationResponse>,
  insert_spread_mode_one?: Maybe<SpreadMode>,
  insert_stake?: Maybe<StakeMutationResponse>,
  insert_stake_ancestry?: Maybe<StakeAncestryMutationResponse>,
  insert_stake_ancestry_one?: Maybe<StakeAncestry>,
  insert_stake_one?: Maybe<Stake>,
  insert_stake_title?: Maybe<StakeTitleMutationResponse>,
  insert_stake_title_one?: Maybe<StakeTitle>,
  insert_stakeholder?: Maybe<StakeholderMutationResponse>,
  insert_stakeholder_attribute?: Maybe<StakeholderAttributeMutationResponse>,
  insert_stakeholder_attribute_key?: Maybe<StakeholderAttributeKeyMutationResponse>,
  insert_stakeholder_attribute_key_one?: Maybe<StakeholderAttributeKey>,
  insert_stakeholder_attribute_keys_to_stakeholder_classes?: Maybe<StakeholderAttributeKeysToStakeholderClassesMutationResponse>,
  insert_stakeholder_attribute_keys_to_stakeholder_classes_one?: Maybe<StakeholderAttributeKeysToStakeholderClasses>,
  insert_stakeholder_attribute_one?: Maybe<StakeholderAttribute>,
  insert_stakeholder_attribute_section?: Maybe<StakeholderAttributeSectionMutationResponse>,
  insert_stakeholder_attribute_section_one?: Maybe<StakeholderAttributeSection>,
  insert_stakeholder_attribute_subsection?: Maybe<StakeholderAttributeSubsectionMutationResponse>,
  insert_stakeholder_attribute_subsection_one?: Maybe<StakeholderAttributeSubsection>,
  insert_stakeholder_class?: Maybe<StakeholderClassMutationResponse>,
  insert_stakeholder_class_one?: Maybe<StakeholderClass>,
  insert_stakeholder_one?: Maybe<Stakeholder>,
  insert_stakes_to_admin_permissions?: Maybe<StakesToAdminPermissionsMutationResponse>,
  insert_stakes_to_admin_permissions_one?: Maybe<StakesToAdminPermissions>,
  insert_stakes_to_stake_titles?: Maybe<StakesToStakeTitlesMutationResponse>,
  insert_stakes_to_stake_titles_one?: Maybe<StakesToStakeTitles>,
  insert_swap_mode?: Maybe<SwapModeMutationResponse>,
  insert_swap_mode_one?: Maybe<SwapMode>,
  insert_symbol?: Maybe<SymbolMutationResponse>,
  insert_symbol_extension?: Maybe<SymbolExtensionMutationResponse>,
  insert_symbol_extension_one?: Maybe<SymbolExtension>,
  insert_symbol_one?: Maybe<Symbol>,
  insert_symbol_type?: Maybe<SymbolTypeMutationResponse>,
  insert_symbol_type_one?: Maybe<SymbolType>,
  insert_tag?: Maybe<TagMutationResponse>,
  insert_tag_one?: Maybe<Tag>,
  insert_trade_rule?: Maybe<TradeRuleMutationResponse>,
  insert_trade_rule_one?: Maybe<TradeRule>,
  insert_transaction?: Maybe<TransactionMutationResponse>,
  insert_transaction_creation_request?: Maybe<TransactionCreationRequestMutationResponse>,
  insert_transaction_creation_request_one?: Maybe<TransactionCreationRequest>,
  insert_transaction_one?: Maybe<Transaction>,
  insert_transaction_platform_status?: Maybe<TransactionPlatformStatusMutationResponse>,
  insert_transaction_platform_status_one?: Maybe<TransactionPlatformStatus>,
  insert_transaction_profile_grid_view?: Maybe<TransactionProfileGridViewMutationResponse>,
  insert_transaction_profile_grid_view_one?: Maybe<TransactionProfileGridView>,
  insert_transaction_status?: Maybe<TransactionStatusMutationResponse>,
  insert_transaction_status_one?: Maybe<TransactionStatus>,
  insert_transaction_type?: Maybe<TransactionTypeMutationResponse>,
  insert_transaction_type_one?: Maybe<TransactionType>,
  insert_transfer_approval_option?: Maybe<TransferApprovalOptionMutationResponse>,
  insert_transfer_approval_option_one?: Maybe<TransferApprovalOption>,
  insert_user_access?: Maybe<UserAccessMutationResponse>,
  insert_user_access_one?: Maybe<UserAccess>,
  insert_user_invite?: Maybe<UserInviteMutationResponse>,
  insert_user_invite_one?: Maybe<UserInvite>,
  insert_user_permission?: Maybe<UserPermissionMutationResponse>,
  insert_user_permission_one?: Maybe<UserPermission>,
  insert_workflow?: Maybe<WorkflowMutationResponse>,
  insert_workflow_one?: Maybe<Workflow>,
  insert_workflow_stage?: Maybe<WorkflowStageMutationResponse>,
  insert_workflow_stage_one?: Maybe<WorkflowStage>,
  insert_workflows_to_profiles?: Maybe<WorkflowsToProfilesMutationResponse>,
  insert_workflows_to_profiles_one?: Maybe<WorkflowsToProfiles>,
  insert_years_experience?: Maybe<YearsExperienceMutationResponse>,
  insert_years_experience_one?: Maybe<YearsExperience>,
  update_access_format?: Maybe<AccessFormatMutationResponse>,
  update_access_format_by_pk?: Maybe<AccessFormat>,
  update_access_format_type?: Maybe<AccessFormatTypeMutationResponse>,
  update_access_format_type_by_pk?: Maybe<AccessFormatType>,
  update_account?: Maybe<AccountMutationResponse>,
  update_account_by_pk?: Maybe<Account>,
  update_account_creation_request?: Maybe<AccountCreationRequestMutationResponse>,
  update_account_creation_request_by_pk?: Maybe<AccountCreationRequest>,
  update_account_group?: Maybe<AccountGroupMutationResponse>,
  update_account_group_by_pk?: Maybe<AccountGroup>,
  update_account_group_default?: Maybe<AccountGroupDefaultMutationResponse>,
  update_account_group_default_by_pk?: Maybe<AccountGroupDefault>,
  update_account_group_security?: Maybe<AccountGroupSecurityMutationResponse>,
  update_account_group_security_by_pk?: Maybe<AccountGroupSecurity>,
  update_account_groups_to_partners?: Maybe<AccountGroupsToPartnersMutationResponse>,
  update_account_groups_to_partners_by_pk?: Maybe<AccountGroupsToPartners>,
  update_account_type?: Maybe<AccountTypeMutationResponse>,
  update_account_type_by_pk?: Maybe<AccountType>,
  update_activities_to_channels?: Maybe<ActivitiesToChannelsMutationResponse>,
  update_activities_to_channels_by_pk?: Maybe<ActivitiesToChannels>,
  update_activity?: Maybe<ActivityMutationResponse>,
  update_activity_by_pk?: Maybe<Activity>,
  update_activity_category?: Maybe<ActivityCategoryMutationResponse>,
  update_activity_category_by_pk?: Maybe<ActivityCategory>,
  update_activity_channel?: Maybe<ActivityChannelMutationResponse>,
  update_activity_channel_by_pk?: Maybe<ActivityChannel>,
  update_activity_subject?: Maybe<ActivitySubjectMutationResponse>,
  update_activity_subject_by_pk?: Maybe<ActivitySubject>,
  update_activity_template?: Maybe<ActivityTemplateMutationResponse>,
  update_activity_template_by_pk?: Maybe<ActivityTemplate>,
  update_admin_permission?: Maybe<AdminPermissionMutationResponse>,
  update_admin_permission_by_pk?: Maybe<AdminPermission>,
  update_agreement?: Maybe<AgreementMutationResponse>,
  update_agreement_by_pk?: Maybe<Agreement>,
  update_agreement_scenario?: Maybe<AgreementScenarioMutationResponse>,
  update_agreement_scenario_by_pk?: Maybe<AgreementScenario>,
  update_agreements_satisfied?: Maybe<AgreementsSatisfiedMutationResponse>,
  update_agreements_satisfied_by_pk?: Maybe<AgreementsSatisfied>,
  update_agreements_to_payment_methods?: Maybe<AgreementsToPaymentMethodsMutationResponse>,
  update_agreements_to_payment_methods_by_pk?: Maybe<AgreementsToPaymentMethods>,
  update_annual_income?: Maybe<AnnualIncomeMutationResponse>,
  update_annual_income_by_pk?: Maybe<AnnualIncome>,
  update_api_key?: Maybe<ApiKeyMutationResponse>,
  update_api_key_by_pk?: Maybe<ApiKey>,
  update_attribute_metadata?: Maybe<AttributeMetadataMutationResponse>,
  update_attribute_metadata_by_pk?: Maybe<AttributeMetadata>,
  update_attribute_type?: Maybe<AttributeTypeMutationResponse>,
  update_attribute_type_by_pk?: Maybe<AttributeType>,
  update_bank_account?: Maybe<BankAccountMutationResponse>,
  update_bank_account_by_pk?: Maybe<BankAccount>,
  update_book_type?: Maybe<BookTypeMutationResponse>,
  update_book_type_by_pk?: Maybe<BookType>,
  update_charge_unit?: Maybe<ChargeUnitMutationResponse>,
  update_charge_unit_by_pk?: Maybe<ChargeUnit>,
  update_countries_to_phone_codes?: Maybe<CountriesToPhoneCodesMutationResponse>,
  update_countries_to_phone_codes_by_pk?: Maybe<CountriesToPhoneCodes>,
  update_country?: Maybe<CountryMutationResponse>,
  update_country_by_pk?: Maybe<Country>,
  update_currencies_to_organizations?: Maybe<CurrenciesToOrganizationsMutationResponse>,
  update_currencies_to_organizations_by_pk?: Maybe<CurrenciesToOrganizations>,
  update_currency?: Maybe<CurrencyMutationResponse>,
  update_currency_by_pk?: Maybe<Currency>,
  update_daily_account_revenue_summary?: Maybe<DailyAccountRevenueSummaryMutationResponse>,
  update_daily_account_revenue_summary_by_pk?: Maybe<DailyAccountRevenueSummary>,
  update_daily_account_status?: Maybe<DailyAccountStatusMutationResponse>,
  update_daily_account_status_by_pk?: Maybe<DailyAccountStatus>,
  update_daily_volume_per_account?: Maybe<DailyVolumePerAccountMutationResponse>,
  update_daily_volume_per_account_by_pk?: Maybe<DailyVolumePerAccount>,
  update_daily_volume_per_profile?: Maybe<DailyVolumePerProfileMutationResponse>,
  update_daily_volume_per_profile_by_pk?: Maybe<DailyVolumePerProfile>,
  update_employment_status?: Maybe<EmploymentStatusMutationResponse>,
  update_employment_status_by_pk?: Maybe<EmploymentStatus>,
  update_experience_type?: Maybe<ExperienceTypeMutationResponse>,
  update_experience_type_by_pk?: Maybe<ExperienceType>,
  update_file?: Maybe<FileMutationResponse>,
  update_file_by_pk?: Maybe<File>,
  update_file_type?: Maybe<FileTypeMutationResponse>,
  update_file_type_by_pk?: Maybe<FileType>,
  update_file_type_category?: Maybe<FileTypeCategoryMutationResponse>,
  update_file_type_category_by_pk?: Maybe<FileTypeCategory>,
  update_files_to_organizations?: Maybe<FilesToOrganizationsMutationResponse>,
  update_files_to_organizations_by_pk?: Maybe<FilesToOrganizations>,
  update_files_to_stakeholders?: Maybe<FilesToStakeholdersMutationResponse>,
  update_files_to_stakeholders_by_pk?: Maybe<FilesToStakeholders>,
  update_gender?: Maybe<GenderMutationResponse>,
  update_gender_by_pk?: Maybe<Gender>,
  update_identification_type?: Maybe<IdentificationTypeMutationResponse>,
  update_identification_type_by_pk?: Maybe<IdentificationType>,
  update_individual_partner_account_group_default?: Maybe<IndividualPartnerAccountGroupDefaultMutationResponse>,
  update_individual_partner_account_group_default_by_pk?: Maybe<IndividualPartnerAccountGroupDefault>,
  update_industry?: Maybe<IndustryMutationResponse>,
  update_industry_by_pk?: Maybe<Industry>,
  update_intermediary_bank?: Maybe<IntermediaryBankMutationResponse>,
  update_intermediary_bank_by_pk?: Maybe<IntermediaryBank>,
  update_investment_objective?: Maybe<InvestmentObjectiveMutationResponse>,
  update_investment_objective_by_pk?: Maybe<InvestmentObjective>,
  update_leverage?: Maybe<LeverageMutationResponse>,
  update_leverage_by_pk?: Maybe<Leverage>,
  update_leverages_to_platform_server_types?: Maybe<LeveragesToPlatformServerTypesMutationResponse>,
  update_leverages_to_platform_server_types_by_pk?: Maybe<LeveragesToPlatformServerTypes>,
  update_liquid_assets?: Maybe<LiquidAssetsMutationResponse>,
  update_liquid_assets_by_pk?: Maybe<LiquidAssets>,
  update_locale?: Maybe<LocaleMutationResponse>,
  update_locale_by_pk?: Maybe<Locale>,
  update_locales_to_organizations?: Maybe<LocalesToOrganizationsMutationResponse>,
  update_locales_to_organizations_by_pk?: Maybe<LocalesToOrganizations>,
  update_margin_type?: Maybe<MarginTypeMutationResponse>,
  update_margin_type_by_pk?: Maybe<MarginType>,
  update_master_admin_client_permission?: Maybe<MasterAdminClientPermissionMutationResponse>,
  update_master_admin_client_permission_by_pk?: Maybe<MasterAdminClientPermission>,
  update_master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionMutationResponse>,
  update_master_admin_lead_permission_by_pk?: Maybe<MasterAdminLeadPermission>,
  update_master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionMutationResponse>,
  update_master_admin_partner_permission_by_pk?: Maybe<MasterAdminPartnerPermission>,
  update_master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionMutationResponse>,
  update_master_admin_stakeholder_permission_by_pk?: Maybe<MasterAdminStakeholderPermission>,
  update_master_admin_system_permission?: Maybe<MasterAdminSystemPermissionMutationResponse>,
  update_master_admin_system_permission_by_pk?: Maybe<MasterAdminSystemPermission>,
  update_master_partner_client_permission?: Maybe<MasterPartnerClientPermissionMutationResponse>,
  update_master_partner_client_permission_by_pk?: Maybe<MasterPartnerClientPermission>,
  update_master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionMutationResponse>,
  update_master_partner_lead_permission_by_pk?: Maybe<MasterPartnerLeadPermission>,
  update_master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionMutationResponse>,
  update_master_partner_partner_permission_by_pk?: Maybe<MasterPartnerPartnerPermission>,
  update_master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionMutationResponse>,
  update_master_partner_stakeholder_permission_by_pk?: Maybe<MasterPartnerStakeholderPermission>,
  update_master_user_permission?: Maybe<MasterUserPermissionMutationResponse>,
  update_master_user_permission_by_pk?: Maybe<MasterUserPermission>,
  update_name_title?: Maybe<NameTitleMutationResponse>,
  update_name_title_by_pk?: Maybe<NameTitle>,
  update_notification?: Maybe<NotificationMutationResponse>,
  update_notification_by_pk?: Maybe<Notification>,
  update_organization?: Maybe<OrganizationMutationResponse>,
  update_organization_by_pk?: Maybe<Organization>,
  update_origin?: Maybe<OriginMutationResponse>,
  update_origin_by_pk?: Maybe<Origin>,
  update_partner_permission?: Maybe<PartnerPermissionMutationResponse>,
  update_partner_permission_by_pk?: Maybe<PartnerPermission>,
  update_payment?: Maybe<PaymentMutationResponse>,
  update_payment_attribute_global_identifier?: Maybe<PaymentAttributeGlobalIdentifierMutationResponse>,
  update_payment_attribute_global_identifier_by_pk?: Maybe<PaymentAttributeGlobalIdentifier>,
  update_payment_attribute_key?: Maybe<PaymentAttributeKeyMutationResponse>,
  update_payment_attribute_key_by_pk?: Maybe<PaymentAttributeKey>,
  update_payment_attribute_section?: Maybe<PaymentAttributeSectionMutationResponse>,
  update_payment_attribute_section_by_pk?: Maybe<PaymentAttributeSection>,
  update_payment_attribute_subsection?: Maybe<PaymentAttributeSubsectionMutationResponse>,
  update_payment_attribute_subsection_by_pk?: Maybe<PaymentAttributeSubsection>,
  update_payment_attribute_value?: Maybe<PaymentAttributeValueMutationResponse>,
  update_payment_attribute_value_by_pk?: Maybe<PaymentAttributeValue>,
  update_payment_by_pk?: Maybe<Payment>,
  update_payment_method?: Maybe<PaymentMethodMutationResponse>,
  update_payment_method_attribute?: Maybe<PaymentMethodAttributeMutationResponse>,
  update_payment_method_attribute_by_pk?: Maybe<PaymentMethodAttribute>,
  update_payment_method_attribute_key?: Maybe<PaymentMethodAttributeKeyMutationResponse>,
  update_payment_method_attribute_key_by_pk?: Maybe<PaymentMethodAttributeKey>,
  update_payment_method_attribute_section?: Maybe<PaymentMethodAttributeSectionMutationResponse>,
  update_payment_method_attribute_section_by_pk?: Maybe<PaymentMethodAttributeSection>,
  update_payment_method_attribute_subsection?: Maybe<PaymentMethodAttributeSubsectionMutationResponse>,
  update_payment_method_attribute_subsection_by_pk?: Maybe<PaymentMethodAttributeSubsection>,
  update_payment_method_by_pk?: Maybe<PaymentMethod>,
  update_payment_method_logo?: Maybe<PaymentMethodLogoMutationResponse>,
  update_payment_method_logo_by_pk?: Maybe<PaymentMethodLogo>,
  update_payment_method_type?: Maybe<PaymentMethodTypeMutationResponse>,
  update_payment_method_type_attribute?: Maybe<PaymentMethodTypeAttributeMutationResponse>,
  update_payment_method_type_attribute_by_pk?: Maybe<PaymentMethodTypeAttribute>,
  update_payment_method_type_attribute_key?: Maybe<PaymentMethodTypeAttributeKeyMutationResponse>,
  update_payment_method_type_attribute_key_by_pk?: Maybe<PaymentMethodTypeAttributeKey>,
  update_payment_method_type_attribute_section?: Maybe<PaymentMethodTypeAttributeSectionMutationResponse>,
  update_payment_method_type_attribute_section_by_pk?: Maybe<PaymentMethodTypeAttributeSection>,
  update_payment_method_type_attribute_subsection?: Maybe<PaymentMethodTypeAttributeSubsectionMutationResponse>,
  update_payment_method_type_attribute_subsection_by_pk?: Maybe<PaymentMethodTypeAttributeSubsection>,
  update_payment_method_type_by_pk?: Maybe<PaymentMethodType>,
  update_payment_method_type_currency?: Maybe<PaymentMethodTypeCurrencyMutationResponse>,
  update_payment_method_type_currency_by_pk?: Maybe<PaymentMethodTypeCurrency>,
  update_payment_orientation?: Maybe<PaymentOrientationMutationResponse>,
  update_payment_orientation_by_pk?: Maybe<PaymentOrientation>,
  update_payment_provider?: Maybe<PaymentProviderMutationResponse>,
  update_payment_provider_by_pk?: Maybe<PaymentProvider>,
  update_payment_provider_integration_mode?: Maybe<PaymentProviderIntegrationModeMutationResponse>,
  update_payment_provider_integration_mode_by_pk?: Maybe<PaymentProviderIntegrationMode>,
  update_payment_provider_method_type?: Maybe<PaymentProviderMethodTypeMutationResponse>,
  update_payment_provider_method_type_by_pk?: Maybe<PaymentProviderMethodType>,
  update_payment_status?: Maybe<PaymentStatusMutationResponse>,
  update_payment_status_by_pk?: Maybe<PaymentStatus>,
  update_phone_code?: Maybe<PhoneCodeMutationResponse>,
  update_phone_code_by_pk?: Maybe<PhoneCode>,
  update_phone_type?: Maybe<PhoneTypeMutationResponse>,
  update_phone_type_by_pk?: Maybe<PhoneType>,
  update_platform_access?: Maybe<PlatformAccessMutationResponse>,
  update_platform_access_by_pk?: Maybe<PlatformAccess>,
  update_platform_server?: Maybe<PlatformServerMutationResponse>,
  update_platform_server_by_pk?: Maybe<PlatformServer>,
  update_platform_server_environment?: Maybe<PlatformServerEnvironmentMutationResponse>,
  update_platform_server_environment_by_pk?: Maybe<PlatformServerEnvironment>,
  update_platform_server_retry_status?: Maybe<PlatformServerRetryStatusMutationResponse>,
  update_platform_server_retry_status_by_pk?: Maybe<PlatformServerRetryStatus>,
  update_platform_server_status?: Maybe<PlatformServerStatusMutationResponse>,
  update_platform_server_status_by_pk?: Maybe<PlatformServerStatus>,
  update_platform_server_type?: Maybe<PlatformServerTypeMutationResponse>,
  update_platform_server_type_by_pk?: Maybe<PlatformServerType>,
  update_platform_servers_to_supported_leverages?: Maybe<PlatformServersToSupportedLeveragesMutationResponse>,
  update_platform_servers_to_supported_leverages_by_pk?: Maybe<PlatformServersToSupportedLeverages>,
  update_platform_symbol?: Maybe<PlatformSymbolMutationResponse>,
  update_platform_symbol_by_pk?: Maybe<PlatformSymbol>,
  update_profile?: Maybe<ProfileMutationResponse>,
  update_profile_activity?: Maybe<ProfileActivityMutationResponse>,
  update_profile_activity_by_pk?: Maybe<ProfileActivity>,
  update_profile_by_pk?: Maybe<Profile>,
  update_profile_category?: Maybe<ProfileCategoryMutationResponse>,
  update_profile_category_by_pk?: Maybe<ProfileCategory>,
  update_profile_entry_type?: Maybe<ProfileEntryTypeMutationResponse>,
  update_profile_entry_type_by_pk?: Maybe<ProfileEntryType>,
  update_profile_flags?: Maybe<ProfileFlagsMutationResponse>,
  update_profile_flags_by_pk?: Maybe<ProfileFlags>,
  update_profile_note?: Maybe<ProfileNoteMutationResponse>,
  update_profile_note_by_pk?: Maybe<ProfileNote>,
  update_profile_note_version?: Maybe<ProfileNoteVersionMutationResponse>,
  update_profile_note_version_by_pk?: Maybe<ProfileNoteVersion>,
  update_profile_note_versions_stakeholder_mentions?: Maybe<ProfileNoteVersionsStakeholderMentionsMutationResponse>,
  update_profile_note_versions_stakeholder_mentions_by_pk?: Maybe<ProfileNoteVersionsStakeholderMentions>,
  update_profile_note_versions_stakeholder_relevant_parties?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMutationResponse>,
  update_profile_note_versions_stakeholder_relevant_parties_by_pk?: Maybe<ProfileNoteVersionsStakeholderRelevantParties>,
  update_profile_risk?: Maybe<ProfileRiskMutationResponse>,
  update_profile_risk_by_pk?: Maybe<ProfileRisk>,
  update_profile_status?: Maybe<ProfileStatusMutationResponse>,
  update_profile_status_by_pk?: Maybe<ProfileStatus>,
  update_profile_type?: Maybe<ProfileTypeMutationResponse>,
  update_profile_type_by_pk?: Maybe<ProfileType>,
  update_profiles_to_tags?: Maybe<ProfilesToTagsMutationResponse>,
  update_profiles_to_tags_by_pk?: Maybe<ProfilesToTags>,
  update_representatives_to_profiles?: Maybe<RepresentativesToProfilesMutationResponse>,
  update_representatives_to_profiles_by_pk?: Maybe<RepresentativesToProfiles>,
  update_request?: Maybe<RequestMutationResponse>,
  update_request_by_pk?: Maybe<Request>,
  update_request_status?: Maybe<RequestStatusMutationResponse>,
  update_request_status_by_pk?: Maybe<RequestStatus>,
  update_request_type?: Maybe<RequestTypeMutationResponse>,
  update_request_type_by_pk?: Maybe<RequestType>,
  update_security?: Maybe<SecurityMutationResponse>,
  update_security_by_pk?: Maybe<Security>,
  update_security_grid_view?: Maybe<SecurityGridViewMutationResponse>,
  update_segment?: Maybe<SegmentMutationResponse>,
  update_segment_by_pk?: Maybe<Segment>,
  update_segment_condition?: Maybe<SegmentConditionMutationResponse>,
  update_segment_condition_by_pk?: Maybe<SegmentCondition>,
  update_segment_criterion?: Maybe<SegmentCriterionMutationResponse>,
  update_segment_criterion_by_pk?: Maybe<SegmentCriterion>,
  update_segment_criterion_type?: Maybe<SegmentCriterionTypeMutationResponse>,
  update_segment_criterion_type_by_pk?: Maybe<SegmentCriterionType>,
  update_segment_operator?: Maybe<SegmentOperatorMutationResponse>,
  update_segment_operator_by_pk?: Maybe<SegmentOperator>,
  update_segment_operators_to_segment_property_conditions?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMutationResponse>,
  update_segment_operators_to_segment_property_conditions_by_pk?: Maybe<SegmentOperatorsToSegmentPropertyConditions>,
  update_segment_property?: Maybe<SegmentPropertyMutationResponse>,
  update_segment_property_by_pk?: Maybe<SegmentProperty>,
  update_segment_property_condition?: Maybe<SegmentPropertyConditionMutationResponse>,
  update_segment_property_condition_by_pk?: Maybe<SegmentPropertyCondition>,
  update_source_of_funds?: Maybe<SourceOfFundsMutationResponse>,
  update_source_of_funds_by_pk?: Maybe<SourceOfFunds>,
  update_spread_mode?: Maybe<SpreadModeMutationResponse>,
  update_spread_mode_by_pk?: Maybe<SpreadMode>,
  update_stake?: Maybe<StakeMutationResponse>,
  update_stake_ancestry?: Maybe<StakeAncestryMutationResponse>,
  update_stake_ancestry_by_pk?: Maybe<StakeAncestry>,
  update_stake_by_pk?: Maybe<Stake>,
  update_stake_title?: Maybe<StakeTitleMutationResponse>,
  update_stake_title_by_pk?: Maybe<StakeTitle>,
  update_stakeholder?: Maybe<StakeholderMutationResponse>,
  update_stakeholder_attribute?: Maybe<StakeholderAttributeMutationResponse>,
  update_stakeholder_attribute_by_pk?: Maybe<StakeholderAttribute>,
  update_stakeholder_attribute_key?: Maybe<StakeholderAttributeKeyMutationResponse>,
  update_stakeholder_attribute_key_by_pk?: Maybe<StakeholderAttributeKey>,
  update_stakeholder_attribute_keys_to_stakeholder_classes?: Maybe<StakeholderAttributeKeysToStakeholderClassesMutationResponse>,
  update_stakeholder_attribute_keys_to_stakeholder_classes_by_pk?: Maybe<StakeholderAttributeKeysToStakeholderClasses>,
  update_stakeholder_attribute_section?: Maybe<StakeholderAttributeSectionMutationResponse>,
  update_stakeholder_attribute_section_by_pk?: Maybe<StakeholderAttributeSection>,
  update_stakeholder_attribute_subsection?: Maybe<StakeholderAttributeSubsectionMutationResponse>,
  update_stakeholder_attribute_subsection_by_pk?: Maybe<StakeholderAttributeSubsection>,
  update_stakeholder_by_pk?: Maybe<Stakeholder>,
  update_stakeholder_class?: Maybe<StakeholderClassMutationResponse>,
  update_stakeholder_class_by_pk?: Maybe<StakeholderClass>,
  update_stakes_to_admin_permissions?: Maybe<StakesToAdminPermissionsMutationResponse>,
  update_stakes_to_admin_permissions_by_pk?: Maybe<StakesToAdminPermissions>,
  update_stakes_to_stake_titles?: Maybe<StakesToStakeTitlesMutationResponse>,
  update_stakes_to_stake_titles_by_pk?: Maybe<StakesToStakeTitles>,
  update_swap_mode?: Maybe<SwapModeMutationResponse>,
  update_swap_mode_by_pk?: Maybe<SwapMode>,
  update_symbol?: Maybe<SymbolMutationResponse>,
  update_symbol_by_pk?: Maybe<Symbol>,
  update_symbol_extension?: Maybe<SymbolExtensionMutationResponse>,
  update_symbol_extension_by_pk?: Maybe<SymbolExtension>,
  update_symbol_type?: Maybe<SymbolTypeMutationResponse>,
  update_symbol_type_by_pk?: Maybe<SymbolType>,
  update_tag?: Maybe<TagMutationResponse>,
  update_tag_by_pk?: Maybe<Tag>,
  update_trade_rule?: Maybe<TradeRuleMutationResponse>,
  update_trade_rule_by_pk?: Maybe<TradeRule>,
  update_transaction?: Maybe<TransactionMutationResponse>,
  update_transaction_by_pk?: Maybe<Transaction>,
  update_transaction_creation_request?: Maybe<TransactionCreationRequestMutationResponse>,
  update_transaction_creation_request_by_pk?: Maybe<TransactionCreationRequest>,
  update_transaction_platform_status?: Maybe<TransactionPlatformStatusMutationResponse>,
  update_transaction_platform_status_by_pk?: Maybe<TransactionPlatformStatus>,
  update_transaction_profile_grid_view?: Maybe<TransactionProfileGridViewMutationResponse>,
  update_transaction_status?: Maybe<TransactionStatusMutationResponse>,
  update_transaction_status_by_pk?: Maybe<TransactionStatus>,
  update_transaction_type?: Maybe<TransactionTypeMutationResponse>,
  update_transaction_type_by_pk?: Maybe<TransactionType>,
  update_transfer_approval_option?: Maybe<TransferApprovalOptionMutationResponse>,
  update_transfer_approval_option_by_pk?: Maybe<TransferApprovalOption>,
  update_user_access?: Maybe<UserAccessMutationResponse>,
  update_user_access_by_pk?: Maybe<UserAccess>,
  update_user_invite?: Maybe<UserInviteMutationResponse>,
  update_user_invite_by_pk?: Maybe<UserInvite>,
  update_user_permission?: Maybe<UserPermissionMutationResponse>,
  update_user_permission_by_pk?: Maybe<UserPermission>,
  update_workflow?: Maybe<WorkflowMutationResponse>,
  update_workflow_by_pk?: Maybe<Workflow>,
  update_workflow_stage?: Maybe<WorkflowStageMutationResponse>,
  update_workflow_stage_by_pk?: Maybe<WorkflowStage>,
  update_workflows_to_profiles?: Maybe<WorkflowsToProfilesMutationResponse>,
  update_workflows_to_profiles_by_pk?: Maybe<WorkflowsToProfiles>,
  update_years_experience?: Maybe<YearsExperienceMutationResponse>,
  update_years_experience_by_pk?: Maybe<YearsExperience>,
}


export interface MutationRootActionNameArgs {
  arg1: SampleInput
}


export interface MutationRootDeleteAccessFormatArgs {
  where: AccessFormatBoolExp
}


export interface MutationRootDeleteAccessFormatByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAccessFormatTypeArgs {
  where: AccessFormatTypeBoolExp
}


export interface MutationRootDeleteAccessFormatTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAccountArgs {
  where: AccountBoolExp
}


export interface MutationRootDeleteAccountByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAccountCreationRequestArgs {
  where: AccountCreationRequestBoolExp
}


export interface MutationRootDeleteAccountCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAccountGroupArgs {
  where: AccountGroupBoolExp
}


export interface MutationRootDeleteAccountGroupByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAccountGroupDefaultArgs {
  where: AccountGroupDefaultBoolExp
}


export interface MutationRootDeleteAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface MutationRootDeleteAccountGroupSecurityArgs {
  where: AccountGroupSecurityBoolExp
}


export interface MutationRootDeleteAccountGroupSecurityByPkArgs {
  group_id: Scalars['String'],
  security_id: Scalars['String']
}


export interface MutationRootDeleteAccountGroupsToPartnersArgs {
  where: AccountGroupsToPartnersBoolExp
}


export interface MutationRootDeleteAccountGroupsToPartnersByPkArgs {
  group_id: Scalars['String'],
  partner_id: Scalars['String']
}


export interface MutationRootDeleteAccountTypeArgs {
  where: AccountTypeBoolExp
}


export interface MutationRootDeleteAccountTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivitiesToChannelsArgs {
  where: ActivitiesToChannelsBoolExp
}


export interface MutationRootDeleteActivitiesToChannelsByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivityArgs {
  where: ActivityBoolExp
}


export interface MutationRootDeleteActivityByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivityCategoryArgs {
  where: ActivityCategoryBoolExp
}


export interface MutationRootDeleteActivityCategoryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivityChannelArgs {
  where: ActivityChannelBoolExp
}


export interface MutationRootDeleteActivityChannelByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivitySubjectArgs {
  where: ActivitySubjectBoolExp
}


export interface MutationRootDeleteActivitySubjectByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteActivityTemplateArgs {
  where: ActivityTemplateBoolExp
}


export interface MutationRootDeleteActivityTemplateByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAdminPermissionArgs {
  where: AdminPermissionBoolExp
}


export interface MutationRootDeleteAdminPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAgreementArgs {
  where: AgreementBoolExp
}


export interface MutationRootDeleteAgreementByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAgreementScenarioArgs {
  where: AgreementScenarioBoolExp
}


export interface MutationRootDeleteAgreementScenarioByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAgreementsSatisfiedArgs {
  where: AgreementsSatisfiedBoolExp
}


export interface MutationRootDeleteAgreementsSatisfiedByPkArgs {
  agreement_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface MutationRootDeleteAgreementsToPaymentMethodsArgs {
  where: AgreementsToPaymentMethodsBoolExp
}


export interface MutationRootDeleteAgreementsToPaymentMethodsByPkArgs {
  agreement_id: Scalars['String'],
  method_id: Scalars['String']
}


export interface MutationRootDeleteAnnualIncomeArgs {
  where: AnnualIncomeBoolExp
}


export interface MutationRootDeleteAnnualIncomeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteApiKeyArgs {
  where: ApiKeyBoolExp
}


export interface MutationRootDeleteApiKeyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAttributeMetadataArgs {
  where: AttributeMetadataBoolExp
}


export interface MutationRootDeleteAttributeMetadataByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteAttributeTypeArgs {
  where: AttributeTypeBoolExp
}


export interface MutationRootDeleteAttributeTypeByPkArgs {
  name: Scalars['String']
}


export interface MutationRootDeleteBankAccountArgs {
  where: BankAccountBoolExp
}


export interface MutationRootDeleteBankAccountByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteBookTypeArgs {
  where: BookTypeBoolExp
}


export interface MutationRootDeleteBookTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteChargeUnitArgs {
  where: ChargeUnitBoolExp
}


export interface MutationRootDeleteChargeUnitByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteCountriesToPhoneCodesArgs {
  where: CountriesToPhoneCodesBoolExp
}


export interface MutationRootDeleteCountriesToPhoneCodesByPkArgs {
  code: Scalars['String'],
  country_id: Scalars['String']
}


export interface MutationRootDeleteCountryArgs {
  where: CountryBoolExp
}


export interface MutationRootDeleteCountryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteCurrenciesToOrganizationsArgs {
  where: CurrenciesToOrganizationsBoolExp
}


export interface MutationRootDeleteCurrenciesToOrganizationsByPkArgs {
  currency_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface MutationRootDeleteCurrencyArgs {
  where: CurrencyBoolExp
}


export interface MutationRootDeleteCurrencyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteDailyAccountRevenueSummaryArgs {
  where: DailyAccountRevenueSummaryBoolExp
}


export interface MutationRootDeleteDailyAccountRevenueSummaryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteDailyAccountStatusArgs {
  where: DailyAccountStatusBoolExp
}


export interface MutationRootDeleteDailyAccountStatusByPkArgs {
  account_id: Scalars['String'],
  organization_id: Scalars['String'],
  record_date: Scalars['date']
}


export interface MutationRootDeleteDailyVolumePerAccountArgs {
  where: DailyVolumePerAccountBoolExp
}


export interface MutationRootDeleteDailyVolumePerAccountByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteDailyVolumePerProfileArgs {
  where: DailyVolumePerProfileBoolExp
}


export interface MutationRootDeleteDailyVolumePerProfileByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteEmploymentStatusArgs {
  where: EmploymentStatusBoolExp
}


export interface MutationRootDeleteEmploymentStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteExperienceTypeArgs {
  where: ExperienceTypeBoolExp
}


export interface MutationRootDeleteExperienceTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteFileArgs {
  where: FileBoolExp
}


export interface MutationRootDeleteFileByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteFileTypeArgs {
  where: FileTypeBoolExp
}


export interface MutationRootDeleteFileTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteFileTypeCategoryArgs {
  where: FileTypeCategoryBoolExp
}


export interface MutationRootDeleteFileTypeCategoryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteFilesToOrganizationsArgs {
  where: FilesToOrganizationsBoolExp
}


export interface MutationRootDeleteFilesToOrganizationsByPkArgs {
  file_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface MutationRootDeleteFilesToStakeholdersArgs {
  where: FilesToStakeholdersBoolExp
}


export interface MutationRootDeleteFilesToStakeholdersByPkArgs {
  file_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface MutationRootDeleteGenderArgs {
  where: GenderBoolExp
}


export interface MutationRootDeleteGenderByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteIdentificationTypeArgs {
  where: IdentificationTypeBoolExp
}


export interface MutationRootDeleteIdentificationTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteIndividualPartnerAccountGroupDefaultArgs {
  where: IndividualPartnerAccountGroupDefaultBoolExp
}


export interface MutationRootDeleteIndividualPartnerAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface MutationRootDeleteIndustryArgs {
  where: IndustryBoolExp
}


export interface MutationRootDeleteIndustryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteIntermediaryBankArgs {
  where: IntermediaryBankBoolExp
}


export interface MutationRootDeleteIntermediaryBankByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteInvestmentObjectiveArgs {
  where: InvestmentObjectiveBoolExp
}


export interface MutationRootDeleteInvestmentObjectiveByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteLeverageArgs {
  where: LeverageBoolExp
}


export interface MutationRootDeleteLeverageByPkArgs {
  ratio: Scalars['Int']
}


export interface MutationRootDeleteLeveragesToPlatformServerTypesArgs {
  where: LeveragesToPlatformServerTypesBoolExp
}


export interface MutationRootDeleteLeveragesToPlatformServerTypesByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteLiquidAssetsArgs {
  where: LiquidAssetsBoolExp
}


export interface MutationRootDeleteLiquidAssetsByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteLocaleArgs {
  where: LocaleBoolExp
}


export interface MutationRootDeleteLocaleByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteLocalesToOrganizationsArgs {
  where: LocalesToOrganizationsBoolExp
}


export interface MutationRootDeleteLocalesToOrganizationsByPkArgs {
  locale_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface MutationRootDeleteMarginTypeArgs {
  where: MarginTypeBoolExp
}


export interface MutationRootDeleteMarginTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterAdminClientPermissionArgs {
  where: MasterAdminClientPermissionBoolExp
}


export interface MutationRootDeleteMasterAdminClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterAdminLeadPermissionArgs {
  where: MasterAdminLeadPermissionBoolExp
}


export interface MutationRootDeleteMasterAdminLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterAdminPartnerPermissionArgs {
  where: MasterAdminPartnerPermissionBoolExp
}


export interface MutationRootDeleteMasterAdminPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterAdminStakeholderPermissionArgs {
  where: MasterAdminStakeholderPermissionBoolExp
}


export interface MutationRootDeleteMasterAdminStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterAdminSystemPermissionArgs {
  where: MasterAdminSystemPermissionBoolExp
}


export interface MutationRootDeleteMasterAdminSystemPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterPartnerClientPermissionArgs {
  where: MasterPartnerClientPermissionBoolExp
}


export interface MutationRootDeleteMasterPartnerClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterPartnerLeadPermissionArgs {
  where: MasterPartnerLeadPermissionBoolExp
}


export interface MutationRootDeleteMasterPartnerLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterPartnerPartnerPermissionArgs {
  where: MasterPartnerPartnerPermissionBoolExp
}


export interface MutationRootDeleteMasterPartnerPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterPartnerStakeholderPermissionArgs {
  where: MasterPartnerStakeholderPermissionBoolExp
}


export interface MutationRootDeleteMasterPartnerStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteMasterUserPermissionArgs {
  where: MasterUserPermissionBoolExp
}


export interface MutationRootDeleteMasterUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteNameTitleArgs {
  where: NameTitleBoolExp
}


export interface MutationRootDeleteNameTitleByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteNotificationArgs {
  where: NotificationBoolExp
}


export interface MutationRootDeleteNotificationByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteOrganizationArgs {
  where: OrganizationBoolExp
}


export interface MutationRootDeleteOrganizationByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteOriginArgs {
  where: OriginBoolExp
}


export interface MutationRootDeleteOriginByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePartnerPermissionArgs {
  where: PartnerPermissionBoolExp
}


export interface MutationRootDeletePartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentArgs {
  where: PaymentBoolExp
}


export interface MutationRootDeletePaymentAttributeGlobalIdentifierArgs {
  where: PaymentAttributeGlobalIdentifierBoolExp
}


export interface MutationRootDeletePaymentAttributeGlobalIdentifierByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentAttributeKeyArgs {
  where: PaymentAttributeKeyBoolExp
}


export interface MutationRootDeletePaymentAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentAttributeSectionArgs {
  where: PaymentAttributeSectionBoolExp
}


export interface MutationRootDeletePaymentAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentAttributeSubsectionArgs {
  where: PaymentAttributeSubsectionBoolExp
}


export interface MutationRootDeletePaymentAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentAttributeValueArgs {
  where: PaymentAttributeValueBoolExp
}


export interface MutationRootDeletePaymentAttributeValueByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodArgs {
  where: PaymentMethodBoolExp
}


export interface MutationRootDeletePaymentMethodAttributeArgs {
  where: PaymentMethodAttributeBoolExp
}


export interface MutationRootDeletePaymentMethodAttributeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodAttributeKeyArgs {
  where: PaymentMethodAttributeKeyBoolExp
}


export interface MutationRootDeletePaymentMethodAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodAttributeSectionArgs {
  where: PaymentMethodAttributeSectionBoolExp
}


export interface MutationRootDeletePaymentMethodAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodAttributeSubsectionArgs {
  where: PaymentMethodAttributeSubsectionBoolExp
}


export interface MutationRootDeletePaymentMethodAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodLogoArgs {
  where: PaymentMethodLogoBoolExp
}


export interface MutationRootDeletePaymentMethodLogoByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeArgs {
  where: PaymentMethodTypeBoolExp
}


export interface MutationRootDeletePaymentMethodTypeAttributeArgs {
  where: PaymentMethodTypeAttributeBoolExp
}


export interface MutationRootDeletePaymentMethodTypeAttributeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeAttributeKeyArgs {
  where: PaymentMethodTypeAttributeKeyBoolExp
}


export interface MutationRootDeletePaymentMethodTypeAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeAttributeSectionArgs {
  where: PaymentMethodTypeAttributeSectionBoolExp
}


export interface MutationRootDeletePaymentMethodTypeAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeAttributeSubsectionArgs {
  where: PaymentMethodTypeAttributeSubsectionBoolExp
}


export interface MutationRootDeletePaymentMethodTypeAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentMethodTypeCurrencyArgs {
  where: PaymentMethodTypeCurrencyBoolExp
}


export interface MutationRootDeletePaymentMethodTypeCurrencyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentOrientationArgs {
  where: PaymentOrientationBoolExp
}


export interface MutationRootDeletePaymentOrientationByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentProviderArgs {
  where: PaymentProviderBoolExp
}


export interface MutationRootDeletePaymentProviderByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentProviderIntegrationModeArgs {
  where: PaymentProviderIntegrationModeBoolExp
}


export interface MutationRootDeletePaymentProviderIntegrationModeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentProviderMethodTypeArgs {
  where: PaymentProviderMethodTypeBoolExp
}


export interface MutationRootDeletePaymentProviderMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePaymentStatusArgs {
  where: PaymentStatusBoolExp
}


export interface MutationRootDeletePaymentStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePhoneCodeArgs {
  where: PhoneCodeBoolExp
}


export interface MutationRootDeletePhoneCodeByPkArgs {
  code: Scalars['String']
}


export interface MutationRootDeletePhoneTypeArgs {
  where: PhoneTypeBoolExp
}


export interface MutationRootDeletePhoneTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformAccessArgs {
  where: PlatformAccessBoolExp
}


export interface MutationRootDeletePlatformAccessByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServerArgs {
  where: PlatformServerBoolExp
}


export interface MutationRootDeletePlatformServerByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServerEnvironmentArgs {
  where: PlatformServerEnvironmentBoolExp
}


export interface MutationRootDeletePlatformServerEnvironmentByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServerRetryStatusArgs {
  where: PlatformServerRetryStatusBoolExp
}


export interface MutationRootDeletePlatformServerRetryStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServerStatusArgs {
  where: PlatformServerStatusBoolExp
}


export interface MutationRootDeletePlatformServerStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServerTypeArgs {
  where: PlatformServerTypeBoolExp
}


export interface MutationRootDeletePlatformServerTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeletePlatformServersToSupportedLeveragesArgs {
  where: PlatformServersToSupportedLeveragesBoolExp
}


export interface MutationRootDeletePlatformServersToSupportedLeveragesByPkArgs {
  platform_server_id: Scalars['String'],
  supported_leverage_id: Scalars['String']
}


export interface MutationRootDeletePlatformSymbolArgs {
  where: PlatformSymbolBoolExp
}


export interface MutationRootDeletePlatformSymbolByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileArgs {
  where: ProfileBoolExp
}


export interface MutationRootDeleteProfileActivityArgs {
  where: ProfileActivityBoolExp
}


export interface MutationRootDeleteProfileActivityByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileCategoryArgs {
  where: ProfileCategoryBoolExp
}


export interface MutationRootDeleteProfileCategoryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileEntryTypeArgs {
  where: ProfileEntryTypeBoolExp
}


export interface MutationRootDeleteProfileEntryTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileFlagsArgs {
  where: ProfileFlagsBoolExp
}


export interface MutationRootDeleteProfileFlagsByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileNoteArgs {
  where: ProfileNoteBoolExp
}


export interface MutationRootDeleteProfileNoteByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileNoteVersionArgs {
  where: ProfileNoteVersionBoolExp
}


export interface MutationRootDeleteProfileNoteVersionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileNoteVersionsStakeholderMentionsArgs {
  where: ProfileNoteVersionsStakeholderMentionsBoolExp
}


export interface MutationRootDeleteProfileNoteVersionsStakeholderMentionsByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface MutationRootDeleteProfileNoteVersionsStakeholderRelevantPartiesArgs {
  where: ProfileNoteVersionsStakeholderRelevantPartiesBoolExp
}


export interface MutationRootDeleteProfileNoteVersionsStakeholderRelevantPartiesByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface MutationRootDeleteProfileRiskArgs {
  where: ProfileRiskBoolExp
}


export interface MutationRootDeleteProfileRiskByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileStatusArgs {
  where: ProfileStatusBoolExp
}


export interface MutationRootDeleteProfileStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfileTypeArgs {
  where: ProfileTypeBoolExp
}


export interface MutationRootDeleteProfileTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteProfilesToTagsArgs {
  where: ProfilesToTagsBoolExp
}


export interface MutationRootDeleteProfilesToTagsByPkArgs {
  profile_id: Scalars['String'],
  tag_id: Scalars['String']
}


export interface MutationRootDeleteRepresentativesToProfilesArgs {
  where: RepresentativesToProfilesBoolExp
}


export interface MutationRootDeleteRepresentativesToProfilesByPkArgs {
  admin_stake_id: Scalars['String'],
  profile_id: Scalars['String']
}


export interface MutationRootDeleteRequestArgs {
  where: RequestBoolExp
}


export interface MutationRootDeleteRequestByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteRequestStatusArgs {
  where: RequestStatusBoolExp
}


export interface MutationRootDeleteRequestStatusByPkArgs {
  id: Scalars['Int']
}


export interface MutationRootDeleteRequestTypeArgs {
  where: RequestTypeBoolExp
}


export interface MutationRootDeleteRequestTypeByPkArgs {
  id: Scalars['Int']
}


export interface MutationRootDeleteSecurityArgs {
  where: SecurityBoolExp
}


export interface MutationRootDeleteSecurityByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSecurityGridViewArgs {
  where: SecurityGridViewBoolExp
}


export interface MutationRootDeleteSegmentArgs {
  where: SegmentBoolExp
}


export interface MutationRootDeleteSegmentByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSegmentConditionArgs {
  where: SegmentConditionBoolExp
}


export interface MutationRootDeleteSegmentConditionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSegmentCriterionArgs {
  where: SegmentCriterionBoolExp
}


export interface MutationRootDeleteSegmentCriterionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSegmentCriterionTypeArgs {
  where: SegmentCriterionTypeBoolExp
}


export interface MutationRootDeleteSegmentCriterionTypeByPkArgs {
  name: Scalars['String']
}


export interface MutationRootDeleteSegmentOperatorArgs {
  where: SegmentOperatorBoolExp
}


export interface MutationRootDeleteSegmentOperatorByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSegmentOperatorsToSegmentPropertyConditionsArgs {
  where: SegmentOperatorsToSegmentPropertyConditionsBoolExp
}


export interface MutationRootDeleteSegmentOperatorsToSegmentPropertyConditionsByPkArgs {
  operator_id: Scalars['String'],
  property_condition_id: Scalars['String']
}


export interface MutationRootDeleteSegmentPropertyArgs {
  where: SegmentPropertyBoolExp
}


export interface MutationRootDeleteSegmentPropertyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSegmentPropertyConditionArgs {
  where: SegmentPropertyConditionBoolExp
}


export interface MutationRootDeleteSegmentPropertyConditionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSourceOfFundsArgs {
  where: SourceOfFundsBoolExp
}


export interface MutationRootDeleteSourceOfFundsByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSpreadModeArgs {
  where: SpreadModeBoolExp
}


export interface MutationRootDeleteSpreadModeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeArgs {
  where: StakeBoolExp
}


export interface MutationRootDeleteStakeAncestryArgs {
  where: StakeAncestryBoolExp
}


export interface MutationRootDeleteStakeAncestryByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeTitleArgs {
  where: StakeTitleBoolExp
}


export interface MutationRootDeleteStakeTitleByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderArgs {
  where: StakeholderBoolExp
}


export interface MutationRootDeleteStakeholderAttributeArgs {
  where: StakeholderAttributeBoolExp
}


export interface MutationRootDeleteStakeholderAttributeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderAttributeKeyArgs {
  where: StakeholderAttributeKeyBoolExp
}


export interface MutationRootDeleteStakeholderAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderAttributeKeysToStakeholderClassesArgs {
  where: StakeholderAttributeKeysToStakeholderClassesBoolExp
}


export interface MutationRootDeleteStakeholderAttributeKeysToStakeholderClassesByPkArgs {
  stakeholder_attribute_key_id: Scalars['String'],
  stakeholder_class_id: Scalars['String']
}


export interface MutationRootDeleteStakeholderAttributeSectionArgs {
  where: StakeholderAttributeSectionBoolExp
}


export interface MutationRootDeleteStakeholderAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderAttributeSubsectionArgs {
  where: StakeholderAttributeSubsectionBoolExp
}


export interface MutationRootDeleteStakeholderAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakeholderClassArgs {
  where: StakeholderClassBoolExp
}


export interface MutationRootDeleteStakeholderClassByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakesToAdminPermissionsArgs {
  where: StakesToAdminPermissionsBoolExp
}


export interface MutationRootDeleteStakesToAdminPermissionsByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteStakesToStakeTitlesArgs {
  where: StakesToStakeTitlesBoolExp
}


export interface MutationRootDeleteStakesToStakeTitlesByPkArgs {
  stake_id: Scalars['String'],
  stake_title_id: Scalars['String']
}


export interface MutationRootDeleteSwapModeArgs {
  where: SwapModeBoolExp
}


export interface MutationRootDeleteSwapModeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSymbolArgs {
  where: SymbolBoolExp
}


export interface MutationRootDeleteSymbolByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSymbolExtensionArgs {
  where: SymbolExtensionBoolExp
}


export interface MutationRootDeleteSymbolExtensionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteSymbolTypeArgs {
  where: SymbolTypeBoolExp
}


export interface MutationRootDeleteSymbolTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTagArgs {
  where: TagBoolExp
}


export interface MutationRootDeleteTagByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTradeRuleArgs {
  where: TradeRuleBoolExp
}


export interface MutationRootDeleteTradeRuleByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransactionArgs {
  where: TransactionBoolExp
}


export interface MutationRootDeleteTransactionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransactionCreationRequestArgs {
  where: TransactionCreationRequestBoolExp
}


export interface MutationRootDeleteTransactionCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransactionPlatformStatusArgs {
  where: TransactionPlatformStatusBoolExp
}


export interface MutationRootDeleteTransactionPlatformStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransactionProfileGridViewArgs {
  where: TransactionProfileGridViewBoolExp
}


export interface MutationRootDeleteTransactionStatusArgs {
  where: TransactionStatusBoolExp
}


export interface MutationRootDeleteTransactionStatusByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransactionTypeArgs {
  where: TransactionTypeBoolExp
}


export interface MutationRootDeleteTransactionTypeByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteTransferApprovalOptionArgs {
  where: TransferApprovalOptionBoolExp
}


export interface MutationRootDeleteTransferApprovalOptionByPkArgs {
  id: Scalars['Int']
}


export interface MutationRootDeleteUserAccessArgs {
  where: UserAccessBoolExp
}


export interface MutationRootDeleteUserAccessByPkArgs {
  code: Scalars['Int']
}


export interface MutationRootDeleteUserInviteArgs {
  where: UserInviteBoolExp
}


export interface MutationRootDeleteUserInviteByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteUserPermissionArgs {
  where: UserPermissionBoolExp
}


export interface MutationRootDeleteUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteWorkflowArgs {
  where: WorkflowBoolExp
}


export interface MutationRootDeleteWorkflowByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteWorkflowStageArgs {
  where: WorkflowStageBoolExp
}


export interface MutationRootDeleteWorkflowStageByPkArgs {
  id: Scalars['String']
}


export interface MutationRootDeleteWorkflowsToProfilesArgs {
  where: WorkflowsToProfilesBoolExp
}


export interface MutationRootDeleteWorkflowsToProfilesByPkArgs {
  profile_id: Scalars['String'],
  workflow_id: Scalars['String']
}


export interface MutationRootDeleteYearsExperienceArgs {
  where: YearsExperienceBoolExp
}


export interface MutationRootDeleteYearsExperienceByPkArgs {
  id: Scalars['String']
}


export interface MutationRootInsertAccessFormatArgs {
  objects: Array<AccessFormatInsertInput>,
  on_conflict?: Maybe<AccessFormatOnConflict>
}


export interface MutationRootInsertAccessFormatOneArgs {
  object: AccessFormatInsertInput,
  on_conflict?: Maybe<AccessFormatOnConflict>
}


export interface MutationRootInsertAccessFormatTypeArgs {
  objects: Array<AccessFormatTypeInsertInput>,
  on_conflict?: Maybe<AccessFormatTypeOnConflict>
}


export interface MutationRootInsertAccessFormatTypeOneArgs {
  object: AccessFormatTypeInsertInput,
  on_conflict?: Maybe<AccessFormatTypeOnConflict>
}


export interface MutationRootInsertAccountArgs {
  objects: Array<AccountInsertInput>,
  on_conflict?: Maybe<AccountOnConflict>
}


export interface MutationRootInsertAccountCreationRequestArgs {
  objects: Array<AccountCreationRequestInsertInput>,
  on_conflict?: Maybe<AccountCreationRequestOnConflict>
}


export interface MutationRootInsertAccountCreationRequestOneArgs {
  object: AccountCreationRequestInsertInput,
  on_conflict?: Maybe<AccountCreationRequestOnConflict>
}


export interface MutationRootInsertAccountGroupArgs {
  objects: Array<AccountGroupInsertInput>,
  on_conflict?: Maybe<AccountGroupOnConflict>
}


export interface MutationRootInsertAccountGroupDefaultArgs {
  objects: Array<AccountGroupDefaultInsertInput>,
  on_conflict?: Maybe<AccountGroupDefaultOnConflict>
}


export interface MutationRootInsertAccountGroupDefaultOneArgs {
  object: AccountGroupDefaultInsertInput,
  on_conflict?: Maybe<AccountGroupDefaultOnConflict>
}


export interface MutationRootInsertAccountGroupOneArgs {
  object: AccountGroupInsertInput,
  on_conflict?: Maybe<AccountGroupOnConflict>
}


export interface MutationRootInsertAccountGroupSecurityArgs {
  objects: Array<AccountGroupSecurityInsertInput>,
  on_conflict?: Maybe<AccountGroupSecurityOnConflict>
}


export interface MutationRootInsertAccountGroupSecurityOneArgs {
  object: AccountGroupSecurityInsertInput,
  on_conflict?: Maybe<AccountGroupSecurityOnConflict>
}


export interface MutationRootInsertAccountGroupsToPartnersArgs {
  objects: Array<AccountGroupsToPartnersInsertInput>,
  on_conflict?: Maybe<AccountGroupsToPartnersOnConflict>
}


export interface MutationRootInsertAccountGroupsToPartnersOneArgs {
  object: AccountGroupsToPartnersInsertInput,
  on_conflict?: Maybe<AccountGroupsToPartnersOnConflict>
}


export interface MutationRootInsertAccountOneArgs {
  object: AccountInsertInput,
  on_conflict?: Maybe<AccountOnConflict>
}


export interface MutationRootInsertAccountTypeArgs {
  objects: Array<AccountTypeInsertInput>,
  on_conflict?: Maybe<AccountTypeOnConflict>
}


export interface MutationRootInsertAccountTypeOneArgs {
  object: AccountTypeInsertInput,
  on_conflict?: Maybe<AccountTypeOnConflict>
}


export interface MutationRootInsertActivitiesToChannelsArgs {
  objects: Array<ActivitiesToChannelsInsertInput>,
  on_conflict?: Maybe<ActivitiesToChannelsOnConflict>
}


export interface MutationRootInsertActivitiesToChannelsOneArgs {
  object: ActivitiesToChannelsInsertInput,
  on_conflict?: Maybe<ActivitiesToChannelsOnConflict>
}


export interface MutationRootInsertActivityArgs {
  objects: Array<ActivityInsertInput>,
  on_conflict?: Maybe<ActivityOnConflict>
}


export interface MutationRootInsertActivityCategoryArgs {
  objects: Array<ActivityCategoryInsertInput>,
  on_conflict?: Maybe<ActivityCategoryOnConflict>
}


export interface MutationRootInsertActivityCategoryOneArgs {
  object: ActivityCategoryInsertInput,
  on_conflict?: Maybe<ActivityCategoryOnConflict>
}


export interface MutationRootInsertActivityChannelArgs {
  objects: Array<ActivityChannelInsertInput>,
  on_conflict?: Maybe<ActivityChannelOnConflict>
}


export interface MutationRootInsertActivityChannelOneArgs {
  object: ActivityChannelInsertInput,
  on_conflict?: Maybe<ActivityChannelOnConflict>
}


export interface MutationRootInsertActivityOneArgs {
  object: ActivityInsertInput,
  on_conflict?: Maybe<ActivityOnConflict>
}


export interface MutationRootInsertActivitySubjectArgs {
  objects: Array<ActivitySubjectInsertInput>,
  on_conflict?: Maybe<ActivitySubjectOnConflict>
}


export interface MutationRootInsertActivitySubjectOneArgs {
  object: ActivitySubjectInsertInput,
  on_conflict?: Maybe<ActivitySubjectOnConflict>
}


export interface MutationRootInsertActivityTemplateArgs {
  objects: Array<ActivityTemplateInsertInput>,
  on_conflict?: Maybe<ActivityTemplateOnConflict>
}


export interface MutationRootInsertActivityTemplateOneArgs {
  object: ActivityTemplateInsertInput,
  on_conflict?: Maybe<ActivityTemplateOnConflict>
}


export interface MutationRootInsertAdminPermissionArgs {
  objects: Array<AdminPermissionInsertInput>,
  on_conflict?: Maybe<AdminPermissionOnConflict>
}


export interface MutationRootInsertAdminPermissionOneArgs {
  object: AdminPermissionInsertInput,
  on_conflict?: Maybe<AdminPermissionOnConflict>
}


export interface MutationRootInsertAgreementArgs {
  objects: Array<AgreementInsertInput>,
  on_conflict?: Maybe<AgreementOnConflict>
}


export interface MutationRootInsertAgreementOneArgs {
  object: AgreementInsertInput,
  on_conflict?: Maybe<AgreementOnConflict>
}


export interface MutationRootInsertAgreementScenarioArgs {
  objects: Array<AgreementScenarioInsertInput>,
  on_conflict?: Maybe<AgreementScenarioOnConflict>
}


export interface MutationRootInsertAgreementScenarioOneArgs {
  object: AgreementScenarioInsertInput,
  on_conflict?: Maybe<AgreementScenarioOnConflict>
}


export interface MutationRootInsertAgreementsSatisfiedArgs {
  objects: Array<AgreementsSatisfiedInsertInput>,
  on_conflict?: Maybe<AgreementsSatisfiedOnConflict>
}


export interface MutationRootInsertAgreementsSatisfiedOneArgs {
  object: AgreementsSatisfiedInsertInput,
  on_conflict?: Maybe<AgreementsSatisfiedOnConflict>
}


export interface MutationRootInsertAgreementsToPaymentMethodsArgs {
  objects: Array<AgreementsToPaymentMethodsInsertInput>,
  on_conflict?: Maybe<AgreementsToPaymentMethodsOnConflict>
}


export interface MutationRootInsertAgreementsToPaymentMethodsOneArgs {
  object: AgreementsToPaymentMethodsInsertInput,
  on_conflict?: Maybe<AgreementsToPaymentMethodsOnConflict>
}


export interface MutationRootInsertAnnualIncomeArgs {
  objects: Array<AnnualIncomeInsertInput>,
  on_conflict?: Maybe<AnnualIncomeOnConflict>
}


export interface MutationRootInsertAnnualIncomeOneArgs {
  object: AnnualIncomeInsertInput,
  on_conflict?: Maybe<AnnualIncomeOnConflict>
}


export interface MutationRootInsertApiKeyArgs {
  objects: Array<ApiKeyInsertInput>,
  on_conflict?: Maybe<ApiKeyOnConflict>
}


export interface MutationRootInsertApiKeyOneArgs {
  object: ApiKeyInsertInput,
  on_conflict?: Maybe<ApiKeyOnConflict>
}


export interface MutationRootInsertAttributeMetadataArgs {
  objects: Array<AttributeMetadataInsertInput>,
  on_conflict?: Maybe<AttributeMetadataOnConflict>
}


export interface MutationRootInsertAttributeMetadataOneArgs {
  object: AttributeMetadataInsertInput,
  on_conflict?: Maybe<AttributeMetadataOnConflict>
}


export interface MutationRootInsertAttributeTypeArgs {
  objects: Array<AttributeTypeInsertInput>,
  on_conflict?: Maybe<AttributeTypeOnConflict>
}


export interface MutationRootInsertAttributeTypeOneArgs {
  object: AttributeTypeInsertInput,
  on_conflict?: Maybe<AttributeTypeOnConflict>
}


export interface MutationRootInsertBankAccountArgs {
  objects: Array<BankAccountInsertInput>,
  on_conflict?: Maybe<BankAccountOnConflict>
}


export interface MutationRootInsertBankAccountOneArgs {
  object: BankAccountInsertInput,
  on_conflict?: Maybe<BankAccountOnConflict>
}


export interface MutationRootInsertBookTypeArgs {
  objects: Array<BookTypeInsertInput>,
  on_conflict?: Maybe<BookTypeOnConflict>
}


export interface MutationRootInsertBookTypeOneArgs {
  object: BookTypeInsertInput,
  on_conflict?: Maybe<BookTypeOnConflict>
}


export interface MutationRootInsertChargeUnitArgs {
  objects: Array<ChargeUnitInsertInput>,
  on_conflict?: Maybe<ChargeUnitOnConflict>
}


export interface MutationRootInsertChargeUnitOneArgs {
  object: ChargeUnitInsertInput,
  on_conflict?: Maybe<ChargeUnitOnConflict>
}


export interface MutationRootInsertCountriesToPhoneCodesArgs {
  objects: Array<CountriesToPhoneCodesInsertInput>,
  on_conflict?: Maybe<CountriesToPhoneCodesOnConflict>
}


export interface MutationRootInsertCountriesToPhoneCodesOneArgs {
  object: CountriesToPhoneCodesInsertInput,
  on_conflict?: Maybe<CountriesToPhoneCodesOnConflict>
}


export interface MutationRootInsertCountryArgs {
  objects: Array<CountryInsertInput>,
  on_conflict?: Maybe<CountryOnConflict>
}


export interface MutationRootInsertCountryOneArgs {
  object: CountryInsertInput,
  on_conflict?: Maybe<CountryOnConflict>
}


export interface MutationRootInsertCurrenciesToOrganizationsArgs {
  objects: Array<CurrenciesToOrganizationsInsertInput>,
  on_conflict?: Maybe<CurrenciesToOrganizationsOnConflict>
}


export interface MutationRootInsertCurrenciesToOrganizationsOneArgs {
  object: CurrenciesToOrganizationsInsertInput,
  on_conflict?: Maybe<CurrenciesToOrganizationsOnConflict>
}


export interface MutationRootInsertCurrencyArgs {
  objects: Array<CurrencyInsertInput>,
  on_conflict?: Maybe<CurrencyOnConflict>
}


export interface MutationRootInsertCurrencyOneArgs {
  object: CurrencyInsertInput,
  on_conflict?: Maybe<CurrencyOnConflict>
}


export interface MutationRootInsertDailyAccountRevenueSummaryArgs {
  objects: Array<DailyAccountRevenueSummaryInsertInput>,
  on_conflict?: Maybe<DailyAccountRevenueSummaryOnConflict>
}


export interface MutationRootInsertDailyAccountRevenueSummaryOneArgs {
  object: DailyAccountRevenueSummaryInsertInput,
  on_conflict?: Maybe<DailyAccountRevenueSummaryOnConflict>
}


export interface MutationRootInsertDailyAccountStatusArgs {
  objects: Array<DailyAccountStatusInsertInput>,
  on_conflict?: Maybe<DailyAccountStatusOnConflict>
}


export interface MutationRootInsertDailyAccountStatusOneArgs {
  object: DailyAccountStatusInsertInput,
  on_conflict?: Maybe<DailyAccountStatusOnConflict>
}


export interface MutationRootInsertDailyVolumePerAccountArgs {
  objects: Array<DailyVolumePerAccountInsertInput>,
  on_conflict?: Maybe<DailyVolumePerAccountOnConflict>
}


export interface MutationRootInsertDailyVolumePerAccountOneArgs {
  object: DailyVolumePerAccountInsertInput,
  on_conflict?: Maybe<DailyVolumePerAccountOnConflict>
}


export interface MutationRootInsertDailyVolumePerProfileArgs {
  objects: Array<DailyVolumePerProfileInsertInput>,
  on_conflict?: Maybe<DailyVolumePerProfileOnConflict>
}


export interface MutationRootInsertDailyVolumePerProfileOneArgs {
  object: DailyVolumePerProfileInsertInput,
  on_conflict?: Maybe<DailyVolumePerProfileOnConflict>
}


export interface MutationRootInsertEmploymentStatusArgs {
  objects: Array<EmploymentStatusInsertInput>,
  on_conflict?: Maybe<EmploymentStatusOnConflict>
}


export interface MutationRootInsertEmploymentStatusOneArgs {
  object: EmploymentStatusInsertInput,
  on_conflict?: Maybe<EmploymentStatusOnConflict>
}


export interface MutationRootInsertExperienceTypeArgs {
  objects: Array<ExperienceTypeInsertInput>,
  on_conflict?: Maybe<ExperienceTypeOnConflict>
}


export interface MutationRootInsertExperienceTypeOneArgs {
  object: ExperienceTypeInsertInput,
  on_conflict?: Maybe<ExperienceTypeOnConflict>
}


export interface MutationRootInsertFileArgs {
  objects: Array<FileInsertInput>,
  on_conflict?: Maybe<FileOnConflict>
}


export interface MutationRootInsertFileOneArgs {
  object: FileInsertInput,
  on_conflict?: Maybe<FileOnConflict>
}


export interface MutationRootInsertFileTypeArgs {
  objects: Array<FileTypeInsertInput>,
  on_conflict?: Maybe<FileTypeOnConflict>
}


export interface MutationRootInsertFileTypeCategoryArgs {
  objects: Array<FileTypeCategoryInsertInput>,
  on_conflict?: Maybe<FileTypeCategoryOnConflict>
}


export interface MutationRootInsertFileTypeCategoryOneArgs {
  object: FileTypeCategoryInsertInput,
  on_conflict?: Maybe<FileTypeCategoryOnConflict>
}


export interface MutationRootInsertFileTypeOneArgs {
  object: FileTypeInsertInput,
  on_conflict?: Maybe<FileTypeOnConflict>
}


export interface MutationRootInsertFilesToOrganizationsArgs {
  objects: Array<FilesToOrganizationsInsertInput>,
  on_conflict?: Maybe<FilesToOrganizationsOnConflict>
}


export interface MutationRootInsertFilesToOrganizationsOneArgs {
  object: FilesToOrganizationsInsertInput,
  on_conflict?: Maybe<FilesToOrganizationsOnConflict>
}


export interface MutationRootInsertFilesToStakeholdersArgs {
  objects: Array<FilesToStakeholdersInsertInput>,
  on_conflict?: Maybe<FilesToStakeholdersOnConflict>
}


export interface MutationRootInsertFilesToStakeholdersOneArgs {
  object: FilesToStakeholdersInsertInput,
  on_conflict?: Maybe<FilesToStakeholdersOnConflict>
}


export interface MutationRootInsertGenderArgs {
  objects: Array<GenderInsertInput>,
  on_conflict?: Maybe<GenderOnConflict>
}


export interface MutationRootInsertGenderOneArgs {
  object: GenderInsertInput,
  on_conflict?: Maybe<GenderOnConflict>
}


export interface MutationRootInsertIdentificationTypeArgs {
  objects: Array<IdentificationTypeInsertInput>,
  on_conflict?: Maybe<IdentificationTypeOnConflict>
}


export interface MutationRootInsertIdentificationTypeOneArgs {
  object: IdentificationTypeInsertInput,
  on_conflict?: Maybe<IdentificationTypeOnConflict>
}


export interface MutationRootInsertIndividualPartnerAccountGroupDefaultArgs {
  objects: Array<IndividualPartnerAccountGroupDefaultInsertInput>,
  on_conflict?: Maybe<IndividualPartnerAccountGroupDefaultOnConflict>
}


export interface MutationRootInsertIndividualPartnerAccountGroupDefaultOneArgs {
  object: IndividualPartnerAccountGroupDefaultInsertInput,
  on_conflict?: Maybe<IndividualPartnerAccountGroupDefaultOnConflict>
}


export interface MutationRootInsertIndustryArgs {
  objects: Array<IndustryInsertInput>,
  on_conflict?: Maybe<IndustryOnConflict>
}


export interface MutationRootInsertIndustryOneArgs {
  object: IndustryInsertInput,
  on_conflict?: Maybe<IndustryOnConflict>
}


export interface MutationRootInsertIntermediaryBankArgs {
  objects: Array<IntermediaryBankInsertInput>,
  on_conflict?: Maybe<IntermediaryBankOnConflict>
}


export interface MutationRootInsertIntermediaryBankOneArgs {
  object: IntermediaryBankInsertInput,
  on_conflict?: Maybe<IntermediaryBankOnConflict>
}


export interface MutationRootInsertInvestmentObjectiveArgs {
  objects: Array<InvestmentObjectiveInsertInput>,
  on_conflict?: Maybe<InvestmentObjectiveOnConflict>
}


export interface MutationRootInsertInvestmentObjectiveOneArgs {
  object: InvestmentObjectiveInsertInput,
  on_conflict?: Maybe<InvestmentObjectiveOnConflict>
}


export interface MutationRootInsertLeverageArgs {
  objects: Array<LeverageInsertInput>,
  on_conflict?: Maybe<LeverageOnConflict>
}


export interface MutationRootInsertLeverageOneArgs {
  object: LeverageInsertInput,
  on_conflict?: Maybe<LeverageOnConflict>
}


export interface MutationRootInsertLeveragesToPlatformServerTypesArgs {
  objects: Array<LeveragesToPlatformServerTypesInsertInput>,
  on_conflict?: Maybe<LeveragesToPlatformServerTypesOnConflict>
}


export interface MutationRootInsertLeveragesToPlatformServerTypesOneArgs {
  object: LeveragesToPlatformServerTypesInsertInput,
  on_conflict?: Maybe<LeveragesToPlatformServerTypesOnConflict>
}


export interface MutationRootInsertLiquidAssetsArgs {
  objects: Array<LiquidAssetsInsertInput>,
  on_conflict?: Maybe<LiquidAssetsOnConflict>
}


export interface MutationRootInsertLiquidAssetsOneArgs {
  object: LiquidAssetsInsertInput,
  on_conflict?: Maybe<LiquidAssetsOnConflict>
}


export interface MutationRootInsertLocaleArgs {
  objects: Array<LocaleInsertInput>,
  on_conflict?: Maybe<LocaleOnConflict>
}


export interface MutationRootInsertLocaleOneArgs {
  object: LocaleInsertInput,
  on_conflict?: Maybe<LocaleOnConflict>
}


export interface MutationRootInsertLocalesToOrganizationsArgs {
  objects: Array<LocalesToOrganizationsInsertInput>,
  on_conflict?: Maybe<LocalesToOrganizationsOnConflict>
}


export interface MutationRootInsertLocalesToOrganizationsOneArgs {
  object: LocalesToOrganizationsInsertInput,
  on_conflict?: Maybe<LocalesToOrganizationsOnConflict>
}


export interface MutationRootInsertMarginTypeArgs {
  objects: Array<MarginTypeInsertInput>,
  on_conflict?: Maybe<MarginTypeOnConflict>
}


export interface MutationRootInsertMarginTypeOneArgs {
  object: MarginTypeInsertInput,
  on_conflict?: Maybe<MarginTypeOnConflict>
}


export interface MutationRootInsertMasterAdminClientPermissionArgs {
  objects: Array<MasterAdminClientPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminClientPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminClientPermissionOneArgs {
  object: MasterAdminClientPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminClientPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminLeadPermissionArgs {
  objects: Array<MasterAdminLeadPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminLeadPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminLeadPermissionOneArgs {
  object: MasterAdminLeadPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminLeadPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminPartnerPermissionArgs {
  objects: Array<MasterAdminPartnerPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminPartnerPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminPartnerPermissionOneArgs {
  object: MasterAdminPartnerPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminPartnerPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminStakeholderPermissionArgs {
  objects: Array<MasterAdminStakeholderPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminStakeholderPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminStakeholderPermissionOneArgs {
  object: MasterAdminStakeholderPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminStakeholderPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminSystemPermissionArgs {
  objects: Array<MasterAdminSystemPermissionInsertInput>,
  on_conflict?: Maybe<MasterAdminSystemPermissionOnConflict>
}


export interface MutationRootInsertMasterAdminSystemPermissionOneArgs {
  object: MasterAdminSystemPermissionInsertInput,
  on_conflict?: Maybe<MasterAdminSystemPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerClientPermissionArgs {
  objects: Array<MasterPartnerClientPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerClientPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerClientPermissionOneArgs {
  object: MasterPartnerClientPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerClientPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerLeadPermissionArgs {
  objects: Array<MasterPartnerLeadPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerLeadPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerLeadPermissionOneArgs {
  object: MasterPartnerLeadPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerLeadPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerPartnerPermissionArgs {
  objects: Array<MasterPartnerPartnerPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerPartnerPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerPartnerPermissionOneArgs {
  object: MasterPartnerPartnerPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerPartnerPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerStakeholderPermissionArgs {
  objects: Array<MasterPartnerStakeholderPermissionInsertInput>,
  on_conflict?: Maybe<MasterPartnerStakeholderPermissionOnConflict>
}


export interface MutationRootInsertMasterPartnerStakeholderPermissionOneArgs {
  object: MasterPartnerStakeholderPermissionInsertInput,
  on_conflict?: Maybe<MasterPartnerStakeholderPermissionOnConflict>
}


export interface MutationRootInsertMasterUserPermissionArgs {
  objects: Array<MasterUserPermissionInsertInput>,
  on_conflict?: Maybe<MasterUserPermissionOnConflict>
}


export interface MutationRootInsertMasterUserPermissionOneArgs {
  object: MasterUserPermissionInsertInput,
  on_conflict?: Maybe<MasterUserPermissionOnConflict>
}


export interface MutationRootInsertNameTitleArgs {
  objects: Array<NameTitleInsertInput>,
  on_conflict?: Maybe<NameTitleOnConflict>
}


export interface MutationRootInsertNameTitleOneArgs {
  object: NameTitleInsertInput,
  on_conflict?: Maybe<NameTitleOnConflict>
}


export interface MutationRootInsertNotificationArgs {
  objects: Array<NotificationInsertInput>,
  on_conflict?: Maybe<NotificationOnConflict>
}


export interface MutationRootInsertNotificationOneArgs {
  object: NotificationInsertInput,
  on_conflict?: Maybe<NotificationOnConflict>
}


export interface MutationRootInsertOrganizationArgs {
  objects: Array<OrganizationInsertInput>,
  on_conflict?: Maybe<OrganizationOnConflict>
}


export interface MutationRootInsertOrganizationOneArgs {
  object: OrganizationInsertInput,
  on_conflict?: Maybe<OrganizationOnConflict>
}


export interface MutationRootInsertOriginArgs {
  objects: Array<OriginInsertInput>,
  on_conflict?: Maybe<OriginOnConflict>
}


export interface MutationRootInsertOriginOneArgs {
  object: OriginInsertInput,
  on_conflict?: Maybe<OriginOnConflict>
}


export interface MutationRootInsertPartnerPermissionArgs {
  objects: Array<PartnerPermissionInsertInput>,
  on_conflict?: Maybe<PartnerPermissionOnConflict>
}


export interface MutationRootInsertPartnerPermissionOneArgs {
  object: PartnerPermissionInsertInput,
  on_conflict?: Maybe<PartnerPermissionOnConflict>
}


export interface MutationRootInsertPaymentArgs {
  objects: Array<PaymentInsertInput>,
  on_conflict?: Maybe<PaymentOnConflict>
}


export interface MutationRootInsertPaymentAttributeGlobalIdentifierArgs {
  objects: Array<PaymentAttributeGlobalIdentifierInsertInput>,
  on_conflict?: Maybe<PaymentAttributeGlobalIdentifierOnConflict>
}


export interface MutationRootInsertPaymentAttributeGlobalIdentifierOneArgs {
  object: PaymentAttributeGlobalIdentifierInsertInput,
  on_conflict?: Maybe<PaymentAttributeGlobalIdentifierOnConflict>
}


export interface MutationRootInsertPaymentAttributeKeyArgs {
  objects: Array<PaymentAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentAttributeKeyOneArgs {
  object: PaymentAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentAttributeSectionArgs {
  objects: Array<PaymentAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentAttributeSectionOneArgs {
  object: PaymentAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentAttributeSubsectionArgs {
  objects: Array<PaymentAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentAttributeSubsectionOneArgs {
  object: PaymentAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentAttributeValueArgs {
  objects: Array<PaymentAttributeValueInsertInput>,
  on_conflict?: Maybe<PaymentAttributeValueOnConflict>
}


export interface MutationRootInsertPaymentAttributeValueOneArgs {
  object: PaymentAttributeValueInsertInput,
  on_conflict?: Maybe<PaymentAttributeValueOnConflict>
}


export interface MutationRootInsertPaymentMethodArgs {
  objects: Array<PaymentMethodInsertInput>,
  on_conflict?: Maybe<PaymentMethodOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeArgs {
  objects: Array<PaymentMethodAttributeInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeKeyArgs {
  objects: Array<PaymentMethodAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeKeyOneArgs {
  object: PaymentMethodAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeOneArgs {
  object: PaymentMethodAttributeInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeSectionArgs {
  objects: Array<PaymentMethodAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeSectionOneArgs {
  object: PaymentMethodAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeSubsectionArgs {
  objects: Array<PaymentMethodAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentMethodAttributeSubsectionOneArgs {
  object: PaymentMethodAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentMethodLogoArgs {
  objects: Array<PaymentMethodLogoInsertInput>,
  on_conflict?: Maybe<PaymentMethodLogoOnConflict>
}


export interface MutationRootInsertPaymentMethodLogoOneArgs {
  object: PaymentMethodLogoInsertInput,
  on_conflict?: Maybe<PaymentMethodLogoOnConflict>
}


export interface MutationRootInsertPaymentMethodOneArgs {
  object: PaymentMethodInsertInput,
  on_conflict?: Maybe<PaymentMethodOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeArgs {
  objects: Array<PaymentMethodTypeInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeArgs {
  objects: Array<PaymentMethodTypeAttributeInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeKeyArgs {
  objects: Array<PaymentMethodTypeAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeKeyOneArgs {
  object: PaymentMethodTypeAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeKeyOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeOneArgs {
  object: PaymentMethodTypeAttributeInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeSectionArgs {
  objects: Array<PaymentMethodTypeAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeSectionOneArgs {
  object: PaymentMethodTypeAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSectionOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeSubsectionArgs {
  objects: Array<PaymentMethodTypeAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeAttributeSubsectionOneArgs {
  object: PaymentMethodTypeAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSubsectionOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeCurrencyArgs {
  objects: Array<PaymentMethodTypeCurrencyInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeCurrencyOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeCurrencyOneArgs {
  object: PaymentMethodTypeCurrencyInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeCurrencyOnConflict>
}


export interface MutationRootInsertPaymentMethodTypeOneArgs {
  object: PaymentMethodTypeInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeOnConflict>
}


export interface MutationRootInsertPaymentOneArgs {
  object: PaymentInsertInput,
  on_conflict?: Maybe<PaymentOnConflict>
}


export interface MutationRootInsertPaymentOrientationArgs {
  objects: Array<PaymentOrientationInsertInput>,
  on_conflict?: Maybe<PaymentOrientationOnConflict>
}


export interface MutationRootInsertPaymentOrientationOneArgs {
  object: PaymentOrientationInsertInput,
  on_conflict?: Maybe<PaymentOrientationOnConflict>
}


export interface MutationRootInsertPaymentProviderArgs {
  objects: Array<PaymentProviderInsertInput>,
  on_conflict?: Maybe<PaymentProviderOnConflict>
}


export interface MutationRootInsertPaymentProviderIntegrationModeArgs {
  objects: Array<PaymentProviderIntegrationModeInsertInput>,
  on_conflict?: Maybe<PaymentProviderIntegrationModeOnConflict>
}


export interface MutationRootInsertPaymentProviderIntegrationModeOneArgs {
  object: PaymentProviderIntegrationModeInsertInput,
  on_conflict?: Maybe<PaymentProviderIntegrationModeOnConflict>
}


export interface MutationRootInsertPaymentProviderMethodTypeArgs {
  objects: Array<PaymentProviderMethodTypeInsertInput>,
  on_conflict?: Maybe<PaymentProviderMethodTypeOnConflict>
}


export interface MutationRootInsertPaymentProviderMethodTypeOneArgs {
  object: PaymentProviderMethodTypeInsertInput,
  on_conflict?: Maybe<PaymentProviderMethodTypeOnConflict>
}


export interface MutationRootInsertPaymentProviderOneArgs {
  object: PaymentProviderInsertInput,
  on_conflict?: Maybe<PaymentProviderOnConflict>
}


export interface MutationRootInsertPaymentStatusArgs {
  objects: Array<PaymentStatusInsertInput>,
  on_conflict?: Maybe<PaymentStatusOnConflict>
}


export interface MutationRootInsertPaymentStatusOneArgs {
  object: PaymentStatusInsertInput,
  on_conflict?: Maybe<PaymentStatusOnConflict>
}


export interface MutationRootInsertPhoneCodeArgs {
  objects: Array<PhoneCodeInsertInput>,
  on_conflict?: Maybe<PhoneCodeOnConflict>
}


export interface MutationRootInsertPhoneCodeOneArgs {
  object: PhoneCodeInsertInput,
  on_conflict?: Maybe<PhoneCodeOnConflict>
}


export interface MutationRootInsertPhoneTypeArgs {
  objects: Array<PhoneTypeInsertInput>,
  on_conflict?: Maybe<PhoneTypeOnConflict>
}


export interface MutationRootInsertPhoneTypeOneArgs {
  object: PhoneTypeInsertInput,
  on_conflict?: Maybe<PhoneTypeOnConflict>
}


export interface MutationRootInsertPlatformAccessArgs {
  objects: Array<PlatformAccessInsertInput>,
  on_conflict?: Maybe<PlatformAccessOnConflict>
}


export interface MutationRootInsertPlatformAccessOneArgs {
  object: PlatformAccessInsertInput,
  on_conflict?: Maybe<PlatformAccessOnConflict>
}


export interface MutationRootInsertPlatformServerArgs {
  objects: Array<PlatformServerInsertInput>,
  on_conflict?: Maybe<PlatformServerOnConflict>
}


export interface MutationRootInsertPlatformServerEnvironmentArgs {
  objects: Array<PlatformServerEnvironmentInsertInput>,
  on_conflict?: Maybe<PlatformServerEnvironmentOnConflict>
}


export interface MutationRootInsertPlatformServerEnvironmentOneArgs {
  object: PlatformServerEnvironmentInsertInput,
  on_conflict?: Maybe<PlatformServerEnvironmentOnConflict>
}


export interface MutationRootInsertPlatformServerOneArgs {
  object: PlatformServerInsertInput,
  on_conflict?: Maybe<PlatformServerOnConflict>
}


export interface MutationRootInsertPlatformServerRetryStatusArgs {
  objects: Array<PlatformServerRetryStatusInsertInput>,
  on_conflict?: Maybe<PlatformServerRetryStatusOnConflict>
}


export interface MutationRootInsertPlatformServerRetryStatusOneArgs {
  object: PlatformServerRetryStatusInsertInput,
  on_conflict?: Maybe<PlatformServerRetryStatusOnConflict>
}


export interface MutationRootInsertPlatformServerStatusArgs {
  objects: Array<PlatformServerStatusInsertInput>,
  on_conflict?: Maybe<PlatformServerStatusOnConflict>
}


export interface MutationRootInsertPlatformServerStatusOneArgs {
  object: PlatformServerStatusInsertInput,
  on_conflict?: Maybe<PlatformServerStatusOnConflict>
}


export interface MutationRootInsertPlatformServerTypeArgs {
  objects: Array<PlatformServerTypeInsertInput>,
  on_conflict?: Maybe<PlatformServerTypeOnConflict>
}


export interface MutationRootInsertPlatformServerTypeOneArgs {
  object: PlatformServerTypeInsertInput,
  on_conflict?: Maybe<PlatformServerTypeOnConflict>
}


export interface MutationRootInsertPlatformServersToSupportedLeveragesArgs {
  objects: Array<PlatformServersToSupportedLeveragesInsertInput>,
  on_conflict?: Maybe<PlatformServersToSupportedLeveragesOnConflict>
}


export interface MutationRootInsertPlatformServersToSupportedLeveragesOneArgs {
  object: PlatformServersToSupportedLeveragesInsertInput,
  on_conflict?: Maybe<PlatformServersToSupportedLeveragesOnConflict>
}


export interface MutationRootInsertPlatformSymbolArgs {
  objects: Array<PlatformSymbolInsertInput>,
  on_conflict?: Maybe<PlatformSymbolOnConflict>
}


export interface MutationRootInsertPlatformSymbolOneArgs {
  object: PlatformSymbolInsertInput,
  on_conflict?: Maybe<PlatformSymbolOnConflict>
}


export interface MutationRootInsertProfileArgs {
  objects: Array<ProfileInsertInput>,
  on_conflict?: Maybe<ProfileOnConflict>
}


export interface MutationRootInsertProfileActivityArgs {
  objects: Array<ProfileActivityInsertInput>,
  on_conflict?: Maybe<ProfileActivityOnConflict>
}


export interface MutationRootInsertProfileActivityOneArgs {
  object: ProfileActivityInsertInput,
  on_conflict?: Maybe<ProfileActivityOnConflict>
}


export interface MutationRootInsertProfileCategoryArgs {
  objects: Array<ProfileCategoryInsertInput>,
  on_conflict?: Maybe<ProfileCategoryOnConflict>
}


export interface MutationRootInsertProfileCategoryOneArgs {
  object: ProfileCategoryInsertInput,
  on_conflict?: Maybe<ProfileCategoryOnConflict>
}


export interface MutationRootInsertProfileEntryTypeArgs {
  objects: Array<ProfileEntryTypeInsertInput>,
  on_conflict?: Maybe<ProfileEntryTypeOnConflict>
}


export interface MutationRootInsertProfileEntryTypeOneArgs {
  object: ProfileEntryTypeInsertInput,
  on_conflict?: Maybe<ProfileEntryTypeOnConflict>
}


export interface MutationRootInsertProfileFlagsArgs {
  objects: Array<ProfileFlagsInsertInput>,
  on_conflict?: Maybe<ProfileFlagsOnConflict>
}


export interface MutationRootInsertProfileFlagsOneArgs {
  object: ProfileFlagsInsertInput,
  on_conflict?: Maybe<ProfileFlagsOnConflict>
}


export interface MutationRootInsertProfileNoteArgs {
  objects: Array<ProfileNoteInsertInput>,
  on_conflict?: Maybe<ProfileNoteOnConflict>
}


export interface MutationRootInsertProfileNoteOneArgs {
  object: ProfileNoteInsertInput,
  on_conflict?: Maybe<ProfileNoteOnConflict>
}


export interface MutationRootInsertProfileNoteVersionArgs {
  objects: Array<ProfileNoteVersionInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionOnConflict>
}


export interface MutationRootInsertProfileNoteVersionOneArgs {
  object: ProfileNoteVersionInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionOnConflict>
}


export interface MutationRootInsertProfileNoteVersionsStakeholderMentionsArgs {
  objects: Array<ProfileNoteVersionsStakeholderMentionsInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderMentionsOnConflict>
}


export interface MutationRootInsertProfileNoteVersionsStakeholderMentionsOneArgs {
  object: ProfileNoteVersionsStakeholderMentionsInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderMentionsOnConflict>
}


export interface MutationRootInsertProfileNoteVersionsStakeholderRelevantPartiesArgs {
  objects: Array<ProfileNoteVersionsStakeholderRelevantPartiesInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesOnConflict>
}


export interface MutationRootInsertProfileNoteVersionsStakeholderRelevantPartiesOneArgs {
  object: ProfileNoteVersionsStakeholderRelevantPartiesInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesOnConflict>
}


export interface MutationRootInsertProfileOneArgs {
  object: ProfileInsertInput,
  on_conflict?: Maybe<ProfileOnConflict>
}


export interface MutationRootInsertProfileRiskArgs {
  objects: Array<ProfileRiskInsertInput>,
  on_conflict?: Maybe<ProfileRiskOnConflict>
}


export interface MutationRootInsertProfileRiskOneArgs {
  object: ProfileRiskInsertInput,
  on_conflict?: Maybe<ProfileRiskOnConflict>
}


export interface MutationRootInsertProfileStatusArgs {
  objects: Array<ProfileStatusInsertInput>,
  on_conflict?: Maybe<ProfileStatusOnConflict>
}


export interface MutationRootInsertProfileStatusOneArgs {
  object: ProfileStatusInsertInput,
  on_conflict?: Maybe<ProfileStatusOnConflict>
}


export interface MutationRootInsertProfileTypeArgs {
  objects: Array<ProfileTypeInsertInput>,
  on_conflict?: Maybe<ProfileTypeOnConflict>
}


export interface MutationRootInsertProfileTypeOneArgs {
  object: ProfileTypeInsertInput,
  on_conflict?: Maybe<ProfileTypeOnConflict>
}


export interface MutationRootInsertProfilesToTagsArgs {
  objects: Array<ProfilesToTagsInsertInput>,
  on_conflict?: Maybe<ProfilesToTagsOnConflict>
}


export interface MutationRootInsertProfilesToTagsOneArgs {
  object: ProfilesToTagsInsertInput,
  on_conflict?: Maybe<ProfilesToTagsOnConflict>
}


export interface MutationRootInsertRepresentativesToProfilesArgs {
  objects: Array<RepresentativesToProfilesInsertInput>,
  on_conflict?: Maybe<RepresentativesToProfilesOnConflict>
}


export interface MutationRootInsertRepresentativesToProfilesOneArgs {
  object: RepresentativesToProfilesInsertInput,
  on_conflict?: Maybe<RepresentativesToProfilesOnConflict>
}


export interface MutationRootInsertRequestArgs {
  objects: Array<RequestInsertInput>,
  on_conflict?: Maybe<RequestOnConflict>
}


export interface MutationRootInsertRequestOneArgs {
  object: RequestInsertInput,
  on_conflict?: Maybe<RequestOnConflict>
}


export interface MutationRootInsertRequestStatusArgs {
  objects: Array<RequestStatusInsertInput>,
  on_conflict?: Maybe<RequestStatusOnConflict>
}


export interface MutationRootInsertRequestStatusOneArgs {
  object: RequestStatusInsertInput,
  on_conflict?: Maybe<RequestStatusOnConflict>
}


export interface MutationRootInsertRequestTypeArgs {
  objects: Array<RequestTypeInsertInput>,
  on_conflict?: Maybe<RequestTypeOnConflict>
}


export interface MutationRootInsertRequestTypeOneArgs {
  object: RequestTypeInsertInput,
  on_conflict?: Maybe<RequestTypeOnConflict>
}


export interface MutationRootInsertSecurityArgs {
  objects: Array<SecurityInsertInput>,
  on_conflict?: Maybe<SecurityOnConflict>
}


export interface MutationRootInsertSecurityGridViewArgs {
  objects: Array<SecurityGridViewInsertInput>
}


export interface MutationRootInsertSecurityGridViewOneArgs {
  object: SecurityGridViewInsertInput
}


export interface MutationRootInsertSecurityOneArgs {
  object: SecurityInsertInput,
  on_conflict?: Maybe<SecurityOnConflict>
}


export interface MutationRootInsertSegmentArgs {
  objects: Array<SegmentInsertInput>,
  on_conflict?: Maybe<SegmentOnConflict>
}


export interface MutationRootInsertSegmentConditionArgs {
  objects: Array<SegmentConditionInsertInput>,
  on_conflict?: Maybe<SegmentConditionOnConflict>
}


export interface MutationRootInsertSegmentConditionOneArgs {
  object: SegmentConditionInsertInput,
  on_conflict?: Maybe<SegmentConditionOnConflict>
}


export interface MutationRootInsertSegmentCriterionArgs {
  objects: Array<SegmentCriterionInsertInput>,
  on_conflict?: Maybe<SegmentCriterionOnConflict>
}


export interface MutationRootInsertSegmentCriterionOneArgs {
  object: SegmentCriterionInsertInput,
  on_conflict?: Maybe<SegmentCriterionOnConflict>
}


export interface MutationRootInsertSegmentCriterionTypeArgs {
  objects: Array<SegmentCriterionTypeInsertInput>,
  on_conflict?: Maybe<SegmentCriterionTypeOnConflict>
}


export interface MutationRootInsertSegmentCriterionTypeOneArgs {
  object: SegmentCriterionTypeInsertInput,
  on_conflict?: Maybe<SegmentCriterionTypeOnConflict>
}


export interface MutationRootInsertSegmentOneArgs {
  object: SegmentInsertInput,
  on_conflict?: Maybe<SegmentOnConflict>
}


export interface MutationRootInsertSegmentOperatorArgs {
  objects: Array<SegmentOperatorInsertInput>,
  on_conflict?: Maybe<SegmentOperatorOnConflict>
}


export interface MutationRootInsertSegmentOperatorOneArgs {
  object: SegmentOperatorInsertInput,
  on_conflict?: Maybe<SegmentOperatorOnConflict>
}


export interface MutationRootInsertSegmentOperatorsToSegmentPropertyConditionsArgs {
  objects: Array<SegmentOperatorsToSegmentPropertyConditionsInsertInput>,
  on_conflict?: Maybe<SegmentOperatorsToSegmentPropertyConditionsOnConflict>
}


export interface MutationRootInsertSegmentOperatorsToSegmentPropertyConditionsOneArgs {
  object: SegmentOperatorsToSegmentPropertyConditionsInsertInput,
  on_conflict?: Maybe<SegmentOperatorsToSegmentPropertyConditionsOnConflict>
}


export interface MutationRootInsertSegmentPropertyArgs {
  objects: Array<SegmentPropertyInsertInput>,
  on_conflict?: Maybe<SegmentPropertyOnConflict>
}


export interface MutationRootInsertSegmentPropertyConditionArgs {
  objects: Array<SegmentPropertyConditionInsertInput>,
  on_conflict?: Maybe<SegmentPropertyConditionOnConflict>
}


export interface MutationRootInsertSegmentPropertyConditionOneArgs {
  object: SegmentPropertyConditionInsertInput,
  on_conflict?: Maybe<SegmentPropertyConditionOnConflict>
}


export interface MutationRootInsertSegmentPropertyOneArgs {
  object: SegmentPropertyInsertInput,
  on_conflict?: Maybe<SegmentPropertyOnConflict>
}


export interface MutationRootInsertSourceOfFundsArgs {
  objects: Array<SourceOfFundsInsertInput>,
  on_conflict?: Maybe<SourceOfFundsOnConflict>
}


export interface MutationRootInsertSourceOfFundsOneArgs {
  object: SourceOfFundsInsertInput,
  on_conflict?: Maybe<SourceOfFundsOnConflict>
}


export interface MutationRootInsertSpreadModeArgs {
  objects: Array<SpreadModeInsertInput>,
  on_conflict?: Maybe<SpreadModeOnConflict>
}


export interface MutationRootInsertSpreadModeOneArgs {
  object: SpreadModeInsertInput,
  on_conflict?: Maybe<SpreadModeOnConflict>
}


export interface MutationRootInsertStakeArgs {
  objects: Array<StakeInsertInput>,
  on_conflict?: Maybe<StakeOnConflict>
}


export interface MutationRootInsertStakeAncestryArgs {
  objects: Array<StakeAncestryInsertInput>,
  on_conflict?: Maybe<StakeAncestryOnConflict>
}


export interface MutationRootInsertStakeAncestryOneArgs {
  object: StakeAncestryInsertInput,
  on_conflict?: Maybe<StakeAncestryOnConflict>
}


export interface MutationRootInsertStakeOneArgs {
  object: StakeInsertInput,
  on_conflict?: Maybe<StakeOnConflict>
}


export interface MutationRootInsertStakeTitleArgs {
  objects: Array<StakeTitleInsertInput>,
  on_conflict?: Maybe<StakeTitleOnConflict>
}


export interface MutationRootInsertStakeTitleOneArgs {
  object: StakeTitleInsertInput,
  on_conflict?: Maybe<StakeTitleOnConflict>
}


export interface MutationRootInsertStakeholderArgs {
  objects: Array<StakeholderInsertInput>,
  on_conflict?: Maybe<StakeholderOnConflict>
}


export interface MutationRootInsertStakeholderAttributeArgs {
  objects: Array<StakeholderAttributeInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeOnConflict>
}


export interface MutationRootInsertStakeholderAttributeKeyArgs {
  objects: Array<StakeholderAttributeKeyInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeKeyOnConflict>
}


export interface MutationRootInsertStakeholderAttributeKeyOneArgs {
  object: StakeholderAttributeKeyInsertInput,
  on_conflict?: Maybe<StakeholderAttributeKeyOnConflict>
}


export interface MutationRootInsertStakeholderAttributeKeysToStakeholderClassesArgs {
  objects: Array<StakeholderAttributeKeysToStakeholderClassesInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeKeysToStakeholderClassesOnConflict>
}


export interface MutationRootInsertStakeholderAttributeKeysToStakeholderClassesOneArgs {
  object: StakeholderAttributeKeysToStakeholderClassesInsertInput,
  on_conflict?: Maybe<StakeholderAttributeKeysToStakeholderClassesOnConflict>
}


export interface MutationRootInsertStakeholderAttributeOneArgs {
  object: StakeholderAttributeInsertInput,
  on_conflict?: Maybe<StakeholderAttributeOnConflict>
}


export interface MutationRootInsertStakeholderAttributeSectionArgs {
  objects: Array<StakeholderAttributeSectionInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeSectionOnConflict>
}


export interface MutationRootInsertStakeholderAttributeSectionOneArgs {
  object: StakeholderAttributeSectionInsertInput,
  on_conflict?: Maybe<StakeholderAttributeSectionOnConflict>
}


export interface MutationRootInsertStakeholderAttributeSubsectionArgs {
  objects: Array<StakeholderAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeSubsectionOnConflict>
}


export interface MutationRootInsertStakeholderAttributeSubsectionOneArgs {
  object: StakeholderAttributeSubsectionInsertInput,
  on_conflict?: Maybe<StakeholderAttributeSubsectionOnConflict>
}


export interface MutationRootInsertStakeholderClassArgs {
  objects: Array<StakeholderClassInsertInput>,
  on_conflict?: Maybe<StakeholderClassOnConflict>
}


export interface MutationRootInsertStakeholderClassOneArgs {
  object: StakeholderClassInsertInput,
  on_conflict?: Maybe<StakeholderClassOnConflict>
}


export interface MutationRootInsertStakeholderOneArgs {
  object: StakeholderInsertInput,
  on_conflict?: Maybe<StakeholderOnConflict>
}


export interface MutationRootInsertStakesToAdminPermissionsArgs {
  objects: Array<StakesToAdminPermissionsInsertInput>,
  on_conflict?: Maybe<StakesToAdminPermissionsOnConflict>
}


export interface MutationRootInsertStakesToAdminPermissionsOneArgs {
  object: StakesToAdminPermissionsInsertInput,
  on_conflict?: Maybe<StakesToAdminPermissionsOnConflict>
}


export interface MutationRootInsertStakesToStakeTitlesArgs {
  objects: Array<StakesToStakeTitlesInsertInput>,
  on_conflict?: Maybe<StakesToStakeTitlesOnConflict>
}


export interface MutationRootInsertStakesToStakeTitlesOneArgs {
  object: StakesToStakeTitlesInsertInput,
  on_conflict?: Maybe<StakesToStakeTitlesOnConflict>
}


export interface MutationRootInsertSwapModeArgs {
  objects: Array<SwapModeInsertInput>,
  on_conflict?: Maybe<SwapModeOnConflict>
}


export interface MutationRootInsertSwapModeOneArgs {
  object: SwapModeInsertInput,
  on_conflict?: Maybe<SwapModeOnConflict>
}


export interface MutationRootInsertSymbolArgs {
  objects: Array<SymbolInsertInput>,
  on_conflict?: Maybe<SymbolOnConflict>
}


export interface MutationRootInsertSymbolExtensionArgs {
  objects: Array<SymbolExtensionInsertInput>,
  on_conflict?: Maybe<SymbolExtensionOnConflict>
}


export interface MutationRootInsertSymbolExtensionOneArgs {
  object: SymbolExtensionInsertInput,
  on_conflict?: Maybe<SymbolExtensionOnConflict>
}


export interface MutationRootInsertSymbolOneArgs {
  object: SymbolInsertInput,
  on_conflict?: Maybe<SymbolOnConflict>
}


export interface MutationRootInsertSymbolTypeArgs {
  objects: Array<SymbolTypeInsertInput>,
  on_conflict?: Maybe<SymbolTypeOnConflict>
}


export interface MutationRootInsertSymbolTypeOneArgs {
  object: SymbolTypeInsertInput,
  on_conflict?: Maybe<SymbolTypeOnConflict>
}


export interface MutationRootInsertTagArgs {
  objects: Array<TagInsertInput>,
  on_conflict?: Maybe<TagOnConflict>
}


export interface MutationRootInsertTagOneArgs {
  object: TagInsertInput,
  on_conflict?: Maybe<TagOnConflict>
}


export interface MutationRootInsertTradeRuleArgs {
  objects: Array<TradeRuleInsertInput>,
  on_conflict?: Maybe<TradeRuleOnConflict>
}


export interface MutationRootInsertTradeRuleOneArgs {
  object: TradeRuleInsertInput,
  on_conflict?: Maybe<TradeRuleOnConflict>
}


export interface MutationRootInsertTransactionArgs {
  objects: Array<TransactionInsertInput>,
  on_conflict?: Maybe<TransactionOnConflict>
}


export interface MutationRootInsertTransactionCreationRequestArgs {
  objects: Array<TransactionCreationRequestInsertInput>,
  on_conflict?: Maybe<TransactionCreationRequestOnConflict>
}


export interface MutationRootInsertTransactionCreationRequestOneArgs {
  object: TransactionCreationRequestInsertInput,
  on_conflict?: Maybe<TransactionCreationRequestOnConflict>
}


export interface MutationRootInsertTransactionOneArgs {
  object: TransactionInsertInput,
  on_conflict?: Maybe<TransactionOnConflict>
}


export interface MutationRootInsertTransactionPlatformStatusArgs {
  objects: Array<TransactionPlatformStatusInsertInput>,
  on_conflict?: Maybe<TransactionPlatformStatusOnConflict>
}


export interface MutationRootInsertTransactionPlatformStatusOneArgs {
  object: TransactionPlatformStatusInsertInput,
  on_conflict?: Maybe<TransactionPlatformStatusOnConflict>
}


export interface MutationRootInsertTransactionProfileGridViewArgs {
  objects: Array<TransactionProfileGridViewInsertInput>
}


export interface MutationRootInsertTransactionProfileGridViewOneArgs {
  object: TransactionProfileGridViewInsertInput
}


export interface MutationRootInsertTransactionStatusArgs {
  objects: Array<TransactionStatusInsertInput>,
  on_conflict?: Maybe<TransactionStatusOnConflict>
}


export interface MutationRootInsertTransactionStatusOneArgs {
  object: TransactionStatusInsertInput,
  on_conflict?: Maybe<TransactionStatusOnConflict>
}


export interface MutationRootInsertTransactionTypeArgs {
  objects: Array<TransactionTypeInsertInput>,
  on_conflict?: Maybe<TransactionTypeOnConflict>
}


export interface MutationRootInsertTransactionTypeOneArgs {
  object: TransactionTypeInsertInput,
  on_conflict?: Maybe<TransactionTypeOnConflict>
}


export interface MutationRootInsertTransferApprovalOptionArgs {
  objects: Array<TransferApprovalOptionInsertInput>,
  on_conflict?: Maybe<TransferApprovalOptionOnConflict>
}


export interface MutationRootInsertTransferApprovalOptionOneArgs {
  object: TransferApprovalOptionInsertInput,
  on_conflict?: Maybe<TransferApprovalOptionOnConflict>
}


export interface MutationRootInsertUserAccessArgs {
  objects: Array<UserAccessInsertInput>,
  on_conflict?: Maybe<UserAccessOnConflict>
}


export interface MutationRootInsertUserAccessOneArgs {
  object: UserAccessInsertInput,
  on_conflict?: Maybe<UserAccessOnConflict>
}


export interface MutationRootInsertUserInviteArgs {
  objects: Array<UserInviteInsertInput>,
  on_conflict?: Maybe<UserInviteOnConflict>
}


export interface MutationRootInsertUserInviteOneArgs {
  object: UserInviteInsertInput,
  on_conflict?: Maybe<UserInviteOnConflict>
}


export interface MutationRootInsertUserPermissionArgs {
  objects: Array<UserPermissionInsertInput>,
  on_conflict?: Maybe<UserPermissionOnConflict>
}


export interface MutationRootInsertUserPermissionOneArgs {
  object: UserPermissionInsertInput,
  on_conflict?: Maybe<UserPermissionOnConflict>
}


export interface MutationRootInsertWorkflowArgs {
  objects: Array<WorkflowInsertInput>,
  on_conflict?: Maybe<WorkflowOnConflict>
}


export interface MutationRootInsertWorkflowOneArgs {
  object: WorkflowInsertInput,
  on_conflict?: Maybe<WorkflowOnConflict>
}


export interface MutationRootInsertWorkflowStageArgs {
  objects: Array<WorkflowStageInsertInput>,
  on_conflict?: Maybe<WorkflowStageOnConflict>
}


export interface MutationRootInsertWorkflowStageOneArgs {
  object: WorkflowStageInsertInput,
  on_conflict?: Maybe<WorkflowStageOnConflict>
}


export interface MutationRootInsertWorkflowsToProfilesArgs {
  objects: Array<WorkflowsToProfilesInsertInput>,
  on_conflict?: Maybe<WorkflowsToProfilesOnConflict>
}


export interface MutationRootInsertWorkflowsToProfilesOneArgs {
  object: WorkflowsToProfilesInsertInput,
  on_conflict?: Maybe<WorkflowsToProfilesOnConflict>
}


export interface MutationRootInsertYearsExperienceArgs {
  objects: Array<YearsExperienceInsertInput>,
  on_conflict?: Maybe<YearsExperienceOnConflict>
}


export interface MutationRootInsertYearsExperienceOneArgs {
  object: YearsExperienceInsertInput,
  on_conflict?: Maybe<YearsExperienceOnConflict>
}


export interface MutationRootUpdateAccessFormatArgs {
  _set?: Maybe<AccessFormatSetInput>,
  where: AccessFormatBoolExp
}


export interface MutationRootUpdateAccessFormatByPkArgs {
  _set?: Maybe<AccessFormatSetInput>,
  pk_columns: AccessFormatPkColumnsInput
}


export interface MutationRootUpdateAccessFormatTypeArgs {
  _set?: Maybe<AccessFormatTypeSetInput>,
  where: AccessFormatTypeBoolExp
}


export interface MutationRootUpdateAccessFormatTypeByPkArgs {
  _set?: Maybe<AccessFormatTypeSetInput>,
  pk_columns: AccessFormatTypePkColumnsInput
}


export interface MutationRootUpdateAccountArgs {
  _inc?: Maybe<AccountIncInput>,
  _set?: Maybe<AccountSetInput>,
  where: AccountBoolExp
}


export interface MutationRootUpdateAccountByPkArgs {
  _inc?: Maybe<AccountIncInput>,
  _set?: Maybe<AccountSetInput>,
  pk_columns: AccountPkColumnsInput
}


export interface MutationRootUpdateAccountCreationRequestArgs {
  _inc?: Maybe<AccountCreationRequestIncInput>,
  _set?: Maybe<AccountCreationRequestSetInput>,
  where: AccountCreationRequestBoolExp
}


export interface MutationRootUpdateAccountCreationRequestByPkArgs {
  _inc?: Maybe<AccountCreationRequestIncInput>,
  _set?: Maybe<AccountCreationRequestSetInput>,
  pk_columns: AccountCreationRequestPkColumnsInput
}


export interface MutationRootUpdateAccountGroupArgs {
  _inc?: Maybe<AccountGroupIncInput>,
  _set?: Maybe<AccountGroupSetInput>,
  where: AccountGroupBoolExp
}


export interface MutationRootUpdateAccountGroupByPkArgs {
  _inc?: Maybe<AccountGroupIncInput>,
  _set?: Maybe<AccountGroupSetInput>,
  pk_columns: AccountGroupPkColumnsInput
}


export interface MutationRootUpdateAccountGroupDefaultArgs {
  _set?: Maybe<AccountGroupDefaultSetInput>,
  where: AccountGroupDefaultBoolExp
}


export interface MutationRootUpdateAccountGroupDefaultByPkArgs {
  _set?: Maybe<AccountGroupDefaultSetInput>,
  pk_columns: AccountGroupDefaultPkColumnsInput
}


export interface MutationRootUpdateAccountGroupSecurityArgs {
  _set?: Maybe<AccountGroupSecuritySetInput>,
  where: AccountGroupSecurityBoolExp
}


export interface MutationRootUpdateAccountGroupSecurityByPkArgs {
  _set?: Maybe<AccountGroupSecuritySetInput>,
  pk_columns: AccountGroupSecurityPkColumnsInput
}


export interface MutationRootUpdateAccountGroupsToPartnersArgs {
  _set?: Maybe<AccountGroupsToPartnersSetInput>,
  where: AccountGroupsToPartnersBoolExp
}


export interface MutationRootUpdateAccountGroupsToPartnersByPkArgs {
  _set?: Maybe<AccountGroupsToPartnersSetInput>,
  pk_columns: AccountGroupsToPartnersPkColumnsInput
}


export interface MutationRootUpdateAccountTypeArgs {
  _set?: Maybe<AccountTypeSetInput>,
  where: AccountTypeBoolExp
}


export interface MutationRootUpdateAccountTypeByPkArgs {
  _set?: Maybe<AccountTypeSetInput>,
  pk_columns: AccountTypePkColumnsInput
}


export interface MutationRootUpdateActivitiesToChannelsArgs {
  _set?: Maybe<ActivitiesToChannelsSetInput>,
  where: ActivitiesToChannelsBoolExp
}


export interface MutationRootUpdateActivitiesToChannelsByPkArgs {
  _set?: Maybe<ActivitiesToChannelsSetInput>,
  pk_columns: ActivitiesToChannelsPkColumnsInput
}


export interface MutationRootUpdateActivityArgs {
  _append?: Maybe<ActivityAppendInput>,
  _delete_at_path?: Maybe<ActivityDeleteAtPathInput>,
  _delete_elem?: Maybe<ActivityDeleteElemInput>,
  _delete_key?: Maybe<ActivityDeleteKeyInput>,
  _prepend?: Maybe<ActivityPrependInput>,
  _set?: Maybe<ActivitySetInput>,
  where: ActivityBoolExp
}


export interface MutationRootUpdateActivityByPkArgs {
  _append?: Maybe<ActivityAppendInput>,
  _delete_at_path?: Maybe<ActivityDeleteAtPathInput>,
  _delete_elem?: Maybe<ActivityDeleteElemInput>,
  _delete_key?: Maybe<ActivityDeleteKeyInput>,
  _prepend?: Maybe<ActivityPrependInput>,
  _set?: Maybe<ActivitySetInput>,
  pk_columns: ActivityPkColumnsInput
}


export interface MutationRootUpdateActivityCategoryArgs {
  _set?: Maybe<ActivityCategorySetInput>,
  where: ActivityCategoryBoolExp
}


export interface MutationRootUpdateActivityCategoryByPkArgs {
  _set?: Maybe<ActivityCategorySetInput>,
  pk_columns: ActivityCategoryPkColumnsInput
}


export interface MutationRootUpdateActivityChannelArgs {
  _set?: Maybe<ActivityChannelSetInput>,
  where: ActivityChannelBoolExp
}


export interface MutationRootUpdateActivityChannelByPkArgs {
  _set?: Maybe<ActivityChannelSetInput>,
  pk_columns: ActivityChannelPkColumnsInput
}


export interface MutationRootUpdateActivitySubjectArgs {
  _append?: Maybe<ActivitySubjectAppendInput>,
  _delete_at_path?: Maybe<ActivitySubjectDeleteAtPathInput>,
  _delete_elem?: Maybe<ActivitySubjectDeleteElemInput>,
  _delete_key?: Maybe<ActivitySubjectDeleteKeyInput>,
  _prepend?: Maybe<ActivitySubjectPrependInput>,
  _set?: Maybe<ActivitySubjectSetInput>,
  where: ActivitySubjectBoolExp
}


export interface MutationRootUpdateActivitySubjectByPkArgs {
  _append?: Maybe<ActivitySubjectAppendInput>,
  _delete_at_path?: Maybe<ActivitySubjectDeleteAtPathInput>,
  _delete_elem?: Maybe<ActivitySubjectDeleteElemInput>,
  _delete_key?: Maybe<ActivitySubjectDeleteKeyInput>,
  _prepend?: Maybe<ActivitySubjectPrependInput>,
  _set?: Maybe<ActivitySubjectSetInput>,
  pk_columns: ActivitySubjectPkColumnsInput
}


export interface MutationRootUpdateActivityTemplateArgs {
  _set?: Maybe<ActivityTemplateSetInput>,
  where: ActivityTemplateBoolExp
}


export interface MutationRootUpdateActivityTemplateByPkArgs {
  _set?: Maybe<ActivityTemplateSetInput>,
  pk_columns: ActivityTemplatePkColumnsInput
}


export interface MutationRootUpdateAdminPermissionArgs {
  _set?: Maybe<AdminPermissionSetInput>,
  where: AdminPermissionBoolExp
}


export interface MutationRootUpdateAdminPermissionByPkArgs {
  _set?: Maybe<AdminPermissionSetInput>,
  pk_columns: AdminPermissionPkColumnsInput
}


export interface MutationRootUpdateAgreementArgs {
  _set?: Maybe<AgreementSetInput>,
  where: AgreementBoolExp
}


export interface MutationRootUpdateAgreementByPkArgs {
  _set?: Maybe<AgreementSetInput>,
  pk_columns: AgreementPkColumnsInput
}


export interface MutationRootUpdateAgreementScenarioArgs {
  _set?: Maybe<AgreementScenarioSetInput>,
  where: AgreementScenarioBoolExp
}


export interface MutationRootUpdateAgreementScenarioByPkArgs {
  _set?: Maybe<AgreementScenarioSetInput>,
  pk_columns: AgreementScenarioPkColumnsInput
}


export interface MutationRootUpdateAgreementsSatisfiedArgs {
  _set?: Maybe<AgreementsSatisfiedSetInput>,
  where: AgreementsSatisfiedBoolExp
}


export interface MutationRootUpdateAgreementsSatisfiedByPkArgs {
  _set?: Maybe<AgreementsSatisfiedSetInput>,
  pk_columns: AgreementsSatisfiedPkColumnsInput
}


export interface MutationRootUpdateAgreementsToPaymentMethodsArgs {
  _set?: Maybe<AgreementsToPaymentMethodsSetInput>,
  where: AgreementsToPaymentMethodsBoolExp
}


export interface MutationRootUpdateAgreementsToPaymentMethodsByPkArgs {
  _set?: Maybe<AgreementsToPaymentMethodsSetInput>,
  pk_columns: AgreementsToPaymentMethodsPkColumnsInput
}


export interface MutationRootUpdateAnnualIncomeArgs {
  _set?: Maybe<AnnualIncomeSetInput>,
  where: AnnualIncomeBoolExp
}


export interface MutationRootUpdateAnnualIncomeByPkArgs {
  _set?: Maybe<AnnualIncomeSetInput>,
  pk_columns: AnnualIncomePkColumnsInput
}


export interface MutationRootUpdateApiKeyArgs {
  _set?: Maybe<ApiKeySetInput>,
  where: ApiKeyBoolExp
}


export interface MutationRootUpdateApiKeyByPkArgs {
  _set?: Maybe<ApiKeySetInput>,
  pk_columns: ApiKeyPkColumnsInput
}


export interface MutationRootUpdateAttributeMetadataArgs {
  _set?: Maybe<AttributeMetadataSetInput>,
  where: AttributeMetadataBoolExp
}


export interface MutationRootUpdateAttributeMetadataByPkArgs {
  _set?: Maybe<AttributeMetadataSetInput>,
  pk_columns: AttributeMetadataPkColumnsInput
}


export interface MutationRootUpdateAttributeTypeArgs {
  _set?: Maybe<AttributeTypeSetInput>,
  where: AttributeTypeBoolExp
}


export interface MutationRootUpdateAttributeTypeByPkArgs {
  _set?: Maybe<AttributeTypeSetInput>,
  pk_columns: AttributeTypePkColumnsInput
}


export interface MutationRootUpdateBankAccountArgs {
  _set?: Maybe<BankAccountSetInput>,
  where: BankAccountBoolExp
}


export interface MutationRootUpdateBankAccountByPkArgs {
  _set?: Maybe<BankAccountSetInput>,
  pk_columns: BankAccountPkColumnsInput
}


export interface MutationRootUpdateBookTypeArgs {
  _set?: Maybe<BookTypeSetInput>,
  where: BookTypeBoolExp
}


export interface MutationRootUpdateBookTypeByPkArgs {
  _set?: Maybe<BookTypeSetInput>,
  pk_columns: BookTypePkColumnsInput
}


export interface MutationRootUpdateChargeUnitArgs {
  _set?: Maybe<ChargeUnitSetInput>,
  where: ChargeUnitBoolExp
}


export interface MutationRootUpdateChargeUnitByPkArgs {
  _set?: Maybe<ChargeUnitSetInput>,
  pk_columns: ChargeUnitPkColumnsInput
}


export interface MutationRootUpdateCountriesToPhoneCodesArgs {
  _set?: Maybe<CountriesToPhoneCodesSetInput>,
  where: CountriesToPhoneCodesBoolExp
}


export interface MutationRootUpdateCountriesToPhoneCodesByPkArgs {
  _set?: Maybe<CountriesToPhoneCodesSetInput>,
  pk_columns: CountriesToPhoneCodesPkColumnsInput
}


export interface MutationRootUpdateCountryArgs {
  _set?: Maybe<CountrySetInput>,
  where: CountryBoolExp
}


export interface MutationRootUpdateCountryByPkArgs {
  _set?: Maybe<CountrySetInput>,
  pk_columns: CountryPkColumnsInput
}


export interface MutationRootUpdateCurrenciesToOrganizationsArgs {
  _set?: Maybe<CurrenciesToOrganizationsSetInput>,
  where: CurrenciesToOrganizationsBoolExp
}


export interface MutationRootUpdateCurrenciesToOrganizationsByPkArgs {
  _set?: Maybe<CurrenciesToOrganizationsSetInput>,
  pk_columns: CurrenciesToOrganizationsPkColumnsInput
}


export interface MutationRootUpdateCurrencyArgs {
  _inc?: Maybe<CurrencyIncInput>,
  _set?: Maybe<CurrencySetInput>,
  where: CurrencyBoolExp
}


export interface MutationRootUpdateCurrencyByPkArgs {
  _inc?: Maybe<CurrencyIncInput>,
  _set?: Maybe<CurrencySetInput>,
  pk_columns: CurrencyPkColumnsInput
}


export interface MutationRootUpdateDailyAccountRevenueSummaryArgs {
  _inc?: Maybe<DailyAccountRevenueSummaryIncInput>,
  _set?: Maybe<DailyAccountRevenueSummarySetInput>,
  where: DailyAccountRevenueSummaryBoolExp
}


export interface MutationRootUpdateDailyAccountRevenueSummaryByPkArgs {
  _inc?: Maybe<DailyAccountRevenueSummaryIncInput>,
  _set?: Maybe<DailyAccountRevenueSummarySetInput>,
  pk_columns: DailyAccountRevenueSummaryPkColumnsInput
}


export interface MutationRootUpdateDailyAccountStatusArgs {
  _inc?: Maybe<DailyAccountStatusIncInput>,
  _set?: Maybe<DailyAccountStatusSetInput>,
  where: DailyAccountStatusBoolExp
}


export interface MutationRootUpdateDailyAccountStatusByPkArgs {
  _inc?: Maybe<DailyAccountStatusIncInput>,
  _set?: Maybe<DailyAccountStatusSetInput>,
  pk_columns: DailyAccountStatusPkColumnsInput
}


export interface MutationRootUpdateDailyVolumePerAccountArgs {
  _inc?: Maybe<DailyVolumePerAccountIncInput>,
  _set?: Maybe<DailyVolumePerAccountSetInput>,
  where: DailyVolumePerAccountBoolExp
}


export interface MutationRootUpdateDailyVolumePerAccountByPkArgs {
  _inc?: Maybe<DailyVolumePerAccountIncInput>,
  _set?: Maybe<DailyVolumePerAccountSetInput>,
  pk_columns: DailyVolumePerAccountPkColumnsInput
}


export interface MutationRootUpdateDailyVolumePerProfileArgs {
  _inc?: Maybe<DailyVolumePerProfileIncInput>,
  _set?: Maybe<DailyVolumePerProfileSetInput>,
  where: DailyVolumePerProfileBoolExp
}


export interface MutationRootUpdateDailyVolumePerProfileByPkArgs {
  _inc?: Maybe<DailyVolumePerProfileIncInput>,
  _set?: Maybe<DailyVolumePerProfileSetInput>,
  pk_columns: DailyVolumePerProfilePkColumnsInput
}


export interface MutationRootUpdateEmploymentStatusArgs {
  _set?: Maybe<EmploymentStatusSetInput>,
  where: EmploymentStatusBoolExp
}


export interface MutationRootUpdateEmploymentStatusByPkArgs {
  _set?: Maybe<EmploymentStatusSetInput>,
  pk_columns: EmploymentStatusPkColumnsInput
}


export interface MutationRootUpdateExperienceTypeArgs {
  _set?: Maybe<ExperienceTypeSetInput>,
  where: ExperienceTypeBoolExp
}


export interface MutationRootUpdateExperienceTypeByPkArgs {
  _set?: Maybe<ExperienceTypeSetInput>,
  pk_columns: ExperienceTypePkColumnsInput
}


export interface MutationRootUpdateFileArgs {
  _set?: Maybe<FileSetInput>,
  where: FileBoolExp
}


export interface MutationRootUpdateFileByPkArgs {
  _set?: Maybe<FileSetInput>,
  pk_columns: FilePkColumnsInput
}


export interface MutationRootUpdateFileTypeArgs {
  _set?: Maybe<FileTypeSetInput>,
  where: FileTypeBoolExp
}


export interface MutationRootUpdateFileTypeByPkArgs {
  _set?: Maybe<FileTypeSetInput>,
  pk_columns: FileTypePkColumnsInput
}


export interface MutationRootUpdateFileTypeCategoryArgs {
  _set?: Maybe<FileTypeCategorySetInput>,
  where: FileTypeCategoryBoolExp
}


export interface MutationRootUpdateFileTypeCategoryByPkArgs {
  _set?: Maybe<FileTypeCategorySetInput>,
  pk_columns: FileTypeCategoryPkColumnsInput
}


export interface MutationRootUpdateFilesToOrganizationsArgs {
  _set?: Maybe<FilesToOrganizationsSetInput>,
  where: FilesToOrganizationsBoolExp
}


export interface MutationRootUpdateFilesToOrganizationsByPkArgs {
  _set?: Maybe<FilesToOrganizationsSetInput>,
  pk_columns: FilesToOrganizationsPkColumnsInput
}


export interface MutationRootUpdateFilesToStakeholdersArgs {
  _set?: Maybe<FilesToStakeholdersSetInput>,
  where: FilesToStakeholdersBoolExp
}


export interface MutationRootUpdateFilesToStakeholdersByPkArgs {
  _set?: Maybe<FilesToStakeholdersSetInput>,
  pk_columns: FilesToStakeholdersPkColumnsInput
}


export interface MutationRootUpdateGenderArgs {
  _set?: Maybe<GenderSetInput>,
  where: GenderBoolExp
}


export interface MutationRootUpdateGenderByPkArgs {
  _set?: Maybe<GenderSetInput>,
  pk_columns: GenderPkColumnsInput
}


export interface MutationRootUpdateIdentificationTypeArgs {
  _set?: Maybe<IdentificationTypeSetInput>,
  where: IdentificationTypeBoolExp
}


export interface MutationRootUpdateIdentificationTypeByPkArgs {
  _set?: Maybe<IdentificationTypeSetInput>,
  pk_columns: IdentificationTypePkColumnsInput
}


export interface MutationRootUpdateIndividualPartnerAccountGroupDefaultArgs {
  _set?: Maybe<IndividualPartnerAccountGroupDefaultSetInput>,
  where: IndividualPartnerAccountGroupDefaultBoolExp
}


export interface MutationRootUpdateIndividualPartnerAccountGroupDefaultByPkArgs {
  _set?: Maybe<IndividualPartnerAccountGroupDefaultSetInput>,
  pk_columns: IndividualPartnerAccountGroupDefaultPkColumnsInput
}


export interface MutationRootUpdateIndustryArgs {
  _set?: Maybe<IndustrySetInput>,
  where: IndustryBoolExp
}


export interface MutationRootUpdateIndustryByPkArgs {
  _set?: Maybe<IndustrySetInput>,
  pk_columns: IndustryPkColumnsInput
}


export interface MutationRootUpdateIntermediaryBankArgs {
  _set?: Maybe<IntermediaryBankSetInput>,
  where: IntermediaryBankBoolExp
}


export interface MutationRootUpdateIntermediaryBankByPkArgs {
  _set?: Maybe<IntermediaryBankSetInput>,
  pk_columns: IntermediaryBankPkColumnsInput
}


export interface MutationRootUpdateInvestmentObjectiveArgs {
  _set?: Maybe<InvestmentObjectiveSetInput>,
  where: InvestmentObjectiveBoolExp
}


export interface MutationRootUpdateInvestmentObjectiveByPkArgs {
  _set?: Maybe<InvestmentObjectiveSetInput>,
  pk_columns: InvestmentObjectivePkColumnsInput
}


export interface MutationRootUpdateLeverageArgs {
  _inc?: Maybe<LeverageIncInput>,
  _set?: Maybe<LeverageSetInput>,
  where: LeverageBoolExp
}


export interface MutationRootUpdateLeverageByPkArgs {
  _inc?: Maybe<LeverageIncInput>,
  _set?: Maybe<LeverageSetInput>,
  pk_columns: LeveragePkColumnsInput
}


export interface MutationRootUpdateLeveragesToPlatformServerTypesArgs {
  _inc?: Maybe<LeveragesToPlatformServerTypesIncInput>,
  _set?: Maybe<LeveragesToPlatformServerTypesSetInput>,
  where: LeveragesToPlatformServerTypesBoolExp
}


export interface MutationRootUpdateLeveragesToPlatformServerTypesByPkArgs {
  _inc?: Maybe<LeveragesToPlatformServerTypesIncInput>,
  _set?: Maybe<LeveragesToPlatformServerTypesSetInput>,
  pk_columns: LeveragesToPlatformServerTypesPkColumnsInput
}


export interface MutationRootUpdateLiquidAssetsArgs {
  _set?: Maybe<LiquidAssetsSetInput>,
  where: LiquidAssetsBoolExp
}


export interface MutationRootUpdateLiquidAssetsByPkArgs {
  _set?: Maybe<LiquidAssetsSetInput>,
  pk_columns: LiquidAssetsPkColumnsInput
}


export interface MutationRootUpdateLocaleArgs {
  _set?: Maybe<LocaleSetInput>,
  where: LocaleBoolExp
}


export interface MutationRootUpdateLocaleByPkArgs {
  _set?: Maybe<LocaleSetInput>,
  pk_columns: LocalePkColumnsInput
}


export interface MutationRootUpdateLocalesToOrganizationsArgs {
  _set?: Maybe<LocalesToOrganizationsSetInput>,
  where: LocalesToOrganizationsBoolExp
}


export interface MutationRootUpdateLocalesToOrganizationsByPkArgs {
  _set?: Maybe<LocalesToOrganizationsSetInput>,
  pk_columns: LocalesToOrganizationsPkColumnsInput
}


export interface MutationRootUpdateMarginTypeArgs {
  _set?: Maybe<MarginTypeSetInput>,
  where: MarginTypeBoolExp
}


export interface MutationRootUpdateMarginTypeByPkArgs {
  _set?: Maybe<MarginTypeSetInput>,
  pk_columns: MarginTypePkColumnsInput
}


export interface MutationRootUpdateMasterAdminClientPermissionArgs {
  _set?: Maybe<MasterAdminClientPermissionSetInput>,
  where: MasterAdminClientPermissionBoolExp
}


export interface MutationRootUpdateMasterAdminClientPermissionByPkArgs {
  _set?: Maybe<MasterAdminClientPermissionSetInput>,
  pk_columns: MasterAdminClientPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterAdminLeadPermissionArgs {
  _set?: Maybe<MasterAdminLeadPermissionSetInput>,
  where: MasterAdminLeadPermissionBoolExp
}


export interface MutationRootUpdateMasterAdminLeadPermissionByPkArgs {
  _set?: Maybe<MasterAdminLeadPermissionSetInput>,
  pk_columns: MasterAdminLeadPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterAdminPartnerPermissionArgs {
  _set?: Maybe<MasterAdminPartnerPermissionSetInput>,
  where: MasterAdminPartnerPermissionBoolExp
}


export interface MutationRootUpdateMasterAdminPartnerPermissionByPkArgs {
  _set?: Maybe<MasterAdminPartnerPermissionSetInput>,
  pk_columns: MasterAdminPartnerPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterAdminStakeholderPermissionArgs {
  _set?: Maybe<MasterAdminStakeholderPermissionSetInput>,
  where: MasterAdminStakeholderPermissionBoolExp
}


export interface MutationRootUpdateMasterAdminStakeholderPermissionByPkArgs {
  _set?: Maybe<MasterAdminStakeholderPermissionSetInput>,
  pk_columns: MasterAdminStakeholderPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterAdminSystemPermissionArgs {
  _set?: Maybe<MasterAdminSystemPermissionSetInput>,
  where: MasterAdminSystemPermissionBoolExp
}


export interface MutationRootUpdateMasterAdminSystemPermissionByPkArgs {
  _set?: Maybe<MasterAdminSystemPermissionSetInput>,
  pk_columns: MasterAdminSystemPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterPartnerClientPermissionArgs {
  _set?: Maybe<MasterPartnerClientPermissionSetInput>,
  where: MasterPartnerClientPermissionBoolExp
}


export interface MutationRootUpdateMasterPartnerClientPermissionByPkArgs {
  _set?: Maybe<MasterPartnerClientPermissionSetInput>,
  pk_columns: MasterPartnerClientPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterPartnerLeadPermissionArgs {
  _set?: Maybe<MasterPartnerLeadPermissionSetInput>,
  where: MasterPartnerLeadPermissionBoolExp
}


export interface MutationRootUpdateMasterPartnerLeadPermissionByPkArgs {
  _set?: Maybe<MasterPartnerLeadPermissionSetInput>,
  pk_columns: MasterPartnerLeadPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterPartnerPartnerPermissionArgs {
  _set?: Maybe<MasterPartnerPartnerPermissionSetInput>,
  where: MasterPartnerPartnerPermissionBoolExp
}


export interface MutationRootUpdateMasterPartnerPartnerPermissionByPkArgs {
  _set?: Maybe<MasterPartnerPartnerPermissionSetInput>,
  pk_columns: MasterPartnerPartnerPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterPartnerStakeholderPermissionArgs {
  _set?: Maybe<MasterPartnerStakeholderPermissionSetInput>,
  where: MasterPartnerStakeholderPermissionBoolExp
}


export interface MutationRootUpdateMasterPartnerStakeholderPermissionByPkArgs {
  _set?: Maybe<MasterPartnerStakeholderPermissionSetInput>,
  pk_columns: MasterPartnerStakeholderPermissionPkColumnsInput
}


export interface MutationRootUpdateMasterUserPermissionArgs {
  _set?: Maybe<MasterUserPermissionSetInput>,
  where: MasterUserPermissionBoolExp
}


export interface MutationRootUpdateMasterUserPermissionByPkArgs {
  _set?: Maybe<MasterUserPermissionSetInput>,
  pk_columns: MasterUserPermissionPkColumnsInput
}


export interface MutationRootUpdateNameTitleArgs {
  _set?: Maybe<NameTitleSetInput>,
  where: NameTitleBoolExp
}


export interface MutationRootUpdateNameTitleByPkArgs {
  _set?: Maybe<NameTitleSetInput>,
  pk_columns: NameTitlePkColumnsInput
}


export interface MutationRootUpdateNotificationArgs {
  _set?: Maybe<NotificationSetInput>,
  where: NotificationBoolExp
}


export interface MutationRootUpdateNotificationByPkArgs {
  _set?: Maybe<NotificationSetInput>,
  pk_columns: NotificationPkColumnsInput
}


export interface MutationRootUpdateOrganizationArgs {
  _inc?: Maybe<OrganizationIncInput>,
  _set?: Maybe<OrganizationSetInput>,
  where: OrganizationBoolExp
}


export interface MutationRootUpdateOrganizationByPkArgs {
  _inc?: Maybe<OrganizationIncInput>,
  _set?: Maybe<OrganizationSetInput>,
  pk_columns: OrganizationPkColumnsInput
}


export interface MutationRootUpdateOriginArgs {
  _set?: Maybe<OriginSetInput>,
  where: OriginBoolExp
}


export interface MutationRootUpdateOriginByPkArgs {
  _set?: Maybe<OriginSetInput>,
  pk_columns: OriginPkColumnsInput
}


export interface MutationRootUpdatePartnerPermissionArgs {
  _set?: Maybe<PartnerPermissionSetInput>,
  where: PartnerPermissionBoolExp
}


export interface MutationRootUpdatePartnerPermissionByPkArgs {
  _set?: Maybe<PartnerPermissionSetInput>,
  pk_columns: PartnerPermissionPkColumnsInput
}


export interface MutationRootUpdatePaymentArgs {
  _inc?: Maybe<PaymentIncInput>,
  _set?: Maybe<PaymentSetInput>,
  where: PaymentBoolExp
}


export interface MutationRootUpdatePaymentAttributeGlobalIdentifierArgs {
  _set?: Maybe<PaymentAttributeGlobalIdentifierSetInput>,
  where: PaymentAttributeGlobalIdentifierBoolExp
}


export interface MutationRootUpdatePaymentAttributeGlobalIdentifierByPkArgs {
  _set?: Maybe<PaymentAttributeGlobalIdentifierSetInput>,
  pk_columns: PaymentAttributeGlobalIdentifierPkColumnsInput
}


export interface MutationRootUpdatePaymentAttributeKeyArgs {
  _inc?: Maybe<PaymentAttributeKeyIncInput>,
  _set?: Maybe<PaymentAttributeKeySetInput>,
  where: PaymentAttributeKeyBoolExp
}


export interface MutationRootUpdatePaymentAttributeKeyByPkArgs {
  _inc?: Maybe<PaymentAttributeKeyIncInput>,
  _set?: Maybe<PaymentAttributeKeySetInput>,
  pk_columns: PaymentAttributeKeyPkColumnsInput
}


export interface MutationRootUpdatePaymentAttributeSectionArgs {
  _inc?: Maybe<PaymentAttributeSectionIncInput>,
  _set?: Maybe<PaymentAttributeSectionSetInput>,
  where: PaymentAttributeSectionBoolExp
}


export interface MutationRootUpdatePaymentAttributeSectionByPkArgs {
  _inc?: Maybe<PaymentAttributeSectionIncInput>,
  _set?: Maybe<PaymentAttributeSectionSetInput>,
  pk_columns: PaymentAttributeSectionPkColumnsInput
}


export interface MutationRootUpdatePaymentAttributeSubsectionArgs {
  _inc?: Maybe<PaymentAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentAttributeSubsectionSetInput>,
  where: PaymentAttributeSubsectionBoolExp
}


export interface MutationRootUpdatePaymentAttributeSubsectionByPkArgs {
  _inc?: Maybe<PaymentAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentAttributeSubsectionSetInput>,
  pk_columns: PaymentAttributeSubsectionPkColumnsInput
}


export interface MutationRootUpdatePaymentAttributeValueArgs {
  _set?: Maybe<PaymentAttributeValueSetInput>,
  where: PaymentAttributeValueBoolExp
}


export interface MutationRootUpdatePaymentAttributeValueByPkArgs {
  _set?: Maybe<PaymentAttributeValueSetInput>,
  pk_columns: PaymentAttributeValuePkColumnsInput
}


export interface MutationRootUpdatePaymentByPkArgs {
  _inc?: Maybe<PaymentIncInput>,
  _set?: Maybe<PaymentSetInput>,
  pk_columns: PaymentPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodArgs {
  _set?: Maybe<PaymentMethodSetInput>,
  where: PaymentMethodBoolExp
}


export interface MutationRootUpdatePaymentMethodAttributeArgs {
  _set?: Maybe<PaymentMethodAttributeSetInput>,
  where: PaymentMethodAttributeBoolExp
}


export interface MutationRootUpdatePaymentMethodAttributeByPkArgs {
  _set?: Maybe<PaymentMethodAttributeSetInput>,
  pk_columns: PaymentMethodAttributePkColumnsInput
}


export interface MutationRootUpdatePaymentMethodAttributeKeyArgs {
  _inc?: Maybe<PaymentMethodAttributeKeyIncInput>,
  _set?: Maybe<PaymentMethodAttributeKeySetInput>,
  where: PaymentMethodAttributeKeyBoolExp
}


export interface MutationRootUpdatePaymentMethodAttributeKeyByPkArgs {
  _inc?: Maybe<PaymentMethodAttributeKeyIncInput>,
  _set?: Maybe<PaymentMethodAttributeKeySetInput>,
  pk_columns: PaymentMethodAttributeKeyPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodAttributeSectionArgs {
  _inc?: Maybe<PaymentMethodAttributeSectionIncInput>,
  _set?: Maybe<PaymentMethodAttributeSectionSetInput>,
  where: PaymentMethodAttributeSectionBoolExp
}


export interface MutationRootUpdatePaymentMethodAttributeSectionByPkArgs {
  _inc?: Maybe<PaymentMethodAttributeSectionIncInput>,
  _set?: Maybe<PaymentMethodAttributeSectionSetInput>,
  pk_columns: PaymentMethodAttributeSectionPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodAttributeSubsectionArgs {
  _inc?: Maybe<PaymentMethodAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentMethodAttributeSubsectionSetInput>,
  where: PaymentMethodAttributeSubsectionBoolExp
}


export interface MutationRootUpdatePaymentMethodAttributeSubsectionByPkArgs {
  _inc?: Maybe<PaymentMethodAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentMethodAttributeSubsectionSetInput>,
  pk_columns: PaymentMethodAttributeSubsectionPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodByPkArgs {
  _set?: Maybe<PaymentMethodSetInput>,
  pk_columns: PaymentMethodPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodLogoArgs {
  _set?: Maybe<PaymentMethodLogoSetInput>,
  where: PaymentMethodLogoBoolExp
}


export interface MutationRootUpdatePaymentMethodLogoByPkArgs {
  _set?: Maybe<PaymentMethodLogoSetInput>,
  pk_columns: PaymentMethodLogoPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeArgs {
  _set?: Maybe<PaymentMethodTypeSetInput>,
  where: PaymentMethodTypeBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeAttributeArgs {
  _set?: Maybe<PaymentMethodTypeAttributeSetInput>,
  where: PaymentMethodTypeAttributeBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeAttributeByPkArgs {
  _set?: Maybe<PaymentMethodTypeAttributeSetInput>,
  pk_columns: PaymentMethodTypeAttributePkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeAttributeKeyArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeKeyIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeKeySetInput>,
  where: PaymentMethodTypeAttributeKeyBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeAttributeKeyByPkArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeKeyIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeKeySetInput>,
  pk_columns: PaymentMethodTypeAttributeKeyPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeAttributeSectionArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeSectionIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeSectionSetInput>,
  where: PaymentMethodTypeAttributeSectionBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeAttributeSectionByPkArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeSectionIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeSectionSetInput>,
  pk_columns: PaymentMethodTypeAttributeSectionPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeAttributeSubsectionArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeSubsectionSetInput>,
  where: PaymentMethodTypeAttributeSubsectionBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeAttributeSubsectionByPkArgs {
  _inc?: Maybe<PaymentMethodTypeAttributeSubsectionIncInput>,
  _set?: Maybe<PaymentMethodTypeAttributeSubsectionSetInput>,
  pk_columns: PaymentMethodTypeAttributeSubsectionPkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeByPkArgs {
  _set?: Maybe<PaymentMethodTypeSetInput>,
  pk_columns: PaymentMethodTypePkColumnsInput
}


export interface MutationRootUpdatePaymentMethodTypeCurrencyArgs {
  _inc?: Maybe<PaymentMethodTypeCurrencyIncInput>,
  _set?: Maybe<PaymentMethodTypeCurrencySetInput>,
  where: PaymentMethodTypeCurrencyBoolExp
}


export interface MutationRootUpdatePaymentMethodTypeCurrencyByPkArgs {
  _inc?: Maybe<PaymentMethodTypeCurrencyIncInput>,
  _set?: Maybe<PaymentMethodTypeCurrencySetInput>,
  pk_columns: PaymentMethodTypeCurrencyPkColumnsInput
}


export interface MutationRootUpdatePaymentOrientationArgs {
  _set?: Maybe<PaymentOrientationSetInput>,
  where: PaymentOrientationBoolExp
}


export interface MutationRootUpdatePaymentOrientationByPkArgs {
  _set?: Maybe<PaymentOrientationSetInput>,
  pk_columns: PaymentOrientationPkColumnsInput
}


export interface MutationRootUpdatePaymentProviderArgs {
  _set?: Maybe<PaymentProviderSetInput>,
  where: PaymentProviderBoolExp
}


export interface MutationRootUpdatePaymentProviderByPkArgs {
  _set?: Maybe<PaymentProviderSetInput>,
  pk_columns: PaymentProviderPkColumnsInput
}


export interface MutationRootUpdatePaymentProviderIntegrationModeArgs {
  _set?: Maybe<PaymentProviderIntegrationModeSetInput>,
  where: PaymentProviderIntegrationModeBoolExp
}


export interface MutationRootUpdatePaymentProviderIntegrationModeByPkArgs {
  _set?: Maybe<PaymentProviderIntegrationModeSetInput>,
  pk_columns: PaymentProviderIntegrationModePkColumnsInput
}


export interface MutationRootUpdatePaymentProviderMethodTypeArgs {
  _set?: Maybe<PaymentProviderMethodTypeSetInput>,
  where: PaymentProviderMethodTypeBoolExp
}


export interface MutationRootUpdatePaymentProviderMethodTypeByPkArgs {
  _set?: Maybe<PaymentProviderMethodTypeSetInput>,
  pk_columns: PaymentProviderMethodTypePkColumnsInput
}


export interface MutationRootUpdatePaymentStatusArgs {
  _set?: Maybe<PaymentStatusSetInput>,
  where: PaymentStatusBoolExp
}


export interface MutationRootUpdatePaymentStatusByPkArgs {
  _set?: Maybe<PaymentStatusSetInput>,
  pk_columns: PaymentStatusPkColumnsInput
}


export interface MutationRootUpdatePhoneCodeArgs {
  _set?: Maybe<PhoneCodeSetInput>,
  where: PhoneCodeBoolExp
}


export interface MutationRootUpdatePhoneCodeByPkArgs {
  _set?: Maybe<PhoneCodeSetInput>,
  pk_columns: PhoneCodePkColumnsInput
}


export interface MutationRootUpdatePhoneTypeArgs {
  _set?: Maybe<PhoneTypeSetInput>,
  where: PhoneTypeBoolExp
}


export interface MutationRootUpdatePhoneTypeByPkArgs {
  _set?: Maybe<PhoneTypeSetInput>,
  pk_columns: PhoneTypePkColumnsInput
}


export interface MutationRootUpdatePlatformAccessArgs {
  _set?: Maybe<PlatformAccessSetInput>,
  where: PlatformAccessBoolExp
}


export interface MutationRootUpdatePlatformAccessByPkArgs {
  _set?: Maybe<PlatformAccessSetInput>,
  pk_columns: PlatformAccessPkColumnsInput
}


export interface MutationRootUpdatePlatformServerArgs {
  _inc?: Maybe<PlatformServerIncInput>,
  _set?: Maybe<PlatformServerSetInput>,
  where: PlatformServerBoolExp
}


export interface MutationRootUpdatePlatformServerByPkArgs {
  _inc?: Maybe<PlatformServerIncInput>,
  _set?: Maybe<PlatformServerSetInput>,
  pk_columns: PlatformServerPkColumnsInput
}


export interface MutationRootUpdatePlatformServerEnvironmentArgs {
  _set?: Maybe<PlatformServerEnvironmentSetInput>,
  where: PlatformServerEnvironmentBoolExp
}


export interface MutationRootUpdatePlatformServerEnvironmentByPkArgs {
  _set?: Maybe<PlatformServerEnvironmentSetInput>,
  pk_columns: PlatformServerEnvironmentPkColumnsInput
}


export interface MutationRootUpdatePlatformServerRetryStatusArgs {
  _set?: Maybe<PlatformServerRetryStatusSetInput>,
  where: PlatformServerRetryStatusBoolExp
}


export interface MutationRootUpdatePlatformServerRetryStatusByPkArgs {
  _set?: Maybe<PlatformServerRetryStatusSetInput>,
  pk_columns: PlatformServerRetryStatusPkColumnsInput
}


export interface MutationRootUpdatePlatformServerStatusArgs {
  _set?: Maybe<PlatformServerStatusSetInput>,
  where: PlatformServerStatusBoolExp
}


export interface MutationRootUpdatePlatformServerStatusByPkArgs {
  _set?: Maybe<PlatformServerStatusSetInput>,
  pk_columns: PlatformServerStatusPkColumnsInput
}


export interface MutationRootUpdatePlatformServerTypeArgs {
  _set?: Maybe<PlatformServerTypeSetInput>,
  where: PlatformServerTypeBoolExp
}


export interface MutationRootUpdatePlatformServerTypeByPkArgs {
  _set?: Maybe<PlatformServerTypeSetInput>,
  pk_columns: PlatformServerTypePkColumnsInput
}


export interface MutationRootUpdatePlatformServersToSupportedLeveragesArgs {
  _set?: Maybe<PlatformServersToSupportedLeveragesSetInput>,
  where: PlatformServersToSupportedLeveragesBoolExp
}


export interface MutationRootUpdatePlatformServersToSupportedLeveragesByPkArgs {
  _set?: Maybe<PlatformServersToSupportedLeveragesSetInput>,
  pk_columns: PlatformServersToSupportedLeveragesPkColumnsInput
}


export interface MutationRootUpdatePlatformSymbolArgs {
  _inc?: Maybe<PlatformSymbolIncInput>,
  _set?: Maybe<PlatformSymbolSetInput>,
  where: PlatformSymbolBoolExp
}


export interface MutationRootUpdatePlatformSymbolByPkArgs {
  _inc?: Maybe<PlatformSymbolIncInput>,
  _set?: Maybe<PlatformSymbolSetInput>,
  pk_columns: PlatformSymbolPkColumnsInput
}


export interface MutationRootUpdateProfileArgs {
  _set?: Maybe<ProfileSetInput>,
  where: ProfileBoolExp
}


export interface MutationRootUpdateProfileActivityArgs {
  _set?: Maybe<ProfileActivitySetInput>,
  where: ProfileActivityBoolExp
}


export interface MutationRootUpdateProfileActivityByPkArgs {
  _set?: Maybe<ProfileActivitySetInput>,
  pk_columns: ProfileActivityPkColumnsInput
}


export interface MutationRootUpdateProfileByPkArgs {
  _set?: Maybe<ProfileSetInput>,
  pk_columns: ProfilePkColumnsInput
}


export interface MutationRootUpdateProfileCategoryArgs {
  _set?: Maybe<ProfileCategorySetInput>,
  where: ProfileCategoryBoolExp
}


export interface MutationRootUpdateProfileCategoryByPkArgs {
  _set?: Maybe<ProfileCategorySetInput>,
  pk_columns: ProfileCategoryPkColumnsInput
}


export interface MutationRootUpdateProfileEntryTypeArgs {
  _set?: Maybe<ProfileEntryTypeSetInput>,
  where: ProfileEntryTypeBoolExp
}


export interface MutationRootUpdateProfileEntryTypeByPkArgs {
  _set?: Maybe<ProfileEntryTypeSetInput>,
  pk_columns: ProfileEntryTypePkColumnsInput
}


export interface MutationRootUpdateProfileFlagsArgs {
  _set?: Maybe<ProfileFlagsSetInput>,
  where: ProfileFlagsBoolExp
}


export interface MutationRootUpdateProfileFlagsByPkArgs {
  _set?: Maybe<ProfileFlagsSetInput>,
  pk_columns: ProfileFlagsPkColumnsInput
}


export interface MutationRootUpdateProfileNoteArgs {
  _set?: Maybe<ProfileNoteSetInput>,
  where: ProfileNoteBoolExp
}


export interface MutationRootUpdateProfileNoteByPkArgs {
  _set?: Maybe<ProfileNoteSetInput>,
  pk_columns: ProfileNotePkColumnsInput
}


export interface MutationRootUpdateProfileNoteVersionArgs {
  _set?: Maybe<ProfileNoteVersionSetInput>,
  where: ProfileNoteVersionBoolExp
}


export interface MutationRootUpdateProfileNoteVersionByPkArgs {
  _set?: Maybe<ProfileNoteVersionSetInput>,
  pk_columns: ProfileNoteVersionPkColumnsInput
}


export interface MutationRootUpdateProfileNoteVersionsStakeholderMentionsArgs {
  _set?: Maybe<ProfileNoteVersionsStakeholderMentionsSetInput>,
  where: ProfileNoteVersionsStakeholderMentionsBoolExp
}


export interface MutationRootUpdateProfileNoteVersionsStakeholderMentionsByPkArgs {
  _set?: Maybe<ProfileNoteVersionsStakeholderMentionsSetInput>,
  pk_columns: ProfileNoteVersionsStakeholderMentionsPkColumnsInput
}


export interface MutationRootUpdateProfileNoteVersionsStakeholderRelevantPartiesArgs {
  _set?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesSetInput>,
  where: ProfileNoteVersionsStakeholderRelevantPartiesBoolExp
}


export interface MutationRootUpdateProfileNoteVersionsStakeholderRelevantPartiesByPkArgs {
  _set?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesSetInput>,
  pk_columns: ProfileNoteVersionsStakeholderRelevantPartiesPkColumnsInput
}


export interface MutationRootUpdateProfileRiskArgs {
  _set?: Maybe<ProfileRiskSetInput>,
  where: ProfileRiskBoolExp
}


export interface MutationRootUpdateProfileRiskByPkArgs {
  _set?: Maybe<ProfileRiskSetInput>,
  pk_columns: ProfileRiskPkColumnsInput
}


export interface MutationRootUpdateProfileStatusArgs {
  _set?: Maybe<ProfileStatusSetInput>,
  where: ProfileStatusBoolExp
}


export interface MutationRootUpdateProfileStatusByPkArgs {
  _set?: Maybe<ProfileStatusSetInput>,
  pk_columns: ProfileStatusPkColumnsInput
}


export interface MutationRootUpdateProfileTypeArgs {
  _set?: Maybe<ProfileTypeSetInput>,
  where: ProfileTypeBoolExp
}


export interface MutationRootUpdateProfileTypeByPkArgs {
  _set?: Maybe<ProfileTypeSetInput>,
  pk_columns: ProfileTypePkColumnsInput
}


export interface MutationRootUpdateProfilesToTagsArgs {
  _set?: Maybe<ProfilesToTagsSetInput>,
  where: ProfilesToTagsBoolExp
}


export interface MutationRootUpdateProfilesToTagsByPkArgs {
  _set?: Maybe<ProfilesToTagsSetInput>,
  pk_columns: ProfilesToTagsPkColumnsInput
}


export interface MutationRootUpdateRepresentativesToProfilesArgs {
  _set?: Maybe<RepresentativesToProfilesSetInput>,
  where: RepresentativesToProfilesBoolExp
}


export interface MutationRootUpdateRepresentativesToProfilesByPkArgs {
  _set?: Maybe<RepresentativesToProfilesSetInput>,
  pk_columns: RepresentativesToProfilesPkColumnsInput
}


export interface MutationRootUpdateRequestArgs {
  _inc?: Maybe<RequestIncInput>,
  _set?: Maybe<RequestSetInput>,
  where: RequestBoolExp
}


export interface MutationRootUpdateRequestByPkArgs {
  _inc?: Maybe<RequestIncInput>,
  _set?: Maybe<RequestSetInput>,
  pk_columns: RequestPkColumnsInput
}


export interface MutationRootUpdateRequestStatusArgs {
  _inc?: Maybe<RequestStatusIncInput>,
  _set?: Maybe<RequestStatusSetInput>,
  where: RequestStatusBoolExp
}


export interface MutationRootUpdateRequestStatusByPkArgs {
  _inc?: Maybe<RequestStatusIncInput>,
  _set?: Maybe<RequestStatusSetInput>,
  pk_columns: RequestStatusPkColumnsInput
}


export interface MutationRootUpdateRequestTypeArgs {
  _inc?: Maybe<RequestTypeIncInput>,
  _set?: Maybe<RequestTypeSetInput>,
  where: RequestTypeBoolExp
}


export interface MutationRootUpdateRequestTypeByPkArgs {
  _inc?: Maybe<RequestTypeIncInput>,
  _set?: Maybe<RequestTypeSetInput>,
  pk_columns: RequestTypePkColumnsInput
}


export interface MutationRootUpdateSecurityArgs {
  _set?: Maybe<SecuritySetInput>,
  where: SecurityBoolExp
}


export interface MutationRootUpdateSecurityByPkArgs {
  _set?: Maybe<SecuritySetInput>,
  pk_columns: SecurityPkColumnsInput
}


export interface MutationRootUpdateSecurityGridViewArgs {
  _inc?: Maybe<SecurityGridViewIncInput>,
  _set?: Maybe<SecurityGridViewSetInput>,
  where: SecurityGridViewBoolExp
}


export interface MutationRootUpdateSegmentArgs {
  _set?: Maybe<SegmentSetInput>,
  where: SegmentBoolExp
}


export interface MutationRootUpdateSegmentByPkArgs {
  _set?: Maybe<SegmentSetInput>,
  pk_columns: SegmentPkColumnsInput
}


export interface MutationRootUpdateSegmentConditionArgs {
  _set?: Maybe<SegmentConditionSetInput>,
  where: SegmentConditionBoolExp
}


export interface MutationRootUpdateSegmentConditionByPkArgs {
  _set?: Maybe<SegmentConditionSetInput>,
  pk_columns: SegmentConditionPkColumnsInput
}


export interface MutationRootUpdateSegmentCriterionArgs {
  _set?: Maybe<SegmentCriterionSetInput>,
  where: SegmentCriterionBoolExp
}


export interface MutationRootUpdateSegmentCriterionByPkArgs {
  _set?: Maybe<SegmentCriterionSetInput>,
  pk_columns: SegmentCriterionPkColumnsInput
}


export interface MutationRootUpdateSegmentCriterionTypeArgs {
  _set?: Maybe<SegmentCriterionTypeSetInput>,
  where: SegmentCriterionTypeBoolExp
}


export interface MutationRootUpdateSegmentCriterionTypeByPkArgs {
  _set?: Maybe<SegmentCriterionTypeSetInput>,
  pk_columns: SegmentCriterionTypePkColumnsInput
}


export interface MutationRootUpdateSegmentOperatorArgs {
  _set?: Maybe<SegmentOperatorSetInput>,
  where: SegmentOperatorBoolExp
}


export interface MutationRootUpdateSegmentOperatorByPkArgs {
  _set?: Maybe<SegmentOperatorSetInput>,
  pk_columns: SegmentOperatorPkColumnsInput
}


export interface MutationRootUpdateSegmentOperatorsToSegmentPropertyConditionsArgs {
  _set?: Maybe<SegmentOperatorsToSegmentPropertyConditionsSetInput>,
  where: SegmentOperatorsToSegmentPropertyConditionsBoolExp
}


export interface MutationRootUpdateSegmentOperatorsToSegmentPropertyConditionsByPkArgs {
  _set?: Maybe<SegmentOperatorsToSegmentPropertyConditionsSetInput>,
  pk_columns: SegmentOperatorsToSegmentPropertyConditionsPkColumnsInput
}


export interface MutationRootUpdateSegmentPropertyArgs {
  _set?: Maybe<SegmentPropertySetInput>,
  where: SegmentPropertyBoolExp
}


export interface MutationRootUpdateSegmentPropertyByPkArgs {
  _set?: Maybe<SegmentPropertySetInput>,
  pk_columns: SegmentPropertyPkColumnsInput
}


export interface MutationRootUpdateSegmentPropertyConditionArgs {
  _inc?: Maybe<SegmentPropertyConditionIncInput>,
  _set?: Maybe<SegmentPropertyConditionSetInput>,
  where: SegmentPropertyConditionBoolExp
}


export interface MutationRootUpdateSegmentPropertyConditionByPkArgs {
  _inc?: Maybe<SegmentPropertyConditionIncInput>,
  _set?: Maybe<SegmentPropertyConditionSetInput>,
  pk_columns: SegmentPropertyConditionPkColumnsInput
}


export interface MutationRootUpdateSourceOfFundsArgs {
  _set?: Maybe<SourceOfFundsSetInput>,
  where: SourceOfFundsBoolExp
}


export interface MutationRootUpdateSourceOfFundsByPkArgs {
  _set?: Maybe<SourceOfFundsSetInput>,
  pk_columns: SourceOfFundsPkColumnsInput
}


export interface MutationRootUpdateSpreadModeArgs {
  _set?: Maybe<SpreadModeSetInput>,
  where: SpreadModeBoolExp
}


export interface MutationRootUpdateSpreadModeByPkArgs {
  _set?: Maybe<SpreadModeSetInput>,
  pk_columns: SpreadModePkColumnsInput
}


export interface MutationRootUpdateStakeArgs {
  _set?: Maybe<StakeSetInput>,
  where: StakeBoolExp
}


export interface MutationRootUpdateStakeAncestryArgs {
  _inc?: Maybe<StakeAncestryIncInput>,
  _set?: Maybe<StakeAncestrySetInput>,
  where: StakeAncestryBoolExp
}


export interface MutationRootUpdateStakeAncestryByPkArgs {
  _inc?: Maybe<StakeAncestryIncInput>,
  _set?: Maybe<StakeAncestrySetInput>,
  pk_columns: StakeAncestryPkColumnsInput
}


export interface MutationRootUpdateStakeByPkArgs {
  _set?: Maybe<StakeSetInput>,
  pk_columns: StakePkColumnsInput
}


export interface MutationRootUpdateStakeTitleArgs {
  _set?: Maybe<StakeTitleSetInput>,
  where: StakeTitleBoolExp
}


export interface MutationRootUpdateStakeTitleByPkArgs {
  _set?: Maybe<StakeTitleSetInput>,
  pk_columns: StakeTitlePkColumnsInput
}


export interface MutationRootUpdateStakeholderArgs {
  _set?: Maybe<StakeholderSetInput>,
  where: StakeholderBoolExp
}


export interface MutationRootUpdateStakeholderAttributeArgs {
  _set?: Maybe<StakeholderAttributeSetInput>,
  where: StakeholderAttributeBoolExp
}


export interface MutationRootUpdateStakeholderAttributeByPkArgs {
  _set?: Maybe<StakeholderAttributeSetInput>,
  pk_columns: StakeholderAttributePkColumnsInput
}


export interface MutationRootUpdateStakeholderAttributeKeyArgs {
  _inc?: Maybe<StakeholderAttributeKeyIncInput>,
  _set?: Maybe<StakeholderAttributeKeySetInput>,
  where: StakeholderAttributeKeyBoolExp
}


export interface MutationRootUpdateStakeholderAttributeKeyByPkArgs {
  _inc?: Maybe<StakeholderAttributeKeyIncInput>,
  _set?: Maybe<StakeholderAttributeKeySetInput>,
  pk_columns: StakeholderAttributeKeyPkColumnsInput
}


export interface MutationRootUpdateStakeholderAttributeKeysToStakeholderClassesArgs {
  _set?: Maybe<StakeholderAttributeKeysToStakeholderClassesSetInput>,
  where: StakeholderAttributeKeysToStakeholderClassesBoolExp
}


export interface MutationRootUpdateStakeholderAttributeKeysToStakeholderClassesByPkArgs {
  _set?: Maybe<StakeholderAttributeKeysToStakeholderClassesSetInput>,
  pk_columns: StakeholderAttributeKeysToStakeholderClassesPkColumnsInput
}


export interface MutationRootUpdateStakeholderAttributeSectionArgs {
  _inc?: Maybe<StakeholderAttributeSectionIncInput>,
  _set?: Maybe<StakeholderAttributeSectionSetInput>,
  where: StakeholderAttributeSectionBoolExp
}


export interface MutationRootUpdateStakeholderAttributeSectionByPkArgs {
  _inc?: Maybe<StakeholderAttributeSectionIncInput>,
  _set?: Maybe<StakeholderAttributeSectionSetInput>,
  pk_columns: StakeholderAttributeSectionPkColumnsInput
}


export interface MutationRootUpdateStakeholderAttributeSubsectionArgs {
  _inc?: Maybe<StakeholderAttributeSubsectionIncInput>,
  _set?: Maybe<StakeholderAttributeSubsectionSetInput>,
  where: StakeholderAttributeSubsectionBoolExp
}


export interface MutationRootUpdateStakeholderAttributeSubsectionByPkArgs {
  _inc?: Maybe<StakeholderAttributeSubsectionIncInput>,
  _set?: Maybe<StakeholderAttributeSubsectionSetInput>,
  pk_columns: StakeholderAttributeSubsectionPkColumnsInput
}


export interface MutationRootUpdateStakeholderByPkArgs {
  _set?: Maybe<StakeholderSetInput>,
  pk_columns: StakeholderPkColumnsInput
}


export interface MutationRootUpdateStakeholderClassArgs {
  _set?: Maybe<StakeholderClassSetInput>,
  where: StakeholderClassBoolExp
}


export interface MutationRootUpdateStakeholderClassByPkArgs {
  _set?: Maybe<StakeholderClassSetInput>,
  pk_columns: StakeholderClassPkColumnsInput
}


export interface MutationRootUpdateStakesToAdminPermissionsArgs {
  _set?: Maybe<StakesToAdminPermissionsSetInput>,
  where: StakesToAdminPermissionsBoolExp
}


export interface MutationRootUpdateStakesToAdminPermissionsByPkArgs {
  _set?: Maybe<StakesToAdminPermissionsSetInput>,
  pk_columns: StakesToAdminPermissionsPkColumnsInput
}


export interface MutationRootUpdateStakesToStakeTitlesArgs {
  _set?: Maybe<StakesToStakeTitlesSetInput>,
  where: StakesToStakeTitlesBoolExp
}


export interface MutationRootUpdateStakesToStakeTitlesByPkArgs {
  _set?: Maybe<StakesToStakeTitlesSetInput>,
  pk_columns: StakesToStakeTitlesPkColumnsInput
}


export interface MutationRootUpdateSwapModeArgs {
  _set?: Maybe<SwapModeSetInput>,
  where: SwapModeBoolExp
}


export interface MutationRootUpdateSwapModeByPkArgs {
  _set?: Maybe<SwapModeSetInput>,
  pk_columns: SwapModePkColumnsInput
}


export interface MutationRootUpdateSymbolArgs {
  _inc?: Maybe<SymbolIncInput>,
  _set?: Maybe<SymbolSetInput>,
  where: SymbolBoolExp
}


export interface MutationRootUpdateSymbolByPkArgs {
  _inc?: Maybe<SymbolIncInput>,
  _set?: Maybe<SymbolSetInput>,
  pk_columns: SymbolPkColumnsInput
}


export interface MutationRootUpdateSymbolExtensionArgs {
  _set?: Maybe<SymbolExtensionSetInput>,
  where: SymbolExtensionBoolExp
}


export interface MutationRootUpdateSymbolExtensionByPkArgs {
  _set?: Maybe<SymbolExtensionSetInput>,
  pk_columns: SymbolExtensionPkColumnsInput
}


export interface MutationRootUpdateSymbolTypeArgs {
  _set?: Maybe<SymbolTypeSetInput>,
  where: SymbolTypeBoolExp
}


export interface MutationRootUpdateSymbolTypeByPkArgs {
  _set?: Maybe<SymbolTypeSetInput>,
  pk_columns: SymbolTypePkColumnsInput
}


export interface MutationRootUpdateTagArgs {
  _set?: Maybe<TagSetInput>,
  where: TagBoolExp
}


export interface MutationRootUpdateTagByPkArgs {
  _set?: Maybe<TagSetInput>,
  pk_columns: TagPkColumnsInput
}


export interface MutationRootUpdateTradeRuleArgs {
  _inc?: Maybe<TradeRuleIncInput>,
  _set?: Maybe<TradeRuleSetInput>,
  where: TradeRuleBoolExp
}


export interface MutationRootUpdateTradeRuleByPkArgs {
  _inc?: Maybe<TradeRuleIncInput>,
  _set?: Maybe<TradeRuleSetInput>,
  pk_columns: TradeRulePkColumnsInput
}


export interface MutationRootUpdateTransactionArgs {
  _inc?: Maybe<TransactionIncInput>,
  _set?: Maybe<TransactionSetInput>,
  where: TransactionBoolExp
}


export interface MutationRootUpdateTransactionByPkArgs {
  _inc?: Maybe<TransactionIncInput>,
  _set?: Maybe<TransactionSetInput>,
  pk_columns: TransactionPkColumnsInput
}


export interface MutationRootUpdateTransactionCreationRequestArgs {
  _inc?: Maybe<TransactionCreationRequestIncInput>,
  _set?: Maybe<TransactionCreationRequestSetInput>,
  where: TransactionCreationRequestBoolExp
}


export interface MutationRootUpdateTransactionCreationRequestByPkArgs {
  _inc?: Maybe<TransactionCreationRequestIncInput>,
  _set?: Maybe<TransactionCreationRequestSetInput>,
  pk_columns: TransactionCreationRequestPkColumnsInput
}


export interface MutationRootUpdateTransactionPlatformStatusArgs {
  _set?: Maybe<TransactionPlatformStatusSetInput>,
  where: TransactionPlatformStatusBoolExp
}


export interface MutationRootUpdateTransactionPlatformStatusByPkArgs {
  _set?: Maybe<TransactionPlatformStatusSetInput>,
  pk_columns: TransactionPlatformStatusPkColumnsInput
}


export interface MutationRootUpdateTransactionProfileGridViewArgs {
  _set?: Maybe<TransactionProfileGridViewSetInput>,
  where: TransactionProfileGridViewBoolExp
}


export interface MutationRootUpdateTransactionStatusArgs {
  _set?: Maybe<TransactionStatusSetInput>,
  where: TransactionStatusBoolExp
}


export interface MutationRootUpdateTransactionStatusByPkArgs {
  _set?: Maybe<TransactionStatusSetInput>,
  pk_columns: TransactionStatusPkColumnsInput
}


export interface MutationRootUpdateTransactionTypeArgs {
  _set?: Maybe<TransactionTypeSetInput>,
  where: TransactionTypeBoolExp
}


export interface MutationRootUpdateTransactionTypeByPkArgs {
  _set?: Maybe<TransactionTypeSetInput>,
  pk_columns: TransactionTypePkColumnsInput
}


export interface MutationRootUpdateTransferApprovalOptionArgs {
  _inc?: Maybe<TransferApprovalOptionIncInput>,
  _set?: Maybe<TransferApprovalOptionSetInput>,
  where: TransferApprovalOptionBoolExp
}


export interface MutationRootUpdateTransferApprovalOptionByPkArgs {
  _inc?: Maybe<TransferApprovalOptionIncInput>,
  _set?: Maybe<TransferApprovalOptionSetInput>,
  pk_columns: TransferApprovalOptionPkColumnsInput
}


export interface MutationRootUpdateUserAccessArgs {
  _inc?: Maybe<UserAccessIncInput>,
  _set?: Maybe<UserAccessSetInput>,
  where: UserAccessBoolExp
}


export interface MutationRootUpdateUserAccessByPkArgs {
  _inc?: Maybe<UserAccessIncInput>,
  _set?: Maybe<UserAccessSetInput>,
  pk_columns: UserAccessPkColumnsInput
}


export interface MutationRootUpdateUserInviteArgs {
  _set?: Maybe<UserInviteSetInput>,
  where: UserInviteBoolExp
}


export interface MutationRootUpdateUserInviteByPkArgs {
  _set?: Maybe<UserInviteSetInput>,
  pk_columns: UserInvitePkColumnsInput
}


export interface MutationRootUpdateUserPermissionArgs {
  _set?: Maybe<UserPermissionSetInput>,
  where: UserPermissionBoolExp
}


export interface MutationRootUpdateUserPermissionByPkArgs {
  _set?: Maybe<UserPermissionSetInput>,
  pk_columns: UserPermissionPkColumnsInput
}


export interface MutationRootUpdateWorkflowArgs {
  _set?: Maybe<WorkflowSetInput>,
  where: WorkflowBoolExp
}


export interface MutationRootUpdateWorkflowByPkArgs {
  _set?: Maybe<WorkflowSetInput>,
  pk_columns: WorkflowPkColumnsInput
}


export interface MutationRootUpdateWorkflowStageArgs {
  _inc?: Maybe<WorkflowStageIncInput>,
  _set?: Maybe<WorkflowStageSetInput>,
  where: WorkflowStageBoolExp
}


export interface MutationRootUpdateWorkflowStageByPkArgs {
  _inc?: Maybe<WorkflowStageIncInput>,
  _set?: Maybe<WorkflowStageSetInput>,
  pk_columns: WorkflowStagePkColumnsInput
}


export interface MutationRootUpdateWorkflowsToProfilesArgs {
  _set?: Maybe<WorkflowsToProfilesSetInput>,
  where: WorkflowsToProfilesBoolExp
}


export interface MutationRootUpdateWorkflowsToProfilesByPkArgs {
  _set?: Maybe<WorkflowsToProfilesSetInput>,
  pk_columns: WorkflowsToProfilesPkColumnsInput
}


export interface MutationRootUpdateYearsExperienceArgs {
  _set?: Maybe<YearsExperienceSetInput>,
  where: YearsExperienceBoolExp
}


export interface MutationRootUpdateYearsExperienceByPkArgs {
  _set?: Maybe<YearsExperienceSetInput>,
  pk_columns: YearsExperiencePkColumnsInput
}

export interface NameTitle {
   __typename?: 'name_title',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface NameTitleAggregate {
   __typename?: 'name_title_aggregate',
  aggregate?: Maybe<NameTitleAggregateFields>,
  nodes: Array<NameTitle>,
}

export interface NameTitleAggregateFields {
   __typename?: 'name_title_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<NameTitleMaxFields>,
  min?: Maybe<NameTitleMinFields>,
}


export interface NameTitleAggregateFieldsCountArgs {
  columns?: Maybe<Array<NameTitleSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface NameTitleAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<NameTitleMaxOrderBy>,
  min?: Maybe<NameTitleMinOrderBy>,
}

export interface NameTitleArrRelInsertInput {
  data: Array<NameTitleInsertInput>,
  on_conflict?: Maybe<NameTitleOnConflict>,
}

export interface NameTitleBoolExp {
  _and?: Maybe<Array<Maybe<NameTitleBoolExp>>>,
  _not?: Maybe<NameTitleBoolExp>,
  _or?: Maybe<Array<Maybe<NameTitleBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum NameTitleConstraint {
  NameTitleNameKey = 'name_title_name_key',
  NameTitlePkey = 'name_title_pkey'
}

export interface NameTitleInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface NameTitleMaxFields {
   __typename?: 'name_title_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface NameTitleMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface NameTitleMinFields {
   __typename?: 'name_title_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface NameTitleMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface NameTitleMutationResponse {
   __typename?: 'name_title_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<NameTitle>,
}

export interface NameTitleObjRelInsertInput {
  data: NameTitleInsertInput,
  on_conflict?: Maybe<NameTitleOnConflict>,
}

export interface NameTitleOnConflict {
  constraint: NameTitleConstraint,
  update_columns: Array<NameTitleUpdateColumn>,
  where?: Maybe<NameTitleBoolExp>,
}

export interface NameTitleOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface NameTitlePkColumnsInput {
  id: Scalars['String'],
}

export enum NameTitleSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface NameTitleSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum NameTitleUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface Notification {
   __typename?: 'notification',
  activity: Activity,
  activity_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  delivered_at?: Maybe<Scalars['timestamptz']>,
  id: Scalars['String'],
  is_delivered: Scalars['Boolean'],
  is_read: Scalars['Boolean'],
  message: Scalars['String'],
  read_at?: Maybe<Scalars['timestamptz']>,
  recipient: Stake,
  recipient_id: Scalars['String'],
}

export interface NotificationAggregate {
   __typename?: 'notification_aggregate',
  aggregate?: Maybe<NotificationAggregateFields>,
  nodes: Array<Notification>,
}

export interface NotificationAggregateFields {
   __typename?: 'notification_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<NotificationMaxFields>,
  min?: Maybe<NotificationMinFields>,
}


export interface NotificationAggregateFieldsCountArgs {
  columns?: Maybe<Array<NotificationSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface NotificationAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<NotificationMaxOrderBy>,
  min?: Maybe<NotificationMinOrderBy>,
}

export interface NotificationArrRelInsertInput {
  data: Array<NotificationInsertInput>,
  on_conflict?: Maybe<NotificationOnConflict>,
}

export interface NotificationBoolExp {
  _and?: Maybe<Array<Maybe<NotificationBoolExp>>>,
  _not?: Maybe<NotificationBoolExp>,
  _or?: Maybe<Array<Maybe<NotificationBoolExp>>>,
  activity?: Maybe<ActivityBoolExp>,
  activity_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  delivered_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_delivered?: Maybe<BooleanComparisonExp>,
  is_read?: Maybe<BooleanComparisonExp>,
  message?: Maybe<StringComparisonExp>,
  read_at?: Maybe<TimestamptzComparisonExp>,
  recipient?: Maybe<StakeBoolExp>,
  recipient_id?: Maybe<StringComparisonExp>,
}

export enum NotificationConstraint {
  NotificationPkey = 'notification_pkey'
}

export interface NotificationInsertInput {
  activity?: Maybe<ActivityObjRelInsertInput>,
  activity_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  delivered_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_delivered?: Maybe<Scalars['Boolean']>,
  is_read?: Maybe<Scalars['Boolean']>,
  message?: Maybe<Scalars['String']>,
  read_at?: Maybe<Scalars['timestamptz']>,
  recipient?: Maybe<StakeObjRelInsertInput>,
  recipient_id?: Maybe<Scalars['String']>,
}

export interface NotificationMaxFields {
   __typename?: 'notification_max_fields',
  activity_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  delivered_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  read_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
}

export interface NotificationMaxOrderBy {
  activity_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  delivered_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  message?: Maybe<OrderBy>,
  read_at?: Maybe<OrderBy>,
  recipient_id?: Maybe<OrderBy>,
}

export interface NotificationMinFields {
   __typename?: 'notification_min_fields',
  activity_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  delivered_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  read_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
}

export interface NotificationMinOrderBy {
  activity_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  delivered_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  message?: Maybe<OrderBy>,
  read_at?: Maybe<OrderBy>,
  recipient_id?: Maybe<OrderBy>,
}

export interface NotificationMutationResponse {
   __typename?: 'notification_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Notification>,
}

export interface NotificationObjRelInsertInput {
  data: NotificationInsertInput,
  on_conflict?: Maybe<NotificationOnConflict>,
}

export interface NotificationOnConflict {
  constraint: NotificationConstraint,
  update_columns: Array<NotificationUpdateColumn>,
  where?: Maybe<NotificationBoolExp>,
}

export interface NotificationOrderBy {
  activity?: Maybe<ActivityOrderBy>,
  activity_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  delivered_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_delivered?: Maybe<OrderBy>,
  is_read?: Maybe<OrderBy>,
  message?: Maybe<OrderBy>,
  read_at?: Maybe<OrderBy>,
  recipient?: Maybe<StakeOrderBy>,
  recipient_id?: Maybe<OrderBy>,
}

export interface NotificationPkColumnsInput {
  id: Scalars['String'],
}

export enum NotificationSelectColumn {
  ActivityId = 'activity_id',
  CreatedAt = 'created_at',
  DeliveredAt = 'delivered_at',
  Id = 'id',
  IsDelivered = 'is_delivered',
  IsRead = 'is_read',
  Message = 'message',
  ReadAt = 'read_at',
  RecipientId = 'recipient_id'
}

export interface NotificationSetInput {
  activity_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  delivered_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_delivered?: Maybe<Scalars['Boolean']>,
  is_read?: Maybe<Scalars['Boolean']>,
  message?: Maybe<Scalars['String']>,
  read_at?: Maybe<Scalars['timestamptz']>,
  recipient_id?: Maybe<Scalars['String']>,
}

export enum NotificationUpdateColumn {
  ActivityId = 'activity_id',
  CreatedAt = 'created_at',
  DeliveredAt = 'delivered_at',
  Id = 'id',
  IsDelivered = 'is_delivered',
  IsRead = 'is_read',
  Message = 'message',
  ReadAt = 'read_at',
  RecipientId = 'recipient_id'
}


export interface NumericComparisonExp {
  _eq?: Maybe<Scalars['numeric']>,
  _gt?: Maybe<Scalars['numeric']>,
  _gte?: Maybe<Scalars['numeric']>,
  _in?: Maybe<Array<Scalars['numeric']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['numeric']>,
  _lte?: Maybe<Scalars['numeric']>,
  _neq?: Maybe<Scalars['numeric']>,
  _nin?: Maybe<Array<Scalars['numeric']>>,
}

export enum OrderBy {
  Asc = 'asc',
  AscNullsFirst = 'asc_nulls_first',
  AscNullsLast = 'asc_nulls_last',
  Desc = 'desc',
  DescNullsFirst = 'desc_nulls_first',
  DescNullsLast = 'desc_nulls_last'
}

export interface Organization {
   __typename?: 'organization',
  api_keys: Array<ApiKey>,
  api_keys_aggregate: ApiKeyAggregate,
  application_id?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  default_timezone: Scalars['String'],
  enabled_currencies: Array<CurrenciesToOrganizations>,
  enabled_currencies_aggregate: CurrenciesToOrganizationsAggregate,
  exchange_markup: Scalars['numeric'],
  exchange_markup_enabled: Scalars['Boolean'],
  file_type_categories: Array<FileTypeCategory>,
  file_type_categories_aggregate: FileTypeCategoryAggregate,
  file_types: Array<FileType>,
  file_types_aggregate: FileTypeAggregate,
  files: Array<FilesToOrganizations>,
  files_aggregate: FilesToOrganizationsAggregate,
  id: Scalars['String'],
  industry: Industry,
  industry_id: Scalars['String'],
  margin_buffer: Scalars['numeric'],
  margin_buffer_enabled: Scalars['Boolean'],
  merchant_application_id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  origins: Array<Origin>,
  origins_aggregate: OriginAggregate,
  payment_method_logos: Array<PaymentMethodLogo>,
  payment_method_logos_aggregate: PaymentMethodLogoAggregate,
  payment_method_types: Array<PaymentMethodType>,
  payment_method_types_aggregate: PaymentMethodTypeAggregate,
  payment_methods: Array<PaymentMethod>,
  payment_methods_aggregate: PaymentMethodAggregate,
  payments: Array<Payment>,
  payments_aggregate: PaymentAggregate,
  profiles: Array<Profile>,
  profiles_aggregate: ProfileAggregate,
  segments: Array<Segment>,
  segments_aggregate: SegmentAggregate,
  share_country: Scalars['Boolean'],
  share_email_address: Scalars['Boolean'],
  share_first_and_last: Scalars['Boolean'],
  stakeholder_attribute_keys: Array<StakeholderAttributeKey>,
  stakeholder_attribute_keys_aggregate: StakeholderAttributeKeyAggregate,
  stakeholders: Array<Stakeholder>,
  stakeholders_aggregate: StakeholderAggregate,
  tags: Array<Tag>,
  tags_aggregate: TagAggregate,
  trading_account_limit: Scalars['Int'],
  trading_account_limit_enabled: Scalars['Boolean'],
  transactions: Array<Transaction>,
  transactions_aggregate: TransactionAggregate,
  transfer_approval_required_id: Scalars['Int'],
  updated_at: Scalars['timestamptz'],
}


export interface OrganizationApiKeysArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface OrganizationApiKeysAggregateArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface OrganizationEnabledCurrenciesArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface OrganizationEnabledCurrenciesAggregateArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface OrganizationFileTypeCategoriesArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface OrganizationFileTypeCategoriesAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface OrganizationFileTypesArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface OrganizationFileTypesAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface OrganizationFilesArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface OrganizationFilesAggregateArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface OrganizationOriginsArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface OrganizationOriginsAggregateArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface OrganizationPaymentMethodLogosArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface OrganizationPaymentMethodLogosAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface OrganizationPaymentMethodTypesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface OrganizationPaymentMethodTypesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface OrganizationPaymentMethodsArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface OrganizationPaymentMethodsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface OrganizationPaymentsArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface OrganizationPaymentsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface OrganizationProfilesArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface OrganizationProfilesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface OrganizationSegmentsArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface OrganizationSegmentsAggregateArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface OrganizationStakeholderAttributeKeysArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface OrganizationStakeholderAttributeKeysAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface OrganizationStakeholdersArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface OrganizationStakeholdersAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface OrganizationTagsArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface OrganizationTagsAggregateArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface OrganizationTransactionsArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface OrganizationTransactionsAggregateArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}

export interface OrganizationAggregate {
   __typename?: 'organization_aggregate',
  aggregate?: Maybe<OrganizationAggregateFields>,
  nodes: Array<Organization>,
}

export interface OrganizationAggregateFields {
   __typename?: 'organization_aggregate_fields',
  avg?: Maybe<OrganizationAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<OrganizationMaxFields>,
  min?: Maybe<OrganizationMinFields>,
  stddev?: Maybe<OrganizationStddevFields>,
  stddev_pop?: Maybe<OrganizationStddevPopFields>,
  stddev_samp?: Maybe<OrganizationStddevSampFields>,
  sum?: Maybe<OrganizationSumFields>,
  var_pop?: Maybe<OrganizationVarPopFields>,
  var_samp?: Maybe<OrganizationVarSampFields>,
  variance?: Maybe<OrganizationVarianceFields>,
}


export interface OrganizationAggregateFieldsCountArgs {
  columns?: Maybe<Array<OrganizationSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface OrganizationAggregateOrderBy {
  avg?: Maybe<OrganizationAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<OrganizationMaxOrderBy>,
  min?: Maybe<OrganizationMinOrderBy>,
  stddev?: Maybe<OrganizationStddevOrderBy>,
  stddev_pop?: Maybe<OrganizationStddevPopOrderBy>,
  stddev_samp?: Maybe<OrganizationStddevSampOrderBy>,
  sum?: Maybe<OrganizationSumOrderBy>,
  var_pop?: Maybe<OrganizationVarPopOrderBy>,
  var_samp?: Maybe<OrganizationVarSampOrderBy>,
  variance?: Maybe<OrganizationVarianceOrderBy>,
}

export interface OrganizationArrRelInsertInput {
  data: Array<OrganizationInsertInput>,
  on_conflict?: Maybe<OrganizationOnConflict>,
}

export interface OrganizationAvgFields {
   __typename?: 'organization_avg_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationAvgOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationBoolExp {
  _and?: Maybe<Array<Maybe<OrganizationBoolExp>>>,
  _not?: Maybe<OrganizationBoolExp>,
  _or?: Maybe<Array<Maybe<OrganizationBoolExp>>>,
  api_keys?: Maybe<ApiKeyBoolExp>,
  application_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  default_timezone?: Maybe<StringComparisonExp>,
  enabled_currencies?: Maybe<CurrenciesToOrganizationsBoolExp>,
  exchange_markup?: Maybe<NumericComparisonExp>,
  exchange_markup_enabled?: Maybe<BooleanComparisonExp>,
  file_type_categories?: Maybe<FileTypeCategoryBoolExp>,
  file_types?: Maybe<FileTypeBoolExp>,
  files?: Maybe<FilesToOrganizationsBoolExp>,
  id?: Maybe<StringComparisonExp>,
  industry?: Maybe<IndustryBoolExp>,
  industry_id?: Maybe<StringComparisonExp>,
  margin_buffer?: Maybe<NumericComparisonExp>,
  margin_buffer_enabled?: Maybe<BooleanComparisonExp>,
  merchant_application_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  origins?: Maybe<OriginBoolExp>,
  payment_method_logos?: Maybe<PaymentMethodLogoBoolExp>,
  payment_method_types?: Maybe<PaymentMethodTypeBoolExp>,
  payment_methods?: Maybe<PaymentMethodBoolExp>,
  payments?: Maybe<PaymentBoolExp>,
  profiles?: Maybe<ProfileBoolExp>,
  segments?: Maybe<SegmentBoolExp>,
  share_country?: Maybe<BooleanComparisonExp>,
  share_email_address?: Maybe<BooleanComparisonExp>,
  share_first_and_last?: Maybe<BooleanComparisonExp>,
  stakeholder_attribute_keys?: Maybe<StakeholderAttributeKeyBoolExp>,
  stakeholders?: Maybe<StakeholderBoolExp>,
  tags?: Maybe<TagBoolExp>,
  trading_account_limit?: Maybe<IntComparisonExp>,
  trading_account_limit_enabled?: Maybe<BooleanComparisonExp>,
  transactions?: Maybe<TransactionBoolExp>,
  transfer_approval_required_id?: Maybe<IntComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum OrganizationConstraint {
  OrganizationApplicationIdKey = 'organization_application_id_key',
  OrganizationMerchantApplicationIdKey = 'organization_merchant_application_id_key',
  OrganizationNameKey = 'organization_name_key',
  OrganizationPkey = 'organization_pkey'
}

export interface OrganizationIncInput {
  exchange_markup?: Maybe<Scalars['numeric']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
}

export interface OrganizationInsertInput {
  api_keys?: Maybe<ApiKeyArrRelInsertInput>,
  application_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  default_timezone?: Maybe<Scalars['String']>,
  enabled_currencies?: Maybe<CurrenciesToOrganizationsArrRelInsertInput>,
  exchange_markup?: Maybe<Scalars['numeric']>,
  exchange_markup_enabled?: Maybe<Scalars['Boolean']>,
  file_type_categories?: Maybe<FileTypeCategoryArrRelInsertInput>,
  file_types?: Maybe<FileTypeArrRelInsertInput>,
  files?: Maybe<FilesToOrganizationsArrRelInsertInput>,
  id?: Maybe<Scalars['String']>,
  industry?: Maybe<IndustryObjRelInsertInput>,
  industry_id?: Maybe<Scalars['String']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  margin_buffer_enabled?: Maybe<Scalars['Boolean']>,
  merchant_application_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  origins?: Maybe<OriginArrRelInsertInput>,
  payment_method_logos?: Maybe<PaymentMethodLogoArrRelInsertInput>,
  payment_method_types?: Maybe<PaymentMethodTypeArrRelInsertInput>,
  payment_methods?: Maybe<PaymentMethodArrRelInsertInput>,
  payments?: Maybe<PaymentArrRelInsertInput>,
  profiles?: Maybe<ProfileArrRelInsertInput>,
  segments?: Maybe<SegmentArrRelInsertInput>,
  share_country?: Maybe<Scalars['Boolean']>,
  share_email_address?: Maybe<Scalars['Boolean']>,
  share_first_and_last?: Maybe<Scalars['Boolean']>,
  stakeholder_attribute_keys?: Maybe<StakeholderAttributeKeyArrRelInsertInput>,
  stakeholders?: Maybe<StakeholderArrRelInsertInput>,
  tags?: Maybe<TagArrRelInsertInput>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  trading_account_limit_enabled?: Maybe<Scalars['Boolean']>,
  transactions?: Maybe<TransactionArrRelInsertInput>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface OrganizationMaxFields {
   __typename?: 'organization_max_fields',
  application_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  default_timezone?: Maybe<Scalars['String']>,
  exchange_markup?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  industry_id?: Maybe<Scalars['String']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  merchant_application_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface OrganizationMaxOrderBy {
  application_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  default_timezone?: Maybe<OrderBy>,
  exchange_markup?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  industry_id?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  merchant_application_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface OrganizationMinFields {
   __typename?: 'organization_min_fields',
  application_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  default_timezone?: Maybe<Scalars['String']>,
  exchange_markup?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  industry_id?: Maybe<Scalars['String']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  merchant_application_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface OrganizationMinOrderBy {
  application_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  default_timezone?: Maybe<OrderBy>,
  exchange_markup?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  industry_id?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  merchant_application_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface OrganizationMutationResponse {
   __typename?: 'organization_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Organization>,
}

export interface OrganizationObjRelInsertInput {
  data: OrganizationInsertInput,
  on_conflict?: Maybe<OrganizationOnConflict>,
}

export interface OrganizationOnConflict {
  constraint: OrganizationConstraint,
  update_columns: Array<OrganizationUpdateColumn>,
  where?: Maybe<OrganizationBoolExp>,
}

export interface OrganizationOrderBy {
  api_keys_aggregate?: Maybe<ApiKeyAggregateOrderBy>,
  application_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  default_timezone?: Maybe<OrderBy>,
  enabled_currencies_aggregate?: Maybe<CurrenciesToOrganizationsAggregateOrderBy>,
  exchange_markup?: Maybe<OrderBy>,
  exchange_markup_enabled?: Maybe<OrderBy>,
  file_type_categories_aggregate?: Maybe<FileTypeCategoryAggregateOrderBy>,
  file_types_aggregate?: Maybe<FileTypeAggregateOrderBy>,
  files_aggregate?: Maybe<FilesToOrganizationsAggregateOrderBy>,
  id?: Maybe<OrderBy>,
  industry?: Maybe<IndustryOrderBy>,
  industry_id?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  margin_buffer_enabled?: Maybe<OrderBy>,
  merchant_application_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  origins_aggregate?: Maybe<OriginAggregateOrderBy>,
  payment_method_logos_aggregate?: Maybe<PaymentMethodLogoAggregateOrderBy>,
  payment_method_types_aggregate?: Maybe<PaymentMethodTypeAggregateOrderBy>,
  payment_methods_aggregate?: Maybe<PaymentMethodAggregateOrderBy>,
  payments_aggregate?: Maybe<PaymentAggregateOrderBy>,
  profiles_aggregate?: Maybe<ProfileAggregateOrderBy>,
  segments_aggregate?: Maybe<SegmentAggregateOrderBy>,
  share_country?: Maybe<OrderBy>,
  share_email_address?: Maybe<OrderBy>,
  share_first_and_last?: Maybe<OrderBy>,
  stakeholder_attribute_keys_aggregate?: Maybe<StakeholderAttributeKeyAggregateOrderBy>,
  stakeholders_aggregate?: Maybe<StakeholderAggregateOrderBy>,
  tags_aggregate?: Maybe<TagAggregateOrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  trading_account_limit_enabled?: Maybe<OrderBy>,
  transactions_aggregate?: Maybe<TransactionAggregateOrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface OrganizationPkColumnsInput {
  id: Scalars['String'],
}

export enum OrganizationSelectColumn {
  ApplicationId = 'application_id',
  CreatedAt = 'created_at',
  DefaultTimezone = 'default_timezone',
  ExchangeMarkup = 'exchange_markup',
  ExchangeMarkupEnabled = 'exchange_markup_enabled',
  Id = 'id',
  IndustryId = 'industry_id',
  MarginBuffer = 'margin_buffer',
  MarginBufferEnabled = 'margin_buffer_enabled',
  MerchantApplicationId = 'merchant_application_id',
  Name = 'name',
  ShareCountry = 'share_country',
  ShareEmailAddress = 'share_email_address',
  ShareFirstAndLast = 'share_first_and_last',
  TradingAccountLimit = 'trading_account_limit',
  TradingAccountLimitEnabled = 'trading_account_limit_enabled',
  TransferApprovalRequiredId = 'transfer_approval_required_id',
  UpdatedAt = 'updated_at'
}

export interface OrganizationSetInput {
  application_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  default_timezone?: Maybe<Scalars['String']>,
  exchange_markup?: Maybe<Scalars['numeric']>,
  exchange_markup_enabled?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['String']>,
  industry_id?: Maybe<Scalars['String']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  margin_buffer_enabled?: Maybe<Scalars['Boolean']>,
  merchant_application_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  share_country?: Maybe<Scalars['Boolean']>,
  share_email_address?: Maybe<Scalars['Boolean']>,
  share_first_and_last?: Maybe<Scalars['Boolean']>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  trading_account_limit_enabled?: Maybe<Scalars['Boolean']>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface OrganizationStddevFields {
   __typename?: 'organization_stddev_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationStddevOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationStddevPopFields {
   __typename?: 'organization_stddev_pop_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationStddevPopOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationStddevSampFields {
   __typename?: 'organization_stddev_samp_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationStddevSampOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationSumFields {
   __typename?: 'organization_sum_fields',
  exchange_markup?: Maybe<Scalars['numeric']>,
  margin_buffer?: Maybe<Scalars['numeric']>,
  trading_account_limit?: Maybe<Scalars['Int']>,
  transfer_approval_required_id?: Maybe<Scalars['Int']>,
}

export interface OrganizationSumOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export enum OrganizationUpdateColumn {
  ApplicationId = 'application_id',
  CreatedAt = 'created_at',
  DefaultTimezone = 'default_timezone',
  ExchangeMarkup = 'exchange_markup',
  ExchangeMarkupEnabled = 'exchange_markup_enabled',
  Id = 'id',
  IndustryId = 'industry_id',
  MarginBuffer = 'margin_buffer',
  MarginBufferEnabled = 'margin_buffer_enabled',
  MerchantApplicationId = 'merchant_application_id',
  Name = 'name',
  ShareCountry = 'share_country',
  ShareEmailAddress = 'share_email_address',
  ShareFirstAndLast = 'share_first_and_last',
  TradingAccountLimit = 'trading_account_limit',
  TradingAccountLimitEnabled = 'trading_account_limit_enabled',
  TransferApprovalRequiredId = 'transfer_approval_required_id',
  UpdatedAt = 'updated_at'
}

export interface OrganizationVarPopFields {
   __typename?: 'organization_var_pop_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationVarPopOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationVarSampFields {
   __typename?: 'organization_var_samp_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationVarSampOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface OrganizationVarianceFields {
   __typename?: 'organization_variance_fields',
  exchange_markup?: Maybe<Scalars['Float']>,
  margin_buffer?: Maybe<Scalars['Float']>,
  trading_account_limit?: Maybe<Scalars['Float']>,
  transfer_approval_required_id?: Maybe<Scalars['Float']>,
}

export interface OrganizationVarianceOrderBy {
  exchange_markup?: Maybe<OrderBy>,
  margin_buffer?: Maybe<OrderBy>,
  trading_account_limit?: Maybe<OrderBy>,
  transfer_approval_required_id?: Maybe<OrderBy>,
}

export interface Origin {
   __typename?: 'origin',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  url: Scalars['String'],
}

export interface OriginAggregate {
   __typename?: 'origin_aggregate',
  aggregate?: Maybe<OriginAggregateFields>,
  nodes: Array<Origin>,
}

export interface OriginAggregateFields {
   __typename?: 'origin_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<OriginMaxFields>,
  min?: Maybe<OriginMinFields>,
}


export interface OriginAggregateFieldsCountArgs {
  columns?: Maybe<Array<OriginSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface OriginAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<OriginMaxOrderBy>,
  min?: Maybe<OriginMinOrderBy>,
}

export interface OriginArrRelInsertInput {
  data: Array<OriginInsertInput>,
  on_conflict?: Maybe<OriginOnConflict>,
}

export interface OriginBoolExp {
  _and?: Maybe<Array<Maybe<OriginBoolExp>>>,
  _not?: Maybe<OriginBoolExp>,
  _or?: Maybe<Array<Maybe<OriginBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  url?: Maybe<StringComparisonExp>,
}

export enum OriginConstraint {
  OriginPkey = 'origin_pkey',
  OriginUrlKey = 'origin_url_key'
}

export interface OriginInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface OriginMaxFields {
   __typename?: 'origin_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface OriginMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface OriginMinFields {
   __typename?: 'origin_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface OriginMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface OriginMutationResponse {
   __typename?: 'origin_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Origin>,
}

export interface OriginObjRelInsertInput {
  data: OriginInsertInput,
  on_conflict?: Maybe<OriginOnConflict>,
}

export interface OriginOnConflict {
  constraint: OriginConstraint,
  update_columns: Array<OriginUpdateColumn>,
  where?: Maybe<OriginBoolExp>,
}

export interface OriginOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface OriginPkColumnsInput {
  id: Scalars['String'],
}

export enum OriginSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface OriginSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export enum OriginUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface PartnerAccountGroupGridView {
   __typename?: 'partner_account_group_grid_view',
  category?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_default?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform?: Maybe<Scalars['String']>,
}

export interface PartnerAccountGroupGridViewAggregate {
   __typename?: 'partner_account_group_grid_view_aggregate',
  aggregate?: Maybe<PartnerAccountGroupGridViewAggregateFields>,
  nodes: Array<PartnerAccountGroupGridView>,
}

export interface PartnerAccountGroupGridViewAggregateFields {
   __typename?: 'partner_account_group_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PartnerAccountGroupGridViewMaxFields>,
  min?: Maybe<PartnerAccountGroupGridViewMinFields>,
}


export interface PartnerAccountGroupGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<PartnerAccountGroupGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PartnerAccountGroupGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PartnerAccountGroupGridViewMaxOrderBy>,
  min?: Maybe<PartnerAccountGroupGridViewMinOrderBy>,
}

export interface PartnerAccountGroupGridViewBoolExp {
  _and?: Maybe<Array<Maybe<PartnerAccountGroupGridViewBoolExp>>>,
  _not?: Maybe<PartnerAccountGroupGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<PartnerAccountGroupGridViewBoolExp>>>,
  category?: Maybe<StringComparisonExp>,
  currency?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_default?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  partner_id?: Maybe<StringComparisonExp>,
  platform?: Maybe<StringComparisonExp>,
}

export interface PartnerAccountGroupGridViewMaxFields {
   __typename?: 'partner_account_group_grid_view_max_fields',
  category?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform?: Maybe<Scalars['String']>,
}

export interface PartnerAccountGroupGridViewMaxOrderBy {
  category?: Maybe<OrderBy>,
  currency?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform?: Maybe<OrderBy>,
}

export interface PartnerAccountGroupGridViewMinFields {
   __typename?: 'partner_account_group_grid_view_min_fields',
  category?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  platform?: Maybe<Scalars['String']>,
}

export interface PartnerAccountGroupGridViewMinOrderBy {
  category?: Maybe<OrderBy>,
  currency?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform?: Maybe<OrderBy>,
}

export interface PartnerAccountGroupGridViewOrderBy {
  category?: Maybe<OrderBy>,
  currency?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_default?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  platform?: Maybe<OrderBy>,
}

export enum PartnerAccountGroupGridViewSelectColumn {
  Category = 'category',
  Currency = 'currency',
  Id = 'id',
  IsDefault = 'is_default',
  Name = 'name',
  PartnerId = 'partner_id',
  Platform = 'platform'
}

export interface PartnerPermission {
   __typename?: 'partner_permission',
  account_list: Scalars['String'],
  account_require_approval: Scalars['String'],
  client_account_require_approval: Scalars['String'],
  client_accounts: Scalars['String'],
  client_activity: Scalars['String'],
  client_list: Scalars['String'],
  client_manage_account: Scalars['String'],
  client_manage_notes?: Maybe<Scalars['String']>,
  client_transaction_between: Scalars['String'],
  client_transaction_credit: Scalars['String'],
  client_transaction_deposit: Scalars['String'],
  client_transaction_list: Scalars['String'],
  client_transaction_transfer: Scalars['String'],
  client_transaction_withdrawal: Scalars['String'],
  create_client: Scalars['String'],
  create_lead: Scalars['String'],
  create_partner: Scalars['String'],
  delete_client: Scalars['String'],
  delete_lead: Scalars['String'],
  delete_partner: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  edit_client: Scalars['String'],
  edit_lead: Scalars['String'],
  edit_partner: Scalars['String'],
  id: Scalars['String'],
  lead_account_list: Scalars['String'],
  lead_account_require_approval: Scalars['String'],
  lead_activity: Scalars['String'],
  lead_list: Scalars['String'],
  lead_manage_account: Scalars['String'],
  lead_manage_notes: Scalars['String'],
  manage_account: Scalars['String'],
  manage_stakeholder_file: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  partner_account_require_approval: Scalars['String'],
  partner_accounts: Scalars['String'],
  partner_activity: Scalars['String'],
  partner_list: Scalars['String'],
  partner_manage_account: Scalars['String'],
  partner_manage_notes: Scalars['String'],
  partner_transaction_between: Scalars['String'],
  partner_transaction_credit: Scalars['String'],
  partner_transaction_deposit: Scalars['String'],
  partner_transaction_list: Scalars['String'],
  partner_transaction_transfer: Scalars['String'],
  partner_transaction_withdrawal: Scalars['String'],
  read_client_approval: Scalars['String'],
  read_lead_approval: Scalars['String'],
  read_partner_approval: Scalars['String'],
  stakeholder_activity: Scalars['String'],
  stakeholder_bank_account_list: Scalars['String'],
  stakeholder_file_list: Scalars['String'],
  stakeholder_list: Scalars['String'],
  transaction_credit: Scalars['String'],
  transaction_deposit: Scalars['String'],
  transaction_list: Scalars['String'],
  transaction_service_fee: Scalars['String'],
  transaction_transfer: Scalars['String'],
  transaction_transfer_between_profiles: Scalars['String'],
  transaction_withdrawal: Scalars['String'],
}

export interface PartnerPermissionAggregate {
   __typename?: 'partner_permission_aggregate',
  aggregate?: Maybe<PartnerPermissionAggregateFields>,
  nodes: Array<PartnerPermission>,
}

export interface PartnerPermissionAggregateFields {
   __typename?: 'partner_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PartnerPermissionMaxFields>,
  min?: Maybe<PartnerPermissionMinFields>,
}


export interface PartnerPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PartnerPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PartnerPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PartnerPermissionMaxOrderBy>,
  min?: Maybe<PartnerPermissionMinOrderBy>,
}

export interface PartnerPermissionArrRelInsertInput {
  data: Array<PartnerPermissionInsertInput>,
  on_conflict?: Maybe<PartnerPermissionOnConflict>,
}

export interface PartnerPermissionBoolExp {
  _and?: Maybe<Array<Maybe<PartnerPermissionBoolExp>>>,
  _not?: Maybe<PartnerPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<PartnerPermissionBoolExp>>>,
  account_list?: Maybe<StringComparisonExp>,
  account_require_approval?: Maybe<StringComparisonExp>,
  client_account_require_approval?: Maybe<StringComparisonExp>,
  client_accounts?: Maybe<StringComparisonExp>,
  client_activity?: Maybe<StringComparisonExp>,
  client_list?: Maybe<StringComparisonExp>,
  client_manage_account?: Maybe<StringComparisonExp>,
  client_manage_notes?: Maybe<StringComparisonExp>,
  client_transaction_between?: Maybe<StringComparisonExp>,
  client_transaction_credit?: Maybe<StringComparisonExp>,
  client_transaction_deposit?: Maybe<StringComparisonExp>,
  client_transaction_list?: Maybe<StringComparisonExp>,
  client_transaction_transfer?: Maybe<StringComparisonExp>,
  client_transaction_withdrawal?: Maybe<StringComparisonExp>,
  create_client?: Maybe<StringComparisonExp>,
  create_lead?: Maybe<StringComparisonExp>,
  create_partner?: Maybe<StringComparisonExp>,
  delete_client?: Maybe<StringComparisonExp>,
  delete_lead?: Maybe<StringComparisonExp>,
  delete_partner?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  edit_client?: Maybe<StringComparisonExp>,
  edit_lead?: Maybe<StringComparisonExp>,
  edit_partner?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  lead_account_list?: Maybe<StringComparisonExp>,
  lead_account_require_approval?: Maybe<StringComparisonExp>,
  lead_activity?: Maybe<StringComparisonExp>,
  lead_list?: Maybe<StringComparisonExp>,
  lead_manage_account?: Maybe<StringComparisonExp>,
  lead_manage_notes?: Maybe<StringComparisonExp>,
  manage_account?: Maybe<StringComparisonExp>,
  manage_stakeholder_file?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner_account_require_approval?: Maybe<StringComparisonExp>,
  partner_accounts?: Maybe<StringComparisonExp>,
  partner_activity?: Maybe<StringComparisonExp>,
  partner_list?: Maybe<StringComparisonExp>,
  partner_manage_account?: Maybe<StringComparisonExp>,
  partner_manage_notes?: Maybe<StringComparisonExp>,
  partner_transaction_between?: Maybe<StringComparisonExp>,
  partner_transaction_credit?: Maybe<StringComparisonExp>,
  partner_transaction_deposit?: Maybe<StringComparisonExp>,
  partner_transaction_list?: Maybe<StringComparisonExp>,
  partner_transaction_transfer?: Maybe<StringComparisonExp>,
  partner_transaction_withdrawal?: Maybe<StringComparisonExp>,
  read_client_approval?: Maybe<StringComparisonExp>,
  read_lead_approval?: Maybe<StringComparisonExp>,
  read_partner_approval?: Maybe<StringComparisonExp>,
  stakeholder_activity?: Maybe<StringComparisonExp>,
  stakeholder_bank_account_list?: Maybe<StringComparisonExp>,
  stakeholder_file_list?: Maybe<StringComparisonExp>,
  stakeholder_list?: Maybe<StringComparisonExp>,
  transaction_credit?: Maybe<StringComparisonExp>,
  transaction_deposit?: Maybe<StringComparisonExp>,
  transaction_list?: Maybe<StringComparisonExp>,
  transaction_service_fee?: Maybe<StringComparisonExp>,
  transaction_transfer?: Maybe<StringComparisonExp>,
  transaction_transfer_between_profiles?: Maybe<StringComparisonExp>,
  transaction_withdrawal?: Maybe<StringComparisonExp>,
}

export enum PartnerPermissionConstraint {
  PartnerPermissionPkey = 'partner_permission_pkey'
}

export interface PartnerPermissionInsertInput {
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  client_account_require_approval?: Maybe<Scalars['String']>,
  client_accounts?: Maybe<Scalars['String']>,
  client_activity?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  client_manage_account?: Maybe<Scalars['String']>,
  client_manage_notes?: Maybe<Scalars['String']>,
  client_transaction_between?: Maybe<Scalars['String']>,
  client_transaction_credit?: Maybe<Scalars['String']>,
  client_transaction_deposit?: Maybe<Scalars['String']>,
  client_transaction_list?: Maybe<Scalars['String']>,
  client_transaction_transfer?: Maybe<Scalars['String']>,
  client_transaction_withdrawal?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_account_list?: Maybe<Scalars['String']>,
  lead_account_require_approval?: Maybe<Scalars['String']>,
  lead_activity?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  lead_manage_account?: Maybe<Scalars['String']>,
  lead_manage_notes?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  partner_account_require_approval?: Maybe<Scalars['String']>,
  partner_accounts?: Maybe<Scalars['String']>,
  partner_activity?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  partner_manage_account?: Maybe<Scalars['String']>,
  partner_manage_notes?: Maybe<Scalars['String']>,
  partner_transaction_between?: Maybe<Scalars['String']>,
  partner_transaction_credit?: Maybe<Scalars['String']>,
  partner_transaction_deposit?: Maybe<Scalars['String']>,
  partner_transaction_list?: Maybe<Scalars['String']>,
  partner_transaction_transfer?: Maybe<Scalars['String']>,
  partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  read_client_approval?: Maybe<Scalars['String']>,
  read_lead_approval?: Maybe<Scalars['String']>,
  read_partner_approval?: Maybe<Scalars['String']>,
  stakeholder_activity?: Maybe<Scalars['String']>,
  stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface PartnerPermissionMaxFields {
   __typename?: 'partner_permission_max_fields',
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  client_account_require_approval?: Maybe<Scalars['String']>,
  client_accounts?: Maybe<Scalars['String']>,
  client_activity?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  client_manage_account?: Maybe<Scalars['String']>,
  client_manage_notes?: Maybe<Scalars['String']>,
  client_transaction_between?: Maybe<Scalars['String']>,
  client_transaction_credit?: Maybe<Scalars['String']>,
  client_transaction_deposit?: Maybe<Scalars['String']>,
  client_transaction_list?: Maybe<Scalars['String']>,
  client_transaction_transfer?: Maybe<Scalars['String']>,
  client_transaction_withdrawal?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_account_list?: Maybe<Scalars['String']>,
  lead_account_require_approval?: Maybe<Scalars['String']>,
  lead_activity?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  lead_manage_account?: Maybe<Scalars['String']>,
  lead_manage_notes?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_account_require_approval?: Maybe<Scalars['String']>,
  partner_accounts?: Maybe<Scalars['String']>,
  partner_activity?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  partner_manage_account?: Maybe<Scalars['String']>,
  partner_manage_notes?: Maybe<Scalars['String']>,
  partner_transaction_between?: Maybe<Scalars['String']>,
  partner_transaction_credit?: Maybe<Scalars['String']>,
  partner_transaction_deposit?: Maybe<Scalars['String']>,
  partner_transaction_list?: Maybe<Scalars['String']>,
  partner_transaction_transfer?: Maybe<Scalars['String']>,
  partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  read_client_approval?: Maybe<Scalars['String']>,
  read_lead_approval?: Maybe<Scalars['String']>,
  read_partner_approval?: Maybe<Scalars['String']>,
  stakeholder_activity?: Maybe<Scalars['String']>,
  stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface PartnerPermissionMaxOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  client_account_require_approval?: Maybe<OrderBy>,
  client_accounts?: Maybe<OrderBy>,
  client_activity?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  client_manage_account?: Maybe<OrderBy>,
  client_manage_notes?: Maybe<OrderBy>,
  client_transaction_between?: Maybe<OrderBy>,
  client_transaction_credit?: Maybe<OrderBy>,
  client_transaction_deposit?: Maybe<OrderBy>,
  client_transaction_list?: Maybe<OrderBy>,
  client_transaction_transfer?: Maybe<OrderBy>,
  client_transaction_withdrawal?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_account_list?: Maybe<OrderBy>,
  lead_account_require_approval?: Maybe<OrderBy>,
  lead_activity?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  lead_manage_account?: Maybe<OrderBy>,
  lead_manage_notes?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_account_require_approval?: Maybe<OrderBy>,
  partner_accounts?: Maybe<OrderBy>,
  partner_activity?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  partner_manage_account?: Maybe<OrderBy>,
  partner_manage_notes?: Maybe<OrderBy>,
  partner_transaction_between?: Maybe<OrderBy>,
  partner_transaction_credit?: Maybe<OrderBy>,
  partner_transaction_deposit?: Maybe<OrderBy>,
  partner_transaction_list?: Maybe<OrderBy>,
  partner_transaction_transfer?: Maybe<OrderBy>,
  partner_transaction_withdrawal?: Maybe<OrderBy>,
  read_client_approval?: Maybe<OrderBy>,
  read_lead_approval?: Maybe<OrderBy>,
  read_partner_approval?: Maybe<OrderBy>,
  stakeholder_activity?: Maybe<OrderBy>,
  stakeholder_bank_account_list?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface PartnerPermissionMinFields {
   __typename?: 'partner_permission_min_fields',
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  client_account_require_approval?: Maybe<Scalars['String']>,
  client_accounts?: Maybe<Scalars['String']>,
  client_activity?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  client_manage_account?: Maybe<Scalars['String']>,
  client_manage_notes?: Maybe<Scalars['String']>,
  client_transaction_between?: Maybe<Scalars['String']>,
  client_transaction_credit?: Maybe<Scalars['String']>,
  client_transaction_deposit?: Maybe<Scalars['String']>,
  client_transaction_list?: Maybe<Scalars['String']>,
  client_transaction_transfer?: Maybe<Scalars['String']>,
  client_transaction_withdrawal?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_account_list?: Maybe<Scalars['String']>,
  lead_account_require_approval?: Maybe<Scalars['String']>,
  lead_activity?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  lead_manage_account?: Maybe<Scalars['String']>,
  lead_manage_notes?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_account_require_approval?: Maybe<Scalars['String']>,
  partner_accounts?: Maybe<Scalars['String']>,
  partner_activity?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  partner_manage_account?: Maybe<Scalars['String']>,
  partner_manage_notes?: Maybe<Scalars['String']>,
  partner_transaction_between?: Maybe<Scalars['String']>,
  partner_transaction_credit?: Maybe<Scalars['String']>,
  partner_transaction_deposit?: Maybe<Scalars['String']>,
  partner_transaction_list?: Maybe<Scalars['String']>,
  partner_transaction_transfer?: Maybe<Scalars['String']>,
  partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  read_client_approval?: Maybe<Scalars['String']>,
  read_lead_approval?: Maybe<Scalars['String']>,
  read_partner_approval?: Maybe<Scalars['String']>,
  stakeholder_activity?: Maybe<Scalars['String']>,
  stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface PartnerPermissionMinOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  client_account_require_approval?: Maybe<OrderBy>,
  client_accounts?: Maybe<OrderBy>,
  client_activity?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  client_manage_account?: Maybe<OrderBy>,
  client_manage_notes?: Maybe<OrderBy>,
  client_transaction_between?: Maybe<OrderBy>,
  client_transaction_credit?: Maybe<OrderBy>,
  client_transaction_deposit?: Maybe<OrderBy>,
  client_transaction_list?: Maybe<OrderBy>,
  client_transaction_transfer?: Maybe<OrderBy>,
  client_transaction_withdrawal?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_account_list?: Maybe<OrderBy>,
  lead_account_require_approval?: Maybe<OrderBy>,
  lead_activity?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  lead_manage_account?: Maybe<OrderBy>,
  lead_manage_notes?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_account_require_approval?: Maybe<OrderBy>,
  partner_accounts?: Maybe<OrderBy>,
  partner_activity?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  partner_manage_account?: Maybe<OrderBy>,
  partner_manage_notes?: Maybe<OrderBy>,
  partner_transaction_between?: Maybe<OrderBy>,
  partner_transaction_credit?: Maybe<OrderBy>,
  partner_transaction_deposit?: Maybe<OrderBy>,
  partner_transaction_list?: Maybe<OrderBy>,
  partner_transaction_transfer?: Maybe<OrderBy>,
  partner_transaction_withdrawal?: Maybe<OrderBy>,
  read_client_approval?: Maybe<OrderBy>,
  read_lead_approval?: Maybe<OrderBy>,
  read_partner_approval?: Maybe<OrderBy>,
  stakeholder_activity?: Maybe<OrderBy>,
  stakeholder_bank_account_list?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface PartnerPermissionMutationResponse {
   __typename?: 'partner_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PartnerPermission>,
}

export interface PartnerPermissionObjRelInsertInput {
  data: PartnerPermissionInsertInput,
  on_conflict?: Maybe<PartnerPermissionOnConflict>,
}

export interface PartnerPermissionOnConflict {
  constraint: PartnerPermissionConstraint,
  update_columns: Array<PartnerPermissionUpdateColumn>,
  where?: Maybe<PartnerPermissionBoolExp>,
}

export interface PartnerPermissionOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  client_account_require_approval?: Maybe<OrderBy>,
  client_accounts?: Maybe<OrderBy>,
  client_activity?: Maybe<OrderBy>,
  client_list?: Maybe<OrderBy>,
  client_manage_account?: Maybe<OrderBy>,
  client_manage_notes?: Maybe<OrderBy>,
  client_transaction_between?: Maybe<OrderBy>,
  client_transaction_credit?: Maybe<OrderBy>,
  client_transaction_deposit?: Maybe<OrderBy>,
  client_transaction_list?: Maybe<OrderBy>,
  client_transaction_transfer?: Maybe<OrderBy>,
  client_transaction_withdrawal?: Maybe<OrderBy>,
  create_client?: Maybe<OrderBy>,
  create_lead?: Maybe<OrderBy>,
  create_partner?: Maybe<OrderBy>,
  delete_client?: Maybe<OrderBy>,
  delete_lead?: Maybe<OrderBy>,
  delete_partner?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  edit_client?: Maybe<OrderBy>,
  edit_lead?: Maybe<OrderBy>,
  edit_partner?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  lead_account_list?: Maybe<OrderBy>,
  lead_account_require_approval?: Maybe<OrderBy>,
  lead_activity?: Maybe<OrderBy>,
  lead_list?: Maybe<OrderBy>,
  lead_manage_account?: Maybe<OrderBy>,
  lead_manage_notes?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_account_require_approval?: Maybe<OrderBy>,
  partner_accounts?: Maybe<OrderBy>,
  partner_activity?: Maybe<OrderBy>,
  partner_list?: Maybe<OrderBy>,
  partner_manage_account?: Maybe<OrderBy>,
  partner_manage_notes?: Maybe<OrderBy>,
  partner_transaction_between?: Maybe<OrderBy>,
  partner_transaction_credit?: Maybe<OrderBy>,
  partner_transaction_deposit?: Maybe<OrderBy>,
  partner_transaction_list?: Maybe<OrderBy>,
  partner_transaction_transfer?: Maybe<OrderBy>,
  partner_transaction_withdrawal?: Maybe<OrderBy>,
  read_client_approval?: Maybe<OrderBy>,
  read_lead_approval?: Maybe<OrderBy>,
  read_partner_approval?: Maybe<OrderBy>,
  stakeholder_activity?: Maybe<OrderBy>,
  stakeholder_bank_account_list?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  stakeholder_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface PartnerPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum PartnerPermissionSelectColumn {
  AccountList = 'account_list',
  AccountRequireApproval = 'account_require_approval',
  ClientAccountRequireApproval = 'client_account_require_approval',
  ClientAccounts = 'client_accounts',
  ClientActivity = 'client_activity',
  ClientList = 'client_list',
  ClientManageAccount = 'client_manage_account',
  ClientManageNotes = 'client_manage_notes',
  ClientTransactionBetween = 'client_transaction_between',
  ClientTransactionCredit = 'client_transaction_credit',
  ClientTransactionDeposit = 'client_transaction_deposit',
  ClientTransactionList = 'client_transaction_list',
  ClientTransactionTransfer = 'client_transaction_transfer',
  ClientTransactionWithdrawal = 'client_transaction_withdrawal',
  CreateClient = 'create_client',
  CreateLead = 'create_lead',
  CreatePartner = 'create_partner',
  DeleteClient = 'delete_client',
  DeleteLead = 'delete_lead',
  DeletePartner = 'delete_partner',
  Description = 'description',
  EditClient = 'edit_client',
  EditLead = 'edit_lead',
  EditPartner = 'edit_partner',
  Id = 'id',
  LeadAccountList = 'lead_account_list',
  LeadAccountRequireApproval = 'lead_account_require_approval',
  LeadActivity = 'lead_activity',
  LeadList = 'lead_list',
  LeadManageAccount = 'lead_manage_account',
  LeadManageNotes = 'lead_manage_notes',
  ManageAccount = 'manage_account',
  ManageStakeholderFile = 'manage_stakeholder_file',
  Name = 'name',
  OrganizationId = 'organization_id',
  PartnerAccountRequireApproval = 'partner_account_require_approval',
  PartnerAccounts = 'partner_accounts',
  PartnerActivity = 'partner_activity',
  PartnerList = 'partner_list',
  PartnerManageAccount = 'partner_manage_account',
  PartnerManageNotes = 'partner_manage_notes',
  PartnerTransactionBetween = 'partner_transaction_between',
  PartnerTransactionCredit = 'partner_transaction_credit',
  PartnerTransactionDeposit = 'partner_transaction_deposit',
  PartnerTransactionList = 'partner_transaction_list',
  PartnerTransactionTransfer = 'partner_transaction_transfer',
  PartnerTransactionWithdrawal = 'partner_transaction_withdrawal',
  ReadClientApproval = 'read_client_approval',
  ReadLeadApproval = 'read_lead_approval',
  ReadPartnerApproval = 'read_partner_approval',
  StakeholderActivity = 'stakeholder_activity',
  StakeholderBankAccountList = 'stakeholder_bank_account_list',
  StakeholderFileList = 'stakeholder_file_list',
  StakeholderList = 'stakeholder_list',
  TransactionCredit = 'transaction_credit',
  TransactionDeposit = 'transaction_deposit',
  TransactionList = 'transaction_list',
  TransactionServiceFee = 'transaction_service_fee',
  TransactionTransfer = 'transaction_transfer',
  TransactionTransferBetweenProfiles = 'transaction_transfer_between_profiles',
  TransactionWithdrawal = 'transaction_withdrawal'
}

export interface PartnerPermissionSetInput {
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  client_account_require_approval?: Maybe<Scalars['String']>,
  client_accounts?: Maybe<Scalars['String']>,
  client_activity?: Maybe<Scalars['String']>,
  client_list?: Maybe<Scalars['String']>,
  client_manage_account?: Maybe<Scalars['String']>,
  client_manage_notes?: Maybe<Scalars['String']>,
  client_transaction_between?: Maybe<Scalars['String']>,
  client_transaction_credit?: Maybe<Scalars['String']>,
  client_transaction_deposit?: Maybe<Scalars['String']>,
  client_transaction_list?: Maybe<Scalars['String']>,
  client_transaction_transfer?: Maybe<Scalars['String']>,
  client_transaction_withdrawal?: Maybe<Scalars['String']>,
  create_client?: Maybe<Scalars['String']>,
  create_lead?: Maybe<Scalars['String']>,
  create_partner?: Maybe<Scalars['String']>,
  delete_client?: Maybe<Scalars['String']>,
  delete_lead?: Maybe<Scalars['String']>,
  delete_partner?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  edit_client?: Maybe<Scalars['String']>,
  edit_lead?: Maybe<Scalars['String']>,
  edit_partner?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  lead_account_list?: Maybe<Scalars['String']>,
  lead_account_require_approval?: Maybe<Scalars['String']>,
  lead_activity?: Maybe<Scalars['String']>,
  lead_list?: Maybe<Scalars['String']>,
  lead_manage_account?: Maybe<Scalars['String']>,
  lead_manage_notes?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_account_require_approval?: Maybe<Scalars['String']>,
  partner_accounts?: Maybe<Scalars['String']>,
  partner_activity?: Maybe<Scalars['String']>,
  partner_list?: Maybe<Scalars['String']>,
  partner_manage_account?: Maybe<Scalars['String']>,
  partner_manage_notes?: Maybe<Scalars['String']>,
  partner_transaction_between?: Maybe<Scalars['String']>,
  partner_transaction_credit?: Maybe<Scalars['String']>,
  partner_transaction_deposit?: Maybe<Scalars['String']>,
  partner_transaction_list?: Maybe<Scalars['String']>,
  partner_transaction_transfer?: Maybe<Scalars['String']>,
  partner_transaction_withdrawal?: Maybe<Scalars['String']>,
  read_client_approval?: Maybe<Scalars['String']>,
  read_lead_approval?: Maybe<Scalars['String']>,
  read_partner_approval?: Maybe<Scalars['String']>,
  stakeholder_activity?: Maybe<Scalars['String']>,
  stakeholder_bank_account_list?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  stakeholder_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export enum PartnerPermissionUpdateColumn {
  AccountList = 'account_list',
  AccountRequireApproval = 'account_require_approval',
  ClientAccountRequireApproval = 'client_account_require_approval',
  ClientAccounts = 'client_accounts',
  ClientActivity = 'client_activity',
  ClientList = 'client_list',
  ClientManageAccount = 'client_manage_account',
  ClientManageNotes = 'client_manage_notes',
  ClientTransactionBetween = 'client_transaction_between',
  ClientTransactionCredit = 'client_transaction_credit',
  ClientTransactionDeposit = 'client_transaction_deposit',
  ClientTransactionList = 'client_transaction_list',
  ClientTransactionTransfer = 'client_transaction_transfer',
  ClientTransactionWithdrawal = 'client_transaction_withdrawal',
  CreateClient = 'create_client',
  CreateLead = 'create_lead',
  CreatePartner = 'create_partner',
  DeleteClient = 'delete_client',
  DeleteLead = 'delete_lead',
  DeletePartner = 'delete_partner',
  Description = 'description',
  EditClient = 'edit_client',
  EditLead = 'edit_lead',
  EditPartner = 'edit_partner',
  Id = 'id',
  LeadAccountList = 'lead_account_list',
  LeadAccountRequireApproval = 'lead_account_require_approval',
  LeadActivity = 'lead_activity',
  LeadList = 'lead_list',
  LeadManageAccount = 'lead_manage_account',
  LeadManageNotes = 'lead_manage_notes',
  ManageAccount = 'manage_account',
  ManageStakeholderFile = 'manage_stakeholder_file',
  Name = 'name',
  OrganizationId = 'organization_id',
  PartnerAccountRequireApproval = 'partner_account_require_approval',
  PartnerAccounts = 'partner_accounts',
  PartnerActivity = 'partner_activity',
  PartnerList = 'partner_list',
  PartnerManageAccount = 'partner_manage_account',
  PartnerManageNotes = 'partner_manage_notes',
  PartnerTransactionBetween = 'partner_transaction_between',
  PartnerTransactionCredit = 'partner_transaction_credit',
  PartnerTransactionDeposit = 'partner_transaction_deposit',
  PartnerTransactionList = 'partner_transaction_list',
  PartnerTransactionTransfer = 'partner_transaction_transfer',
  PartnerTransactionWithdrawal = 'partner_transaction_withdrawal',
  ReadClientApproval = 'read_client_approval',
  ReadLeadApproval = 'read_lead_approval',
  ReadPartnerApproval = 'read_partner_approval',
  StakeholderActivity = 'stakeholder_activity',
  StakeholderBankAccountList = 'stakeholder_bank_account_list',
  StakeholderFileList = 'stakeholder_file_list',
  StakeholderList = 'stakeholder_list',
  TransactionCredit = 'transaction_credit',
  TransactionDeposit = 'transaction_deposit',
  TransactionList = 'transaction_list',
  TransactionServiceFee = 'transaction_service_fee',
  TransactionTransfer = 'transaction_transfer',
  TransactionTransferBetweenProfiles = 'transaction_transfer_between_profiles',
  TransactionWithdrawal = 'transaction_withdrawal'
}

export interface Payment {
   __typename?: 'payment',
  amount: Scalars['numeric'],
  attributes: Array<PaymentAttributeValue>,
  attributes_aggregate: PaymentAttributeValueAggregate,
  bank_account?: Maybe<BankAccount>,
  bank_account_id?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  currency: Currency,
  currency_id: Scalars['String'],
  id: Scalars['String'],
  method_type: PaymentMethodType,
  method_type_id: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  provider_transaction_id?: Maybe<Scalars['String']>,
  reference_id?: Maybe<Scalars['String']>,
  status: PaymentStatus,
  status_id: Scalars['String'],
  transaction?: Maybe<Transaction>,
  updated_at: Scalars['timestamptz'],
}


export interface PaymentAttributesArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}


export interface PaymentAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}

export interface PaymentAggregate {
   __typename?: 'payment_aggregate',
  aggregate?: Maybe<PaymentAggregateFields>,
  nodes: Array<Payment>,
}

export interface PaymentAggregateFields {
   __typename?: 'payment_aggregate_fields',
  avg?: Maybe<PaymentAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMaxFields>,
  min?: Maybe<PaymentMinFields>,
  stddev?: Maybe<PaymentStddevFields>,
  stddev_pop?: Maybe<PaymentStddevPopFields>,
  stddev_samp?: Maybe<PaymentStddevSampFields>,
  sum?: Maybe<PaymentSumFields>,
  var_pop?: Maybe<PaymentVarPopFields>,
  var_samp?: Maybe<PaymentVarSampFields>,
  variance?: Maybe<PaymentVarianceFields>,
}


export interface PaymentAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAggregateOrderBy {
  avg?: Maybe<PaymentAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMaxOrderBy>,
  min?: Maybe<PaymentMinOrderBy>,
  stddev?: Maybe<PaymentStddevOrderBy>,
  stddev_pop?: Maybe<PaymentStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentStddevSampOrderBy>,
  sum?: Maybe<PaymentSumOrderBy>,
  var_pop?: Maybe<PaymentVarPopOrderBy>,
  var_samp?: Maybe<PaymentVarSampOrderBy>,
  variance?: Maybe<PaymentVarianceOrderBy>,
}

export interface PaymentArrRelInsertInput {
  data: Array<PaymentInsertInput>,
  on_conflict?: Maybe<PaymentOnConflict>,
}

export interface PaymentAttributeGlobalIdentifier {
   __typename?: 'payment_attribute_global_identifier',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  keys: Array<PaymentAttributeKey>,
  keys_aggregate: PaymentAttributeKeyAggregate,
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentAttributeGlobalIdentifierKeysArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface PaymentAttributeGlobalIdentifierKeysAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}

export interface PaymentAttributeGlobalIdentifierAggregate {
   __typename?: 'payment_attribute_global_identifier_aggregate',
  aggregate?: Maybe<PaymentAttributeGlobalIdentifierAggregateFields>,
  nodes: Array<PaymentAttributeGlobalIdentifier>,
}

export interface PaymentAttributeGlobalIdentifierAggregateFields {
   __typename?: 'payment_attribute_global_identifier_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentAttributeGlobalIdentifierMaxFields>,
  min?: Maybe<PaymentAttributeGlobalIdentifierMinFields>,
}


export interface PaymentAttributeGlobalIdentifierAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentAttributeGlobalIdentifierSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAttributeGlobalIdentifierAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentAttributeGlobalIdentifierMaxOrderBy>,
  min?: Maybe<PaymentAttributeGlobalIdentifierMinOrderBy>,
}

export interface PaymentAttributeGlobalIdentifierArrRelInsertInput {
  data: Array<PaymentAttributeGlobalIdentifierInsertInput>,
  on_conflict?: Maybe<PaymentAttributeGlobalIdentifierOnConflict>,
}

export interface PaymentAttributeGlobalIdentifierBoolExp {
  _and?: Maybe<Array<Maybe<PaymentAttributeGlobalIdentifierBoolExp>>>,
  _not?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentAttributeGlobalIdentifierBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  keys?: Maybe<PaymentAttributeKeyBoolExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentAttributeGlobalIdentifierConstraint {
  PaymentAttributeGlobalIdentifierNameKey = 'payment_attribute_global_identifier_name_key',
  PaymentAttributeGlobalIdentifierPkey = 'payment_attribute_global_identifier_pkey'
}

export interface PaymentAttributeGlobalIdentifierInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  keys?: Maybe<PaymentAttributeKeyArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeGlobalIdentifierMaxFields {
   __typename?: 'payment_attribute_global_identifier_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeGlobalIdentifierMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeGlobalIdentifierMinFields {
   __typename?: 'payment_attribute_global_identifier_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeGlobalIdentifierMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeGlobalIdentifierMutationResponse {
   __typename?: 'payment_attribute_global_identifier_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentAttributeGlobalIdentifier>,
}

export interface PaymentAttributeGlobalIdentifierObjRelInsertInput {
  data: PaymentAttributeGlobalIdentifierInsertInput,
  on_conflict?: Maybe<PaymentAttributeGlobalIdentifierOnConflict>,
}

export interface PaymentAttributeGlobalIdentifierOnConflict {
  constraint: PaymentAttributeGlobalIdentifierConstraint,
  update_columns: Array<PaymentAttributeGlobalIdentifierUpdateColumn>,
  where?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>,
}

export interface PaymentAttributeGlobalIdentifierOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  keys_aggregate?: Maybe<PaymentAttributeKeyAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeGlobalIdentifierPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentAttributeGlobalIdentifierSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeGlobalIdentifierSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentAttributeGlobalIdentifierUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeKey {
   __typename?: 'payment_attribute_key',
  created_at: Scalars['timestamptz'],
  global_identifier: PaymentAttributeGlobalIdentifier,
  global_identifier_id: Scalars['String'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  order: Scalars['Int'],
  provider_key: Scalars['String'],
  provider_method_type: PaymentProviderMethodType,
  provider_method_type_id: Scalars['String'],
  subsection: PaymentAttributeSubsection,
  subsection_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentAttributeKeyAggregate {
   __typename?: 'payment_attribute_key_aggregate',
  aggregate?: Maybe<PaymentAttributeKeyAggregateFields>,
  nodes: Array<PaymentAttributeKey>,
}

export interface PaymentAttributeKeyAggregateFields {
   __typename?: 'payment_attribute_key_aggregate_fields',
  avg?: Maybe<PaymentAttributeKeyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentAttributeKeyMaxFields>,
  min?: Maybe<PaymentAttributeKeyMinFields>,
  stddev?: Maybe<PaymentAttributeKeyStddevFields>,
  stddev_pop?: Maybe<PaymentAttributeKeyStddevPopFields>,
  stddev_samp?: Maybe<PaymentAttributeKeyStddevSampFields>,
  sum?: Maybe<PaymentAttributeKeySumFields>,
  var_pop?: Maybe<PaymentAttributeKeyVarPopFields>,
  var_samp?: Maybe<PaymentAttributeKeyVarSampFields>,
  variance?: Maybe<PaymentAttributeKeyVarianceFields>,
}


export interface PaymentAttributeKeyAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAttributeKeyAggregateOrderBy {
  avg?: Maybe<PaymentAttributeKeyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentAttributeKeyMaxOrderBy>,
  min?: Maybe<PaymentAttributeKeyMinOrderBy>,
  stddev?: Maybe<PaymentAttributeKeyStddevOrderBy>,
  stddev_pop?: Maybe<PaymentAttributeKeyStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentAttributeKeyStddevSampOrderBy>,
  sum?: Maybe<PaymentAttributeKeySumOrderBy>,
  var_pop?: Maybe<PaymentAttributeKeyVarPopOrderBy>,
  var_samp?: Maybe<PaymentAttributeKeyVarSampOrderBy>,
  variance?: Maybe<PaymentAttributeKeyVarianceOrderBy>,
}

export interface PaymentAttributeKeyArrRelInsertInput {
  data: Array<PaymentAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentAttributeKeyOnConflict>,
}

export interface PaymentAttributeKeyAvgFields {
   __typename?: 'payment_attribute_key_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyBoolExp {
  _and?: Maybe<Array<Maybe<PaymentAttributeKeyBoolExp>>>,
  _not?: Maybe<PaymentAttributeKeyBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentAttributeKeyBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  global_identifier?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>,
  global_identifier_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  provider_key?: Maybe<StringComparisonExp>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeBoolExp>,
  provider_method_type_id?: Maybe<StringComparisonExp>,
  subsection?: Maybe<PaymentAttributeSubsectionBoolExp>,
  subsection_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentAttributeKeyConstraint {
  PaymentAttributeKeyOrderSubsectionIdKey = 'payment_attribute_key_order_subsection_id_key',
  PaymentAttributeKeyPkey = 'payment_attribute_key_pkey'
}

export interface PaymentAttributeKeyIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeKeyInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  global_identifier?: Maybe<PaymentAttributeGlobalIdentifierObjRelInsertInput>,
  global_identifier_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Scalars['Int']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeObjRelInsertInput>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection?: Maybe<PaymentAttributeSubsectionObjRelInsertInput>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeKeyMaxFields {
   __typename?: 'payment_attribute_key_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  global_identifier_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeKeyMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  global_identifier_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyMinFields {
   __typename?: 'payment_attribute_key_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  global_identifier_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeKeyMinOrderBy {
  created_at?: Maybe<OrderBy>,
  global_identifier_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyMutationResponse {
   __typename?: 'payment_attribute_key_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentAttributeKey>,
}

export interface PaymentAttributeKeyObjRelInsertInput {
  data: PaymentAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentAttributeKeyOnConflict>,
}

export interface PaymentAttributeKeyOnConflict {
  constraint: PaymentAttributeKeyConstraint,
  update_columns: Array<PaymentAttributeKeyUpdateColumn>,
  where?: Maybe<PaymentAttributeKeyBoolExp>,
}

export interface PaymentAttributeKeyOrderBy {
  created_at?: Maybe<OrderBy>,
  global_identifier?: Maybe<PaymentAttributeGlobalIdentifierOrderBy>,
  global_identifier_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeOrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection?: Maybe<PaymentAttributeSubsectionOrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentAttributeKeySelectColumn {
  CreatedAt = 'created_at',
  GlobalIdentifierId = 'global_identifier_id',
  Id = 'id',
  IsRequired = 'is_required',
  Order = 'order',
  ProviderKey = 'provider_key',
  ProviderMethodTypeId = 'provider_method_type_id',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeKeySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  global_identifier_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  order?: Maybe<Scalars['Int']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeKeyStddevFields {
   __typename?: 'payment_attribute_key_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyStddevPopFields {
   __typename?: 'payment_attribute_key_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyStddevSampFields {
   __typename?: 'payment_attribute_key_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeySumFields {
   __typename?: 'payment_attribute_key_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeKeySumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentAttributeKeyUpdateColumn {
  CreatedAt = 'created_at',
  GlobalIdentifierId = 'global_identifier_id',
  Id = 'id',
  IsRequired = 'is_required',
  Order = 'order',
  ProviderKey = 'provider_key',
  ProviderMethodTypeId = 'provider_method_type_id',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeKeyVarPopFields {
   __typename?: 'payment_attribute_key_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyVarSampFields {
   __typename?: 'payment_attribute_key_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeKeyVarianceFields {
   __typename?: 'payment_attribute_key_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeKeyVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSection {
   __typename?: 'payment_attribute_section',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  name: Scalars['String'],
  order: Scalars['Int'],
  provider_method_type: PaymentProviderMethodType,
  provider_method_type_id: Scalars['String'],
  subsections: Array<PaymentAttributeSubsection>,
  subsections_aggregate: PaymentAttributeSubsectionAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface PaymentAttributeSectionSubsectionsArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}


export interface PaymentAttributeSectionSubsectionsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}

export interface PaymentAttributeSectionAggregate {
   __typename?: 'payment_attribute_section_aggregate',
  aggregate?: Maybe<PaymentAttributeSectionAggregateFields>,
  nodes: Array<PaymentAttributeSection>,
}

export interface PaymentAttributeSectionAggregateFields {
   __typename?: 'payment_attribute_section_aggregate_fields',
  avg?: Maybe<PaymentAttributeSectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentAttributeSectionMaxFields>,
  min?: Maybe<PaymentAttributeSectionMinFields>,
  stddev?: Maybe<PaymentAttributeSectionStddevFields>,
  stddev_pop?: Maybe<PaymentAttributeSectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentAttributeSectionStddevSampFields>,
  sum?: Maybe<PaymentAttributeSectionSumFields>,
  var_pop?: Maybe<PaymentAttributeSectionVarPopFields>,
  var_samp?: Maybe<PaymentAttributeSectionVarSampFields>,
  variance?: Maybe<PaymentAttributeSectionVarianceFields>,
}


export interface PaymentAttributeSectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentAttributeSectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAttributeSectionAggregateOrderBy {
  avg?: Maybe<PaymentAttributeSectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentAttributeSectionMaxOrderBy>,
  min?: Maybe<PaymentAttributeSectionMinOrderBy>,
  stddev?: Maybe<PaymentAttributeSectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentAttributeSectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentAttributeSectionStddevSampOrderBy>,
  sum?: Maybe<PaymentAttributeSectionSumOrderBy>,
  var_pop?: Maybe<PaymentAttributeSectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentAttributeSectionVarSampOrderBy>,
  variance?: Maybe<PaymentAttributeSectionVarianceOrderBy>,
}

export interface PaymentAttributeSectionArrRelInsertInput {
  data: Array<PaymentAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentAttributeSectionOnConflict>,
}

export interface PaymentAttributeSectionAvgFields {
   __typename?: 'payment_attribute_section_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentAttributeSectionBoolExp>>>,
  _not?: Maybe<PaymentAttributeSectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentAttributeSectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeBoolExp>,
  provider_method_type_id?: Maybe<StringComparisonExp>,
  subsections?: Maybe<PaymentAttributeSubsectionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentAttributeSectionConstraint {
  PaymentAttributeSectionNameKey = 'payment_attribute_section_name_key',
  PaymentAttributeSectionPkey = 'payment_attribute_section_pkey'
}

export interface PaymentAttributeSectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeSectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeObjRelInsertInput>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsections?: Maybe<PaymentAttributeSubsectionArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSectionMaxFields {
   __typename?: 'payment_attribute_section_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionMinFields {
   __typename?: 'payment_attribute_section_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionMutationResponse {
   __typename?: 'payment_attribute_section_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentAttributeSection>,
}

export interface PaymentAttributeSectionObjRelInsertInput {
  data: PaymentAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentAttributeSectionOnConflict>,
}

export interface PaymentAttributeSectionOnConflict {
  constraint: PaymentAttributeSectionConstraint,
  update_columns: Array<PaymentAttributeSectionUpdateColumn>,
  where?: Maybe<PaymentAttributeSectionBoolExp>,
}

export interface PaymentAttributeSectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeOrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsections_aggregate?: Maybe<PaymentAttributeSubsectionAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentAttributeSectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeSectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSectionStddevFields {
   __typename?: 'payment_attribute_section_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionStddevPopFields {
   __typename?: 'payment_attribute_section_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionStddevSampFields {
   __typename?: 'payment_attribute_section_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionSumFields {
   __typename?: 'payment_attribute_section_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeSectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentAttributeSectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeSectionVarPopFields {
   __typename?: 'payment_attribute_section_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionVarSampFields {
   __typename?: 'payment_attribute_section_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSectionVarianceFields {
   __typename?: 'payment_attribute_section_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsection {
   __typename?: 'payment_attribute_subsection',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  keys: Array<PaymentAttributeKey>,
  keys_aggregate: PaymentAttributeKeyAggregate,
  name: Scalars['String'],
  order: Scalars['Int'],
  section: PaymentAttributeSection,
  section_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentAttributeSubsectionKeysArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface PaymentAttributeSubsectionKeysAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}

export interface PaymentAttributeSubsectionAggregate {
   __typename?: 'payment_attribute_subsection_aggregate',
  aggregate?: Maybe<PaymentAttributeSubsectionAggregateFields>,
  nodes: Array<PaymentAttributeSubsection>,
}

export interface PaymentAttributeSubsectionAggregateFields {
   __typename?: 'payment_attribute_subsection_aggregate_fields',
  avg?: Maybe<PaymentAttributeSubsectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentAttributeSubsectionMaxFields>,
  min?: Maybe<PaymentAttributeSubsectionMinFields>,
  stddev?: Maybe<PaymentAttributeSubsectionStddevFields>,
  stddev_pop?: Maybe<PaymentAttributeSubsectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentAttributeSubsectionStddevSampFields>,
  sum?: Maybe<PaymentAttributeSubsectionSumFields>,
  var_pop?: Maybe<PaymentAttributeSubsectionVarPopFields>,
  var_samp?: Maybe<PaymentAttributeSubsectionVarSampFields>,
  variance?: Maybe<PaymentAttributeSubsectionVarianceFields>,
}


export interface PaymentAttributeSubsectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAttributeSubsectionAggregateOrderBy {
  avg?: Maybe<PaymentAttributeSubsectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentAttributeSubsectionMaxOrderBy>,
  min?: Maybe<PaymentAttributeSubsectionMinOrderBy>,
  stddev?: Maybe<PaymentAttributeSubsectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentAttributeSubsectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentAttributeSubsectionStddevSampOrderBy>,
  sum?: Maybe<PaymentAttributeSubsectionSumOrderBy>,
  var_pop?: Maybe<PaymentAttributeSubsectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentAttributeSubsectionVarSampOrderBy>,
  variance?: Maybe<PaymentAttributeSubsectionVarianceOrderBy>,
}

export interface PaymentAttributeSubsectionArrRelInsertInput {
  data: Array<PaymentAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentAttributeSubsectionOnConflict>,
}

export interface PaymentAttributeSubsectionAvgFields {
   __typename?: 'payment_attribute_subsection_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentAttributeSubsectionBoolExp>>>,
  _not?: Maybe<PaymentAttributeSubsectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentAttributeSubsectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  keys?: Maybe<PaymentAttributeKeyBoolExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  section?: Maybe<PaymentAttributeSectionBoolExp>,
  section_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentAttributeSubsectionConstraint {
  PaymentAttributeSubsectionNameSectionIdKey = 'payment_attribute_subsection_name_section_id_key',
  PaymentAttributeSubsectionOrderSectionIdKey = 'payment_attribute_subsection_order_section_id_key',
  PaymentAttributeSubsectionPkey = 'payment_attribute_subsection_pkey'
}

export interface PaymentAttributeSubsectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeSubsectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  keys?: Maybe<PaymentAttributeKeyArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section?: Maybe<PaymentAttributeSectionObjRelInsertInput>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSubsectionMaxFields {
   __typename?: 'payment_attribute_subsection_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSubsectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionMinFields {
   __typename?: 'payment_attribute_subsection_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSubsectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionMutationResponse {
   __typename?: 'payment_attribute_subsection_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentAttributeSubsection>,
}

export interface PaymentAttributeSubsectionObjRelInsertInput {
  data: PaymentAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentAttributeSubsectionOnConflict>,
}

export interface PaymentAttributeSubsectionOnConflict {
  constraint: PaymentAttributeSubsectionConstraint,
  update_columns: Array<PaymentAttributeSubsectionUpdateColumn>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>,
}

export interface PaymentAttributeSubsectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  keys_aggregate?: Maybe<PaymentAttributeKeyAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section?: Maybe<PaymentAttributeSectionOrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentAttributeSubsectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeSubsectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentAttributeSubsectionStddevFields {
   __typename?: 'payment_attribute_subsection_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionStddevPopFields {
   __typename?: 'payment_attribute_subsection_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionStddevSampFields {
   __typename?: 'payment_attribute_subsection_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionSumFields {
   __typename?: 'payment_attribute_subsection_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentAttributeSubsectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentAttributeSubsectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentAttributeSubsectionVarPopFields {
   __typename?: 'payment_attribute_subsection_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionVarSampFields {
   __typename?: 'payment_attribute_subsection_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeSubsectionVarianceFields {
   __typename?: 'payment_attribute_subsection_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentAttributeSubsectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentAttributeValue {
   __typename?: 'payment_attribute_value',
  id: Scalars['String'],
  key_id: Scalars['String'],
  payment: Payment,
  payment_attribute_key: PaymentAttributeKey,
  payment_id: Scalars['String'],
  value: Scalars['String'],
}

export interface PaymentAttributeValueAggregate {
   __typename?: 'payment_attribute_value_aggregate',
  aggregate?: Maybe<PaymentAttributeValueAggregateFields>,
  nodes: Array<PaymentAttributeValue>,
}

export interface PaymentAttributeValueAggregateFields {
   __typename?: 'payment_attribute_value_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentAttributeValueMaxFields>,
  min?: Maybe<PaymentAttributeValueMinFields>,
}


export interface PaymentAttributeValueAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentAttributeValueAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentAttributeValueMaxOrderBy>,
  min?: Maybe<PaymentAttributeValueMinOrderBy>,
}

export interface PaymentAttributeValueArrRelInsertInput {
  data: Array<PaymentAttributeValueInsertInput>,
  on_conflict?: Maybe<PaymentAttributeValueOnConflict>,
}

export interface PaymentAttributeValueBoolExp {
  _and?: Maybe<Array<Maybe<PaymentAttributeValueBoolExp>>>,
  _not?: Maybe<PaymentAttributeValueBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentAttributeValueBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  key_id?: Maybe<StringComparisonExp>,
  payment?: Maybe<PaymentBoolExp>,
  payment_attribute_key?: Maybe<PaymentAttributeKeyBoolExp>,
  payment_id?: Maybe<StringComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum PaymentAttributeValueConstraint {
  PaymentAttributeValuePkey = 'payment_attribute_value_pkey'
}

export interface PaymentAttributeValueInsertInput {
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  payment?: Maybe<PaymentObjRelInsertInput>,
  payment_attribute_key?: Maybe<PaymentAttributeKeyObjRelInsertInput>,
  payment_id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentAttributeValueMaxFields {
   __typename?: 'payment_attribute_value_max_fields',
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentAttributeValueMaxOrderBy {
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  payment_id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentAttributeValueMinFields {
   __typename?: 'payment_attribute_value_min_fields',
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentAttributeValueMinOrderBy {
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  payment_id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentAttributeValueMutationResponse {
   __typename?: 'payment_attribute_value_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentAttributeValue>,
}

export interface PaymentAttributeValueObjRelInsertInput {
  data: PaymentAttributeValueInsertInput,
  on_conflict?: Maybe<PaymentAttributeValueOnConflict>,
}

export interface PaymentAttributeValueOnConflict {
  constraint: PaymentAttributeValueConstraint,
  update_columns: Array<PaymentAttributeValueUpdateColumn>,
  where?: Maybe<PaymentAttributeValueBoolExp>,
}

export interface PaymentAttributeValueOrderBy {
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  payment?: Maybe<PaymentOrderBy>,
  payment_attribute_key?: Maybe<PaymentAttributeKeyOrderBy>,
  payment_id?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentAttributeValuePkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentAttributeValueSelectColumn {
  Id = 'id',
  KeyId = 'key_id',
  PaymentId = 'payment_id',
  Value = 'value'
}

export interface PaymentAttributeValueSetInput {
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
}

export enum PaymentAttributeValueUpdateColumn {
  Id = 'id',
  KeyId = 'key_id',
  PaymentId = 'payment_id',
  Value = 'value'
}

export interface PaymentAvgFields {
   __typename?: 'payment_avg_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentAvgOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentBoolExp {
  _and?: Maybe<Array<Maybe<PaymentBoolExp>>>,
  _not?: Maybe<PaymentBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentBoolExp>>>,
  amount?: Maybe<NumericComparisonExp>,
  attributes?: Maybe<PaymentAttributeValueBoolExp>,
  bank_account?: Maybe<BankAccountBoolExp>,
  bank_account_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  method_type?: Maybe<PaymentMethodTypeBoolExp>,
  method_type_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  provider_transaction_id?: Maybe<StringComparisonExp>,
  reference_id?: Maybe<StringComparisonExp>,
  status?: Maybe<PaymentStatusBoolExp>,
  status_id?: Maybe<StringComparisonExp>,
  transaction?: Maybe<TransactionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentConstraint {
  PaymentPkey = 'payment_pkey'
}

export interface PaymentIncInput {
  amount?: Maybe<Scalars['numeric']>,
}

export interface PaymentInsertInput {
  amount?: Maybe<Scalars['numeric']>,
  attributes?: Maybe<PaymentAttributeValueArrRelInsertInput>,
  bank_account?: Maybe<BankAccountObjRelInsertInput>,
  bank_account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_type?: Maybe<PaymentMethodTypeObjRelInsertInput>,
  method_type_id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  provider_transaction_id?: Maybe<Scalars['String']>,
  reference_id?: Maybe<Scalars['String']>,
  status?: Maybe<PaymentStatusObjRelInsertInput>,
  status_id?: Maybe<Scalars['String']>,
  transaction?: Maybe<TransactionObjRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMaxFields {
   __typename?: 'payment_max_fields',
  amount?: Maybe<Scalars['numeric']>,
  bank_account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_transaction_id?: Maybe<Scalars['String']>,
  reference_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMaxOrderBy {
  amount?: Maybe<OrderBy>,
  bank_account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_transaction_id?: Maybe<OrderBy>,
  reference_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethod {
   __typename?: 'payment_method',
  agreements: Array<AgreementsToPaymentMethods>,
  agreements_aggregate: AgreementsToPaymentMethodsAggregate,
  attributes: Array<PaymentMethodAttribute>,
  attributes_aggregate: PaymentMethodAttributeAggregate,
  created_at: Scalars['timestamptz'],
  created_by?: Maybe<Stakeholder>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  logo: PaymentMethodLogo,
  logo_id: Scalars['String'],
  method_types: Array<PaymentMethodType>,
  method_types_aggregate: PaymentMethodTypeAggregate,
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  provider: PaymentProvider,
  provider_id: Scalars['String'],
  segment: Segment,
  segment_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodAgreementsArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface PaymentMethodAgreementsAggregateArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface PaymentMethodAttributesArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface PaymentMethodAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface PaymentMethodMethodTypesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface PaymentMethodMethodTypesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}

export interface PaymentMethodAggregate {
   __typename?: 'payment_method_aggregate',
  aggregate?: Maybe<PaymentMethodAggregateFields>,
  nodes: Array<PaymentMethod>,
}

export interface PaymentMethodAggregateFields {
   __typename?: 'payment_method_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodMaxFields>,
  min?: Maybe<PaymentMethodMinFields>,
}


export interface PaymentMethodAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodMaxOrderBy>,
  min?: Maybe<PaymentMethodMinOrderBy>,
}

export interface PaymentMethodArrRelInsertInput {
  data: Array<PaymentMethodInsertInput>,
  on_conflict?: Maybe<PaymentMethodOnConflict>,
}

export interface PaymentMethodAttribute {
   __typename?: 'payment_method_attribute',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  key: PaymentMethodAttributeKey,
  key_id: Scalars['String'],
  method: PaymentMethod,
  method_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  value: Scalars['String'],
}

export interface PaymentMethodAttributeAggregate {
   __typename?: 'payment_method_attribute_aggregate',
  aggregate?: Maybe<PaymentMethodAttributeAggregateFields>,
  nodes: Array<PaymentMethodAttribute>,
}

export interface PaymentMethodAttributeAggregateFields {
   __typename?: 'payment_method_attribute_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodAttributeMaxFields>,
  min?: Maybe<PaymentMethodAttributeMinFields>,
}


export interface PaymentMethodAttributeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodAttributeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodAttributeMaxOrderBy>,
  min?: Maybe<PaymentMethodAttributeMinOrderBy>,
}

export interface PaymentMethodAttributeArrRelInsertInput {
  data: Array<PaymentMethodAttributeInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeOnConflict>,
}

export interface PaymentMethodAttributeBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodAttributeBoolExp>>>,
  _not?: Maybe<PaymentMethodAttributeBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodAttributeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  key?: Maybe<PaymentMethodAttributeKeyBoolExp>,
  key_id?: Maybe<StringComparisonExp>,
  method?: Maybe<PaymentMethodBoolExp>,
  method_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum PaymentMethodAttributeConstraint {
  PaymentMethodAttributePkey = 'payment_method_attribute_pkey'
}

export interface PaymentMethodAttributeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key?: Maybe<PaymentMethodAttributeKeyObjRelInsertInput>,
  key_id?: Maybe<Scalars['String']>,
  method?: Maybe<PaymentMethodObjRelInsertInput>,
  method_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodAttributeKey {
   __typename?: 'payment_method_attribute_key',
  attribute_type: Scalars['String'],
  created_at: Scalars['timestamptz'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  metadata?: Maybe<AttributeMetadata>,
  metadata_id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  order: Scalars['Int'],
  placeholder?: Maybe<Scalars['String']>,
  provider: PaymentProvider,
  provider_id: Scalars['String'],
  provider_key?: Maybe<Scalars['String']>,
  subsection: PaymentMethodAttributeSubsection,
  subsection_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentMethodAttributeKeyAggregate {
   __typename?: 'payment_method_attribute_key_aggregate',
  aggregate?: Maybe<PaymentMethodAttributeKeyAggregateFields>,
  nodes: Array<PaymentMethodAttributeKey>,
}

export interface PaymentMethodAttributeKeyAggregateFields {
   __typename?: 'payment_method_attribute_key_aggregate_fields',
  avg?: Maybe<PaymentMethodAttributeKeyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodAttributeKeyMaxFields>,
  min?: Maybe<PaymentMethodAttributeKeyMinFields>,
  stddev?: Maybe<PaymentMethodAttributeKeyStddevFields>,
  stddev_pop?: Maybe<PaymentMethodAttributeKeyStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodAttributeKeyStddevSampFields>,
  sum?: Maybe<PaymentMethodAttributeKeySumFields>,
  var_pop?: Maybe<PaymentMethodAttributeKeyVarPopFields>,
  var_samp?: Maybe<PaymentMethodAttributeKeyVarSampFields>,
  variance?: Maybe<PaymentMethodAttributeKeyVarianceFields>,
}


export interface PaymentMethodAttributeKeyAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodAttributeKeyAggregateOrderBy {
  avg?: Maybe<PaymentMethodAttributeKeyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodAttributeKeyMaxOrderBy>,
  min?: Maybe<PaymentMethodAttributeKeyMinOrderBy>,
  stddev?: Maybe<PaymentMethodAttributeKeyStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodAttributeKeyStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodAttributeKeyStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodAttributeKeySumOrderBy>,
  var_pop?: Maybe<PaymentMethodAttributeKeyVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodAttributeKeyVarSampOrderBy>,
  variance?: Maybe<PaymentMethodAttributeKeyVarianceOrderBy>,
}

export interface PaymentMethodAttributeKeyArrRelInsertInput {
  data: Array<PaymentMethodAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeKeyOnConflict>,
}

export interface PaymentMethodAttributeKeyAvgFields {
   __typename?: 'payment_method_attribute_key_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodAttributeKeyBoolExp>>>,
  _not?: Maybe<PaymentMethodAttributeKeyBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodAttributeKeyBoolExp>>>,
  attribute_type?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  metadata?: Maybe<AttributeMetadataBoolExp>,
  metadata_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  placeholder?: Maybe<StringComparisonExp>,
  provider?: Maybe<PaymentProviderBoolExp>,
  provider_id?: Maybe<StringComparisonExp>,
  provider_key?: Maybe<StringComparisonExp>,
  subsection?: Maybe<PaymentMethodAttributeSubsectionBoolExp>,
  subsection_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodAttributeKeyConstraint {
  PaymentMethodAttributeKeyNameKey = 'payment_method_attribute_key_name_key',
  PaymentMethodAttributeKeyOrderSectionIdKey = 'payment_method_attribute_key_order_section_id_key',
  PaymentMethodAttributeKeyPkey = 'payment_method_attribute_key_pkey'
}

export interface PaymentMethodAttributeKeyIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeKeyInsertInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  metadata?: Maybe<AttributeMetadataObjRelInsertInput>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider?: Maybe<PaymentProviderObjRelInsertInput>,
  provider_id?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  subsection?: Maybe<PaymentMethodAttributeSubsectionObjRelInsertInput>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeKeyMaxFields {
   __typename?: 'payment_method_attribute_key_max_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeKeyMaxOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyMinFields {
   __typename?: 'payment_method_attribute_key_min_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeKeyMinOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyMutationResponse {
   __typename?: 'payment_method_attribute_key_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodAttributeKey>,
}

export interface PaymentMethodAttributeKeyObjRelInsertInput {
  data: PaymentMethodAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeKeyOnConflict>,
}

export interface PaymentMethodAttributeKeyOnConflict {
  constraint: PaymentMethodAttributeKeyConstraint,
  update_columns: Array<PaymentMethodAttributeKeyUpdateColumn>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>,
}

export interface PaymentMethodAttributeKeyOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  metadata?: Maybe<AttributeMetadataOrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider?: Maybe<PaymentProviderOrderBy>,
  provider_id?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  subsection?: Maybe<PaymentMethodAttributeSubsectionOrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodAttributeKeySelectColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  Placeholder = 'placeholder',
  ProviderId = 'provider_id',
  ProviderKey = 'provider_key',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeKeySetInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeKeyStddevFields {
   __typename?: 'payment_method_attribute_key_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyStddevPopFields {
   __typename?: 'payment_method_attribute_key_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyStddevSampFields {
   __typename?: 'payment_method_attribute_key_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeySumFields {
   __typename?: 'payment_method_attribute_key_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeKeySumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodAttributeKeyUpdateColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  Placeholder = 'placeholder',
  ProviderId = 'provider_id',
  ProviderKey = 'provider_key',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeKeyVarPopFields {
   __typename?: 'payment_method_attribute_key_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyVarSampFields {
   __typename?: 'payment_method_attribute_key_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeKeyVarianceFields {
   __typename?: 'payment_method_attribute_key_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeKeyVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeMaxFields {
   __typename?: 'payment_method_attribute_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodAttributeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeMinFields {
   __typename?: 'payment_method_attribute_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodAttributeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeMutationResponse {
   __typename?: 'payment_method_attribute_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodAttribute>,
}

export interface PaymentMethodAttributeObjRelInsertInput {
  data: PaymentMethodAttributeInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeOnConflict>,
}

export interface PaymentMethodAttributeOnConflict {
  constraint: PaymentMethodAttributeConstraint,
  update_columns: Array<PaymentMethodAttributeUpdateColumn>,
  where?: Maybe<PaymentMethodAttributeBoolExp>,
}

export interface PaymentMethodAttributeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key?: Maybe<PaymentMethodAttributeKeyOrderBy>,
  key_id?: Maybe<OrderBy>,
  method?: Maybe<PaymentMethodOrderBy>,
  method_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributePkColumnsInput {
  id: Scalars['String'],
}

export interface PaymentMethodAttributeSection {
   __typename?: 'payment_method_attribute_section',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  name: Scalars['String'],
  order: Scalars['Int'],
  provider: PaymentProvider,
  provider_id: Scalars['String'],
  subsections: Array<PaymentMethodAttributeSubsection>,
  subsections_aggregate: PaymentMethodAttributeSubsectionAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodAttributeSectionSubsectionsArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}


export interface PaymentMethodAttributeSectionSubsectionsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}

export interface PaymentMethodAttributeSectionAggregate {
   __typename?: 'payment_method_attribute_section_aggregate',
  aggregate?: Maybe<PaymentMethodAttributeSectionAggregateFields>,
  nodes: Array<PaymentMethodAttributeSection>,
}

export interface PaymentMethodAttributeSectionAggregateFields {
   __typename?: 'payment_method_attribute_section_aggregate_fields',
  avg?: Maybe<PaymentMethodAttributeSectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodAttributeSectionMaxFields>,
  min?: Maybe<PaymentMethodAttributeSectionMinFields>,
  stddev?: Maybe<PaymentMethodAttributeSectionStddevFields>,
  stddev_pop?: Maybe<PaymentMethodAttributeSectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodAttributeSectionStddevSampFields>,
  sum?: Maybe<PaymentMethodAttributeSectionSumFields>,
  var_pop?: Maybe<PaymentMethodAttributeSectionVarPopFields>,
  var_samp?: Maybe<PaymentMethodAttributeSectionVarSampFields>,
  variance?: Maybe<PaymentMethodAttributeSectionVarianceFields>,
}


export interface PaymentMethodAttributeSectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodAttributeSectionAggregateOrderBy {
  avg?: Maybe<PaymentMethodAttributeSectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodAttributeSectionMaxOrderBy>,
  min?: Maybe<PaymentMethodAttributeSectionMinOrderBy>,
  stddev?: Maybe<PaymentMethodAttributeSectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodAttributeSectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodAttributeSectionStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodAttributeSectionSumOrderBy>,
  var_pop?: Maybe<PaymentMethodAttributeSectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodAttributeSectionVarSampOrderBy>,
  variance?: Maybe<PaymentMethodAttributeSectionVarianceOrderBy>,
}

export interface PaymentMethodAttributeSectionArrRelInsertInput {
  data: Array<PaymentMethodAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeSectionOnConflict>,
}

export interface PaymentMethodAttributeSectionAvgFields {
   __typename?: 'payment_method_attribute_section_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodAttributeSectionBoolExp>>>,
  _not?: Maybe<PaymentMethodAttributeSectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodAttributeSectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  provider?: Maybe<PaymentProviderBoolExp>,
  provider_id?: Maybe<StringComparisonExp>,
  subsections?: Maybe<PaymentMethodAttributeSubsectionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodAttributeSectionConstraint {
  PaymentMethodAttributeSectionNameKey = 'payment_method_attribute_section_name_key',
  PaymentMethodAttributeSectionOrderProviderIdKey = 'payment_method_attribute_section_order_provider_id_key',
  PaymentMethodAttributeSectionPkey = 'payment_method_attribute_section_pkey'
}

export interface PaymentMethodAttributeSectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeSectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider?: Maybe<PaymentProviderObjRelInsertInput>,
  provider_id?: Maybe<Scalars['String']>,
  subsections?: Maybe<PaymentMethodAttributeSubsectionArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSectionMaxFields {
   __typename?: 'payment_method_attribute_section_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionMinFields {
   __typename?: 'payment_method_attribute_section_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionMutationResponse {
   __typename?: 'payment_method_attribute_section_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodAttributeSection>,
}

export interface PaymentMethodAttributeSectionObjRelInsertInput {
  data: PaymentMethodAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeSectionOnConflict>,
}

export interface PaymentMethodAttributeSectionOnConflict {
  constraint: PaymentMethodAttributeSectionConstraint,
  update_columns: Array<PaymentMethodAttributeSectionUpdateColumn>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>,
}

export interface PaymentMethodAttributeSectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider?: Maybe<PaymentProviderOrderBy>,
  provider_id?: Maybe<OrderBy>,
  subsections_aggregate?: Maybe<PaymentMethodAttributeSubsectionAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodAttributeSectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderId = 'provider_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeSectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSectionStddevFields {
   __typename?: 'payment_method_attribute_section_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionStddevPopFields {
   __typename?: 'payment_method_attribute_section_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionStddevSampFields {
   __typename?: 'payment_method_attribute_section_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionSumFields {
   __typename?: 'payment_method_attribute_section_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeSectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodAttributeSectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderId = 'provider_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeSectionVarPopFields {
   __typename?: 'payment_method_attribute_section_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionVarSampFields {
   __typename?: 'payment_method_attribute_section_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSectionVarianceFields {
   __typename?: 'payment_method_attribute_section_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodAttributeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  MethodId = 'method_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface PaymentMethodAttributeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodAttributeSubsection {
   __typename?: 'payment_method_attribute_subsection',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  keys: Array<PaymentMethodAttributeKey>,
  keys_aggregate: PaymentMethodAttributeKeyAggregate,
  name: Scalars['String'],
  order: Scalars['Int'],
  section: PaymentMethodAttributeSection,
  section_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodAttributeSubsectionKeysArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface PaymentMethodAttributeSubsectionKeysAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}

export interface PaymentMethodAttributeSubsectionAggregate {
   __typename?: 'payment_method_attribute_subsection_aggregate',
  aggregate?: Maybe<PaymentMethodAttributeSubsectionAggregateFields>,
  nodes: Array<PaymentMethodAttributeSubsection>,
}

export interface PaymentMethodAttributeSubsectionAggregateFields {
   __typename?: 'payment_method_attribute_subsection_aggregate_fields',
  avg?: Maybe<PaymentMethodAttributeSubsectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodAttributeSubsectionMaxFields>,
  min?: Maybe<PaymentMethodAttributeSubsectionMinFields>,
  stddev?: Maybe<PaymentMethodAttributeSubsectionStddevFields>,
  stddev_pop?: Maybe<PaymentMethodAttributeSubsectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodAttributeSubsectionStddevSampFields>,
  sum?: Maybe<PaymentMethodAttributeSubsectionSumFields>,
  var_pop?: Maybe<PaymentMethodAttributeSubsectionVarPopFields>,
  var_samp?: Maybe<PaymentMethodAttributeSubsectionVarSampFields>,
  variance?: Maybe<PaymentMethodAttributeSubsectionVarianceFields>,
}


export interface PaymentMethodAttributeSubsectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodAttributeSubsectionAggregateOrderBy {
  avg?: Maybe<PaymentMethodAttributeSubsectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodAttributeSubsectionMaxOrderBy>,
  min?: Maybe<PaymentMethodAttributeSubsectionMinOrderBy>,
  stddev?: Maybe<PaymentMethodAttributeSubsectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodAttributeSubsectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodAttributeSubsectionStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodAttributeSubsectionSumOrderBy>,
  var_pop?: Maybe<PaymentMethodAttributeSubsectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodAttributeSubsectionVarSampOrderBy>,
  variance?: Maybe<PaymentMethodAttributeSubsectionVarianceOrderBy>,
}

export interface PaymentMethodAttributeSubsectionArrRelInsertInput {
  data: Array<PaymentMethodAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodAttributeSubsectionOnConflict>,
}

export interface PaymentMethodAttributeSubsectionAvgFields {
   __typename?: 'payment_method_attribute_subsection_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodAttributeSubsectionBoolExp>>>,
  _not?: Maybe<PaymentMethodAttributeSubsectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodAttributeSubsectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  keys?: Maybe<PaymentMethodAttributeKeyBoolExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  section?: Maybe<PaymentMethodAttributeSectionBoolExp>,
  section_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodAttributeSubsectionConstraint {
  PaymentMethodAttributeSubsectionOrderProviderIdKey = 'payment_method_attribute_subsection_order_provider_id_key',
  PaymentMethodAttributeSubsectionPkey = 'payment_method_attribute_subsection_pkey'
}

export interface PaymentMethodAttributeSubsectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeSubsectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  keys?: Maybe<PaymentMethodAttributeKeyArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section?: Maybe<PaymentMethodAttributeSectionObjRelInsertInput>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSubsectionMaxFields {
   __typename?: 'payment_method_attribute_subsection_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSubsectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionMinFields {
   __typename?: 'payment_method_attribute_subsection_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSubsectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionMutationResponse {
   __typename?: 'payment_method_attribute_subsection_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodAttributeSubsection>,
}

export interface PaymentMethodAttributeSubsectionObjRelInsertInput {
  data: PaymentMethodAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentMethodAttributeSubsectionOnConflict>,
}

export interface PaymentMethodAttributeSubsectionOnConflict {
  constraint: PaymentMethodAttributeSubsectionConstraint,
  update_columns: Array<PaymentMethodAttributeSubsectionUpdateColumn>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>,
}

export interface PaymentMethodAttributeSubsectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  keys_aggregate?: Maybe<PaymentMethodAttributeKeyAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section?: Maybe<PaymentMethodAttributeSectionOrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodAttributeSubsectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeSubsectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodAttributeSubsectionStddevFields {
   __typename?: 'payment_method_attribute_subsection_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionStddevPopFields {
   __typename?: 'payment_method_attribute_subsection_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionStddevSampFields {
   __typename?: 'payment_method_attribute_subsection_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionSumFields {
   __typename?: 'payment_method_attribute_subsection_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodAttributeSubsectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodAttributeSubsectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodAttributeSubsectionVarPopFields {
   __typename?: 'payment_method_attribute_subsection_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionVarSampFields {
   __typename?: 'payment_method_attribute_subsection_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodAttributeSubsectionVarianceFields {
   __typename?: 'payment_method_attribute_subsection_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodAttributeSubsectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodAttributeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  MethodId = 'method_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface PaymentMethodBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodBoolExp>>>,
  _not?: Maybe<PaymentMethodBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodBoolExp>>>,
  agreements?: Maybe<AgreementsToPaymentMethodsBoolExp>,
  attributes?: Maybe<PaymentMethodAttributeBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  logo?: Maybe<PaymentMethodLogoBoolExp>,
  logo_id?: Maybe<StringComparisonExp>,
  method_types?: Maybe<PaymentMethodTypeBoolExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  provider?: Maybe<PaymentProviderBoolExp>,
  provider_id?: Maybe<StringComparisonExp>,
  segment?: Maybe<SegmentBoolExp>,
  segment_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodConstraint {
  PaymentMethodPkey = 'payment_method_pkey'
}

export interface PaymentMethodGridView {
   __typename?: 'payment_method_grid_view',
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  payment_method?: Maybe<PaymentMethod>,
  provider?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface PaymentMethodGridViewAggregate {
   __typename?: 'payment_method_grid_view_aggregate',
  aggregate?: Maybe<PaymentMethodGridViewAggregateFields>,
  nodes: Array<PaymentMethodGridView>,
}

export interface PaymentMethodGridViewAggregateFields {
   __typename?: 'payment_method_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodGridViewMaxFields>,
  min?: Maybe<PaymentMethodGridViewMinFields>,
}


export interface PaymentMethodGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodGridViewMaxOrderBy>,
  min?: Maybe<PaymentMethodGridViewMinOrderBy>,
}

export interface PaymentMethodGridViewBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodGridViewBoolExp>>>,
  _not?: Maybe<PaymentMethodGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodGridViewBoolExp>>>,
  created_by?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  payment_method?: Maybe<PaymentMethodBoolExp>,
  provider?: Maybe<StringComparisonExp>,
  segment?: Maybe<StringComparisonExp>,
}

export interface PaymentMethodGridViewMaxFields {
   __typename?: 'payment_method_grid_view_max_fields',
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface PaymentMethodGridViewMaxOrderBy {
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface PaymentMethodGridViewMinFields {
   __typename?: 'payment_method_grid_view_min_fields',
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
}

export interface PaymentMethodGridViewMinOrderBy {
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export interface PaymentMethodGridViewOrderBy {
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  payment_method?: Maybe<PaymentMethodOrderBy>,
  provider?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
}

export enum PaymentMethodGridViewSelectColumn {
  CreatedBy = 'created_by',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  Provider = 'provider',
  Segment = 'segment'
}

export interface PaymentMethodInsertInput {
  agreements?: Maybe<AgreementsToPaymentMethodsArrRelInsertInput>,
  attributes?: Maybe<PaymentMethodAttributeArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  logo?: Maybe<PaymentMethodLogoObjRelInsertInput>,
  logo_id?: Maybe<Scalars['String']>,
  method_types?: Maybe<PaymentMethodTypeArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  provider?: Maybe<PaymentProviderObjRelInsertInput>,
  provider_id?: Maybe<Scalars['String']>,
  segment?: Maybe<SegmentObjRelInsertInput>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodLogo {
   __typename?: 'payment_method_logo',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
  url: Scalars['String'],
}

export interface PaymentMethodLogoAggregate {
   __typename?: 'payment_method_logo_aggregate',
  aggregate?: Maybe<PaymentMethodLogoAggregateFields>,
  nodes: Array<PaymentMethodLogo>,
}

export interface PaymentMethodLogoAggregateFields {
   __typename?: 'payment_method_logo_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodLogoMaxFields>,
  min?: Maybe<PaymentMethodLogoMinFields>,
}


export interface PaymentMethodLogoAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodLogoAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodLogoMaxOrderBy>,
  min?: Maybe<PaymentMethodLogoMinOrderBy>,
}

export interface PaymentMethodLogoArrRelInsertInput {
  data: Array<PaymentMethodLogoInsertInput>,
  on_conflict?: Maybe<PaymentMethodLogoOnConflict>,
}

export interface PaymentMethodLogoBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodLogoBoolExp>>>,
  _not?: Maybe<PaymentMethodLogoBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodLogoBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  url?: Maybe<StringComparisonExp>,
}

export enum PaymentMethodLogoConstraint {
  PaymentMethodLogoPkey = 'payment_method_logo_pkey'
}

export interface PaymentMethodLogoInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PaymentMethodLogoMaxFields {
   __typename?: 'payment_method_logo_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PaymentMethodLogoMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PaymentMethodLogoMinFields {
   __typename?: 'payment_method_logo_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PaymentMethodLogoMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PaymentMethodLogoMutationResponse {
   __typename?: 'payment_method_logo_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodLogo>,
}

export interface PaymentMethodLogoObjRelInsertInput {
  data: PaymentMethodLogoInsertInput,
  on_conflict?: Maybe<PaymentMethodLogoOnConflict>,
}

export interface PaymentMethodLogoOnConflict {
  constraint: PaymentMethodLogoConstraint,
  update_columns: Array<PaymentMethodLogoUpdateColumn>,
  where?: Maybe<PaymentMethodLogoBoolExp>,
}

export interface PaymentMethodLogoOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PaymentMethodLogoPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodLogoSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface PaymentMethodLogoSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export enum PaymentMethodLogoUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface PaymentMethodMaxFields {
   __typename?: 'payment_method_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  logo_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  logo_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodMinFields {
   __typename?: 'payment_method_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  logo_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodMinOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  logo_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodMutationResponse {
   __typename?: 'payment_method_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethod>,
}

export interface PaymentMethodObjRelInsertInput {
  data: PaymentMethodInsertInput,
  on_conflict?: Maybe<PaymentMethodOnConflict>,
}

export interface PaymentMethodOnConflict {
  constraint: PaymentMethodConstraint,
  update_columns: Array<PaymentMethodUpdateColumn>,
  where?: Maybe<PaymentMethodBoolExp>,
}

export interface PaymentMethodOrderBy {
  agreements_aggregate?: Maybe<AgreementsToPaymentMethodsAggregateOrderBy>,
  attributes_aggregate?: Maybe<PaymentMethodAttributeAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  logo?: Maybe<PaymentMethodLogoOrderBy>,
  logo_id?: Maybe<OrderBy>,
  method_types_aggregate?: Maybe<PaymentMethodTypeAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider?: Maybe<PaymentProviderOrderBy>,
  provider_id?: Maybe<OrderBy>,
  segment?: Maybe<SegmentOrderBy>,
  segment_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodSelectColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  LogoId = 'logo_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  ProviderId = 'provider_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  logo_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodType {
   __typename?: 'payment_method_type',
  attributes: Array<PaymentMethodTypeAttribute>,
  attributes_aggregate: PaymentMethodTypeAttributeAggregate,
  callback_url?: Maybe<Scalars['String']>,
  can_only_withdrawal_net_deposits: Scalars['Boolean'],
  created_at: Scalars['timestamptz'],
  created_by?: Maybe<Stakeholder>,
  created_by_id?: Maybe<Scalars['String']>,
  currencies: Array<PaymentMethodTypeCurrency>,
  currencies_aggregate: PaymentMethodTypeCurrencyAggregate,
  deleted_by?: Maybe<Stakeholder>,
  deleted_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  method: PaymentMethod,
  method_id: Scalars['String'],
  name: Scalars['String'],
  notification_url?: Maybe<Scalars['String']>,
  organization: Organization,
  organization_id: Scalars['String'],
  provider_method_type: PaymentProviderMethodType,
  provider_method_type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodTypeAttributesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface PaymentMethodTypeAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface PaymentMethodTypeCurrenciesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}


export interface PaymentMethodTypeCurrenciesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}

export interface PaymentMethodTypeAggregate {
   __typename?: 'payment_method_type_aggregate',
  aggregate?: Maybe<PaymentMethodTypeAggregateFields>,
  nodes: Array<PaymentMethodType>,
}

export interface PaymentMethodTypeAggregateFields {
   __typename?: 'payment_method_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeMaxFields>,
  min?: Maybe<PaymentMethodTypeMinFields>,
}


export interface PaymentMethodTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeMinOrderBy>,
}

export interface PaymentMethodTypeArrRelInsertInput {
  data: Array<PaymentMethodTypeInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeOnConflict>,
}

export interface PaymentMethodTypeAttribute {
   __typename?: 'payment_method_type_attribute',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  key: PaymentMethodTypeAttributeKey,
  key_id: Scalars['String'],
  method_type: PaymentMethodType,
  method_type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  value: Scalars['String'],
}

export interface PaymentMethodTypeAttributeAggregate {
   __typename?: 'payment_method_type_attribute_aggregate',
  aggregate?: Maybe<PaymentMethodTypeAttributeAggregateFields>,
  nodes: Array<PaymentMethodTypeAttribute>,
}

export interface PaymentMethodTypeAttributeAggregateFields {
   __typename?: 'payment_method_type_attribute_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeAttributeMaxFields>,
  min?: Maybe<PaymentMethodTypeAttributeMinFields>,
}


export interface PaymentMethodTypeAttributeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeAttributeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeAttributeMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeAttributeMinOrderBy>,
}

export interface PaymentMethodTypeAttributeArrRelInsertInput {
  data: Array<PaymentMethodTypeAttributeInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeOnConflict>,
}

export interface PaymentMethodTypeAttributeBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeAttributeBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeAttributeBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeAttributeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  key?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>,
  key_id?: Maybe<StringComparisonExp>,
  method_type?: Maybe<PaymentMethodTypeBoolExp>,
  method_type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum PaymentMethodTypeAttributeConstraint {
  PaymentMethodTypeAttributePkey = 'payment_method_type_attribute_pkey'
}

export interface PaymentMethodTypeAttributeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key?: Maybe<PaymentMethodTypeAttributeKeyObjRelInsertInput>,
  key_id?: Maybe<Scalars['String']>,
  method_type?: Maybe<PaymentMethodTypeObjRelInsertInput>,
  method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodTypeAttributeKey {
   __typename?: 'payment_method_type_attribute_key',
  attribute_type: Scalars['String'],
  created_at: Scalars['timestamptz'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  metadata?: Maybe<AttributeMetadata>,
  metadata_id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  order: Scalars['Int'],
  placeholder?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type: PaymentProviderMethodType,
  provider_method_type_id: Scalars['String'],
  subsection: PaymentMethodTypeAttributeSubsection,
  subsection_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentMethodTypeAttributeKeyAggregate {
   __typename?: 'payment_method_type_attribute_key_aggregate',
  aggregate?: Maybe<PaymentMethodTypeAttributeKeyAggregateFields>,
  nodes: Array<PaymentMethodTypeAttributeKey>,
}

export interface PaymentMethodTypeAttributeKeyAggregateFields {
   __typename?: 'payment_method_type_attribute_key_aggregate_fields',
  avg?: Maybe<PaymentMethodTypeAttributeKeyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeAttributeKeyMaxFields>,
  min?: Maybe<PaymentMethodTypeAttributeKeyMinFields>,
  stddev?: Maybe<PaymentMethodTypeAttributeKeyStddevFields>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeKeyStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeKeyStddevSampFields>,
  sum?: Maybe<PaymentMethodTypeAttributeKeySumFields>,
  var_pop?: Maybe<PaymentMethodTypeAttributeKeyVarPopFields>,
  var_samp?: Maybe<PaymentMethodTypeAttributeKeyVarSampFields>,
  variance?: Maybe<PaymentMethodTypeAttributeKeyVarianceFields>,
}


export interface PaymentMethodTypeAttributeKeyAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeAttributeKeyAggregateOrderBy {
  avg?: Maybe<PaymentMethodTypeAttributeKeyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeAttributeKeyMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeAttributeKeyMinOrderBy>,
  stddev?: Maybe<PaymentMethodTypeAttributeKeyStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeKeyStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeKeyStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodTypeAttributeKeySumOrderBy>,
  var_pop?: Maybe<PaymentMethodTypeAttributeKeyVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodTypeAttributeKeyVarSampOrderBy>,
  variance?: Maybe<PaymentMethodTypeAttributeKeyVarianceOrderBy>,
}

export interface PaymentMethodTypeAttributeKeyArrRelInsertInput {
  data: Array<PaymentMethodTypeAttributeKeyInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeKeyOnConflict>,
}

export interface PaymentMethodTypeAttributeKeyAvgFields {
   __typename?: 'payment_method_type_attribute_key_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeAttributeKeyBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeAttributeKeyBoolExp>>>,
  attribute_type?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  metadata?: Maybe<AttributeMetadataBoolExp>,
  metadata_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  placeholder?: Maybe<StringComparisonExp>,
  provider_key?: Maybe<StringComparisonExp>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeBoolExp>,
  provider_method_type_id?: Maybe<StringComparisonExp>,
  subsection?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>,
  subsection_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodTypeAttributeKeyConstraint {
  PaymentMethodTypeAttributeKeyOrderSubsectionIdKey = 'payment_method_type_attribute_key_order_subsection_id_key',
  PaymentMethodTypeAttributeKeyPkey = 'payment_method_type_attribute_key_pkey'
}

export interface PaymentMethodTypeAttributeKeyIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeKeyInsertInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  metadata?: Maybe<AttributeMetadataObjRelInsertInput>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeObjRelInsertInput>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection?: Maybe<PaymentMethodTypeAttributeSubsectionObjRelInsertInput>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeKeyMaxFields {
   __typename?: 'payment_method_type_attribute_key_max_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeKeyMaxOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyMinFields {
   __typename?: 'payment_method_type_attribute_key_min_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeKeyMinOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyMutationResponse {
   __typename?: 'payment_method_type_attribute_key_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodTypeAttributeKey>,
}

export interface PaymentMethodTypeAttributeKeyObjRelInsertInput {
  data: PaymentMethodTypeAttributeKeyInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeKeyOnConflict>,
}

export interface PaymentMethodTypeAttributeKeyOnConflict {
  constraint: PaymentMethodTypeAttributeKeyConstraint,
  update_columns: Array<PaymentMethodTypeAttributeKeyUpdateColumn>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>,
}

export interface PaymentMethodTypeAttributeKeyOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  metadata?: Maybe<AttributeMetadataOrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  placeholder?: Maybe<OrderBy>,
  provider_key?: Maybe<OrderBy>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeOrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsection?: Maybe<PaymentMethodTypeAttributeSubsectionOrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodTypeAttributeKeySelectColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  Placeholder = 'placeholder',
  ProviderKey = 'provider_key',
  ProviderMethodTypeId = 'provider_method_type_id',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeKeySetInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  placeholder?: Maybe<Scalars['String']>,
  provider_key?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeKeyStddevFields {
   __typename?: 'payment_method_type_attribute_key_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyStddevPopFields {
   __typename?: 'payment_method_type_attribute_key_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyStddevSampFields {
   __typename?: 'payment_method_type_attribute_key_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeySumFields {
   __typename?: 'payment_method_type_attribute_key_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeKeySumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeAttributeKeyUpdateColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsRequired = 'is_required',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  Placeholder = 'placeholder',
  ProviderKey = 'provider_key',
  ProviderMethodTypeId = 'provider_method_type_id',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeKeyVarPopFields {
   __typename?: 'payment_method_type_attribute_key_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyVarSampFields {
   __typename?: 'payment_method_type_attribute_key_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeKeyVarianceFields {
   __typename?: 'payment_method_type_attribute_key_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeKeyVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeMaxFields {
   __typename?: 'payment_method_type_attribute_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodTypeAttributeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeMinFields {
   __typename?: 'payment_method_type_attribute_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodTypeAttributeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeMutationResponse {
   __typename?: 'payment_method_type_attribute_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodTypeAttribute>,
}

export interface PaymentMethodTypeAttributeObjRelInsertInput {
  data: PaymentMethodTypeAttributeInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeOnConflict>,
}

export interface PaymentMethodTypeAttributeOnConflict {
  constraint: PaymentMethodTypeAttributeConstraint,
  update_columns: Array<PaymentMethodTypeAttributeUpdateColumn>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>,
}

export interface PaymentMethodTypeAttributeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key?: Maybe<PaymentMethodTypeAttributeKeyOrderBy>,
  key_id?: Maybe<OrderBy>,
  method_type?: Maybe<PaymentMethodTypeOrderBy>,
  method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributePkColumnsInput {
  id: Scalars['String'],
}

export interface PaymentMethodTypeAttributeSection {
   __typename?: 'payment_method_type_attribute_section',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  name: Scalars['String'],
  order: Scalars['Int'],
  provider_method_type_id: Scalars['String'],
  subsections: Array<PaymentMethodTypeAttributeSubsection>,
  subsections_aggregate: PaymentMethodTypeAttributeSubsectionAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodTypeAttributeSectionSubsectionsArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}


export interface PaymentMethodTypeAttributeSectionSubsectionsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}

export interface PaymentMethodTypeAttributeSectionAggregate {
   __typename?: 'payment_method_type_attribute_section_aggregate',
  aggregate?: Maybe<PaymentMethodTypeAttributeSectionAggregateFields>,
  nodes: Array<PaymentMethodTypeAttributeSection>,
}

export interface PaymentMethodTypeAttributeSectionAggregateFields {
   __typename?: 'payment_method_type_attribute_section_aggregate_fields',
  avg?: Maybe<PaymentMethodTypeAttributeSectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeAttributeSectionMaxFields>,
  min?: Maybe<PaymentMethodTypeAttributeSectionMinFields>,
  stddev?: Maybe<PaymentMethodTypeAttributeSectionStddevFields>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeSectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeSectionStddevSampFields>,
  sum?: Maybe<PaymentMethodTypeAttributeSectionSumFields>,
  var_pop?: Maybe<PaymentMethodTypeAttributeSectionVarPopFields>,
  var_samp?: Maybe<PaymentMethodTypeAttributeSectionVarSampFields>,
  variance?: Maybe<PaymentMethodTypeAttributeSectionVarianceFields>,
}


export interface PaymentMethodTypeAttributeSectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeAttributeSectionAggregateOrderBy {
  avg?: Maybe<PaymentMethodTypeAttributeSectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeAttributeSectionMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeAttributeSectionMinOrderBy>,
  stddev?: Maybe<PaymentMethodTypeAttributeSectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeSectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeSectionStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodTypeAttributeSectionSumOrderBy>,
  var_pop?: Maybe<PaymentMethodTypeAttributeSectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodTypeAttributeSectionVarSampOrderBy>,
  variance?: Maybe<PaymentMethodTypeAttributeSectionVarianceOrderBy>,
}

export interface PaymentMethodTypeAttributeSectionArrRelInsertInput {
  data: Array<PaymentMethodTypeAttributeSectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSectionOnConflict>,
}

export interface PaymentMethodTypeAttributeSectionAvgFields {
   __typename?: 'payment_method_type_attribute_section_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeAttributeSectionBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeAttributeSectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  provider_method_type_id?: Maybe<StringComparisonExp>,
  subsections?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodTypeAttributeSectionConstraint {
  PaymentMethodTypeAttributeSectionNameProviderMethodType = 'payment_method_type_attribute_section_name_provider_method_type',
  PaymentMethodTypeAttributeSectionOrderMethodTypeIdKey = 'payment_method_type_attribute_section_order_method_type_id_key',
  PaymentMethodTypeAttributeSectionPkey = 'payment_method_type_attribute_section_pkey'
}

export interface PaymentMethodTypeAttributeSectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeSectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  subsections?: Maybe<PaymentMethodTypeAttributeSubsectionArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSectionMaxFields {
   __typename?: 'payment_method_type_attribute_section_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionMinFields {
   __typename?: 'payment_method_type_attribute_section_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionMutationResponse {
   __typename?: 'payment_method_type_attribute_section_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodTypeAttributeSection>,
}

export interface PaymentMethodTypeAttributeSectionObjRelInsertInput {
  data: PaymentMethodTypeAttributeSectionInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSectionOnConflict>,
}

export interface PaymentMethodTypeAttributeSectionOnConflict {
  constraint: PaymentMethodTypeAttributeSectionConstraint,
  update_columns: Array<PaymentMethodTypeAttributeSectionUpdateColumn>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>,
}

export interface PaymentMethodTypeAttributeSectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  subsections_aggregate?: Maybe<PaymentMethodTypeAttributeSubsectionAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodTypeAttributeSectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeSectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSectionStddevFields {
   __typename?: 'payment_method_type_attribute_section_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionStddevPopFields {
   __typename?: 'payment_method_type_attribute_section_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionStddevSampFields {
   __typename?: 'payment_method_type_attribute_section_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionSumFields {
   __typename?: 'payment_method_type_attribute_section_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeSectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeAttributeSectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeSectionVarPopFields {
   __typename?: 'payment_method_type_attribute_section_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionVarSampFields {
   __typename?: 'payment_method_type_attribute_section_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSectionVarianceFields {
   __typename?: 'payment_method_type_attribute_section_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeAttributeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  MethodTypeId = 'method_type_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface PaymentMethodTypeAttributeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface PaymentMethodTypeAttributeSubsection {
   __typename?: 'payment_method_type_attribute_subsection',
  classes: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_required: Scalars['Boolean'],
  keys: Array<PaymentMethodTypeAttributeKey>,
  keys_aggregate: PaymentMethodTypeAttributeKeyAggregate,
  name: Scalars['String'],
  order: Scalars['Int'],
  section: PaymentMethodTypeAttributeSection,
  section_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface PaymentMethodTypeAttributeSubsectionKeysArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface PaymentMethodTypeAttributeSubsectionKeysAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}

export interface PaymentMethodTypeAttributeSubsectionAggregate {
   __typename?: 'payment_method_type_attribute_subsection_aggregate',
  aggregate?: Maybe<PaymentMethodTypeAttributeSubsectionAggregateFields>,
  nodes: Array<PaymentMethodTypeAttributeSubsection>,
}

export interface PaymentMethodTypeAttributeSubsectionAggregateFields {
   __typename?: 'payment_method_type_attribute_subsection_aggregate_fields',
  avg?: Maybe<PaymentMethodTypeAttributeSubsectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeAttributeSubsectionMaxFields>,
  min?: Maybe<PaymentMethodTypeAttributeSubsectionMinFields>,
  stddev?: Maybe<PaymentMethodTypeAttributeSubsectionStddevFields>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeSubsectionStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeSubsectionStddevSampFields>,
  sum?: Maybe<PaymentMethodTypeAttributeSubsectionSumFields>,
  var_pop?: Maybe<PaymentMethodTypeAttributeSubsectionVarPopFields>,
  var_samp?: Maybe<PaymentMethodTypeAttributeSubsectionVarSampFields>,
  variance?: Maybe<PaymentMethodTypeAttributeSubsectionVarianceFields>,
}


export interface PaymentMethodTypeAttributeSubsectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeAttributeSubsectionAggregateOrderBy {
  avg?: Maybe<PaymentMethodTypeAttributeSubsectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeAttributeSubsectionMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeAttributeSubsectionMinOrderBy>,
  stddev?: Maybe<PaymentMethodTypeAttributeSubsectionStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodTypeAttributeSubsectionStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodTypeAttributeSubsectionStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodTypeAttributeSubsectionSumOrderBy>,
  var_pop?: Maybe<PaymentMethodTypeAttributeSubsectionVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodTypeAttributeSubsectionVarSampOrderBy>,
  variance?: Maybe<PaymentMethodTypeAttributeSubsectionVarianceOrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionArrRelInsertInput {
  data: Array<PaymentMethodTypeAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSubsectionOnConflict>,
}

export interface PaymentMethodTypeAttributeSubsectionAvgFields {
   __typename?: 'payment_method_type_attribute_subsection_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>>>,
  classes?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  keys?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  section?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>,
  section_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodTypeAttributeSubsectionConstraint {
  PaymentMethodTypeAttributeSubsectionNameSectionIdKey = 'payment_method_type_attribute_subsection_name_section_id_key',
  PaymentMethodTypeAttributeSubsectionOrderSectionIdKey = 'payment_method_type_attribute_subsection_order_section_id_key',
  PaymentMethodTypeAttributeSubsectionPkey = 'payment_method_type_attribute_subsection_pkey'
}

export interface PaymentMethodTypeAttributeSubsectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeSubsectionInsertInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  keys?: Maybe<PaymentMethodTypeAttributeKeyArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section?: Maybe<PaymentMethodTypeAttributeSectionObjRelInsertInput>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSubsectionMaxFields {
   __typename?: 'payment_method_type_attribute_subsection_max_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSubsectionMaxOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionMinFields {
   __typename?: 'payment_method_type_attribute_subsection_min_fields',
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSubsectionMinOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionMutationResponse {
   __typename?: 'payment_method_type_attribute_subsection_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodTypeAttributeSubsection>,
}

export interface PaymentMethodTypeAttributeSubsectionObjRelInsertInput {
  data: PaymentMethodTypeAttributeSubsectionInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeAttributeSubsectionOnConflict>,
}

export interface PaymentMethodTypeAttributeSubsectionOnConflict {
  constraint: PaymentMethodTypeAttributeSubsectionConstraint,
  update_columns: Array<PaymentMethodTypeAttributeSubsectionUpdateColumn>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>,
}

export interface PaymentMethodTypeAttributeSubsectionOrderBy {
  classes?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  keys_aggregate?: Maybe<PaymentMethodTypeAttributeKeyAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section?: Maybe<PaymentMethodTypeAttributeSectionOrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodTypeAttributeSubsectionSelectColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeSubsectionSetInput {
  classes?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_required?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevFields {
   __typename?: 'payment_method_type_attribute_subsection_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevPopFields {
   __typename?: 'payment_method_type_attribute_subsection_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevSampFields {
   __typename?: 'payment_method_type_attribute_subsection_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionSumFields {
   __typename?: 'payment_method_type_attribute_subsection_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface PaymentMethodTypeAttributeSubsectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeAttributeSubsectionUpdateColumn {
  Classes = 'classes',
  CreatedAt = 'created_at',
  Id = 'id',
  IsRequired = 'is_required',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeAttributeSubsectionVarPopFields {
   __typename?: 'payment_method_type_attribute_subsection_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionVarSampFields {
   __typename?: 'payment_method_type_attribute_subsection_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeAttributeSubsectionVarianceFields {
   __typename?: 'payment_method_type_attribute_subsection_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeAttributeSubsectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeAttributeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  MethodTypeId = 'method_type_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface PaymentMethodTypeBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeBoolExp>>>,
  attributes?: Maybe<PaymentMethodTypeAttributeBoolExp>,
  callback_url?: Maybe<StringComparisonExp>,
  can_only_withdrawal_net_deposits?: Maybe<BooleanComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  currencies?: Maybe<PaymentMethodTypeCurrencyBoolExp>,
  deleted_by?: Maybe<StakeholderBoolExp>,
  deleted_by_id?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  method?: Maybe<PaymentMethodBoolExp>,
  method_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  notification_url?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeBoolExp>,
  provider_method_type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodTypeConstraint {
  PaymentMethodTypePkey = 'payment_method_type_pkey'
}

export interface PaymentMethodTypeCurrency {
   __typename?: 'payment_method_type_currency',
  created_at: Scalars['timestamptz'],
  currency: Currency,
  currency_id: Scalars['String'],
  id: Scalars['String'],
  internal_fee: Scalars['numeric'],
  internal_fee_percent: Scalars['numeric'],
  is_approval_required: Scalars['Boolean'],
  maximum_amount: Scalars['numeric'],
  merchant_fee: Scalars['numeric'],
  merchant_fee_percent: Scalars['numeric'],
  method_type: PaymentMethodType,
  method_type_id: Scalars['String'],
  minimum_amount: Scalars['numeric'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentMethodTypeCurrencyAggregate {
   __typename?: 'payment_method_type_currency_aggregate',
  aggregate?: Maybe<PaymentMethodTypeCurrencyAggregateFields>,
  nodes: Array<PaymentMethodTypeCurrency>,
}

export interface PaymentMethodTypeCurrencyAggregateFields {
   __typename?: 'payment_method_type_currency_aggregate_fields',
  avg?: Maybe<PaymentMethodTypeCurrencyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentMethodTypeCurrencyMaxFields>,
  min?: Maybe<PaymentMethodTypeCurrencyMinFields>,
  stddev?: Maybe<PaymentMethodTypeCurrencyStddevFields>,
  stddev_pop?: Maybe<PaymentMethodTypeCurrencyStddevPopFields>,
  stddev_samp?: Maybe<PaymentMethodTypeCurrencyStddevSampFields>,
  sum?: Maybe<PaymentMethodTypeCurrencySumFields>,
  var_pop?: Maybe<PaymentMethodTypeCurrencyVarPopFields>,
  var_samp?: Maybe<PaymentMethodTypeCurrencyVarSampFields>,
  variance?: Maybe<PaymentMethodTypeCurrencyVarianceFields>,
}


export interface PaymentMethodTypeCurrencyAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentMethodTypeCurrencyAggregateOrderBy {
  avg?: Maybe<PaymentMethodTypeCurrencyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentMethodTypeCurrencyMaxOrderBy>,
  min?: Maybe<PaymentMethodTypeCurrencyMinOrderBy>,
  stddev?: Maybe<PaymentMethodTypeCurrencyStddevOrderBy>,
  stddev_pop?: Maybe<PaymentMethodTypeCurrencyStddevPopOrderBy>,
  stddev_samp?: Maybe<PaymentMethodTypeCurrencyStddevSampOrderBy>,
  sum?: Maybe<PaymentMethodTypeCurrencySumOrderBy>,
  var_pop?: Maybe<PaymentMethodTypeCurrencyVarPopOrderBy>,
  var_samp?: Maybe<PaymentMethodTypeCurrencyVarSampOrderBy>,
  variance?: Maybe<PaymentMethodTypeCurrencyVarianceOrderBy>,
}

export interface PaymentMethodTypeCurrencyArrRelInsertInput {
  data: Array<PaymentMethodTypeCurrencyInsertInput>,
  on_conflict?: Maybe<PaymentMethodTypeCurrencyOnConflict>,
}

export interface PaymentMethodTypeCurrencyAvgFields {
   __typename?: 'payment_method_type_currency_avg_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyAvgOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyBoolExp {
  _and?: Maybe<Array<Maybe<PaymentMethodTypeCurrencyBoolExp>>>,
  _not?: Maybe<PaymentMethodTypeCurrencyBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentMethodTypeCurrencyBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  currency?: Maybe<CurrencyBoolExp>,
  currency_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  internal_fee?: Maybe<NumericComparisonExp>,
  internal_fee_percent?: Maybe<NumericComparisonExp>,
  is_approval_required?: Maybe<BooleanComparisonExp>,
  maximum_amount?: Maybe<NumericComparisonExp>,
  merchant_fee?: Maybe<NumericComparisonExp>,
  merchant_fee_percent?: Maybe<NumericComparisonExp>,
  method_type?: Maybe<PaymentMethodTypeBoolExp>,
  method_type_id?: Maybe<StringComparisonExp>,
  minimum_amount?: Maybe<NumericComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentMethodTypeCurrencyConstraint {
  PaymentMethodTypeCurrencyPkey = 'payment_method_type_currency_pkey'
}

export interface PaymentMethodTypeCurrencyIncInput {
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
}

export interface PaymentMethodTypeCurrencyInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  currency?: Maybe<CurrencyObjRelInsertInput>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  is_approval_required?: Maybe<Scalars['Boolean']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  method_type?: Maybe<PaymentMethodTypeObjRelInsertInput>,
  method_type_id?: Maybe<Scalars['String']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeCurrencyMaxFields {
   __typename?: 'payment_method_type_currency_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  method_type_id?: Maybe<Scalars['String']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeCurrencyMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyMinFields {
   __typename?: 'payment_method_type_currency_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  method_type_id?: Maybe<Scalars['String']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeCurrencyMinOrderBy {
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyMutationResponse {
   __typename?: 'payment_method_type_currency_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodTypeCurrency>,
}

export interface PaymentMethodTypeCurrencyObjRelInsertInput {
  data: PaymentMethodTypeCurrencyInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeCurrencyOnConflict>,
}

export interface PaymentMethodTypeCurrencyOnConflict {
  constraint: PaymentMethodTypeCurrencyConstraint,
  update_columns: Array<PaymentMethodTypeCurrencyUpdateColumn>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>,
}

export interface PaymentMethodTypeCurrencyOrderBy {
  created_at?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  is_approval_required?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  method_type?: Maybe<PaymentMethodTypeOrderBy>,
  method_type_id?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodTypeCurrencySelectColumn {
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Id = 'id',
  InternalFee = 'internal_fee',
  InternalFeePercent = 'internal_fee_percent',
  IsApprovalRequired = 'is_approval_required',
  MaximumAmount = 'maximum_amount',
  MerchantFee = 'merchant_fee',
  MerchantFeePercent = 'merchant_fee_percent',
  MethodTypeId = 'method_type_id',
  MinimumAmount = 'minimum_amount',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeCurrencySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  is_approval_required?: Maybe<Scalars['Boolean']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  method_type_id?: Maybe<Scalars['String']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeCurrencyStddevFields {
   __typename?: 'payment_method_type_currency_stddev_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyStddevOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyStddevPopFields {
   __typename?: 'payment_method_type_currency_stddev_pop_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyStddevPopOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyStddevSampFields {
   __typename?: 'payment_method_type_currency_stddev_samp_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyStddevSampOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencySumFields {
   __typename?: 'payment_method_type_currency_sum_fields',
  internal_fee?: Maybe<Scalars['numeric']>,
  internal_fee_percent?: Maybe<Scalars['numeric']>,
  maximum_amount?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  merchant_fee_percent?: Maybe<Scalars['numeric']>,
  minimum_amount?: Maybe<Scalars['numeric']>,
}

export interface PaymentMethodTypeCurrencySumOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export enum PaymentMethodTypeCurrencyUpdateColumn {
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Id = 'id',
  InternalFee = 'internal_fee',
  InternalFeePercent = 'internal_fee_percent',
  IsApprovalRequired = 'is_approval_required',
  MaximumAmount = 'maximum_amount',
  MerchantFee = 'merchant_fee',
  MerchantFeePercent = 'merchant_fee_percent',
  MethodTypeId = 'method_type_id',
  MinimumAmount = 'minimum_amount',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeCurrencyVarPopFields {
   __typename?: 'payment_method_type_currency_var_pop_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyVarPopOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyVarSampFields {
   __typename?: 'payment_method_type_currency_var_samp_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyVarSampOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeCurrencyVarianceFields {
   __typename?: 'payment_method_type_currency_variance_fields',
  internal_fee?: Maybe<Scalars['Float']>,
  internal_fee_percent?: Maybe<Scalars['Float']>,
  maximum_amount?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
  merchant_fee_percent?: Maybe<Scalars['Float']>,
  minimum_amount?: Maybe<Scalars['Float']>,
}

export interface PaymentMethodTypeCurrencyVarianceOrderBy {
  internal_fee?: Maybe<OrderBy>,
  internal_fee_percent?: Maybe<OrderBy>,
  maximum_amount?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  merchant_fee_percent?: Maybe<OrderBy>,
  minimum_amount?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeInsertInput {
  attributes?: Maybe<PaymentMethodTypeAttributeArrRelInsertInput>,
  callback_url?: Maybe<Scalars['String']>,
  can_only_withdrawal_net_deposits?: Maybe<Scalars['Boolean']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  currencies?: Maybe<PaymentMethodTypeCurrencyArrRelInsertInput>,
  deleted_by?: Maybe<StakeholderObjRelInsertInput>,
  deleted_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method?: Maybe<PaymentMethodObjRelInsertInput>,
  method_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notification_url?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeObjRelInsertInput>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeMaxFields {
   __typename?: 'payment_method_type_max_fields',
  callback_url?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notification_url?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeMaxOrderBy {
  callback_url?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notification_url?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeMinFields {
   __typename?: 'payment_method_type_min_fields',
  callback_url?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notification_url?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMethodTypeMinOrderBy {
  callback_url?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notification_url?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypeMutationResponse {
   __typename?: 'payment_method_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentMethodType>,
}

export interface PaymentMethodTypeObjRelInsertInput {
  data: PaymentMethodTypeInsertInput,
  on_conflict?: Maybe<PaymentMethodTypeOnConflict>,
}

export interface PaymentMethodTypeOnConflict {
  constraint: PaymentMethodTypeConstraint,
  update_columns: Array<PaymentMethodTypeUpdateColumn>,
  where?: Maybe<PaymentMethodTypeBoolExp>,
}

export interface PaymentMethodTypeOrderBy {
  attributes_aggregate?: Maybe<PaymentMethodTypeAttributeAggregateOrderBy>,
  callback_url?: Maybe<OrderBy>,
  can_only_withdrawal_net_deposits?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  currencies_aggregate?: Maybe<PaymentMethodTypeCurrencyAggregateOrderBy>,
  deleted_by?: Maybe<StakeholderOrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method?: Maybe<PaymentMethodOrderBy>,
  method_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notification_url?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_method_type?: Maybe<PaymentProviderMethodTypeOrderBy>,
  provider_method_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMethodTypePkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentMethodTypeSelectColumn {
  CallbackUrl = 'callback_url',
  CanOnlyWithdrawalNetDeposits = 'can_only_withdrawal_net_deposits',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  DeletedById = 'deleted_by_id',
  Description = 'description',
  Id = 'id',
  MethodId = 'method_id',
  Name = 'name',
  NotificationUrl = 'notification_url',
  OrganizationId = 'organization_id',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMethodTypeSetInput {
  callback_url?: Maybe<Scalars['String']>,
  can_only_withdrawal_net_deposits?: Maybe<Scalars['Boolean']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notification_url?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_method_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentMethodTypeUpdateColumn {
  CallbackUrl = 'callback_url',
  CanOnlyWithdrawalNetDeposits = 'can_only_withdrawal_net_deposits',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  DeletedById = 'deleted_by_id',
  Description = 'description',
  Id = 'id',
  MethodId = 'method_id',
  Name = 'name',
  NotificationUrl = 'notification_url',
  OrganizationId = 'organization_id',
  ProviderMethodTypeId = 'provider_method_type_id',
  UpdatedAt = 'updated_at'
}

export enum PaymentMethodUpdateColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  LogoId = 'logo_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  ProviderId = 'provider_id',
  SegmentId = 'segment_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentMinFields {
   __typename?: 'payment_min_fields',
  amount?: Maybe<Scalars['numeric']>,
  bank_account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_transaction_id?: Maybe<Scalars['String']>,
  reference_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentMinOrderBy {
  amount?: Maybe<OrderBy>,
  bank_account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method_type_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_transaction_id?: Maybe<OrderBy>,
  reference_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentMutationResponse {
   __typename?: 'payment_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Payment>,
}

export interface PaymentObjRelInsertInput {
  data: PaymentInsertInput,
  on_conflict?: Maybe<PaymentOnConflict>,
}

export interface PaymentOnConflict {
  constraint: PaymentConstraint,
  update_columns: Array<PaymentUpdateColumn>,
  where?: Maybe<PaymentBoolExp>,
}

export interface PaymentOrderBy {
  amount?: Maybe<OrderBy>,
  attributes_aggregate?: Maybe<PaymentAttributeValueAggregateOrderBy>,
  bank_account?: Maybe<BankAccountOrderBy>,
  bank_account_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  currency?: Maybe<CurrencyOrderBy>,
  currency_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method_type?: Maybe<PaymentMethodTypeOrderBy>,
  method_type_id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  provider_transaction_id?: Maybe<OrderBy>,
  reference_id?: Maybe<OrderBy>,
  status?: Maybe<PaymentStatusOrderBy>,
  status_id?: Maybe<OrderBy>,
  transaction?: Maybe<TransactionOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentOrientation {
   __typename?: 'payment_orientation',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentOrientationAggregate {
   __typename?: 'payment_orientation_aggregate',
  aggregate?: Maybe<PaymentOrientationAggregateFields>,
  nodes: Array<PaymentOrientation>,
}

export interface PaymentOrientationAggregateFields {
   __typename?: 'payment_orientation_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentOrientationMaxFields>,
  min?: Maybe<PaymentOrientationMinFields>,
}


export interface PaymentOrientationAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentOrientationSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentOrientationAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentOrientationMaxOrderBy>,
  min?: Maybe<PaymentOrientationMinOrderBy>,
}

export interface PaymentOrientationArrRelInsertInput {
  data: Array<PaymentOrientationInsertInput>,
  on_conflict?: Maybe<PaymentOrientationOnConflict>,
}

export interface PaymentOrientationBoolExp {
  _and?: Maybe<Array<Maybe<PaymentOrientationBoolExp>>>,
  _not?: Maybe<PaymentOrientationBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentOrientationBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentOrientationConstraint {
  PaymentOrientationNameKey = 'payment_orientation_name_key',
  PaymentOrientationPkey = 'payment_orientation_pkey'
}

export interface PaymentOrientationInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentOrientationMaxFields {
   __typename?: 'payment_orientation_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentOrientationMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentOrientationMinFields {
   __typename?: 'payment_orientation_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentOrientationMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentOrientationMutationResponse {
   __typename?: 'payment_orientation_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentOrientation>,
}

export interface PaymentOrientationObjRelInsertInput {
  data: PaymentOrientationInsertInput,
  on_conflict?: Maybe<PaymentOrientationOnConflict>,
}

export interface PaymentOrientationOnConflict {
  constraint: PaymentOrientationConstraint,
  update_columns: Array<PaymentOrientationUpdateColumn>,
  where?: Maybe<PaymentOrientationBoolExp>,
}

export interface PaymentOrientationOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentOrientationPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentOrientationSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentOrientationSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentOrientationUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentPkColumnsInput {
  id: Scalars['String'],
}

export interface PaymentProvider {
   __typename?: 'payment_provider',
  attributes: Array<PaymentMethodAttributeKey>,
  attributes_aggregate: PaymentMethodAttributeKeyAggregate,
  children: Array<PaymentProvider>,
  children_aggregate: PaymentProviderAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_deprecated?: Maybe<Scalars['Boolean']>,
  method_types: Array<PaymentProviderMethodType>,
  method_types_aggregate: PaymentProviderMethodTypeAggregate,
  methods: Array<PaymentMethod>,
  methods_aggregate: PaymentMethodAggregate,
  name: Scalars['String'],
  only_v1?: Maybe<Scalars['Boolean']>,
  parent?: Maybe<PaymentProvider>,
  parent_id?: Maybe<Scalars['String']>,
  sections: Array<PaymentMethodAttributeSection>,
  sections_aggregate: PaymentMethodAttributeSectionAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface PaymentProviderAttributesArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface PaymentProviderAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface PaymentProviderChildrenArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface PaymentProviderChildrenAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface PaymentProviderMethodTypesArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface PaymentProviderMethodTypesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface PaymentProviderMethodsArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface PaymentProviderMethodsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface PaymentProviderSectionsArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}


export interface PaymentProviderSectionsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}

export interface PaymentProviderAggregate {
   __typename?: 'payment_provider_aggregate',
  aggregate?: Maybe<PaymentProviderAggregateFields>,
  nodes: Array<PaymentProvider>,
}

export interface PaymentProviderAggregateFields {
   __typename?: 'payment_provider_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentProviderMaxFields>,
  min?: Maybe<PaymentProviderMinFields>,
}


export interface PaymentProviderAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentProviderSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentProviderAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentProviderMaxOrderBy>,
  min?: Maybe<PaymentProviderMinOrderBy>,
}

export interface PaymentProviderArrRelInsertInput {
  data: Array<PaymentProviderInsertInput>,
  on_conflict?: Maybe<PaymentProviderOnConflict>,
}

export interface PaymentProviderBoolExp {
  _and?: Maybe<Array<Maybe<PaymentProviderBoolExp>>>,
  _not?: Maybe<PaymentProviderBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentProviderBoolExp>>>,
  attributes?: Maybe<PaymentMethodAttributeKeyBoolExp>,
  children?: Maybe<PaymentProviderBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deprecated?: Maybe<BooleanComparisonExp>,
  method_types?: Maybe<PaymentProviderMethodTypeBoolExp>,
  methods?: Maybe<PaymentMethodBoolExp>,
  name?: Maybe<StringComparisonExp>,
  only_v1?: Maybe<BooleanComparisonExp>,
  parent?: Maybe<PaymentProviderBoolExp>,
  parent_id?: Maybe<StringComparisonExp>,
  sections?: Maybe<PaymentMethodAttributeSectionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentProviderConstraint {
  PaymentProviderNameKey = 'payment_provider_name_key',
  PaymentProviderPkey = 'payment_provider_pkey'
}

export interface PaymentProviderInsertInput {
  attributes?: Maybe<PaymentMethodAttributeKeyArrRelInsertInput>,
  children?: Maybe<PaymentProviderArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deprecated?: Maybe<Scalars['Boolean']>,
  method_types?: Maybe<PaymentProviderMethodTypeArrRelInsertInput>,
  methods?: Maybe<PaymentMethodArrRelInsertInput>,
  name?: Maybe<Scalars['String']>,
  only_v1?: Maybe<Scalars['Boolean']>,
  parent?: Maybe<PaymentProviderObjRelInsertInput>,
  parent_id?: Maybe<Scalars['String']>,
  sections?: Maybe<PaymentMethodAttributeSectionArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderIntegrationMode {
   __typename?: 'payment_provider_integration_mode',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentProviderIntegrationModeAggregate {
   __typename?: 'payment_provider_integration_mode_aggregate',
  aggregate?: Maybe<PaymentProviderIntegrationModeAggregateFields>,
  nodes: Array<PaymentProviderIntegrationMode>,
}

export interface PaymentProviderIntegrationModeAggregateFields {
   __typename?: 'payment_provider_integration_mode_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentProviderIntegrationModeMaxFields>,
  min?: Maybe<PaymentProviderIntegrationModeMinFields>,
}


export interface PaymentProviderIntegrationModeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentProviderIntegrationModeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentProviderIntegrationModeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentProviderIntegrationModeMaxOrderBy>,
  min?: Maybe<PaymentProviderIntegrationModeMinOrderBy>,
}

export interface PaymentProviderIntegrationModeArrRelInsertInput {
  data: Array<PaymentProviderIntegrationModeInsertInput>,
  on_conflict?: Maybe<PaymentProviderIntegrationModeOnConflict>,
}

export interface PaymentProviderIntegrationModeBoolExp {
  _and?: Maybe<Array<Maybe<PaymentProviderIntegrationModeBoolExp>>>,
  _not?: Maybe<PaymentProviderIntegrationModeBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentProviderIntegrationModeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentProviderIntegrationModeConstraint {
  PaymentProviderMethodTypeIntegrationModeNameKey = 'payment_provider_method_type_integration_mode_name_key',
  PaymentProviderMethodTypeIntegrationModePkey = 'payment_provider_method_type_integration_mode_pkey'
}

export interface PaymentProviderIntegrationModeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderIntegrationModeMaxFields {
   __typename?: 'payment_provider_integration_mode_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderIntegrationModeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderIntegrationModeMinFields {
   __typename?: 'payment_provider_integration_mode_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderIntegrationModeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderIntegrationModeMutationResponse {
   __typename?: 'payment_provider_integration_mode_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentProviderIntegrationMode>,
}

export interface PaymentProviderIntegrationModeObjRelInsertInput {
  data: PaymentProviderIntegrationModeInsertInput,
  on_conflict?: Maybe<PaymentProviderIntegrationModeOnConflict>,
}

export interface PaymentProviderIntegrationModeOnConflict {
  constraint: PaymentProviderIntegrationModeConstraint,
  update_columns: Array<PaymentProviderIntegrationModeUpdateColumn>,
  where?: Maybe<PaymentProviderIntegrationModeBoolExp>,
}

export interface PaymentProviderIntegrationModeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderIntegrationModePkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentProviderIntegrationModeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentProviderIntegrationModeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentProviderIntegrationModeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentProviderMaxFields {
   __typename?: 'payment_provider_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderMethodType {
   __typename?: 'payment_provider_method_type',
  attributes: Array<PaymentMethodTypeAttributeKey>,
  attributes_aggregate: PaymentMethodTypeAttributeKeyAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  integration_key?: Maybe<Scalars['String']>,
  is_deprecated?: Maybe<Scalars['Boolean']>,
  method_types: Array<PaymentMethodType>,
  method_types_aggregate: PaymentMethodTypeAggregate,
  mode: PaymentProviderIntegrationMode,
  mode_id: Scalars['String'],
  name: Scalars['String'],
  orientation: PaymentOrientation,
  orientation_id: Scalars['String'],
  payment_attributes: Array<PaymentAttributeKey>,
  payment_attributes_aggregate: PaymentAttributeKeyAggregate,
  provider: PaymentProvider,
  provider_id: Scalars['String'],
  sections: Array<PaymentMethodTypeAttributeSection>,
  sections_aggregate: PaymentMethodTypeAttributeSectionAggregate,
  target?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
  webhook?: Maybe<Scalars['String']>,
}


export interface PaymentProviderMethodTypeAttributesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface PaymentProviderMethodTypeAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface PaymentProviderMethodTypeMethodTypesArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface PaymentProviderMethodTypeMethodTypesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface PaymentProviderMethodTypePaymentAttributesArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface PaymentProviderMethodTypePaymentAttributesAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface PaymentProviderMethodTypeSectionsArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}


export interface PaymentProviderMethodTypeSectionsAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}

export interface PaymentProviderMethodTypeAggregate {
   __typename?: 'payment_provider_method_type_aggregate',
  aggregate?: Maybe<PaymentProviderMethodTypeAggregateFields>,
  nodes: Array<PaymentProviderMethodType>,
}

export interface PaymentProviderMethodTypeAggregateFields {
   __typename?: 'payment_provider_method_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentProviderMethodTypeMaxFields>,
  min?: Maybe<PaymentProviderMethodTypeMinFields>,
}


export interface PaymentProviderMethodTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentProviderMethodTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentProviderMethodTypeMaxOrderBy>,
  min?: Maybe<PaymentProviderMethodTypeMinOrderBy>,
}

export interface PaymentProviderMethodTypeArrRelInsertInput {
  data: Array<PaymentProviderMethodTypeInsertInput>,
  on_conflict?: Maybe<PaymentProviderMethodTypeOnConflict>,
}

export interface PaymentProviderMethodTypeBoolExp {
  _and?: Maybe<Array<Maybe<PaymentProviderMethodTypeBoolExp>>>,
  _not?: Maybe<PaymentProviderMethodTypeBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentProviderMethodTypeBoolExp>>>,
  attributes?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  integration_key?: Maybe<StringComparisonExp>,
  is_deprecated?: Maybe<BooleanComparisonExp>,
  method_types?: Maybe<PaymentMethodTypeBoolExp>,
  mode?: Maybe<PaymentProviderIntegrationModeBoolExp>,
  mode_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  orientation?: Maybe<PaymentOrientationBoolExp>,
  orientation_id?: Maybe<StringComparisonExp>,
  payment_attributes?: Maybe<PaymentAttributeKeyBoolExp>,
  provider?: Maybe<PaymentProviderBoolExp>,
  provider_id?: Maybe<StringComparisonExp>,
  sections?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>,
  target?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  webhook?: Maybe<StringComparisonExp>,
}

export enum PaymentProviderMethodTypeConstraint {
  PaymentProviderMethodTypeNameKey = 'payment_provider_method_type_name_key',
  PaymentProviderMethodTypePkey = 'payment_provider_method_type_pkey'
}

export interface PaymentProviderMethodTypeInsertInput {
  attributes?: Maybe<PaymentMethodTypeAttributeKeyArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  integration_key?: Maybe<Scalars['String']>,
  is_deprecated?: Maybe<Scalars['Boolean']>,
  method_types?: Maybe<PaymentMethodTypeArrRelInsertInput>,
  mode?: Maybe<PaymentProviderIntegrationModeObjRelInsertInput>,
  mode_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  orientation?: Maybe<PaymentOrientationObjRelInsertInput>,
  orientation_id?: Maybe<Scalars['String']>,
  payment_attributes?: Maybe<PaymentAttributeKeyArrRelInsertInput>,
  provider?: Maybe<PaymentProviderObjRelInsertInput>,
  provider_id?: Maybe<Scalars['String']>,
  sections?: Maybe<PaymentMethodTypeAttributeSectionArrRelInsertInput>,
  target?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  webhook?: Maybe<Scalars['String']>,
}

export interface PaymentProviderMethodTypeMaxFields {
   __typename?: 'payment_provider_method_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  integration_key?: Maybe<Scalars['String']>,
  mode_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  orientation_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  target?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  webhook?: Maybe<Scalars['String']>,
}

export interface PaymentProviderMethodTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  integration_key?: Maybe<OrderBy>,
  mode_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  orientation_id?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  target?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  webhook?: Maybe<OrderBy>,
}

export interface PaymentProviderMethodTypeMinFields {
   __typename?: 'payment_provider_method_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  integration_key?: Maybe<Scalars['String']>,
  mode_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  orientation_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  target?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  webhook?: Maybe<Scalars['String']>,
}

export interface PaymentProviderMethodTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  integration_key?: Maybe<OrderBy>,
  mode_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  orientation_id?: Maybe<OrderBy>,
  provider_id?: Maybe<OrderBy>,
  target?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  webhook?: Maybe<OrderBy>,
}

export interface PaymentProviderMethodTypeMutationResponse {
   __typename?: 'payment_provider_method_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentProviderMethodType>,
}

export interface PaymentProviderMethodTypeObjRelInsertInput {
  data: PaymentProviderMethodTypeInsertInput,
  on_conflict?: Maybe<PaymentProviderMethodTypeOnConflict>,
}

export interface PaymentProviderMethodTypeOnConflict {
  constraint: PaymentProviderMethodTypeConstraint,
  update_columns: Array<PaymentProviderMethodTypeUpdateColumn>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>,
}

export interface PaymentProviderMethodTypeOrderBy {
  attributes_aggregate?: Maybe<PaymentMethodTypeAttributeKeyAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  integration_key?: Maybe<OrderBy>,
  is_deprecated?: Maybe<OrderBy>,
  method_types_aggregate?: Maybe<PaymentMethodTypeAggregateOrderBy>,
  mode?: Maybe<PaymentProviderIntegrationModeOrderBy>,
  mode_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  orientation?: Maybe<PaymentOrientationOrderBy>,
  orientation_id?: Maybe<OrderBy>,
  payment_attributes_aggregate?: Maybe<PaymentAttributeKeyAggregateOrderBy>,
  provider?: Maybe<PaymentProviderOrderBy>,
  provider_id?: Maybe<OrderBy>,
  sections_aggregate?: Maybe<PaymentMethodTypeAttributeSectionAggregateOrderBy>,
  target?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  webhook?: Maybe<OrderBy>,
}

export interface PaymentProviderMethodTypePkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentProviderMethodTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IntegrationKey = 'integration_key',
  IsDeprecated = 'is_deprecated',
  ModeId = 'mode_id',
  Name = 'name',
  OrientationId = 'orientation_id',
  ProviderId = 'provider_id',
  Target = 'target',
  UpdatedAt = 'updated_at',
  Webhook = 'webhook'
}

export interface PaymentProviderMethodTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  integration_key?: Maybe<Scalars['String']>,
  is_deprecated?: Maybe<Scalars['Boolean']>,
  mode_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  orientation_id?: Maybe<Scalars['String']>,
  provider_id?: Maybe<Scalars['String']>,
  target?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  webhook?: Maybe<Scalars['String']>,
}

export enum PaymentProviderMethodTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IntegrationKey = 'integration_key',
  IsDeprecated = 'is_deprecated',
  ModeId = 'mode_id',
  Name = 'name',
  OrientationId = 'orientation_id',
  ProviderId = 'provider_id',
  Target = 'target',
  UpdatedAt = 'updated_at',
  Webhook = 'webhook'
}

export interface PaymentProviderMinFields {
   __typename?: 'payment_provider_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentProviderMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderMutationResponse {
   __typename?: 'payment_provider_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentProvider>,
}

export interface PaymentProviderObjRelInsertInput {
  data: PaymentProviderInsertInput,
  on_conflict?: Maybe<PaymentProviderOnConflict>,
}

export interface PaymentProviderOnConflict {
  constraint: PaymentProviderConstraint,
  update_columns: Array<PaymentProviderUpdateColumn>,
  where?: Maybe<PaymentProviderBoolExp>,
}

export interface PaymentProviderOrderBy {
  attributes_aggregate?: Maybe<PaymentMethodAttributeKeyAggregateOrderBy>,
  children_aggregate?: Maybe<PaymentProviderAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deprecated?: Maybe<OrderBy>,
  method_types_aggregate?: Maybe<PaymentProviderMethodTypeAggregateOrderBy>,
  methods_aggregate?: Maybe<PaymentMethodAggregateOrderBy>,
  name?: Maybe<OrderBy>,
  only_v1?: Maybe<OrderBy>,
  parent?: Maybe<PaymentProviderOrderBy>,
  parent_id?: Maybe<OrderBy>,
  sections_aggregate?: Maybe<PaymentMethodAttributeSectionAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentProviderPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentProviderSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeprecated = 'is_deprecated',
  Name = 'name',
  OnlyV1 = 'only_v1',
  ParentId = 'parent_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentProviderSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deprecated?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  only_v1?: Maybe<Scalars['Boolean']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentProviderUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeprecated = 'is_deprecated',
  Name = 'name',
  OnlyV1 = 'only_v1',
  ParentId = 'parent_id',
  UpdatedAt = 'updated_at'
}

export enum PaymentSelectColumn {
  Amount = 'amount',
  BankAccountId = 'bank_account_id',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Id = 'id',
  MethodTypeId = 'method_type_id',
  OrganizationId = 'organization_id',
  ProviderTransactionId = 'provider_transaction_id',
  ReferenceId = 'reference_id',
  StatusId = 'status_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentSetInput {
  amount?: Maybe<Scalars['numeric']>,
  bank_account_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  currency_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  method_type_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  provider_transaction_id?: Maybe<Scalars['String']>,
  reference_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentStatus {
   __typename?: 'payment_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PaymentStatusAggregate {
   __typename?: 'payment_status_aggregate',
  aggregate?: Maybe<PaymentStatusAggregateFields>,
  nodes: Array<PaymentStatus>,
}

export interface PaymentStatusAggregateFields {
   __typename?: 'payment_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PaymentStatusMaxFields>,
  min?: Maybe<PaymentStatusMinFields>,
}


export interface PaymentStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<PaymentStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PaymentStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PaymentStatusMaxOrderBy>,
  min?: Maybe<PaymentStatusMinOrderBy>,
}

export interface PaymentStatusArrRelInsertInput {
  data: Array<PaymentStatusInsertInput>,
  on_conflict?: Maybe<PaymentStatusOnConflict>,
}

export interface PaymentStatusBoolExp {
  _and?: Maybe<Array<Maybe<PaymentStatusBoolExp>>>,
  _not?: Maybe<PaymentStatusBoolExp>,
  _or?: Maybe<Array<Maybe<PaymentStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PaymentStatusConstraint {
  PaymentStatusNameKey = 'payment_status_name_key',
  PaymentStatusPkey = 'payment_status_pkey'
}

export interface PaymentStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentStatusMaxFields {
   __typename?: 'payment_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentStatusMinFields {
   __typename?: 'payment_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PaymentStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentStatusMutationResponse {
   __typename?: 'payment_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PaymentStatus>,
}

export interface PaymentStatusObjRelInsertInput {
  data: PaymentStatusInsertInput,
  on_conflict?: Maybe<PaymentStatusOnConflict>,
}

export interface PaymentStatusOnConflict {
  constraint: PaymentStatusConstraint,
  update_columns: Array<PaymentStatusUpdateColumn>,
  where?: Maybe<PaymentStatusBoolExp>,
}

export interface PaymentStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PaymentStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum PaymentStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PaymentStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PaymentStddevFields {
   __typename?: 'payment_stddev_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentStddevOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentStddevPopFields {
   __typename?: 'payment_stddev_pop_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentStddevPopOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentStddevSampFields {
   __typename?: 'payment_stddev_samp_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentStddevSampOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentSumFields {
   __typename?: 'payment_sum_fields',
  amount?: Maybe<Scalars['numeric']>,
}

export interface PaymentSumOrderBy {
  amount?: Maybe<OrderBy>,
}

export enum PaymentUpdateColumn {
  Amount = 'amount',
  BankAccountId = 'bank_account_id',
  CreatedAt = 'created_at',
  CurrencyId = 'currency_id',
  Id = 'id',
  MethodTypeId = 'method_type_id',
  OrganizationId = 'organization_id',
  ProviderTransactionId = 'provider_transaction_id',
  ReferenceId = 'reference_id',
  StatusId = 'status_id',
  UpdatedAt = 'updated_at'
}

export interface PaymentVarPopFields {
   __typename?: 'payment_var_pop_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentVarPopOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentVarSampFields {
   __typename?: 'payment_var_samp_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentVarSampOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PaymentVarianceFields {
   __typename?: 'payment_variance_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface PaymentVarianceOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface PhoneCode {
   __typename?: 'phone_code',
  code: Scalars['String'],
  created_at: Scalars['timestamptz'],
  updated_at: Scalars['timestamptz'],
}

export interface PhoneCodeAggregate {
   __typename?: 'phone_code_aggregate',
  aggregate?: Maybe<PhoneCodeAggregateFields>,
  nodes: Array<PhoneCode>,
}

export interface PhoneCodeAggregateFields {
   __typename?: 'phone_code_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PhoneCodeMaxFields>,
  min?: Maybe<PhoneCodeMinFields>,
}


export interface PhoneCodeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PhoneCodeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PhoneCodeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PhoneCodeMaxOrderBy>,
  min?: Maybe<PhoneCodeMinOrderBy>,
}

export interface PhoneCodeArrRelInsertInput {
  data: Array<PhoneCodeInsertInput>,
  on_conflict?: Maybe<PhoneCodeOnConflict>,
}

export interface PhoneCodeBoolExp {
  _and?: Maybe<Array<Maybe<PhoneCodeBoolExp>>>,
  _not?: Maybe<PhoneCodeBoolExp>,
  _or?: Maybe<Array<Maybe<PhoneCodeBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PhoneCodeConstraint {
  PhoneCodeCodeKey = 'phone_code_code_key',
  PhoneCodePkey = 'phone_code_pkey'
}

export interface PhoneCodeInsertInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneCodeMaxFields {
   __typename?: 'phone_code_max_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneCodeMaxOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneCodeMinFields {
   __typename?: 'phone_code_min_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneCodeMinOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneCodeMutationResponse {
   __typename?: 'phone_code_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PhoneCode>,
}

export interface PhoneCodeObjRelInsertInput {
  data: PhoneCodeInsertInput,
  on_conflict?: Maybe<PhoneCodeOnConflict>,
}

export interface PhoneCodeOnConflict {
  constraint: PhoneCodeConstraint,
  update_columns: Array<PhoneCodeUpdateColumn>,
  where?: Maybe<PhoneCodeBoolExp>,
}

export interface PhoneCodeOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneCodePkColumnsInput {
  code: Scalars['String'],
}

export enum PhoneCodeSelectColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export interface PhoneCodeSetInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PhoneCodeUpdateColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export interface PhoneType {
   __typename?: 'phone_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PhoneTypeAggregate {
   __typename?: 'phone_type_aggregate',
  aggregate?: Maybe<PhoneTypeAggregateFields>,
  nodes: Array<PhoneType>,
}

export interface PhoneTypeAggregateFields {
   __typename?: 'phone_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PhoneTypeMaxFields>,
  min?: Maybe<PhoneTypeMinFields>,
}


export interface PhoneTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PhoneTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PhoneTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PhoneTypeMaxOrderBy>,
  min?: Maybe<PhoneTypeMinOrderBy>,
}

export interface PhoneTypeArrRelInsertInput {
  data: Array<PhoneTypeInsertInput>,
  on_conflict?: Maybe<PhoneTypeOnConflict>,
}

export interface PhoneTypeBoolExp {
  _and?: Maybe<Array<Maybe<PhoneTypeBoolExp>>>,
  _not?: Maybe<PhoneTypeBoolExp>,
  _or?: Maybe<Array<Maybe<PhoneTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PhoneTypeConstraint {
  PhoneTypeNameKey = 'phone_type_name_key',
  PhoneTypePkey = 'phone_type_pkey'
}

export interface PhoneTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneTypeMaxFields {
   __typename?: 'phone_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneTypeMinFields {
   __typename?: 'phone_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PhoneTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneTypeMutationResponse {
   __typename?: 'phone_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PhoneType>,
}

export interface PhoneTypeObjRelInsertInput {
  data: PhoneTypeInsertInput,
  on_conflict?: Maybe<PhoneTypeOnConflict>,
}

export interface PhoneTypeOnConflict {
  constraint: PhoneTypeConstraint,
  update_columns: Array<PhoneTypeUpdateColumn>,
  where?: Maybe<PhoneTypeBoolExp>,
}

export interface PhoneTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PhoneTypePkColumnsInput {
  id: Scalars['String'],
}

export enum PhoneTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PhoneTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PhoneTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformAccess {
   __typename?: 'platform_access',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PlatformAccessAggregate {
   __typename?: 'platform_access_aggregate',
  aggregate?: Maybe<PlatformAccessAggregateFields>,
  nodes: Array<PlatformAccess>,
}

export interface PlatformAccessAggregateFields {
   __typename?: 'platform_access_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformAccessMaxFields>,
  min?: Maybe<PlatformAccessMinFields>,
}


export interface PlatformAccessAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformAccessSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformAccessAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformAccessMaxOrderBy>,
  min?: Maybe<PlatformAccessMinOrderBy>,
}

export interface PlatformAccessArrRelInsertInput {
  data: Array<PlatformAccessInsertInput>,
  on_conflict?: Maybe<PlatformAccessOnConflict>,
}

export interface PlatformAccessBoolExp {
  _and?: Maybe<Array<Maybe<PlatformAccessBoolExp>>>,
  _not?: Maybe<PlatformAccessBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformAccessBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformAccessConstraint {
  PlatformAccessNameKey = 'platform_access_name_key',
  PlatformAccessPkey = 'platform_access_pkey'
}

export interface PlatformAccessInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformAccessMaxFields {
   __typename?: 'platform_access_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformAccessMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformAccessMinFields {
   __typename?: 'platform_access_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformAccessMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformAccessMutationResponse {
   __typename?: 'platform_access_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformAccess>,
}

export interface PlatformAccessObjRelInsertInput {
  data: PlatformAccessInsertInput,
  on_conflict?: Maybe<PlatformAccessOnConflict>,
}

export interface PlatformAccessOnConflict {
  constraint: PlatformAccessConstraint,
  update_columns: Array<PlatformAccessUpdateColumn>,
  where?: Maybe<PlatformAccessBoolExp>,
}

export interface PlatformAccessOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformAccessPkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformAccessSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformAccessSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PlatformAccessUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServer {
   __typename?: 'platform_server',
  access_formats: Array<AccessFormat>,
  access_formats_aggregate: AccessFormatAggregate,
  account_groups: Array<AccountGroup>,
  account_groups_aggregate: AccountGroupAggregate,
  account_range_end?: Maybe<Scalars['String']>,
  account_range_start?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  environment: PlatformServerEnvironment,
  environment_id: Scalars['String'],
  extensions: Array<SymbolExtension>,
  extensions_aggregate: SymbolExtensionAggregate,
  id: Scalars['String'],
  is_admin: Scalars['Boolean'],
  is_deleted: Scalars['Boolean'],
  is_pumping: Scalars['Boolean'],
  last_seen?: Maybe<Scalars['timestamptz']>,
  leverages: Array<PlatformServersToSupportedLeverages>,
  leverages_aggregate: PlatformServersToSupportedLeveragesAggregate,
  login: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  next_login: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  password: Scalars['String'],
  platform_symbols: Array<PlatformSymbol>,
  platform_symbols_aggregate: PlatformSymbolAggregate,
  platform_type: PlatformServerType,
  retry_status: PlatformServerRetryStatus,
  retry_status_id: Scalars['String'],
  securities: Array<Security>,
  securities_aggregate: SecurityAggregate,
  status: PlatformServerStatus,
  status_id: Scalars['String'],
  symbols: Array<Symbol>,
  symbols_aggregate: SymbolAggregate,
  timezone: Scalars['Int'],
  type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  url: Scalars['String'],
}


export interface PlatformServerAccessFormatsArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface PlatformServerAccessFormatsAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface PlatformServerAccountGroupsArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface PlatformServerAccountGroupsAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface PlatformServerExtensionsArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface PlatformServerExtensionsAggregateArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface PlatformServerLeveragesArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface PlatformServerLeveragesAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface PlatformServerPlatformSymbolsArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface PlatformServerPlatformSymbolsAggregateArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface PlatformServerSecuritiesArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface PlatformServerSecuritiesAggregateArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface PlatformServerSymbolsArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface PlatformServerSymbolsAggregateArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}

export interface PlatformServerAggregate {
   __typename?: 'platform_server_aggregate',
  aggregate?: Maybe<PlatformServerAggregateFields>,
  nodes: Array<PlatformServer>,
}

export interface PlatformServerAggregateFields {
   __typename?: 'platform_server_aggregate_fields',
  avg?: Maybe<PlatformServerAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerMaxFields>,
  min?: Maybe<PlatformServerMinFields>,
  stddev?: Maybe<PlatformServerStddevFields>,
  stddev_pop?: Maybe<PlatformServerStddevPopFields>,
  stddev_samp?: Maybe<PlatformServerStddevSampFields>,
  sum?: Maybe<PlatformServerSumFields>,
  var_pop?: Maybe<PlatformServerVarPopFields>,
  var_samp?: Maybe<PlatformServerVarSampFields>,
  variance?: Maybe<PlatformServerVarianceFields>,
}


export interface PlatformServerAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerAggregateOrderBy {
  avg?: Maybe<PlatformServerAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerMaxOrderBy>,
  min?: Maybe<PlatformServerMinOrderBy>,
  stddev?: Maybe<PlatformServerStddevOrderBy>,
  stddev_pop?: Maybe<PlatformServerStddevPopOrderBy>,
  stddev_samp?: Maybe<PlatformServerStddevSampOrderBy>,
  sum?: Maybe<PlatformServerSumOrderBy>,
  var_pop?: Maybe<PlatformServerVarPopOrderBy>,
  var_samp?: Maybe<PlatformServerVarSampOrderBy>,
  variance?: Maybe<PlatformServerVarianceOrderBy>,
}

export interface PlatformServerArrRelInsertInput {
  data: Array<PlatformServerInsertInput>,
  on_conflict?: Maybe<PlatformServerOnConflict>,
}

export interface PlatformServerAvgFields {
   __typename?: 'platform_server_avg_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerAvgOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerBoolExp>>>,
  _not?: Maybe<PlatformServerBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerBoolExp>>>,
  access_formats?: Maybe<AccessFormatBoolExp>,
  account_groups?: Maybe<AccountGroupBoolExp>,
  account_range_end?: Maybe<StringComparisonExp>,
  account_range_start?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  environment?: Maybe<PlatformServerEnvironmentBoolExp>,
  environment_id?: Maybe<StringComparisonExp>,
  extensions?: Maybe<SymbolExtensionBoolExp>,
  id?: Maybe<StringComparisonExp>,
  is_admin?: Maybe<BooleanComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  is_pumping?: Maybe<BooleanComparisonExp>,
  last_seen?: Maybe<TimestamptzComparisonExp>,
  leverages?: Maybe<PlatformServersToSupportedLeveragesBoolExp>,
  login?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  next_login?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  password?: Maybe<StringComparisonExp>,
  platform_symbols?: Maybe<PlatformSymbolBoolExp>,
  platform_type?: Maybe<PlatformServerTypeBoolExp>,
  retry_status?: Maybe<PlatformServerRetryStatusBoolExp>,
  retry_status_id?: Maybe<StringComparisonExp>,
  securities?: Maybe<SecurityBoolExp>,
  status?: Maybe<PlatformServerStatusBoolExp>,
  status_id?: Maybe<StringComparisonExp>,
  symbols?: Maybe<SymbolBoolExp>,
  timezone?: Maybe<IntComparisonExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  url?: Maybe<StringComparisonExp>,
}

export enum PlatformServerConstraint {
  PlatformServerPkey = 'platform_server_pkey'
}

export interface PlatformServerEnvironment {
   __typename?: 'platform_server_environment',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PlatformServerEnvironmentAggregate {
   __typename?: 'platform_server_environment_aggregate',
  aggregate?: Maybe<PlatformServerEnvironmentAggregateFields>,
  nodes: Array<PlatformServerEnvironment>,
}

export interface PlatformServerEnvironmentAggregateFields {
   __typename?: 'platform_server_environment_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerEnvironmentMaxFields>,
  min?: Maybe<PlatformServerEnvironmentMinFields>,
}


export interface PlatformServerEnvironmentAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerEnvironmentSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerEnvironmentAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerEnvironmentMaxOrderBy>,
  min?: Maybe<PlatformServerEnvironmentMinOrderBy>,
}

export interface PlatformServerEnvironmentArrRelInsertInput {
  data: Array<PlatformServerEnvironmentInsertInput>,
  on_conflict?: Maybe<PlatformServerEnvironmentOnConflict>,
}

export interface PlatformServerEnvironmentBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerEnvironmentBoolExp>>>,
  _not?: Maybe<PlatformServerEnvironmentBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerEnvironmentBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformServerEnvironmentConstraint {
  PlatformEnvironmentNameKey = 'platform_environment_name_key',
  PlatformEnvironmentPkey = 'platform_environment_pkey'
}

export interface PlatformServerEnvironmentInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerEnvironmentMaxFields {
   __typename?: 'platform_server_environment_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerEnvironmentMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerEnvironmentMinFields {
   __typename?: 'platform_server_environment_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerEnvironmentMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerEnvironmentMutationResponse {
   __typename?: 'platform_server_environment_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServerEnvironment>,
}

export interface PlatformServerEnvironmentObjRelInsertInput {
  data: PlatformServerEnvironmentInsertInput,
  on_conflict?: Maybe<PlatformServerEnvironmentOnConflict>,
}

export interface PlatformServerEnvironmentOnConflict {
  constraint: PlatformServerEnvironmentConstraint,
  update_columns: Array<PlatformServerEnvironmentUpdateColumn>,
  where?: Maybe<PlatformServerEnvironmentBoolExp>,
}

export interface PlatformServerEnvironmentOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerEnvironmentPkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformServerEnvironmentSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerEnvironmentSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PlatformServerEnvironmentUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerGridView {
   __typename?: 'platform_server_grid_view',
  account_groups?: Maybe<Scalars['bigint']>,
  environment?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServer>,
  securities?: Maybe<Scalars['bigint']>,
  symbols?: Maybe<Scalars['bigint']>,
  type?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerGridViewAggregate {
   __typename?: 'platform_server_grid_view_aggregate',
  aggregate?: Maybe<PlatformServerGridViewAggregateFields>,
  nodes: Array<PlatformServerGridView>,
}

export interface PlatformServerGridViewAggregateFields {
   __typename?: 'platform_server_grid_view_aggregate_fields',
  avg?: Maybe<PlatformServerGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerGridViewMaxFields>,
  min?: Maybe<PlatformServerGridViewMinFields>,
  stddev?: Maybe<PlatformServerGridViewStddevFields>,
  stddev_pop?: Maybe<PlatformServerGridViewStddevPopFields>,
  stddev_samp?: Maybe<PlatformServerGridViewStddevSampFields>,
  sum?: Maybe<PlatformServerGridViewSumFields>,
  var_pop?: Maybe<PlatformServerGridViewVarPopFields>,
  var_samp?: Maybe<PlatformServerGridViewVarSampFields>,
  variance?: Maybe<PlatformServerGridViewVarianceFields>,
}


export interface PlatformServerGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerGridViewAggregateOrderBy {
  avg?: Maybe<PlatformServerGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerGridViewMaxOrderBy>,
  min?: Maybe<PlatformServerGridViewMinOrderBy>,
  stddev?: Maybe<PlatformServerGridViewStddevOrderBy>,
  stddev_pop?: Maybe<PlatformServerGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<PlatformServerGridViewStddevSampOrderBy>,
  sum?: Maybe<PlatformServerGridViewSumOrderBy>,
  var_pop?: Maybe<PlatformServerGridViewVarPopOrderBy>,
  var_samp?: Maybe<PlatformServerGridViewVarSampOrderBy>,
  variance?: Maybe<PlatformServerGridViewVarianceOrderBy>,
}

export interface PlatformServerGridViewAvgFields {
   __typename?: 'platform_server_grid_view_avg_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewAvgOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerGridViewBoolExp>>>,
  _not?: Maybe<PlatformServerGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerGridViewBoolExp>>>,
  account_groups?: Maybe<BigintComparisonExp>,
  environment?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  securities?: Maybe<BigintComparisonExp>,
  symbols?: Maybe<BigintComparisonExp>,
  type?: Maybe<StringComparisonExp>,
  url?: Maybe<StringComparisonExp>,
}

export interface PlatformServerGridViewMaxFields {
   __typename?: 'platform_server_grid_view_max_fields',
  account_groups?: Maybe<Scalars['bigint']>,
  environment?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  securities?: Maybe<Scalars['bigint']>,
  symbols?: Maybe<Scalars['bigint']>,
  type?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerGridViewMaxOrderBy {
  account_groups?: Maybe<OrderBy>,
  environment?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewMinFields {
   __typename?: 'platform_server_grid_view_min_fields',
  account_groups?: Maybe<Scalars['bigint']>,
  environment?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  securities?: Maybe<Scalars['bigint']>,
  symbols?: Maybe<Scalars['bigint']>,
  type?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerGridViewMinOrderBy {
  account_groups?: Maybe<OrderBy>,
  environment?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewOrderBy {
  account_groups?: Maybe<OrderBy>,
  environment?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export enum PlatformServerGridViewSelectColumn {
  AccountGroups = 'account_groups',
  Environment = 'environment',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  Securities = 'securities',
  Symbols = 'symbols',
  Type = 'type',
  Url = 'url'
}

export interface PlatformServerGridViewStddevFields {
   __typename?: 'platform_server_grid_view_stddev_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewStddevOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewStddevPopFields {
   __typename?: 'platform_server_grid_view_stddev_pop_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewStddevPopOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewStddevSampFields {
   __typename?: 'platform_server_grid_view_stddev_samp_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewStddevSampOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewSumFields {
   __typename?: 'platform_server_grid_view_sum_fields',
  account_groups?: Maybe<Scalars['bigint']>,
  securities?: Maybe<Scalars['bigint']>,
  symbols?: Maybe<Scalars['bigint']>,
}

export interface PlatformServerGridViewSumOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewVarPopFields {
   __typename?: 'platform_server_grid_view_var_pop_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewVarPopOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewVarSampFields {
   __typename?: 'platform_server_grid_view_var_samp_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewVarSampOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerGridViewVarianceFields {
   __typename?: 'platform_server_grid_view_variance_fields',
  account_groups?: Maybe<Scalars['Float']>,
  securities?: Maybe<Scalars['Float']>,
  symbols?: Maybe<Scalars['Float']>,
}

export interface PlatformServerGridViewVarianceOrderBy {
  account_groups?: Maybe<OrderBy>,
  securities?: Maybe<OrderBy>,
  symbols?: Maybe<OrderBy>,
}

export interface PlatformServerIncInput {
  timezone?: Maybe<Scalars['Int']>,
}

export interface PlatformServerInsertInput {
  access_formats?: Maybe<AccessFormatArrRelInsertInput>,
  account_groups?: Maybe<AccountGroupArrRelInsertInput>,
  account_range_end?: Maybe<Scalars['String']>,
  account_range_start?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  environment?: Maybe<PlatformServerEnvironmentObjRelInsertInput>,
  environment_id?: Maybe<Scalars['String']>,
  extensions?: Maybe<SymbolExtensionArrRelInsertInput>,
  id?: Maybe<Scalars['String']>,
  is_admin?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_pumping?: Maybe<Scalars['Boolean']>,
  last_seen?: Maybe<Scalars['timestamptz']>,
  leverages?: Maybe<PlatformServersToSupportedLeveragesArrRelInsertInput>,
  login?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  next_login?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  platform_symbols?: Maybe<PlatformSymbolArrRelInsertInput>,
  platform_type?: Maybe<PlatformServerTypeObjRelInsertInput>,
  retry_status?: Maybe<PlatformServerRetryStatusObjRelInsertInput>,
  retry_status_id?: Maybe<Scalars['String']>,
  securities?: Maybe<SecurityArrRelInsertInput>,
  status?: Maybe<PlatformServerStatusObjRelInsertInput>,
  status_id?: Maybe<Scalars['String']>,
  symbols?: Maybe<SymbolArrRelInsertInput>,
  timezone?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerMaxFields {
   __typename?: 'platform_server_max_fields',
  account_range_end?: Maybe<Scalars['String']>,
  account_range_start?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  environment_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  last_seen?: Maybe<Scalars['timestamptz']>,
  login?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  next_login?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  retry_status_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerMaxOrderBy {
  account_range_end?: Maybe<OrderBy>,
  account_range_start?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  last_seen?: Maybe<OrderBy>,
  login?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  next_login?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  password?: Maybe<OrderBy>,
  retry_status_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  timezone?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PlatformServerMinFields {
   __typename?: 'platform_server_min_fields',
  account_range_end?: Maybe<Scalars['String']>,
  account_range_start?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  environment_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  last_seen?: Maybe<Scalars['timestamptz']>,
  login?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  next_login?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  retry_status_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerMinOrderBy {
  account_range_end?: Maybe<OrderBy>,
  account_range_start?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  environment_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  last_seen?: Maybe<OrderBy>,
  login?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  next_login?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  password?: Maybe<OrderBy>,
  retry_status_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  timezone?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PlatformServerMutationResponse {
   __typename?: 'platform_server_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServer>,
}

export interface PlatformServerObjRelInsertInput {
  data: PlatformServerInsertInput,
  on_conflict?: Maybe<PlatformServerOnConflict>,
}

export interface PlatformServerOnConflict {
  constraint: PlatformServerConstraint,
  update_columns: Array<PlatformServerUpdateColumn>,
  where?: Maybe<PlatformServerBoolExp>,
}

export interface PlatformServerOrderBy {
  access_formats_aggregate?: Maybe<AccessFormatAggregateOrderBy>,
  account_groups_aggregate?: Maybe<AccountGroupAggregateOrderBy>,
  account_range_end?: Maybe<OrderBy>,
  account_range_start?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  environment?: Maybe<PlatformServerEnvironmentOrderBy>,
  environment_id?: Maybe<OrderBy>,
  extensions_aggregate?: Maybe<SymbolExtensionAggregateOrderBy>,
  id?: Maybe<OrderBy>,
  is_admin?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  is_pumping?: Maybe<OrderBy>,
  last_seen?: Maybe<OrderBy>,
  leverages_aggregate?: Maybe<PlatformServersToSupportedLeveragesAggregateOrderBy>,
  login?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  next_login?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  password?: Maybe<OrderBy>,
  platform_symbols_aggregate?: Maybe<PlatformSymbolAggregateOrderBy>,
  platform_type?: Maybe<PlatformServerTypeOrderBy>,
  retry_status?: Maybe<PlatformServerRetryStatusOrderBy>,
  retry_status_id?: Maybe<OrderBy>,
  securities_aggregate?: Maybe<SecurityAggregateOrderBy>,
  status?: Maybe<PlatformServerStatusOrderBy>,
  status_id?: Maybe<OrderBy>,
  symbols_aggregate?: Maybe<SymbolAggregateOrderBy>,
  timezone?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  url?: Maybe<OrderBy>,
}

export interface PlatformServerPkColumnsInput {
  id: Scalars['String'],
}

export interface PlatformServerRetryStatus {
   __typename?: 'platform_server_retry_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PlatformServerRetryStatusAggregate {
   __typename?: 'platform_server_retry_status_aggregate',
  aggregate?: Maybe<PlatformServerRetryStatusAggregateFields>,
  nodes: Array<PlatformServerRetryStatus>,
}

export interface PlatformServerRetryStatusAggregateFields {
   __typename?: 'platform_server_retry_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerRetryStatusMaxFields>,
  min?: Maybe<PlatformServerRetryStatusMinFields>,
}


export interface PlatformServerRetryStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerRetryStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerRetryStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerRetryStatusMaxOrderBy>,
  min?: Maybe<PlatformServerRetryStatusMinOrderBy>,
}

export interface PlatformServerRetryStatusArrRelInsertInput {
  data: Array<PlatformServerRetryStatusInsertInput>,
  on_conflict?: Maybe<PlatformServerRetryStatusOnConflict>,
}

export interface PlatformServerRetryStatusBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerRetryStatusBoolExp>>>,
  _not?: Maybe<PlatformServerRetryStatusBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerRetryStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformServerRetryStatusConstraint {
  PlatformServerRetryStatusNameKey = 'platform_server_retry_status_name_key',
  PlatformServerRetryStatusPkey = 'platform_server_retry_status_pkey'
}

export interface PlatformServerRetryStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerRetryStatusMaxFields {
   __typename?: 'platform_server_retry_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerRetryStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerRetryStatusMinFields {
   __typename?: 'platform_server_retry_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerRetryStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerRetryStatusMutationResponse {
   __typename?: 'platform_server_retry_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServerRetryStatus>,
}

export interface PlatformServerRetryStatusObjRelInsertInput {
  data: PlatformServerRetryStatusInsertInput,
  on_conflict?: Maybe<PlatformServerRetryStatusOnConflict>,
}

export interface PlatformServerRetryStatusOnConflict {
  constraint: PlatformServerRetryStatusConstraint,
  update_columns: Array<PlatformServerRetryStatusUpdateColumn>,
  where?: Maybe<PlatformServerRetryStatusBoolExp>,
}

export interface PlatformServerRetryStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerRetryStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformServerRetryStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerRetryStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PlatformServerRetryStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum PlatformServerSelectColumn {
  AccountRangeEnd = 'account_range_end',
  AccountRangeStart = 'account_range_start',
  CreatedAt = 'created_at',
  EnvironmentId = 'environment_id',
  Id = 'id',
  IsAdmin = 'is_admin',
  IsDeleted = 'is_deleted',
  IsPumping = 'is_pumping',
  LastSeen = 'last_seen',
  Login = 'login',
  Name = 'name',
  NextLogin = 'next_login',
  OrganizationId = 'organization_id',
  Password = 'password',
  RetryStatusId = 'retry_status_id',
  StatusId = 'status_id',
  Timezone = 'timezone',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface PlatformServerSetInput {
  account_range_end?: Maybe<Scalars['String']>,
  account_range_start?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  environment_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_admin?: Maybe<Scalars['Boolean']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_pumping?: Maybe<Scalars['Boolean']>,
  last_seen?: Maybe<Scalars['timestamptz']>,
  login?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  next_login?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  password?: Maybe<Scalars['String']>,
  retry_status_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  url?: Maybe<Scalars['String']>,
}

export interface PlatformServerStatus {
   __typename?: 'platform_server_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface PlatformServerStatusAggregate {
   __typename?: 'platform_server_status_aggregate',
  aggregate?: Maybe<PlatformServerStatusAggregateFields>,
  nodes: Array<PlatformServerStatus>,
}

export interface PlatformServerStatusAggregateFields {
   __typename?: 'platform_server_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerStatusMaxFields>,
  min?: Maybe<PlatformServerStatusMinFields>,
}


export interface PlatformServerStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerStatusMaxOrderBy>,
  min?: Maybe<PlatformServerStatusMinOrderBy>,
}

export interface PlatformServerStatusArrRelInsertInput {
  data: Array<PlatformServerStatusInsertInput>,
  on_conflict?: Maybe<PlatformServerStatusOnConflict>,
}

export interface PlatformServerStatusBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerStatusBoolExp>>>,
  _not?: Maybe<PlatformServerStatusBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformServerStatusConstraint {
  PlatformServerStatusNameKey = 'platform_server_status_name_key',
  PlatformServerStatusPkey = 'platform_server_status_pkey'
}

export interface PlatformServerStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerStatusMaxFields {
   __typename?: 'platform_server_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerStatusMinFields {
   __typename?: 'platform_server_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerStatusMutationResponse {
   __typename?: 'platform_server_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServerStatus>,
}

export interface PlatformServerStatusObjRelInsertInput {
  data: PlatformServerStatusInsertInput,
  on_conflict?: Maybe<PlatformServerStatusOnConflict>,
}

export interface PlatformServerStatusOnConflict {
  constraint: PlatformServerStatusConstraint,
  update_columns: Array<PlatformServerStatusUpdateColumn>,
  where?: Maybe<PlatformServerStatusBoolExp>,
}

export interface PlatformServerStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformServerStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PlatformServerStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerStddevFields {
   __typename?: 'platform_server_stddev_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerStddevOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerStddevPopFields {
   __typename?: 'platform_server_stddev_pop_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerStddevPopOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerStddevSampFields {
   __typename?: 'platform_server_stddev_samp_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerStddevSampOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerSumFields {
   __typename?: 'platform_server_sum_fields',
  timezone?: Maybe<Scalars['Int']>,
}

export interface PlatformServerSumOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerType {
   __typename?: 'platform_server_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  route: Scalars['String'],
  supported_leverages: Array<LeveragesToPlatformServerTypes>,
  supported_leverages_aggregate: LeveragesToPlatformServerTypesAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface PlatformServerTypeSupportedLeveragesArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface PlatformServerTypeSupportedLeveragesAggregateArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}

export interface PlatformServerTypeAggregate {
   __typename?: 'platform_server_type_aggregate',
  aggregate?: Maybe<PlatformServerTypeAggregateFields>,
  nodes: Array<PlatformServerType>,
}

export interface PlatformServerTypeAggregateFields {
   __typename?: 'platform_server_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServerTypeMaxFields>,
  min?: Maybe<PlatformServerTypeMinFields>,
}


export interface PlatformServerTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServerTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServerTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServerTypeMaxOrderBy>,
  min?: Maybe<PlatformServerTypeMinOrderBy>,
}

export interface PlatformServerTypeArrRelInsertInput {
  data: Array<PlatformServerTypeInsertInput>,
  on_conflict?: Maybe<PlatformServerTypeOnConflict>,
}

export interface PlatformServerTypeBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServerTypeBoolExp>>>,
  _not?: Maybe<PlatformServerTypeBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServerTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  route?: Maybe<StringComparisonExp>,
  supported_leverages?: Maybe<LeveragesToPlatformServerTypesBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformServerTypeConstraint {
  PlatformServerTypeNameKey = 'platform_server_type_name_key',
  PlatformServerTypePkey = 'platform_server_type_pkey'
}

export interface PlatformServerTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  route?: Maybe<Scalars['String']>,
  supported_leverages?: Maybe<LeveragesToPlatformServerTypesArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerTypeMaxFields {
   __typename?: 'platform_server_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  route?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  route?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerTypeMinFields {
   __typename?: 'platform_server_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  route?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformServerTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  route?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerTypeMutationResponse {
   __typename?: 'platform_server_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServerType>,
}

export interface PlatformServerTypeObjRelInsertInput {
  data: PlatformServerTypeInsertInput,
  on_conflict?: Maybe<PlatformServerTypeOnConflict>,
}

export interface PlatformServerTypeOnConflict {
  constraint: PlatformServerTypeConstraint,
  update_columns: Array<PlatformServerTypeUpdateColumn>,
  where?: Maybe<PlatformServerTypeBoolExp>,
}

export interface PlatformServerTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  route?: Maybe<OrderBy>,
  supported_leverages_aggregate?: Maybe<LeveragesToPlatformServerTypesAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformServerTypePkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformServerTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Route = 'route',
  UpdatedAt = 'updated_at'
}

export interface PlatformServerTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  route?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum PlatformServerTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Route = 'route',
  UpdatedAt = 'updated_at'
}

export enum PlatformServerUpdateColumn {
  AccountRangeEnd = 'account_range_end',
  AccountRangeStart = 'account_range_start',
  CreatedAt = 'created_at',
  EnvironmentId = 'environment_id',
  Id = 'id',
  IsAdmin = 'is_admin',
  IsDeleted = 'is_deleted',
  IsPumping = 'is_pumping',
  LastSeen = 'last_seen',
  Login = 'login',
  Name = 'name',
  NextLogin = 'next_login',
  OrganizationId = 'organization_id',
  Password = 'password',
  RetryStatusId = 'retry_status_id',
  StatusId = 'status_id',
  Timezone = 'timezone',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at',
  Url = 'url'
}

export interface PlatformServerVarPopFields {
   __typename?: 'platform_server_var_pop_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerVarPopOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerVarSampFields {
   __typename?: 'platform_server_var_samp_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerVarSampOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServerVarianceFields {
   __typename?: 'platform_server_variance_fields',
  timezone?: Maybe<Scalars['Float']>,
}

export interface PlatformServerVarianceOrderBy {
  timezone?: Maybe<OrderBy>,
}

export interface PlatformServersToSupportedLeverages {
   __typename?: 'platform_servers_to_supported_leverages',
  created_at: Scalars['timestamptz'],
  is_enabled: Scalars['Boolean'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  supported_leverage: LeveragesToPlatformServerTypes,
  supported_leverage_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  updated_by: Stakeholder,
  updated_by_id: Scalars['String'],
}

export interface PlatformServersToSupportedLeveragesAggregate {
   __typename?: 'platform_servers_to_supported_leverages_aggregate',
  aggregate?: Maybe<PlatformServersToSupportedLeveragesAggregateFields>,
  nodes: Array<PlatformServersToSupportedLeverages>,
}

export interface PlatformServersToSupportedLeveragesAggregateFields {
   __typename?: 'platform_servers_to_supported_leverages_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformServersToSupportedLeveragesMaxFields>,
  min?: Maybe<PlatformServersToSupportedLeveragesMinFields>,
}


export interface PlatformServersToSupportedLeveragesAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformServersToSupportedLeveragesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformServersToSupportedLeveragesMaxOrderBy>,
  min?: Maybe<PlatformServersToSupportedLeveragesMinOrderBy>,
}

export interface PlatformServersToSupportedLeveragesArrRelInsertInput {
  data: Array<PlatformServersToSupportedLeveragesInsertInput>,
  on_conflict?: Maybe<PlatformServersToSupportedLeveragesOnConflict>,
}

export interface PlatformServersToSupportedLeveragesBoolExp {
  _and?: Maybe<Array<Maybe<PlatformServersToSupportedLeveragesBoolExp>>>,
  _not?: Maybe<PlatformServersToSupportedLeveragesBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformServersToSupportedLeveragesBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  is_enabled?: Maybe<BooleanComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  supported_leverage?: Maybe<LeveragesToPlatformServerTypesBoolExp>,
  supported_leverage_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  updated_by?: Maybe<StakeholderBoolExp>,
  updated_by_id?: Maybe<StringComparisonExp>,
}

export enum PlatformServersToSupportedLeveragesConstraint {
  PlatformServersToSupportedLeveragesPkey = 'platform_servers_to_supported_leverages_pkey'
}

export interface PlatformServersToSupportedLeveragesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  supported_leverage?: Maybe<LeveragesToPlatformServerTypesObjRelInsertInput>,
  supported_leverage_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by?: Maybe<StakeholderObjRelInsertInput>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface PlatformServersToSupportedLeveragesMaxFields {
   __typename?: 'platform_servers_to_supported_leverages_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  platform_server_id?: Maybe<Scalars['String']>,
  supported_leverage_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface PlatformServersToSupportedLeveragesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  supported_leverage_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface PlatformServersToSupportedLeveragesMinFields {
   __typename?: 'platform_servers_to_supported_leverages_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  platform_server_id?: Maybe<Scalars['String']>,
  supported_leverage_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export interface PlatformServersToSupportedLeveragesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  supported_leverage_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface PlatformServersToSupportedLeveragesMutationResponse {
   __typename?: 'platform_servers_to_supported_leverages_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformServersToSupportedLeverages>,
}

export interface PlatformServersToSupportedLeveragesObjRelInsertInput {
  data: PlatformServersToSupportedLeveragesInsertInput,
  on_conflict?: Maybe<PlatformServersToSupportedLeveragesOnConflict>,
}

export interface PlatformServersToSupportedLeveragesOnConflict {
  constraint: PlatformServersToSupportedLeveragesConstraint,
  update_columns: Array<PlatformServersToSupportedLeveragesUpdateColumn>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>,
}

export interface PlatformServersToSupportedLeveragesOrderBy {
  created_at?: Maybe<OrderBy>,
  is_enabled?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  supported_leverage?: Maybe<LeveragesToPlatformServerTypesOrderBy>,
  supported_leverage_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by?: Maybe<StakeholderOrderBy>,
  updated_by_id?: Maybe<OrderBy>,
}

export interface PlatformServersToSupportedLeveragesPkColumnsInput {
  platform_server_id: Scalars['String'],
  supported_leverage_id: Scalars['String'],
}

export enum PlatformServersToSupportedLeveragesSelectColumn {
  CreatedAt = 'created_at',
  IsEnabled = 'is_enabled',
  PlatformServerId = 'platform_server_id',
  SupportedLeverageId = 'supported_leverage_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id'
}

export interface PlatformServersToSupportedLeveragesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  is_enabled?: Maybe<Scalars['Boolean']>,
  platform_server_id?: Maybe<Scalars['String']>,
  supported_leverage_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
}

export enum PlatformServersToSupportedLeveragesUpdateColumn {
  CreatedAt = 'created_at',
  IsEnabled = 'is_enabled',
  PlatformServerId = 'platform_server_id',
  SupportedLeverageId = 'supported_leverage_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id'
}

export interface PlatformSymbol {
   __typename?: 'platform_symbol',
  contract_size?: Maybe<Scalars['numeric']>,
  created_at: Scalars['timestamptz'],
  digits?: Maybe<Scalars['Int']>,
  id: Scalars['String'],
  is_deleted: Scalars['Boolean'],
  last_synced?: Maybe<Scalars['timestamptz']>,
  long_swaps: Scalars['numeric'],
  margin_currency: Scalars['String'],
  name: Scalars['String'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  short_swaps: Scalars['numeric'],
  swap_mode: SwapMode,
  swap_mode_id: Scalars['String'],
  symbol?: Maybe<Symbol>,
  symbol_extension?: Maybe<SymbolExtension>,
  symbol_extension_id?: Maybe<Scalars['String']>,
  symbol_type: SymbolType,
  symbol_type_id: Scalars['String'],
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
  updated_at: Scalars['timestamptz'],
}

export interface PlatformSymbolAggregate {
   __typename?: 'platform_symbol_aggregate',
  aggregate?: Maybe<PlatformSymbolAggregateFields>,
  nodes: Array<PlatformSymbol>,
}

export interface PlatformSymbolAggregateFields {
   __typename?: 'platform_symbol_aggregate_fields',
  avg?: Maybe<PlatformSymbolAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<PlatformSymbolMaxFields>,
  min?: Maybe<PlatformSymbolMinFields>,
  stddev?: Maybe<PlatformSymbolStddevFields>,
  stddev_pop?: Maybe<PlatformSymbolStddevPopFields>,
  stddev_samp?: Maybe<PlatformSymbolStddevSampFields>,
  sum?: Maybe<PlatformSymbolSumFields>,
  var_pop?: Maybe<PlatformSymbolVarPopFields>,
  var_samp?: Maybe<PlatformSymbolVarSampFields>,
  variance?: Maybe<PlatformSymbolVarianceFields>,
}


export interface PlatformSymbolAggregateFieldsCountArgs {
  columns?: Maybe<Array<PlatformSymbolSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface PlatformSymbolAggregateOrderBy {
  avg?: Maybe<PlatformSymbolAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<PlatformSymbolMaxOrderBy>,
  min?: Maybe<PlatformSymbolMinOrderBy>,
  stddev?: Maybe<PlatformSymbolStddevOrderBy>,
  stddev_pop?: Maybe<PlatformSymbolStddevPopOrderBy>,
  stddev_samp?: Maybe<PlatformSymbolStddevSampOrderBy>,
  sum?: Maybe<PlatformSymbolSumOrderBy>,
  var_pop?: Maybe<PlatformSymbolVarPopOrderBy>,
  var_samp?: Maybe<PlatformSymbolVarSampOrderBy>,
  variance?: Maybe<PlatformSymbolVarianceOrderBy>,
}

export interface PlatformSymbolArrRelInsertInput {
  data: Array<PlatformSymbolInsertInput>,
  on_conflict?: Maybe<PlatformSymbolOnConflict>,
}

export interface PlatformSymbolAvgFields {
   __typename?: 'platform_symbol_avg_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolAvgOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolBoolExp {
  _and?: Maybe<Array<Maybe<PlatformSymbolBoolExp>>>,
  _not?: Maybe<PlatformSymbolBoolExp>,
  _or?: Maybe<Array<Maybe<PlatformSymbolBoolExp>>>,
  contract_size?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  digits?: Maybe<IntComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  last_synced?: Maybe<TimestamptzComparisonExp>,
  long_swaps?: Maybe<NumericComparisonExp>,
  margin_currency?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  short_swaps?: Maybe<NumericComparisonExp>,
  swap_mode?: Maybe<SwapModeBoolExp>,
  swap_mode_id?: Maybe<StringComparisonExp>,
  symbol?: Maybe<SymbolBoolExp>,
  symbol_extension?: Maybe<SymbolExtensionBoolExp>,
  symbol_extension_id?: Maybe<StringComparisonExp>,
  symbol_type?: Maybe<SymbolTypeBoolExp>,
  symbol_type_id?: Maybe<StringComparisonExp>,
  tick_price?: Maybe<NumericComparisonExp>,
  tick_size?: Maybe<NumericComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum PlatformSymbolConstraint {
  PlatformSymbolNamePlatformServerIdKey = 'platform_symbol_name_platform_server_id_key',
  PlatformSymbolPkey = 'platform_symbol_pkey'
}

export interface PlatformSymbolIncInput {
  contract_size?: Maybe<Scalars['numeric']>,
  digits?: Maybe<Scalars['Int']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
}

export interface PlatformSymbolInsertInput {
  contract_size?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  digits?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  last_synced?: Maybe<Scalars['timestamptz']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  swap_mode?: Maybe<SwapModeObjRelInsertInput>,
  swap_mode_id?: Maybe<Scalars['String']>,
  symbol?: Maybe<SymbolObjRelInsertInput>,
  symbol_extension?: Maybe<SymbolExtensionObjRelInsertInput>,
  symbol_extension_id?: Maybe<Scalars['String']>,
  symbol_type?: Maybe<SymbolTypeObjRelInsertInput>,
  symbol_type_id?: Maybe<Scalars['String']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformSymbolMaxFields {
   __typename?: 'platform_symbol_max_fields',
  contract_size?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  digits?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  last_synced?: Maybe<Scalars['timestamptz']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  swap_mode_id?: Maybe<Scalars['String']>,
  symbol_extension_id?: Maybe<Scalars['String']>,
  symbol_type_id?: Maybe<Scalars['String']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformSymbolMaxOrderBy {
  contract_size?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  last_synced?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  swap_mode_id?: Maybe<OrderBy>,
  symbol_extension_id?: Maybe<OrderBy>,
  symbol_type_id?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformSymbolMinFields {
   __typename?: 'platform_symbol_min_fields',
  contract_size?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  digits?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  last_synced?: Maybe<Scalars['timestamptz']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  swap_mode_id?: Maybe<Scalars['String']>,
  symbol_extension_id?: Maybe<Scalars['String']>,
  symbol_type_id?: Maybe<Scalars['String']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformSymbolMinOrderBy {
  contract_size?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  last_synced?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  swap_mode_id?: Maybe<OrderBy>,
  symbol_extension_id?: Maybe<OrderBy>,
  symbol_type_id?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformSymbolMutationResponse {
   __typename?: 'platform_symbol_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<PlatformSymbol>,
}

export interface PlatformSymbolObjRelInsertInput {
  data: PlatformSymbolInsertInput,
  on_conflict?: Maybe<PlatformSymbolOnConflict>,
}

export interface PlatformSymbolOnConflict {
  constraint: PlatformSymbolConstraint,
  update_columns: Array<PlatformSymbolUpdateColumn>,
  where?: Maybe<PlatformSymbolBoolExp>,
}

export interface PlatformSymbolOrderBy {
  contract_size?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  last_synced?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  swap_mode?: Maybe<SwapModeOrderBy>,
  swap_mode_id?: Maybe<OrderBy>,
  symbol?: Maybe<SymbolOrderBy>,
  symbol_extension?: Maybe<SymbolExtensionOrderBy>,
  symbol_extension_id?: Maybe<OrderBy>,
  symbol_type?: Maybe<SymbolTypeOrderBy>,
  symbol_type_id?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface PlatformSymbolPkColumnsInput {
  id: Scalars['String'],
}

export enum PlatformSymbolSelectColumn {
  ContractSize = 'contract_size',
  CreatedAt = 'created_at',
  Digits = 'digits',
  Id = 'id',
  IsDeleted = 'is_deleted',
  LastSynced = 'last_synced',
  LongSwaps = 'long_swaps',
  MarginCurrency = 'margin_currency',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  ShortSwaps = 'short_swaps',
  SwapModeId = 'swap_mode_id',
  SymbolExtensionId = 'symbol_extension_id',
  SymbolTypeId = 'symbol_type_id',
  TickPrice = 'tick_price',
  TickSize = 'tick_size',
  UpdatedAt = 'updated_at'
}

export interface PlatformSymbolSetInput {
  contract_size?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  digits?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  last_synced?: Maybe<Scalars['timestamptz']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  swap_mode_id?: Maybe<Scalars['String']>,
  symbol_extension_id?: Maybe<Scalars['String']>,
  symbol_type_id?: Maybe<Scalars['String']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface PlatformSymbolStddevFields {
   __typename?: 'platform_symbol_stddev_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolStddevOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolStddevPopFields {
   __typename?: 'platform_symbol_stddev_pop_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolStddevPopOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolStddevSampFields {
   __typename?: 'platform_symbol_stddev_samp_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolStddevSampOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolSumFields {
   __typename?: 'platform_symbol_sum_fields',
  contract_size?: Maybe<Scalars['numeric']>,
  digits?: Maybe<Scalars['Int']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  tick_price?: Maybe<Scalars['numeric']>,
  tick_size?: Maybe<Scalars['numeric']>,
}

export interface PlatformSymbolSumOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export enum PlatformSymbolUpdateColumn {
  ContractSize = 'contract_size',
  CreatedAt = 'created_at',
  Digits = 'digits',
  Id = 'id',
  IsDeleted = 'is_deleted',
  LastSynced = 'last_synced',
  LongSwaps = 'long_swaps',
  MarginCurrency = 'margin_currency',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  ShortSwaps = 'short_swaps',
  SwapModeId = 'swap_mode_id',
  SymbolExtensionId = 'symbol_extension_id',
  SymbolTypeId = 'symbol_type_id',
  TickPrice = 'tick_price',
  TickSize = 'tick_size',
  UpdatedAt = 'updated_at'
}

export interface PlatformSymbolVarPopFields {
   __typename?: 'platform_symbol_var_pop_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolVarPopOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolVarSampFields {
   __typename?: 'platform_symbol_var_samp_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolVarSampOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface PlatformSymbolVarianceFields {
   __typename?: 'platform_symbol_variance_fields',
  contract_size?: Maybe<Scalars['Float']>,
  digits?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
  tick_price?: Maybe<Scalars['Float']>,
  tick_size?: Maybe<Scalars['Float']>,
}

export interface PlatformSymbolVarianceOrderBy {
  contract_size?: Maybe<OrderBy>,
  digits?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  tick_price?: Maybe<OrderBy>,
  tick_size?: Maybe<OrderBy>,
}

export interface Profile {
   __typename?: 'profile',
  accounts: Array<Account>,
  accounts_aggregate: AccountAggregate,
  activity: ProfileActivity,
  activity_id: Scalars['String'],
  category: ProfileCategory,
  category_id: Scalars['String'],
  clients: Array<Profile>,
  clients_aggregate: ProfileAggregate,
  created_at: Scalars['timestamptz'],
  entry?: Maybe<ProfileEntryType>,
  entry_id?: Maybe<Scalars['String']>,
  flags?: Maybe<ProfileFlags>,
  flags_id?: Maybe<Scalars['String']>,
  grid_view?: Maybe<ProfileGridView>,
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  master_partner_client_permission: MasterPartnerClientPermission,
  master_partner_client_permission_id: Scalars['String'],
  master_partner_lead_permission: MasterPartnerLeadPermission,
  master_partner_lead_permission_id: Scalars['String'],
  master_partner_partner_permission: MasterPartnerPartnerPermission,
  master_partner_partner_permission_id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  notes: Array<ProfileNote>,
  notes_aggregate: ProfileNoteAggregate,
  number: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  partner?: Maybe<Profile>,
  partner_id?: Maybe<Scalars['String']>,
  representatives: Array<RepresentativesToProfiles>,
  representatives_aggregate: RepresentativesToProfilesAggregate,
  risk?: Maybe<ProfileRisk>,
  risk_id?: Maybe<Scalars['String']>,
  stakes: Array<Stake>,
  stakes_aggregate: StakeAggregate,
  status: ProfileStatus,
  status_id: Scalars['String'],
  tags: Array<ProfilesToTags>,
  tags_aggregate: ProfilesToTagsAggregate,
  type: ProfileType,
  type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  workflows: Array<WorkflowsToProfiles>,
  workflows_aggregate: WorkflowsToProfilesAggregate,
}


export interface ProfileAccountsArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface ProfileAccountsAggregateArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface ProfileClientsArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface ProfileClientsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface ProfileNotesArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface ProfileNotesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface ProfileRepresentativesArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface ProfileRepresentativesAggregateArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface ProfileStakesArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface ProfileStakesAggregateArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface ProfileTagsArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface ProfileTagsAggregateArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface ProfileWorkflowsArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface ProfileWorkflowsAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}

export interface ProfileActivity {
   __typename?: 'profile_activity',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileActivityAggregate {
   __typename?: 'profile_activity_aggregate',
  aggregate?: Maybe<ProfileActivityAggregateFields>,
  nodes: Array<ProfileActivity>,
}

export interface ProfileActivityAggregateFields {
   __typename?: 'profile_activity_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileActivityMaxFields>,
  min?: Maybe<ProfileActivityMinFields>,
}


export interface ProfileActivityAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileActivitySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileActivityAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileActivityMaxOrderBy>,
  min?: Maybe<ProfileActivityMinOrderBy>,
}

export interface ProfileActivityArrRelInsertInput {
  data: Array<ProfileActivityInsertInput>,
  on_conflict?: Maybe<ProfileActivityOnConflict>,
}

export interface ProfileActivityBoolExp {
  _and?: Maybe<Array<Maybe<ProfileActivityBoolExp>>>,
  _not?: Maybe<ProfileActivityBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileActivityBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileActivityConstraint {
  ProfileActivityNameKey = 'profile_activity_name_key',
  ProfileActivityPkey = 'profile_activity_pkey'
}

export interface ProfileActivityInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileActivityMaxFields {
   __typename?: 'profile_activity_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileActivityMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileActivityMinFields {
   __typename?: 'profile_activity_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileActivityMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileActivityMutationResponse {
   __typename?: 'profile_activity_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileActivity>,
}

export interface ProfileActivityObjRelInsertInput {
  data: ProfileActivityInsertInput,
  on_conflict?: Maybe<ProfileActivityOnConflict>,
}

export interface ProfileActivityOnConflict {
  constraint: ProfileActivityConstraint,
  update_columns: Array<ProfileActivityUpdateColumn>,
  where?: Maybe<ProfileActivityBoolExp>,
}

export interface ProfileActivityOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileActivityPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileActivitySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileActivitySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileActivityUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileAggregate {
   __typename?: 'profile_aggregate',
  aggregate?: Maybe<ProfileAggregateFields>,
  nodes: Array<Profile>,
}

export interface ProfileAggregateFields {
   __typename?: 'profile_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileMaxFields>,
  min?: Maybe<ProfileMinFields>,
}


export interface ProfileAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileMaxOrderBy>,
  min?: Maybe<ProfileMinOrderBy>,
}

export interface ProfileArrRelInsertInput {
  data: Array<ProfileInsertInput>,
  on_conflict?: Maybe<ProfileOnConflict>,
}

export interface ProfileBoolExp {
  _and?: Maybe<Array<Maybe<ProfileBoolExp>>>,
  _not?: Maybe<ProfileBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileBoolExp>>>,
  accounts?: Maybe<AccountBoolExp>,
  activity?: Maybe<ProfileActivityBoolExp>,
  activity_id?: Maybe<StringComparisonExp>,
  category?: Maybe<ProfileCategoryBoolExp>,
  category_id?: Maybe<StringComparisonExp>,
  clients?: Maybe<ProfileBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  entry?: Maybe<ProfileEntryTypeBoolExp>,
  entry_id?: Maybe<StringComparisonExp>,
  flags?: Maybe<ProfileFlagsBoolExp>,
  flags_id?: Maybe<StringComparisonExp>,
  grid_view?: Maybe<ProfileGridViewBoolExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionBoolExp>,
  master_partner_client_permission_id?: Maybe<StringComparisonExp>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionBoolExp>,
  master_partner_lead_permission_id?: Maybe<StringComparisonExp>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionBoolExp>,
  master_partner_partner_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  notes?: Maybe<ProfileNoteBoolExp>,
  number?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner?: Maybe<ProfileBoolExp>,
  partner_id?: Maybe<StringComparisonExp>,
  representatives?: Maybe<RepresentativesToProfilesBoolExp>,
  risk?: Maybe<ProfileRiskBoolExp>,
  risk_id?: Maybe<StringComparisonExp>,
  stakes?: Maybe<StakeBoolExp>,
  status?: Maybe<ProfileStatusBoolExp>,
  status_id?: Maybe<StringComparisonExp>,
  tags?: Maybe<ProfilesToTagsBoolExp>,
  type?: Maybe<ProfileTypeBoolExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  workflows?: Maybe<WorkflowsToProfilesBoolExp>,
}

export interface ProfileCategory {
   __typename?: 'profile_category',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_deleted: Scalars['Boolean'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileCategoryAggregate {
   __typename?: 'profile_category_aggregate',
  aggregate?: Maybe<ProfileCategoryAggregateFields>,
  nodes: Array<ProfileCategory>,
}

export interface ProfileCategoryAggregateFields {
   __typename?: 'profile_category_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileCategoryMaxFields>,
  min?: Maybe<ProfileCategoryMinFields>,
}


export interface ProfileCategoryAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileCategorySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileCategoryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileCategoryMaxOrderBy>,
  min?: Maybe<ProfileCategoryMinOrderBy>,
}

export interface ProfileCategoryArrRelInsertInput {
  data: Array<ProfileCategoryInsertInput>,
  on_conflict?: Maybe<ProfileCategoryOnConflict>,
}

export interface ProfileCategoryBoolExp {
  _and?: Maybe<Array<Maybe<ProfileCategoryBoolExp>>>,
  _not?: Maybe<ProfileCategoryBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileCategoryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileCategoryConstraint {
  ProfileCategoryNameOrganizationIdKey = 'profile_category_name_organization_id_key',
  ProfileCategoryPkey = 'profile_category_pkey'
}

export interface ProfileCategoryInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileCategoryMaxFields {
   __typename?: 'profile_category_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileCategoryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileCategoryMinFields {
   __typename?: 'profile_category_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileCategoryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileCategoryMutationResponse {
   __typename?: 'profile_category_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileCategory>,
}

export interface ProfileCategoryObjRelInsertInput {
  data: ProfileCategoryInsertInput,
  on_conflict?: Maybe<ProfileCategoryOnConflict>,
}

export interface ProfileCategoryOnConflict {
  constraint: ProfileCategoryConstraint,
  update_columns: Array<ProfileCategoryUpdateColumn>,
  where?: Maybe<ProfileCategoryBoolExp>,
}

export interface ProfileCategoryOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileCategoryPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileCategorySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeleted = 'is_deleted',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileCategorySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileCategoryUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeleted = 'is_deleted',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export enum ProfileConstraint {
  ProfileFlagsIdKey = 'profile_flags_id_key',
  ProfilePkey = 'profile_pkey'
}

export interface ProfileEntryType {
   __typename?: 'profile_entry_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileEntryTypeAggregate {
   __typename?: 'profile_entry_type_aggregate',
  aggregate?: Maybe<ProfileEntryTypeAggregateFields>,
  nodes: Array<ProfileEntryType>,
}

export interface ProfileEntryTypeAggregateFields {
   __typename?: 'profile_entry_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileEntryTypeMaxFields>,
  min?: Maybe<ProfileEntryTypeMinFields>,
}


export interface ProfileEntryTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileEntryTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileEntryTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileEntryTypeMaxOrderBy>,
  min?: Maybe<ProfileEntryTypeMinOrderBy>,
}

export interface ProfileEntryTypeArrRelInsertInput {
  data: Array<ProfileEntryTypeInsertInput>,
  on_conflict?: Maybe<ProfileEntryTypeOnConflict>,
}

export interface ProfileEntryTypeBoolExp {
  _and?: Maybe<Array<Maybe<ProfileEntryTypeBoolExp>>>,
  _not?: Maybe<ProfileEntryTypeBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileEntryTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileEntryTypeConstraint {
  ProfileEntryTypeNameKey = 'profile_entry_type_name_key',
  ProfileEntryTypePkey = 'profile_entry_type_pkey'
}

export interface ProfileEntryTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileEntryTypeMaxFields {
   __typename?: 'profile_entry_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileEntryTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileEntryTypeMinFields {
   __typename?: 'profile_entry_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileEntryTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileEntryTypeMutationResponse {
   __typename?: 'profile_entry_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileEntryType>,
}

export interface ProfileEntryTypeObjRelInsertInput {
  data: ProfileEntryTypeInsertInput,
  on_conflict?: Maybe<ProfileEntryTypeOnConflict>,
}

export interface ProfileEntryTypeOnConflict {
  constraint: ProfileEntryTypeConstraint,
  update_columns: Array<ProfileEntryTypeUpdateColumn>,
  where?: Maybe<ProfileEntryTypeBoolExp>,
}

export interface ProfileEntryTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileEntryTypePkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileEntryTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileEntryTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileEntryTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileFlags {
   __typename?: 'profile_flags',
  blue: Scalars['Boolean'],
  green: Scalars['Boolean'],
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  profile?: Maybe<Profile>,
  red: Scalars['Boolean'],
  yellow: Scalars['Boolean'],
}

export interface ProfileFlagsAggregate {
   __typename?: 'profile_flags_aggregate',
  aggregate?: Maybe<ProfileFlagsAggregateFields>,
  nodes: Array<ProfileFlags>,
}

export interface ProfileFlagsAggregateFields {
   __typename?: 'profile_flags_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileFlagsMaxFields>,
  min?: Maybe<ProfileFlagsMinFields>,
}


export interface ProfileFlagsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileFlagsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileFlagsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileFlagsMaxOrderBy>,
  min?: Maybe<ProfileFlagsMinOrderBy>,
}

export interface ProfileFlagsArrRelInsertInput {
  data: Array<ProfileFlagsInsertInput>,
  on_conflict?: Maybe<ProfileFlagsOnConflict>,
}

export interface ProfileFlagsBoolExp {
  _and?: Maybe<Array<Maybe<ProfileFlagsBoolExp>>>,
  _not?: Maybe<ProfileFlagsBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileFlagsBoolExp>>>,
  blue?: Maybe<BooleanComparisonExp>,
  green?: Maybe<BooleanComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  red?: Maybe<BooleanComparisonExp>,
  yellow?: Maybe<BooleanComparisonExp>,
}

export enum ProfileFlagsConstraint {
  ProfileFlagsPkey = 'profile_flags_pkey'
}

export interface ProfileFlagsInsertInput {
  blue?: Maybe<Scalars['Boolean']>,
  green?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  red?: Maybe<Scalars['Boolean']>,
  yellow?: Maybe<Scalars['Boolean']>,
}

export interface ProfileFlagsMaxFields {
   __typename?: 'profile_flags_max_fields',
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
}

export interface ProfileFlagsMaxOrderBy {
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
}

export interface ProfileFlagsMinFields {
   __typename?: 'profile_flags_min_fields',
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
}

export interface ProfileFlagsMinOrderBy {
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
}

export interface ProfileFlagsMutationResponse {
   __typename?: 'profile_flags_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileFlags>,
}

export interface ProfileFlagsObjRelInsertInput {
  data: ProfileFlagsInsertInput,
  on_conflict?: Maybe<ProfileFlagsOnConflict>,
}

export interface ProfileFlagsOnConflict {
  constraint: ProfileFlagsConstraint,
  update_columns: Array<ProfileFlagsUpdateColumn>,
  where?: Maybe<ProfileFlagsBoolExp>,
}

export interface ProfileFlagsOrderBy {
  blue?: Maybe<OrderBy>,
  green?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  red?: Maybe<OrderBy>,
  yellow?: Maybe<OrderBy>,
}

export interface ProfileFlagsPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileFlagsSelectColumn {
  Blue = 'blue',
  Green = 'green',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Red = 'red',
  Yellow = 'yellow'
}

export interface ProfileFlagsSetInput {
  blue?: Maybe<Scalars['Boolean']>,
  green?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  red?: Maybe<Scalars['Boolean']>,
  yellow?: Maybe<Scalars['Boolean']>,
}

export enum ProfileFlagsUpdateColumn {
  Blue = 'blue',
  Green = 'green',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Red = 'red',
  Yellow = 'yellow'
}

export interface ProfileGridView {
   __typename?: 'profile_grid_view',
  category?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  partner_name?: Maybe<Scalars['String']>,
  profile?: Maybe<Profile>,
  registered_date?: Maybe<Scalars['timestamptz']>,
  representatives: Array<RepresentativesToProfiles>,
  representatives_aggregate: RepresentativesToProfilesAggregate,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}


export interface ProfileGridViewRepresentativesArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface ProfileGridViewRepresentativesAggregateArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}

export interface ProfileGridViewAggregate {
   __typename?: 'profile_grid_view_aggregate',
  aggregate?: Maybe<ProfileGridViewAggregateFields>,
  nodes: Array<ProfileGridView>,
}

export interface ProfileGridViewAggregateFields {
   __typename?: 'profile_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileGridViewMaxFields>,
  min?: Maybe<ProfileGridViewMinFields>,
}


export interface ProfileGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileGridViewMaxOrderBy>,
  min?: Maybe<ProfileGridViewMinOrderBy>,
}

export interface ProfileGridViewBoolExp {
  _and?: Maybe<Array<Maybe<ProfileGridViewBoolExp>>>,
  _not?: Maybe<ProfileGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileGridViewBoolExp>>>,
  category?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  partner_id?: Maybe<StringComparisonExp>,
  partner_name?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  registered_date?: Maybe<TimestamptzComparisonExp>,
  representatives?: Maybe<RepresentativesToProfilesBoolExp>,
  status?: Maybe<StringComparisonExp>,
  type?: Maybe<StringComparisonExp>,
}

export interface ProfileGridViewMaxFields {
   __typename?: 'profile_grid_view_max_fields',
  category?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  partner_name?: Maybe<Scalars['String']>,
  registered_date?: Maybe<Scalars['timestamptz']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface ProfileGridViewMaxOrderBy {
  category?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  partner_name?: Maybe<OrderBy>,
  registered_date?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface ProfileGridViewMinFields {
   __typename?: 'profile_grid_view_min_fields',
  category?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  partner_name?: Maybe<Scalars['String']>,
  registered_date?: Maybe<Scalars['timestamptz']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface ProfileGridViewMinOrderBy {
  category?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  partner_name?: Maybe<OrderBy>,
  registered_date?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface ProfileGridViewOrderBy {
  category?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  partner_name?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  registered_date?: Maybe<OrderBy>,
  representatives_aggregate?: Maybe<RepresentativesToProfilesAggregateOrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export enum ProfileGridViewSelectColumn {
  Category = 'category',
  Id = 'id',
  Name = 'name',
  Number = 'number',
  OrganizationId = 'organization_id',
  PartnerId = 'partner_id',
  PartnerName = 'partner_name',
  RegisteredDate = 'registered_date',
  Status = 'status',
  Type = 'type'
}

export interface ProfileInsertInput {
  accounts?: Maybe<AccountArrRelInsertInput>,
  activity?: Maybe<ProfileActivityObjRelInsertInput>,
  activity_id?: Maybe<Scalars['String']>,
  category?: Maybe<ProfileCategoryObjRelInsertInput>,
  category_id?: Maybe<Scalars['String']>,
  clients?: Maybe<ProfileArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry?: Maybe<ProfileEntryTypeObjRelInsertInput>,
  entry_id?: Maybe<Scalars['String']>,
  flags?: Maybe<ProfileFlagsObjRelInsertInput>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionObjRelInsertInput>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionObjRelInsertInput>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionObjRelInsertInput>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes?: Maybe<ProfileNoteArrRelInsertInput>,
  number?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  partner?: Maybe<ProfileObjRelInsertInput>,
  partner_id?: Maybe<Scalars['String']>,
  representatives?: Maybe<RepresentativesToProfilesArrRelInsertInput>,
  risk?: Maybe<ProfileRiskObjRelInsertInput>,
  risk_id?: Maybe<Scalars['String']>,
  stakes?: Maybe<StakeArrRelInsertInput>,
  status?: Maybe<ProfileStatusObjRelInsertInput>,
  status_id?: Maybe<Scalars['String']>,
  tags?: Maybe<ProfilesToTagsArrRelInsertInput>,
  type?: Maybe<ProfileTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  workflows?: Maybe<WorkflowsToProfilesArrRelInsertInput>,
}

export interface ProfileMaxFields {
   __typename?: 'profile_max_fields',
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileMaxOrderBy {
  activity_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  risk_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileMinFields {
   __typename?: 'profile_min_fields',
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileMinOrderBy {
  activity_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner_id?: Maybe<OrderBy>,
  risk_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileMutationResponse {
   __typename?: 'profile_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Profile>,
}

export interface ProfileNote {
   __typename?: 'profile_note',
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  deleted_by?: Maybe<Stakeholder>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  profile: Profile,
  profile_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  versions: Array<ProfileNoteVersion>,
  versions_aggregate: ProfileNoteVersionAggregate,
}


export interface ProfileNoteVersionsArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}


export interface ProfileNoteVersionsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}

export interface ProfileNoteAggregate {
   __typename?: 'profile_note_aggregate',
  aggregate?: Maybe<ProfileNoteAggregateFields>,
  nodes: Array<ProfileNote>,
}

export interface ProfileNoteAggregateFields {
   __typename?: 'profile_note_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileNoteMaxFields>,
  min?: Maybe<ProfileNoteMinFields>,
}


export interface ProfileNoteAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileNoteSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileNoteAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileNoteMaxOrderBy>,
  min?: Maybe<ProfileNoteMinOrderBy>,
}

export interface ProfileNoteArrRelInsertInput {
  data: Array<ProfileNoteInsertInput>,
  on_conflict?: Maybe<ProfileNoteOnConflict>,
}

export interface ProfileNoteBoolExp {
  _and?: Maybe<Array<Maybe<ProfileNoteBoolExp>>>,
  _not?: Maybe<ProfileNoteBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileNoteBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  deleted_by?: Maybe<StakeholderBoolExp>,
  deleted_by_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  versions?: Maybe<ProfileNoteVersionBoolExp>,
}

export enum ProfileNoteConstraint {
  ProfileNotePkey = 'profile_note_pkey'
}

export interface ProfileNoteInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by?: Maybe<StakeholderObjRelInsertInput>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  versions?: Maybe<ProfileNoteVersionArrRelInsertInput>,
}

export interface ProfileNoteMaxFields {
   __typename?: 'profile_note_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteMinFields {
   __typename?: 'profile_note_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteMinOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteMutationResponse {
   __typename?: 'profile_note_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileNote>,
}

export interface ProfileNoteObjRelInsertInput {
  data: ProfileNoteInsertInput,
  on_conflict?: Maybe<ProfileNoteOnConflict>,
}

export interface ProfileNoteOnConflict {
  constraint: ProfileNoteConstraint,
  update_columns: Array<ProfileNoteUpdateColumn>,
  where?: Maybe<ProfileNoteBoolExp>,
}

export interface ProfileNoteOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  deleted_by?: Maybe<StakeholderOrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  versions_aggregate?: Maybe<ProfileNoteVersionAggregateOrderBy>,
}

export interface ProfileNotePkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileNoteSelectColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  DeletedById = 'deleted_by_id',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileNoteUpdateColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  DeletedById = 'deleted_by_id',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersion {
   __typename?: 'profile_note_version',
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  id: Scalars['String'],
  mentions: Array<ProfileNoteVersionsStakeholderMentions>,
  mentions_aggregate: ProfileNoteVersionsStakeholderMentionsAggregate,
  message: Scalars['String'],
  note: ProfileNote,
  note_id: Scalars['String'],
  relevant_parties: Array<ProfileNoteVersionsStakeholderRelevantParties>,
  relevant_parties_aggregate: ProfileNoteVersionsStakeholderRelevantPartiesAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface ProfileNoteVersionMentionsArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface ProfileNoteVersionMentionsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface ProfileNoteVersionRelevantPartiesArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}


export interface ProfileNoteVersionRelevantPartiesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}

export interface ProfileNoteVersionAggregate {
   __typename?: 'profile_note_version_aggregate',
  aggregate?: Maybe<ProfileNoteVersionAggregateFields>,
  nodes: Array<ProfileNoteVersion>,
}

export interface ProfileNoteVersionAggregateFields {
   __typename?: 'profile_note_version_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileNoteVersionMaxFields>,
  min?: Maybe<ProfileNoteVersionMinFields>,
}


export interface ProfileNoteVersionAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileNoteVersionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileNoteVersionMaxOrderBy>,
  min?: Maybe<ProfileNoteVersionMinOrderBy>,
}

export interface ProfileNoteVersionArrRelInsertInput {
  data: Array<ProfileNoteVersionInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionOnConflict>,
}

export interface ProfileNoteVersionBoolExp {
  _and?: Maybe<Array<Maybe<ProfileNoteVersionBoolExp>>>,
  _not?: Maybe<ProfileNoteVersionBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileNoteVersionBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  mentions?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>,
  message?: Maybe<StringComparisonExp>,
  note?: Maybe<ProfileNoteBoolExp>,
  note_id?: Maybe<StringComparisonExp>,
  relevant_parties?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileNoteVersionConstraint {
  ProfileNoteVersionPkey = 'profile_note_version_pkey'
}

export interface ProfileNoteVersionInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  mentions?: Maybe<ProfileNoteVersionsStakeholderMentionsArrRelInsertInput>,
  message?: Maybe<Scalars['String']>,
  note?: Maybe<ProfileNoteObjRelInsertInput>,
  note_id?: Maybe<Scalars['String']>,
  relevant_parties?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionMaxFields {
   __typename?: 'profile_note_version_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  message?: Maybe<OrderBy>,
  note_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionMinFields {
   __typename?: 'profile_note_version_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  message?: Maybe<OrderBy>,
  note_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionMutationResponse {
   __typename?: 'profile_note_version_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileNoteVersion>,
}

export interface ProfileNoteVersionObjRelInsertInput {
  data: ProfileNoteVersionInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionOnConflict>,
}

export interface ProfileNoteVersionOnConflict {
  constraint: ProfileNoteVersionConstraint,
  update_columns: Array<ProfileNoteVersionUpdateColumn>,
  where?: Maybe<ProfileNoteVersionBoolExp>,
}

export interface ProfileNoteVersionOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  mentions_aggregate?: Maybe<ProfileNoteVersionsStakeholderMentionsAggregateOrderBy>,
  message?: Maybe<OrderBy>,
  note?: Maybe<ProfileNoteOrderBy>,
  note_id?: Maybe<OrderBy>,
  relevant_parties_aggregate?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileNoteVersionSelectColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  Message = 'message',
  NoteId = 'note_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  note_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileNoteVersionUpdateColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  Message = 'message',
  NoteId = 'note_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersionsStakeholderMentions {
   __typename?: 'profile_note_versions_stakeholder_mentions',
  created_at: Scalars['timestamptz'],
  profile_note_version_id: Scalars['String'],
  stakeholder: Stakeholder,
  stakeholder_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileNoteVersionsStakeholderMentionsAggregate {
   __typename?: 'profile_note_versions_stakeholder_mentions_aggregate',
  aggregate?: Maybe<ProfileNoteVersionsStakeholderMentionsAggregateFields>,
  nodes: Array<ProfileNoteVersionsStakeholderMentions>,
}

export interface ProfileNoteVersionsStakeholderMentionsAggregateFields {
   __typename?: 'profile_note_versions_stakeholder_mentions_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileNoteVersionsStakeholderMentionsMaxFields>,
  min?: Maybe<ProfileNoteVersionsStakeholderMentionsMinFields>,
}


export interface ProfileNoteVersionsStakeholderMentionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileNoteVersionsStakeholderMentionsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileNoteVersionsStakeholderMentionsMaxOrderBy>,
  min?: Maybe<ProfileNoteVersionsStakeholderMentionsMinOrderBy>,
}

export interface ProfileNoteVersionsStakeholderMentionsArrRelInsertInput {
  data: Array<ProfileNoteVersionsStakeholderMentionsInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderMentionsOnConflict>,
}

export interface ProfileNoteVersionsStakeholderMentionsBoolExp {
  _and?: Maybe<Array<Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>>>,
  _not?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  profile_note_version_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileNoteVersionsStakeholderMentionsConstraint {
  ProfileNoteVersionMentionPkey = 'profile_note_version_mention_pkey'
}

export interface ProfileNoteVersionsStakeholderMentionsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderMentionsMaxFields {
   __typename?: 'profile_note_versions_stakeholder_mentions_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderMentionsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderMentionsMinFields {
   __typename?: 'profile_note_versions_stakeholder_mentions_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderMentionsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderMentionsMutationResponse {
   __typename?: 'profile_note_versions_stakeholder_mentions_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileNoteVersionsStakeholderMentions>,
}

export interface ProfileNoteVersionsStakeholderMentionsObjRelInsertInput {
  data: ProfileNoteVersionsStakeholderMentionsInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderMentionsOnConflict>,
}

export interface ProfileNoteVersionsStakeholderMentionsOnConflict {
  constraint: ProfileNoteVersionsStakeholderMentionsConstraint,
  update_columns: Array<ProfileNoteVersionsStakeholderMentionsUpdateColumn>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>,
}

export interface ProfileNoteVersionsStakeholderMentionsOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderMentionsPkColumnsInput {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String'],
}

export enum ProfileNoteVersionsStakeholderMentionsSelectColumn {
  CreatedAt = 'created_at',
  ProfileNoteVersionId = 'profile_note_version_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersionsStakeholderMentionsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileNoteVersionsStakeholderMentionsUpdateColumn {
  CreatedAt = 'created_at',
  ProfileNoteVersionId = 'profile_note_version_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersionsStakeholderRelevantParties {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties',
  created_at: Scalars['timestamptz'],
  profile_note_version_id: Scalars['String'],
  stakeholder: Stakeholder,
  stakeholder_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesAggregate {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties_aggregate',
  aggregate?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesAggregateFields>,
  nodes: Array<ProfileNoteVersionsStakeholderRelevantParties>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesAggregateFields {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMaxFields>,
  min?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMinFields>,
}


export interface ProfileNoteVersionsStakeholderRelevantPartiesAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMaxOrderBy>,
  min?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesMinOrderBy>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesArrRelInsertInput {
  data: Array<ProfileNoteVersionsStakeholderRelevantPartiesInsertInput>,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesOnConflict>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesBoolExp {
  _and?: Maybe<Array<Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>>>,
  _not?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  profile_note_version_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileNoteVersionsStakeholderRelevantPartiesConstraint {
  ProfileNoteVersionRelevantPartyPkey = 'profile_note_version_relevant_party_pkey'
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesMaxFields {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesMinFields {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesMutationResponse {
   __typename?: 'profile_note_versions_stakeholder_relevant_parties_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileNoteVersionsStakeholderRelevantParties>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesObjRelInsertInput {
  data: ProfileNoteVersionsStakeholderRelevantPartiesInsertInput,
  on_conflict?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesOnConflict>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesOnConflict {
  constraint: ProfileNoteVersionsStakeholderRelevantPartiesConstraint,
  update_columns: Array<ProfileNoteVersionsStakeholderRelevantPartiesUpdateColumn>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_note_version_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesPkColumnsInput {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String'],
}

export enum ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn {
  CreatedAt = 'created_at',
  ProfileNoteVersionId = 'profile_note_version_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileNoteVersionsStakeholderRelevantPartiesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_note_version_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileNoteVersionsStakeholderRelevantPartiesUpdateColumn {
  CreatedAt = 'created_at',
  ProfileNoteVersionId = 'profile_note_version_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileObjRelInsertInput {
  data: ProfileInsertInput,
  on_conflict?: Maybe<ProfileOnConflict>,
}

export interface ProfileOnConflict {
  constraint: ProfileConstraint,
  update_columns: Array<ProfileUpdateColumn>,
  where?: Maybe<ProfileBoolExp>,
}

export interface ProfileOrderBy {
  accounts_aggregate?: Maybe<AccountAggregateOrderBy>,
  activity?: Maybe<ProfileActivityOrderBy>,
  activity_id?: Maybe<OrderBy>,
  category?: Maybe<ProfileCategoryOrderBy>,
  category_id?: Maybe<OrderBy>,
  clients_aggregate?: Maybe<ProfileAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  entry?: Maybe<ProfileEntryTypeOrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags?: Maybe<ProfileFlagsOrderBy>,
  flags_id?: Maybe<OrderBy>,
  grid_view?: Maybe<ProfileGridViewOrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionOrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_lead_permission?: Maybe<MasterPartnerLeadPermissionOrderBy>,
  master_partner_lead_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionOrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  notes_aggregate?: Maybe<ProfileNoteAggregateOrderBy>,
  number?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  partner?: Maybe<ProfileOrderBy>,
  partner_id?: Maybe<OrderBy>,
  representatives_aggregate?: Maybe<RepresentativesToProfilesAggregateOrderBy>,
  risk?: Maybe<ProfileRiskOrderBy>,
  risk_id?: Maybe<OrderBy>,
  stakes_aggregate?: Maybe<StakeAggregateOrderBy>,
  status?: Maybe<ProfileStatusOrderBy>,
  status_id?: Maybe<OrderBy>,
  tags_aggregate?: Maybe<ProfilesToTagsAggregateOrderBy>,
  type?: Maybe<ProfileTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  workflows_aggregate?: Maybe<WorkflowsToProfilesAggregateOrderBy>,
}

export interface ProfilePkColumnsInput {
  id: Scalars['String'],
}

export interface ProfileRisk {
   __typename?: 'profile_risk',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileRiskAggregate {
   __typename?: 'profile_risk_aggregate',
  aggregate?: Maybe<ProfileRiskAggregateFields>,
  nodes: Array<ProfileRisk>,
}

export interface ProfileRiskAggregateFields {
   __typename?: 'profile_risk_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileRiskMaxFields>,
  min?: Maybe<ProfileRiskMinFields>,
}


export interface ProfileRiskAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileRiskSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileRiskAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileRiskMaxOrderBy>,
  min?: Maybe<ProfileRiskMinOrderBy>,
}

export interface ProfileRiskArrRelInsertInput {
  data: Array<ProfileRiskInsertInput>,
  on_conflict?: Maybe<ProfileRiskOnConflict>,
}

export interface ProfileRiskBoolExp {
  _and?: Maybe<Array<Maybe<ProfileRiskBoolExp>>>,
  _not?: Maybe<ProfileRiskBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileRiskBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileRiskConstraint {
  ProfileRiskNameKey = 'profile_risk_name_key',
  ProfileRiskPkey = 'profile_risk_pkey'
}

export interface ProfileRiskInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileRiskMaxFields {
   __typename?: 'profile_risk_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileRiskMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileRiskMinFields {
   __typename?: 'profile_risk_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileRiskMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileRiskMutationResponse {
   __typename?: 'profile_risk_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileRisk>,
}

export interface ProfileRiskObjRelInsertInput {
  data: ProfileRiskInsertInput,
  on_conflict?: Maybe<ProfileRiskOnConflict>,
}

export interface ProfileRiskOnConflict {
  constraint: ProfileRiskConstraint,
  update_columns: Array<ProfileRiskUpdateColumn>,
  where?: Maybe<ProfileRiskBoolExp>,
}

export interface ProfileRiskOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileRiskPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileRiskSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileRiskSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileRiskUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum ProfileSelectColumn {
  ActivityId = 'activity_id',
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  EntryId = 'entry_id',
  FlagsId = 'flags_id',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerLeadPermissionId = 'master_partner_lead_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  Name = 'name',
  Number = 'number',
  OrganizationId = 'organization_id',
  PartnerId = 'partner_id',
  RiskId = 'risk_id',
  StatusId = 'status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface ProfileSetInput {
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_lead_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  partner_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileStatus {
   __typename?: 'profile_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  name: Scalars['String'],
  profiles: Array<Profile>,
  profiles_aggregate: ProfileAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface ProfileStatusProfilesArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface ProfileStatusProfilesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}

export interface ProfileStatusAggregate {
   __typename?: 'profile_status_aggregate',
  aggregate?: Maybe<ProfileStatusAggregateFields>,
  nodes: Array<ProfileStatus>,
}

export interface ProfileStatusAggregateFields {
   __typename?: 'profile_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileStatusMaxFields>,
  min?: Maybe<ProfileStatusMinFields>,
}


export interface ProfileStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileStatusMaxOrderBy>,
  min?: Maybe<ProfileStatusMinOrderBy>,
}

export interface ProfileStatusArrRelInsertInput {
  data: Array<ProfileStatusInsertInput>,
  on_conflict?: Maybe<ProfileStatusOnConflict>,
}

export interface ProfileStatusBoolExp {
  _and?: Maybe<Array<Maybe<ProfileStatusBoolExp>>>,
  _not?: Maybe<ProfileStatusBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  profiles?: Maybe<ProfileBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileStatusConstraint {
  ProfileStatusNameKey = 'profile_status_name_key',
  ProfileStatusPkey = 'profile_status_pkey'
}

export interface ProfileStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profiles?: Maybe<ProfileArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileStatusMaxFields {
   __typename?: 'profile_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileStatusMinFields {
   __typename?: 'profile_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileStatusMutationResponse {
   __typename?: 'profile_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileStatus>,
}

export interface ProfileStatusObjRelInsertInput {
  data: ProfileStatusInsertInput,
  on_conflict?: Maybe<ProfileStatusOnConflict>,
}

export interface ProfileStatusOnConflict {
  constraint: ProfileStatusConstraint,
  update_columns: Array<ProfileStatusUpdateColumn>,
  where?: Maybe<ProfileStatusBoolExp>,
}

export interface ProfileStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  profiles_aggregate?: Maybe<ProfileAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileType {
   __typename?: 'profile_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfileTypeAggregate {
   __typename?: 'profile_type_aggregate',
  aggregate?: Maybe<ProfileTypeAggregateFields>,
  nodes: Array<ProfileType>,
}

export interface ProfileTypeAggregateFields {
   __typename?: 'profile_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfileTypeMaxFields>,
  min?: Maybe<ProfileTypeMinFields>,
}


export interface ProfileTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfileTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfileTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfileTypeMaxOrderBy>,
  min?: Maybe<ProfileTypeMinOrderBy>,
}

export interface ProfileTypeArrRelInsertInput {
  data: Array<ProfileTypeInsertInput>,
  on_conflict?: Maybe<ProfileTypeOnConflict>,
}

export interface ProfileTypeBoolExp {
  _and?: Maybe<Array<Maybe<ProfileTypeBoolExp>>>,
  _not?: Maybe<ProfileTypeBoolExp>,
  _or?: Maybe<Array<Maybe<ProfileTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfileTypeConstraint {
  ProfileTypeNameKey = 'profile_type_name_key',
  ProfileTypePkey = 'profile_type_pkey'
}

export interface ProfileTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileTypeMaxFields {
   __typename?: 'profile_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileTypeMinFields {
   __typename?: 'profile_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfileTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileTypeMutationResponse {
   __typename?: 'profile_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfileType>,
}

export interface ProfileTypeObjRelInsertInput {
  data: ProfileTypeInsertInput,
  on_conflict?: Maybe<ProfileTypeOnConflict>,
}

export interface ProfileTypeOnConflict {
  constraint: ProfileTypeConstraint,
  update_columns: Array<ProfileTypeUpdateColumn>,
  where?: Maybe<ProfileTypeBoolExp>,
}

export interface ProfileTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfileTypePkColumnsInput {
  id: Scalars['String'],
}

export enum ProfileTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface ProfileTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfileTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum ProfileUpdateColumn {
  ActivityId = 'activity_id',
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  EntryId = 'entry_id',
  FlagsId = 'flags_id',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerLeadPermissionId = 'master_partner_lead_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  Name = 'name',
  Number = 'number',
  OrganizationId = 'organization_id',
  PartnerId = 'partner_id',
  RiskId = 'risk_id',
  StatusId = 'status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface ProfilesToTags {
   __typename?: 'profiles_to_tags',
  created_at: Scalars['timestamptz'],
  profile: Profile,
  profile_id: Scalars['String'],
  tag: Tag,
  tag_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface ProfilesToTagsAggregate {
   __typename?: 'profiles_to_tags_aggregate',
  aggregate?: Maybe<ProfilesToTagsAggregateFields>,
  nodes: Array<ProfilesToTags>,
}

export interface ProfilesToTagsAggregateFields {
   __typename?: 'profiles_to_tags_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<ProfilesToTagsMaxFields>,
  min?: Maybe<ProfilesToTagsMinFields>,
}


export interface ProfilesToTagsAggregateFieldsCountArgs {
  columns?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface ProfilesToTagsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<ProfilesToTagsMaxOrderBy>,
  min?: Maybe<ProfilesToTagsMinOrderBy>,
}

export interface ProfilesToTagsArrRelInsertInput {
  data: Array<ProfilesToTagsInsertInput>,
  on_conflict?: Maybe<ProfilesToTagsOnConflict>,
}

export interface ProfilesToTagsBoolExp {
  _and?: Maybe<Array<Maybe<ProfilesToTagsBoolExp>>>,
  _not?: Maybe<ProfilesToTagsBoolExp>,
  _or?: Maybe<Array<Maybe<ProfilesToTagsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  tag?: Maybe<TagBoolExp>,
  tag_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum ProfilesToTagsConstraint {
  ProfilesToTagsPkey = 'profiles_to_tags_pkey'
}

export interface ProfilesToTagsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  tag?: Maybe<TagObjRelInsertInput>,
  tag_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfilesToTagsMaxFields {
   __typename?: 'profiles_to_tags_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  tag_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfilesToTagsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  tag_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfilesToTagsMinFields {
   __typename?: 'profiles_to_tags_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  tag_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface ProfilesToTagsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  tag_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfilesToTagsMutationResponse {
   __typename?: 'profiles_to_tags_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<ProfilesToTags>,
}

export interface ProfilesToTagsObjRelInsertInput {
  data: ProfilesToTagsInsertInput,
  on_conflict?: Maybe<ProfilesToTagsOnConflict>,
}

export interface ProfilesToTagsOnConflict {
  constraint: ProfilesToTagsConstraint,
  update_columns: Array<ProfilesToTagsUpdateColumn>,
  where?: Maybe<ProfilesToTagsBoolExp>,
}

export interface ProfilesToTagsOrderBy {
  created_at?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  tag?: Maybe<TagOrderBy>,
  tag_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface ProfilesToTagsPkColumnsInput {
  profile_id: Scalars['String'],
  tag_id: Scalars['String'],
}

export enum ProfilesToTagsSelectColumn {
  CreatedAt = 'created_at',
  ProfileId = 'profile_id',
  TagId = 'tag_id',
  UpdatedAt = 'updated_at'
}

export interface ProfilesToTagsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  tag_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum ProfilesToTagsUpdateColumn {
  CreatedAt = 'created_at',
  ProfileId = 'profile_id',
  TagId = 'tag_id',
  UpdatedAt = 'updated_at'
}

export interface QueryRoot {
   __typename?: 'query_root',
  access_format: Array<AccessFormat>,
  access_format_aggregate: AccessFormatAggregate,
  access_format_by_pk?: Maybe<AccessFormat>,
  access_format_type: Array<AccessFormatType>,
  access_format_type_aggregate: AccessFormatTypeAggregate,
  access_format_type_by_pk?: Maybe<AccessFormatType>,
  account: Array<Account>,
  account_aggregate: AccountAggregate,
  account_by_pk?: Maybe<Account>,
  account_creation_request: Array<AccountCreationRequest>,
  account_creation_request_aggregate: AccountCreationRequestAggregate,
  account_creation_request_by_pk?: Maybe<AccountCreationRequest>,
  account_grid_view: Array<AccountGridView>,
  account_grid_view_aggregate: AccountGridViewAggregate,
  account_group: Array<AccountGroup>,
  account_group_aggregate: AccountGroupAggregate,
  account_group_by_pk?: Maybe<AccountGroup>,
  account_group_default: Array<AccountGroupDefault>,
  account_group_default_aggregate: AccountGroupDefaultAggregate,
  account_group_default_by_pk?: Maybe<AccountGroupDefault>,
  account_group_grid_view: Array<AccountGroupGridView>,
  account_group_grid_view_aggregate: AccountGroupGridViewAggregate,
  account_group_security: Array<AccountGroupSecurity>,
  account_group_security_aggregate: AccountGroupSecurityAggregate,
  account_group_security_by_pk?: Maybe<AccountGroupSecurity>,
  account_groups_to_partners: Array<AccountGroupsToPartners>,
  account_groups_to_partners_aggregate: AccountGroupsToPartnersAggregate,
  account_groups_to_partners_by_pk?: Maybe<AccountGroupsToPartners>,
  account_number: Array<AccountNumber>,
  account_number_aggregate: AccountNumberAggregate,
  account_type: Array<AccountType>,
  account_type_aggregate: AccountTypeAggregate,
  account_type_by_pk?: Maybe<AccountType>,
  activities_to_channels: Array<ActivitiesToChannels>,
  activities_to_channels_aggregate: ActivitiesToChannelsAggregate,
  activities_to_channels_by_pk?: Maybe<ActivitiesToChannels>,
  activity: Array<Activity>,
  activity_aggregate: ActivityAggregate,
  activity_by_pk?: Maybe<Activity>,
  activity_category: Array<ActivityCategory>,
  activity_category_aggregate: ActivityCategoryAggregate,
  activity_category_by_pk?: Maybe<ActivityCategory>,
  activity_channel: Array<ActivityChannel>,
  activity_channel_aggregate: ActivityChannelAggregate,
  activity_channel_by_pk?: Maybe<ActivityChannel>,
  activity_subject: Array<ActivitySubject>,
  activity_subject_aggregate: ActivitySubjectAggregate,
  activity_subject_by_pk?: Maybe<ActivitySubject>,
  activity_template: Array<ActivityTemplate>,
  activity_template_aggregate: ActivityTemplateAggregate,
  activity_template_by_pk?: Maybe<ActivityTemplate>,
  admin_permission: Array<AdminPermission>,
  admin_permission_aggregate: AdminPermissionAggregate,
  admin_permission_by_pk?: Maybe<AdminPermission>,
  agreement: Array<Agreement>,
  agreement_aggregate: AgreementAggregate,
  agreement_by_pk?: Maybe<Agreement>,
  agreement_grid_view: Array<AgreementGridView>,
  agreement_grid_view_aggregate: AgreementGridViewAggregate,
  agreement_scenario: Array<AgreementScenario>,
  agreement_scenario_aggregate: AgreementScenarioAggregate,
  agreement_scenario_by_pk?: Maybe<AgreementScenario>,
  agreements_satisfied: Array<AgreementsSatisfied>,
  agreements_satisfied_aggregate: AgreementsSatisfiedAggregate,
  agreements_satisfied_by_pk?: Maybe<AgreementsSatisfied>,
  agreements_to_payment_methods: Array<AgreementsToPaymentMethods>,
  agreements_to_payment_methods_aggregate: AgreementsToPaymentMethodsAggregate,
  agreements_to_payment_methods_by_pk?: Maybe<AgreementsToPaymentMethods>,
  annual_income: Array<AnnualIncome>,
  annual_income_aggregate: AnnualIncomeAggregate,
  annual_income_by_pk?: Maybe<AnnualIncome>,
  api_key: Array<ApiKey>,
  api_key_aggregate: ApiKeyAggregate,
  api_key_by_pk?: Maybe<ApiKey>,
  attribute_metadata: Array<AttributeMetadata>,
  attribute_metadata_aggregate: AttributeMetadataAggregate,
  attribute_metadata_by_pk?: Maybe<AttributeMetadata>,
  attribute_type: Array<AttributeType>,
  attribute_type_aggregate: AttributeTypeAggregate,
  attribute_type_by_pk?: Maybe<AttributeType>,
  bank_account: Array<BankAccount>,
  bank_account_aggregate: BankAccountAggregate,
  bank_account_by_pk?: Maybe<BankAccount>,
  book_type: Array<BookType>,
  book_type_aggregate: BookTypeAggregate,
  book_type_by_pk?: Maybe<BookType>,
  charge_unit: Array<ChargeUnit>,
  charge_unit_aggregate: ChargeUnitAggregate,
  charge_unit_by_pk?: Maybe<ChargeUnit>,
  countries_to_phone_codes: Array<CountriesToPhoneCodes>,
  countries_to_phone_codes_aggregate: CountriesToPhoneCodesAggregate,
  countries_to_phone_codes_by_pk?: Maybe<CountriesToPhoneCodes>,
  country: Array<Country>,
  country_aggregate: CountryAggregate,
  country_by_pk?: Maybe<Country>,
  currencies_to_organizations: Array<CurrenciesToOrganizations>,
  currencies_to_organizations_aggregate: CurrenciesToOrganizationsAggregate,
  currencies_to_organizations_by_pk?: Maybe<CurrenciesToOrganizations>,
  currency: Array<Currency>,
  currency_aggregate: CurrencyAggregate,
  currency_by_pk?: Maybe<Currency>,
  currency_grid_view: Array<CurrencyGridView>,
  currency_grid_view_aggregate: CurrencyGridViewAggregate,
  daily_account_revenue_summary: Array<DailyAccountRevenueSummary>,
  daily_account_revenue_summary_aggregate: DailyAccountRevenueSummaryAggregate,
  daily_account_revenue_summary_by_pk?: Maybe<DailyAccountRevenueSummary>,
  daily_account_status: Array<DailyAccountStatus>,
  daily_account_status_aggregate: DailyAccountStatusAggregate,
  daily_account_status_by_pk?: Maybe<DailyAccountStatus>,
  daily_volume_per_account: Array<DailyVolumePerAccount>,
  daily_volume_per_account_aggregate: DailyVolumePerAccountAggregate,
  daily_volume_per_account_by_pk?: Maybe<DailyVolumePerAccount>,
  daily_volume_per_profile: Array<DailyVolumePerProfile>,
  daily_volume_per_profile_aggregate: DailyVolumePerProfileAggregate,
  daily_volume_per_profile_by_pk?: Maybe<DailyVolumePerProfile>,
  employment_status: Array<EmploymentStatus>,
  employment_status_aggregate: EmploymentStatusAggregate,
  employment_status_by_pk?: Maybe<EmploymentStatus>,
  experience_type: Array<ExperienceType>,
  experience_type_aggregate: ExperienceTypeAggregate,
  experience_type_by_pk?: Maybe<ExperienceType>,
  file: Array<File>,
  file_aggregate: FileAggregate,
  file_by_pk?: Maybe<File>,
  file_type: Array<FileType>,
  file_type_aggregate: FileTypeAggregate,
  file_type_by_pk?: Maybe<FileType>,
  file_type_category: Array<FileTypeCategory>,
  file_type_category_aggregate: FileTypeCategoryAggregate,
  file_type_category_by_pk?: Maybe<FileTypeCategory>,
  file_type_grid_view: Array<FileTypeGridView>,
  file_type_grid_view_aggregate: FileTypeGridViewAggregate,
  files_to_organizations: Array<FilesToOrganizations>,
  files_to_organizations_aggregate: FilesToOrganizationsAggregate,
  files_to_organizations_by_pk?: Maybe<FilesToOrganizations>,
  files_to_stakeholders: Array<FilesToStakeholders>,
  files_to_stakeholders_aggregate: FilesToStakeholdersAggregate,
  files_to_stakeholders_by_pk?: Maybe<FilesToStakeholders>,
  gender: Array<Gender>,
  gender_aggregate: GenderAggregate,
  gender_by_pk?: Maybe<Gender>,
  identification_type: Array<IdentificationType>,
  identification_type_aggregate: IdentificationTypeAggregate,
  identification_type_by_pk?: Maybe<IdentificationType>,
  individual_partner_account_group_default: Array<IndividualPartnerAccountGroupDefault>,
  individual_partner_account_group_default_aggregate: IndividualPartnerAccountGroupDefaultAggregate,
  individual_partner_account_group_default_by_pk?: Maybe<IndividualPartnerAccountGroupDefault>,
  industry: Array<Industry>,
  industry_aggregate: IndustryAggregate,
  industry_by_pk?: Maybe<Industry>,
  intermediary_bank: Array<IntermediaryBank>,
  intermediary_bank_aggregate: IntermediaryBankAggregate,
  intermediary_bank_by_pk?: Maybe<IntermediaryBank>,
  investment_objective: Array<InvestmentObjective>,
  investment_objective_aggregate: InvestmentObjectiveAggregate,
  investment_objective_by_pk?: Maybe<InvestmentObjective>,
  leverage: Array<Leverage>,
  leverage_aggregate: LeverageAggregate,
  leverage_by_pk?: Maybe<Leverage>,
  leverage_grid_view: Array<LeverageGridView>,
  leverage_grid_view_aggregate: LeverageGridViewAggregate,
  leverages_to_platform_server_types: Array<LeveragesToPlatformServerTypes>,
  leverages_to_platform_server_types_aggregate: LeveragesToPlatformServerTypesAggregate,
  leverages_to_platform_server_types_by_pk?: Maybe<LeveragesToPlatformServerTypes>,
  liquid_assets: Array<LiquidAssets>,
  liquid_assets_aggregate: LiquidAssetsAggregate,
  liquid_assets_by_pk?: Maybe<LiquidAssets>,
  locale: Array<Locale>,
  locale_aggregate: LocaleAggregate,
  locale_by_pk?: Maybe<Locale>,
  locales_to_organizations: Array<LocalesToOrganizations>,
  locales_to_organizations_aggregate: LocalesToOrganizationsAggregate,
  locales_to_organizations_by_pk?: Maybe<LocalesToOrganizations>,
  lookup_view: Array<LookupView>,
  lookup_view_aggregate: LookupViewAggregate,
  margin_type: Array<MarginType>,
  margin_type_aggregate: MarginTypeAggregate,
  margin_type_by_pk?: Maybe<MarginType>,
  master_admin_client_permission: Array<MasterAdminClientPermission>,
  master_admin_client_permission_aggregate: MasterAdminClientPermissionAggregate,
  master_admin_client_permission_by_pk?: Maybe<MasterAdminClientPermission>,
  master_admin_lead_permission: Array<MasterAdminLeadPermission>,
  master_admin_lead_permission_aggregate: MasterAdminLeadPermissionAggregate,
  master_admin_lead_permission_by_pk?: Maybe<MasterAdminLeadPermission>,
  master_admin_partner_permission: Array<MasterAdminPartnerPermission>,
  master_admin_partner_permission_aggregate: MasterAdminPartnerPermissionAggregate,
  master_admin_partner_permission_by_pk?: Maybe<MasterAdminPartnerPermission>,
  master_admin_stakeholder_permission: Array<MasterAdminStakeholderPermission>,
  master_admin_stakeholder_permission_aggregate: MasterAdminStakeholderPermissionAggregate,
  master_admin_stakeholder_permission_by_pk?: Maybe<MasterAdminStakeholderPermission>,
  master_admin_system_permission: Array<MasterAdminSystemPermission>,
  master_admin_system_permission_aggregate: MasterAdminSystemPermissionAggregate,
  master_admin_system_permission_by_pk?: Maybe<MasterAdminSystemPermission>,
  master_partner_client_permission: Array<MasterPartnerClientPermission>,
  master_partner_client_permission_aggregate: MasterPartnerClientPermissionAggregate,
  master_partner_client_permission_by_pk?: Maybe<MasterPartnerClientPermission>,
  master_partner_lead_permission: Array<MasterPartnerLeadPermission>,
  master_partner_lead_permission_aggregate: MasterPartnerLeadPermissionAggregate,
  master_partner_lead_permission_by_pk?: Maybe<MasterPartnerLeadPermission>,
  master_partner_partner_permission: Array<MasterPartnerPartnerPermission>,
  master_partner_partner_permission_aggregate: MasterPartnerPartnerPermissionAggregate,
  master_partner_partner_permission_by_pk?: Maybe<MasterPartnerPartnerPermission>,
  master_partner_stakeholder_permission: Array<MasterPartnerStakeholderPermission>,
  master_partner_stakeholder_permission_aggregate: MasterPartnerStakeholderPermissionAggregate,
  master_partner_stakeholder_permission_by_pk?: Maybe<MasterPartnerStakeholderPermission>,
  master_user_permission: Array<MasterUserPermission>,
  master_user_permission_aggregate: MasterUserPermissionAggregate,
  master_user_permission_by_pk?: Maybe<MasterUserPermission>,
  name_title: Array<NameTitle>,
  name_title_aggregate: NameTitleAggregate,
  name_title_by_pk?: Maybe<NameTitle>,
  notification: Array<Notification>,
  notification_aggregate: NotificationAggregate,
  notification_by_pk?: Maybe<Notification>,
  organization: Array<Organization>,
  organization_aggregate: OrganizationAggregate,
  organization_by_pk?: Maybe<Organization>,
  origin: Array<Origin>,
  origin_aggregate: OriginAggregate,
  origin_by_pk?: Maybe<Origin>,
  partner_account_group_grid_view: Array<PartnerAccountGroupGridView>,
  partner_account_group_grid_view_aggregate: PartnerAccountGroupGridViewAggregate,
  partner_permission: Array<PartnerPermission>,
  partner_permission_aggregate: PartnerPermissionAggregate,
  partner_permission_by_pk?: Maybe<PartnerPermission>,
  payment: Array<Payment>,
  payment_aggregate: PaymentAggregate,
  payment_attribute_global_identifier: Array<PaymentAttributeGlobalIdentifier>,
  payment_attribute_global_identifier_aggregate: PaymentAttributeGlobalIdentifierAggregate,
  payment_attribute_global_identifier_by_pk?: Maybe<PaymentAttributeGlobalIdentifier>,
  payment_attribute_key: Array<PaymentAttributeKey>,
  payment_attribute_key_aggregate: PaymentAttributeKeyAggregate,
  payment_attribute_key_by_pk?: Maybe<PaymentAttributeKey>,
  payment_attribute_section: Array<PaymentAttributeSection>,
  payment_attribute_section_aggregate: PaymentAttributeSectionAggregate,
  payment_attribute_section_by_pk?: Maybe<PaymentAttributeSection>,
  payment_attribute_subsection: Array<PaymentAttributeSubsection>,
  payment_attribute_subsection_aggregate: PaymentAttributeSubsectionAggregate,
  payment_attribute_subsection_by_pk?: Maybe<PaymentAttributeSubsection>,
  payment_attribute_value: Array<PaymentAttributeValue>,
  payment_attribute_value_aggregate: PaymentAttributeValueAggregate,
  payment_attribute_value_by_pk?: Maybe<PaymentAttributeValue>,
  payment_by_pk?: Maybe<Payment>,
  payment_method: Array<PaymentMethod>,
  payment_method_aggregate: PaymentMethodAggregate,
  payment_method_attribute: Array<PaymentMethodAttribute>,
  payment_method_attribute_aggregate: PaymentMethodAttributeAggregate,
  payment_method_attribute_by_pk?: Maybe<PaymentMethodAttribute>,
  payment_method_attribute_key: Array<PaymentMethodAttributeKey>,
  payment_method_attribute_key_aggregate: PaymentMethodAttributeKeyAggregate,
  payment_method_attribute_key_by_pk?: Maybe<PaymentMethodAttributeKey>,
  payment_method_attribute_section: Array<PaymentMethodAttributeSection>,
  payment_method_attribute_section_aggregate: PaymentMethodAttributeSectionAggregate,
  payment_method_attribute_section_by_pk?: Maybe<PaymentMethodAttributeSection>,
  payment_method_attribute_subsection: Array<PaymentMethodAttributeSubsection>,
  payment_method_attribute_subsection_aggregate: PaymentMethodAttributeSubsectionAggregate,
  payment_method_attribute_subsection_by_pk?: Maybe<PaymentMethodAttributeSubsection>,
  payment_method_by_pk?: Maybe<PaymentMethod>,
  payment_method_grid_view: Array<PaymentMethodGridView>,
  payment_method_grid_view_aggregate: PaymentMethodGridViewAggregate,
  payment_method_logo: Array<PaymentMethodLogo>,
  payment_method_logo_aggregate: PaymentMethodLogoAggregate,
  payment_method_logo_by_pk?: Maybe<PaymentMethodLogo>,
  payment_method_type: Array<PaymentMethodType>,
  payment_method_type_aggregate: PaymentMethodTypeAggregate,
  payment_method_type_attribute: Array<PaymentMethodTypeAttribute>,
  payment_method_type_attribute_aggregate: PaymentMethodTypeAttributeAggregate,
  payment_method_type_attribute_by_pk?: Maybe<PaymentMethodTypeAttribute>,
  payment_method_type_attribute_key: Array<PaymentMethodTypeAttributeKey>,
  payment_method_type_attribute_key_aggregate: PaymentMethodTypeAttributeKeyAggregate,
  payment_method_type_attribute_key_by_pk?: Maybe<PaymentMethodTypeAttributeKey>,
  payment_method_type_attribute_section: Array<PaymentMethodTypeAttributeSection>,
  payment_method_type_attribute_section_aggregate: PaymentMethodTypeAttributeSectionAggregate,
  payment_method_type_attribute_section_by_pk?: Maybe<PaymentMethodTypeAttributeSection>,
  payment_method_type_attribute_subsection: Array<PaymentMethodTypeAttributeSubsection>,
  payment_method_type_attribute_subsection_aggregate: PaymentMethodTypeAttributeSubsectionAggregate,
  payment_method_type_attribute_subsection_by_pk?: Maybe<PaymentMethodTypeAttributeSubsection>,
  payment_method_type_by_pk?: Maybe<PaymentMethodType>,
  payment_method_type_currency: Array<PaymentMethodTypeCurrency>,
  payment_method_type_currency_aggregate: PaymentMethodTypeCurrencyAggregate,
  payment_method_type_currency_by_pk?: Maybe<PaymentMethodTypeCurrency>,
  payment_orientation: Array<PaymentOrientation>,
  payment_orientation_aggregate: PaymentOrientationAggregate,
  payment_orientation_by_pk?: Maybe<PaymentOrientation>,
  payment_provider: Array<PaymentProvider>,
  payment_provider_aggregate: PaymentProviderAggregate,
  payment_provider_by_pk?: Maybe<PaymentProvider>,
  payment_provider_integration_mode: Array<PaymentProviderIntegrationMode>,
  payment_provider_integration_mode_aggregate: PaymentProviderIntegrationModeAggregate,
  payment_provider_integration_mode_by_pk?: Maybe<PaymentProviderIntegrationMode>,
  payment_provider_method_type: Array<PaymentProviderMethodType>,
  payment_provider_method_type_aggregate: PaymentProviderMethodTypeAggregate,
  payment_provider_method_type_by_pk?: Maybe<PaymentProviderMethodType>,
  payment_status: Array<PaymentStatus>,
  payment_status_aggregate: PaymentStatusAggregate,
  payment_status_by_pk?: Maybe<PaymentStatus>,
  phone_code: Array<PhoneCode>,
  phone_code_aggregate: PhoneCodeAggregate,
  phone_code_by_pk?: Maybe<PhoneCode>,
  phone_type: Array<PhoneType>,
  phone_type_aggregate: PhoneTypeAggregate,
  phone_type_by_pk?: Maybe<PhoneType>,
  platform_access: Array<PlatformAccess>,
  platform_access_aggregate: PlatformAccessAggregate,
  platform_access_by_pk?: Maybe<PlatformAccess>,
  platform_server: Array<PlatformServer>,
  platform_server_aggregate: PlatformServerAggregate,
  platform_server_by_pk?: Maybe<PlatformServer>,
  platform_server_environment: Array<PlatformServerEnvironment>,
  platform_server_environment_aggregate: PlatformServerEnvironmentAggregate,
  platform_server_environment_by_pk?: Maybe<PlatformServerEnvironment>,
  platform_server_grid_view: Array<PlatformServerGridView>,
  platform_server_grid_view_aggregate: PlatformServerGridViewAggregate,
  platform_server_retry_status: Array<PlatformServerRetryStatus>,
  platform_server_retry_status_aggregate: PlatformServerRetryStatusAggregate,
  platform_server_retry_status_by_pk?: Maybe<PlatformServerRetryStatus>,
  platform_server_status: Array<PlatformServerStatus>,
  platform_server_status_aggregate: PlatformServerStatusAggregate,
  platform_server_status_by_pk?: Maybe<PlatformServerStatus>,
  platform_server_type: Array<PlatformServerType>,
  platform_server_type_aggregate: PlatformServerTypeAggregate,
  platform_server_type_by_pk?: Maybe<PlatformServerType>,
  platform_servers_to_supported_leverages: Array<PlatformServersToSupportedLeverages>,
  platform_servers_to_supported_leverages_aggregate: PlatformServersToSupportedLeveragesAggregate,
  platform_servers_to_supported_leverages_by_pk?: Maybe<PlatformServersToSupportedLeverages>,
  platform_symbol: Array<PlatformSymbol>,
  platform_symbol_aggregate: PlatformSymbolAggregate,
  platform_symbol_by_pk?: Maybe<PlatformSymbol>,
  profile: Array<Profile>,
  profile_activity: Array<ProfileActivity>,
  profile_activity_aggregate: ProfileActivityAggregate,
  profile_activity_by_pk?: Maybe<ProfileActivity>,
  profile_aggregate: ProfileAggregate,
  profile_by_pk?: Maybe<Profile>,
  profile_category: Array<ProfileCategory>,
  profile_category_aggregate: ProfileCategoryAggregate,
  profile_category_by_pk?: Maybe<ProfileCategory>,
  profile_entry_type: Array<ProfileEntryType>,
  profile_entry_type_aggregate: ProfileEntryTypeAggregate,
  profile_entry_type_by_pk?: Maybe<ProfileEntryType>,
  profile_flags: Array<ProfileFlags>,
  profile_flags_aggregate: ProfileFlagsAggregate,
  profile_flags_by_pk?: Maybe<ProfileFlags>,
  profile_grid_view: Array<ProfileGridView>,
  profile_grid_view_aggregate: ProfileGridViewAggregate,
  profile_note: Array<ProfileNote>,
  profile_note_aggregate: ProfileNoteAggregate,
  profile_note_by_pk?: Maybe<ProfileNote>,
  profile_note_version: Array<ProfileNoteVersion>,
  profile_note_version_aggregate: ProfileNoteVersionAggregate,
  profile_note_version_by_pk?: Maybe<ProfileNoteVersion>,
  profile_note_versions_stakeholder_mentions: Array<ProfileNoteVersionsStakeholderMentions>,
  profile_note_versions_stakeholder_mentions_aggregate: ProfileNoteVersionsStakeholderMentionsAggregate,
  profile_note_versions_stakeholder_mentions_by_pk?: Maybe<ProfileNoteVersionsStakeholderMentions>,
  profile_note_versions_stakeholder_relevant_parties: Array<ProfileNoteVersionsStakeholderRelevantParties>,
  profile_note_versions_stakeholder_relevant_parties_aggregate: ProfileNoteVersionsStakeholderRelevantPartiesAggregate,
  profile_note_versions_stakeholder_relevant_parties_by_pk?: Maybe<ProfileNoteVersionsStakeholderRelevantParties>,
  profile_risk: Array<ProfileRisk>,
  profile_risk_aggregate: ProfileRiskAggregate,
  profile_risk_by_pk?: Maybe<ProfileRisk>,
  profile_status: Array<ProfileStatus>,
  profile_status_aggregate: ProfileStatusAggregate,
  profile_status_by_pk?: Maybe<ProfileStatus>,
  profile_type: Array<ProfileType>,
  profile_type_aggregate: ProfileTypeAggregate,
  profile_type_by_pk?: Maybe<ProfileType>,
  profiles_to_tags: Array<ProfilesToTags>,
  profiles_to_tags_aggregate: ProfilesToTagsAggregate,
  profiles_to_tags_by_pk?: Maybe<ProfilesToTags>,
  random_country: Array<RandomCountry>,
  random_country_aggregate: RandomCountryAggregate,
  random_currency: Array<RandomCurrency>,
  random_currency_aggregate: RandomCurrencyAggregate,
  random_phone_type: Array<RandomPhoneType>,
  random_phone_type_aggregate: RandomPhoneTypeAggregate,
  random_profile: Array<RandomProfile>,
  random_profile_aggregate: RandomProfileAggregate,
  random_profile_risk: Array<RandomProfileRisk>,
  random_profile_risk_aggregate: RandomProfileRiskAggregate,
  random_profile_status: Array<RandomProfileStatus>,
  random_profile_status_aggregate: RandomProfileStatusAggregate,
  random_tag: Array<RandomTag>,
  random_tag_aggregate: RandomTagAggregate,
  representatives_to_profiles: Array<RepresentativesToProfiles>,
  representatives_to_profiles_aggregate: RepresentativesToProfilesAggregate,
  representatives_to_profiles_by_pk?: Maybe<RepresentativesToProfiles>,
  request: Array<Request>,
  request_aggregate: RequestAggregate,
  request_by_pk?: Maybe<Request>,
  request_grid_view: Array<RequestGridView>,
  request_grid_view_aggregate: RequestGridViewAggregate,
  request_status: Array<RequestStatus>,
  request_status_aggregate: RequestStatusAggregate,
  request_status_by_pk?: Maybe<RequestStatus>,
  request_type: Array<RequestType>,
  request_type_aggregate: RequestTypeAggregate,
  request_type_by_pk?: Maybe<RequestType>,
  security: Array<Security>,
  security_aggregate: SecurityAggregate,
  security_by_pk?: Maybe<Security>,
  security_grid_view: Array<SecurityGridView>,
  security_grid_view_aggregate: SecurityGridViewAggregate,
  segment: Array<Segment>,
  segment_aggregate: SegmentAggregate,
  segment_by_pk?: Maybe<Segment>,
  segment_condition: Array<SegmentCondition>,
  segment_condition_aggregate: SegmentConditionAggregate,
  segment_condition_by_pk?: Maybe<SegmentCondition>,
  segment_criterion: Array<SegmentCriterion>,
  segment_criterion_aggregate: SegmentCriterionAggregate,
  segment_criterion_by_pk?: Maybe<SegmentCriterion>,
  segment_criterion_type: Array<SegmentCriterionType>,
  segment_criterion_type_aggregate: SegmentCriterionTypeAggregate,
  segment_criterion_type_by_pk?: Maybe<SegmentCriterionType>,
  segment_grid_view: Array<SegmentGridView>,
  segment_grid_view_aggregate: SegmentGridViewAggregate,
  segment_operator: Array<SegmentOperator>,
  segment_operator_aggregate: SegmentOperatorAggregate,
  segment_operator_by_pk?: Maybe<SegmentOperator>,
  segment_operators_to_segment_property_conditions: Array<SegmentOperatorsToSegmentPropertyConditions>,
  segment_operators_to_segment_property_conditions_aggregate: SegmentOperatorsToSegmentPropertyConditionsAggregate,
  segment_operators_to_segment_property_conditions_by_pk?: Maybe<SegmentOperatorsToSegmentPropertyConditions>,
  segment_property: Array<SegmentProperty>,
  segment_property_aggregate: SegmentPropertyAggregate,
  segment_property_by_pk?: Maybe<SegmentProperty>,
  segment_property_condition: Array<SegmentPropertyCondition>,
  segment_property_condition_aggregate: SegmentPropertyConditionAggregate,
  segment_property_condition_by_pk?: Maybe<SegmentPropertyCondition>,
  shared_file_grid_view: Array<SharedFileGridView>,
  shared_file_grid_view_aggregate: SharedFileGridViewAggregate,
  source_of_funds: Array<SourceOfFunds>,
  source_of_funds_aggregate: SourceOfFundsAggregate,
  source_of_funds_by_pk?: Maybe<SourceOfFunds>,
  spread_mode: Array<SpreadMode>,
  spread_mode_aggregate: SpreadModeAggregate,
  spread_mode_by_pk?: Maybe<SpreadMode>,
  stake: Array<Stake>,
  stake_aggregate: StakeAggregate,
  stake_ancestry: Array<StakeAncestry>,
  stake_ancestry_aggregate: StakeAncestryAggregate,
  stake_ancestry_by_pk?: Maybe<StakeAncestry>,
  stake_by_pk?: Maybe<Stake>,
  stake_title: Array<StakeTitle>,
  stake_title_aggregate: StakeTitleAggregate,
  stake_title_by_pk?: Maybe<StakeTitle>,
  stakeholder: Array<Stakeholder>,
  stakeholder_aggregate: StakeholderAggregate,
  stakeholder_attribute: Array<StakeholderAttribute>,
  stakeholder_attribute_aggregate: StakeholderAttributeAggregate,
  stakeholder_attribute_by_pk?: Maybe<StakeholderAttribute>,
  stakeholder_attribute_key: Array<StakeholderAttributeKey>,
  stakeholder_attribute_key_aggregate: StakeholderAttributeKeyAggregate,
  stakeholder_attribute_key_by_pk?: Maybe<StakeholderAttributeKey>,
  stakeholder_attribute_keys_to_stakeholder_classes: Array<StakeholderAttributeKeysToStakeholderClasses>,
  stakeholder_attribute_keys_to_stakeholder_classes_aggregate: StakeholderAttributeKeysToStakeholderClassesAggregate,
  stakeholder_attribute_keys_to_stakeholder_classes_by_pk?: Maybe<StakeholderAttributeKeysToStakeholderClasses>,
  stakeholder_attribute_pivot: Array<StakeholderAttributePivot>,
  stakeholder_attribute_pivot_aggregate: StakeholderAttributePivotAggregate,
  stakeholder_attribute_section: Array<StakeholderAttributeSection>,
  stakeholder_attribute_section_aggregate: StakeholderAttributeSectionAggregate,
  stakeholder_attribute_section_by_pk?: Maybe<StakeholderAttributeSection>,
  stakeholder_attribute_subsection: Array<StakeholderAttributeSubsection>,
  stakeholder_attribute_subsection_aggregate: StakeholderAttributeSubsectionAggregate,
  stakeholder_attribute_subsection_by_pk?: Maybe<StakeholderAttributeSubsection>,
  stakeholder_by_pk?: Maybe<Stakeholder>,
  stakeholder_class: Array<StakeholderClass>,
  stakeholder_class_aggregate: StakeholderClassAggregate,
  stakeholder_class_by_pk?: Maybe<StakeholderClass>,
  stakeholder_file_grid_view: Array<StakeholderFileGridView>,
  stakeholder_file_grid_view_aggregate: StakeholderFileGridViewAggregate,
  stakeholder_name: Array<StakeholderName>,
  stakeholder_name_aggregate: StakeholderNameAggregate,
  stakes_to_admin_permissions: Array<StakesToAdminPermissions>,
  stakes_to_admin_permissions_aggregate: StakesToAdminPermissionsAggregate,
  stakes_to_admin_permissions_by_pk?: Maybe<StakesToAdminPermissions>,
  stakes_to_stake_titles: Array<StakesToStakeTitles>,
  stakes_to_stake_titles_aggregate: StakesToStakeTitlesAggregate,
  stakes_to_stake_titles_by_pk?: Maybe<StakesToStakeTitles>,
  swap_mode: Array<SwapMode>,
  swap_mode_aggregate: SwapModeAggregate,
  swap_mode_by_pk?: Maybe<SwapMode>,
  symbol: Array<Symbol>,
  symbol_aggregate: SymbolAggregate,
  symbol_by_pk?: Maybe<Symbol>,
  symbol_extension: Array<SymbolExtension>,
  symbol_extension_aggregate: SymbolExtensionAggregate,
  symbol_extension_by_pk?: Maybe<SymbolExtension>,
  symbol_grid_view: Array<SymbolGridView>,
  symbol_grid_view_aggregate: SymbolGridViewAggregate,
  symbol_type: Array<SymbolType>,
  symbol_type_aggregate: SymbolTypeAggregate,
  symbol_type_by_pk?: Maybe<SymbolType>,
  tag: Array<Tag>,
  tag_aggregate: TagAggregate,
  tag_by_pk?: Maybe<Tag>,
  trade_rule: Array<TradeRule>,
  trade_rule_aggregate: TradeRuleAggregate,
  trade_rule_by_pk?: Maybe<TradeRule>,
  transaction: Array<Transaction>,
  transaction_aggregate: TransactionAggregate,
  transaction_by_pk?: Maybe<Transaction>,
  transaction_creation_request: Array<TransactionCreationRequest>,
  transaction_creation_request_aggregate: TransactionCreationRequestAggregate,
  transaction_creation_request_by_pk?: Maybe<TransactionCreationRequest>,
  transaction_grid_view: Array<TransactionGridView>,
  transaction_grid_view_aggregate: TransactionGridViewAggregate,
  transaction_platform_status: Array<TransactionPlatformStatus>,
  transaction_platform_status_aggregate: TransactionPlatformStatusAggregate,
  transaction_platform_status_by_pk?: Maybe<TransactionPlatformStatus>,
  transaction_profile_grid_view: Array<TransactionProfileGridView>,
  transaction_profile_grid_view_aggregate: TransactionProfileGridViewAggregate,
  transaction_status: Array<TransactionStatus>,
  transaction_status_aggregate: TransactionStatusAggregate,
  transaction_status_by_pk?: Maybe<TransactionStatus>,
  transaction_type: Array<TransactionType>,
  transaction_type_aggregate: TransactionTypeAggregate,
  transaction_type_by_pk?: Maybe<TransactionType>,
  transfer_approval_option: Array<TransferApprovalOption>,
  transfer_approval_option_aggregate: TransferApprovalOptionAggregate,
  transfer_approval_option_by_pk?: Maybe<TransferApprovalOption>,
  user_access: Array<UserAccess>,
  user_access_aggregate: UserAccessAggregate,
  user_access_by_pk?: Maybe<UserAccess>,
  user_invite: Array<UserInvite>,
  user_invite_aggregate: UserInviteAggregate,
  user_invite_by_pk?: Maybe<UserInvite>,
  user_management_grid_view: Array<UserManagementGridView>,
  user_management_grid_view_aggregate: UserManagementGridViewAggregate,
  user_permission: Array<UserPermission>,
  user_permission_aggregate: UserPermissionAggregate,
  user_permission_by_pk?: Maybe<UserPermission>,
  workflow: Array<Workflow>,
  workflow_aggregate: WorkflowAggregate,
  workflow_by_pk?: Maybe<Workflow>,
  workflow_stage: Array<WorkflowStage>,
  workflow_stage_aggregate: WorkflowStageAggregate,
  workflow_stage_by_pk?: Maybe<WorkflowStage>,
  workflows_to_profiles: Array<WorkflowsToProfiles>,
  workflows_to_profiles_aggregate: WorkflowsToProfilesAggregate,
  workflows_to_profiles_by_pk?: Maybe<WorkflowsToProfiles>,
  years_experience: Array<YearsExperience>,
  years_experience_aggregate: YearsExperienceAggregate,
  years_experience_by_pk?: Maybe<YearsExperience>,
}


export interface QueryRootAccessFormatArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface QueryRootAccessFormatAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface QueryRootAccessFormatByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAccessFormatTypeArgs {
  distinct_on?: Maybe<Array<AccessFormatTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatTypeOrderBy>>,
  where?: Maybe<AccessFormatTypeBoolExp>
}


export interface QueryRootAccessFormatTypeAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatTypeOrderBy>>,
  where?: Maybe<AccessFormatTypeBoolExp>
}


export interface QueryRootAccessFormatTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAccountArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface QueryRootAccountAggregateArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface QueryRootAccountByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAccountCreationRequestArgs {
  distinct_on?: Maybe<Array<AccountCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountCreationRequestOrderBy>>,
  where?: Maybe<AccountCreationRequestBoolExp>
}


export interface QueryRootAccountCreationRequestAggregateArgs {
  distinct_on?: Maybe<Array<AccountCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountCreationRequestOrderBy>>,
  where?: Maybe<AccountCreationRequestBoolExp>
}


export interface QueryRootAccountCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAccountGridViewArgs {
  distinct_on?: Maybe<Array<AccountGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGridViewOrderBy>>,
  where?: Maybe<AccountGridViewBoolExp>
}


export interface QueryRootAccountGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AccountGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGridViewOrderBy>>,
  where?: Maybe<AccountGridViewBoolExp>
}


export interface QueryRootAccountGroupArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface QueryRootAccountGroupAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface QueryRootAccountGroupByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAccountGroupDefaultArgs {
  distinct_on?: Maybe<Array<AccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupDefaultOrderBy>>,
  where?: Maybe<AccountGroupDefaultBoolExp>
}


export interface QueryRootAccountGroupDefaultAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupDefaultOrderBy>>,
  where?: Maybe<AccountGroupDefaultBoolExp>
}


export interface QueryRootAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface QueryRootAccountGroupGridViewArgs {
  distinct_on?: Maybe<Array<AccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupGridViewOrderBy>>,
  where?: Maybe<AccountGroupGridViewBoolExp>
}


export interface QueryRootAccountGroupGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupGridViewOrderBy>>,
  where?: Maybe<AccountGroupGridViewBoolExp>
}


export interface QueryRootAccountGroupSecurityArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface QueryRootAccountGroupSecurityAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface QueryRootAccountGroupSecurityByPkArgs {
  group_id: Scalars['String'],
  security_id: Scalars['String']
}


export interface QueryRootAccountGroupsToPartnersArgs {
  distinct_on?: Maybe<Array<AccountGroupsToPartnersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupsToPartnersOrderBy>>,
  where?: Maybe<AccountGroupsToPartnersBoolExp>
}


export interface QueryRootAccountGroupsToPartnersAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupsToPartnersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupsToPartnersOrderBy>>,
  where?: Maybe<AccountGroupsToPartnersBoolExp>
}


export interface QueryRootAccountGroupsToPartnersByPkArgs {
  group_id: Scalars['String'],
  partner_id: Scalars['String']
}


export interface QueryRootAccountNumberArgs {
  distinct_on?: Maybe<Array<AccountNumberSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountNumberOrderBy>>,
  where?: Maybe<AccountNumberBoolExp>
}


export interface QueryRootAccountNumberAggregateArgs {
  distinct_on?: Maybe<Array<AccountNumberSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountNumberOrderBy>>,
  where?: Maybe<AccountNumberBoolExp>
}


export interface QueryRootAccountTypeArgs {
  distinct_on?: Maybe<Array<AccountTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountTypeOrderBy>>,
  where?: Maybe<AccountTypeBoolExp>
}


export interface QueryRootAccountTypeAggregateArgs {
  distinct_on?: Maybe<Array<AccountTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountTypeOrderBy>>,
  where?: Maybe<AccountTypeBoolExp>
}


export interface QueryRootAccountTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivitiesToChannelsArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface QueryRootActivitiesToChannelsAggregateArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface QueryRootActivitiesToChannelsByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivityArgs {
  distinct_on?: Maybe<Array<ActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityOrderBy>>,
  where?: Maybe<ActivityBoolExp>
}


export interface QueryRootActivityAggregateArgs {
  distinct_on?: Maybe<Array<ActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityOrderBy>>,
  where?: Maybe<ActivityBoolExp>
}


export interface QueryRootActivityByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivityCategoryArgs {
  distinct_on?: Maybe<Array<ActivityCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityCategoryOrderBy>>,
  where?: Maybe<ActivityCategoryBoolExp>
}


export interface QueryRootActivityCategoryAggregateArgs {
  distinct_on?: Maybe<Array<ActivityCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityCategoryOrderBy>>,
  where?: Maybe<ActivityCategoryBoolExp>
}


export interface QueryRootActivityCategoryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivityChannelArgs {
  distinct_on?: Maybe<Array<ActivityChannelSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityChannelOrderBy>>,
  where?: Maybe<ActivityChannelBoolExp>
}


export interface QueryRootActivityChannelAggregateArgs {
  distinct_on?: Maybe<Array<ActivityChannelSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityChannelOrderBy>>,
  where?: Maybe<ActivityChannelBoolExp>
}


export interface QueryRootActivityChannelByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivitySubjectArgs {
  distinct_on?: Maybe<Array<ActivitySubjectSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitySubjectOrderBy>>,
  where?: Maybe<ActivitySubjectBoolExp>
}


export interface QueryRootActivitySubjectAggregateArgs {
  distinct_on?: Maybe<Array<ActivitySubjectSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitySubjectOrderBy>>,
  where?: Maybe<ActivitySubjectBoolExp>
}


export interface QueryRootActivitySubjectByPkArgs {
  id: Scalars['String']
}


export interface QueryRootActivityTemplateArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}


export interface QueryRootActivityTemplateAggregateArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}


export interface QueryRootActivityTemplateByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAdminPermissionArgs {
  distinct_on?: Maybe<Array<AdminPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AdminPermissionOrderBy>>,
  where?: Maybe<AdminPermissionBoolExp>
}


export interface QueryRootAdminPermissionAggregateArgs {
  distinct_on?: Maybe<Array<AdminPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AdminPermissionOrderBy>>,
  where?: Maybe<AdminPermissionBoolExp>
}


export interface QueryRootAdminPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAgreementArgs {
  distinct_on?: Maybe<Array<AgreementSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementOrderBy>>,
  where?: Maybe<AgreementBoolExp>
}


export interface QueryRootAgreementAggregateArgs {
  distinct_on?: Maybe<Array<AgreementSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementOrderBy>>,
  where?: Maybe<AgreementBoolExp>
}


export interface QueryRootAgreementByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAgreementGridViewArgs {
  distinct_on?: Maybe<Array<AgreementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementGridViewOrderBy>>,
  where?: Maybe<AgreementGridViewBoolExp>
}


export interface QueryRootAgreementGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AgreementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementGridViewOrderBy>>,
  where?: Maybe<AgreementGridViewBoolExp>
}


export interface QueryRootAgreementScenarioArgs {
  distinct_on?: Maybe<Array<AgreementScenarioSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementScenarioOrderBy>>,
  where?: Maybe<AgreementScenarioBoolExp>
}


export interface QueryRootAgreementScenarioAggregateArgs {
  distinct_on?: Maybe<Array<AgreementScenarioSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementScenarioOrderBy>>,
  where?: Maybe<AgreementScenarioBoolExp>
}


export interface QueryRootAgreementScenarioByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAgreementsSatisfiedArgs {
  distinct_on?: Maybe<Array<AgreementsSatisfiedSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsSatisfiedOrderBy>>,
  where?: Maybe<AgreementsSatisfiedBoolExp>
}


export interface QueryRootAgreementsSatisfiedAggregateArgs {
  distinct_on?: Maybe<Array<AgreementsSatisfiedSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsSatisfiedOrderBy>>,
  where?: Maybe<AgreementsSatisfiedBoolExp>
}


export interface QueryRootAgreementsSatisfiedByPkArgs {
  agreement_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface QueryRootAgreementsToPaymentMethodsArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface QueryRootAgreementsToPaymentMethodsAggregateArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface QueryRootAgreementsToPaymentMethodsByPkArgs {
  agreement_id: Scalars['String'],
  method_id: Scalars['String']
}


export interface QueryRootAnnualIncomeArgs {
  distinct_on?: Maybe<Array<AnnualIncomeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AnnualIncomeOrderBy>>,
  where?: Maybe<AnnualIncomeBoolExp>
}


export interface QueryRootAnnualIncomeAggregateArgs {
  distinct_on?: Maybe<Array<AnnualIncomeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AnnualIncomeOrderBy>>,
  where?: Maybe<AnnualIncomeBoolExp>
}


export interface QueryRootAnnualIncomeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootApiKeyArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface QueryRootApiKeyAggregateArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface QueryRootApiKeyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAttributeMetadataArgs {
  distinct_on?: Maybe<Array<AttributeMetadataSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeMetadataOrderBy>>,
  where?: Maybe<AttributeMetadataBoolExp>
}


export interface QueryRootAttributeMetadataAggregateArgs {
  distinct_on?: Maybe<Array<AttributeMetadataSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeMetadataOrderBy>>,
  where?: Maybe<AttributeMetadataBoolExp>
}


export interface QueryRootAttributeMetadataByPkArgs {
  id: Scalars['String']
}


export interface QueryRootAttributeTypeArgs {
  distinct_on?: Maybe<Array<AttributeTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeTypeOrderBy>>,
  where?: Maybe<AttributeTypeBoolExp>
}


export interface QueryRootAttributeTypeAggregateArgs {
  distinct_on?: Maybe<Array<AttributeTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeTypeOrderBy>>,
  where?: Maybe<AttributeTypeBoolExp>
}


export interface QueryRootAttributeTypeByPkArgs {
  name: Scalars['String']
}


export interface QueryRootBankAccountArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface QueryRootBankAccountAggregateArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface QueryRootBankAccountByPkArgs {
  id: Scalars['String']
}


export interface QueryRootBookTypeArgs {
  distinct_on?: Maybe<Array<BookTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BookTypeOrderBy>>,
  where?: Maybe<BookTypeBoolExp>
}


export interface QueryRootBookTypeAggregateArgs {
  distinct_on?: Maybe<Array<BookTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BookTypeOrderBy>>,
  where?: Maybe<BookTypeBoolExp>
}


export interface QueryRootBookTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootChargeUnitArgs {
  distinct_on?: Maybe<Array<ChargeUnitSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ChargeUnitOrderBy>>,
  where?: Maybe<ChargeUnitBoolExp>
}


export interface QueryRootChargeUnitAggregateArgs {
  distinct_on?: Maybe<Array<ChargeUnitSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ChargeUnitOrderBy>>,
  where?: Maybe<ChargeUnitBoolExp>
}


export interface QueryRootChargeUnitByPkArgs {
  id: Scalars['String']
}


export interface QueryRootCountriesToPhoneCodesArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}


export interface QueryRootCountriesToPhoneCodesAggregateArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}


export interface QueryRootCountriesToPhoneCodesByPkArgs {
  code: Scalars['String'],
  country_id: Scalars['String']
}


export interface QueryRootCountryArgs {
  distinct_on?: Maybe<Array<CountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountryOrderBy>>,
  where?: Maybe<CountryBoolExp>
}


export interface QueryRootCountryAggregateArgs {
  distinct_on?: Maybe<Array<CountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountryOrderBy>>,
  where?: Maybe<CountryBoolExp>
}


export interface QueryRootCountryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootCurrenciesToOrganizationsArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface QueryRootCurrenciesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface QueryRootCurrenciesToOrganizationsByPkArgs {
  currency_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface QueryRootCurrencyArgs {
  distinct_on?: Maybe<Array<CurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyOrderBy>>,
  where?: Maybe<CurrencyBoolExp>
}


export interface QueryRootCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<CurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyOrderBy>>,
  where?: Maybe<CurrencyBoolExp>
}


export interface QueryRootCurrencyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootCurrencyGridViewArgs {
  distinct_on?: Maybe<Array<CurrencyGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyGridViewOrderBy>>,
  where?: Maybe<CurrencyGridViewBoolExp>
}


export interface QueryRootCurrencyGridViewAggregateArgs {
  distinct_on?: Maybe<Array<CurrencyGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyGridViewOrderBy>>,
  where?: Maybe<CurrencyGridViewBoolExp>
}


export interface QueryRootDailyAccountRevenueSummaryArgs {
  distinct_on?: Maybe<Array<DailyAccountRevenueSummarySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountRevenueSummaryOrderBy>>,
  where?: Maybe<DailyAccountRevenueSummaryBoolExp>
}


export interface QueryRootDailyAccountRevenueSummaryAggregateArgs {
  distinct_on?: Maybe<Array<DailyAccountRevenueSummarySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountRevenueSummaryOrderBy>>,
  where?: Maybe<DailyAccountRevenueSummaryBoolExp>
}


export interface QueryRootDailyAccountRevenueSummaryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootDailyAccountStatusArgs {
  distinct_on?: Maybe<Array<DailyAccountStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountStatusOrderBy>>,
  where?: Maybe<DailyAccountStatusBoolExp>
}


export interface QueryRootDailyAccountStatusAggregateArgs {
  distinct_on?: Maybe<Array<DailyAccountStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountStatusOrderBy>>,
  where?: Maybe<DailyAccountStatusBoolExp>
}


export interface QueryRootDailyAccountStatusByPkArgs {
  account_id: Scalars['String'],
  organization_id: Scalars['String'],
  record_date: Scalars['date']
}


export interface QueryRootDailyVolumePerAccountArgs {
  distinct_on?: Maybe<Array<DailyVolumePerAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerAccountOrderBy>>,
  where?: Maybe<DailyVolumePerAccountBoolExp>
}


export interface QueryRootDailyVolumePerAccountAggregateArgs {
  distinct_on?: Maybe<Array<DailyVolumePerAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerAccountOrderBy>>,
  where?: Maybe<DailyVolumePerAccountBoolExp>
}


export interface QueryRootDailyVolumePerAccountByPkArgs {
  id: Scalars['String']
}


export interface QueryRootDailyVolumePerProfileArgs {
  distinct_on?: Maybe<Array<DailyVolumePerProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerProfileOrderBy>>,
  where?: Maybe<DailyVolumePerProfileBoolExp>
}


export interface QueryRootDailyVolumePerProfileAggregateArgs {
  distinct_on?: Maybe<Array<DailyVolumePerProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerProfileOrderBy>>,
  where?: Maybe<DailyVolumePerProfileBoolExp>
}


export interface QueryRootDailyVolumePerProfileByPkArgs {
  id: Scalars['String']
}


export interface QueryRootEmploymentStatusArgs {
  distinct_on?: Maybe<Array<EmploymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<EmploymentStatusOrderBy>>,
  where?: Maybe<EmploymentStatusBoolExp>
}


export interface QueryRootEmploymentStatusAggregateArgs {
  distinct_on?: Maybe<Array<EmploymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<EmploymentStatusOrderBy>>,
  where?: Maybe<EmploymentStatusBoolExp>
}


export interface QueryRootEmploymentStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootExperienceTypeArgs {
  distinct_on?: Maybe<Array<ExperienceTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ExperienceTypeOrderBy>>,
  where?: Maybe<ExperienceTypeBoolExp>
}


export interface QueryRootExperienceTypeAggregateArgs {
  distinct_on?: Maybe<Array<ExperienceTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ExperienceTypeOrderBy>>,
  where?: Maybe<ExperienceTypeBoolExp>
}


export interface QueryRootExperienceTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootFileArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}


export interface QueryRootFileAggregateArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}


export interface QueryRootFileByPkArgs {
  id: Scalars['String']
}


export interface QueryRootFileTypeArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface QueryRootFileTypeAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface QueryRootFileTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootFileTypeCategoryArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface QueryRootFileTypeCategoryAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface QueryRootFileTypeCategoryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootFileTypeGridViewArgs {
  distinct_on?: Maybe<Array<FileTypeGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeGridViewOrderBy>>,
  where?: Maybe<FileTypeGridViewBoolExp>
}


export interface QueryRootFileTypeGridViewAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeGridViewOrderBy>>,
  where?: Maybe<FileTypeGridViewBoolExp>
}


export interface QueryRootFilesToOrganizationsArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface QueryRootFilesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface QueryRootFilesToOrganizationsByPkArgs {
  file_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface QueryRootFilesToStakeholdersArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface QueryRootFilesToStakeholdersAggregateArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface QueryRootFilesToStakeholdersByPkArgs {
  file_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface QueryRootGenderArgs {
  distinct_on?: Maybe<Array<GenderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<GenderOrderBy>>,
  where?: Maybe<GenderBoolExp>
}


export interface QueryRootGenderAggregateArgs {
  distinct_on?: Maybe<Array<GenderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<GenderOrderBy>>,
  where?: Maybe<GenderBoolExp>
}


export interface QueryRootGenderByPkArgs {
  id: Scalars['String']
}


export interface QueryRootIdentificationTypeArgs {
  distinct_on?: Maybe<Array<IdentificationTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IdentificationTypeOrderBy>>,
  where?: Maybe<IdentificationTypeBoolExp>
}


export interface QueryRootIdentificationTypeAggregateArgs {
  distinct_on?: Maybe<Array<IdentificationTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IdentificationTypeOrderBy>>,
  where?: Maybe<IdentificationTypeBoolExp>
}


export interface QueryRootIdentificationTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootIndividualPartnerAccountGroupDefaultArgs {
  distinct_on?: Maybe<Array<IndividualPartnerAccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndividualPartnerAccountGroupDefaultOrderBy>>,
  where?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>
}


export interface QueryRootIndividualPartnerAccountGroupDefaultAggregateArgs {
  distinct_on?: Maybe<Array<IndividualPartnerAccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndividualPartnerAccountGroupDefaultOrderBy>>,
  where?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>
}


export interface QueryRootIndividualPartnerAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface QueryRootIndustryArgs {
  distinct_on?: Maybe<Array<IndustrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndustryOrderBy>>,
  where?: Maybe<IndustryBoolExp>
}


export interface QueryRootIndustryAggregateArgs {
  distinct_on?: Maybe<Array<IndustrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndustryOrderBy>>,
  where?: Maybe<IndustryBoolExp>
}


export interface QueryRootIndustryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootIntermediaryBankArgs {
  distinct_on?: Maybe<Array<IntermediaryBankSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IntermediaryBankOrderBy>>,
  where?: Maybe<IntermediaryBankBoolExp>
}


export interface QueryRootIntermediaryBankAggregateArgs {
  distinct_on?: Maybe<Array<IntermediaryBankSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IntermediaryBankOrderBy>>,
  where?: Maybe<IntermediaryBankBoolExp>
}


export interface QueryRootIntermediaryBankByPkArgs {
  id: Scalars['String']
}


export interface QueryRootInvestmentObjectiveArgs {
  distinct_on?: Maybe<Array<InvestmentObjectiveSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<InvestmentObjectiveOrderBy>>,
  where?: Maybe<InvestmentObjectiveBoolExp>
}


export interface QueryRootInvestmentObjectiveAggregateArgs {
  distinct_on?: Maybe<Array<InvestmentObjectiveSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<InvestmentObjectiveOrderBy>>,
  where?: Maybe<InvestmentObjectiveBoolExp>
}


export interface QueryRootInvestmentObjectiveByPkArgs {
  id: Scalars['String']
}


export interface QueryRootLeverageArgs {
  distinct_on?: Maybe<Array<LeverageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageOrderBy>>,
  where?: Maybe<LeverageBoolExp>
}


export interface QueryRootLeverageAggregateArgs {
  distinct_on?: Maybe<Array<LeverageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageOrderBy>>,
  where?: Maybe<LeverageBoolExp>
}


export interface QueryRootLeverageByPkArgs {
  ratio: Scalars['Int']
}


export interface QueryRootLeverageGridViewArgs {
  distinct_on?: Maybe<Array<LeverageGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageGridViewOrderBy>>,
  where?: Maybe<LeverageGridViewBoolExp>
}


export interface QueryRootLeverageGridViewAggregateArgs {
  distinct_on?: Maybe<Array<LeverageGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageGridViewOrderBy>>,
  where?: Maybe<LeverageGridViewBoolExp>
}


export interface QueryRootLeveragesToPlatformServerTypesArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface QueryRootLeveragesToPlatformServerTypesAggregateArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface QueryRootLeveragesToPlatformServerTypesByPkArgs {
  id: Scalars['String']
}


export interface QueryRootLiquidAssetsArgs {
  distinct_on?: Maybe<Array<LiquidAssetsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LiquidAssetsOrderBy>>,
  where?: Maybe<LiquidAssetsBoolExp>
}


export interface QueryRootLiquidAssetsAggregateArgs {
  distinct_on?: Maybe<Array<LiquidAssetsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LiquidAssetsOrderBy>>,
  where?: Maybe<LiquidAssetsBoolExp>
}


export interface QueryRootLiquidAssetsByPkArgs {
  id: Scalars['String']
}


export interface QueryRootLocaleArgs {
  distinct_on?: Maybe<Array<LocaleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocaleOrderBy>>,
  where?: Maybe<LocaleBoolExp>
}


export interface QueryRootLocaleAggregateArgs {
  distinct_on?: Maybe<Array<LocaleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocaleOrderBy>>,
  where?: Maybe<LocaleBoolExp>
}


export interface QueryRootLocaleByPkArgs {
  id: Scalars['String']
}


export interface QueryRootLocalesToOrganizationsArgs {
  distinct_on?: Maybe<Array<LocalesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocalesToOrganizationsOrderBy>>,
  where?: Maybe<LocalesToOrganizationsBoolExp>
}


export interface QueryRootLocalesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<LocalesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocalesToOrganizationsOrderBy>>,
  where?: Maybe<LocalesToOrganizationsBoolExp>
}


export interface QueryRootLocalesToOrganizationsByPkArgs {
  locale_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface QueryRootLookupViewArgs {
  distinct_on?: Maybe<Array<LookupViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LookupViewOrderBy>>,
  where?: Maybe<LookupViewBoolExp>
}


export interface QueryRootLookupViewAggregateArgs {
  distinct_on?: Maybe<Array<LookupViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LookupViewOrderBy>>,
  where?: Maybe<LookupViewBoolExp>
}


export interface QueryRootMarginTypeArgs {
  distinct_on?: Maybe<Array<MarginTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MarginTypeOrderBy>>,
  where?: Maybe<MarginTypeBoolExp>
}


export interface QueryRootMarginTypeAggregateArgs {
  distinct_on?: Maybe<Array<MarginTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MarginTypeOrderBy>>,
  where?: Maybe<MarginTypeBoolExp>
}


export interface QueryRootMarginTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterAdminClientPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminClientPermissionOrderBy>>,
  where?: Maybe<MasterAdminClientPermissionBoolExp>
}


export interface QueryRootMasterAdminClientPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminClientPermissionOrderBy>>,
  where?: Maybe<MasterAdminClientPermissionBoolExp>
}


export interface QueryRootMasterAdminClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterAdminLeadPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminLeadPermissionOrderBy>>,
  where?: Maybe<MasterAdminLeadPermissionBoolExp>
}


export interface QueryRootMasterAdminLeadPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminLeadPermissionOrderBy>>,
  where?: Maybe<MasterAdminLeadPermissionBoolExp>
}


export interface QueryRootMasterAdminLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterAdminPartnerPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminPartnerPermissionOrderBy>>,
  where?: Maybe<MasterAdminPartnerPermissionBoolExp>
}


export interface QueryRootMasterAdminPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminPartnerPermissionOrderBy>>,
  where?: Maybe<MasterAdminPartnerPermissionBoolExp>
}


export interface QueryRootMasterAdminPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterAdminStakeholderPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterAdminStakeholderPermissionBoolExp>
}


export interface QueryRootMasterAdminStakeholderPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterAdminStakeholderPermissionBoolExp>
}


export interface QueryRootMasterAdminStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterAdminSystemPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminSystemPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminSystemPermissionOrderBy>>,
  where?: Maybe<MasterAdminSystemPermissionBoolExp>
}


export interface QueryRootMasterAdminSystemPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminSystemPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminSystemPermissionOrderBy>>,
  where?: Maybe<MasterAdminSystemPermissionBoolExp>
}


export interface QueryRootMasterAdminSystemPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterPartnerClientPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerClientPermissionOrderBy>>,
  where?: Maybe<MasterPartnerClientPermissionBoolExp>
}


export interface QueryRootMasterPartnerClientPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerClientPermissionOrderBy>>,
  where?: Maybe<MasterPartnerClientPermissionBoolExp>
}


export interface QueryRootMasterPartnerClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterPartnerLeadPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerLeadPermissionOrderBy>>,
  where?: Maybe<MasterPartnerLeadPermissionBoolExp>
}


export interface QueryRootMasterPartnerLeadPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerLeadPermissionOrderBy>>,
  where?: Maybe<MasterPartnerLeadPermissionBoolExp>
}


export interface QueryRootMasterPartnerLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterPartnerPartnerPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerPartnerPermissionOrderBy>>,
  where?: Maybe<MasterPartnerPartnerPermissionBoolExp>
}


export interface QueryRootMasterPartnerPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerPartnerPermissionOrderBy>>,
  where?: Maybe<MasterPartnerPartnerPermissionBoolExp>
}


export interface QueryRootMasterPartnerPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterPartnerStakeholderPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterPartnerStakeholderPermissionBoolExp>
}


export interface QueryRootMasterPartnerStakeholderPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterPartnerStakeholderPermissionBoolExp>
}


export interface QueryRootMasterPartnerStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootMasterUserPermissionArgs {
  distinct_on?: Maybe<Array<MasterUserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterUserPermissionOrderBy>>,
  where?: Maybe<MasterUserPermissionBoolExp>
}


export interface QueryRootMasterUserPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterUserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterUserPermissionOrderBy>>,
  where?: Maybe<MasterUserPermissionBoolExp>
}


export interface QueryRootMasterUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootNameTitleArgs {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NameTitleOrderBy>>,
  where?: Maybe<NameTitleBoolExp>
}


export interface QueryRootNameTitleAggregateArgs {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NameTitleOrderBy>>,
  where?: Maybe<NameTitleBoolExp>
}


export interface QueryRootNameTitleByPkArgs {
  id: Scalars['String']
}


export interface QueryRootNotificationArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface QueryRootNotificationAggregateArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface QueryRootNotificationByPkArgs {
  id: Scalars['String']
}


export interface QueryRootOrganizationArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}


export interface QueryRootOrganizationAggregateArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}


export interface QueryRootOrganizationByPkArgs {
  id: Scalars['String']
}


export interface QueryRootOriginArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface QueryRootOriginAggregateArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface QueryRootOriginByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPartnerAccountGroupGridViewArgs {
  distinct_on?: Maybe<Array<PartnerAccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerAccountGroupGridViewOrderBy>>,
  where?: Maybe<PartnerAccountGroupGridViewBoolExp>
}


export interface QueryRootPartnerAccountGroupGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PartnerAccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerAccountGroupGridViewOrderBy>>,
  where?: Maybe<PartnerAccountGroupGridViewBoolExp>
}


export interface QueryRootPartnerPermissionArgs {
  distinct_on?: Maybe<Array<PartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerPermissionOrderBy>>,
  where?: Maybe<PartnerPermissionBoolExp>
}


export interface QueryRootPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<PartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerPermissionOrderBy>>,
  where?: Maybe<PartnerPermissionBoolExp>
}


export interface QueryRootPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface QueryRootPaymentAggregateArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface QueryRootPaymentAttributeGlobalIdentifierArgs {
  distinct_on?: Maybe<Array<PaymentAttributeGlobalIdentifierSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeGlobalIdentifierOrderBy>>,
  where?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>
}


export interface QueryRootPaymentAttributeGlobalIdentifierAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeGlobalIdentifierSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeGlobalIdentifierOrderBy>>,
  where?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>
}


export interface QueryRootPaymentAttributeGlobalIdentifierByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface QueryRootPaymentAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface QueryRootPaymentAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSectionOrderBy>>,
  where?: Maybe<PaymentAttributeSectionBoolExp>
}


export interface QueryRootPaymentAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSectionOrderBy>>,
  where?: Maybe<PaymentAttributeSectionBoolExp>
}


export interface QueryRootPaymentAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentAttributeValueArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}


export interface QueryRootPaymentAttributeValueAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}


export interface QueryRootPaymentAttributeValueByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface QueryRootPaymentMethodAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface QueryRootPaymentMethodAttributeArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface QueryRootPaymentMethodAttributeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface QueryRootPaymentMethodAttributeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface QueryRootPaymentMethodAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface QueryRootPaymentMethodAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}


export interface QueryRootPaymentMethodAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}


export interface QueryRootPaymentMethodAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentMethodAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentMethodAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodGridViewArgs {
  distinct_on?: Maybe<Array<PaymentMethodGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodGridViewOrderBy>>,
  where?: Maybe<PaymentMethodGridViewBoolExp>
}


export interface QueryRootPaymentMethodGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodGridViewOrderBy>>,
  where?: Maybe<PaymentMethodGridViewBoolExp>
}


export interface QueryRootPaymentMethodLogoArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface QueryRootPaymentMethodLogoAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface QueryRootPaymentMethodLogoByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface QueryRootPaymentMethodTypeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}


export interface QueryRootPaymentMethodTypeAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentMethodTypeCurrencyArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}


export interface QueryRootPaymentMethodTypeCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}


export interface QueryRootPaymentMethodTypeCurrencyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentOrientationArgs {
  distinct_on?: Maybe<Array<PaymentOrientationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrientationOrderBy>>,
  where?: Maybe<PaymentOrientationBoolExp>
}


export interface QueryRootPaymentOrientationAggregateArgs {
  distinct_on?: Maybe<Array<PaymentOrientationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrientationOrderBy>>,
  where?: Maybe<PaymentOrientationBoolExp>
}


export interface QueryRootPaymentOrientationByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentProviderArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface QueryRootPaymentProviderAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface QueryRootPaymentProviderByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentProviderIntegrationModeArgs {
  distinct_on?: Maybe<Array<PaymentProviderIntegrationModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderIntegrationModeOrderBy>>,
  where?: Maybe<PaymentProviderIntegrationModeBoolExp>
}


export interface QueryRootPaymentProviderIntegrationModeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderIntegrationModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderIntegrationModeOrderBy>>,
  where?: Maybe<PaymentProviderIntegrationModeBoolExp>
}


export interface QueryRootPaymentProviderIntegrationModeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentProviderMethodTypeArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface QueryRootPaymentProviderMethodTypeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface QueryRootPaymentProviderMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPaymentStatusArgs {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentStatusOrderBy>>,
  where?: Maybe<PaymentStatusBoolExp>
}


export interface QueryRootPaymentStatusAggregateArgs {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentStatusOrderBy>>,
  where?: Maybe<PaymentStatusBoolExp>
}


export interface QueryRootPaymentStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPhoneCodeArgs {
  distinct_on?: Maybe<Array<PhoneCodeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneCodeOrderBy>>,
  where?: Maybe<PhoneCodeBoolExp>
}


export interface QueryRootPhoneCodeAggregateArgs {
  distinct_on?: Maybe<Array<PhoneCodeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneCodeOrderBy>>,
  where?: Maybe<PhoneCodeBoolExp>
}


export interface QueryRootPhoneCodeByPkArgs {
  code: Scalars['String']
}


export interface QueryRootPhoneTypeArgs {
  distinct_on?: Maybe<Array<PhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneTypeOrderBy>>,
  where?: Maybe<PhoneTypeBoolExp>
}


export interface QueryRootPhoneTypeAggregateArgs {
  distinct_on?: Maybe<Array<PhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneTypeOrderBy>>,
  where?: Maybe<PhoneTypeBoolExp>
}


export interface QueryRootPhoneTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformAccessArgs {
  distinct_on?: Maybe<Array<PlatformAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformAccessOrderBy>>,
  where?: Maybe<PlatformAccessBoolExp>
}


export interface QueryRootPlatformAccessAggregateArgs {
  distinct_on?: Maybe<Array<PlatformAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformAccessOrderBy>>,
  where?: Maybe<PlatformAccessBoolExp>
}


export interface QueryRootPlatformAccessByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServerArgs {
  distinct_on?: Maybe<Array<PlatformServerSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerOrderBy>>,
  where?: Maybe<PlatformServerBoolExp>
}


export interface QueryRootPlatformServerAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerOrderBy>>,
  where?: Maybe<PlatformServerBoolExp>
}


export interface QueryRootPlatformServerByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServerEnvironmentArgs {
  distinct_on?: Maybe<Array<PlatformServerEnvironmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerEnvironmentOrderBy>>,
  where?: Maybe<PlatformServerEnvironmentBoolExp>
}


export interface QueryRootPlatformServerEnvironmentAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerEnvironmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerEnvironmentOrderBy>>,
  where?: Maybe<PlatformServerEnvironmentBoolExp>
}


export interface QueryRootPlatformServerEnvironmentByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServerGridViewArgs {
  distinct_on?: Maybe<Array<PlatformServerGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerGridViewOrderBy>>,
  where?: Maybe<PlatformServerGridViewBoolExp>
}


export interface QueryRootPlatformServerGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerGridViewOrderBy>>,
  where?: Maybe<PlatformServerGridViewBoolExp>
}


export interface QueryRootPlatformServerRetryStatusArgs {
  distinct_on?: Maybe<Array<PlatformServerRetryStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerRetryStatusOrderBy>>,
  where?: Maybe<PlatformServerRetryStatusBoolExp>
}


export interface QueryRootPlatformServerRetryStatusAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerRetryStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerRetryStatusOrderBy>>,
  where?: Maybe<PlatformServerRetryStatusBoolExp>
}


export interface QueryRootPlatformServerRetryStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServerStatusArgs {
  distinct_on?: Maybe<Array<PlatformServerStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerStatusOrderBy>>,
  where?: Maybe<PlatformServerStatusBoolExp>
}


export interface QueryRootPlatformServerStatusAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerStatusOrderBy>>,
  where?: Maybe<PlatformServerStatusBoolExp>
}


export interface QueryRootPlatformServerStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServerTypeArgs {
  distinct_on?: Maybe<Array<PlatformServerTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerTypeOrderBy>>,
  where?: Maybe<PlatformServerTypeBoolExp>
}


export interface QueryRootPlatformServerTypeAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerTypeOrderBy>>,
  where?: Maybe<PlatformServerTypeBoolExp>
}


export interface QueryRootPlatformServerTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootPlatformServersToSupportedLeveragesArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface QueryRootPlatformServersToSupportedLeveragesAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface QueryRootPlatformServersToSupportedLeveragesByPkArgs {
  platform_server_id: Scalars['String'],
  supported_leverage_id: Scalars['String']
}


export interface QueryRootPlatformSymbolArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface QueryRootPlatformSymbolAggregateArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface QueryRootPlatformSymbolByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface QueryRootProfileActivityArgs {
  distinct_on?: Maybe<Array<ProfileActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileActivityOrderBy>>,
  where?: Maybe<ProfileActivityBoolExp>
}


export interface QueryRootProfileActivityAggregateArgs {
  distinct_on?: Maybe<Array<ProfileActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileActivityOrderBy>>,
  where?: Maybe<ProfileActivityBoolExp>
}


export interface QueryRootProfileActivityByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileAggregateArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface QueryRootProfileByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileCategoryArgs {
  distinct_on?: Maybe<Array<ProfileCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileCategoryOrderBy>>,
  where?: Maybe<ProfileCategoryBoolExp>
}


export interface QueryRootProfileCategoryAggregateArgs {
  distinct_on?: Maybe<Array<ProfileCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileCategoryOrderBy>>,
  where?: Maybe<ProfileCategoryBoolExp>
}


export interface QueryRootProfileCategoryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileEntryTypeArgs {
  distinct_on?: Maybe<Array<ProfileEntryTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileEntryTypeOrderBy>>,
  where?: Maybe<ProfileEntryTypeBoolExp>
}


export interface QueryRootProfileEntryTypeAggregateArgs {
  distinct_on?: Maybe<Array<ProfileEntryTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileEntryTypeOrderBy>>,
  where?: Maybe<ProfileEntryTypeBoolExp>
}


export interface QueryRootProfileEntryTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileFlagsArgs {
  distinct_on?: Maybe<Array<ProfileFlagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileFlagsOrderBy>>,
  where?: Maybe<ProfileFlagsBoolExp>
}


export interface QueryRootProfileFlagsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileFlagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileFlagsOrderBy>>,
  where?: Maybe<ProfileFlagsBoolExp>
}


export interface QueryRootProfileFlagsByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileGridViewArgs {
  distinct_on?: Maybe<Array<ProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileGridViewOrderBy>>,
  where?: Maybe<ProfileGridViewBoolExp>
}


export interface QueryRootProfileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<ProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileGridViewOrderBy>>,
  where?: Maybe<ProfileGridViewBoolExp>
}


export interface QueryRootProfileNoteArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface QueryRootProfileNoteAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface QueryRootProfileNoteByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileNoteVersionArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}


export interface QueryRootProfileNoteVersionAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}


export interface QueryRootProfileNoteVersionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileNoteVersionsStakeholderMentionsArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface QueryRootProfileNoteVersionsStakeholderMentionsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface QueryRootProfileNoteVersionsStakeholderMentionsByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface QueryRootProfileNoteVersionsStakeholderRelevantPartiesArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}


export interface QueryRootProfileNoteVersionsStakeholderRelevantPartiesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}


export interface QueryRootProfileNoteVersionsStakeholderRelevantPartiesByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface QueryRootProfileRiskArgs {
  distinct_on?: Maybe<Array<ProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileRiskOrderBy>>,
  where?: Maybe<ProfileRiskBoolExp>
}


export interface QueryRootProfileRiskAggregateArgs {
  distinct_on?: Maybe<Array<ProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileRiskOrderBy>>,
  where?: Maybe<ProfileRiskBoolExp>
}


export interface QueryRootProfileRiskByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileStatusArgs {
  distinct_on?: Maybe<Array<ProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileStatusOrderBy>>,
  where?: Maybe<ProfileStatusBoolExp>
}


export interface QueryRootProfileStatusAggregateArgs {
  distinct_on?: Maybe<Array<ProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileStatusOrderBy>>,
  where?: Maybe<ProfileStatusBoolExp>
}


export interface QueryRootProfileStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfileTypeArgs {
  distinct_on?: Maybe<Array<ProfileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileTypeOrderBy>>,
  where?: Maybe<ProfileTypeBoolExp>
}


export interface QueryRootProfileTypeAggregateArgs {
  distinct_on?: Maybe<Array<ProfileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileTypeOrderBy>>,
  where?: Maybe<ProfileTypeBoolExp>
}


export interface QueryRootProfileTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootProfilesToTagsArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface QueryRootProfilesToTagsAggregateArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface QueryRootProfilesToTagsByPkArgs {
  profile_id: Scalars['String'],
  tag_id: Scalars['String']
}


export interface QueryRootRandomCountryArgs {
  distinct_on?: Maybe<Array<RandomCountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCountryOrderBy>>,
  where?: Maybe<RandomCountryBoolExp>
}


export interface QueryRootRandomCountryAggregateArgs {
  distinct_on?: Maybe<Array<RandomCountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCountryOrderBy>>,
  where?: Maybe<RandomCountryBoolExp>
}


export interface QueryRootRandomCurrencyArgs {
  distinct_on?: Maybe<Array<RandomCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCurrencyOrderBy>>,
  where?: Maybe<RandomCurrencyBoolExp>
}


export interface QueryRootRandomCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<RandomCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCurrencyOrderBy>>,
  where?: Maybe<RandomCurrencyBoolExp>
}


export interface QueryRootRandomPhoneTypeArgs {
  distinct_on?: Maybe<Array<RandomPhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomPhoneTypeOrderBy>>,
  where?: Maybe<RandomPhoneTypeBoolExp>
}


export interface QueryRootRandomPhoneTypeAggregateArgs {
  distinct_on?: Maybe<Array<RandomPhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomPhoneTypeOrderBy>>,
  where?: Maybe<RandomPhoneTypeBoolExp>
}


export interface QueryRootRandomProfileArgs {
  distinct_on?: Maybe<Array<RandomProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileOrderBy>>,
  where?: Maybe<RandomProfileBoolExp>
}


export interface QueryRootRandomProfileAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileOrderBy>>,
  where?: Maybe<RandomProfileBoolExp>
}


export interface QueryRootRandomProfileRiskArgs {
  distinct_on?: Maybe<Array<RandomProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileRiskOrderBy>>,
  where?: Maybe<RandomProfileRiskBoolExp>
}


export interface QueryRootRandomProfileRiskAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileRiskOrderBy>>,
  where?: Maybe<RandomProfileRiskBoolExp>
}


export interface QueryRootRandomProfileStatusArgs {
  distinct_on?: Maybe<Array<RandomProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileStatusOrderBy>>,
  where?: Maybe<RandomProfileStatusBoolExp>
}


export interface QueryRootRandomProfileStatusAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileStatusOrderBy>>,
  where?: Maybe<RandomProfileStatusBoolExp>
}


export interface QueryRootRandomTagArgs {
  distinct_on?: Maybe<Array<RandomTagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomTagOrderBy>>,
  where?: Maybe<RandomTagBoolExp>
}


export interface QueryRootRandomTagAggregateArgs {
  distinct_on?: Maybe<Array<RandomTagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomTagOrderBy>>,
  where?: Maybe<RandomTagBoolExp>
}


export interface QueryRootRepresentativesToProfilesArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface QueryRootRepresentativesToProfilesAggregateArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface QueryRootRepresentativesToProfilesByPkArgs {
  admin_stake_id: Scalars['String'],
  profile_id: Scalars['String']
}


export interface QueryRootRequestArgs {
  distinct_on?: Maybe<Array<RequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestOrderBy>>,
  where?: Maybe<RequestBoolExp>
}


export interface QueryRootRequestAggregateArgs {
  distinct_on?: Maybe<Array<RequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestOrderBy>>,
  where?: Maybe<RequestBoolExp>
}


export interface QueryRootRequestByPkArgs {
  id: Scalars['String']
}


export interface QueryRootRequestGridViewArgs {
  distinct_on?: Maybe<Array<RequestGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestGridViewOrderBy>>,
  where?: Maybe<RequestGridViewBoolExp>
}


export interface QueryRootRequestGridViewAggregateArgs {
  distinct_on?: Maybe<Array<RequestGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestGridViewOrderBy>>,
  where?: Maybe<RequestGridViewBoolExp>
}


export interface QueryRootRequestStatusArgs {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestStatusOrderBy>>,
  where?: Maybe<RequestStatusBoolExp>
}


export interface QueryRootRequestStatusAggregateArgs {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestStatusOrderBy>>,
  where?: Maybe<RequestStatusBoolExp>
}


export interface QueryRootRequestStatusByPkArgs {
  id: Scalars['Int']
}


export interface QueryRootRequestTypeArgs {
  distinct_on?: Maybe<Array<RequestTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestTypeOrderBy>>,
  where?: Maybe<RequestTypeBoolExp>
}


export interface QueryRootRequestTypeAggregateArgs {
  distinct_on?: Maybe<Array<RequestTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestTypeOrderBy>>,
  where?: Maybe<RequestTypeBoolExp>
}


export interface QueryRootRequestTypeByPkArgs {
  id: Scalars['Int']
}


export interface QueryRootSecurityArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface QueryRootSecurityAggregateArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface QueryRootSecurityByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSecurityGridViewArgs {
  distinct_on?: Maybe<Array<SecurityGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityGridViewOrderBy>>,
  where?: Maybe<SecurityGridViewBoolExp>
}


export interface QueryRootSecurityGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SecurityGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityGridViewOrderBy>>,
  where?: Maybe<SecurityGridViewBoolExp>
}


export interface QueryRootSegmentArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface QueryRootSegmentAggregateArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface QueryRootSegmentByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSegmentConditionArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}


export interface QueryRootSegmentConditionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}


export interface QueryRootSegmentConditionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSegmentCriterionArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface QueryRootSegmentCriterionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface QueryRootSegmentCriterionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSegmentCriterionTypeArgs {
  distinct_on?: Maybe<Array<SegmentCriterionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionTypeOrderBy>>,
  where?: Maybe<SegmentCriterionTypeBoolExp>
}


export interface QueryRootSegmentCriterionTypeAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionTypeOrderBy>>,
  where?: Maybe<SegmentCriterionTypeBoolExp>
}


export interface QueryRootSegmentCriterionTypeByPkArgs {
  name: Scalars['String']
}


export interface QueryRootSegmentGridViewArgs {
  distinct_on?: Maybe<Array<SegmentGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentGridViewOrderBy>>,
  where?: Maybe<SegmentGridViewBoolExp>
}


export interface QueryRootSegmentGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SegmentGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentGridViewOrderBy>>,
  where?: Maybe<SegmentGridViewBoolExp>
}


export interface QueryRootSegmentOperatorArgs {
  distinct_on?: Maybe<Array<SegmentOperatorSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorOrderBy>>,
  where?: Maybe<SegmentOperatorBoolExp>
}


export interface QueryRootSegmentOperatorAggregateArgs {
  distinct_on?: Maybe<Array<SegmentOperatorSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorOrderBy>>,
  where?: Maybe<SegmentOperatorBoolExp>
}


export interface QueryRootSegmentOperatorByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSegmentOperatorsToSegmentPropertyConditionsArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}


export interface QueryRootSegmentOperatorsToSegmentPropertyConditionsAggregateArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}


export interface QueryRootSegmentOperatorsToSegmentPropertyConditionsByPkArgs {
  operator_id: Scalars['String'],
  property_condition_id: Scalars['String']
}


export interface QueryRootSegmentPropertyArgs {
  distinct_on?: Maybe<Array<SegmentPropertySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyOrderBy>>,
  where?: Maybe<SegmentPropertyBoolExp>
}


export interface QueryRootSegmentPropertyAggregateArgs {
  distinct_on?: Maybe<Array<SegmentPropertySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyOrderBy>>,
  where?: Maybe<SegmentPropertyBoolExp>
}


export interface QueryRootSegmentPropertyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSegmentPropertyConditionArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}


export interface QueryRootSegmentPropertyConditionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}


export interface QueryRootSegmentPropertyConditionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSharedFileGridViewArgs {
  distinct_on?: Maybe<Array<SharedFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SharedFileGridViewOrderBy>>,
  where?: Maybe<SharedFileGridViewBoolExp>
}


export interface QueryRootSharedFileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SharedFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SharedFileGridViewOrderBy>>,
  where?: Maybe<SharedFileGridViewBoolExp>
}


export interface QueryRootSourceOfFundsArgs {
  distinct_on?: Maybe<Array<SourceOfFundsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SourceOfFundsOrderBy>>,
  where?: Maybe<SourceOfFundsBoolExp>
}


export interface QueryRootSourceOfFundsAggregateArgs {
  distinct_on?: Maybe<Array<SourceOfFundsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SourceOfFundsOrderBy>>,
  where?: Maybe<SourceOfFundsBoolExp>
}


export interface QueryRootSourceOfFundsByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSpreadModeArgs {
  distinct_on?: Maybe<Array<SpreadModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SpreadModeOrderBy>>,
  where?: Maybe<SpreadModeBoolExp>
}


export interface QueryRootSpreadModeAggregateArgs {
  distinct_on?: Maybe<Array<SpreadModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SpreadModeOrderBy>>,
  where?: Maybe<SpreadModeBoolExp>
}


export interface QueryRootSpreadModeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface QueryRootStakeAggregateArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface QueryRootStakeAncestryArgs {
  distinct_on?: Maybe<Array<StakeAncestrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeAncestryOrderBy>>,
  where?: Maybe<StakeAncestryBoolExp>
}


export interface QueryRootStakeAncestryAggregateArgs {
  distinct_on?: Maybe<Array<StakeAncestrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeAncestryOrderBy>>,
  where?: Maybe<StakeAncestryBoolExp>
}


export interface QueryRootStakeAncestryByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeTitleArgs {
  distinct_on?: Maybe<Array<StakeTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeTitleOrderBy>>,
  where?: Maybe<StakeTitleBoolExp>
}


export interface QueryRootStakeTitleAggregateArgs {
  distinct_on?: Maybe<Array<StakeTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeTitleOrderBy>>,
  where?: Maybe<StakeTitleBoolExp>
}


export interface QueryRootStakeTitleByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface QueryRootStakeholderAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface QueryRootStakeholderAttributeArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface QueryRootStakeholderAttributeAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface QueryRootStakeholderAttributeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderAttributeKeyArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface QueryRootStakeholderAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface QueryRootStakeholderAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderAttributeKeysToStakeholderClassesArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface QueryRootStakeholderAttributeKeysToStakeholderClassesAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface QueryRootStakeholderAttributeKeysToStakeholderClassesByPkArgs {
  stakeholder_attribute_key_id: Scalars['String'],
  stakeholder_class_id: Scalars['String']
}


export interface QueryRootStakeholderAttributePivotArgs {
  distinct_on?: Maybe<Array<StakeholderAttributePivotSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributePivotOrderBy>>,
  where?: Maybe<StakeholderAttributePivotBoolExp>
}


export interface QueryRootStakeholderAttributePivotAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributePivotSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributePivotOrderBy>>,
  where?: Maybe<StakeholderAttributePivotBoolExp>
}


export interface QueryRootStakeholderAttributeSectionArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSectionBoolExp>
}


export interface QueryRootStakeholderAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSectionBoolExp>
}


export interface QueryRootStakeholderAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}


export interface QueryRootStakeholderAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}


export interface QueryRootStakeholderAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderClassArgs {
  distinct_on?: Maybe<Array<StakeholderClassSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderClassOrderBy>>,
  where?: Maybe<StakeholderClassBoolExp>
}


export interface QueryRootStakeholderClassAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderClassSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderClassOrderBy>>,
  where?: Maybe<StakeholderClassBoolExp>
}


export interface QueryRootStakeholderClassByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakeholderFileGridViewArgs {
  distinct_on?: Maybe<Array<StakeholderFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderFileGridViewOrderBy>>,
  where?: Maybe<StakeholderFileGridViewBoolExp>
}


export interface QueryRootStakeholderFileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderFileGridViewOrderBy>>,
  where?: Maybe<StakeholderFileGridViewBoolExp>
}


export interface QueryRootStakeholderNameArgs {
  distinct_on?: Maybe<Array<StakeholderNameSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderNameOrderBy>>,
  where?: Maybe<StakeholderNameBoolExp>
}


export interface QueryRootStakeholderNameAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderNameSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderNameOrderBy>>,
  where?: Maybe<StakeholderNameBoolExp>
}


export interface QueryRootStakesToAdminPermissionsArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface QueryRootStakesToAdminPermissionsAggregateArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface QueryRootStakesToAdminPermissionsByPkArgs {
  id: Scalars['String']
}


export interface QueryRootStakesToStakeTitlesArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}


export interface QueryRootStakesToStakeTitlesAggregateArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}


export interface QueryRootStakesToStakeTitlesByPkArgs {
  stake_id: Scalars['String'],
  stake_title_id: Scalars['String']
}


export interface QueryRootSwapModeArgs {
  distinct_on?: Maybe<Array<SwapModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SwapModeOrderBy>>,
  where?: Maybe<SwapModeBoolExp>
}


export interface QueryRootSwapModeAggregateArgs {
  distinct_on?: Maybe<Array<SwapModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SwapModeOrderBy>>,
  where?: Maybe<SwapModeBoolExp>
}


export interface QueryRootSwapModeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSymbolArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface QueryRootSymbolAggregateArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface QueryRootSymbolByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSymbolExtensionArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface QueryRootSymbolExtensionAggregateArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface QueryRootSymbolExtensionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootSymbolGridViewArgs {
  distinct_on?: Maybe<Array<SymbolGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolGridViewOrderBy>>,
  where?: Maybe<SymbolGridViewBoolExp>
}


export interface QueryRootSymbolGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SymbolGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolGridViewOrderBy>>,
  where?: Maybe<SymbolGridViewBoolExp>
}


export interface QueryRootSymbolTypeArgs {
  distinct_on?: Maybe<Array<SymbolTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolTypeOrderBy>>,
  where?: Maybe<SymbolTypeBoolExp>
}


export interface QueryRootSymbolTypeAggregateArgs {
  distinct_on?: Maybe<Array<SymbolTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolTypeOrderBy>>,
  where?: Maybe<SymbolTypeBoolExp>
}


export interface QueryRootSymbolTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTagArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface QueryRootTagAggregateArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface QueryRootTagByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTradeRuleArgs {
  distinct_on?: Maybe<Array<TradeRuleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TradeRuleOrderBy>>,
  where?: Maybe<TradeRuleBoolExp>
}


export interface QueryRootTradeRuleAggregateArgs {
  distinct_on?: Maybe<Array<TradeRuleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TradeRuleOrderBy>>,
  where?: Maybe<TradeRuleBoolExp>
}


export interface QueryRootTradeRuleByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransactionArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface QueryRootTransactionAggregateArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface QueryRootTransactionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransactionCreationRequestArgs {
  distinct_on?: Maybe<Array<TransactionCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionCreationRequestOrderBy>>,
  where?: Maybe<TransactionCreationRequestBoolExp>
}


export interface QueryRootTransactionCreationRequestAggregateArgs {
  distinct_on?: Maybe<Array<TransactionCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionCreationRequestOrderBy>>,
  where?: Maybe<TransactionCreationRequestBoolExp>
}


export interface QueryRootTransactionCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransactionGridViewArgs {
  distinct_on?: Maybe<Array<TransactionGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionGridViewOrderBy>>,
  where?: Maybe<TransactionGridViewBoolExp>
}


export interface QueryRootTransactionGridViewAggregateArgs {
  distinct_on?: Maybe<Array<TransactionGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionGridViewOrderBy>>,
  where?: Maybe<TransactionGridViewBoolExp>
}


export interface QueryRootTransactionPlatformStatusArgs {
  distinct_on?: Maybe<Array<TransactionPlatformStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionPlatformStatusOrderBy>>,
  where?: Maybe<TransactionPlatformStatusBoolExp>
}


export interface QueryRootTransactionPlatformStatusAggregateArgs {
  distinct_on?: Maybe<Array<TransactionPlatformStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionPlatformStatusOrderBy>>,
  where?: Maybe<TransactionPlatformStatusBoolExp>
}


export interface QueryRootTransactionPlatformStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransactionProfileGridViewArgs {
  distinct_on?: Maybe<Array<TransactionProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionProfileGridViewOrderBy>>,
  where?: Maybe<TransactionProfileGridViewBoolExp>
}


export interface QueryRootTransactionProfileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<TransactionProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionProfileGridViewOrderBy>>,
  where?: Maybe<TransactionProfileGridViewBoolExp>
}


export interface QueryRootTransactionStatusArgs {
  distinct_on?: Maybe<Array<TransactionStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionStatusOrderBy>>,
  where?: Maybe<TransactionStatusBoolExp>
}


export interface QueryRootTransactionStatusAggregateArgs {
  distinct_on?: Maybe<Array<TransactionStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionStatusOrderBy>>,
  where?: Maybe<TransactionStatusBoolExp>
}


export interface QueryRootTransactionStatusByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransactionTypeArgs {
  distinct_on?: Maybe<Array<TransactionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionTypeOrderBy>>,
  where?: Maybe<TransactionTypeBoolExp>
}


export interface QueryRootTransactionTypeAggregateArgs {
  distinct_on?: Maybe<Array<TransactionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionTypeOrderBy>>,
  where?: Maybe<TransactionTypeBoolExp>
}


export interface QueryRootTransactionTypeByPkArgs {
  id: Scalars['String']
}


export interface QueryRootTransferApprovalOptionArgs {
  distinct_on?: Maybe<Array<TransferApprovalOptionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransferApprovalOptionOrderBy>>,
  where?: Maybe<TransferApprovalOptionBoolExp>
}


export interface QueryRootTransferApprovalOptionAggregateArgs {
  distinct_on?: Maybe<Array<TransferApprovalOptionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransferApprovalOptionOrderBy>>,
  where?: Maybe<TransferApprovalOptionBoolExp>
}


export interface QueryRootTransferApprovalOptionByPkArgs {
  id: Scalars['Int']
}


export interface QueryRootUserAccessArgs {
  distinct_on?: Maybe<Array<UserAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserAccessOrderBy>>,
  where?: Maybe<UserAccessBoolExp>
}


export interface QueryRootUserAccessAggregateArgs {
  distinct_on?: Maybe<Array<UserAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserAccessOrderBy>>,
  where?: Maybe<UserAccessBoolExp>
}


export interface QueryRootUserAccessByPkArgs {
  code: Scalars['Int']
}


export interface QueryRootUserInviteArgs {
  distinct_on?: Maybe<Array<UserInviteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserInviteOrderBy>>,
  where?: Maybe<UserInviteBoolExp>
}


export interface QueryRootUserInviteAggregateArgs {
  distinct_on?: Maybe<Array<UserInviteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserInviteOrderBy>>,
  where?: Maybe<UserInviteBoolExp>
}


export interface QueryRootUserInviteByPkArgs {
  id: Scalars['String']
}


export interface QueryRootUserManagementGridViewArgs {
  distinct_on?: Maybe<Array<UserManagementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserManagementGridViewOrderBy>>,
  where?: Maybe<UserManagementGridViewBoolExp>
}


export interface QueryRootUserManagementGridViewAggregateArgs {
  distinct_on?: Maybe<Array<UserManagementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserManagementGridViewOrderBy>>,
  where?: Maybe<UserManagementGridViewBoolExp>
}


export interface QueryRootUserPermissionArgs {
  distinct_on?: Maybe<Array<UserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserPermissionOrderBy>>,
  where?: Maybe<UserPermissionBoolExp>
}


export interface QueryRootUserPermissionAggregateArgs {
  distinct_on?: Maybe<Array<UserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserPermissionOrderBy>>,
  where?: Maybe<UserPermissionBoolExp>
}


export interface QueryRootUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface QueryRootWorkflowArgs {
  distinct_on?: Maybe<Array<WorkflowSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowOrderBy>>,
  where?: Maybe<WorkflowBoolExp>
}


export interface QueryRootWorkflowAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowOrderBy>>,
  where?: Maybe<WorkflowBoolExp>
}


export interface QueryRootWorkflowByPkArgs {
  id: Scalars['String']
}


export interface QueryRootWorkflowStageArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}


export interface QueryRootWorkflowStageAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}


export interface QueryRootWorkflowStageByPkArgs {
  id: Scalars['String']
}


export interface QueryRootWorkflowsToProfilesArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface QueryRootWorkflowsToProfilesAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface QueryRootWorkflowsToProfilesByPkArgs {
  profile_id: Scalars['String'],
  workflow_id: Scalars['String']
}


export interface QueryRootYearsExperienceArgs {
  distinct_on?: Maybe<Array<YearsExperienceSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<YearsExperienceOrderBy>>,
  where?: Maybe<YearsExperienceBoolExp>
}


export interface QueryRootYearsExperienceAggregateArgs {
  distinct_on?: Maybe<Array<YearsExperienceSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<YearsExperienceOrderBy>>,
  where?: Maybe<YearsExperienceBoolExp>
}


export interface QueryRootYearsExperienceByPkArgs {
  id: Scalars['String']
}

export interface RandomCountry {
   __typename?: 'random_country',
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCountryAggregate {
   __typename?: 'random_country_aggregate',
  aggregate?: Maybe<RandomCountryAggregateFields>,
  nodes: Array<RandomCountry>,
}

export interface RandomCountryAggregateFields {
   __typename?: 'random_country_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomCountryMaxFields>,
  min?: Maybe<RandomCountryMinFields>,
}


export interface RandomCountryAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomCountrySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomCountryAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomCountryMaxOrderBy>,
  min?: Maybe<RandomCountryMinOrderBy>,
}

export interface RandomCountryBoolExp {
  _and?: Maybe<Array<Maybe<RandomCountryBoolExp>>>,
  _not?: Maybe<RandomCountryBoolExp>,
  _or?: Maybe<Array<Maybe<RandomCountryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  flag?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomCountryMaxFields {
   __typename?: 'random_country_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCountryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomCountryMinFields {
   __typename?: 'random_country_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  flag?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCountryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomCountryOrderBy {
  created_at?: Maybe<OrderBy>,
  flag?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomCountrySelectColumn {
  CreatedAt = 'created_at',
  Flag = 'flag',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface RandomCurrency {
   __typename?: 'random_currency',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCurrencyAggregate {
   __typename?: 'random_currency_aggregate',
  aggregate?: Maybe<RandomCurrencyAggregateFields>,
  nodes: Array<RandomCurrency>,
}

export interface RandomCurrencyAggregateFields {
   __typename?: 'random_currency_aggregate_fields',
  avg?: Maybe<RandomCurrencyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomCurrencyMaxFields>,
  min?: Maybe<RandomCurrencyMinFields>,
  stddev?: Maybe<RandomCurrencyStddevFields>,
  stddev_pop?: Maybe<RandomCurrencyStddevPopFields>,
  stddev_samp?: Maybe<RandomCurrencyStddevSampFields>,
  sum?: Maybe<RandomCurrencySumFields>,
  var_pop?: Maybe<RandomCurrencyVarPopFields>,
  var_samp?: Maybe<RandomCurrencyVarSampFields>,
  variance?: Maybe<RandomCurrencyVarianceFields>,
}


export interface RandomCurrencyAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomCurrencySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomCurrencyAggregateOrderBy {
  avg?: Maybe<RandomCurrencyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomCurrencyMaxOrderBy>,
  min?: Maybe<RandomCurrencyMinOrderBy>,
  stddev?: Maybe<RandomCurrencyStddevOrderBy>,
  stddev_pop?: Maybe<RandomCurrencyStddevPopOrderBy>,
  stddev_samp?: Maybe<RandomCurrencyStddevSampOrderBy>,
  sum?: Maybe<RandomCurrencySumOrderBy>,
  var_pop?: Maybe<RandomCurrencyVarPopOrderBy>,
  var_samp?: Maybe<RandomCurrencyVarSampOrderBy>,
  variance?: Maybe<RandomCurrencyVarianceOrderBy>,
}

export interface RandomCurrencyAvgFields {
   __typename?: 'random_currency_avg_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyAvgOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyBoolExp {
  _and?: Maybe<Array<Maybe<RandomCurrencyBoolExp>>>,
  _not?: Maybe<RandomCurrencyBoolExp>,
  _or?: Maybe<Array<Maybe<RandomCurrencyBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  decimals?: Maybe<IntComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomCurrencyMaxFields {
   __typename?: 'random_currency_max_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCurrencyMaxOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomCurrencyMinFields {
   __typename?: 'random_currency_min_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  decimals?: Maybe<Scalars['Int']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomCurrencyMinOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomCurrencyOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  decimals?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomCurrencySelectColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Decimals = 'decimals',
  Id = 'id',
  Name = 'name',
  Number = 'number',
  UpdatedAt = 'updated_at'
}

export interface RandomCurrencyStddevFields {
   __typename?: 'random_currency_stddev_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyStddevOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyStddevPopFields {
   __typename?: 'random_currency_stddev_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyStddevPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyStddevSampFields {
   __typename?: 'random_currency_stddev_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyStddevSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencySumFields {
   __typename?: 'random_currency_sum_fields',
  decimals?: Maybe<Scalars['Int']>,
}

export interface RandomCurrencySumOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyVarPopFields {
   __typename?: 'random_currency_var_pop_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyVarPopOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyVarSampFields {
   __typename?: 'random_currency_var_samp_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyVarSampOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomCurrencyVarianceFields {
   __typename?: 'random_currency_variance_fields',
  decimals?: Maybe<Scalars['Float']>,
}

export interface RandomCurrencyVarianceOrderBy {
  decimals?: Maybe<OrderBy>,
}

export interface RandomPhoneType {
   __typename?: 'random_phone_type',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomPhoneTypeAggregate {
   __typename?: 'random_phone_type_aggregate',
  aggregate?: Maybe<RandomPhoneTypeAggregateFields>,
  nodes: Array<RandomPhoneType>,
}

export interface RandomPhoneTypeAggregateFields {
   __typename?: 'random_phone_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomPhoneTypeMaxFields>,
  min?: Maybe<RandomPhoneTypeMinFields>,
}


export interface RandomPhoneTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomPhoneTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomPhoneTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomPhoneTypeMaxOrderBy>,
  min?: Maybe<RandomPhoneTypeMinOrderBy>,
}

export interface RandomPhoneTypeBoolExp {
  _and?: Maybe<Array<Maybe<RandomPhoneTypeBoolExp>>>,
  _not?: Maybe<RandomPhoneTypeBoolExp>,
  _or?: Maybe<Array<Maybe<RandomPhoneTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomPhoneTypeMaxFields {
   __typename?: 'random_phone_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomPhoneTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomPhoneTypeMinFields {
   __typename?: 'random_phone_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomPhoneTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomPhoneTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomPhoneTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface RandomProfile {
   __typename?: 'random_profile',
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileAggregate {
   __typename?: 'random_profile_aggregate',
  aggregate?: Maybe<RandomProfileAggregateFields>,
  nodes: Array<RandomProfile>,
}

export interface RandomProfileAggregateFields {
   __typename?: 'random_profile_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomProfileMaxFields>,
  min?: Maybe<RandomProfileMinFields>,
}


export interface RandomProfileAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomProfileSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomProfileAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomProfileMaxOrderBy>,
  min?: Maybe<RandomProfileMinOrderBy>,
}

export interface RandomProfileBoolExp {
  _and?: Maybe<Array<Maybe<RandomProfileBoolExp>>>,
  _not?: Maybe<RandomProfileBoolExp>,
  _or?: Maybe<Array<Maybe<RandomProfileBoolExp>>>,
  activity_id?: Maybe<StringComparisonExp>,
  category_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  entry_id?: Maybe<StringComparisonExp>,
  flags_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  risk_id?: Maybe<StringComparisonExp>,
  status_id?: Maybe<StringComparisonExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomProfileMaxFields {
   __typename?: 'random_profile_max_fields',
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileMaxOrderBy {
  activity_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  risk_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileMinFields {
   __typename?: 'random_profile_min_fields',
  activity_id?: Maybe<Scalars['String']>,
  category_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  entry_id?: Maybe<Scalars['String']>,
  flags_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  risk_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileMinOrderBy {
  activity_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  risk_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileOrderBy {
  activity_id?: Maybe<OrderBy>,
  category_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  entry_id?: Maybe<OrderBy>,
  flags_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  risk_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileRisk {
   __typename?: 'random_profile_risk',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileRiskAggregate {
   __typename?: 'random_profile_risk_aggregate',
  aggregate?: Maybe<RandomProfileRiskAggregateFields>,
  nodes: Array<RandomProfileRisk>,
}

export interface RandomProfileRiskAggregateFields {
   __typename?: 'random_profile_risk_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomProfileRiskMaxFields>,
  min?: Maybe<RandomProfileRiskMinFields>,
}


export interface RandomProfileRiskAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomProfileRiskSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomProfileRiskAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomProfileRiskMaxOrderBy>,
  min?: Maybe<RandomProfileRiskMinOrderBy>,
}

export interface RandomProfileRiskBoolExp {
  _and?: Maybe<Array<Maybe<RandomProfileRiskBoolExp>>>,
  _not?: Maybe<RandomProfileRiskBoolExp>,
  _or?: Maybe<Array<Maybe<RandomProfileRiskBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomProfileRiskMaxFields {
   __typename?: 'random_profile_risk_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileRiskMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileRiskMinFields {
   __typename?: 'random_profile_risk_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileRiskMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileRiskOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomProfileRiskSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum RandomProfileSelectColumn {
  ActivityId = 'activity_id',
  CategoryId = 'category_id',
  CreatedAt = 'created_at',
  EntryId = 'entry_id',
  FlagsId = 'flags_id',
  Id = 'id',
  Number = 'number',
  OrganizationId = 'organization_id',
  RiskId = 'risk_id',
  StatusId = 'status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface RandomProfileStatus {
   __typename?: 'random_profile_status',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileStatusAggregate {
   __typename?: 'random_profile_status_aggregate',
  aggregate?: Maybe<RandomProfileStatusAggregateFields>,
  nodes: Array<RandomProfileStatus>,
}

export interface RandomProfileStatusAggregateFields {
   __typename?: 'random_profile_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomProfileStatusMaxFields>,
  min?: Maybe<RandomProfileStatusMinFields>,
}


export interface RandomProfileStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomProfileStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomProfileStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomProfileStatusMaxOrderBy>,
  min?: Maybe<RandomProfileStatusMinOrderBy>,
}

export interface RandomProfileStatusBoolExp {
  _and?: Maybe<Array<Maybe<RandomProfileStatusBoolExp>>>,
  _not?: Maybe<RandomProfileStatusBoolExp>,
  _or?: Maybe<Array<Maybe<RandomProfileStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomProfileStatusMaxFields {
   __typename?: 'random_profile_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileStatusMinFields {
   __typename?: 'random_profile_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomProfileStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomProfileStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomProfileStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface RandomTag {
   __typename?: 'random_tag',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomTagAggregate {
   __typename?: 'random_tag_aggregate',
  aggregate?: Maybe<RandomTagAggregateFields>,
  nodes: Array<RandomTag>,
}

export interface RandomTagAggregateFields {
   __typename?: 'random_tag_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RandomTagMaxFields>,
  min?: Maybe<RandomTagMinFields>,
}


export interface RandomTagAggregateFieldsCountArgs {
  columns?: Maybe<Array<RandomTagSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RandomTagAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RandomTagMaxOrderBy>,
  min?: Maybe<RandomTagMinOrderBy>,
}

export interface RandomTagBoolExp {
  _and?: Maybe<Array<Maybe<RandomTagBoolExp>>>,
  _not?: Maybe<RandomTagBoolExp>,
  _or?: Maybe<Array<Maybe<RandomTagBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface RandomTagMaxFields {
   __typename?: 'random_tag_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomTagMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomTagMinFields {
   __typename?: 'random_tag_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RandomTagMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RandomTagOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum RandomTagSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface RepresentativesToProfiles {
   __typename?: 'representatives_to_profiles',
  admin_stake?: Maybe<Stake>,
  admin_stake_id: Scalars['String'],
  created_at: Scalars['timestamptz'],
  created_by?: Maybe<Stakeholder>,
  created_by_id: Scalars['String'],
  profile?: Maybe<Profile>,
  profile_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface RepresentativesToProfilesAggregate {
   __typename?: 'representatives_to_profiles_aggregate',
  aggregate?: Maybe<RepresentativesToProfilesAggregateFields>,
  nodes: Array<RepresentativesToProfiles>,
}

export interface RepresentativesToProfilesAggregateFields {
   __typename?: 'representatives_to_profiles_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RepresentativesToProfilesMaxFields>,
  min?: Maybe<RepresentativesToProfilesMinFields>,
}


export interface RepresentativesToProfilesAggregateFieldsCountArgs {
  columns?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RepresentativesToProfilesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<RepresentativesToProfilesMaxOrderBy>,
  min?: Maybe<RepresentativesToProfilesMinOrderBy>,
}

export interface RepresentativesToProfilesArrRelInsertInput {
  data: Array<RepresentativesToProfilesInsertInput>,
  on_conflict?: Maybe<RepresentativesToProfilesOnConflict>,
}

export interface RepresentativesToProfilesBoolExp {
  _and?: Maybe<Array<Maybe<RepresentativesToProfilesBoolExp>>>,
  _not?: Maybe<RepresentativesToProfilesBoolExp>,
  _or?: Maybe<Array<Maybe<RepresentativesToProfilesBoolExp>>>,
  admin_stake?: Maybe<StakeBoolExp>,
  admin_stake_id?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum RepresentativesToProfilesConstraint {
  RepresentativesToProfilesPkey = 'representatives_to_profiles_pkey'
}

export interface RepresentativesToProfilesInsertInput {
  admin_stake?: Maybe<StakeObjRelInsertInput>,
  admin_stake_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RepresentativesToProfilesMaxFields {
   __typename?: 'representatives_to_profiles_max_fields',
  admin_stake_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RepresentativesToProfilesMaxOrderBy {
  admin_stake_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RepresentativesToProfilesMinFields {
   __typename?: 'representatives_to_profiles_min_fields',
  admin_stake_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RepresentativesToProfilesMinOrderBy {
  admin_stake_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RepresentativesToProfilesMutationResponse {
   __typename?: 'representatives_to_profiles_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<RepresentativesToProfiles>,
}

export interface RepresentativesToProfilesObjRelInsertInput {
  data: RepresentativesToProfilesInsertInput,
  on_conflict?: Maybe<RepresentativesToProfilesOnConflict>,
}

export interface RepresentativesToProfilesOnConflict {
  constraint: RepresentativesToProfilesConstraint,
  update_columns: Array<RepresentativesToProfilesUpdateColumn>,
  where?: Maybe<RepresentativesToProfilesBoolExp>,
}

export interface RepresentativesToProfilesOrderBy {
  admin_stake?: Maybe<StakeOrderBy>,
  admin_stake_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RepresentativesToProfilesPkColumnsInput {
  admin_stake_id: Scalars['String'],
  profile_id: Scalars['String'],
}

export enum RepresentativesToProfilesSelectColumn {
  AdminStakeId = 'admin_stake_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at'
}

export interface RepresentativesToProfilesSetInput {
  admin_stake_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum RepresentativesToProfilesUpdateColumn {
  AdminStakeId = 'admin_stake_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at'
}

export interface Request {
   __typename?: 'request',
  acted_on_account?: Maybe<Account>,
  acted_on_id?: Maybe<Scalars['String']>,
  acted_on_transaction?: Maybe<Transaction>,
  approved_item_account?: Maybe<AccountCreationRequest>,
  approved_item_id?: Maybe<Scalars['String']>,
  approved_item_leverage?: Maybe<Leverage>,
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  id: Scalars['String'],
  number: Scalars['Int'],
  original_state_id?: Maybe<Scalars['String']>,
  original_state_leverage?: Maybe<Leverage>,
  profile: Profile,
  profile_id: Scalars['String'],
  requested_item_account?: Maybe<AccountCreationRequest>,
  requested_item_id: Scalars['String'],
  requested_item_leverage?: Maybe<Leverage>,
  requested_item_transaction?: Maybe<TransactionCreationRequest>,
  reviewed_at?: Maybe<Scalars['timestamptz']>,
  reviewed_by?: Maybe<Stakeholder>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status: RequestStatus,
  status_id: Scalars['Int'],
  type: RequestType,
  type_id: Scalars['Int'],
  updated_at: Scalars['timestamptz'],
}

export interface RequestAggregate {
   __typename?: 'request_aggregate',
  aggregate?: Maybe<RequestAggregateFields>,
  nodes: Array<Request>,
}

export interface RequestAggregateFields {
   __typename?: 'request_aggregate_fields',
  avg?: Maybe<RequestAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RequestMaxFields>,
  min?: Maybe<RequestMinFields>,
  stddev?: Maybe<RequestStddevFields>,
  stddev_pop?: Maybe<RequestStddevPopFields>,
  stddev_samp?: Maybe<RequestStddevSampFields>,
  sum?: Maybe<RequestSumFields>,
  var_pop?: Maybe<RequestVarPopFields>,
  var_samp?: Maybe<RequestVarSampFields>,
  variance?: Maybe<RequestVarianceFields>,
}


export interface RequestAggregateFieldsCountArgs {
  columns?: Maybe<Array<RequestSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RequestAggregateOrderBy {
  avg?: Maybe<RequestAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<RequestMaxOrderBy>,
  min?: Maybe<RequestMinOrderBy>,
  stddev?: Maybe<RequestStddevOrderBy>,
  stddev_pop?: Maybe<RequestStddevPopOrderBy>,
  stddev_samp?: Maybe<RequestStddevSampOrderBy>,
  sum?: Maybe<RequestSumOrderBy>,
  var_pop?: Maybe<RequestVarPopOrderBy>,
  var_samp?: Maybe<RequestVarSampOrderBy>,
  variance?: Maybe<RequestVarianceOrderBy>,
}

export interface RequestArrRelInsertInput {
  data: Array<RequestInsertInput>,
  on_conflict?: Maybe<RequestOnConflict>,
}

export interface RequestAvgFields {
   __typename?: 'request_avg_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestAvgOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestBoolExp {
  _and?: Maybe<Array<Maybe<RequestBoolExp>>>,
  _not?: Maybe<RequestBoolExp>,
  _or?: Maybe<Array<Maybe<RequestBoolExp>>>,
  acted_on_account?: Maybe<AccountBoolExp>,
  acted_on_id?: Maybe<StringComparisonExp>,
  acted_on_transaction?: Maybe<TransactionBoolExp>,
  approved_item_account?: Maybe<AccountCreationRequestBoolExp>,
  approved_item_id?: Maybe<StringComparisonExp>,
  approved_item_leverage?: Maybe<LeverageBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  number?: Maybe<IntComparisonExp>,
  original_state_id?: Maybe<StringComparisonExp>,
  original_state_leverage?: Maybe<LeverageBoolExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  requested_item_account?: Maybe<AccountCreationRequestBoolExp>,
  requested_item_id?: Maybe<StringComparisonExp>,
  requested_item_leverage?: Maybe<LeverageBoolExp>,
  requested_item_transaction?: Maybe<TransactionCreationRequestBoolExp>,
  reviewed_at?: Maybe<TimestamptzComparisonExp>,
  reviewed_by?: Maybe<StakeholderBoolExp>,
  reviewed_by_id?: Maybe<StringComparisonExp>,
  status?: Maybe<RequestStatusBoolExp>,
  status_id?: Maybe<IntComparisonExp>,
  type?: Maybe<RequestTypeBoolExp>,
  type_id?: Maybe<IntComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum RequestConstraint {
  RequestPkey = 'request_pkey'
}

export interface RequestGridView {
   __typename?: 'request_grid_view',
  created_by_id?: Maybe<Scalars['String']>,
  created_by_stakeholder?: Maybe<Stakeholder>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  profile?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_date?: Maybe<Scalars['timestamptz']>,
  requested_by?: Maybe<Scalars['String']>,
  requested_on_profile?: Maybe<Profile>,
  review_date?: Maybe<Scalars['timestamptz']>,
  reviewed_by?: Maybe<Scalars['String']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  reviewed_by_stakeholder?: Maybe<Stakeholder>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface RequestGridViewAggregate {
   __typename?: 'request_grid_view_aggregate',
  aggregate?: Maybe<RequestGridViewAggregateFields>,
  nodes: Array<RequestGridView>,
}

export interface RequestGridViewAggregateFields {
   __typename?: 'request_grid_view_aggregate_fields',
  avg?: Maybe<RequestGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RequestGridViewMaxFields>,
  min?: Maybe<RequestGridViewMinFields>,
  stddev?: Maybe<RequestGridViewStddevFields>,
  stddev_pop?: Maybe<RequestGridViewStddevPopFields>,
  stddev_samp?: Maybe<RequestGridViewStddevSampFields>,
  sum?: Maybe<RequestGridViewSumFields>,
  var_pop?: Maybe<RequestGridViewVarPopFields>,
  var_samp?: Maybe<RequestGridViewVarSampFields>,
  variance?: Maybe<RequestGridViewVarianceFields>,
}


export interface RequestGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<RequestGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RequestGridViewAggregateOrderBy {
  avg?: Maybe<RequestGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<RequestGridViewMaxOrderBy>,
  min?: Maybe<RequestGridViewMinOrderBy>,
  stddev?: Maybe<RequestGridViewStddevOrderBy>,
  stddev_pop?: Maybe<RequestGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<RequestGridViewStddevSampOrderBy>,
  sum?: Maybe<RequestGridViewSumOrderBy>,
  var_pop?: Maybe<RequestGridViewVarPopOrderBy>,
  var_samp?: Maybe<RequestGridViewVarSampOrderBy>,
  variance?: Maybe<RequestGridViewVarianceOrderBy>,
}

export interface RequestGridViewAvgFields {
   __typename?: 'request_grid_view_avg_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewAvgOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewBoolExp {
  _and?: Maybe<Array<Maybe<RequestGridViewBoolExp>>>,
  _not?: Maybe<RequestGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<RequestGridViewBoolExp>>>,
  created_by_id?: Maybe<StringComparisonExp>,
  created_by_stakeholder?: Maybe<StakeholderBoolExp>,
  id?: Maybe<StringComparisonExp>,
  number?: Maybe<IntComparisonExp>,
  profile?: Maybe<StringComparisonExp>,
  profile_id?: Maybe<StringComparisonExp>,
  request_date?: Maybe<TimestamptzComparisonExp>,
  requested_by?: Maybe<StringComparisonExp>,
  requested_on_profile?: Maybe<ProfileBoolExp>,
  review_date?: Maybe<TimestamptzComparisonExp>,
  reviewed_by?: Maybe<StringComparisonExp>,
  reviewed_by_id?: Maybe<StringComparisonExp>,
  reviewed_by_stakeholder?: Maybe<StakeholderBoolExp>,
  status?: Maybe<StringComparisonExp>,
  type?: Maybe<StringComparisonExp>,
}

export interface RequestGridViewMaxFields {
   __typename?: 'request_grid_view_max_fields',
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  profile?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_date?: Maybe<Scalars['timestamptz']>,
  requested_by?: Maybe<Scalars['String']>,
  review_date?: Maybe<Scalars['timestamptz']>,
  reviewed_by?: Maybe<Scalars['String']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface RequestGridViewMaxOrderBy {
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  request_date?: Maybe<OrderBy>,
  requested_by?: Maybe<OrderBy>,
  review_date?: Maybe<OrderBy>,
  reviewed_by?: Maybe<OrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface RequestGridViewMinFields {
   __typename?: 'request_grid_view_min_fields',
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  profile?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  request_date?: Maybe<Scalars['timestamptz']>,
  requested_by?: Maybe<Scalars['String']>,
  review_date?: Maybe<Scalars['timestamptz']>,
  reviewed_by?: Maybe<Scalars['String']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface RequestGridViewMinOrderBy {
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  request_date?: Maybe<OrderBy>,
  requested_by?: Maybe<OrderBy>,
  review_date?: Maybe<OrderBy>,
  reviewed_by?: Maybe<OrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface RequestGridViewOrderBy {
  created_by_id?: Maybe<OrderBy>,
  created_by_stakeholder?: Maybe<StakeholderOrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  request_date?: Maybe<OrderBy>,
  requested_by?: Maybe<OrderBy>,
  requested_on_profile?: Maybe<ProfileOrderBy>,
  review_date?: Maybe<OrderBy>,
  reviewed_by?: Maybe<OrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  reviewed_by_stakeholder?: Maybe<StakeholderOrderBy>,
  status?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export enum RequestGridViewSelectColumn {
  CreatedById = 'created_by_id',
  Id = 'id',
  Number = 'number',
  Profile = 'profile',
  ProfileId = 'profile_id',
  RequestDate = 'request_date',
  RequestedBy = 'requested_by',
  ReviewDate = 'review_date',
  ReviewedBy = 'reviewed_by',
  ReviewedById = 'reviewed_by_id',
  Status = 'status',
  Type = 'type'
}

export interface RequestGridViewStddevFields {
   __typename?: 'request_grid_view_stddev_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewStddevOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewStddevPopFields {
   __typename?: 'request_grid_view_stddev_pop_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewStddevPopOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewStddevSampFields {
   __typename?: 'request_grid_view_stddev_samp_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewStddevSampOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewSumFields {
   __typename?: 'request_grid_view_sum_fields',
  number?: Maybe<Scalars['Int']>,
}

export interface RequestGridViewSumOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewVarPopFields {
   __typename?: 'request_grid_view_var_pop_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewVarPopOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewVarSampFields {
   __typename?: 'request_grid_view_var_samp_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewVarSampOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestGridViewVarianceFields {
   __typename?: 'request_grid_view_variance_fields',
  number?: Maybe<Scalars['Float']>,
}

export interface RequestGridViewVarianceOrderBy {
  number?: Maybe<OrderBy>,
}

export interface RequestIncInput {
  number?: Maybe<Scalars['Int']>,
  status_id?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['Int']>,
}

export interface RequestInsertInput {
  acted_on_account?: Maybe<AccountObjRelInsertInput>,
  acted_on_id?: Maybe<Scalars['String']>,
  acted_on_transaction?: Maybe<TransactionObjRelInsertInput>,
  approved_item_account?: Maybe<AccountCreationRequestObjRelInsertInput>,
  approved_item_id?: Maybe<Scalars['String']>,
  approved_item_leverage?: Maybe<LeverageObjRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  original_state_id?: Maybe<Scalars['String']>,
  original_state_leverage?: Maybe<LeverageObjRelInsertInput>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  requested_item_account?: Maybe<AccountCreationRequestObjRelInsertInput>,
  requested_item_id?: Maybe<Scalars['String']>,
  requested_item_leverage?: Maybe<LeverageObjRelInsertInput>,
  requested_item_transaction?: Maybe<TransactionCreationRequestObjRelInsertInput>,
  reviewed_at?: Maybe<Scalars['timestamptz']>,
  reviewed_by?: Maybe<StakeholderObjRelInsertInput>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status?: Maybe<RequestStatusObjRelInsertInput>,
  status_id?: Maybe<Scalars['Int']>,
  type?: Maybe<RequestTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RequestMaxFields {
   __typename?: 'request_max_fields',
  acted_on_id?: Maybe<Scalars['String']>,
  approved_item_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  original_state_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  requested_item_id?: Maybe<Scalars['String']>,
  reviewed_at?: Maybe<Scalars['timestamptz']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RequestMaxOrderBy {
  acted_on_id?: Maybe<OrderBy>,
  approved_item_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  original_state_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  requested_item_id?: Maybe<OrderBy>,
  reviewed_at?: Maybe<OrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RequestMinFields {
   __typename?: 'request_min_fields',
  acted_on_id?: Maybe<Scalars['String']>,
  approved_item_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  original_state_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  requested_item_id?: Maybe<Scalars['String']>,
  reviewed_at?: Maybe<Scalars['timestamptz']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RequestMinOrderBy {
  acted_on_id?: Maybe<OrderBy>,
  approved_item_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  original_state_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  requested_item_id?: Maybe<OrderBy>,
  reviewed_at?: Maybe<OrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RequestMutationResponse {
   __typename?: 'request_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Request>,
}

export interface RequestObjRelInsertInput {
  data: RequestInsertInput,
  on_conflict?: Maybe<RequestOnConflict>,
}

export interface RequestOnConflict {
  constraint: RequestConstraint,
  update_columns: Array<RequestUpdateColumn>,
  where?: Maybe<RequestBoolExp>,
}

export interface RequestOrderBy {
  acted_on_account?: Maybe<AccountOrderBy>,
  acted_on_id?: Maybe<OrderBy>,
  acted_on_transaction?: Maybe<TransactionOrderBy>,
  approved_item_account?: Maybe<AccountCreationRequestOrderBy>,
  approved_item_id?: Maybe<OrderBy>,
  approved_item_leverage?: Maybe<LeverageOrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  original_state_id?: Maybe<OrderBy>,
  original_state_leverage?: Maybe<LeverageOrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  requested_item_account?: Maybe<AccountCreationRequestOrderBy>,
  requested_item_id?: Maybe<OrderBy>,
  requested_item_leverage?: Maybe<LeverageOrderBy>,
  requested_item_transaction?: Maybe<TransactionCreationRequestOrderBy>,
  reviewed_at?: Maybe<OrderBy>,
  reviewed_by?: Maybe<StakeholderOrderBy>,
  reviewed_by_id?: Maybe<OrderBy>,
  status?: Maybe<RequestStatusOrderBy>,
  status_id?: Maybe<OrderBy>,
  type?: Maybe<RequestTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface RequestPkColumnsInput {
  id: Scalars['String'],
}

export enum RequestSelectColumn {
  ActedOnId = 'acted_on_id',
  ApprovedItemId = 'approved_item_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  Number = 'number',
  OriginalStateId = 'original_state_id',
  ProfileId = 'profile_id',
  RequestedItemId = 'requested_item_id',
  ReviewedAt = 'reviewed_at',
  ReviewedById = 'reviewed_by_id',
  StatusId = 'status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface RequestSetInput {
  acted_on_id?: Maybe<Scalars['String']>,
  approved_item_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['Int']>,
  original_state_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  requested_item_id?: Maybe<Scalars['String']>,
  reviewed_at?: Maybe<Scalars['timestamptz']>,
  reviewed_by_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface RequestStatus {
   __typename?: 'request_status',
  id: Scalars['Int'],
  name: Scalars['String'],
}

export interface RequestStatusAggregate {
   __typename?: 'request_status_aggregate',
  aggregate?: Maybe<RequestStatusAggregateFields>,
  nodes: Array<RequestStatus>,
}

export interface RequestStatusAggregateFields {
   __typename?: 'request_status_aggregate_fields',
  avg?: Maybe<RequestStatusAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RequestStatusMaxFields>,
  min?: Maybe<RequestStatusMinFields>,
  stddev?: Maybe<RequestStatusStddevFields>,
  stddev_pop?: Maybe<RequestStatusStddevPopFields>,
  stddev_samp?: Maybe<RequestStatusStddevSampFields>,
  sum?: Maybe<RequestStatusSumFields>,
  var_pop?: Maybe<RequestStatusVarPopFields>,
  var_samp?: Maybe<RequestStatusVarSampFields>,
  variance?: Maybe<RequestStatusVarianceFields>,
}


export interface RequestStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<RequestStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RequestStatusAggregateOrderBy {
  avg?: Maybe<RequestStatusAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<RequestStatusMaxOrderBy>,
  min?: Maybe<RequestStatusMinOrderBy>,
  stddev?: Maybe<RequestStatusStddevOrderBy>,
  stddev_pop?: Maybe<RequestStatusStddevPopOrderBy>,
  stddev_samp?: Maybe<RequestStatusStddevSampOrderBy>,
  sum?: Maybe<RequestStatusSumOrderBy>,
  var_pop?: Maybe<RequestStatusVarPopOrderBy>,
  var_samp?: Maybe<RequestStatusVarSampOrderBy>,
  variance?: Maybe<RequestStatusVarianceOrderBy>,
}

export interface RequestStatusArrRelInsertInput {
  data: Array<RequestStatusInsertInput>,
  on_conflict?: Maybe<RequestStatusOnConflict>,
}

export interface RequestStatusAvgFields {
   __typename?: 'request_status_avg_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusAvgOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusBoolExp {
  _and?: Maybe<Array<Maybe<RequestStatusBoolExp>>>,
  _not?: Maybe<RequestStatusBoolExp>,
  _or?: Maybe<Array<Maybe<RequestStatusBoolExp>>>,
  id?: Maybe<IntComparisonExp>,
  name?: Maybe<StringComparisonExp>,
}

export enum RequestStatusConstraint {
  RequestStatusPkey = 'request_status_pkey'
}

export interface RequestStatusIncInput {
  id?: Maybe<Scalars['Int']>,
}

export interface RequestStatusInsertInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestStatusMaxFields {
   __typename?: 'request_status_max_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestStatusMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestStatusMinFields {
   __typename?: 'request_status_min_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestStatusMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestStatusMutationResponse {
   __typename?: 'request_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<RequestStatus>,
}

export interface RequestStatusObjRelInsertInput {
  data: RequestStatusInsertInput,
  on_conflict?: Maybe<RequestStatusOnConflict>,
}

export interface RequestStatusOnConflict {
  constraint: RequestStatusConstraint,
  update_columns: Array<RequestStatusUpdateColumn>,
  where?: Maybe<RequestStatusBoolExp>,
}

export interface RequestStatusOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestStatusPkColumnsInput {
  id: Scalars['Int'],
}

export enum RequestStatusSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface RequestStatusSetInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestStatusStddevFields {
   __typename?: 'request_status_stddev_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusStddevOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusStddevPopFields {
   __typename?: 'request_status_stddev_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusStddevPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusStddevSampFields {
   __typename?: 'request_status_stddev_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusStddevSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusSumFields {
   __typename?: 'request_status_sum_fields',
  id?: Maybe<Scalars['Int']>,
}

export interface RequestStatusSumOrderBy {
  id?: Maybe<OrderBy>,
}

export enum RequestStatusUpdateColumn {
  Id = 'id',
  Name = 'name'
}

export interface RequestStatusVarPopFields {
   __typename?: 'request_status_var_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusVarPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusVarSampFields {
   __typename?: 'request_status_var_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusVarSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStatusVarianceFields {
   __typename?: 'request_status_variance_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestStatusVarianceOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestStddevFields {
   __typename?: 'request_stddev_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestStddevOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestStddevPopFields {
   __typename?: 'request_stddev_pop_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestStddevPopOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestStddevSampFields {
   __typename?: 'request_stddev_samp_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestStddevSampOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestSumFields {
   __typename?: 'request_sum_fields',
  number?: Maybe<Scalars['Int']>,
  status_id?: Maybe<Scalars['Int']>,
  type_id?: Maybe<Scalars['Int']>,
}

export interface RequestSumOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestType {
   __typename?: 'request_type',
  id: Scalars['Int'],
  name: Scalars['String'],
}

export interface RequestTypeAggregate {
   __typename?: 'request_type_aggregate',
  aggregate?: Maybe<RequestTypeAggregateFields>,
  nodes: Array<RequestType>,
}

export interface RequestTypeAggregateFields {
   __typename?: 'request_type_aggregate_fields',
  avg?: Maybe<RequestTypeAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<RequestTypeMaxFields>,
  min?: Maybe<RequestTypeMinFields>,
  stddev?: Maybe<RequestTypeStddevFields>,
  stddev_pop?: Maybe<RequestTypeStddevPopFields>,
  stddev_samp?: Maybe<RequestTypeStddevSampFields>,
  sum?: Maybe<RequestTypeSumFields>,
  var_pop?: Maybe<RequestTypeVarPopFields>,
  var_samp?: Maybe<RequestTypeVarSampFields>,
  variance?: Maybe<RequestTypeVarianceFields>,
}


export interface RequestTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<RequestTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface RequestTypeAggregateOrderBy {
  avg?: Maybe<RequestTypeAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<RequestTypeMaxOrderBy>,
  min?: Maybe<RequestTypeMinOrderBy>,
  stddev?: Maybe<RequestTypeStddevOrderBy>,
  stddev_pop?: Maybe<RequestTypeStddevPopOrderBy>,
  stddev_samp?: Maybe<RequestTypeStddevSampOrderBy>,
  sum?: Maybe<RequestTypeSumOrderBy>,
  var_pop?: Maybe<RequestTypeVarPopOrderBy>,
  var_samp?: Maybe<RequestTypeVarSampOrderBy>,
  variance?: Maybe<RequestTypeVarianceOrderBy>,
}

export interface RequestTypeArrRelInsertInput {
  data: Array<RequestTypeInsertInput>,
  on_conflict?: Maybe<RequestTypeOnConflict>,
}

export interface RequestTypeAvgFields {
   __typename?: 'request_type_avg_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeAvgOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeBoolExp {
  _and?: Maybe<Array<Maybe<RequestTypeBoolExp>>>,
  _not?: Maybe<RequestTypeBoolExp>,
  _or?: Maybe<Array<Maybe<RequestTypeBoolExp>>>,
  id?: Maybe<IntComparisonExp>,
  name?: Maybe<StringComparisonExp>,
}

export enum RequestTypeConstraint {
  RequestTypePkey = 'request_type_pkey'
}

export interface RequestTypeIncInput {
  id?: Maybe<Scalars['Int']>,
}

export interface RequestTypeInsertInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestTypeMaxFields {
   __typename?: 'request_type_max_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestTypeMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestTypeMinFields {
   __typename?: 'request_type_min_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestTypeMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestTypeMutationResponse {
   __typename?: 'request_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<RequestType>,
}

export interface RequestTypeObjRelInsertInput {
  data: RequestTypeInsertInput,
  on_conflict?: Maybe<RequestTypeOnConflict>,
}

export interface RequestTypeOnConflict {
  constraint: RequestTypeConstraint,
  update_columns: Array<RequestTypeUpdateColumn>,
  where?: Maybe<RequestTypeBoolExp>,
}

export interface RequestTypeOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface RequestTypePkColumnsInput {
  id: Scalars['Int'],
}

export enum RequestTypeSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface RequestTypeSetInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface RequestTypeStddevFields {
   __typename?: 'request_type_stddev_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeStddevOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeStddevPopFields {
   __typename?: 'request_type_stddev_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeStddevPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeStddevSampFields {
   __typename?: 'request_type_stddev_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeStddevSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeSumFields {
   __typename?: 'request_type_sum_fields',
  id?: Maybe<Scalars['Int']>,
}

export interface RequestTypeSumOrderBy {
  id?: Maybe<OrderBy>,
}

export enum RequestTypeUpdateColumn {
  Id = 'id',
  Name = 'name'
}

export interface RequestTypeVarPopFields {
   __typename?: 'request_type_var_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeVarPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeVarSampFields {
   __typename?: 'request_type_var_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeVarSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface RequestTypeVarianceFields {
   __typename?: 'request_type_variance_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface RequestTypeVarianceOrderBy {
  id?: Maybe<OrderBy>,
}

export enum RequestUpdateColumn {
  ActedOnId = 'acted_on_id',
  ApprovedItemId = 'approved_item_id',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Id = 'id',
  Number = 'number',
  OriginalStateId = 'original_state_id',
  ProfileId = 'profile_id',
  RequestedItemId = 'requested_item_id',
  ReviewedAt = 'reviewed_at',
  ReviewedById = 'reviewed_by_id',
  StatusId = 'status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface RequestVarPopFields {
   __typename?: 'request_var_pop_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestVarPopOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestVarSampFields {
   __typename?: 'request_var_samp_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestVarSampOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface RequestVarianceFields {
   __typename?: 'request_variance_fields',
  number?: Maybe<Scalars['Float']>,
  status_id?: Maybe<Scalars['Float']>,
  type_id?: Maybe<Scalars['Float']>,
}

export interface RequestVarianceOrderBy {
  number?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
}

export interface SampleInput {
  password: Scalars['String'],
  username: Scalars['String'],
}

export interface SampleOutput {
   __typename?: 'SampleOutput',
  accessToken: Scalars['String'],
}

export interface Security {
   __typename?: 'security',
  account_groups: Array<AccountGroupSecurity>,
  account_groups_aggregate: AccountGroupSecurityAggregate,
  created_at: Scalars['timestamptz'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_deleted: Scalars['Boolean'],
  name: Scalars['String'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  symbols: Array<Symbol>,
  symbols_aggregate: SymbolAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface SecurityAccountGroupsArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface SecurityAccountGroupsAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface SecuritySymbolsArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface SecuritySymbolsAggregateArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}

export interface SecurityAggregate {
   __typename?: 'security_aggregate',
  aggregate?: Maybe<SecurityAggregateFields>,
  nodes: Array<Security>,
}

export interface SecurityAggregateFields {
   __typename?: 'security_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SecurityMaxFields>,
  min?: Maybe<SecurityMinFields>,
}


export interface SecurityAggregateFieldsCountArgs {
  columns?: Maybe<Array<SecuritySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SecurityAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SecurityMaxOrderBy>,
  min?: Maybe<SecurityMinOrderBy>,
}

export interface SecurityArrRelInsertInput {
  data: Array<SecurityInsertInput>,
  on_conflict?: Maybe<SecurityOnConflict>,
}

export interface SecurityBoolExp {
  _and?: Maybe<Array<Maybe<SecurityBoolExp>>>,
  _not?: Maybe<SecurityBoolExp>,
  _or?: Maybe<Array<Maybe<SecurityBoolExp>>>,
  account_groups?: Maybe<AccountGroupSecurityBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  symbols?: Maybe<SymbolBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SecurityConstraint {
  SecurityPkey = 'security_pkey'
}

export interface SecurityGridView {
   __typename?: 'security_grid_view',
  count?: Maybe<Scalars['bigint']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  security?: Maybe<Security>,
}

export interface SecurityGridViewAggregate {
   __typename?: 'security_grid_view_aggregate',
  aggregate?: Maybe<SecurityGridViewAggregateFields>,
  nodes: Array<SecurityGridView>,
}

export interface SecurityGridViewAggregateFields {
   __typename?: 'security_grid_view_aggregate_fields',
  avg?: Maybe<SecurityGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SecurityGridViewMaxFields>,
  min?: Maybe<SecurityGridViewMinFields>,
  stddev?: Maybe<SecurityGridViewStddevFields>,
  stddev_pop?: Maybe<SecurityGridViewStddevPopFields>,
  stddev_samp?: Maybe<SecurityGridViewStddevSampFields>,
  sum?: Maybe<SecurityGridViewSumFields>,
  var_pop?: Maybe<SecurityGridViewVarPopFields>,
  var_samp?: Maybe<SecurityGridViewVarSampFields>,
  variance?: Maybe<SecurityGridViewVarianceFields>,
}


export interface SecurityGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<SecurityGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SecurityGridViewAggregateOrderBy {
  avg?: Maybe<SecurityGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<SecurityGridViewMaxOrderBy>,
  min?: Maybe<SecurityGridViewMinOrderBy>,
  stddev?: Maybe<SecurityGridViewStddevOrderBy>,
  stddev_pop?: Maybe<SecurityGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<SecurityGridViewStddevSampOrderBy>,
  sum?: Maybe<SecurityGridViewSumOrderBy>,
  var_pop?: Maybe<SecurityGridViewVarPopOrderBy>,
  var_samp?: Maybe<SecurityGridViewVarSampOrderBy>,
  variance?: Maybe<SecurityGridViewVarianceOrderBy>,
}

export interface SecurityGridViewArrRelInsertInput {
  data: Array<SecurityGridViewInsertInput>,
}

export interface SecurityGridViewAvgFields {
   __typename?: 'security_grid_view_avg_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewAvgOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewBoolExp {
  _and?: Maybe<Array<Maybe<SecurityGridViewBoolExp>>>,
  _not?: Maybe<SecurityGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<SecurityGridViewBoolExp>>>,
  count?: Maybe<BigintComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  security?: Maybe<SecurityBoolExp>,
}

export interface SecurityGridViewIncInput {
  count?: Maybe<Scalars['bigint']>,
}

export interface SecurityGridViewInsertInput {
  count?: Maybe<Scalars['bigint']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  security?: Maybe<SecurityObjRelInsertInput>,
}

export interface SecurityGridViewMaxFields {
   __typename?: 'security_grid_view_max_fields',
  count?: Maybe<Scalars['bigint']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
}

export interface SecurityGridViewMaxOrderBy {
  count?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
}

export interface SecurityGridViewMinFields {
   __typename?: 'security_grid_view_min_fields',
  count?: Maybe<Scalars['bigint']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
}

export interface SecurityGridViewMinOrderBy {
  count?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
}

export interface SecurityGridViewMutationResponse {
   __typename?: 'security_grid_view_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SecurityGridView>,
}

export interface SecurityGridViewObjRelInsertInput {
  data: SecurityGridViewInsertInput,
}

export interface SecurityGridViewOrderBy {
  count?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  security?: Maybe<SecurityOrderBy>,
}

export enum SecurityGridViewSelectColumn {
  Count = 'count',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  PlatformServerId = 'platform_server_id'
}

export interface SecurityGridViewSetInput {
  count?: Maybe<Scalars['bigint']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
}

export interface SecurityGridViewStddevFields {
   __typename?: 'security_grid_view_stddev_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewStddevOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewStddevPopFields {
   __typename?: 'security_grid_view_stddev_pop_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewStddevPopOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewStddevSampFields {
   __typename?: 'security_grid_view_stddev_samp_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewStddevSampOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewSumFields {
   __typename?: 'security_grid_view_sum_fields',
  count?: Maybe<Scalars['bigint']>,
}

export interface SecurityGridViewSumOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewVarPopFields {
   __typename?: 'security_grid_view_var_pop_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewVarPopOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewVarSampFields {
   __typename?: 'security_grid_view_var_samp_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewVarSampOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityGridViewVarianceFields {
   __typename?: 'security_grid_view_variance_fields',
  count?: Maybe<Scalars['Float']>,
}

export interface SecurityGridViewVarianceOrderBy {
  count?: Maybe<OrderBy>,
}

export interface SecurityInsertInput {
  account_groups?: Maybe<AccountGroupSecurityArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  symbols?: Maybe<SymbolArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SecurityMaxFields {
   __typename?: 'security_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SecurityMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SecurityMinFields {
   __typename?: 'security_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SecurityMinOrderBy {
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SecurityMutationResponse {
   __typename?: 'security_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Security>,
}

export interface SecurityObjRelInsertInput {
  data: SecurityInsertInput,
  on_conflict?: Maybe<SecurityOnConflict>,
}

export interface SecurityOnConflict {
  constraint: SecurityConstraint,
  update_columns: Array<SecurityUpdateColumn>,
  where?: Maybe<SecurityBoolExp>,
}

export interface SecurityOrderBy {
  account_groups_aggregate?: Maybe<AccountGroupSecurityAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  symbols_aggregate?: Maybe<SymbolAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SecurityPkColumnsInput {
  id: Scalars['String'],
}

export enum SecuritySelectColumn {
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsDeleted = 'is_deleted',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  UpdatedAt = 'updated_at'
}

export interface SecuritySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SecurityUpdateColumn {
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsDeleted = 'is_deleted',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  UpdatedAt = 'updated_at'
}

export interface Segment {
   __typename?: 'segment',
  created_at: Scalars['timestamptz'],
  created_by?: Maybe<Stakeholder>,
  created_by_id?: Maybe<Scalars['String']>,
  criteria: Array<SegmentCriterion>,
  criteria_aggregate: SegmentCriterionAggregate,
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  included_by: Array<SegmentCriterion>,
  included_by_aggregate: SegmentCriterionAggregate,
  is_default?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
}


export interface SegmentCriteriaArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SegmentCriteriaAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SegmentIncludedByArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SegmentIncludedByAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}

export interface SegmentAggregate {
   __typename?: 'segment_aggregate',
  aggregate?: Maybe<SegmentAggregateFields>,
  nodes: Array<Segment>,
}

export interface SegmentAggregateFields {
   __typename?: 'segment_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentMaxFields>,
  min?: Maybe<SegmentMinFields>,
}


export interface SegmentAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentMaxOrderBy>,
  min?: Maybe<SegmentMinOrderBy>,
}

export interface SegmentArrRelInsertInput {
  data: Array<SegmentInsertInput>,
  on_conflict?: Maybe<SegmentOnConflict>,
}

export interface SegmentBoolExp {
  _and?: Maybe<Array<Maybe<SegmentBoolExp>>>,
  _not?: Maybe<SegmentBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  criteria?: Maybe<SegmentCriterionBoolExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  included_by?: Maybe<SegmentCriterionBoolExp>,
  is_default?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface SegmentCondition {
   __typename?: 'segment_condition',
  created_at: Scalars['timestamptz'],
  criterion: SegmentCriterion,
  criterion_id: Scalars['String'],
  id: Scalars['String'],
  operator: SegmentOperator,
  operator_id: Scalars['String'],
  property_condition: SegmentPropertyCondition,
  property_condition_id: Scalars['String'],
  resolved?: Maybe<LookupView>,
  updated_at: Scalars['timestamptz'],
  value: Scalars['String'],
}

export interface SegmentConditionAggregate {
   __typename?: 'segment_condition_aggregate',
  aggregate?: Maybe<SegmentConditionAggregateFields>,
  nodes: Array<SegmentCondition>,
}

export interface SegmentConditionAggregateFields {
   __typename?: 'segment_condition_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentConditionMaxFields>,
  min?: Maybe<SegmentConditionMinFields>,
}


export interface SegmentConditionAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentConditionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentConditionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentConditionMaxOrderBy>,
  min?: Maybe<SegmentConditionMinOrderBy>,
}

export interface SegmentConditionArrRelInsertInput {
  data: Array<SegmentConditionInsertInput>,
  on_conflict?: Maybe<SegmentConditionOnConflict>,
}

export interface SegmentConditionBoolExp {
  _and?: Maybe<Array<Maybe<SegmentConditionBoolExp>>>,
  _not?: Maybe<SegmentConditionBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentConditionBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  criterion?: Maybe<SegmentCriterionBoolExp>,
  criterion_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  operator?: Maybe<SegmentOperatorBoolExp>,
  operator_id?: Maybe<StringComparisonExp>,
  property_condition?: Maybe<SegmentPropertyConditionBoolExp>,
  property_condition_id?: Maybe<StringComparisonExp>,
  resolved?: Maybe<LookupViewBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum SegmentConditionConstraint {
  SegmentConditionPkey = 'segment_condition_pkey'
}

export interface SegmentConditionInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  criterion?: Maybe<SegmentCriterionObjRelInsertInput>,
  criterion_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  operator?: Maybe<SegmentOperatorObjRelInsertInput>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition?: Maybe<SegmentPropertyConditionObjRelInsertInput>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface SegmentConditionMaxFields {
   __typename?: 'segment_condition_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  criterion_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface SegmentConditionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  criterion_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface SegmentConditionMinFields {
   __typename?: 'segment_condition_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  criterion_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface SegmentConditionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  criterion_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface SegmentConditionMutationResponse {
   __typename?: 'segment_condition_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentCondition>,
}

export interface SegmentConditionObjRelInsertInput {
  data: SegmentConditionInsertInput,
  on_conflict?: Maybe<SegmentConditionOnConflict>,
}

export interface SegmentConditionOnConflict {
  constraint: SegmentConditionConstraint,
  update_columns: Array<SegmentConditionUpdateColumn>,
  where?: Maybe<SegmentConditionBoolExp>,
}

export interface SegmentConditionOrderBy {
  created_at?: Maybe<OrderBy>,
  criterion?: Maybe<SegmentCriterionOrderBy>,
  criterion_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  operator?: Maybe<SegmentOperatorOrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition?: Maybe<SegmentPropertyConditionOrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  resolved?: Maybe<LookupViewOrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface SegmentConditionPkColumnsInput {
  id: Scalars['String'],
}

export enum SegmentConditionSelectColumn {
  CreatedAt = 'created_at',
  CriterionId = 'criterion_id',
  Id = 'id',
  OperatorId = 'operator_id',
  PropertyConditionId = 'property_condition_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface SegmentConditionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  criterion_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export enum SegmentConditionUpdateColumn {
  CreatedAt = 'created_at',
  CriterionId = 'criterion_id',
  Id = 'id',
  OperatorId = 'operator_id',
  PropertyConditionId = 'property_condition_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export enum SegmentConstraint {
  SegmentNameOrganizationIdKey = 'segment_name_organization_id_key',
  SegmentPkey = 'segment_pkey'
}

export interface SegmentCriterion {
   __typename?: 'segment_criterion',
  created_at: Scalars['timestamptz'],
  criteria: Array<SegmentCriterion>,
  criteria_aggregate: SegmentCriterionAggregate,
  id: Scalars['String'],
  include?: Maybe<Segment>,
  include_id?: Maybe<Scalars['String']>,
  parent?: Maybe<SegmentCriterion>,
  parent_id?: Maybe<Scalars['String']>,
  rule: Array<SegmentCondition>,
  rule_aggregate: SegmentConditionAggregate,
  segment: Segment,
  segment_id: Scalars['String'],
  type: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface SegmentCriterionCriteriaArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SegmentCriterionCriteriaAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SegmentCriterionRuleArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}


export interface SegmentCriterionRuleAggregateArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}

export interface SegmentCriterionAggregate {
   __typename?: 'segment_criterion_aggregate',
  aggregate?: Maybe<SegmentCriterionAggregateFields>,
  nodes: Array<SegmentCriterion>,
}

export interface SegmentCriterionAggregateFields {
   __typename?: 'segment_criterion_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentCriterionMaxFields>,
  min?: Maybe<SegmentCriterionMinFields>,
}


export interface SegmentCriterionAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentCriterionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentCriterionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentCriterionMaxOrderBy>,
  min?: Maybe<SegmentCriterionMinOrderBy>,
}

export interface SegmentCriterionArrRelInsertInput {
  data: Array<SegmentCriterionInsertInput>,
  on_conflict?: Maybe<SegmentCriterionOnConflict>,
}

export interface SegmentCriterionBoolExp {
  _and?: Maybe<Array<Maybe<SegmentCriterionBoolExp>>>,
  _not?: Maybe<SegmentCriterionBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentCriterionBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  criteria?: Maybe<SegmentCriterionBoolExp>,
  id?: Maybe<StringComparisonExp>,
  include?: Maybe<SegmentBoolExp>,
  include_id?: Maybe<StringComparisonExp>,
  parent?: Maybe<SegmentCriterionBoolExp>,
  parent_id?: Maybe<StringComparisonExp>,
  rule?: Maybe<SegmentConditionBoolExp>,
  segment?: Maybe<SegmentBoolExp>,
  segment_id?: Maybe<StringComparisonExp>,
  type?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SegmentCriterionConstraint {
  SegmentCriterionPkey = 'segment_criterion_pkey'
}

export interface SegmentCriterionInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  criteria?: Maybe<SegmentCriterionArrRelInsertInput>,
  id?: Maybe<Scalars['String']>,
  include?: Maybe<SegmentObjRelInsertInput>,
  include_id?: Maybe<Scalars['String']>,
  parent?: Maybe<SegmentCriterionObjRelInsertInput>,
  parent_id?: Maybe<Scalars['String']>,
  rule?: Maybe<SegmentConditionArrRelInsertInput>,
  segment?: Maybe<SegmentObjRelInsertInput>,
  segment_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionMaxFields {
   __typename?: 'segment_criterion_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  include_id?: Maybe<Scalars['String']>,
  parent_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  include_id?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionMinFields {
   __typename?: 'segment_criterion_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  include_id?: Maybe<Scalars['String']>,
  parent_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  include_id?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  segment_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionMutationResponse {
   __typename?: 'segment_criterion_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentCriterion>,
}

export interface SegmentCriterionObjRelInsertInput {
  data: SegmentCriterionInsertInput,
  on_conflict?: Maybe<SegmentCriterionOnConflict>,
}

export interface SegmentCriterionOnConflict {
  constraint: SegmentCriterionConstraint,
  update_columns: Array<SegmentCriterionUpdateColumn>,
  where?: Maybe<SegmentCriterionBoolExp>,
}

export interface SegmentCriterionOrderBy {
  created_at?: Maybe<OrderBy>,
  criteria_aggregate?: Maybe<SegmentCriterionAggregateOrderBy>,
  id?: Maybe<OrderBy>,
  include?: Maybe<SegmentOrderBy>,
  include_id?: Maybe<OrderBy>,
  parent?: Maybe<SegmentCriterionOrderBy>,
  parent_id?: Maybe<OrderBy>,
  rule_aggregate?: Maybe<SegmentConditionAggregateOrderBy>,
  segment?: Maybe<SegmentOrderBy>,
  segment_id?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionPkColumnsInput {
  id: Scalars['String'],
}

export enum SegmentCriterionSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IncludeId = 'include_id',
  ParentId = 'parent_id',
  SegmentId = 'segment_id',
  Type = 'type',
  UpdatedAt = 'updated_at'
}

export interface SegmentCriterionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  include_id?: Maybe<Scalars['String']>,
  parent_id?: Maybe<Scalars['String']>,
  segment_id?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionType {
   __typename?: 'segment_criterion_type',
  created_at: Scalars['timestamptz'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SegmentCriterionTypeAggregate {
   __typename?: 'segment_criterion_type_aggregate',
  aggregate?: Maybe<SegmentCriterionTypeAggregateFields>,
  nodes: Array<SegmentCriterionType>,
}

export interface SegmentCriterionTypeAggregateFields {
   __typename?: 'segment_criterion_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentCriterionTypeMaxFields>,
  min?: Maybe<SegmentCriterionTypeMinFields>,
}


export interface SegmentCriterionTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentCriterionTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentCriterionTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentCriterionTypeMaxOrderBy>,
  min?: Maybe<SegmentCriterionTypeMinOrderBy>,
}

export interface SegmentCriterionTypeArrRelInsertInput {
  data: Array<SegmentCriterionTypeInsertInput>,
  on_conflict?: Maybe<SegmentCriterionTypeOnConflict>,
}

export interface SegmentCriterionTypeBoolExp {
  _and?: Maybe<Array<Maybe<SegmentCriterionTypeBoolExp>>>,
  _not?: Maybe<SegmentCriterionTypeBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentCriterionTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SegmentCriterionTypeConstraint {
  SegmentCriterionTypeNameKey = 'segment_criterion_type_name_key',
  SegmentCriterionTypePkey = 'segment_criterion_type_pkey'
}

export interface SegmentCriterionTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionTypeMaxFields {
   __typename?: 'segment_criterion_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionTypeMinFields {
   __typename?: 'segment_criterion_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentCriterionTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionTypeMutationResponse {
   __typename?: 'segment_criterion_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentCriterionType>,
}

export interface SegmentCriterionTypeObjRelInsertInput {
  data: SegmentCriterionTypeInsertInput,
  on_conflict?: Maybe<SegmentCriterionTypeOnConflict>,
}

export interface SegmentCriterionTypeOnConflict {
  constraint: SegmentCriterionTypeConstraint,
  update_columns: Array<SegmentCriterionTypeUpdateColumn>,
  where?: Maybe<SegmentCriterionTypeBoolExp>,
}

export interface SegmentCriterionTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentCriterionTypePkColumnsInput {
  name: Scalars['String'],
}

export enum SegmentCriterionTypeSelectColumn {
  CreatedAt = 'created_at',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SegmentCriterionTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SegmentCriterionTypeUpdateColumn {
  CreatedAt = 'created_at',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum SegmentCriterionUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IncludeId = 'include_id',
  ParentId = 'parent_id',
  SegmentId = 'segment_id',
  Type = 'type',
  UpdatedAt = 'updated_at'
}

export interface SegmentGridView {
   __typename?: 'segment_grid_view',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Segment>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentGridViewAggregate {
   __typename?: 'segment_grid_view_aggregate',
  aggregate?: Maybe<SegmentGridViewAggregateFields>,
  nodes: Array<SegmentGridView>,
}

export interface SegmentGridViewAggregateFields {
   __typename?: 'segment_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentGridViewMaxFields>,
  min?: Maybe<SegmentGridViewMinFields>,
}


export interface SegmentGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentGridViewMaxOrderBy>,
  min?: Maybe<SegmentGridViewMinOrderBy>,
}

export interface SegmentGridViewBoolExp {
  _and?: Maybe<Array<Maybe<SegmentGridViewBoolExp>>>,
  _not?: Maybe<SegmentGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentGridViewBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  segment?: Maybe<SegmentBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface SegmentGridViewMaxFields {
   __typename?: 'segment_grid_view_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentGridViewMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentGridViewMinFields {
   __typename?: 'segment_grid_view_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentGridViewMinOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentGridViewOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<SegmentOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export enum SegmentGridViewSelectColumn {
  CreatedAt = 'created_at',
  CreatedBy = 'created_by',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  criteria?: Maybe<SegmentCriterionArrRelInsertInput>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  included_by?: Maybe<SegmentCriterionArrRelInsertInput>,
  is_default?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentMaxFields {
   __typename?: 'segment_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentMinFields {
   __typename?: 'segment_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentMinOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentMutationResponse {
   __typename?: 'segment_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Segment>,
}

export interface SegmentObjRelInsertInput {
  data: SegmentInsertInput,
  on_conflict?: Maybe<SegmentOnConflict>,
}

export interface SegmentOnConflict {
  constraint: SegmentConstraint,
  update_columns: Array<SegmentUpdateColumn>,
  where?: Maybe<SegmentBoolExp>,
}

export interface SegmentOperator {
   __typename?: 'segment_operator',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  symbol: Scalars['String'],
  text: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SegmentOperatorAggregate {
   __typename?: 'segment_operator_aggregate',
  aggregate?: Maybe<SegmentOperatorAggregateFields>,
  nodes: Array<SegmentOperator>,
}

export interface SegmentOperatorAggregateFields {
   __typename?: 'segment_operator_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentOperatorMaxFields>,
  min?: Maybe<SegmentOperatorMinFields>,
}


export interface SegmentOperatorAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentOperatorSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentOperatorAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentOperatorMaxOrderBy>,
  min?: Maybe<SegmentOperatorMinOrderBy>,
}

export interface SegmentOperatorArrRelInsertInput {
  data: Array<SegmentOperatorInsertInput>,
  on_conflict?: Maybe<SegmentOperatorOnConflict>,
}

export interface SegmentOperatorBoolExp {
  _and?: Maybe<Array<Maybe<SegmentOperatorBoolExp>>>,
  _not?: Maybe<SegmentOperatorBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentOperatorBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  symbol?: Maybe<StringComparisonExp>,
  text?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SegmentOperatorConstraint {
  SegmentOperatorNameKey = 'segment_operator_name_key',
  SegmentOperatorPkey = 'segment_operator_pkey',
  SegmentOperatorSymbolKey = 'segment_operator_symbol_key'
}

export interface SegmentOperatorInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  symbol?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorMaxFields {
   __typename?: 'segment_operator_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  symbol?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  symbol?: Maybe<OrderBy>,
  text?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorMinFields {
   __typename?: 'segment_operator_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  symbol?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  symbol?: Maybe<OrderBy>,
  text?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorMutationResponse {
   __typename?: 'segment_operator_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentOperator>,
}

export interface SegmentOperatorObjRelInsertInput {
  data: SegmentOperatorInsertInput,
  on_conflict?: Maybe<SegmentOperatorOnConflict>,
}

export interface SegmentOperatorOnConflict {
  constraint: SegmentOperatorConstraint,
  update_columns: Array<SegmentOperatorUpdateColumn>,
  where?: Maybe<SegmentOperatorBoolExp>,
}

export interface SegmentOperatorOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  symbol?: Maybe<OrderBy>,
  text?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorPkColumnsInput {
  id: Scalars['String'],
}

export enum SegmentOperatorSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Symbol = 'symbol',
  Text = 'text',
  UpdatedAt = 'updated_at'
}

export interface SegmentOperatorSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  symbol?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SegmentOperatorUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Symbol = 'symbol',
  Text = 'text',
  UpdatedAt = 'updated_at'
}

export interface SegmentOperatorsToSegmentPropertyConditions {
   __typename?: 'segment_operators_to_segment_property_conditions',
  created_at: Scalars['timestamptz'],
  operator: SegmentOperator,
  operator_id: Scalars['String'],
  property_condition: SegmentPropertyCondition,
  property_condition_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SegmentOperatorsToSegmentPropertyConditionsAggregate {
   __typename?: 'segment_operators_to_segment_property_conditions_aggregate',
  aggregate?: Maybe<SegmentOperatorsToSegmentPropertyConditionsAggregateFields>,
  nodes: Array<SegmentOperatorsToSegmentPropertyConditions>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsAggregateFields {
   __typename?: 'segment_operators_to_segment_property_conditions_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMaxFields>,
  min?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMinFields>,
}


export interface SegmentOperatorsToSegmentPropertyConditionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentOperatorsToSegmentPropertyConditionsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMaxOrderBy>,
  min?: Maybe<SegmentOperatorsToSegmentPropertyConditionsMinOrderBy>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsArrRelInsertInput {
  data: Array<SegmentOperatorsToSegmentPropertyConditionsInsertInput>,
  on_conflict?: Maybe<SegmentOperatorsToSegmentPropertyConditionsOnConflict>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsBoolExp {
  _and?: Maybe<Array<Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>>>,
  _not?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  operator?: Maybe<SegmentOperatorBoolExp>,
  operator_id?: Maybe<StringComparisonExp>,
  property_condition?: Maybe<SegmentPropertyConditionBoolExp>,
  property_condition_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SegmentOperatorsToSegmentPropertyConditionsConstraint {
  SegmentOperatorsToSegmentPropertyConditionsPkey = 'segment_operators_to_segment_property_conditions_pkey'
}

export interface SegmentOperatorsToSegmentPropertyConditionsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  operator?: Maybe<SegmentOperatorObjRelInsertInput>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition?: Maybe<SegmentPropertyConditionObjRelInsertInput>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsMaxFields {
   __typename?: 'segment_operators_to_segment_property_conditions_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsMinFields {
   __typename?: 'segment_operators_to_segment_property_conditions_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsMutationResponse {
   __typename?: 'segment_operators_to_segment_property_conditions_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentOperatorsToSegmentPropertyConditions>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsObjRelInsertInput {
  data: SegmentOperatorsToSegmentPropertyConditionsInsertInput,
  on_conflict?: Maybe<SegmentOperatorsToSegmentPropertyConditionsOnConflict>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsOnConflict {
  constraint: SegmentOperatorsToSegmentPropertyConditionsConstraint,
  update_columns: Array<SegmentOperatorsToSegmentPropertyConditionsUpdateColumn>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsOrderBy {
  created_at?: Maybe<OrderBy>,
  operator?: Maybe<SegmentOperatorOrderBy>,
  operator_id?: Maybe<OrderBy>,
  property_condition?: Maybe<SegmentPropertyConditionOrderBy>,
  property_condition_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentOperatorsToSegmentPropertyConditionsPkColumnsInput {
  operator_id: Scalars['String'],
  property_condition_id: Scalars['String'],
}

export enum SegmentOperatorsToSegmentPropertyConditionsSelectColumn {
  CreatedAt = 'created_at',
  OperatorId = 'operator_id',
  PropertyConditionId = 'property_condition_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentOperatorsToSegmentPropertyConditionsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  operator_id?: Maybe<Scalars['String']>,
  property_condition_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SegmentOperatorsToSegmentPropertyConditionsUpdateColumn {
  CreatedAt = 'created_at',
  OperatorId = 'operator_id',
  PropertyConditionId = 'property_condition_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentOrderBy {
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  criteria_aggregate?: Maybe<SegmentCriterionAggregateOrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  included_by_aggregate?: Maybe<SegmentCriterionAggregateOrderBy>,
  is_default?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPkColumnsInput {
  id: Scalars['String'],
}

export interface SegmentProperty {
   __typename?: 'segment_property',
  conditions: Array<SegmentPropertyCondition>,
  conditions_aggregate: SegmentPropertyConditionAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface SegmentPropertyConditionsArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}


export interface SegmentPropertyConditionsAggregateArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}

export interface SegmentPropertyAggregate {
   __typename?: 'segment_property_aggregate',
  aggregate?: Maybe<SegmentPropertyAggregateFields>,
  nodes: Array<SegmentProperty>,
}

export interface SegmentPropertyAggregateFields {
   __typename?: 'segment_property_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentPropertyMaxFields>,
  min?: Maybe<SegmentPropertyMinFields>,
}


export interface SegmentPropertyAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentPropertySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentPropertyAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentPropertyMaxOrderBy>,
  min?: Maybe<SegmentPropertyMinOrderBy>,
}

export interface SegmentPropertyArrRelInsertInput {
  data: Array<SegmentPropertyInsertInput>,
  on_conflict?: Maybe<SegmentPropertyOnConflict>,
}

export interface SegmentPropertyBoolExp {
  _and?: Maybe<Array<Maybe<SegmentPropertyBoolExp>>>,
  _not?: Maybe<SegmentPropertyBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentPropertyBoolExp>>>,
  conditions?: Maybe<SegmentPropertyConditionBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export interface SegmentPropertyCondition {
   __typename?: 'segment_property_condition',
  attribute_type?: Maybe<Scalars['String']>,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  metadata?: Maybe<AttributeMetadata>,
  metadata_id?: Maybe<Scalars['String']>,
  operators: Array<SegmentOperatorsToSegmentPropertyConditions>,
  operators_aggregate: SegmentOperatorsToSegmentPropertyConditionsAggregate,
  order: Scalars['Int'],
  path: Scalars['String'],
  property: SegmentProperty,
  property_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface SegmentPropertyConditionOperatorsArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}


export interface SegmentPropertyConditionOperatorsAggregateArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}

export interface SegmentPropertyConditionAggregate {
   __typename?: 'segment_property_condition_aggregate',
  aggregate?: Maybe<SegmentPropertyConditionAggregateFields>,
  nodes: Array<SegmentPropertyCondition>,
}

export interface SegmentPropertyConditionAggregateFields {
   __typename?: 'segment_property_condition_aggregate_fields',
  avg?: Maybe<SegmentPropertyConditionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SegmentPropertyConditionMaxFields>,
  min?: Maybe<SegmentPropertyConditionMinFields>,
  stddev?: Maybe<SegmentPropertyConditionStddevFields>,
  stddev_pop?: Maybe<SegmentPropertyConditionStddevPopFields>,
  stddev_samp?: Maybe<SegmentPropertyConditionStddevSampFields>,
  sum?: Maybe<SegmentPropertyConditionSumFields>,
  var_pop?: Maybe<SegmentPropertyConditionVarPopFields>,
  var_samp?: Maybe<SegmentPropertyConditionVarSampFields>,
  variance?: Maybe<SegmentPropertyConditionVarianceFields>,
}


export interface SegmentPropertyConditionAggregateFieldsCountArgs {
  columns?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SegmentPropertyConditionAggregateOrderBy {
  avg?: Maybe<SegmentPropertyConditionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<SegmentPropertyConditionMaxOrderBy>,
  min?: Maybe<SegmentPropertyConditionMinOrderBy>,
  stddev?: Maybe<SegmentPropertyConditionStddevOrderBy>,
  stddev_pop?: Maybe<SegmentPropertyConditionStddevPopOrderBy>,
  stddev_samp?: Maybe<SegmentPropertyConditionStddevSampOrderBy>,
  sum?: Maybe<SegmentPropertyConditionSumOrderBy>,
  var_pop?: Maybe<SegmentPropertyConditionVarPopOrderBy>,
  var_samp?: Maybe<SegmentPropertyConditionVarSampOrderBy>,
  variance?: Maybe<SegmentPropertyConditionVarianceOrderBy>,
}

export interface SegmentPropertyConditionArrRelInsertInput {
  data: Array<SegmentPropertyConditionInsertInput>,
  on_conflict?: Maybe<SegmentPropertyConditionOnConflict>,
}

export interface SegmentPropertyConditionAvgFields {
   __typename?: 'segment_property_condition_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionBoolExp {
  _and?: Maybe<Array<Maybe<SegmentPropertyConditionBoolExp>>>,
  _not?: Maybe<SegmentPropertyConditionBoolExp>,
  _or?: Maybe<Array<Maybe<SegmentPropertyConditionBoolExp>>>,
  attribute_type?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  metadata?: Maybe<AttributeMetadataBoolExp>,
  metadata_id?: Maybe<StringComparisonExp>,
  operators?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>,
  order?: Maybe<IntComparisonExp>,
  path?: Maybe<StringComparisonExp>,
  property?: Maybe<SegmentPropertyBoolExp>,
  property_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SegmentPropertyConditionConstraint {
  SegmentPropertyConditionPathPropertyIdKey = 'segment_property_condition_path_property_id_key',
  SegmentPropertyConditionPkey = 'segment_property_condition_pkey'
}

export interface SegmentPropertyConditionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface SegmentPropertyConditionInsertInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata?: Maybe<AttributeMetadataObjRelInsertInput>,
  metadata_id?: Maybe<Scalars['String']>,
  operators?: Maybe<SegmentOperatorsToSegmentPropertyConditionsArrRelInsertInput>,
  order?: Maybe<Scalars['Int']>,
  path?: Maybe<Scalars['String']>,
  property?: Maybe<SegmentPropertyObjRelInsertInput>,
  property_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyConditionMaxFields {
   __typename?: 'segment_property_condition_max_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  path?: Maybe<Scalars['String']>,
  property_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyConditionMaxOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  path?: Maybe<OrderBy>,
  property_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionMinFields {
   __typename?: 'segment_property_condition_min_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  path?: Maybe<Scalars['String']>,
  property_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyConditionMinOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  path?: Maybe<OrderBy>,
  property_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionMutationResponse {
   __typename?: 'segment_property_condition_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentPropertyCondition>,
}

export interface SegmentPropertyConditionObjRelInsertInput {
  data: SegmentPropertyConditionInsertInput,
  on_conflict?: Maybe<SegmentPropertyConditionOnConflict>,
}

export interface SegmentPropertyConditionOnConflict {
  constraint: SegmentPropertyConditionConstraint,
  update_columns: Array<SegmentPropertyConditionUpdateColumn>,
  where?: Maybe<SegmentPropertyConditionBoolExp>,
}

export interface SegmentPropertyConditionOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata?: Maybe<AttributeMetadataOrderBy>,
  metadata_id?: Maybe<OrderBy>,
  operators_aggregate?: Maybe<SegmentOperatorsToSegmentPropertyConditionsAggregateOrderBy>,
  order?: Maybe<OrderBy>,
  path?: Maybe<OrderBy>,
  property?: Maybe<SegmentPropertyOrderBy>,
  property_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionPkColumnsInput {
  id: Scalars['String'],
}

export enum SegmentPropertyConditionSelectColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Id = 'id',
  MetadataId = 'metadata_id',
  Order = 'order',
  Path = 'path',
  PropertyId = 'property_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentPropertyConditionSetInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  path?: Maybe<Scalars['String']>,
  property_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyConditionStddevFields {
   __typename?: 'segment_property_condition_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionStddevPopFields {
   __typename?: 'segment_property_condition_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionStddevSampFields {
   __typename?: 'segment_property_condition_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionSumFields {
   __typename?: 'segment_property_condition_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface SegmentPropertyConditionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum SegmentPropertyConditionUpdateColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Id = 'id',
  MetadataId = 'metadata_id',
  Order = 'order',
  Path = 'path',
  PropertyId = 'property_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentPropertyConditionVarPopFields {
   __typename?: 'segment_property_condition_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionVarSampFields {
   __typename?: 'segment_property_condition_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface SegmentPropertyConditionVarianceFields {
   __typename?: 'segment_property_condition_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface SegmentPropertyConditionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum SegmentPropertyConstraint {
  SegmentPropertyNameKey = 'segment_property_name_key',
  SegmentPropertyPkey = 'segment_property_pkey'
}

export interface SegmentPropertyInsertInput {
  conditions?: Maybe<SegmentPropertyConditionArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyMaxFields {
   __typename?: 'segment_property_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyMinFields {
   __typename?: 'segment_property_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SegmentPropertyMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyMutationResponse {
   __typename?: 'segment_property_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SegmentProperty>,
}

export interface SegmentPropertyObjRelInsertInput {
  data: SegmentPropertyInsertInput,
  on_conflict?: Maybe<SegmentPropertyOnConflict>,
}

export interface SegmentPropertyOnConflict {
  constraint: SegmentPropertyConstraint,
  update_columns: Array<SegmentPropertyUpdateColumn>,
  where?: Maybe<SegmentPropertyBoolExp>,
}

export interface SegmentPropertyOrderBy {
  conditions_aggregate?: Maybe<SegmentPropertyConditionAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SegmentPropertyPkColumnsInput {
  id: Scalars['String'],
}

export enum SegmentPropertySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SegmentPropertySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SegmentPropertyUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum SegmentSelectColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  IsDefault = 'is_default',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface SegmentSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_default?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SegmentUpdateColumn {
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  Description = 'description',
  Id = 'id',
  IsDefault = 'is_default',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface SharedFileGridView {
   __typename?: 'shared_file_grid_view',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  file?: Maybe<File>,
  id?: Maybe<Scalars['String']>,
  is_private?: Maybe<Scalars['Boolean']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface SharedFileGridViewAggregate {
   __typename?: 'shared_file_grid_view_aggregate',
  aggregate?: Maybe<SharedFileGridViewAggregateFields>,
  nodes: Array<SharedFileGridView>,
}

export interface SharedFileGridViewAggregateFields {
   __typename?: 'shared_file_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SharedFileGridViewMaxFields>,
  min?: Maybe<SharedFileGridViewMinFields>,
}


export interface SharedFileGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<SharedFileGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SharedFileGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SharedFileGridViewMaxOrderBy>,
  min?: Maybe<SharedFileGridViewMinOrderBy>,
}

export interface SharedFileGridViewBoolExp {
  _and?: Maybe<Array<Maybe<SharedFileGridViewBoolExp>>>,
  _not?: Maybe<SharedFileGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<SharedFileGridViewBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  file?: Maybe<FileBoolExp>,
  id?: Maybe<StringComparisonExp>,
  is_private?: Maybe<BooleanComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  segment?: Maybe<StringComparisonExp>,
  title?: Maybe<StringComparisonExp>,
  uploaded_by?: Maybe<StringComparisonExp>,
}

export interface SharedFileGridViewMaxFields {
   __typename?: 'shared_file_grid_view_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface SharedFileGridViewMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export interface SharedFileGridViewMinFields {
   __typename?: 'shared_file_grid_view_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  segment?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface SharedFileGridViewMinOrderBy {
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export interface SharedFileGridViewOrderBy {
  created_at?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  file?: Maybe<FileOrderBy>,
  id?: Maybe<OrderBy>,
  is_private?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  segment?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export enum SharedFileGridViewSelectColumn {
  CreatedAt = 'created_at',
  Description = 'description',
  Id = 'id',
  IsPrivate = 'is_private',
  OrganizationId = 'organization_id',
  Segment = 'segment',
  Title = 'title',
  UploadedBy = 'uploaded_by'
}

export interface SourceOfFunds {
   __typename?: 'source_of_funds',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SourceOfFundsAggregate {
   __typename?: 'source_of_funds_aggregate',
  aggregate?: Maybe<SourceOfFundsAggregateFields>,
  nodes: Array<SourceOfFunds>,
}

export interface SourceOfFundsAggregateFields {
   __typename?: 'source_of_funds_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SourceOfFundsMaxFields>,
  min?: Maybe<SourceOfFundsMinFields>,
}


export interface SourceOfFundsAggregateFieldsCountArgs {
  columns?: Maybe<Array<SourceOfFundsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SourceOfFundsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SourceOfFundsMaxOrderBy>,
  min?: Maybe<SourceOfFundsMinOrderBy>,
}

export interface SourceOfFundsArrRelInsertInput {
  data: Array<SourceOfFundsInsertInput>,
  on_conflict?: Maybe<SourceOfFundsOnConflict>,
}

export interface SourceOfFundsBoolExp {
  _and?: Maybe<Array<Maybe<SourceOfFundsBoolExp>>>,
  _not?: Maybe<SourceOfFundsBoolExp>,
  _or?: Maybe<Array<Maybe<SourceOfFundsBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SourceOfFundsConstraint {
  SourceOfFundsNameKey = 'source_of_funds_name_key',
  SourceOfFundsPkey = 'source_of_funds_pkey'
}

export interface SourceOfFundsInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SourceOfFundsMaxFields {
   __typename?: 'source_of_funds_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SourceOfFundsMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SourceOfFundsMinFields {
   __typename?: 'source_of_funds_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SourceOfFundsMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SourceOfFundsMutationResponse {
   __typename?: 'source_of_funds_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SourceOfFunds>,
}

export interface SourceOfFundsObjRelInsertInput {
  data: SourceOfFundsInsertInput,
  on_conflict?: Maybe<SourceOfFundsOnConflict>,
}

export interface SourceOfFundsOnConflict {
  constraint: SourceOfFundsConstraint,
  update_columns: Array<SourceOfFundsUpdateColumn>,
  where?: Maybe<SourceOfFundsBoolExp>,
}

export interface SourceOfFundsOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SourceOfFundsPkColumnsInput {
  id: Scalars['String'],
}

export enum SourceOfFundsSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SourceOfFundsSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SourceOfFundsUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SpreadMode {
   __typename?: 'spread_mode',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SpreadModeAggregate {
   __typename?: 'spread_mode_aggregate',
  aggregate?: Maybe<SpreadModeAggregateFields>,
  nodes: Array<SpreadMode>,
}

export interface SpreadModeAggregateFields {
   __typename?: 'spread_mode_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SpreadModeMaxFields>,
  min?: Maybe<SpreadModeMinFields>,
}


export interface SpreadModeAggregateFieldsCountArgs {
  columns?: Maybe<Array<SpreadModeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SpreadModeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SpreadModeMaxOrderBy>,
  min?: Maybe<SpreadModeMinOrderBy>,
}

export interface SpreadModeArrRelInsertInput {
  data: Array<SpreadModeInsertInput>,
  on_conflict?: Maybe<SpreadModeOnConflict>,
}

export interface SpreadModeBoolExp {
  _and?: Maybe<Array<Maybe<SpreadModeBoolExp>>>,
  _not?: Maybe<SpreadModeBoolExp>,
  _or?: Maybe<Array<Maybe<SpreadModeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SpreadModeConstraint {
  SpreadModeNameKey = 'spread_mode_name_key',
  SpreadModePkey = 'spread_mode_pkey'
}

export interface SpreadModeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SpreadModeMaxFields {
   __typename?: 'spread_mode_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SpreadModeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SpreadModeMinFields {
   __typename?: 'spread_mode_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SpreadModeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SpreadModeMutationResponse {
   __typename?: 'spread_mode_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SpreadMode>,
}

export interface SpreadModeObjRelInsertInput {
  data: SpreadModeInsertInput,
  on_conflict?: Maybe<SpreadModeOnConflict>,
}

export interface SpreadModeOnConflict {
  constraint: SpreadModeConstraint,
  update_columns: Array<SpreadModeUpdateColumn>,
  where?: Maybe<SpreadModeBoolExp>,
}

export interface SpreadModeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SpreadModePkColumnsInput {
  id: Scalars['String'],
}

export enum SpreadModeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SpreadModeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SpreadModeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface Stake {
   __typename?: 'stake',
  admin_permission_set_id?: Maybe<Scalars['String']>,
  admin_permission_sets: Array<StakesToAdminPermissions>,
  admin_permission_sets_aggregate: StakesToAdminPermissionsAggregate,
  ancestry?: Maybe<StakeAncestry>,
  ancestry_id?: Maybe<Scalars['String']>,
  authorized: Scalars['Boolean'],
  contact: Scalars['Boolean'],
  created_at: Scalars['timestamptz'],
  holder: Stakeholder,
  holder_id: Scalars['String'],
  id: Scalars['String'],
  is_active: Scalars['Boolean'],
  is_marked_for_deletion: Scalars['Boolean'],
  master_admin_stakeholder_permission: MasterAdminStakeholderPermission,
  master_admin_stakeholder_permission_id: Scalars['String'],
  partner_permission_set?: Maybe<PartnerPermission>,
  partner_permission_set_id?: Maybe<Scalars['String']>,
  primary: Scalars['Boolean'],
  profile: Profile,
  profile_id: Scalars['String'],
  representees: Array<RepresentativesToProfiles>,
  representees_aggregate: RepresentativesToProfilesAggregate,
  titles: Array<StakesToStakeTitles>,
  titles_aggregate: StakesToStakeTitlesAggregate,
  updated_at: Scalars['timestamptz'],
  user_permission_set?: Maybe<UserPermission>,
  user_permission_set_id?: Maybe<Scalars['String']>,
}


export interface StakeAdminPermissionSetsArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface StakeAdminPermissionSetsAggregateArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface StakeRepresenteesArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface StakeRepresenteesAggregateArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface StakeTitlesArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}


export interface StakeTitlesAggregateArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}

export interface StakeAggregate {
   __typename?: 'stake_aggregate',
  aggregate?: Maybe<StakeAggregateFields>,
  nodes: Array<Stake>,
}

export interface StakeAggregateFields {
   __typename?: 'stake_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeMaxFields>,
  min?: Maybe<StakeMinFields>,
}


export interface StakeAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeMaxOrderBy>,
  min?: Maybe<StakeMinOrderBy>,
}

export interface StakeAncestry {
   __typename?: 'stake_ancestry',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  ownership?: Maybe<Scalars['numeric']>,
  parent: Stakeholder,
  parent_id: Scalars['String'],
  stake?: Maybe<Stake>,
  updated_at: Scalars['timestamptz'],
}

export interface StakeAncestryAggregate {
   __typename?: 'stake_ancestry_aggregate',
  aggregate?: Maybe<StakeAncestryAggregateFields>,
  nodes: Array<StakeAncestry>,
}

export interface StakeAncestryAggregateFields {
   __typename?: 'stake_ancestry_aggregate_fields',
  avg?: Maybe<StakeAncestryAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeAncestryMaxFields>,
  min?: Maybe<StakeAncestryMinFields>,
  stddev?: Maybe<StakeAncestryStddevFields>,
  stddev_pop?: Maybe<StakeAncestryStddevPopFields>,
  stddev_samp?: Maybe<StakeAncestryStddevSampFields>,
  sum?: Maybe<StakeAncestrySumFields>,
  var_pop?: Maybe<StakeAncestryVarPopFields>,
  var_samp?: Maybe<StakeAncestryVarSampFields>,
  variance?: Maybe<StakeAncestryVarianceFields>,
}


export interface StakeAncestryAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeAncestrySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeAncestryAggregateOrderBy {
  avg?: Maybe<StakeAncestryAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeAncestryMaxOrderBy>,
  min?: Maybe<StakeAncestryMinOrderBy>,
  stddev?: Maybe<StakeAncestryStddevOrderBy>,
  stddev_pop?: Maybe<StakeAncestryStddevPopOrderBy>,
  stddev_samp?: Maybe<StakeAncestryStddevSampOrderBy>,
  sum?: Maybe<StakeAncestrySumOrderBy>,
  var_pop?: Maybe<StakeAncestryVarPopOrderBy>,
  var_samp?: Maybe<StakeAncestryVarSampOrderBy>,
  variance?: Maybe<StakeAncestryVarianceOrderBy>,
}

export interface StakeAncestryArrRelInsertInput {
  data: Array<StakeAncestryInsertInput>,
  on_conflict?: Maybe<StakeAncestryOnConflict>,
}

export interface StakeAncestryAvgFields {
   __typename?: 'stake_ancestry_avg_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryAvgOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestryBoolExp {
  _and?: Maybe<Array<Maybe<StakeAncestryBoolExp>>>,
  _not?: Maybe<StakeAncestryBoolExp>,
  _or?: Maybe<Array<Maybe<StakeAncestryBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  ownership?: Maybe<NumericComparisonExp>,
  parent?: Maybe<StakeholderBoolExp>,
  parent_id?: Maybe<StringComparisonExp>,
  stake?: Maybe<StakeBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeAncestryConstraint {
  StakeAcestryPkey = 'stake_acestry_pkey'
}

export interface StakeAncestryIncInput {
  ownership?: Maybe<Scalars['numeric']>,
}

export interface StakeAncestryInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  ownership?: Maybe<Scalars['numeric']>,
  parent?: Maybe<StakeholderObjRelInsertInput>,
  parent_id?: Maybe<Scalars['String']>,
  stake?: Maybe<StakeObjRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeAncestryMaxFields {
   __typename?: 'stake_ancestry_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  ownership?: Maybe<Scalars['numeric']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeAncestryMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  ownership?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeAncestryMinFields {
   __typename?: 'stake_ancestry_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  ownership?: Maybe<Scalars['numeric']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeAncestryMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  ownership?: Maybe<OrderBy>,
  parent_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeAncestryMutationResponse {
   __typename?: 'stake_ancestry_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeAncestry>,
}

export interface StakeAncestryObjRelInsertInput {
  data: StakeAncestryInsertInput,
  on_conflict?: Maybe<StakeAncestryOnConflict>,
}

export interface StakeAncestryOnConflict {
  constraint: StakeAncestryConstraint,
  update_columns: Array<StakeAncestryUpdateColumn>,
  where?: Maybe<StakeAncestryBoolExp>,
}

export interface StakeAncestryOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  ownership?: Maybe<OrderBy>,
  parent?: Maybe<StakeholderOrderBy>,
  parent_id?: Maybe<OrderBy>,
  stake?: Maybe<StakeOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeAncestryPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeAncestrySelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Ownership = 'ownership',
  ParentId = 'parent_id',
  UpdatedAt = 'updated_at'
}

export interface StakeAncestrySetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  ownership?: Maybe<Scalars['numeric']>,
  parent_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeAncestryStddevFields {
   __typename?: 'stake_ancestry_stddev_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryStddevOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestryStddevPopFields {
   __typename?: 'stake_ancestry_stddev_pop_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryStddevPopOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestryStddevSampFields {
   __typename?: 'stake_ancestry_stddev_samp_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryStddevSampOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestrySumFields {
   __typename?: 'stake_ancestry_sum_fields',
  ownership?: Maybe<Scalars['numeric']>,
}

export interface StakeAncestrySumOrderBy {
  ownership?: Maybe<OrderBy>,
}

export enum StakeAncestryUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Ownership = 'ownership',
  ParentId = 'parent_id',
  UpdatedAt = 'updated_at'
}

export interface StakeAncestryVarPopFields {
   __typename?: 'stake_ancestry_var_pop_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryVarPopOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestryVarSampFields {
   __typename?: 'stake_ancestry_var_samp_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryVarSampOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeAncestryVarianceFields {
   __typename?: 'stake_ancestry_variance_fields',
  ownership?: Maybe<Scalars['Float']>,
}

export interface StakeAncestryVarianceOrderBy {
  ownership?: Maybe<OrderBy>,
}

export interface StakeArrRelInsertInput {
  data: Array<StakeInsertInput>,
  on_conflict?: Maybe<StakeOnConflict>,
}

export interface StakeBoolExp {
  _and?: Maybe<Array<Maybe<StakeBoolExp>>>,
  _not?: Maybe<StakeBoolExp>,
  _or?: Maybe<Array<Maybe<StakeBoolExp>>>,
  admin_permission_set_id?: Maybe<StringComparisonExp>,
  admin_permission_sets?: Maybe<StakesToAdminPermissionsBoolExp>,
  ancestry?: Maybe<StakeAncestryBoolExp>,
  ancestry_id?: Maybe<StringComparisonExp>,
  authorized?: Maybe<BooleanComparisonExp>,
  contact?: Maybe<BooleanComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  holder?: Maybe<StakeholderBoolExp>,
  holder_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_active?: Maybe<BooleanComparisonExp>,
  is_marked_for_deletion?: Maybe<BooleanComparisonExp>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionBoolExp>,
  master_admin_stakeholder_permission_id?: Maybe<StringComparisonExp>,
  partner_permission_set?: Maybe<PartnerPermissionBoolExp>,
  partner_permission_set_id?: Maybe<StringComparisonExp>,
  primary?: Maybe<BooleanComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  representees?: Maybe<RepresentativesToProfilesBoolExp>,
  titles?: Maybe<StakesToStakeTitlesBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  user_permission_set?: Maybe<UserPermissionBoolExp>,
  user_permission_set_id?: Maybe<StringComparisonExp>,
}

export enum StakeConstraint {
  StakeAncestryIdKey = 'stake_ancestry_id_key',
  StakePkey = 'stake_pkey'
}

export interface StakeInsertInput {
  admin_permission_set_id?: Maybe<Scalars['String']>,
  admin_permission_sets?: Maybe<StakesToAdminPermissionsArrRelInsertInput>,
  ancestry?: Maybe<StakeAncestryObjRelInsertInput>,
  ancestry_id?: Maybe<Scalars['String']>,
  authorized?: Maybe<Scalars['Boolean']>,
  contact?: Maybe<Scalars['Boolean']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  holder?: Maybe<StakeholderObjRelInsertInput>,
  holder_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_marked_for_deletion?: Maybe<Scalars['Boolean']>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionObjRelInsertInput>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  partner_permission_set?: Maybe<PartnerPermissionObjRelInsertInput>,
  partner_permission_set_id?: Maybe<Scalars['String']>,
  primary?: Maybe<Scalars['Boolean']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  representees?: Maybe<RepresentativesToProfilesArrRelInsertInput>,
  titles?: Maybe<StakesToStakeTitlesArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_permission_set?: Maybe<UserPermissionObjRelInsertInput>,
  user_permission_set_id?: Maybe<Scalars['String']>,
}

export interface StakeMaxFields {
   __typename?: 'stake_max_fields',
  admin_permission_set_id?: Maybe<Scalars['String']>,
  ancestry_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  holder_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  partner_permission_set_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_permission_set_id?: Maybe<Scalars['String']>,
}

export interface StakeMaxOrderBy {
  admin_permission_set_id?: Maybe<OrderBy>,
  ancestry_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  holder_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  partner_permission_set_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_permission_set_id?: Maybe<OrderBy>,
}

export interface StakeMinFields {
   __typename?: 'stake_min_fields',
  admin_permission_set_id?: Maybe<Scalars['String']>,
  ancestry_id?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  holder_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  partner_permission_set_id?: Maybe<Scalars['String']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_permission_set_id?: Maybe<Scalars['String']>,
}

export interface StakeMinOrderBy {
  admin_permission_set_id?: Maybe<OrderBy>,
  ancestry_id?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  holder_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  partner_permission_set_id?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_permission_set_id?: Maybe<OrderBy>,
}

export interface StakeMutationResponse {
   __typename?: 'stake_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Stake>,
}

export interface StakeObjRelInsertInput {
  data: StakeInsertInput,
  on_conflict?: Maybe<StakeOnConflict>,
}

export interface StakeOnConflict {
  constraint: StakeConstraint,
  update_columns: Array<StakeUpdateColumn>,
  where?: Maybe<StakeBoolExp>,
}

export interface StakeOrderBy {
  admin_permission_set_id?: Maybe<OrderBy>,
  admin_permission_sets_aggregate?: Maybe<StakesToAdminPermissionsAggregateOrderBy>,
  ancestry?: Maybe<StakeAncestryOrderBy>,
  ancestry_id?: Maybe<OrderBy>,
  authorized?: Maybe<OrderBy>,
  contact?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  holder?: Maybe<StakeholderOrderBy>,
  holder_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_active?: Maybe<OrderBy>,
  is_marked_for_deletion?: Maybe<OrderBy>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionOrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  partner_permission_set?: Maybe<PartnerPermissionOrderBy>,
  partner_permission_set_id?: Maybe<OrderBy>,
  primary?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  representees_aggregate?: Maybe<RepresentativesToProfilesAggregateOrderBy>,
  titles_aggregate?: Maybe<StakesToStakeTitlesAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_permission_set?: Maybe<UserPermissionOrderBy>,
  user_permission_set_id?: Maybe<OrderBy>,
}

export interface StakePkColumnsInput {
  id: Scalars['String'],
}

export enum StakeSelectColumn {
  AdminPermissionSetId = 'admin_permission_set_id',
  AncestryId = 'ancestry_id',
  Authorized = 'authorized',
  Contact = 'contact',
  CreatedAt = 'created_at',
  HolderId = 'holder_id',
  Id = 'id',
  IsActive = 'is_active',
  IsMarkedForDeletion = 'is_marked_for_deletion',
  MasterAdminStakeholderPermissionId = 'master_admin_stakeholder_permission_id',
  PartnerPermissionSetId = 'partner_permission_set_id',
  Primary = 'primary',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at',
  UserPermissionSetId = 'user_permission_set_id'
}

export interface StakeSetInput {
  admin_permission_set_id?: Maybe<Scalars['String']>,
  ancestry_id?: Maybe<Scalars['String']>,
  authorized?: Maybe<Scalars['Boolean']>,
  contact?: Maybe<Scalars['Boolean']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  holder_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_active?: Maybe<Scalars['Boolean']>,
  is_marked_for_deletion?: Maybe<Scalars['Boolean']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  partner_permission_set_id?: Maybe<Scalars['String']>,
  primary?: Maybe<Scalars['Boolean']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_permission_set_id?: Maybe<Scalars['String']>,
}

export interface StakeTitle {
   __typename?: 'stake_title',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface StakeTitleAggregate {
   __typename?: 'stake_title_aggregate',
  aggregate?: Maybe<StakeTitleAggregateFields>,
  nodes: Array<StakeTitle>,
}

export interface StakeTitleAggregateFields {
   __typename?: 'stake_title_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeTitleMaxFields>,
  min?: Maybe<StakeTitleMinFields>,
}


export interface StakeTitleAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeTitleSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeTitleAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeTitleMaxOrderBy>,
  min?: Maybe<StakeTitleMinOrderBy>,
}

export interface StakeTitleArrRelInsertInput {
  data: Array<StakeTitleInsertInput>,
  on_conflict?: Maybe<StakeTitleOnConflict>,
}

export interface StakeTitleBoolExp {
  _and?: Maybe<Array<Maybe<StakeTitleBoolExp>>>,
  _not?: Maybe<StakeTitleBoolExp>,
  _or?: Maybe<Array<Maybe<StakeTitleBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeTitleConstraint {
  StakeTitleNameKey = 'stake_title_name_key',
  StakeTitlePkey = 'stake_title_pkey'
}

export interface StakeTitleInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeTitleMaxFields {
   __typename?: 'stake_title_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeTitleMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeTitleMinFields {
   __typename?: 'stake_title_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeTitleMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeTitleMutationResponse {
   __typename?: 'stake_title_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeTitle>,
}

export interface StakeTitleObjRelInsertInput {
  data: StakeTitleInsertInput,
  on_conflict?: Maybe<StakeTitleOnConflict>,
}

export interface StakeTitleOnConflict {
  constraint: StakeTitleConstraint,
  update_columns: Array<StakeTitleUpdateColumn>,
  where?: Maybe<StakeTitleBoolExp>,
}

export interface StakeTitleOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeTitlePkColumnsInput {
  id: Scalars['String'],
}

export enum StakeTitleSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface StakeTitleSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum StakeTitleUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum StakeUpdateColumn {
  AdminPermissionSetId = 'admin_permission_set_id',
  AncestryId = 'ancestry_id',
  Authorized = 'authorized',
  Contact = 'contact',
  CreatedAt = 'created_at',
  HolderId = 'holder_id',
  Id = 'id',
  IsActive = 'is_active',
  IsMarkedForDeletion = 'is_marked_for_deletion',
  MasterAdminStakeholderPermissionId = 'master_admin_stakeholder_permission_id',
  PartnerPermissionSetId = 'partner_permission_set_id',
  Primary = 'primary',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at',
  UserPermissionSetId = 'user_permission_set_id'
}

export interface Stakeholder {
   __typename?: 'stakeholder',
  attribute_name?: Maybe<StakeholderAttributePivot>,
  attribute_pivot?: Maybe<StakeholderAttributePivot>,
  attributes: Array<StakeholderAttribute>,
  attributes_aggregate: StakeholderAttributeAggregate,
  avatar?: Maybe<Scalars['String']>,
  bank_accounts: Array<BankAccount>,
  bank_accounts_aggregate: BankAccountAggregate,
  class: StakeholderClass,
  class_id: Scalars['String'],
  color: Scalars['String'],
  created_at?: Maybe<Scalars['timestamptz']>,
  files: Array<FilesToStakeholders>,
  files_aggregate: FilesToStakeholdersAggregate,
  id: Scalars['String'],
  is_marketing_communication_allowed: Scalars['Boolean'],
  locale?: Maybe<Locale>,
  locale_id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission: MasterAdminStakeholderPermission,
  master_admin_stakeholder_permission_id: Scalars['String'],
  master_partner_stakeholder_permission: MasterPartnerStakeholderPermission,
  master_partner_stakeholder_permission_id: Scalars['String'],
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  stakeholder_files_grid_view?: Maybe<StakeholderFileGridView>,
  stakeholder_name?: Maybe<StakeholderName>,
  stakes: Array<Stake>,
  stakes_aggregate: StakeAggregate,
  timezone?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
  user_invite?: Maybe<UserInvite>,
  user_invite_id?: Maybe<Scalars['String']>,
  user_management_grid_view?: Maybe<UserManagementGridView>,
}


export interface StakeholderAttributesArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface StakeholderAttributesAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface StakeholderBankAccountsArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface StakeholderBankAccountsAggregateArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface StakeholderFilesArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface StakeholderFilesAggregateArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface StakeholderStakesArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface StakeholderStakesAggregateArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}

export interface StakeholderAggregate {
   __typename?: 'stakeholder_aggregate',
  aggregate?: Maybe<StakeholderAggregateFields>,
  nodes: Array<Stakeholder>,
}

export interface StakeholderAggregateFields {
   __typename?: 'stakeholder_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderMaxFields>,
  min?: Maybe<StakeholderMinFields>,
}


export interface StakeholderAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderMaxOrderBy>,
  min?: Maybe<StakeholderMinOrderBy>,
}

export interface StakeholderArrRelInsertInput {
  data: Array<StakeholderInsertInput>,
  on_conflict?: Maybe<StakeholderOnConflict>,
}

export interface StakeholderAttribute {
   __typename?: 'stakeholder_attribute',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  key: StakeholderAttributeKey,
  key_id: Scalars['String'],
  stakeholder: Stakeholder,
  stakeholder_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  value: Scalars['String'],
}

export interface StakeholderAttributeAggregate {
   __typename?: 'stakeholder_attribute_aggregate',
  aggregate?: Maybe<StakeholderAttributeAggregateFields>,
  nodes: Array<StakeholderAttribute>,
}

export interface StakeholderAttributeAggregateFields {
   __typename?: 'stakeholder_attribute_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributeMaxFields>,
  min?: Maybe<StakeholderAttributeMinFields>,
}


export interface StakeholderAttributeAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributeMaxOrderBy>,
  min?: Maybe<StakeholderAttributeMinOrderBy>,
}

export interface StakeholderAttributeArrRelInsertInput {
  data: Array<StakeholderAttributeInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeOnConflict>,
}

export interface StakeholderAttributeBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributeBoolExp>>>,
  _not?: Maybe<StakeholderAttributeBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  key?: Maybe<StakeholderAttributeKeyBoolExp>,
  key_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  value?: Maybe<StringComparisonExp>,
}

export enum StakeholderAttributeConstraint {
  StakeholderAttributeKeyIdStakeholderIdKey = 'stakeholder_attribute_key_id_stakeholder_id_key',
  StakeholderAttributePkey = 'stakeholder_attribute_pkey'
}

export interface StakeholderAttributeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key?: Maybe<StakeholderAttributeKeyObjRelInsertInput>,
  key_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<StakeholderObjRelInsertInput>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributeKey {
   __typename?: 'stakeholder_attribute_key',
  attribute_type: Scalars['String'],
  classes: Array<StakeholderAttributeKeysToStakeholderClasses>,
  classes_aggregate: StakeholderAttributeKeysToStakeholderClassesAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_key: Scalars['Boolean'],
  is_required: Scalars['Boolean'],
  is_segmentable: Scalars['Boolean'],
  metadata?: Maybe<AttributeMetadata>,
  metadata_id?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  order: Scalars['Int'],
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  pivot_name?: Maybe<Scalars['String']>,
  subsection: StakeholderAttributeSubsection,
  subsection_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface StakeholderAttributeKeyClassesArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface StakeholderAttributeKeyClassesAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}

export interface StakeholderAttributeKeyAggregate {
   __typename?: 'stakeholder_attribute_key_aggregate',
  aggregate?: Maybe<StakeholderAttributeKeyAggregateFields>,
  nodes: Array<StakeholderAttributeKey>,
}

export interface StakeholderAttributeKeyAggregateFields {
   __typename?: 'stakeholder_attribute_key_aggregate_fields',
  avg?: Maybe<StakeholderAttributeKeyAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributeKeyMaxFields>,
  min?: Maybe<StakeholderAttributeKeyMinFields>,
  stddev?: Maybe<StakeholderAttributeKeyStddevFields>,
  stddev_pop?: Maybe<StakeholderAttributeKeyStddevPopFields>,
  stddev_samp?: Maybe<StakeholderAttributeKeyStddevSampFields>,
  sum?: Maybe<StakeholderAttributeKeySumFields>,
  var_pop?: Maybe<StakeholderAttributeKeyVarPopFields>,
  var_samp?: Maybe<StakeholderAttributeKeyVarSampFields>,
  variance?: Maybe<StakeholderAttributeKeyVarianceFields>,
}


export interface StakeholderAttributeKeyAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributeKeyAggregateOrderBy {
  avg?: Maybe<StakeholderAttributeKeyAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributeKeyMaxOrderBy>,
  min?: Maybe<StakeholderAttributeKeyMinOrderBy>,
  stddev?: Maybe<StakeholderAttributeKeyStddevOrderBy>,
  stddev_pop?: Maybe<StakeholderAttributeKeyStddevPopOrderBy>,
  stddev_samp?: Maybe<StakeholderAttributeKeyStddevSampOrderBy>,
  sum?: Maybe<StakeholderAttributeKeySumOrderBy>,
  var_pop?: Maybe<StakeholderAttributeKeyVarPopOrderBy>,
  var_samp?: Maybe<StakeholderAttributeKeyVarSampOrderBy>,
  variance?: Maybe<StakeholderAttributeKeyVarianceOrderBy>,
}

export interface StakeholderAttributeKeyArrRelInsertInput {
  data: Array<StakeholderAttributeKeyInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeKeyOnConflict>,
}

export interface StakeholderAttributeKeyAvgFields {
   __typename?: 'stakeholder_attribute_key_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributeKeyBoolExp>>>,
  _not?: Maybe<StakeholderAttributeKeyBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributeKeyBoolExp>>>,
  attribute_type?: Maybe<StringComparisonExp>,
  classes?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_key?: Maybe<BooleanComparisonExp>,
  is_required?: Maybe<BooleanComparisonExp>,
  is_segmentable?: Maybe<BooleanComparisonExp>,
  metadata?: Maybe<AttributeMetadataBoolExp>,
  metadata_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  pivot_name?: Maybe<StringComparisonExp>,
  subsection?: Maybe<StakeholderAttributeSubsectionBoolExp>,
  subsection_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeholderAttributeKeyConstraint {
  StakeholderAttributeKeyNameOrganizationIdKey = 'stakeholder_attribute_key_name_organization_id_key',
  StakeholderAttributeKeyOrderSubsectionIdKey = 'stakeholder_attribute_key_order_subsection_id_key',
  StakeholderAttributeKeyPivotNameKey = 'stakeholder_attribute_key_pivot_name_key',
  StakeholderAttributeKeyPkey = 'stakeholder_attribute_key_pkey'
}

export interface StakeholderAttributeKeyIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeKeyInsertInput {
  attribute_type?: Maybe<Scalars['String']>,
  classes?: Maybe<StakeholderAttributeKeysToStakeholderClassesArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_key?: Maybe<Scalars['Boolean']>,
  is_required?: Maybe<Scalars['Boolean']>,
  is_segmentable?: Maybe<Scalars['Boolean']>,
  metadata?: Maybe<AttributeMetadataObjRelInsertInput>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  pivot_name?: Maybe<Scalars['String']>,
  subsection?: Maybe<StakeholderAttributeSubsectionObjRelInsertInput>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeyMaxFields {
   __typename?: 'stakeholder_attribute_key_max_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  organization_id?: Maybe<Scalars['String']>,
  pivot_name?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeyMaxOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  pivot_name?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyMinFields {
   __typename?: 'stakeholder_attribute_key_min_fields',
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  organization_id?: Maybe<Scalars['String']>,
  pivot_name?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeyMinOrderBy {
  attribute_type?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  pivot_name?: Maybe<OrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyMutationResponse {
   __typename?: 'stakeholder_attribute_key_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderAttributeKey>,
}

export interface StakeholderAttributeKeyObjRelInsertInput {
  data: StakeholderAttributeKeyInsertInput,
  on_conflict?: Maybe<StakeholderAttributeKeyOnConflict>,
}

export interface StakeholderAttributeKeyOnConflict {
  constraint: StakeholderAttributeKeyConstraint,
  update_columns: Array<StakeholderAttributeKeyUpdateColumn>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>,
}

export interface StakeholderAttributeKeyOrderBy {
  attribute_type?: Maybe<OrderBy>,
  classes_aggregate?: Maybe<StakeholderAttributeKeysToStakeholderClassesAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_key?: Maybe<OrderBy>,
  is_required?: Maybe<OrderBy>,
  is_segmentable?: Maybe<OrderBy>,
  metadata?: Maybe<AttributeMetadataOrderBy>,
  metadata_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  pivot_name?: Maybe<OrderBy>,
  subsection?: Maybe<StakeholderAttributeSubsectionOrderBy>,
  subsection_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeholderAttributeKeySelectColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Id = 'id',
  IsKey = 'is_key',
  IsRequired = 'is_required',
  IsSegmentable = 'is_segmentable',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  OrganizationId = 'organization_id',
  PivotName = 'pivot_name',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeKeySetInput {
  attribute_type?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_key?: Maybe<Scalars['Boolean']>,
  is_required?: Maybe<Scalars['Boolean']>,
  is_segmentable?: Maybe<Scalars['Boolean']>,
  metadata_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  organization_id?: Maybe<Scalars['String']>,
  pivot_name?: Maybe<Scalars['String']>,
  subsection_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeyStddevFields {
   __typename?: 'stakeholder_attribute_key_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyStddevPopFields {
   __typename?: 'stakeholder_attribute_key_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyStddevSampFields {
   __typename?: 'stakeholder_attribute_key_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeySumFields {
   __typename?: 'stakeholder_attribute_key_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeKeySumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum StakeholderAttributeKeyUpdateColumn {
  AttributeType = 'attribute_type',
  CreatedAt = 'created_at',
  Id = 'id',
  IsKey = 'is_key',
  IsRequired = 'is_required',
  IsSegmentable = 'is_segmentable',
  MetadataId = 'metadata_id',
  Name = 'name',
  Order = 'order',
  OrganizationId = 'organization_id',
  PivotName = 'pivot_name',
  SubsectionId = 'subsection_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeKeyVarPopFields {
   __typename?: 'stakeholder_attribute_key_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyVarSampFields {
   __typename?: 'stakeholder_attribute_key_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeyVarianceFields {
   __typename?: 'stakeholder_attribute_key_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeKeyVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeysToStakeholderClasses {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes',
  class: StakeholderClass,
  created_at: Scalars['timestamptz'],
  key: StakeholderAttributeKey,
  stakeholder_attribute_key_id: Scalars['String'],
  stakeholder_class_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface StakeholderAttributeKeysToStakeholderClassesAggregate {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes_aggregate',
  aggregate?: Maybe<StakeholderAttributeKeysToStakeholderClassesAggregateFields>,
  nodes: Array<StakeholderAttributeKeysToStakeholderClasses>,
}

export interface StakeholderAttributeKeysToStakeholderClassesAggregateFields {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributeKeysToStakeholderClassesMaxFields>,
  min?: Maybe<StakeholderAttributeKeysToStakeholderClassesMinFields>,
}


export interface StakeholderAttributeKeysToStakeholderClassesAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributeKeysToStakeholderClassesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributeKeysToStakeholderClassesMaxOrderBy>,
  min?: Maybe<StakeholderAttributeKeysToStakeholderClassesMinOrderBy>,
}

export interface StakeholderAttributeKeysToStakeholderClassesArrRelInsertInput {
  data: Array<StakeholderAttributeKeysToStakeholderClassesInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeKeysToStakeholderClassesOnConflict>,
}

export interface StakeholderAttributeKeysToStakeholderClassesBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>>>,
  _not?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>>>,
  class?: Maybe<StakeholderClassBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  key?: Maybe<StakeholderAttributeKeyBoolExp>,
  stakeholder_attribute_key_id?: Maybe<StringComparisonExp>,
  stakeholder_class_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeholderAttributeKeysToStakeholderClassesConstraint {
  StakeholderAttributesToStakeholderClassesPkey = 'stakeholder_attributes_to_stakeholder_classes_pkey'
}

export interface StakeholderAttributeKeysToStakeholderClassesInsertInput {
  class?: Maybe<StakeholderClassObjRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  key?: Maybe<StakeholderAttributeKeyObjRelInsertInput>,
  stakeholder_attribute_key_id?: Maybe<Scalars['String']>,
  stakeholder_class_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeysToStakeholderClassesMaxFields {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_attribute_key_id?: Maybe<Scalars['String']>,
  stakeholder_class_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeysToStakeholderClassesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  stakeholder_attribute_key_id?: Maybe<OrderBy>,
  stakeholder_class_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeysToStakeholderClassesMinFields {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_attribute_key_id?: Maybe<Scalars['String']>,
  stakeholder_class_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeKeysToStakeholderClassesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  stakeholder_attribute_key_id?: Maybe<OrderBy>,
  stakeholder_class_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeysToStakeholderClassesMutationResponse {
   __typename?: 'stakeholder_attribute_keys_to_stakeholder_classes_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderAttributeKeysToStakeholderClasses>,
}

export interface StakeholderAttributeKeysToStakeholderClassesObjRelInsertInput {
  data: StakeholderAttributeKeysToStakeholderClassesInsertInput,
  on_conflict?: Maybe<StakeholderAttributeKeysToStakeholderClassesOnConflict>,
}

export interface StakeholderAttributeKeysToStakeholderClassesOnConflict {
  constraint: StakeholderAttributeKeysToStakeholderClassesConstraint,
  update_columns: Array<StakeholderAttributeKeysToStakeholderClassesUpdateColumn>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>,
}

export interface StakeholderAttributeKeysToStakeholderClassesOrderBy {
  class?: Maybe<StakeholderClassOrderBy>,
  created_at?: Maybe<OrderBy>,
  key?: Maybe<StakeholderAttributeKeyOrderBy>,
  stakeholder_attribute_key_id?: Maybe<OrderBy>,
  stakeholder_class_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeKeysToStakeholderClassesPkColumnsInput {
  stakeholder_attribute_key_id: Scalars['String'],
  stakeholder_class_id: Scalars['String'],
}

export enum StakeholderAttributeKeysToStakeholderClassesSelectColumn {
  CreatedAt = 'created_at',
  StakeholderAttributeKeyId = 'stakeholder_attribute_key_id',
  StakeholderClassId = 'stakeholder_class_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeKeysToStakeholderClassesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  stakeholder_attribute_key_id?: Maybe<Scalars['String']>,
  stakeholder_class_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum StakeholderAttributeKeysToStakeholderClassesUpdateColumn {
  CreatedAt = 'created_at',
  StakeholderAttributeKeyId = 'stakeholder_attribute_key_id',
  StakeholderClassId = 'stakeholder_class_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeMaxFields {
   __typename?: 'stakeholder_attribute_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface StakeholderAttributeMinFields {
   __typename?: 'stakeholder_attribute_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key_id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface StakeholderAttributeMutationResponse {
   __typename?: 'stakeholder_attribute_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderAttribute>,
}

export interface StakeholderAttributeObjRelInsertInput {
  data: StakeholderAttributeInsertInput,
  on_conflict?: Maybe<StakeholderAttributeOnConflict>,
}

export interface StakeholderAttributeOnConflict {
  constraint: StakeholderAttributeConstraint,
  update_columns: Array<StakeholderAttributeUpdateColumn>,
  where?: Maybe<StakeholderAttributeBoolExp>,
}

export interface StakeholderAttributeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  key?: Maybe<StakeholderAttributeKeyOrderBy>,
  key_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
}

export interface StakeholderAttributePivot {
   __typename?: 'stakeholder_attribute_pivot',
  address_1?: Maybe<Scalars['String']>,
  address_2?: Maybe<Scalars['String']>,
  annual_income?: Maybe<AnnualIncome>,
  annual_income_id?: Maybe<Scalars['String']>,
  auditor_name?: Maybe<Scalars['String']>,
  auditor_phone?: Maybe<Scalars['String']>,
  business_nature?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Country>,
  country_id?: Maybe<Scalars['String']>,
  country_of_birth?: Maybe<Country>,
  country_of_birth_id?: Maybe<Scalars['String']>,
  country_of_citizenship?: Maybe<Country>,
  country_of_citizenship_id?: Maybe<Scalars['String']>,
  country_of_nationality?: Maybe<Country>,
  country_of_nationality_id?: Maybe<Scalars['String']>,
  country_of_residence?: Maybe<Country>,
  country_of_residence_id?: Maybe<Scalars['String']>,
  date_of_birth?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emir_classification?: Maybe<Scalars['String']>,
  employees_registered_financial_sector?: Maybe<Scalars['String']>,
  employment_industry?: Maybe<Scalars['String']>,
  employment_status?: Maybe<EmploymentStatus>,
  employment_status_id?: Maybe<Scalars['String']>,
  entity_name?: Maybe<Scalars['String']>,
  entity_number?: Maybe<Scalars['String']>,
  entity_type?: Maybe<Scalars['String']>,
  fatca_certification?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  fulltime_employees?: Maybe<Scalars['String']>,
  funding_source?: Maybe<SourceOfFunds>,
  funding_source_id?: Maybe<Scalars['String']>,
  futures_deal_size?: Maybe<Scalars['String']>,
  futures_experience_gained?: Maybe<ExperienceType>,
  futures_experience_gained_id?: Maybe<Scalars['String']>,
  futures_trades_month?: Maybe<Scalars['String']>,
  futures_years_experience?: Maybe<YearsExperience>,
  futures_years_experience_id?: Maybe<Scalars['String']>,
  gender?: Maybe<Gender>,
  gender_id?: Maybe<Scalars['String']>,
  had_account?: Maybe<Scalars['String']>,
  has_auditor?: Maybe<Scalars['String']>,
  has_filed_bankruptcy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  identification_number?: Maybe<Scalars['String']>,
  identification_type?: Maybe<IdentificationType>,
  identification_type_id?: Maybe<Scalars['String']>,
  incorporation_country?: Maybe<Country>,
  incorporation_country_id?: Maybe<Scalars['String']>,
  incorporation_date?: Maybe<Scalars['String']>,
  initial_deposit?: Maybe<Scalars['String']>,
  investment_objective?: Maybe<InvestmentObjective>,
  investment_objective_id?: Maybe<Scalars['String']>,
  is_anti_laundering_regulated?: Maybe<Scalars['String']>,
  is_currently_employed_financial_sector?: Maybe<Scalars['String']>,
  is_currently_registered_financial_sector?: Maybe<Scalars['String']>,
  is_entity_regulated?: Maybe<Scalars['String']>,
  is_pending_legal_action?: Maybe<Scalars['String']>,
  is_politically_exposed?: Maybe<Scalars['String']>,
  is_required_to_register_financial_sector?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  legal_entity_identifier?: Maybe<Scalars['String']>,
  license_expiration?: Maybe<Scalars['String']>,
  license_number?: Maybe<Scalars['String']>,
  liquid_assets?: Maybe<LiquidAssets>,
  liquid_assets_id?: Maybe<Scalars['String']>,
  middle_name?: Maybe<Scalars['String']>,
  mifid2_identification?: Maybe<Scalars['String']>,
  mifid2_type?: Maybe<Scalars['String']>,
  mobile_number?: Maybe<Scalars['String']>,
  months_at_address?: Maybe<Scalars['String']>,
  not_regulated_reason?: Maybe<Scalars['String']>,
  occupation?: Maybe<Scalars['String']>,
  options_deal_size?: Maybe<Scalars['String']>,
  options_experience_gained?: Maybe<ExperienceType>,
  options_experience_gained_id?: Maybe<Scalars['String']>,
  options_trades_month?: Maybe<Scalars['String']>,
  options_years_experience?: Maybe<YearsExperience>,
  options_years_experience_id?: Maybe<Scalars['String']>,
  other_assets?: Maybe<Scalars['String']>,
  phone_id?: Maybe<Scalars['String']>,
  phone_number?: Maybe<Scalars['String']>,
  place_of_business?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  previous_address_1?: Maybe<Scalars['String']>,
  previous_address_2?: Maybe<Scalars['String']>,
  previous_city?: Maybe<Scalars['String']>,
  previous_country?: Maybe<Country>,
  previous_country_id?: Maybe<Scalars['String']>,
  previous_postal_code?: Maybe<Scalars['String']>,
  primary_phone_type?: Maybe<PhoneType>,
  primary_phone_type_id?: Maybe<Scalars['String']>,
  reason_no_lei?: Maybe<Scalars['String']>,
  registration_country?: Maybe<Country>,
  registration_country_id?: Maybe<Scalars['String']>,
  regulatory_authority?: Maybe<Scalars['String']>,
  regulatory_authority_for_employees?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['String']>,
  shares_deal_size?: Maybe<Scalars['String']>,
  shares_experience_gained?: Maybe<ExperienceType>,
  shares_experience_gained_id?: Maybe<Scalars['String']>,
  shares_trades_month?: Maybe<Scalars['String']>,
  shares_years_experience?: Maybe<YearsExperience>,
  shares_years_experience_id?: Maybe<Scalars['String']>,
  speaks_english?: Maybe<Scalars['String']>,
  spot_deal_size?: Maybe<Scalars['String']>,
  spot_experience_gained?: Maybe<ExperienceType>,
  spot_experience_gained_id?: Maybe<Scalars['String']>,
  spot_trades_month?: Maybe<Scalars['String']>,
  spot_years_experience?: Maybe<YearsExperience>,
  spot_years_experience_id?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<Stakeholder>,
  tax_country?: Maybe<Country>,
  tax_country_id?: Maybe<Scalars['String']>,
  tax_number?: Maybe<Scalars['String']>,
  title?: Maybe<NameTitle>,
  title_id?: Maybe<Scalars['String']>,
  trading_capital?: Maybe<Scalars['String']>,
  usa_tax_number?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  was_previously_employed_financial_sector?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributePivotAggregate {
   __typename?: 'stakeholder_attribute_pivot_aggregate',
  aggregate?: Maybe<StakeholderAttributePivotAggregateFields>,
  nodes: Array<StakeholderAttributePivot>,
}

export interface StakeholderAttributePivotAggregateFields {
   __typename?: 'stakeholder_attribute_pivot_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributePivotMaxFields>,
  min?: Maybe<StakeholderAttributePivotMinFields>,
}


export interface StakeholderAttributePivotAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributePivotSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributePivotAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributePivotMaxOrderBy>,
  min?: Maybe<StakeholderAttributePivotMinOrderBy>,
}

export interface StakeholderAttributePivotBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributePivotBoolExp>>>,
  _not?: Maybe<StakeholderAttributePivotBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributePivotBoolExp>>>,
  address_1?: Maybe<StringComparisonExp>,
  address_2?: Maybe<StringComparisonExp>,
  annual_income?: Maybe<AnnualIncomeBoolExp>,
  annual_income_id?: Maybe<StringComparisonExp>,
  auditor_name?: Maybe<StringComparisonExp>,
  auditor_phone?: Maybe<StringComparisonExp>,
  business_nature?: Maybe<StringComparisonExp>,
  city?: Maybe<StringComparisonExp>,
  country?: Maybe<CountryBoolExp>,
  country_id?: Maybe<StringComparisonExp>,
  country_of_birth?: Maybe<CountryBoolExp>,
  country_of_birth_id?: Maybe<StringComparisonExp>,
  country_of_citizenship?: Maybe<CountryBoolExp>,
  country_of_citizenship_id?: Maybe<StringComparisonExp>,
  country_of_nationality?: Maybe<CountryBoolExp>,
  country_of_nationality_id?: Maybe<StringComparisonExp>,
  country_of_residence?: Maybe<CountryBoolExp>,
  country_of_residence_id?: Maybe<StringComparisonExp>,
  date_of_birth?: Maybe<StringComparisonExp>,
  email?: Maybe<StringComparisonExp>,
  emir_classification?: Maybe<StringComparisonExp>,
  employees_registered_financial_sector?: Maybe<StringComparisonExp>,
  employment_industry?: Maybe<StringComparisonExp>,
  employment_status?: Maybe<EmploymentStatusBoolExp>,
  employment_status_id?: Maybe<StringComparisonExp>,
  entity_name?: Maybe<StringComparisonExp>,
  entity_number?: Maybe<StringComparisonExp>,
  entity_type?: Maybe<StringComparisonExp>,
  fatca_certification?: Maybe<StringComparisonExp>,
  first_name?: Maybe<StringComparisonExp>,
  fulltime_employees?: Maybe<StringComparisonExp>,
  funding_source?: Maybe<SourceOfFundsBoolExp>,
  funding_source_id?: Maybe<StringComparisonExp>,
  futures_deal_size?: Maybe<StringComparisonExp>,
  futures_experience_gained?: Maybe<ExperienceTypeBoolExp>,
  futures_experience_gained_id?: Maybe<StringComparisonExp>,
  futures_trades_month?: Maybe<StringComparisonExp>,
  futures_years_experience?: Maybe<YearsExperienceBoolExp>,
  futures_years_experience_id?: Maybe<StringComparisonExp>,
  gender?: Maybe<GenderBoolExp>,
  gender_id?: Maybe<StringComparisonExp>,
  had_account?: Maybe<StringComparisonExp>,
  has_auditor?: Maybe<StringComparisonExp>,
  has_filed_bankruptcy?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  identification_number?: Maybe<StringComparisonExp>,
  identification_type?: Maybe<IdentificationTypeBoolExp>,
  identification_type_id?: Maybe<StringComparisonExp>,
  incorporation_country?: Maybe<CountryBoolExp>,
  incorporation_country_id?: Maybe<StringComparisonExp>,
  incorporation_date?: Maybe<StringComparisonExp>,
  initial_deposit?: Maybe<StringComparisonExp>,
  investment_objective?: Maybe<InvestmentObjectiveBoolExp>,
  investment_objective_id?: Maybe<StringComparisonExp>,
  is_anti_laundering_regulated?: Maybe<StringComparisonExp>,
  is_currently_employed_financial_sector?: Maybe<StringComparisonExp>,
  is_currently_registered_financial_sector?: Maybe<StringComparisonExp>,
  is_entity_regulated?: Maybe<StringComparisonExp>,
  is_pending_legal_action?: Maybe<StringComparisonExp>,
  is_politically_exposed?: Maybe<StringComparisonExp>,
  is_required_to_register_financial_sector?: Maybe<StringComparisonExp>,
  language?: Maybe<StringComparisonExp>,
  last_name?: Maybe<StringComparisonExp>,
  legal_entity_identifier?: Maybe<StringComparisonExp>,
  license_expiration?: Maybe<StringComparisonExp>,
  license_number?: Maybe<StringComparisonExp>,
  liquid_assets?: Maybe<LiquidAssetsBoolExp>,
  liquid_assets_id?: Maybe<StringComparisonExp>,
  middle_name?: Maybe<StringComparisonExp>,
  mifid2_identification?: Maybe<StringComparisonExp>,
  mifid2_type?: Maybe<StringComparisonExp>,
  mobile_number?: Maybe<StringComparisonExp>,
  months_at_address?: Maybe<StringComparisonExp>,
  not_regulated_reason?: Maybe<StringComparisonExp>,
  occupation?: Maybe<StringComparisonExp>,
  options_deal_size?: Maybe<StringComparisonExp>,
  options_experience_gained?: Maybe<ExperienceTypeBoolExp>,
  options_experience_gained_id?: Maybe<StringComparisonExp>,
  options_trades_month?: Maybe<StringComparisonExp>,
  options_years_experience?: Maybe<YearsExperienceBoolExp>,
  options_years_experience_id?: Maybe<StringComparisonExp>,
  other_assets?: Maybe<StringComparisonExp>,
  phone_id?: Maybe<StringComparisonExp>,
  phone_number?: Maybe<StringComparisonExp>,
  place_of_business?: Maybe<StringComparisonExp>,
  postal_code?: Maybe<StringComparisonExp>,
  previous_address_1?: Maybe<StringComparisonExp>,
  previous_address_2?: Maybe<StringComparisonExp>,
  previous_city?: Maybe<StringComparisonExp>,
  previous_country?: Maybe<CountryBoolExp>,
  previous_country_id?: Maybe<StringComparisonExp>,
  previous_postal_code?: Maybe<StringComparisonExp>,
  primary_phone_type?: Maybe<PhoneTypeBoolExp>,
  primary_phone_type_id?: Maybe<StringComparisonExp>,
  reason_no_lei?: Maybe<StringComparisonExp>,
  registration_country?: Maybe<CountryBoolExp>,
  registration_country_id?: Maybe<StringComparisonExp>,
  regulatory_authority?: Maybe<StringComparisonExp>,
  regulatory_authority_for_employees?: Maybe<StringComparisonExp>,
  score?: Maybe<StringComparisonExp>,
  shares_deal_size?: Maybe<StringComparisonExp>,
  shares_experience_gained?: Maybe<ExperienceTypeBoolExp>,
  shares_experience_gained_id?: Maybe<StringComparisonExp>,
  shares_trades_month?: Maybe<StringComparisonExp>,
  shares_years_experience?: Maybe<YearsExperienceBoolExp>,
  shares_years_experience_id?: Maybe<StringComparisonExp>,
  speaks_english?: Maybe<StringComparisonExp>,
  spot_deal_size?: Maybe<StringComparisonExp>,
  spot_experience_gained?: Maybe<ExperienceTypeBoolExp>,
  spot_experience_gained_id?: Maybe<StringComparisonExp>,
  spot_trades_month?: Maybe<StringComparisonExp>,
  spot_years_experience?: Maybe<YearsExperienceBoolExp>,
  spot_years_experience_id?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  tax_country?: Maybe<CountryBoolExp>,
  tax_country_id?: Maybe<StringComparisonExp>,
  tax_number?: Maybe<StringComparisonExp>,
  title?: Maybe<NameTitleBoolExp>,
  title_id?: Maybe<StringComparisonExp>,
  trading_capital?: Maybe<StringComparisonExp>,
  usa_tax_number?: Maybe<StringComparisonExp>,
  value?: Maybe<StringComparisonExp>,
  was_previously_employed_financial_sector?: Maybe<StringComparisonExp>,
  website?: Maybe<StringComparisonExp>,
}

export interface StakeholderAttributePivotMaxFields {
   __typename?: 'stakeholder_attribute_pivot_max_fields',
  address_1?: Maybe<Scalars['String']>,
  address_2?: Maybe<Scalars['String']>,
  annual_income_id?: Maybe<Scalars['String']>,
  auditor_name?: Maybe<Scalars['String']>,
  auditor_phone?: Maybe<Scalars['String']>,
  business_nature?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  country_of_birth_id?: Maybe<Scalars['String']>,
  country_of_citizenship_id?: Maybe<Scalars['String']>,
  country_of_nationality_id?: Maybe<Scalars['String']>,
  country_of_residence_id?: Maybe<Scalars['String']>,
  date_of_birth?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emir_classification?: Maybe<Scalars['String']>,
  employees_registered_financial_sector?: Maybe<Scalars['String']>,
  employment_industry?: Maybe<Scalars['String']>,
  employment_status_id?: Maybe<Scalars['String']>,
  entity_name?: Maybe<Scalars['String']>,
  entity_number?: Maybe<Scalars['String']>,
  entity_type?: Maybe<Scalars['String']>,
  fatca_certification?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  fulltime_employees?: Maybe<Scalars['String']>,
  funding_source_id?: Maybe<Scalars['String']>,
  futures_deal_size?: Maybe<Scalars['String']>,
  futures_experience_gained_id?: Maybe<Scalars['String']>,
  futures_trades_month?: Maybe<Scalars['String']>,
  futures_years_experience_id?: Maybe<Scalars['String']>,
  gender_id?: Maybe<Scalars['String']>,
  had_account?: Maybe<Scalars['String']>,
  has_auditor?: Maybe<Scalars['String']>,
  has_filed_bankruptcy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  identification_number?: Maybe<Scalars['String']>,
  identification_type_id?: Maybe<Scalars['String']>,
  incorporation_country_id?: Maybe<Scalars['String']>,
  incorporation_date?: Maybe<Scalars['String']>,
  initial_deposit?: Maybe<Scalars['String']>,
  investment_objective_id?: Maybe<Scalars['String']>,
  is_anti_laundering_regulated?: Maybe<Scalars['String']>,
  is_currently_employed_financial_sector?: Maybe<Scalars['String']>,
  is_currently_registered_financial_sector?: Maybe<Scalars['String']>,
  is_entity_regulated?: Maybe<Scalars['String']>,
  is_pending_legal_action?: Maybe<Scalars['String']>,
  is_politically_exposed?: Maybe<Scalars['String']>,
  is_required_to_register_financial_sector?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  legal_entity_identifier?: Maybe<Scalars['String']>,
  license_expiration?: Maybe<Scalars['String']>,
  license_number?: Maybe<Scalars['String']>,
  liquid_assets_id?: Maybe<Scalars['String']>,
  middle_name?: Maybe<Scalars['String']>,
  mifid2_identification?: Maybe<Scalars['String']>,
  mifid2_type?: Maybe<Scalars['String']>,
  mobile_number?: Maybe<Scalars['String']>,
  months_at_address?: Maybe<Scalars['String']>,
  not_regulated_reason?: Maybe<Scalars['String']>,
  occupation?: Maybe<Scalars['String']>,
  options_deal_size?: Maybe<Scalars['String']>,
  options_experience_gained_id?: Maybe<Scalars['String']>,
  options_trades_month?: Maybe<Scalars['String']>,
  options_years_experience_id?: Maybe<Scalars['String']>,
  other_assets?: Maybe<Scalars['String']>,
  phone_id?: Maybe<Scalars['String']>,
  phone_number?: Maybe<Scalars['String']>,
  place_of_business?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  previous_address_1?: Maybe<Scalars['String']>,
  previous_address_2?: Maybe<Scalars['String']>,
  previous_city?: Maybe<Scalars['String']>,
  previous_country_id?: Maybe<Scalars['String']>,
  previous_postal_code?: Maybe<Scalars['String']>,
  primary_phone_type_id?: Maybe<Scalars['String']>,
  reason_no_lei?: Maybe<Scalars['String']>,
  registration_country_id?: Maybe<Scalars['String']>,
  regulatory_authority?: Maybe<Scalars['String']>,
  regulatory_authority_for_employees?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['String']>,
  shares_deal_size?: Maybe<Scalars['String']>,
  shares_experience_gained_id?: Maybe<Scalars['String']>,
  shares_trades_month?: Maybe<Scalars['String']>,
  shares_years_experience_id?: Maybe<Scalars['String']>,
  speaks_english?: Maybe<Scalars['String']>,
  spot_deal_size?: Maybe<Scalars['String']>,
  spot_experience_gained_id?: Maybe<Scalars['String']>,
  spot_trades_month?: Maybe<Scalars['String']>,
  spot_years_experience_id?: Maybe<Scalars['String']>,
  tax_country_id?: Maybe<Scalars['String']>,
  tax_number?: Maybe<Scalars['String']>,
  title_id?: Maybe<Scalars['String']>,
  trading_capital?: Maybe<Scalars['String']>,
  usa_tax_number?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  was_previously_employed_financial_sector?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributePivotMaxOrderBy {
  address_1?: Maybe<OrderBy>,
  address_2?: Maybe<OrderBy>,
  annual_income_id?: Maybe<OrderBy>,
  auditor_name?: Maybe<OrderBy>,
  auditor_phone?: Maybe<OrderBy>,
  business_nature?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  country_of_birth_id?: Maybe<OrderBy>,
  country_of_citizenship_id?: Maybe<OrderBy>,
  country_of_nationality_id?: Maybe<OrderBy>,
  country_of_residence_id?: Maybe<OrderBy>,
  date_of_birth?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  emir_classification?: Maybe<OrderBy>,
  employees_registered_financial_sector?: Maybe<OrderBy>,
  employment_industry?: Maybe<OrderBy>,
  employment_status_id?: Maybe<OrderBy>,
  entity_name?: Maybe<OrderBy>,
  entity_number?: Maybe<OrderBy>,
  entity_type?: Maybe<OrderBy>,
  fatca_certification?: Maybe<OrderBy>,
  first_name?: Maybe<OrderBy>,
  fulltime_employees?: Maybe<OrderBy>,
  funding_source_id?: Maybe<OrderBy>,
  futures_deal_size?: Maybe<OrderBy>,
  futures_experience_gained_id?: Maybe<OrderBy>,
  futures_trades_month?: Maybe<OrderBy>,
  futures_years_experience_id?: Maybe<OrderBy>,
  gender_id?: Maybe<OrderBy>,
  had_account?: Maybe<OrderBy>,
  has_auditor?: Maybe<OrderBy>,
  has_filed_bankruptcy?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  identification_number?: Maybe<OrderBy>,
  identification_type_id?: Maybe<OrderBy>,
  incorporation_country_id?: Maybe<OrderBy>,
  incorporation_date?: Maybe<OrderBy>,
  initial_deposit?: Maybe<OrderBy>,
  investment_objective_id?: Maybe<OrderBy>,
  is_anti_laundering_regulated?: Maybe<OrderBy>,
  is_currently_employed_financial_sector?: Maybe<OrderBy>,
  is_currently_registered_financial_sector?: Maybe<OrderBy>,
  is_entity_regulated?: Maybe<OrderBy>,
  is_pending_legal_action?: Maybe<OrderBy>,
  is_politically_exposed?: Maybe<OrderBy>,
  is_required_to_register_financial_sector?: Maybe<OrderBy>,
  language?: Maybe<OrderBy>,
  last_name?: Maybe<OrderBy>,
  legal_entity_identifier?: Maybe<OrderBy>,
  license_expiration?: Maybe<OrderBy>,
  license_number?: Maybe<OrderBy>,
  liquid_assets_id?: Maybe<OrderBy>,
  middle_name?: Maybe<OrderBy>,
  mifid2_identification?: Maybe<OrderBy>,
  mifid2_type?: Maybe<OrderBy>,
  mobile_number?: Maybe<OrderBy>,
  months_at_address?: Maybe<OrderBy>,
  not_regulated_reason?: Maybe<OrderBy>,
  occupation?: Maybe<OrderBy>,
  options_deal_size?: Maybe<OrderBy>,
  options_experience_gained_id?: Maybe<OrderBy>,
  options_trades_month?: Maybe<OrderBy>,
  options_years_experience_id?: Maybe<OrderBy>,
  other_assets?: Maybe<OrderBy>,
  phone_id?: Maybe<OrderBy>,
  phone_number?: Maybe<OrderBy>,
  place_of_business?: Maybe<OrderBy>,
  postal_code?: Maybe<OrderBy>,
  previous_address_1?: Maybe<OrderBy>,
  previous_address_2?: Maybe<OrderBy>,
  previous_city?: Maybe<OrderBy>,
  previous_country_id?: Maybe<OrderBy>,
  previous_postal_code?: Maybe<OrderBy>,
  primary_phone_type_id?: Maybe<OrderBy>,
  reason_no_lei?: Maybe<OrderBy>,
  registration_country_id?: Maybe<OrderBy>,
  regulatory_authority?: Maybe<OrderBy>,
  regulatory_authority_for_employees?: Maybe<OrderBy>,
  score?: Maybe<OrderBy>,
  shares_deal_size?: Maybe<OrderBy>,
  shares_experience_gained_id?: Maybe<OrderBy>,
  shares_trades_month?: Maybe<OrderBy>,
  shares_years_experience_id?: Maybe<OrderBy>,
  speaks_english?: Maybe<OrderBy>,
  spot_deal_size?: Maybe<OrderBy>,
  spot_experience_gained_id?: Maybe<OrderBy>,
  spot_trades_month?: Maybe<OrderBy>,
  spot_years_experience_id?: Maybe<OrderBy>,
  tax_country_id?: Maybe<OrderBy>,
  tax_number?: Maybe<OrderBy>,
  title_id?: Maybe<OrderBy>,
  trading_capital?: Maybe<OrderBy>,
  usa_tax_number?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
  was_previously_employed_financial_sector?: Maybe<OrderBy>,
  website?: Maybe<OrderBy>,
}

export interface StakeholderAttributePivotMinFields {
   __typename?: 'stakeholder_attribute_pivot_min_fields',
  address_1?: Maybe<Scalars['String']>,
  address_2?: Maybe<Scalars['String']>,
  annual_income_id?: Maybe<Scalars['String']>,
  auditor_name?: Maybe<Scalars['String']>,
  auditor_phone?: Maybe<Scalars['String']>,
  business_nature?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country_id?: Maybe<Scalars['String']>,
  country_of_birth_id?: Maybe<Scalars['String']>,
  country_of_citizenship_id?: Maybe<Scalars['String']>,
  country_of_nationality_id?: Maybe<Scalars['String']>,
  country_of_residence_id?: Maybe<Scalars['String']>,
  date_of_birth?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  emir_classification?: Maybe<Scalars['String']>,
  employees_registered_financial_sector?: Maybe<Scalars['String']>,
  employment_industry?: Maybe<Scalars['String']>,
  employment_status_id?: Maybe<Scalars['String']>,
  entity_name?: Maybe<Scalars['String']>,
  entity_number?: Maybe<Scalars['String']>,
  entity_type?: Maybe<Scalars['String']>,
  fatca_certification?: Maybe<Scalars['String']>,
  first_name?: Maybe<Scalars['String']>,
  fulltime_employees?: Maybe<Scalars['String']>,
  funding_source_id?: Maybe<Scalars['String']>,
  futures_deal_size?: Maybe<Scalars['String']>,
  futures_experience_gained_id?: Maybe<Scalars['String']>,
  futures_trades_month?: Maybe<Scalars['String']>,
  futures_years_experience_id?: Maybe<Scalars['String']>,
  gender_id?: Maybe<Scalars['String']>,
  had_account?: Maybe<Scalars['String']>,
  has_auditor?: Maybe<Scalars['String']>,
  has_filed_bankruptcy?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  identification_number?: Maybe<Scalars['String']>,
  identification_type_id?: Maybe<Scalars['String']>,
  incorporation_country_id?: Maybe<Scalars['String']>,
  incorporation_date?: Maybe<Scalars['String']>,
  initial_deposit?: Maybe<Scalars['String']>,
  investment_objective_id?: Maybe<Scalars['String']>,
  is_anti_laundering_regulated?: Maybe<Scalars['String']>,
  is_currently_employed_financial_sector?: Maybe<Scalars['String']>,
  is_currently_registered_financial_sector?: Maybe<Scalars['String']>,
  is_entity_regulated?: Maybe<Scalars['String']>,
  is_pending_legal_action?: Maybe<Scalars['String']>,
  is_politically_exposed?: Maybe<Scalars['String']>,
  is_required_to_register_financial_sector?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  last_name?: Maybe<Scalars['String']>,
  legal_entity_identifier?: Maybe<Scalars['String']>,
  license_expiration?: Maybe<Scalars['String']>,
  license_number?: Maybe<Scalars['String']>,
  liquid_assets_id?: Maybe<Scalars['String']>,
  middle_name?: Maybe<Scalars['String']>,
  mifid2_identification?: Maybe<Scalars['String']>,
  mifid2_type?: Maybe<Scalars['String']>,
  mobile_number?: Maybe<Scalars['String']>,
  months_at_address?: Maybe<Scalars['String']>,
  not_regulated_reason?: Maybe<Scalars['String']>,
  occupation?: Maybe<Scalars['String']>,
  options_deal_size?: Maybe<Scalars['String']>,
  options_experience_gained_id?: Maybe<Scalars['String']>,
  options_trades_month?: Maybe<Scalars['String']>,
  options_years_experience_id?: Maybe<Scalars['String']>,
  other_assets?: Maybe<Scalars['String']>,
  phone_id?: Maybe<Scalars['String']>,
  phone_number?: Maybe<Scalars['String']>,
  place_of_business?: Maybe<Scalars['String']>,
  postal_code?: Maybe<Scalars['String']>,
  previous_address_1?: Maybe<Scalars['String']>,
  previous_address_2?: Maybe<Scalars['String']>,
  previous_city?: Maybe<Scalars['String']>,
  previous_country_id?: Maybe<Scalars['String']>,
  previous_postal_code?: Maybe<Scalars['String']>,
  primary_phone_type_id?: Maybe<Scalars['String']>,
  reason_no_lei?: Maybe<Scalars['String']>,
  registration_country_id?: Maybe<Scalars['String']>,
  regulatory_authority?: Maybe<Scalars['String']>,
  regulatory_authority_for_employees?: Maybe<Scalars['String']>,
  score?: Maybe<Scalars['String']>,
  shares_deal_size?: Maybe<Scalars['String']>,
  shares_experience_gained_id?: Maybe<Scalars['String']>,
  shares_trades_month?: Maybe<Scalars['String']>,
  shares_years_experience_id?: Maybe<Scalars['String']>,
  speaks_english?: Maybe<Scalars['String']>,
  spot_deal_size?: Maybe<Scalars['String']>,
  spot_experience_gained_id?: Maybe<Scalars['String']>,
  spot_trades_month?: Maybe<Scalars['String']>,
  spot_years_experience_id?: Maybe<Scalars['String']>,
  tax_country_id?: Maybe<Scalars['String']>,
  tax_number?: Maybe<Scalars['String']>,
  title_id?: Maybe<Scalars['String']>,
  trading_capital?: Maybe<Scalars['String']>,
  usa_tax_number?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
  was_previously_employed_financial_sector?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributePivotMinOrderBy {
  address_1?: Maybe<OrderBy>,
  address_2?: Maybe<OrderBy>,
  annual_income_id?: Maybe<OrderBy>,
  auditor_name?: Maybe<OrderBy>,
  auditor_phone?: Maybe<OrderBy>,
  business_nature?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  country_id?: Maybe<OrderBy>,
  country_of_birth_id?: Maybe<OrderBy>,
  country_of_citizenship_id?: Maybe<OrderBy>,
  country_of_nationality_id?: Maybe<OrderBy>,
  country_of_residence_id?: Maybe<OrderBy>,
  date_of_birth?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  emir_classification?: Maybe<OrderBy>,
  employees_registered_financial_sector?: Maybe<OrderBy>,
  employment_industry?: Maybe<OrderBy>,
  employment_status_id?: Maybe<OrderBy>,
  entity_name?: Maybe<OrderBy>,
  entity_number?: Maybe<OrderBy>,
  entity_type?: Maybe<OrderBy>,
  fatca_certification?: Maybe<OrderBy>,
  first_name?: Maybe<OrderBy>,
  fulltime_employees?: Maybe<OrderBy>,
  funding_source_id?: Maybe<OrderBy>,
  futures_deal_size?: Maybe<OrderBy>,
  futures_experience_gained_id?: Maybe<OrderBy>,
  futures_trades_month?: Maybe<OrderBy>,
  futures_years_experience_id?: Maybe<OrderBy>,
  gender_id?: Maybe<OrderBy>,
  had_account?: Maybe<OrderBy>,
  has_auditor?: Maybe<OrderBy>,
  has_filed_bankruptcy?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  identification_number?: Maybe<OrderBy>,
  identification_type_id?: Maybe<OrderBy>,
  incorporation_country_id?: Maybe<OrderBy>,
  incorporation_date?: Maybe<OrderBy>,
  initial_deposit?: Maybe<OrderBy>,
  investment_objective_id?: Maybe<OrderBy>,
  is_anti_laundering_regulated?: Maybe<OrderBy>,
  is_currently_employed_financial_sector?: Maybe<OrderBy>,
  is_currently_registered_financial_sector?: Maybe<OrderBy>,
  is_entity_regulated?: Maybe<OrderBy>,
  is_pending_legal_action?: Maybe<OrderBy>,
  is_politically_exposed?: Maybe<OrderBy>,
  is_required_to_register_financial_sector?: Maybe<OrderBy>,
  language?: Maybe<OrderBy>,
  last_name?: Maybe<OrderBy>,
  legal_entity_identifier?: Maybe<OrderBy>,
  license_expiration?: Maybe<OrderBy>,
  license_number?: Maybe<OrderBy>,
  liquid_assets_id?: Maybe<OrderBy>,
  middle_name?: Maybe<OrderBy>,
  mifid2_identification?: Maybe<OrderBy>,
  mifid2_type?: Maybe<OrderBy>,
  mobile_number?: Maybe<OrderBy>,
  months_at_address?: Maybe<OrderBy>,
  not_regulated_reason?: Maybe<OrderBy>,
  occupation?: Maybe<OrderBy>,
  options_deal_size?: Maybe<OrderBy>,
  options_experience_gained_id?: Maybe<OrderBy>,
  options_trades_month?: Maybe<OrderBy>,
  options_years_experience_id?: Maybe<OrderBy>,
  other_assets?: Maybe<OrderBy>,
  phone_id?: Maybe<OrderBy>,
  phone_number?: Maybe<OrderBy>,
  place_of_business?: Maybe<OrderBy>,
  postal_code?: Maybe<OrderBy>,
  previous_address_1?: Maybe<OrderBy>,
  previous_address_2?: Maybe<OrderBy>,
  previous_city?: Maybe<OrderBy>,
  previous_country_id?: Maybe<OrderBy>,
  previous_postal_code?: Maybe<OrderBy>,
  primary_phone_type_id?: Maybe<OrderBy>,
  reason_no_lei?: Maybe<OrderBy>,
  registration_country_id?: Maybe<OrderBy>,
  regulatory_authority?: Maybe<OrderBy>,
  regulatory_authority_for_employees?: Maybe<OrderBy>,
  score?: Maybe<OrderBy>,
  shares_deal_size?: Maybe<OrderBy>,
  shares_experience_gained_id?: Maybe<OrderBy>,
  shares_trades_month?: Maybe<OrderBy>,
  shares_years_experience_id?: Maybe<OrderBy>,
  speaks_english?: Maybe<OrderBy>,
  spot_deal_size?: Maybe<OrderBy>,
  spot_experience_gained_id?: Maybe<OrderBy>,
  spot_trades_month?: Maybe<OrderBy>,
  spot_years_experience_id?: Maybe<OrderBy>,
  tax_country_id?: Maybe<OrderBy>,
  tax_number?: Maybe<OrderBy>,
  title_id?: Maybe<OrderBy>,
  trading_capital?: Maybe<OrderBy>,
  usa_tax_number?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
  was_previously_employed_financial_sector?: Maybe<OrderBy>,
  website?: Maybe<OrderBy>,
}

export interface StakeholderAttributePivotOrderBy {
  address_1?: Maybe<OrderBy>,
  address_2?: Maybe<OrderBy>,
  annual_income?: Maybe<AnnualIncomeOrderBy>,
  annual_income_id?: Maybe<OrderBy>,
  auditor_name?: Maybe<OrderBy>,
  auditor_phone?: Maybe<OrderBy>,
  business_nature?: Maybe<OrderBy>,
  city?: Maybe<OrderBy>,
  country?: Maybe<CountryOrderBy>,
  country_id?: Maybe<OrderBy>,
  country_of_birth?: Maybe<CountryOrderBy>,
  country_of_birth_id?: Maybe<OrderBy>,
  country_of_citizenship?: Maybe<CountryOrderBy>,
  country_of_citizenship_id?: Maybe<OrderBy>,
  country_of_nationality?: Maybe<CountryOrderBy>,
  country_of_nationality_id?: Maybe<OrderBy>,
  country_of_residence?: Maybe<CountryOrderBy>,
  country_of_residence_id?: Maybe<OrderBy>,
  date_of_birth?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  emir_classification?: Maybe<OrderBy>,
  employees_registered_financial_sector?: Maybe<OrderBy>,
  employment_industry?: Maybe<OrderBy>,
  employment_status?: Maybe<EmploymentStatusOrderBy>,
  employment_status_id?: Maybe<OrderBy>,
  entity_name?: Maybe<OrderBy>,
  entity_number?: Maybe<OrderBy>,
  entity_type?: Maybe<OrderBy>,
  fatca_certification?: Maybe<OrderBy>,
  first_name?: Maybe<OrderBy>,
  fulltime_employees?: Maybe<OrderBy>,
  funding_source?: Maybe<SourceOfFundsOrderBy>,
  funding_source_id?: Maybe<OrderBy>,
  futures_deal_size?: Maybe<OrderBy>,
  futures_experience_gained?: Maybe<ExperienceTypeOrderBy>,
  futures_experience_gained_id?: Maybe<OrderBy>,
  futures_trades_month?: Maybe<OrderBy>,
  futures_years_experience?: Maybe<YearsExperienceOrderBy>,
  futures_years_experience_id?: Maybe<OrderBy>,
  gender?: Maybe<GenderOrderBy>,
  gender_id?: Maybe<OrderBy>,
  had_account?: Maybe<OrderBy>,
  has_auditor?: Maybe<OrderBy>,
  has_filed_bankruptcy?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  identification_number?: Maybe<OrderBy>,
  identification_type?: Maybe<IdentificationTypeOrderBy>,
  identification_type_id?: Maybe<OrderBy>,
  incorporation_country?: Maybe<CountryOrderBy>,
  incorporation_country_id?: Maybe<OrderBy>,
  incorporation_date?: Maybe<OrderBy>,
  initial_deposit?: Maybe<OrderBy>,
  investment_objective?: Maybe<InvestmentObjectiveOrderBy>,
  investment_objective_id?: Maybe<OrderBy>,
  is_anti_laundering_regulated?: Maybe<OrderBy>,
  is_currently_employed_financial_sector?: Maybe<OrderBy>,
  is_currently_registered_financial_sector?: Maybe<OrderBy>,
  is_entity_regulated?: Maybe<OrderBy>,
  is_pending_legal_action?: Maybe<OrderBy>,
  is_politically_exposed?: Maybe<OrderBy>,
  is_required_to_register_financial_sector?: Maybe<OrderBy>,
  language?: Maybe<OrderBy>,
  last_name?: Maybe<OrderBy>,
  legal_entity_identifier?: Maybe<OrderBy>,
  license_expiration?: Maybe<OrderBy>,
  license_number?: Maybe<OrderBy>,
  liquid_assets?: Maybe<LiquidAssetsOrderBy>,
  liquid_assets_id?: Maybe<OrderBy>,
  middle_name?: Maybe<OrderBy>,
  mifid2_identification?: Maybe<OrderBy>,
  mifid2_type?: Maybe<OrderBy>,
  mobile_number?: Maybe<OrderBy>,
  months_at_address?: Maybe<OrderBy>,
  not_regulated_reason?: Maybe<OrderBy>,
  occupation?: Maybe<OrderBy>,
  options_deal_size?: Maybe<OrderBy>,
  options_experience_gained?: Maybe<ExperienceTypeOrderBy>,
  options_experience_gained_id?: Maybe<OrderBy>,
  options_trades_month?: Maybe<OrderBy>,
  options_years_experience?: Maybe<YearsExperienceOrderBy>,
  options_years_experience_id?: Maybe<OrderBy>,
  other_assets?: Maybe<OrderBy>,
  phone_id?: Maybe<OrderBy>,
  phone_number?: Maybe<OrderBy>,
  place_of_business?: Maybe<OrderBy>,
  postal_code?: Maybe<OrderBy>,
  previous_address_1?: Maybe<OrderBy>,
  previous_address_2?: Maybe<OrderBy>,
  previous_city?: Maybe<OrderBy>,
  previous_country?: Maybe<CountryOrderBy>,
  previous_country_id?: Maybe<OrderBy>,
  previous_postal_code?: Maybe<OrderBy>,
  primary_phone_type?: Maybe<PhoneTypeOrderBy>,
  primary_phone_type_id?: Maybe<OrderBy>,
  reason_no_lei?: Maybe<OrderBy>,
  registration_country?: Maybe<CountryOrderBy>,
  registration_country_id?: Maybe<OrderBy>,
  regulatory_authority?: Maybe<OrderBy>,
  regulatory_authority_for_employees?: Maybe<OrderBy>,
  score?: Maybe<OrderBy>,
  shares_deal_size?: Maybe<OrderBy>,
  shares_experience_gained?: Maybe<ExperienceTypeOrderBy>,
  shares_experience_gained_id?: Maybe<OrderBy>,
  shares_trades_month?: Maybe<OrderBy>,
  shares_years_experience?: Maybe<YearsExperienceOrderBy>,
  shares_years_experience_id?: Maybe<OrderBy>,
  speaks_english?: Maybe<OrderBy>,
  spot_deal_size?: Maybe<OrderBy>,
  spot_experience_gained?: Maybe<ExperienceTypeOrderBy>,
  spot_experience_gained_id?: Maybe<OrderBy>,
  spot_trades_month?: Maybe<OrderBy>,
  spot_years_experience?: Maybe<YearsExperienceOrderBy>,
  spot_years_experience_id?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  tax_country?: Maybe<CountryOrderBy>,
  tax_country_id?: Maybe<OrderBy>,
  tax_number?: Maybe<OrderBy>,
  title?: Maybe<NameTitleOrderBy>,
  title_id?: Maybe<OrderBy>,
  trading_capital?: Maybe<OrderBy>,
  usa_tax_number?: Maybe<OrderBy>,
  value?: Maybe<OrderBy>,
  was_previously_employed_financial_sector?: Maybe<OrderBy>,
  website?: Maybe<OrderBy>,
}

export enum StakeholderAttributePivotSelectColumn {
  Address_1 = 'address_1',
  Address_2 = 'address_2',
  AnnualIncomeId = 'annual_income_id',
  AuditorName = 'auditor_name',
  AuditorPhone = 'auditor_phone',
  BusinessNature = 'business_nature',
  City = 'city',
  CountryId = 'country_id',
  CountryOfBirthId = 'country_of_birth_id',
  CountryOfCitizenshipId = 'country_of_citizenship_id',
  CountryOfNationalityId = 'country_of_nationality_id',
  CountryOfResidenceId = 'country_of_residence_id',
  DateOfBirth = 'date_of_birth',
  Email = 'email',
  EmirClassification = 'emir_classification',
  EmployeesRegisteredFinancialSector = 'employees_registered_financial_sector',
  EmploymentIndustry = 'employment_industry',
  EmploymentStatusId = 'employment_status_id',
  EntityName = 'entity_name',
  EntityNumber = 'entity_number',
  EntityType = 'entity_type',
  FatcaCertification = 'fatca_certification',
  FirstName = 'first_name',
  FulltimeEmployees = 'fulltime_employees',
  FundingSourceId = 'funding_source_id',
  FuturesDealSize = 'futures_deal_size',
  FuturesExperienceGainedId = 'futures_experience_gained_id',
  FuturesTradesMonth = 'futures_trades_month',
  FuturesYearsExperienceId = 'futures_years_experience_id',
  GenderId = 'gender_id',
  HadAccount = 'had_account',
  HasAuditor = 'has_auditor',
  HasFiledBankruptcy = 'has_filed_bankruptcy',
  Id = 'id',
  IdentificationNumber = 'identification_number',
  IdentificationTypeId = 'identification_type_id',
  IncorporationCountryId = 'incorporation_country_id',
  IncorporationDate = 'incorporation_date',
  InitialDeposit = 'initial_deposit',
  InvestmentObjectiveId = 'investment_objective_id',
  IsAntiLaunderingRegulated = 'is_anti_laundering_regulated',
  IsCurrentlyEmployedFinancialSector = 'is_currently_employed_financial_sector',
  IsCurrentlyRegisteredFinancialSector = 'is_currently_registered_financial_sector',
  IsEntityRegulated = 'is_entity_regulated',
  IsPendingLegalAction = 'is_pending_legal_action',
  IsPoliticallyExposed = 'is_politically_exposed',
  IsRequiredToRegisterFinancialSector = 'is_required_to_register_financial_sector',
  Language = 'language',
  LastName = 'last_name',
  LegalEntityIdentifier = 'legal_entity_identifier',
  LicenseExpiration = 'license_expiration',
  LicenseNumber = 'license_number',
  LiquidAssetsId = 'liquid_assets_id',
  MiddleName = 'middle_name',
  Mifid2Identification = 'mifid2_identification',
  Mifid2Type = 'mifid2_type',
  MobileNumber = 'mobile_number',
  MonthsAtAddress = 'months_at_address',
  NotRegulatedReason = 'not_regulated_reason',
  Occupation = 'occupation',
  OptionsDealSize = 'options_deal_size',
  OptionsExperienceGainedId = 'options_experience_gained_id',
  OptionsTradesMonth = 'options_trades_month',
  OptionsYearsExperienceId = 'options_years_experience_id',
  OtherAssets = 'other_assets',
  PhoneId = 'phone_id',
  PhoneNumber = 'phone_number',
  PlaceOfBusiness = 'place_of_business',
  PostalCode = 'postal_code',
  PreviousAddress_1 = 'previous_address_1',
  PreviousAddress_2 = 'previous_address_2',
  PreviousCity = 'previous_city',
  PreviousCountryId = 'previous_country_id',
  PreviousPostalCode = 'previous_postal_code',
  PrimaryPhoneTypeId = 'primary_phone_type_id',
  ReasonNoLei = 'reason_no_lei',
  RegistrationCountryId = 'registration_country_id',
  RegulatoryAuthority = 'regulatory_authority',
  RegulatoryAuthorityForEmployees = 'regulatory_authority_for_employees',
  Score = 'score',
  SharesDealSize = 'shares_deal_size',
  SharesExperienceGainedId = 'shares_experience_gained_id',
  SharesTradesMonth = 'shares_trades_month',
  SharesYearsExperienceId = 'shares_years_experience_id',
  SpeaksEnglish = 'speaks_english',
  SpotDealSize = 'spot_deal_size',
  SpotExperienceGainedId = 'spot_experience_gained_id',
  SpotTradesMonth = 'spot_trades_month',
  SpotYearsExperienceId = 'spot_years_experience_id',
  TaxCountryId = 'tax_country_id',
  TaxNumber = 'tax_number',
  TitleId = 'title_id',
  TradingCapital = 'trading_capital',
  UsaTaxNumber = 'usa_tax_number',
  Value = 'value',
  WasPreviouslyEmployedFinancialSector = 'was_previously_employed_financial_sector',
  Website = 'website'
}

export interface StakeholderAttributePkColumnsInput {
  id: Scalars['String'],
}

export interface StakeholderAttributeSection {
   __typename?: 'stakeholder_attribute_section',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  order: Scalars['Int'],
  subsections: Array<StakeholderAttributeSubsection>,
  subsections_aggregate: StakeholderAttributeSubsectionAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface StakeholderAttributeSectionSubsectionsArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}


export interface StakeholderAttributeSectionSubsectionsAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}

export interface StakeholderAttributeSectionAggregate {
   __typename?: 'stakeholder_attribute_section_aggregate',
  aggregate?: Maybe<StakeholderAttributeSectionAggregateFields>,
  nodes: Array<StakeholderAttributeSection>,
}

export interface StakeholderAttributeSectionAggregateFields {
   __typename?: 'stakeholder_attribute_section_aggregate_fields',
  avg?: Maybe<StakeholderAttributeSectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributeSectionMaxFields>,
  min?: Maybe<StakeholderAttributeSectionMinFields>,
  stddev?: Maybe<StakeholderAttributeSectionStddevFields>,
  stddev_pop?: Maybe<StakeholderAttributeSectionStddevPopFields>,
  stddev_samp?: Maybe<StakeholderAttributeSectionStddevSampFields>,
  sum?: Maybe<StakeholderAttributeSectionSumFields>,
  var_pop?: Maybe<StakeholderAttributeSectionVarPopFields>,
  var_samp?: Maybe<StakeholderAttributeSectionVarSampFields>,
  variance?: Maybe<StakeholderAttributeSectionVarianceFields>,
}


export interface StakeholderAttributeSectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributeSectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributeSectionAggregateOrderBy {
  avg?: Maybe<StakeholderAttributeSectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributeSectionMaxOrderBy>,
  min?: Maybe<StakeholderAttributeSectionMinOrderBy>,
  stddev?: Maybe<StakeholderAttributeSectionStddevOrderBy>,
  stddev_pop?: Maybe<StakeholderAttributeSectionStddevPopOrderBy>,
  stddev_samp?: Maybe<StakeholderAttributeSectionStddevSampOrderBy>,
  sum?: Maybe<StakeholderAttributeSectionSumOrderBy>,
  var_pop?: Maybe<StakeholderAttributeSectionVarPopOrderBy>,
  var_samp?: Maybe<StakeholderAttributeSectionVarSampOrderBy>,
  variance?: Maybe<StakeholderAttributeSectionVarianceOrderBy>,
}

export interface StakeholderAttributeSectionArrRelInsertInput {
  data: Array<StakeholderAttributeSectionInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeSectionOnConflict>,
}

export interface StakeholderAttributeSectionAvgFields {
   __typename?: 'stakeholder_attribute_section_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributeSectionBoolExp>>>,
  _not?: Maybe<StakeholderAttributeSectionBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributeSectionBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  subsections?: Maybe<StakeholderAttributeSubsectionBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeholderAttributeSectionConstraint {
  StakeholderAttributeSectionNameKey = 'stakeholder_attribute_section_name_key',
  StakeholderAttributeSectionOrderKey = 'stakeholder_attribute_section_order_key',
  StakeholderAttributeSectionPkey = 'stakeholder_attribute_section_pkey'
}

export interface StakeholderAttributeSectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeSectionInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  subsections?: Maybe<StakeholderAttributeSubsectionArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSectionMaxFields {
   __typename?: 'stakeholder_attribute_section_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSectionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionMinFields {
   __typename?: 'stakeholder_attribute_section_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSectionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionMutationResponse {
   __typename?: 'stakeholder_attribute_section_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderAttributeSection>,
}

export interface StakeholderAttributeSectionObjRelInsertInput {
  data: StakeholderAttributeSectionInsertInput,
  on_conflict?: Maybe<StakeholderAttributeSectionOnConflict>,
}

export interface StakeholderAttributeSectionOnConflict {
  constraint: StakeholderAttributeSectionConstraint,
  update_columns: Array<StakeholderAttributeSectionUpdateColumn>,
  where?: Maybe<StakeholderAttributeSectionBoolExp>,
}

export interface StakeholderAttributeSectionOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  subsections_aggregate?: Maybe<StakeholderAttributeSubsectionAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeholderAttributeSectionSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeSectionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSectionStddevFields {
   __typename?: 'stakeholder_attribute_section_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionStddevPopFields {
   __typename?: 'stakeholder_attribute_section_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionStddevSampFields {
   __typename?: 'stakeholder_attribute_section_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionSumFields {
   __typename?: 'stakeholder_attribute_section_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeSectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum StakeholderAttributeSectionUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeSectionVarPopFields {
   __typename?: 'stakeholder_attribute_section_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionVarSampFields {
   __typename?: 'stakeholder_attribute_section_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSectionVarianceFields {
   __typename?: 'stakeholder_attribute_section_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum StakeholderAttributeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface StakeholderAttributeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  key_id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  value?: Maybe<Scalars['String']>,
}

export interface StakeholderAttributeSubsection {
   __typename?: 'stakeholder_attribute_subsection',
  attribute_keys: Array<StakeholderAttributeKey>,
  attribute_keys_aggregate: StakeholderAttributeKeyAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  order: Scalars['Int'],
  section: StakeholderAttributeSection,
  section_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface StakeholderAttributeSubsectionAttributeKeysArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface StakeholderAttributeSubsectionAttributeKeysAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}

export interface StakeholderAttributeSubsectionAggregate {
   __typename?: 'stakeholder_attribute_subsection_aggregate',
  aggregate?: Maybe<StakeholderAttributeSubsectionAggregateFields>,
  nodes: Array<StakeholderAttributeSubsection>,
}

export interface StakeholderAttributeSubsectionAggregateFields {
   __typename?: 'stakeholder_attribute_subsection_aggregate_fields',
  avg?: Maybe<StakeholderAttributeSubsectionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderAttributeSubsectionMaxFields>,
  min?: Maybe<StakeholderAttributeSubsectionMinFields>,
  stddev?: Maybe<StakeholderAttributeSubsectionStddevFields>,
  stddev_pop?: Maybe<StakeholderAttributeSubsectionStddevPopFields>,
  stddev_samp?: Maybe<StakeholderAttributeSubsectionStddevSampFields>,
  sum?: Maybe<StakeholderAttributeSubsectionSumFields>,
  var_pop?: Maybe<StakeholderAttributeSubsectionVarPopFields>,
  var_samp?: Maybe<StakeholderAttributeSubsectionVarSampFields>,
  variance?: Maybe<StakeholderAttributeSubsectionVarianceFields>,
}


export interface StakeholderAttributeSubsectionAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderAttributeSubsectionAggregateOrderBy {
  avg?: Maybe<StakeholderAttributeSubsectionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderAttributeSubsectionMaxOrderBy>,
  min?: Maybe<StakeholderAttributeSubsectionMinOrderBy>,
  stddev?: Maybe<StakeholderAttributeSubsectionStddevOrderBy>,
  stddev_pop?: Maybe<StakeholderAttributeSubsectionStddevPopOrderBy>,
  stddev_samp?: Maybe<StakeholderAttributeSubsectionStddevSampOrderBy>,
  sum?: Maybe<StakeholderAttributeSubsectionSumOrderBy>,
  var_pop?: Maybe<StakeholderAttributeSubsectionVarPopOrderBy>,
  var_samp?: Maybe<StakeholderAttributeSubsectionVarSampOrderBy>,
  variance?: Maybe<StakeholderAttributeSubsectionVarianceOrderBy>,
}

export interface StakeholderAttributeSubsectionArrRelInsertInput {
  data: Array<StakeholderAttributeSubsectionInsertInput>,
  on_conflict?: Maybe<StakeholderAttributeSubsectionOnConflict>,
}

export interface StakeholderAttributeSubsectionAvgFields {
   __typename?: 'stakeholder_attribute_subsection_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderAttributeSubsectionBoolExp>>>,
  _not?: Maybe<StakeholderAttributeSubsectionBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderAttributeSubsectionBoolExp>>>,
  attribute_keys?: Maybe<StakeholderAttributeKeyBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  section?: Maybe<StakeholderAttributeSectionBoolExp>,
  section_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeholderAttributeSubsectionConstraint {
  StakeholderAttributeSubsectionNameKey = 'stakeholder_attribute_subsection_name_key',
  StakeholderAttributeSubsectionOrderSectionIdKey = 'stakeholder_attribute_subsection_order_section_id_key',
  StakeholderAttributeSubsectionPkey = 'stakeholder_attribute_subsection_pkey'
}

export interface StakeholderAttributeSubsectionIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeSubsectionInsertInput {
  attribute_keys?: Maybe<StakeholderAttributeKeyArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section?: Maybe<StakeholderAttributeSectionObjRelInsertInput>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSubsectionMaxFields {
   __typename?: 'stakeholder_attribute_subsection_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSubsectionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionMinFields {
   __typename?: 'stakeholder_attribute_subsection_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSubsectionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionMutationResponse {
   __typename?: 'stakeholder_attribute_subsection_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderAttributeSubsection>,
}

export interface StakeholderAttributeSubsectionObjRelInsertInput {
  data: StakeholderAttributeSubsectionInsertInput,
  on_conflict?: Maybe<StakeholderAttributeSubsectionOnConflict>,
}

export interface StakeholderAttributeSubsectionOnConflict {
  constraint: StakeholderAttributeSubsectionConstraint,
  update_columns: Array<StakeholderAttributeSubsectionUpdateColumn>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>,
}

export interface StakeholderAttributeSubsectionOrderBy {
  attribute_keys_aggregate?: Maybe<StakeholderAttributeKeyAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  section?: Maybe<StakeholderAttributeSectionOrderBy>,
  section_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeholderAttributeSubsectionSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeSubsectionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  section_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderAttributeSubsectionStddevFields {
   __typename?: 'stakeholder_attribute_subsection_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionStddevPopFields {
   __typename?: 'stakeholder_attribute_subsection_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionStddevSampFields {
   __typename?: 'stakeholder_attribute_subsection_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionSumFields {
   __typename?: 'stakeholder_attribute_subsection_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface StakeholderAttributeSubsectionSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum StakeholderAttributeSubsectionUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  SectionId = 'section_id',
  UpdatedAt = 'updated_at'
}

export interface StakeholderAttributeSubsectionVarPopFields {
   __typename?: 'stakeholder_attribute_subsection_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionVarSampFields {
   __typename?: 'stakeholder_attribute_subsection_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface StakeholderAttributeSubsectionVarianceFields {
   __typename?: 'stakeholder_attribute_subsection_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface StakeholderAttributeSubsectionVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum StakeholderAttributeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  KeyId = 'key_id',
  StakeholderId = 'stakeholder_id',
  UpdatedAt = 'updated_at',
  Value = 'value'
}

export interface StakeholderBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderBoolExp>>>,
  _not?: Maybe<StakeholderBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderBoolExp>>>,
  attribute_name?: Maybe<StakeholderAttributePivotBoolExp>,
  attribute_pivot?: Maybe<StakeholderAttributePivotBoolExp>,
  attributes?: Maybe<StakeholderAttributeBoolExp>,
  avatar?: Maybe<StringComparisonExp>,
  bank_accounts?: Maybe<BankAccountBoolExp>,
  class?: Maybe<StakeholderClassBoolExp>,
  class_id?: Maybe<StringComparisonExp>,
  color?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  files?: Maybe<FilesToStakeholdersBoolExp>,
  id?: Maybe<StringComparisonExp>,
  is_marketing_communication_allowed?: Maybe<BooleanComparisonExp>,
  locale?: Maybe<LocaleBoolExp>,
  locale_id?: Maybe<StringComparisonExp>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionBoolExp>,
  master_admin_stakeholder_permission_id?: Maybe<StringComparisonExp>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionBoolExp>,
  master_partner_stakeholder_permission_id?: Maybe<StringComparisonExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  stakeholder_files_grid_view?: Maybe<StakeholderFileGridViewBoolExp>,
  stakeholder_name?: Maybe<StakeholderNameBoolExp>,
  stakes?: Maybe<StakeBoolExp>,
  timezone?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  user_id?: Maybe<StringComparisonExp>,
  user_invite?: Maybe<UserInviteBoolExp>,
  user_invite_id?: Maybe<StringComparisonExp>,
  user_management_grid_view?: Maybe<UserManagementGridViewBoolExp>,
}

export interface StakeholderClass {
   __typename?: 'stakeholder_class',
  attribute_keys: Array<StakeholderAttributeKeysToStakeholderClasses>,
  attribute_keys_aggregate: StakeholderAttributeKeysToStakeholderClassesAggregate,
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}


export interface StakeholderClassAttributeKeysArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface StakeholderClassAttributeKeysAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}

export interface StakeholderClassAggregate {
   __typename?: 'stakeholder_class_aggregate',
  aggregate?: Maybe<StakeholderClassAggregateFields>,
  nodes: Array<StakeholderClass>,
}

export interface StakeholderClassAggregateFields {
   __typename?: 'stakeholder_class_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderClassMaxFields>,
  min?: Maybe<StakeholderClassMinFields>,
}


export interface StakeholderClassAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderClassSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderClassAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderClassMaxOrderBy>,
  min?: Maybe<StakeholderClassMinOrderBy>,
}

export interface StakeholderClassArrRelInsertInput {
  data: Array<StakeholderClassInsertInput>,
  on_conflict?: Maybe<StakeholderClassOnConflict>,
}

export interface StakeholderClassBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderClassBoolExp>>>,
  _not?: Maybe<StakeholderClassBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderClassBoolExp>>>,
  attribute_keys?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakeholderClassConstraint {
  StakeholderClassNameKey = 'stakeholder_class_name_key',
  StakeholderClassPkey = 'stakeholder_class_pkey'
}

export interface StakeholderClassInsertInput {
  attribute_keys?: Maybe<StakeholderAttributeKeysToStakeholderClassesArrRelInsertInput>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderClassMaxFields {
   __typename?: 'stakeholder_class_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderClassMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderClassMinFields {
   __typename?: 'stakeholder_class_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakeholderClassMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderClassMutationResponse {
   __typename?: 'stakeholder_class_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakeholderClass>,
}

export interface StakeholderClassObjRelInsertInput {
  data: StakeholderClassInsertInput,
  on_conflict?: Maybe<StakeholderClassOnConflict>,
}

export interface StakeholderClassOnConflict {
  constraint: StakeholderClassConstraint,
  update_columns: Array<StakeholderClassUpdateColumn>,
  where?: Maybe<StakeholderClassBoolExp>,
}

export interface StakeholderClassOrderBy {
  attribute_keys_aggregate?: Maybe<StakeholderAttributeKeysToStakeholderClassesAggregateOrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakeholderClassPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeholderClassSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface StakeholderClassSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum StakeholderClassUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum StakeholderConstraint {
  StakeholderOrganiztionIdIdKey = 'stakeholder_organiztion_id_id_key',
  StakeholderPkey = 'stakeholder_pkey',
  StakeholderUserIdKey = 'stakeholder_user_id_key'
}

export interface StakeholderFileGridView {
   __typename?: 'stakeholder_file_grid_view',
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  file?: Maybe<File>,
  id?: Maybe<Scalars['String']>,
  is_private?: Maybe<Scalars['Boolean']>,
  stakeholder?: Maybe<Stakeholder>,
  stakeholder_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface StakeholderFileGridViewAggregate {
   __typename?: 'stakeholder_file_grid_view_aggregate',
  aggregate?: Maybe<StakeholderFileGridViewAggregateFields>,
  nodes: Array<StakeholderFileGridView>,
}

export interface StakeholderFileGridViewAggregateFields {
   __typename?: 'stakeholder_file_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderFileGridViewMaxFields>,
  min?: Maybe<StakeholderFileGridViewMinFields>,
}


export interface StakeholderFileGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderFileGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderFileGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderFileGridViewMaxOrderBy>,
  min?: Maybe<StakeholderFileGridViewMinOrderBy>,
}

export interface StakeholderFileGridViewBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderFileGridViewBoolExp>>>,
  _not?: Maybe<StakeholderFileGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderFileGridViewBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  expires_at?: Maybe<TimestamptzComparisonExp>,
  file?: Maybe<FileBoolExp>,
  id?: Maybe<StringComparisonExp>,
  is_private?: Maybe<BooleanComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  stakeholder_id?: Maybe<StringComparisonExp>,
  title?: Maybe<StringComparisonExp>,
  type?: Maybe<StringComparisonExp>,
  uploaded_by?: Maybe<StringComparisonExp>,
}

export interface StakeholderFileGridViewMaxFields {
   __typename?: 'stakeholder_file_grid_view_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface StakeholderFileGridViewMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export interface StakeholderFileGridViewMinFields {
   __typename?: 'stakeholder_file_grid_view_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  stakeholder_id?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  uploaded_by?: Maybe<Scalars['String']>,
}

export interface StakeholderFileGridViewMinOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export interface StakeholderFileGridViewOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  file?: Maybe<FileOrderBy>,
  id?: Maybe<OrderBy>,
  is_private?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  stakeholder_id?: Maybe<OrderBy>,
  title?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
  uploaded_by?: Maybe<OrderBy>,
}

export enum StakeholderFileGridViewSelectColumn {
  CreatedAt = 'created_at',
  ExpiresAt = 'expires_at',
  Id = 'id',
  IsPrivate = 'is_private',
  StakeholderId = 'stakeholder_id',
  Title = 'title',
  Type = 'type',
  UploadedBy = 'uploaded_by'
}

export interface StakeholderInsertInput {
  attributes?: Maybe<StakeholderAttributeArrRelInsertInput>,
  avatar?: Maybe<Scalars['String']>,
  bank_accounts?: Maybe<BankAccountArrRelInsertInput>,
  class?: Maybe<StakeholderClassObjRelInsertInput>,
  class_id?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  files?: Maybe<FilesToStakeholdersArrRelInsertInput>,
  id?: Maybe<Scalars['String']>,
  is_marketing_communication_allowed?: Maybe<Scalars['Boolean']>,
  locale?: Maybe<LocaleObjRelInsertInput>,
  locale_id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionObjRelInsertInput>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionObjRelInsertInput>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  stakes?: Maybe<StakeArrRelInsertInput>,
  timezone?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
  user_invite?: Maybe<UserInviteObjRelInsertInput>,
  user_invite_id?: Maybe<Scalars['String']>,
}

export interface StakeholderMaxFields {
   __typename?: 'stakeholder_max_fields',
  avatar?: Maybe<Scalars['String']>,
  class_id?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  locale_id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
  user_invite_id?: Maybe<Scalars['String']>,
}

export interface StakeholderMaxOrderBy {
  avatar?: Maybe<OrderBy>,
  class_id?: Maybe<OrderBy>,
  color?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  locale_id?: Maybe<OrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  timezone?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_id?: Maybe<OrderBy>,
  user_invite_id?: Maybe<OrderBy>,
}

export interface StakeholderMinFields {
   __typename?: 'stakeholder_min_fields',
  avatar?: Maybe<Scalars['String']>,
  class_id?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  locale_id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
  user_invite_id?: Maybe<Scalars['String']>,
}

export interface StakeholderMinOrderBy {
  avatar?: Maybe<OrderBy>,
  class_id?: Maybe<OrderBy>,
  color?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  locale_id?: Maybe<OrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  timezone?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_id?: Maybe<OrderBy>,
  user_invite_id?: Maybe<OrderBy>,
}

export interface StakeholderMutationResponse {
   __typename?: 'stakeholder_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Stakeholder>,
}

export interface StakeholderName {
   __typename?: 'stakeholder_name',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<Stakeholder>,
}

export interface StakeholderNameAggregate {
   __typename?: 'stakeholder_name_aggregate',
  aggregate?: Maybe<StakeholderNameAggregateFields>,
  nodes: Array<StakeholderName>,
}

export interface StakeholderNameAggregateFields {
   __typename?: 'stakeholder_name_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakeholderNameMaxFields>,
  min?: Maybe<StakeholderNameMinFields>,
}


export interface StakeholderNameAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakeholderNameSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakeholderNameAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakeholderNameMaxOrderBy>,
  min?: Maybe<StakeholderNameMinOrderBy>,
}

export interface StakeholderNameBoolExp {
  _and?: Maybe<Array<Maybe<StakeholderNameBoolExp>>>,
  _not?: Maybe<StakeholderNameBoolExp>,
  _or?: Maybe<Array<Maybe<StakeholderNameBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
}

export interface StakeholderNameMaxFields {
   __typename?: 'stakeholder_name_max_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface StakeholderNameMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface StakeholderNameMinFields {
   __typename?: 'stakeholder_name_min_fields',
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

export interface StakeholderNameMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface StakeholderNameOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
}

export enum StakeholderNameSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface StakeholderObjRelInsertInput {
  data: StakeholderInsertInput,
  on_conflict?: Maybe<StakeholderOnConflict>,
}

export interface StakeholderOnConflict {
  constraint: StakeholderConstraint,
  update_columns: Array<StakeholderUpdateColumn>,
  where?: Maybe<StakeholderBoolExp>,
}

export interface StakeholderOrderBy {
  attribute_name?: Maybe<StakeholderAttributePivotOrderBy>,
  attribute_pivot?: Maybe<StakeholderAttributePivotOrderBy>,
  attributes_aggregate?: Maybe<StakeholderAttributeAggregateOrderBy>,
  avatar?: Maybe<OrderBy>,
  bank_accounts_aggregate?: Maybe<BankAccountAggregateOrderBy>,
  class?: Maybe<StakeholderClassOrderBy>,
  class_id?: Maybe<OrderBy>,
  color?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  files_aggregate?: Maybe<FilesToStakeholdersAggregateOrderBy>,
  id?: Maybe<OrderBy>,
  is_marketing_communication_allowed?: Maybe<OrderBy>,
  locale?: Maybe<LocaleOrderBy>,
  locale_id?: Maybe<OrderBy>,
  master_admin_stakeholder_permission?: Maybe<MasterAdminStakeholderPermissionOrderBy>,
  master_admin_stakeholder_permission_id?: Maybe<OrderBy>,
  master_partner_stakeholder_permission?: Maybe<MasterPartnerStakeholderPermissionOrderBy>,
  master_partner_stakeholder_permission_id?: Maybe<OrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  stakeholder_files_grid_view?: Maybe<StakeholderFileGridViewOrderBy>,
  stakeholder_name?: Maybe<StakeholderNameOrderBy>,
  stakes_aggregate?: Maybe<StakeAggregateOrderBy>,
  timezone?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  user_id?: Maybe<OrderBy>,
  user_invite?: Maybe<UserInviteOrderBy>,
  user_invite_id?: Maybe<OrderBy>,
  user_management_grid_view?: Maybe<UserManagementGridViewOrderBy>,
}

export interface StakeholderPkColumnsInput {
  id: Scalars['String'],
}

export enum StakeholderSelectColumn {
  Avatar = 'avatar',
  ClassId = 'class_id',
  Color = 'color',
  CreatedAt = 'created_at',
  Id = 'id',
  IsMarketingCommunicationAllowed = 'is_marketing_communication_allowed',
  LocaleId = 'locale_id',
  MasterAdminStakeholderPermissionId = 'master_admin_stakeholder_permission_id',
  MasterPartnerStakeholderPermissionId = 'master_partner_stakeholder_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  OrganizationId = 'organization_id',
  Timezone = 'timezone',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  UserInviteId = 'user_invite_id'
}

export interface StakeholderSetInput {
  avatar?: Maybe<Scalars['String']>,
  class_id?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_marketing_communication_allowed?: Maybe<Scalars['Boolean']>,
  locale_id?: Maybe<Scalars['String']>,
  master_admin_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_partner_stakeholder_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  user_id?: Maybe<Scalars['String']>,
  user_invite_id?: Maybe<Scalars['String']>,
}

export enum StakeholderUpdateColumn {
  Avatar = 'avatar',
  ClassId = 'class_id',
  Color = 'color',
  CreatedAt = 'created_at',
  Id = 'id',
  IsMarketingCommunicationAllowed = 'is_marketing_communication_allowed',
  LocaleId = 'locale_id',
  MasterAdminStakeholderPermissionId = 'master_admin_stakeholder_permission_id',
  MasterPartnerStakeholderPermissionId = 'master_partner_stakeholder_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  OrganizationId = 'organization_id',
  Timezone = 'timezone',
  UpdatedAt = 'updated_at',
  UserId = 'user_id',
  UserInviteId = 'user_invite_id'
}

export interface StakesToAdminPermissions {
   __typename?: 'stakes_to_admin_permissions',
  id: Scalars['String'],
  permission: AdminPermission,
  permission_id: Scalars['String'],
  stake: Stake,
  stake_id: Scalars['String'],
}

export interface StakesToAdminPermissionsAggregate {
   __typename?: 'stakes_to_admin_permissions_aggregate',
  aggregate?: Maybe<StakesToAdminPermissionsAggregateFields>,
  nodes: Array<StakesToAdminPermissions>,
}

export interface StakesToAdminPermissionsAggregateFields {
   __typename?: 'stakes_to_admin_permissions_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakesToAdminPermissionsMaxFields>,
  min?: Maybe<StakesToAdminPermissionsMinFields>,
}


export interface StakesToAdminPermissionsAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakesToAdminPermissionsAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakesToAdminPermissionsMaxOrderBy>,
  min?: Maybe<StakesToAdminPermissionsMinOrderBy>,
}

export interface StakesToAdminPermissionsArrRelInsertInput {
  data: Array<StakesToAdminPermissionsInsertInput>,
  on_conflict?: Maybe<StakesToAdminPermissionsOnConflict>,
}

export interface StakesToAdminPermissionsBoolExp {
  _and?: Maybe<Array<Maybe<StakesToAdminPermissionsBoolExp>>>,
  _not?: Maybe<StakesToAdminPermissionsBoolExp>,
  _or?: Maybe<Array<Maybe<StakesToAdminPermissionsBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  permission?: Maybe<AdminPermissionBoolExp>,
  permission_id?: Maybe<StringComparisonExp>,
  stake?: Maybe<StakeBoolExp>,
  stake_id?: Maybe<StringComparisonExp>,
}

export enum StakesToAdminPermissionsConstraint {
  StakesToAdminPermissionsPkey = 'stakes_to_admin_permissions_pkey'
}

export interface StakesToAdminPermissionsInsertInput {
  id?: Maybe<Scalars['String']>,
  permission?: Maybe<AdminPermissionObjRelInsertInput>,
  permission_id?: Maybe<Scalars['String']>,
  stake?: Maybe<StakeObjRelInsertInput>,
  stake_id?: Maybe<Scalars['String']>,
}

export interface StakesToAdminPermissionsMaxFields {
   __typename?: 'stakes_to_admin_permissions_max_fields',
  id?: Maybe<Scalars['String']>,
  permission_id?: Maybe<Scalars['String']>,
  stake_id?: Maybe<Scalars['String']>,
}

export interface StakesToAdminPermissionsMaxOrderBy {
  id?: Maybe<OrderBy>,
  permission_id?: Maybe<OrderBy>,
  stake_id?: Maybe<OrderBy>,
}

export interface StakesToAdminPermissionsMinFields {
   __typename?: 'stakes_to_admin_permissions_min_fields',
  id?: Maybe<Scalars['String']>,
  permission_id?: Maybe<Scalars['String']>,
  stake_id?: Maybe<Scalars['String']>,
}

export interface StakesToAdminPermissionsMinOrderBy {
  id?: Maybe<OrderBy>,
  permission_id?: Maybe<OrderBy>,
  stake_id?: Maybe<OrderBy>,
}

export interface StakesToAdminPermissionsMutationResponse {
   __typename?: 'stakes_to_admin_permissions_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakesToAdminPermissions>,
}

export interface StakesToAdminPermissionsObjRelInsertInput {
  data: StakesToAdminPermissionsInsertInput,
  on_conflict?: Maybe<StakesToAdminPermissionsOnConflict>,
}

export interface StakesToAdminPermissionsOnConflict {
  constraint: StakesToAdminPermissionsConstraint,
  update_columns: Array<StakesToAdminPermissionsUpdateColumn>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>,
}

export interface StakesToAdminPermissionsOrderBy {
  id?: Maybe<OrderBy>,
  permission?: Maybe<AdminPermissionOrderBy>,
  permission_id?: Maybe<OrderBy>,
  stake?: Maybe<StakeOrderBy>,
  stake_id?: Maybe<OrderBy>,
}

export interface StakesToAdminPermissionsPkColumnsInput {
  id: Scalars['String'],
}

export enum StakesToAdminPermissionsSelectColumn {
  Id = 'id',
  PermissionId = 'permission_id',
  StakeId = 'stake_id'
}

export interface StakesToAdminPermissionsSetInput {
  id?: Maybe<Scalars['String']>,
  permission_id?: Maybe<Scalars['String']>,
  stake_id?: Maybe<Scalars['String']>,
}

export enum StakesToAdminPermissionsUpdateColumn {
  Id = 'id',
  PermissionId = 'permission_id',
  StakeId = 'stake_id'
}

export interface StakesToStakeTitles {
   __typename?: 'stakes_to_stake_titles',
  created_at: Scalars['timestamptz'],
  stake: Stake,
  stake_id: Scalars['String'],
  stake_title_id: Scalars['String'],
  title: StakeTitle,
  updated_at: Scalars['timestamptz'],
}

export interface StakesToStakeTitlesAggregate {
   __typename?: 'stakes_to_stake_titles_aggregate',
  aggregate?: Maybe<StakesToStakeTitlesAggregateFields>,
  nodes: Array<StakesToStakeTitles>,
}

export interface StakesToStakeTitlesAggregateFields {
   __typename?: 'stakes_to_stake_titles_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<StakesToStakeTitlesMaxFields>,
  min?: Maybe<StakesToStakeTitlesMinFields>,
}


export interface StakesToStakeTitlesAggregateFieldsCountArgs {
  columns?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface StakesToStakeTitlesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<StakesToStakeTitlesMaxOrderBy>,
  min?: Maybe<StakesToStakeTitlesMinOrderBy>,
}

export interface StakesToStakeTitlesArrRelInsertInput {
  data: Array<StakesToStakeTitlesInsertInput>,
  on_conflict?: Maybe<StakesToStakeTitlesOnConflict>,
}

export interface StakesToStakeTitlesBoolExp {
  _and?: Maybe<Array<Maybe<StakesToStakeTitlesBoolExp>>>,
  _not?: Maybe<StakesToStakeTitlesBoolExp>,
  _or?: Maybe<Array<Maybe<StakesToStakeTitlesBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  stake?: Maybe<StakeBoolExp>,
  stake_id?: Maybe<StringComparisonExp>,
  stake_title_id?: Maybe<StringComparisonExp>,
  title?: Maybe<StakeTitleBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum StakesToStakeTitlesConstraint {
  RolesToStakesPkey = 'roles_to_stakes_pkey'
}

export interface StakesToStakeTitlesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  stake?: Maybe<StakeObjRelInsertInput>,
  stake_id?: Maybe<Scalars['String']>,
  stake_title_id?: Maybe<Scalars['String']>,
  title?: Maybe<StakeTitleObjRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakesToStakeTitlesMaxFields {
   __typename?: 'stakes_to_stake_titles_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  stake_id?: Maybe<Scalars['String']>,
  stake_title_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakesToStakeTitlesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  stake_id?: Maybe<OrderBy>,
  stake_title_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakesToStakeTitlesMinFields {
   __typename?: 'stakes_to_stake_titles_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  stake_id?: Maybe<Scalars['String']>,
  stake_title_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface StakesToStakeTitlesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  stake_id?: Maybe<OrderBy>,
  stake_title_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakesToStakeTitlesMutationResponse {
   __typename?: 'stakes_to_stake_titles_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<StakesToStakeTitles>,
}

export interface StakesToStakeTitlesObjRelInsertInput {
  data: StakesToStakeTitlesInsertInput,
  on_conflict?: Maybe<StakesToStakeTitlesOnConflict>,
}

export interface StakesToStakeTitlesOnConflict {
  constraint: StakesToStakeTitlesConstraint,
  update_columns: Array<StakesToStakeTitlesUpdateColumn>,
  where?: Maybe<StakesToStakeTitlesBoolExp>,
}

export interface StakesToStakeTitlesOrderBy {
  created_at?: Maybe<OrderBy>,
  stake?: Maybe<StakeOrderBy>,
  stake_id?: Maybe<OrderBy>,
  stake_title_id?: Maybe<OrderBy>,
  title?: Maybe<StakeTitleOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface StakesToStakeTitlesPkColumnsInput {
  stake_id: Scalars['String'],
  stake_title_id: Scalars['String'],
}

export enum StakesToStakeTitlesSelectColumn {
  CreatedAt = 'created_at',
  StakeId = 'stake_id',
  StakeTitleId = 'stake_title_id',
  UpdatedAt = 'updated_at'
}

export interface StakesToStakeTitlesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  stake_id?: Maybe<Scalars['String']>,
  stake_title_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum StakesToStakeTitlesUpdateColumn {
  CreatedAt = 'created_at',
  StakeId = 'stake_id',
  StakeTitleId = 'stake_title_id',
  UpdatedAt = 'updated_at'
}

export interface StringComparisonExp {
  _eq?: Maybe<Scalars['String']>,
  _gt?: Maybe<Scalars['String']>,
  _gte?: Maybe<Scalars['String']>,
  _ilike?: Maybe<Scalars['String']>,
  _in?: Maybe<Array<Scalars['String']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _like?: Maybe<Scalars['String']>,
  _lt?: Maybe<Scalars['String']>,
  _lte?: Maybe<Scalars['String']>,
  _neq?: Maybe<Scalars['String']>,
  _nilike?: Maybe<Scalars['String']>,
  _nin?: Maybe<Array<Scalars['String']>>,
  _nlike?: Maybe<Scalars['String']>,
  _nsimilar?: Maybe<Scalars['String']>,
  _similar?: Maybe<Scalars['String']>,
}

export interface SubscriptionRoot {
   __typename?: 'subscription_root',
  access_format: Array<AccessFormat>,
  access_format_aggregate: AccessFormatAggregate,
  access_format_by_pk?: Maybe<AccessFormat>,
  access_format_type: Array<AccessFormatType>,
  access_format_type_aggregate: AccessFormatTypeAggregate,
  access_format_type_by_pk?: Maybe<AccessFormatType>,
  account: Array<Account>,
  account_aggregate: AccountAggregate,
  account_by_pk?: Maybe<Account>,
  account_creation_request: Array<AccountCreationRequest>,
  account_creation_request_aggregate: AccountCreationRequestAggregate,
  account_creation_request_by_pk?: Maybe<AccountCreationRequest>,
  account_grid_view: Array<AccountGridView>,
  account_grid_view_aggregate: AccountGridViewAggregate,
  account_group: Array<AccountGroup>,
  account_group_aggregate: AccountGroupAggregate,
  account_group_by_pk?: Maybe<AccountGroup>,
  account_group_default: Array<AccountGroupDefault>,
  account_group_default_aggregate: AccountGroupDefaultAggregate,
  account_group_default_by_pk?: Maybe<AccountGroupDefault>,
  account_group_grid_view: Array<AccountGroupGridView>,
  account_group_grid_view_aggregate: AccountGroupGridViewAggregate,
  account_group_security: Array<AccountGroupSecurity>,
  account_group_security_aggregate: AccountGroupSecurityAggregate,
  account_group_security_by_pk?: Maybe<AccountGroupSecurity>,
  account_groups_to_partners: Array<AccountGroupsToPartners>,
  account_groups_to_partners_aggregate: AccountGroupsToPartnersAggregate,
  account_groups_to_partners_by_pk?: Maybe<AccountGroupsToPartners>,
  account_number: Array<AccountNumber>,
  account_number_aggregate: AccountNumberAggregate,
  account_type: Array<AccountType>,
  account_type_aggregate: AccountTypeAggregate,
  account_type_by_pk?: Maybe<AccountType>,
  activities_to_channels: Array<ActivitiesToChannels>,
  activities_to_channels_aggregate: ActivitiesToChannelsAggregate,
  activities_to_channels_by_pk?: Maybe<ActivitiesToChannels>,
  activity: Array<Activity>,
  activity_aggregate: ActivityAggregate,
  activity_by_pk?: Maybe<Activity>,
  activity_category: Array<ActivityCategory>,
  activity_category_aggregate: ActivityCategoryAggregate,
  activity_category_by_pk?: Maybe<ActivityCategory>,
  activity_channel: Array<ActivityChannel>,
  activity_channel_aggregate: ActivityChannelAggregate,
  activity_channel_by_pk?: Maybe<ActivityChannel>,
  activity_subject: Array<ActivitySubject>,
  activity_subject_aggregate: ActivitySubjectAggregate,
  activity_subject_by_pk?: Maybe<ActivitySubject>,
  activity_template: Array<ActivityTemplate>,
  activity_template_aggregate: ActivityTemplateAggregate,
  activity_template_by_pk?: Maybe<ActivityTemplate>,
  admin_permission: Array<AdminPermission>,
  admin_permission_aggregate: AdminPermissionAggregate,
  admin_permission_by_pk?: Maybe<AdminPermission>,
  agreement: Array<Agreement>,
  agreement_aggregate: AgreementAggregate,
  agreement_by_pk?: Maybe<Agreement>,
  agreement_grid_view: Array<AgreementGridView>,
  agreement_grid_view_aggregate: AgreementGridViewAggregate,
  agreement_scenario: Array<AgreementScenario>,
  agreement_scenario_aggregate: AgreementScenarioAggregate,
  agreement_scenario_by_pk?: Maybe<AgreementScenario>,
  agreements_satisfied: Array<AgreementsSatisfied>,
  agreements_satisfied_aggregate: AgreementsSatisfiedAggregate,
  agreements_satisfied_by_pk?: Maybe<AgreementsSatisfied>,
  agreements_to_payment_methods: Array<AgreementsToPaymentMethods>,
  agreements_to_payment_methods_aggregate: AgreementsToPaymentMethodsAggregate,
  agreements_to_payment_methods_by_pk?: Maybe<AgreementsToPaymentMethods>,
  annual_income: Array<AnnualIncome>,
  annual_income_aggregate: AnnualIncomeAggregate,
  annual_income_by_pk?: Maybe<AnnualIncome>,
  api_key: Array<ApiKey>,
  api_key_aggregate: ApiKeyAggregate,
  api_key_by_pk?: Maybe<ApiKey>,
  attribute_metadata: Array<AttributeMetadata>,
  attribute_metadata_aggregate: AttributeMetadataAggregate,
  attribute_metadata_by_pk?: Maybe<AttributeMetadata>,
  attribute_type: Array<AttributeType>,
  attribute_type_aggregate: AttributeTypeAggregate,
  attribute_type_by_pk?: Maybe<AttributeType>,
  bank_account: Array<BankAccount>,
  bank_account_aggregate: BankAccountAggregate,
  bank_account_by_pk?: Maybe<BankAccount>,
  book_type: Array<BookType>,
  book_type_aggregate: BookTypeAggregate,
  book_type_by_pk?: Maybe<BookType>,
  charge_unit: Array<ChargeUnit>,
  charge_unit_aggregate: ChargeUnitAggregate,
  charge_unit_by_pk?: Maybe<ChargeUnit>,
  countries_to_phone_codes: Array<CountriesToPhoneCodes>,
  countries_to_phone_codes_aggregate: CountriesToPhoneCodesAggregate,
  countries_to_phone_codes_by_pk?: Maybe<CountriesToPhoneCodes>,
  country: Array<Country>,
  country_aggregate: CountryAggregate,
  country_by_pk?: Maybe<Country>,
  currencies_to_organizations: Array<CurrenciesToOrganizations>,
  currencies_to_organizations_aggregate: CurrenciesToOrganizationsAggregate,
  currencies_to_organizations_by_pk?: Maybe<CurrenciesToOrganizations>,
  currency: Array<Currency>,
  currency_aggregate: CurrencyAggregate,
  currency_by_pk?: Maybe<Currency>,
  currency_grid_view: Array<CurrencyGridView>,
  currency_grid_view_aggregate: CurrencyGridViewAggregate,
  daily_account_revenue_summary: Array<DailyAccountRevenueSummary>,
  daily_account_revenue_summary_aggregate: DailyAccountRevenueSummaryAggregate,
  daily_account_revenue_summary_by_pk?: Maybe<DailyAccountRevenueSummary>,
  daily_account_status: Array<DailyAccountStatus>,
  daily_account_status_aggregate: DailyAccountStatusAggregate,
  daily_account_status_by_pk?: Maybe<DailyAccountStatus>,
  daily_volume_per_account: Array<DailyVolumePerAccount>,
  daily_volume_per_account_aggregate: DailyVolumePerAccountAggregate,
  daily_volume_per_account_by_pk?: Maybe<DailyVolumePerAccount>,
  daily_volume_per_profile: Array<DailyVolumePerProfile>,
  daily_volume_per_profile_aggregate: DailyVolumePerProfileAggregate,
  daily_volume_per_profile_by_pk?: Maybe<DailyVolumePerProfile>,
  employment_status: Array<EmploymentStatus>,
  employment_status_aggregate: EmploymentStatusAggregate,
  employment_status_by_pk?: Maybe<EmploymentStatus>,
  experience_type: Array<ExperienceType>,
  experience_type_aggregate: ExperienceTypeAggregate,
  experience_type_by_pk?: Maybe<ExperienceType>,
  file: Array<File>,
  file_aggregate: FileAggregate,
  file_by_pk?: Maybe<File>,
  file_type: Array<FileType>,
  file_type_aggregate: FileTypeAggregate,
  file_type_by_pk?: Maybe<FileType>,
  file_type_category: Array<FileTypeCategory>,
  file_type_category_aggregate: FileTypeCategoryAggregate,
  file_type_category_by_pk?: Maybe<FileTypeCategory>,
  file_type_grid_view: Array<FileTypeGridView>,
  file_type_grid_view_aggregate: FileTypeGridViewAggregate,
  files_to_organizations: Array<FilesToOrganizations>,
  files_to_organizations_aggregate: FilesToOrganizationsAggregate,
  files_to_organizations_by_pk?: Maybe<FilesToOrganizations>,
  files_to_stakeholders: Array<FilesToStakeholders>,
  files_to_stakeholders_aggregate: FilesToStakeholdersAggregate,
  files_to_stakeholders_by_pk?: Maybe<FilesToStakeholders>,
  gender: Array<Gender>,
  gender_aggregate: GenderAggregate,
  gender_by_pk?: Maybe<Gender>,
  identification_type: Array<IdentificationType>,
  identification_type_aggregate: IdentificationTypeAggregate,
  identification_type_by_pk?: Maybe<IdentificationType>,
  individual_partner_account_group_default: Array<IndividualPartnerAccountGroupDefault>,
  individual_partner_account_group_default_aggregate: IndividualPartnerAccountGroupDefaultAggregate,
  individual_partner_account_group_default_by_pk?: Maybe<IndividualPartnerAccountGroupDefault>,
  industry: Array<Industry>,
  industry_aggregate: IndustryAggregate,
  industry_by_pk?: Maybe<Industry>,
  intermediary_bank: Array<IntermediaryBank>,
  intermediary_bank_aggregate: IntermediaryBankAggregate,
  intermediary_bank_by_pk?: Maybe<IntermediaryBank>,
  investment_objective: Array<InvestmentObjective>,
  investment_objective_aggregate: InvestmentObjectiveAggregate,
  investment_objective_by_pk?: Maybe<InvestmentObjective>,
  leverage: Array<Leverage>,
  leverage_aggregate: LeverageAggregate,
  leverage_by_pk?: Maybe<Leverage>,
  leverage_grid_view: Array<LeverageGridView>,
  leverage_grid_view_aggregate: LeverageGridViewAggregate,
  leverages_to_platform_server_types: Array<LeveragesToPlatformServerTypes>,
  leverages_to_platform_server_types_aggregate: LeveragesToPlatformServerTypesAggregate,
  leverages_to_platform_server_types_by_pk?: Maybe<LeveragesToPlatformServerTypes>,
  liquid_assets: Array<LiquidAssets>,
  liquid_assets_aggregate: LiquidAssetsAggregate,
  liquid_assets_by_pk?: Maybe<LiquidAssets>,
  locale: Array<Locale>,
  locale_aggregate: LocaleAggregate,
  locale_by_pk?: Maybe<Locale>,
  locales_to_organizations: Array<LocalesToOrganizations>,
  locales_to_organizations_aggregate: LocalesToOrganizationsAggregate,
  locales_to_organizations_by_pk?: Maybe<LocalesToOrganizations>,
  lookup_view: Array<LookupView>,
  lookup_view_aggregate: LookupViewAggregate,
  margin_type: Array<MarginType>,
  margin_type_aggregate: MarginTypeAggregate,
  margin_type_by_pk?: Maybe<MarginType>,
  master_admin_client_permission: Array<MasterAdminClientPermission>,
  master_admin_client_permission_aggregate: MasterAdminClientPermissionAggregate,
  master_admin_client_permission_by_pk?: Maybe<MasterAdminClientPermission>,
  master_admin_lead_permission: Array<MasterAdminLeadPermission>,
  master_admin_lead_permission_aggregate: MasterAdminLeadPermissionAggregate,
  master_admin_lead_permission_by_pk?: Maybe<MasterAdminLeadPermission>,
  master_admin_partner_permission: Array<MasterAdminPartnerPermission>,
  master_admin_partner_permission_aggregate: MasterAdminPartnerPermissionAggregate,
  master_admin_partner_permission_by_pk?: Maybe<MasterAdminPartnerPermission>,
  master_admin_stakeholder_permission: Array<MasterAdminStakeholderPermission>,
  master_admin_stakeholder_permission_aggregate: MasterAdminStakeholderPermissionAggregate,
  master_admin_stakeholder_permission_by_pk?: Maybe<MasterAdminStakeholderPermission>,
  master_admin_system_permission: Array<MasterAdminSystemPermission>,
  master_admin_system_permission_aggregate: MasterAdminSystemPermissionAggregate,
  master_admin_system_permission_by_pk?: Maybe<MasterAdminSystemPermission>,
  master_partner_client_permission: Array<MasterPartnerClientPermission>,
  master_partner_client_permission_aggregate: MasterPartnerClientPermissionAggregate,
  master_partner_client_permission_by_pk?: Maybe<MasterPartnerClientPermission>,
  master_partner_lead_permission: Array<MasterPartnerLeadPermission>,
  master_partner_lead_permission_aggregate: MasterPartnerLeadPermissionAggregate,
  master_partner_lead_permission_by_pk?: Maybe<MasterPartnerLeadPermission>,
  master_partner_partner_permission: Array<MasterPartnerPartnerPermission>,
  master_partner_partner_permission_aggregate: MasterPartnerPartnerPermissionAggregate,
  master_partner_partner_permission_by_pk?: Maybe<MasterPartnerPartnerPermission>,
  master_partner_stakeholder_permission: Array<MasterPartnerStakeholderPermission>,
  master_partner_stakeholder_permission_aggregate: MasterPartnerStakeholderPermissionAggregate,
  master_partner_stakeholder_permission_by_pk?: Maybe<MasterPartnerStakeholderPermission>,
  master_user_permission: Array<MasterUserPermission>,
  master_user_permission_aggregate: MasterUserPermissionAggregate,
  master_user_permission_by_pk?: Maybe<MasterUserPermission>,
  name_title: Array<NameTitle>,
  name_title_aggregate: NameTitleAggregate,
  name_title_by_pk?: Maybe<NameTitle>,
  notification: Array<Notification>,
  notification_aggregate: NotificationAggregate,
  notification_by_pk?: Maybe<Notification>,
  organization: Array<Organization>,
  organization_aggregate: OrganizationAggregate,
  organization_by_pk?: Maybe<Organization>,
  origin: Array<Origin>,
  origin_aggregate: OriginAggregate,
  origin_by_pk?: Maybe<Origin>,
  partner_account_group_grid_view: Array<PartnerAccountGroupGridView>,
  partner_account_group_grid_view_aggregate: PartnerAccountGroupGridViewAggregate,
  partner_permission: Array<PartnerPermission>,
  partner_permission_aggregate: PartnerPermissionAggregate,
  partner_permission_by_pk?: Maybe<PartnerPermission>,
  payment: Array<Payment>,
  payment_aggregate: PaymentAggregate,
  payment_attribute_global_identifier: Array<PaymentAttributeGlobalIdentifier>,
  payment_attribute_global_identifier_aggregate: PaymentAttributeGlobalIdentifierAggregate,
  payment_attribute_global_identifier_by_pk?: Maybe<PaymentAttributeGlobalIdentifier>,
  payment_attribute_key: Array<PaymentAttributeKey>,
  payment_attribute_key_aggregate: PaymentAttributeKeyAggregate,
  payment_attribute_key_by_pk?: Maybe<PaymentAttributeKey>,
  payment_attribute_section: Array<PaymentAttributeSection>,
  payment_attribute_section_aggregate: PaymentAttributeSectionAggregate,
  payment_attribute_section_by_pk?: Maybe<PaymentAttributeSection>,
  payment_attribute_subsection: Array<PaymentAttributeSubsection>,
  payment_attribute_subsection_aggregate: PaymentAttributeSubsectionAggregate,
  payment_attribute_subsection_by_pk?: Maybe<PaymentAttributeSubsection>,
  payment_attribute_value: Array<PaymentAttributeValue>,
  payment_attribute_value_aggregate: PaymentAttributeValueAggregate,
  payment_attribute_value_by_pk?: Maybe<PaymentAttributeValue>,
  payment_by_pk?: Maybe<Payment>,
  payment_method: Array<PaymentMethod>,
  payment_method_aggregate: PaymentMethodAggregate,
  payment_method_attribute: Array<PaymentMethodAttribute>,
  payment_method_attribute_aggregate: PaymentMethodAttributeAggregate,
  payment_method_attribute_by_pk?: Maybe<PaymentMethodAttribute>,
  payment_method_attribute_key: Array<PaymentMethodAttributeKey>,
  payment_method_attribute_key_aggregate: PaymentMethodAttributeKeyAggregate,
  payment_method_attribute_key_by_pk?: Maybe<PaymentMethodAttributeKey>,
  payment_method_attribute_section: Array<PaymentMethodAttributeSection>,
  payment_method_attribute_section_aggregate: PaymentMethodAttributeSectionAggregate,
  payment_method_attribute_section_by_pk?: Maybe<PaymentMethodAttributeSection>,
  payment_method_attribute_subsection: Array<PaymentMethodAttributeSubsection>,
  payment_method_attribute_subsection_aggregate: PaymentMethodAttributeSubsectionAggregate,
  payment_method_attribute_subsection_by_pk?: Maybe<PaymentMethodAttributeSubsection>,
  payment_method_by_pk?: Maybe<PaymentMethod>,
  payment_method_grid_view: Array<PaymentMethodGridView>,
  payment_method_grid_view_aggregate: PaymentMethodGridViewAggregate,
  payment_method_logo: Array<PaymentMethodLogo>,
  payment_method_logo_aggregate: PaymentMethodLogoAggregate,
  payment_method_logo_by_pk?: Maybe<PaymentMethodLogo>,
  payment_method_type: Array<PaymentMethodType>,
  payment_method_type_aggregate: PaymentMethodTypeAggregate,
  payment_method_type_attribute: Array<PaymentMethodTypeAttribute>,
  payment_method_type_attribute_aggregate: PaymentMethodTypeAttributeAggregate,
  payment_method_type_attribute_by_pk?: Maybe<PaymentMethodTypeAttribute>,
  payment_method_type_attribute_key: Array<PaymentMethodTypeAttributeKey>,
  payment_method_type_attribute_key_aggregate: PaymentMethodTypeAttributeKeyAggregate,
  payment_method_type_attribute_key_by_pk?: Maybe<PaymentMethodTypeAttributeKey>,
  payment_method_type_attribute_section: Array<PaymentMethodTypeAttributeSection>,
  payment_method_type_attribute_section_aggregate: PaymentMethodTypeAttributeSectionAggregate,
  payment_method_type_attribute_section_by_pk?: Maybe<PaymentMethodTypeAttributeSection>,
  payment_method_type_attribute_subsection: Array<PaymentMethodTypeAttributeSubsection>,
  payment_method_type_attribute_subsection_aggregate: PaymentMethodTypeAttributeSubsectionAggregate,
  payment_method_type_attribute_subsection_by_pk?: Maybe<PaymentMethodTypeAttributeSubsection>,
  payment_method_type_by_pk?: Maybe<PaymentMethodType>,
  payment_method_type_currency: Array<PaymentMethodTypeCurrency>,
  payment_method_type_currency_aggregate: PaymentMethodTypeCurrencyAggregate,
  payment_method_type_currency_by_pk?: Maybe<PaymentMethodTypeCurrency>,
  payment_orientation: Array<PaymentOrientation>,
  payment_orientation_aggregate: PaymentOrientationAggregate,
  payment_orientation_by_pk?: Maybe<PaymentOrientation>,
  payment_provider: Array<PaymentProvider>,
  payment_provider_aggregate: PaymentProviderAggregate,
  payment_provider_by_pk?: Maybe<PaymentProvider>,
  payment_provider_integration_mode: Array<PaymentProviderIntegrationMode>,
  payment_provider_integration_mode_aggregate: PaymentProviderIntegrationModeAggregate,
  payment_provider_integration_mode_by_pk?: Maybe<PaymentProviderIntegrationMode>,
  payment_provider_method_type: Array<PaymentProviderMethodType>,
  payment_provider_method_type_aggregate: PaymentProviderMethodTypeAggregate,
  payment_provider_method_type_by_pk?: Maybe<PaymentProviderMethodType>,
  payment_status: Array<PaymentStatus>,
  payment_status_aggregate: PaymentStatusAggregate,
  payment_status_by_pk?: Maybe<PaymentStatus>,
  phone_code: Array<PhoneCode>,
  phone_code_aggregate: PhoneCodeAggregate,
  phone_code_by_pk?: Maybe<PhoneCode>,
  phone_type: Array<PhoneType>,
  phone_type_aggregate: PhoneTypeAggregate,
  phone_type_by_pk?: Maybe<PhoneType>,
  platform_access: Array<PlatformAccess>,
  platform_access_aggregate: PlatformAccessAggregate,
  platform_access_by_pk?: Maybe<PlatformAccess>,
  platform_server: Array<PlatformServer>,
  platform_server_aggregate: PlatformServerAggregate,
  platform_server_by_pk?: Maybe<PlatformServer>,
  platform_server_environment: Array<PlatformServerEnvironment>,
  platform_server_environment_aggregate: PlatformServerEnvironmentAggregate,
  platform_server_environment_by_pk?: Maybe<PlatformServerEnvironment>,
  platform_server_grid_view: Array<PlatformServerGridView>,
  platform_server_grid_view_aggregate: PlatformServerGridViewAggregate,
  platform_server_retry_status: Array<PlatformServerRetryStatus>,
  platform_server_retry_status_aggregate: PlatformServerRetryStatusAggregate,
  platform_server_retry_status_by_pk?: Maybe<PlatformServerRetryStatus>,
  platform_server_status: Array<PlatformServerStatus>,
  platform_server_status_aggregate: PlatformServerStatusAggregate,
  platform_server_status_by_pk?: Maybe<PlatformServerStatus>,
  platform_server_type: Array<PlatformServerType>,
  platform_server_type_aggregate: PlatformServerTypeAggregate,
  platform_server_type_by_pk?: Maybe<PlatformServerType>,
  platform_servers_to_supported_leverages: Array<PlatformServersToSupportedLeverages>,
  platform_servers_to_supported_leverages_aggregate: PlatformServersToSupportedLeveragesAggregate,
  platform_servers_to_supported_leverages_by_pk?: Maybe<PlatformServersToSupportedLeverages>,
  platform_symbol: Array<PlatformSymbol>,
  platform_symbol_aggregate: PlatformSymbolAggregate,
  platform_symbol_by_pk?: Maybe<PlatformSymbol>,
  profile: Array<Profile>,
  profile_activity: Array<ProfileActivity>,
  profile_activity_aggregate: ProfileActivityAggregate,
  profile_activity_by_pk?: Maybe<ProfileActivity>,
  profile_aggregate: ProfileAggregate,
  profile_by_pk?: Maybe<Profile>,
  profile_category: Array<ProfileCategory>,
  profile_category_aggregate: ProfileCategoryAggregate,
  profile_category_by_pk?: Maybe<ProfileCategory>,
  profile_entry_type: Array<ProfileEntryType>,
  profile_entry_type_aggregate: ProfileEntryTypeAggregate,
  profile_entry_type_by_pk?: Maybe<ProfileEntryType>,
  profile_flags: Array<ProfileFlags>,
  profile_flags_aggregate: ProfileFlagsAggregate,
  profile_flags_by_pk?: Maybe<ProfileFlags>,
  profile_grid_view: Array<ProfileGridView>,
  profile_grid_view_aggregate: ProfileGridViewAggregate,
  profile_note: Array<ProfileNote>,
  profile_note_aggregate: ProfileNoteAggregate,
  profile_note_by_pk?: Maybe<ProfileNote>,
  profile_note_version: Array<ProfileNoteVersion>,
  profile_note_version_aggregate: ProfileNoteVersionAggregate,
  profile_note_version_by_pk?: Maybe<ProfileNoteVersion>,
  profile_note_versions_stakeholder_mentions: Array<ProfileNoteVersionsStakeholderMentions>,
  profile_note_versions_stakeholder_mentions_aggregate: ProfileNoteVersionsStakeholderMentionsAggregate,
  profile_note_versions_stakeholder_mentions_by_pk?: Maybe<ProfileNoteVersionsStakeholderMentions>,
  profile_note_versions_stakeholder_relevant_parties: Array<ProfileNoteVersionsStakeholderRelevantParties>,
  profile_note_versions_stakeholder_relevant_parties_aggregate: ProfileNoteVersionsStakeholderRelevantPartiesAggregate,
  profile_note_versions_stakeholder_relevant_parties_by_pk?: Maybe<ProfileNoteVersionsStakeholderRelevantParties>,
  profile_risk: Array<ProfileRisk>,
  profile_risk_aggregate: ProfileRiskAggregate,
  profile_risk_by_pk?: Maybe<ProfileRisk>,
  profile_status: Array<ProfileStatus>,
  profile_status_aggregate: ProfileStatusAggregate,
  profile_status_by_pk?: Maybe<ProfileStatus>,
  profile_type: Array<ProfileType>,
  profile_type_aggregate: ProfileTypeAggregate,
  profile_type_by_pk?: Maybe<ProfileType>,
  profiles_to_tags: Array<ProfilesToTags>,
  profiles_to_tags_aggregate: ProfilesToTagsAggregate,
  profiles_to_tags_by_pk?: Maybe<ProfilesToTags>,
  random_country: Array<RandomCountry>,
  random_country_aggregate: RandomCountryAggregate,
  random_currency: Array<RandomCurrency>,
  random_currency_aggregate: RandomCurrencyAggregate,
  random_phone_type: Array<RandomPhoneType>,
  random_phone_type_aggregate: RandomPhoneTypeAggregate,
  random_profile: Array<RandomProfile>,
  random_profile_aggregate: RandomProfileAggregate,
  random_profile_risk: Array<RandomProfileRisk>,
  random_profile_risk_aggregate: RandomProfileRiskAggregate,
  random_profile_status: Array<RandomProfileStatus>,
  random_profile_status_aggregate: RandomProfileStatusAggregate,
  random_tag: Array<RandomTag>,
  random_tag_aggregate: RandomTagAggregate,
  representatives_to_profiles: Array<RepresentativesToProfiles>,
  representatives_to_profiles_aggregate: RepresentativesToProfilesAggregate,
  representatives_to_profiles_by_pk?: Maybe<RepresentativesToProfiles>,
  request: Array<Request>,
  request_aggregate: RequestAggregate,
  request_by_pk?: Maybe<Request>,
  request_grid_view: Array<RequestGridView>,
  request_grid_view_aggregate: RequestGridViewAggregate,
  request_status: Array<RequestStatus>,
  request_status_aggregate: RequestStatusAggregate,
  request_status_by_pk?: Maybe<RequestStatus>,
  request_type: Array<RequestType>,
  request_type_aggregate: RequestTypeAggregate,
  request_type_by_pk?: Maybe<RequestType>,
  security: Array<Security>,
  security_aggregate: SecurityAggregate,
  security_by_pk?: Maybe<Security>,
  security_grid_view: Array<SecurityGridView>,
  security_grid_view_aggregate: SecurityGridViewAggregate,
  segment: Array<Segment>,
  segment_aggregate: SegmentAggregate,
  segment_by_pk?: Maybe<Segment>,
  segment_condition: Array<SegmentCondition>,
  segment_condition_aggregate: SegmentConditionAggregate,
  segment_condition_by_pk?: Maybe<SegmentCondition>,
  segment_criterion: Array<SegmentCriterion>,
  segment_criterion_aggregate: SegmentCriterionAggregate,
  segment_criterion_by_pk?: Maybe<SegmentCriterion>,
  segment_criterion_type: Array<SegmentCriterionType>,
  segment_criterion_type_aggregate: SegmentCriterionTypeAggregate,
  segment_criterion_type_by_pk?: Maybe<SegmentCriterionType>,
  segment_grid_view: Array<SegmentGridView>,
  segment_grid_view_aggregate: SegmentGridViewAggregate,
  segment_operator: Array<SegmentOperator>,
  segment_operator_aggregate: SegmentOperatorAggregate,
  segment_operator_by_pk?: Maybe<SegmentOperator>,
  segment_operators_to_segment_property_conditions: Array<SegmentOperatorsToSegmentPropertyConditions>,
  segment_operators_to_segment_property_conditions_aggregate: SegmentOperatorsToSegmentPropertyConditionsAggregate,
  segment_operators_to_segment_property_conditions_by_pk?: Maybe<SegmentOperatorsToSegmentPropertyConditions>,
  segment_property: Array<SegmentProperty>,
  segment_property_aggregate: SegmentPropertyAggregate,
  segment_property_by_pk?: Maybe<SegmentProperty>,
  segment_property_condition: Array<SegmentPropertyCondition>,
  segment_property_condition_aggregate: SegmentPropertyConditionAggregate,
  segment_property_condition_by_pk?: Maybe<SegmentPropertyCondition>,
  shared_file_grid_view: Array<SharedFileGridView>,
  shared_file_grid_view_aggregate: SharedFileGridViewAggregate,
  source_of_funds: Array<SourceOfFunds>,
  source_of_funds_aggregate: SourceOfFundsAggregate,
  source_of_funds_by_pk?: Maybe<SourceOfFunds>,
  spread_mode: Array<SpreadMode>,
  spread_mode_aggregate: SpreadModeAggregate,
  spread_mode_by_pk?: Maybe<SpreadMode>,
  stake: Array<Stake>,
  stake_aggregate: StakeAggregate,
  stake_ancestry: Array<StakeAncestry>,
  stake_ancestry_aggregate: StakeAncestryAggregate,
  stake_ancestry_by_pk?: Maybe<StakeAncestry>,
  stake_by_pk?: Maybe<Stake>,
  stake_title: Array<StakeTitle>,
  stake_title_aggregate: StakeTitleAggregate,
  stake_title_by_pk?: Maybe<StakeTitle>,
  stakeholder: Array<Stakeholder>,
  stakeholder_aggregate: StakeholderAggregate,
  stakeholder_attribute: Array<StakeholderAttribute>,
  stakeholder_attribute_aggregate: StakeholderAttributeAggregate,
  stakeholder_attribute_by_pk?: Maybe<StakeholderAttribute>,
  stakeholder_attribute_key: Array<StakeholderAttributeKey>,
  stakeholder_attribute_key_aggregate: StakeholderAttributeKeyAggregate,
  stakeholder_attribute_key_by_pk?: Maybe<StakeholderAttributeKey>,
  stakeholder_attribute_keys_to_stakeholder_classes: Array<StakeholderAttributeKeysToStakeholderClasses>,
  stakeholder_attribute_keys_to_stakeholder_classes_aggregate: StakeholderAttributeKeysToStakeholderClassesAggregate,
  stakeholder_attribute_keys_to_stakeholder_classes_by_pk?: Maybe<StakeholderAttributeKeysToStakeholderClasses>,
  stakeholder_attribute_pivot: Array<StakeholderAttributePivot>,
  stakeholder_attribute_pivot_aggregate: StakeholderAttributePivotAggregate,
  stakeholder_attribute_section: Array<StakeholderAttributeSection>,
  stakeholder_attribute_section_aggregate: StakeholderAttributeSectionAggregate,
  stakeholder_attribute_section_by_pk?: Maybe<StakeholderAttributeSection>,
  stakeholder_attribute_subsection: Array<StakeholderAttributeSubsection>,
  stakeholder_attribute_subsection_aggregate: StakeholderAttributeSubsectionAggregate,
  stakeholder_attribute_subsection_by_pk?: Maybe<StakeholderAttributeSubsection>,
  stakeholder_by_pk?: Maybe<Stakeholder>,
  stakeholder_class: Array<StakeholderClass>,
  stakeholder_class_aggregate: StakeholderClassAggregate,
  stakeholder_class_by_pk?: Maybe<StakeholderClass>,
  stakeholder_file_grid_view: Array<StakeholderFileGridView>,
  stakeholder_file_grid_view_aggregate: StakeholderFileGridViewAggregate,
  stakeholder_name: Array<StakeholderName>,
  stakeholder_name_aggregate: StakeholderNameAggregate,
  stakes_to_admin_permissions: Array<StakesToAdminPermissions>,
  stakes_to_admin_permissions_aggregate: StakesToAdminPermissionsAggregate,
  stakes_to_admin_permissions_by_pk?: Maybe<StakesToAdminPermissions>,
  stakes_to_stake_titles: Array<StakesToStakeTitles>,
  stakes_to_stake_titles_aggregate: StakesToStakeTitlesAggregate,
  stakes_to_stake_titles_by_pk?: Maybe<StakesToStakeTitles>,
  swap_mode: Array<SwapMode>,
  swap_mode_aggregate: SwapModeAggregate,
  swap_mode_by_pk?: Maybe<SwapMode>,
  symbol: Array<Symbol>,
  symbol_aggregate: SymbolAggregate,
  symbol_by_pk?: Maybe<Symbol>,
  symbol_extension: Array<SymbolExtension>,
  symbol_extension_aggregate: SymbolExtensionAggregate,
  symbol_extension_by_pk?: Maybe<SymbolExtension>,
  symbol_grid_view: Array<SymbolGridView>,
  symbol_grid_view_aggregate: SymbolGridViewAggregate,
  symbol_type: Array<SymbolType>,
  symbol_type_aggregate: SymbolTypeAggregate,
  symbol_type_by_pk?: Maybe<SymbolType>,
  tag: Array<Tag>,
  tag_aggregate: TagAggregate,
  tag_by_pk?: Maybe<Tag>,
  trade_rule: Array<TradeRule>,
  trade_rule_aggregate: TradeRuleAggregate,
  trade_rule_by_pk?: Maybe<TradeRule>,
  transaction: Array<Transaction>,
  transaction_aggregate: TransactionAggregate,
  transaction_by_pk?: Maybe<Transaction>,
  transaction_creation_request: Array<TransactionCreationRequest>,
  transaction_creation_request_aggregate: TransactionCreationRequestAggregate,
  transaction_creation_request_by_pk?: Maybe<TransactionCreationRequest>,
  transaction_grid_view: Array<TransactionGridView>,
  transaction_grid_view_aggregate: TransactionGridViewAggregate,
  transaction_platform_status: Array<TransactionPlatformStatus>,
  transaction_platform_status_aggregate: TransactionPlatformStatusAggregate,
  transaction_platform_status_by_pk?: Maybe<TransactionPlatformStatus>,
  transaction_profile_grid_view: Array<TransactionProfileGridView>,
  transaction_profile_grid_view_aggregate: TransactionProfileGridViewAggregate,
  transaction_status: Array<TransactionStatus>,
  transaction_status_aggregate: TransactionStatusAggregate,
  transaction_status_by_pk?: Maybe<TransactionStatus>,
  transaction_type: Array<TransactionType>,
  transaction_type_aggregate: TransactionTypeAggregate,
  transaction_type_by_pk?: Maybe<TransactionType>,
  transfer_approval_option: Array<TransferApprovalOption>,
  transfer_approval_option_aggregate: TransferApprovalOptionAggregate,
  transfer_approval_option_by_pk?: Maybe<TransferApprovalOption>,
  user_access: Array<UserAccess>,
  user_access_aggregate: UserAccessAggregate,
  user_access_by_pk?: Maybe<UserAccess>,
  user_invite: Array<UserInvite>,
  user_invite_aggregate: UserInviteAggregate,
  user_invite_by_pk?: Maybe<UserInvite>,
  user_management_grid_view: Array<UserManagementGridView>,
  user_management_grid_view_aggregate: UserManagementGridViewAggregate,
  user_permission: Array<UserPermission>,
  user_permission_aggregate: UserPermissionAggregate,
  user_permission_by_pk?: Maybe<UserPermission>,
  workflow: Array<Workflow>,
  workflow_aggregate: WorkflowAggregate,
  workflow_by_pk?: Maybe<Workflow>,
  workflow_stage: Array<WorkflowStage>,
  workflow_stage_aggregate: WorkflowStageAggregate,
  workflow_stage_by_pk?: Maybe<WorkflowStage>,
  workflows_to_profiles: Array<WorkflowsToProfiles>,
  workflows_to_profiles_aggregate: WorkflowsToProfilesAggregate,
  workflows_to_profiles_by_pk?: Maybe<WorkflowsToProfiles>,
  years_experience: Array<YearsExperience>,
  years_experience_aggregate: YearsExperienceAggregate,
  years_experience_by_pk?: Maybe<YearsExperience>,
}


export interface SubscriptionRootAccessFormatArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface SubscriptionRootAccessFormatAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatOrderBy>>,
  where?: Maybe<AccessFormatBoolExp>
}


export interface SubscriptionRootAccessFormatByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAccessFormatTypeArgs {
  distinct_on?: Maybe<Array<AccessFormatTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatTypeOrderBy>>,
  where?: Maybe<AccessFormatTypeBoolExp>
}


export interface SubscriptionRootAccessFormatTypeAggregateArgs {
  distinct_on?: Maybe<Array<AccessFormatTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccessFormatTypeOrderBy>>,
  where?: Maybe<AccessFormatTypeBoolExp>
}


export interface SubscriptionRootAccessFormatTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAccountArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface SubscriptionRootAccountAggregateArgs {
  distinct_on?: Maybe<Array<AccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountOrderBy>>,
  where?: Maybe<AccountBoolExp>
}


export interface SubscriptionRootAccountByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAccountCreationRequestArgs {
  distinct_on?: Maybe<Array<AccountCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountCreationRequestOrderBy>>,
  where?: Maybe<AccountCreationRequestBoolExp>
}


export interface SubscriptionRootAccountCreationRequestAggregateArgs {
  distinct_on?: Maybe<Array<AccountCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountCreationRequestOrderBy>>,
  where?: Maybe<AccountCreationRequestBoolExp>
}


export interface SubscriptionRootAccountCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAccountGridViewArgs {
  distinct_on?: Maybe<Array<AccountGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGridViewOrderBy>>,
  where?: Maybe<AccountGridViewBoolExp>
}


export interface SubscriptionRootAccountGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AccountGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGridViewOrderBy>>,
  where?: Maybe<AccountGridViewBoolExp>
}


export interface SubscriptionRootAccountGroupArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface SubscriptionRootAccountGroupAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupOrderBy>>,
  where?: Maybe<AccountGroupBoolExp>
}


export interface SubscriptionRootAccountGroupByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAccountGroupDefaultArgs {
  distinct_on?: Maybe<Array<AccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupDefaultOrderBy>>,
  where?: Maybe<AccountGroupDefaultBoolExp>
}


export interface SubscriptionRootAccountGroupDefaultAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupDefaultOrderBy>>,
  where?: Maybe<AccountGroupDefaultBoolExp>
}


export interface SubscriptionRootAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface SubscriptionRootAccountGroupGridViewArgs {
  distinct_on?: Maybe<Array<AccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupGridViewOrderBy>>,
  where?: Maybe<AccountGroupGridViewBoolExp>
}


export interface SubscriptionRootAccountGroupGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupGridViewOrderBy>>,
  where?: Maybe<AccountGroupGridViewBoolExp>
}


export interface SubscriptionRootAccountGroupSecurityArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface SubscriptionRootAccountGroupSecurityAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface SubscriptionRootAccountGroupSecurityByPkArgs {
  group_id: Scalars['String'],
  security_id: Scalars['String']
}


export interface SubscriptionRootAccountGroupsToPartnersArgs {
  distinct_on?: Maybe<Array<AccountGroupsToPartnersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupsToPartnersOrderBy>>,
  where?: Maybe<AccountGroupsToPartnersBoolExp>
}


export interface SubscriptionRootAccountGroupsToPartnersAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupsToPartnersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupsToPartnersOrderBy>>,
  where?: Maybe<AccountGroupsToPartnersBoolExp>
}


export interface SubscriptionRootAccountGroupsToPartnersByPkArgs {
  group_id: Scalars['String'],
  partner_id: Scalars['String']
}


export interface SubscriptionRootAccountNumberArgs {
  distinct_on?: Maybe<Array<AccountNumberSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountNumberOrderBy>>,
  where?: Maybe<AccountNumberBoolExp>
}


export interface SubscriptionRootAccountNumberAggregateArgs {
  distinct_on?: Maybe<Array<AccountNumberSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountNumberOrderBy>>,
  where?: Maybe<AccountNumberBoolExp>
}


export interface SubscriptionRootAccountTypeArgs {
  distinct_on?: Maybe<Array<AccountTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountTypeOrderBy>>,
  where?: Maybe<AccountTypeBoolExp>
}


export interface SubscriptionRootAccountTypeAggregateArgs {
  distinct_on?: Maybe<Array<AccountTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountTypeOrderBy>>,
  where?: Maybe<AccountTypeBoolExp>
}


export interface SubscriptionRootAccountTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivitiesToChannelsArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface SubscriptionRootActivitiesToChannelsAggregateArgs {
  distinct_on?: Maybe<Array<ActivitiesToChannelsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitiesToChannelsOrderBy>>,
  where?: Maybe<ActivitiesToChannelsBoolExp>
}


export interface SubscriptionRootActivitiesToChannelsByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivityArgs {
  distinct_on?: Maybe<Array<ActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityOrderBy>>,
  where?: Maybe<ActivityBoolExp>
}


export interface SubscriptionRootActivityAggregateArgs {
  distinct_on?: Maybe<Array<ActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityOrderBy>>,
  where?: Maybe<ActivityBoolExp>
}


export interface SubscriptionRootActivityByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivityCategoryArgs {
  distinct_on?: Maybe<Array<ActivityCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityCategoryOrderBy>>,
  where?: Maybe<ActivityCategoryBoolExp>
}


export interface SubscriptionRootActivityCategoryAggregateArgs {
  distinct_on?: Maybe<Array<ActivityCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityCategoryOrderBy>>,
  where?: Maybe<ActivityCategoryBoolExp>
}


export interface SubscriptionRootActivityCategoryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivityChannelArgs {
  distinct_on?: Maybe<Array<ActivityChannelSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityChannelOrderBy>>,
  where?: Maybe<ActivityChannelBoolExp>
}


export interface SubscriptionRootActivityChannelAggregateArgs {
  distinct_on?: Maybe<Array<ActivityChannelSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityChannelOrderBy>>,
  where?: Maybe<ActivityChannelBoolExp>
}


export interface SubscriptionRootActivityChannelByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivitySubjectArgs {
  distinct_on?: Maybe<Array<ActivitySubjectSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitySubjectOrderBy>>,
  where?: Maybe<ActivitySubjectBoolExp>
}


export interface SubscriptionRootActivitySubjectAggregateArgs {
  distinct_on?: Maybe<Array<ActivitySubjectSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivitySubjectOrderBy>>,
  where?: Maybe<ActivitySubjectBoolExp>
}


export interface SubscriptionRootActivitySubjectByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootActivityTemplateArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}


export interface SubscriptionRootActivityTemplateAggregateArgs {
  distinct_on?: Maybe<Array<ActivityTemplateSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ActivityTemplateOrderBy>>,
  where?: Maybe<ActivityTemplateBoolExp>
}


export interface SubscriptionRootActivityTemplateByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAdminPermissionArgs {
  distinct_on?: Maybe<Array<AdminPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AdminPermissionOrderBy>>,
  where?: Maybe<AdminPermissionBoolExp>
}


export interface SubscriptionRootAdminPermissionAggregateArgs {
  distinct_on?: Maybe<Array<AdminPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AdminPermissionOrderBy>>,
  where?: Maybe<AdminPermissionBoolExp>
}


export interface SubscriptionRootAdminPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAgreementArgs {
  distinct_on?: Maybe<Array<AgreementSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementOrderBy>>,
  where?: Maybe<AgreementBoolExp>
}


export interface SubscriptionRootAgreementAggregateArgs {
  distinct_on?: Maybe<Array<AgreementSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementOrderBy>>,
  where?: Maybe<AgreementBoolExp>
}


export interface SubscriptionRootAgreementByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAgreementGridViewArgs {
  distinct_on?: Maybe<Array<AgreementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementGridViewOrderBy>>,
  where?: Maybe<AgreementGridViewBoolExp>
}


export interface SubscriptionRootAgreementGridViewAggregateArgs {
  distinct_on?: Maybe<Array<AgreementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementGridViewOrderBy>>,
  where?: Maybe<AgreementGridViewBoolExp>
}


export interface SubscriptionRootAgreementScenarioArgs {
  distinct_on?: Maybe<Array<AgreementScenarioSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementScenarioOrderBy>>,
  where?: Maybe<AgreementScenarioBoolExp>
}


export interface SubscriptionRootAgreementScenarioAggregateArgs {
  distinct_on?: Maybe<Array<AgreementScenarioSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementScenarioOrderBy>>,
  where?: Maybe<AgreementScenarioBoolExp>
}


export interface SubscriptionRootAgreementScenarioByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAgreementsSatisfiedArgs {
  distinct_on?: Maybe<Array<AgreementsSatisfiedSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsSatisfiedOrderBy>>,
  where?: Maybe<AgreementsSatisfiedBoolExp>
}


export interface SubscriptionRootAgreementsSatisfiedAggregateArgs {
  distinct_on?: Maybe<Array<AgreementsSatisfiedSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsSatisfiedOrderBy>>,
  where?: Maybe<AgreementsSatisfiedBoolExp>
}


export interface SubscriptionRootAgreementsSatisfiedByPkArgs {
  agreement_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface SubscriptionRootAgreementsToPaymentMethodsArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface SubscriptionRootAgreementsToPaymentMethodsAggregateArgs {
  distinct_on?: Maybe<Array<AgreementsToPaymentMethodsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AgreementsToPaymentMethodsOrderBy>>,
  where?: Maybe<AgreementsToPaymentMethodsBoolExp>
}


export interface SubscriptionRootAgreementsToPaymentMethodsByPkArgs {
  agreement_id: Scalars['String'],
  method_id: Scalars['String']
}


export interface SubscriptionRootAnnualIncomeArgs {
  distinct_on?: Maybe<Array<AnnualIncomeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AnnualIncomeOrderBy>>,
  where?: Maybe<AnnualIncomeBoolExp>
}


export interface SubscriptionRootAnnualIncomeAggregateArgs {
  distinct_on?: Maybe<Array<AnnualIncomeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AnnualIncomeOrderBy>>,
  where?: Maybe<AnnualIncomeBoolExp>
}


export interface SubscriptionRootAnnualIncomeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootApiKeyArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface SubscriptionRootApiKeyAggregateArgs {
  distinct_on?: Maybe<Array<ApiKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ApiKeyOrderBy>>,
  where?: Maybe<ApiKeyBoolExp>
}


export interface SubscriptionRootApiKeyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAttributeMetadataArgs {
  distinct_on?: Maybe<Array<AttributeMetadataSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeMetadataOrderBy>>,
  where?: Maybe<AttributeMetadataBoolExp>
}


export interface SubscriptionRootAttributeMetadataAggregateArgs {
  distinct_on?: Maybe<Array<AttributeMetadataSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeMetadataOrderBy>>,
  where?: Maybe<AttributeMetadataBoolExp>
}


export interface SubscriptionRootAttributeMetadataByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootAttributeTypeArgs {
  distinct_on?: Maybe<Array<AttributeTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeTypeOrderBy>>,
  where?: Maybe<AttributeTypeBoolExp>
}


export interface SubscriptionRootAttributeTypeAggregateArgs {
  distinct_on?: Maybe<Array<AttributeTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AttributeTypeOrderBy>>,
  where?: Maybe<AttributeTypeBoolExp>
}


export interface SubscriptionRootAttributeTypeByPkArgs {
  name: Scalars['String']
}


export interface SubscriptionRootBankAccountArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface SubscriptionRootBankAccountAggregateArgs {
  distinct_on?: Maybe<Array<BankAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BankAccountOrderBy>>,
  where?: Maybe<BankAccountBoolExp>
}


export interface SubscriptionRootBankAccountByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootBookTypeArgs {
  distinct_on?: Maybe<Array<BookTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BookTypeOrderBy>>,
  where?: Maybe<BookTypeBoolExp>
}


export interface SubscriptionRootBookTypeAggregateArgs {
  distinct_on?: Maybe<Array<BookTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<BookTypeOrderBy>>,
  where?: Maybe<BookTypeBoolExp>
}


export interface SubscriptionRootBookTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootChargeUnitArgs {
  distinct_on?: Maybe<Array<ChargeUnitSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ChargeUnitOrderBy>>,
  where?: Maybe<ChargeUnitBoolExp>
}


export interface SubscriptionRootChargeUnitAggregateArgs {
  distinct_on?: Maybe<Array<ChargeUnitSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ChargeUnitOrderBy>>,
  where?: Maybe<ChargeUnitBoolExp>
}


export interface SubscriptionRootChargeUnitByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootCountriesToPhoneCodesArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}


export interface SubscriptionRootCountriesToPhoneCodesAggregateArgs {
  distinct_on?: Maybe<Array<CountriesToPhoneCodesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountriesToPhoneCodesOrderBy>>,
  where?: Maybe<CountriesToPhoneCodesBoolExp>
}


export interface SubscriptionRootCountriesToPhoneCodesByPkArgs {
  code: Scalars['String'],
  country_id: Scalars['String']
}


export interface SubscriptionRootCountryArgs {
  distinct_on?: Maybe<Array<CountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountryOrderBy>>,
  where?: Maybe<CountryBoolExp>
}


export interface SubscriptionRootCountryAggregateArgs {
  distinct_on?: Maybe<Array<CountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CountryOrderBy>>,
  where?: Maybe<CountryBoolExp>
}


export interface SubscriptionRootCountryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootCurrenciesToOrganizationsArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface SubscriptionRootCurrenciesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<CurrenciesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrenciesToOrganizationsOrderBy>>,
  where?: Maybe<CurrenciesToOrganizationsBoolExp>
}


export interface SubscriptionRootCurrenciesToOrganizationsByPkArgs {
  currency_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface SubscriptionRootCurrencyArgs {
  distinct_on?: Maybe<Array<CurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyOrderBy>>,
  where?: Maybe<CurrencyBoolExp>
}


export interface SubscriptionRootCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<CurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyOrderBy>>,
  where?: Maybe<CurrencyBoolExp>
}


export interface SubscriptionRootCurrencyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootCurrencyGridViewArgs {
  distinct_on?: Maybe<Array<CurrencyGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyGridViewOrderBy>>,
  where?: Maybe<CurrencyGridViewBoolExp>
}


export interface SubscriptionRootCurrencyGridViewAggregateArgs {
  distinct_on?: Maybe<Array<CurrencyGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<CurrencyGridViewOrderBy>>,
  where?: Maybe<CurrencyGridViewBoolExp>
}


export interface SubscriptionRootDailyAccountRevenueSummaryArgs {
  distinct_on?: Maybe<Array<DailyAccountRevenueSummarySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountRevenueSummaryOrderBy>>,
  where?: Maybe<DailyAccountRevenueSummaryBoolExp>
}


export interface SubscriptionRootDailyAccountRevenueSummaryAggregateArgs {
  distinct_on?: Maybe<Array<DailyAccountRevenueSummarySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountRevenueSummaryOrderBy>>,
  where?: Maybe<DailyAccountRevenueSummaryBoolExp>
}


export interface SubscriptionRootDailyAccountRevenueSummaryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootDailyAccountStatusArgs {
  distinct_on?: Maybe<Array<DailyAccountStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountStatusOrderBy>>,
  where?: Maybe<DailyAccountStatusBoolExp>
}


export interface SubscriptionRootDailyAccountStatusAggregateArgs {
  distinct_on?: Maybe<Array<DailyAccountStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyAccountStatusOrderBy>>,
  where?: Maybe<DailyAccountStatusBoolExp>
}


export interface SubscriptionRootDailyAccountStatusByPkArgs {
  account_id: Scalars['String'],
  organization_id: Scalars['String'],
  record_date: Scalars['date']
}


export interface SubscriptionRootDailyVolumePerAccountArgs {
  distinct_on?: Maybe<Array<DailyVolumePerAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerAccountOrderBy>>,
  where?: Maybe<DailyVolumePerAccountBoolExp>
}


export interface SubscriptionRootDailyVolumePerAccountAggregateArgs {
  distinct_on?: Maybe<Array<DailyVolumePerAccountSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerAccountOrderBy>>,
  where?: Maybe<DailyVolumePerAccountBoolExp>
}


export interface SubscriptionRootDailyVolumePerAccountByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootDailyVolumePerProfileArgs {
  distinct_on?: Maybe<Array<DailyVolumePerProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerProfileOrderBy>>,
  where?: Maybe<DailyVolumePerProfileBoolExp>
}


export interface SubscriptionRootDailyVolumePerProfileAggregateArgs {
  distinct_on?: Maybe<Array<DailyVolumePerProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<DailyVolumePerProfileOrderBy>>,
  where?: Maybe<DailyVolumePerProfileBoolExp>
}


export interface SubscriptionRootDailyVolumePerProfileByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootEmploymentStatusArgs {
  distinct_on?: Maybe<Array<EmploymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<EmploymentStatusOrderBy>>,
  where?: Maybe<EmploymentStatusBoolExp>
}


export interface SubscriptionRootEmploymentStatusAggregateArgs {
  distinct_on?: Maybe<Array<EmploymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<EmploymentStatusOrderBy>>,
  where?: Maybe<EmploymentStatusBoolExp>
}


export interface SubscriptionRootEmploymentStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootExperienceTypeArgs {
  distinct_on?: Maybe<Array<ExperienceTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ExperienceTypeOrderBy>>,
  where?: Maybe<ExperienceTypeBoolExp>
}


export interface SubscriptionRootExperienceTypeAggregateArgs {
  distinct_on?: Maybe<Array<ExperienceTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ExperienceTypeOrderBy>>,
  where?: Maybe<ExperienceTypeBoolExp>
}


export interface SubscriptionRootExperienceTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootFileArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}


export interface SubscriptionRootFileAggregateArgs {
  distinct_on?: Maybe<Array<FileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileOrderBy>>,
  where?: Maybe<FileBoolExp>
}


export interface SubscriptionRootFileByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootFileTypeArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface SubscriptionRootFileTypeAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeOrderBy>>,
  where?: Maybe<FileTypeBoolExp>
}


export interface SubscriptionRootFileTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootFileTypeCategoryArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface SubscriptionRootFileTypeCategoryAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeCategoryOrderBy>>,
  where?: Maybe<FileTypeCategoryBoolExp>
}


export interface SubscriptionRootFileTypeCategoryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootFileTypeGridViewArgs {
  distinct_on?: Maybe<Array<FileTypeGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeGridViewOrderBy>>,
  where?: Maybe<FileTypeGridViewBoolExp>
}


export interface SubscriptionRootFileTypeGridViewAggregateArgs {
  distinct_on?: Maybe<Array<FileTypeGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FileTypeGridViewOrderBy>>,
  where?: Maybe<FileTypeGridViewBoolExp>
}


export interface SubscriptionRootFilesToOrganizationsArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface SubscriptionRootFilesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<FilesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToOrganizationsOrderBy>>,
  where?: Maybe<FilesToOrganizationsBoolExp>
}


export interface SubscriptionRootFilesToOrganizationsByPkArgs {
  file_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface SubscriptionRootFilesToStakeholdersArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface SubscriptionRootFilesToStakeholdersAggregateArgs {
  distinct_on?: Maybe<Array<FilesToStakeholdersSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<FilesToStakeholdersOrderBy>>,
  where?: Maybe<FilesToStakeholdersBoolExp>
}


export interface SubscriptionRootFilesToStakeholdersByPkArgs {
  file_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface SubscriptionRootGenderArgs {
  distinct_on?: Maybe<Array<GenderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<GenderOrderBy>>,
  where?: Maybe<GenderBoolExp>
}


export interface SubscriptionRootGenderAggregateArgs {
  distinct_on?: Maybe<Array<GenderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<GenderOrderBy>>,
  where?: Maybe<GenderBoolExp>
}


export interface SubscriptionRootGenderByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootIdentificationTypeArgs {
  distinct_on?: Maybe<Array<IdentificationTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IdentificationTypeOrderBy>>,
  where?: Maybe<IdentificationTypeBoolExp>
}


export interface SubscriptionRootIdentificationTypeAggregateArgs {
  distinct_on?: Maybe<Array<IdentificationTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IdentificationTypeOrderBy>>,
  where?: Maybe<IdentificationTypeBoolExp>
}


export interface SubscriptionRootIdentificationTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootIndividualPartnerAccountGroupDefaultArgs {
  distinct_on?: Maybe<Array<IndividualPartnerAccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndividualPartnerAccountGroupDefaultOrderBy>>,
  where?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>
}


export interface SubscriptionRootIndividualPartnerAccountGroupDefaultAggregateArgs {
  distinct_on?: Maybe<Array<IndividualPartnerAccountGroupDefaultSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndividualPartnerAccountGroupDefaultOrderBy>>,
  where?: Maybe<IndividualPartnerAccountGroupDefaultBoolExp>
}


export interface SubscriptionRootIndividualPartnerAccountGroupDefaultByPkArgs {
  currency_id: Scalars['String'],
  environment_id: Scalars['String'],
  organization_id: Scalars['String'],
  partner_id: Scalars['String'],
  platform_server_type_id: Scalars['String'],
  profile_category_id: Scalars['String']
}


export interface SubscriptionRootIndustryArgs {
  distinct_on?: Maybe<Array<IndustrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndustryOrderBy>>,
  where?: Maybe<IndustryBoolExp>
}


export interface SubscriptionRootIndustryAggregateArgs {
  distinct_on?: Maybe<Array<IndustrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IndustryOrderBy>>,
  where?: Maybe<IndustryBoolExp>
}


export interface SubscriptionRootIndustryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootIntermediaryBankArgs {
  distinct_on?: Maybe<Array<IntermediaryBankSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IntermediaryBankOrderBy>>,
  where?: Maybe<IntermediaryBankBoolExp>
}


export interface SubscriptionRootIntermediaryBankAggregateArgs {
  distinct_on?: Maybe<Array<IntermediaryBankSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<IntermediaryBankOrderBy>>,
  where?: Maybe<IntermediaryBankBoolExp>
}


export interface SubscriptionRootIntermediaryBankByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootInvestmentObjectiveArgs {
  distinct_on?: Maybe<Array<InvestmentObjectiveSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<InvestmentObjectiveOrderBy>>,
  where?: Maybe<InvestmentObjectiveBoolExp>
}


export interface SubscriptionRootInvestmentObjectiveAggregateArgs {
  distinct_on?: Maybe<Array<InvestmentObjectiveSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<InvestmentObjectiveOrderBy>>,
  where?: Maybe<InvestmentObjectiveBoolExp>
}


export interface SubscriptionRootInvestmentObjectiveByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootLeverageArgs {
  distinct_on?: Maybe<Array<LeverageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageOrderBy>>,
  where?: Maybe<LeverageBoolExp>
}


export interface SubscriptionRootLeverageAggregateArgs {
  distinct_on?: Maybe<Array<LeverageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageOrderBy>>,
  where?: Maybe<LeverageBoolExp>
}


export interface SubscriptionRootLeverageByPkArgs {
  ratio: Scalars['Int']
}


export interface SubscriptionRootLeverageGridViewArgs {
  distinct_on?: Maybe<Array<LeverageGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageGridViewOrderBy>>,
  where?: Maybe<LeverageGridViewBoolExp>
}


export interface SubscriptionRootLeverageGridViewAggregateArgs {
  distinct_on?: Maybe<Array<LeverageGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeverageGridViewOrderBy>>,
  where?: Maybe<LeverageGridViewBoolExp>
}


export interface SubscriptionRootLeveragesToPlatformServerTypesArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface SubscriptionRootLeveragesToPlatformServerTypesAggregateArgs {
  distinct_on?: Maybe<Array<LeveragesToPlatformServerTypesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LeveragesToPlatformServerTypesOrderBy>>,
  where?: Maybe<LeveragesToPlatformServerTypesBoolExp>
}


export interface SubscriptionRootLeveragesToPlatformServerTypesByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootLiquidAssetsArgs {
  distinct_on?: Maybe<Array<LiquidAssetsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LiquidAssetsOrderBy>>,
  where?: Maybe<LiquidAssetsBoolExp>
}


export interface SubscriptionRootLiquidAssetsAggregateArgs {
  distinct_on?: Maybe<Array<LiquidAssetsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LiquidAssetsOrderBy>>,
  where?: Maybe<LiquidAssetsBoolExp>
}


export interface SubscriptionRootLiquidAssetsByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootLocaleArgs {
  distinct_on?: Maybe<Array<LocaleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocaleOrderBy>>,
  where?: Maybe<LocaleBoolExp>
}


export interface SubscriptionRootLocaleAggregateArgs {
  distinct_on?: Maybe<Array<LocaleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocaleOrderBy>>,
  where?: Maybe<LocaleBoolExp>
}


export interface SubscriptionRootLocaleByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootLocalesToOrganizationsArgs {
  distinct_on?: Maybe<Array<LocalesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocalesToOrganizationsOrderBy>>,
  where?: Maybe<LocalesToOrganizationsBoolExp>
}


export interface SubscriptionRootLocalesToOrganizationsAggregateArgs {
  distinct_on?: Maybe<Array<LocalesToOrganizationsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LocalesToOrganizationsOrderBy>>,
  where?: Maybe<LocalesToOrganizationsBoolExp>
}


export interface SubscriptionRootLocalesToOrganizationsByPkArgs {
  locale_id: Scalars['String'],
  organization_id: Scalars['String']
}


export interface SubscriptionRootLookupViewArgs {
  distinct_on?: Maybe<Array<LookupViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LookupViewOrderBy>>,
  where?: Maybe<LookupViewBoolExp>
}


export interface SubscriptionRootLookupViewAggregateArgs {
  distinct_on?: Maybe<Array<LookupViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<LookupViewOrderBy>>,
  where?: Maybe<LookupViewBoolExp>
}


export interface SubscriptionRootMarginTypeArgs {
  distinct_on?: Maybe<Array<MarginTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MarginTypeOrderBy>>,
  where?: Maybe<MarginTypeBoolExp>
}


export interface SubscriptionRootMarginTypeAggregateArgs {
  distinct_on?: Maybe<Array<MarginTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MarginTypeOrderBy>>,
  where?: Maybe<MarginTypeBoolExp>
}


export interface SubscriptionRootMarginTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterAdminClientPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminClientPermissionOrderBy>>,
  where?: Maybe<MasterAdminClientPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminClientPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminClientPermissionOrderBy>>,
  where?: Maybe<MasterAdminClientPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterAdminLeadPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminLeadPermissionOrderBy>>,
  where?: Maybe<MasterAdminLeadPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminLeadPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminLeadPermissionOrderBy>>,
  where?: Maybe<MasterAdminLeadPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterAdminPartnerPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminPartnerPermissionOrderBy>>,
  where?: Maybe<MasterAdminPartnerPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminPartnerPermissionOrderBy>>,
  where?: Maybe<MasterAdminPartnerPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterAdminStakeholderPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterAdminStakeholderPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminStakeholderPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterAdminStakeholderPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterAdminSystemPermissionArgs {
  distinct_on?: Maybe<Array<MasterAdminSystemPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminSystemPermissionOrderBy>>,
  where?: Maybe<MasterAdminSystemPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminSystemPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterAdminSystemPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterAdminSystemPermissionOrderBy>>,
  where?: Maybe<MasterAdminSystemPermissionBoolExp>
}


export interface SubscriptionRootMasterAdminSystemPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterPartnerClientPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerClientPermissionOrderBy>>,
  where?: Maybe<MasterPartnerClientPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerClientPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerClientPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerClientPermissionOrderBy>>,
  where?: Maybe<MasterPartnerClientPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerClientPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterPartnerLeadPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerLeadPermissionOrderBy>>,
  where?: Maybe<MasterPartnerLeadPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerLeadPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerLeadPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerLeadPermissionOrderBy>>,
  where?: Maybe<MasterPartnerLeadPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerLeadPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterPartnerPartnerPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerPartnerPermissionOrderBy>>,
  where?: Maybe<MasterPartnerPartnerPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerPartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerPartnerPermissionOrderBy>>,
  where?: Maybe<MasterPartnerPartnerPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterPartnerStakeholderPermissionArgs {
  distinct_on?: Maybe<Array<MasterPartnerStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterPartnerStakeholderPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerStakeholderPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterPartnerStakeholderPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterPartnerStakeholderPermissionOrderBy>>,
  where?: Maybe<MasterPartnerStakeholderPermissionBoolExp>
}


export interface SubscriptionRootMasterPartnerStakeholderPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootMasterUserPermissionArgs {
  distinct_on?: Maybe<Array<MasterUserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterUserPermissionOrderBy>>,
  where?: Maybe<MasterUserPermissionBoolExp>
}


export interface SubscriptionRootMasterUserPermissionAggregateArgs {
  distinct_on?: Maybe<Array<MasterUserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<MasterUserPermissionOrderBy>>,
  where?: Maybe<MasterUserPermissionBoolExp>
}


export interface SubscriptionRootMasterUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootNameTitleArgs {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NameTitleOrderBy>>,
  where?: Maybe<NameTitleBoolExp>
}


export interface SubscriptionRootNameTitleAggregateArgs {
  distinct_on?: Maybe<Array<NameTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NameTitleOrderBy>>,
  where?: Maybe<NameTitleBoolExp>
}


export interface SubscriptionRootNameTitleByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootNotificationArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface SubscriptionRootNotificationAggregateArgs {
  distinct_on?: Maybe<Array<NotificationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<NotificationOrderBy>>,
  where?: Maybe<NotificationBoolExp>
}


export interface SubscriptionRootNotificationByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootOrganizationArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}


export interface SubscriptionRootOrganizationAggregateArgs {
  distinct_on?: Maybe<Array<OrganizationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OrganizationOrderBy>>,
  where?: Maybe<OrganizationBoolExp>
}


export interface SubscriptionRootOrganizationByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootOriginArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface SubscriptionRootOriginAggregateArgs {
  distinct_on?: Maybe<Array<OriginSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<OriginOrderBy>>,
  where?: Maybe<OriginBoolExp>
}


export interface SubscriptionRootOriginByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPartnerAccountGroupGridViewArgs {
  distinct_on?: Maybe<Array<PartnerAccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerAccountGroupGridViewOrderBy>>,
  where?: Maybe<PartnerAccountGroupGridViewBoolExp>
}


export interface SubscriptionRootPartnerAccountGroupGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PartnerAccountGroupGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerAccountGroupGridViewOrderBy>>,
  where?: Maybe<PartnerAccountGroupGridViewBoolExp>
}


export interface SubscriptionRootPartnerPermissionArgs {
  distinct_on?: Maybe<Array<PartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerPermissionOrderBy>>,
  where?: Maybe<PartnerPermissionBoolExp>
}


export interface SubscriptionRootPartnerPermissionAggregateArgs {
  distinct_on?: Maybe<Array<PartnerPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PartnerPermissionOrderBy>>,
  where?: Maybe<PartnerPermissionBoolExp>
}


export interface SubscriptionRootPartnerPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface SubscriptionRootPaymentAggregateArgs {
  distinct_on?: Maybe<Array<PaymentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrderBy>>,
  where?: Maybe<PaymentBoolExp>
}


export interface SubscriptionRootPaymentAttributeGlobalIdentifierArgs {
  distinct_on?: Maybe<Array<PaymentAttributeGlobalIdentifierSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeGlobalIdentifierOrderBy>>,
  where?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>
}


export interface SubscriptionRootPaymentAttributeGlobalIdentifierAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeGlobalIdentifierSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeGlobalIdentifierOrderBy>>,
  where?: Maybe<PaymentAttributeGlobalIdentifierBoolExp>
}


export interface SubscriptionRootPaymentAttributeGlobalIdentifierByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeKeyOrderBy>>,
  where?: Maybe<PaymentAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSectionOrderBy>>,
  where?: Maybe<PaymentAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSectionOrderBy>>,
  where?: Maybe<PaymentAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentAttributeValueArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}


export interface SubscriptionRootPaymentAttributeValueAggregateArgs {
  distinct_on?: Maybe<Array<PaymentAttributeValueSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentAttributeValueOrderBy>>,
  where?: Maybe<PaymentAttributeValueBoolExp>
}


export interface SubscriptionRootPaymentAttributeValueByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface SubscriptionRootPaymentMethodAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodOrderBy>>,
  where?: Maybe<PaymentMethodBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeOrderBy>>,
  where?: Maybe<PaymentMethodAttributeBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentMethodAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodGridViewArgs {
  distinct_on?: Maybe<Array<PaymentMethodGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodGridViewOrderBy>>,
  where?: Maybe<PaymentMethodGridViewBoolExp>
}


export interface SubscriptionRootPaymentMethodGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodGridViewOrderBy>>,
  where?: Maybe<PaymentMethodGridViewBoolExp>
}


export interface SubscriptionRootPaymentMethodLogoArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface SubscriptionRootPaymentMethodLogoAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodLogoSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodLogoOrderBy>>,
  where?: Maybe<PaymentMethodLogoBoolExp>
}


export interface SubscriptionRootPaymentMethodLogoByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeOrderBy>>,
  where?: Maybe<PaymentMethodTypeBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeAttributeKeyArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeKeyOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeKeyBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeAttributeSectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSectionBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeAttributeSubsectionOrderBy>>,
  where?: Maybe<PaymentMethodTypeAttributeSubsectionBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentMethodTypeCurrencyArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<PaymentMethodTypeCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentMethodTypeCurrencyOrderBy>>,
  where?: Maybe<PaymentMethodTypeCurrencyBoolExp>
}


export interface SubscriptionRootPaymentMethodTypeCurrencyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentOrientationArgs {
  distinct_on?: Maybe<Array<PaymentOrientationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrientationOrderBy>>,
  where?: Maybe<PaymentOrientationBoolExp>
}


export interface SubscriptionRootPaymentOrientationAggregateArgs {
  distinct_on?: Maybe<Array<PaymentOrientationSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentOrientationOrderBy>>,
  where?: Maybe<PaymentOrientationBoolExp>
}


export interface SubscriptionRootPaymentOrientationByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentProviderArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface SubscriptionRootPaymentProviderAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderOrderBy>>,
  where?: Maybe<PaymentProviderBoolExp>
}


export interface SubscriptionRootPaymentProviderByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentProviderIntegrationModeArgs {
  distinct_on?: Maybe<Array<PaymentProviderIntegrationModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderIntegrationModeOrderBy>>,
  where?: Maybe<PaymentProviderIntegrationModeBoolExp>
}


export interface SubscriptionRootPaymentProviderIntegrationModeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderIntegrationModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderIntegrationModeOrderBy>>,
  where?: Maybe<PaymentProviderIntegrationModeBoolExp>
}


export interface SubscriptionRootPaymentProviderIntegrationModeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentProviderMethodTypeArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface SubscriptionRootPaymentProviderMethodTypeAggregateArgs {
  distinct_on?: Maybe<Array<PaymentProviderMethodTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentProviderMethodTypeOrderBy>>,
  where?: Maybe<PaymentProviderMethodTypeBoolExp>
}


export interface SubscriptionRootPaymentProviderMethodTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPaymentStatusArgs {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentStatusOrderBy>>,
  where?: Maybe<PaymentStatusBoolExp>
}


export interface SubscriptionRootPaymentStatusAggregateArgs {
  distinct_on?: Maybe<Array<PaymentStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PaymentStatusOrderBy>>,
  where?: Maybe<PaymentStatusBoolExp>
}


export interface SubscriptionRootPaymentStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPhoneCodeArgs {
  distinct_on?: Maybe<Array<PhoneCodeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneCodeOrderBy>>,
  where?: Maybe<PhoneCodeBoolExp>
}


export interface SubscriptionRootPhoneCodeAggregateArgs {
  distinct_on?: Maybe<Array<PhoneCodeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneCodeOrderBy>>,
  where?: Maybe<PhoneCodeBoolExp>
}


export interface SubscriptionRootPhoneCodeByPkArgs {
  code: Scalars['String']
}


export interface SubscriptionRootPhoneTypeArgs {
  distinct_on?: Maybe<Array<PhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneTypeOrderBy>>,
  where?: Maybe<PhoneTypeBoolExp>
}


export interface SubscriptionRootPhoneTypeAggregateArgs {
  distinct_on?: Maybe<Array<PhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PhoneTypeOrderBy>>,
  where?: Maybe<PhoneTypeBoolExp>
}


export interface SubscriptionRootPhoneTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformAccessArgs {
  distinct_on?: Maybe<Array<PlatformAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformAccessOrderBy>>,
  where?: Maybe<PlatformAccessBoolExp>
}


export interface SubscriptionRootPlatformAccessAggregateArgs {
  distinct_on?: Maybe<Array<PlatformAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformAccessOrderBy>>,
  where?: Maybe<PlatformAccessBoolExp>
}


export interface SubscriptionRootPlatformAccessByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServerArgs {
  distinct_on?: Maybe<Array<PlatformServerSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerOrderBy>>,
  where?: Maybe<PlatformServerBoolExp>
}


export interface SubscriptionRootPlatformServerAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerOrderBy>>,
  where?: Maybe<PlatformServerBoolExp>
}


export interface SubscriptionRootPlatformServerByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServerEnvironmentArgs {
  distinct_on?: Maybe<Array<PlatformServerEnvironmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerEnvironmentOrderBy>>,
  where?: Maybe<PlatformServerEnvironmentBoolExp>
}


export interface SubscriptionRootPlatformServerEnvironmentAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerEnvironmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerEnvironmentOrderBy>>,
  where?: Maybe<PlatformServerEnvironmentBoolExp>
}


export interface SubscriptionRootPlatformServerEnvironmentByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServerGridViewArgs {
  distinct_on?: Maybe<Array<PlatformServerGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerGridViewOrderBy>>,
  where?: Maybe<PlatformServerGridViewBoolExp>
}


export interface SubscriptionRootPlatformServerGridViewAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerGridViewOrderBy>>,
  where?: Maybe<PlatformServerGridViewBoolExp>
}


export interface SubscriptionRootPlatformServerRetryStatusArgs {
  distinct_on?: Maybe<Array<PlatformServerRetryStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerRetryStatusOrderBy>>,
  where?: Maybe<PlatformServerRetryStatusBoolExp>
}


export interface SubscriptionRootPlatformServerRetryStatusAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerRetryStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerRetryStatusOrderBy>>,
  where?: Maybe<PlatformServerRetryStatusBoolExp>
}


export interface SubscriptionRootPlatformServerRetryStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServerStatusArgs {
  distinct_on?: Maybe<Array<PlatformServerStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerStatusOrderBy>>,
  where?: Maybe<PlatformServerStatusBoolExp>
}


export interface SubscriptionRootPlatformServerStatusAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerStatusOrderBy>>,
  where?: Maybe<PlatformServerStatusBoolExp>
}


export interface SubscriptionRootPlatformServerStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServerTypeArgs {
  distinct_on?: Maybe<Array<PlatformServerTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerTypeOrderBy>>,
  where?: Maybe<PlatformServerTypeBoolExp>
}


export interface SubscriptionRootPlatformServerTypeAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServerTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServerTypeOrderBy>>,
  where?: Maybe<PlatformServerTypeBoolExp>
}


export interface SubscriptionRootPlatformServerTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootPlatformServersToSupportedLeveragesArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface SubscriptionRootPlatformServersToSupportedLeveragesAggregateArgs {
  distinct_on?: Maybe<Array<PlatformServersToSupportedLeveragesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformServersToSupportedLeveragesOrderBy>>,
  where?: Maybe<PlatformServersToSupportedLeveragesBoolExp>
}


export interface SubscriptionRootPlatformServersToSupportedLeveragesByPkArgs {
  platform_server_id: Scalars['String'],
  supported_leverage_id: Scalars['String']
}


export interface SubscriptionRootPlatformSymbolArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface SubscriptionRootPlatformSymbolAggregateArgs {
  distinct_on?: Maybe<Array<PlatformSymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<PlatformSymbolOrderBy>>,
  where?: Maybe<PlatformSymbolBoolExp>
}


export interface SubscriptionRootPlatformSymbolByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface SubscriptionRootProfileActivityArgs {
  distinct_on?: Maybe<Array<ProfileActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileActivityOrderBy>>,
  where?: Maybe<ProfileActivityBoolExp>
}


export interface SubscriptionRootProfileActivityAggregateArgs {
  distinct_on?: Maybe<Array<ProfileActivitySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileActivityOrderBy>>,
  where?: Maybe<ProfileActivityBoolExp>
}


export interface SubscriptionRootProfileActivityByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileAggregateArgs {
  distinct_on?: Maybe<Array<ProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileOrderBy>>,
  where?: Maybe<ProfileBoolExp>
}


export interface SubscriptionRootProfileByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileCategoryArgs {
  distinct_on?: Maybe<Array<ProfileCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileCategoryOrderBy>>,
  where?: Maybe<ProfileCategoryBoolExp>
}


export interface SubscriptionRootProfileCategoryAggregateArgs {
  distinct_on?: Maybe<Array<ProfileCategorySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileCategoryOrderBy>>,
  where?: Maybe<ProfileCategoryBoolExp>
}


export interface SubscriptionRootProfileCategoryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileEntryTypeArgs {
  distinct_on?: Maybe<Array<ProfileEntryTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileEntryTypeOrderBy>>,
  where?: Maybe<ProfileEntryTypeBoolExp>
}


export interface SubscriptionRootProfileEntryTypeAggregateArgs {
  distinct_on?: Maybe<Array<ProfileEntryTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileEntryTypeOrderBy>>,
  where?: Maybe<ProfileEntryTypeBoolExp>
}


export interface SubscriptionRootProfileEntryTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileFlagsArgs {
  distinct_on?: Maybe<Array<ProfileFlagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileFlagsOrderBy>>,
  where?: Maybe<ProfileFlagsBoolExp>
}


export interface SubscriptionRootProfileFlagsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileFlagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileFlagsOrderBy>>,
  where?: Maybe<ProfileFlagsBoolExp>
}


export interface SubscriptionRootProfileFlagsByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileGridViewArgs {
  distinct_on?: Maybe<Array<ProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileGridViewOrderBy>>,
  where?: Maybe<ProfileGridViewBoolExp>
}


export interface SubscriptionRootProfileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<ProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileGridViewOrderBy>>,
  where?: Maybe<ProfileGridViewBoolExp>
}


export interface SubscriptionRootProfileNoteArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface SubscriptionRootProfileNoteAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteOrderBy>>,
  where?: Maybe<ProfileNoteBoolExp>
}


export interface SubscriptionRootProfileNoteByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileNoteVersionArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}


export interface SubscriptionRootProfileNoteVersionAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionOrderBy>>,
  where?: Maybe<ProfileNoteVersionBoolExp>
}


export interface SubscriptionRootProfileNoteVersionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileNoteVersionsStakeholderMentionsArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface SubscriptionRootProfileNoteVersionsStakeholderMentionsAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderMentionsOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderMentionsBoolExp>
}


export interface SubscriptionRootProfileNoteVersionsStakeholderMentionsByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface SubscriptionRootProfileNoteVersionsStakeholderRelevantPartiesArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}


export interface SubscriptionRootProfileNoteVersionsStakeholderRelevantPartiesAggregateArgs {
  distinct_on?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileNoteVersionsStakeholderRelevantPartiesOrderBy>>,
  where?: Maybe<ProfileNoteVersionsStakeholderRelevantPartiesBoolExp>
}


export interface SubscriptionRootProfileNoteVersionsStakeholderRelevantPartiesByPkArgs {
  profile_note_version_id: Scalars['String'],
  stakeholder_id: Scalars['String']
}


export interface SubscriptionRootProfileRiskArgs {
  distinct_on?: Maybe<Array<ProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileRiskOrderBy>>,
  where?: Maybe<ProfileRiskBoolExp>
}


export interface SubscriptionRootProfileRiskAggregateArgs {
  distinct_on?: Maybe<Array<ProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileRiskOrderBy>>,
  where?: Maybe<ProfileRiskBoolExp>
}


export interface SubscriptionRootProfileRiskByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileStatusArgs {
  distinct_on?: Maybe<Array<ProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileStatusOrderBy>>,
  where?: Maybe<ProfileStatusBoolExp>
}


export interface SubscriptionRootProfileStatusAggregateArgs {
  distinct_on?: Maybe<Array<ProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileStatusOrderBy>>,
  where?: Maybe<ProfileStatusBoolExp>
}


export interface SubscriptionRootProfileStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfileTypeArgs {
  distinct_on?: Maybe<Array<ProfileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileTypeOrderBy>>,
  where?: Maybe<ProfileTypeBoolExp>
}


export interface SubscriptionRootProfileTypeAggregateArgs {
  distinct_on?: Maybe<Array<ProfileTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfileTypeOrderBy>>,
  where?: Maybe<ProfileTypeBoolExp>
}


export interface SubscriptionRootProfileTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootProfilesToTagsArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface SubscriptionRootProfilesToTagsAggregateArgs {
  distinct_on?: Maybe<Array<ProfilesToTagsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<ProfilesToTagsOrderBy>>,
  where?: Maybe<ProfilesToTagsBoolExp>
}


export interface SubscriptionRootProfilesToTagsByPkArgs {
  profile_id: Scalars['String'],
  tag_id: Scalars['String']
}


export interface SubscriptionRootRandomCountryArgs {
  distinct_on?: Maybe<Array<RandomCountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCountryOrderBy>>,
  where?: Maybe<RandomCountryBoolExp>
}


export interface SubscriptionRootRandomCountryAggregateArgs {
  distinct_on?: Maybe<Array<RandomCountrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCountryOrderBy>>,
  where?: Maybe<RandomCountryBoolExp>
}


export interface SubscriptionRootRandomCurrencyArgs {
  distinct_on?: Maybe<Array<RandomCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCurrencyOrderBy>>,
  where?: Maybe<RandomCurrencyBoolExp>
}


export interface SubscriptionRootRandomCurrencyAggregateArgs {
  distinct_on?: Maybe<Array<RandomCurrencySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomCurrencyOrderBy>>,
  where?: Maybe<RandomCurrencyBoolExp>
}


export interface SubscriptionRootRandomPhoneTypeArgs {
  distinct_on?: Maybe<Array<RandomPhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomPhoneTypeOrderBy>>,
  where?: Maybe<RandomPhoneTypeBoolExp>
}


export interface SubscriptionRootRandomPhoneTypeAggregateArgs {
  distinct_on?: Maybe<Array<RandomPhoneTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomPhoneTypeOrderBy>>,
  where?: Maybe<RandomPhoneTypeBoolExp>
}


export interface SubscriptionRootRandomProfileArgs {
  distinct_on?: Maybe<Array<RandomProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileOrderBy>>,
  where?: Maybe<RandomProfileBoolExp>
}


export interface SubscriptionRootRandomProfileAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileOrderBy>>,
  where?: Maybe<RandomProfileBoolExp>
}


export interface SubscriptionRootRandomProfileRiskArgs {
  distinct_on?: Maybe<Array<RandomProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileRiskOrderBy>>,
  where?: Maybe<RandomProfileRiskBoolExp>
}


export interface SubscriptionRootRandomProfileRiskAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileRiskSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileRiskOrderBy>>,
  where?: Maybe<RandomProfileRiskBoolExp>
}


export interface SubscriptionRootRandomProfileStatusArgs {
  distinct_on?: Maybe<Array<RandomProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileStatusOrderBy>>,
  where?: Maybe<RandomProfileStatusBoolExp>
}


export interface SubscriptionRootRandomProfileStatusAggregateArgs {
  distinct_on?: Maybe<Array<RandomProfileStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomProfileStatusOrderBy>>,
  where?: Maybe<RandomProfileStatusBoolExp>
}


export interface SubscriptionRootRandomTagArgs {
  distinct_on?: Maybe<Array<RandomTagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomTagOrderBy>>,
  where?: Maybe<RandomTagBoolExp>
}


export interface SubscriptionRootRandomTagAggregateArgs {
  distinct_on?: Maybe<Array<RandomTagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RandomTagOrderBy>>,
  where?: Maybe<RandomTagBoolExp>
}


export interface SubscriptionRootRepresentativesToProfilesArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface SubscriptionRootRepresentativesToProfilesAggregateArgs {
  distinct_on?: Maybe<Array<RepresentativesToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RepresentativesToProfilesOrderBy>>,
  where?: Maybe<RepresentativesToProfilesBoolExp>
}


export interface SubscriptionRootRepresentativesToProfilesByPkArgs {
  admin_stake_id: Scalars['String'],
  profile_id: Scalars['String']
}


export interface SubscriptionRootRequestArgs {
  distinct_on?: Maybe<Array<RequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestOrderBy>>,
  where?: Maybe<RequestBoolExp>
}


export interface SubscriptionRootRequestAggregateArgs {
  distinct_on?: Maybe<Array<RequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestOrderBy>>,
  where?: Maybe<RequestBoolExp>
}


export interface SubscriptionRootRequestByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootRequestGridViewArgs {
  distinct_on?: Maybe<Array<RequestGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestGridViewOrderBy>>,
  where?: Maybe<RequestGridViewBoolExp>
}


export interface SubscriptionRootRequestGridViewAggregateArgs {
  distinct_on?: Maybe<Array<RequestGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestGridViewOrderBy>>,
  where?: Maybe<RequestGridViewBoolExp>
}


export interface SubscriptionRootRequestStatusArgs {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestStatusOrderBy>>,
  where?: Maybe<RequestStatusBoolExp>
}


export interface SubscriptionRootRequestStatusAggregateArgs {
  distinct_on?: Maybe<Array<RequestStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestStatusOrderBy>>,
  where?: Maybe<RequestStatusBoolExp>
}


export interface SubscriptionRootRequestStatusByPkArgs {
  id: Scalars['Int']
}


export interface SubscriptionRootRequestTypeArgs {
  distinct_on?: Maybe<Array<RequestTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestTypeOrderBy>>,
  where?: Maybe<RequestTypeBoolExp>
}


export interface SubscriptionRootRequestTypeAggregateArgs {
  distinct_on?: Maybe<Array<RequestTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<RequestTypeOrderBy>>,
  where?: Maybe<RequestTypeBoolExp>
}


export interface SubscriptionRootRequestTypeByPkArgs {
  id: Scalars['Int']
}


export interface SubscriptionRootSecurityArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface SubscriptionRootSecurityAggregateArgs {
  distinct_on?: Maybe<Array<SecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityOrderBy>>,
  where?: Maybe<SecurityBoolExp>
}


export interface SubscriptionRootSecurityByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSecurityGridViewArgs {
  distinct_on?: Maybe<Array<SecurityGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityGridViewOrderBy>>,
  where?: Maybe<SecurityGridViewBoolExp>
}


export interface SubscriptionRootSecurityGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SecurityGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SecurityGridViewOrderBy>>,
  where?: Maybe<SecurityGridViewBoolExp>
}


export interface SubscriptionRootSegmentArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface SubscriptionRootSegmentAggregateArgs {
  distinct_on?: Maybe<Array<SegmentSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOrderBy>>,
  where?: Maybe<SegmentBoolExp>
}


export interface SubscriptionRootSegmentByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSegmentConditionArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}


export interface SubscriptionRootSegmentConditionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentConditionOrderBy>>,
  where?: Maybe<SegmentConditionBoolExp>
}


export interface SubscriptionRootSegmentConditionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSegmentCriterionArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SubscriptionRootSegmentCriterionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionOrderBy>>,
  where?: Maybe<SegmentCriterionBoolExp>
}


export interface SubscriptionRootSegmentCriterionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSegmentCriterionTypeArgs {
  distinct_on?: Maybe<Array<SegmentCriterionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionTypeOrderBy>>,
  where?: Maybe<SegmentCriterionTypeBoolExp>
}


export interface SubscriptionRootSegmentCriterionTypeAggregateArgs {
  distinct_on?: Maybe<Array<SegmentCriterionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentCriterionTypeOrderBy>>,
  where?: Maybe<SegmentCriterionTypeBoolExp>
}


export interface SubscriptionRootSegmentCriterionTypeByPkArgs {
  name: Scalars['String']
}


export interface SubscriptionRootSegmentGridViewArgs {
  distinct_on?: Maybe<Array<SegmentGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentGridViewOrderBy>>,
  where?: Maybe<SegmentGridViewBoolExp>
}


export interface SubscriptionRootSegmentGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SegmentGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentGridViewOrderBy>>,
  where?: Maybe<SegmentGridViewBoolExp>
}


export interface SubscriptionRootSegmentOperatorArgs {
  distinct_on?: Maybe<Array<SegmentOperatorSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorOrderBy>>,
  where?: Maybe<SegmentOperatorBoolExp>
}


export interface SubscriptionRootSegmentOperatorAggregateArgs {
  distinct_on?: Maybe<Array<SegmentOperatorSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorOrderBy>>,
  where?: Maybe<SegmentOperatorBoolExp>
}


export interface SubscriptionRootSegmentOperatorByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSegmentOperatorsToSegmentPropertyConditionsArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}


export interface SubscriptionRootSegmentOperatorsToSegmentPropertyConditionsAggregateArgs {
  distinct_on?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentOperatorsToSegmentPropertyConditionsOrderBy>>,
  where?: Maybe<SegmentOperatorsToSegmentPropertyConditionsBoolExp>
}


export interface SubscriptionRootSegmentOperatorsToSegmentPropertyConditionsByPkArgs {
  operator_id: Scalars['String'],
  property_condition_id: Scalars['String']
}


export interface SubscriptionRootSegmentPropertyArgs {
  distinct_on?: Maybe<Array<SegmentPropertySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyOrderBy>>,
  where?: Maybe<SegmentPropertyBoolExp>
}


export interface SubscriptionRootSegmentPropertyAggregateArgs {
  distinct_on?: Maybe<Array<SegmentPropertySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyOrderBy>>,
  where?: Maybe<SegmentPropertyBoolExp>
}


export interface SubscriptionRootSegmentPropertyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSegmentPropertyConditionArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}


export interface SubscriptionRootSegmentPropertyConditionAggregateArgs {
  distinct_on?: Maybe<Array<SegmentPropertyConditionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SegmentPropertyConditionOrderBy>>,
  where?: Maybe<SegmentPropertyConditionBoolExp>
}


export interface SubscriptionRootSegmentPropertyConditionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSharedFileGridViewArgs {
  distinct_on?: Maybe<Array<SharedFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SharedFileGridViewOrderBy>>,
  where?: Maybe<SharedFileGridViewBoolExp>
}


export interface SubscriptionRootSharedFileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SharedFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SharedFileGridViewOrderBy>>,
  where?: Maybe<SharedFileGridViewBoolExp>
}


export interface SubscriptionRootSourceOfFundsArgs {
  distinct_on?: Maybe<Array<SourceOfFundsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SourceOfFundsOrderBy>>,
  where?: Maybe<SourceOfFundsBoolExp>
}


export interface SubscriptionRootSourceOfFundsAggregateArgs {
  distinct_on?: Maybe<Array<SourceOfFundsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SourceOfFundsOrderBy>>,
  where?: Maybe<SourceOfFundsBoolExp>
}


export interface SubscriptionRootSourceOfFundsByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSpreadModeArgs {
  distinct_on?: Maybe<Array<SpreadModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SpreadModeOrderBy>>,
  where?: Maybe<SpreadModeBoolExp>
}


export interface SubscriptionRootSpreadModeAggregateArgs {
  distinct_on?: Maybe<Array<SpreadModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SpreadModeOrderBy>>,
  where?: Maybe<SpreadModeBoolExp>
}


export interface SubscriptionRootSpreadModeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface SubscriptionRootStakeAggregateArgs {
  distinct_on?: Maybe<Array<StakeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeOrderBy>>,
  where?: Maybe<StakeBoolExp>
}


export interface SubscriptionRootStakeAncestryArgs {
  distinct_on?: Maybe<Array<StakeAncestrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeAncestryOrderBy>>,
  where?: Maybe<StakeAncestryBoolExp>
}


export interface SubscriptionRootStakeAncestryAggregateArgs {
  distinct_on?: Maybe<Array<StakeAncestrySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeAncestryOrderBy>>,
  where?: Maybe<StakeAncestryBoolExp>
}


export interface SubscriptionRootStakeAncestryByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeTitleArgs {
  distinct_on?: Maybe<Array<StakeTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeTitleOrderBy>>,
  where?: Maybe<StakeTitleBoolExp>
}


export interface SubscriptionRootStakeTitleAggregateArgs {
  distinct_on?: Maybe<Array<StakeTitleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeTitleOrderBy>>,
  where?: Maybe<StakeTitleBoolExp>
}


export interface SubscriptionRootStakeTitleByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface SubscriptionRootStakeholderAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderOrderBy>>,
  where?: Maybe<StakeholderBoolExp>
}


export interface SubscriptionRootStakeholderAttributeArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface SubscriptionRootStakeholderAttributeAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeOrderBy>>,
  where?: Maybe<StakeholderAttributeBoolExp>
}


export interface SubscriptionRootStakeholderAttributeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderAttributeKeyArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface SubscriptionRootStakeholderAttributeKeyAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeyOrderBy>>,
  where?: Maybe<StakeholderAttributeKeyBoolExp>
}


export interface SubscriptionRootStakeholderAttributeKeyByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderAttributeKeysToStakeholderClassesArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface SubscriptionRootStakeholderAttributeKeysToStakeholderClassesAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeKeysToStakeholderClassesOrderBy>>,
  where?: Maybe<StakeholderAttributeKeysToStakeholderClassesBoolExp>
}


export interface SubscriptionRootStakeholderAttributeKeysToStakeholderClassesByPkArgs {
  stakeholder_attribute_key_id: Scalars['String'],
  stakeholder_class_id: Scalars['String']
}


export interface SubscriptionRootStakeholderAttributePivotArgs {
  distinct_on?: Maybe<Array<StakeholderAttributePivotSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributePivotOrderBy>>,
  where?: Maybe<StakeholderAttributePivotBoolExp>
}


export interface SubscriptionRootStakeholderAttributePivotAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributePivotSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributePivotOrderBy>>,
  where?: Maybe<StakeholderAttributePivotBoolExp>
}


export interface SubscriptionRootStakeholderAttributeSectionArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSectionBoolExp>
}


export interface SubscriptionRootStakeholderAttributeSectionAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSectionBoolExp>
}


export interface SubscriptionRootStakeholderAttributeSectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderAttributeSubsectionArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}


export interface SubscriptionRootStakeholderAttributeSubsectionAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderAttributeSubsectionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderAttributeSubsectionOrderBy>>,
  where?: Maybe<StakeholderAttributeSubsectionBoolExp>
}


export interface SubscriptionRootStakeholderAttributeSubsectionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderClassArgs {
  distinct_on?: Maybe<Array<StakeholderClassSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderClassOrderBy>>,
  where?: Maybe<StakeholderClassBoolExp>
}


export interface SubscriptionRootStakeholderClassAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderClassSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderClassOrderBy>>,
  where?: Maybe<StakeholderClassBoolExp>
}


export interface SubscriptionRootStakeholderClassByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakeholderFileGridViewArgs {
  distinct_on?: Maybe<Array<StakeholderFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderFileGridViewOrderBy>>,
  where?: Maybe<StakeholderFileGridViewBoolExp>
}


export interface SubscriptionRootStakeholderFileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderFileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderFileGridViewOrderBy>>,
  where?: Maybe<StakeholderFileGridViewBoolExp>
}


export interface SubscriptionRootStakeholderNameArgs {
  distinct_on?: Maybe<Array<StakeholderNameSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderNameOrderBy>>,
  where?: Maybe<StakeholderNameBoolExp>
}


export interface SubscriptionRootStakeholderNameAggregateArgs {
  distinct_on?: Maybe<Array<StakeholderNameSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakeholderNameOrderBy>>,
  where?: Maybe<StakeholderNameBoolExp>
}


export interface SubscriptionRootStakesToAdminPermissionsArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface SubscriptionRootStakesToAdminPermissionsAggregateArgs {
  distinct_on?: Maybe<Array<StakesToAdminPermissionsSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToAdminPermissionsOrderBy>>,
  where?: Maybe<StakesToAdminPermissionsBoolExp>
}


export interface SubscriptionRootStakesToAdminPermissionsByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootStakesToStakeTitlesArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}


export interface SubscriptionRootStakesToStakeTitlesAggregateArgs {
  distinct_on?: Maybe<Array<StakesToStakeTitlesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<StakesToStakeTitlesOrderBy>>,
  where?: Maybe<StakesToStakeTitlesBoolExp>
}


export interface SubscriptionRootStakesToStakeTitlesByPkArgs {
  stake_id: Scalars['String'],
  stake_title_id: Scalars['String']
}


export interface SubscriptionRootSwapModeArgs {
  distinct_on?: Maybe<Array<SwapModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SwapModeOrderBy>>,
  where?: Maybe<SwapModeBoolExp>
}


export interface SubscriptionRootSwapModeAggregateArgs {
  distinct_on?: Maybe<Array<SwapModeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SwapModeOrderBy>>,
  where?: Maybe<SwapModeBoolExp>
}


export interface SubscriptionRootSwapModeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSymbolArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface SubscriptionRootSymbolAggregateArgs {
  distinct_on?: Maybe<Array<SymbolSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolOrderBy>>,
  where?: Maybe<SymbolBoolExp>
}


export interface SubscriptionRootSymbolByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSymbolExtensionArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface SubscriptionRootSymbolExtensionAggregateArgs {
  distinct_on?: Maybe<Array<SymbolExtensionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolExtensionOrderBy>>,
  where?: Maybe<SymbolExtensionBoolExp>
}


export interface SubscriptionRootSymbolExtensionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootSymbolGridViewArgs {
  distinct_on?: Maybe<Array<SymbolGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolGridViewOrderBy>>,
  where?: Maybe<SymbolGridViewBoolExp>
}


export interface SubscriptionRootSymbolGridViewAggregateArgs {
  distinct_on?: Maybe<Array<SymbolGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolGridViewOrderBy>>,
  where?: Maybe<SymbolGridViewBoolExp>
}


export interface SubscriptionRootSymbolTypeArgs {
  distinct_on?: Maybe<Array<SymbolTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolTypeOrderBy>>,
  where?: Maybe<SymbolTypeBoolExp>
}


export interface SubscriptionRootSymbolTypeAggregateArgs {
  distinct_on?: Maybe<Array<SymbolTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<SymbolTypeOrderBy>>,
  where?: Maybe<SymbolTypeBoolExp>
}


export interface SubscriptionRootSymbolTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTagArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface SubscriptionRootTagAggregateArgs {
  distinct_on?: Maybe<Array<TagSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TagOrderBy>>,
  where?: Maybe<TagBoolExp>
}


export interface SubscriptionRootTagByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTradeRuleArgs {
  distinct_on?: Maybe<Array<TradeRuleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TradeRuleOrderBy>>,
  where?: Maybe<TradeRuleBoolExp>
}


export interface SubscriptionRootTradeRuleAggregateArgs {
  distinct_on?: Maybe<Array<TradeRuleSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TradeRuleOrderBy>>,
  where?: Maybe<TradeRuleBoolExp>
}


export interface SubscriptionRootTradeRuleByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransactionArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface SubscriptionRootTransactionAggregateArgs {
  distinct_on?: Maybe<Array<TransactionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionOrderBy>>,
  where?: Maybe<TransactionBoolExp>
}


export interface SubscriptionRootTransactionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransactionCreationRequestArgs {
  distinct_on?: Maybe<Array<TransactionCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionCreationRequestOrderBy>>,
  where?: Maybe<TransactionCreationRequestBoolExp>
}


export interface SubscriptionRootTransactionCreationRequestAggregateArgs {
  distinct_on?: Maybe<Array<TransactionCreationRequestSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionCreationRequestOrderBy>>,
  where?: Maybe<TransactionCreationRequestBoolExp>
}


export interface SubscriptionRootTransactionCreationRequestByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransactionGridViewArgs {
  distinct_on?: Maybe<Array<TransactionGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionGridViewOrderBy>>,
  where?: Maybe<TransactionGridViewBoolExp>
}


export interface SubscriptionRootTransactionGridViewAggregateArgs {
  distinct_on?: Maybe<Array<TransactionGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionGridViewOrderBy>>,
  where?: Maybe<TransactionGridViewBoolExp>
}


export interface SubscriptionRootTransactionPlatformStatusArgs {
  distinct_on?: Maybe<Array<TransactionPlatformStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionPlatformStatusOrderBy>>,
  where?: Maybe<TransactionPlatformStatusBoolExp>
}


export interface SubscriptionRootTransactionPlatformStatusAggregateArgs {
  distinct_on?: Maybe<Array<TransactionPlatformStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionPlatformStatusOrderBy>>,
  where?: Maybe<TransactionPlatformStatusBoolExp>
}


export interface SubscriptionRootTransactionPlatformStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransactionProfileGridViewArgs {
  distinct_on?: Maybe<Array<TransactionProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionProfileGridViewOrderBy>>,
  where?: Maybe<TransactionProfileGridViewBoolExp>
}


export interface SubscriptionRootTransactionProfileGridViewAggregateArgs {
  distinct_on?: Maybe<Array<TransactionProfileGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionProfileGridViewOrderBy>>,
  where?: Maybe<TransactionProfileGridViewBoolExp>
}


export interface SubscriptionRootTransactionStatusArgs {
  distinct_on?: Maybe<Array<TransactionStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionStatusOrderBy>>,
  where?: Maybe<TransactionStatusBoolExp>
}


export interface SubscriptionRootTransactionStatusAggregateArgs {
  distinct_on?: Maybe<Array<TransactionStatusSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionStatusOrderBy>>,
  where?: Maybe<TransactionStatusBoolExp>
}


export interface SubscriptionRootTransactionStatusByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransactionTypeArgs {
  distinct_on?: Maybe<Array<TransactionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionTypeOrderBy>>,
  where?: Maybe<TransactionTypeBoolExp>
}


export interface SubscriptionRootTransactionTypeAggregateArgs {
  distinct_on?: Maybe<Array<TransactionTypeSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransactionTypeOrderBy>>,
  where?: Maybe<TransactionTypeBoolExp>
}


export interface SubscriptionRootTransactionTypeByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootTransferApprovalOptionArgs {
  distinct_on?: Maybe<Array<TransferApprovalOptionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransferApprovalOptionOrderBy>>,
  where?: Maybe<TransferApprovalOptionBoolExp>
}


export interface SubscriptionRootTransferApprovalOptionAggregateArgs {
  distinct_on?: Maybe<Array<TransferApprovalOptionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<TransferApprovalOptionOrderBy>>,
  where?: Maybe<TransferApprovalOptionBoolExp>
}


export interface SubscriptionRootTransferApprovalOptionByPkArgs {
  id: Scalars['Int']
}


export interface SubscriptionRootUserAccessArgs {
  distinct_on?: Maybe<Array<UserAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserAccessOrderBy>>,
  where?: Maybe<UserAccessBoolExp>
}


export interface SubscriptionRootUserAccessAggregateArgs {
  distinct_on?: Maybe<Array<UserAccessSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserAccessOrderBy>>,
  where?: Maybe<UserAccessBoolExp>
}


export interface SubscriptionRootUserAccessByPkArgs {
  code: Scalars['Int']
}


export interface SubscriptionRootUserInviteArgs {
  distinct_on?: Maybe<Array<UserInviteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserInviteOrderBy>>,
  where?: Maybe<UserInviteBoolExp>
}


export interface SubscriptionRootUserInviteAggregateArgs {
  distinct_on?: Maybe<Array<UserInviteSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserInviteOrderBy>>,
  where?: Maybe<UserInviteBoolExp>
}


export interface SubscriptionRootUserInviteByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootUserManagementGridViewArgs {
  distinct_on?: Maybe<Array<UserManagementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserManagementGridViewOrderBy>>,
  where?: Maybe<UserManagementGridViewBoolExp>
}


export interface SubscriptionRootUserManagementGridViewAggregateArgs {
  distinct_on?: Maybe<Array<UserManagementGridViewSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserManagementGridViewOrderBy>>,
  where?: Maybe<UserManagementGridViewBoolExp>
}


export interface SubscriptionRootUserPermissionArgs {
  distinct_on?: Maybe<Array<UserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserPermissionOrderBy>>,
  where?: Maybe<UserPermissionBoolExp>
}


export interface SubscriptionRootUserPermissionAggregateArgs {
  distinct_on?: Maybe<Array<UserPermissionSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<UserPermissionOrderBy>>,
  where?: Maybe<UserPermissionBoolExp>
}


export interface SubscriptionRootUserPermissionByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootWorkflowArgs {
  distinct_on?: Maybe<Array<WorkflowSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowOrderBy>>,
  where?: Maybe<WorkflowBoolExp>
}


export interface SubscriptionRootWorkflowAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowOrderBy>>,
  where?: Maybe<WorkflowBoolExp>
}


export interface SubscriptionRootWorkflowByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootWorkflowStageArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}


export interface SubscriptionRootWorkflowStageAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}


export interface SubscriptionRootWorkflowStageByPkArgs {
  id: Scalars['String']
}


export interface SubscriptionRootWorkflowsToProfilesArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface SubscriptionRootWorkflowsToProfilesAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface SubscriptionRootWorkflowsToProfilesByPkArgs {
  profile_id: Scalars['String'],
  workflow_id: Scalars['String']
}


export interface SubscriptionRootYearsExperienceArgs {
  distinct_on?: Maybe<Array<YearsExperienceSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<YearsExperienceOrderBy>>,
  where?: Maybe<YearsExperienceBoolExp>
}


export interface SubscriptionRootYearsExperienceAggregateArgs {
  distinct_on?: Maybe<Array<YearsExperienceSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<YearsExperienceOrderBy>>,
  where?: Maybe<YearsExperienceBoolExp>
}


export interface SubscriptionRootYearsExperienceByPkArgs {
  id: Scalars['String']
}

export interface SwapMode {
   __typename?: 'swap_mode',
  code: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SwapModeAggregate {
   __typename?: 'swap_mode_aggregate',
  aggregate?: Maybe<SwapModeAggregateFields>,
  nodes: Array<SwapMode>,
}

export interface SwapModeAggregateFields {
   __typename?: 'swap_mode_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SwapModeMaxFields>,
  min?: Maybe<SwapModeMinFields>,
}


export interface SwapModeAggregateFieldsCountArgs {
  columns?: Maybe<Array<SwapModeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SwapModeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SwapModeMaxOrderBy>,
  min?: Maybe<SwapModeMinOrderBy>,
}

export interface SwapModeArrRelInsertInput {
  data: Array<SwapModeInsertInput>,
  on_conflict?: Maybe<SwapModeOnConflict>,
}

export interface SwapModeBoolExp {
  _and?: Maybe<Array<Maybe<SwapModeBoolExp>>>,
  _not?: Maybe<SwapModeBoolExp>,
  _or?: Maybe<Array<Maybe<SwapModeBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SwapModeConstraint {
  SwapModeCodeKey = 'swap_mode_code_key',
  SwapModePkey = 'swap_mode_pkey'
}

export interface SwapModeInsertInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SwapModeMaxFields {
   __typename?: 'swap_mode_max_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SwapModeMaxOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SwapModeMinFields {
   __typename?: 'swap_mode_min_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SwapModeMinOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SwapModeMutationResponse {
   __typename?: 'swap_mode_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SwapMode>,
}

export interface SwapModeObjRelInsertInput {
  data: SwapModeInsertInput,
  on_conflict?: Maybe<SwapModeOnConflict>,
}

export interface SwapModeOnConflict {
  constraint: SwapModeConstraint,
  update_columns: Array<SwapModeUpdateColumn>,
  where?: Maybe<SwapModeBoolExp>,
}

export interface SwapModeOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SwapModePkColumnsInput {
  id: Scalars['String'],
}

export enum SwapModeSelectColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SwapModeSetInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SwapModeUpdateColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface Symbol {
   __typename?: 'symbol',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  created_at: Scalars['timestamptz'],
  deleted_by?: Maybe<Stakeholder>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  is_deleted: Scalars['Boolean'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  platform_symbol: PlatformSymbol,
  platform_symbol_id: Scalars['String'],
  security?: Maybe<Security>,
  security_id?: Maybe<Scalars['String']>,
  spread_mode?: Maybe<SpreadMode>,
  spread_mode_id?: Maybe<Scalars['String']>,
  updated_at: Scalars['timestamptz'],
}

export interface SymbolAggregate {
   __typename?: 'symbol_aggregate',
  aggregate?: Maybe<SymbolAggregateFields>,
  nodes: Array<Symbol>,
}

export interface SymbolAggregateFields {
   __typename?: 'symbol_aggregate_fields',
  avg?: Maybe<SymbolAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SymbolMaxFields>,
  min?: Maybe<SymbolMinFields>,
  stddev?: Maybe<SymbolStddevFields>,
  stddev_pop?: Maybe<SymbolStddevPopFields>,
  stddev_samp?: Maybe<SymbolStddevSampFields>,
  sum?: Maybe<SymbolSumFields>,
  var_pop?: Maybe<SymbolVarPopFields>,
  var_samp?: Maybe<SymbolVarSampFields>,
  variance?: Maybe<SymbolVarianceFields>,
}


export interface SymbolAggregateFieldsCountArgs {
  columns?: Maybe<Array<SymbolSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SymbolAggregateOrderBy {
  avg?: Maybe<SymbolAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<SymbolMaxOrderBy>,
  min?: Maybe<SymbolMinOrderBy>,
  stddev?: Maybe<SymbolStddevOrderBy>,
  stddev_pop?: Maybe<SymbolStddevPopOrderBy>,
  stddev_samp?: Maybe<SymbolStddevSampOrderBy>,
  sum?: Maybe<SymbolSumOrderBy>,
  var_pop?: Maybe<SymbolVarPopOrderBy>,
  var_samp?: Maybe<SymbolVarSampOrderBy>,
  variance?: Maybe<SymbolVarianceOrderBy>,
}

export interface SymbolArrRelInsertInput {
  data: Array<SymbolInsertInput>,
  on_conflict?: Maybe<SymbolOnConflict>,
}

export interface SymbolAvgFields {
   __typename?: 'symbol_avg_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolAvgOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolBoolExp {
  _and?: Maybe<Array<Maybe<SymbolBoolExp>>>,
  _not?: Maybe<SymbolBoolExp>,
  _or?: Maybe<Array<Maybe<SymbolBoolExp>>>,
  ask_spread_markup?: Maybe<NumericComparisonExp>,
  bid_spread_markup?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  deleted_by?: Maybe<StakeholderBoolExp>,
  deleted_by_id?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  platform_symbol?: Maybe<PlatformSymbolBoolExp>,
  platform_symbol_id?: Maybe<StringComparisonExp>,
  security?: Maybe<SecurityBoolExp>,
  security_id?: Maybe<StringComparisonExp>,
  spread_mode?: Maybe<SpreadModeBoolExp>,
  spread_mode_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SymbolConstraint {
  SymbolPkey = 'symbol_pkey'
}

export interface SymbolExtension {
   __typename?: 'symbol_extension',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  is_deleted: Scalars['Boolean'],
  is_tracked: Scalars['Boolean'],
  name: Scalars['String'],
  platform_server: PlatformServer,
  platform_server_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SymbolExtensionAggregate {
   __typename?: 'symbol_extension_aggregate',
  aggregate?: Maybe<SymbolExtensionAggregateFields>,
  nodes: Array<SymbolExtension>,
}

export interface SymbolExtensionAggregateFields {
   __typename?: 'symbol_extension_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SymbolExtensionMaxFields>,
  min?: Maybe<SymbolExtensionMinFields>,
}


export interface SymbolExtensionAggregateFieldsCountArgs {
  columns?: Maybe<Array<SymbolExtensionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SymbolExtensionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SymbolExtensionMaxOrderBy>,
  min?: Maybe<SymbolExtensionMinOrderBy>,
}

export interface SymbolExtensionArrRelInsertInput {
  data: Array<SymbolExtensionInsertInput>,
  on_conflict?: Maybe<SymbolExtensionOnConflict>,
}

export interface SymbolExtensionBoolExp {
  _and?: Maybe<Array<Maybe<SymbolExtensionBoolExp>>>,
  _not?: Maybe<SymbolExtensionBoolExp>,
  _or?: Maybe<Array<Maybe<SymbolExtensionBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_deleted?: Maybe<BooleanComparisonExp>,
  is_tracked?: Maybe<BooleanComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SymbolExtensionConstraint {
  SymbolExtensionNamePlatformServerIdKey = 'symbol_extension_name_platform_server_id_key',
  SymbolExtensionPkey = 'symbol_extension_pkey'
}

export interface SymbolExtensionInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_tracked?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolExtensionMaxFields {
   __typename?: 'symbol_extension_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolExtensionMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolExtensionMinFields {
   __typename?: 'symbol_extension_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolExtensionMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolExtensionMutationResponse {
   __typename?: 'symbol_extension_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SymbolExtension>,
}

export interface SymbolExtensionObjRelInsertInput {
  data: SymbolExtensionInsertInput,
  on_conflict?: Maybe<SymbolExtensionOnConflict>,
}

export interface SymbolExtensionOnConflict {
  constraint: SymbolExtensionConstraint,
  update_columns: Array<SymbolExtensionUpdateColumn>,
  where?: Maybe<SymbolExtensionBoolExp>,
}

export interface SymbolExtensionOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  is_tracked?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolExtensionPkColumnsInput {
  id: Scalars['String'],
}

export enum SymbolExtensionSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeleted = 'is_deleted',
  IsTracked = 'is_tracked',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  UpdatedAt = 'updated_at'
}

export interface SymbolExtensionSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  is_tracked?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SymbolExtensionUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  IsDeleted = 'is_deleted',
  IsTracked = 'is_tracked',
  Name = 'name',
  PlatformServerId = 'platform_server_id',
  UpdatedAt = 'updated_at'
}

export interface SymbolGridView {
   __typename?: 'symbol_grid_view',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  currency_pair?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  platform_server?: Maybe<PlatformServer>,
  platform_server_id?: Maybe<Scalars['String']>,
  security?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  spread_mode?: Maybe<Scalars['String']>,
  swap_mode?: Maybe<Scalars['String']>,
  symbol?: Maybe<Symbol>,
  symbol_type?: Maybe<Scalars['String']>,
}

export interface SymbolGridViewAggregate {
   __typename?: 'symbol_grid_view_aggregate',
  aggregate?: Maybe<SymbolGridViewAggregateFields>,
  nodes: Array<SymbolGridView>,
}

export interface SymbolGridViewAggregateFields {
   __typename?: 'symbol_grid_view_aggregate_fields',
  avg?: Maybe<SymbolGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SymbolGridViewMaxFields>,
  min?: Maybe<SymbolGridViewMinFields>,
  stddev?: Maybe<SymbolGridViewStddevFields>,
  stddev_pop?: Maybe<SymbolGridViewStddevPopFields>,
  stddev_samp?: Maybe<SymbolGridViewStddevSampFields>,
  sum?: Maybe<SymbolGridViewSumFields>,
  var_pop?: Maybe<SymbolGridViewVarPopFields>,
  var_samp?: Maybe<SymbolGridViewVarSampFields>,
  variance?: Maybe<SymbolGridViewVarianceFields>,
}


export interface SymbolGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<SymbolGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SymbolGridViewAggregateOrderBy {
  avg?: Maybe<SymbolGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<SymbolGridViewMaxOrderBy>,
  min?: Maybe<SymbolGridViewMinOrderBy>,
  stddev?: Maybe<SymbolGridViewStddevOrderBy>,
  stddev_pop?: Maybe<SymbolGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<SymbolGridViewStddevSampOrderBy>,
  sum?: Maybe<SymbolGridViewSumOrderBy>,
  var_pop?: Maybe<SymbolGridViewVarPopOrderBy>,
  var_samp?: Maybe<SymbolGridViewVarSampOrderBy>,
  variance?: Maybe<SymbolGridViewVarianceOrderBy>,
}

export interface SymbolGridViewAvgFields {
   __typename?: 'symbol_grid_view_avg_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewAvgOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewBoolExp {
  _and?: Maybe<Array<Maybe<SymbolGridViewBoolExp>>>,
  _not?: Maybe<SymbolGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<SymbolGridViewBoolExp>>>,
  ask_spread_markup?: Maybe<NumericComparisonExp>,
  bid_spread_markup?: Maybe<NumericComparisonExp>,
  currency_pair?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  long_swaps?: Maybe<NumericComparisonExp>,
  margin_currency?: Maybe<StringComparisonExp>,
  platform_server?: Maybe<PlatformServerBoolExp>,
  platform_server_id?: Maybe<StringComparisonExp>,
  security?: Maybe<StringComparisonExp>,
  short_swaps?: Maybe<NumericComparisonExp>,
  spread_mode?: Maybe<StringComparisonExp>,
  swap_mode?: Maybe<StringComparisonExp>,
  symbol?: Maybe<SymbolBoolExp>,
  symbol_type?: Maybe<StringComparisonExp>,
}

export interface SymbolGridViewMaxFields {
   __typename?: 'symbol_grid_view_max_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  currency_pair?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  security?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  spread_mode?: Maybe<Scalars['String']>,
  swap_mode?: Maybe<Scalars['String']>,
  symbol_type?: Maybe<Scalars['String']>,
}

export interface SymbolGridViewMaxOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  currency_pair?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  security?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  spread_mode?: Maybe<OrderBy>,
  swap_mode?: Maybe<OrderBy>,
  symbol_type?: Maybe<OrderBy>,
}

export interface SymbolGridViewMinFields {
   __typename?: 'symbol_grid_view_min_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  currency_pair?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  margin_currency?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  security?: Maybe<Scalars['String']>,
  short_swaps?: Maybe<Scalars['numeric']>,
  spread_mode?: Maybe<Scalars['String']>,
  swap_mode?: Maybe<Scalars['String']>,
  symbol_type?: Maybe<Scalars['String']>,
}

export interface SymbolGridViewMinOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  currency_pair?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  security?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  spread_mode?: Maybe<OrderBy>,
  swap_mode?: Maybe<OrderBy>,
  symbol_type?: Maybe<OrderBy>,
}

export interface SymbolGridViewOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  currency_pair?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  margin_currency?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  security?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
  spread_mode?: Maybe<OrderBy>,
  swap_mode?: Maybe<OrderBy>,
  symbol?: Maybe<SymbolOrderBy>,
  symbol_type?: Maybe<OrderBy>,
}

export enum SymbolGridViewSelectColumn {
  AskSpreadMarkup = 'ask_spread_markup',
  BidSpreadMarkup = 'bid_spread_markup',
  CurrencyPair = 'currency_pair',
  Id = 'id',
  LongSwaps = 'long_swaps',
  MarginCurrency = 'margin_currency',
  PlatformServerId = 'platform_server_id',
  Security = 'security',
  ShortSwaps = 'short_swaps',
  SpreadMode = 'spread_mode',
  SwapMode = 'swap_mode',
  SymbolType = 'symbol_type'
}

export interface SymbolGridViewStddevFields {
   __typename?: 'symbol_grid_view_stddev_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewStddevOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewStddevPopFields {
   __typename?: 'symbol_grid_view_stddev_pop_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewStddevPopOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewStddevSampFields {
   __typename?: 'symbol_grid_view_stddev_samp_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewStddevSampOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewSumFields {
   __typename?: 'symbol_grid_view_sum_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  long_swaps?: Maybe<Scalars['numeric']>,
  short_swaps?: Maybe<Scalars['numeric']>,
}

export interface SymbolGridViewSumOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewVarPopFields {
   __typename?: 'symbol_grid_view_var_pop_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewVarPopOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewVarSampFields {
   __typename?: 'symbol_grid_view_var_samp_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewVarSampOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolGridViewVarianceFields {
   __typename?: 'symbol_grid_view_variance_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
  long_swaps?: Maybe<Scalars['Float']>,
  short_swaps?: Maybe<Scalars['Float']>,
}

export interface SymbolGridViewVarianceOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  long_swaps?: Maybe<OrderBy>,
  short_swaps?: Maybe<OrderBy>,
}

export interface SymbolIncInput {
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
}

export interface SymbolInsertInput {
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  deleted_by?: Maybe<StakeholderObjRelInsertInput>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  platform_server?: Maybe<PlatformServerObjRelInsertInput>,
  platform_server_id?: Maybe<Scalars['String']>,
  platform_symbol?: Maybe<PlatformSymbolObjRelInsertInput>,
  platform_symbol_id?: Maybe<Scalars['String']>,
  security?: Maybe<SecurityObjRelInsertInput>,
  security_id?: Maybe<Scalars['String']>,
  spread_mode?: Maybe<SpreadModeObjRelInsertInput>,
  spread_mode_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolMaxFields {
   __typename?: 'symbol_max_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  platform_symbol_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
  spread_mode_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolMaxOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  platform_symbol_id?: Maybe<OrderBy>,
  security_id?: Maybe<OrderBy>,
  spread_mode_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolMinFields {
   __typename?: 'symbol_min_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  platform_server_id?: Maybe<Scalars['String']>,
  platform_symbol_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
  spread_mode_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolMinOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  platform_symbol_id?: Maybe<OrderBy>,
  security_id?: Maybe<OrderBy>,
  spread_mode_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolMutationResponse {
   __typename?: 'symbol_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Symbol>,
}

export interface SymbolObjRelInsertInput {
  data: SymbolInsertInput,
  on_conflict?: Maybe<SymbolOnConflict>,
}

export interface SymbolOnConflict {
  constraint: SymbolConstraint,
  update_columns: Array<SymbolUpdateColumn>,
  where?: Maybe<SymbolBoolExp>,
}

export interface SymbolOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  deleted_by?: Maybe<StakeholderOrderBy>,
  deleted_by_id?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_deleted?: Maybe<OrderBy>,
  platform_server?: Maybe<PlatformServerOrderBy>,
  platform_server_id?: Maybe<OrderBy>,
  platform_symbol?: Maybe<PlatformSymbolOrderBy>,
  platform_symbol_id?: Maybe<OrderBy>,
  security?: Maybe<SecurityOrderBy>,
  security_id?: Maybe<OrderBy>,
  spread_mode?: Maybe<SpreadModeOrderBy>,
  spread_mode_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolPkColumnsInput {
  id: Scalars['String'],
}

export enum SymbolSelectColumn {
  AskSpreadMarkup = 'ask_spread_markup',
  BidSpreadMarkup = 'bid_spread_markup',
  CreatedAt = 'created_at',
  DeletedById = 'deleted_by_id',
  Id = 'id',
  IsDeleted = 'is_deleted',
  PlatformServerId = 'platform_server_id',
  PlatformSymbolId = 'platform_symbol_id',
  SecurityId = 'security_id',
  SpreadModeId = 'spread_mode_id',
  UpdatedAt = 'updated_at'
}

export interface SymbolSetInput {
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  deleted_by_id?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  is_deleted?: Maybe<Scalars['Boolean']>,
  platform_server_id?: Maybe<Scalars['String']>,
  platform_symbol_id?: Maybe<Scalars['String']>,
  security_id?: Maybe<Scalars['String']>,
  spread_mode_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolStddevFields {
   __typename?: 'symbol_stddev_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolStddevOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolStddevPopFields {
   __typename?: 'symbol_stddev_pop_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolStddevPopOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolStddevSampFields {
   __typename?: 'symbol_stddev_samp_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolStddevSampOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolSumFields {
   __typename?: 'symbol_sum_fields',
  ask_spread_markup?: Maybe<Scalars['numeric']>,
  bid_spread_markup?: Maybe<Scalars['numeric']>,
}

export interface SymbolSumOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolType {
   __typename?: 'symbol_type',
  code: Scalars['String'],
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface SymbolTypeAggregate {
   __typename?: 'symbol_type_aggregate',
  aggregate?: Maybe<SymbolTypeAggregateFields>,
  nodes: Array<SymbolType>,
}

export interface SymbolTypeAggregateFields {
   __typename?: 'symbol_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<SymbolTypeMaxFields>,
  min?: Maybe<SymbolTypeMinFields>,
}


export interface SymbolTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<SymbolTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface SymbolTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<SymbolTypeMaxOrderBy>,
  min?: Maybe<SymbolTypeMinOrderBy>,
}

export interface SymbolTypeArrRelInsertInput {
  data: Array<SymbolTypeInsertInput>,
  on_conflict?: Maybe<SymbolTypeOnConflict>,
}

export interface SymbolTypeBoolExp {
  _and?: Maybe<Array<Maybe<SymbolTypeBoolExp>>>,
  _not?: Maybe<SymbolTypeBoolExp>,
  _or?: Maybe<Array<Maybe<SymbolTypeBoolExp>>>,
  code?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum SymbolTypeConstraint {
  SymbolTypeCodeKey = 'symbol_type_code_key',
  SymbolTypePkey = 'symbol_type_pkey'
}

export interface SymbolTypeInsertInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolTypeMaxFields {
   __typename?: 'symbol_type_max_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolTypeMaxOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolTypeMinFields {
   __typename?: 'symbol_type_min_fields',
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface SymbolTypeMinOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolTypeMutationResponse {
   __typename?: 'symbol_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<SymbolType>,
}

export interface SymbolTypeObjRelInsertInput {
  data: SymbolTypeInsertInput,
  on_conflict?: Maybe<SymbolTypeOnConflict>,
}

export interface SymbolTypeOnConflict {
  constraint: SymbolTypeConstraint,
  update_columns: Array<SymbolTypeUpdateColumn>,
  where?: Maybe<SymbolTypeBoolExp>,
}

export interface SymbolTypeOrderBy {
  code?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface SymbolTypePkColumnsInput {
  id: Scalars['String'],
}

export enum SymbolTypeSelectColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface SymbolTypeSetInput {
  code?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum SymbolTypeUpdateColumn {
  Code = 'code',
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum SymbolUpdateColumn {
  AskSpreadMarkup = 'ask_spread_markup',
  BidSpreadMarkup = 'bid_spread_markup',
  CreatedAt = 'created_at',
  DeletedById = 'deleted_by_id',
  Id = 'id',
  IsDeleted = 'is_deleted',
  PlatformServerId = 'platform_server_id',
  PlatformSymbolId = 'platform_symbol_id',
  SecurityId = 'security_id',
  SpreadModeId = 'spread_mode_id',
  UpdatedAt = 'updated_at'
}

export interface SymbolVarPopFields {
   __typename?: 'symbol_var_pop_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolVarPopOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolVarSampFields {
   __typename?: 'symbol_var_samp_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolVarSampOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface SymbolVarianceFields {
   __typename?: 'symbol_variance_fields',
  ask_spread_markup?: Maybe<Scalars['Float']>,
  bid_spread_markup?: Maybe<Scalars['Float']>,
}

export interface SymbolVarianceOrderBy {
  ask_spread_markup?: Maybe<OrderBy>,
  bid_spread_markup?: Maybe<OrderBy>,
}

export interface Tag {
   __typename?: 'tag',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface TagAggregate {
   __typename?: 'tag_aggregate',
  aggregate?: Maybe<TagAggregateFields>,
  nodes: Array<Tag>,
}

export interface TagAggregateFields {
   __typename?: 'tag_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TagMaxFields>,
  min?: Maybe<TagMinFields>,
}


export interface TagAggregateFieldsCountArgs {
  columns?: Maybe<Array<TagSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TagAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<TagMaxOrderBy>,
  min?: Maybe<TagMinOrderBy>,
}

export interface TagArrRelInsertInput {
  data: Array<TagInsertInput>,
  on_conflict?: Maybe<TagOnConflict>,
}

export interface TagBoolExp {
  _and?: Maybe<Array<Maybe<TagBoolExp>>>,
  _not?: Maybe<TagBoolExp>,
  _or?: Maybe<Array<Maybe<TagBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum TagConstraint {
  TagNameKey = 'tag_name_key',
  TagNameOrganizationIdKey = 'tag_name_organization_id_key',
  TagPkey = 'tag_pkey'
}

export interface TagInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TagMaxFields {
   __typename?: 'tag_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TagMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TagMinFields {
   __typename?: 'tag_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TagMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TagMutationResponse {
   __typename?: 'tag_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Tag>,
}

export interface TagObjRelInsertInput {
  data: TagInsertInput,
  on_conflict?: Maybe<TagOnConflict>,
}

export interface TagOnConflict {
  constraint: TagConstraint,
  update_columns: Array<TagUpdateColumn>,
  where?: Maybe<TagBoolExp>,
}

export interface TagOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TagPkColumnsInput {
  id: Scalars['String'],
}

export enum TagSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}

export interface TagSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum TagUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  Name = 'name',
  OrganizationId = 'organization_id',
  UpdatedAt = 'updated_at'
}


export interface TimestamptzComparisonExp {
  _eq?: Maybe<Scalars['timestamptz']>,
  _gt?: Maybe<Scalars['timestamptz']>,
  _gte?: Maybe<Scalars['timestamptz']>,
  _in?: Maybe<Array<Scalars['timestamptz']>>,
  _is_null?: Maybe<Scalars['Boolean']>,
  _lt?: Maybe<Scalars['timestamptz']>,
  _lte?: Maybe<Scalars['timestamptz']>,
  _neq?: Maybe<Scalars['timestamptz']>,
  _nin?: Maybe<Array<Scalars['timestamptz']>>,
}

export interface TradeRule {
   __typename?: 'trade_rule',
  account_groups: Array<AccountGroupSecurity>,
  account_groups_aggregate: AccountGroupSecurityAggregate,
  charge_unit: ChargeUnit,
  charge_unit_id: Scalars['String'],
  commission_markup: Scalars['numeric'],
  commission_rebate: Scalars['numeric'],
  id: Scalars['String'],
  is_default: Scalars['Boolean'],
  maximum_lot_size: Scalars['numeric'],
  minimum_commission: Scalars['numeric'],
  minimum_rebate: Scalars['numeric'],
  minimum_size: Scalars['numeric'],
  spread_markup: Scalars['numeric'],
  spread_rebate: Scalars['numeric'],
  step: Scalars['numeric'],
}


export interface TradeRuleAccountGroupsArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}


export interface TradeRuleAccountGroupsAggregateArgs {
  distinct_on?: Maybe<Array<AccountGroupSecuritySelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<AccountGroupSecurityOrderBy>>,
  where?: Maybe<AccountGroupSecurityBoolExp>
}

export interface TradeRuleAggregate {
   __typename?: 'trade_rule_aggregate',
  aggregate?: Maybe<TradeRuleAggregateFields>,
  nodes: Array<TradeRule>,
}

export interface TradeRuleAggregateFields {
   __typename?: 'trade_rule_aggregate_fields',
  avg?: Maybe<TradeRuleAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TradeRuleMaxFields>,
  min?: Maybe<TradeRuleMinFields>,
  stddev?: Maybe<TradeRuleStddevFields>,
  stddev_pop?: Maybe<TradeRuleStddevPopFields>,
  stddev_samp?: Maybe<TradeRuleStddevSampFields>,
  sum?: Maybe<TradeRuleSumFields>,
  var_pop?: Maybe<TradeRuleVarPopFields>,
  var_samp?: Maybe<TradeRuleVarSampFields>,
  variance?: Maybe<TradeRuleVarianceFields>,
}


export interface TradeRuleAggregateFieldsCountArgs {
  columns?: Maybe<Array<TradeRuleSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TradeRuleAggregateOrderBy {
  avg?: Maybe<TradeRuleAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<TradeRuleMaxOrderBy>,
  min?: Maybe<TradeRuleMinOrderBy>,
  stddev?: Maybe<TradeRuleStddevOrderBy>,
  stddev_pop?: Maybe<TradeRuleStddevPopOrderBy>,
  stddev_samp?: Maybe<TradeRuleStddevSampOrderBy>,
  sum?: Maybe<TradeRuleSumOrderBy>,
  var_pop?: Maybe<TradeRuleVarPopOrderBy>,
  var_samp?: Maybe<TradeRuleVarSampOrderBy>,
  variance?: Maybe<TradeRuleVarianceOrderBy>,
}

export interface TradeRuleArrRelInsertInput {
  data: Array<TradeRuleInsertInput>,
  on_conflict?: Maybe<TradeRuleOnConflict>,
}

export interface TradeRuleAvgFields {
   __typename?: 'trade_rule_avg_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleAvgOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleBoolExp {
  _and?: Maybe<Array<Maybe<TradeRuleBoolExp>>>,
  _not?: Maybe<TradeRuleBoolExp>,
  _or?: Maybe<Array<Maybe<TradeRuleBoolExp>>>,
  account_groups?: Maybe<AccountGroupSecurityBoolExp>,
  charge_unit?: Maybe<ChargeUnitBoolExp>,
  charge_unit_id?: Maybe<StringComparisonExp>,
  commission_markup?: Maybe<NumericComparisonExp>,
  commission_rebate?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  is_default?: Maybe<BooleanComparisonExp>,
  maximum_lot_size?: Maybe<NumericComparisonExp>,
  minimum_commission?: Maybe<NumericComparisonExp>,
  minimum_rebate?: Maybe<NumericComparisonExp>,
  minimum_size?: Maybe<NumericComparisonExp>,
  spread_markup?: Maybe<NumericComparisonExp>,
  spread_rebate?: Maybe<NumericComparisonExp>,
  step?: Maybe<NumericComparisonExp>,
}

export enum TradeRuleConstraint {
  TradeRulePkey = 'trade_rule_pkey'
}

export interface TradeRuleIncInput {
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleInsertInput {
  account_groups?: Maybe<AccountGroupSecurityArrRelInsertInput>,
  charge_unit?: Maybe<ChargeUnitObjRelInsertInput>,
  charge_unit_id?: Maybe<Scalars['String']>,
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  is_default?: Maybe<Scalars['Boolean']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleMaxFields {
   __typename?: 'trade_rule_max_fields',
  charge_unit_id?: Maybe<Scalars['String']>,
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleMaxOrderBy {
  charge_unit_id?: Maybe<OrderBy>,
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleMinFields {
   __typename?: 'trade_rule_min_fields',
  charge_unit_id?: Maybe<Scalars['String']>,
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleMinOrderBy {
  charge_unit_id?: Maybe<OrderBy>,
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleMutationResponse {
   __typename?: 'trade_rule_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TradeRule>,
}

export interface TradeRuleObjRelInsertInput {
  data: TradeRuleInsertInput,
  on_conflict?: Maybe<TradeRuleOnConflict>,
}

export interface TradeRuleOnConflict {
  constraint: TradeRuleConstraint,
  update_columns: Array<TradeRuleUpdateColumn>,
  where?: Maybe<TradeRuleBoolExp>,
}

export interface TradeRuleOrderBy {
  account_groups_aggregate?: Maybe<AccountGroupSecurityAggregateOrderBy>,
  charge_unit?: Maybe<ChargeUnitOrderBy>,
  charge_unit_id?: Maybe<OrderBy>,
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  is_default?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRulePkColumnsInput {
  id: Scalars['String'],
}

export enum TradeRuleSelectColumn {
  ChargeUnitId = 'charge_unit_id',
  CommissionMarkup = 'commission_markup',
  CommissionRebate = 'commission_rebate',
  Id = 'id',
  IsDefault = 'is_default',
  MaximumLotSize = 'maximum_lot_size',
  MinimumCommission = 'minimum_commission',
  MinimumRebate = 'minimum_rebate',
  MinimumSize = 'minimum_size',
  SpreadMarkup = 'spread_markup',
  SpreadRebate = 'spread_rebate',
  Step = 'step'
}

export interface TradeRuleSetInput {
  charge_unit_id?: Maybe<Scalars['String']>,
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  is_default?: Maybe<Scalars['Boolean']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleStddevFields {
   __typename?: 'trade_rule_stddev_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleStddevOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleStddevPopFields {
   __typename?: 'trade_rule_stddev_pop_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleStddevPopOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleStddevSampFields {
   __typename?: 'trade_rule_stddev_samp_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleStddevSampOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleSumFields {
   __typename?: 'trade_rule_sum_fields',
  commission_markup?: Maybe<Scalars['numeric']>,
  commission_rebate?: Maybe<Scalars['numeric']>,
  maximum_lot_size?: Maybe<Scalars['numeric']>,
  minimum_commission?: Maybe<Scalars['numeric']>,
  minimum_rebate?: Maybe<Scalars['numeric']>,
  minimum_size?: Maybe<Scalars['numeric']>,
  spread_markup?: Maybe<Scalars['numeric']>,
  spread_rebate?: Maybe<Scalars['numeric']>,
  step?: Maybe<Scalars['numeric']>,
}

export interface TradeRuleSumOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export enum TradeRuleUpdateColumn {
  ChargeUnitId = 'charge_unit_id',
  CommissionMarkup = 'commission_markup',
  CommissionRebate = 'commission_rebate',
  Id = 'id',
  IsDefault = 'is_default',
  MaximumLotSize = 'maximum_lot_size',
  MinimumCommission = 'minimum_commission',
  MinimumRebate = 'minimum_rebate',
  MinimumSize = 'minimum_size',
  SpreadMarkup = 'spread_markup',
  SpreadRebate = 'spread_rebate',
  Step = 'step'
}

export interface TradeRuleVarPopFields {
   __typename?: 'trade_rule_var_pop_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleVarPopOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleVarSampFields {
   __typename?: 'trade_rule_var_samp_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleVarSampOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface TradeRuleVarianceFields {
   __typename?: 'trade_rule_variance_fields',
  commission_markup?: Maybe<Scalars['Float']>,
  commission_rebate?: Maybe<Scalars['Float']>,
  maximum_lot_size?: Maybe<Scalars['Float']>,
  minimum_commission?: Maybe<Scalars['Float']>,
  minimum_rebate?: Maybe<Scalars['Float']>,
  minimum_size?: Maybe<Scalars['Float']>,
  spread_markup?: Maybe<Scalars['Float']>,
  spread_rebate?: Maybe<Scalars['Float']>,
  step?: Maybe<Scalars['Float']>,
}

export interface TradeRuleVarianceOrderBy {
  commission_markup?: Maybe<OrderBy>,
  commission_rebate?: Maybe<OrderBy>,
  maximum_lot_size?: Maybe<OrderBy>,
  minimum_commission?: Maybe<OrderBy>,
  minimum_rebate?: Maybe<OrderBy>,
  minimum_size?: Maybe<OrderBy>,
  spread_markup?: Maybe<OrderBy>,
  spread_rebate?: Maybe<OrderBy>,
  step?: Maybe<OrderBy>,
}

export interface Transaction {
   __typename?: 'transaction',
  account_profiles?: Maybe<TransactionProfileGridView>,
  amount: Scalars['numeric'],
  approved_at?: Maybe<Scalars['timestamptz']>,
  approved_by?: Maybe<Stakeholder>,
  approved_by_id?: Maybe<Scalars['String']>,
  authorized_by: Stakeholder,
  authorized_by_id: Scalars['String'],
  bank_rate?: Maybe<Scalars['numeric']>,
  created_at: Scalars['timestamptz'],
  created_by: Stakeholder,
  created_by_id: Scalars['String'],
  exchange_rate: Scalars['numeric'],
  id: Scalars['String'],
  internal_fee: Scalars['numeric'],
  is_paid: Scalars['Boolean'],
  market_exchange_rate: Scalars['numeric'],
  master_admin_client_permission: MasterAdminClientPermission,
  master_admin_client_permission_id: Scalars['String'],
  master_admin_lead_permission: MasterAdminLeadPermission,
  master_admin_lead_permission_id: Scalars['String'],
  master_admin_partner_permission: MasterAdminPartnerPermission,
  master_admin_partner_permission_id: Scalars['String'],
  master_partner_client_permission: MasterPartnerClientPermission,
  master_partner_client_permission_id: Scalars['String'],
  master_partner_partner_permission: MasterPartnerPartnerPermission,
  master_partner_partner_permission_id: Scalars['String'],
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  memo?: Maybe<Scalars['String']>,
  merchant_fee: Scalars['numeric'],
  number: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  payment?: Maybe<Payment>,
  payment_id?: Maybe<Scalars['String']>,
  request?: Maybe<Request>,
  request_id?: Maybe<Scalars['String']>,
  source_account?: Maybe<Account>,
  source_account_id?: Maybe<Scalars['String']>,
  source_currency: Currency,
  source_currency_id: Scalars['String'],
  status_id?: Maybe<Scalars['String']>,
  target_account?: Maybe<Account>,
  target_account_id?: Maybe<Scalars['String']>,
  target_currency: Currency,
  target_currency_id: Scalars['String'],
  transaction_platform_status?: Maybe<TransactionPlatformStatus>,
  transaction_platform_status_id?: Maybe<Scalars['String']>,
  transaction_status?: Maybe<TransactionStatus>,
  transaction_type: TransactionType,
  type_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface TransactionAggregate {
   __typename?: 'transaction_aggregate',
  aggregate?: Maybe<TransactionAggregateFields>,
  nodes: Array<Transaction>,
}

export interface TransactionAggregateFields {
   __typename?: 'transaction_aggregate_fields',
  avg?: Maybe<TransactionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionMaxFields>,
  min?: Maybe<TransactionMinFields>,
  stddev?: Maybe<TransactionStddevFields>,
  stddev_pop?: Maybe<TransactionStddevPopFields>,
  stddev_samp?: Maybe<TransactionStddevSampFields>,
  sum?: Maybe<TransactionSumFields>,
  var_pop?: Maybe<TransactionVarPopFields>,
  var_samp?: Maybe<TransactionVarSampFields>,
  variance?: Maybe<TransactionVarianceFields>,
}


export interface TransactionAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionAggregateOrderBy {
  avg?: Maybe<TransactionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionMaxOrderBy>,
  min?: Maybe<TransactionMinOrderBy>,
  stddev?: Maybe<TransactionStddevOrderBy>,
  stddev_pop?: Maybe<TransactionStddevPopOrderBy>,
  stddev_samp?: Maybe<TransactionStddevSampOrderBy>,
  sum?: Maybe<TransactionSumOrderBy>,
  var_pop?: Maybe<TransactionVarPopOrderBy>,
  var_samp?: Maybe<TransactionVarSampOrderBy>,
  variance?: Maybe<TransactionVarianceOrderBy>,
}

export interface TransactionArrRelInsertInput {
  data: Array<TransactionInsertInput>,
  on_conflict?: Maybe<TransactionOnConflict>,
}

export interface TransactionAvgFields {
   __typename?: 'transaction_avg_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionAvgOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionBoolExp {
  _and?: Maybe<Array<Maybe<TransactionBoolExp>>>,
  _not?: Maybe<TransactionBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionBoolExp>>>,
  account_profiles?: Maybe<TransactionProfileGridViewBoolExp>,
  amount?: Maybe<NumericComparisonExp>,
  approved_at?: Maybe<TimestamptzComparisonExp>,
  approved_by?: Maybe<StakeholderBoolExp>,
  approved_by_id?: Maybe<StringComparisonExp>,
  authorized_by?: Maybe<StakeholderBoolExp>,
  authorized_by_id?: Maybe<StringComparisonExp>,
  bank_rate?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  created_by?: Maybe<StakeholderBoolExp>,
  created_by_id?: Maybe<StringComparisonExp>,
  exchange_rate?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  internal_fee?: Maybe<NumericComparisonExp>,
  is_paid?: Maybe<BooleanComparisonExp>,
  market_exchange_rate?: Maybe<NumericComparisonExp>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionBoolExp>,
  master_admin_client_permission_id?: Maybe<StringComparisonExp>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionBoolExp>,
  master_admin_lead_permission_id?: Maybe<StringComparisonExp>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionBoolExp>,
  master_admin_partner_permission_id?: Maybe<StringComparisonExp>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionBoolExp>,
  master_partner_client_permission_id?: Maybe<StringComparisonExp>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionBoolExp>,
  master_partner_partner_permission_id?: Maybe<StringComparisonExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  memo?: Maybe<StringComparisonExp>,
  merchant_fee?: Maybe<NumericComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  payment?: Maybe<PaymentBoolExp>,
  payment_id?: Maybe<StringComparisonExp>,
  request?: Maybe<RequestBoolExp>,
  request_id?: Maybe<StringComparisonExp>,
  source_account?: Maybe<AccountBoolExp>,
  source_account_id?: Maybe<StringComparisonExp>,
  source_currency?: Maybe<CurrencyBoolExp>,
  source_currency_id?: Maybe<StringComparisonExp>,
  status_id?: Maybe<StringComparisonExp>,
  target_account?: Maybe<AccountBoolExp>,
  target_account_id?: Maybe<StringComparisonExp>,
  target_currency?: Maybe<CurrencyBoolExp>,
  target_currency_id?: Maybe<StringComparisonExp>,
  transaction_platform_status?: Maybe<TransactionPlatformStatusBoolExp>,
  transaction_platform_status_id?: Maybe<StringComparisonExp>,
  transaction_status?: Maybe<TransactionStatusBoolExp>,
  transaction_type?: Maybe<TransactionTypeBoolExp>,
  type_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum TransactionConstraint {
  TransactionNumberKey = 'transaction_number_key',
  TransactionPaymentIdKey = 'transaction_payment_id_key',
  TransactionPkey = 'transaction_pkey'
}

export interface TransactionCreationRequest {
   __typename?: 'transaction_creation_request',
  amount: Scalars['numeric'],
  created_at: Scalars['timestamptz'],
  exchange_rate?: Maybe<Scalars['numeric']>,
  id: Scalars['String'],
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  request: Request,
  request_id: Scalars['String'],
}

export interface TransactionCreationRequestAggregate {
   __typename?: 'transaction_creation_request_aggregate',
  aggregate?: Maybe<TransactionCreationRequestAggregateFields>,
  nodes: Array<TransactionCreationRequest>,
}

export interface TransactionCreationRequestAggregateFields {
   __typename?: 'transaction_creation_request_aggregate_fields',
  avg?: Maybe<TransactionCreationRequestAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionCreationRequestMaxFields>,
  min?: Maybe<TransactionCreationRequestMinFields>,
  stddev?: Maybe<TransactionCreationRequestStddevFields>,
  stddev_pop?: Maybe<TransactionCreationRequestStddevPopFields>,
  stddev_samp?: Maybe<TransactionCreationRequestStddevSampFields>,
  sum?: Maybe<TransactionCreationRequestSumFields>,
  var_pop?: Maybe<TransactionCreationRequestVarPopFields>,
  var_samp?: Maybe<TransactionCreationRequestVarSampFields>,
  variance?: Maybe<TransactionCreationRequestVarianceFields>,
}


export interface TransactionCreationRequestAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionCreationRequestSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionCreationRequestAggregateOrderBy {
  avg?: Maybe<TransactionCreationRequestAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionCreationRequestMaxOrderBy>,
  min?: Maybe<TransactionCreationRequestMinOrderBy>,
  stddev?: Maybe<TransactionCreationRequestStddevOrderBy>,
  stddev_pop?: Maybe<TransactionCreationRequestStddevPopOrderBy>,
  stddev_samp?: Maybe<TransactionCreationRequestStddevSampOrderBy>,
  sum?: Maybe<TransactionCreationRequestSumOrderBy>,
  var_pop?: Maybe<TransactionCreationRequestVarPopOrderBy>,
  var_samp?: Maybe<TransactionCreationRequestVarSampOrderBy>,
  variance?: Maybe<TransactionCreationRequestVarianceOrderBy>,
}

export interface TransactionCreationRequestArrRelInsertInput {
  data: Array<TransactionCreationRequestInsertInput>,
  on_conflict?: Maybe<TransactionCreationRequestOnConflict>,
}

export interface TransactionCreationRequestAvgFields {
   __typename?: 'transaction_creation_request_avg_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestAvgOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestBoolExp {
  _and?: Maybe<Array<Maybe<TransactionCreationRequestBoolExp>>>,
  _not?: Maybe<TransactionCreationRequestBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionCreationRequestBoolExp>>>,
  amount?: Maybe<NumericComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  exchange_rate?: Maybe<NumericComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  internal_fee?: Maybe<NumericComparisonExp>,
  merchant_fee?: Maybe<NumericComparisonExp>,
  request?: Maybe<RequestBoolExp>,
  request_id?: Maybe<StringComparisonExp>,
}

export enum TransactionCreationRequestConstraint {
  TransactionCreationRequestPkey = 'transaction_creation_request_pkey'
}

export interface TransactionCreationRequestIncInput {
  amount?: Maybe<Scalars['numeric']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
}

export interface TransactionCreationRequestInsertInput {
  amount?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  request?: Maybe<RequestObjRelInsertInput>,
  request_id?: Maybe<Scalars['String']>,
}

export interface TransactionCreationRequestMaxFields {
   __typename?: 'transaction_creation_request_max_fields',
  amount?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  request_id?: Maybe<Scalars['String']>,
}

export interface TransactionCreationRequestMaxOrderBy {
  amount?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestMinFields {
   __typename?: 'transaction_creation_request_min_fields',
  amount?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  request_id?: Maybe<Scalars['String']>,
}

export interface TransactionCreationRequestMinOrderBy {
  amount?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestMutationResponse {
   __typename?: 'transaction_creation_request_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransactionCreationRequest>,
}

export interface TransactionCreationRequestObjRelInsertInput {
  data: TransactionCreationRequestInsertInput,
  on_conflict?: Maybe<TransactionCreationRequestOnConflict>,
}

export interface TransactionCreationRequestOnConflict {
  constraint: TransactionCreationRequestConstraint,
  update_columns: Array<TransactionCreationRequestUpdateColumn>,
  where?: Maybe<TransactionCreationRequestBoolExp>,
}

export interface TransactionCreationRequestOrderBy {
  amount?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  request?: Maybe<RequestOrderBy>,
  request_id?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestPkColumnsInput {
  id: Scalars['String'],
}

export enum TransactionCreationRequestSelectColumn {
  Amount = 'amount',
  CreatedAt = 'created_at',
  ExchangeRate = 'exchange_rate',
  Id = 'id',
  InternalFee = 'internal_fee',
  MerchantFee = 'merchant_fee',
  RequestId = 'request_id'
}

export interface TransactionCreationRequestSetInput {
  amount?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  request_id?: Maybe<Scalars['String']>,
}

export interface TransactionCreationRequestStddevFields {
   __typename?: 'transaction_creation_request_stddev_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestStddevOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestStddevPopFields {
   __typename?: 'transaction_creation_request_stddev_pop_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestStddevPopOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestStddevSampFields {
   __typename?: 'transaction_creation_request_stddev_samp_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestStddevSampOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestSumFields {
   __typename?: 'transaction_creation_request_sum_fields',
  amount?: Maybe<Scalars['numeric']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
}

export interface TransactionCreationRequestSumOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export enum TransactionCreationRequestUpdateColumn {
  Amount = 'amount',
  CreatedAt = 'created_at',
  ExchangeRate = 'exchange_rate',
  Id = 'id',
  InternalFee = 'internal_fee',
  MerchantFee = 'merchant_fee',
  RequestId = 'request_id'
}

export interface TransactionCreationRequestVarPopFields {
   __typename?: 'transaction_creation_request_var_pop_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestVarPopOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestVarSampFields {
   __typename?: 'transaction_creation_request_var_samp_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestVarSampOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionCreationRequestVarianceFields {
   __typename?: 'transaction_creation_request_variance_fields',
  amount?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionCreationRequestVarianceOrderBy {
  amount?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionGridView {
   __typename?: 'transaction_grid_view',
  amount?: Maybe<Scalars['numeric']>,
  authorized_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization?: Maybe<Organization>,
  organization_id?: Maybe<Scalars['String']>,
  source_account?: Maybe<Scalars['String']>,
  source_account_type?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  target_account?: Maybe<Scalars['String']>,
  target_account_type?: Maybe<Scalars['String']>,
  transaction?: Maybe<Transaction>,
  type?: Maybe<Scalars['String']>,
}

export interface TransactionGridViewAggregate {
   __typename?: 'transaction_grid_view_aggregate',
  aggregate?: Maybe<TransactionGridViewAggregateFields>,
  nodes: Array<TransactionGridView>,
}

export interface TransactionGridViewAggregateFields {
   __typename?: 'transaction_grid_view_aggregate_fields',
  avg?: Maybe<TransactionGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionGridViewMaxFields>,
  min?: Maybe<TransactionGridViewMinFields>,
  stddev?: Maybe<TransactionGridViewStddevFields>,
  stddev_pop?: Maybe<TransactionGridViewStddevPopFields>,
  stddev_samp?: Maybe<TransactionGridViewStddevSampFields>,
  sum?: Maybe<TransactionGridViewSumFields>,
  var_pop?: Maybe<TransactionGridViewVarPopFields>,
  var_samp?: Maybe<TransactionGridViewVarSampFields>,
  variance?: Maybe<TransactionGridViewVarianceFields>,
}


export interface TransactionGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionGridViewAggregateOrderBy {
  avg?: Maybe<TransactionGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionGridViewMaxOrderBy>,
  min?: Maybe<TransactionGridViewMinOrderBy>,
  stddev?: Maybe<TransactionGridViewStddevOrderBy>,
  stddev_pop?: Maybe<TransactionGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<TransactionGridViewStddevSampOrderBy>,
  sum?: Maybe<TransactionGridViewSumOrderBy>,
  var_pop?: Maybe<TransactionGridViewVarPopOrderBy>,
  var_samp?: Maybe<TransactionGridViewVarSampOrderBy>,
  variance?: Maybe<TransactionGridViewVarianceOrderBy>,
}

export interface TransactionGridViewAvgFields {
   __typename?: 'transaction_grid_view_avg_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewAvgOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewBoolExp {
  _and?: Maybe<Array<Maybe<TransactionGridViewBoolExp>>>,
  _not?: Maybe<TransactionGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionGridViewBoolExp>>>,
  amount?: Maybe<NumericComparisonExp>,
  authorized_by?: Maybe<StringComparisonExp>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  method?: Maybe<StringComparisonExp>,
  number?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  source_account?: Maybe<StringComparisonExp>,
  source_account_type?: Maybe<StringComparisonExp>,
  status?: Maybe<StringComparisonExp>,
  target_account?: Maybe<StringComparisonExp>,
  target_account_type?: Maybe<StringComparisonExp>,
  transaction?: Maybe<TransactionBoolExp>,
  type?: Maybe<StringComparisonExp>,
}

export interface TransactionGridViewMaxFields {
   __typename?: 'transaction_grid_view_max_fields',
  amount?: Maybe<Scalars['numeric']>,
  authorized_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  source_account?: Maybe<Scalars['String']>,
  source_account_type?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  target_account?: Maybe<Scalars['String']>,
  target_account_type?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface TransactionGridViewMaxOrderBy {
  amount?: Maybe<OrderBy>,
  authorized_by?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  source_account?: Maybe<OrderBy>,
  source_account_type?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  target_account?: Maybe<OrderBy>,
  target_account_type?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface TransactionGridViewMinFields {
   __typename?: 'transaction_grid_view_min_fields',
  amount?: Maybe<Scalars['numeric']>,
  authorized_by?: Maybe<Scalars['String']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  source_account?: Maybe<Scalars['String']>,
  source_account_type?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  target_account?: Maybe<Scalars['String']>,
  target_account_type?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface TransactionGridViewMinOrderBy {
  amount?: Maybe<OrderBy>,
  authorized_by?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  source_account?: Maybe<OrderBy>,
  source_account_type?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  target_account?: Maybe<OrderBy>,
  target_account_type?: Maybe<OrderBy>,
  type?: Maybe<OrderBy>,
}

export interface TransactionGridViewOrderBy {
  amount?: Maybe<OrderBy>,
  authorized_by?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  method?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  source_account?: Maybe<OrderBy>,
  source_account_type?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  target_account?: Maybe<OrderBy>,
  target_account_type?: Maybe<OrderBy>,
  transaction?: Maybe<TransactionOrderBy>,
  type?: Maybe<OrderBy>,
}

export enum TransactionGridViewSelectColumn {
  Amount = 'amount',
  AuthorizedBy = 'authorized_by',
  CreatedAt = 'created_at',
  Id = 'id',
  Method = 'method',
  Number = 'number',
  OrganizationId = 'organization_id',
  SourceAccount = 'source_account',
  SourceAccountType = 'source_account_type',
  Status = 'status',
  TargetAccount = 'target_account',
  TargetAccountType = 'target_account_type',
  Type = 'type'
}

export interface TransactionGridViewStddevFields {
   __typename?: 'transaction_grid_view_stddev_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewStddevOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewStddevPopFields {
   __typename?: 'transaction_grid_view_stddev_pop_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewStddevPopOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewStddevSampFields {
   __typename?: 'transaction_grid_view_stddev_samp_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewStddevSampOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewSumFields {
   __typename?: 'transaction_grid_view_sum_fields',
  amount?: Maybe<Scalars['numeric']>,
}

export interface TransactionGridViewSumOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewVarPopFields {
   __typename?: 'transaction_grid_view_var_pop_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewVarPopOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewVarSampFields {
   __typename?: 'transaction_grid_view_var_samp_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewVarSampOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionGridViewVarianceFields {
   __typename?: 'transaction_grid_view_variance_fields',
  amount?: Maybe<Scalars['Float']>,
}

export interface TransactionGridViewVarianceOrderBy {
  amount?: Maybe<OrderBy>,
}

export interface TransactionIncInput {
  amount?: Maybe<Scalars['numeric']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
}

export interface TransactionInsertInput {
  account_profiles?: Maybe<TransactionProfileGridViewObjRelInsertInput>,
  amount?: Maybe<Scalars['numeric']>,
  approved_at?: Maybe<Scalars['timestamptz']>,
  approved_by?: Maybe<StakeholderObjRelInsertInput>,
  approved_by_id?: Maybe<Scalars['String']>,
  authorized_by?: Maybe<StakeholderObjRelInsertInput>,
  authorized_by_id?: Maybe<Scalars['String']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by?: Maybe<StakeholderObjRelInsertInput>,
  created_by_id?: Maybe<Scalars['String']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  is_paid?: Maybe<Scalars['Boolean']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionObjRelInsertInput>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionObjRelInsertInput>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionObjRelInsertInput>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionObjRelInsertInput>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionObjRelInsertInput>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  memo?: Maybe<Scalars['String']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  payment?: Maybe<PaymentObjRelInsertInput>,
  payment_id?: Maybe<Scalars['String']>,
  request?: Maybe<RequestObjRelInsertInput>,
  request_id?: Maybe<Scalars['String']>,
  source_account?: Maybe<AccountObjRelInsertInput>,
  source_account_id?: Maybe<Scalars['String']>,
  source_currency?: Maybe<CurrencyObjRelInsertInput>,
  source_currency_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  target_account?: Maybe<AccountObjRelInsertInput>,
  target_account_id?: Maybe<Scalars['String']>,
  target_currency?: Maybe<CurrencyObjRelInsertInput>,
  target_currency_id?: Maybe<Scalars['String']>,
  transaction_platform_status?: Maybe<TransactionPlatformStatusObjRelInsertInput>,
  transaction_platform_status_id?: Maybe<Scalars['String']>,
  transaction_status?: Maybe<TransactionStatusObjRelInsertInput>,
  transaction_type?: Maybe<TransactionTypeObjRelInsertInput>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionMaxFields {
   __typename?: 'transaction_max_fields',
  amount?: Maybe<Scalars['numeric']>,
  approved_at?: Maybe<Scalars['timestamptz']>,
  approved_by_id?: Maybe<Scalars['String']>,
  authorized_by_id?: Maybe<Scalars['String']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  memo?: Maybe<Scalars['String']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  source_account_id?: Maybe<Scalars['String']>,
  source_currency_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  target_account_id?: Maybe<Scalars['String']>,
  target_currency_id?: Maybe<Scalars['String']>,
  transaction_platform_status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionMaxOrderBy {
  amount?: Maybe<OrderBy>,
  approved_at?: Maybe<OrderBy>,
  approved_by_id?: Maybe<OrderBy>,
  authorized_by_id?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  memo?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  payment_id?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
  source_account_id?: Maybe<OrderBy>,
  source_currency_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  target_account_id?: Maybe<OrderBy>,
  target_currency_id?: Maybe<OrderBy>,
  transaction_platform_status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionMinFields {
   __typename?: 'transaction_min_fields',
  amount?: Maybe<Scalars['numeric']>,
  approved_at?: Maybe<Scalars['timestamptz']>,
  approved_by_id?: Maybe<Scalars['String']>,
  authorized_by_id?: Maybe<Scalars['String']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  memo?: Maybe<Scalars['String']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  source_account_id?: Maybe<Scalars['String']>,
  source_currency_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  target_account_id?: Maybe<Scalars['String']>,
  target_currency_id?: Maybe<Scalars['String']>,
  transaction_platform_status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionMinOrderBy {
  amount?: Maybe<OrderBy>,
  approved_at?: Maybe<OrderBy>,
  approved_by_id?: Maybe<OrderBy>,
  authorized_by_id?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by_id?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  memo?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  payment_id?: Maybe<OrderBy>,
  request_id?: Maybe<OrderBy>,
  source_account_id?: Maybe<OrderBy>,
  source_currency_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  target_account_id?: Maybe<OrderBy>,
  target_currency_id?: Maybe<OrderBy>,
  transaction_platform_status_id?: Maybe<OrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionMutationResponse {
   __typename?: 'transaction_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Transaction>,
}

export interface TransactionObjRelInsertInput {
  data: TransactionInsertInput,
  on_conflict?: Maybe<TransactionOnConflict>,
}

export interface TransactionOnConflict {
  constraint: TransactionConstraint,
  update_columns: Array<TransactionUpdateColumn>,
  where?: Maybe<TransactionBoolExp>,
}

export interface TransactionOrderBy {
  account_profiles?: Maybe<TransactionProfileGridViewOrderBy>,
  amount?: Maybe<OrderBy>,
  approved_at?: Maybe<OrderBy>,
  approved_by?: Maybe<StakeholderOrderBy>,
  approved_by_id?: Maybe<OrderBy>,
  authorized_by?: Maybe<StakeholderOrderBy>,
  authorized_by_id?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  created_at?: Maybe<OrderBy>,
  created_by?: Maybe<StakeholderOrderBy>,
  created_by_id?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  is_paid?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  master_admin_client_permission?: Maybe<MasterAdminClientPermissionOrderBy>,
  master_admin_client_permission_id?: Maybe<OrderBy>,
  master_admin_lead_permission?: Maybe<MasterAdminLeadPermissionOrderBy>,
  master_admin_lead_permission_id?: Maybe<OrderBy>,
  master_admin_partner_permission?: Maybe<MasterAdminPartnerPermissionOrderBy>,
  master_admin_partner_permission_id?: Maybe<OrderBy>,
  master_partner_client_permission?: Maybe<MasterPartnerClientPermissionOrderBy>,
  master_partner_client_permission_id?: Maybe<OrderBy>,
  master_partner_partner_permission?: Maybe<MasterPartnerPartnerPermissionOrderBy>,
  master_partner_partner_permission_id?: Maybe<OrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  memo?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
  number?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  payment?: Maybe<PaymentOrderBy>,
  payment_id?: Maybe<OrderBy>,
  request?: Maybe<RequestOrderBy>,
  request_id?: Maybe<OrderBy>,
  source_account?: Maybe<AccountOrderBy>,
  source_account_id?: Maybe<OrderBy>,
  source_currency?: Maybe<CurrencyOrderBy>,
  source_currency_id?: Maybe<OrderBy>,
  status_id?: Maybe<OrderBy>,
  target_account?: Maybe<AccountOrderBy>,
  target_account_id?: Maybe<OrderBy>,
  target_currency?: Maybe<CurrencyOrderBy>,
  target_currency_id?: Maybe<OrderBy>,
  transaction_platform_status?: Maybe<TransactionPlatformStatusOrderBy>,
  transaction_platform_status_id?: Maybe<OrderBy>,
  transaction_status?: Maybe<TransactionStatusOrderBy>,
  transaction_type?: Maybe<TransactionTypeOrderBy>,
  type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionPkColumnsInput {
  id: Scalars['String'],
}

export interface TransactionPlatformStatus {
   __typename?: 'transaction_platform_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface TransactionPlatformStatusAggregate {
   __typename?: 'transaction_platform_status_aggregate',
  aggregate?: Maybe<TransactionPlatformStatusAggregateFields>,
  nodes: Array<TransactionPlatformStatus>,
}

export interface TransactionPlatformStatusAggregateFields {
   __typename?: 'transaction_platform_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionPlatformStatusMaxFields>,
  min?: Maybe<TransactionPlatformStatusMinFields>,
}


export interface TransactionPlatformStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionPlatformStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionPlatformStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionPlatformStatusMaxOrderBy>,
  min?: Maybe<TransactionPlatformStatusMinOrderBy>,
}

export interface TransactionPlatformStatusArrRelInsertInput {
  data: Array<TransactionPlatformStatusInsertInput>,
  on_conflict?: Maybe<TransactionPlatformStatusOnConflict>,
}

export interface TransactionPlatformStatusBoolExp {
  _and?: Maybe<Array<Maybe<TransactionPlatformStatusBoolExp>>>,
  _not?: Maybe<TransactionPlatformStatusBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionPlatformStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum TransactionPlatformStatusConstraint {
  TransactionPlatformStatusNameKey = 'transaction_platform_status_name_key',
  TransactionPlatformStatusPkey = 'transaction_platform_status_pkey'
}

export interface TransactionPlatformStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionPlatformStatusMaxFields {
   __typename?: 'transaction_platform_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionPlatformStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionPlatformStatusMinFields {
   __typename?: 'transaction_platform_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionPlatformStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionPlatformStatusMutationResponse {
   __typename?: 'transaction_platform_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransactionPlatformStatus>,
}

export interface TransactionPlatformStatusObjRelInsertInput {
  data: TransactionPlatformStatusInsertInput,
  on_conflict?: Maybe<TransactionPlatformStatusOnConflict>,
}

export interface TransactionPlatformStatusOnConflict {
  constraint: TransactionPlatformStatusConstraint,
  update_columns: Array<TransactionPlatformStatusUpdateColumn>,
  where?: Maybe<TransactionPlatformStatusBoolExp>,
}

export interface TransactionPlatformStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionPlatformStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum TransactionPlatformStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface TransactionPlatformStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum TransactionPlatformStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface TransactionProfileGridView {
   __typename?: 'transaction_profile_grid_view',
  id?: Maybe<Scalars['String']>,
  source_profile_id?: Maybe<Scalars['String']>,
  target_profile_id?: Maybe<Scalars['String']>,
}

export interface TransactionProfileGridViewAggregate {
   __typename?: 'transaction_profile_grid_view_aggregate',
  aggregate?: Maybe<TransactionProfileGridViewAggregateFields>,
  nodes: Array<TransactionProfileGridView>,
}

export interface TransactionProfileGridViewAggregateFields {
   __typename?: 'transaction_profile_grid_view_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionProfileGridViewMaxFields>,
  min?: Maybe<TransactionProfileGridViewMinFields>,
}


export interface TransactionProfileGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionProfileGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionProfileGridViewAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionProfileGridViewMaxOrderBy>,
  min?: Maybe<TransactionProfileGridViewMinOrderBy>,
}

export interface TransactionProfileGridViewArrRelInsertInput {
  data: Array<TransactionProfileGridViewInsertInput>,
}

export interface TransactionProfileGridViewBoolExp {
  _and?: Maybe<Array<Maybe<TransactionProfileGridViewBoolExp>>>,
  _not?: Maybe<TransactionProfileGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionProfileGridViewBoolExp>>>,
  id?: Maybe<StringComparisonExp>,
  source_profile_id?: Maybe<StringComparisonExp>,
  target_profile_id?: Maybe<StringComparisonExp>,
}

export interface TransactionProfileGridViewInsertInput {
  id?: Maybe<Scalars['String']>,
  source_profile_id?: Maybe<Scalars['String']>,
  target_profile_id?: Maybe<Scalars['String']>,
}

export interface TransactionProfileGridViewMaxFields {
   __typename?: 'transaction_profile_grid_view_max_fields',
  id?: Maybe<Scalars['String']>,
  source_profile_id?: Maybe<Scalars['String']>,
  target_profile_id?: Maybe<Scalars['String']>,
}

export interface TransactionProfileGridViewMaxOrderBy {
  id?: Maybe<OrderBy>,
  source_profile_id?: Maybe<OrderBy>,
  target_profile_id?: Maybe<OrderBy>,
}

export interface TransactionProfileGridViewMinFields {
   __typename?: 'transaction_profile_grid_view_min_fields',
  id?: Maybe<Scalars['String']>,
  source_profile_id?: Maybe<Scalars['String']>,
  target_profile_id?: Maybe<Scalars['String']>,
}

export interface TransactionProfileGridViewMinOrderBy {
  id?: Maybe<OrderBy>,
  source_profile_id?: Maybe<OrderBy>,
  target_profile_id?: Maybe<OrderBy>,
}

export interface TransactionProfileGridViewMutationResponse {
   __typename?: 'transaction_profile_grid_view_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransactionProfileGridView>,
}

export interface TransactionProfileGridViewObjRelInsertInput {
  data: TransactionProfileGridViewInsertInput,
}

export interface TransactionProfileGridViewOrderBy {
  id?: Maybe<OrderBy>,
  source_profile_id?: Maybe<OrderBy>,
  target_profile_id?: Maybe<OrderBy>,
}

export enum TransactionProfileGridViewSelectColumn {
  Id = 'id',
  SourceProfileId = 'source_profile_id',
  TargetProfileId = 'target_profile_id'
}

export interface TransactionProfileGridViewSetInput {
  id?: Maybe<Scalars['String']>,
  source_profile_id?: Maybe<Scalars['String']>,
  target_profile_id?: Maybe<Scalars['String']>,
}

export enum TransactionSelectColumn {
  Amount = 'amount',
  ApprovedAt = 'approved_at',
  ApprovedById = 'approved_by_id',
  AuthorizedById = 'authorized_by_id',
  BankRate = 'bank_rate',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  ExchangeRate = 'exchange_rate',
  Id = 'id',
  InternalFee = 'internal_fee',
  IsPaid = 'is_paid',
  MarketExchangeRate = 'market_exchange_rate',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Memo = 'memo',
  MerchantFee = 'merchant_fee',
  Number = 'number',
  OrganizationId = 'organization_id',
  PaymentId = 'payment_id',
  RequestId = 'request_id',
  SourceAccountId = 'source_account_id',
  SourceCurrencyId = 'source_currency_id',
  StatusId = 'status_id',
  TargetAccountId = 'target_account_id',
  TargetCurrencyId = 'target_currency_id',
  TransactionPlatformStatusId = 'transaction_platform_status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface TransactionSetInput {
  amount?: Maybe<Scalars['numeric']>,
  approved_at?: Maybe<Scalars['timestamptz']>,
  approved_by_id?: Maybe<Scalars['String']>,
  authorized_by_id?: Maybe<Scalars['String']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  created_at?: Maybe<Scalars['timestamptz']>,
  created_by_id?: Maybe<Scalars['String']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  id?: Maybe<Scalars['String']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  is_paid?: Maybe<Scalars['Boolean']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  master_admin_client_permission_id?: Maybe<Scalars['String']>,
  master_admin_lead_permission_id?: Maybe<Scalars['String']>,
  master_admin_partner_permission_id?: Maybe<Scalars['String']>,
  master_partner_client_permission_id?: Maybe<Scalars['String']>,
  master_partner_partner_permission_id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  memo?: Maybe<Scalars['String']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
  number?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  payment_id?: Maybe<Scalars['String']>,
  request_id?: Maybe<Scalars['String']>,
  source_account_id?: Maybe<Scalars['String']>,
  source_currency_id?: Maybe<Scalars['String']>,
  status_id?: Maybe<Scalars['String']>,
  target_account_id?: Maybe<Scalars['String']>,
  target_currency_id?: Maybe<Scalars['String']>,
  transaction_platform_status_id?: Maybe<Scalars['String']>,
  type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionStatus {
   __typename?: 'transaction_status',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface TransactionStatusAggregate {
   __typename?: 'transaction_status_aggregate',
  aggregate?: Maybe<TransactionStatusAggregateFields>,
  nodes: Array<TransactionStatus>,
}

export interface TransactionStatusAggregateFields {
   __typename?: 'transaction_status_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionStatusMaxFields>,
  min?: Maybe<TransactionStatusMinFields>,
}


export interface TransactionStatusAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionStatusSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionStatusAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionStatusMaxOrderBy>,
  min?: Maybe<TransactionStatusMinOrderBy>,
}

export interface TransactionStatusArrRelInsertInput {
  data: Array<TransactionStatusInsertInput>,
  on_conflict?: Maybe<TransactionStatusOnConflict>,
}

export interface TransactionStatusBoolExp {
  _and?: Maybe<Array<Maybe<TransactionStatusBoolExp>>>,
  _not?: Maybe<TransactionStatusBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionStatusBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum TransactionStatusConstraint {
  TransactionStatusNameKey = 'transaction_status_name_key',
  TransactionStatusPkey = 'transaction_status_pkey'
}

export interface TransactionStatusInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionStatusMaxFields {
   __typename?: 'transaction_status_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionStatusMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionStatusMinFields {
   __typename?: 'transaction_status_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionStatusMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionStatusMutationResponse {
   __typename?: 'transaction_status_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransactionStatus>,
}

export interface TransactionStatusObjRelInsertInput {
  data: TransactionStatusInsertInput,
  on_conflict?: Maybe<TransactionStatusOnConflict>,
}

export interface TransactionStatusOnConflict {
  constraint: TransactionStatusConstraint,
  update_columns: Array<TransactionStatusUpdateColumn>,
  where?: Maybe<TransactionStatusBoolExp>,
}

export interface TransactionStatusOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionStatusPkColumnsInput {
  id: Scalars['String'],
}

export enum TransactionStatusSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface TransactionStatusSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum TransactionStatusUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface TransactionStddevFields {
   __typename?: 'transaction_stddev_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionStddevOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionStddevPopFields {
   __typename?: 'transaction_stddev_pop_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionStddevPopOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionStddevSampFields {
   __typename?: 'transaction_stddev_samp_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionStddevSampOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionSumFields {
   __typename?: 'transaction_sum_fields',
  amount?: Maybe<Scalars['numeric']>,
  bank_rate?: Maybe<Scalars['numeric']>,
  exchange_rate?: Maybe<Scalars['numeric']>,
  internal_fee?: Maybe<Scalars['numeric']>,
  market_exchange_rate?: Maybe<Scalars['numeric']>,
  merchant_fee?: Maybe<Scalars['numeric']>,
}

export interface TransactionSumOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionType {
   __typename?: 'transaction_type',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  master_user_permission: MasterUserPermission,
  master_user_permission_id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface TransactionTypeAggregate {
   __typename?: 'transaction_type_aggregate',
  aggregate?: Maybe<TransactionTypeAggregateFields>,
  nodes: Array<TransactionType>,
}

export interface TransactionTypeAggregateFields {
   __typename?: 'transaction_type_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransactionTypeMaxFields>,
  min?: Maybe<TransactionTypeMinFields>,
}


export interface TransactionTypeAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransactionTypeSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransactionTypeAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<TransactionTypeMaxOrderBy>,
  min?: Maybe<TransactionTypeMinOrderBy>,
}

export interface TransactionTypeArrRelInsertInput {
  data: Array<TransactionTypeInsertInput>,
  on_conflict?: Maybe<TransactionTypeOnConflict>,
}

export interface TransactionTypeBoolExp {
  _and?: Maybe<Array<Maybe<TransactionTypeBoolExp>>>,
  _not?: Maybe<TransactionTypeBoolExp>,
  _or?: Maybe<Array<Maybe<TransactionTypeBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  master_user_permission?: Maybe<MasterUserPermissionBoolExp>,
  master_user_permission_id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum TransactionTypeConstraint {
  TransactionTypeNameKey = 'transaction_type_name_key',
  TransactionTypePkey = 'transaction_type_pkey'
}

export interface TransactionTypeInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_user_permission?: Maybe<MasterUserPermissionObjRelInsertInput>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionTypeMaxFields {
   __typename?: 'transaction_type_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionTypeMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionTypeMinFields {
   __typename?: 'transaction_type_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface TransactionTypeMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionTypeMutationResponse {
   __typename?: 'transaction_type_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransactionType>,
}

export interface TransactionTypeObjRelInsertInput {
  data: TransactionTypeInsertInput,
  on_conflict?: Maybe<TransactionTypeOnConflict>,
}

export interface TransactionTypeOnConflict {
  constraint: TransactionTypeConstraint,
  update_columns: Array<TransactionTypeUpdateColumn>,
  where?: Maybe<TransactionTypeBoolExp>,
}

export interface TransactionTypeOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  master_user_permission?: Maybe<MasterUserPermissionOrderBy>,
  master_user_permission_id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface TransactionTypePkColumnsInput {
  id: Scalars['String'],
}

export enum TransactionTypeSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface TransactionTypeSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  master_user_permission_id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum TransactionTypeUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  MasterUserPermissionId = 'master_user_permission_id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export enum TransactionUpdateColumn {
  Amount = 'amount',
  ApprovedAt = 'approved_at',
  ApprovedById = 'approved_by_id',
  AuthorizedById = 'authorized_by_id',
  BankRate = 'bank_rate',
  CreatedAt = 'created_at',
  CreatedById = 'created_by_id',
  ExchangeRate = 'exchange_rate',
  Id = 'id',
  InternalFee = 'internal_fee',
  IsPaid = 'is_paid',
  MarketExchangeRate = 'market_exchange_rate',
  MasterAdminClientPermissionId = 'master_admin_client_permission_id',
  MasterAdminLeadPermissionId = 'master_admin_lead_permission_id',
  MasterAdminPartnerPermissionId = 'master_admin_partner_permission_id',
  MasterPartnerClientPermissionId = 'master_partner_client_permission_id',
  MasterPartnerPartnerPermissionId = 'master_partner_partner_permission_id',
  MasterUserPermissionId = 'master_user_permission_id',
  Memo = 'memo',
  MerchantFee = 'merchant_fee',
  Number = 'number',
  OrganizationId = 'organization_id',
  PaymentId = 'payment_id',
  RequestId = 'request_id',
  SourceAccountId = 'source_account_id',
  SourceCurrencyId = 'source_currency_id',
  StatusId = 'status_id',
  TargetAccountId = 'target_account_id',
  TargetCurrencyId = 'target_currency_id',
  TransactionPlatformStatusId = 'transaction_platform_status_id',
  TypeId = 'type_id',
  UpdatedAt = 'updated_at'
}

export interface TransactionVarPopFields {
   __typename?: 'transaction_var_pop_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionVarPopOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionVarSampFields {
   __typename?: 'transaction_var_samp_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionVarSampOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransactionVarianceFields {
   __typename?: 'transaction_variance_fields',
  amount?: Maybe<Scalars['Float']>,
  bank_rate?: Maybe<Scalars['Float']>,
  exchange_rate?: Maybe<Scalars['Float']>,
  internal_fee?: Maybe<Scalars['Float']>,
  market_exchange_rate?: Maybe<Scalars['Float']>,
  merchant_fee?: Maybe<Scalars['Float']>,
}

export interface TransactionVarianceOrderBy {
  amount?: Maybe<OrderBy>,
  bank_rate?: Maybe<OrderBy>,
  exchange_rate?: Maybe<OrderBy>,
  internal_fee?: Maybe<OrderBy>,
  market_exchange_rate?: Maybe<OrderBy>,
  merchant_fee?: Maybe<OrderBy>,
}

export interface TransferApprovalOption {
   __typename?: 'transfer_approval_option',
  id: Scalars['Int'],
  name: Scalars['String'],
}

export interface TransferApprovalOptionAggregate {
   __typename?: 'transfer_approval_option_aggregate',
  aggregate?: Maybe<TransferApprovalOptionAggregateFields>,
  nodes: Array<TransferApprovalOption>,
}

export interface TransferApprovalOptionAggregateFields {
   __typename?: 'transfer_approval_option_aggregate_fields',
  avg?: Maybe<TransferApprovalOptionAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<TransferApprovalOptionMaxFields>,
  min?: Maybe<TransferApprovalOptionMinFields>,
  stddev?: Maybe<TransferApprovalOptionStddevFields>,
  stddev_pop?: Maybe<TransferApprovalOptionStddevPopFields>,
  stddev_samp?: Maybe<TransferApprovalOptionStddevSampFields>,
  sum?: Maybe<TransferApprovalOptionSumFields>,
  var_pop?: Maybe<TransferApprovalOptionVarPopFields>,
  var_samp?: Maybe<TransferApprovalOptionVarSampFields>,
  variance?: Maybe<TransferApprovalOptionVarianceFields>,
}


export interface TransferApprovalOptionAggregateFieldsCountArgs {
  columns?: Maybe<Array<TransferApprovalOptionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface TransferApprovalOptionAggregateOrderBy {
  avg?: Maybe<TransferApprovalOptionAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<TransferApprovalOptionMaxOrderBy>,
  min?: Maybe<TransferApprovalOptionMinOrderBy>,
  stddev?: Maybe<TransferApprovalOptionStddevOrderBy>,
  stddev_pop?: Maybe<TransferApprovalOptionStddevPopOrderBy>,
  stddev_samp?: Maybe<TransferApprovalOptionStddevSampOrderBy>,
  sum?: Maybe<TransferApprovalOptionSumOrderBy>,
  var_pop?: Maybe<TransferApprovalOptionVarPopOrderBy>,
  var_samp?: Maybe<TransferApprovalOptionVarSampOrderBy>,
  variance?: Maybe<TransferApprovalOptionVarianceOrderBy>,
}

export interface TransferApprovalOptionArrRelInsertInput {
  data: Array<TransferApprovalOptionInsertInput>,
  on_conflict?: Maybe<TransferApprovalOptionOnConflict>,
}

export interface TransferApprovalOptionAvgFields {
   __typename?: 'transfer_approval_option_avg_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionAvgOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionBoolExp {
  _and?: Maybe<Array<Maybe<TransferApprovalOptionBoolExp>>>,
  _not?: Maybe<TransferApprovalOptionBoolExp>,
  _or?: Maybe<Array<Maybe<TransferApprovalOptionBoolExp>>>,
  id?: Maybe<IntComparisonExp>,
  name?: Maybe<StringComparisonExp>,
}

export enum TransferApprovalOptionConstraint {
  TransferApprovalOptionNameKey = 'transfer_approval_option_name_key',
  TransferApprovalOptionPkey = 'transfer_approval_option_pkey'
}

export interface TransferApprovalOptionIncInput {
  id?: Maybe<Scalars['Int']>,
}

export interface TransferApprovalOptionInsertInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface TransferApprovalOptionMaxFields {
   __typename?: 'transfer_approval_option_max_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface TransferApprovalOptionMaxOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionMinFields {
   __typename?: 'transfer_approval_option_min_fields',
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface TransferApprovalOptionMinOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionMutationResponse {
   __typename?: 'transfer_approval_option_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<TransferApprovalOption>,
}

export interface TransferApprovalOptionObjRelInsertInput {
  data: TransferApprovalOptionInsertInput,
  on_conflict?: Maybe<TransferApprovalOptionOnConflict>,
}

export interface TransferApprovalOptionOnConflict {
  constraint: TransferApprovalOptionConstraint,
  update_columns: Array<TransferApprovalOptionUpdateColumn>,
  where?: Maybe<TransferApprovalOptionBoolExp>,
}

export interface TransferApprovalOptionOrderBy {
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionPkColumnsInput {
  id: Scalars['Int'],
}

export enum TransferApprovalOptionSelectColumn {
  Id = 'id',
  Name = 'name'
}

export interface TransferApprovalOptionSetInput {
  id?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
}

export interface TransferApprovalOptionStddevFields {
   __typename?: 'transfer_approval_option_stddev_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionStddevOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionStddevPopFields {
   __typename?: 'transfer_approval_option_stddev_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionStddevPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionStddevSampFields {
   __typename?: 'transfer_approval_option_stddev_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionStddevSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionSumFields {
   __typename?: 'transfer_approval_option_sum_fields',
  id?: Maybe<Scalars['Int']>,
}

export interface TransferApprovalOptionSumOrderBy {
  id?: Maybe<OrderBy>,
}

export enum TransferApprovalOptionUpdateColumn {
  Id = 'id',
  Name = 'name'
}

export interface TransferApprovalOptionVarPopFields {
   __typename?: 'transfer_approval_option_var_pop_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionVarPopOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionVarSampFields {
   __typename?: 'transfer_approval_option_var_samp_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionVarSampOrderBy {
  id?: Maybe<OrderBy>,
}

export interface TransferApprovalOptionVarianceFields {
   __typename?: 'transfer_approval_option_variance_fields',
  id?: Maybe<Scalars['Float']>,
}

export interface TransferApprovalOptionVarianceOrderBy {
  id?: Maybe<OrderBy>,
}

export interface UserAccess {
   __typename?: 'user_access',
  code: Scalars['Int'],
  status: Scalars['String'],
}

export interface UserAccessAggregate {
   __typename?: 'user_access_aggregate',
  aggregate?: Maybe<UserAccessAggregateFields>,
  nodes: Array<UserAccess>,
}

export interface UserAccessAggregateFields {
   __typename?: 'user_access_aggregate_fields',
  avg?: Maybe<UserAccessAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<UserAccessMaxFields>,
  min?: Maybe<UserAccessMinFields>,
  stddev?: Maybe<UserAccessStddevFields>,
  stddev_pop?: Maybe<UserAccessStddevPopFields>,
  stddev_samp?: Maybe<UserAccessStddevSampFields>,
  sum?: Maybe<UserAccessSumFields>,
  var_pop?: Maybe<UserAccessVarPopFields>,
  var_samp?: Maybe<UserAccessVarSampFields>,
  variance?: Maybe<UserAccessVarianceFields>,
}


export interface UserAccessAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserAccessSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface UserAccessAggregateOrderBy {
  avg?: Maybe<UserAccessAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<UserAccessMaxOrderBy>,
  min?: Maybe<UserAccessMinOrderBy>,
  stddev?: Maybe<UserAccessStddevOrderBy>,
  stddev_pop?: Maybe<UserAccessStddevPopOrderBy>,
  stddev_samp?: Maybe<UserAccessStddevSampOrderBy>,
  sum?: Maybe<UserAccessSumOrderBy>,
  var_pop?: Maybe<UserAccessVarPopOrderBy>,
  var_samp?: Maybe<UserAccessVarSampOrderBy>,
  variance?: Maybe<UserAccessVarianceOrderBy>,
}

export interface UserAccessArrRelInsertInput {
  data: Array<UserAccessInsertInput>,
  on_conflict?: Maybe<UserAccessOnConflict>,
}

export interface UserAccessAvgFields {
   __typename?: 'user_access_avg_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessAvgOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessBoolExp {
  _and?: Maybe<Array<Maybe<UserAccessBoolExp>>>,
  _not?: Maybe<UserAccessBoolExp>,
  _or?: Maybe<Array<Maybe<UserAccessBoolExp>>>,
  code?: Maybe<IntComparisonExp>,
  status?: Maybe<StringComparisonExp>,
}

export enum UserAccessConstraint {
  UserAccessPkey = 'user_access_pkey'
}

export interface UserAccessIncInput {
  code?: Maybe<Scalars['Int']>,
}

export interface UserAccessInsertInput {
  code?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
}

export interface UserAccessMaxFields {
   __typename?: 'user_access_max_fields',
  code?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
}

export interface UserAccessMaxOrderBy {
  code?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
}

export interface UserAccessMinFields {
   __typename?: 'user_access_min_fields',
  code?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
}

export interface UserAccessMinOrderBy {
  code?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
}

export interface UserAccessMutationResponse {
   __typename?: 'user_access_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<UserAccess>,
}

export interface UserAccessObjRelInsertInput {
  data: UserAccessInsertInput,
  on_conflict?: Maybe<UserAccessOnConflict>,
}

export interface UserAccessOnConflict {
  constraint: UserAccessConstraint,
  update_columns: Array<UserAccessUpdateColumn>,
  where?: Maybe<UserAccessBoolExp>,
}

export interface UserAccessOrderBy {
  code?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
}

export interface UserAccessPkColumnsInput {
  code: Scalars['Int'],
}

export enum UserAccessSelectColumn {
  Code = 'code',
  Status = 'status'
}

export interface UserAccessSetInput {
  code?: Maybe<Scalars['Int']>,
  status?: Maybe<Scalars['String']>,
}

export interface UserAccessStddevFields {
   __typename?: 'user_access_stddev_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessStddevOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessStddevPopFields {
   __typename?: 'user_access_stddev_pop_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessStddevPopOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessStddevSampFields {
   __typename?: 'user_access_stddev_samp_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessStddevSampOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessSumFields {
   __typename?: 'user_access_sum_fields',
  code?: Maybe<Scalars['Int']>,
}

export interface UserAccessSumOrderBy {
  code?: Maybe<OrderBy>,
}

export enum UserAccessUpdateColumn {
  Code = 'code',
  Status = 'status'
}

export interface UserAccessVarPopFields {
   __typename?: 'user_access_var_pop_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessVarPopOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessVarSampFields {
   __typename?: 'user_access_var_samp_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessVarSampOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserAccessVarianceFields {
   __typename?: 'user_access_variance_fields',
  code?: Maybe<Scalars['Float']>,
}

export interface UserAccessVarianceOrderBy {
  code?: Maybe<OrderBy>,
}

export interface UserInvite {
   __typename?: 'user_invite',
  created_at: Scalars['timestamptz'],
  expires_at: Scalars['timestamptz'],
  id: Scalars['String'],
}

export interface UserInviteAggregate {
   __typename?: 'user_invite_aggregate',
  aggregate?: Maybe<UserInviteAggregateFields>,
  nodes: Array<UserInvite>,
}

export interface UserInviteAggregateFields {
   __typename?: 'user_invite_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<UserInviteMaxFields>,
  min?: Maybe<UserInviteMinFields>,
}


export interface UserInviteAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserInviteSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface UserInviteAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<UserInviteMaxOrderBy>,
  min?: Maybe<UserInviteMinOrderBy>,
}

export interface UserInviteArrRelInsertInput {
  data: Array<UserInviteInsertInput>,
  on_conflict?: Maybe<UserInviteOnConflict>,
}

export interface UserInviteBoolExp {
  _and?: Maybe<Array<Maybe<UserInviteBoolExp>>>,
  _not?: Maybe<UserInviteBoolExp>,
  _or?: Maybe<Array<Maybe<UserInviteBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  expires_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
}

export enum UserInviteConstraint {
  UserInvitePkey = 'user_invite_pkey'
}

export interface UserInviteInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
}

export interface UserInviteMaxFields {
   __typename?: 'user_invite_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
}

export interface UserInviteMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface UserInviteMinFields {
   __typename?: 'user_invite_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
}

export interface UserInviteMinOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface UserInviteMutationResponse {
   __typename?: 'user_invite_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<UserInvite>,
}

export interface UserInviteObjRelInsertInput {
  data: UserInviteInsertInput,
  on_conflict?: Maybe<UserInviteOnConflict>,
}

export interface UserInviteOnConflict {
  constraint: UserInviteConstraint,
  update_columns: Array<UserInviteUpdateColumn>,
  where?: Maybe<UserInviteBoolExp>,
}

export interface UserInviteOrderBy {
  created_at?: Maybe<OrderBy>,
  expires_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
}

export interface UserInvitePkColumnsInput {
  id: Scalars['String'],
}

export enum UserInviteSelectColumn {
  CreatedAt = 'created_at',
  ExpiresAt = 'expires_at',
  Id = 'id'
}

export interface UserInviteSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  expires_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
}

export enum UserInviteUpdateColumn {
  CreatedAt = 'created_at',
  ExpiresAt = 'expires_at',
  Id = 'id'
}

export interface UserManagementGridView {
   __typename?: 'user_management_grid_view',
  class?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profile?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['String']>,
  stakeholder?: Maybe<Stakeholder>,
  status?: Maybe<Scalars['Int']>,
  total_profiles?: Maybe<Scalars['bigint']>,
  total_roles?: Maybe<Scalars['Int']>,
}

export interface UserManagementGridViewAggregate {
   __typename?: 'user_management_grid_view_aggregate',
  aggregate?: Maybe<UserManagementGridViewAggregateFields>,
  nodes: Array<UserManagementGridView>,
}

export interface UserManagementGridViewAggregateFields {
   __typename?: 'user_management_grid_view_aggregate_fields',
  avg?: Maybe<UserManagementGridViewAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<UserManagementGridViewMaxFields>,
  min?: Maybe<UserManagementGridViewMinFields>,
  stddev?: Maybe<UserManagementGridViewStddevFields>,
  stddev_pop?: Maybe<UserManagementGridViewStddevPopFields>,
  stddev_samp?: Maybe<UserManagementGridViewStddevSampFields>,
  sum?: Maybe<UserManagementGridViewSumFields>,
  var_pop?: Maybe<UserManagementGridViewVarPopFields>,
  var_samp?: Maybe<UserManagementGridViewVarSampFields>,
  variance?: Maybe<UserManagementGridViewVarianceFields>,
}


export interface UserManagementGridViewAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserManagementGridViewSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface UserManagementGridViewAggregateOrderBy {
  avg?: Maybe<UserManagementGridViewAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<UserManagementGridViewMaxOrderBy>,
  min?: Maybe<UserManagementGridViewMinOrderBy>,
  stddev?: Maybe<UserManagementGridViewStddevOrderBy>,
  stddev_pop?: Maybe<UserManagementGridViewStddevPopOrderBy>,
  stddev_samp?: Maybe<UserManagementGridViewStddevSampOrderBy>,
  sum?: Maybe<UserManagementGridViewSumOrderBy>,
  var_pop?: Maybe<UserManagementGridViewVarPopOrderBy>,
  var_samp?: Maybe<UserManagementGridViewVarSampOrderBy>,
  variance?: Maybe<UserManagementGridViewVarianceOrderBy>,
}

export interface UserManagementGridViewAvgFields {
   __typename?: 'user_management_grid_view_avg_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewAvgOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewBoolExp {
  _and?: Maybe<Array<Maybe<UserManagementGridViewBoolExp>>>,
  _not?: Maybe<UserManagementGridViewBoolExp>,
  _or?: Maybe<Array<Maybe<UserManagementGridViewBoolExp>>>,
  class?: Maybe<StringComparisonExp>,
  email?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  profile?: Maybe<StringComparisonExp>,
  role?: Maybe<StringComparisonExp>,
  stakeholder?: Maybe<StakeholderBoolExp>,
  status?: Maybe<IntComparisonExp>,
  total_profiles?: Maybe<BigintComparisonExp>,
  total_roles?: Maybe<IntComparisonExp>,
}

export interface UserManagementGridViewMaxFields {
   __typename?: 'user_management_grid_view_max_fields',
  class?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profile?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Int']>,
  total_profiles?: Maybe<Scalars['bigint']>,
  total_roles?: Maybe<Scalars['Int']>,
}

export interface UserManagementGridViewMaxOrderBy {
  class?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  role?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewMinFields {
   __typename?: 'user_management_grid_view_min_fields',
  class?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  profile?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Int']>,
  total_profiles?: Maybe<Scalars['bigint']>,
  total_roles?: Maybe<Scalars['Int']>,
}

export interface UserManagementGridViewMinOrderBy {
  class?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  role?: Maybe<OrderBy>,
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewOrderBy {
  class?: Maybe<OrderBy>,
  email?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  profile?: Maybe<OrderBy>,
  role?: Maybe<OrderBy>,
  stakeholder?: Maybe<StakeholderOrderBy>,
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export enum UserManagementGridViewSelectColumn {
  Class = 'class',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Profile = 'profile',
  Role = 'role',
  Status = 'status',
  TotalProfiles = 'total_profiles',
  TotalRoles = 'total_roles'
}

export interface UserManagementGridViewStddevFields {
   __typename?: 'user_management_grid_view_stddev_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewStddevOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewStddevPopFields {
   __typename?: 'user_management_grid_view_stddev_pop_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewStddevPopOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewStddevSampFields {
   __typename?: 'user_management_grid_view_stddev_samp_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewStddevSampOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewSumFields {
   __typename?: 'user_management_grid_view_sum_fields',
  status?: Maybe<Scalars['Int']>,
  total_profiles?: Maybe<Scalars['bigint']>,
  total_roles?: Maybe<Scalars['Int']>,
}

export interface UserManagementGridViewSumOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewVarPopFields {
   __typename?: 'user_management_grid_view_var_pop_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewVarPopOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewVarSampFields {
   __typename?: 'user_management_grid_view_var_samp_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewVarSampOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserManagementGridViewVarianceFields {
   __typename?: 'user_management_grid_view_variance_fields',
  status?: Maybe<Scalars['Float']>,
  total_profiles?: Maybe<Scalars['Float']>,
  total_roles?: Maybe<Scalars['Float']>,
}

export interface UserManagementGridViewVarianceOrderBy {
  status?: Maybe<OrderBy>,
  total_profiles?: Maybe<OrderBy>,
  total_roles?: Maybe<OrderBy>,
}

export interface UserPermission {
   __typename?: 'user_permission',
  account_list: Scalars['String'],
  account_require_approval: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  manage_account: Scalars['String'],
  manage_stakeholder_file: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  stakeholder_file_list: Scalars['String'],
  transaction_credit: Scalars['String'],
  transaction_deposit: Scalars['String'],
  transaction_list: Scalars['String'],
  transaction_service_fee: Scalars['String'],
  transaction_transfer: Scalars['String'],
  transaction_transfer_between_profiles: Scalars['String'],
  transaction_withdrawal: Scalars['String'],
}

export interface UserPermissionAggregate {
   __typename?: 'user_permission_aggregate',
  aggregate?: Maybe<UserPermissionAggregateFields>,
  nodes: Array<UserPermission>,
}

export interface UserPermissionAggregateFields {
   __typename?: 'user_permission_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<UserPermissionMaxFields>,
  min?: Maybe<UserPermissionMinFields>,
}


export interface UserPermissionAggregateFieldsCountArgs {
  columns?: Maybe<Array<UserPermissionSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface UserPermissionAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<UserPermissionMaxOrderBy>,
  min?: Maybe<UserPermissionMinOrderBy>,
}

export interface UserPermissionArrRelInsertInput {
  data: Array<UserPermissionInsertInput>,
  on_conflict?: Maybe<UserPermissionOnConflict>,
}

export interface UserPermissionBoolExp {
  _and?: Maybe<Array<Maybe<UserPermissionBoolExp>>>,
  _not?: Maybe<UserPermissionBoolExp>,
  _or?: Maybe<Array<Maybe<UserPermissionBoolExp>>>,
  account_list?: Maybe<StringComparisonExp>,
  account_require_approval?: Maybe<StringComparisonExp>,
  description?: Maybe<StringComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  manage_account?: Maybe<StringComparisonExp>,
  manage_stakeholder_file?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  stakeholder_file_list?: Maybe<StringComparisonExp>,
  transaction_credit?: Maybe<StringComparisonExp>,
  transaction_deposit?: Maybe<StringComparisonExp>,
  transaction_list?: Maybe<StringComparisonExp>,
  transaction_service_fee?: Maybe<StringComparisonExp>,
  transaction_transfer?: Maybe<StringComparisonExp>,
  transaction_transfer_between_profiles?: Maybe<StringComparisonExp>,
  transaction_withdrawal?: Maybe<StringComparisonExp>,
}

export enum UserPermissionConstraint {
  UserPermissionPkey = 'user_permission_pkey'
}

export interface UserPermissionInsertInput {
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface UserPermissionMaxFields {
   __typename?: 'user_permission_max_fields',
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface UserPermissionMaxOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface UserPermissionMinFields {
   __typename?: 'user_permission_min_fields',
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export interface UserPermissionMinOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface UserPermissionMutationResponse {
   __typename?: 'user_permission_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<UserPermission>,
}

export interface UserPermissionObjRelInsertInput {
  data: UserPermissionInsertInput,
  on_conflict?: Maybe<UserPermissionOnConflict>,
}

export interface UserPermissionOnConflict {
  constraint: UserPermissionConstraint,
  update_columns: Array<UserPermissionUpdateColumn>,
  where?: Maybe<UserPermissionBoolExp>,
}

export interface UserPermissionOrderBy {
  account_list?: Maybe<OrderBy>,
  account_require_approval?: Maybe<OrderBy>,
  description?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  manage_account?: Maybe<OrderBy>,
  manage_stakeholder_file?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  stakeholder_file_list?: Maybe<OrderBy>,
  transaction_credit?: Maybe<OrderBy>,
  transaction_deposit?: Maybe<OrderBy>,
  transaction_list?: Maybe<OrderBy>,
  transaction_service_fee?: Maybe<OrderBy>,
  transaction_transfer?: Maybe<OrderBy>,
  transaction_transfer_between_profiles?: Maybe<OrderBy>,
  transaction_withdrawal?: Maybe<OrderBy>,
}

export interface UserPermissionPkColumnsInput {
  id: Scalars['String'],
}

export enum UserPermissionSelectColumn {
  AccountList = 'account_list',
  AccountRequireApproval = 'account_require_approval',
  Description = 'description',
  Id = 'id',
  ManageAccount = 'manage_account',
  ManageStakeholderFile = 'manage_stakeholder_file',
  Name = 'name',
  OrganizationId = 'organization_id',
  StakeholderFileList = 'stakeholder_file_list',
  TransactionCredit = 'transaction_credit',
  TransactionDeposit = 'transaction_deposit',
  TransactionList = 'transaction_list',
  TransactionServiceFee = 'transaction_service_fee',
  TransactionTransfer = 'transaction_transfer',
  TransactionTransferBetweenProfiles = 'transaction_transfer_between_profiles',
  TransactionWithdrawal = 'transaction_withdrawal'
}

export interface UserPermissionSetInput {
  account_list?: Maybe<Scalars['String']>,
  account_require_approval?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  manage_account?: Maybe<Scalars['String']>,
  manage_stakeholder_file?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  stakeholder_file_list?: Maybe<Scalars['String']>,
  transaction_credit?: Maybe<Scalars['String']>,
  transaction_deposit?: Maybe<Scalars['String']>,
  transaction_list?: Maybe<Scalars['String']>,
  transaction_service_fee?: Maybe<Scalars['String']>,
  transaction_transfer?: Maybe<Scalars['String']>,
  transaction_transfer_between_profiles?: Maybe<Scalars['String']>,
  transaction_withdrawal?: Maybe<Scalars['String']>,
}

export enum UserPermissionUpdateColumn {
  AccountList = 'account_list',
  AccountRequireApproval = 'account_require_approval',
  Description = 'description',
  Id = 'id',
  ManageAccount = 'manage_account',
  ManageStakeholderFile = 'manage_stakeholder_file',
  Name = 'name',
  OrganizationId = 'organization_id',
  StakeholderFileList = 'stakeholder_file_list',
  TransactionCredit = 'transaction_credit',
  TransactionDeposit = 'transaction_deposit',
  TransactionList = 'transaction_list',
  TransactionServiceFee = 'transaction_service_fee',
  TransactionTransfer = 'transaction_transfer',
  TransactionTransferBetweenProfiles = 'transaction_transfer_between_profiles',
  TransactionWithdrawal = 'transaction_withdrawal'
}


export interface Workflow {
   __typename?: 'workflow',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  organization: Organization,
  organization_id: Scalars['String'],
  profile_type: ProfileType,
  profile_type_id: Scalars['String'],
  stages: Array<WorkflowStage>,
  stages_aggregate: WorkflowStageAggregate,
  updated_at: Scalars['timestamptz'],
}


export interface WorkflowStagesArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}


export interface WorkflowStagesAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowStageSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowStageOrderBy>>,
  where?: Maybe<WorkflowStageBoolExp>
}

export interface WorkflowAggregate {
   __typename?: 'workflow_aggregate',
  aggregate?: Maybe<WorkflowAggregateFields>,
  nodes: Array<Workflow>,
}

export interface WorkflowAggregateFields {
   __typename?: 'workflow_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<WorkflowMaxFields>,
  min?: Maybe<WorkflowMinFields>,
}


export interface WorkflowAggregateFieldsCountArgs {
  columns?: Maybe<Array<WorkflowSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface WorkflowAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<WorkflowMaxOrderBy>,
  min?: Maybe<WorkflowMinOrderBy>,
}

export interface WorkflowArrRelInsertInput {
  data: Array<WorkflowInsertInput>,
  on_conflict?: Maybe<WorkflowOnConflict>,
}

export interface WorkflowBoolExp {
  _and?: Maybe<Array<Maybe<WorkflowBoolExp>>>,
  _not?: Maybe<WorkflowBoolExp>,
  _or?: Maybe<Array<Maybe<WorkflowBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  organization?: Maybe<OrganizationBoolExp>,
  organization_id?: Maybe<StringComparisonExp>,
  profile_type?: Maybe<ProfileTypeBoolExp>,
  profile_type_id?: Maybe<StringComparisonExp>,
  stages?: Maybe<WorkflowStageBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum WorkflowConstraint {
  WorkflowPkey = 'workflow_pkey'
}

export interface WorkflowInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization?: Maybe<OrganizationObjRelInsertInput>,
  organization_id?: Maybe<Scalars['String']>,
  profile_type?: Maybe<ProfileTypeObjRelInsertInput>,
  profile_type_id?: Maybe<Scalars['String']>,
  stages?: Maybe<WorkflowStageArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface WorkflowMaxFields {
   __typename?: 'workflow_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  profile_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface WorkflowMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  profile_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface WorkflowMinFields {
   __typename?: 'workflow_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  profile_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface WorkflowMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization_id?: Maybe<OrderBy>,
  profile_type_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface WorkflowMutationResponse {
   __typename?: 'workflow_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<Workflow>,
}

export interface WorkflowObjRelInsertInput {
  data: WorkflowInsertInput,
  on_conflict?: Maybe<WorkflowOnConflict>,
}

export interface WorkflowOnConflict {
  constraint: WorkflowConstraint,
  update_columns: Array<WorkflowUpdateColumn>,
  where?: Maybe<WorkflowBoolExp>,
}

export interface WorkflowOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  organization?: Maybe<OrganizationOrderBy>,
  organization_id?: Maybe<OrderBy>,
  profile_type?: Maybe<ProfileTypeOrderBy>,
  profile_type_id?: Maybe<OrderBy>,
  stages_aggregate?: Maybe<WorkflowStageAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface WorkflowPkColumnsInput {
  id: Scalars['String'],
}

export enum WorkflowSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  ProfileTypeId = 'profile_type_id',
  UpdatedAt = 'updated_at'
}

export interface WorkflowSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  organization_id?: Maybe<Scalars['String']>,
  profile_type_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface WorkflowStage {
   __typename?: 'workflow_stage',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  order: Scalars['Int'],
  profiles: Array<WorkflowsToProfiles>,
  profiles_aggregate: WorkflowsToProfilesAggregate,
  updated_at: Scalars['timestamptz'],
  workflow: Workflow,
  workflow_id: Scalars['String'],
}


export interface WorkflowStageProfilesArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}


export interface WorkflowStageProfilesAggregateArgs {
  distinct_on?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  limit?: Maybe<Scalars['Int']>,
  offset?: Maybe<Scalars['Int']>,
  order_by?: Maybe<Array<WorkflowsToProfilesOrderBy>>,
  where?: Maybe<WorkflowsToProfilesBoolExp>
}

export interface WorkflowStageAggregate {
   __typename?: 'workflow_stage_aggregate',
  aggregate?: Maybe<WorkflowStageAggregateFields>,
  nodes: Array<WorkflowStage>,
}

export interface WorkflowStageAggregateFields {
   __typename?: 'workflow_stage_aggregate_fields',
  avg?: Maybe<WorkflowStageAvgFields>,
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<WorkflowStageMaxFields>,
  min?: Maybe<WorkflowStageMinFields>,
  stddev?: Maybe<WorkflowStageStddevFields>,
  stddev_pop?: Maybe<WorkflowStageStddevPopFields>,
  stddev_samp?: Maybe<WorkflowStageStddevSampFields>,
  sum?: Maybe<WorkflowStageSumFields>,
  var_pop?: Maybe<WorkflowStageVarPopFields>,
  var_samp?: Maybe<WorkflowStageVarSampFields>,
  variance?: Maybe<WorkflowStageVarianceFields>,
}


export interface WorkflowStageAggregateFieldsCountArgs {
  columns?: Maybe<Array<WorkflowStageSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface WorkflowStageAggregateOrderBy {
  avg?: Maybe<WorkflowStageAvgOrderBy>,
  count?: Maybe<OrderBy>,
  max?: Maybe<WorkflowStageMaxOrderBy>,
  min?: Maybe<WorkflowStageMinOrderBy>,
  stddev?: Maybe<WorkflowStageStddevOrderBy>,
  stddev_pop?: Maybe<WorkflowStageStddevPopOrderBy>,
  stddev_samp?: Maybe<WorkflowStageStddevSampOrderBy>,
  sum?: Maybe<WorkflowStageSumOrderBy>,
  var_pop?: Maybe<WorkflowStageVarPopOrderBy>,
  var_samp?: Maybe<WorkflowStageVarSampOrderBy>,
  variance?: Maybe<WorkflowStageVarianceOrderBy>,
}

export interface WorkflowStageArrRelInsertInput {
  data: Array<WorkflowStageInsertInput>,
  on_conflict?: Maybe<WorkflowStageOnConflict>,
}

export interface WorkflowStageAvgFields {
   __typename?: 'workflow_stage_avg_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageAvgOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageBoolExp {
  _and?: Maybe<Array<Maybe<WorkflowStageBoolExp>>>,
  _not?: Maybe<WorkflowStageBoolExp>,
  _or?: Maybe<Array<Maybe<WorkflowStageBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  order?: Maybe<IntComparisonExp>,
  profiles?: Maybe<WorkflowsToProfilesBoolExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  workflow?: Maybe<WorkflowBoolExp>,
  workflow_id?: Maybe<StringComparisonExp>,
}

export enum WorkflowStageConstraint {
  StagePkey = 'stage_pkey',
  WorkflowStageOrderWorkflowIdKey = 'workflow_stage_order_workflow_id_key'
}

export interface WorkflowStageIncInput {
  order?: Maybe<Scalars['Int']>,
}

export interface WorkflowStageInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  profiles?: Maybe<WorkflowsToProfilesArrRelInsertInput>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  workflow?: Maybe<WorkflowObjRelInsertInput>,
  workflow_id?: Maybe<Scalars['String']>,
}

export interface WorkflowStageMaxFields {
   __typename?: 'workflow_stage_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  workflow_id?: Maybe<Scalars['String']>,
}

export interface WorkflowStageMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  workflow_id?: Maybe<OrderBy>,
}

export interface WorkflowStageMinFields {
   __typename?: 'workflow_stage_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  workflow_id?: Maybe<Scalars['String']>,
}

export interface WorkflowStageMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  workflow_id?: Maybe<OrderBy>,
}

export interface WorkflowStageMutationResponse {
   __typename?: 'workflow_stage_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<WorkflowStage>,
}

export interface WorkflowStageObjRelInsertInput {
  data: WorkflowStageInsertInput,
  on_conflict?: Maybe<WorkflowStageOnConflict>,
}

export interface WorkflowStageOnConflict {
  constraint: WorkflowStageConstraint,
  update_columns: Array<WorkflowStageUpdateColumn>,
  where?: Maybe<WorkflowStageBoolExp>,
}

export interface WorkflowStageOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  order?: Maybe<OrderBy>,
  profiles_aggregate?: Maybe<WorkflowsToProfilesAggregateOrderBy>,
  updated_at?: Maybe<OrderBy>,
  workflow?: Maybe<WorkflowOrderBy>,
  workflow_id?: Maybe<OrderBy>,
}

export interface WorkflowStagePkColumnsInput {
  id: Scalars['String'],
}

export enum WorkflowStageSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updated_at',
  WorkflowId = 'workflow_id'
}

export interface WorkflowStageSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  workflow_id?: Maybe<Scalars['String']>,
}

export interface WorkflowStageStddevFields {
   __typename?: 'workflow_stage_stddev_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageStddevOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageStddevPopFields {
   __typename?: 'workflow_stage_stddev_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageStddevPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageStddevSampFields {
   __typename?: 'workflow_stage_stddev_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageStddevSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageSumFields {
   __typename?: 'workflow_stage_sum_fields',
  order?: Maybe<Scalars['Int']>,
}

export interface WorkflowStageSumOrderBy {
  order?: Maybe<OrderBy>,
}

export enum WorkflowStageUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  Order = 'order',
  UpdatedAt = 'updated_at',
  WorkflowId = 'workflow_id'
}

export interface WorkflowStageVarPopFields {
   __typename?: 'workflow_stage_var_pop_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageVarPopOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageVarSampFields {
   __typename?: 'workflow_stage_var_samp_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageVarSampOrderBy {
  order?: Maybe<OrderBy>,
}

export interface WorkflowStageVarianceFields {
   __typename?: 'workflow_stage_variance_fields',
  order?: Maybe<Scalars['Float']>,
}

export interface WorkflowStageVarianceOrderBy {
  order?: Maybe<OrderBy>,
}

export enum WorkflowUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organization_id',
  ProfileTypeId = 'profile_type_id',
  UpdatedAt = 'updated_at'
}

export interface WorkflowsToProfiles {
   __typename?: 'workflows_to_profiles',
  created_at: Scalars['timestamptz'],
  profile: Profile,
  profile_id: Scalars['String'],
  updated_at: Scalars['timestamptz'],
  updated_by_id?: Maybe<Scalars['String']>,
  workflow: Workflow,
  workflow_id: Scalars['String'],
  workflow_stage: WorkflowStage,
  workflow_stage_id: Scalars['String'],
}

export interface WorkflowsToProfilesAggregate {
   __typename?: 'workflows_to_profiles_aggregate',
  aggregate?: Maybe<WorkflowsToProfilesAggregateFields>,
  nodes: Array<WorkflowsToProfiles>,
}

export interface WorkflowsToProfilesAggregateFields {
   __typename?: 'workflows_to_profiles_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<WorkflowsToProfilesMaxFields>,
  min?: Maybe<WorkflowsToProfilesMinFields>,
}


export interface WorkflowsToProfilesAggregateFieldsCountArgs {
  columns?: Maybe<Array<WorkflowsToProfilesSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface WorkflowsToProfilesAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<WorkflowsToProfilesMaxOrderBy>,
  min?: Maybe<WorkflowsToProfilesMinOrderBy>,
}

export interface WorkflowsToProfilesArrRelInsertInput {
  data: Array<WorkflowsToProfilesInsertInput>,
  on_conflict?: Maybe<WorkflowsToProfilesOnConflict>,
}

export interface WorkflowsToProfilesBoolExp {
  _and?: Maybe<Array<Maybe<WorkflowsToProfilesBoolExp>>>,
  _not?: Maybe<WorkflowsToProfilesBoolExp>,
  _or?: Maybe<Array<Maybe<WorkflowsToProfilesBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  profile?: Maybe<ProfileBoolExp>,
  profile_id?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
  updated_by_id?: Maybe<StringComparisonExp>,
  workflow?: Maybe<WorkflowBoolExp>,
  workflow_id?: Maybe<StringComparisonExp>,
  workflow_stage?: Maybe<WorkflowStageBoolExp>,
  workflow_stage_id?: Maybe<StringComparisonExp>,
}

export enum WorkflowsToProfilesConstraint {
  WorkflowsToProfilesPkey = 'workflows_to_profiles_pkey'
}

export interface WorkflowsToProfilesInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile?: Maybe<ProfileObjRelInsertInput>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
  workflow?: Maybe<WorkflowObjRelInsertInput>,
  workflow_id?: Maybe<Scalars['String']>,
  workflow_stage?: Maybe<WorkflowStageObjRelInsertInput>,
  workflow_stage_id?: Maybe<Scalars['String']>,
}

export interface WorkflowsToProfilesMaxFields {
   __typename?: 'workflows_to_profiles_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
  workflow_id?: Maybe<Scalars['String']>,
  workflow_stage_id?: Maybe<Scalars['String']>,
}

export interface WorkflowsToProfilesMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
  workflow_id?: Maybe<OrderBy>,
  workflow_stage_id?: Maybe<OrderBy>,
}

export interface WorkflowsToProfilesMinFields {
   __typename?: 'workflows_to_profiles_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
  workflow_id?: Maybe<Scalars['String']>,
  workflow_stage_id?: Maybe<Scalars['String']>,
}

export interface WorkflowsToProfilesMinOrderBy {
  created_at?: Maybe<OrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
  workflow_id?: Maybe<OrderBy>,
  workflow_stage_id?: Maybe<OrderBy>,
}

export interface WorkflowsToProfilesMutationResponse {
   __typename?: 'workflows_to_profiles_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<WorkflowsToProfiles>,
}

export interface WorkflowsToProfilesObjRelInsertInput {
  data: WorkflowsToProfilesInsertInput,
  on_conflict?: Maybe<WorkflowsToProfilesOnConflict>,
}

export interface WorkflowsToProfilesOnConflict {
  constraint: WorkflowsToProfilesConstraint,
  update_columns: Array<WorkflowsToProfilesUpdateColumn>,
  where?: Maybe<WorkflowsToProfilesBoolExp>,
}

export interface WorkflowsToProfilesOrderBy {
  created_at?: Maybe<OrderBy>,
  profile?: Maybe<ProfileOrderBy>,
  profile_id?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
  updated_by_id?: Maybe<OrderBy>,
  workflow?: Maybe<WorkflowOrderBy>,
  workflow_id?: Maybe<OrderBy>,
  workflow_stage?: Maybe<WorkflowStageOrderBy>,
  workflow_stage_id?: Maybe<OrderBy>,
}

export interface WorkflowsToProfilesPkColumnsInput {
  profile_id: Scalars['String'],
  workflow_id: Scalars['String'],
}

export enum WorkflowsToProfilesSelectColumn {
  CreatedAt = 'created_at',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id',
  WorkflowId = 'workflow_id',
  WorkflowStageId = 'workflow_stage_id'
}

export interface WorkflowsToProfilesSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  profile_id?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
  updated_by_id?: Maybe<Scalars['String']>,
  workflow_id?: Maybe<Scalars['String']>,
  workflow_stage_id?: Maybe<Scalars['String']>,
}

export enum WorkflowsToProfilesUpdateColumn {
  CreatedAt = 'created_at',
  ProfileId = 'profile_id',
  UpdatedAt = 'updated_at',
  UpdatedById = 'updated_by_id',
  WorkflowId = 'workflow_id',
  WorkflowStageId = 'workflow_stage_id'
}

export interface YearsExperience {
   __typename?: 'years_experience',
  created_at: Scalars['timestamptz'],
  id: Scalars['String'],
  name: Scalars['String'],
  updated_at: Scalars['timestamptz'],
}

export interface YearsExperienceAggregate {
   __typename?: 'years_experience_aggregate',
  aggregate?: Maybe<YearsExperienceAggregateFields>,
  nodes: Array<YearsExperience>,
}

export interface YearsExperienceAggregateFields {
   __typename?: 'years_experience_aggregate_fields',
  count?: Maybe<Scalars['Int']>,
  max?: Maybe<YearsExperienceMaxFields>,
  min?: Maybe<YearsExperienceMinFields>,
}


export interface YearsExperienceAggregateFieldsCountArgs {
  columns?: Maybe<Array<YearsExperienceSelectColumn>>,
  distinct?: Maybe<Scalars['Boolean']>
}

export interface YearsExperienceAggregateOrderBy {
  count?: Maybe<OrderBy>,
  max?: Maybe<YearsExperienceMaxOrderBy>,
  min?: Maybe<YearsExperienceMinOrderBy>,
}

export interface YearsExperienceArrRelInsertInput {
  data: Array<YearsExperienceInsertInput>,
  on_conflict?: Maybe<YearsExperienceOnConflict>,
}

export interface YearsExperienceBoolExp {
  _and?: Maybe<Array<Maybe<YearsExperienceBoolExp>>>,
  _not?: Maybe<YearsExperienceBoolExp>,
  _or?: Maybe<Array<Maybe<YearsExperienceBoolExp>>>,
  created_at?: Maybe<TimestamptzComparisonExp>,
  id?: Maybe<StringComparisonExp>,
  name?: Maybe<StringComparisonExp>,
  updated_at?: Maybe<TimestamptzComparisonExp>,
}

export enum YearsExperienceConstraint {
  YearsExperienceNameKey = 'years_experience_name_key',
  YearsExperiencePkey = 'years_experience_pkey'
}

export interface YearsExperienceInsertInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface YearsExperienceMaxFields {
   __typename?: 'years_experience_max_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface YearsExperienceMaxOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface YearsExperienceMinFields {
   __typename?: 'years_experience_min_fields',
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export interface YearsExperienceMinOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface YearsExperienceMutationResponse {
   __typename?: 'years_experience_mutation_response',
  affected_rows: Scalars['Int'],
  returning: Array<YearsExperience>,
}

export interface YearsExperienceObjRelInsertInput {
  data: YearsExperienceInsertInput,
  on_conflict?: Maybe<YearsExperienceOnConflict>,
}

export interface YearsExperienceOnConflict {
  constraint: YearsExperienceConstraint,
  update_columns: Array<YearsExperienceUpdateColumn>,
  where?: Maybe<YearsExperienceBoolExp>,
}

export interface YearsExperienceOrderBy {
  created_at?: Maybe<OrderBy>,
  id?: Maybe<OrderBy>,
  name?: Maybe<OrderBy>,
  updated_at?: Maybe<OrderBy>,
}

export interface YearsExperiencePkColumnsInput {
  id: Scalars['String'],
}

export enum YearsExperienceSelectColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}

export interface YearsExperienceSetInput {
  created_at?: Maybe<Scalars['timestamptz']>,
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  updated_at?: Maybe<Scalars['timestamptz']>,
}

export enum YearsExperienceUpdateColumn {
  CreatedAt = 'created_at',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updated_at'
}
