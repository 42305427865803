import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SegmentDetailsComponent } from './components/segment-details/segment-details.component'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SegmentDetailsComponent,
  ],
  exports: [
    SegmentDetailsComponent,
  ]
})
export class SegmentDetailsModule { }
