import { Injectable } from '@angular/core'
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router'

import { of } from 'rxjs'
import { Observable } from 'rxjs/Observable'

import { ProfileService } from '@core/services/profile/profile.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileTypeResolver implements Resolve<boolean> {
  public constructor(
    private service: ProfileService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.service.setActiveType(route.data.type)
    // this.service.reset()
    // this.service.fetch()

    return of(true)
  }
}
