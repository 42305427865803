<!-- avatar: legal entity -->
<ng-container *ngIf="isLegalEntity(); else individual_avatar">
  <i class="fal fa-building"></i>
</ng-container>

<!-- avatar: individual -->
<ng-template #individual_avatar>

  <!-- user image -->
  <img *ngIf="(_stakeholder | async)?.avatar; else alternate_avatar" [src]="(_stakeholder | async)?.avatar" alt="Avatar" i18n-alt="Image associated with user">

  <!-- user initials -->
  <ng-template #alternate_avatar>
    <span class="avatar-initials">{{ initials() }}</span>
  </ng-template>

</ng-template>
