<ng-container *ngIf="(request | async)">
  <ng-container *ngIf="(request | async).status_id === 0; else reviewedTemplate">
    <button class="c-btn btn-line btn-danger btn-sm u-mr-3" (click)="denyRequest($event)" i18n="Reject"><i class="far fa-ban"></i> Deny</button>
    <button class="c-btn btn-line btn-success btn-sm" (click)="approveRequest($event)" i18n="Accept"><i class="far fa-check"></i> Approve</button>
  </ng-container>
</ng-container>

<ng-template #reviewedTemplate>
  <small class="c-notification__help">
    {{ (request | async)?.status.name }}
    <ng-container i18n="the person who made the request">by </ng-container>
    <a [routerLink]="['/', 'stakeholders', (request | async)?.reviewed_by.id]">{{ (request | async)?.reviewed_by.stakeholder_name.name }}</a>
    {{ (request | async)?.reviewed_at | timeAgo }}
    <ng-container i18n="in the past, as in 5 minutes ago">ago</ng-container>
  </small>
</ng-template>
