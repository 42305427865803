import {
  Component, OnDestroy,
} from '@angular/core'

import {
  merge,
  Subject,
  BehaviorSubject,
} from 'rxjs'
import {
  filter as filterRx,
  switchMap,
  takeUntil,
} from 'rxjs/operators'

import {
  Stakeholder,
} from '@@types'
import { IdentityService } from '@core/services/identity/identity.service'
import { StakeholderService } from '@core/services/stakeholder/stakeholder.service'
import { UserInviteService } from '@core/services/user-invite/user-invite.service'

@Component({
  selector: 'cb-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnDestroy {
  public destroy: Subject<void>
  public userInviteCheck: BehaviorSubject<any>
  public stakeholder: BehaviorSubject<Stakeholder | null> = new BehaviorSubject(null)

  public constructor(
    private identityService: IdentityService,
    private stakeHolderService: StakeholderService,
    private userInviteService: UserInviteService,
  ) {
    this.destroy = new Subject()
    this.identityService.me.pipe(
      takeUntil(this.destroy),
      switchMap(holder => this.stakeHolderService.find(holder.id).pipe()),
      filterRx(holder => holder != null),
    ).subscribe(this.stakeholder)

    const noInvite$ = this.stakeholder.pipe(
      filterRx(holder => holder != null && holder.user_invite_id != null),
    )

    noInvite$.pipe(
      switchMap(holder => merge(
        this.userInviteService.remove({ id: { _eq: holder!.user_invite_id } }),
        this.stakeHolderService.update({ id: { _eq: holder!.id } }, { user_invite_id: null })
      )
      )
    ).subscribe()
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
