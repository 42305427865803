import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

import {
  ProfileInsertInput,
  Profile,
  ProfileSetInput,
  ProfileBoolExp,
  Stake,
  RepresentativesToProfilesInsertInput,
  RepresentativesToProfilesBoolExp,
  ProfileSelectColumn,
  ProfileOrderBy
} from '@@types'

import {
  Service,
} from '@core/services/service'

import * as activeSubscription from './graphql/active.subscription.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as find from './graphql/find.query.graphql'
import * as representatives from './graphql/representatives.query.graphql'
import * as insertRepresentative from './graphql/insert-representative.mutation.graphql'
import * as deleteRepresentative from './graphql/delete-representative.mutation.graphql'
import * as access from './graphql/access.query.graphql'

export type ProfileTypeName = 'Client' | 'Partner' | 'Lead'
export interface ProfileExtended extends Profile {
  primaryStakes?: Stake[]
}

export enum ProfileTypeEnum {
  client = 'Client',
  lead = 'Lead',
  partner = 'Partner',
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends Service<Profile> {
  protected API = 'client'
  protected viewSubscription = activeSubscription
  protected editQuery = null

  public activeType: BehaviorSubject<ProfileTypeName | null>

  public constructor(
    apollo: Apollo,
  ) {
    super(apollo)

    this.activeType = new BehaviorSubject(null)
  }

  public setActiveType(type: ProfileTypeName): Observable<ProfileTypeName | null> {
    this.activeType.next(type)

    return this.activeType
  }

  public find(id: string): Observable<Profile> {
    return this.queryOne(find, { id })
  }

  public create(objects: ProfileInsertInput[]) {
    return this.mutate(create, { objects })
  }

  public update(where: ProfileBoolExp, _set: ProfileSetInput) {
    return this.mutate(update, { _set, where })
  }
  public representatives(id: string) {
    return this.watch(representatives, { id })
  }

  public insertRepresentative(objects: RepresentativesToProfilesInsertInput[]) {
    return this.mutate(insertRepresentative, { objects })
  }

  public deleteRepresentative(where: RepresentativesToProfilesBoolExp) {
    return this.mutate(deleteRepresentative, { where })
  }

  public access(
    variables: {
      distinct_on?: ProfileSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: ProfileOrderBy,
      where?: ProfileBoolExp,
    },
  ): Observable<Profile[]> {
    return this.query(access, variables)
  }
}
