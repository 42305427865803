import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import cuid from 'cuid'
import { assoc } from 'ramda'
import { from } from 'rxjs'
import {
  EmailTemplateResponse,
  Errors,
  ForgotPasswordResponse,
  FusionAuthClient,
  LoginResponse,
  RegistrationRequest,
  TwoFactorDelivery,
  TwoFactorLoginRequest,
  TwoFactorRequest,
  TwoFactorSendRequest,
  UserResponse,
} from '@fusionauth/typescript-client'
import ClientResponse from '@fusionauth/typescript-client/build/src/ClientResponse'
import {
  StakeholderBoolExp,
  StakeholderSetInput,
} from '@@types'
import { StakeholderService } from '../stakeholder/stakeholder.service'
import { UserInviteService } from '../user-invite/user-invite.service'

@Injectable({
  providedIn: 'root'
})

export class FusionauthService {
  private applicationId
  private authClient
  public emailTemplate

  public sendCurrentBusinessInviteObs = (id: string, email: string) => from(this.sendCurrentBusinessInvite(id, email))

  public constructor(
    private service: StakeholderService,
    private userInvite: UserInviteService,
    ) {
    this.authClient = new FusionAuthClient(environment.fusionauth.apiKey, environment.fusionauth.baseUrl)
    this.applicationId = environment.fusionauth.applicationId
    this.authClient.setRequestCredentials('include')
  }

  public sendUserInvite(email: string): string | null {
    const request: RegistrationRequest = {
      registration: {
        applicationId: this.applicationId,
      },
      sendSetPasswordEmail: true,
      skipVerification: false,
      skipRegistrationVerification: true,
      user: {
        email: email,
      }
    }

    return this.authClient.register(null, request).then(res => {
      return res.statusCode === 200 ? res.response.user.id : null
    }).catch(err => {
      console.error('Need to handle errors:', err)
      return null
    })
  }

  public async sendCurrentBusinessInvite(id, email) {
    if (email) {
      const result = await this.sendUserInvite(email)
      const expires = new Date(Date.now())
      const userInviteId = cuid()
      expires.setDate(expires.getDate() + 10)

      this.userInvite.create([{
        id: userInviteId,
        expires_at: expires.toDateString()
      }]).subscribe()

      const where: StakeholderBoolExp = {
        id: {
          _eq: id
        }
      }

      const _set: StakeholderSetInput = {
        user_id: result,
        user_invite_id: userInviteId,
      }

      this.service.update(where, _set).subscribe()
    } else {
      console.error('Need email address')
    }
  }

  public deleteUser(userId: string): Boolean {
    return this.authClient.deactivateUser(userId).then(res => {
      return res.statusCode === 200
    })
  }

  public async resendUserInvite(email: string): Promise<boolean> {
    const userResponse = await this.retrieveUserByEmail(email)
    const changePasswordIdResponse = await this.generateChangePasswordId(email)
    const sendEmailRequest = {
      userIds: [userResponse.response.user!.id!],
      requestData: {
        changePasswordId: changePasswordIdResponse.response.changePasswordId
      }
    }

    const emailTemplateId = await this.retrieveEmailTemplateIdByName('Resend User Invite')

    return this.authClient.sendEmail(emailTemplateId, sendEmailRequest).then(res => {
      if (res.statusCode === 202) {
        return true
      }
    }).catch(err => {
      console.error(err)
      return Promise.reject(err.statusCode)
    })
  }

  public async retrieveEmailTemplates(): Promise<EmailTemplateResponse> {
    return await this.authClient.retrieveEmailTemplates().then(res => {
      if (res.statusCode === 200) {
        return res.response
      }
    }).catch(err => {
      console.error(err)
      return Promise.reject(err.statusCode)
    })
  }

  public async retrieveEmailTemplateIdByName(templateName: string): Promise<string | null> {
    const emailTemplatesResponse = await this.retrieveEmailTemplates()
    if (emailTemplatesResponse.emailTemplates !== undefined) {
      const filteredTemplates = emailTemplatesResponse.emailTemplates.filter( et => {
        if (et.name !== undefined && et.name === templateName) {
          return et.id!
        }
      })
      return filteredTemplates[0].id!
    }
    return null
  }

  public checkEmailAvailable(email: string): Boolean {
    return this.authClient.retrieveUserByLoginId(email).then(res => {
      // 404 indicates user record with requested email doesn't exist
      return res.statusCode === 404
    }).catch(err => {
      return true
    })
  }

  public async updateUserEmail(userId: string, email: string): Promise<boolean> {
    const userResponse = await this.retrieveUserByUserId(userId)
    const userToUpdate = userResponse.response.user!
    userToUpdate.email = email
    const userRequest = {
      user: userToUpdate
    }
    return this.authClient.updateUser(userId, userRequest).then(res => {
      return res.statusCode === 200
    })
  }

  public async updateUserMobilePhone(userId: string, email: string, mobilePhone: string): Promise<ClientResponse<Errors>> {
    const userResponse = await this.retrieveUserByUserId(userId)
    const userToUpdate = userResponse.response.user!
    userToUpdate.mobilePhone = mobilePhone
    userToUpdate.email = email
    const userRequest = {
      user: userToUpdate
    }
    return this.authClient.updateUser(userId, userRequest).then(res => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async updateUserTwoFactorDeliveryMethod(userId: string, deliveryMethod: number, secret: string | null = null) {
    const userResponse = await this.retrieveUserByUserId(userId)
    const userToUpdate = userResponse.response.user!
    userToUpdate.twoFactorDelivery = deliveryMethod === 0 ? TwoFactorDelivery.None : TwoFactorDelivery.TextMessage
    if (deliveryMethod === 0 && secret) {
      userToUpdate.twoFactorSecret = secret
    }
    const userRequest = {
      user: userToUpdate
    }
    return this.authClient.updateUser(userId, userRequest).then(res => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async logout(userId: string) {
    await this.authClient.revokeRefreshToken('', userId, '')
    return this.authClient.logout(true, null).catch(err => console.error(err))
  }

  public async login(username: string, password: string): Promise<ClientResponse<LoginResponse>> {
    const request = {
      loginId: username,
      password,
      applicationId: environment.fusionauth.applicationId
    }
    return await this.authClient.login(request).then(res => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async twoFactorLogin(code: string, twoFactorId: string, trust: boolean = true): Promise<ClientResponse<LoginResponse>> {
    const request: TwoFactorLoginRequest = {
      code: code,
      twoFactorId: twoFactorId,
      applicationId: environment.fusionauth.applicationId,
      trustComputer: trust
    }
    return await this.authClient.twoFactorLogin(request).then((res) => {
      return res
    }).catch(err => {
      console.error(err)
      return null
    })
  }

  public async forgotPassword(email: string): Promise<ClientResponse<ForgotPasswordResponse>> {
    const request = {
      loginId: email,
      sendForgotPasswordEmail: true,
      applicationId: environment.fusionauth.applicationId
    }

    return await this.authClient.forgotPassword(request).then((res) => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async generateChangePasswordId(email: string): Promise<ClientResponse<ForgotPasswordResponse>> {
    const request = {
      loginId: email,
      sendForgotPasswordEmail: false
    }

    return await this.authClient.forgotPassword(request).then((res) => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public changePassword(newPassword: string, passwordId: string, currentPassword: string | null = null) {
    const request = {
      changePasswordId : passwordId,
      password: newPassword
    }

    const changePasswordRequest = currentPassword
      ? assoc('currentPassword', currentPassword, request)
      : request

    return this.authClient.changePassword(passwordId, changePasswordRequest).then(res => {
      return res
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public refreshToken(): string | null {
    return this.authClient.exchangeRefreshTokenForJWT({}).then(res => {
      if (res.statusCode === 200) {
        return res.response.token
      } else {
        return null
      }
    }).catch(err => {
      console.error(err)
      return null
    })
  }

  public apolloRefresh(): Promise<Response> {
    return this.authClient.exchangeRefreshTokenForJWT({})
  }

  public retrieveUserByChangePasswordId(changePasswordId: string): Promise<ClientResponse<UserResponse>> {
    return this.authClient.retrieveUserByChangePasswordId(changePasswordId).then(res => {
      if (res.statusCode === 200) {
        return res
      }
    })
  }

  public retrieveUserByUserId(userId: any): Promise<ClientResponse<UserResponse>> {
    return this.authClient.retrieveUser(userId).then(res => {
      if (res.statusCode === 200) {
        return res
      }
    }).catch(err => {
      console.error(err)
      return Promise.reject(err.statusCode)
    })
  }

  public retrieveUserByEmail(email: string): Promise<ClientResponse<UserResponse>> {
    return this.authClient.retrieveUserByEmail(email).then(res => {
      if (res.statusCode === 200) {
        return res
      }
    }).catch(err => {
      console.error(err)
      return Promise.reject(err.statusCode)
    })
  }

  public generateTwoFactorSecret(): any {
    return this.authClient.generateTwoFactorSecret().then(res => {
      if (res.statusCode === 200) {
        return res.response
      }
    })
  }

  public sendTwoFactorCode(mobilePhone: string, secret: string): Promise<ClientResponse<Errors>> {
    const request: TwoFactorSendRequest = {
      mobilePhone: mobilePhone,
      secret: secret
    }

    return this.authClient.sendTwoFactorCode(request).then(res => {
      if (res.statusCode === 200) {
        return res
      }
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public sendTwoFactorCodeForLogin(twoFactorId: string): boolean {
    return this.authClient.sendTwoFactorCodeForLogin(twoFactorId).then(res => {
      if (res.statusCode !== 200) {
        return false
      }

      return true
    })
  }

  public enableTwoFactor(userId: string, code: string, secret: string, deliveryMethod: number): Promise<ClientResponse<Errors>> {
    const request: TwoFactorRequest = {
      code: code,
      delivery: deliveryMethod === 0 ? TwoFactorDelivery.None : TwoFactorDelivery.TextMessage,
      secret: secret
    }

    return this.authClient.enableTwoFactor(userId, request).then(res => {
      if (res.statusCode === 200) {
        return res
      }
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async retrieveTenant(tenantId: string): Promise<any> {
    return await this.authClient.retrieveTenant(tenantId).then(res => {
      if (res.statusCode === 200) {
        return res.response
      }
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async resendVerificationEmail(email: string): Promise<any> {
    return await this.authClient.resendEmailVerification(email).then(res => {
      if (res.statusCode === 200) {
        return res.response
      }
    }).catch(err => {
      console.error(err)
      return err
    })
  }

  public async verifyEmail(verificationId: string): Promise<any> {
    return await this.authClient.verifyEmail(verificationId).then(res => {
      if (res.responseCode === 200) {
        return res
      }
    }).catch(err => {
      console.error(err)
      return err
    })
  }

}
