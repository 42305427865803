<!-- navbar top -->
<div class="c-navbar__top">
  <a *ngIf="(access.location | async).leads" class="c-navbar__link" href="#" routerLink="/leads" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-leads qa-navbar-leads"></i>
  </a>
  <a *ngIf="(access.location | async).clients" class="c-navbar__link" href="#" routerLink="/clients" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-clients qa-navbar-clients"></i>
  </a>
  <a *ngIf="(access.location | async).partners" class="c-navbar__link" href="#" routerLink="/partners" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-partners qa-navbar-partners"></i>
  </a>
  <a *ngIf="(access.location | async).accounts" class="c-navbar__link" href="#" routerLink="/profiles" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-accounts qa-navbar-accounts"></i>
  </a>
  <a *ngIf="(access.location | async).transactions" class="c-navbar__link" href="#" routerLink="/transactions" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-transactions qa-navbar-transactions"></i>
  </a>
  <a class="c-navbar__link" href="#" routerLink="/marketing" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-marketing qa-navbar-marketing"></i>
  </a>
  <a class="c-navbar__link" href="#" routerLink="/reports" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-reports qa-navbar-reports"></i>
  </a>
  <a *ngIf="(access.location | async).requests" class="c-navbar__link" href="#" routerLink="/requests" routerLinkActive="is-active">
    <i class="fas fa-inbox qa-navbar-requests"></i>
  </a>
</div>

<!-- navbar bottom -->
<div class="c-navbar__bottom">
  <cb-user-settings-menu></cb-user-settings-menu>
  <cb-notification-center></cb-notification-center>
  <a *ngIf="(access.location | async).settings" class="c-navbar__link" href="#" routerLink="/settings" routerLinkActive="is-active">
    <i class="cb-icon cb-icon-settings qa-navbar-settings"></i>
  </a>
</div>
