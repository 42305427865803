import {
  Component,
} from '@angular/core'

@Component({
  selector: 'cb-brandbar',
  templateUrl: './brandbar.component.html',
  styleUrls: ['./brandbar.component.scss']
})
export class BrandbarComponent {
  public constructor(
  ) {
  }
}
