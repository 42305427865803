import {
  Component,
  Input,
  OnDestroy,
} from '@angular/core'
import { Subject } from 'rxjs'
import {
  filter,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { FileService } from '@core/services/file/file.service'
import { FileAPIService } from '@core/services/file-api/file-api.service'

@Component({
  selector: 'cb-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})

export class FileDownLoadComponent implements OnDestroy {
  @Input() public filePath: string

  @Input() public file_id: string

  public destroy: Subject<void>

  public constructor(
    private fileService: FileService,
    private fileAPIService: FileAPIService,
  ) {
    this.destroy = new Subject()
  }

  public downloadFile() {
    if (this.filePath) {
      this.downloadFileViaPath(this.filePath)
    }

    if (this.file_id) {
      this.downloadFileViaId(this.file_id)
    }
  }

  public downloadFileViaPath(filePath: string) {
    this.fileAPIService.signedURL(filePath).pipe(
      takeUntil(this.destroy),
      tap(link => {
        window.location.assign(link)
      })
    ).subscribe()
  }

  public downloadFileViaId(file_id: string) {
    this.fileService.find(file_id).pipe(
      takeUntil(this.destroy),
      filter(file => file != null)
    ).subscribe(file => {
      this.fileAPIService.signedURL(file.path).pipe(
        takeUntil(this.destroy),
      )
      .subscribe(link => {
        window.location.assign(link)
      })
    })
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
