import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ToolbarLeftDirective } from '@toolbar/directives/toolbar-left/toolbar-left.directive'
import { ToolbarCenterDirective } from '@toolbar/directives/toolbar-center/toolbar-center.directive'
import { ToolbarRightDirective } from '@toolbar/directives/toolbar-right/toolbar-right.directive'

import { ToolbarComponent } from '@toolbar/components/toolbar/toolbar.component'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ToolbarComponent,

    ToolbarLeftDirective,
    ToolbarCenterDirective,
    ToolbarRightDirective,
  ],
  exports: [
    ToolbarComponent,

    ToolbarLeftDirective,
    ToolbarCenterDirective,
    ToolbarRightDirective,
  ],
})
export class ToolbarModule { }
