import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'

import {
  StakeholderAttribute,
  StakeholderAttributeBoolExp,
  StakeholderAttributeInsertInput,
  StakeholderAttributeMutationResponse,
  StakeholderAttributeOrderBy,
  StakeholderAttributeSelectColumn,
  StakeholderAttributeSetInput,
  StakeholderAttributeUpdateColumn,
} from '@@types'

import { Service } from '@core/services/service'

import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as viewSubscription from './graphql/active.subscription.graphql'

@Injectable({
  providedIn: 'root'
})
export class StakeholderAttributeService extends Service<StakeholderAttribute> {
  protected API = 'client'
  protected viewSubscription = viewSubscription
  protected editQuery = find

  public constructor(
    apollo: Apollo,
  ) {
    super(apollo)
  }

  public find(id: string): Observable<StakeholderAttribute> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: StakeholderAttributeSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: StakeholderAttributeOrderBy,
      where?: StakeholderAttributeBoolExp,
    },
  ): Observable<StakeholderAttribute[]> {
    return this.query(fetch, variables)
  }

  public create(objects: StakeholderAttributeInsertInput[]): Observable<StakeholderAttributeMutationResponse> {
    return this.mutate(create, { objects })
  }

  public upsert(
    objects: StakeholderAttributeInsertInput[],
    update_columns: StakeholderAttributeUpdateColumn[],
  ): Observable<StakeholderAttributeMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: StakeholderAttributeBoolExp,
    _set: StakeholderAttributeSetInput,
  ): Observable<StakeholderAttributeMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(where: StakeholderAttributeBoolExp): Observable<StakeholderAttributeMutationResponse> {
    return this.mutate(remove, { where })
  }
}
