import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { DatePickerModule } from '@progress/kendo-angular-dateinputs'
import { DialogsModule } from '@progress/kendo-angular-dialog'
import { ButtonsModule } from '@progress/kendo-angular-buttons'
import { TooltipModule } from '@progress/kendo-angular-tooltip'
import { ToolbarModule } from '@toolbar/toolbar.module'
import { PopupModule } from '@progress/kendo-angular-popup'
import { NotificationModule } from '@progress/kendo-angular-notification'

import { TogglableDirective } from '@core/directives/togglable/togglable.directive'
import { TogglerDirective } from '@core/directives/toggler/toggler.directive'

import { SlugPipe } from '@core/pipes/slug/slug.pipe'
import { OperatorPipe } from '@core/pipes/operator/operator.pipe'
import { ParameterizePipe } from '@core/pipes/parameterize/parameterize.pipe'
import { StatusPipe } from '@core/pipes/status/status.pipe'
import { TimeAgoPipe } from '@core/pipes/time-ago/time-ago.pipe'
import { PluckPipe } from '@core/pipes/pluck/pluck.pipe'
import { DifferenceWithPipe } from '@core/pipes/difference-with/difference-with.pipe'
import { TitleizePipe } from '@core/pipes/titleize/titleize.pipe'
import { NoCommaPipe } from './pipes/no-comma/no-comma.pipe'
import { IncPipe } from './pipes/inc/inc.pipe'
import { OrderByPipe } from './pipes/order-by/order-by.pipe'

import { NavbarComponent } from '@core/components/navbar/navbar.component'
import { BrandbarComponent } from '@core/components/brandbar/brandbar.component'

import { LayoutComponent } from '@core/pages/layout/layout.component'
import { QrCodeComponent } from './components/qr-code/qr-code.component'
import { AvatarComponent } from '@core/components/avatar/avatar.component'
import { PageHeaderComponent } from '@core/components/page-header/page-header.component'
import { PageNavComponent } from '@core/components/page-nav/page-nav.component'
import { PageNotFoundComponent } from '@core/pages/page-not-found/page-not-found.component'
import { EmptyValueComponent } from '@core/components/empty-value/empty-value.component'
import { ImagePickerComponent } from './components/image-picker/image-picker.component'
import { StaticDropdownComponent } from './components/static-dropdown/static-dropdown.component'
import { CollapseComponent } from './components/collapse/collapse.component'
import { UserSettingsMenuComponent } from './components/user-settings-menu/user-settings-menu.component'
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component'
import { ProfileSelectorComponent } from './components/profile-selector/profile-selector.component'
import { TruncatedPillComponent } from './components/truncated-pill/truncated-pill.component'

import { QuickviewActionsDirective } from '@core/directives/quickview-actions/quickview-actions.directive'
import { InlineComponent } from './components/inline/inline.component'
import { SideMenuComponent } from './components/side-menu/side-menu.component'
import { SideMenuLinkComponent } from './components/side-menu-link/side-menu-link.component'
import { StakeholderLabelComponent } from './components/stakeholder-label/stakeholder-label.component'
import { AgreementsAcknowledgementConfirmComponent } from './components/agreements-acknowledgement-confirm/agreements-acknowledgement-confirm.component'
import { SectionModule } from '@section/section.module'
import { FileDownLoadComponent } from './components/file-download/file-download.component'
import { NotificationCenterComponent } from './components/notification-center/notification-center.component'
import { NotificationRequestComponent } from './components/notification-request/notification-request.component'
import { ToastComponent } from './components/toast/toast.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    DropDownsModule,
    DatePickerModule,
    DialogsModule,
    ButtonsModule,
    TooltipModule,
    ToolbarModule,
    PopupModule,
    NotificationModule,
    SectionModule,
  ],
  declarations: [
    // Components
    LayoutComponent,
    AvatarComponent,
    BrandbarComponent,
    EmptyValueComponent,
    ImagePickerComponent,
    NavbarComponent,
    PageHeaderComponent,
    PageNavComponent,
    PageNotFoundComponent,
    UserSettingsMenuComponent,
    PopupMenuComponent,
    ProfileSelectorComponent,
    SideMenuComponent,
    SideMenuLinkComponent,
    AgreementsAcknowledgementConfirmComponent,
    FileDownLoadComponent,
    ToastComponent,
    // Directives
    QuickviewActionsDirective,
    TogglableDirective,
    TogglerDirective,

    // Pipes
    DifferenceWithPipe,
    IncPipe,
    NoCommaPipe,
    OperatorPipe,
    ParameterizePipe,
    PluckPipe,
    SlugPipe,
    StatusPipe,
    TimeAgoPipe,
    TitleizePipe,
    OrderByPipe,
    StaticDropdownComponent,
    CollapseComponent,
    QrCodeComponent,
    TruncatedPillComponent,
    InlineComponent,
    StakeholderLabelComponent,
    NotificationCenterComponent,
    NotificationRequestComponent,
  ],
  exports: [
    // Components
    LayoutComponent,
    AvatarComponent,
    ImagePickerComponent,
    PageHeaderComponent,
    PageNavComponent,
    QrCodeComponent,
    PageNotFoundComponent,
    StaticDropdownComponent,
    CollapseComponent,
    TruncatedPillComponent,
    InlineComponent,
    SideMenuComponent,
    SideMenuLinkComponent,
    StakeholderLabelComponent,
    AgreementsAcknowledgementConfirmComponent,
    FileDownLoadComponent,
    ToastComponent,
    // Directives
    QuickviewActionsDirective,
    TogglableDirective,
    TogglerDirective,

    // Pipes
    DifferenceWithPipe,
    IncPipe,
    NoCommaPipe,
    OperatorPipe,
    ParameterizePipe,
    PluckPipe,
    SlugPipe,
    StatusPipe,
    TimeAgoPipe,
    TitleizePipe,
    OrderByPipe,
  ],
  entryComponents: [
    EmptyValueComponent,
  ],
  providers: [
    TitleizePipe,
  ],
})
export class CoreModule { }
