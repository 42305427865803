import {
  Pipe,
  PipeTransform,
} from '@angular/core'

import { titleize } from 'inflected'

@Pipe({
  name: 'titleize'
})
export class TitleizePipe implements PipeTransform {
  transform(value: string): string {
    return titleize(value)
  }
}
