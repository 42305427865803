import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'

import {
  UserInvite,
  UserInviteBoolExp,
  UserInviteInsertInput,
  UserInviteMutationResponse,
  UserInviteOrderBy,
  UserInviteSelectColumn,
  UserInviteSetInput,
  UserInviteUpdateColumn,
} from '@@types'

import { Service } from '@core/services/service'

import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as viewSubscription from './graphql/active.subscription.graphql'

@Injectable({
  providedIn: 'root'
})
export class UserInviteService extends Service<UserInvite> {
  protected API = 'client'
  protected viewSubscription = viewSubscription
  protected editQuery = find

  public constructor(apollo: Apollo) {
    super(apollo)
  }

  public find(id: string): Observable<UserInvite> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: UserInviteSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: UserInviteOrderBy,
      where?: UserInviteBoolExp,
    },
  ): Observable<UserInvite[]> {
    return this.query(fetch, variables)
  }

  public create(objects: UserInviteInsertInput[]): Observable<UserInviteMutationResponse> {
    return this.mutate(create, { objects })
  }

  public upsert(
    objects: UserInviteInsertInput[],
    update_columns: UserInviteUpdateColumn[],
  ): Observable<UserInviteMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: UserInviteBoolExp,
    _set: UserInviteSetInput,
  ): Observable<UserInviteMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(where: UserInviteBoolExp): Observable<UserInviteMutationResponse> {
    return this.mutate(remove, { where })
  }
}
