import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'
import { switchMap } from 'rxjs/operators'
import { merge } from 'rxjs'

import {
  Stake,
  StakeBoolExp,
  StakeInsertInput,
  StakeMutationResponse,
  StakeSelectColumn,
  StakeOrderBy,
  StakeSetInput,
} from '@@types'

import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { Service } from '@core/services/service'

import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as fetch from './graphql/fetch.mutation.graphql'
import * as findStakesForProfile from './graphql/find-stakes-for-profile.subscription.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as stakeUserAccess from './graphql/stake-user-access.subscription.graphql'
import * as markForDeletion from './graphql/mark-for-deletion.mutation.graphql'
import { LifecycleDispatch } from '../lifecycle/lifecycle.dispatch'

@Injectable({
  providedIn: 'root'
})
export class StakeService extends Service<Stake> {
  protected API = 'client'
  protected viewSubscription = null
  protected editQuery = null

  public constructor(
    apollo: Apollo,
    public auth: AuthenticationService,
    private dispatch: LifecycleDispatch,
  ) {
    super(apollo)
  }

  public create(objects: StakeInsertInput[]): Observable<StakeMutationResponse> {
    return this.mutate(create, { objects })
  }

  public fetch(
    variables: {
      distinct_on?: StakeSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: StakeOrderBy,
      where?: StakeBoolExp,
    },
  ): Observable<Stake[]> {
    return this.query(fetch, variables)
  }

  public remove(where: StakeBoolExp): Observable<StakeMutationResponse> {
    return this.mutate(remove, { where })
  }

  public update(
    where: StakeBoolExp,
    _set: StakeSetInput,
  ): Observable<StakeMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public markForDeletion(id: string): Observable<StakeMutationResponse> {
    return this.mutate(markForDeletion, { id })
  }

  public findStakesForProfile(id: string): Observable<Stake[]> {
    return this.watch(findStakesForProfile, { id })
  }

  public stakeUserAccess(): Observable<Stake> {
    const id  = this.auth.decodedJwt['CBV2']['X-Hasura-Active-Id']
    const current$ = this.watch(stakeUserAccess, { id })

    const newLogin$ = this.dispatch.onLogin.pipe(
      switchMap(() => this.watch(stakeUserAccess, { id })
      )
    )

    return merge(current$, newLogin$) as any
  }

  public stakeUserAccessById(id: string): Observable<Stake> {
    const current$ = this.watch(stakeUserAccess, { id })

    const newLogin$ = this.dispatch.onLogin.pipe(
      switchMap(() => this.watch(stakeUserAccess, { id })
      )
    )

    return merge(current$, newLogin$) as any
  }
}
