import { Injectable } from '@angular/core'
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  public canActivate = this.canActivateChild

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let { isAuthenticated } = this.auth

    if (!isAuthenticated) {
      return this.auth.refreshToken().then(() => {
        isAuthenticated = this.auth.isAuthenticated
        if (!isAuthenticated) {
          this.router.navigate(this.auth.loginPath)
          return false
        } else {
          return isAuthenticated
        }
      })
    }

    return isAuthenticated
  }
}
