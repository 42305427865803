import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'

import {
  Notification,
  NotificationBoolExp,
  NotificationMutationResponse,
  NotificationOrderBy,
  NotificationSelectColumn,
  NotificationSetInput,
} from '@@types'

import { Service } from '@core/services/service'

import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as viewSubscription from './graphql/active.subscription.graphql'

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends Service<Notification> {
  protected API = 'client'
  protected viewSubscription = null
  protected editQuery = null

  public constructor(apollo: Apollo) {
    super(apollo)
  }

  public find(id: string): Observable<Notification> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: NotificationSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: NotificationOrderBy,
      where?: NotificationBoolExp,
    },
  ): Observable<Notification[]> {
    return this.query(fetch, variables)
  }

  public update(
    where: NotificationBoolExp,
    _set: NotificationSetInput,
  ): Observable<NotificationMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public notifications = (
    variables: {
      distinct_on?: NotificationSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: NotificationOrderBy,
      where?: NotificationBoolExp,
    }) => this.watch(viewSubscription, variables)
}
