import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'
import { AlertModule } from '@alert/alert.module'
import { AuthenticationRoutingModule } from '@authentication/authentication-routing.module'
import { PasswordCriteria } from '@authentication/lib/password-criteria'
import { MockData } from '@authentication/mock-data/mock-data'
import { ForgotPasswordComponent } from '@authentication/pages/forgot-password/forgot-password.component'
import { LayoutComponent } from '@authentication/pages/layout/layout.component'
import { LoginComponent } from '@authentication/pages/login/login.component'
import { ResetPasswordComponent } from '@authentication/pages/reset-password/reset-password.component'
import { SetPasswordComponent } from '@authentication/pages/set-password/set-password.component'
import { SetTwoFactorComponent } from '@authentication/pages/set-two-factor/set-two-factor.component'
import { TwoFactorLoginComponent } from '@authentication/pages/two-factor-login/two-factor-login.component'

import { CoreModule } from '@core/core.module'
import { ControlModule } from '@control/control.module'
import { DropDownsModule } from '@progress/kendo-angular-dropdowns'
import { InputsModule } from '@progress/kendo-angular-inputs'
import { AgreementAcknowledgementComponent } from '@agreements/pages/agreement-acknowledgement/agreement-acknowledgement.component'
import { PanelModule } from '@panel/panel.module'

@NgModule({
  imports: [
    AlertModule,
    AuthenticationRoutingModule,
    CommonModule,
    CoreModule,
    PanelModule,
    ControlModule,
    DropDownsModule,
    FormsModule,
    InputsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ForgotPasswordComponent,
    LayoutComponent,
    LoginComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    SetTwoFactorComponent,
    TwoFactorLoginComponent,
    AgreementAcknowledgementComponent,
  ],
  providers: [
    MockData,
    PasswordCriteria,
  ]
})
export class AuthenticationModule {}
