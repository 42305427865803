import {
  SchedulerLike,
  OperatorFunction,
} from 'rxjs/internal/types'
import { Subscriber } from 'rxjs/internal/Subscriber'
import { Observable } from 'rxjs/Observable'
import { Subscription } from 'rxjs/Subscription'

import { EternalReplaySubject } from '@rxjs/EternalReplaySubject'

function shareEternalReplayOperator<T>(
  bufferSize?: number,
  windowTime?: number,
  scheduler?: SchedulerLike,
) {
  let subject: EternalReplaySubject<T>
  let subscription: Subscription
  let refCount = 0
  let hasError = false
  let isComplete = false

  return function shareEternalReplayOperation(this: Subscriber<T>, source: Observable<T>) {
    refCount++
    if (!subject || hasError) {
      hasError = false
      subject = new EternalReplaySubject<T>(bufferSize, windowTime, scheduler)
      subscription = source.subscribe({
        next(value) { subject.next(value) },
        error(err) {
          hasError = true
          subject.error(err)
        },
        complete() {
          isComplete = true
          subject.complete()
        },
      })
    }

    const innerSub = subject.subscribe(this)

    return () => {
      refCount--
      innerSub.unsubscribe()
      if (subscription && refCount === 0 && isComplete) {
        subscription.unsubscribe()
      }
    }
  }
}

export function shareEternalReplay<T, R>(
  bufferSize?: number,
  windowTime?: number,
  scheduler?: SchedulerLike,
): OperatorFunction<T, R> {
  return (source: Observable<T>) => source.lift(shareEternalReplayOperator(bufferSize, windowTime, scheduler))
}
