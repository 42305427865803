import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { DashboardComponent } from '@dashboard/pages/dashboard/dashboard.component'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DashboardComponent,
  ]
})
export class DashboardModule {}
