import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  FormGroup,
  FormArray,
} from '@angular/forms'

import {
  Subject,
  merge,
} from 'rxjs'
import {
  map as mapRx,
  filter as filterRx,
  distinctUntilChanged,
} from 'rxjs/operators'
import { equals } from 'ramda'

export interface FormattedLeverage { formatted: string, leverage: number }

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private router: Router
  ) { }

  public replaceMany(find: string[], replace: string, initialSet: string[]): string[] {
    for (let i = 0; i < find.length; i++) {
      for (let j = 0; j < initialSet.length; j++) {

        initialSet[j] = initialSet[j].replace(find[i], replace)
      }
    }

    return initialSet
  }

  public trimRole(target: string): string {
    if (target.includes('user_limited')) {
      target = target.substring(target.indexOf('_'))
      target = target.substring(target.indexOf('_') + 1)
    }

    target = target.substring(target.indexOf('_') + 1)

    return target
  }

  public rolePriority(role: string) {
    const trimmedPriority = role.substring(3)

    return parseFloat(trimmedPriority)
  }

  public checkRoles(roles: string[], leastPrivilege: number, strict = false) {
    const numericRoles = roles.map(role => this.rolePriority(role))

    if (strict) {
      return Math.max(...numericRoles) >= leastPrivilege && Math.min(...numericRoles) <= leastPrivilege
    }

    return Math.max(...numericRoles) >= leastPrivilege
  }

  public clearRouterOutlets(primaryOutletName: string, route: any) {
    const urlTree = this.router.createUrlTree([])
    const clearingOutlets: { [outlet: string]: null } = {}

    // Get all router outlet names except the primary router outlet and remove them.
    Object.keys(urlTree.root.children)
      .filter(outletName => outletName !== primaryOutletName)
      .forEach(remainingOutletName => clearingOutlets[remainingOutletName] = null)

    this.router.navigate([
      {
        outlets: clearingOutlets
      }
    ])
  }

  public formErrors(form: FormGroup) {
    const errors = new Subject()

    const status$ = form.statusChanges.pipe(
      filterRx(value => value !== 'PENDING')
    )

    merge(form.valueChanges, status$).pipe(
      mapRx(() => this.mapValidations(form)),
      distinctUntilChanged(equals),
    ).subscribe(errors)

    return errors
  }

  private trimFalseyValues(obj: any) {
    if (!obj) {
      return {}
    }
    const keys = Object.keys(obj)

    return keys.filter(key => obj[key]).reduce((total, curr) => {
      const ob = total
      ob[curr] = obj[curr]

      return ob
    }, {})
  }

  public mapValidations(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key)
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.mapValidations(control)
        : control!.errors
      if (errors) {
        acc = { ...this.trimFalseyValues(errors), ...acc }
        hasError = true
      }
      return acc
    }, {} as { [key: string]: any })
    return hasError ? result : null
  }

  public formatLeverage(leverage: number): { formatted: string, leverage: number } {
    return { formatted: `1:${ leverage }`, leverage }
  }
}

