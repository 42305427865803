<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="Get a new password">Reset your password</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- alerts -->
  <cb-alert *ngIf="!resetSuccess && !changePasswordError" cbAlertWarning>
    <i class="far fa-info-circle"></i>
    <ng-container i18n="Pick a new password that meets the following rules">Choose a password that meets the following criteria: {{passwordCriteria.passwordLength}} {{passwordCriteria.mixedCase}} {{passwordCriteria.nonAlpha}} {{passwordCriteria.number}}</ng-container>
  </cb-alert>
  <cb-alert *ngIf="changePasswordError" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="Something went wrong with reseting your password. Do it again.">{{errorCode}} error occurred while changing your password. Please try again.</ng-container>
  </cb-alert>
  <cb-alert *ngIf="errorMessage" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container>{{errorMessage}}</ng-container>
  </cb-alert>
  <cb-alert *ngIf="resetSuccess" cbAlertSuccess>
    <i class="far fa-check-circle"></i>
    <ng-container i18n="The system was able to reset your password.">Your password has been successfully reset!</ng-container>
  </cb-alert>

  <!-- form control: new password -->
  <div *ngIf="!resetSuccess"  class="c-form-control">
    <input class="qa-new-password" autocorrect="off" autocapitalize="off" spellcheck="off" type="password" formControlName="new_password" i18n-placeholder="New security key for logging in" placeholder="new password">
    <input class="u-mt-3 qa-confirm-password" autocorrect="off" autocapitalize="off" spellcheck="off" type="password" formControlName="confirm_password" i18n-placeholder="Reenter the new password to double check it" placeholder="confirm password">
  </div>

  <!-- buttons -->
  <button *ngIf="!resetSuccess" class="btn-primary u-mt-3 qa-password-reset-submit" [disabled]="form.invalid" (click)="reset()" i18n="Get new password">Reset Password</button>
  <button *ngIf="!resetSuccess && !changePasswordError" class="c-btn c-btn--link u-mt-3 qa-cancel" [routerLink]="[ '/', 'login' ]" i18n="Go back to the login page">Return to Sign in</button>
  <button *ngIf="resetSuccess" class="btn-primary u-mt-5 qa-return-login" [disabled]="form.invalid" (click)="continueToSignIn()" i18n="Go on to the login page">Continue to Sign in</button>
  <button *ngIf="changePasswordError" class="btn-primary u-mt-3 qa-return-password-reset" (click)="returnToPasswordReset()" i18n="Go back to the page to get a new password.">Return to reset password</button>

</form>
