import { Injectable } from '@angular/core'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { from, split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws'
import { Apollo } from 'apollo-angular'
import { OperationDefinitionNode } from 'graphql'
import { environment } from '@environments/environment'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { FusionauthService } from '../fusionauth/fusionauth.service'
import { LifecycleDispatch } from '../lifecycle/lifecycle.dispatch'

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  constructor(
    apollo: Apollo,
    private auth: AuthenticationService,
    private fusionauth: FusionauthService,
    private dispatch: LifecycleDispatch,
  ) {

    Object.entries(environment.API).forEach(([name, { uri, authRequired, websocket }]) => {

      let ws: (WebSocketLink | null) = null
      let link
      let wsClient: (SubscriptionClient | null) = null
      const endpoint = createUploadLink({ uri })

      // Create a WebSocket link:
      if (websocket) {
        wsClient = new SubscriptionClient(uri.replace('http', 'ws'), {
          reconnect: true,
          lazy: true,
          connectionCallback: async error => {
            if (error) {
              await this.auth.refreshToken()
              if (wsClient) {
                wsClient.close(false, false)
              }
            }
          },
          connectionParams: () => {
            const decodedJwt = this.auth.decodedJwt
            const role = decodedJwt.CBV2['X-Hasura-Role']

            return {
              headers: {
                Authorization: this.auth.authorizationHeader,
                'X-Hasura-Role': role,
                'X-Hasura-Origin': location.origin,
              }
            }
          }
        })
      }

      this.dispatch.onLogin.subscribe(() => {
        if (wsClient) {
          wsClient.close()
          // @ts-ignore
          wsClient.connect()
        }
      })

      this.dispatch.onUserLogOut.subscribe(() => {
        if (wsClient) {
          wsClient.close()
        }
      })

      ws = (wsClient ? new WebSocketLink(wsClient) : null)



      const authMiddleware = setContext((_request, _previous) => {
        const header = this.auth.authorizationHeader ? {
          Authorization: this.auth.authorizationHeader,
          ...(name === 'default' ? {} : this.auth.getRole())
        } : {}
        return { headers: { ...header } }
      })

      ws ? (
        link = split(
          // split based on operation type
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
            return kind === 'OperationDefinition' && operation === 'subscription'
          },
          ws,
          authRequired ? from([authMiddleware, endpoint]) : endpoint,
        )
      ) : (link = authRequired ? from([authMiddleware, endpoint]) : endpoint)


      apollo.create({
        link: from([
          new TokenRefreshLink({
            accessTokenField: 'token',
            isTokenValidOrUndefined: () => {
              return this.auth.isAuthenticated
            },
            fetchAccessToken: () => {
              if (wsClient) {
                wsClient.close(false)
              }
              return this.fusionauth.apolloRefresh()
            },
            handleFetch: async accessToken => {
              await this.auth.refreshToken()
            },
            handleResponse: (operation, accessTokenField) => response => {
              return response.response
            },
            handleError: (err) => {
              this.auth.logout()
            },
          }),
          link
        ]),
        cache: new InMemoryCache(),
        // there is a typings issue with apollo defaultOptions which we need to address
        // going to ts-ignore this for now so we can proceed with demo prep
        // @ts-ignore
        defaultOptions: {
          query: {
            fetchPolicy: 'no-cache',
          },
          mutate: {
            fetchPolicy: 'no-cache',
          },
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
        }
      }, name)
    })

  }

}
