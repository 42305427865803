import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AlertInfoComponent } from './alert-info/alert-info.component'
import { AlertWarningComponent } from './alert-warning/alert-warning.component'
import { AlertDangerComponent } from './alert-danger/alert-danger.component'



@NgModule({
  declarations: [
    AlertInfoComponent,
    AlertWarningComponent,
    AlertDangerComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlertInfoComponent,
    AlertWarningComponent,
    AlertDangerComponent,
  ]
})
export class BaseModule { }
