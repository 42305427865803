import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'
import { DocumentNode } from 'apollo-link'

import setUserPermission from './graphql/set-user-permissions.mutation.graphql'
import setAdminPermission from './graphql/set-admin-permissions.mutation.graphql'
import setManyUserPermissions from './graphql/set-many-user-permissions.mutation.graphql'
import setManyAdminPermissions from './graphql/set-many-admin-permissions.mutation.graphql'
import setManyPartnerPermissions from './graphql/set-many-partner-permissions.mutation.graphql'
import editPartnerPermission from './graphql/edit-partner-permission.mutation.graphql'
import editAdminPermission from './graphql/edit-admin-permission.mutation.graphql'
import switchUser from './graphql/switch-user.mutation.graphql'

@Injectable({
  providedIn: 'root'
})
export class PermissionApiService {
  protected API = 'permission'

  public constructor(private apollo: Apollo) { }

  protected mutate(mutation: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
      .use(this.API)
      .mutate({ mutation, variables })
  }

  public setUserPermission(permissionSetId: string) {

    return this.mutate(
      setUserPermission,
      {
        permissionSetId
      }
    )
  }

  public setAdminPermission(adminId: string) {

    return this.mutate(
      setAdminPermission,
      {
        adminId
      }
    )
  }

  public setManyAdminPermission(adminIds: string[]) {

    return this.mutate(
      setManyAdminPermissions,
      {
        adminIds
      }
    )
  }

  public editAdminPermission(permissionId: string[]) {

    return this.mutate(
      editAdminPermission,
      {
        permissionId
      }
    )
  }

  public setManyPartnerPermission(partnerIds: string[]) {

    return this.mutate(
      setManyPartnerPermissions,
      {
        partnerIds
      }
    )
  }

  public setManyUserPermission(permissionSetId: string[]) {

    return this.mutate(
      setManyUserPermissions,
      {
        permissionSetId
      }
    )
  }

  public editPartnerPermission(permissionSetId: string[]) {

    return this.mutate(
      editPartnerPermission,
      {
        permissionSetId
      }
    )
  }

  public switchUser(stakeId: string) {

    return this.mutate(
      switchUser,
      {
        stakeId,
      }
    )
  }
}
