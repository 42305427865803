<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="A log in system that uses two different pieces of information or actions to log you in, for example, a user would need their password and a random code sent to them in a text message to log in">Two-Factor Authentication</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- form control: username/password -->
  <div class="c-form-control">
    <label *ngIf="twoFactorDeliveryMethod === 'TextMessage'" class="c-form-control__label" i18n="Type in the single-use secret code that we sent to your cell phone">Please enter the one time passcode sent to your mobile phone.</label>
    <label *ngIf="twoFactorDeliveryMethod !== 'TextMessage'" class="c-form-control__label" i18n="Type in the single-use secret code that your authentication application is showing on your cell phone">Please enter the one time passcode shown in your authenticator app.</label>
    <input class="qa-2fa-code" autocorrect="off" autocapitalize="off" spellcheck="off" formControlName="code" type="text" i18n-placeholder="Type in the six number code" placeholder="enter 6-digit code">
  </div>

  <!-- butotns -->
  <button class="btn-primary u-mt-3 qa-continue" [disabled]="form.invalid" (click)="submit()" i18n="Go on">Continue</button>
  <button class="c-btn c-btn--link u-mt-3 qa-cancel" [routerLink]="[ '/', 'login' ]" i18n="Disregard">Cancel</button>

</form>
