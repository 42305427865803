import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ValidationErrorListComponent } from './validation-error-list/validation-error-list.component'
import { ValidationErrorHintComponent } from './validation-error-hint/validation-error-hint.component'

@NgModule({
  declarations: [
    ValidationErrorListComponent,
    ValidationErrorHintComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ValidationErrorListComponent,
    ValidationErrorHintComponent,
  ]
})
export class FormValidationModule { }
