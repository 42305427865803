import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AlertDefaultDirective } from '@alert/directives/alert-default/alert-default.directive'
import { AlertSuccessDirective } from '@alert/directives/alert-success/alert-success.directive'
import { AlertInfoDirective } from '@alert/directives/alert-info/alert-info.directive'
import { AlertWarningDirective } from '@alert/directives/alert-warning/alert-warning.directive'
import { AlertDangerDirective } from '@alert/directives/alert-danger/alert-danger.directive'

import { AlertComponent } from '@alert/components/alert/alert.component'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AlertComponent,

    AlertDefaultDirective,
    AlertSuccessDirective,
    AlertInfoDirective,
    AlertWarningDirective,
    AlertDangerDirective,
  ],
  exports: [
    AlertComponent,

    AlertDefaultDirective,
    AlertSuccessDirective,
    AlertInfoDirective,
    AlertWarningDirective,
    AlertDangerDirective,
  ],
})
export class AlertModule { }
