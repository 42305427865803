// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const HASURA_GRAPHQL_ENDPOINT = 'https://devh.services.v2.currentdesk.com'

export const environment = {
  production: false,
  fusionauth: {
    apiKey: 'G6jvFku4DZzGrhNhUfbTpbPOglGW6FSbTI0bkEZlAmw',
    applicationId: 'aae43963-4683-402d-ae71-0d2ae35a4cc7',
    baseUrl: 'https://auth.v2.currentdesk.com',
  },
  currentCashier: 'https://dev.api.currentcashier.com/v1/current-cashier',
  API: {
    client: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/v1/graphql`,
      authRequired: true,
      websocket: true
    },
    file: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/file`,
      authRequired: true,
      websocket: false,
    },
    symbol: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/symbol`,
      authRequired: true,
      websocket: false,
    },
    segment: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/segment`,
      authRequired: true,
      websocket: false,
    },
    transaction: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/transaction`,
      authRequired: true,
      websocket: false,
    },
    currency: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/currency`,
      authRequired: true,
      websocket: false,
    },
    metatrader: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/metatrader`,
      authRequired: true,
      websocket: false,
    },
    permission: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/permission`,
      authRequired: false,
      websocket: false,
    },
    default: {
      uri: `${HASURA_GRAPHQL_ENDPOINT}/v1/graphql`,
      authRequired: true,
      websocket: true,
    }
  },
}
