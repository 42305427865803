import { Injectable } from '@angular/core'
import {
  Router,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router'

import { Observable } from 'rxjs/Observable'

import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

@Injectable({
  providedIn: 'root'
})
export class UnauthGuard implements CanActivateChild {

  constructor(private auth: AuthenticationService, private router: Router) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { isAuthenticated } = this.auth
    if (isAuthenticated) {
      this.router.navigateByUrl(this.auth.postLoginURL)
    }

    return !isAuthenticated
  }
}
