<ng-container *ngIf="form" [formGroup]="form">
  <div class="c-form-control__group">

    <!-- country code -->
    <kendo-dropdownlist
      #list
      [data]="data"
      [filterable]="true"
      [loading]="!source"
      [popupSettings]="{ width: 265, animate: false }"
      [textField]="'code'"
      [valueField]="'code'"
      [valuePrimitive]="true"
      (blur)="reset()"
      (filterChange)="filterChange($event)"
      class="qa-country-code"
      formControlName="code"
      style="width: 100px;"
    >
      <!-- opened template -->
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <div class="c-country-code">
          <img class="c-country-code__flag" [src]="dataItem?.country.flag" alt="Flag">
          <span class="c-country-code__name">{{ dataItem?.country.name }}</span>
          <span class="c-country-code__code">{{ dataItem?.code }}</span>
        </div>
      </ng-template>

      <!-- no data template -->
      <ng-template kendoDropDownListNoDataTemplate>
        <h1>
          <i class="fas fa-spinner fa-spin"></i><br><br>
          <span i18n="The system is processing information">Loading</span>
        </h1>
      </ng-template>
    </kendo-dropdownlist>

    <!-- phone number -->
    <kendo-maskedtextbox class="u-ml-2 qa-phone-number" style="flex: 1; width: auto;" [mask]="mask" formControlName="number">
    </kendo-maskedtextbox>

  </div>
</ng-container>
