import {
  Component,
  ViewChild,
  TemplateRef,
} from '@angular/core'

import { NotificationService } from '@progress/kendo-angular-notification'

import {
  ToastService,
  Toast,
} from '@core/services/toast/toast.service'

@Component({
  selector: 'cb-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @ViewChild('createProfile', { read: TemplateRef, static: true }) public createProfile: any
  @ViewChild('createAccount', { read: TemplateRef, static: true }) public createAccount: any
  @ViewChild('createAgreement', { read: TemplateRef, static: true }) public createAgreement: any
  @ViewChild('createBankAccount', { read: TemplateRef, static: true }) public createBankAccount: any
  @ViewChild('createPlatformServer', { read: TemplateRef, static: true }) public createPlatformServer: any
  @ViewChild('updatePlatformServer', { read: TemplateRef, static: true }) public updatePlatformServer: any
  @ViewChild('serverError', { read: TemplateRef, static: true }) public serverError: any
  @ViewChild('uploadFile', { read: TemplateRef, static: true }) public uploadFile: any
  @ViewChild('updateAgreement', { read: TemplateRef, static: true }) public updateAgreement: any
  @ViewChild('deleteAgreement', { read: TemplateRef, static: true }) public deleteAgreement: any
  @ViewChild('publishAgreement', { read: TemplateRef, static: true }) public publishAgreement: any
  @ViewChild('createAccountGroup', { read: TemplateRef, static: true }) public createAccountGroup: any
  @ViewChild('accountGroupSettings', { read: TemplateRef, static: true }) public accountGroupSettings: any
  @ViewChild('createSecurity', { read: TemplateRef, static: true }) public createSecurity: any
  @ViewChild('updateSecurity', { read: TemplateRef, static: true }) public updateSecurity: any
  @ViewChild('updateSymbol', { read: TemplateRef, static: true }) public updateSymbol: any

  constructor(
    private notificationService: NotificationService,
    private toastService: ToastService,
  ) {
    this.toastService.deliverToast.subscribe(toast => this.resolveToast(toast))
  }

  public resolveToast(toast: Toast) {
    let template: TemplateRef<any>
    let type: 'none' | 'success' | 'info' | 'error' | 'warning'
    switch (toast) {
      case Toast.createProfile: {
        template = this.createProfile
        type = 'success'
        break
      }
      case Toast.createAccount: {
        template = this.createAccount
        type = 'success'
        break
      }
      case Toast.createPlatformServer: {
        template = this.createPlatformServer
        type = 'success'
        break
      }
      case Toast.createBankAccount: {
        template = this.createBankAccount
        type = 'success'
        break
      }
      case Toast.uploadFile: {
        template = this.uploadFile
        type = 'success'
        break
      }
      case Toast.createSecurity: {
        template = this.createSecurity
        type = 'success'
        break
      }
      case Toast.createAccountGroup: {
        template = this.createAccountGroup
        type = 'success'
        break
      }
      case Toast.accountGroupSettings: {
        template = this.accountGroupSettings
        type = 'success'
        break
      }
      case Toast.serverError: {
        template = this.serverError
        type = 'error'
        break
      }
      case Toast.updateSecurity: {
        template = this.updateSecurity
        type = 'success'
        break
      }
      case Toast.updateSymbol: {
        template = this.updateSymbol
        type = 'success'
        break
      }
      case Toast.updatePlatformServer: {
        template = this.updatePlatformServer
        type = 'success'
        break
      }
      case Toast.createAgreement: {
        template = this.createAgreement
        type = 'success'
        break
      }
      case Toast.updateAgreement: {
        template = this.updateAgreement
        type = 'success'
        break
      }
      case Toast.deleteAgreement: {
        template = this.deleteAgreement
        type = 'success'
        break
      }
      case Toast.publishAgreement: {
        template = this.publishAgreement
        type = 'success'
        break
      }
      default: {
        throw new Error('Unrecognized toast delivered')
      }
    }

    this.notificationService.show({
      content: template,
      hideAfter: 10000,
      animation: { type: 'fade', duration: 500 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: type, icon: true },
      closable: true,
    })
  }
}
