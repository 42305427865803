<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="Finish creating a user account">Complete Account Setup</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- alerts -->
  <cb-alert *ngIf="mobileError" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="Something went wrong with sending you information. Enter your cell phone number and do this again.">{{ errorCode }} error occurred while sending your code. Please re enter your mobile number and try again.</ng-container>
  </cb-alert>

  <!-- form control: email address -->
  <div class="c-form-control">
    <label class="c-form-control__label" i18n="Email Address">Email Address</label>
    <span>{{ email }}</span>
  </div>

  <!--TODO: add input controls for first and last name if user is an admin and determine, maybe move to set password view? -->
  <!-- form control: users name -->
  <div class="c-form-control">
    <label class="c-form-control__label" i18n="Full name">Your Name</label>
    <input type="text" formControlName="first_name" placeholder="First Name" i18n-placeholder="Given name">
    <input class="u-mt-2" type="text" formControlName="last_name" placeholder="Last Name" i18n-placeholder="Surname">
  </div>

  <!-- form control: two-factor authentication -->
  <div *ngIf="!codeSent" class="c-form-control">
    <label class="c-form-control__label" i18n="Choose a way to verify it's you with a second authentication option">Choose a two-factor authentication method:</label>
    <label class="c-form-control__radio" i18n="Send SMS text messages to a cell phone"><input type="radio" formControlName="radio" value="sms" checked> Send text messages to a mobile phone</label>
    <label class="c-form-control__radio u-mt-1" i18n="Utilize a verification application"><input type="radio" formControlName="radio" value="app" (click)="resetMobileError()"> Use a mobile authenticator app</label>
  </div>

  <!-- two-factor option: phone // enter mobile number -->
  <ng-container *ngIf="radio === 'sms' && !codeSent">
    <cb-phone-control class="u-mt-5 qa-control-phone" [source]="mockData" formControlName="phone"></cb-phone-control>
    <button class="btn-primary u-mt-3 qa-send-code" [disabled]="form.invalid" (click)="sendCode()" i18n="Deliver the secret passcode">Send Code</button>
  </ng-container>

  <!-- two-factor option: phone // code sent -->
  <ng-container *ngIf="codeSent">
    <div class="c-form-control">
      <label class="c-form-control__label" i18n="We sent a secret code to you cell phone. Type it in here.">A code has been sent to your mobile number. Please enter it here or <a href="" (click)="sendCode()">resend code</a>:</label>
      <input class="qa-2fa-code" autocorrect="off" autocapitalize="off" spellcheck="off" formControlName="code" type="text" i18n-placeholder="Type in the code with 6 numbers" placeholder="enter 6-digit code">
    </div>
    <button class="btn-primary u-mt-3 qa-finish-setup" [disabled]="form.invalid" (click)="finishSetup()" i18n="Complete the process of setting up">Finish Setup</button>
  </ng-container>

  <!-- two-factor option: app -->
  <ng-container *ngIf="radio === 'app'">
    <div class="c-form-control">
      <label class="c-form-control__label" i18n="Download an authentication application on your mobile phone, and use it to scan the QR code under this message.">Install an authenticator app on your phone, then scan the QR code below with the app:</label>
      <cb-qr-code class="c-authentication__qrcode" [data]="angularxQrCode" [size]="150"></cb-qr-code>
      <input class="qa-2fa-code" autocorrect="off" autocapitalize="off" spellcheck="off" formControlName="code" type="text" i18n-placeholder="Type in the code with 6 numbers" placeholder="enter 6-digit code">
    </div>
    <button class="btn-primary u-mt-3 qa-finish-setup" [disabled]="form.invalid" (click)="finishSetup()" i18n="Complete the process of setting up">Finish Setup</button>
  </ng-container>

  <!-- button: go back -->
  <button class="c-btn c-btn--link u-mt-3 qa-cancel" (click)="goBack()" i18n="Return to previous place">Go back</button>

</form>
