import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'
import { ReplaySubject } from 'rxjs'
import {
  map,
  switchMap,
  filter,
} from 'rxjs/operators'

import { head } from 'ramda'

import {
  Organization,
  Stakeholder,
} from '@@types'

import { Service } from '@core/services/service'

import * as hereHere from './graphql/here.query.graphql'
import * as meMeMeMe from './graphql/me.query.graphql'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

const here = Symbol('here')
const me = Symbol('me')

@Injectable({
  providedIn: 'root'
})
export class IdentityService extends Service<Stakeholder | Organization> {
  protected API = 'default'
  protected viewSubscription = null
  protected editQuery = null

  private [here]: ReplaySubject<Organization>
  private [me]: ReplaySubject<Stakeholder>

  public constructor(
    apollo: Apollo,
    private auth: AuthenticationService,
  ) {
    super(apollo)
  }

  public get me(): Observable<Stakeholder> {
    if (this[me] == null) {
      this[me] = new ReplaySubject(1)
      this.auth.decodedJwtRx.pipe(
          filter(value => value != null),
          switchMap(() => this.watch(meMeMeMe).pipe(
            map(head),
          )),
      )
      .subscribe(
        stakeholder => {
          this[me].next(stakeholder)
        },
        console.error,
      )
    }

    return this[me]
  }

  public get here(): Observable<Organization> {
    if (this[here] == null) {
      this[here] = new ReplaySubject(1)

      this.watch(hereHere).pipe(
        map(head)
      ).subscribe(
        organization => this[here].next(organization),
        console.error,
      )
    }

    return this[here]
  }
}
