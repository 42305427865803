import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'cb-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
