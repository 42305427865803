import {
  always,
  converge,
  head,
  ifElse,
  isNil,
  join,
  map,
  pipe,
  prop,
  propOr,
  propSatisfies,
  unapply,
} from 'ramda'

import { Stakeholder } from '@@types'

export const initials: (stakeholder: Stakeholder) => string = ifElse(
  propSatisfies(isNil, 'attribute_pivot'),
  always(''),
  pipe(
    prop('attribute_pivot'),
    converge(
      unapply(map(head)),
      [
        propOr('', 'first_name'),
        propOr('', 'last_name'),
      ],
    ),
    join(''),
  ),
)
