import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { filter as filterRx, pluck } from 'rxjs/operators'

export interface LifecycleAction {
  type: LifecycleActionType,
  data?: any,
}

export enum LifecycleActionType {
  AUTHORIZED_AGREEMENTS = 'AUTHORIZED_AGREEMENTS',
  FINISH_USER_SETUP = 'FINISH_USER_SETUP',
  USER_ACCESS_GRANTED = 'USER_ACCESS_GRANTED',
  LOGIN_AGREEMENTS = 'LOGIN_AGREEMENTS',
  USER_LOGOUT = 'USER_LOGOUT',
}

@Injectable({
  providedIn: 'root'
})
export class LifecycleDispatch {
  public generateAction: Subject<LifecycleAction> = new Subject()

  public onLogin: Subject<void> = new Subject()
  public onAuthorizedAgreements: Subject<any> = new Subject()
  public onFinishUserSetup: Subject<any> = new Subject()
  public onUserAccessGranted: Subject<any> = new Subject()
  public onLoginAgreements: Subject<any> = new Subject()
  public onUserLogOut: Subject<any> = new Subject()

  constructor() {
    this.generateAction.pipe(
      filterRx(data => data.type === LifecycleActionType.AUTHORIZED_AGREEMENTS),
      pluck('data'),
    ).subscribe(this.onAuthorizedAgreements)

    this.generateAction.pipe(
      filterRx(data => data.type === LifecycleActionType.FINISH_USER_SETUP),
      pluck('data'),
    ).subscribe(this.onFinishUserSetup)

    this.generateAction.pipe(
      filterRx(data => data.type === LifecycleActionType.USER_ACCESS_GRANTED),
      pluck('data'),
    ).subscribe(this.onUserAccessGranted)

    this.generateAction.pipe(
      filterRx(data => data.type === LifecycleActionType.USER_LOGOUT),
      pluck('data'),
    ).subscribe(this.onUserLogOut)

    this.generateAction.pipe(
      filterRx(data => data.type === LifecycleActionType.LOGIN_AGREEMENTS),
      pluck('data'),
    ).subscribe(this.onLoginAgreements)
  }
}
