import {
  Component,
  OnInit,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import {
  ActivatedRoute,
  Router,
} from '@angular/router'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { PasswordCriteria } from '../../lib/password-criteria'

@Component({
  selector: 'cb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  public changePasswordError = false
  public changePasswordId: string | null
  public errorCode: string
  public errorMessage: string
  public form: FormGroup
  public passwordCriteria: PasswordCriteria = {
    passwordLength: '',
    mixedCase: '',
    nonAlpha: '',
    number: ''
  }
  public resetSuccess = false
  public tenantId: string
  public constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.form = this.builder.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    },
    {
      validators: this.passwordMatchValidator,
    })
  }

  public reset() {
    const newPassword = this.form.get('new_password')!.value

    this.auth.changePassword(newPassword, this.changePasswordId!)
    .then(res => {
      if (res) {
        if (res.oneTimePassword) {
          this.resetSuccess = true
          this.errorMessage = ''
          this.changePasswordError = false
        }
        if (res.fieldErrors) {
          this.errorMessage = res.fieldErrors.password[0].message
          this.changePasswordError = true
          this.resetSuccess = false
          this.form.get('new_password')!.setValue('')
          this.form.get('confirm_password')!.setValue('')
        }
        if (!res.oneTimePassword && !res.fieldErrors) {
          this.errorMessage = res
          this.changePasswordError = true
          this.resetSuccess = false
        }
      }
    })
    .catch(error => {
      console.error('error resetting password', error)
      this.changePasswordError = true
    })
  }

  public passwordMatchValidator(group: FormGroup) {
    return group.get('new_password')!.value === group.get('confirm_password')!.value
    ? null : {'mismatch': true }
  }

  public continueToSignIn() {
    this.router.navigate(['login'])
  }

  public returnToPasswordReset() {
    this.router.navigate(['login/forgot'])
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(params => {
    this.changePasswordId = params.get('changePasswordId')
    this.auth.getUserByChangePasswordId(this.changePasswordId!)
    .then(user => {
      this.tenantId = user.response.user!.tenantId!
      return this.auth.getTenant(this.tenantId)
    })
    .then(response => {
      this.passwordCriteria = this.auth.generatePasswordCriteria(response.tenant.passwordValidationRules)
      })
    })
  }
}
