<!-- navbar -->
<cb-navbar></cb-navbar>

<!-- brandbar -->
<cb-brandbar></cb-brandbar>

<!-- page -->
<main class="page">
  <router-outlet></router-outlet>
</main>

<!-- sidebar -->
<aside class="l-sidebar">
  <router-outlet name="sidebar"></router-outlet>
</aside>

<!-- drawer -->
<router-outlet name="drawer"></router-outlet>
