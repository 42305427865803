import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms'

import {
  DropDownsModule,
  AutoCompleteModule,
} from '@progress/kendo-angular-dropdowns'
import { DatePickerModule } from '@progress/kendo-angular-dateinputs'
import { PanelBarModule } from '@progress/kendo-angular-layout'
import { InputsModule } from '@progress/kendo-angular-inputs'
import { SwitchModule } from '@progress/kendo-angular-inputs'
import { PopupModule } from '@progress/kendo-angular-popup'

import { BaseModule } from '@base/base.module'
import { CoreModule } from '@core/core.module'
import { PanelModule } from '@panel/panel.module'
import { SectionModule } from '@section/section.module'
import { ToolbarModule } from '@toolbar/toolbar.module'
import { SegmentDetailsModule } from '@segment-details/segment-details.module'
import { FormValidationModule } from '@form-validation/form-validation.module'

import { DynamicControlComponent } from '@control/components/dynamic-control/dynamic-control.component'
import { FilterListControlComponent } from '@control/components/filter-list-control/filter-list-control.component'
import { FlagsControlComponent } from '@control/components/flags-control/flags-control.component'
import { PaymentMethodControlComponent } from '@control/components/payment-method-control/payment-method-control.component'
import { PhoneControlComponent } from '@control/components/phone-control/phone-control.component'
import { PlatformAccessControlComponent } from '@control/components/platform-access-control/platform-access-control.component'
import { RepresentativeControlComponent } from '@control/components/representative-control/representative-control.component'
import { RepresentativesControlComponent } from '@control/components/representatives-control/representatives-control.component'
import { StakeholderControlComponent } from '@control/components/stakeholder-control/stakeholder-control.component'
import { AdminUserControlComponent } from '@control/components/admin-user-control/admin-user-control.component'
import { EmailControlComponent } from './components/email-control/email-control.component'
import { KendoSwitchValueComponent } from './components/kendo-switch-value/kendo-switch-value.component'
import { MultiselectComponent } from './components/multiselect/multiselect.component'
import { TextareaComponent } from './components/textarea/textarea.component';
import { ProfileSelectComponent } from './components/profile-select/profile-select.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DatePickerModule,
    DropDownsModule,
    PanelBarModule,
    InputsModule,
    SwitchModule,
    PopupModule,

    BaseModule,
    CoreModule,
    PanelModule,
    SectionModule,
    ToolbarModule,
    SegmentDetailsModule,
    FormValidationModule,
  ],
  declarations: [
    AdminUserControlComponent,
    StakeholderControlComponent,
    DynamicControlComponent,
    FlagsControlComponent,
    RepresentativeControlComponent,
    RepresentativesControlComponent,
    PhoneControlComponent,
    PaymentMethodControlComponent,
    PlatformAccessControlComponent,
    FilterListControlComponent,
    EmailControlComponent,
    KendoSwitchValueComponent,
    MultiselectComponent,
    TextareaComponent,
    ProfileSelectComponent,
  ],
  exports: [
    AdminUserControlComponent,
    StakeholderControlComponent,
    DynamicControlComponent,
    FlagsControlComponent,
    RepresentativeControlComponent,
    RepresentativesControlComponent,
    PhoneControlComponent,
    PaymentMethodControlComponent,
    PlatformAccessControlComponent,
    FilterListControlComponent,
    EmailControlComponent,
    KendoSwitchValueComponent,
    MultiselectComponent,
    TextareaComponent,
    ProfileSelectComponent,
  ]
})
export class ControlModule { }
