import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { combineLatest } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import {
  AgreementsSatisfied,
  AgreementsSatisfiedBoolExp,
  AgreementsSatisfiedInsertInput,
  AgreementsSatisfiedMutationResponse,
  AgreementsSatisfiedOrderBy,
  AgreementsSatisfiedSelectColumn,
  AgreementsSatisfiedSetInput,
  AgreementsSatisfiedUpdateColumn,
  Agreement,
} from '@@types'
import { Service } from '@core/services/service'
import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as viewSubscription from './graphql/active.subscription.graphql'
import { SegmentApiService } from '../segment-api/segment-api.service'
import {
  switchMap,
  take,
  map,
} from 'rxjs/operators'
import { AgreementService } from '../agreement/agreement.service'

@Injectable({
  providedIn: 'root'
})
export class AgreementsSatisfiedService extends Service<AgreementsSatisfied> {
  protected API = 'client'
  protected viewSubscription = viewSubscription
  protected editQuery = find

  public constructor(
    apollo: Apollo,
    private segmentApi: SegmentApiService,
    private agreement: AgreementService,
    ) {
    super(apollo)
  }

  public find(id: string): Observable<AgreementsSatisfied> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: AgreementsSatisfiedSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: AgreementsSatisfiedOrderBy,
      where?: AgreementsSatisfiedBoolExp,
    },
  ): Observable<AgreementsSatisfied[]> {
    return this.query(fetch, variables)
  }

  public create(objects: AgreementsSatisfiedInsertInput[]): Observable<AgreementsSatisfiedMutationResponse> {
    return this.mutate(create, { objects })
  }

  public upsert(
    objects: AgreementsSatisfiedInsertInput[],
    update_columns: AgreementsSatisfiedUpdateColumn[],
  ): Observable<AgreementsSatisfiedMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: AgreementsSatisfiedBoolExp,
    _set: AgreementsSatisfiedSetInput,
  ): Observable<AgreementsSatisfiedMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(where: AgreementsSatisfiedBoolExp): Observable<AgreementsSatisfiedMutationResponse> {
    return this.mutate(remove, { where })
  }

  public agreementsSatisfied(stakeholderId: string): Observable<AgreementsSatisfied[]> {
    const where: AgreementsSatisfiedBoolExp = {
      stakeholder_id: { _eq: stakeholderId }
    }
    return this.fetch({where})
  }

  public missingAgreements(stakeholderId: string): Observable<Agreement[]> {
    const where: AgreementsSatisfiedBoolExp = {
      stakeholder_id: { _eq: stakeholderId }
    }

    const requiredAgreements$ = this.segmentApi.segmentsMatchingStake(stakeholderId).pipe(
      switchMap(segments => this.agreement.getStakeholderAgreements(segments!)),
      take(1),
      )

      return combineLatest([
        requiredAgreements$,
        this.fetch({where}),
      ]).pipe(
        map(([agreements, satisfied]) => agreements.filter(agreement => !satisfied.find(ag => ag.agreement_id === agreement.id))
        ))
  }

  public async deleteAgreementSatisfiedByAgreementId(agreementId: string) {
    const where: AgreementsSatisfiedBoolExp = {
      agreement_id: {_eq: agreementId}
    }
    const response = this.remove(where)
    const deleteAgreementSatisfiedPromise = response.toPromise<AgreementsSatisfiedMutationResponse>()
    return deleteAgreementSatisfiedPromise
  }

  public async acceptAgreement(objects: AgreementsSatisfiedInsertInput[]) {
    const updateColumns: AgreementsSatisfiedUpdateColumn[] = [
      AgreementsSatisfiedUpdateColumn.AgreementId,
      AgreementsSatisfiedUpdateColumn.StakeholderId
    ]
    const response = this.upsert(objects, updateColumns)
    const acceptAgreementPromise = response.toPromise<AgreementsSatisfiedMutationResponse>()
    return acceptAgreementPromise
  }

}
