import {
  Component,
  OnDestroy,
} from '@angular/core'

import {
  Subject,
  BehaviorSubject,
} from 'rxjs'
import {
  filter as filterRx,
  map as mapRx,
  switchMap,
  pluck,
  takeUntil,
} from 'rxjs/operators'

import {
  complement,
  isNil
} from 'ramda'

import {
  Stakeholder,
  Stake,
  Profile,
} from '@@types'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { IdentityService } from '@core/services/identity/identity.service'
import { StakeholderService } from '@core/services/stakeholder/stakeholder.service'
import { PermissionApiService } from '@core/services/permission-api/permission-api.service'

@Component({
  selector: 'cb-profile-selector',
  templateUrl: './profile-selector.component.html',
  styleUrls: ['./profile-selector.component.scss']
})
export class ProfileSelectorComponent implements OnDestroy {
  public destroy: Subject<void>
  public stakeholder: BehaviorSubject<Stakeholder | null> = new BehaviorSubject(null)
  public availableStakes: BehaviorSubject<Stake[]> = new BehaviorSubject([])
  public activeStakeId: string
  public activeProfile: BehaviorSubject<Profile | null> = new BehaviorSubject(null)
  public showPopup = false

  public constructor(
    private identityService: IdentityService,
    private authService: AuthenticationService,
    private stakeHolderService: StakeholderService,
    private permission: PermissionApiService,
  ) {
    this.destroy = new Subject()
    this.activeStakeId = this.authService.decodedJwt.CBV2['X-Hasura-Active-Id']

    this.identityService.me.pipe(
      switchMap(holder => this.stakeHolderService.find(holder.id).pipe()),
      filterRx(holder => holder != null),
    ).subscribe(this.stakeholder)

    this.stakeholder.pipe(
      filterRx(complement(isNil)),
      pluck('stakes'),
      filterRx(() => this.activeStakeId != null && this.activeStakeId !== 'none'),
      mapRx(stakes => stakes.filter(stake => stake.id !== this.activeStakeId))
    )
      .subscribe(this.availableStakes)

    this.stakeholder.pipe(
      filterRx(complement(isNil)),
      pluck('stakes'),
      filterRx(() => this.activeStakeId != null && this.activeStakeId !== 'none'),
      mapRx<Stake[], Profile>(stakes => stakes.find(stake => stake.id === this.activeStakeId)!.profile)
    )
      .subscribe(this.activeProfile)

    this.stakeholder.pipe(
      filterRx(complement(isNil)),
      pluck('stakes'),
      filterRx(() => this.activeStakeId === null || this.activeStakeId === 'none'),
      mapRx<Stake[], Profile>(stakes => stakes[0].profile)
    )
    .subscribe(this.activeProfile)

  }

  public togglePopup($event?: Event) {
    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }
    this.showPopup = !this.showPopup
  }

  public determineType(type: string) {
    switch (type) {
      case 'Lead': return 'Demo'
      case 'Client': return 'Live'
      default: return type
    }
  }

  public switchUser(stakeId: string, $event: Event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.permission.switchUser(stakeId).pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      this.authService.refreshToken()
      location.reload()
    })
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
