import { MonoTypeOperatorFunction } from 'rxjs/internal/types'
import { Observable } from 'rxjs/Observable'
import {
  refCount,
  multicast,
} from 'rxjs/operators'

import { EternalSubject } from '@rxjs/EternalSubject'

function shareEternalSubjectFactory() {
  return new EternalSubject()
}

export function shareEternal<T>(): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.pipe(multicast(shareEternalSubjectFactory), refCount()) as Observable<T>
}
