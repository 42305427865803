<div *ngIf="agreementData | async" class="l-interstitial">

  <!-- header -->
  <h1 class="u-pb-5 u-bb" i18n="Header for disclosures and agreements">Important Dislosures & Agreements</h1>

  <!-- body -->
  <cb-agreements-acknowledgement-confirm
    (agreementsNotAccepted)="receiveAgreementAcknowledgmentValidation($event)"
    [displayAgreements]="true"
    [scenarioType]="'SignIn'"
    [acceptAgreementFor]="(me | async)"
  >
  </cb-agreements-acknowledgement-confirm>

  <!-- footer -->
  <footer class="u-pt-5 u-bt">
    <div class="c-toolbar">
      <button class="btn-rounded btn-transparent btn-default" (click)="cancel()" i18n="Go Back">Go Back</button>
      <button class="btn-rounded btn-primary" (click)="continue()" [disabled]="agreementsNotAccepted" i18n="Go Forward">Continue</button>
    </div>
  </footer>

</div>
