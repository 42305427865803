<cb-popup-menu #popup>

  <!-- button -->
  <button trigger class="c-navbar__link qa-navbar-notifications" [ngClass]="{ 'has-new': (newUnreads | async) }">
    <i class="fas fa-bell"></i>
  </button>

  <!-- popup -->
  <div content class="c-popup c-popup--notifications">

    <!-- popup header -->
    <div class="c-popup__header">
      <!-- <span [ngClass]="{ 'has-new': (newUnreads | async) }"><i class="fas fa-bell"></i></span> -->
      <h2 class="c-popup__title" i18n="Messages">Notifications</h2>
    </div>

    <!-- popup body -->
    <div class="c-popup__body">
      <ng-container *ngFor="let notification of notifications | async">

        <!-- notification -->
        <div class="c-notification" [ngClass]="{'is-unread': !notification.is_read}">

          <!-- notification: avatar -->
          <cb-avatar
            *ngIf="notification.activity.subject.stakeholder"
            [stakeholder]="notification.activity.subject.stakeholder"
            class="c-notification__avatar"
          >
          </cb-avatar>

          <!-- notification: time  -->
          <small class="c-notification__time">
            {{ notification.created_at | timeAgo }} <ng-container i18n="in the past, as in 5 minutes ago">ago</ng-container>
          </small>

          <!-- notification: content -->
          <div class="c-notification__content" [innerHTML]="notification.message"></div>

          <!-- notification: controls -->
          <cb-notification-request
            *ngIf="notification.activity.misc_data && notification.activity.misc_data.request_id"
            [requestId]="notification.activity.misc_data.request_id"
            class="c-notification__controls"
          >
          </cb-notification-request>
        </div>

      </ng-container>
    </div>

    <!-- popup footer -->
    <div class="c-popup__footer u-text-center">
      <a href="" [routerLink]="['/', 'notifications']" i18n="View all notification activity">See all notification activity</a>
    </div>

  </div>
</cb-popup-menu>
