import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'

import { DocumentNode } from 'apollo-link'
import { shareEternalReplay } from '@rxjs/operators'
import { map } from 'rxjs/operators'
import gql from 'graphql-tag'
import { FileType } from '@@types'

import * as presignedPost from './graphql/presigned-post.query.graphql'
import * as signedURL from './graphql/signed-url.query.graphql'
import * as kycFilesForStakeholder from './graphql/kyc-files-for-stakeholder.query.graphql'
import { BehaviorSubject } from 'rxjs'

// TODO: Swap this interface with true type once requests and approvals has been implemented
export interface KYCGridView {
  id: string,
  title: string,
  type: FileType,
  expires_at: number,
  created_at: number,
  uploaded_by: string,
  stakeholder_id: string,
  status: string,
}

@Injectable({
  providedIn: 'root'
})
export class FileAPIService {
  protected API = 'file'
  public activeKYCFiles: BehaviorSubject<KYCGridView[] | null>

  public constructor(
    private apollo: Apollo,
  ) {
    this.activeKYCFiles = new BehaviorSubject(null)
  }

  private fetch<T>(query: DocumentNode, variables?: Record<string, any>): Observable<T> {
    return this.apollo
      .use(this.API)
      .query<any>({ query, variables })
      .pipe(
        map(({ data }) => {
          const keys = Object.keys(data)

          return keys.length > 1 ? data : data[keys[0]]
        }),
        shareEternalReplay(1),
      )
  }

  protected mutate(mutation: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .mutate({ mutation, variables })
  }

  public query<T = any>(query: string): Observable<T> {
    return this.fetch(gql`${query}`)
  }

  public presignedPost(filePath: string): Observable<string> {
    return this.fetch(presignedPost, { filePath })
  }

  public signedURL(filePath: string): Observable<string> {
    return this.fetch(signedURL, { filePath })
  }

  public kycFilesForStakeholder(stakeholderId: string): void {
    this.fetch(kycFilesForStakeholder, { stakeholderId }).subscribe((data: KYCGridView[]) => {
      this.activeKYCFiles.next(data)
    })
  }
}
