import { Component } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import {
  Router,
} from '@angular/router'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

@Component({
  selector: 'cb-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  styleUrls: ['./two-factor-login.component.scss']
})

export class TwoFactorLoginComponent {
  public form: FormGroup
  public twoFactorDeliveryMethod: string
  public userId: string
  constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
    private router: Router,
  ) {
    this.form = this.builder.group({
      code: ['', Validators.required]
    })
    this.auth.getUserByEmail(this.auth.email).then(user => {
      this.userId = user.response.user!.id!
      this.twoFactorDeliveryMethod = user.response.user!.twoFactorDelivery!.toString()
    })
  }

  public submit() {
    const code = this.form.get('code')!.value
    this.auth.twoFactorLogin(code, this.auth.twoFactorId).then((res) => {
      if (res!.statusCode === 200) {
        const isAuthenticated = this.auth.isAuthenticated
        if (isAuthenticated) {
          this.router.navigateByUrl(this.auth.postLoginURL)
        } else {
          console.log('failed to route')
        }
      }
    })
  }
}
