<cb-popup-menu>

  <!-- avatar -->
  <cb-avatar class="c-avatar--navbar qa-avatar" trigger [stakeholder]="me | async"></cb-avatar>

  <!-- popup -->
  <div content class="c-popup c-popup--user">

    <!-- popup header -->
    <div class="c-popup__header">
      <h2 class="c-popup__title">{{ (me | async)?.stakeholder_name.name }}</h2>
      <h3 class="c-popup__subtitle">{{ (me | async)?.attribute_pivot.email }}</h3>
    </div>

    <!-- popup body -->
    <div class="c-popup__body">
      <nav class="c-menu">
        <a *ngIf="(me | async)" class="c-menu__item qa-user-settings" href="#" [routerLink]="['/', 'user']">
          <span class="c-menu__icon"><i class="fas fa-user-circle"></i></span>
          <ng-container i18n="Software settings for end users">User Settings</ng-container>
        </a>
        <a *ngIf="isAdmin" class="c-menu__item qa-help-center" href="https://currentdesk.helpdocs.io/">
          <span class="c-menu__icon"><i class="fas fa-life-ring"></i></span>
          <ng-container i18n="Knowledgebase; website to visit to read more about how to use the software">Help Center</ng-container>
        </a>
        <a *ngIf="isAdmin" class="c-menu__item qa-support-requests" href="https://cb.support.currentdesk.com/">
          <span class="c-menu__icon"><i class="fas fa-inbox"></i></span>
          <ng-container i18n="Requests for help with the software from customer service">Support Requests</ng-container>
        </a>
        <!-- TODO: Need to get the api docs link from David and populate it -->
        <a *ngIf="isAdmin" class="c-menu__item qa-api-docs" href="#" [routerLink]="">
          <span class="c-menu__icon"><i class="fas fa-book"></i></span>
          <ng-container i18n="Documents about the application program interface">API Docs</ng-container>
        </a>
        <a *ngIf="!isAdmin" class="c-menu__item" href="#" [routerLink]="['/', 'user', 'contact']">
          <span class="c-menu__icon"><i class="fas fa-id-card"></i></span>
          <ng-container i18n="The ways and details of how to contact a person, such as telephone number and email address">Contact Information</ng-container>
        </a>
        <a *ngIf="!isAdmin" class="c-menu__item" href="#" [routerLink]="['/', 'user', 'identity']">
          <span class="c-menu__icon"><i class="fas fa-user-shield"></i></span>
          <ng-container i18n="Confirming the user's identity with documents">Identity Verification</ng-container>
        </a>
        <a *ngIf="!isAdmin" class="c-menu__item" href="#" [routerLink]="['/', 'user', 'bank-accounts']">
          <span class="c-menu__icon"><i class="fas fa-university"></i></span>
          <ng-container i18n="Financial accounts at banking institutions">Bank Accounts</ng-container>
        </a>
        <a *ngIf="!isAdmin" class="c-menu__item" href="#" [routerLink]="['/', 'user', 'forms']">
          <span class="c-menu__icon"><i class="fas fa-cabinet-filing"></i></span>
          <ng-container i18n="Documents that provide required information">Forms & Disclosures</ng-container>
        </a>
        <a *ngIf="!isAdmin" class="c-menu__item" href="#" [routerLink]="['/', 'user', 'files']">
          <span class="c-menu__icon"><i class="fas fa-folder-open"></i></span>
          <ng-container i18n="Computer files related to the user">My Files</ng-container>
        </a>
      </nav>
      <nav class="c-menu u-bt">
        <button class="c-menu__item qa-signOut" (click)="logout()">
          <span class="c-menu__icon"><i class="fas fa-power-off"></i></span>
          <ng-container i18n="Log out of the software">Sign out</ng-container>
        </button>
      </nav>
    </div>

  </div>
</cb-popup-menu>
