import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs/Observable'
import {
  Agreement,
  AgreementBoolExp,
  AgreementInsertInput,
  AgreementMutationResponse,
  AgreementOrderBy,
  AgreementSelectColumn,
  AgreementSetInput,
  AgreementUpdateColumn,
  Segment,
  OrderBy,
} from '@@types'
import { FileService } from '@core/services/file/file.service'
import { Service } from '@core/services/service'
import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as activeSubscription from './graphql/active.subscription.graphql'
import * as stakeholderAgreements from './graphql/stakeholder-agreements.query.graphql'
import { pluck } from 'ramda'

@Injectable({
  providedIn: 'root'
})
export class AgreementService extends Service<Agreement> {
  protected API = 'client'
  protected viewSubscription = activeSubscription
  protected editQuery = find
  public constructor(
    apollo: Apollo,
    private fileService: FileService,
  ) {
    super(apollo)
  }

  public find(id: string): Observable<Agreement> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: AgreementSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: AgreementOrderBy,
      where?: AgreementBoolExp,
    },
  ): Observable<Agreement[]> {
    return this.query(fetch, variables)
  }

  public create(objects: AgreementInsertInput[]): Observable<AgreementMutationResponse> {
    return this.mutate(create, { objects })
  }

  public upsert(
    objects: AgreementInsertInput[],
    update_columns: AgreementUpdateColumn[],
  ): Observable<AgreementMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: AgreementBoolExp,
    _set: AgreementSetInput,
  ): Observable<AgreementMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(where: AgreementBoolExp): Observable<AgreementMutationResponse> {
    return this.mutate(remove, { where })
  }

  public getAgreementFile(fileId: string) {
    return this.fileService.find(fileId)
  }

  public getStakeholderAgreements(segments: Segment[]): Observable<Agreement[]> {
    const where = {
      is_published: { _eq: true },
      scenario_id: { _eq: '2' },
      segment_id: {
        _in: pluck('id', segments)
      }
    }

    return this.query(stakeholderAgreements, {where})
  }

  public getAgreementsForScenario(scenario: string) {
    const where: AgreementBoolExp = {
      scenario_id: { _eq: scenario },
      is_published: { _eq: true },
      is_deleted: { _eq: false }
    }

    const order_by: AgreementOrderBy = {
      created_at: OrderBy.Asc
    }
    return this.fetch({where, order_by})
  }

  public get PublishedAgreementsForPaymentMethod() {
    const where: AgreementBoolExp = {
      is_published: { _eq: true },
      scenario_id: { _eq: '1' },
      is_deleted: { _eq: false }
    }

    return this.fetch({ where })
  }
}
