import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators'

import {
  AccessFormatType,
  AccountBoolExp,
  AccountGroup,
  AccountType,
  BookType,
  ChargeUnit,
  Country,
  Currency,
  FileType,
  FileTypeCategory,
  LeverageGridView,
  MarginType,
  PaymentMethod,
  PaymentMethodBoolExp,
  PaymentMethodLogo,
  PaymentMethodTypeBoolExp,
  PaymentProvider,
  PaymentProviderMethodTypeBoolExp,
  PlatformAccess,
  PlatformServer,
  PlatformServerEnvironment,
  PlatformServerRetryStatus,
  PlatformServerType,
  ProfileActivity,
  ProfileCategory,
  ProfileEntryType,
  ProfileGridView,
  ProfileRisk,
  ProfileStatus,
  ProfileType,
  ProfileTypeBoolExp,
  Segment,
  SegmentProperty,
  Stake,
  StakeholderAttributeSection,
  StakeholderClass,
  StakeholderClassBoolExp,
  Tag,
  TransactionBoolExp,
  TransactionStatus,
  TransactionType,
  UserAccess,
  Workflow,
  UserManagementGridView,
  WorkflowStage,
  StakeholderAttributeKey,
  IndividualPartnerAccountGroupDefault,
  MasterUserPermission,
  CountriesToPhoneCodes,
  AgreementScenario,
  RequestType,
  RequestStatus,
  Stakeholder,
  AgreementsToPaymentMethodsBoolExp,
  AgreementsToPaymentMethods,
  PlatformServerBoolExp,
} from '@@types'

import { shareEternalReplay } from '@rxjs/operators'

// Queries
import * as accessFormatTypes from './graphql/access-format-types.query.graphql'
import * as accountCreationRequestQuickViewData from './graphql/account-creation-request-quickview.query.graphql'
import * as accountsDrawerCreate from './graphql/accounts-drawer-create.query.graphql'
import * as accountsDrawerInitialData from './graphql/accounts-drawer-initial-data.query.graphql'
import * as accountsDrawerEdit from './graphql/accounts-drawer-edit.query.graphql'
import * as accountFilter from './graphql/account-filter.query.graphql'
import * as agreementDrawerCreate from './graphql/agreements-drawer-create.query.graphql'
import * as agreementsToPaymentMethods from './graphql/agreements-to-payment-methods.query.graphql'
import * as countries from './graphql/countries.query.graphql'
import * as countriesToPhoneCodes from './graphql/countries-to-phone-codes.query.graphql'
import * as createAccountGroup from './graphql/create-account-group.query.graphql'
import * as createStakeholderDrawer from './graphql/create-stakeholder-drawer.query.graphql'
import * as createPlatformServer from './graphql/create-platform-server.query.graphql'
import * as currencies from './graphql/currencies.query.graphql'
import * as editPaymentMethod from './graphql/edit-payment-method.query.graphql'
import * as defaultWorkflowData from './graphql/default-workflow-data.query.graphql'
import * as filesDrawerCreate from './graphql/files-drawer-create.query.graphql'
import * as manageSecurities from './graphql/manage-securities.query.graphql'
import * as kycTypesDrawerCreate from './graphql/kyc-types-drawer-create.query.graphql'
import * as paymentMethodControl from './graphql/payment-method-control.query.graphql'
import * as paymentProvider from './graphql/payment-provider.query.graphql'
import * as paymentProviders from './graphql/payment-providers.query.graphql'
import * as profileDrawerCreate from './graphql/profile-drawer-create.query.graphql'
import * as profileCategories from './graphql/categories.query.graphql'
import * as profileRisks from './graphql/profile-risks.query.graphql'
import * as profilesFilter from './graphql/profiles-filter.query.graphql'
import * as profileStatuses from './graphql/profile-statuses.query.graphql'
import * as searchByTypeQuery from './graphql/search-by-type.query.graphql'
import * as searchByNameQuery from './graphql/search-by-name.query.graphql'
import * as queryByNameEmail from './graphql/stakes-by-name-email.query.graphql'
import * as searchAdminStakesByNameEmail from './graphql/admin-stakes-by-name-email.query.graphql'
import * as searchByNameTransactionsQuery from './graphql/search-by-name-transactions.query.graphql'
import * as segmentEdit from './graphql/segment-edit.query.graphql'
import * as stakeholderAttributeKeyByNameQuery from './graphql/stakeholder-attribute-key-by-name.query.graphql'
import * as stakeholderClasses from './graphql/stakeholder-classes.query.graphql'
import * as tags from './graphql/profile-tags.query.graphql'
import * as transactionFilter from './graphql/transaction-filter.query.graphql'
import * as transactionTypes from './graphql/transaction-types.query.graphql'
import * as userAccess from './graphql/user-access.query.graphql'
import * as userManagementGridView from './graphql/user-grid-view.query.graphql'
import * as masterUserPermissions from './graphql/master-user-permissions.query.graphql'
import * as masterAdminPermissions from './graphql/master-admin-permissions.query.graphql'
import * as masterPartnerPermissions from './graphql/master-partner-permissions.query.graphql'
import * as countQuery from './graphql/count.query.graphql'
import * as requestFilter from './graphql/request-filter.query.graphql'
import * as searchStakeholderByNameQuery from './graphql/search-stakeholder-by-name.query.graphql'
import * as supportedLeveragesQuery from './graphql/supported-leverages.query.graphql'
// Subscriptions
import * as watchPlatformServer from './graphql/platform-server.subscription.graphql'

export interface CreateProfileDrawer {
  profile_type: ProfileType[]
  profile_activity: ProfileActivity[]
  profile_risk: ProfileRisk[]
  profile_category: ProfileCategory[]
  profile_entry_type: ProfileEntryType[]
  tag: Tag[]
}

export interface FilterProfiles {
  profile_category: ProfileCategory[]
  profile_status: ProfileStatus[]
  tag: Tag[]
  profile_risk: ProfileRisk[]
  country: Country[]
}

export interface RequestFilterData {
  request_type: RequestType[],
  request_status: RequestStatus[],
}

export interface CreateAccountDrawer {
  account_type: AccountType[]
  currency: Currency[]
  account_group: AccountGroup[]
  individual_partner_account_group_default: IndividualPartnerAccountGroupDefault[]
  platform_server: PlatformServer[]
  platform_server_environment: PlatformServerEnvironment[]
  leverage_grid_view: LeverageGridView[]
  platform_server_type: PlatformServerType[]
}

export interface EditAccountDrawer {
  accountGroups: AccountGroup[]
  platformAccesses: PlatformAccess[]
  platformServerLeverages: LeverageGridView[]
}

export interface CreateKYCTypeDrawer {
  file_type_category: FileTypeCategory[]
  segment: Segment[]
}

export interface CreateAgreementsDrawer {
  agreement_scenario: AgreementScenario[]
  segment: Segment[]
}

export interface CreateFileDrawer {
  file_type: FileType[]
  segment: Segment[]
}

export interface CreateAccountGroup {
  currency: Currency[] | null
  platform_group: string[] | null
  profile_category: ProfileCategory[] | null
  book_type: BookType[] | null
  margin_type?: MarginType | null
}

export interface CreatePlatformServer {
  access_format_type: AccessFormatType[] | null,
  platform_server_type: PlatformServerType[] | null,
  platform_server_retry_status: PlatformServerRetryStatus[] | null,
}

export interface DefaultWorkflowData {
  workflow: Workflow[] | null
  workflow_stage: WorkflowStage[] | null
}

export interface SegmentEdit {
  segment: Segment[]
  segment_property: SegmentProperty[]
}

export interface UpsertPaymentMethod {
  segments: Segment[]
  paymentMethodLogoes: PaymentMethodLogo[]
}

export interface AccountFilterData {
  profileCategories: ProfileCategory[]
  profileStatuses: ProfileStatus[]
  tags: Tag[]
  profileRisks: ProfileRisk[]
  accountGroups: AccountGroup[]
  accountTypes: AccountType[]
}
export interface TransactionFilterData {
  transaction_statuses: TransactionStatus[]
  transaction_types: TransactionType[]
  payment_methods: PaymentMethod[]
}

export interface AccountCreationRequestQuickviewData {
  platform_server: PlatformServer[]
  platform_access: PlatformAccess[]
}

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  protected API = 'client'

  public constructor(
    private apollo: Apollo,
  ) { }

  private fetch<T>(query: DocumentNode, variables?: Record<string, any>): Observable<T> {
    return this.apollo
      .use(this.API)
      .query<any>({ query, variables })
      .pipe(
        map(({ data }) => {
          const keys = Object.keys(data)

          return keys.length > 1 ? data : data[keys[0]]
        }),
        shareEternalReplay(1),
      )
  }

  protected watch(query: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .subscribe<any>({
      query,
      variables,
      fetchPolicy: 'no-cache',
    })
    .pipe(
      map(({ data }) => {
        const keys = Object.keys(data)
        const nestedKeys = data && keys ? Object.keys(data[keys[0]] || {}) : []

        return nestedKeys.length === 0 ? null : data[keys[0]]
      }),
    )
  }

  protected mutate(mutation: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .mutate({ mutation, variables })
  }

  public query<T = any>(query: string): Observable<T> {
    return this.fetch(gql`${query}`)

  }

  public get accessFormatTypes(): Observable<AccessFormatType[]> {
    return this.fetch(accessFormatTypes)
  }

  public adminUserGridView(id: string): Observable<UserManagementGridView> {
    const where = {
      where: {
        id: {
          _eq: id,
        }
      }
    }

    return this.fetch(userManagementGridView, where)
  }

  public getAccountFilterData(where: AccountBoolExp): Observable<AccountFilterData> {
    return this.fetch(accountFilter, where)

  }
  public getTransactionFilterData(where: TransactionBoolExp): Observable<TransactionFilterData> {
    return this.fetch(transactionFilter, where)
  }

  public getAccountsDrawerCreate(environmentType: string, platformType: string): Observable<CreateAccountDrawer> {
    return this.fetch(accountsDrawerCreate, { environment: environmentType, platform: platformType })
  }

  public getInitialAccountsDrawerCreate(): Observable<any> {
    return this.fetch(accountsDrawerInitialData)
  }

  public getAccountCreationRequestQuickviewData(platformId: string, currencyId: string): Observable<AccountCreationRequestQuickviewData> {
    const where: PlatformServerBoolExp = {
      type_id: {
        _eq: platformId
      },
      account_groups: {
        currency_id: {
          _eq: currencyId
        }
      }
    }
    return this.fetch(accountCreationRequestQuickViewData, { where })
  }

  public get phoneControlCreate(): Observable<CountriesToPhoneCodes[]> {
    return this.fetch(countriesToPhoneCodes)
  }

  public get accountsDrawerEdit(): Observable<EditAccountDrawer> {
    return this.fetch(accountsDrawerEdit)
  }

  public get countries(): Observable<Country[]> {
    return this.fetch(countries)
  }

  public agreementsToPaymentMethods(method_id: string): Observable<AgreementsToPaymentMethods[]> {
    const where: AgreementsToPaymentMethodsBoolExp = {
      method_id: { _eq: method_id }
    }
    return this.fetch(agreementsToPaymentMethods, { where })
  }

  public supportedLeveragesByPlatformServerId(platform_server_id: string): Observable<PlatformServer[]> {
    const where: PlatformServerBoolExp = {
      id: {
        _eq: platform_server_id
      },
    }
    return this.fetch(supportedLeveragesQuery, { where })
  }

  public get profileCategories(): Observable<ProfileCategory[]> {
    return this.fetch(profileCategories)
  }

  public get mangeSecurities(): Observable<ChargeUnit[]> {
    return this.fetch(manageSecurities)
  }

  public createAccountGroup(): Observable<CreateAccountGroup> {
    return this.fetch(createAccountGroup)
  }

  public createPlatformServer(): Observable<CreatePlatformServer> {
    return this.fetch(createPlatformServer)
  }

  public createStakeholderDrawer(where: StakeholderClassBoolExp = { id: {_neq: '' }}): Observable<StakeholderAttributeSection[]> {
    return this.fetch(createStakeholderDrawer, { where })
  }

  public get currencies(): Observable<Currency[]> {
    return this.fetch(currencies)
  }

  public editPaymentMethod(id: string): Observable<PaymentMethod> {
    return this.fetch(editPaymentMethod, { id })
  }

  public get kycTypesDrawerCreate(): Observable<CreateKYCTypeDrawer> {
    return this.fetch(kycTypesDrawerCreate)
  }

  public get filesDrawerCreate(): Observable<CreateFileDrawer> {
    return this.fetch(filesDrawerCreate)
  }

  public get agreementsDrawerCreate(): Observable<CreateAgreementsDrawer> {
    return this.fetch(agreementDrawerCreate)
  }

  public paymentMethodControl(orientation: string, currencyId: string, segmentIds?: string[]): Observable<PaymentMethod[]> {

    const agreementsWhere: AgreementsToPaymentMethodsBoolExp = {
      agreement: {
        is_deleted: {_eq: false },
        is_published: {_eq: true }
      }
    }

    // Non-admin user
    if (segmentIds) {
      const where: PaymentMethodBoolExp = {
        _and: [
          {
            method_types: {
              provider_method_type: {
                orientation: {
                  name: {
                    _eq: orientation
                  }
                }
              }
            }
          },
          {
            method_types: {
              currencies: {
                currency_id: {
                  _eq: currencyId
                }
              }
            }
          },
          {
            segment_id: {
              _in: segmentIds
            }
          }
        ]
      }

      const methodTypeWhere: PaymentProviderMethodTypeBoolExp = {
        _and: [
          {
            orientation: {
              name: {
                _eq: orientation
              }
            }
          },
          {
            method_types: {
              currencies: {
                currency_id: {
                  _eq: currencyId
                }
              }
            }
          },
          {
            method_types: {
              method: {
                segment_id: {
                  _in: segmentIds
                }
              }
            }
          }
        ]
      }

      const paymentMethodType: PaymentMethodTypeBoolExp = {
        _and: [
          {
            provider_method_type: {
              orientation: {
                name: {
                  _eq: orientation
                }
              }
            }
          },
          {
            currencies: {
              currency_id: {
                _eq: currencyId
              }
            }
          },
          {
            method: {
              segment_id: {
                _in: segmentIds
              }
            }
          }
        ]
      }

      return this.fetch(paymentMethodControl, { where, methodTypeWhere, paymentMethodType, agreementsWhere })

      // Admin user
    } else {
      const where: PaymentMethodBoolExp = {
        _and: [
          {
            method_types: {
              provider_method_type: {
                orientation: {
                  name: {
                    _eq: orientation
                  }
                }
              }
            }
          },
          {
            method_types: {
              currencies: {
                currency_id: {
                  _eq: currencyId
                }
              }
            }
          }
        ]
      }

      const methodTypeWhere: PaymentProviderMethodTypeBoolExp = {
        _and: [
          {
            orientation: {
              name: {
                _eq: orientation
              }
            }
          },
          {
            method_types: {
              currencies: {
                currency_id: {
                  _eq: currencyId
                }
              }
            }
          }
        ]
      }

      const paymentMethodType: PaymentMethodTypeBoolExp = {
        _and: [
          {
            provider_method_type: {
              orientation: {
                name: {
                  _eq: orientation
                }
              }
            }
          },
          {
            currencies: {
              currency_id: {
                _eq: currencyId
              }
            }
          }
        ]
      }

      return this.fetch(paymentMethodControl, { where, methodTypeWhere, paymentMethodType, agreementsWhere })
    }
  }

  // Queries

  public paymentProvider(id: string): Observable<PaymentProvider> {
    return this.fetch(paymentProvider, { id })
  }

  public get paymentProviders(): Observable<any> {
    return this.fetch(paymentProviders)
  }

  public get profileCount() {
    return this.fetch(countQuery).pipe(
      map(({ aggregate: { count } }) => count)
    )
  }

  public get createProfileDrawer(): Observable<CreateProfileDrawer> {
    return this.fetch(profileDrawerCreate)
  }

  // TODO: Complete when profile representatives is architected
  // public get representativeTypes(): Observable<ProfileRepresentativeType[]> {
  //   return this.fetch(profileRepresentativeTypes)
  // }

  public get profileRisks(): Observable<ProfileStatus[]> {
    return this.fetch(profileRisks)
  }

  public get profileStatuses(): Observable<ProfileStatus[]> {
    return this.fetch(profileStatuses)
  }

  public get profileTags(): Observable<ProfileStatus[]> {
    return this.fetch(tags)
  }

  public get filterProfiles(): Observable<FilterProfiles> {
    return this.fetch(profilesFilter)
  }

  public searchByNameEmail(value: string): Observable<Stake[]> {
    return this.fetch(queryByNameEmail, { value })
  }

  public searchByNameTransactions(value: string): Observable<ProfileGridView[]> {
    const formattedValue = '%' + value + '%'

    return this.fetch(searchByNameTransactionsQuery, { value: formattedValue })
  }

  public stakeholderAttributeKeyByName(value: string): Observable<StakeholderAttributeKey[]> {
    return this.fetch(stakeholderAttributeKeyByNameQuery, { pivot_name: value })
  }

  public searchByName(value: string): Observable<ProfileGridView[]> {
    const formattedValue = '%' + value + '%'

    return this.fetch(searchByNameQuery, { value: formattedValue })
  }

  public searchByType(value: string, type: ProfileTypeBoolExp = { name: { _neq: 'Admin' } }): Observable<ProfileGridView[]> {
    const formattedValue = '%' + value + '%'

    return this.fetch(searchByTypeQuery, { value: formattedValue, type })
  }

  public searchStakeholderByName(value: string): Observable<Stakeholder[]> {
    const formattedValue = '%' + value + '%'

    return this.fetch(searchStakeholderByNameQuery, { value: formattedValue })
  }

  public searchAdminStakesByNameEmail(value: string): Observable<Stake[]> {
    const formattedValue = '%' + value + '%'
    return this.fetch(searchAdminStakesByNameEmail, { value: formattedValue })
  }

  public get segmentEdit(): Observable<SegmentEdit> {
    return this.fetch(segmentEdit)
  }

  public get stakeholderClasses(): Observable<StakeholderClass[]> {
    return this.fetch(stakeholderClasses)
  }

  public get transactionTypes(): Observable<TransactionType[]> {
    return this.fetch(transactionTypes)
  }

  public get userAccess(): Observable<UserAccess[]> {
    return this.fetch(userAccess)
  }

  public get defaultWorkflowData(): Observable<DefaultWorkflowData> {
    return this.fetch(defaultWorkflowData)
  }

  public get masterUserPermissions(): Observable<MasterUserPermission> {
    return this.fetch(masterUserPermissions)
  }

  public get masterAdminPermissions(): Observable<any> {
    return this.fetch(masterAdminPermissions)
  }

  public get masterPartnerPermissions(): Observable<any> {
    return this.fetch(masterPartnerPermissions)
  }

  public get requestsFilter(): Observable<RequestFilterData> {
    return this.fetch(requestFilter)
  }

  // Subscriptions
  public watchPlatformServer(id: string): Observable<PlatformServer> {
    return this.watch(watchPlatformServer, { id })
  }
}
