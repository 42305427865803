<ng-container *ngIf="displayAgreements; then agreementTemplate; else blankTemplate">
</ng-container>

<!-- template: agreement -->
<ng-template #agreementTemplate>

  <!-- form control: agreement -->
  <div class="c-form-control" [formGroup]="form" *ngFor="let agreement of (agreements | async)">
    <label class="c-form-control__label">{{ agreement.name }}</label>
    <div class="c-agreement">
      <input *ngIf="agreement.is_acceptance_required" class="c-agreement__checkbox" type="checkbox" [formControlName]="agreement.id" (change)="acceptAgreement(agreement.id)">
      <div class="c-agreement__value">
        <p class="u-mt-1">{{ agreement.acknowledgement }}</p>
        <cb-file-download *ngIf="agreement.file_id" [file_id]="agreement.file_id"></cb-file-download>
      </div>
    </div>
  </div>

</ng-template>

<!-- template: blank // no agreements -->
<ng-template #blankTemplate>
</ng-template>
