<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="Did you lose your security key to log in?">Forgot your password?</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- alerts -->
  <cb-alert *ngIf="!submit" cbAlertWarning>
    <i class="far fa-info-circle"></i>
    <ng-container i18n="Type in your email address, and we will send an email with a link where you can get a new password.">Enter your email address and a link will be sent to reset your password.</ng-container>
  </cb-alert>
  <cb-alert *ngIf="submit" cbAlertSuccess>
    <i class="far fa-check-circle"></i>
    <ng-container i18n="You will get an email that includes a link to follow so you can reset your password.">You should receive an email with a link to reset your password.</ng-container>
  </cb-alert>

  <!-- form control: email address -->
  <div *ngIf="!submit" class="c-form-control">
    <input class="qa-login-email" autocorrect="off" autocapitalize="off" spellcheck="off" type="email" formControlName="email" i18n-placeholder placeholder="email address">
  </div>

  <!-- buttons -->
  <button *ngIf="!submit" class="btn-primary u-mt-3 qa-password-reset-submit" [disabled]="form.invalid" (click)="sendPasswordReset()" i18n="Email me a link to get a new password.">Send Password Reset</button>
  <button *ngIf="!submit" class="c-btn c-btn--link u-mt-3 qa-cancel" [routerLink]="[ '/', 'login' ]" i18n="Disregard">Go back</button>
  <button *ngIf="submit" class="btn-primary u-mt-5 qa-return-login" [disabled]="form.invalid" [routerLink]="[ '/', 'login' ]" i18n="Go back to the page to sign in to the software.">Return to Sign In</button>

</form>
