import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { PanelHeaderDirective } from '@panel/directives/panel-header/panel-header.directive'
import { PanelFooterDirective } from '@panel/directives/panel-footer/panel-footer.directive'

import { PanelComponent } from '@panel/components/panel/panel.component'

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    PanelComponent,

    PanelHeaderDirective,
    PanelFooterDirective,
  ],
  exports: [
    PanelComponent,

    PanelHeaderDirective,
    PanelFooterDirective,
  ],
})
export class PanelModule { }
