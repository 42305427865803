import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core'

import { toCanvas } from 'qrcode'

@Component({
  selector: 'cb-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements AfterViewInit {
  @ViewChild('qr', { static: false }) qr: ElementRef
  canvasContext: any

  @Input()
  data: string

  @Input()
  size: number

  constructor() { }

  ngAfterViewInit() {
    toCanvas(this.qr.nativeElement, this.data, {
      width: this.size,
      margin: 0,
    })
}

}
