import {
  Component,
  Input,
  OnDestroy,
} from '@angular/core'
import {
  BehaviorSubject,
  Subject,
} from 'rxjs'
import {
  filter as filterRx,
  switchMap,
  takeUntil,
} from 'rxjs/operators'

import {
  Request,
  Stakeholder,
} from '@@types'
import { IdentityService } from '@core/services/identity/identity.service'
import { RequestService } from '@core/services/request/request.service'

@Component({
  selector: 'cb-notification-request',
  templateUrl: './notification-request.component.html',
  styleUrls: ['./notification-request.component.scss']
})
export class NotificationRequestComponent implements OnDestroy {
  public destroy: Subject<void> = new Subject()

  @Input() public set requestId(requestId: string) {
    if (requestId) {
      this.activeRequestId.next(requestId)
    }
  }
  public activeRequestId: BehaviorSubject<string|null> = new BehaviorSubject(null)
  public request: BehaviorSubject<Request|null> = new BehaviorSubject(null)
  public activeStakeholder: BehaviorSubject<Stakeholder|null> = new BehaviorSubject(null)

  constructor(
    private requestService: RequestService,
    private identityService: IdentityService,
  ) {
    this.activeRequestId.pipe(
      takeUntil(this.destroy),
      filterRx(id => id !== null),
      switchMap(id => this.requestService.find(id!))
      ).subscribe(this.request)

    this.identityService.me.pipe(
      takeUntil(this.destroy),
    ).subscribe(this.activeStakeholder)
  }

  public approveRequest($event: Event) {
    $event.stopPropagation()
    $event.preventDefault()
    this.requestService.approveRequestWithoutReview(this.request.value!, this.activeStakeholder.value!.id)
    .subscribe(() => {
        // reset activeRequestId to refresh query
        this.activeRequestId.next(this.activeRequestId.value)
      })
  }

  public denyRequest($event: Event) {
    $event.stopPropagation()
    $event.preventDefault()
    this.requestService.denyRequest(
      this.request.value!.id,
      this.activeStakeholder.value!.id
      ).subscribe(() => {
        // reset activeRequestId to refresh query
        this.activeRequestId.next(this.activeRequestId.value)
      })
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy

}
