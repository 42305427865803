<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="Sign in to your account with this software">Sign in to your account</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- alerts -->
  <cb-alert *ngIf="loginFailed" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="The username or the password entered were wrong.">Username or password are incorrect.</ng-container>
  </cb-alert>

  <cb-alert *ngIf="emailNotVerified" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="You need to confirm your email address. We sent you a verification email. Go to your email inbox and click the link in that email to confirm your email address.">Your email address has not been verified. A verification email has been sent to your inbox. Please click on the link in your email to verify your address.</ng-container>
  </cb-alert>

  <cb-alert *ngIf="emailWasVerified" cbAlertSuccess>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="Your email address is confirmed. Log in to the system.">Your email address has been verified. Please sign in below.</ng-container>
  </cb-alert>

  <!-- form control: username/password -->
  <div *ngIf="!emailNotVerified" class="c-form-control">
    <input class="qa-login-email" autocorrect="off" autocapitalize="off" spellcheck="off" type="email" formControlName="email" i18n-placeholder placeholder="email address">
    <input class="u-mt-3 qa-login-password" autocorrect="off" autocapitalize="off" spellcheck="off" type="password" formControlName="password" i18n-placeholder="Secret security key" placeholder="password">
  </div>

  <!-- buttons -->
  <button *ngIf="!emailNotVerified" class="btn-primary u-mt-3 qa-login-submit" [disabled]="form.invalid" (click)="login()" i18n="Log in to the system">Sign in</button>
  <button *ngIf="!emailNotVerified" class="c-btn c-btn--link u-mt-3 qa-forgot-password" [routerLink]="['/', 'login', 'forgot']" i18n="Did you lose the security key to sign in to the software?">Forgot your password?</button>

</form>
