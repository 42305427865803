import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
} from '@angular/core'

import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

@Component({
  selector: 'cb-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements AfterViewInit {

  @ViewChild('popup', { static: false })
  public menu: ElementRef
  @ViewChild('trigger', { static: false })
  public trigger: ElementRef
  public onTriggerEnd = new Subject()
  public onClose = new Subject()

  @Input()
  public display = false

  constructor(
    private renderer: Renderer2,
  ) { }

  public toggle() {
    this.onTriggerEnd.pipe(
      take(1),
    ).subscribe(() => this.display = !this.display)
  }

  public ngAfterViewInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.display && (e.target !== this.menu.nativeElement)) {
        this.display = false
        this.onClose.next()
      }
      this.onTriggerEnd.next()
    })
  }
}
