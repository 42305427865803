import {
  Component,
  OnDestroy,
  Input,
  HostBinding,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subject } from 'rxjs/Subject'
import { takeUntil, pluck, filter } from 'rxjs/operators'
import { Stakeholder, StakeholderClass } from '@@types'
import { initials, isLegalEntity } from '@helpers/stakeholder'

@Component({
  selector: 'cb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnDestroy {
  public destroy: Subject<void>
  public _stakeholder: BehaviorSubject<Stakeholder | null>

  @Input()
  public set stakeholder(value: Stakeholder) {
    this._stakeholder.next(value)
  }

  @HostBinding('style.background')
  public background: string

  @HostBinding('class.c-avatar--entity')
  public entityStyle: boolean

  @HostBinding('class.c-avatar--individual')
  public individualStyle: boolean

  public constructor() {
    this.destroy = new Subject()
    this._stakeholder = new BehaviorSubject(null)

    this._stakeholder.pipe(
      takeUntil(this.destroy),
      filter(value => value != null),
      pluck('class'),
    ).subscribe((value: StakeholderClass) => {
      this.entityStyle = Boolean(isLegalEntity(value))
      this.individualStyle = Boolean(!isLegalEntity(value))
    })

    this._stakeholder.pipe(
      takeUntil(this.destroy),
    )
    .subscribe(stakeholder => {
      if (stakeholder) {
        this.background = stakeholder.color
      }
    })
  }

  public initials() {
    if (this._stakeholder.value != null) {
      return initials(this._stakeholder.value)
    }
  }

  public isLegalEntity() {
    if (this._stakeholder.value != null) {
      return isLegalEntity(this._stakeholder.value.class)
    }
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
