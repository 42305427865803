import {
  Component,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core'

import handleBars from 'handlebars'
import { format } from 'date-fns'
import {
  Subject,
  BehaviorSubject,
  combineLatest,
  ReplaySubject,
  merge,
} from 'rxjs'
import {
  map as mapRx,
  filter as filterRx,
  switchMap,
  takeUntil,
  mapTo,
} from 'rxjs/operators'
import {
  prop,
  assoc,
} from 'ramda'

import {
  Notification,
  NotificationOrderBy,
} from '@@types'
import { NotificationService } from '@core/services/notification/notification.service'
import { PopupMenuComponent } from '../popup-menu/popup-menu.component'

@Component({
  selector: 'cb-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnDestroy, AfterViewInit {
  public destroy: Subject<void> = new Subject()
  public activeStakeId: string
  public notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([])
  public newUnreads: BehaviorSubject<boolean> = new BehaviorSubject(false)
  @ViewChild(PopupMenuComponent, { static: false })
  public popup: PopupMenuComponent
  public constructor(
    private notificationService: NotificationService,
  ) {

    const $unreadNotifications: ReplaySubject<Notification[]> = new ReplaySubject(1)

    this.notificationService.notifications({
      where: { is_read: { _eq: false } },
      order_by: [{ created_at: 'desc' }] as NotificationOrderBy
    }).pipe(
      takeUntil(this.destroy),
    ).subscribe($unreadNotifications)

    $unreadNotifications.pipe(
      takeUntil(this.destroy),
      mapRx(notifications => notifications.length > 0),
    ).subscribe(this.newUnreads)

    $unreadNotifications.pipe(
      mapRx(notifications => notifications.filter(({ is_delivered }) => !is_delivered).map(prop('id'))),
      filterRx(deliveredIds => deliveredIds.length > 0)
    ).subscribe(deliveredIds => {
      this.notificationService.update(
        { id: { _in: deliveredIds } },
        {
          is_delivered: true,
          delivered_at: this.formatTimestamp(new Date().toUTCString()),
        }
      ).subscribe()
    })

    const $unreadCount = $unreadNotifications.pipe(mapRx(notifications => notifications.length))

    const $readNotifications = merge(
      $unreadCount.pipe(
        filterRx(length => length < 5),
        switchMap(length => this.notificationService.fetch({
          where: { is_read: { _eq: true } },
          order_by: [{ created_at: 'desc' }] as NotificationOrderBy,
          limit: 5 - length
        }))),
      $unreadNotifications.pipe(
        mapRx(notifications => notifications.length),
        filterRx(length => length > 5),
        mapTo([])
      )
    )

    combineLatest([
      $unreadNotifications,
      $readNotifications,
    ]).pipe(
      mapRx(notifications => {
        return notifications.flat(1).map(notification => {
          const message = handleBars.compile(notification.message)(notification.activity.template_data)
          return assoc('message', message, notification)
        })
      }),
    ).subscribe(notifications => this.notifications.next(notifications))

  }

  public markRead() {
    // for all unread notifications set is_read true and read_at to the current time
    const readIds = this.notifications.value.filter(({ is_read }) => !is_read).map(prop('id'))
    if (readIds.length > 0) {
      this.notificationService.update(
        { id: { _in: readIds } },
        {
          is_read: true,
          read_at: this.formatTimestamp(new Date().toUTCString()),
        }
      ).subscribe()
    }
  }

  // formatTimestamp 2020-03-11T23:00:42.719584+00:00 // TODO REFACTOR THIS TO SERVICE WITH CHRIS
  public formatTimestamp = (date: string): string => format(new Date(date), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSxxxxx')

  public ngAfterViewInit() {
    this.popup.onClose
    .pipe(
      takeUntil(this.destroy)
    )
    .subscribe(() => this.markRead())
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
