import { Component } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import {
ActivatedRoute,
Router,
} from '@angular/router'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'

@Component({
  selector: 'cb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  public emailNotVerified = false
  public emailVerificationId: string | null
  public emailWasVerified = false
  public form: FormGroup
  public loginFailed = false
  constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.form = this.builder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    })

    this.route.paramMap.subscribe(params => {
      this.emailVerificationId = params.get('emailVerificationId')
    })

    if (this.emailVerificationId) {
      const userId = this.emailVerificationId.substring(0, this.emailVerificationId.lastIndexOf(':'))
      const verificationId = this.emailVerificationId.substring(
        this.emailVerificationId.lastIndexOf(':') + 1,
        this.emailVerificationId.length
      )
      this.auth.getUserByUserId(userId).then(authResponse => {
        if (authResponse.response.user) {
          this.auth.verifyEmail(verificationId)
          this.emailWasVerified = true
        }
      }).catch(err => {
        console.error(err)
        return err
      })
    }
  }

  login() {
    this.auth.login(
      this.form.value.email,
      this.form.value.password
    )
    .then((res) => {
      if (res) {
        switch (res.statusCode) {
          case 202:
            console.log('User is not verified for this application')
            break
          case 203:
            const changePasswordId = res.response.changePasswordId
            console.log(`User must change password ${changePasswordId}`)
            break
          case 212:
            console.log('Email Address is not verified')
            this.emailNotVerified = true
            this.auth.sendEmailVerification(this.form.value.email)
            break
          case 242:
            this.auth.twoFactorId = res.response.twoFactorId!
            this.auth.email = this.form.value.email
            this.router.navigateByUrl(this.router.url + '/twoFactorLogin')
            break
          default:
            console.error(`Unhandled case ${res.statusCode}`)
            break
        }
      } else {
        this.router.navigateByUrl(this.auth.postLoginURL)
      }
    })
    .catch(error => {
      console.error('error logging in', error)
      this.loginFailed = true
    })
  }

  forgotPassword() {
    this.router.navigateByUrl(this.router.url + '/forgot')
  }
}
