<ng-container *ngIf="(activeProfile | async); else loadingTemplate">

  <!-- profile: user has only one profile  -->
  <ng-container *ngIf="(availableStakes|async).length === 0" [ngTemplateOutlet]="activeProfileTemplate" [ngTemplateOutletContext]="{activeProfile: activeProfile}">
  </ng-container>

  <!-- profile: user has multiple profiles -->
  <ng-container *ngIf="(availableStakes|async).length > 0">

    <!-- button: switch profiles (triggers profile selector popup) -->
    <button class="c-profile-selector__trigger" (click)="togglePopup($event)" #anchor>
      <ng-container [ngTemplateOutlet]="activeProfileTemplate" [ngTemplateOutletContext]="{activeProfile: activeProfile}"></ng-container>
    </button>

    <!-- popup: profile selector -->
    <kendo-popup
      *ngIf="showPopup"
      [anchor]="anchor"
      (anchorViewportLeave)="showPopup = false"
      [animate]="false"
      class="c-profile-selector__popup"
      [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
      [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
    >

      <!-- active profile -->
      <div class="c-profile-selector__active">
        <ng-container [ngTemplateOutlet]="activeProfileTemplate" [ngTemplateOutletContext]="{activeProfile: activeProfile}">
        </ng-container>
        <button class="c-icon-btn c-icon-btn--dark" (click)="togglePopup($event)"><i class="far fa-times"></i></button>
      </div>

      <!-- available profiles -->
      <ng-container *ngFor="let stake of availableStakes | async">
        <button class="c-profile-selector__item" (click)="switchUser(stake.id, $event)">
          <div class="c-profile c-profile--dark">
            <span class="c-profile__type"><span class="c-label" [ngClass]="{ 'c-label--admin': stake.profile.type.name === 'Admin', 'c-label--dark': stake.profile.type.name != 'Admin' }">{{ determineType(stake.profile.type.name) }}</span></span>
            <span class="c-profile__number">{{ stake.profile.number }}</span>
            <span class="c-profile__primary">{{ stake.profile.name }}</span>
          </div>
        </button>
      </ng-container>

    </kendo-popup>
  </ng-container>

</ng-container>

<!-- template: loading -->
<ng-template #loadingTemplate i18n="The system is processing information">
  Loading...
</ng-template>

<!-- template: active profile -->
<ng-template #activeProfileTemplate>
  <div class="c-profile c-profile--dark">
    <span class="c-profile__type"><span class="c-label" [ngClass]="{ 'c-label--admin': (activeProfile | async).type.name === 'Admin', 'c-label--dark': (activeProfile | async).type.name != 'Admin' }">{{ determineType((activeProfile | async).type.name) }}</span></span>
    <span class="c-profile__number">{{ (activeProfile | async).number }}</span>
    <span class="c-profile__primary">{{ (activeProfile | async).name }}</span>
  </div>
</ng-template>
