import {
  Component,
  ViewChild,
  ElementRef,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs'

import { Stakeholder } from '@@types'

import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { IdentityService } from '@core/services/identity/identity.service'
import {
  AccessService,
  UserType
} from '@core/services/access/access.service'

@Component({
  selector: 'cb-user-settings-menu',
  templateUrl: './user-settings-menu.component.html',
  styleUrls: ['./user-settings-menu.component.scss']
})
export class UserSettingsMenuComponent {

  @ViewChild('menu', { static: false })
  menu: ElementRef

  public me: BehaviorSubject<Stakeholder | null> = new BehaviorSubject(null)

  constructor(
    private auth: AuthenticationService,
    private identity: IdentityService,
    private access: AccessService,
  ) {
    this.identity.me.subscribe(this.me)
  }

  public get isAdmin() {
    return this.access.userType === UserType.Admin
  }

  public async logout() {
    await this.auth.logout()
  }

}
