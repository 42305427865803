import {
  Component,
  OnDestroy,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs/BehaviorSubject'
import { Subject } from 'rxjs/Subject'
import { takeUntil, filter } from 'rxjs/operators'

import { Stakeholder } from '@@types'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { IdentityService } from '@core/services/identity/identity.service'
import { AccessService } from '@core/services/access/access.service'

@Component({
  selector: 'cb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnDestroy {
  public destroy: Subject<void>
  public me: BehaviorSubject<Stakeholder | null>

  public constructor(
    private auth: AuthenticationService,
    private service: IdentityService,
    public access: AccessService,
  ) {
    this.destroy = new Subject()
    this.me = new BehaviorSubject(null)

    const me$ = this.service.me.pipe(
      takeUntil(this.destroy),
      filter(value => value != null),
    )

    me$.subscribe(this.me)
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy

  public async logout() {
    await this.auth.logout()
  }
}
