import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs'

import {
  Request,
  RequestBoolExp,
  RequestInsertInput,
  RequestMutationResponse,
  RequestOrderBy,
  RequestSelectColumn,
  RequestSetInput,
  RequestUpdateColumn,
  AccountCreationRequestInsertInput,
  AccountCreationRequestMutationResponse,
  OrderBy,
  TransactionCreationRequestInsertInput,
  TransactionCreationRequestMutationResponse,
} from '@@types'

import { Service } from '@core/services/service'

import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as activeSubscription from './graphql/active.subscription.graphql'
import * as createAccountCreationRequest from './graphql/create-account-creation-request.mutation.graphql'
import * as latestApprovedLeverage from './graphql/latest-approved-leverage.query.graphql'
import * as createTransactionCreationRequest from './graphql/create-transaction-creation-request.mutation.graphql'
import cuid from 'cuid'
import { format } from 'date-fns'

export enum RequestStatuses {
  'Awaiting Review' = 0,
  'Approved' = 1,
  'Denied' = 2
}

export enum RequestTypes {
  'Leverage Change' = 0,
  'Account Creation' = 1,
  'Transfer' = 2,
  'Withdrawal' = 3,
  'Deposit' = 4
}

@Injectable({
  providedIn: 'root'
})

export class RequestService extends Service<Request> {
  protected API = 'client'
  protected viewSubscription = activeSubscription
  protected editQuery = find

  public constructor(apollo: Apollo) {
    super(apollo)
  }

  public find(id: string): Observable<Request> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on?: RequestSelectColumn[],
      limit?: number,
      offset?: number,
      order_by?: RequestOrderBy,
      where?: RequestBoolExp,
    },
  ): Observable<Request[]> {
    return this.query(fetch, variables)
  }

  public create(objects: RequestInsertInput[]): Observable<RequestMutationResponse> {
    return this.mutate(create, { objects })
  }

  public upsert(
    objects: RequestInsertInput[],
    update_columns: RequestUpdateColumn[],
  ): Observable<RequestMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: RequestBoolExp,
    _set: RequestSetInput,
  ): Observable<RequestMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(where: RequestBoolExp): Observable<RequestMutationResponse> {
    return this.mutate(remove, { where })
  }

  public createAccountCreationRequest(objects: AccountCreationRequestInsertInput[]): Observable<AccountCreationRequestMutationResponse> {
    return this.mutate(createAccountCreationRequest, { objects })
  }

  public createTransactionCreationRequest(
    objects: TransactionCreationRequestInsertInput[]
  ): Observable<TransactionCreationRequestMutationResponse> {
    return this.mutate(createTransactionCreationRequest, { objects })
  }

  public approveRequestWithoutReview(request: Request, reviewed_by_id: string, transaction_id?: string) {
    switch (request.type_id) {
      case RequestTypes['Leverage Change']:
        return this.approveLeverageChangeRequestWithoutReview(request, reviewed_by_id)
      case RequestTypes['Account Creation']:
        return this.approveAccountCreationRequestWithoutReview(request, reviewed_by_id)
      case RequestTypes['Transfer']:
      case RequestTypes['Withdrawal']:
      case RequestTypes['Deposit']:
        if (transaction_id) {
          return this.approveTransactionRequestWithoutReview(request, reviewed_by_id, transaction_id)
        }
        throw new Error('Transaction Id must be supplied')
      default:
        throw new Error('Not a valid request type')
    }

  }

  private approveTransactionRequestWithoutReview(request: Request, reviewed_by_id: string, transaction_id: string) {
    const where: RequestBoolExp = {
      id: {
        _eq: request.id
      }
    }

    const set: RequestSetInput = {
      reviewed_at: this.dateConstructor(),
      reviewed_by_id,
      status_id: RequestStatuses.Approved,
      approved_item_id: request.requested_item_id,
      acted_on_id: transaction_id
    }

    return this.update(where, set)
  }

  private approveLeverageChangeRequestWithoutReview(request: Request, reviewed_by_id: string) {
    const where: RequestBoolExp = {
      id: {
        _eq: request.id
      }
    }

    const set: RequestSetInput = {
      reviewed_at: this.dateConstructor(),
      reviewed_by_id: reviewed_by_id,
      status_id: RequestStatuses.Approved,
      approved_item_id: request.requested_item_id
    }

    return this.update(where, set)
  }

  private approveAccountCreationRequestWithoutReview(request: Request, reviewed_by_id: string) {
    const approvedRequest: RequestInsertInput = {
      id: request.id,
      reviewed_by_id: reviewed_by_id,
      reviewed_at: this.dateConstructor(),
      status_id: RequestStatuses.Approved,
      type_id: request.type_id,
      created_by_id: request.created_by_id,
      profile_id: request.profile_id,
      requested_item_id: request.requested_item_id,
      approved_item_account: {
        data: {
          type_id: request.requested_item_account!.type_id,
          request_id: request.id,
          id: cuid(),
          allow_password_change: request.requested_item_account!.allow_password_change,
          currency_id: request.requested_item_account!.currency_id,
          server_id: request.requested_item_account!.server_id,
          group_id: request.requested_item_account!.group_id,
          leverage: request.requested_item_account!.leverage,
          platform_access_id: request.requested_item_account!.platform_access_id,
          profile_id: request.profile_id,
          platform_id: request.requested_item_account!.platform_id,
          balance: 0,
          name: request.requested_item_account!.name
        }
      }
    }

    const requestUpdateColumns: RequestUpdateColumn[] = [
      RequestUpdateColumn.Id,
      RequestUpdateColumn.ReviewedById,
      RequestUpdateColumn.ReviewedAt,
      RequestUpdateColumn.StatusId,
      RequestUpdateColumn.ApprovedItemId
    ]

    return this.upsert([approvedRequest], requestUpdateColumns)
  }

  public denyRequest(request_id: string, reviewed_by_id: string): Observable<RequestMutationResponse> {
    const set: RequestSetInput = {
      reviewed_by_id,
      reviewed_at: this.dateConstructor(),
      status_id: RequestStatuses.Denied
    }

    const where: RequestBoolExp = {
      id: { _eq: request_id }
    }

    return this.update(where, set)
  }

  public getLatestApprovedLeverage(accountId: string) {
    const where: RequestBoolExp = {
      type_id: {_eq: RequestTypes['Leverage Change']},
      status_id: {_eq: RequestStatuses.Approved},
      acted_on_id: {_eq: accountId}
    }

    const order_by: RequestOrderBy = {
      reviewed_at: OrderBy.Desc
    }

    const limit = 1

    return this.query(latestApprovedLeverage, { where, order_by, limit } )
  }

  public dateConstructor = (): string => format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSxxxxx')
}
