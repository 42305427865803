import { Injectable } from '@angular/core'
import {
  combineLatest,
  BehaviorSubject,
  ReplaySubject,
} from 'rxjs'
import {
  filter,
  pluck,
  switchMap,
  take,
  mapTo,
} from 'rxjs/operators'
import cuid from 'cuid'

import { GenericService } from '../generic/generic.service'
import { StakeholderAttributeService } from '../stakeholder-attribute/stakeholder-attribute.service'
import {
  StakeholderAttributeUpdateColumn,
  Segment,
  Agreement
} from '@@types'
import { SegmentApiService } from '../segment-api/segment-api.service'
import { AgreementsSatisfiedService } from '../agreements-satisfied/agreements-satisfied.service'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { LifecycleDispatch, LifecycleActionType } from './lifecycle.dispatch'
import { IdentityService } from '../identity/identity.service'

@Injectable({
  providedIn: 'root'
})
export class LifecycleService {
  public accessType: BehaviorSubject<string | null> = new BehaviorSubject(null)
  public myAgreements: BehaviorSubject<Agreement[] | null> = new BehaviorSubject(null)
  public mySegments: ReplaySubject<Segment[]> = new ReplaySubject(1)

  private stakeholderAccess: BehaviorSubject<string | null> = new BehaviorSubject(null)
  private stakeholderId: BehaviorSubject<string | null> = new BehaviorSubject(null)
  constructor(
    private agreementsSatisfiedService: AgreementsSatisfiedService,
    public generic: GenericService,
    public segmentApi: SegmentApiService,
    public stakeholderAttribute: StakeholderAttributeService,
    private auth: AuthenticationService,
    private dispatch: LifecycleDispatch,
    private identity: IdentityService,
  ) {
    this.dispatch.onLogin.subscribe()
    this.myAgreements.subscribe()

    this.identity.me.pipe(
      filter(stakeholder => stakeholder !== null),
      pluck('id'),
    ).subscribe(this.stakeholderId)

    combineLatest(
      [
        this.auth.userTypeRx.pipe(filter(item => item != null)),
        this.stakeholderId.pipe(filter(item => item != null)),
      ]
    )
    this.stakeholderId
      .pipe(
        filter(item => item != null)
      )
      .subscribe(() => this.dispatch.generateAction.next({ type: LifecycleActionType.USER_ACCESS_GRANTED }))

    this.dispatch.onFinishUserSetup.subscribe(data => this.finishUserSetup(data))

    this.stakeholderAccess.pipe(
      filter(value => value != null),
      filter(value => value === 'administrator')
    )

    const cleanse$ = this.dispatch.onUserLogOut.pipe(
      mapTo(null),
    )

    cleanse$.subscribe(this.myAgreements)
    cleanse$.subscribe(this.stakeholderAccess)
    cleanse$.subscribe(this.stakeholderId)

    this.stakeholderId.pipe(
      filter(value => value != null),
      switchMap(id => this.agreementsSatisfiedService.missingAgreements(id!)),
    ).subscribe(this.myAgreements)
  }

  private finishUserSetup(data) {

    const firstName$ = this.generic.stakeholderAttributeKeyByName('first_name').pipe(
      take(1),
    )
    const lastName$ = this.generic.stakeholderAttributeKeyByName('last_name').pipe(
      take(1),
    )

    combineLatest([
      firstName$,
      lastName$,
      this.stakeholderId.pipe(
        filter(item => item !== null),
      ),
    ]).pipe(
      switchMap(([firstName, lastName, stakeholder_id]) => this.stakeholderAttribute.upsert([
        {
          id: cuid(),
          value: data.first_name,
          key_id: firstName[0].id,
          stakeholder_id,
        },
        {
          id: cuid(),
          value: data.last_name,
          key_id: lastName[0].id,
          stakeholder_id,
        },
      ], [StakeholderAttributeUpdateColumn.Value]))
    ).subscribe()
  }

}
