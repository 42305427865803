import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core'
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms'
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
} from 'rxjs'
import {
  map,
  takeUntil,
  tap,
  filter,
} from 'rxjs/operators'
import {
  Agreement,
  AgreementsSatisfied,
  AgreementsSatisfiedBoolExp,
  AgreementsSatisfiedInsertInput,
  Stakeholder,
  PaymentMethod,
} from '@@types'
import { AgreementService } from '@core/services/agreement/agreement.service'
import { AgreementsSatisfiedService } from '@core/services/agreements-satisfied/agreements-satisfied.service'
import { SegmentApiService } from '@core/services/segment-api/segment-api.service'
import { LifecycleService } from '@core/services/lifecycle/lifecycle.service'

enum ScenarioType {
  AccountCreation = 'AccountCreation',
  PaymentMethod = 'PaymentMethod',
  SignIn = 'SignIn'
}

@Component({
  selector: 'cb-agreements-acknowledgement-confirm',
  templateUrl: './agreements-acknowledgement-confirm.component.html',
  styleUrls: ['./agreements-acknowledgement-confirm.component.scss'],
})

export class AgreementsAcknowledgementConfirmComponent implements OnDestroy, AfterViewInit {

  @Input() public acceptAgreementFor: Stakeholder

  @Input() public acceptAgreementOnBehalfOf: Stakeholder

  @Input() public authorizedId: string

  @Input() public displayAgreements: boolean

  @Input() public scenarioType: ScenarioType

  @Input() public paymentMethod: PaymentMethod

  @Output() public acceptedAgreementAlert: EventEmitter<string>

  @Output() public agreementsNotAccepted: EventEmitter<boolean>

  @Output() public passiveAgreementAlert: EventEmitter<string>

  @Output() public submit: EventEmitter<any>

  public acceptedAgreementIds: string[] = []

  public agreementForId: string

  public agreements: BehaviorSubject<Agreement[] | null | undefined>

  public agreementsSatisfied: Observable<AgreementsSatisfied[]>

  public agreementsSatisfiedForAuthorizer: BehaviorSubject<AgreementsSatisfied[] | null | undefined>

  public destroy: Subject<void>

  public form: FormGroup

  public paymentMethodAgreements: Agreement[]

  public passiveAgreementsOnly: boolean

  public unsatisfiedAgreements: boolean

  public constructor(
    private agreementsSatisfiedService: AgreementsSatisfiedService,
    private builder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private lifecycle: LifecycleService,
    private segmentService: SegmentApiService,
    private service: AgreementService,
  ) {
    this.acceptedAgreementAlert = new EventEmitter()
    this.agreements = new BehaviorSubject(null)
    this.agreementsNotAccepted = new EventEmitter()
    this.agreementsSatisfied = new Observable()
    this.agreementsSatisfiedForAuthorizer = new BehaviorSubject(null)
    this.destroy = new Subject()
    this.passiveAgreementAlert = new EventEmitter()
    this.passiveAgreementsOnly = false
    this.paymentMethodAgreements = []
    this.scenarioType = ScenarioType.PaymentMethod
    this.submit = new EventEmitter()
    this.unsatisfiedAgreements = false
    this.constructForm([])
  }

  public constructForm(agreements: Agreement[]) {
    this.form = this.builder.group({})
    agreements.forEach(agreement => {
      agreement.is_acceptance_required
      ? this.form.addControl(agreement.id, this.builder.control(
        false, {validators: this.agreementsAcceptedValidator}
      ))
      : this.form.addControl(agreement.id, this.builder.control(false))
    })

    if (this.form.valid) {
      this.passiveAgreementsOnly = true
      this.agreementsNotAccepted.emit(this.form.invalid)
    }
  }

  public constructFormForTransaction(agreements: Agreement[], agreementsSatisfied: AgreementsSatisfied[]): boolean {
    const agreementsSatisfiedIds = agreementsSatisfied.map(a => a.agreement_id)

    this.form = this.builder.group({})

    agreements.forEach(agreement => {
      if (agreementsSatisfiedIds.includes(agreement.id)) {
        agreement.is_acceptance_required
        ? this.form.addControl(
          agreement.id,
          this.builder.control(
            true,
            {validators: this.agreementsAcceptedValidator}
          )
        )
        : this.form.addControl(agreement.id, this.builder.control(true))

      } else {
        if (agreement.is_acceptance_required) {
          this.form.addControl(agreement.id, this.builder.control(
            false, {validators: this.agreementsAcceptedValidator}
          ))
        } else {
          this.form.addControl(agreement.id, this.builder.control(false))
          this.passiveAgreementAlert.emit(agreement.id)
        }
      }
    })

    if (this.form.valid) {
      this.passiveAgreementsOnly = true
    }
    return this.form.invalid
  }

  public satisfiedAgreementsForTransactions(stakeHolderId: string) {
    this.agreementsSatisfied = this.agreementsSatisfiedService.agreementsSatisfied(stakeHolderId)
  }

  public resetAgreements() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValue(false)
    })
  }

  private getScenarioId(type: ScenarioType) {
    switch (type) {
      case ScenarioType.AccountCreation: return '0'
      case ScenarioType.PaymentMethod: return '1'
      case ScenarioType.SignIn: return '2'
      default:
        console.error('input key is not a valid case')
        return null
    }
  }

  public async acceptAgreement(agreementId: string) {
    const control = this.acceptAgreementFormControl(agreementId)

    if (control.value) {
      this.agreementsNotAccepted.emit(this.form.invalid)
      this.acceptedAgreementAlert.emit(agreementId)
      this.acceptedAgreementIds.push(agreementId)
    } else {
      this.agreementsNotAccepted.emit(this.form.invalid)
    }

  }

  public async saveAcceptedAgreements(acceptedAgreementIds: string[] = [], passiveAgreements: string[] = [], stakeHolderId: any = null ) {
    const agreementsSatisfiedInsertInput: AgreementsSatisfiedInsertInput[] = []
    const passiveAgreementIds = passiveAgreements.length > 0 ? passiveAgreements : this.createPassiveAgreements()
    const acknowledgedAgreements = acceptedAgreementIds.length > 0 ? acceptedAgreementIds : this.acceptedAgreementIds
    acknowledgedAgreements.push(...passiveAgreementIds)
    acknowledgedAgreements.forEach(id => {
      agreementsSatisfiedInsertInput.push({
        agreement_id: id,
        stakeholder_id: stakeHolderId ? stakeHolderId : this.agreementForId
      })
    })
    if (agreementsSatisfiedInsertInput.length > 0) {
      const response = await this.agreementsSatisfiedService.acceptAgreement(agreementsSatisfiedInsertInput)
      if (response) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  public acceptAgreementFormControl(agreementId: string) {
    return (<FormControl>this.form.get(agreementId))
  }

  public agreementsAcceptedValidator(control: FormControl) {
    return control.value === true ? null : {'not_accepted': true}
  }

  public removeSatisfiedAgreements() {
    const where: AgreementsSatisfiedBoolExp = {
      stakeholder_id: {
        _eq: this.agreementForId
      },
      agreement_id: {
        _in: this.acceptedAgreementIds
      }
    }
    return this.agreementsSatisfiedService.remove(where)
  }

  public createPassiveAgreements() {
    const objects: string[] = new Array()
    this.agreements.value!.forEach(agreement => {
      if (!agreement.is_acceptance_required) {
        objects.push(agreement.id)
      }
    })

    return objects
  }

  // AfterViewInit
  public ngAfterViewInit() {
    this.agreementForId = this.acceptAgreementFor ? this.acceptAgreementFor.id : this.authorizedId
    switch (this.scenarioType) {
      case ScenarioType.AccountCreation:
        this.setAgreementDataForAccountCreationScenario()
        break
      case ScenarioType.PaymentMethod:
        this.setAgreementDataForPaymentMethodScenario()
        break
      case ScenarioType.SignIn:
        this.setAgreementDataForSignInScenario()
        break
      default:
        console.error('Not a valid Scenario Type')
        break
    }
  }
  // END AfterViewInit

  private async setAgreementDataForSignInScenario() {
    const agreementData$ = this.lifecycle.myAgreements

    agreementData$.pipe(
      takeUntil(this.destroy),
      filter(data => data != null),
      tap(data => this.unsatisfiedAgreements = data!.length > 0),
      tap(data => this.constructForm(data!))
    ).subscribe(this.agreements)

    agreementData$.pipe(
      takeUntil(this.destroy),
    ).subscribe(
      () => this.changeDetector.detectChanges(),
    )
  }

  private setAgreementDataForAccountCreationScenario() {
    const $segments = this.segmentService.segmentsMatchingStake(this.agreementForId)

    const $scenarioAgreements = this.service.getAgreementsForScenario(this.getScenarioId(this.scenarioType)!)

    const $satisfiedAgreements = this.agreementsSatisfiedService.agreementsSatisfied(this.agreementForId)

    const $agreementData = combineLatest([$segments, $scenarioAgreements, $satisfiedAgreements])

    $agreementData.pipe(
      takeUntil(this.destroy),
      map(([segments, scenarioAgreements, satisfiedAgreements]) => {

        const segmentIds = segments.map(seg => seg.id)

        const agreementSegmentIds = scenarioAgreements.map(agree => agree.segment_id)

        const satisfiedAgreementIds = satisfiedAgreements.map(sat => sat.agreement_id)

        const agreementSegmentIdsPertainingToHolder = agreementSegmentIds.filter(
          id => segmentIds.includes(id)
        )

        const agreementsForHoldersSegments = scenarioAgreements.filter(
          sa => agreementSegmentIdsPertainingToHolder.includes(sa.segment_id)
        )

        const agreementIds = agreementsForHoldersSegments.map(agreement => agreement.id)

        const agreementIdsForHoldersSegmentsThatHaveNotBeenAccepted = agreementIds.filter(
          id => !satisfiedAgreementIds.includes(id)
        )

        const agreementsForHoldersSegmentsThatHaveNotBeenAccepted = scenarioAgreements.filter(
          sa => agreementIdsForHoldersSegmentsThatHaveNotBeenAccepted.includes(sa.id)
        )

        if (agreementsForHoldersSegmentsThatHaveNotBeenAccepted.length > 0) {
          this.unsatisfiedAgreements = true
        } else {
          this.unsatisfiedAgreements = false
        }
        scenarioAgreements = agreementsForHoldersSegmentsThatHaveNotBeenAccepted

        return scenarioAgreements
      }),
      tap(agreements => this.constructForm(agreements))
    ).subscribe(agreements => this.agreements = new BehaviorSubject(agreements))
  }

  private setAgreementDataForPaymentMethodScenario() {
    if (this.paymentMethod) {
      const paymentMethodAgreements = this.paymentMethod.agreements.map(agreementsToPaymentMethod => agreementsToPaymentMethod.agreement)
      this.satisfiedAgreementsForTransactions(this.authorizedId)
      this.agreementsSatisfied.pipe(
        takeUntil(this.destroy)
      ).subscribe(agreementsSatisfied => {
        this.agreements.next(paymentMethodAgreements)
        this.agreementsSatisfiedForAuthorizer = new BehaviorSubject(agreementsSatisfied)
        this.constructFormForTransaction(paymentMethodAgreements, agreementsSatisfied)
      })
    } else {
      this.agreements.next(this.paymentMethodAgreements)
    }
  }

  // OnDestroy
  public ngOnDestroy() {
    this.destroy.next()
    this.destroy.unsubscribe()
  }
  // End OnDestroy
}
