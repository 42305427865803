import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { NgModule, LOCALE_ID } from '@angular/core'

// Kendo UI
import { DialogModule } from '@progress/kendo-angular-dialog'
import { load, IntlModule } from '@progress/kendo-angular-intl'
import * as likelySubtags from 'cldr-data/supplemental/likelySubtags.json'
import * as weekData from 'cldr-data/supplemental/weekData.json'
import * as currencyData from 'cldr-data/supplemental/currencyData.json'
import * as numbers from 'cldr-data/main/en/numbers.json'
import * as timeZoneNames from 'cldr-data/main/en/timeZoneNames.json'
import * as calendar from 'cldr-data/main/en/ca-gregorian.json'
import * as currencies from 'cldr-data/main/en/currencies.json'
import * as dateFields from 'cldr-data/main/en/dateFields.json'

import {
  ApolloModule,
} from 'apollo-angular'

import { HttpLinkModule } from 'apollo-angular-link-http'

import { AppRoutingModule } from 'app/app-routing.module'

import { CoreModule } from '@core/core.module'
import { AuthenticationModule } from '@authentication/authentication.module'
import { DashboardModule } from '@dashboard/dashboard.module'


import { AppComponent } from 'app/app.component'
import { ConnectionService } from '@core/services/connection/connection.service'
import { LifecycleService } from '@core/services/lifecycle/lifecycle.service'

load(
  likelySubtags,
  weekData,
  currencyData,
  numbers,
  currencies,
  calendar,
  dateFields,
  timeZoneNames
)

@NgModule({
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Third-party Modules
    ApolloModule,
    HttpLinkModule,

    // Kendo I18n Module
    IntlModule,
    DialogModule,

    // Modules
    CoreModule,
    AuthenticationModule,
    DashboardModule,

    // Routing Module
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
})
export class AppModule {
  constructor(
    _connection: ConnectionService,
    _lifecycle: LifecycleService,
  ) { }
}
