import { Observable } from 'rxjs/Observable'

import {
  merge,
  BehaviorSubject,
  of,
  iif,
} from 'rxjs'
import {
  filter as filterRX,
  map,
  switchMap,
  mapTo,
} from 'rxjs/operators'

import { T, isNil } from 'ramda'

import { Apollo } from 'apollo-angular'
import { DocumentNode } from 'apollo-link'

import { EternalSubject } from '@rxjs/EternalSubject'
import { shareEternal } from '@rxjs/operators'

type Data<Type> = Record<string, Type>

export abstract class BaseService<Type> {
  public view: BehaviorSubject<Type | null>
  public edit: BehaviorSubject<Type | null>

  protected viewId: EternalSubject<string>
  protected editId: EternalSubject<string>

  protected abstract viewSubscription: DocumentNode | null
  protected abstract editQuery: DocumentNode | null

  public constructor(protected apollo: Apollo) {
    this.view = new BehaviorSubject(null)
    this.edit = new BehaviorSubject(null)

    this.viewId = new EternalSubject()
    this.editId = new EternalSubject()

    this.view.subscribe(T)
    this.edit.subscribe(T)

    const view = this.viewId
    .pipe(
      filterRX(() => this.viewSubscription != null),
      switchMap(id => iif(
        () => id != null,
        this.watchOne(this.viewSubscription!, id),
        of(null)
        ))
    )

    view.subscribe(this.view)

    const setEdit = this.editId
    .pipe(
      filterRX(() => this.editQuery != null),
      filterRX(data => data != null),
      switchMap(id => this.queryOne(this.editQuery!, { id })),
    )
    const clearEdit = this.editId
    .pipe(
      filterRX(isNil),
      mapTo(null),
    )
    merge<Type | null>(
      setEdit,
      clearEdit
    )
    .subscribe(this.edit)
  }

  public setView(id?: string) {
    this.viewId.next(id)
  }
  public setEdit(id?: string) {
    this.editId.next(id)
  }

  protected abstract watchOne(subscription: DocumentNode, id: string): Observable<Type>
  protected abstract queryOne(query: DocumentNode, variables?: Record<string, any>): Observable<Type>
}

export abstract class Service<Type> extends BaseService<Type> {
  protected abstract API: string

  public constructor(protected apollo: Apollo) {
    super(apollo)
  }

  protected query(query: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .query<Data<Type[]>>({ query, variables })
    .pipe(
      map(({ data }) => this.peel(data)),
    )
  }

  protected queryOne(query: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .query<Data<Type>>({ query, variables })
    .pipe(
      map(({ data }) => this.peel(data)),
      shareEternal()
    )
  }

  protected mutate(mutation: DocumentNode, variables?: Record<string, any>): Observable<any> {
    return this.apollo
    .use(this.API)
    .mutate({ mutation, variables })
  }

  protected mutateOne(mutation: DocumentNode, variables?: Record<string, any>): Observable<any> {
    return this.apollo
    .use(this.API)
    .mutate({ mutation, variables })
  }

  protected watch(query: DocumentNode, variables?: Record<string, any>) {
    return this.apollo
    .use(this.API)
    .subscribe({
      query,
      variables,
      fetchPolicy: 'no-cache',
    })
    .pipe(
      map(({ data }: { data: Data<Type[]> }) => this.peel(data))
    )
  }

  protected watchOne(query: DocumentNode, id: string): Observable<Type> {
    return this.apollo
    .use(this.API)
    .subscribe({
      query,
      variables: { id },
      fetchPolicy: 'no-cache',
    })
    .pipe(
      map(({ data }: { data: Data<Type> }) => this.peel(data)),
    )
  }

  private peel<R>(data: R | Data<R>): R {
    const keys = Object.keys(data)

    return keys.length > 1 ? data : data[keys[0]]
  }
}
