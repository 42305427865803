import { NgModule } from '@angular/core'
import {
  Routes,
  RouterModule,
} from '@angular/router'
import { UnauthGuard } from '@authentication/guards/unauth.guard'
import { ForgotPasswordComponent } from '@authentication/pages/forgot-password/forgot-password.component'
import { LayoutComponent } from '@authentication/pages/layout/layout.component'
import { LoginComponent } from '@authentication/pages/login/login.component'
import { ResetPasswordComponent } from '@authentication/pages/reset-password/reset-password.component'
import { SetPasswordComponent } from '@authentication/pages/set-password/set-password.component'
import { SetTwoFactorComponent } from '@authentication/pages/set-two-factor/set-two-factor.component'
import { TwoFactorLoginComponent } from '@authentication/pages/two-factor-login/two-factor-login.component'
import { AgreementAcknowledgementComponent } from '@agreements/pages/agreement-acknowledgement/agreement-acknowledgement.component'
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
  {
    path: 'login',
    component: LayoutComponent,
    canActivateChild: [UnauthGuard],
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'twoFactorLogin', component: TwoFactorLoginComponent},
      { path: 'verify/:emailVerificationId', component: LoginComponent}
    ],
  },
  {
    path: 'reset',
    component: LayoutComponent,
    canActivateChild: [UnauthGuard],
    children: [
      {path: ':changePasswordId', component: ResetPasswordComponent}
    ],
  },
  {
    path: 'acknowledgement',
    component: AgreementAcknowledgementComponent,
    canActivateChild: [AuthGuard],
  },
  {
    path: 'setPassword',
    component: LayoutComponent,
    canActivateChild: [UnauthGuard],
    children: [
      {path: ':changePasswordId', component: SetPasswordComponent}
    ],
  },
  {
    path: 'setTwoFactor',
    component: LayoutComponent,
    canActivateChild: [UnauthGuard],
    children: [
      {path: ':userId', component: SetTwoFactorComponent}
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
