import {
  Component,
  OnInit,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms'
import {
  ActivatedRoute,
  Router,
} from '@angular/router'
import { AuthenticationService } from '@authentication/services/authentication/authentication.service'
import { PasswordCriteria } from '../../lib/password-criteria'
import { MockData } from '../../mock-data/mock-data'

@Component({
  selector: 'cb-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  public changePasswordError = false
  public changePasswordId: string | null
  public email: string
  public errorCode: string
  public errorMessage: string
  public form: FormGroup
  public passwordCriteria: PasswordCriteria = {
    passwordLength: '',
    mixedCase: '',
    nonAlpha: '',
    number: ''
  }
  public resetSuccess = false
  public tenantId: string
  public userId: any
  public mockRequireTwoFactor: boolean
  public constructor(
    private auth: AuthenticationService,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private mock: MockData,
  ) {
    this.form = this.builder.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    },
    {
      validators: this.passwordMatchValidator,
    })

    this.mockRequireTwoFactor = this.mock.mockRequireTwoFactor
  }

  public setPassword() {
    const newPassword = this.form.get('new_password')!.value
    this.auth.password = newPassword
    this.auth.changePassword(newPassword, this.changePasswordId!)
    .then(res => {
      if (res) {
        if (res.oneTimePassword) {
          this.resetSuccess = true
          this.errorMessage = ''
          if (this.mockRequireTwoFactor) {
            this.router.navigate([`setTwoFactor/${this.userId}`])
          } else {
            this.auth.login(this.email, newPassword).then(() => {
              this.router.navigateByUrl(this.auth.postLoginURL)
            })
          }
        }
        if (res.fieldErrors) {
          this.errorMessage = res.fieldErrors.password[0].message
          this.changePasswordError = true
          this.form.get('new_password')!.setValue('')
          this.form.get('confirm_password')!.setValue('')
        }
        if (!res.oneTimePassword && !res.fieldErrors) {
          this.errorMessage = res
          this.changePasswordError = true
        }
      }
    })
    .catch(error => {
      console.error('error resetting password', error)
      this.changePasswordError = true
    })
  }

  public passwordMatchValidator(group: FormGroup) {
    return group.get('new_password')!.value === group.get('confirm_password')!.value
    ? null : {'mismatch': true }
  }

  public continueToTwoFactorAuthentication() {
    this.setPassword()
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.changePasswordId = params.get('changePasswordId')
      this.auth.getUserByChangePasswordId(this.changePasswordId!)
      .then(user => {
        this.email = user.response.user!.email!
        this.userId = user.response.user!.id
        this.tenantId = user.response.user!.tenantId!
        return this.auth.getTenant(this.tenantId)
      })
      .then(response => {
        this.passwordCriteria = this.auth.generatePasswordCriteria(response.tenant.passwordValidationRules)
      })
    })
  }
}
