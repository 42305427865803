import { CountriesToPhoneCodes } from '@@types'

export class MockData {
  public mockCountriesToPhoneCodes: CountriesToPhoneCodes[]
  public mockRequireTwoFactor: boolean
  public constructor() {
    this.mockCountriesToPhoneCodes = [
      {
        code: '+86',
        country_id: 'ck37jzdy300f0kovhguia9koq',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy300f0kovhguia9koq',
          flag: '/assets/images/flags/cn.svg',
          name: 'China',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+49',
        country_id: 'ck37jzdy400g1kovh40iy5tjj',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy400g1kovh40iy5tjj',
          flag: '/assets/images/flags/de.svg',
          name: 'Germany',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+48',
        country_id: 'ck37jzdy700inkovhcb4a19o4',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy700inkovhcb4a19o4',
          flag: '/assets/images/flags/pl.svg',
          name: 'Poland',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+7',
        country_id: 'ck37jzdy700itkovh0vovhg9q',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy700itkovh0vovhg9q',
          flag: '/assets/images/flags/ru.svg',
          name: 'Russian Federation',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+65',
        country_id: 'ck37jzdy800jakovhfhcwb3ha',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy800jakovhfhcwb3ha',
          flag: '/assets/images/flags/sg.svg',
          name: 'Singapore',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+971',
        country_id: 'ck37jzdy900k8kovh64kqaols',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy900k8kovh64kqaols',
          flag: '/assets/images/flags/ae.svg',
          name: 'United Arab Emirates',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+44',
        country_id: 'ck37jzdy900k9kovh15rmgud6',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy900k9kovh15rmgud6',
          flag: '/assets/images/flags/gb.svg',
          name: 'United Kingdom',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
      {
        code: '+1',
        country_id: 'ck37jzdy900kakovhcgkjhxuw',
        created_at: '2019-11-20T17:20:58.627296+00:00',
        updated_at: '2019-11-20T17:20:58.627296+00:00',
        country: {
          id: 'ck37jzdy900kakovhcgkjhxuw',
          flag: '/assets/images/flags/us.svg',
          name: 'United States',
          created_at: '2019-11-20T17:20:58.627296+00:00',
          updated_at: '2019-11-20T17:20:58.627296+00:00',
          phone_codes: [],
          phone_codes_aggregate: {
            aggregate: {
              count: 1
            },
            nodes: []
          }
        },
      },
    ]

    this.mockRequireTwoFactor = true
  }
}
