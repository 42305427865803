import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'
import { BehaviorSubject } from 'rxjs'
import { shareEternalReplay } from '@rxjs/operators'
import gql from 'graphql-tag'

import * as segmentsMatchingStake from './graphql/segments-matching-stake.query.graphql'
import * as segmentsMatchingStakeByStake from './graphql/segments-matching-stake-by-stake.query.graphql'
import { map } from 'rxjs/operators'
import { DocumentNode } from 'apollo-link'
import { Segment } from '@@types'

@Injectable({
  providedIn: 'root'
})
export class SegmentApiService {
  protected API = 'segment'
  public segments: BehaviorSubject<any[] | null>

  constructor(
    private apollo: Apollo,
  ) {
    this.segments = new BehaviorSubject(null)
  }

  private fetch<T>(query: DocumentNode, variables?: Record<string, any>): Observable<T> {
    return this.apollo
      .use(this.API)
      .query<any>({ query, variables })
      .pipe(
        map(({data}) => {
          const keys = Object.keys(data)

          return keys.length > 1 ? data : data[keys[0]]
        }),
        shareEternalReplay(1),
      )
  }

  public query<T = any>(query: string): Observable<T> {
    return this.fetch(gql`${query}`)
  }

  public segmentsMatchingStake(stakeholderId: string): Observable<Segment[]> {
    return this.fetch(segmentsMatchingStake, {stakeholderId})
  }

  public segmentsMatchingStakeByStake(stakeId: string): Observable<Segment[]> {
    return this.fetch(segmentsMatchingStakeByStake, {stakeId})
  }
}
