<!-- Success -->
<ng-template #createProfile>
  <span i18n="A new profile has been created">A new profile has been created</span>
</ng-template>

<ng-template #createAccount>
  <span i18n="A new account has been created">A new account has been created</span>
</ng-template>

<ng-template #createPlatformServer>
  <span i18n="A new platform server has been created">A new platform server has been created</span>
</ng-template>

<ng-template #createBankAccount>
  <span i18n="A new bank account has been created">A new bank account has been created</span>
</ng-template>

<ng-template #uploadFile>
  <span i18n="Your file has been uploaded">Your file has been uploaded</span>
</ng-template>

<ng-template #createAgreement>
  <span i18n="A new agreement has been created">A new agreement has been created</span>
</ng-template>

<ng-template #updateAgreement>
  <span i18n="Agreement updated">Agreement updated</span>
</ng-template>

<ng-template #deleteAgreement>
  <span i18n="Agreement deleted">Agreement deleted</span>
</ng-template>

<ng-template #publishAgreement>
  <span i18n="Agreement publishd">Agreement published</span>
</ng-template>

<ng-template #createAccountGroup>
  <span i18n="Your account group has been created">A new account group has been created</span>
</ng-template>

<ng-template #createSecurity>
  <span i18n="A new security has been created">A new security has been created</span>
</ng-template>

<ng-template #accountGroupSettings>
  <span i18n="changes to account group settings have been saved">Account Group settings updated</span>
</ng-template>

<ng-template #updateSecurity>
  <span i18n="A security has been updated">A security has been updated</span>
</ng-template>

<ng-template #updateSymbol>
  <span i18n="changes to symbol settings have been saved">Symbol settings updated</span>
</ng-template>

<ng-template #updatePlatformServer>
  <span i18n="Platform server settings have been updated">Platform server settings have been updated</span>
</ng-template>

<!-- Error -->

<ng-template #serverError>
  <span>Unable to process your request at this time</span>
</ng-template>
