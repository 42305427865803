import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular'

import { Observable } from 'rxjs/Observable'

import {
  File,
  FileBoolExp,
  FileInsertInput,
  FileMutationResponse,
  FileOrderBy,
  FileSelectColumn,
  FileSetInput,
  FileUpdateColumn,
  FilesToOrganizationsInsertInput,
  FilesToStakeholdersInsertInput,
} from '@@types'

import { Service } from '@core/services/service'

import * as find from './graphql/find.query.graphql'
import * as fetch from './graphql/fetch.query.graphql'
import * as create from './graphql/create.mutation.graphql'
import * as createForOrganization from './graphql/create-for-organization.mutation.graphql'
import * as createForStakeholder from './graphql/create-for-stakeholder.mutation.graphql'
import * as update from './graphql/update.mutation.graphql'
import * as upsert from './graphql/upsert.mutation.graphql'
import * as remove from './graphql/remove.mutation.graphql'
import * as activeSubscription from './graphql/active.subscription.graphql'
import * as markForDeletion from './graphql/mark-for-deletion.mutation.graphql'

@Injectable({
  providedIn: 'root'
})
export class FileService extends Service<File> {
  protected API = 'client'
  protected viewSubscription = activeSubscription
  protected editQuery = find

  public constructor(
    apollo: Apollo,
  ) {
    super(apollo)
  }

  public find(id: string): Observable<File> {
    return this.queryOne(find, { id })
  }

  public fetch(
    variables: {
      distinct_on: FileSelectColumn[],
      limit: number,
      offset: number,
      order_by: FileOrderBy,
      where: FileBoolExp,
    },
  ): Observable<File[]> {
    return this.query(fetch, variables)
  }

  public create(objects: FileInsertInput[]): Observable<FileMutationResponse> {
    return this.mutate(create, { objects })
  }

  public createForOrganization(objects: FilesToOrganizationsInsertInput[]) {
    return this.mutate(createForOrganization, { objects })
  }

  public createForStakeholder(objects: FilesToStakeholdersInsertInput[]) {
    return this.mutate(createForStakeholder, { objects })
  }

  public upsert(
    objects: FileInsertInput[],
    update_columns: FileUpdateColumn[],
  ): Observable<FileMutationResponse> {
    return this.mutate(upsert, { objects, update_columns })
  }

  public update(
    where: FileBoolExp,
    _set: FileSetInput,
  ): Observable<FileMutationResponse> {
    return this.mutate(
      update,
      {
        where,
        _set,
      },
    )
  }

  public remove(id: string): Observable<FileMutationResponse> {
    return this.mutate(remove, { id })
  }

  public markForDeletion(id: string): Observable<FileMutationResponse> {
    return this.mutate(markForDeletion, { id })
  }
}
