<!-- header -->
<h1 class="c-text-header u-text-center u-mb-4" i18n="Finish establishing a user account">Complete Account Setup</h1>

<!-- form -->
<form class="c-authentication__form" [formGroup]="form">

  <!-- alerts -->
  <cb-alert *ngIf="changePasswordError" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container i18n="Something went wrong with setting your password. Do it again.">{{ errorCode }} error occurred while setting your password. Please try again.</ng-container>
  </cb-alert>
  <cb-alert *ngIf="errorMessage" cbAlertDanger>
    <i class="far fa-exclamation-triangle"></i>
    <ng-container>{{ errorMessage }}</ng-container>
  </cb-alert>

  <!-- form control: email address -->
  <div class="c-form-control">
    <label class="c-form-control__label" i18n="Email Address">Email Address</label>
    <span>{{ email }}</span>
  </div>

  <!-- form control: set password -->
  <div class="c-form-control">
    <label class="c-form-control__label" i18n="Pick a new password that meets the following rules">Create a password that meets the following criteria: {{ passwordCriteria.passwordLength }} {{ passwordCriteria.mixedCase }} {{ passwordCriteria.nonAlpha }} {{ passwordCriteria.number }}</label>
    <input *ngIf="!resetSuccess" class="qa-new-password" autocorrect="off" autocapitalize="off" spellcheck="off" type="password" formControlName="new_password" i18n-placeholder="New security key for logging in" placeholder="new password">
    <input *ngIf="!resetSuccess" class="u-mt-2 qa-confirm-password" autocorrect="off" autocapitalize="off" spellcheck="off" type="password" formControlName="confirm_password" i18n-placeholder="Reenter the new password to double check it" placeholder="confirm password">
  </div>

  <!-- button: continue -->
  <button class="btn-primary u-mt-5 qa-return-login" [disabled]="form.invalid" (click)="continueToTwoFactorAuthentication()" i18n="Go on">Continue</button>

</form>
